<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='create'"
    style="color: white;" class="modal-title pull-left">Create Language Response Option</h4>
    <h4 *ngIf="fromParent.context==='edit'"
    style="color: white;" class="modal-title pull-left">Edit Language Response Option</h4>
    
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #botResponseOptionForm="ngForm">  

        <div *ngIf="fromParent.context==='create'">
        <div>
            <label for="fkBotLanguageId">Language</label>
            <select required class="form-control" [(ngModel)]="botLangResOption.fkBotLanguageId">
              <option value="chooselanguage" disabled>Choose Language</option>
              <option *ngFor="let temp of botLanguages" [ngValue]="temp.botLanguageId">{{temp.language}}</option>
            </select>
          </div>

          <br>
        
        <div class="form-group row">

            <div class="col-md-4">
            <label class="required" for="responseOptionNumber">Option Number</label>
            <input  #phone="ngModel" required maxlength="250" class="form-control" type="text" placeholder="Enter Option Number" 
            name="responseOptionNumber" id="responseOptionNumber" [(ngModel)]="botLangResOption.langResOptionNumber">
            <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid"> Number required</div>
            </div>
    
            <div class="col-md-8">
            <label class="required" for="responseOption">Option</label>
            <input  maxlength="19" #responseOption="ngModel" required class="form-control" type="text" placeholder="Enter Option" 
            name="responseOption" id="responseOption" [(ngModel)]="botLangResOption.langResOption">
            <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Option required</div>
            </div>
          
        </div>

        <div class="form-group row">

          <div class="col-md-4">
          <label class="required" for="interactiveListSectionName"> Interactive List Section Name</label>
          <input class="form-control" type="text" placeholder="Enter Section Name" 
          name="interactiveListSectionName" id="interactiveListSectionName" [(ngModel)]="botLangResOption.interactiveListSectionName">
          </div>
  
          <div class="col-md-8">
          <label class="required" for="responseOptionDescription">Description</label>
          <input class="form-control" type="text" placeholder="Enter Description" 
          name="responseOptionDescription" id="responseOptionDescription" [(ngModel)]="botLangResOption.responseOptionDescription">
       
          </div>
        
      </div>

    </div>

    <div *ngIf="fromParent.context==='edit'">
       
        
        <div class="form-group row">

            <div class="col-md-4">
            <label class="required" for="responseOptionNumber">Option Number</label>
            <input  #phone="ngModel" required maxlength="250" class="form-control" type="text" placeholder="Enter Option Number" 
            name="responseOptionNumber" id="responseOptionNumber" [(ngModel)]="botLangResOption.langResOptionNumber">
            <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid"> Number required</div>
            </div>
    
            <div class="col-md-8">
            <label class="required" for="responseOption">Option</label>
            <input  maxlength="19" #responseOption="ngModel" required class="form-control" type="text" placeholder="Enter Option" 
            name="responseOption" id="responseOption" [(ngModel)]="botLangResOption.langResOption">
            <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Option required</div>
            </div>
          
        </div>

        <div class="form-group row">

          <div class="col-md-4">
          <label class="required" for="interactiveListSectionName"> Interactive List Section Name</label>
          <input class="form-control" type="text" placeholder="Enter Section Name" 
          name="interactiveListSectionName" id="interactiveListSectionName" [(ngModel)]="botLangResOption.interactiveListSectionName">
          </div>
  
          <div class="col-md-8">
          <label class="required" for="responseOptionDescription">Description</label>
          <input class="form-control" type="text" placeholder="Enter Description" 
          name="responseOptionDescription" id="responseOptionDescription" [(ngModel)]="botLangResOption.responseOptionDescription">
       
          </div>
        
      </div>

    </div>

 
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='create'" (click)="create(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='edit'" (click)="edit(); clicked=true">Update</button>
</div>