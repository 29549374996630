import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/BIZ_CRM/biz_crm_services/user.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  clicked : boolean = false;
  firstName : any;
  lastName : any;
  userId : any;
  npw : any;
  cnpw : any;
  @Input() fromParent: any;  
  loggedInUser : any;
  theme : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  object : any ={
    userId : '',
    password : ''
  }

  constructor( private toaster : ToastrService,
                private userService : UserService,
                private sharedDataService: SharedDataService,
              public activeModal: NgbActiveModal) { } 

  ngOnInit(): void {

    this.userId =  this.fromParent.userId;
    this.firstName = this.fromParent.firstName;
    this.lastName = this.fromParent.lastName;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }


  onSubmit(){

    if(this.npw !== this.cnpw){
      this.toaster.error("Passwords donot match !");
    }else if(this.npw === this.cnpw){

      this.object.userId = this.userId;
      this.object.password = this.npw;

      this.userService.resetUserPassword(environment.apiUrl + "bcrmUser/resetPassword", this.object).then(
        data=>{
          this.toaster.success("Password changed successfully !");
          this.passEntry.emit('success');
          this.activeModal.close();
        }
      )
    }
   
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

}
