import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginServiceService } from 'src/app/services/auth/login-service.service';
import { ResetPasswordService } from 'src/app/services/reset-password/reset-password.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm = new FormGroup({
    password: new FormControl(null, Validators.required),
    confirmPassword: new FormControl(null, Validators.required)
  });

  constructor(
    private resetPasswordService: ResetPasswordService, public loginService: LoginServiceService,
    private toastr: ToastrService,
    private router: Router, private sharedDataService: SharedDataService) {
  }

  ngOnInit() {
  }

  resetPassword(form: any): void {
    
    this.resetPasswordService.resetUserPassword(environment.apiUrl + 'user/reset/password?' + 'email=' +
      this.sharedDataService.getLoggedInUserDtls()?.email + '&password=' + form.value.password,
      this.sharedDataService.getLoggedInUserDtls()?.email, form.value.password).then(responseResetPassword => {
        // this.router.navigate(['/auth/login']).then();
        this.loginService.getToken(environment.apiUrl + 'oauth/token',
       
        // email to username change
          //  this.sharedDataService.getLoggedInUserDtls()?.email, form.value.password).then(
            this.sharedDataService.getLoggedInUserDtls()?.username, form.value.password).then(

            authResponse => {
              localStorage.setItem('auth-token', authResponse.access_token);
            this.loginService.doLogin(environment.apiUrl + 'user/login',
            // email to username change
              //  this.sharedDataService.getLoggedInUserDtls()?.email, form.value.password, authResponse.access_token).then(response => {
                this.sharedDataService.getLoggedInUserDtls()?.username, form.value.password, authResponse.access_token).then(response => {
                  localStorage.setItem('loggedUser', JSON.stringify(response));
                this.sharedDataService.setLoggedInUserDtls(response);
                if (response.passwordStatus) {
                  this.router.navigate(['/auth/reset-password']).then();
                } else if (response.defaultRoleName === 'SUPER_ADMIN') {
                  this.router.navigate(['/dashboard/super-admin']).then();
                } else if (response.defaultRoleName === 'OFFICE_ADMIN') {
                  this.router.navigate(['/dashboard/office-admin']).then();
                } else if (response.defaultRoleName === 'CRM_ADMIN') {
                  this.router.navigate(['/dashboard/crm-admin']).then();
                } else if (response.defaultRoleName === 'CLASS_ADMIN') {
                  this.router.navigate(['/dashboard/class-admin']).then();
                } else if (response.defaultRoleName === 'COURSE_ADMIN') {
                  this.router.navigate(['/dashboard/curriculum-admin']).then();
                } else if (response.defaultRoleName === 'PARENT_MENTOR') {
                  this.router.navigate(['/dashboard/UserParent']).then();
                } else if (response.defaultRoleName === 'TEACHER') {
                  this.router.navigate(['/dashboard/teacher']).then();
                } else if (response.defaultRoleName === 'STUDENT') { 
                  this.router.navigate(['/dashboard/student']).then();
                } else if (response.defaultRoleName === 'PARENT') { 
                  this.router.navigate(['/dashboard/parent']).then();
                } else if (response.defaultRoleName === 'BCRM_OFFICE_ADMIN') { 
                  this.router.navigate(['/dashboard/bcrm-office-admin']).then();
                } else if (response.defaultRoleName === 'BCRM_EXE') { 
                  this.router.navigate(['/dashboard/bcrm-exe-dashboard']).then();        
                } else if (response.defaultRoleName === 'BCRM_MAN') { 
                  this.router.navigate(['/dashboard/bcrm-man-dashboard']).then();
                } else if (response.defaultRoleName === 'BCRM_PM') { 
                  this.router.navigate(['/dashboard/bcrm-product-management']).then();
                } else if (response.defaultRoleName === 'BCRM_DM') { 
                  this.router.navigate(['/dashboard/bcrm-dispatch-management']).then();
               } else if (response.defaultRoleName === 'BCRM_FM') { 
                  this.router.navigate(['/dashboard/bcrm-finance-management']).then();
                } else if (response.defaultRoleName === 'BCRM_TM') { 
                  this.router.navigate(['/dashboard/bcrm-top-management']).then();
                } else if (response.defaultRoleName === 'BCRM_CUSTOMER') { 
                  this.router.navigate(['/dashboard/bcrm-customer']).then();
                } else if (response.defaultRoleName === 'BCRM_DATA') { 
                  this.router.navigate(['/dashboard/bcrm-data-dashboard']).then();
                } else if (response.defaultRoleName === 'BCRM_FEEDBACK_MANAGER') { 
                  this.router.navigate(['/dashboard/bcrm-feedback-manager']).then();
                } else if (response.defaultRoleName === 'BCRM_SALES_COORDINATOR') { 
                  this.router.navigate(['/dashboard/bcrm-sales-coordinator-dashboard']).then();
                } else if (response.defaultRoleName === 'CB_SUPER_ADMIN') { 
                  this.router.navigate(['/dashboard/cb-super-admin']).then();
                  } else if (response.defaultRoleName === 'CB_OFFICE_ADMIN') { 
                    this.router.navigate(['/dashboard/cb-office-admin']).then();
                  } else if (response.defaultRoleName === 'CB_AGENT') { 
                    this.router.navigate(['/dashboard/cb-agent-convo-log-view']).then();
                  } else if (response.defaultRoleName === 'CB_NOTIFICATIONS_MANAGER') { 
                    this.router.navigate(['/dashboard/office/notifications']).then();
                  } else if (response.defaultRoleName === 'CB_SHOP_ORDERS_MANAGER') { 
                    this.router.navigate(['/dashboard/office/shop-orders']).then();
                  } else if (response.defaultRoleName === 'CB_ORG_ADMIN') { 
                    this.router.navigate(['/dashboard/cb-org-admin/home']).then();
                  }else {
                  this.router.navigate(['/dashboard/user']).then();
                }
              }).catch((err) => {
                this.toastr.error(err.error.message, 'Error');
              });
          }).catch((err) => {
            this.toastr.error(err.error.error_description == null ? 'User Not Found' : err.error.error_description, 'Error');
          });
      });
  }
}
