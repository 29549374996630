import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { FeedbackFormService } from '../../services/feedback-form.service';
import { FormActive } from '../../classes/form-active';
import { FormResponse } from '../../classes/form-response';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl } from '@angular/forms';
import { EditFormActiveComponent } from '../../modals/form-active/edit-form-active/edit-form-active.component';
import { ViewFormResponsesComponent } from '../../modals/form-response/view-form-responses/view-form-responses.component';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { FeedbackQuestion } from '../../classes/feedback-question';
import { FeedbackAnswerValue } from '../../classes/feedback-answer-value';
import { FormRespondent } from '../../classes/form-respondent';

@Component({
  selector: 'app-form-active-dashboard',
  templateUrl: './form-active-dashboard.component.html',
  styleUrls: ['./form-active-dashboard.component.scss']
})
export class FormActiveDashboardComponent implements OnInit {

  currentRole = "BCRM_FEEDBACK_MANAGER";
  customerLoaderMessage!: string;
  formRespondentsExist: boolean = false;
  customerLoader: boolean = false;
  roleLoader = false;
  userName ! : string;
  feedbackOptions : any;
  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  customerId: any;
  userId!: string
  navbarTitle: any;

  sortedFormRespondents : FormRespondent[] = [];
  
  color1: ThemePalette = 'accent';
mode1: ProgressSpinnerMode = 'determinate';
value1 = 50;

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private datePipe : DatePipe,
              private formService : FeedbackFormService,
              private spinner: NgxSpinnerService, 
              private route : ActivatedRoute,
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService ) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    startCustomerLoading(){
      this.customerLoader ? this.spinner.show() : this.spinner.hide();
    }

    formActive : FormActive = new FormActive();
    formActiveId : any;
    formResponses : FormResponse[] = [];
    toppingsControl = new FormControl([]);
    showSpinner : boolean = true;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;
    this.formActiveId = this.route.snapshot.params['formActiveId'];
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getFormResponses();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.feedbackOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };
  }
 
  editFormActive(){

    let formActive = new FormActive();
    formActive.id = this.formActive.id;
    formActive.fromDate = this.formActive.fromDate;
    formActive.toDate = this.formActive.toDate;
    formActive.formLimit = this.formActive.formLimit;
    formActive.url = this.formActive.url;
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFormActiveComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : formActive
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getFormResponses();
    });  
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
  }
    
  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }

  viewFormResponses(formRespondent : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewFormResponsesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      formRespondent : formRespondent
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

  jsonData =  [
    {
      name: "Mrinal",
      age: 33,
      average: 98,
      approved: true,
      description: "I am active blogger and Author."
    },
    {
      name: 'Maan',
      age: 28,
      average: 99,
      approved: true,
      description: "I am active HR."
    },
    {
      name: 'Aradhya',
      age: 4,
      average: 99,
      approved: true,
      description: "I am engle."
    },
  ];

  download(){
    this.downloadFile2(this.arr,'responses');
  }

  downloadFile2(data : any, filename='data') {
    let csvData = this.ConvertToCSV(data, this.questionsArray);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray : any[], headerList : string[]) {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = 'S.No,';

  for (let index in headerList) {
      row += headerList[index] + ',';
  }
  row = row.slice(0, -1);
  str += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
      let line = (i+1)+'';
      for (let index in headerList) {
         let head = headerList[index];

          line += ' ' + array[i][head];
      }
      str += line + '\r\n';
  }
  return str;
}

 //csv
//  csvArray : any[] = [];
 questionsArray : string[] = [];


//  list :any[]= [];
//  names = ["Bob","Tom","Larry"];
//  ages =  ["10", "20", "30"];

  arr : any[] = [];

getFormResponses() {
  
  this.showSpinner = true;
  this.formService.getFormResponsesByFormActiveId(environment.apiUrl + "feedbackForm/getFormResponsesByFormActiveId/" + this.formActiveId).then(
    data=>{        
              
      this.formActive = new FormActive();
      this.formResponses = [];
      this.toppingsControl = new FormControl([]);

      this.formActive = data;

    //   interface Foo {
    //     [key: string]: string
    //  }

    //   let fooArray : Foo[] = [];

      this.sortedFormRespondents = this.formActive.sortedFormRespondents;

      if(this.formActive.sortedFormRespondents !== null &&  this.formActive.sortedFormRespondents !== undefined &&  this.formActive.sortedFormRespondents.length !== 0){
        this.formActive.sortedFormRespondents.forEach((respondent)=>{
          let dict: {
            [id: number]: string
         } = {};
         for (const company of respondent.sortedResponses) {
          let ans = "";
          company.answers.forEach((ans0)=>{
            ans = ans.concat(ans0).concat(",");
          })
            dict[company.question] = ans;
         }
    this.arr.push(dict)
        });
       
      }

    

      
      
      if(this.formActive.sortedFormRespondents !== null &&  this.formActive.sortedFormRespondents !== undefined &&  this.formActive.sortedFormRespondents.length !== 0){


      this.sortedFormRespondents[0].sortedResponses.forEach((res)=>{
        this.questionsArray.push(res.question);
      });
             
      this.sortedFormRespondents.forEach((sortedFormRespondent)=>{


        sortedFormRespondent.sortedResponses.forEach((formResponse)=>{


          if(formResponse.answerType === "timestamp" && formResponse.answerValueTimestamp !== null){
            formResponse.answerValueTimestamp = new Date(formResponse.answerValueTimestamp[0], formResponse.answerValueTimestamp[1] - 1, formResponse.answerValueTimestamp[2], formResponse.answerValueTimestamp[3], formResponse.answerValueTimestamp[4])
            formResponse.answerValueTimestamp = this.datePipe.transform(formResponse.answerValueTimestamp, 'dd-MM-yyyy hh:mm');
          }
        });

      });
     
    }       
             
      this.value1 =  this.formActive.formCount / this.formActive.formLimit;
      this.value1 =  this.value1 * 100;

      this.formActive.formattedFromDate = new Date(this.formActive.fromDate);
      this.formActive.formattedFromDate = this.datePipe.transform(this.formActive.formattedFromDate, "dd-MM-yyyy");
      this.formActive.formattedToDate = new Date(this.formActive.toDate);
      this.formActive.formattedToDate = this.datePipe.transform(this.formActive.formattedToDate, "dd-MM-yyyy");
     
          this.formActive.feedbackForm.feedbackQuestions.forEach((fq)=>{

            if(fq.answerType === "multiSelectDropdown"){

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = false;
              this.formResponses.push(formResponse);

            } else if(fq.answerType === "checkbox"){

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = false;
              this.formResponses.push(formResponse);

            }else if(fq.answerType === "slider"){

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = true;

             let stepsArray :any[] = [];
             formResponse.feedbackAnswerValuesDto.forEach((ansValue)=>{
               let option : any = {value : ansValue.answerValue}
               stepsArray.push(option);
             });

            let options: Options = {
              showTicksValues: true,
              stepsArray: stepsArray
            };

            formResponse.sliderOptions = options;
  
              this.formResponses.push(formResponse);
            }else{

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = true;

              this.formResponses.push(formResponse);
            }

          });

          if(this.formActive.formRespondents !== null && this.formActive.formRespondents !== undefined && this.formActive.formRespondents.length !== 0){
            this.formActive.formRespondents.forEach((formRespondent)=>{
              formRespondent.timestamp = new Date(formRespondent.timestamp[0], formRespondent.timestamp[1] - 1, formRespondent.timestamp[2], formRespondent.timestamp[3], formRespondent.timestamp[4])
              formRespondent.timestamp = this.datePipe.transform(formRespondent.timestamp, 'dd-MM-yyyy hh:mm');
            });
          }


          if(this.formActive.sortedFormRespondents !== null && this.formActive.sortedFormRespondents !== undefined && this.formActive.sortedFormRespondents.length !== 0){
            this.formActive.sortedFormRespondents.forEach((formRespondent)=>{
              formRespondent.timestamp = new Date(formRespondent.timestamp[0], formRespondent.timestamp[1] - 1, formRespondent.timestamp[2], formRespondent.timestamp[3], formRespondent.timestamp[4])
              formRespondent.timestamp = this.datePipe.transform(formRespondent.timestamp, 'dd-MM-yyyy hh:mm');
            });
          }


          this.formActive.feedbackForm.feedbackQuestionsForAnalytics = [];

          this.formActive.feedbackForm.feedbackQuestions.forEach((question)=>{              

            let questionForAnalytics : FeedbackQuestion = new FeedbackQuestion();
            questionForAnalytics = question;
            questionForAnalytics.formResponses = [];
            questionForAnalytics.viewAnswers = false;
            questionForAnalytics.pieChartType = 'pie';
            questionForAnalytics.pieChartLabels = [];
            questionForAnalytics.pieChartData = [];
            if(this.formActive.formRespondents !== null && this.formActive.formRespondents !== undefined && this.formActive.formRespondents.length !== 0){

            this.formActive.formRespondents.forEach((respondent)=>{

              respondent.formResponsesDto.forEach((formResponse)=>{
                if(question.id === formResponse.fkQuestionId){
                  // if(question.answerType === "text"){
                  
                    let response : FormResponse = new FormResponse();
                    response = formResponse;
                    question.formResponses.push(response);
                    

                  // }
                }
              });
            });
          }
            this.formActive.feedbackForm.feedbackQuestionsForAnalytics.push(questionForAnalytics);
          });
          
          this.formActive.feedbackForm.feedbackQuestionsForAnalytics.forEach((question)=>{
            if(question.answerType !== "text" && question.answerType !== "timestamp"){

                  let ansValIds : string[] = [];
                  question.formResponses.forEach((formResponse)=>{
                    ansValIds.push(formResponse.fkAnswerValueId);
                  });                    
                  ansValIds = ansValIds.filter((element, i) => i === ansValIds.indexOf(element));
                 
                  let ansValues : FeedbackAnswerValue[] = [];

                  ansValIds.forEach((ansValId)=>{
                    let ansValue : FeedbackAnswerValue = new FeedbackAnswerValue();
                    ansValue.id = ansValId;
                    question.formResponses.forEach((formResponse)=>{
                      if(ansValue.id === formResponse.fkAnswerValueId){
                        ansValue.answerValue = formResponse.answer;
                      }
                    });  
                    ansValues.push(ansValue); 
                  });
                  
                  ansValues.forEach((ansValue)=>{
                    if(ansValue.answerValue !== null){
                    let count = 0;
                    question.pieChartLabels.push(ansValue.answerValue);

                    question.formResponses.forEach((formResponse)=>{
                     if(ansValue.id === formResponse.fkAnswerValueId){
                      count++;
                     }
                    }); 
                    question.pieChartData.push(count);
                  }
                  });

            }else if(question.answerType === "timestamp"){
              question.formResponses.forEach((formResponse)=>{
                if(formResponse.answerValueTimestamp !== null){
                  formResponse.answerValueTimestamp = new Date(formResponse.answerValueTimestamp[0], formResponse.answerValueTimestamp[1] - 1, formResponse.answerValueTimestamp[2], formResponse.answerValueTimestamp[3], formResponse.answerValueTimestamp[4])
                  formResponse.answerValueTimestamp = this.datePipe.transform(formResponse.answerValueTimestamp, 'dd-MM-yyyy hh:mm');
                 formResponse.answer = formResponse.answerValueTimestamp;
                 formResponse.answerValue = formResponse.answerValueTimestamp;
                }
              });  
            }
          });

      this.formRespondentsExist = true;
      this.showSpinner = false;
    });
}

}
