import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotResponse } from 'src/app/BOT_CODE/bot_classes/bot-response';
import { BotLanguage } from 'src/app/BOT_CODE/bot_classes/bot-language';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { BotLangResponse } from 'src/app/BOT_CODE/bot_classes/bot-lang-response';
import { BotSuperadminServiceService } from 'src/app/BOT_CODE/bot_services/bot-superadmin--service.service';
import { BotLangResOptions } from 'src/app/BOT_CODE/bot_classes/bot-lang-res-options';
import { BotResponseOptions } from 'src/app/BOT_CODE/bot_classes/bot-response-options';
import { CreateOptionLangVariationsComponent } from '../create-option-lang-variations/create-option-lang-variations.component';

@Component({
  selector: 'app-view-option-lang-variations',
  templateUrl: './view-option-lang-variations.component.html',
  styleUrls: ['./view-option-lang-variations.component.scss']
})
export class ViewOptionLangVariationsComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any; 

  constructor(private sharedDataService: SharedDataService,
    private ngbModalService: NgbModal,
    private botService : BotSuperadminServiceService,
    private botOfficeService : BotOfficeServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    botLangResOptions : BotLangResOptions[] = [];
    botResOption : BotResponseOptions = new BotResponseOptions();
    botLanguages : BotLanguage[] = [];

    context : any;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md'
    }; 

    ngOnInit(): void {      
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botResOption = this.fromParent.botResOption;
    this.getAllLangResOptionsByOptionId();

   }

  getAllLangResOptionsByOptionId(){
    
    this.botOfficeService.getAllLangVariations(environment.apiUrl + "botOffice/getAllLangResOptionsByOptionId/" + this.botResOption.botResponseOptionsId).then(
      res=>{
        let resObj : BotLangResOptions = new BotLangResOptions();
        resObj = res;
        this.botLangResOptions = resObj.list;
      });

  }

  createLanguageVariationForOption(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateOptionLangVariationsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botResponseOption : this.botResOption,
      context : "create"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
this.getAllLangResOptionsByOptionId();
    });    
  }

  edit(option:BotLangResOptions){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateOptionLangVariationsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botLangResOption : option,
      context : "edit"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotLangResOptions) => {      
      this.botLangResOptions.forEach((langOpt)=>{
        if(langOpt.botLangResOptionsId === option.botLangResOptionsId){          
          langOpt.langResOption = receivedEntry.langResOption;
          langOpt.langResOptionNumber = receivedEntry.langResOptionNumber;
          langOpt.interactiveListSectionName = receivedEntry.interactiveListSectionName;
          langOpt.responseOptionDescription = receivedEntry.responseOptionDescription;
        }
      })
    }); 
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}