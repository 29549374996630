export class UserList {

    id!: string;

	userId!: string;

	userName!: string;

	roleId!: string;

	officeId!: string;

	//parent

	childImage: any
	childName: any
	childUserId: any
	childUserRoleOfficeId: any
	parentImage: any
	parentName: any
	parentUserId: any
	parentUserRoleOfficeId: any
	safeUrl : any;

children : UserList[] = [];


firstName!: string;
lastName!: string;
password!: string;
phoneNumber!: string;
email!: string;
activeStatus!: boolean;
passwordStatus!: boolean;
dateOfBirth!: any;
gender!: string;
lock!: boolean;
userImage : any;

username : any;

countryDialCode : any;

addressId!: string;
addressLine1!: string;
addressLine2!: string;
city!: string;
country!: string;
state!: string;
zipCode!: string;
fkUserId!: string;

}
