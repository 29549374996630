import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityQuestionsService } from 'src/app/service/activity-questions.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-activity-questions',
  templateUrl: './add-activity-questions.component.html',
  styleUrls: ['./add-activity-questions.component.scss']
})
export class AddActivityQuestionsComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  actQuestion : ActivityQuestions = new ActivityQuestions();
  activityPlannerId! : string;
  clicked = false;

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService, private sharedDataService : SharedDataService,
              private activityQuestionsService : ActivityQuestionsService ) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.activityPlannerId = this.fromParent.activityPlannerId;

    this.activityQuestionsService.getTotalQuestions(environment.apiUrl + "activityQuestion/getTotalQuestionsByActPlnrId/" + this.activityPlannerId).then(
      data=>{
        let count = 0;
        count = data;

        this.actQuestion.activityQuestionOrder = count + 1;
      });
  } 
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  onSubmit(){
    
    // authorized
    this.actQuestion.activityQuestionType = 'R';
    this.actQuestion.fkActivityPlannerId = this.activityPlannerId;
    this.activityQuestionsService.addActivityQuestions(environment.apiUrl + 'activityQuestion/add', this.actQuestion).then(
            data=>{
        this.activeModal.close();
        this.passEntry.emit(this.actQuestion);
        this.toaster.success("Question Created Successfully");
      });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
