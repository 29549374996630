<div class="modal-header">
    <h4 class="modal-title text-center">Out Of {{fileUploadResponse.totalRecords}} records {{fileUploadResponse.recordsStored}} are newly created</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
        <div class="text-center">
            <span>This Emails are already exists</span>
        </div>
      <div class="card-body">
      {{fileUploadResponse.existingRecords}}
      </div>
    </div>
  </div>
  <div class="modal-footer">
  
  </div>
  