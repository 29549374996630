<nav class="navbar navbar-expand-md bg-light navbar-light" *ngIf="dataLoaded===true">

    
    <div *ngIf="loadLogo === true && logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ logo" />
    </div>
    <div *ngIf="loadLogo === true && logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/logo.png" width="30">
    </div>
    <a class="navbar-brand" style="color:rgb(42, 32, 61);cursor: pointer;margin-left: 1%;">{{officeName}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
   
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
          
        <!-- <li *ngIf="viewCart===false && orderDeleted === false && newOrder === true 
        || pageFunction==='edit order' && viewCart===false" class="dropdown pmd-dropdown pmd-user-info ml-auto">
            <a (click)="openCart();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
                <i class="fa fa-shopping-cart" style="color:white"></i> Cart </a>
          </li>

          <li *ngIf="viewCart===true;" class="dropdown pmd-dropdown pmd-user-info ml-auto">
            <a (click)="viewCart=false;" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-arrow-left" aria-hidden="true"></i> Back </a>
          </li> -->
       
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div> 



<div class="container">





  <div *ngIf="pageFunction==='place order'">

<div *ngIf="orderDeleted === false && newOrder === true">

  <mat-accordion *ngIf="viewCart===false && dataLoaded===true" multi>

    <div style="margin: 1%;padding: 1%;">
      <label style="font-weight: bold;">Categories</label>
      <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
        <mat-select [formControl]="toppingsControl" required
        (selectionChange)="multiSelectValueChange($event.value);" multiple>
        
        <mat-option (click)="selectAll();" value="selected all">Select All</mat-option>
          <mat-option *ngFor="let cb of allCategories" [value]="cb.botCategoryId">{{cb.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <br> -->

    <mat-expansion-panel *ngFor="let category of categories" [expanded]="category.panelOpenState">

      <mat-expansion-panel-header>
        <mat-panel-title *ngIf="category.categoryTotalNoOfItemsAddedInCart===0">
          {{category.name}} 
          <!-- <a (click)="$event.stopPropagation();open(desc);" class="button" style="cursor:pointer;">
            <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
        </a> -->
        <br>
          <a style="font-size: x-small;color: black;">{{category.headerInfo}}</a>
        </mat-panel-title> 
        
        <mat-panel-title *ngIf="category.categoryTotalNoOfItemsAddedInCart > 0">
          {{category.name}} ({{category.categoryTotalNoOfItemsAddedInCart}})
          <!-- <a (click)="$event.stopPropagation();open(desc);" class="button" style="cursor:pointer;">
            <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
        </a> -->
         <br>
          <a style="font-size: x-small;color: black;">{{category.headerInfo}}</a>
        </mat-panel-title> 
       
        <mat-panel-description *ngIf="linkType==='s' && category.categoryTotalPriceOfItemsInCart > 0">
          {{category.categoryTotalPriceOfItemsInCart}}
        </mat-panel-description>
      </mat-expansion-panel-header>
   
      <div *ngIf="category.description!==null && category.description!==undefined">
        <a style="font-size: small;color: black;">{{category.description}}</a>
        <br> <br>
      </div>
      
      <div *ngIf="category.itemList !== null && category.itemList.length > 0">
        <div *ngFor="let item of category.itemList">

          <div class="content">
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
          
              <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
          
                <a> 
                <img style="border-radius: 50%;margin-bottom: 2%;" [src]="item.safeImageUrl" 
                width="44" height="44" alt="" loading="lazy">
                </a>
                   
              </div>
              <div fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%" style="text-align: left;">
                
                <a style="font-weight: 700;">{{item.botItemName}} 
                  <!-- <a (click)="$event.stopPropagation();open(itemDesc);" class="button" style="cursor:pointer;">
                    <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
                </a> -->
              </a>
               <br>
                <a *ngIf="linkType==='s'" style="font-weight: 500;color: black;">{{item.pricePerItem}}</a> <br>
          
              </div>
              <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
          
                
<button *ngIf="item.quantityAddedToCart === 0" (click)="incrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;">ADD</button>

<div class="row" *ngIf="item.quantityAddedToCart > 0">
<button (click)="decrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;"><i class="fa fa-minus"></i></button>
<button class="btn btn-light" style="width: auto;">{{item.quantityAddedToCart}}</button>
<button (click)="incrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;"><i class="fa fa-plus"></i></button>
</div>
              
                   
              </div>        
          
              <div *ngIf="item.botItemDescription!==null&&item.botItemDescription!==undefined">
                <a style="font-size: small;color: black;">{{item.botItemDescription}}</a>
              </div>

            </div>
          </div>
          
          
          <br>
          <mat-divider></mat-divider>
          <br> 

          <ng-template #itemDesc let-modal>
            <div class="modal-header">
              <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">
                <a> 
                  <img style="border-radius: 50%;" [src]="item.safeImageUrl" width="40" height="40" alt="" loading="lazy">
                 </a> {{item.botItemName}}
              </h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="color: black;">
              {{item.botItemDescription}} 
            </div> 
            </ng-template>
        </div>
      </div>

      <ng-template #desc let-modal>
        <div class="modal-header">
          <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">{{category.name}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{category.description}} 
        </div> 
        </ng-template>

    </mat-expansion-panel>
    
    <div id="mybutton">
      <button (click)="openCart();" class="feedback"
      style="width:max-content; float:right;">View Cart ({{totalNumberOfItemsInCart}}) <i class="fa fa-shopping-cart"></i></button>
  
    </div>
    
    <br>
    <br>
    <br>
    <br>
  </mat-accordion>

  <div class="container" style="margin: 1%;padding: 1%;" *ngIf="viewCart===true && dataLoaded===true">
    <div *ngIf="cartItems !== null && cartItems.length > 0">
        <mat-card class="content" *ngFor="let item of cartItems">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
        
            <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%">
              
              <a style="font-weight: 700;"> 
                <img style="border-radius: 50%;margin-bottom: 2%;" [src]="item.safeImageUrl" width="44" height="44" alt="" loading="lazy">
               </a>
            
                 
            </div>
            <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
        
              
              <a style="font-weight: 700;">{{item.botItemName}} 
                <a (click)="$event.stopPropagation();open(itemDesc);" class="button" style="cursor:pointer;">
                  <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
              </a></a> <br>
              <a *ngIf="linkType==='s'" style="font-weight: 500;color: black;">{{item.pricePerItem}}</a> <br>
        
             
            </div>
            <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
        
              
<button *ngIf="item.quantityAddedToCart === 0" (click)="incrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;">ADD</button>
<div class="row" *ngIf="item.quantityAddedToCart > 0">
<button (click)="decrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;"><i class="fa fa-minus"></i></button>
<button class="btn btn-light" style="width: auto;">{{item.quantityAddedToCart}}</button>
<button (click)="incrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;"><i class="fa fa-plus"></i></button>
</div>
            
                 
            </div>  
            <div *ngIf="linkType==='s'" fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
        
              <br>
              <a style="font-weight: 700;font-size: medium;color: black;">{{item.totalPriceforQty}}</a> 
        
             
            </div>      
        
          </div>

          <ng-template #itemDesc let-modal>
            <div class="modal-header">
              <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">
                <a> 
                  <img style="border-radius: 50%;" [src]="item.safeImageUrl" width="40" height="40" alt="" loading="lazy">
                 </a> {{item.botItemName}}
                </h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="color: black;">
              
              {{item.botItemDescription}} 
            </div> 
            </ng-template>

        </mat-card>
        <br> 
    </div>

    <div *ngIf="linkType==='s' && cartItems !== null && cartItems.length > 0">
     

      <mat-card class="content">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
      
          <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
            
            <a style="font-weight: 700;"> 
              <img style="margin-bottom: 2%;" src="assets/images/icons/sigma.png" width="44" height="44" alt="" loading="lazy">
             </a>
          
          </div>
          <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
           
          </div>
          <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
            
            <br>
            <a style="font-weight: 700;font-size: medium;">Total</a> 
               
          </div>  
          <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
      
            <br>
            <a style="font-weight: 700;font-size: medium;">{{totalCartSum}}</a> 
           
          </div>      
      
        </div>
      </mat-card>

 
      
      <br> 
    

    
  </div>

 
  <div *ngIf="botUserLocations!==null && botUserLocations!==undefined && botUserLocations.length>0">
    <h4 style="text-align: left; font-weight: bold;color: rgb(76, 71, 71);">Choose Delivery Address :</h4>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div *ngFor="let addr of botUserLocations" fxFlex="33.333333%" fxFlex.xs="100%" fxFlex.sm="100%" >
        <mat-card class="example-card" >
          <div>
            <mat-radio-group aria-label="addr.botUserLocationId">
              <mat-radio-button style="font-weight: bold;" 
              (change)="radioChange(addr.botUserLocationId)" [checked]="addr.checked" [value]="addr.botUserLocationId">Deliver to this address</mat-radio-button>
             </mat-radio-group>
          </div>
          <div style="text-align: center;">
            <h5 style="font-weight: bold;">
              {{addr.name}}
              <br>
             {{addr.addressByBotUser}}
            </h5>
          </div>
      </mat-card>
      </div>
    </div>
  </div>
  
  <button (click)="clicked=true;placeOrder();" [disabled]="clicked" class="btn btn-primary" 
  style="width:max-content; float:right;">{{ linkType==='s' ? 'Place Order': 'Place Quotation Request'}}</button>


  <br>
  <br>
  <br>

</div>

</div>

<div style="text-align: center;" *ngIf="orderDeleted === true && dataLoaded === true">
  <a>
    <img src="assets/images/icons/unverified.png" style="border-radius: 50%;"
    height="200" width="200" alt="...">
  </a>

    <h3 style="color: black;">Link Expired</h3>
</div>

<div style="text-align: center;" *ngIf="orderLoaded === true && orderStatus==='order placed'">
  <a>
    <img src="assets/images/icons/tick.png" style="border-radius: 50%;padding: 5%;"
    height="250" width="250" alt="...">
  </a>

    <h4 style="color: black;">{{ linkType==='s' ? 'Order': 'Quotation Request'}} placed successfully, you'll receive updates on whatsapp. <br>
       Thank you !
      <h4
      (click)="editOrder();" style="color: blue;cursor: pointer;">Click here to edit {{ linkType==='s' ? 'Order': 'Quotation Request'}}</h4>
      </h4>
</div>

<div style="text-align: center;" *ngIf="orderLoaded === true && orderStatus==='order processed'">
  <a>
    <img src="assets/images/icons/tick.png" style="border-radius: 50%;padding: 5%;"
    height="250" width="250" alt="...">
  </a>

    <h4 style="color: black;"> {{ linkType==='s' ? 'Order': 'Quotation Request'}} processed successfully, you'll receive updates on whatsapp. <br>
       Thank you !
      </h4>
</div>

<div style="text-align: center;" *ngIf="orderLoaded === true && orderStatus==='order cancelled'">
  <a>
    <img src="assets/images/icons/ordersCancelled.png" style="border-radius: 50%;padding: 5%;"
    height="250" width="250" alt="...">
  </a>

    <h4 style="color: black;">{{ linkType==='s' ? 'Order cancelled !': 'Quotation Request cancelled !'}}</h4>
</div>
  
 






</div>

<div *ngIf="pageFunction==='edit order'">

    

  <mat-accordion *ngIf="viewCart===false && dataLoaded===true" multi>

    <div style="margin: 1%;padding: 1%;">
      <label>Categories</label>
      <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
        
        <mat-select [formControl]="toppingsControl" required
        (selectionChange)="multiSelectValueChange($event.value);" multiple>
        
        <mat-option (click)="selectAll();" value="selected all">Select All</mat-option>
          <mat-option *ngFor="let cb of allCategories" [value]="cb.botCategoryId">{{cb.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <br>

    <mat-expansion-panel *ngFor="let category of categories" [expanded]="category.panelOpenState">

      <mat-expansion-panel-header>
        <mat-panel-title *ngIf="category.categoryTotalNoOfItemsAddedInCart===0">
          {{category.name}} 
          <a style="cursor:pointer;">
            <img src="/assets/images/icons/info.png" width="27" height="27" alt="" loading="lazy">
        </a>
        <br>
          <a style="font-size: x-small;color: black;">{{category.headerInfo}}</a>
        </mat-panel-title> 

        <mat-panel-title *ngIf="category.categoryTotalNoOfItemsAddedInCart > 0">
          {{category.name}} ({{category.categoryTotalNoOfItemsAddedInCart}}) 
          <a (click)="$event.stopPropagation();open(desc);" class="button" style="cursor:pointer;">
            <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
        </a><br>
          <a style="font-size: x-small;color: black;">{{category.headerInfo}}</a>
        </mat-panel-title> 
       
        <mat-panel-description *ngIf="linkType==='s' && category.categoryTotalPriceOfItemsInCart > 0">
          {{category.categoryTotalPriceOfItemsInCart}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      
      <div *ngIf="category.itemList !== null && category.itemList.length > 0">
        <div *ngFor="let item of category.itemList">

          <div class="content">
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
          
              <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
          
                
                <a> 
                  <img style="border-radius: 50%;margin-bottom: 2%;" [src]="item.safeImageUrl" width="44" height="44" alt="" loading="lazy">
                 </a>
              
                   
              </div>
              <div fxFlex="50%" fxFlex.xs="50%" fxFlex.sm="50%" style="text-align: left;">
          
                
                <a style="font-weight: 700;">{{item.botItemName}} 
                  <a (click)="$event.stopPropagation();open(itemDesc);" class="button" style="cursor:pointer;">
                    <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
                </a></a> <br>
                <a *ngIf="linkType==='s'" style="font-weight: 500;color: black;">{{item.pricePerItem}}</a> <br>
          
               
              </div>
              <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
          
                
<button *ngIf="item.quantityAddedToCart === 0" 
(click)="incrementItemQuantityAddedToCart(item);" class="btn btn-info" style="width: auto;">ADD</button>

<div class="row" *ngIf="item.quantityAddedToCart > 0">
<button (click)="decrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;"><i class="fa fa-minus"></i></button>
<button class="btn btn-light" style="width: auto;">{{item.quantityAddedToCart}}</button>
<button (click)="incrementItemQuantityAddedToCart(item);" 
class="btn btn-info" style="width: auto;"><i class="fa fa-plus"></i></button>
</div>
              
                   
              </div>        
          
            </div>
          </div>
          
          
          <br>
          <mat-divider></mat-divider>
          <br> 

          <ng-template #itemDesc let-modal>
            <div class="modal-header">
              <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">
                <a> 
                <img style="border-radius: 50%;" [src]="item.safeImageUrl" width="40" height="40" alt="" loading="lazy">
               </a> {{item.botItemName}}
              </h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="color: black;">
              
              {{item.botItemDescription}} 
            </div> 
            </ng-template>

        </div>
      </div>

      <ng-template #desc let-modal>
        <div class="modal-header">
          <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">{{category.name}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{category.description}} 
        </div> 
        </ng-template>
        
    </mat-expansion-panel>
    
    <button (click)="openCart();" class="btn btn-primary" 
    style="width:max-content; float:right;">View Cart ({{totalNumberOfItemsInCart}}) <i class="fa fa-shopping-cart"></i></button>

    <br>
    <br>
    <br>
    <br>
  </mat-accordion>

  <div class="container" style="margin: 1%;padding: 1%;" *ngIf="viewCart===true && dataLoaded===true">
    <div *ngIf="cartItems !== null && cartItems.length > 0">
     

        <mat-card class="content" *ngFor="let item of cartItems">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
        
            <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
        
              
              <a style="font-weight: 700;"> 
                <img style="border-radius: 50%;margin-bottom: 2%;" [src]="item.safeImageUrl" width="44" height="44" alt="" loading="lazy">
               </a>
            
                 
            </div>
            <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
        
              
              <a style="font-weight: 700;">{{item.botItemName}} 
                <a (click)="$event.stopPropagation();open(itemDesc);" class="button" style="cursor:pointer;">
                  <img src="/assets/images/icons/info.png" width="24" height="24" alt="" loading="lazy">
              </a></a> <br>
              <a *ngIf="linkType==='s'" style="font-weight: 500;color: black;">{{item.pricePerItem}}</a> <br>
        
             
            </div>
            <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
        
              
              <button *ngIf="item.quantityAddedToCart === 0" (click)="incrementItemQuantityAddedToCart(item);" class="btn btn-info" style="width: auto;">ADD</button>
              <div class="row" *ngIf="item.quantityAddedToCart > 0">
    <button (click)="decrementItemQuantityAddedToCart(item);" class="btn btn-info" style="width: auto;"><i class="fa fa-minus"></i></button>
    <button class="btn btn-light" style="width: auto;">{{item.quantityAddedToCart}}</button>
    <button (click)="incrementItemQuantityAddedToCart(item);" class="btn btn-info" style="width: auto;"><i class="fa fa-plus"></i></button>
              </div>
            
                 
            </div>  
            <div *ngIf="linkType==='s'" fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
        
              <br>
              <a style="font-weight: 700;font-size: medium;">{{item.totalPriceforQty}}</a> 
        
             
            </div>      
        
          </div>

          <ng-template #itemDesc let-modal>
            <div class="modal-header">
              <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">
                <a> 
                  <img style="border-radius: 50%;" [src]="item.safeImageUrl" width="40" height="40" alt="" loading="lazy">
                 </a> {{item.botItemName}}
                </h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="color: black;">
              
              {{item.botItemDescription}} 
            </div> 
            </ng-template>

        </mat-card>

   
        
        <br> 
      

      
    </div>

    <div *ngIf="linkType==='s' && cartItems !== null && cartItems.length > 0">
     

      <mat-card class="content">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
      
          <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
            
            <a style="font-weight: 700;"> 
              <img style="margin-bottom: 2%;" src="assets/images/icons/sigma.png" width="44" height="44" alt="" loading="lazy">
             </a>
          
          </div>
          <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
           
          </div>
          <div fxFlex="30%" fxFlex.xs="30%" fxFlex.sm="30%" >
            
            <br>
            <a style="font-weight: 700;font-size: medium;">Total</a> 
               
          </div>  
          <div fxFlex="20%" fxFlex.xs="20%" fxFlex.sm="20%" >
      
            <br>
            <a style="font-weight: 700;font-size: medium;">{{totalCartSum}}</a> 
           
          </div>      
      
        </div>
      </mat-card>

 
      
      <br> 
    

    
  </div>

  <div *ngIf="botUserLocations!==null && botUserLocations!==undefined && botUserLocations.length>0">
    <h4 style="text-align: left; font-weight: bold;color: rgb(76, 71, 71);">Choose Delivery Address :</h4>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div *ngFor="let addr of botUserLocations" fxFlex="33.333333%" fxFlex.xs="100%" fxFlex.sm="100%" >
        <mat-card class="example-card" >
          <div>
            <mat-radio-group aria-label="addr.botUserLocationId">
              <mat-radio-button style="font-weight: bold;" 
              (change)="radioChange(addr.botUserLocationId)" [checked]="addr.checked" [value]="addr.botUserLocationId">Deliver to this address</mat-radio-button>
             </mat-radio-group>
          </div>
          <div style="text-align: center;">
            <h5 style="font-weight: bold;">
              {{addr.name}}
              <br>
             {{addr.addressByBotUser}}
            </h5>
          </div>
      </mat-card>
      </div>
    </div>
  </div>

  <button (click)="clicked=true;updateOrder();" [disabled]="clicked" class="btn btn-primary" 
  style="width:max-content; float:right;">{{ linkType==='s' ? 'Update Order': 'Update Quotation Request'}}</button>

  <br>
  <br>
  <br>

  </div>

  

</div>


<div style="text-align: center;" *ngIf="orderLoaded === true && orderStatus==='order edited'">
<a>
  <img src="assets/images/icons/tick.png" style="border-radius: 50%;padding: 5%;"
  height="250" width="250" alt="...">
</a>

  <h4 style="color: black;">{{ linkType==='s' ? 'Order': 'Quotation Request'}} edited successfully, you'll receive updates on whatsapp. <br>
     Thank you !
    <h4
    (click)="editOrder();" style="color: blue;cursor: pointer;">Click here to edit {{ linkType==='s' ? 'Order': 'Quotation Request'}}</h4>
    </h4>
</div>


</div>


















