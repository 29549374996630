import { DatePipe, formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Announcement } from 'src/app/BIZ_CRM/biz_crm_classes/announcement';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { TargetGroup } from 'src/app/BIZ_CRM/biz_crm_classes/target-group';
import { ViewOrdersTableComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-orders-table/view-orders-table.component';
import { ViewTargetBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-target-based-invoices/view-target-based-invoices.component';
// import { AssignTargetToExecsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/target/assign-target-to-execs/assign-target-to-execs.component';
// import { ViewExecTargetsByPeriodComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/target/view-exec-targets-by-period/view-exec-targets-by-period.component';
// import { ViewTargetsTableComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/target/view-targets-table/view-targets-table.component';
import { AnnouncementService } from 'src/app/BIZ_CRM/biz_crm_services/announcement.service';
import { TargetService } from 'src/app/BIZ_CRM/biz_crm_services/target.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
// fix_target_name_error

@Component({
  selector: 'app-bcrm-man-home',
  templateUrl: './bcrm-man-home.component.html',
  styleUrls: ['./bcrm-man-home.component.scss']
})

export class BcrmManHomeComponent implements OnInit {

  gridColumns = 3;
  currentRole = "BCRM_MAN";
  officeId!: string;
  showAssignTarget = false;
  loggedInUser: any;
  userName!: string;
  userId!: string;
  navbarTitle : any;
  target : Target = new Target();
  target1 : Target = new Target();
  targetGroup : TargetGroup = new TargetGroup();
  showSpinner = false;
  orderStatus : any = {

    totalOrders:'',

    deliveredOrderCount:'',
    invoicedOrderCount:'',
    pendingApprovalOrderCount:'',
    shippedOrderCount: '',
    approvedOrderCount:'',
    cancelledOrderCount:'',

    totalCustomers: '',
    totalInvoices: '',
    totalLeads:'',
    totalPayments:'',
    totalProducts:'',
    totalShipments:'',

    allOrders:'',

    invoicedOrders:'',
    shippedOrders:'',
    deliveredOrders:'',
    pendingApprovalOrders:'',
    approvedOrders:'',
    cancelledOrders:''
  }
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  announcements : Announcement[] = [];

  achievedPercentage : any;
  closeResult!: string;

  constructor(
              private router : Router,
              private datePipe : DatePipe,
              private announcementService : AnnouncementService,
              private invoiceService : InvoiceService,
              private targetService : TargetService,
              private ngbModalService: NgbModal,
              private sharedDataService : SharedDataService ) {
  }
  
  ngOnInit(): void {
    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;

    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.getTarget();
    this.getOrderStatus();
    this.getCurrentAnnouncements();
  }

  deleteTargets(id : any){
    this.targetService.deleteAssignedTargets(environment.apiUrl+"target/deleteAssignedTargets/" + id).then(
      data=>{
        this.getTarget();
      });
  } 

  goToTicketSupportPage(){
    this.router.navigate(['dashboard/ticket-support']);
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  getCurrentAnnouncements(){
    this.showSpinner = true;
    this.announcementService.getCurrentAnnouncementsForMan(environment.apiUrl + "announcements/getCurrentAnnouncementsForMan").then(
      data=>{
        this.announcements = data;
        this.showSpinner = false;
      });
   }

  viewOrdersTable(invoices : any, header : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewOrdersTableComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      invoices : invoices,
      header : header
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  //authorized
  getOrderStatus(){
    this.showSpinner = true;
    this.invoiceService.getOrderStatusForExecDashboard(environment.apiUrl + "invoice/getOrderStatusForManagerDashboard").then(
      data=>{

       this.orderStatus = data;
       this.orderStatus.totalOrders = this.orderStatus.cancelledOrderCount + this.orderStatus.approvedOrderCount + this.orderStatus.shippedOrderCount + this.orderStatus.pendingApprovalOrderCount + this.orderStatus.invoicedOrderCount + this.orderStatus.deliveredOrderCount;

       this.orderStatus.allOrders = [];

         this.orderStatus.pendingApprovalOrders.forEach((value : any)=>{
           this.orderStatus.allOrders.push(value);
           value.invoiceDate = new Date(value.invoiceDate);
           value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
           value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
          });
         this.orderStatus.invoicedOrders.forEach((value : any)=>{
          this.orderStatus.allOrders.push(value);
          value.invoiceDate = new Date(value.invoiceDate);
          value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
          value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.orderStatus.shippedOrders.forEach((value : any)=>{
          this.orderStatus.allOrders.push(value);
          value.invoiceDate = new Date(value.invoiceDate);
          value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
          value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.orderStatus.deliveredOrders.forEach((value : any)=>{
          this.orderStatus.allOrders.push(value);
          value.invoiceDate = new Date(value.invoiceDate);
          value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
          value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.orderStatus.approvedOrders.forEach((value : any)=>{
          this.orderStatus.allOrders.push(value);
          value.invoiceDate = new Date(value.invoiceDate);
          value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
          value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.orderStatus.cancelledOrders.forEach((value : any)=>{
          this.orderStatus.allOrders.push(value);
          value.invoiceDate = new Date(value.invoiceDate);
          value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
          value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.showSpinner = false;
        });
  }

  dueOrdersDashboard(){
    this.router.navigate(['/dashboard/bcrm-man-due-orders']);
  }

  //authorized
  getTarget(){

    this.targetService.getBcrmManagerTarget(environment.apiUrl + "target/getBcrmManagerTarget").then(
      data=>{ 
     
        this.target = data;
 
        this.target.outstandingOverdue =  Math.round((this.target.outstandingOverdue + Number.EPSILON) * 100) / 100;
        this.target.eligibleIncentive =  Math.round((this.target.eligibleIncentive + Number.EPSILON) * 100) / 100;

        if(this.target !== undefined && this.target !== null){
          if(this.target.executiveTargets === undefined || this.target.executiveTargets.length === 0  ){
            this.showAssignTarget = true;
          } else{
            this.showAssignTarget = false;
          } 
        }

        this.target.executiveTargets.forEach((tar)=>{
          if(tar.targetAchieved !== 0 && tar.targetAchieved !== null){
            tar.targetAchieved =  Math.round((tar.targetAchieved + Number.EPSILON) * 100) / 100;
          }
        });

        if(this.target.eligibleIncentive !== null){
          this.target.eligibleIncentive = formatNumber(Number(this.target.eligibleIncentive), 'en-US', '1.0-0');
               }
       
        this.achievedPercentage = this.target.targetAchieved / this.target.targetValue;
        this.achievedPercentage = this.achievedPercentage * 100;
        this.achievedPercentage =  Math.round((this.achievedPercentage + Number.EPSILON) * 100) / 100;

        this.target1 = data;

        this.target.formattedTargetValue = formatNumber(Number(this.target.targetValue), 'en-US', '1.0-0');
        this.target.formattedTargetAchieved = formatNumber(Number(this.target.targetAchieved), 'en-US', '1.0-0');
        this.target.outstandingOverdue = formatNumber(Number(this.target.outstandingOverdue), 'en-US', '1.0-0');
      
        if(this.target.targetName !== null && this.target.targetName !== undefined){

        let sd = this.target.targetStartDate;
        let startDate = new Date(sd);
        this.target.targetStartDate = startDate;
        this.target.targetStartDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');   

        let ed = this.target.targetEndDate;
        let endDate = new Date(ed);
        this.target.targetEndDate = endDate;
        this.target.targetEndDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');  

        }
      });
  }

  ViewExecTargetsByPeriod(){
    // this.config.class = 'modal-md';
    // const modalRef = this.ngbModalService.open( ViewExecTargetsByPeriodComponent,
    //   {
    //     size : 'lg',
    //     scrollable: true,
    //     windowClass: 'app-modal-window',
    //     keyboard: false
    //   });
    // let data = { 
    // }
    // modalRef.componentInstance.fromParent = data;
    //   modalRef.result.then((result) => {
    //   }, (reason) => {
    //   });
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    // });    
  }

  //authorized
  checkForChildTargets(targetId : any, targetValue : any, targetAchieved : any, executiveName : any, targetName : any){
    this.targetService.checkForChildTargets(environment.apiUrl + "target/checkForChildTargets/" + targetId).then(
      data=>{
        this.targetGroup = data;        
        if(this.targetGroup.childTargetsExist === false){
          this.viewTargetPeriodBasedOrders(targetId, targetValue, targetAchieved);
        }else if(this.targetGroup.childTargetsExist === true){
          this.viewTargetsTable(this.targetGroup.targets, executiveName, targetValue, targetAchieved, targetName);
        }
      });
  }

  //authorized
  viewTargetsTable(targets : any, executiveName : any, targetValue : any, targetAchieved : any, targetName : any){
    // this.config.class = 'modal-md';
    // const modalRef = this.ngbModalService.open( ViewTargetsTableComponent,
    //   {
    //     size : 'lg',
    //     scrollable: true,
    //     windowClass: 'app-modal-window',
    //     keyboard: false
    //   });
    // let data = { 
    // targets : targets,
    // manager : executiveName,
    // targetValue : targetValue,
    // targetAchieved : targetAchieved,
    // targetName : targetName
    // }
    // modalRef.componentInstance.fromParent = data;
    //   modalRef.result.then((result) => {
    //   }, (reason) => {
    //   });
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    // });    
  } 

  // authorized
  viewTargetPeriodBasedOrders(targetId : any,targetValue : any, targetAchieved : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewTargetBasedInvoicesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      targetId : targetId,
      targetStartDate : this.target.targetStartDate,
      targetEndDate : this.target.targetEndDate,
      targetName : this.target.targetName,
      targetValue : targetValue,
      targetAchieved : targetAchieved
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  } 
//authorized
  assignTargetToExecs(){
  //   this.config.class = 'modal-md';
  //   const modalRef = this.ngbModalService.open( AssignTargetToExecsComponent,
  //     {
  //       size : 'lg',
  //       scrollable: true,
  //       windowClass: 'app-modal-window',
  //       keyboard: false
  //     });
  //   let data = { 
  //     targetId : this.target1.id,
  //     targetName : this.target1.targetName,
	// targetStartDate :  this.target1.targetStartDate,
	// targetEndDate : this.target1.targetEndDate,
	// targetValue :  this.target1.targetValue,
	// targetAchieved :  this.target1.targetAchieved,
	// targetStatus : this.target1.targetStatus,
	// assignedUroId :  this.target1.assignedUroId,
  //   }
  //   modalRef.componentInstance.fromParent = data;
  //     modalRef.result.then((result) => {
  //     }, (reason) => {
  //     });
  //   modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  //     this.getTarget();
  //   });    
  }

  customerDashboard(){
    this.router.navigate(['/dashboard/bcrm-man-customers']); 
  }

  LeadDashboard(){
    this.router.navigate(['/dashboard/bcrm-man-leads']);
  }

  exploreTargets(){
    this.router.navigate(['/dashboard/bcrm-man-targets']);
  }

  exploreOrders(){
    this.router.navigate(['/dashboard/bcrm-man-orders']);
  }

 

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }
 
}