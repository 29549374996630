export class ActivityScore {
 
    activityScoreId!: string;				//backend
	fkActivityActualId!: string;			//backend
	fkStudentId!: string;					//added
	fkActivityQuestionId!: string;			//added
	activityScoreName!: string;				//added
	activityScoreValue!: string;			//added
	activityScoreStatus!: boolean;			//backend


}
