import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Announcement } from 'src/app/BIZ_CRM/biz_crm_classes/announcement';
import { AnnouncementService } from 'src/app/BIZ_CRM/biz_crm_services/announcement.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.scss']
})
export class AddAnnouncementComponent implements OnInit {

  constructor(private activeModal : NgbActiveModal,
    private announcementService : AnnouncementService,
    private sharedDataService : SharedDataService,
    private toastrService : ToastrService) { }

    @Output() passEntry : EventEmitter<any> = new EventEmitter();
    @Input() fromParent : any;

  announcement : Announcement = new Announcement();
  clicked = false;
  loggedInUser : any; 
  theme : any;

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
  }

  onSubmit(){
    if(this.announcement.startDate < this.announcement.endDate){
    //authorized
    this.clicked = true;
    this.announcementService.addAnnouncement(environment.apiUrl + "announcements/add", this.announcement).then(
      data=>{
        this.passEntry.emit("success");
        this.toastrService.success("Announcement created successfully");
        this.activeModal.close();
      });
    }else{
      this.toastrService.success("Proper Date-range required");
    }

  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
