import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotInvoice } from "src/app/BOT_CODE/bot_classes/bot-invoice";
import { DatePipe } from "@angular/common";
import { TableUtil } from "src/app/table-util";
import { InvoiceDetailsViewComponent } from "src/app/BOT_CODE/bot_modals/bot_invoice_details/invoice-details-view/invoice-details-view.component";

@Component({
  selector: "app-cb-shop-orders",
  templateUrl: "./cb-shop-orders.component.html",
  styleUrls: ["./cb-shop-orders.component.scss"],
})
export class CbShopOrdersComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_SHOP_ORDERS_MANAGER";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  invoicesExist = false;
  itemsExist = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
    fkBotLangId: "",
    multiLanguageSettings: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private datePipe: DatePipe,
    private botOfficeService: BotOfficeServiceService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  invoices: BotInvoice[] = [];

  orderStatusTable = "Orders Placed";
  currentDateRange = "";

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getBotInvoicesByOfficeId("order placed", null, null);

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
    };
  }

  rangeStartDate = null;
  rangeEndDate = null;

  getBotInvoicesByOfficeId(orderStatus: any, fromDate: any, toDate: any) {
    this.showSpinner = true;
    this.orderStatusTable = "";
    this.currentDateRange = "";
    let inv: BotInvoice = new BotInvoice();
    inv.orderStatus = orderStatus;
    inv.fromDate = fromDate;
    inv.toDate = toDate;
    this.invoices = [];

    this.botOfficeService
      .getBotInvoicesByOfficeId(
        environment.apiUrl + "botOfficeShop/getBotInvoicesByOfficeId",
        inv
      )
      .then((res) => {
        let resObj: BotInvoice = new BotInvoice();
        resObj = res;
        this.invoices = resObj.invoices;

        if (this.invoices !== null && this.invoices.length > 0) {
          this.invoices = this.formatInvoiceDates(this.invoices);
          this.invoicesExist = true;
        } else {
          this.toaster.error("No invoices to display !");
        }

        let x: any;
        if (fromDate === null && toDate === null) {
          x = new Date();
          x = this.datePipe.transform(x, "dd-MM-yyyy");
          x = "on " + x;
        } else {
          fromDate = this.datePipe.transform(fromDate, "dd-MM-yyyy");
          toDate = this.datePipe.transform(toDate, "dd-MM-yyyy");
          x = "( " + fromDate + " to " + toDate + " )";
        }

        if (orderStatus === "order placed") {
          this.orderStatusTable = "Orders Placed";
          if (fromDate !== null && toDate !== null) {
            this.currentDateRange = x;
          }
        } else if (orderStatus === "order cancelled") {
          this.orderStatusTable = "Orders Cancelled";
          this.currentDateRange = x;
        } else if (orderStatus === "order invoiced") {
          this.orderStatusTable = "Orders Invoiced";
          this.currentDateRange = x;
        } else if (orderStatus === "order processed") {
          this.orderStatusTable = "Orders Processed";
          this.currentDateRange = x;
        }

        this.showSpinner = false;
      });
  }

  formatInvoiceDates(invoices: BotInvoice[]) {
    if (this.invoices !== null && this.invoices.length > 0) {
      this.invoices.forEach((invoice) => {
        if (invoice.orderPlacedDate !== null) {
          let sd = invoice.orderPlacedDate;
          // invoice.orderPlacedDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
          invoice.orderPlacedDate = Date.UTC(
            sd[0],
            sd[1] - 1,
            sd[2],
            sd[3],
            sd[4]
          );
          invoice.orderPlacedDate = this.datePipe.transform(
            invoice.orderPlacedDate,
            "dd-MM-yyyy hh:mm"
          );
        }
        if (invoice.invoiceDate !== null) {
          let sd = invoice.invoiceDate;
          // invoice.invoiceDate =new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
          invoice.invoiceDate = Date.UTC(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
          invoice.invoiceDate = this.datePipe.transform(
            invoice.invoiceDate,
            "dd-MM-yyyy hh:mm"
          );
        }
      });
    }
    return invoices;
  }

  dataSource = this.invoices;
  exportArray() {
    this.dataSource = this.invoices;
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map((x) => ({
      Customer_Name: x.fkBotUserName,
      Customer_Phone: x.fkBotUserPhone,
      Order_Placed_On: x.orderPlacedDate,
      Status: x.orderStatus,
      Grand_Total: x.grandTotal,
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

  searchInvoices() {
    if (this.rangeStartDate !== null && this.rangeEndDate !== null) {
      let os = "";
      if (this.orderStatusTable === "Orders Placed") {
        os = "order placed";
      } else if (this.orderStatusTable === "Orders Cancelled") {
        os = "order cancelled";
      } else if (this.orderStatusTable === "Orders Processed") {
        os = "order processed";
      } else if (this.orderStatusTable === "Orders Invoiced") {
        os = "order invoiced";
      }
      this.getBotInvoicesByOfficeId(os, this.rangeStartDate, this.rangeEndDate);
    } else {
      this.toaster.error("Select a proper date range !");
    }
  }

  orderStatusUpdate(id: any, status: any) {
    this.showSpinner = true;
    this.botOfficeService
      .orderStatusUpdate(
        environment.apiUrl +
          "botOfficeShop/invoice/orderStatusUpdate/" +
          id +
          "/" +
          status
      )
      .then((res) => {
        this.removeInvoiceObjectFromInvoiceList(id);
        this.toaster.success("Status updated successfully !");
        this.showSpinner = false;
      });
  }

  removeInvoiceObjectFromInvoiceList(invoiceId: any) {
    this.invoices.forEach((invoice, index) => {
      if (invoice.invoiceId === invoiceId) this.invoices.splice(index, 1);
    });
  }

  viewInvoiceDetails(id: any) {
    this.config.class = "modal-md";
    // const modalRef = this.ngbModalService.open(ViewInvoiceDetailsComponent,
    const modalRef = this.ngbModalService.open(
      InvoiceDetailsViewComponent,

      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      invoiceId: id,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }
}
