import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-comp-cust-details',
  templateUrl: './add-comp-cust-details.component.html',
  styleUrls: ['./add-comp-cust-details.component.scss'] 
})

export class AddCompCustDetailsComponent implements OnInit {
  
  clicked = false;
  combinedPTC : CompleteCustomerDetails = new CompleteCustomerDetails();
  officeId! : string;
  loggedInUser: any;
  type : any;
  headerText : any;
  userExists = false;
  customerExists = false;
  showForm = false;
  custObject : CompleteCustomerDetails = new CompleteCustomerDetails();
  theme : any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private ccdService : CompCustDetailsService,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef, 
              public activeModal: NgbActiveModal,
              private toaster: ToastrService ,
              private subscriptionService  : SubscriptionService) {}

              @Input() fromParent: any;

              countryCodes : any[] = [];
     
  ngOnInit(): void {

    this.countryCodes = GlobalClass.CountryCodes;

    this.combinedPTC.countryDialCode = "";
    this.combinedPTC.contactPersonCountryDialCode = "";

    this.type = this.fromParent.type;
    this.custObject = this.fromParent.custObject;
    this.combinedPTC = this.custObject;

    this.combinedPTC.entityType = "Individual";

    if(this.type === 'CUSTOMER'){
      this.combinedPTC.customerType = 'C';
    }else if(this.type === 'LEAD'){
      this.combinedPTC.customerType = 'L';
      this.combinedPTC.leadSource = "";
    }

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;  
    this.theme = this.sharedDataService.getCardOfficeTheme();

  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  onSubmit(){

    this.subscriptionService.searchUserByEmail(this.combinedPTC.email).subscribe(
      data=>{
        this.userExists = data;
      });

    // authorized
    this.combinedPTC.fkOfficeId = this.officeId;
    this.ccdService.addCompCustomerDetails(environment.apiUrl + 'crmCustAdd/addCustAdd', this.combinedPTC).then(
            data=>{
        this.activeModal.close();
        this.passEntry.emit(this.combinedPTC);
        if(this.type === 'CUSTOMER'){
          this.toaster.success("Customer Created Successfully");
        }else if(this.type === 'LEAD'){
          this.toaster.success("Lead Created Successfully");
        }
      });
  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }  

  closeUpdateModal(){
    this.activeModal.close();
  }

}
