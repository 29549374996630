import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BcrmData } from 'src/app/BIZ_CRM/biz_crm_classes/bcrm-data';
import { BcrmDataService } from 'src/app/BIZ_CRM/biz_crm_services/bcrm-data.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-bcrm-data',
  templateUrl: './add-bcrm-data.component.html',
  styleUrls: ['./add-bcrm-data.component.scss']
})

export class AddBcrmDataComponent implements OnInit {

  constructor(private bcrmDataService : BcrmDataService,
    private datePipe : DatePipe,
    private toastr : ToastrService,
    private sharedDataService: SharedDataService,
    private activeModal : NgbActiveModal) { }

  clicked = false;
  file1Selected = false;
  file2Selected = false;

  bcrmData : BcrmData = new BcrmData();
  selectedFile1!: File;
  selectedFile2!: File;

  loggedInUser : any;
  theme : any;

  lastEndDate : any;

  @Input() fromParent : any;
  @Output() passEntry : EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

this.lastEndDate = this.fromParent.lastEndDate;

    // this.bcrmData.id = null;
    // this.bcrmData.activeStatus = null;
    // this.bcrmData.customersLedger = null;
    // this.bcrmData.customersLedgerFile = null; 
    // this.bcrmData.endDate = null;
    // this.bcrmData.officeId = null;
    // this.bcrmData.processStatus = null;
    // this.bcrmData.salesLedger = null;
    // this.bcrmData.salesLedgerFile = null;
    // this.bcrmData.timestamp = null;
    // this.bcrmData.uroId = null;
    
  }

  onFileSelected1(event: any){
    this.selectedFile1 = event.target.files[0];
    this.file1Selected = true;
  }

  onFileSelected2(event: any){
    this.selectedFile2 = event.target.files[0];
    this.file2Selected = true;
  }

  onSubmit(){    
    
    let startDate : any = this.datePipe.transform(this.bcrmData.startDate, "dd-MM-yyyy");
    
    let lastDateCheck = false;

   


    
    if(startDate > this.lastEndDate || this.lastEndDate === null){
      lastDateCheck = true;
    }

     

 if(this.bcrmData.startDate < this.bcrmData.endDate && lastDateCheck === true){
      
        this.clicked = true;
    
    if(this.file1Selected === true && this.file2Selected === true){

    let body = new FormData();
    body.append("salesLedgerFile", this.selectedFile1);
    body.append("customersLedgerFile", this.selectedFile2);
    body.append("bcrmData",  JSON.stringify(this.bcrmData));
   
    //authorized
    this.bcrmDataService.addBcrmDataAndFileUpload(environment.apiUrl + "file/addBcrmDataAndFileUpload", body).then(
      data=>{
        this.passEntry.emit('success');
        this.activeModal.close();
        this.toastr.success('Data added successfully');

        this.bcrmData = data;

        this.bcrmDataService.testPostMethod(environment.apiUrl + "test/run", this.bcrmData).then(
          data=>{
            this.toastr.success("Process success")
          });
      });
        }else{
          this.toastr.error("Choose files to submit");
          this.clicked = false;
        }
    }else{
      this.toastr.error("Proper Date-range is required");
    }
  }

  onetimeFileUpload(){

        this.clicked = true;

    if(this.file2Selected === true){

      this.selectedFile1 = this.selectedFile2;

    let body = new FormData();
    body.append("salesLedgerFile", this.selectedFile1);
    body.append("customersLedgerFile", this.selectedFile2);
    body.append("bcrmData",  JSON.stringify(this.bcrmData));
   
    //authorized
    this.bcrmDataService.addBcrmDataAndFileUpload(environment.apiUrl + "file/oneTimeFileUpload", body).then(
      data=>{
        this.passEntry.emit('success');
        this.activeModal.close();
        this.toastr.success('File uploaded successfully');

        this.bcrmData = data;
        
        this.bcrmDataService.testPostMethod(environment.apiUrl + "test/runOneTimeUpload", this.bcrmData).then(
          data=>{
            this.toastr.success("Process success")
          });
      });
        }else{
          this.toastr.error("Choose files to submit");
          this.clicked = false;
        }
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  close(){
    this.activeModal.close();
  }

}
