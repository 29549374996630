import { CRMTaskConfiguration } from "src/app/CRM_MODULE/classes/crmtask-configuration";
import { BotConditionalResponse } from "./bot-conditional-response";
import { BotMedia } from "./bot-media";
import { BotNotificationConfiguration } from "./bot-notification-configuration";
import { BotResponseOptions } from "./bot-response-options";
import { BotTreeDefinition } from "./bot-tree-definition";
import { BotValidationConfiguration } from "./bot-validation-configuration";

export class BotResponse {
  botResponseId: any;
  botResponseStatus: any;
  response: any;
  fkOfficeId: any;
  type: any;
  saveVar: any;
  var: any;

  callExtApi: any;
  optionsExist: any;
  nextBotResponseId: any;
  slNo: any;
  backResponseId: any;
  responses: BotResponse[] = [];
  errorResponses: BotResponse[] = [];
  templateResponses: BotResponse[] = [];
  options: BotResponseOptions[] = [];
  optionsByExtApi: BotResponseOptions[] = [];
  optionsByMembersInfo: BotResponseOptions[] = [];
  activeOptions: BotResponseOptions[] = [];
  treeDefList: BotTreeDefinition[] = [];
  customizableTreeDefList: BotTreeDefinition[] = [];
  resOptionsType: any;
  parentResponseId: any;
  parentOptionId: any;
  definition: any;
  adminNotificationMessage: any;
  adminNotesMessage: any;
  createAdminNotification: any;
  createAdminNotes: any;
  treeDefId: any;
  botUserGrpId: any;
  callbackNotifResFunction: any;
  userDetailsUpdateVar: any;
  viewOptions = false;
  qsnAnswered = false;
  resAlias: any;
  globalTemplateRes: any;
  globalTemplateTag: any;
  fkAgentUroIdToAssign: any;
  saveLocationTypeAs: any;
  advancedLocationConfig: any;
  callExtApiForResVariables: any;
  fkApiConfigIdForResVariables: any;
  fkApiConfigIdForResVariablesTitle: any;

  fkAgentFullNameToAssign: any;
  getOptionsFromBotMembersInfo: any;
  optionsFromBotMembersInfoDetail: any;
  resValidationConfigs: BotValidationConfiguration[] = [];
  fkBotMediaId: any;
  backResponseResponse: any;
  backResponseSlNo: any;

  getOptionsByExtApi: any;
  fkApiConfigId: any;
  optionsByExtApiTitle: any;

  userResType: any;
  fkFormActiveId: any;
  formActiveBaseUrl: any;
  fkFormName: any;

  nextConditionalResponsesExist: any;
  fkApiConfigIdForCondRes: any;
  fkApiConfigIdForCondResTitle: any;

  conditionsFetched = false;
  currentQueryId: any;
  selectedOptionId: any;
  textFromUser: any;
  responseCountNumber: any;
  languageVariationOverlay = false;
  nextResponseOrOptionsOverlay = false;
  conditionValue: any;
  conditionType: any;
  botCondResponses: BotConditionalResponse[] = [];
  resType: any;
  botMedia: BotMedia = new BotMedia();
  userResValidation: any;
  userResValidationType: any;
  adminNotificationTitle: any;
  adminNotesTitle: any;
  adminNotesMedia: any;
  adminNotificationMedia: any;
  createManualChatNotification: any;
  interactiveListButtonName: any;
  isDefaultErrorResponse: any;
  filterVar: any;
  errorResponseId: any;
  errorResponseResponse: any;
  errorResponseSlNo: any;
  userRegResSlNo: any;
  backChecked = true;
  mainMenuChecked = true;
  settingsChecked = true;
  additonalSubResponses: BotResponse[] = [];
  additionalSubResOrder: any;
  botNotificationConfigurations: BotNotificationConfiguration[] = [];
  crmTaskConfigs: CRMTaskConfiguration[] = [];
  fkCrmListId: any;
  fkCrmListName: any;
}
