<nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Teachers</li>
    </ol>
  </nav>


  
        <div class="content">
 
          <div fxLayout="row wrap" fxLayoutGap="16px grid">

            <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" >
              <mat-card class="example-card mat-elevation-z12">
          
               
           
              
      <div class="row col-lg-12 col-md-12" style="margin-top: 18%;margin-bottom: 18%;">

          <div style="text-align: center;margin-bottom: 7%;" class="col-lg-6 col-md-6">
        
                    <mat-card-title>Back</mat-card-title>
                    <a style="cursor:pointer;"
                    onclick="history.back()" > 
                    <img src="/assets/images/icons/back-track.svg" width="55" height="55" alt="" loading="lazy">
                    </a> 
          </div>

          <div style="text-align: center;"  class="col-lg-6 col-md-6"> 

                  <mat-card-title>New</mat-card-title>
                    <a style="cursor:pointer;"
                   (click)="addUser();" >  
                    <img src="/assets/images/icons/plus.png" width="55" height="55" alt="" loading="lazy">
                  </a> 
          </div>   
                
       </div>    
                
              </mat-card>
          
              
            </div>
        
              <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="50%" *ngFor="let teacher of teachers">
        
                <mat-card *ngIf="teachersExist === true" class="example-card mat-elevation-z12">
  
                  <div style="text-align: center;border-radius: 50%;">
                    <img style="border-radius: 50%;" [src]="teacher.safeUrl" height="60" width="60" alt="...">
                    </div>
  
                    <br>
                
                    <mat-card-subtitle style="color: black; font-weight: bold;">{{teacher.teacherName}}</mat-card-subtitle>
                  
                    <div class="row" style="text-align: center;margin: 0%;display: block;
                margin: auto;">
               
               <button style="margin: 1%;width: auto;" class="btn btn-primary mr-2" (click)="openProfileModal(teacher.userId);">Profile</button> 

               <br>
               
                    <button style="margin: 1%;" *ngIf="teacher.active!== null && teacher.active" type="button"
                    class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation);statusUpdateUserId=teacher.userId;">Deactivate</button>
                  <button style="margin: 1%;" *ngIf="teacher.active !== null && !teacher.active" type="button"
                    class="btn btn-success mr-2" (click)="open(confirmActivation);statusUpdateUserId=teacher.userId;">Activate</button>
                  
                   
                   
                  </div>

                    <div class="row" style="text-align: center;margin: 0%;display: block;
                margin: auto;">
                   
                   
                    

                  </div>
                </mat-card>
              
            </div>
          </div>
        </div>


<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate user ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="updateUserStatus();modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template> 


<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate user ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="updateUserStatus();modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>

      

        <br>
        <br>
        <br>
