<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
      
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

 

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <mat-tab-group style="padding: 1em;" dynamicHeight>
  
    <mat-tab>
      <ng-template mat-tab-label>
       Schedules
        <button (click)="addBotSchedule();" matTooltip="Create Schedule" mat-icon-button>
          <mat-icon>add_box</mat-icon>
      </button>
      </ng-template>
      <div class="p-4">
 
    

        
  
            <div class="search-hero">
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText1" autocomplete="off" 
              placeholder="Start searching by schedule title or template name">
            </div>
    
            <br>
            <table *ngIf="schedulesExists"
              class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
              <thead class="tableheader" >
                <tr>
                  <th>SCHEDULE TITLE</th>
                  <th>STATUS</th>
                  <th>TEMPLATE NAME</th>
                  <th>DESCRIPTION</th>
                  <th>SCHEDULED FOR (Time)</th>
                  <th class="tble-action">MENU</th>
                </tr>
              </thead>
              <tbody>
                <tr #rowInstance *ngFor="let schedule of schedules | filter:searchText1">
                  <td class="td-height">

                    
                    <img matTooltip="Queued" *ngIf="schedule.botScheduleCompletionStatus==='q'" 
                    src="/assets/images/icons/wall-clock.png" width="21" height="21" alt="" loading="lazy">

                    <img matTooltip="Completed" *ngIf="schedule.botScheduleCompletionStatus==='c'" 
                    src="/assets/images/icons/complete.png" width="21" height="21" alt="" loading="lazy"> 

                    <img matTooltip="Scheduled" *ngIf="schedule.botScheduleCompletionStatus===null && schedule.botScheduleStatus===true" 
                    src="/assets/images/icons/inProgress.png" width="21" height="21" alt="" loading="lazy"> 

                    <img matTooltip="Deactivated" *ngIf="schedule.botScheduleCompletionStatus==='d' || schedule.botScheduleStatus===false" 
                    src="/assets/images/icons/nonStarted.png" width="21" height="21" alt="" loading="lazy">
                  
                    {{schedule.botScheduleTitle}}
                  </td>

                  <!-- STATUS -->
                  <td *ngIf="schedule.botScheduleCompletionStatus==='q'" class="td-height">Queued</td>
                  <td *ngIf="schedule.botScheduleCompletionStatus==='c'" class="td-height">Completed</td>
                  <td *ngIf="schedule.botScheduleCompletionStatus===null && schedule.botScheduleStatus===true" class="td-height">Scheduled</td>
                  <td *ngIf="schedule.botScheduleCompletionStatus==='d' || schedule.botScheduleStatus===false" class="td-height">Deactivated</td>
                   <!-- STATUS -->


                  <td class="td-height">{{schedule.fkBotTemplateName}}</td>
                  <td class="td-height">{{schedule.botScheduleDescription}}</td>
                  <td class="td-height">{{schedule.executionTimestamp}}
                  </td>
                  <td class="td-height tble-action">
                   
                    <button (click)="viewBotScheduleVarDetails(schedule);" matTooltip="Template Details" mat-icon-button>
                      <mat-icon>pageview</mat-icon>
                  </button>

                    <button (click)="viewScheduleDetails(schedule);" matTooltip="Explore Users" mat-icon-button>
                        <mat-icon>info</mat-icon>
                    </button>
                   
                    <button *ngIf="schedule.botScheduleStatus===true && schedule.botScheduleCompletionStatus===null"
                    (click)="open(deactivateScheduleModal);" matTooltip="Deactivate" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                  </button>
    
                    <button *ngIf="schedule.botScheduleStatus===false && schedule.botScheduleCompletionStatus===null"
                    (click)="botScheduleStatusUpdate(schedule);" matTooltip="Activate" mat-icon-button>
                      <mat-icon>check_box</mat-icon>
                  </button>
                   
                  </td>
  
                  <ng-template #deactivateScheduleModal let-modal>
                    <div class="modal-body" style="color: black;">
                
                      <h3 style="font-weight: bold;">
                        Deactivate Schedule ?
                      </h3>
                
                      Are you sure to Deactivate Schedule ? <br>
                      Only Active Schedules are executed ! <br>
                      Deactivated Scheduled Messages will not be sent to Whatsapp Users<br> <br>
                
                      <button style="float: right;width: auto;margin: 0.5%;" (click)="botScheduleStatusUpdate(schedule);modal.dismiss('Cross click')"
                      mat-raised-button>Deactivate</button>
                      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      
                    </div> 
                  </ng-template>

                </tr>
              </tbody>
            </table>

          
  
  </div>
    </mat-tab>
    
    <mat-tab>
      <ng-template mat-tab-label>
        Media
        <button (click)="uploadBotMedia();" matTooltip="Upload Media" mat-icon-button>
          <mat-icon>add_box</mat-icon>
      </button>

      <!-- <button (click)="uploadMediaToResumableUploadApi();" matTooltip="Resumable Upload Media" mat-icon-button>
        <mat-icon>add_box</mat-icon>
    </button> -->
      </ng-template>
      <div class="p-4">
 


      
    
            <div class="search-hero">
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off" 
              placeholder="Start searching by title, type or description">
            </div>
    
            <br>
    
            <table *ngIf="mediaExists"
              class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
              <thead class="tableheader" >
                <tr>
                  <th>TITLE</th>
                  <th>TYPE</th>
                  <th>DESCRIPTION</th>
                  <!-- <th>LAST UPLOAD ON</th> -->
                  <th>MEDIA ID(META)</th>
                  <th>MEDIA ID(ZOTI)</th>
                  <th class="tble-action">MENU</th>
                </tr>
              </thead>
              <tbody>
                <tr #rowInstance *ngFor="let med of media | filter:searchText2">
                  <td class="td-height">{{med.title}}</td>
                  <td class="td-height">{{med.mediaType}}</td>
                  <td class="td-height">{{med.description}}</td>
                  <!-- <td class="td-height">{{med.timestamp}}</td> -->
                  <td class="td-height">{{med.wcaMediaId}}</td>
                  <td class="td-height">{{med.botMediaId}}</td>
                  <td class="row">
                    

                    <button (click)="editBotMedia(med);" matTooltip="Edit" mat-icon-button>
                      <mat-icon>mode_edit_outline</mat-icon>
                  </button>
                  <button (click)="viewBotMedia(med);" matTooltip="Preview" mat-icon-button>
                    <mat-icon>preview</mat-icon>
                </button>
                <button (click)="botMediaStatusUpdate(med, 'Deactivate');" matTooltip="Deactivate" mat-icon-button>
                  <mat-icon>delete</mat-icon>
              </button>

                  </td>
    
                </tr>
              </tbody>
            </table>
          
    
    </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Inactive Media
      </ng-template>
      <div class="p-4">

            <div class="search-hero">
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText3" autocomplete="off" 
              placeholder="Start searching by title, type or description">
            </div>
    
            <br>
    
            <table *ngIf="inactiveMediaExists"
              class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
              <thead class="tableheader" >
                <tr>
                  <th>TITLE</th>
                  <th>TYPE</th>
                  <th>DESCRIPTION</th>
                  <!-- <th>LAST UPLOAD ON</th> -->
                  <th>MEDIA ID</th>
                  <th class="tble-action">MENU</th>
                </tr>
              </thead>
              <tbody>
                <tr #rowInstance *ngFor="let med of inactiveMedia | filter:searchText3">
                  <td class="td-height">{{med.title}}</td>
                  <td class="td-height">{{med.mediaType}}</td>
                  <td class="td-height">{{med.description}}</td>
                  <!-- <td class="td-height">{{med.timestamp}}</td> -->
                  <td class="td-height">{{med.wcaMediaId}}</td>
                  <td class="row">
                    

                    <button (click)="editBotMedia(med);" matTooltip="Edit" mat-icon-button>
                      <mat-icon>mode_edit_outline</mat-icon>
                  </button>
                  <button (click)="viewBotMedia(med);" matTooltip="Preview" mat-icon-button>
                    <mat-icon>preview</mat-icon>
                </button>
                <button (click)="botMediaStatusUpdate(med, 'Activate');" matTooltip="Activate" mat-icon-button>
                  <mat-icon>check_box</mat-icon>
              </button>

                  </td>
    
                </tr>
              </tbody>
            </table>
          
    
    </div>
    </mat-tab>

  </mat-tab-group>










<ng-template #testPdfUrl let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">TEST URL </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    

    <form (ngSubmit)="test()" #aplnrForm="ngForm"> 

      <div class="col-md-6">
                  <label  class="required">Media File</label>
                  <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
                   class="form-control">
               </div>
      
          </form>

  </div> 
  <div class="modal-footer">
    <button type="button" style="width: auto;" class="btn btn-info" (click)="test();">Test API (Url)</button>
    <button type="button" style="width: auto;" class="btn btn-primary" (click)="test2();">Test API (PDF)</button>
  </div>
</ng-template>