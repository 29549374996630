import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityQuestionsService } from 'src/app/service/activity-questions.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-generate-activity-questions',
  templateUrl: './generate-activity-questions.component.html',
  styleUrls: ['./generate-activity-questions.component.scss']
})
export class GenerateActivityQuestionsComponent implements OnInit {

  generate = true;
  generated = false;
  error = false;
  generatedQuestions : any[] = [];
  actQuestion : ActivityQuestions = new ActivityQuestions();

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private activityQuestionsService : ActivityQuestionsService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;
    
  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {


    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  generateQ(){

    this.activityQuestionsService.generateActivityQuestions(environment.apiUrl + "autoGenerateQuestions/autoGenerateQuestions/" + this.fromParent.description).then(
      data=>{
        this.generatedQuestions = data;

        if(this.generatedQuestions.length === 1){
          this.generate = false;
          this.error = true;
        }else{
          this.generate = false;
          this.generated = true;
        }
       
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  save(){
    let count : any = 8;
    this.generatedQuestions.forEach((value)=>{
      count++;
      this.actQuestion.activityQuestionOrder = count;
      this.actQuestion.activityQuestion = value;
      this.actQuestion.activityQuestionType = 'R';
    this.actQuestion.fkActivityPlannerId = this.fromParent.activityPlannerId;
    this.activityQuestionsService.addActivityQuestions(environment.apiUrl + 'activityQuestion/add', this.actQuestion).then(
            data=>{
        // this.activeModal.close();
        // this.passEntry.emit(this.actQuestion);
        // this.toaster.success("Question Created Successfully");
      });
    })
    this.activeModal.close();
    this.toaster.success('Questions saved successfully');
  }

}
