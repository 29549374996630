import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddProductComponent } from 'src/app/crm/product/add-product/add-product.component';
import { UpdateProductComponent } from 'src/app/crm/product/update-product/update-product.component';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-super-course-admin-product-dashboard',
  templateUrl: './super-course-admin-product-dashboard.component.html',
  styleUrls: ['./super-course-admin-product-dashboard.component.scss']
})
export class SuperCourseAdminProductDashboardComponent implements OnInit {

  showSpinner : boolean = true;
  productList: Product[] = [];
  officeId! : string;
  userName!: string;
  loggedInUser : any;
  userId!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  navbarTitle : any;

  constructor(private productService : ProductService,
              private sharedDataService: SharedDataService,
              private ngbModalService: NgbModal,
              private logoutService: LogoutService,
              private router : Router,
              private route : ActivatedRoute ) { }

  ngOnInit(): void { 
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.officeId = this.route.snapshot.params['officeId'];
    
    this.getProductList();
  }

  // authorized
  getProductList(){
      
     this.productService.getProductsByOfficeIdForSA(environment.apiUrl + "crmproduct/getProductsByOfficeIdForSA/" + this.officeId).then(
      data => {
        this.productList = data;
        this.showSpinner = false;
      });

      this.showSpinner = false;

  }
  
  exploreProduct(productId : string){
    this.router.navigate(['dashboard/super-course-admin/subjects',productId]);
  }

    // authorized
  editProduct(productId: string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateProductComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });
      

let data = {
  productId: productId ,
  // officeId: this.loggedInUser.offices[0].id,
  officeType : "EDU"
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {
      this.showSpinner = true;
      this.getProductList();

    }); 
  } 
  
// authorized
  addNewProduct(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddProductComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });
      
let data = {
  officeId: this.officeId,
  officeType : "EDU"
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {
this.showSpinner=true;
this.productService.getProductListByOfficeId(this.officeId).subscribe(
  data => {
            
    this.productList = data;
    this.showSpinner = false;
  });
  
    }); 
    
  }

  logOut(): void {

    localStorage.removeItem('auth-token');
    localStorage.clear();
    window.location.href=`${environment.authUrl}/logout`;
      
  }

  reloadPage() {
    window.location.reload();
  }

  closeResult !:string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
