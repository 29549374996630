import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';


import { environment } from 'src/environments/environment';
import { AddUserComponent } from '../../../auth/add-user/add-user.component';
import { SuperAdminUserService } from '../../../services/user/super-admin-user.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { UserSub } from 'src/app/models/subscriptionDTOS';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploadInfoComponent } from '../../file-upload-info/file-upload-info.component';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from 'src/app/services/destroyService';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/crmClasses/product';
import { AddAdminComponent } from 'src/app/course-management/admin/add-admin/add-admin.component';
import { AddAdmin } from 'src/app/courseManagementClasses/add-admin';
import { AssignRoleComponent } from 'src/app/course-management/assign-role/assign-role.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { OfficesService } from 'src/app/services/office/offices.service';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { StudentService } from 'src/app/service/student.service';

@Component({
  selector: 'app-office-admin-dashboard',
  templateUrl: './office-admin-dashboard.component.html',
  styleUrls: ['./office-admin-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OfficeAdminDashboardComponent extends DestroyService implements OnInit {
  showSpinner = true;
  currentRole = "OFFICE_ADMIN";
  navbarTitle : any;
  users: any;
  userName = '';
  userId !: string;
  officeAdminUsers: any;
  usersLoading = false;
  superAdminUsers: any;
  products: any;
  userOfficeId: any;
  productsLoading = true;
  loggedInUser;
  productsExists = false;
  officeUsersExists = false;
  productOptions: any;
  userOptions: any;
  productLoader = false;
  productLoaderMessage = ''
  subscriptionLoader = false;
  subscriptionLoaderMessage = '';
  userLoader = false;
  userLoaderMessage = '';
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 
  closeResult!:string;
  officeObject : any = {
                            activeStatus: '',
                            backgroundColor: '',
                            id:'',
                            logo: '',
                            oa_ParentMentor:'',
                            oa_SubAdmin: '',
                            oa_CRMAdmin : '',
                            oa_CourseAdmin : '',
                            oa_ClassAdmin : '',
                            oa_SkillReport : '',
                            officeLocation: '',
                            officeName: '',
                            themeColour: ''
                          }

  modalRefs: BsModalRef[] = [];
  table: any;
  authToken: string | null = '';
  usersSubscriptions: UserSub[] = [];

  public screenWidth: any;
  public screenHeight: any;

  getProductListByofficeId!: string;
  productListById : Product[] = [];

  constructor(
    private ngbModalService: NgbModal, private studentService : StudentService,
    public router: Router, private sharedDataService: SharedDataService, private datatableElement: DataTableDirective,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef, private toaster: ToastrService,

    private route: ActivatedRoute,
    private superAdminUserService: SuperAdminUserService,
    private spinner: NgxSpinnerService,
    private officeService : OfficesService) {
      super();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
   
    this.authToken = null;
    this.authToken = localStorage.getItem('auth-token'); 

    this.userOfficeId = this.loggedInUser.offices[0].id;

    // authorized
    officeService.getOfficeByIdForOfficeAdmin(environment.apiUrl + "admin/getOfficeByIdForOfficeAdmin/" + this.userOfficeId).then(res=>{ 
      this.officeObject = res; 
    });

    this.userId = this.loggedInUser.userId ;

    
    if (this.route?.snapshot?.data?.users) {
      this.usersSubscriptions = [];
      const users = this.route?.snapshot?.data?.users;
      for (let i = 0; i < users.length; i++) {
        let userSub = new UserSub();
        for (let j = 0; j < users[i].subscriptionDTOS.length; j++) {
          if (users[i].subscriptionDTOS[j].role !== null) {
            userSub = new UserSub();
            userSub.name = users[i].firstName + ' ' + users[i].lastName;
            userSub.userId = users[i].userId;
            userSub.role = users[i].subscriptionDTOS[j].role.roleName;
            userSub.roleId = users[i].subscriptionDTOS[j].role.id;
            userSub.product = users[i].subscriptionDTOS[j].product.productName;
            userSub.productId = users[i].subscriptionDTOS[j].product.id;
            userSub.startDate = users[i].subscriptionDTOS[j].startDate;
            userSub.endDate = users[i].subscriptionDTOS[j].endDate;
            userSub.subscriptionId = users[i].subscriptionDTOS[j].subscriptionId;

            this.usersSubscriptions.push(userSub);
          }
        }
      };

      this.officeAdminUsers = this.route?.snapshot?.data?.users;
      this.officeUsersExists = true;
    }
  }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.productOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: false,
      info:false
    };

    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    setTimeout(()=>{                           //timer
            this.showSpinner = false;
       }, 1500);
 
  
     }

     open(content: any) {
      this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
      
    }
 
    computeSkillReport(){

      this.studentService.generateOfficeStudentsSkillReport(environment.apiUrl + "student/generateOfficeStudentsSkillReport").then(
        res=>{
          this.toaster.success("Skill report generated");
        });

    }



  reloadPage() {
    window.location.reload();
  }

  startProductLoading() {
    this.productLoader ? this.spinner.show() : this.spinner.hide();
  }
  startSubscriptionLoading() {
    this.subscriptionLoader ? this.spinner.show() : this.spinner.hide();
  }
  startUserLoading() {
    this.userLoader ? this.spinner.show() : this.spinner.hide();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass();
    // this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  // openModalForUserAdd(): void {
  //   this.config.class = 'modal-lg';
  //   const data = { source: 'OFFICE_ADMIN' };
  //   const initialState = {
  //     sourceObject: data,
  //     config: this.config
  //   };
  //   this.bsModalRef = this.modalService.show(AddUserComponent, {
  //     class: 'modal-lg', backdrop: true,
  //     ignoreBackdropClick: true,
  //     keyboard: false, initialState
  //   });
  //   this.bsModalRef.content.closeBtnName = 'Close';
  //   this.modalRefs.push(this.bsModalRef);
  //   const self = this;
  //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
  //     self.addNewOfficeUser(res.data);
  //   });
  // }

  // openModalForBulkUserAdd(): void {
  //   this.config.class = 'modal-lg';
  //   const data = { source: 'OFFICE_ADMIN' };
  //   const initialState = {
  //     bulkUserObject: data,
  //     config: this.config
  //   };
  //   this.bsModalRef = this.modalService.show(AddBulkUserComponent, {
  //     class: 'modal-lg', backdrop: true,
  //     ignoreBackdropClick: true,
  //     keyboard: false, initialState
  //   });
  //   this.bsModalRef.content.closeBtnName = 'Close';
  //   this.modalRefs.push(this.bsModalRef);
  //   const self = this;
  //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
  //     self.addBulkUsers(res.data, res.data.file);
  //   });
  // }

  // addBulkUsers(info: any, file: File): void {
  //   const self = this;
  //   const data = {
  //     officeId: this.loggedInUser.offices[0].id,
  //     roleId: info.role,
  //     csvFile: file as File
  //   };
  //   this.userLoaderMessage = 'Uploading File';
  //   this.userLoader = true;
  //   this.startUserLoading();
  //   this.superAdminUserService.bulkUsers(environment.apiUrl + 'officeAdmin/uploadCSVFile', toFormData(data)).then(bulkUserResRes => {
  //    this.fileUploadContent(bulkUserResRes);
  //     this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + this.userOfficeId).then(users => {
  //       this.usersSubscriptions = [];
  //       for (let i = 0; i < users.length; i++) {
  //         let userSub = new UserSub();
  //         for (let j = 0; j < users[i].subscriptionDTOS.length; j++) {
  //           if (users[i].subscriptionDTOS[j].role !== null) {
  //             userSub = new UserSub();
  //             userSub.name = users[i].firstName + ' ' + users[i].lastName;
  //             userSub.userId = users[i].userId;
  //             userSub.role = users[i].subscriptionDTOS[j].role.roleName;
  //             userSub.roleId = users[i].subscriptionDTOS[j].role.id;
  //             userSub.product = users[i].subscriptionDTOS[j].product.productName;
  //             userSub.productId = users[i].subscriptionDTOS[j].product.id;
  //             userSub.startDate = users[i].subscriptionDTOS[j].startDate;
  //             userSub.endDate = users[i].subscriptionDTOS[j].endDate;
  //             userSub.subscriptionId = users[i].subscriptionDTOS[j].subscriptionId;
  //             this.usersSubscriptions.push(userSub);
  //           }
  //           this.userLoader = false;
  //           this.startUserLoading();
  //         }
  //       };
  //       this.toaster.success("Users Added Successfully");
  //     });
  //   }).catch((err) => {
  //     this.toaster.error(err.error.message);
  //     this.userLoader = false;
  //     this.startUserLoading();
  //   }).catch((err) => {
  //     console.log(err)
  //     this.toaster.error(err.error.message);
  //     this.userLoader = false;
  //     this.startUserLoading();
  //   });
  // }

  // openModalForUserSubscriptionAdd() {
  //   this.config.class = 'modal-lg';
  //   const data = { source: 'OFFICE_ADMIN' };
  //   const initialState = {
  //     sourceObject: data,
  //     config: this.config
  //   };
  //   this.bsModalRef = this.modalService.show(AddUserSubscriptionComponent, {
  //     class: 'modal-lg', backdrop: true,
  //     ignoreBackdropClick: true,
  //     keyboard: false, initialState
  //   });
  //   this.bsModalRef.content.closeBtnName = 'Close';
  //   this.modalRefs.push(this.bsModalRef);
  //   const self = this;
  //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
  //     res.data[`officeId`] = this.userOfficeId;
  //     self.updateUserWithSubscription(res.data);
  //   });
  // }

  // openModalForUserSubscriptionEdit(userSub: any) {
  //   this.config.class = 'modal-md';
  //   const initialState = {
  //     userSubObject: userSub
  //   };
  //   this.bsModalRef = this.modalService.show(AddUserSubscriptionComponent, { initialState });
  //   this.bsModalRef.content.closeBtnName = 'Close';
  //   this.modalRefs.push(this.bsModalRef);
  //   const self = this;
  //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
  //     res.data[`officeId`] = this.userOfficeId;
  //     self.updateUserWithSubscription(res.data);
  //   });
  // }



  // updateUserWithSubscription(subscriptionData: any) {
  //   const self = this;
  //   this.subscriptionLoaderMessage = 'Adding new Subscription to User';
  //   this.subscriptionLoader = true;
  //   this.startSubscriptionLoading();
  //   this.superAdminUserService.addSubscriptionToUser(environment.apiUrl + 'subscribe/add/new/subscription', subscriptionData).then(updateSubscriRes => {
  //     this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + this.userOfficeId).then(users => {
  //       this.usersSubscriptions = [];
  //       for (let i = 0; i < users.length; i++) {
  //         let userSub = new UserSub(); 
  //         for (let j = 0; j < users[i].subscriptionDTOS.length; j++) {
  //           if (users[i].subscriptionDTOS[j].role !== null) {
  //             userSub = new UserSub();
  //             userSub.name = users[i].firstName + ' ' + users[i].lastName;
  //             userSub.userId = users[i].userId;
  //             userSub.role = users[i].subscriptionDTOS[j].role.roleName;
  //             userSub.roleId = users[i].subscriptionDTOS[j].role.id;
  //             userSub.product = users[i].subscriptionDTOS[j].product.productName;
  //             userSub.productId = users[i].subscriptionDTOS[j].product.id;
  //             userSub.startDate = users[i].subscriptionDTOS[j].startDate;
  //             userSub.endDate = users[i].subscriptionDTOS[j].endDate;
  //             userSub.subscriptionId = users[i].subscriptionDTOS[j].subscriptionId;

  //             this.usersSubscriptions.push(userSub);
  //           }
  //         }
  //       };
  //       this.toaster.success("Subscription updated Successfully");
  //       this.subscriptionLoader = false;
  //   this.startSubscriptionLoading();
  //     }).catch((err) => {
  //       this.toaster.error(err.error.message);
  //       this.subscriptionLoader = false;
  //   this.startSubscriptionLoading();
  //     });
  //   }).catch((err) => {
  //     this.toaster.error(err.error.message);
  //     this.subscriptionLoader = false;
  //   this.startSubscriptionLoading();
  //   });
  // }

  // addNewOfficeUser(adminInfo: any): void {
  //   const newOfficeAdmin = {
  //     firstName: adminInfo.first_name,
  //     lastName: adminInfo.last_name,
  //     email: adminInfo.email,
  //     phoneNumber: adminInfo.phone_number,
  //     gender: adminInfo.gender,
  //     dateOfBirth: adminInfo.date_of_birth,
  //     role: adminInfo.role, // this.roles.filter((role: { roleName: string; }) => role.roleName === 'OFFICE_ADMIN')[0].roleId,
  //     office: this.loggedInUser.offices[0].id,
  //     address: {
  //       addressLine1: adminInfo.addressLine1,
  //       addressLine2: adminInfo.addressLine2,
  //       city: adminInfo.city,
  //       country: adminInfo.country,
  //       state: adminInfo.state,
  //       zipCode: adminInfo.zipCode
  //     },
  //     subscriptions: adminInfo.subscriptionDTOS
  //   };

  //   this.userLoader = true;
  //   this.userLoaderMessage = 'Adding new User';
  //   this.startUserLoading();
  //   this.superAdminUserService.createOfficeAdmin(environment.apiUrl + 'subscribe/user/subscription', newOfficeAdmin).then(addOfficeAdminRes => {
  //     this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + this.userOfficeId).then(officeAdmins => {
  //       this.officeAdminUsers = officeAdmins;
  //       this.toaster.success("user addedd successfully");
  //       this.usersSubscriptions = [];
  //       for (let i = 0; i < this.officeAdminUsers.length; i++) {
  //         let userSub = new UserSub();
  //         for (let j = 0; j <this.officeAdminUsers[i].subscriptionDTOS.length; j++) {
  //           if (this.officeAdminUsers[i].subscriptionDTOS[j].role !== null) {
  //             userSub = new UserSub();
  //             userSub.name = this.officeAdminUsers[i].firstName + ' ' + this.officeAdminUsers[i].lastName;
  //             userSub.userId =this.officeAdminUsers[i].userId;
  //             userSub.role = this.officeAdminUsers[i].subscriptionDTOS[j].role.roleName;
  //             userSub.roleId = this.officeAdminUsers[i].subscriptionDTOS[j].role.id;
  //             userSub.product = this.officeAdminUsers[i].subscriptionDTOS[j].product.productName;
  //             userSub.productId = this.officeAdminUsers[i].subscriptionDTOS[j].product.id;
  //             userSub.startDate =this.officeAdminUsers[i].subscriptionDTOS[j].startDate;
  //             userSub.endDate =this.officeAdminUsers[i].subscriptionDTOS[j].endDate;
  //             userSub.subscriptionId =this.officeAdminUsers[i].subscriptionDTOS[j].subscriptionId;

  //             this.usersSubscriptions.push(userSub);
  //           }
  //         }
  //       };
  //       this.userLoader = false;
  //       this.startUserLoading();
  //     });
  //   }).catch((error) =>{
  //     this.toaster.error(error.error.message);
  //       this.userLoader = false;
  //       this.startUserLoading();
  //   });
  // }

  getRowNumber(index: number, role = null): number | null {
    if (role !== null && (role?.[`roleId`] === null || role?.[`id`] === null)) {
      return null;
    }
    return ++index;
  }

  // editProduct(product: FormGroup): void {
  //   this.config.class = 'modal-md';
  //   const initialState = {
  //     productObject: product
  //   };
  //   this.bsModalRef = this.modalService.show(AddProductComponent, { initialState });
  //   this.bsModalRef.content.closeBtnName = 'Close';
  //   this.modalRefs.push(this.bsModalRef);
  //   const self = this;
  //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
  //     self.editProductInDB(res.data);
  //   });
  // }
  


 

  editOfficeUser(officeUser: any): void {
    this.config.class = 'modal-lg';
    const data = { source: 'OFFICE_ADMIN' };
    const initialState = {
      officeAdminState: officeUser,
      sourceObject: data,
      config: this.config
    };
    this.bsModalRef = this.modalService.show(AddUserComponent, {
      class: 'modal-lg', backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false, initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      this.editOfficeAdminUser(res.data);
    });
  }

  editOfficeAdminUser(userInfo: any) {
    const newOfficeAdmin = {
      userId: userInfo.userId,
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
      email: userInfo.email,
      phoneNumber: userInfo.phone_number,
      gender: userInfo.gender,
      dateOfBirth: userInfo.date_of_birth,
      role: userInfo.role,
      office: this.loggedInUser.offices[0].id,
      address: {
        addressLine1: userInfo.addressLine1,
        addressLine2: userInfo.addressLine2,
        city: userInfo.city,
        country: userInfo.country,
        state: userInfo.state,
        zipCode: userInfo.zipCode
      }
    };

    this.userLoaderMessage = 'Updating User Status';
    this.userLoader = true;
    this.startUserLoading();
    this.superAdminUserService.createOfficeAdmin(environment.apiUrl + 'admin/add/office/adminForOfficeAdmin', newOfficeAdmin).then(addOfficeAdminRes => {
        this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + this.userOfficeId).then(officeAdmins => {

      this.officeAdminUsers = officeAdmins;
        this.toaster.success("User updated successfully");
        this.userLoader = false;
        this.startUserLoading();
      });
    }).catch((error) =>{
      this.toaster.success(error.error.message);
        this.userLoader = false;
        this.startUserLoading();
    });
  }

  // addNewProduct(productName: string): void {
  //   const self = this;
  //   const productDtls = {
  //     productName: productName,
  //     officeId: this.loggedInUser.offices[0].id
  //   };
  //   this.productLoader = true;
  //   this.productLoaderMessage = 'Adding new Product';
  //   this.startProductLoading();
    
  //   this.productService.createProduct(environment.apiUrl + 'product/add/product', productDtls).then(addProductRes => {
  //     self.productService.getProductsByOffice(environment.apiUrl + 'product/get/' + this.userOfficeId + '/products').then(productRes => {
  //       self.products = productRes;

  //       this.toaster.success("Product addedd successfully");
  //       this.productLoader = false;
  //       this.startProductLoading();
  //     }).catch((err) =>{
  //       this.toaster.error(err.error.message)
  //       this.productLoader = false;
  //       this.startProductLoading();
  //     });
  //   }).catch((err) =>{
  //     this.toaster.error(err.error.message)
  //     this.productLoader = false;
  //       this.startProductLoading(); 
  //   });
  // }

//   openModalForProductAdd(){

   
    
//     this.config.class = 'modal-md';
//     const modalRef = this.ngbModalService.open(AddProductComponent,
//       {
//         size : 'lg',
//         scrollable: true,
//         windowClass: 'app-modal-window',
        
//       keyboard: false
//       });
      

// let data = {
//   officeId: this.loggedInUser.offices[0].id
//     }

// modalRef.componentInstance.fromParent = data;
//     modalRef.result.then((result) => {
//       console.log(result);
//     }, (reason) => {
//     });

//     modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {

//       this.productLoader = true;
//       this.productLoaderMessage = 'Adding new Product';
//       this.startProductLoading();

//       this.getProductList();

//       this.productLoader = false;
//       this.startProductLoading();
//     }); 
    
   
//   }




//   openModalForCombinedPTCUserAdd(){

//     this.userOfficeId = this.loggedInUser.offices[0].id;
//     this.addCombinedPTCUser(this.userOfficeId);
    

//   }

//   addCombinedPTCUser(userOfficeId : string){
//     this.config.class = 'modal-md';
//     const modalRef = this.ngbModalService.open(AddCombinedParentTeacherComponent,
//       {
//         size : 'lg',
//         scrollable: true,
//         windowClass: 'app-modal-window',
        
//       keyboard: false
//       });
      

// let data = {
//   userOfficeId: userOfficeId 
//     }

// modalRef.componentInstance.fromParent = data;
//     modalRef.result.then((result) => {
//       console.log(result);
//     }, (reason) => {
//     });

//     modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
//       this.userLoader = true;
//       this.userLoaderMessage = 'Adding Parent'
//       this.startUserLoading();
//       // this.getCcDetails();
//       this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + this.userOfficeId).then(officeAdmins => {
//         this.officeAdminUsers = officeAdmins;
//         this.userLoader = false;
//         this.startUserLoading();
//       });
//     });  
//   } 


  openModalForAddAdmin(){
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.openModalForAddAdmin1(this.userOfficeId);
  }

  // authorized
  openModalForAddAdmin1(userOfficeId : string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddAdminComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        
      keyboard: false 
      });
      

let data = {
  userOfficeId: userOfficeId,
  oa_CRMAdmin : this.officeObject.oa_CRMAdmin
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddAdmin) => {
    this.userLoader = true;
    this.userLoaderMessage = 'Adding Parent'
    this.startUserLoading();
    // this.getCcDetails();
    this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + this.userOfficeId).then(officeAdmins => {
      this.officeAdminUsers = officeAdmins;
      this.userLoader = false;
      this.startUserLoading();
    });
  }); 
  }





// authorized
  AssignRole(userId : string, userFirstName : string, userLastName : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AssignRoleComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false 
      });
      

let data = {
  userId: userId ,
  userFirstName : userFirstName,
  userLastName : userLastName,
  officeId : this.userOfficeId
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddAdmin) => {
    // this.userLoader = true;
    // this.userLoaderMessage = 'Adding Parent'
    // this.startUserLoading();
    // // this.getCcDetails();
    //   this.officeAdminUsers = officeAdmins;
    //   this.userLoader = false;
    //   this.startUserLoading();
    // });
  }); 
  }















// authorized
  updateUserStatus(officeAdmin: any, value: string) {

    if(officeAdmin.userId === this.userId){
      this.toaster.error('Cannot deactivate Office Admin User')
    } else {
    const self = this;
    this.userLoader = true;
    this.userLoaderMessage = 'Updating User Status';
    this.startUserLoading();
    this.superAdminUserService.updateUserStatus(environment.apiUrl + 'user/update/statusForOfficeAdmin?userId=' + officeAdmin.userId + '&value=' + value + '&status=' + !officeAdmin.active).then(userResponse => {
      self.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/'+this.userOfficeId).then(officeAdmins => {
        this.officeAdminUsers = officeAdmins;
        this.toaster.success("User Status Updated Successfully")
        this.userLoader = false;
        this.startUserLoading();
      })
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.userLoader = false;
      this.startUserLoading();
    }) 
  }
  }
 
//   updateProductStatus(productId : string): void {


//     this.productLoader = true;
//     this.startProductLoading();
//     this.productLoaderMessage = 'Updating Product Status ';


// this.productService.changeStatus(productId).subscribe(
// data =>{
//   this.getProductList();
// this.productLoader = false;
// this.startProductLoading();

//  });
//   } 

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });    
  } 
  // updateProductStatus(product: any): void {
  //   const self = this;
  //   officeId: this.loggedInUser.offices[0].id;
  //   this.productLoaderMessage = 'updating product status';
  //   this.productLoader = true;
  //   this.startProductLoading();
  //   this.productService.updateProductStatus(environment.apiUrl + 'product/update/status?productId=' + product.productId + '&status=' + !product.activeStatus).then(addProductRes => {
  //     self.productService.getProductsByOffice(environment.apiUrl + 'product/get/' + this.userOfficeId + '/products').then(productRes => {
  //       self.products = productRes;
  //       this.toaster.success("Product status updated successfully");
  //       this.productLoader =false;
  //       this.startProductLoading();
  //     });
  //   }).catch((err) => {
  //     this.toaster.error(err.error.message);
  //     this.productLoader = false;
  //     this.startProductLoading();
  //   })
  // }

  fileUploadContent(fileUploadResponse: any): void {
    this.config.class = 'modal-lg';
    const initialState = {
      fileUploadResponse: fileUploadResponse,
      config: this.config  
    };
      this.bsModalRef = this.modalService.show(FileUploadInfoComponent, {
      class: 'modal-lg', backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
  }

}

export function toFormData(formValue: any): FormData {
  const formData = new FormData();
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;


  



 
}
