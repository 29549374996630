import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Skill } from '../courseManagementClasses/skill';

@Injectable({
  providedIn: 'root'
})

export class SkillServiceService {

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  updateSkill(avenue: string, skill : Skill): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, skill, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, skill, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  updateSkillStatus(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers }).toPromise()
        .then((data) => {resolve(data);
        }).catch((err) => {
        });
    });
  }

  getSkillById(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers }).toPromise()
        .then((data) => {resolve(data);
        }).catch((err) => {
        });
    });
  }

  addSkill(avenue: string, skill : Skill): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, skill, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, skill, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getAllSkills(avenue: string): Promise<any> {
    
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers }).toPromise()
        .then((data) => {resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentSkillResultForReport(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers }).toPromise()
        .then((data) => {resolve(data);
        }).catch((err) => {
        });
    });
  }

}
