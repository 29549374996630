import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router'; 
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { environment } from 'src/environments/environment';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { Subscription } from 'src/app/courseManagementClasses/subscription';
import { DatePipe } from '@angular/common';
import { AddNewSubscriptionModalComponent } from '../classDashboards/add-new-subscription-modal/add-new-subscription-modal.component';
import { TableUtil } from 'src/app/table-util';
import { FileServiceService } from 'src/app/service/file-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
 
@Component({
  selector: 'app-crm-subscriptions-dashboard',
  templateUrl: './crm-subscriptions-dashboard.component.html',
  styleUrls: ['./crm-subscriptions-dashboard.component.scss']
})
export class CrmSubscriptionsDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";
  closeResult !: string;
  customerLoader!: boolean;
  customerLoaderMessage!: string;
  loggedInUser: any;
  loggedUser: any;

  rebindDataTable() {}
  users : any[] =[{
    address: '',
    dateOfBirth: '',
    email: '',
    firstName: '',
    gender: '',
    lastName:'',
    office: '',
    phoneNumber: '',
    role:'',
    userId: '',
    parentId : '',
    userImage : '',
    status: '',
    safeUrl: '',
    roles : '',
    isParent : '',
    isStudent : '',
    isParentMentor : '',
    isChildStudent : ''
  }]

  userName!: string;
  userId!: string;
  roleOptions: any = {};
  unSubcriber!: Observable<any>;

  customerOptions : any;
  customerExists!: boolean;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  ccd: any;
  customerId!: string;
  addressId!: string;
  officeId!: string;

  officeLoader = false;
  officeLoaderMessage!: string;

  viewProducts = false;

constructor(private ngbModalService: NgbModal,
            public router: Router,
            private spinner: NgxSpinnerService,
            private imageService: FileServiceService,
            private sanitizer : DomSanitizer,
             private datePipe : DatePipe,
            private toaster: ToastrService,
            private subscriptionService : SubscriptionService,
            private sharedDataService: SharedDataService,
            public bsModalRef: BsModalRef ) { }
            
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    navbarTitle : any;
    
    productBasedSubscriptions : Subscription[] = [];
    subs : Subscription[] = [];

    subscriptionForSearch : Subscription = new Subscription();

    showSpinner = false;
    status = "Active as on today";
    totalCount = 0;

    searchValue : any = null;
    searchInput = true;

    abc : any;
    xyz : any;

  ngOnInit(): void {

    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

   this.getProductWiseSubscriptions();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info:false
    }; 
  }

  searchInputCustomerSubscription = true;
  searchValueCustomerSubscription : any = null;
  ccdetails2: CompleteCustomerDetails[] = [];

  searchCustomerSubscriptionsByCustomer(){
    this.subscriptionService.searchSubscriptionsByCustomerDetails(environment.apiUrl + "crmCustAdd/searchSubscriptionsByCustomerDetails/" + this.searchValueCustomerSubscription).then(
      data=>{
        this.ccdetails2 = data;

        if(this.ccdetails2.length !== 0 && this.ccdetails2 !== undefined){
          this.searchInputCustomerSubscription = false;
        }else{
          this.searchInputCustomerSubscription = true;
          this.toaster.error("Customer Not found !")
        }
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getSubscriptionsByCustomerUserId(userId : any){
    this.showSpinner =true;
    this.totalCount = 0;
    this.productBasedSubscriptions = [];
    this.subs = [];
    this.ccdetails2.forEach((cust)=>{
      if(cust.userId === userId){

        this.status = "Subscriptions of " + cust.firstName + " " + cust.lastName;

        this.productBasedSubscriptions = cust.subscriptions;
        this.productBasedSubscriptions.forEach((prod)=>{
          prod.subscriptions.forEach((sub)=>{

            sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
            sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");

            this.subs.push(sub);
            this.totalCount = this.totalCount + 1;
          });
          this.imageService.getProductImage(prod.productImage).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              prod.safeUrl = this.xyz;

            });
        });
        this.dataSource = this.subs;
        this.showSpinner = false;
      }
    })

  }

  getExpiringSubscriptionsByPeriod(){
    this.showSpinner = true;
    this.subscriptionService.getSubscriptionsByPeriod(environment.apiUrl + "subscribe/getExpiringSubscriptionsByPeriod", this.subscriptionForSearch).then(
      data=>{

        this.subscriptionForSearch.startedDate = this.datePipe.transform(this.subscriptionForSearch.startedDate, "dd-MM-yyyy");
        this.subscriptionForSearch.endedDate = this.datePipe.transform(this.subscriptionForSearch.endedDate, "dd-MM-yyyy");

        this.status = this.subscriptionForSearch.startedDate + " to " + this.subscriptionForSearch.endedDate;
        this.productBasedSubscriptions = [];
        this.subs = [];
        this.totalCount = 0;
        this.productBasedSubscriptions = data;
        this.showSpinner = false;
        
        this.productBasedSubscriptions.forEach((prod)=>{
          prod.subscriptions.forEach((sub)=>{

            sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
            sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");

            this.subs.push(sub);
            this.totalCount = this.totalCount + 1;
          });
          this.imageService.getProductImage(prod.productImage).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              prod.safeUrl = this.xyz;

            });
        });
        this.dataSource = this.subs;
        this.customerExists = true;
        this.subscriptionForSearch = new Subscription();
        
      });
  }

  searchSubscriptions(){
    this.showSpinner = true;
    this.subscriptionService.getSubscriptionsByPeriod(environment.apiUrl + "subscribe/getSubscriptionsByPeriod", this.subscriptionForSearch).then(
      data=>{

        this.subscriptionForSearch.startedDate = this.datePipe.transform(this.subscriptionForSearch.startedDate, "dd-MM-yyyy");
        this.subscriptionForSearch.endedDate = this.datePipe.transform(this.subscriptionForSearch.endedDate, "dd-MM-yyyy");

        this.status = this.subscriptionForSearch.startedDate + " to " + this.subscriptionForSearch.endedDate;
        this.productBasedSubscriptions = [];
        this.subs = [];
        this.totalCount = 0;
        this.productBasedSubscriptions = data;
        this.showSpinner = false;
        
        this.productBasedSubscriptions.forEach((prod)=>{
          prod.subscriptions.forEach((sub)=>{

            sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
            sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");

            this.subs.push(sub);
            this.totalCount = this.totalCount + 1;
          });
          this.imageService.getProductImage(prod.productImage).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              prod.safeUrl = this.xyz;

            });
        });
        this.dataSource = this.subs;
        this.customerExists = true;
        this.subscriptionForSearch = new Subscription();

      });
  }

  getProductWiseSubscriptionsAboutToEnd(){
    this.showSpinner = true;
    this.subscriptionService.getProductWiseSubscriptionsAboutToEnd(environment.apiUrl + "subscribe/getProductWiseSubscriptionsAboutToEnd").then(
      data=>{

        this.status = "Subscriptions expiring in one week"
        this.productBasedSubscriptions = [];
        this.subs = [];
        this.totalCount = 0;
        this.productBasedSubscriptions = data;
        this.showSpinner = false;
        
        this.productBasedSubscriptions.forEach((prod)=>{
          prod.subscriptions.forEach((sub)=>{

            sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
            sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");

            this.subs.push(sub);
            this.totalCount = this.totalCount + 1;
          });
          this.imageService.getProductImage(prod.productImage).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              prod.safeUrl = this.xyz;

            });
        });
        this.dataSource = this.subs;
        this.customerExists = true;
        
      });
  }

  searchFoUsers(){
    if(this.searchValue === null){
      this.toaster.error("Enter name, email or phone !");
      this.searchInput = true;
    }else{
      this.showSpinner = true;
      this.subscriptionService.getCustomerUsersByEmailOrPhone(environment.apiUrl + "user/getCustomerUsersByEmailOrPhone/" + this.searchValue).then(
        data=>{
          this.users = [];
          this.users = data;  
          this.searchValue = null;        
          this.showSpinner = false;

          if(this.users === undefined || this.users.length === 0 ){
          this.toaster.error('User not found !')
          this.showSpinner = false;
          this.searchInput = true;
          }else if( this.users.length > 0 ){
            this.searchInput = false;
          }
        });
    }
  }

  filterSubscriptions(prodId:any){
    this.showSpinner = true;
    this.subs = [];
    if(prodId === "All Subscriptions"){
      this.status = "All subscriptions";
      this.productBasedSubscriptions.forEach((prod)=>{
        prod.subscriptions.forEach((sub)=>{

          // sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
          // sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");

          this.subs.push(sub);
        })
      });
      this.dataSource = this.subs;
      this.showSpinner = false;
    }else{
      this.productBasedSubscriptions.forEach((prod)=>{
        if(prod.productId === prodId){
          this.status = prod.productName;
          prod.subscriptions.forEach((sub)=>{
            this.subs.push(sub);
          });
        }
      });
      this.dataSource = this.subs;
      this.showSpinner = false;
    }
  }

  getProductWiseSubscriptions(){
    this.showSpinner = true;
    this.subscriptionService.getProductWiseSubscriptions(environment.apiUrl + "subscribe/getProductWiseSubscriptions").then(
      data=>{
        this.totalCount = 0;
        this.productBasedSubscriptions = [];
        this.subs = [];
        this.productBasedSubscriptions = data;
        this.showSpinner = false;
        
        this.productBasedSubscriptions.forEach((prod)=>{
          prod.subscriptions.forEach((sub)=>{

            sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
            sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");

            this.subs.push(sub);
            this.totalCount = this.totalCount + 1;
          });

          this.imageService.getProductImage(prod.productImage).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              prod.safeUrl = this.xyz;

            });
        });
        this.dataSource = this.subs;
        this.customerExists = true;
      })
  }
 
  dataSource = this.subs;
    exportArray() {
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
        Name: x.userName,
        Email: x.userEmail,
        Phone: x.userPhone,
        Period: x.startedDate + " to " + x.endedDate
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
    }





     switchDashboard(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
        {
          size : 'sm',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        userId : this.userId,
        currentRole : this.currentRole
  
      }
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });    
    }

    goToAccountPage(){
      this.router.navigate(['user-account',this.userId])
    }
    
  startOfficeLoading() {
    this.officeLoader ? this.spinner.show() : this.spinner.hide();
  } 


  getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }


  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
  
  reloadPage() {
    window.location.reload();
  } 

  addNewSubscription(userId : any, firstName:any, lastName:any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddNewSubscriptionModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : userId,
      officeId : this.officeId,
      firstName : firstName,
      lastName : lastName
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  
      this.getProductWiseSubscriptions();

    }); 
  }
 
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    // this.searchInput = !this.searchInput;
    if(this.searchInput === false){
      this.searchInput = true;
    }
    this.searchValue = null;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
          
  }

    

