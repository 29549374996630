import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { LocationGroup } from 'src/app/BIZ_CRM/biz_crm_classes/location-group';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { LocationGroupService } from 'src/app/BIZ_CRM/biz_crm_services/location-group.service';
import { GlobalClass } from 'src/app/global/global-class';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

  clicked = false;
  combinedPTC : Customer = new Customer();
  officeId!:string;
  loggedInUser: any;
  type : any;
  headerText : any;
  userExists = false;
  customerExists = false;
  showForm = false;
  custObject : Customer = new Customer();
  theme : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private lgService : LocationGroupService,
              private sharedDataService : SharedDataService, 
              public bsModalRef: BsModalRef, 
              private customerService : CustomerService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService ,
              private subscriptionService  : SubscriptionService) {}

              locationGroups : LocationGroup[] = [];

  @Input() fromParent: any;

  countryCodes : any[] = [];
     
  ngOnInit(): void {

    this.countryCodes = GlobalClass.CountryCodes;
    
    this.type = this.fromParent.type;
    this.custObject = this.fromParent.custObject;
   
    
    this.combinedPTC = this.custObject;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;  
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getLocationGroups();

  }

  getLocationGroups(){
    this.lgService.getLocationGroupsByOfficeId(environment.apiUrl + "locationGroup/getLocationGroupsByOfficeId").then(
      data=>{
        this.locationGroups = data;
      });
  }

  onSubmit(){

    this.subscriptionService.searchUserByEmail(this.combinedPTC.email).subscribe(
      data=>{
        this.userExists = data;
      });

    // authorized
    this.customerService.updateCustomer(environment.apiUrl + 'bcrmCustomer/updateCustomerOrLead', this.combinedPTC).then(
            data=>{
        this.activeModal.close();
        this.passEntry.emit(this.combinedPTC);
        this.toaster.success("Customer Updated Successfully");
      });
  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 
  
}