<div class="modal-header">
    <h4 class="modal-title pull-left"> Skill Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
      <div class="card">
         <div class="card-body">

    <form> 
 
      <div style="width: 100%;color: black;text-align: center;" >
         <a style="cursor:pointer;">
            <img style="border-radius: 50%;" [src]="skill.safeUrl" width="75" height="75"
            alt="" loading="lazy">
         </a>
       </div>
       <br>

       <div style="text-align: center;">
         <h3>{{skill.skillName}} </h3> 
         <p>
           {{skill.skillDescription}}
         </p>
       </div>

    </form>

         </div>
      </div>
   </div>