import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FeedbackQuestion } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-question';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ViewFeedbackQuestionComponent } from '../../feedback-question/view-feedback-question/view-feedback-question.component';

@Component({
  selector: 'app-create-feedback-form',
  templateUrl: './create-feedback-form.component.html',
  styleUrls: ['./create-feedback-form.component.scss']
})
export class CreateFeedbackFormComponent implements OnInit {
  clicked = false;
  feedbackForm : FeedbackForm = new FeedbackForm();
  feedbackQuestions : FeedbackQuestion[] = [];
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  addedQuestionIds : string[] = [];
  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private imageService: FileServiceService,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();    
  }

  uploadImage=false;
  selectedFile!: File;
  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
    this.uploadImage = true;
  }

  addFeedbackQuestions(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewFeedbackQuestionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      addedQuestionIds : this.addedQuestionIds
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      
      receivedEntry.forEach((val:any)=>{
        this.feedbackQuestions.push(val);
        this.addedQuestionIds.push(val.id);
      });
    });   
  }

  onSubmit(){

    if(this.feedbackQuestions.length === 0){

      this.toaster.error('Add atleast one question to proceed !');

    }else{

    let oneMandatoryQsnCheck = false;
    this.feedbackQuestions.forEach((qn)=>{
      if(qn.mandatory === true){
        oneMandatoryQsnCheck = true;
      }
    });

    if(oneMandatoryQsnCheck===false){

      this.toaster.error('Make atleast one of the questions mandatory to proceed !');

    }else if(oneMandatoryQsnCheck===true){
      this.clicked = true;

    //authorized
    this.feedbackForm.feedbackQuestions = this.feedbackQuestions;
    
    this.formService.addFeedbackForm(environment.apiUrl + "feedbackForm/createFeedbackForm", this.feedbackForm).then(
      data=>{
        this.feedbackForm = data;
        this.activeModal.close();
        this.toaster.success('Form created successfully');
        this.passEntry.emit('success');

        if(this.uploadImage === true){
          let body = new FormData();
          body.append("file", this.selectedFile);
  
          if(this.uploadImage === true){
          this.imageService.uploadFeedbackFormImage(body, this.feedbackForm.id).subscribe(
            data=>{
            });
          }
        }

      });
    }
  }
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 