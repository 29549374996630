import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { OfficesService } from "src/app/services/office/offices.service";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { AddBotUserComponent } from "src/app/BOT_CODE/bot_modals/bot_user/add-bot-user/add-bot-user.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { SubscriptionService } from "src/app/service/subscription.service";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotUserGroupModalComponent } from "src/app/BOT_CODE/bot_modals/bot_user_group/bot-user-group-modal/bot-user-group-modal.component";
import { TableUtil } from "src/app/table-util";

@Component({
  selector: "app-bot-admins-management",
  templateUrl: "./bot-admins-management.component.html",
  styleUrls: ["./bot-admins-management.component.scss"],
})
export class BotAdminsManagementComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  agentsExists = false;
  adminsExist = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
    fkBotLangId: "",
    multiLanguageSettings: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botCustomerUser: BotCustomerUser = new BotCustomerUser();

  languageCardOverlay = false;

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    private sharedDataService: SharedDataService,
    private subscriptionService: SubscriptionService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private botOfficeService: BotOfficeServiceService,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;

    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "admin/getOfficeByIdForBotOfficeAdmin"
      )
      .then((res) => {
        this.officeObject = res;
      });

    this.userId = this.loggedInUser.userId;
  }

  botUser: BotUser = new BotUser();
  botAdmin: BotUser = new BotUser();

  searchText: any;
  searchText2: any;
  agentsListType = "tabular";

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getBotAgents();
    // this.getBotAdmins();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  tabClick(tab: any) {
    if (tab.index === 1 && this.adminsExist === false) {
      this.getBotAdmins();
    }
  }

  getBotAgents() {
    this.botOfficeService
      // .getBotUsers(environment.apiUrl + "botOffice/getBotAgentsByOffice")
      .getBotUsers(
        environment.apiUrl + "botOffice/getBotAgentsAndAllowedCountByOffice"
      )
      .then((res) => {
        this.botUser = res;
        if (this.botUser.users !== null && this.botUser.users.length > 0) {
          this.agentsExists = true;
        }
        this.showSpinner = false;
      });
  }

  getBotAdmins() {
    this.showSpinner = true;
    this.botOfficeService
      .getBotUsers(environment.apiUrl + "botOffice/getBotAdminsByOffice")
      .then((res) => {
        this.botAdmin = res;
        if (this.botAdmin.users !== null && this.botAdmin.users.length > 0) {
          this.adminsExist = true;
        }
        this.showSpinner = false;
      });
  }

  editBotUser(botUser: any, header: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotUserComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botUser: botUser,
      header: header,
      context: "EDIT_BOT_USER",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: BotUser) => {
      if (header === "Edit Bot Agent") {
        this.botUser.users.forEach((user) => {
          if (user.userId === receivedEntry.userId) {
            user = receivedEntry;
          }
        });
      }
    });
  }

  addBotAgent(roleName: any, header: any) {
    if (this.botUser.users.length >= this.botUser.numberOfAgentsAllowed) {
      this.toaster.error(
        "You have reached the maximum number of agents allowed !"
      );
    } else {
      this.addBotUser(roleName, header);
    }
  }

  addBotUser(roleName: any, header: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotUserComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      roleName: roleName,
      header: header,
      context: "ADD_BOT_USER",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: BotUser) => {
      if (roleName === "CB_AGENT") {
        receivedEntry.activeStatus = true;
        this.botUser.users.push(receivedEntry);
        this.agentsExists = true;
      }
      if (roleName === "CHOOSE_ADMIN") {
        receivedEntry.activeStatus = true;
        this.botAdmin.users.push(receivedEntry);
        this.adminsExist = true;
      }
    });
  }

  agentStatusUpdate(userId: any) {
    this.subscriptionService
      .userStatusUpdate(environment.apiUrl + "user/userStatusUpdate/" + userId)
      .then((data) => {
        this.botUser.users.forEach((value) => {
          if (value.userId === userId) {
            value.activeStatus = !value.activeStatus;
          }
        });
      });
  }

  newPassword: any = null;
  resetPassword(userId: any) {
    if (
      this.newPassword !== undefined &&
      this.newPassword !== null &&
      this.newPassword.length > 5
    ) {
      let botUser: BotUser = new BotUser();
      botUser.password = this.newPassword;
      botUser.userId = userId;

      this.subscriptionService
        .passwordUpdate(
          environment.apiUrl + "user/resetPasswordByUserId",
          botUser
        )
        .then((data) => {
          this.toaster.success("Password updated successfully !");
          this.newPassword = null;
        });
    } else {
      this.toaster.error(
        "Length of the new password must be atleast 6 characters"
      );
      this.newPassword = null;
    }
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  goToAdminsConversationsPage() {
    this.router.navigate(["dashboard/bot-admin/admins/conversations"]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  whatsappNo: any = null;
  updateWhatsappNo(role: any, userId: any) {
    if (this.whatsappNo !== null && this.whatsappNo !== undefined) {
      this.showSpinner = true;
      this.subscriptionService
        .userStatusUpdate(
          environment.apiUrl +
            "botOffice/updateWhatsappNoAndCreateBotAgentAndAdminUsers/" +
            userId +
            "/" +
            this.whatsappNo
        )
        .then((data) => {
          if (data === true) {
            if (role !== null && role === "ag") {
              this.botUser.users.forEach((agent) => {
                if (agent.userId === userId) {
                  agent.whatsappNumber = this.whatsappNo;
                }
              });
            } else {
              this.botAdmin.users.forEach((admin) => {
                if (admin.userId === userId) {
                  admin.whatsappNumber = this.whatsappNo;
                }
              });
            }
            this.toaster.success("Update Successful !");
            this.showSpinner = false;
            this.whatsappNo = null;
          } else if (data === false) {
            this.toaster.error(
              "Customer with Whatsapp number : " +
                this.whatsappNo +
                " already exists !"
            );
            this.showSpinner = false;
            this.whatsappNo = null;
          }
        });
    } else {
      this.toaster.error(" Whatsapp number cannot be empty ");
      this.showSpinner = false;
    }
  }

  filterVar: any = null;
  updateFilterVar(role: any, userId: any) {
    this.showSpinner = true;
    this.subscriptionService
      .userStatusUpdate(
        environment.apiUrl +
          "botOffice/updateFilterVar/" +
          userId +
          "/" +
          this.filterVar
      )
      .then((data) => {
        if (role !== null && role === "ag") {
          this.botUser.users.forEach((agent) => {
            if (agent.userId === userId) {
              agent.filterVar = this.filterVar;
            }
          });
        } else {
          this.botAdmin.users.forEach((admin) => {
            if (admin.userId === userId) {
              admin.filterVar = this.filterVar;
            }
          });
        }
        this.toaster.success("Update Successful !");
        this.showSpinner = false;
        this.filterVar = null;
      });
  }

  assignMultipleUsersToAgent(agentUroId: any): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserGroupModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "assignMultipleUsersToAgent",
      agents: this.botUser.users,
      agentUroToAssign: agentUroId,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotAgents();
    });
  }

  dataSource: BotCustomerUser[] = [];
  exportUsersArray(users: BotCustomerUser[]) {
    this.showSpinner = true;
    if (users !== null && users !== undefined && users.length > 0) {
      this.dataSource = users;
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map((x) => ({
        Name: x.name,
        Phone: x.userIdentifier,
        Alternate_Phone: x.alternatePhone,
        Gender: x.gender,
        Custom_var_1: x.customVar1,
        Custom_var_2: x.customVar2
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "users");
      this.showSpinner = false;
    } else {
      this.toaster.error("No Users to download !");
      this.showSpinner = false;
    }
  }
}
