import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { FeedbackForm } from '../../classes/feedback-form';

@Component({
  selector: 'app-feedback-form-modal',
  templateUrl: './feedback-form-modal.component.html',
  styleUrls: ['./feedback-form-modal.component.scss']
})

export class FeedbackFormModalComponent implements OnInit {
  clicked = false;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
 
  constructor(public activeModal: NgbActiveModal,
              private formService : FeedbackFormService,
              private sharedDataService: SharedDataService,
              private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

            formActiveId : any;
            feedbackForm : FeedbackForm = new FeedbackForm();

  ngOnInit(): void { 
    this.formActiveId = this.fromParent.formActiveId;

    this.getForm(this.formActiveId);

  }

  getForm(formActiveId : any){
    this.formService.getCompleteFeedbackFormByFormActiveId(environment.apiUrl + "feedbackForm/getCompleteFeedbackFormByFormActiveId/" + formActiveId).then(
      res=>{
        this.feedbackForm = res;
        if(this.feedbackForm.expiryStatus === false){

        }else if(this.feedbackForm.expiryStatus === true){
          this.passEntry.emit();
          this.activeModal.close();
          
        }
      });
  }

  onSubmit(){
   
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 