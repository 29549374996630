import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-class-admin-dashboard',
  templateUrl: './class-admin-dashboard.component.html',
  styleUrls: ['./class-admin-dashboard.component.scss']
})
export class ClassAdminDashboardComponent implements OnInit {

  navbarTitle : any;
  currentRole = "CLASS_ADMIN";
  showSpinner : boolean = true;
  classLoaderMessage!: string;
  classExists!: boolean;
  classOptions: any = {};
  loggedInUser: any;

  officeId!: string;
  userName!: string;
  userId!: string;

  public screenWidth: any;
  public screenHeight: any;
  
  constructor(
              private ngbModalService: NgbModal,
              public router: Router, 
              private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    ngOnInit(): void {

      this.navbarTitle = GlobalClass.navbarTitle;
      
          this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
          this.officeId = this.loggedInUser.offices[0].id;
          this.userId = this.loggedInUser.userId;
          this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
          
          this.screenWidth = window.innerWidth;
          this.screenHeight = window.innerHeight;
      
          this.classOptions = {
            pagingType: 'full_numbers',
            pageLength: 5,
            lengthChange: false,
            processing: true,
            info:false
          }; 

          setTimeout(()=>{                           //timer
            this.showSpinner = false;
       }, 700);
        }

    exploreClasses(){
      this.router.navigate(['dashboard/class-dashboard']);
    }

    exploreTeachers(){
      this.router.navigate(['dashboard/class-admin/teachers']);
    }

    exploreStudents(){
      this.router.navigate(['dashboard/class-admin/students']);
    }

    exploreSubscriptions(){
      this.router.navigate(['dashboard/class-admin/subscriptions']);
    }

    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      this.sharedDataService.setfooterColor(globalClass.backgroundStyle(this.loggedInUser.offices[0].theme));
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }

        switchDashboard(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
            {
              size : 'sm',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
          let data = { 
            userId : this.userId,
            currentRole : this.currentRole
          }
      
          modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
          });    
        }
    
        goToAccountPage(){
          this.router.navigate(['user-account',this.userId])
        }
        
      
        getColor(){
            this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
            return this.loggedInUser.offices[0].theme + '!important';
          }
      
          logOut(): void {
            this.config.class = 'modal-md';
            const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
              {
                size : 'md',
                scrollable: true,
                windowClass: 'app-modal-window',
                keyboard: false
              });
            let data = {
             email : this.loggedInUser.email 
            }
        
            modalRef.componentInstance.fromParent = data;
              modalRef.result.then((result) => {
              }, (reason) => {
              });
        
            modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            });  
          }
      
          changePassword(): void {
            this.router.navigate(['/auth/change-password']).then();
          }
              
        reloadPage() {
          window.location.reload();
        }
      
}
