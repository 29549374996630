import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FormRespondent } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-respondent';
import { FormResponse } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-response';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-external-ticket-support-form',
  templateUrl: './external-ticket-support-form.component.html',
  styleUrls: ['./external-ticket-support-form.component.scss']
})
export class ExternalTicketSupportFormComponent implements OnInit {

  ticketRaised = false;
  clicked = false;
  showSpinner : boolean = true;
  ticketSupportDefinitionId : any;
  customerId : any;
  feedbackForm : FeedbackForm = new FeedbackForm();
  formResponses : FormResponse[] = [];
  checkBoxFormResponses : FormResponse[] = [];
  formRespondent : FormRespondent = new FormRespondent();
  formActive = false;
  formInActive = false;
  officeId : any;
  public screenWidth: any;
  public screenHeight: any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  constructor(private route : ActivatedRoute,
    private formService : FeedbackFormService,
    private ngbModalService: NgbModal,
    private sanitizer : DomSanitizer,
              private router : Router,
              private imageService : FileServiceService ) { }

    // PAGE PATH---- feedback/extFeedback/:formActiveId
  ngOnInit(): void {

    this.ticketSupportDefinitionId = this.route.snapshot.params['ticketSupportDefinitionId'];
    this.officeId = this.route.snapshot.params['officeId'];

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getForm(this.ticketSupportDefinitionId);

  }


  safeImageUrl: any; 
  unsafeImageUrl: any;

  getForm(formActiveId : any){
    this.formService.getCompleteFeedbackFormByFormActiveId(environment.apiUrl + "feedbackForm/getTicketSupportFormByTicketSupportDefinitionId/" + formActiveId).then(
      res=>{
        this.feedbackForm = res;   

        this.imageService.getFeedbackFormImage(this.feedbackForm.formImage).subscribe(
          data=>{
            this.safeImageUrl = data;
            this.unsafeImageUrl = URL.createObjectURL(this.safeImageUrl);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
          });

        if(this.feedbackForm.expiryStatus === false){
          this.formActive = true;
          this.feedbackForm.feedbackQuestions.forEach((fq)=>{
            if(fq.answerType === "multiSelectDropdown"){

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = false;
              this.formResponses.push(formResponse);

              formResponse.feedbackAnswerValuesDto.forEach((answerValue)=>{
                let formResponse = new FormResponse();
                formResponse.fkQuestionId = fq.id;
                formResponse.question = fq.question;
                formResponse.questionDescription = fq.questionDescription;
                formResponse.answerType = fq.answerType;
                formResponse.validResponse = false;
                formResponse.fkAnswerValueId = answerValue.id;
                
                this.checkBoxFormResponses.push(formResponse);
              });

            } else if(fq.answerType === "checkbox"){

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = false;
              this.formResponses.push(formResponse);

              formResponse.feedbackAnswerValuesDto.forEach((answerValue)=>{
                let formResponse = new FormResponse();
                formResponse.fkQuestionId = fq.id;
                formResponse.question = fq.question;
                formResponse.questionDescription = fq.questionDescription;
                formResponse.answerType = fq.answerType;
                formResponse.validResponse = false;
                formResponse.fkAnswerValueId = answerValue.id;
                
                this.checkBoxFormResponses.push(formResponse);
              });

            }else if(fq.answerType === "slider"){

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = true;

             let stepsArray :any[] = [];
             formResponse.feedbackAnswerValuesDto.forEach((ansValue)=>{
               let option : any = {value : ansValue.answerValue}
               stepsArray.push(option);
             });

            let options: Options = {
              showTicksValues: true,
              stepsArray: stepsArray
            };

            formResponse.sliderOptions = options;
  
              this.formResponses.push(formResponse);
            }else{

              let formResponse = new FormResponse();
              formResponse.fkQuestionId = fq.id;
              formResponse.question = fq.question;
              formResponse.questionDescription = fq.questionDescription;
              formResponse.answerType = fq.answerType;
              formResponse.feedbackAnswerDto = fq.feedbackAnswerDto;
              formResponse.feedbackAnswerValuesDto = fq.feedbackAnswerValuesDto;
              formResponse.validResponse = true;

              this.formResponses.push(formResponse);
            }

          });
        }else if(this.feedbackForm.expiryStatus === true){
          this.formInActive = true;
        }
      });
  }

  onSubmit(){

    this.formRespondent.fkOfficeId = this.officeId;
    this.formRespondent.fkTicketSupportDefinitionId = this.ticketSupportDefinitionId;
    this.formRespondent.fkCustomerId = this.customerId;

    let validFormResponses : FormResponse[] = [];
    this.formResponses.forEach((formResponse)=>{
      if(formResponse.validResponse === true){
        validFormResponses.push(formResponse);
      }
    });
    this.checkBoxFormResponses.forEach((formResponse)=>{
      if(formResponse.validResponse === true){
        validFormResponses.push(formResponse);
      }
    });
    this.formRespondent.formResponsesDto = validFormResponses;
    
    this.formService.createExternalFeedbackResponse(environment.apiUrl + "ticketSupport/createExternalTicketActual", this.formRespondent).then(
      res=>{
        this.formActive = false;
        this.ticketRaised = true;
      });

  }
// slider
  value: number = 5;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
    ],
  };

  sliderEvent(fkQuestionId : any, value : any){    
    this.formResponses.forEach((formResponse)=>{
      if(formResponse.fkQuestionId === fkQuestionId){
        formResponse.feedbackAnswerValuesDto.forEach((answerValue)=>{
          if(answerValue.answerValue === value){
            formResponse.fkAnswerValueId = answerValue.id;
          }
        })
      }
    });
  }

  handleCheckBoxClick(questionId : any, answerValueId : any){
      this.checkBoxFormResponses.forEach((fr)=>{
        if(fr.fkQuestionId === questionId && fr.fkAnswerValueId === answerValueId){
          fr.validResponse = !fr.validResponse;
        }
      });      
  }

  // multi select dropdown
  toppingsControl = new FormControl([]);
  multiSelectValueChange(value : any){
  }

  multiSelectOptionChange(questionId : any, answerValueId : any){
    this.checkBoxFormResponses.forEach((fr)=>{
      if(fr.fkQuestionId === questionId && fr.fkAnswerValueId === answerValueId){
        fr.validResponse = !fr.validResponse;
      }
    });  
}

}
