import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { UserList } from 'src/app/courseManagementClasses/user-list';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ParentServiceService } from 'src/app/service/parent-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parent-mentor-home',
  templateUrl: './parent-mentor-home.component.html',
  styleUrls: ['./parent-mentor-home.component.scss']
})
export class ParentMentorHomeComponent implements OnInit {

  currentRole = "PARENT_MENTOR";
  showSpinner : boolean = true;
  loggedInUser;
  theme: string = '';

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  public screenWidth: any;
  public screenHeight: any;
  navbarTitle: any;
  userId!: string;

  abc: any;

  data : UserList = new UserList();

  constructor(private sharedDataService: SharedDataService, public router: Router,
    private ngbModalService: NgbModal,
    private parentServiceService : ParentServiceService,
    private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
    public bsModalRef: BsModalRef) {
     
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
 
    this.getParentDashboardDataActivities();

  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getParentDashboardDataActivities(){

    this.parentServiceService.getParentDashboardDataActivities(environment.apiUrl + "parent/getChildrenForParentMentor").then(
      res=>{

        this.data = res;

        if(this.data !== null && this.data.children !== null && this.data.children.length === 1){
          this.router.navigate(['/dashboard/UserParent/child', this.data.children[0].childUserId]);
        }else{
        this.data.children.forEach((child)=>{

          this.imageService.getUserImage(child.childImage).subscribe(
            data=>{
  
              this.abc = URL.createObjectURL(data);
              child.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.abc);
              
            });
            
        });
        this.showSpinner = false;
      }
      });
  }

  exploreChildClasses(childUserId : any){
    this.router.navigate(['/dashboard/UserParent/child', childUserId]);
  }

  goToAccountPage(){
    this.router.navigate(['account',this.userId]);
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });    
  }
}