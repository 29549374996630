import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseOptions } from 'src/app/BOT_CODE/bot_classes/bot-response-options';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';

@Component({
  selector: 'app-response-option-settings',
  templateUrl: './response-option-settings.component.html',
  styleUrls: ['./response-option-settings.component.scss']
})
export class ResponseOptionSettingsComponent implements OnInit {


  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 
  
  clicked = false;
  loggedInUser : any;
  theme : any;

  constructor(private sharedDataService: SharedDataService,private ngbModalService: NgbModal,
    private botResponseService : BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    options : BotResponseOptions[] = [];
    context:any;
    subcontext:any;
    
    sections : any[] = [{sectionName : "",
                         selected : ""}];

                         fkBotResponseId="";


    ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

    if(this.context === "assign_sections_&_make_options_interactive_lists"){

      this.subcontext = "choose_sections";
      
      this.options = this.fromParent.options;
      this.fkBotResponseId  = this.fromParent.botResponseId;

      this.botResponseService.getBotResponseOptionDetails(environment.apiUrl + "botConvo/getExistingSectionsForInteractiveLists").then(
        res=>{

          this.sections = [];

          let existingSections : string[] = [];
          existingSections = res;

          existingSections.forEach((eSec)=>{
            let sec : any = {sectionName : "",
                              selected : ""};

                sec.sectionName = eSec;
                sec.selected = false;
                this.sections.push(sec);
          });
        });
    } 
   }

   newSectionName=null;
   addNewSectionToList() {
    if(this.newSectionName===null){
this.toastr.error("Provide section name !");
    }else{
      let sec : any = {sectionName : "",
      selected : ""};
  
  sec.sectionName = this.newSectionName;
  sec.selected = false;
  
  this.sections.push(sec);
  this.newSectionName = null;
    }
    
   }

   assignSections(){
    let invalidAssignment! : boolean;
    this.options.forEach((option)=>{
      if(option.interactiveListSectionName === null){
        invalidAssignment = true;
      }
    });

    if(invalidAssignment===true){
      this.toastr.error("Assign sections for all options !");
    }else{
      let botResOption : BotResponseOptions = new BotResponseOptions();
      botResOption.interactiveListSectionsExist = true;
      botResOption.childOptions = [];
      botResOption.childOptions = this.options;
      botResOption.fkBotResponseId = this.fkBotResponseId;

      this.botResponseService.assignSections(environment.apiUrl + "botConvo/assignInteractiveListSectionsForResOptions", botResOption).then(
        res=>{

          this.activeModal.close();
          this.toastr.success("Sections successfully assigned");
this.passEntry.emit("success");
        });
    }
   }

   proceedToAssignSections(){

    let selectedSections : any[] = [];
    this.sections.forEach((section)=>{
  if(section.selected===true){
    selectedSections.push(section);
    }
    });

    if(selectedSections.length > 0){
      this.subcontext="assign_sections";
      this.options.forEach((option)=>{
        option.interactiveListSectionName = null;
        option.sections=selectedSections;
      });
    }else{
      this.toastr.error("Choose atleast one section to proceed !")
    }
    
   }

   resetSectionsSelection(){
    this.sections.forEach((section)=>{
      section.selected = false;
      });
      this.options.forEach((option)=>{
        option.interactiveListSectionName = null;
        option.sections=[];
      });
      this.subcontext="choose_sections";
   }

   closeResult!:string;
   open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}