 <!-- MODAL CLOSE BUTTON -->
 <div class="modal-header">
    <h4 class="modal-title pull-left">View Product</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
   <!-- MODAL CLOSE BUTTON -->




   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form >

     

      <div class="form-group row">

        <div class="col-md-4">
        <label class="required" for="productName"> Name</label>
        <input  maxlength="50"  #name="ngModel" required class="form-control"  placeholder="Enter Product Name" type="text" name="productName" id="productName" [(ngModel)]="prod.productName">
        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
    </div>

    <div class="col-md-4">
        <label for="productPrice"> Price</label>
        <input   maxlength="250" class="form-control"  placeholder="Enter Price" type="number" name="productPrice" id="productPrice" [(ngModel)]="prod.productPrice">
    </div>

    <div class="col-md-4">
      <label for="defaultQuantity"> Default Quantity</label>
      <input   maxlength="250" class="form-control"  placeholder="Enter Default Quantity" type="number" name="defaultQuantity" id="defaultQuantity" [(ngModel)]="prod.defaultQuantity">
  </div>

    </div>

        <div>
            <label for="productDescription">Description</label>
            <textarea readonly class="form-control"  placeholder="Enter Description" type="text" name="productDescription" id="productDescription" [(ngModel)]="prod.productDescription"></textarea>
        </div>

    

          

    </form>
</div>
    </div>
   </div>

   <!-- <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary float-right" (click)="closeModal()">Close</button>
 </div> -->

 