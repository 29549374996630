import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  authToken: string | null = '';
  constructor(private http: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  resetUserPassword(avenue: string, email: string, password: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    const params = new HttpParams().set('email', email).set('password', password);
    return new Promise((resolve, reject) => {
      self.http.put(avenue, params,{headers: headers})
        .toPromise()
        .then((data: any) => {
          resolve(data);
        }).catch((err) => {
      });
    });
  }

}
