


   <div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Choose time period to get Orders / Invoices</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 
             
     <div>

        <div class="form-group row">
          <div class="col-md-6">
          <label class="required" for="targetStartDate">Start Date</label>
          <input #date="ngModel" required class="form-control" type="date" name="targetStartDate" 
          id="targetStartDate" [(ngModel)]="target.targetStartDate">
          <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provide Start Date</div>
      </div>
  
      <div class="col-md-6">
          <label class="required" for="targetEndDate">End Date</label>
          <input #date2="ngModel" required class="form-control" [(ngModel)]="target.targetEndDate" type="date" id="targetEndDate"
           name="targetEndDate">
           <div class="alert alert-danger" *ngIf="date2.touched && !date2.valid">Provide End Date</div>

      </div>
      </div>

      <button *ngIf="role === 'BCRM_EXE'" [disabled]="!invoiceForm.valid" type="submit"
       class="btn btn-sm btn-primary float-right" 
       (click)="getInvoicesForExec(); clicked = true;">Search</button>

       <button *ngIf="role === 'BCRM_TM'" [disabled]="!invoiceForm.valid" type="submit"
       class="btn btn-sm btn-primary float-right" 
       (click)="getInvoicesForTM(); clicked = true;">Search</button>

       <button *ngIf="role === 'BCRM_MAN'" [disabled]="!invoiceForm.valid" type="submit"
       class="btn btn-sm btn-primary float-right" 
       (click)="getInvoicesForMan(); clicked = true;">Search</button>

    </div>


      </form>
      </div>
      </div>
      </div>
  
     