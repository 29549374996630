import { BotScheduleTempVariable } from "./bot-schedule-temp-variable";

export class BotSchedule {
  botScheduleId: any;
  executionTimestamp: any;
  timezone: string;
  fkBotTemplateId: any;
  botScheduleSuccessCount: any;
  botScheduleStatus: any;
  fkOfficeId: any;
  botScheduleTitle: any;
  botScheduleDescription: any;
  botScheduleType: any;
  fkFormActiveId: any;
  fkBotMediaIdForTemplateHeader: any;
  headerTextForTemplateHeader: any;
  fkBotTemplateName: any;
  tempBody: any;
  list: BotSchedule[] = [];
  botUserIds: string[] = [];
  botUserGroupIds: string[] = [];
  botScheduleTempVariables: BotScheduleTempVariable[] = [];
  tempVarList: BotScheduleTempVariable[] = [];
  receiverBotUserId: any;
  botScheduleCompletionStatus: any;

  constructor() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
