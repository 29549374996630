
<div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

<div class="content" *ngIf="formActive === true">

  <form #feedbackForm0="ngForm"> 

    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
  
        
        <mat-card  class="example-card mat-elevation-z12">
       
          <div class="col-md-12 col-xs-12 col-sm-12 row" style="margin: 0%;">

            <div class="col-md-2 col-sm-2 col-xs-2">
              <div class="sub-image-div">
                <img class="sub-image" [src]="safeImageUrl" style="border-radius: 50%;padding: 5%;"
                 height="100" width="100" alt="...">
                </div>
    
            </div>
            <div class="col-md-10 col-sm-10 col-xs-10">
              <br>
              <h2 style="color: black;text-align: left;font-weight: bold;">{{feedbackForm.name}}</h2>
              <h4 style="color: black;text-align: left;font-style: italic;">{{feedbackForm.type}}</h4>
  
            </div>
          </div>
      
        
          
      </mat-card>
      
            
          </div>
     
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let response of formResponses">
  
        
        <mat-card  class="example-card mat-elevation-z12">
       
          <h4 *ngIf="response.mandatory === true" class="required" style="color: black;">{{response.question}}</h4>
          <h4 *ngIf="response.mandatory === false" style="color: black;">{{response.question}}</h4>
 
                <mat-form-field *ngIf="response.answerType === 'text' && response.mandatory === false" [style.width.%]=100 class="example-full-width" appearance="fill">
                <mat-label>Message</mat-label>
                <input [(ngModel)]="response.answerValue" matInput [name]="response.fkQuestionId"
                 maxlength="256" [placeholder]="response.questionDescription">
                </mat-form-field>
               <mat-form-field *ngIf="response.answerType === 'text' && response.mandatory === true" [style.width.%]=100 class="example-full-width" appearance="fill">
                <mat-label>Message</mat-label>
                <input [(ngModel)]="response.answerValue" required
                 matInput [name]="response.fkQuestionId"
                 maxlength="256" [placeholder]="response.questionDescription">
                </mat-form-field>

                <mat-radio-group *ngIf="response.answerType === 'radio' && response.mandatory === false" 
                aria-labelledby="example-radio-group-label" class="example-radio-group" 
                [(ngModel)]="response.fkAnswerValueId" [name]="response.fkQuestionId">
                <mat-radio-button class="example-radio-button" *ngFor="let answerValue of response.feedbackAnswerValuesDto"
                [value]="answerValue.id"> {{answerValue.answerValue}}</mat-radio-button>
              </mat-radio-group>

              <mat-radio-group *ngIf="response.answerType === 'radio' && response.mandatory === true" 
              aria-labelledby="example-radio-group-label" class="example-radio-group" required
              [(ngModel)]="response.fkAnswerValueId" [name]="response.fkQuestionId">
              <mat-radio-button class="example-radio-button" *ngFor="let answerValue of response.feedbackAnswerValuesDto"
                [value]="answerValue.id"> {{answerValue.answerValue}}</mat-radio-button>
              </mat-radio-group>

<div style="width: 50%;">
  <ngx-slider id="response.fkQuestionId" *ngIf="response.answerType === 'slider'" 
  [(value)]="response.sliderValue" 
  [options]="response.sliderOptions"
  (mouseup)="sliderEvent(response.fkQuestionId, response.sliderValue)"></ngx-slider>
</div>

<div  *ngIf="response.answerType === 'checkbox'">
<div class="form-check" *ngFor="let cb of response.feedbackAnswerValuesDto">
  <input (click)="handleCheckBoxClick(response.fkQuestionId, cb.id);" class="form-check-input"
   type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label" for="flexCheckDefault">
    <a style="color: black;">
    {{cb.answerValue}} 
  </a> 
  </label>
  

</div>
</div>

                  <div *ngIf="response.answerType === 'singleSelectDropdown' && response.mandatory === false">
                    <mat-form-field appearance="fill">
                      <mat-label>Choose an option</mat-label>
                      <mat-select disableRipple [(ngModel)]="response.fkAnswerValueId" [name]="response.fkQuestionId">
                        <mat-option *ngFor="let cb of response.feedbackAnswerValuesDto" [value]="cb.id"> {{cb.answerValue}} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="response.answerType === 'singleSelectDropdown' && response.mandatory === true">
                    <mat-form-field appearance="fill">
                      <mat-label>Choose an option</mat-label>
                      <mat-select disableRipple [(ngModel)]="response.fkAnswerValueId" [name]="response.fkQuestionId" required >
                        <mat-option *ngFor="let cb of response.feedbackAnswerValuesDto" [value]="cb.id"> {{cb.answerValue}} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>


<div *ngIf="response.answerType === 'multiSelectDropdown' && response.mandatory === false">
  <mat-form-field [style.width.%]=50 style="color: white; margin-right: 2%; border-top: 0;">
    <mat-label>Choose Options</mat-label>
    <mat-select [formControl]="toppingsControl"
     (selectionChange)="multiSelectValueChange($event.value);" multiple>
      <mat-option (click)="multiSelectOptionChange(response.fkQuestionId, cb.id);" *ngFor="let cb of response.feedbackAnswerValuesDto" [value]="cb.id">{{cb.answerValue}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="response.answerType === 'multiSelectDropdown' && response.mandatory === true">
  <mat-form-field [style.width.%]=50 style="color: white; margin-right: 2%; border-top: 0;">
    <mat-label>Choose Options</mat-label>
    <mat-select [formControl]="toppingsControl" required
    (selectionChange)="multiSelectValueChange($event.value);" multiple>
      <mat-option (click)="multiSelectOptionChange(response.fkQuestionId, cb.id);" *ngFor="let cb of response.feedbackAnswerValuesDto" [value]="cb.id">{{cb.answerValue}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="response.answerType === 'timestamp'" >
  <div style="width: 50%;">
    <!-- <label class="required"  for="shippedDate">Choose </label> -->
    <input class="form-control" type="datetime-local" name="timestamp" id="timestamp" [(ngModel)]="response.answerValueTimestamp">
</div>
</div>
     </mat-card>
        
          </div>
     
    </div>
    <button [disabled]="clicked" [disabled]="!feedbackForm0.valid" type="submit"
     class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
 
  </form>
</div>
<div class="content" *ngIf="formInActive === true">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
  
        
        <mat-card  class="example-card mat-elevation-z12">
       
         
            <h4 style="color: black;">{{feedbackForm.formActiveResponseMessage}}</h4>

          
      </mat-card>
      
           
          </div>

    </div>
</div>
<div class="content2" *ngIf="response === true">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">

      
      <mat-card  class="example-card mat-elevation-z12" style="margin :10%">
     
       
          <h4 style="color: black;">Your response submitted successfully, Thank you !</h4>

          <button class="btn btn-info" style="width: auto;" (click)="reloadPage();" >Submit another response</button>
        
    </mat-card>
    
         
        </div>
  </div>
</div>

</div>
<br>
<br>