import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-activities',
  templateUrl: './view-activities.component.html',
  styleUrls: ['./view-activities.component.scss']
})
export class ViewActivitiesComponent implements OnInit {

  


  constructor() { }

 
  ngOnInit(): void {
  
  }
 


}
