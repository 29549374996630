import { BotConditionalResponse } from "./bot-conditional-response";
import { BotResponse } from "./bot-response";
import { BotTreeDefinition } from "./bot-tree-definition";

export class BotResponseOptions {
  botResponseOptionsId: any;
  botResponseOptionsStatus: any;
  responseOption: any;
  responseOptionNumber: any;
  fkBotResponseId: any;
  nextBotResponseId: any;
  parentOptionId: any;
  link: any;
  childOptionsExist: any;
  interactiveListSectionName: any;
  successfulApiCall: any;
  interactiveListSectionsExist: any;
  selected: any;
  botCondResponses: BotConditionalResponse[] = [];
  fkCallbackTreeDefinitionId: any;
  childOptions: BotResponseOptions[] = [];
  list: BotResponseOptions[] = [];
  nextBotResponseResponse: any;
  fkDefaultConvoFlowBotTreeDefinitionId: any;
  fkBotUserGrpIdToAdd: any;
  fkBotUserGrpNameToAdd: any;
  fkDefaultConvoFlowBotTreeDef: BotTreeDefinition = new BotTreeDefinition();
  languageVariationOverlay = false;
  nextResponseOrOptionsOverlay = false;
  callbackNotificationFunction: any;
  sections: any[] = [];
  responseOptionDescription: any;
  callbackResponse: BotResponse = new BotResponse();
  fkCallbackTreeDefDefinition: any;
  optionOrNumberExists: any;
  linkToLastUserErrorResponse: any;
  initiateCallbackNotifications: any;
  mainUrl: any;
  dynamicUrl: any;
  optionType: any;
  nextConditionalResponsesExist: any;
  fkApiConfigIdForCondRes: any;
  fkApiConfigIdForCondResTitle: any;

  fkBotTemplateId: any;
  optInStatusUpdateVar: any;
  callbackNotifFunctionAgentFilter: any;
}
