import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OfficesService } from 'src/app/services/office/offices.service';
import { environment } from 'src/environments/environment';
import { RolesServiceService } from '../../services/role/roles-service.service';
// import { ProductService } from '../../services/product/product.service';
import { SharedDataService } from '../../services/sharedData.service';
import { Roles } from 'src/app/models/role';
import { SuperAdminUserService } from 'src/app/services/user/super-admin-user.service';
import { User } from 'src/app/models/user';
import {MODAL_HEADERS} from '../../utils/constants/app-constants';
import { ProductService } from 'src/app/service/product.service';
import { Product } from 'src/app/crmClasses/product';


@Component({
  selector: 'app-add-user-subscription',
  templateUrl: './add-user-subscription.component.html',
  styleUrls: ['./add-user-subscription.component.scss']
})
export class AddUserSubscriptionComponent implements OnInit {
  clicked=false
 users: any;
  roles = new Roles();
  userOfficeId: any;
  products: Product[] = [];
  officeAdminState: any;
  sourceObject: any;
  userSubObject: any;
  public event: EventEmitter<any> = new EventEmitter();
  headetText = MODAL_HEADERS.ADD_USER_SUBSCRIPTION; // 'Add Subscription';

  addUserSubscriptionForm = this.formBuilder.group({
    userId: new FormControl(null),
    roleId: new FormControl(null, Validators.required),
    officeId: new FormControl(null, Validators.required),
    productId: new FormControl(null),
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    subscriptionId: new FormControl(null)
  });

  constructor(private formBuilder: FormBuilder, public bsModalRef: BsModalRef, private productService: ProductService,
    private sharedDataService: SharedDataService, private roleService: RolesServiceService,
    private superAdminUserService: SuperAdminUserService) {

      const loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());

		const userOfficeId = loggedInUser.offices[0].id;
			this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/users/' + userOfficeId).then(usersRes => {
        this.users = usersRes;
			});

    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/roles').then(rolesRes => {
      this.roles.roles = rolesRes;
      this.roles.roles = this.roles.roles.filter(role => role.activeStatus && !role?.roleName?.includes('ADMIN'));
    });

    this.userOfficeId = loggedInUser.offices[0].id;
    // this.productService.getProductsByOffice(environment.apiUrl + 'product/get/' + this.userOfficeId + '/products').then(productsRes => {
    //   this.products = productsRes;
    // });
    this.productService.getProductListByOfficeId(this.userOfficeId).subscribe(data=>{
      this.products = data;
    })
  } 
  

  ngOnInit() {
    this.addUserSubscriptionForm = this.formBuilder.group({
      userId: (this.userSubObject) ? this.userSubObject.userId : null,
      roleId: (this.userSubObject) ? this.userSubObject.roleId : null,
      officeId: (this.userSubObject) ? this.userSubObject.officeId : null,
      productId: (this.userSubObject) ? this.userSubObject.productId : null,
      startDate: (this.userSubObject) ? this.userSubObject.startDate : null,
      endDate: (this.userSubObject) ? this.userSubObject.endDate : null,
      subscriptionId: (this.userSubObject) ? this.userSubObject.subscriptionId : null
    });

    if (this.userSubObject?.productId) {
      this.headetText = MODAL_HEADERS.EDIT_USER_SUBSCRIPTION; // 'Edit Subscription';
    }
  }

  saveToList(form: any): void {
    if (form.value) {
      this.triggerEvent(form.value);
      this.bsModalRef.hide();
    }
  }

  triggerEvent(item: any): void {
    this.event.emit({data: item, res: 200});
  }

}
