import { UserDetails } from "src/app/models/user-details";

export class AddCombinedParentTeacher {

	// parent info
    teacherId!: string;
	firstName!: string;
	lastName!: string;
	password!: string;
	phoneNumber!: string;
	email!: string;
	activeStatus!: boolean;
	passwordStatus!: boolean;
	dateOfBirth!: any;
	gender!: string;
	lock!: boolean;
	userImage : any;
	childPetname:any
	petname : any;
	username : any;

	customerId : any;

	countryDialCode : any;

	userDetails : UserDetails[] = [];

    addressId!: string;
	addressLine1!: string;
	addressLine2!: string;
	city!: string;
	country!: string;
	state!: string;
    zipCode!: string;
    fkUserId!: string;

	// student/child info
	studentId!: string;
	studentFirstName!: string;
	studentLastName!: string;
	studentDateOfBirth!: string;
	studentGender!: string;

	// office info
	fkOfficeId!: string;

	// product info
	fkProductId!: string;

	// subscription info
	startedDate!: string;
	endedDate!: string;

	// role
	roleName!: string;
	userId!: string;

	parentDetails : any;

	parentEmail : any = null;
	parentFirstName : any = null;
	parentLastName : any = null;

	studentUserId : any;
	parentId : any;
	parentUserId : any;

	existingStudents: string[] = []
	existingParents: string[] = []
	createdStudents: string[] = []
}
