<div class="modal-header">
    <h4 class="modal-title pull-left">Create Settings</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

  
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="add()" #aplnrForm="ngForm"> 

      
      <div class="form-group row">
       
        <div class="col-md-12">
          <label for="officeId">Office</label> <br>
              <select class="form-control" [(ngModel)]="setting.officeId" id="officeId" name="officeId">
                <option  value="" disabled >Choose Office</option>
                <option  *ngFor="let off of roles.offices"  [ngValue]="off.id">{{off.officeName}}</option>
              </select>
          </div>

        
      </div>
     

<br>

           <div class="form-group row">
 
       

            <div class="col-md-4">
                <label class="required" for="key"> Key</label>
                <input #key="ngModel" required placeholder="Enter key" required class="form-control" type="text" name="key"  id="key" [(ngModel)]="setting.key">
                <div class="alert alert-danger" *ngIf="key.touched && !key.valid">key is required</div>
             </div> 
             
             <div class="col-md-4">
                <label class="required" for="value"> Value</label>
                <input  #value="ngModel" required placeholder="Enter value" required class="form-control" type="text" name="value"  id="value" [(ngModel)]="setting.value">
                <div class="alert alert-danger" *ngIf="value.touched && !value.valid">value is required</div>
             </div>

             <div class="col-md-4">
              <label class="required" for="value">Date Value</label>
              <input  #value="ngModel" required placeholder="Enter Date" required class="form-control" type="date" name="dateValue"  id="dateValue" [(ngModel)]="setting.dateValue">
              <div class="alert alert-danger" *ngIf="value.touched && !value.valid">value is required</div>
           </div>
 

   
      </div>

     
     






    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="add(); clicked=true">Save</button>
  </div>