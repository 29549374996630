
 <div [ngStyle]="backgroundStyle" class="modal-header">
  <h4 style="color: white;" class="modal-title pull-left">Activity Timeline</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> 



 <div class="modal-body" style="overflow-x: hidden;">
 

  <h3 *ngIf="activitiesDontExist === true">No Timeline to display !</h3>

<ul class="timeline" style="overflow-x : hidden" *ngIf="showTimeline === true">
  <li *ngFor="let act of allActivitiesList">
    <div class="direction-l">
      <div class="flag-wrapper">
        <span *ngIf="act.activityPlannerCompletionStatus === false" class="hexa"></span>
        <span *ngIf="act.activityPlannerCompletionStatus === true" class="hexC"></span>
        <span class="flag">
           <a><img style=" border-radius: 50%;" height="25" width="25" [src]="act.safeUrl" alt="activity image">{{act.activityPlannerName}}</a>
        </span>
        
      </div>
      <div class="desc">{{act.activityPlannerDescription}}</div>
    </div>
  </li>

 

 
</ul>


</div>
