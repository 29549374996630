import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExtShopServiceService {
  constructor(private httpClient: HttpClient) {}

  url1 =
    environment.apiUrl + "botOfficeShopExt/getBotCategoriesAndItemsForShop";

  getBotCategoriesAndItemsForShop(botInvoiceId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url1}/${botInvoiceId}`);
  }

  placeOrder(obj: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiUrl + "botOfficeShopExt/placeOrder",
      obj
    );
  }

  updateOrder(obj: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiUrl + "botOfficeShopExt/updateOrder",
      obj
    );
  }
}
