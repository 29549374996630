import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalClass } from 'src/app/global/global-class';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { ActivatedRoute } from '@angular/router';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';

@Component({
  selector: 'app-child-class-dashboard',
  templateUrl: './child-class-dashboard.component.html',
  styleUrls: ['./child-class-dashboard.component.scss']
})
export class ChildClassDashboardComponent implements OnInit {

  studentUserId : any;
  cardsExist = false;
  loggedInUser : any;
  showSpinner : boolean = true;
  navbarTitle : any;
  userName: string = 'User';
  theme: string = '';
  userId!: string;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  public screenWidth: any;
  public screenHeight: any;

  classCards : any[] = [{
                          classDescription: '',
                          classId: '',
                          className: '',
                          courseName: '',
                          fkOfficeId: '',
                          fkProductIdOfClass:  '',
                          studentId:  '',
                          userRoleOfficeId:  ''
                       }]

  constructor(private sharedDataService: SharedDataService, 
    private ngbModalService: NgbModal,
    private route : ActivatedRoute,
    public router: Router,
    private studentService : StudentService,
    public bsModalRef: BsModalRef) {
   
  this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
  this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
  this.theme = this.sharedDataService.getCardOfficeTheme();
  this.userId = this.loggedInUser.userId;

}
  ngOnInit(): void {

    this.studentUserId = this.route.snapshot.params['studentUserId'];
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.userId = this.loggedInUser.userId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;

    this.studentService.getStudentClassesByUserId(this.studentUserId).subscribe(
      data=>{
        this.classCards = data;
        this.cardsExist = true;
        setTimeout(()=>{                           //timer
          this.showSpinner = false;
      },1000);
      });

  }

  explore(productId : any, studentId : any, classId : any){
    this.router.navigate(['dashboard/parent/subjects',productId, studentId, classId]);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
 
  getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

    reloadPage() {
      window.location.reload();
    }

    currentRole = "PARENT";
    switchDashboard(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
        {
          size : 'sm',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        userId : this.userId,
        currentRole : this.currentRole
      }
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      });    
    }
}
 