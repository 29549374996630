 <div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left"> Activity Planner</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form > 


         <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img style="border-radius: 50%;" [src]="safeImageUrl" width="100" height="100"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
          

          <div style="text-align: center;margin-bottom: 3%;">
            <h3 style="margin: 0%;" class="card-title">{{activityPlanner.activityPlannerName}}</h3>
            <h4>{{activityPlanner.activityPlannerDescription}}</h4>
         </div>
        
         <h3 style="text-align: center;font-weight: bold;">Videos :</h3>
             

         
          <div *ngFor="let activityVideoLink of activityPlanner.activityVideoLinkList, let i = index" [style.display]="videoDivState ? 'block' : 'none'">
            <h3 style="text-align: left;font-weight: bold;"> {{i+1}}) {{activityVideoLink.actVideoLinkName}}</h3>
            
         <div class="container">
            <div class="embed-responsive embed-responsive-16by9">
               <iframe class="embed-responsive-item" [src]="activityVideoLink.urlSafe" allowfullscreen></iframe>
             </div> 
         </div>
         <br>
              
         <a>
           {{activityVideoLink.actVideoLinkDescription}}
         </a>
      <br>
      <br>
          
              </div>
               
       
          
     

         

    </form>
      </div>
    </div>
   </div>
 