import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotTemplate } from "src/app/BOT_CODE/bot_classes/bot-template";
import { AddBotResponseComponent } from "src/app/BOT_CODE/bot_modals/bot_response/add-bot-response/add-bot-response.component";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { AddBotResponseOptionsComponent } from "src/app/BOT_CODE/bot_modals/bot_response_options/add-bot-response-options/add-bot-response-options.component";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { CreateBotTemplateModalComponent } from "src/app/BOT_CODE/bot_modals/bot_template_modals/create-bot-template-modal/create-bot-template-modal.component";

@Component({
  selector: "app-bot-templates-management",
  templateUrl: "./bot-templates-management.component.html",
  styleUrls: ["./bot-templates-management.component.scss"],
})
export class BotTemplatesManagementComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;

  loggedInUser;
  schedulesExists = false;
  mediaExists = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botTemplates: BotTemplate[] = [];
  deactivatedBotTemplates: BotTemplate[] = [];

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private botOfficeService: BotOfficeServiceService,
    private botResponseService: BotResponseServiceService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userId = this.loggedInUser.userId;
  }

  searchText1: any;
  searchText2: any;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getWhatsappCloudApiTemplatesByOfficeId();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 50000,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
      searchable: false,
      sortable: false,
      orderable: false,
    };
  }

  getWhatsappCloudApiTemplatesByOfficeId() {
    this.botOfficeService
      .getWhatsappCloudApiTemplatesByOfficeId(
        environment.apiUrl + "botOffice/getWhatsappCloudApiTemplatesByOfficeId"
      )
      .then((res) => {
        let bTemp: BotTemplate = new BotTemplate();
        bTemp = res;

        bTemp.list.forEach((tem) => {
          if (tem.botTemplateStatus === true) {
            this.botTemplates.push(tem);
          } else if (tem.botTemplateStatus === false) {
            this.deactivatedBotTemplates.push(tem);
          }
        });

        // this.botTemplates = bTemp.list;
        this.schedulesExists = true;
        this.showSpinner = false;
      });
  }

  createBotTemplate() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotTemplateModalComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      context: "Create Template",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotTemplate) => {
        this.botTemplates.push(receivedEntry);
      }
    );
  }

  updateWhatsappTemplateStatusAndCategory(botTempl: BotTemplate) {
    this.showSpinner = true;
    if (
      botTempl.whatsappMsgTemplateId !== null &&
      botTempl.whatsappMsgTemplateId !== undefined
    ) {
      this.botOfficeService
        .getWhatsappCloudApiTemplatesByOfficeId(
          environment.apiUrl +
            "botOffice/updateWhatsappTemplateStatusAndCategory/" +
            botTempl.botTemplateId
        )
        .then((res) => {
          let bTemp: BotTemplate = new BotTemplate();
          bTemp = res;
          this.botTemplates.forEach((templ) => {
            if (templ.botTemplateId === botTempl.botTemplateId) {
              templ.whatsappMsgTemplateStatus = bTemp.whatsappMsgTemplateStatus;
              templ.tempCategory = bTemp.tempCategory;
              this.showSpinner = false;
            }
          });
        });
    } else {
      this.toaster.error("TEMPLATE ID is missing !");
      this.showSpinner = false;
    }
  }

  updateBotTemplateStatus(botTempl: BotTemplate, currentList: any) {
    this.botOfficeService
      .getWhatsappCloudApiTemplatesByOfficeId(
        environment.apiUrl +
          "botOffice/updateBotTemplateStatus/" +
          botTempl.botTemplateId
      )
      .then((res) => {
        if (currentList === "actives") {
          botTempl.botTemplateStatus = false;
          this.deactivatedBotTemplates.push(botTempl);
          this.botTemplates = this.botTemplates.filter(
            (i) => i.botTemplateId !== botTempl.botTemplateId
          );
        } else if (currentList === "inactives") {
          botTempl.botTemplateStatus = true;
          this.botTemplates.push(botTempl);
          this.deactivatedBotTemplates = this.deactivatedBotTemplates.filter(
            (i) => i.botTemplateId !== botTempl.botTemplateId
          );
        }
      });
  }

  editBotTemplate(botTempl: BotTemplate) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotTemplateModalComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      context: "Edit Template",
      botTemplate: botTempl,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotTemplate) => {
        this.botTemplates.forEach((templ) => {
          if (templ.botTemplateId === receivedEntry.botTemplateId) {
            templ = receivedEntry;
          }
        });
      }
    );
  }

  botResOptionDeactivation(templateId: any, resOptionsId: any) {
    this.botResponseService
      .botResOptionStatusChange(
        environment.apiUrl + "botConvo/botResOptionStatusChange/" + resOptionsId
      )
      .then((res) => {
        this.toaster.success("Status changed successfully");
        this.botTemplates.forEach((resp) => {
          if (
            resp.options !== null &&
            resp.options !== undefined &&
            resp.botTemplateId === templateId
          ) {
            resp.options.forEach((optn) => {
              if (optn.botResponseOptionsId === resOptionsId) {
                optn.botResponseOptionsStatus = !optn.botResponseOptionsStatus;
              }
            });
          }
        });
      });
  }

  addOptionToTemplateResponse(tempId: any, botResponseId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addOptionToTemplateResponse",
      botResponseId: botResponseId,
      fkBotTemplateId: tempId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.botTemplates.forEach((resp) => {
          if (resp.botTemplateId === tempId) {
            resp.fkBotTemplateResId = receivedEntry.fkBotResponseId;
            if (resp.options === null && resp.options === undefined) {
              resp.options = [];
            }
            resp.options.push(receivedEntry);
          }
        });
      }
    );
  }

  nextResponseForTemplateResOption: BotResponse = new BotResponse();
  getNextResponseForTemplateResOption(option: BotResponseOptions) {
    if (option.linkToLastUserErrorResponse === true) {
      this.toaster.success(
        "This option is connected to User's Previous Error Response !"
      );
    } else if (option.nextBotResponseId === null) {
      this.toaster.error("Link next response to proceed !");
      this.showSpinner = false;
    } else {
      this.botResponseService
        .getNextResponse(
          environment.apiUrl + "botConvo/office/getNextResponseForOption",
          option
        )
        .then((res) => {
          this.nextResponseForTemplateResOption = new BotResponse();
          this.nextResponseForTemplateResOption = res;
          if (
            this.nextResponseForTemplateResOption.type ===
            "UpdateNewUserDetails"
          ) {
            this.toaster.success(
              "This option is connected to User Registration Questions !"
            );
            this.showSpinner = false;
          }
        });
    }
  }

  unlinkNextResponseForTemplateResOption(templateId: any, optionId: any) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl + "botConvo/unlinkNextResponseForOption/" + optionId
      )
      .then((res) => {
        this.botTemplates.forEach((res) => {
          if (
            res.options !== null &&
            res.options !== undefined &&
            res.options.length > 0 &&
            res.botTemplateId === templateId
          ) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === optionId) {
                optn.nextBotResponseId = null;
              }
            });
          }
        });
        this.toaster.success("Next response unlinked successfully");
      });
  }

  linkNextResponseToTemplateResOption(templateId: any, optionId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkNextResponseToOption",
      botResponseOptionsId: optionId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botTemplates.forEach((res) => {
          if (
            res.options !== null &&
            res.options !== undefined &&
            res.options.length > 0 &&
            res.botTemplateId === templateId
          ) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === optionId) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  unlinkFkBotTemplateResToBotTemplate(templateId: any) {
    this.botResponseService
      .setUp(
        environment.apiUrl +
          "botConvo/unlinkFkBotTemplateResToBotTemplate/" +
          templateId
      )
      .then((res) => {
        this.botTemplates.forEach((template) => {
          if (template.botTemplateId === templateId) {
            template.fkBotTemplateRes = new BotResponse();
            template.options = [];
            template.fkBotTemplateResId = null;
            template.tryZotiTemplateLinkedResponse = false;
            template.updateUserSessionCurrentQueryId = false;
            this.toaster.success("Template Response unlinked successfully !");
          }
        });
      });
  }

  tryZotiTemplateLinkedResponseStatusUpdate(templateId: any) {
    this.botResponseService
      .setUp(
        environment.apiUrl +
          "botConvo/tryZotiTemplateLinkedResponseStatusUpdate/" +
          templateId
      )
      .then((res) => {
        this.botTemplates.forEach((template) => {
          if (template.botTemplateId === templateId) {
            template.tryZotiTemplateLinkedResponse =
              !template.tryZotiTemplateLinkedResponse;
            this.toaster.success("Template Response unlinked successfully !");
          }
        });
      });
  }

  templateUserSessionCurrentQueryIdStatusUpdate(templateId: any) {
    this.botResponseService
      .setUp(
        environment.apiUrl +
          "botConvo/templateUserSessionCurrentQueryIdStatusUpdate/" +
          templateId
      )
      .then((res) => {
        this.botTemplates.forEach((template) => {
          if (template.botTemplateId === templateId) {
            template.updateUserSessionCurrentQueryId =
              !template.updateUserSessionCurrentQueryId;
            this.toaster.success("Template Response unlinked successfully !");
          }
        });
      });
  }

  linkFkBotTemplateResToBotTemplate(templateId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkFkBotTemplateResToBotTemplate",
      templateId: templateId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botTemplates.forEach((template) => {
          if (template.botTemplateId === templateId) {
            template.fkBotTemplateRes = receivedEntry;
            template.fkBotTemplateResId = receivedEntry.botResponseId;
          }
        });
      }
    );
  }

  editTemplateButton(templateId: any, botResponseOption0: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editTemplateButton",
      botResponseOption: botResponseOption0,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.botTemplates.forEach((templ) => {
          if (templ.botTemplateId === templateId) {
            templ.options.forEach((tOption) => {
              if (
                tOption.botResponseOptionsId ===
                botResponseOption0.botResponseOptionsId
              ) {
                tOption.responseOption = receivedEntry.responseOption;
                tOption.optInStatusUpdateVar =
                  receivedEntry.optInStatusUpdateVar;
              }
            });
          }
        });
      }
    );
  }
}
