import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Shipment } from 'src/app/crmClasses/shipment';
import { GlobalClass } from 'src/app/global/global-class';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-shipment',
  templateUrl: './update-shipment.component.html',
  styleUrls: ['./update-shipment.component.scss']
})
export class UpdateShipmentComponent implements OnInit {

  shipment : Shipment = new Shipment();
  clicked = false;
  shipmentId!: string; 

  @Input() fromParent: any; 
  loggedInUser : any;
  theme : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private router : Router,private datePipe: DatePipe,
              private shipmentService : ShipmentService,
              private sharedDataService: SharedDataService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.shipmentId = this.fromParent.shipmentId;

    this.shipmentService.getShipmentById(this.shipmentId).subscribe(data=>{
      this.shipment = data;

      if(this.shipment.shippedDateTime !== null && this.shipment.shippedDateTime !== undefined){
      let sd = this.shipment.shippedDateTime;
      let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
      this.shipment.shippedDateTime = startDate;   
      this.shipment.shippedDateTime = this.datePipe.transform(startDate, 'yyyy-MM-ddThh:mm');   
      }


      if(this.shipment.expectedDeliveryDateTime !== null && this.shipment.expectedDeliveryDateTime !== undefined){
      let ed = this.shipment.expectedDeliveryDateTime;
      let expectedDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);
      this.shipment.expectedDeliveryDateTime = expectedDate;
      this.shipment.expectedDeliveryDateTime = this.datePipe.transform(expectedDate, 'yyyy-MM-ddThh:mm');
      }

      if(this.shipment.deliveryDateTime !== null && this.shipment.deliveryDateTime !== undefined){
      let dd = this.shipment.deliveryDateTime;
     let deliveryDate =  new Date(dd[0], dd[1] - 1, dd[2], dd[3], dd[4]);
     this.shipment.deliveryDateTime = deliveryDate;           
     this.shipment.deliveryDateTime = this.datePipe.transform(deliveryDate, 'yyyy-MM-ddThh:mm');    
      }
    }, error => console.log(error));
  } 

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onEdit(){
    
    this.shipmentService.updateShipment(environment.apiUrl + "shipment/update", this.shipment).then(
      data =>{
        this.passEntry.emit(this.shipment);
        this.activeModal.close();
        this.toaster.success("Shipment Updated Successfully");
      }
      ,error => console.error()
    );
  }
  
  goToShipmentList(){
    this.router.navigate(['/listShipment']);
  }

  closeModal(){
    this.activeModal.close();
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

}

 