import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-start-assignment-modal',
  templateUrl: './start-assignment-modal.component.html',
  styleUrls: ['./start-assignment-modal.component.scss']
})
export class StartAssignmentModalComponent implements OnInit {

  clicked = false;
  activityPlannerId : any;
  teacherId : any;
  assignmentName : any;

  teacherPlanner : any ={
                          teacherPlannerId : '',
                          fkActivityPlannerId : '',
                          fkTeacherId: '',
                          show: '',
                          teacherPlannerStatus: '',
                          startDateTime: '',
                          endDateTime: '',
                          assignmentActiveStatus: '',
                          startDateTimeNew : '',
                          endDateTimeNew : ''
                        }

   constructor(public activeModal: NgbActiveModal,
                private toaster: ToastrService,
                private activityPlannerService : ActivityPlannerService ,
                private sharedDataService : SharedDataService
                ) { }
                loggedInUser: any;
                theme: any;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.teacherId = this.fromParent.teacherId;
    this.assignmentName = this.fromParent.assignmentName;

    this.teacherPlanner.fkTeacherId = this.teacherId;
    this.teacherPlanner.fkActivityPlannerId = this.activityPlannerId;

  
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  onSubmit(){
 
this.activityPlannerService.startAssignment(environment.apiUrl + "activityPlanner/startAssignment", this.teacherPlanner).then(
  data=>{
    this.passEntry.emit('successful');
    this.activeModal.close();
    this.toaster.success('Assignment started successfully');
  });
}
  
    closeUpdateModal(){
      this.activeModal.close();
    } 

}
