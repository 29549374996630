<div class="modal-header" style=" max-height: 20% !important;" [ngStyle]="backgroundStyle">
    <h2 style="color: white;" class="modal-title pull-left">{{combinedPTC.firstName}} {{combinedPTC.lastName}}</h2>
   
    <div class="row pull-right">

      <button style=" color: black;background-color: white; ;" class="btn btn-info" (click)="editUser();">Edit Profile</button> 

    <button type="button" class="close" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button> 


  </div>

   
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body" style="padding: 0.25em;">

      
       
       <div class="content" style="margin: 0%;padding: 0%;" [style.display]="profile ? 'block' : 'none'">

        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
             
                <div style="text-align: left;" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

                    <a style="cursor:pointer;text-align: center;">
                       <img [src]="safeImageUrl" width="100" height="100"
                       alt="" loading="lazy">
                    </a>
                  <!-- <a style="color: black;">Login Username : {{combinedPTC.username}}</a> -->
            

              

            </div>
             
    
  
            </mat-card>
          </div>

          <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
             
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" style="text-align: left;">

               
                <a><i class="fa fa-birthday-cake" aria-hidden="true"> {{combinedPTC.dateOfBirth}}</i>
                  </a> <br>
                <a style="color: black;" *ngIf="combinedPTC.gender === 'male' || combinedPTC.gender === 'Male'"><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.gender}}</i>
                  </a> 
                  <a style="color: black;" *ngIf="combinedPTC.gender === 'female' || combinedPTC.gender === 'Female'"><i class="fa fa-female" aria-hidden="true"> {{combinedPTC.gender}}</i>
                  </a> 
                  <a style="color: black;" *ngIf="combinedPTC.gender === 'other' || combinedPTC.gender === 'Other'"><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.gender}}</i>
                  </a> 
<br>

                
               

            </div>
             
    
  
            </mat-card>
          </div>

          <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
             
            
             
              <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
                <a style="color: black;"> <i class="fa fa-sign-in" aria-hidden="true"> Login Username : {{combinedPTC.username}}</i></a> <br>
                <a><i class="fa fa-phone" aria-hidden="true"> {{combinedPTC.countryDialCode}} {{combinedPTC.phoneNumber}}</i></a> <br>
                <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.email}}</i></a> <br> 
              </div>
             
    
  
            </mat-card>

            
          </div>

          <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
           

            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
                         
              <div style="text-align: left; color: black;" >
                <a><i class="fa fa-address-card" aria-hidden="true"> Address : </i></a> <br>
                <a>{{combinedPTC.addressLine1}}</a> <a style="color: black;" *ngIf="combinedPTC.addressLine1 !== undefined && combinedPTC.addressLine1 !== null">,</a>
                <a> {{ combinedPTC.addressLine2}}</a> <a style="color: black;" *ngIf="combinedPTC.addressLine2 !== undefined && combinedPTC.addressLine2 !== null">,</a> <br>
                <a> {{ combinedPTC.city}}</a> <a style="color: black;" *ngIf="combinedPTC.city !== undefined && combinedPTC.city !== null">,</a> 
                <a> {{ combinedPTC.state}}</a> <a style="color: black;" *ngIf="combinedPTC.state !== undefined && combinedPTC.state !== null">,</a> <br>
                <a> {{ combinedPTC.country}}</a> <a style="color: black;" *ngIf="combinedPTC.country !== undefined && combinedPTC.country !== null">,</a> 
                <a> {{ combinedPTC.zipCode}}</a> <a style="color: black;" *ngIf="combinedPTC.zipCode !== undefined && combinedPTC.zipCode !== null">.</a> <br>

                 </div>
             
    
  
            </mat-card>
          </div>
        </div>
        </div> 

        <br>

       

        

        <div class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
          

            <!-- <a *ngIf="showSubscriptions === true" style="cursor:pointer;" class="float-right" (click)="addNewSubscription()">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a> -->

            <a *ngIf="showSubjects === true" style="cursor:pointer;" class="float-right" (click)="addClassSubject()">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
 


             <div style="float: left;">
              <select class="form-select form-select-sm"  [(ngModel)]="selectedOption" 
              (change)="onChange($event)" aria-label=".form-select-sm example">
                <option  value="0" selected>Choose an option</option>
                <option *ngIf="role === 'TEACHER' || role === 'STUDENT' && fromParent.noSubsciptionsOption !== true" value="1"> Subscriptions</option>
                <option *ngIf="role === 'TEACHER'" value="2"> Subjects</option>
                <option *ngIf="role === 'PARENT' || role === 'PARENT_MENTOR'" value="3">Student Details</option>
                <option *ngIf="role === 'CHILD_STUDENT' || role === 'STUDENT'" value="4">Parent Details</option>
             
              </select>
            </div>
    
           

          
            
           
          </div> 
        </div>

    
        
       

       <!-- Subsciptions -->
        <div  *ngIf="showSubscriptions === true">
  
    
        
          <div class="card">
            <div class="card-body table-responsive">
            
              <table class="table table-striped table-bordered table-sm row-border hover">
                <thead class="tableheader" >
                  <tr>
                    <th>Role</th>
                    <th>Product</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th *ngIf="role === 'STUDENT'">Class</th>
                    <th>Action</th>
                   
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let subs of subscriptions">
                    <td class="td-height">{{subs.roleName}}</td>
                    <td class="td-height">
                      <a style="cursor:pointer;text-align: center;">
                        <img [src]="subs.safeUrl" width="35" height="35"
                        alt="" loading="lazy">
                     </a> <br>
                     {{subs.productName}}</td>
                    <td class="td-height">{{subs.startedDate}}</td>
                    <td class="td-height">{{subs.endedDate}}</td>
                    <td *ngIf="role === 'STUDENT'" class="td-height">{{subs.className}}
                      <button class="btn btn-info" *ngIf="subs.className === undefined || subs.className === null" style="cursor:pointer;" (click)="addStudentToClass(subs.fkProductId)">Add</button>
                     </td>
                    <td>
          <button (click)="editSubscription(subs.subscriptionId, subs.startedDate , subs.endedDate , subs.roleName , subs.productName )"
          class="btn btn-primary" style="margin: 2%;">Edit</button>

          <button *ngIf="subs.className !== undefined || subs.className !== null"
           (click)="getAcademicDetailsByStudentId(subs.studentId, subs.productName, subs.className, subs.safeUrl);"
          class="btn btn-secondary" style="margin: 2%;" >Details</button>

                      </td>

                  </tr>
                </tbody>
              </table>
      
            </div>
          </div>
        
      
      </div>   
 
      <!-- Subjects -->
      <div  *ngIf="showSubjects === true">
  
    
       
        <div class="card">
          <div class="card-body table-responsive">
          
            <table class="table table-striped table-bordered table-sm row-border hover" >
              <thead class="tableheader">
                <tr>
                  <th>Subject</th>
                  <th>Class</th>  
                  <th>Action</th>
                </tr>
              </thead> 
              <tbody>
                <tr class="td-height" *ngFor="let sub of classCardsWithSafeUrl">
                  <td class="td-height">{{sub.subName}}</td>
                  <td class="td-height">{{sub.className}}</td>
                  <td>

                    <button style="margin: 1%;" *ngIf="sub.teacherStatus!== null && sub.teacherStatus" type="button"
            class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
          <button style="margin: 1%;" *ngIf="sub.teacherStatus !== null && !sub.teacherStatus" type="button"
            class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
          

                </td>
                
<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate subject ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="teacherStatusUpdate(sub.teacherId);modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template> 


<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate subject ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="teacherStatusUpdate(sub.teacherId);modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>
                </tr>
              </tbody>
            </table>
    
          </div>
        </div>
      
    
    </div> 
         

  <!-- Parent Details -->
     <div *ngIf="showParentDetails === true">

      <br *ngIf="combinedPTC.parentId === null && userSearch===true">
<h3 *ngIf="combinedPTC.parentId === null && userSearch===true">No Parent details for this user to display !</h3>
     
      <div  *ngIf="combinedPTC.parentId === null && userSearch===true">
     <button (click)="addParent();"
     style="width: auto;margin: 2%;" class="btn btn-primary">Create Parent Login</button>

     <button (click)="open(linkParentModal);" style="width: auto;margin: 2%;"
      class="btn btn-success">Link Existing User as a Parent</button>
    </div>
    <br>

    <button  *ngIf="userSearch===false" type="button" style="width: auto;" class="btn btn-info"
    (click)="userSearch=true;open(linkParentModal);">Search Again</button> <br>
    <br *ngIf="userSearch===false">
    <table *ngIf="userSearch===false" style="overflow: scroll;" class="table table-striped table-bordered table-sm row-border hover" >
      <thead class="tableheader">
        <tr>
          <th>Name</th>
          <th>Email</th>  
          <th>Number</th>
          <th>Action</th>
        </tr>
      </thead> 
      <tbody>
        <tr class="td-height" *ngFor="let sub of users">
          <td class="td-height">{{sub.firstName}} {{sub.lastName}}</td>
          <td class="td-height">{{sub.email}}</td>
          <td class="td-height">{{sub.phoneNumber}}</td>
    
          <td>
    
            <button style="width: auto;" type="button" (click)="open(confirmActivation);"
    class="btn btn-info mr-2  btn-sm" >Add as Parent</button>
    
    
        </td>
        
    <ng-template #confirmActivation let-modal>
    <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
    Are you sure to add {{sub.firstName}} {{sub.lastName}} as Parent ?
    </div> 
    <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="linkParent(sub.userId);modal.dismiss('Cross click')">Add</button>
    </div>
    </ng-template> 
    
    
        </tr>
      </tbody>
    </table>


      <div  *ngIf="combinedPTC.parentId !== null"  fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
           
              <div style="text-align: center;" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

                  <a style="cursor:pointer;text-align: center;">
                     <img [src]="parentImageUrl" width="100" height="100"
                     alt="" loading="lazy">
                  </a>
          </div>
           
  

          </mat-card>
        </div>

        <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
           
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

                <a style="color: black;font-weight: bold;">{{parent.firstName}} {{parent.lastName}}</a>
                <br>
                <a><i class="fa fa-birthday-cake" aria-hidden="true"> {{parent.dateOfBirth}}</i>
                </a> <br>
              <a style="color: black;" *ngIf="parent.gender === 'male' || parent.gender === 'Male'"><i class="fa fa-male" aria-hidden="true"> {{parent.gender}}</i>
                </a> 
                <a style="color: black;" *ngIf="parent.gender === 'female' || parent.gender === 'Female'"><i class="fa fa-female" aria-hidden="true"> {{parent.gender}}</i>
                </a> 
                <a style="color: black;" *ngIf="parent.gender === 'other' || parent.gender === 'Other'"><i class="fa fa-male" aria-hidden="true"> {{parent.gender}}</i>
                </a> 
              <br>

   

          </div>
           
  

          </mat-card>
        </div>

        <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
           
          
           
            <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
              <a style="color: black;"> <i class="fa fa-sign-in" aria-hidden="true"> Login Username : {{parent.username}}</i></a> <br>
               <a><i class="fa fa-phone" aria-hidden="true"> {{parent.phoneNumber}}</i></a> <br>
              <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{parent.email}}</i></a>
       
            </div>
           
  

          </mat-card>

          
        </div>

        <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
         

          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
                       
            <div style="text-align: left; color: black;" >
              <a><i class="fa fa-address-card" aria-hidden="true"> Address : </i></a> <br>
              <a>{{parent.addressLine1}}</a> <a style="color: black;" *ngIf="parent.addressLine1 !== undefined && parent.addressLine1 !== null">,</a>
              <a> {{ parent.addressLine2}}</a> <a style="color: black;" *ngIf="parent.addressLine2 !== undefined && parent.addressLine2 !== null">,</a> <br>
              <a> {{ parent.city}}</a> <a style="color: black;" *ngIf="parent.city !== undefined && parent.city !== null">,</a> 
              <a> {{ parent.state}}</a> <a style="color: black;" *ngIf="parent.state !== undefined && parent.state !== null">,</a> <br>
              <a> {{ parent.country}}</a> <a style="color: black;" *ngIf="parent.country !== undefined && parent.country !== null">,</a> 
              <a> {{ parent.zipCode}}</a> <a style="color: black;" *ngIf="parent.zipCode !== undefined && parent.zipCode !== null">.</a> <br>

            </div>
           
  

          </mat-card>
        </div>
      </div>
     </div>
       
 <!-- Child Details -->
 <div *ngIf="showChildDetails === true">

  <br>

  <div fxLayout="row wrap" fxLayoutGap="16px grid" *ngFor="let child of children.children">

    <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
      <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
       
          <div style="text-align: center;" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

              <a style="cursor:pointer;text-align: center;">
                 <img [src]="child.safeUrl" width="100" height="100"
                 alt="" loading="lazy">
              </a>
      </div>

    </mat-card>
    </div>

    <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
      <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
       
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

            <a style="color: black;font-weight: bold;">{{child.firstName}} {{child.lastName}}</a>
<br>
            <a><i class="fa fa-birthday-cake" aria-hidden="true"> {{child.dateOfBirth}}</i>
            </a> <br>
          <a style="color: black;" *ngIf="child.gender === 'male' || child.gender === 'Male'"><i class="fa fa-male" aria-hidden="true"> {{child.gender}}</i>
            </a> 
            <a style="color: black;" *ngIf="child.gender === 'female' || child.gender === 'Female'"><i class="fa fa-female" aria-hidden="true"> {{child.gender}}</i>
            </a> 
            <a style="color: black;" *ngIf="child.gender === 'other' || child.gender === 'Other'"><i class="fa fa-male" aria-hidden="true"> {{child.gender}}</i>
            </a> 
          
          

      </div>
       


      </mat-card>
    </div>

    <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
      <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
       
      
       
        <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
          <a style="color: black;"> <i class="fa fa-sign-in" aria-hidden="true"> Login Username : {{child.username}}</i></a> <br>
          <a><i class="fa fa-phone" aria-hidden="true"> {{child.phoneNumber}}</i></a> <br>
          <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{child.email}}</i></a>
      </div>
       
      </mat-card>
    </div>

    <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
     
      <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
                   
        <div style="text-align: left; color: black;" >
          <a><i class="fa fa-address-card" aria-hidden="true"> Address : </i></a> <br>
          <a>{{child.addressLine1}}</a> <a style="color: black;" *ngIf="child.addressLine1 !== undefined && child.addressLine1 !== null">,</a>
          <a> {{ child.addressLine2}}</a> <a style="color: black;" *ngIf="child.addressLine2 !== undefined && child.addressLine2 !== null">,</a> <br>
          <a> {{ child.city}}</a> <a style="color: black;" *ngIf="child.city !== undefined && child.city !== null">,</a> 
          <a> {{ child.state}}</a> <a style="color: black;" *ngIf="child.state !== undefined && child.state !== null">,</a> <br>
          <a> {{ child.country}}</a> <a style="color: black;" *ngIf="child.country !== undefined && child.country !== null">,</a> 
          <a> {{ child.zipCode}}</a> <a style="color: black;" *ngIf="child.zipCode !== undefined && child.zipCode !== null">.</a> <br>

        </div>
       
      </mat-card>
    </div>
  </div>


  <br *ngIf="parentType === 'PARENT_MENTOR'">
  <button *ngIf="parentType === 'PARENT_MENTOR'" style="width: auto;float: right;"
  class="btn btn-info" (click)="addAnotherChildStudentToParentMentor();">Add Another Child</button>
 

 </div>
   
      </div>
    </div>
   </div>

 
   
   <ng-template #linkParentModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Link Parent</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="overflow-x: scroll;">
  
    <form #usernameForm="ngForm">  
    <div >
      <label>Parent : </label>
      <input class="col-md-12 form-control" required #username="ngModel"
  type="text" [(ngModel)]="value" placeholder="Enter firstname or lastname or email or phone" name="search">
</div>
    </form>

   
<br>
<br>
    </div> 
    <div class="modal-footer">
      <button  *ngIf="userSearch===true" type="button" class="btn btn-success"  [disabled]="clicked" [disabled]="!usernameForm.valid"
      (click)="searchForUsersToLinkParent();modal.dismiss('Cross click')">Search</button>
    </div>
  </ng-template>