export class Curriculum {

    curId!: string;

	name!: string;

	description!: string;

	fkSubject!: string;
 
	fkCurriculumId!: string;

	currStatus!: boolean;

	curriculumOrder : any;

	selectedCurriculumId : any;

	descriptionBYTEA : any;

	curriculumLevel : any;

	subCurriculumsExist! : boolean;

}
