import { BotSchedule } from "src/app/BOT_CODE/bot_classes/bot-schedule";
import { BotScheduleDetails } from "src/app/BOT_CODE/bot_classes/bot-schedule-details";
import { FeedbackForm } from "./feedback-form";
import { FormRespondent } from "./form-respondent";

export class FormActive {

    id:any;
	activeStatus:any;
    expiryStatus : any;
    fkFormId : any;
    fkRoleId : any;
    fkOfficeId : any;
    formLimit : any;
    formCount : any;
    url : any;
    fromDate : any;
    toDate : any;
    formActiveType:any;
    roleName : any;
    roleDisplayName : any;

    formattedFromDate : any;
    formattedToDate : any;
    
    feedbackForm : FeedbackForm = new FeedbackForm();
	formRespondents : FormRespondent[] = [];

    sortedFormRespondents: FormRespondent[] = [];

    formName:any;

    // bot schedule 
    botScheduleId:any;
	executionTimestamp:any;
	fkBotTemplateId:any;
	botScheduleSuccessCount:any;
	botScheduleStatus:any;
	botScheduleTitle:any;
	botScheduleDescription:any;
	botScheduleType:any;
    fkFormActiveId:any;
    urlPrefixResponse:any;

	fkBotTemplateName:any;
	list : BotSchedule[] = [];
	botUserIds : string[] = [];

    botScheduleDetails : BotScheduleDetails[] = [];

    formActives : FormActive[] = [];

}
