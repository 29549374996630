import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Student } from 'src/app/courseManagementClasses/student';
import { UserList } from 'src/app/courseManagementClasses/user-list';
import { GlobalClass } from 'src/app/global/global-class';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss']
})
export class AddStudentComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              public bsModalRef: BsModalRef    ,
                        private sharedDataService : SharedDataService,

              private studentService : StudentService,
              private toaster: ToastrService ) { }
              loggedInUser: any;
              theme: any;
   @Input() fromParent: any; 
   @Output() passEntry: EventEmitter<any> = new EventEmitter();

  student : Student = new Student();
  clicked=false
  classId!: string;
  officeId : any;
  name!: string; 
  users : UserList[] = [];

  ngOnInit(): void {
    this.student.fkUserRoleOffice = "";
    this.classId = this.fromParent.classId;
    this.name = this.fromParent.name;
    this.officeId = this.fromParent.officeId;
    this.getUserList();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}
getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  onSubmit(){
    this.student.fkClass = this.classId;
    this.studentService.addStudent(this.student).subscribe(data=> {
      this.activeModal.close();
      this.passEntry.emit('Student added successfully');
      this.toaster.success('Student added successfully')
    });
  }

  getUserList(){
    this.studentService.getStudentsWithProductActiveSubscriptions(environment.apiUrl + "student/getStudentsWithProductActiveSubscriptions/" + this.classId).then(
      data=>{
        this.users = data;
      });
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  closeUpdateModal(){
    this.activeModal.close();
  }

}
