export class BotInvoiceDetails {

    invoiceDetId:any;
	invoiceDetstatus:any;
	itemName:any;
	itemQuantity:any;
	alternateQuantity:any;
	pricePerItem:any;
	totalPrice:any;
	fkBotInvoiceId:any;
	fkBotItemId:any;

	invoiceDetails:BotInvoiceDetails[]=[];

}
