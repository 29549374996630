export class BotMemberInfo {
    botMemberId:any;
	memberStatus:any;
	fkOfficeId:any;

	memberType:any;
	memberImage:any;

	memberName:any;
	memberPhone:any;
	memberEmail:any;
	memberGender:any;
	memberDescription:any;
	memberSpecialization:any;

	orgName:any;
	orgPhone:any;
	orgEmail:any;
	orgLocationName:any;
	orgAddress:any;
	orgType:any;

	list:BotMemberInfo[]=[];
}
