import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { StudentService } from 'src/app/service/student.service';
import { SubjectService } from 'src/app/service/subject.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({ 
  selector: 'app-student-subject-dashboard',
  templateUrl: './student-subject-dashboard.component.html',
  styleUrls: ['./student-subject-dashboard.component.scss']
})
export class StudentSubjectDashboardComponent implements OnInit {
  cardsExist = false;
  loggedInUser;
  showSpinner : boolean = true;
  navbarTitle : any;
  userName: string = 'User';
  theme: string = '';
  userId!: string;
  classId!: string;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  centered = false;
  disabled = false;
  unbounded = false;
  radius!: number;
  color!: string;

  abc : any;
  xyz : any;
  xxx : any;
  
  subjectListWithSafeUrl : any[] =[ { subId : '', 
  name : '',
  description : '',
  subjectStatus : '',
  fkProductId : '',
  subjectImage : '',
  safeUrl : '' ,
  readMore : ''
} ];

productId!: string;
studentId!: string;

safeImageUrl2: any;
unsafeImageUrl2: any;

  
  public screenWidth: any;
  public screenHeight: any;

  constructor(private sharedDataService: SharedDataService, 
    private ngbModalService: NgbModal,
              public router: Router,private logoutService : LogoutService,
              private route : ActivatedRoute,
              private imageService: FileServiceService,
              private sanitizer : DomSanitizer ,
              private subjectService : SubjectService,
              private modalService: BsModalService,
              private studentService : StudentService,
              private bnIdle: BnNgIdleService,
              private toastr : ToastrService,
              public bsModalRef: BsModalRef) {
              // super();
            this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
            this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
            this.theme = this.sharedDataService.getCardOfficeTheme();
            this.userId = this.loggedInUser.userId;

  }
  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.userId = this.loggedInUser.userId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;
    // this.showSpinner = false;

    this.productId = this.route.snapshot.params['productId'];
    this.studentId = this.route.snapshot.params['studentId'];
    this.classId = this.route.snapshot.params['classId'];

    this.getSubListByProductId(this.productId);

  }


getSubListByProductId(productId : any){
  this.subjectService.getSubListByProductId(productId).subscribe(
    data=>{
      this.subjectListWithSafeUrl = [];

      for(var index in data){

          this.subjectListWithSafeUrl.push( { 'subId' : data[index].subId,
                                              'name' : data[index].name,
                                              'description' : data[index].description,
                                              'subjectStatus' : data[index].subjectStatus,
                                              'fkProductId' : data[index].fkProductId,
                                              'subjectImage' : data[index].subjectImage,
                                              'safeUrl' : this.xyz,
                                              'readMore' : false
                                             });
      }

      this.cardsExist = true;
      if(this.subjectListWithSafeUrl===undefined || this.subjectListWithSafeUrl.length===0){
        this.showSpinner = false;
      }

      let count = 0;
      this.subjectListWithSafeUrl.forEach( (value) => {
        
        count++;

        this.imageService.getSubjectImage( value.subjectImage ).subscribe(
          data=>{
            this.xyz = data;
            this.abc = URL.createObjectURL(data);
            this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

            value.safeUrl = this.xyz;

          });

          if(count === this.subjectListWithSafeUrl.length){
            setTimeout(()=>{                           //timer
              this.showSpinner = false;
         }, 1500);
          }
      }); 

    });

 

}

  explore(subjectId : any){
    this.router.navigate(['dashboard/student-course',subjectId, this.studentId, this.classId]);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId]);
  }
 

 getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  goBack() {
    this.router.navigate(['/dashboard/user']).then();
  }

  reloadPage() {
    window.location.reload();
  }

  currentRole = "STUDENT";
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });    
  }
}
