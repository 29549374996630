
 <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
   
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToTicketSupportPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-question-circle"></i> Ticket Support </a>
        </li>
        
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <br>

  <div class="content" >
   <div fxLayout="row wrap" fxLayoutGap="16px grid">
 
     <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
       <mat-card  class="example-card mat-elevation-z12" >
      
         <div class="card-icon" >
           <a> 
             <img src="/assets/images/icons/inv.svg" width="65" height="65"
              alt="" loading="lazy">
           </a>
         </div>
          <br>
          <mat-card-title style="margin-bottom: 6%;">Total <br>
           Orders</mat-card-title>
          <mat-card-title (click)="viewOrdersTable(orderStatus.allOrders, 'All Orders')"
           style="margin-top: 6%;color: blue;cursor: pointer;"> {{orderStatus.totalOrders}}</mat-card-title>
       
     </mat-card>
     
          
         </div>
     <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
   <mat-card  class="example-card mat-elevation-z12" >
  
     <div class="card-icon" >
       <a> 
         <img src="/assets/images/icons/ordersPlaced.png" width="65" height="65"
          alt="" loading="lazy">
       </a>
     </div>
      <br>
      <mat-card-title style="margin-bottom: 6%;">Orders Placed</mat-card-title>
      <mat-card-title (click)="viewOrdersTable(orderStatus.pendingApprovalOrders, 'Orders - Pending approval')"
       style="margin-top: 6%;color: blue;cursor: pointer;"> {{orderStatus.pendingApprovalOrderCount}}</mat-card-title>
   
 </mat-card>
 
      
     </div>
     <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
       <mat-card  class="example-card mat-elevation-z12" >
      
         <div class="card-icon" >
           <a> 
             <img src="/assets/images/icons/ordersApproved.png" width="65" height="65"
              alt="" loading="lazy">
           </a>
         </div>
          <br>
          <mat-card-title style="margin-bottom: 6%;">Orders Approved</mat-card-title>
          <mat-card-title (click)="viewOrdersTable(orderStatus.approvedOrders, 'Orders - Approved orders')"
           style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.approvedOrderCount}}</mat-card-title>
       
     </mat-card>
     
          
         </div>
         <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
           <mat-card  class="example-card mat-elevation-z12" >
          
             <div class="card-icon" >
               <a> 
                 <img src="/assets/images/icons/ordersInvoiced.png" width="65" height="65"
                  alt="" loading="lazy">
               </a>
             </div>
              <br>
              <mat-card-title style="margin-bottom: 6%;">Orders Invoiced</mat-card-title>
              <mat-card-title (click)="viewOrdersTable(orderStatus.invoicedOrders, 'Orders - Invoiced')"
               style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.invoicedOrderCount}}</mat-card-title>
           
         </mat-card>
         
              
             </div>
             <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
               <mat-card  class="example-card mat-elevation-z12" >
              
                 <div class="card-icon" >
                   <a> 
                     <img src="/assets/images/icons/ordersShipped.png" width="65" height="65"
                      alt="" loading="lazy">
                   </a>
                 </div>
                  <br>
                  <mat-card-title style="margin-bottom: 6%;">Orders Shipped</mat-card-title>
                  <mat-card-title (click)="viewOrdersTable(orderStatus.shippedOrders, 'Orders - Shipped')"
                   style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.shippedOrderCount}}</mat-card-title>
               
             </mat-card>
             
                  
                 </div>
                 <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
                   <mat-card  class="example-card mat-elevation-z12" >
                  
                     <div class="card-icon" >
                       <a> 
                         <img src="/assets/images/icons/ordersDelivered.png" width="65" height="65"
                          alt="" loading="lazy">
                       </a>
                     </div>
                      <br>
                      <mat-card-title style="margin-bottom: 6%;">Orders Delivered</mat-card-title>
                      <mat-card-title  (click)="viewOrdersTable(orderStatus.deliveredOrders, 'Orders - Delivered')"
                       style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.deliveredOrderCount}}</mat-card-title>
                   
                 </mat-card>
                 
                      
                     </div>
                     <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
                       <mat-card  class="example-card mat-elevation-z12" >
                      
                         <div class="card-icon" >
                           <a> 
                             <img src="/assets/images/icons/ordersCancelled.png" width="65" height="65"
                              alt="" loading="lazy">
                           </a>
                         </div>
                          <br>
                          <mat-card-title style="margin-bottom: 6%;">Orders Cancelled</mat-card-title>
                          <mat-card-title (click)="viewOrdersTable(orderStatus.cancelledOrders, 'Orders - Cancelled orders')"
                           style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.cancelledOrderCount}}</mat-card-title>
                       
                     </mat-card>
                     
                          
                         </div>
                   
 
   </div>
   </div>




  <div class="row col-md-12 col-lg-12 col-sm-12" style="margin: 0%;padding: 0%;">

    <div class="col-md-9 col-lg-9 col-sm-12" style="padding: 0%;">

      <div class="content">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
    
       
    
      
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
      
              <div class="card-icon">
                <a> 
                  <img class="card-icon-image" src="/assets/images/icons/customers.svg" width="85" height="85"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Customers
            </mat-card-title>
            <mat-card-content>
              <p >
                <button class="btn btn-info" [style.background-color]='getColor()' (click)="customerDashboard()" mat-button>Explore</button>
              </p>
            </mat-card-content>
           
          </mat-card>
      
           
          </div>
      
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
           
              <div class="card-icon" >
                <a> 
                  <img src="/assets/images/icons/leads.svg" width="85" height="85"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Leads
            </mat-card-title>
            <mat-card-content>
              <p >
                <button [style.background-color]='getColor()'
                 class="btn btn-info" (click)="LeadDashboard()" mat-button>Explore</button>
              </p>
            </mat-card-content>
            
          </mat-card>
           
          </div>
    
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
           
              <div class="card-icon" >
                <a> 
                  <img src="/assets/images/icons/inv.svg" width="85" height="85"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Orders
            </mat-card-title>
            <mat-card-content>
              <p >
                <button [style.background-color]='getColor()'
                 class="btn btn-info" (click)="exploreOrders()" mat-button>Explore</button>
              </p>
            </mat-card-content>
            
          </mat-card>
           
          </div>
    
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
             
              <div class="row col-md-12">
                <div class="row col-md-6">
                  <div class="col-md-3">
                    
                      <a> 
                        <img src="/assets/images/icons/goal.png" width="80" height="80"
                         alt="" loading="lazy">
                      </a>
                  
                  </div>
                  <div class="col-md-9">
                   <h2 style="text-align: left;margin: 0%"> {{target.targetName}}</h2>
    
                   <h3 style="text-align: left;margin: 0%">[ {{target.targetStartDate}} to {{target.targetEndDate}} ]
                   </h3>


                    <button class="btn btn-info" *ngIf="showAssignTarget === true"
                    [style.background-color]='getColor()' 
                    (click)="assignTargetToExecs()" style="width: auto;margin: 1%;" mat-button>Assign Target</button>
      
                    <button class="btn btn-info"
                    [style.background-color]='getColor()' 
                    (click)="exploreTargets()" style="width: auto;margin: 1%;" mat-button>View Targets</button> <br>

                    <button class="btn btn-info"
                    [style.background-color]='getColor()' 
                    (click)="open(confirmDeactivation)" style="width: auto;margin: 1%;" mat-button>Delete Assigned Targets</button>
      
                  </div>

                
                </div>
                <div style="text-align: left;" class="col-md-6">
                      <h1 style="font-weight: bold;margin: 0%;">Goal : {{target.formattedTargetValue}}</h1>
                      <h2 style="font-weight: bold;margin: 0%;">Achieved : {{target.formattedTargetAchieved}}</h2>
                      <h2 style="text-align: left;margin: 0%"> {{achievedPercentage}}%</h2>
    
                      <div class="progress" style="background-color: #c3daf1; margin-bottom: 2%;">
                       <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width]="achievedPercentage + '%'">{{achievedPercentage}}%</div>
                       </div>
                </div>
              </div>
              <br>
              <br>
            
              <table class="table table-striped table-bordered table-sm row-border hover">
                <thead class="tableheader">
                  <tr>
                    <th>User</th>
                    <th> Goal</th>
                    <th> Achieved</th>
                  <th>Action</th>
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let tar of target.executiveTargets">
                    <td class="td-height" style="text-align: center;">{{tar.executiveName}}</td>
                    <td class="td-height" style="text-align: right;">{{tar.targetValue}}</td>
                    <td class="td-height" style="text-align: right;">{{tar.targetAchieved}}</td>
                  
                    <td  style="text-align: center;">
                      <a *ngIf="tar.childTargetsExist === true" matTooltip="View Team Targets" (click)="checkForChildTargets(tar.id, tar.targetValue, tar.targetAchieved, tar.executiveName, tar.targetName)"> 
                        <img src="/assets/images/icons/people.png" width="25" height="25"
                         alt="" loading="lazy">
                      </a>
                      <a *ngIf="tar.childTargetsExist === false" matTooltip="View Details" (click)="checkForChildTargets(tar.id, tar.targetValue, tar.targetAchieved, tar.executiveName, tar.targetName)"> 
                        <img src="/assets/images/icons/orders.png" width="25" height="25"
                         alt="" loading="lazy">
                      </a>

                    
                    </td>
                 
                  
                  
                  </tr>
                </tbody>
              </table> 
          
           
          </mat-card>
      
           
          </div>

          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
            <mat-card class="example-card mat-elevation-z12" >
          <div class="row">
            <a style="margin-left: 2%;margin-right: 2%;"> 
              <img class="card-icon-image" src="/assets/images/icons/announcements.svg" width="80" height="80"
               alt="" loading="lazy">
            </a>
            <mat-card-title style="margin-left: 2%;">Announcements
            </mat-card-title>
          </div> 
            <div style="padding: 2%;overflow-y: auto;
            height: 200px;">
              <h3 style="color: black;text-align: left;" *ngFor="let announcement of announcements">
                <i class="fa fa-dot-circle-o" aria-hidden="true"></i> 
                  {{announcement.message}}</h3>  </div>
          </mat-card>  </div>
          
        </div>
        </div>
      
    </div>

    <div class="col-md-3 col-lg-3 col-sm-12" style="padding: 0%;">

      <div class="content">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
    
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
      <br>
      <div class="card-icon" >
        <a> 
          <img class="card-icon-image" src="/assets/images/icons/outstandingOverdue.png" width="72" height="72"
           alt="" loading="lazy">
        </a>
      </div>

      <br>
<br>
      <mat-card-title>Outstanding Overdue
      </mat-card-title> 
     
      <br>


      <mat-card-title>
        <i class="fa fa-inr" aria-hidden="true"></i>  {{target.outstandingOverdue}}
      </mat-card-title> 

     <br>

      <mat-card-content>
        <p>
          <button class="btn btn-info" [style.background-color]='getColor()' (click)="dueOrdersDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>

           
          </mat-card>
      
           
          </div>

              
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
            <mat-card class="example-card mat-elevation-z12" > <br>
      <div class="card-icon" >
        <a> <img class="card-icon-image" src="/assets/images/icons/incentive.svg"
           width="80" height="80"
           alt="" loading="lazy"> </a>
      </div>
      <br>
      <mat-card-title>Eligible Incentive</mat-card-title> <br>
      <mat-card-subtitle>{{target.activeTargetMonth}} <br></mat-card-subtitle>   
      <mat-card-title> <i class="fa fa-inr" aria-hidden="true"></i>  {{target.eligibleIncentive}} </mat-card-title> 
      <mat-card-content>
        <p> <button class="btn btn-info" [style.background-color]='getColor()' (click)="ViewExecTargetsByPeriod()" mat-button>Explore</button>
          <!-- <button class="btn btn-info" [style.background-color]='getColor()' (click)="dueOrdersDashboard()" mat-button>Explore</button> -->
        </p> </mat-card-content>
          </mat-card>
          </div>




        </div>
        </div>
    </div>


  </div>
  
  
     
  <ng-template #confirmDeactivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to delete assigned targets to users ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="deleteTargets(target.id);modal.dismiss('Cross click')">Delete</button>
    </div>
  </ng-template>
  
   
  