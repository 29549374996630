import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../services/sharedData.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ChangePasswordService } from '../../services/change-password/change-password.service';
import { FormControl, FormGroup, MaxLengthValidator, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimeoutScreenComponent } from '../timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ConfirmedValidator } from './confirmed.validator';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };
  changePasswordForm = this.formBuilder.group({
    currentPassword: [null, [Validators.required, Validators.minLength(8)]],
    newPassword: [null, [Validators.required, Validators.minLength(8)]],
    confirmNewPassword: [null, [Validators.required, Validators.minLength(8)]]}, {
      validator: ConfirmedValidator('newPassword', 'confirmNewPassword')
  });

  constructor(private sharedDataService: SharedDataService, private router: Router,
    private changePasswordService: ChangePasswordService, private modalService: BsModalService,
    public bsModalRef: BsModalRef, private bnIdle: BnNgIdleService, private formBuilder: FormBuilder,
    private toaster: ToastrService) {
  }

  get f() { return this.changePasswordForm.controls; }

  ngOnInit() {
  }

  changePassword(form: any): void {
    const loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.changePasswordService.changeUserPassword(environment.apiUrl + 'user/update/password?' + 'email=' +
    loggedInUser?.email + '&oldPassword=' + form.value.currentPassword + '&newPassword=' + form.value.newPassword,
    loggedInUser.email, form.value.currentPassword, form.value.newPassword).then(response => {
        this.router.navigate(['/auth/login']).then();
        this.toaster.success(response.message)
      }).catch((err) =>{
        this.toaster.error(err.error.message)
      });
  }

}
