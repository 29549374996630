
 
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="openOptions()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-cog"></i> Options </a>
        </li> -->
 
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToTicketSupportPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-question-circle"></i> Ticket Support </a>
        </li>
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
  
 
 
 
 
 
 
 
 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  <div class="p-4">
   
     
        <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              {{currentOrders}}
            </span>

            <a style="cursor:pointer;" matTooltip="Options" class="float-right" 
            (click)="openOptions();" >
            <img src="/assets/images/icons/settings.svg" width="26" height="26" alt="" loading="lazy">
          </a>
          <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
            <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
          </a>
          <a matTooltip="Product Based Orders" style="cursor:pointer;margin-right: 2%;" class="float-right" (click)="viewProductBasedOrders();">
            <img src="/assets/images/icons/productSvg.svg" width="25" height="25" alt="" loading="lazy">
          </a>

          <a *ngIf="batchShipment===true" matTooltip="Batch Shipment" style="cursor:pointer;margin-right: 2%;" class="float-right" (click)="createBatchShipment();">
            <img src="/assets/images/icons/batchShipment.svg" width="25" height="25" alt="" loading="lazy">
          </a>
          
          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="invoiceOptions" >
              <thead class="tableheader">
                <tr>
                  <th>Executive</th>
                  <th>Customer</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Total Alt Qty</th>
                  <th> Grand Total</th>
                  <th> Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of invoices">
                  <td class="td-height">{{ccd.executiveName}}</td>
                  <td class="td-height">{{ccd.customerName}}</td>
                  <td class="td-height">{{ccd.orderStatus}}</td>
                  <td class="td-height">{{ccd.invoiceDate}}</td>
                  <td  style="text-align: right;" class="td-height">{{ccd.totalAlternateQuantity}}</td>
                  <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
                  <td>

                    <a *ngIf="ccd.verifiedByUroId !== null" style="cursor:pointer;" matTooltip="Order Verified">
                      <img src="/assets/images/icons/verified.png" width="30" height="30" alt="" loading="lazy">
                    </a>
        
                    <a *ngIf="ccd.verifiedByUroId === null" style="cursor:pointer;" matTooltip="Order Unverified">
                      <img src="/assets/images/icons/unverified.png" width="30" height="30" alt="" loading="lazy">
                    </a>

                    <button *ngIf="ccd.shipmentExists === false" style="width:auto;" 
                    (click)="createShipment(ccd);" class="btn btn-success">Add shipment</button>
                    <button *ngIf="ccd.shipmentExists === true" style="width:auto;" 
                    (click)="viewShipmentDetailsModal(ccd.shipmentId);" class="btn btn-secondary">View shipment</button>
                    <button *ngIf="ccd.shipmentExists === true" style="width:auto;" 
                    (click)="editShipment(ccd.shipmentId);" class="btn btn-info">Edit shipment</button>
                    <button *ngIf="ccd.orderStatus === 'Order Shipped'" style="width:auto;" 
                    (click)="open(confirmDeactivation);selectedInvoiceId=ccd.invoiceId;selectedShipmentId=ccd.shipmentId" class="btn btn-dark">Mark as delivered</button>
                    <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" 
                    class="btn btn-primary">View Order</button>  


                  </td>

                
                  
                </tr>    

              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>

   


<div class="p-4">
   
     
  <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Location Group based {{currentOrders}}
      </span>

      <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArrayLG()">
        <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
      </a>

    </div> 
  </div>
  <div class="card">
    <div class="card-body table-responsive">
      <ngx-spinner *ngIf="invoiceLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
      [fullScreen]="false">
      <p style="color: white">{{invoiceLoaderMessage}}</p>
    </ngx-spinner>
      <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="invoiceOptions" >
        <thead class="tableheader">
          <tr>
            <th>Group Name</th>
            <th>Group Code</th>
            <th>No of Orders</th>
            <th>Total Alt Qty</th>
            <th> Grand Total </th>
            <th> Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of locationGroups">
            <td class="td-height">{{ccd.groupName}}</td>
            <td class="td-height">{{ccd.groupCode}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.totalNoOfOrders}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.totalAlternateQuantity}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>

            <td>
              <button type="button" class="btn btn-success" style="width: auto;"
              (click)="viewLocationGroupBasedOrders(ccd.id, ccd.groupName);">View Orders</button>
            </td>

          
            
<ng-template #confirmActivation let-modal>
<div class="modal-header">
  <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure to activate location group ?
</div> 
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
  <button type="button" class="btn btn-success" (click)="statusUpdate(ccd.id);modal.dismiss('Cross click')">Activate</button>
</div>
</ng-template> 


<ng-template #confirmDeactivation let-modal>
<div class="modal-header">
  <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure to deactivate location group ?
</div> 
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
  <button type="button" class="btn btn-success" (click)="statusUpdate(ccd.id);modal.dismiss('Cross click')">Deactivate</button>
</div>
</ng-template>
          </tr>    

        </tbody>
      </table>

    </div>
  </div>


</div>

  
  
 
  
  
  <ng-template #confirmDeactivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to change order status to delivered ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" style="width:auto;" (click)="markOrderAsDelivered();modal.dismiss('Cross click')">Mark as delivered</button>
    </div>
  </ng-template>





















  <div class="p-4">
   
     
    <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Location Groups
        </span>

        <a style="cursor:pointer;" class="float-right" 
          (click)="addLocationGroup();" >
          <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
        </a>

        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArrayLG2()">
          <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
        </a>

      </div> 
    </div>
    <div class="card">
      <div class="card-body table-responsive">
        <ngx-spinner *ngIf="invoiceLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
        [fullScreen]="false">
        <p style="color: white">{{invoiceLoaderMessage}}</p>
      </ngx-spinner>
        <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="invoiceOptions" >
          <thead class="tableheader">
            <tr>
              <th>Group Name</th>
              <th>Group Code</th>
              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of locationGroups">
              <td class="td-height">{{ccd.groupName}}</td>
              <td class="td-height">{{ccd.groupCode}}</td>

              <td>

                <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
                class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation);">Deactivate</button>
              <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
                class="btn btn-success mr-2" (click)="open(confirmActivation);">Activate</button>
             <button class="btn btn-info" (click)="editLocationGroup(ccd);">Edit</button>
              </td>

            
              
<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate location group ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="statusUpdate(ccd.id);modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template> 


<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate location group ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="statusUpdate(ccd.id);modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>
            </tr>    

          </tbody>
        </table>

      </div>
    </div>
  

</div>

