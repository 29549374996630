import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ClassTeachers } from 'src/app/courseManagementClasses/class-teachers';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-class-teachers',
  templateUrl: './view-class-teachers.component.html',
  styleUrls: ['./view-class-teachers.component.scss']
})
export class ViewClassTeachersComponent implements OnInit {

  constructor(private teacherService : TeacherService,
              public activeModal: NgbActiveModal,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService ,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

  classTeachers : ClassTeachers[] = [];

  classId!: string;
    name!: string;

  @Input() fromParent: any; 

  ngOnInit(): void {

    this.classId = this.fromParent.classId;
    this.name=this.fromParent.name;
    this.teacherService.getTeachersInaClass(this.classId).subscribe(data=>{
      this.classTeachers = data;
    }, error => console.log(error)
    )
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  closeUpdateModal(){
  this.activeModal.close();
  }

}
