import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordServiceService } from 'src/app/services/forgot-password/forgot-password-service.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimeoutScreenComponent } from '../timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  constructor(public router: Router, public service: ForgotPasswordServiceService,
    private modalService: BsModalService,  public bsModalRef: BsModalRef,private bnIdle: BnNgIdleService,
    private toaster: ToastrService) { }

  ngOnInit() {
    // this.bnIdle.startWatching(10000000000000).subscribe((isTimedOut: boolean) => {
    //   this.bsModalRef = this.modalService.show(TimeoutScreenComponent, this.config);
    //   this.bsModalRef.content.closeBtnName = 'Close';
    //   const self = this;
    //   this.bsModalRef.content.event.subscribe((res: any) => {
    //       if(res.data === 'Logout'){
    //         this.router.navigate(['/login']);
    //       }
    //   }
    //   );
    // });
  }

  backToLogin() {
    this.router.navigate(['/auth/login']).then();
  }

  goToLoginPage(){
    this.router.navigate(['/auth/login']);
  }

  resetPassword(value: any){
       this.service.forgetPassword(environment.apiUrl + 'user/forgot/' + value.email + '/password').then(response =>{
        this.toaster.success(response.message);
        this.router.navigate(['/auth/login']).then(); 
       }).catch((error) =>{
         this.toaster.error(error.error.message);
       })
  }

}