import { SafeResourceUrl } from "@angular/platform-browser";

export class ActivityVideoLink {

    actVideoLinkId!: string;
	actVideoLinkName!: string;
	actVideoLink!: string;
	actVideoLinkDescription!: string;
	fkActivityPlannerId!: string;
	actVideoLinkStatus!: string;
	urlSafe!: SafeResourceUrl;

}
