<div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Start Assignment : {{assignmentName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
   
        <form #aplnrForm="ngForm">

            <div class="form-group row">
                <div class="col-md-6">
                    <label class="required" for="">Start Date</label>
                    <input class="form-control"  required [(ngModel)]="teacherPlanner.startDateTimeNew" type="datetime-local" id="birthdaytime" name="birthdaytime">
                </div>
                <div class="col-md-6">
                    <label class="required" for="">End Date</label>
                    <input class="form-control"  required [(ngModel)]="teacherPlanner.endDateTimeNew" type="datetime-local" id="birthdaytime" name="birthdaytime">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6">
                    <label  class="required" for="">Display Activity ?</label> <br>
                    <mat-radio-group aria-label="show" required [(ngModel)]="teacherPlanner.show">
                        <mat-radio-button name="show" value="true">Yes</mat-radio-button>
                        <mat-radio-button name="show" value="false"  >No</mat-radio-button>
                      </mat-radio-group>
                </div>
                <div class="col-md-6">
                    <label  class="required" for="">Start Activity ?</label> <br>
                    <mat-radio-group aria-label="assignmentActiveStatus" required [(ngModel)]="teacherPlanner.assignmentActiveStatus">
                        <mat-radio-button name="assignmentActiveStatus" value="true">Yes</mat-radio-button>
                        <mat-radio-button name="assignmentActiveStatus" value="false"  >No</mat-radio-button>
                      </mat-radio-group>
                </div>
            </div>

        </form>

      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button  [disabled]="!aplnrForm.valid" [disabled]="clicked" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
  </div>