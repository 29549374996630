<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li *ngIf="viewResponses === true || viewErrorResponses === true" class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="viewResponses=false;viewErrorResponses=false;" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>
        
        <li *ngIf="viewResponses===true" class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="refreshFlow();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-refresh" aria-hidden="true"></i> Refresh </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
       
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>  
        
       
  
      </ul>
    </div>  
  </nav>

 

 

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
 

<div *ngIf="viewResponses === false && viewErrorResponses === false && viewTemplateResponses === false">
 
  <div class="p-4">
    <h2 style="text-align: left; font-weight: bold;color: rgb(76, 71, 71);">Definitions</h2>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="goToSetupPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/gear.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f">Set-Up</h4>
      </mat-card>
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="viewUserRegDefs();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/contact-list.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">User Details Registration</h4>
      </mat-card>
      </div>
     
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="showFlow();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/discussion.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;"> Conversation Flows </h4>
      </mat-card>
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="viewErrorResponses=true;" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/conversation.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;"> Error Responses </h4>
      </mat-card>
      </div>

      

      
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="goToAPIConfigsPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/url.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;"> API Config </h3>
      </mat-card>
      </div>

    </div>
  </div>


  <div class="p-4">
    <h2 style="text-align: left; font-weight: bold;">Templates, Scheduled Messages & Forms</h2>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
     
      <div *ngIf="officeObject.templatesAccess===true" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToTemplatesPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/layout.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Templates</h4>
           
          
          

          </mat-card>
         
      </div>

      <div *ngIf="officeObject.scheduleMsgsAccess===true" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToSchedulesPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/calendar.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Schedules & Media</h4>
           
          </mat-card>
         
      </div>

      <div *ngIf="officeObject.autoschedulerEnabled===true" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToAutoScheduleSettingsPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/setting.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Auto-Schedule Settings</h4>
           
           

          </mat-card>
         
      </div>

      <div *ngIf="officeObject.formsAccess===true" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToFormsManagementPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/form.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Schedule Forms</h4>
           
          
          

          </mat-card>
         
      </div>

      <div *ngIf="officeObject.crmAccess===true" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToCrmListsDashboard();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/form.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">CRM</h4>
          </mat-card>
         
      </div>

      </div>
  </div>

  <div class="p-4">
    <h2 style="text-align: left; font-weight: bold;">Users, Notifications & Conversations</h2>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
     
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToCustomerUsersPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/user.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Users</h4>
           
           
          

          </mat-card>
         
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToAdminNotificationPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/textFile.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Notifications</h4>
           
           

          </mat-card>
        </div>

        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-card (click)="goToUsersConversationsPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
           
            <div style="width: 100%;color: black;text-align: center;" >
              <a style="cursor:pointer;">
                 <img src="/assets/images/icons/chat.png" width="60" height="60"
                 alt="" loading="lazy">
              </a>
            </div>
            <br>
             <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Conversations</h4>
             
             
            
  
            </mat-card>
          </div>

      </div>
  </div>

  <div class="p-4">
    <h2 style="text-align: left; font-weight: bold;">Admins, Chat Agents</h2>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="goToAdminManagementPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/cRole.svg" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Admins & Chat Agents</h3>
      </mat-card>
      </div>

      <div *ngIf="officeObject.medSpecialistsAccess===true" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card (click)="goToMedicalSpecialistsManagementPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/cRole.svg" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Listings</h3>
      </mat-card>
      </div>

    </div>
  </div>
  
  




  




  <div *ngIf="officeObject.shopAccess===true" class="p-4">
    <h2 style="text-align: left; font-weight: bold;">Shop</h2>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="goToShopPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/shoppingcart.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Shop Set-Up</h4>
           
          
          

          </mat-card>
        </div>

        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-card (click)="goToShopOrdersPage();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
           
            <div style="width: 100%;color: black;text-align: center;" >
              <a style="cursor:pointer;">
                 <img src="/assets/images/icons/bag.png" width="60" height="60"
                 alt="" loading="lazy">
              </a>
            </div>
            <br>
             <h4 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Orders</h4>
             
            
            
  
            </mat-card>
          </div>
      </div>
  </div>
</div>




































<!-- CONVO FLOW RESPONSES ------------- CONVO FLOW RESPONSES ------------- CONVO FLOW RESPONSES ------------- CONVO FLOW RESPONSES ------------- CONVO FLOW RESPONSES -->

<div class="p-4" *ngIf="viewResponses===true">

  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
      <mat-card class="example-card mat-elevation-z12" >
        <div style="width: 100%;text-align: center;margin-top: 5%;" >
          <a style="cursor:pointer;">
             <img src="/assets/images/icons/discussion.png" width="125" height="125"
             alt="" loading="lazy">
          </a>
        </div>
        
        <br>
        <div style="text-align: center;">
          <div style="margin: 5%;">
        
            <mat-form-field [style.width.%]=100 appearance="fill">
              <mat-label> Flow</mat-label>
              <mat-select [(value)]="activeFlowDefiniton">
                <mat-option *ngFor="let treeDef of botResponse.customizableTreeDefList" (click)="switchFlow(treeDef);" [value]="treeDef.definition">{{treeDef.definition}}</mat-option>
              </mat-select>
            </mat-form-field>

            <button (click)="createNewConversationFlow();" mat-raised-button>Create New Flow</button>

            </div>
         </div>
    </mat-card>
    </div>

    <div *ngIf="botResponse.responses === null || botResponse.responses === undefined || botResponse.responses.length === 0" fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
      <mat-card class="example-card mat-elevation-z12" >
        <div style="width: 100%;text-align: center;margin-top: 5%;" >
          <a (click)="createFlowInitiationResponse();" style="cursor:pointer;">
             <img src="/assets/images/icons/add.png" width="120" height="120"
             alt="" loading="lazy">
          </a>
          <br><br>
          <br>
          <br>
            <h3>Create Flow Initiation Response</h3>
        </div>
        <br><br><br>
    </mat-card>
    </div>

    <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let res of botResponse.responses,let i = index" >

      <div style="margin: 0%;" class="row col-md-12">

      <mat-card class="example-card mat-elevation-z12 col-md-11" >
      
        <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
          <a style="float: left;font-weight: bold;">Response {{res.slNo}}</a>

          <a (click)="open(attentionModal);" 
          *ngIf="res.optionsExist===false && res.nextBotResponseId === null && res.getOptionsByExtApi === false
          || res.optionsExist===true && res.nextBotResponseId === null && res.getOptionsByExtApi === true"
          style="margin: 1%;float:right;"> <img src="/assets/images/icons/attention.png" width="25" height="25"
          alt="" loading="lazy"> </a>

          <a matTooltip="More" [matMenuTriggerFor]="beforeMenu" matTooltip="Menu"  style="margin: 1%;float:right;">
            <img src="/assets/images/icons/three-dots.png" width="25" height="25" 
         alt="" loading="lazy"> </a>

         <mat-menu #beforeMenu="matMenu" xPosition="after">
          <button (click)="open(quickReplyButtonsModal);" mat-menu-item>
            <mat-icon>reply_all</mat-icon>Quick Replies</button>
            <button *ngIf="res.backResponseId===null" (click)="setBackResponse(res);" mat-menu-item>
              <mat-icon>reply</mat-icon>Back Response</button>
            <button *ngIf="res.errorResponseId===null" (click)="setErrorResponse(res);" mat-menu-item>
              <mat-icon>error_outline</mat-icon>Error Response</button>
          <button (click)="viewResponseDetails(res);" mat-menu-item>
            <mat-icon>settings</mat-icon>Settings</button>
            <button (click)="viewLanguageVariationsForResponse(res);" mat-menu-item>
              <mat-icon>language</mat-icon>Language Variation</button>
            <button *ngIf="officeObject.botCallbackAccess===true" (click)="setupResponseCallbackFunction(res);" mat-menu-item>
              <mat-icon>settings_applications</mat-icon>Callback Notification Function</button>
              <button (click)="viewAdditionalResponses(res);" mat-menu-item>
                <mat-icon>library_add</mat-icon>Additional Sub-Responses</button>
         </mat-menu>

         <a matTooltip="Options & Next Response" [matMenuTriggerFor]="nxtRes" style="margin: 1%;float:right;">
          <img src="/assets/images/icons/add.png" width="25" height="25" 
       alt="" loading="lazy"> </a>

        <mat-menu #nxtRes="matMenu" xPosition="after">
          <button *ngIf="res.nextBotResponseId !== null"
          (click)="open(confirmUnlinkNextResponse);" mat-menu-item>
          Unlink Next Response
          </button>
          <button *ngIf="res.nextBotResponseId === null && res.nextConditionalResponsesExist === false
          || res.getOptionsByExtApi === true && res.nextBotResponseId === null && res.fkApiConfigId !== null
          && res.nextConditionalResponsesExist === false"
          (click)="addResponseToResponse(res.botResponseId);" mat-menu-item>
            Create Response
          </button>
          <button *ngIf="res.nextBotResponseId === null  && res.nextConditionalResponsesExist === false
          || res.getOptionsByExtApi === true && res.nextBotResponseId === null && res.fkApiConfigId !== null
          && res.nextConditionalResponsesExist === false"
          (click)="linkNextResponseToResponse(res);" mat-menu-item>
          Link Response
          </button>
          <button *ngIf="res.optionsExist===false && res.nextBotResponseId===null  && res.nextConditionalResponsesExist === false"
          (click)="getTemplateResponses(res.botResponseId);" mat-menu-item>
          Template Response
          </button>
          <button *ngIf="res.optionsExist===false && res.nextBotResponseId === null  && res.nextConditionalResponsesExist === false
          || res.optionsExist===true && res.getOptionsByExtApi === true && res.nextBotResponseId === null && res.fkApiConfigId !== null
          && res.nextConditionalResponsesExist === false"
          (click)="addBotApiConfigurationForCondtitonalResponses(res);" mat-menu-item>
          Conditional Responses
          </button>
          <button *ngIf="res.nextConditionalResponsesExist === true"
          (click)="addConditionalResponseToResponse(res.botResponseId);" mat-menu-item>
         More Conditional Responses
          </button>
          <!-- <button *ngIf="res.nextBotResponseId===null && res.userResType===1 && res.nextConditionalResponsesExist === false
          || res.getOptionsByExtApi===true && res.userResType===1 && res.nextConditionalResponsesExist === false"
          (click)="addOptionToResponse(res);" mat-menu-item>
         Create Options
          </button> -->
          <button (click)="addOptionToResponse(res);" mat-menu-item>
         Create Options
          </button>

          <button *ngIf="res.getOptionsByExtApi === false && res.nextConditionalResponsesExist === false && res.userResType===1
          || res.getOptionsByExtApi === false && res.nextConditionalResponsesExist === false && res.userResType===9"
          (click)="createOrLinkApiConfigurationToGetOptions(res);" mat-menu-item>
          API based Options
          </button>

          <button *ngIf="res.getOptionsByExtApi === false 
          && res.userResType===1 && res.nextConditionalResponsesExist === false"
          (click)="optionsFromBotMembersInfoResponseId=res.botResponseId;open(optionsFromBotMembersInfoDetailSelection);" mat-menu-item>
          Members based Options
          </button>

          
         </mat-menu>

          </div>
        <br>

      <h3 style="font-weight: bold;margin: 0%;"> Response 
            <a matTooltip="Edit Response" style="margin-right: 2%;"
            (click)="editBotResponse(res);"> <img src="/assets/images/icons/edit.svg" width="19" height="19"
            alt="" loading="lazy"> </a>
      </h3>

      <div style="padding: 2%;overflow-y: auto;height: 115px;">
        
              <h3 style="margin: 0%;color: rgb(25, 25, 114);text-align: justify;text-justify: inter-word;white-space: pre-line"> 
              {{res.response}}
              </h3> <br>

            <div *ngIf="res.resType === 'image' || res.resType === 'video' || res.resType === 'audio'">
              <a (click)="viewBotMedia(res.botMedia);" style="color: blue;font-weight: bold;">Click here to view Media</a> <br>
            </div>

            
            
            <div *ngIf="res.globalTemplateRes===true">
              <a style="font-weight: bold;color: black;">Global Template Tag</a> 
              <a style="color: black;">: {{res.globalTemplateTag}}</a>
            </div>
      
    </div>

    <br>
    <div *ngIf="res.saveVar===true">
      <a style="font-weight: bold;color: black;">Variable</a> 
      <a style="color: black;">: ###{{res.var}}###</a> <br>
    </div>
    <div *ngIf="res.saveVar===false">
      <a style="font-weight: bold;color: black;">Variable</a> 
      <a style="color: black;">: n/a</a> <br>
    </div>
<br>
<mat-divider></mat-divider>
<br>

   <button class="btn btn-secondary" style="width: auto;" 
  *ngIf="res.qsnAnswered === false && res.nextBotResponseId !== null && res.viewOptions === false"
   (click)="getNextLinkedResponse(res.botResponseId);">
   Get Next Response</button>
  
  <button *ngIf="res.viewOptions === false" (click)="res.viewOptions=true" 
  style="width: auto;float: right;" class="btn btn-link">
  More Details</button>

  <div *ngIf="res.nextConditionalResponsesExist===true && res.fkApiConfigIdForCondRes!==null">

    <h4 style="margin: 0%;font-weight: bold;">Conditional Responses</h4>
    <a style="margin: 0%;font-weight: bold;">[ API Config Linked : {{res.fkApiConfigIdForCondResTitle}} ]</a>

    <div *ngIf="res.conditionsFetched!==true">
      <a (click)="getBotCondResponsesByBotResId(res.botResponseId);" style="color: blue;font-weight: bold;">
        Click here to view API based conditions</a><br><br>
    </div>

    <div *ngIf="res.botCondResponses!==null && res.botCondResponses!==undefined && res.botCondResponses.length > 0">

          <div *ngIf="res.qsnAnswered === false">
              <h3 style="margin: 0%;font-weight: bold;">Conditions : </h3>
              <h3 style="margin: 0%;" *ngFor="let cond of res.botCondResponses">
             
              <h3 (click)="getNextResponseForCondition(res.botResponseId, cond);" style="margin: 0%;color: black;cursor: pointer;" *ngIf="cond.selected===false"> 
                {{cond.slNo}}. {{cond.conditionValue}} 
                <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
                  <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
                </a>
              </h3>
              <h3 (click)="getNextResponseForCondition(res.botResponseId, cond);" style="margin: 0%;color: green;font-weight: bold;" *ngIf="cond.selected===true"> 
                {{cond.slNo}}. {{cond.conditionValue}}
                <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
                  <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
                </a>
               </h3>
              
              <ng-template #editOptionCondResModal let-modal>
                <div class="modal-body" style="color: black;">
                  <h3 style="font-weight: bold;">
                  Edit Condition
                  </h3>
                  <div>
                    <label class="required" for="cond.conditionValue">Condition</label>
                    <input maxlength="255" required class="form-control" type="text" 
                     name="cond.conditionValue" id="cond.conditionValue" [(ngModel)]="cond.conditionValue">
                  </div>
                  <br> <br>
                  <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(cond);modal.dismiss('Cross click')"
                  mat-raised-button>Update</button>
                  <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                </div>
              </ng-template>

            </h3><br>
            <a style="color: blue;font-weight: bold;cursor: text;">Click on a condition to proceed</a> <br><br>
          </div>

          <div *ngIf="res.qsnAnswered === true">
              <h3 style="margin: 0%;font-weight: bold;">Conditions : </h3>
              <h3 style="margin: 0%;" *ngFor="let cond of res.botCondResponses">
              <h3 (click)="getNextResponseForDifferentCondition(res.responseCountNumber, cond, res.botResponseId);" style="margin: 0%;color: black;cursor: pointer;" *ngIf="cond.selected===false">
                 {{cond.slNo}}. {{cond.conditionValue}} 
                 <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
                  <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
                </a>
              </h3>
              <h3 (click)="getNextResponseForDifferentCondition(res.responseCountNumber, cond, res.botResponseId);" style="margin: 0%;color: green;font-weight: bold;" *ngIf="cond.selected===true"> 
                {{cond.slNo}}. {{cond.conditionValue}} 
                <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
                  <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
                </a>
              </h3>
           
              <ng-template #editOptionCondResModal let-modal>
                <div class="modal-body" style="color: black;">
                  <h3 style="font-weight: bold;">
                  Edit Condition
                  </h3>
                  <div>
                    <label class="required" for="cond.conditionValue">Condition</label>
                    <input maxlength="255" required class="form-control" type="text" 
                     name="cond.conditionValue" id="cond.conditionValue" [(ngModel)]="cond.conditionValue">
                  </div>
                  <br> <br>
                  <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(cond);modal.dismiss('Cross click')"
                  mat-raised-button>Update</button>
                  <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                </div>
              </ng-template>

            </h3><br>
            <a style="color: blue;font-weight: bold;cursor: text;">Click on a condition to proceed</a> <br><br>
          </div>

      </div>

  </div>

<div *ngIf="res.viewOptions === true">

<div *ngIf="res.optionsExist === true">
   
  
<div *ngIf="res.options !== null && res.options !== undefined && res.options.length > 0">

<a style="font-weight: bold;color: black;">Options :</a> 

<div *ngFor="let opt of res.options">

  <ng-template #optionConditionsForCondResModal let-modal>
    <div class="modal-body" style="color: black;">
      <h3 style="font-weight: bold;">Responses based on Conditions</h3>

      <h3>API Configuration linked : {{opt.fkApiConfigIdForCondResTitle}} </h3>
      Click on a condition from the following conditions to proceed : <br> <br>
      
      <div *ngFor="let condForOptn of opt.botCondResponses">
    
        <h3 style="margin: 0%;">
          <a style="color: black;cursor: pointer;" (click)="getNextResponseForDifferentCondition(res.responseCountNumber, condForOptn, res.botResponseId);modal.dismiss('Cross click');">
            {{condForOptn.slNo}}. {{condForOptn.conditionValue}}
           </a> 
            <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
              <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
            </a>
        </h3>

        <ng-template #editOptionCondResModal let-modal>
          <div class="modal-body" style="color: black;">
      
            <h3 style="font-weight: bold;">
            Edit Condition
            </h3>
      
            <div>
              <label class="required" for="condForOptn.conditionValue">Condition</label>
              <input maxlength="255" required class="form-control" type="text" 
               name="condForOptn.conditionValue" id="condForOptn.conditionValue" [(ngModel)]="condForOptn.conditionValue">
            </div>
            <br> <br>
      
            <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(condForOptn);modal.dismiss('Cross click')"
            mat-raised-button>Update</button>
            <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
            
          </div>
        </ng-template>

    </div>

    </div>
  </ng-template>

<h4 *ngIf="res.qsnAnswered === false && opt.selected===false" style="margin: 0%;color: rgb(25, 25, 114);cursor: pointer;">

  <a *ngIf="opt.nextConditionalResponsesExist===false" style="margin-right: 1%;color: rgb(25, 25, 114);" (click)="getNextResponseForOption(res.botResponseId, opt);">
  <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
  <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
  {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>
  
  <a *ngIf="opt.nextConditionalResponsesExist===true" style="margin-right: 1%;color: rgb(25, 25, 114)" 
  (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
    opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
    open(optionConditionsForCondResModal);" >
    <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
    <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
    {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

     <a matTooltip="Settings" [matMenuTriggerFor]="OptnsMenu" matTooltip="Settings"  style="margin: 1%;">
      <img src="/assets/images/icons/settings.png" width="21" height="21" 
   alt="" loading="lazy"> </a>

   <mat-menu #OptnsMenu="matMenu" xPosition="after">
    
    <button *ngIf="opt.botResponseOptionsStatus===false" 
    (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
    mat-menu-item>Activate Option</button>
    <button *ngIf="opt.botResponseOptionsStatus===true" 
    (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
    mat-menu-item>Deactivate Option</button>
    
    <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
    (click)="editOption(opt);" mat-menu-item>
     Edit Option
    </button>
    <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
    (click)="viewLanguageVariationsForOptions(opt);" mat-menu-item>
      Language Variation
     </button>
     <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
     && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
     (click)="addNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
     Create Next Response
     </button>
     <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
     && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
     (click)="linkNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
     Link Next Response
     </button>
     <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
     && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
     (click)="getTemplateResponsesForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
     Link Template Response
     </button>
     <button *ngIf="opt.nextBotResponseId!==null
      && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
     (click)="unlinkNextResponseForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
     Unlink  Next Response
     </button>
     <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
     && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
     (click)="addBotApiConfigurationToOptionForCondtitonalResponses(opt);" mat-menu-item>
     Create Conditional Responses
     </button>
     <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
     && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
     (click)="addConditionalResponseToOption(opt.botResponseOptionsId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;" mat-menu-item>
     Create Conditional Responses
     </button>
    <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
    && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
    (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
    opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
    open(optionConditionsForCondResModal);" mat-menu-item>
    View Conditions
    </button>
    

    <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===false" 
    (click)="enableInitiateCallbackNotifsForOption(res.botResponseId,opt.botResponseOptionsId);"
    mat-menu-item>Activate Agent Callback Notifications</button>
    <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===true" 
    (click)="deactivateOptionCallbackResponse(res.botResponseId,opt);"
    mat-menu-item>Deactivate Agent Callback Notifications</button>
    <button *ngIf="officeObject.botCallbackAccess===true"
    (click)="setupOptionCallbackFunction(res.botResponseId,opt);"
    mat-menu-item>Set Callback Notification Function</button>
   </mat-menu>

<a (click)="open(attentionModal2);" *ngIf="opt.nextBotResponseId === null && opt.nextConditionalResponsesExist===false" style="margin-right: 1%;"> 
        <img src="/assets/images/icons/attention.png" width="21" height="21"
       alt="" loading="lazy"> </a>


</h4>

<h4 *ngIf="res.qsnAnswered === true && opt.selected===false" style="margin: 0%;color: rgb(25, 25, 114);cursor: pointer;">
  
  <a *ngIf="opt.nextConditionalResponsesExist===false" style="margin-right: 1%;color: rgb(25, 25, 114)" (click)="getNextResponseForDifferentOption(res.responseCountNumber, opt, res.botResponseId);">
    <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
    <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
    {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

    <a *ngIf="opt.nextConditionalResponsesExist===true" style="margin-right: 1%;color: rgb(25, 25, 114)" 
    (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
          opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
          open(optionConditionsForCondResModal);">
      <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
      <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
      {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

      <a matTooltip="Settings" [matMenuTriggerFor]="OptnsMenu" matTooltip="Settings"  style="margin: 1%;">
            <img src="/assets/images/icons/settings.png" width="21" height="21" 
         alt="" loading="lazy"> </a>
      
         <mat-menu #OptnsMenu="matMenu" xPosition="after">
          
          <button *ngIf="opt.botResponseOptionsStatus===false" 
          (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
          mat-menu-item>Activate Option</button>
          <button *ngIf="opt.botResponseOptionsStatus===true" 
          (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
          mat-menu-item>Deactivate Option</button>
          
          <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
          (click)="editOption(opt);" mat-menu-item>
           Edit Option
          </button>
          <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
          (click)="viewLanguageVariationsForOptions(opt);" mat-menu-item>
            Language Variation
           </button>
           <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
           && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
           (click)="addNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
           Create Next Response
           </button>
           <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
           && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
           (click)="linkNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
           Link Next Response
           </button>
           <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
           && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
           (click)="getTemplateResponsesForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
           Link Template Response
           </button>
           <button *ngIf="opt.nextBotResponseId!==null
            && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
           (click)="unlinkNextResponseForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
           Unlink  Next Response
           </button>
           <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
           && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
           (click)="addBotApiConfigurationToOptionForCondtitonalResponses(opt);" mat-menu-item>
           Create Conditional Responses
           </button>
           <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
           && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
           (click)="addConditionalResponseToOption(opt.botResponseOptionsId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;" mat-menu-item>
           Create Conditional Responses
           </button>
          <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
          && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
          (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
          opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
          open(optionConditionsForCondResModal);" mat-menu-item>
          View Conditions
          </button>
          
      
          <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===false" 
          (click)="enableInitiateCallbackNotifsForOption(res.botResponseId,opt.botResponseOptionsId);"
          mat-menu-item>Activate Agent Callback Notifications</button>
          <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===true" 
          (click)="deactivateOptionCallbackResponse(res.botResponseId,opt);"
          mat-menu-item>Deactivate Agent Callback Notifications</button>
          <button *ngIf="officeObject.botCallbackAccess===true"
          (click)="setupOptionCallbackFunction(res.botResponseId,opt);"
          mat-menu-item>Set Callback Notification Function</button>
         </mat-menu>
     

        <a (click)="open(attentionModal2);" style="margin-right: 1%;" *ngIf="opt.nextBotResponseId === null && opt.nextConditionalResponsesExist===false"
        > <img src="/assets/images/icons/attention.png" width="21" height="21"
        alt="" loading="lazy"> </a>
  </h4>

<h4 *ngIf="res.qsnAnswered === false && opt.selected===true"  style="margin: 0%;color: green; cursor: pointer;font-weight: bold;">
 
  <a *ngIf="opt.nextConditionalResponsesExist===false" style="margin-right: 1%;color: green;"
   (click)="getNextResponseForOption(res.botResponseId, opt);">
  <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
    <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
   {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

   <a *ngIf="opt.nextConditionalResponsesExist===true" style="margin-right: 1%;color: green;" 
   (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
   opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
   open(optionConditionsForCondResModal);">
    <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
      <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
     {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

 <a matTooltip="Settings" [matMenuTriggerFor]="OptnsMenu" matTooltip="Settings"  style="margin: 1%;">
          <img src="/assets/images/icons/settings.png" width="21" height="21" 
       alt="" loading="lazy"> </a>
    
       <mat-menu #OptnsMenu="matMenu" xPosition="after">
        
        <button *ngIf="opt.botResponseOptionsStatus===false" 
        (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
        mat-menu-item>Activate Option</button>
        <button *ngIf="opt.botResponseOptionsStatus===true" 
        (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
        mat-menu-item>Deactivate Option</button>
        
        <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
        (click)="editOption(opt);" mat-menu-item>
         Edit Option
        </button>
        <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
        (click)="viewLanguageVariationsForOptions(opt);" mat-menu-item>
          Language Variation
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="addNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Create Next Response
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
         && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="linkNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Link Next Response
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="getTemplateResponsesForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Link Template Response
         </button>
         <button *ngIf="opt.nextBotResponseId!==null
          && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="unlinkNextResponseForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Unlink  Next Response
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="addBotApiConfigurationToOptionForCondtitonalResponses(opt);" mat-menu-item>
         Create Conditional Responses
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="addConditionalResponseToOption(opt.botResponseOptionsId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;" mat-menu-item>
         Create Conditional Responses
         </button>
        <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
        && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
        (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
        opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
        open(optionConditionsForCondResModal);" mat-menu-item>
        View Conditions
        </button>
        
    
        <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===false" 
        (click)="enableInitiateCallbackNotifsForOption(res.botResponseId,opt.botResponseOptionsId);"
        mat-menu-item>Activate Agent Callback Notifications</button>
        <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===true" 
        (click)="deactivateOptionCallbackResponse(res.botResponseId,opt);"
        mat-menu-item>Deactivate Agent Callback Notifications</button>
        <button *ngIf="officeObject.botCallbackAccess===true"
        (click)="setupOptionCallbackFunction(res.botResponseId,opt);"
        mat-menu-item>Set Callback Notification Function</button>
       </mat-menu>

      <a (click)="open(attentionModal2);" style="margin-right: 1%;" *ngIf="opt.nextBotResponseId === null && opt.nextConditionalResponsesExist===false"
          > <img src="/assets/images/icons/attention.png" width="21" height="21"
          alt="" loading="lazy"> </a>
</h4>

<h4 *ngIf="res.qsnAnswered === true && opt.selected===true"  style="margin: 0%;color: green; cursor: pointer;font-weight: bold;">
 
  <a *ngIf="opt.nextConditionalResponsesExist===false" style="margin-right: 1%;color: green;" >
    <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
    <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
   {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

   <a *ngIf="opt.nextConditionalResponsesExist===true" style="margin-right: 1%;color: green;" 
   (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
        opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
        open(optionConditionsForCondResModal);">
    <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
    <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
   {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

  

        <a matTooltip="Settings" [matMenuTriggerFor]="OptnsMenu" matTooltip="Settings"  style="margin: 1%;">
          <img src="/assets/images/icons/settings.png" width="21" height="21" 
       alt="" loading="lazy"> </a>
    
       <mat-menu #OptnsMenu="matMenu" xPosition="after">
        
        <button *ngIf="opt.botResponseOptionsStatus===false" 
        (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
        mat-menu-item>Activate Option</button>
        <button *ngIf="opt.botResponseOptionsStatus===true" 
        (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"
        mat-menu-item>Deactivate Option</button>
        
        <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
        (click)="editOption(opt);" mat-menu-item>
         Edit Option
        </button>
        <button *ngIf="opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
        (click)="viewLanguageVariationsForOptions(opt);" mat-menu-item>
          Language Variation
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="addNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Create Next Response
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
         && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="linkNextResponseToOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Link Next Response
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false 
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="getTemplateResponsesForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Link Template Response
         </button>
         <button *ngIf="opt.nextBotResponseId!==null
          && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="unlinkNextResponseForOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);" mat-menu-item>
         Unlink  Next Response
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===false
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="addBotApiConfigurationToOptionForCondtitonalResponses(opt);" mat-menu-item>
         Create Conditional Responses
         </button>
         <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
         && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
         (click)="addConditionalResponseToOption(opt.botResponseOptionsId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;" mat-menu-item>
         Create Conditional Responses
         </button>
        <button *ngIf="opt.nextBotResponseId===null && opt.nextConditionalResponsesExist===true
        && opt.responseOptionNumber!=='back' && opt.responseOptionNumber!=='main menu' && opt.responseOptionNumber!=='settings'"
        (click)="getBotCondResponsesByBotResOptionsId(res.botResponseId, opt.botResponseOptionsId);
        opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;
        open(optionConditionsForCondResModal);" mat-menu-item>
        View Conditions
        </button>
        
    
        <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===false" 
        (click)="enableInitiateCallbackNotifsForOption(res.botResponseId,opt.botResponseOptionsId);"
        mat-menu-item>Activate Agent Callback Notifications</button>
        <button *ngIf="officeObject.botCallbackAccess===true && opt.initiateCallbackNotifications===true" 
        (click)="deactivateOptionCallbackResponse(res.botResponseId,opt);"
        mat-menu-item>Deactivate Agent Callback Notifications</button>
        <button *ngIf="officeObject.botCallbackAccess===true"
        (click)="setupOptionCallbackFunction(res.botResponseId,opt);"
        mat-menu-item>Set Callback Notification Function</button>
       </mat-menu>

        <a (click)="open(attentionModal2);" style="margin-right: 1%;" *ngIf="opt.nextBotResponseId === null && opt.nextConditionalResponsesExist===false"
        > <img src="/assets/images/icons/attention.png" width="21" height="21"
         alt="" loading="lazy"> </a>
  </h4>

</div>

<br>
<a>Click on an Option to proceed</a>
<br><br>

</div>

<div *ngIf="res.getOptionsByExtApi === true && res.fkApiConfigId!==null">

  <mat-divider></mat-divider>
  <br>
  <a style="font-weight: bold;color: black;">Options By External API :</a>  <br>

  <div *ngFor="let opt of res.optionsByExtApi">
    <a style="margin: 0%;color: black;">
       {{opt.responseOptionNumber}}. {{opt.responseOption}} 
    </a>
  </div>

  <div *ngIf="res.optionsByExtApi === null 
  || res.optionsByExtApi === undefined 
  || res.optionsByExtApi !== null && res.optionsByExtApi !== undefined && res.optionsByExtApi.length === 0">
    <a (click)="getApiBasedOptionsByConfigId(res);" style="color: blue;font-weight: bold;">Click here </a>
    <a (click)="getApiBasedOptionsByConfigId(res);" style="font-weight: bold;">to view API based options</a>
    <br>
  </div>

  <br>
  <a style="color: black;font-weight: bold;">[ API Config : {{res.optionsByExtApiTitle}} ]</a>
  <br>
  <a (click)="open(confirmUnlinkApiForOptions);" style="color: blue;font-weight: bold;">Click here</a>
    <a (click)="open(confirmUnlinkApiForOptions);" style="font-weight: bold;"> to remove API based options</a>
    <br>
</div>

<div *ngIf="res.getOptionsFromBotMembersInfo === true">
  <h3 style="margin: 0%;font-weight: bold;">Options By Members Info : </h3>
  <h4>[Filter Detail - {{res.optionsFromBotMembersInfoDetail}}]</h4>
  <button (click)="removeOptionsFromBotMembersInfoType(res.botResponseId);" style="width: auto;" mat-raised-button>Remove Members Based Options</button>
  <br><br>
  <div *ngFor="let opt of res.optionsByMembersInfo">
    <h3 style="margin: 0%;color: black;">
      <a> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>
    </h3>
  </div>
</div>

<!-- <div *ngIf="res.optionsExist===true && res.getOptionsFromBotMembersInfo===true 
&& res.options !== null 
&& res.options !== undefined
&& res.options.length === 0"> -->
<div *ngIf="res.optionsExist===true && res.getOptionsFromBotMembersInfo===true && res.optionsByMembersInfo === null ||
res.optionsExist===true && res.getOptionsFromBotMembersInfo===true && res.optionsByMembersInfo === undefined ||
res.optionsExist===true && res.getOptionsFromBotMembersInfo===true && res.optionsByMembersInfo.length === 0">
  <a (click)="getMembersBasedOptions(res);" style="color: blue;font-weight: bold;">Click here to view Members based options</a>
  <br>  
</div>

 <br>
</div>

<div *ngIf="res.errorResponseId!==null">
  <h3  style="font-weight: bold;margin: 0%;"> "Error" Response 
    <a matTooltip="Unlink Error Response" style="margin-right: 1%;"
    (click)="unlinkErrorResponseForResponse(res.botResponseId);"> 
     <img src="/assets/images/icons/broken-link.png" width="21" height="21" 
     alt="" loading="lazy"> </a>
     : </h3>
  <h3 style="margin: 0%;color: rgb(25, 25, 114);">
     {{res.errorResponseResponse}}</h3>
  <br>
</div>

</div>

<button class="btn btn-secondary" style="width: auto;" 
  *ngIf="res.qsnAnswered === false && res.nextBotResponseId !== null && res.viewOptions === true"
    (click)="getNextLinkedResponse(res.botResponseId);">Get Next Response</button>
  
  <button *ngIf="res.viewOptions === true" (click)="res.viewOptions=false" 
  style="width: auto;float: right;" class="btn btn-link">View Less</button>

      </mat-card>

    <div class="col-md-1" style="height: 100%;">
      <a style="vertical-align: bottom;">
        <img src="/assets/images/icons/forward.png" width="26" height="26" alt="" loading="lazy">
      </a>
    </div>

  </div>

  <ng-template #quickReplyButtonsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Quick Reply Buttons</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <section>
        <h4>Choose buttons to add :</h4> 
        <mat-checkbox [(ngModel)]='res.backChecked' value="back">Back</mat-checkbox> <br>
        <mat-checkbox [(ngModel)]='res.mainMenuChecked' value="main menu">Main Menu</mat-checkbox><br>
        <mat-checkbox [(ngModel)]='res.settingsChecked' value="settings">Settings</mat-checkbox><br>
      </section>    
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addQuickReplyButtons(res);modal.dismiss('Cross click')">Add</button>
    </div>
  </ng-template>

  <ng-template #confirmUnlinkNextResponse let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to unlink next response to the the response "<a style="font-weight: bold;">{{res.response}}</a>" ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="unlinkNextResponse(res);modal.dismiss('Cross click')">Unlink</button>
    </div>
  </ng-template>



  <ng-template #confirmUnlinkApiForOptions let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
        Are you sure to remove API for external options ?
      </h3>


      <button style="float: right;width: auto;margin: 0.5%;" (click)="removeBotApiConfigForOptionsFromResponse(res.botResponseId);modal.dismiss('Cross click')"
      mat-raised-button>Remove</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div> 
   
  </ng-template>
 
    </div>
  </div>
</div>




























<!-- ERROR RESPONSES ------------- ERROR RESPONSES ------------- ERROR RESPONSES ------------- ERROR RESPONSES ------------- ERROR RESPONSES -->

<div class="p-4" *ngIf="viewErrorResponses===true">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
      <mat-card class="example-card mat-elevation-z12" >
        <div style="width: 100%;text-align: center;margin-top: 1%;" >
          <a (click)="createErrorResponse();" style="cursor:pointer;color: black;font-weight: bold;">
             <img src="/assets/images/icons/add.png" width="72" height="72"
             alt="" loading="lazy"> <br> <br>
             Add Error Response
          </a>
        </div>

        
      
         <div style="width: 100%;text-align: center;margin-top: 3%;" >
          <a (click)="setOfficeDefaultErrorResponse();" style="cursor:pointer;color: black;font-weight: bold;">
             <img src="/assets/images/icons/default.png" width="72" height="72"
             alt="" loading="lazy"> <br> <br>
             Set Default Error Response
          </a>
        </div>

    </mat-card>
    </div>

    <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let res of errorResponse.responses,let i = index" >

      <div style="margin: 0%;" class="row col-md-12">

      <mat-card class="example-card mat-elevation-z12 col-md-12" >
        <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
          <a style="float: left;font-weight: bold;">Response {{res.slNo}}</a>

          

  <a matTooltip="Add Options" (click)="addOptionToErrorResponse(res);" style="margin: 1%;float:right;"> 
    <img src="/assets/images/icons/add.png" width="25" height="25"
  alt="" loading="lazy"> </a>

        </div>
        <br>
        <h3 style="font-weight: bold;margin: 0%;"> Response 
          <a matTooltip="Edit Response" style="margin-right: 2%;"
           (click)="editErrorResponse(res);"> <img src="/assets/images/icons/edit.svg" width="21" height="21"
          alt="" loading="lazy"> </a>
          
          
          <a matTooltip="Language Variation"
          (click)="viewLanguageVariationsForResponse(res);" #trigger="cdkOverlayOrigin" cdkOverlayOrigin> 
          <img src="/assets/images/icons/translate.png" width="21" height="21" alt="" loading="lazy"> </a>

          

         : </h3>
        <div style="padding: 2%;overflow-y: auto;height: 115px;">
         <h3 style="margin: 0%;color: rgb(25, 25, 114);text-align: justify;text-justify: inter-word;white-space: pre-line"> 
          {{res.response}}
        </h3> <br>

        <a *ngIf="res.resType === 'image' || res.resType === 'video' || res.resType === 'audio'"
        (click)="viewBotMedia(res.botMedia);"
 style="color: blue;font-weight: bold;">Click here to view Media</a>
<br *ngIf="res.resType === 'image' || res.resType === 'video' || res.resType === 'audio'" >

        <a *ngIf="res.saveVar===true" style="font-weight: bold;color: black;">Variable</a> 
        <a style="color: black;" *ngIf="res.saveVar===true">: ###{{res.var}}###</a> <br *ngIf="res.saveVar===true">
        <a *ngIf="res.globalTemplateRes===true" style="font-weight: bold;color: black;">Global Template Tag</a> 
        <a style="color: black;" *ngIf="res.globalTemplateRes===true">: {{res.globalTemplateTag}}</a>
        </div>

<br>

  <button class="btn btn-primary" style="width: auto;" 
  *ngIf="res.qsnAnswered === false && res.optionsExist === false && res.nextBotResponseId !== null && res.viewOptions === false
  || res.qsnAnswered === false && res.optionsExist === true && res.nextBotResponseId !== null  && res.viewOptions === false && res.getOptionsByExtApi === true
  || res.qsnAnswered === false && res.optionsExist === true && res.nextBotResponseId !== null  && res.viewOptions === false && res.getOptionsFromBotMembersInfo === true"
   (click)="getNextResponseForResponse(res.botResponseId);">Get Next Response</button>
  
  <button *ngIf="res.viewOptions === false" (click)="res.viewOptions=true" 
  style="width: auto;float: right;" class="btn btn-link">More Details</button>

  <div *ngIf="res.nextConditionalResponsesExist===true && res.fkApiConfigIdForCondRes!==null">

    <h4 style="margin: 0%;font-weight: bold;">Conditional Responses</h4>
    <a style="margin: 0%;font-weight: bold;">[ API Config Linked : {{res.fkApiConfigIdForCondResTitle}} ]</a>

    <div *ngIf="res.nextConditionalResponsesExist===true && res.fkApiConfigIdForCondRes!==null 
    && res.botCondResponses !== null 
    && res.botCondResponses !== undefined 
    && res.botCondResponses.length === 0">
      <a (click)="getBotCondResponsesByBotResId(res.botResponseId);" style="color: blue;font-weight: bold;">Click here to view API based conditions</a>
<br>
<br>
    </div>

<div *ngIf="res.botCondResponses!==null && res.botCondResponses !== undefined && res.botCondResponses.length > 0 && res.qsnAnswered === false">
  <h3 style="margin: 0%;font-weight: bold;">Conditions : </h3>
  <h3 style="margin: 0%;" *ngFor="let cond of res.botCondResponses">
    <h3 (click)="getNextResponseForCondition(res.botResponseId, cond);" style="margin: 0%;color: black;cursor: pointer;" *ngIf="cond.selected===false"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
    <h3 (click)="getNextResponseForCondition(res.botResponseId, cond);" style="margin: 0%;color: green;font-weight: bold;" *ngIf="cond.selected===true"> 
      {{cond.slNo}}. {{cond.conditionValue}}
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
     </h3>
  
    <ng-template #editOptionCondResModal let-modal>
      <div class="modal-body" style="color: black;">
        <h3 style="font-weight: bold;">
        Edit Condition
        </h3>
        <div>
          <label class="required" for="cond.conditionValue">Condition</label>
          <input maxlength="255" required class="form-control" type="text" 
           name="cond.conditionValue" id="cond.conditionValue" [(ngModel)]="cond.conditionValue">
        </div>
        <br> <br>
        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(cond);modal.dismiss('Cross click')"
        mat-raised-button>Update</button>
        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      </div>
    </ng-template>

  </h3>
  <br>
  <a style="color: blue;font-weight: bold;cursor: text;">Click on a condition to proceed</a>
<br> <br>
</div>

<div *ngIf="res.botCondResponses!==null 
&& res.botCondResponses !== undefined 
&& res.botCondResponses.length > 0 
&& res.qsnAnswered === true">
  <h3 style="margin: 0%;font-weight: bold;">Conditions : </h3>
  <h3 style="margin: 0%;" *ngFor="let cond of res.botCondResponses">
    <h3 (click)="getNextResponseForDifferentCondition(res.responseCountNumber, cond, res.botResponseId);" style="margin: 0%;color: black;cursor: pointer;" *ngIf="cond.selected===false"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
    <h3 (click)="getNextResponseForDifferentCondition(res.responseCountNumber, cond, res.botResponseId);" style="margin: 0%;color: green;font-weight: bold;" *ngIf="cond.selected===true"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
 
 
    <ng-template #editOptionCondResModal let-modal>
      <div class="modal-body" style="color: black;">
        <h3 style="font-weight: bold;">
        Edit Condition
        </h3>
        <div>
          <label class="required" for="cond.conditionValue">Condition</label>
          <input maxlength="255" required class="form-control" type="text" 
           name="cond.conditionValue" id="cond.conditionValue" [(ngModel)]="cond.conditionValue">
        </div>
        <br> <br>
        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(cond);modal.dismiss('Cross click')"
        mat-raised-button>Update</button>
        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      </div>
    </ng-template>

  </h3>
  <br>
  <a style="color: blue;font-weight: bold;cursor: text;">Click on a condition to proceed</a>
<br> <br>
</div>

  </div>

<div *ngIf="res.viewOptions === true">

<div *ngIf="res.optionsExist === true">
        
<h3 style="margin: 0%;font-weight: bold;">Options : </h3>



<div *ngFor="let opt of res.options">

  <h3 style="margin: 0%;color: rgb(25, 25, 114);cursor: pointer;">
    <a *ngIf="opt.nextBotResponseId !== null" style="margin-right: 1%;color: rgb(25, 25, 114);" 
    (click)="getNextResponseForErrorResOption(res.botResponseId, opt);open(ErrorResOptionLinkedResModal);">
       {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

       <a *ngIf="opt.nextBotResponseId === null" style="margin-right: 1%;color: rgb(25, 25, 114);" 
    (click)="getNextResponseForErrorResOption(res.botResponseId, opt);">
       {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

       
    
       <!-- <a matTooltip="Edit" style="cursor:pointer;margin-right: 1%;" (click)="editBotErrResOption(opt);">
        <img src="/assets/images/icons/edit.svg" width="21" height="21" alt="" loading="lazy">
    </a> -->
    
       <a matTooltip="Settings" style="margin-right: 1%;"
       (click)="opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay"
                cdkOverlayOrigin #trigger="cdkOverlayOrigin"> 
         <img src="/assets/images/icons/settings.png" width="21" height="21" 
         alt="" loading="lazy"> </a>
    
         <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerOptn" [cdkConnectedOverlayOpen]="opt.nextResponseOrOptionsOverlay"
         [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
         (backdropClick)="opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay" >
         <ul class="example-list">
          <li
          (click)="editBotErrResOption(opt);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Edit Option</li>
           <li *ngIf="opt.nextBotResponseId === null && opt.linkToLastUserErrorResponse === false"
           (click)="linkNextResponseToErrResOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Link Next Response</li>
           <li *ngIf="opt.nextBotResponseId === null && opt.linkToLastUserErrorResponse === false"
           (click)="linkErrResOptionToUserLastErrResponse(opt.botResponseOptionsId, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Link To User's Previous Error Response</li>
           <li *ngIf="opt.nextBotResponseId!==null"
           (click)="unlinkNextResponseForErrResOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Unlink  Next Response</li>
           <li *ngIf="opt.linkToLastUserErrorResponse===true"
           (click)="linkErrResOptionToUserLastErrResponse(opt.botResponseOptionsId, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Unink To User's Previous Error Response</li>
         </ul>
       </ng-template>
    
       <a matTooltip="Language Variation" (click)="viewLanguageVariationsForOptions(opt);" style="margin-right: 1%;"
       cdkOverlayOrigin #triggerOptn="cdkOverlayOrigin"> <img src="/assets/images/icons/translate.png" width="21" height="21"
             alt="" loading="lazy"> </a>
    
       <a *ngIf="opt.botResponseOptionsStatus===true" matTooltip="Deactivate" style="margin-right: 1%;"
       (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
        <img src="/assets/images/icons/delete.png" width="21" height="21" 
        alt="" loading="lazy"> </a>
    
        <a *ngIf="opt.botResponseOptionsStatus===false" matTooltip="Deactivated" style="margin-right: 1%;"
        (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
          <img src="/assets/images/icons/disable.png" width="21" height="21" 
          alt="" loading="lazy"> </a>
    
          <a (click)="open(attentionModal2);" *ngIf="opt.nextBotResponseId === null && opt.linkToLastUserErrorResponse === false && opt.nextConditionalResponsesExist===false" style="margin-right: 1%;"> 
            <img src="/assets/images/icons/attention.png" width="21" height="21"
           alt="" loading="lazy"> </a>
    </h3>
    
   

</div>

<br *ngIf="res.optionsExist === true && res.viewOptions === true && res.getOptionsByExtApi === false && res.getOptionsFromBotMembersInfo === false">
 <a *ngIf="res.optionsExist === true && res.viewOptions === true && res.getOptionsByExtApi === false && res.getOptionsFromBotMembersInfo === false"
style="color: blue;font-weight: bold;cursor: text;">Click on an Option to view linked Response</a>
<br *ngIf="res.optionsExist === true && res.viewOptions === true && res.getOptionsByExtApi === false && res.getOptionsFromBotMembersInfo === false">




 <br>
</div>



</div>


        

  
  
  <button *ngIf="res.viewOptions === true" (click)="res.viewOptions=false" 
  style="width: auto;float: right;" class="btn btn-link">View Less</button>

          
      
    </mat-card>

  </div>

  <ng-template #confirmUnlinkNextResponse let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to unlink next response to the the response "<a style="font-weight: bold;">{{res.response}}</a>" ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="unlinkNextResponse(res);modal.dismiss('Cross click')">Unlink</button>
    </div>
  </ng-template>
 
    </div>
  </div>
</div>






















































<!-- TEMPLATE RESPONSES ------------- TEMPLATE RESPONSES ------------- TEMPLATE RESPONSES ------------- TEMPLATE RESPONSES ------------- Template RESPONSES -->

<div class="p-4" *ngIf="viewTemplateResponses===true">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
      <mat-card class="example-card mat-elevation-z12" >
        <div style="width: 100%;text-align: center;margin-top: 1%;" >
          <a (click)="createTemplateResponse();" style="cursor:pointer;color: black;font-weight: bold;">
             <img src="/assets/images/icons/add.png" width="100" height="100"
             alt="" loading="lazy"> <br> <br>
             Add Template Response
          </a>
        </div>

        
      
        

    </mat-card>
    </div>

    <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let res of templateResponse.responses,let i = index" >

      <div style="margin: 0%;" class="row col-md-12">

      <mat-card class="example-card mat-elevation-z12 col-md-12" >
        <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
          <a style="float: left;font-weight: bold;">Response {{res.slNo}}</a>

          

  <a matTooltip="Add Options" (click)="addOptionToTemplateResponse(res);" style="margin: 1%;float:right;"> 
    <img src="/assets/images/icons/add.png" width="25" height="25"
  alt="" loading="lazy"> </a>

        </div>
        <br>
        <h3 style="font-weight: bold;margin: 0%;"> Response 
          <a matTooltip="Edit Response" style="margin-right: 2%;"
           (click)="editTemplateResponse(res);"> <img src="/assets/images/icons/edit.svg" width="21" height="21"
          alt="" loading="lazy"> </a>
          
          
          <a matTooltip="Language Variation"
          (click)="viewLanguageVariationsForResponse(res);" #trigger="cdkOverlayOrigin" cdkOverlayOrigin> 
          <img src="/assets/images/icons/translate.png" width="21" height="21" alt="" loading="lazy"> </a>

          

         : </h3>
        <div style="padding: 2%;overflow-y: auto;height: 115px;">
         <h3 style="margin: 0%;color: rgb(25, 25, 114);text-align: justify;text-justify: inter-word;white-space: pre-line"> 
          {{res.response}}
        </h3> <br>

        <a *ngIf="res.resType === 'image' || res.resType === 'video' || res.resType === 'audio'"
        (click)="viewBotMedia(res.botMedia);"
 style="color: blue;font-weight: bold;">Click here to view Media</a>
<br *ngIf="res.resType === 'image' || res.resType === 'video' || res.resType === 'audio'" >

        <a *ngIf="res.saveVar===true" style="font-weight: bold;color: black;">Variable</a> 
        <a style="color: black;" *ngIf="res.saveVar===true">: ###{{res.var}}###</a> <br *ngIf="res.saveVar===true">
        <a *ngIf="res.globalTemplateRes===true" style="font-weight: bold;color: black;">Global Template Tag</a> 
        <a style="color: black;" *ngIf="res.globalTemplateRes===true">: {{res.globalTemplateTag}}</a>
        </div>

<br>

  <button class="btn btn-primary" style="width: auto;" 
  *ngIf="res.qsnAnswered === false && res.optionsExist === false && res.nextBotResponseId !== null && res.viewOptions === false
  || res.qsnAnswered === false && res.optionsExist === true && res.nextBotResponseId !== null  && res.viewOptions === false && res.getOptionsByExtApi === true
  || res.qsnAnswered === false && res.optionsExist === true && res.nextBotResponseId !== null  && res.viewOptions === false && res.getOptionsFromBotMembersInfo === true"
   (click)="getNextResponseForResponse(res.botResponseId);">Get Next Response</button>
  
  <button *ngIf="res.viewOptions === false" (click)="res.viewOptions=true" 
  style="width: auto;float: right;" class="btn btn-link">More Details</button>

  <div *ngIf="res.nextConditionalResponsesExist===true && res.fkApiConfigIdForCondRes!==null">

    <h4 style="margin: 0%;font-weight: bold;">Conditional Responses</h4>
    <a style="margin: 0%;font-weight: bold;">[ API Config Linked : {{res.fkApiConfigIdForCondResTitle}} ]</a>

    <div *ngIf="res.nextConditionalResponsesExist===true && res.fkApiConfigIdForCondRes!==null 
    && res.botCondResponses !== null 
    && res.botCondResponses !== undefined 
    && res.botCondResponses.length === 0">
      <a (click)="getBotCondResponsesByBotResId(res.botResponseId);" style="color: blue;font-weight: bold;">Click here to view API based conditions</a>
<br>
<br>
    </div>

<div *ngIf="res.botCondResponses!==null 
&& res.botCondResponses !== undefined 
&& res.botCondResponses.length > 0 && res.qsnAnswered === false">
  <h3 style="margin: 0%;font-weight: bold;">Conditions : </h3>
  <h3 style="margin: 0%;" *ngFor="let cond of res.botCondResponses">
    <h3 (click)="getNextResponseForCondition(res.botResponseId, cond);" style="margin: 0%;color: black;cursor: pointer;" *ngIf="cond.selected===false"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
    <h3 (click)="getNextResponseForCondition(res.botResponseId, cond);" style="margin: 0%;color: green;font-weight: bold;" *ngIf="cond.selected===true"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
  
    <ng-template #editOptionCondResModal let-modal>
      <div class="modal-body" style="color: black;">
        <h3 style="font-weight: bold;">
        Edit Condition
        </h3>
        <div>
          <label class="required" for="cond.conditionValue">Condition</label>
          <input maxlength="255" required class="form-control" type="text" 
           name="cond.conditionValue" id="cond.conditionValue" [(ngModel)]="cond.conditionValue">
        </div>
        <br> <br>
        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(cond);modal.dismiss('Cross click')"
        mat-raised-button>Update</button>
        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      </div>
    </ng-template>

  </h3>
  <br>
  <a style="color: blue;font-weight: bold;cursor: text;">Click on a condition to proceed</a>
<br> <br>
</div>

<div *ngIf="res.botCondResponses!==null 
&& res.botCondResponses !== undefined 
&& res.botCondResponses.length > 0 
&& res.qsnAnswered === true">
  <h3 style="margin: 0%;font-weight: bold;">Conditions : </h3>
  <h3 style="margin: 0%;" *ngFor="let cond of res.botCondResponses">
    
    <h3 (click)="getNextResponseForDifferentCondition(res.responseCountNumber, cond, res.botResponseId);" style="margin: 0%;color: black;cursor: pointer;" *ngIf="cond.selected===false"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
    <h3 (click)="getNextResponseForDifferentCondition(res.responseCountNumber, cond, res.botResponseId);" style="margin: 0%;color: green;font-weight: bold;" *ngIf="cond.selected===true"> 
      {{cond.slNo}}. {{cond.conditionValue}} 
      <a (click)="open(editOptionCondResModal);" style="color: black;cursor: pointer;" matTooltip="Edit Condition"> 
        <img src="/assets/images/icons/edit.svg" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </h3>
  
  
    <ng-template #editOptionCondResModal let-modal>
      <div class="modal-body" style="color: black;">
        <h3 style="font-weight: bold;">
        Edit Condition
        </h3>
        <div>
          <label class="required" for="cond.conditionValue">Condition</label>
          <input maxlength="255" required class="form-control" type="text" 
           name="cond.conditionValue" id="cond.conditionValue" [(ngModel)]="cond.conditionValue">
        </div>
        <br> <br>
        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateCondResponse(cond);modal.dismiss('Cross click')"
        mat-raised-button>Update</button>
        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      </div>
    </ng-template>

  </h3>
  <br>
  <a style="color: blue;font-weight: bold;cursor: text;">Click on a condition to proceed</a>
<br> <br>
</div>

  </div>

<div *ngIf="res.viewOptions === true">

<div *ngIf="res.optionsExist === true">
        
<h3 style="margin: 0%;font-weight: bold;">Options : </h3>

<div *ngFor="let opt of res.options">

  <h3 style="margin: 0%;color: rgb(25, 25, 114);cursor: pointer;">
    <a *ngIf="opt.nextBotResponseId !== null" style="margin-right: 1%;color: rgb(25, 25, 114);" 
    (click)="getNextResponseForTemplateResOption(res.botResponseId, opt);open(TemplateResOptionLinkedResModal);">
       {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

       <a *ngIf="opt.nextBotResponseId === null" style="margin-right: 1%;color: rgb(25, 25, 114);" 
    (click)="getNextResponseForTemplateResOption(res.botResponseId, opt);">
       {{opt.responseOptionNumber}}. {{opt.responseOption}} </a>

       
    
       <!-- <a matTooltip="Edit" style="cursor:pointer;margin-right: 1%;" (click)="editBotErrResOption(opt);">
        <img src="/assets/images/icons/edit.svg" width="21" height="21" alt="" loading="lazy">
    </a> -->
    
       <a matTooltip="Settings" style="margin-right: 1%;"
       (click)="opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay"
                cdkOverlayOrigin #trigger="cdkOverlayOrigin"> 
         <img src="/assets/images/icons/settings.png" width="21" height="21" 
         alt="" loading="lazy"> </a>
    
         <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerOptn" [cdkConnectedOverlayOpen]="opt.nextResponseOrOptionsOverlay"
         [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
         (backdropClick)="opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay" >
         <ul class="example-list">
          <li
          (click)="editBotErrResOption(opt);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Edit Option</li>
           <li *ngIf="opt.nextBotResponseId === null && opt.linkToLastUserErrorResponse === false"
           (click)="linkNextResponseToTemplateResOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Link Next Response</li>
           <!-- <li *ngIf="opt.nextBotResponseId === null && opt.linkToLastUserErrorResponse === false"
           (click)="linkErrResOptionToUserLastErrResponse(opt.botResponseOptionsId, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Link To User's Previous Error Response</li> -->
           <li *ngIf="opt.nextBotResponseId!==null"
           (click)="unlinkNextResponseForTemplateResOption(opt.botResponseOptionsId, res.qsnAnswered, opt.selected, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Unlink Next Response</li>
           <!-- <li *ngIf="opt.linkToLastUserErrorResponse===true"
           (click)="linkErrResOptionToUserLastErrResponse(opt.botResponseOptionsId, res.botResponseId);opt.nextResponseOrOptionsOverlay=!opt.nextResponseOrOptionsOverlay;"
           style="cursor: pointer;">Unink To User's Previous Error Response</li> -->
         </ul>
       </ng-template>
    
       <a matTooltip="Language Variation" (click)="viewLanguageVariationsForOptions(opt);" style="margin-right: 1%;"
       cdkOverlayOrigin #triggerOptn="cdkOverlayOrigin"> <img src="/assets/images/icons/translate.png" width="21" height="21"
             alt="" loading="lazy"> </a>
    
       <a *ngIf="opt.botResponseOptionsStatus===true" matTooltip="Deactivate" style="margin-right: 1%;"
       (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
        <img src="/assets/images/icons/delete.png" width="21" height="21" 
        alt="" loading="lazy"> </a>
    
        <a *ngIf="opt.botResponseOptionsStatus===false" matTooltip="Deactivated" style="margin-right: 1%;"
        (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
          <img src="/assets/images/icons/disable.png" width="21" height="21" 
          alt="" loading="lazy"> </a>
    
          <a (click)="open(attentionModal2);" *ngIf="opt.nextBotResponseId === null && opt.linkToLastUserErrorResponse === false && opt.nextConditionalResponsesExist===false" style="margin-right: 1%;"> 
            <img src="/assets/images/icons/attention.png" width="21" height="21"
           alt="" loading="lazy"> </a>
    </h3>
    
   

</div>

<br *ngIf="res.optionsExist === true && res.viewOptions === true && res.getOptionsByExtApi === false && res.getOptionsFromBotMembersInfo === false">
 <a *ngIf="res.optionsExist === true && res.viewOptions === true && res.getOptionsByExtApi === false && res.getOptionsFromBotMembersInfo === false"
style="color: blue;font-weight: bold;cursor: text;">Click on an Option to view linked Response</a>
<br *ngIf="res.optionsExist === true && res.viewOptions === true && res.getOptionsByExtApi === false && res.getOptionsFromBotMembersInfo === false">




 <br>
</div>



</div>


        

  
  
  <button *ngIf="res.viewOptions === true" (click)="res.viewOptions=false" 
  style="width: auto;float: right;" class="btn btn-link">View Less</button>

          
      
    </mat-card>

  </div>

  <ng-template #confirmUnlinkNextResponse let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to unlink next response to the the response "<a style="font-weight: bold;">{{res.response}}</a>" ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="unlinkNextResponse(res);modal.dismiss('Cross click')">Unlink</button>
    </div>
  </ng-template>
 
    </div>
  </div>
</div>














































































<!-- ng-template ng-template ng-template ng-template ng-template ng-template ng-template ng-template ng-template ng-template ng-template -->



<ng-template #confirmOptionDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation Deactivation</h4>
  </div>
  <div class="modal-body">
    Are you sure to deactivate the option <a style="font-weight: bold;">{{optionToDeactivate}}</a> ? <br>
    Upon deactivation, this option will not appear in the whatsapp conversation. <br>
    You can activate it again later.
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="botResOptionDeactivation();modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template> 

<ng-template #confirmOptionActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Option Activation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate the option <a style="font-weight: bold;">{{optionToDeactivate}}</a> ? <br>
    Upon activation, this option will appear in the whatsapp conversation. <br>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="botResOptionDeactivation();modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template>

<ng-template #attentionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Attention</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a style="font-weight: bold;">Next Response Or Options to this response not created !</a>
  </div> 
</ng-template>

<ng-template #attentionModal2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Attention</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a style="font-weight: bold;">Next Response to this option not created !</a>
  </div> 
</ng-template>

<ng-template #testPdfUrl let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">TEST URL </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    

    <form (ngSubmit)="test()" #aplnrForm="ngForm"> 

      <div class="col-md-6">
                  <label  class="required">Media File</label>
                  <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
                   class="form-control">
               </div>
      
          </form>

  </div> 
  <div class="modal-footer">
    <button type="button" style="width: auto;" class="btn btn-info" (click)="test();">Test API (Url)</button>
    <button type="button" style="width: auto;" class="btn btn-primary" (click)="test2();">Test API (PDF)</button>
  </div>
</ng-template>

<ng-template #optionsFromBotMembersInfoDetailSelection let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Choose Options Type</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
   
    <div>
      <label>Choose Options Type to attach to the response</label> <br>
      <mat-radio-group aria-label="gender" [(ngModel)]="optionsFromBotMembersInfoDetailType">
        <mat-radio-button name="gender" id="organisation type" value="organisation type"  >Organisation Type</mat-radio-button>
        <mat-radio-button name="gender" id="organisation location" value="organisation location"  >Organisation Location</mat-radio-button>
        <mat-radio-button name="gender" id="specialization" value="specialization"  >Specialization</mat-radio-button>
        <mat-radio-button name="gender" id="specialist name" value="specialist name"  >Specialist Name</mat-radio-button>
        <mat-radio-button name="gender" id="organisation name" value="organisation name"  >Organisation Name</mat-radio-button>
      </mat-radio-group>
    </div>

  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="setOptionsFromBotMembersInfoType();modal.dismiss('Cross click')">Save</button>
  </div>
</ng-template> 

<ng-template #ErrorResOptionLinkedResModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Next Linked Response</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a style="float: left;font-weight: bold;color: black;">Response {{nextResponseForErrorResOption.slNo}}</a> <br>
    <a style="float: left;font-weight: bold;color: black;">{{nextResponseForErrorResOption.response}}</a>

    <br>
    <br>
    <h4 style="margin: 0%;"
     *ngIf="nextResponseForErrorResOption.options !== null 
     && nextResponseForErrorResOption.options !== undefined 
     && nextResponseForErrorResOption.options.length > 0">Options :</h4>
    <div *ngFor="let opt of nextResponseForErrorResOption.options">
        <a style="color:black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> <br>
    </div>

  </div> 
</ng-template>

<ng-template #TemplateResOptionLinkedResModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Next Linked Response</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a style="float: left;font-weight: bold;color: black;">Response {{nextResponseForTemplateResOption.slNo}}</a> <br>
    <a style="float: left;font-weight: bold;color: black;">{{nextResponseForTemplateResOption.response}}</a>

    <br>
    <br>
    <h4 style="margin: 0%;"
     *ngIf="nextResponseForTemplateResOption.options !== null 
     && nextResponseForTemplateResOption.options !== undefined 
     && nextResponseForTemplateResOption.options.length > 0">Options :</h4>
    <div *ngFor="let opt of nextResponseForTemplateResOption.options">
        <a style="color:black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> <br>
    </div>

  </div> 
</ng-template>

