import { InvoiceDetails } from "./invoice-details";

export class Invoice {


	// details for basic invoice
    invoiceId!: string;
	invoiceDate!: Date;
	totalAmount!: any;
	totalQuantity : any;
	totalAlternateQuantity : any
	invoiceRemarks!: string;
	invoiceStatus!: boolean;
	fkCustomerId!: string;
	invoiceNo! : string;
	paymentMethod! : string;
	// array/list of invoice details
	invDetList: InvoiceDetails[] = [];
	taxesAndCess: InvoiceDetails[] = [];

	customerName : any
	updateCustomerOutstanding : any;
	outstandingAmount : any;

	discountObj: InvoiceDetails = new InvoiceDetails();
	totalTax : any;
	grandTotal : any;
	totalCess : any;
	orderStatus! : string;
	type ! : string;

	subTotalAmount = 0;

	subscriptionFromDate : any
	subscriptionToDate : any
}
