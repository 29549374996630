import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-test-graph',
  templateUrl: './test-graph.component.html', 
  styleUrls: ['./test-graph.component.scss']
})
export class TestGraphComponent implements OnInit {

 
  constructor() { }

  ngOnInit(): void {

  }


}
