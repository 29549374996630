<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='create crmList'"
    style="color: white;" class="modal-title pull-left">Create List</h4>
    <h4 *ngIf="fromParent.context==='edit crmList'"
    style="color: white;" class="modal-title pull-left">Edit List</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card-body"> 
    <form #adminForm="ngForm">  

   
    <div>
        <div>
            <label class="required" for="listName">Name</label>
            <input #listName="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
             name="listName" id="listName" [(ngModel)]="crmList.listName">
            <div class="alert alert-danger" *ngIf="listName.touched && !listName.valid">Name is Required</div>
            </div>

            <br>
    
          <div>
              <label for="listDescription">Description</label>
              <textarea class="form-control" type="text" name="listDescription" 
               id="listDescription" [(ngModel)]="crmList.listDescription"></textarea>
           </div> 
    </div>


    





</form>
</div>
</div>

<div class="modal-footer">
<button *ngIf="fromParent.context==='create crmList' || fromParent.context==='edit crmList'"
 [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="createOrEditList();">save</button>
</div>