import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OfficesService } from "src/app/services/office/offices.service";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { BotAdminNotification } from "src/app/BOT_CODE/bot_classes/bot-admin-notification";
import { DatePipe } from "@angular/common";
import { TableUtil } from "src/app/table-util";
import { ViewBotFormResponsesComponent } from "src/app/BOT_CODE/bot_modals/bot_forms/view-bot-form-responses/view-bot-form-responses.component";
import { ViewBotMediaForAgentComponent } from "src/app/BOT_CODE/bot_modals/bot-media/view-bot-media-for-agent/view-bot-media-for-agent.component";
import { BotAutoSchedulerSettings } from "src/app/BOT_CODE/bot_classes/bot-auto-scheduler-settings";
import { CreateBotAutoSchedulerSettingsComponent } from "src/app/BOT_CODE/bot_modals/bot_auto_scheduler_settings/create-bot-auto-scheduler-settings/create-bot-auto-scheduler-settings.component";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";

@Component({
  selector: "app-admin-notifications-home",
  templateUrl: "./admin-notifications-home.component.html",
  styleUrls: ["./admin-notifications-home.component.scss"],
})
export class AdminNotificationsHomeComponent implements OnInit {
  currentRole = "CB_OFFICE_ADMIN";

  showSpinner = true;
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser: any;
  userOptions: any;
  closeResult!: string;

  newNotificationsListType = "table";
  newNotesListType = "table";
  searchText2: any;
  viewedNotificationsListType = "table";
  manualChatReqNotificationsListType = "table";
  userNotificationsListType = "table";

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
    wcaCallbackUrlVerificationStatus: "",
    wcaPermanentToken: "",
    wcaPhoneNumberID: "",
    whatsappNumber: "",
    officeTimezone: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private ngbModalService: NgbModal,
    private botResponseService: BotResponseServiceService,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private datePipe: DatePipe,
    private botOfficeService: BotOfficeServiceService,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;

    this.userId = this.loggedInUser.userId;
  }

  botResponse: BotResponse = new BotResponse();
  allResponses: BotResponse = new BotResponse();
  convoInitiationTreeDef: BotResponse = new BotResponse();
  UpdateNewUserDetailsTreeDefs: BotResponse[] = [];
  botAgent: BotUser = new BotUser();
  viewResponses = false;

  theme: any;
  currentTimezone: any;
  settingsExists = true;
  notifyManualChatRequestsSettings: BotAutoSchedulerSettings =
    new BotAutoSchedulerSettings();
  notifyAgentsAssignmentSettings: BotAutoSchedulerSettings =
    new BotAutoSchedulerSettings();
  agentsNewOrdersPlacedNotificationSettings: BotAutoSchedulerSettings =
    new BotAutoSchedulerSettings();

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "admin/getOfficeByIdForBotOfficeAdmin"
      )
      .then((res) => {
        this.officeObject = res;
        this.currentTimezone = this.officeObject.officeTimezone;
      });

    this.getBotAgents();
    this.getBotAutoSchedulerSettingsByOfficeId();

    let obj: BotAdminNotification = new BotAdminNotification();
    obj.botAdminNotificationType = this.searchType = "custom convo flow notification";
    obj.from = null;
    obj.to = null;
    this.getAdminNotifications(obj);

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  groupNotfns: BotAdminNotification[] = [];
  groupNotifications() {
    this.groupNotfns = [];
    let uniqueTitles: any[] = [];
    this.newCustomConvoFlowNotifications.newNotifications.forEach((notf) => {
      uniqueTitles.push(notf.botAdminNotificationTitle);
    });

    uniqueTitles = uniqueTitles.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    uniqueTitles.forEach((title) => {
      let groupNotf: BotAdminNotification = new BotAdminNotification();
      groupNotf.botAdminNotificationTitle = title;
      groupNotf.splitMessage = false;
      let newNotifications: BotAdminNotification[] = [];
      this.newCustomConvoFlowNotifications.newNotifications.forEach((notf) => {
        if (title === notf.botAdminNotificationTitle) {
          newNotifications.push(notf);
        }
      });
      groupNotf.newNotifications = newNotifications;
      groupNotf = this.splitNotificationMessages(groupNotf);
      this.groupNotfns.push(groupNotf);
    });

    this.grpNewConvTableBasedOnTitle = true;
  }

  groupNotes: BotAdminNotification[] = [];
  groupNotesFunction() {
    this.groupNotes = [];
    let uniqueTitles: any[] = [];
    this.newCustomConvoFlowNotes.newNotifications.forEach((notf) => {
      uniqueTitles.push(notf.botAdminNotificationTitle);
    });

    uniqueTitles = uniqueTitles.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    uniqueTitles.forEach((title) => {
      let groupNotf: BotAdminNotification = new BotAdminNotification();
      groupNotf.botAdminNotificationTitle = title;
      groupNotf.splitMessage = false;
      let newNotifications: BotAdminNotification[] = [];
      this.newCustomConvoFlowNotes.newNotifications.forEach((notf) => {
        if (title === notf.botAdminNotificationTitle) {
          newNotifications.push(notf);
        }
      });
      groupNotf.newNotifications = newNotifications;
      groupNotf = this.splitNotificationMessages(groupNotf);
      this.groupNotes.push(groupNotf);
    });

    this.grpNewNotesTableBasedOnTitle = true;
  }

  headers: any[] = [];
  data: any[] = [];
  grpNewConvTableBasedOnTitle = false;
  grpNewNotesTableBasedOnTitle = false;

  splitNotificationMessages(groupedNotification: BotAdminNotification) {
    this.headers = [];
    this.data = [];

    this.headers.push("Name");
    this.headers.push("Phone");
    this.headers.push("Date");

    let dyHeaders: any[] = [];

    groupedNotification.newNotifications.forEach((notf) => {
      let notfData: any[] = [];

      notfData.push(notf.fkBotUserName);
      notfData.push(notf.fkBotUserPhone);
      notfData.push(notf.formattedTimestamp);

      let msg: any = notf.botAdminNotificationMessage;

      msg = msg.split(";;");

      msg.forEach((pair: any) => {
        pair = pair.split("::");

        let count = 0;
        pair.forEach((pairElement: any) => {
          if (count === 0) {
            dyHeaders.push(pairElement);
          } else if (count === 1) {
            notfData.push(pairElement);
          }
          count++;
        });
      });
      this.data.push(notfData);
    });

    dyHeaders = dyHeaders.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    dyHeaders.forEach((dyHdr) => {
      this.headers.push(dyHdr);
    });

    groupedNotification.headers = this.headers;
    groupedNotification.data = this.data;

    return groupedNotification;
  }

  tableNewUserNotifsExists = false;
  tableNewConvoFlowNotifsExists = false;
  tableNewCustomConvoFlowNotesExists = false;
  tableNewManualChatNotifsExists = false;
  tableNewFormResSubNotifsExists = false;
  tableViewedNotifsExists = false;

  searchText3: any;
  searchText4: any;
  searchText5: any;
  searchText6: any;
  searchText7: any;
  searchText8: any;

  newNotifications: BotAdminNotification[] = [];

  newUserNotifications: BotAdminNotification = new BotAdminNotification();
  newCustomConvoFlowNotifications: BotAdminNotification =
    new BotAdminNotification();
  newCustomConvoFlowNotes: BotAdminNotification = new BotAdminNotification();
  newFormResSubmissionNotifications: BotAdminNotification =
    new BotAdminNotification();
  manualChatRequestNotifications: BotAdminNotification =
    new BotAdminNotification();

  searchType = "";
  searchByDate() {
    this.showSpinner = true;
    let obj: BotAdminNotification = new BotAdminNotification();
    obj.botAdminNotificationType = this.searchType;
    obj.from = this.fromDateTime;
    obj.to = this.toDateTime;
    this.getAdminNotifications(obj);
  }

  getAdminNotifications(obj: BotAdminNotification) {
    this.showSpinner = true;

    this.tableNewUserNotifsExists = false;
    this.tableNewConvoFlowNotifsExists = false;
    this.tableNewCustomConvoFlowNotesExists = false;
    this.tableNewManualChatNotifsExists = false;
    this.tableNewFormResSubNotifsExists = false;

    this.botResponseService
      .getBotAdminNotifications(
        environment.apiUrl + "botConvo/getBotAdminNotifications",
        obj
      )
      .then((res) => {
        this.newNotifications = [];

        let resObj: BotAdminNotification = new BotAdminNotification();
        resObj = res;

        let fromT = resObj.from;
        fromT = Date.UTC(fromT[0], fromT[1] - 1, fromT[2], fromT[3], fromT[4]);
        fromT = this.datePipe.transform(fromT, "dd-MM-yy, h:mm a");

        let toT = resObj.to;
        toT = Date.UTC(toT[0], toT[1] - 1, toT[2], toT[3], toT[4]);
        toT = this.datePipe.transform(toT, "dd-MM-yy, h:mm a");

        this.newNotifications = resObj.newNotifications;

        this.newUserNotifications.newNotifications =
          this.newCustomConvoFlowNotifications.newNotifications =
          this.newFormResSubmissionNotifications.newNotifications =
          this.manualChatRequestNotifications.newNotifications =
          this.newCustomConvoFlowNotes.newNotifications =
            [];
        this.newUserNotifications.from =
          this.newCustomConvoFlowNotifications.from =
          this.newFormResSubmissionNotifications.from =
          this.manualChatRequestNotifications.from =
          this.newCustomConvoFlowNotes.from =
            fromT;
        this.newUserNotifications.to =
          this.newCustomConvoFlowNotifications.to =
          this.newFormResSubmissionNotifications.to =
          this.manualChatRequestNotifications.to =
          this.newCustomConvoFlowNotes.to =
            toT;

        this.newNotifications.forEach((notification) => {
          if (
            notification.timestamp !== null &&
            notification.timestamp !== undefined
          ) {
            let x = notification.timestamp;
            // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
            notification.formattedTimestamp = this.datePipe.transform(
              x,
              "d/M/yy, h:mm a"
            );
          }
        });

        this.newNotifications.forEach((notification) => {
          if (
            notification.botAdminNotificationType === "new user notification"
          ) {
            this.newUserNotifications.newNotifications.push(notification);
            this.tableNewUserNotifsExists = true;
          } else if (
            notification.botAdminNotificationType ===
            "custom convo flow notification"
          ) {
            this.tableNewConvoFlowNotifsExists = true;

            if (
              notification.botAdminNotificationMedia !== null &&
              notification.botAdminNotificationMedia !== undefined
            ) {
              let media: BotAdminNotification[] = [];

              let mediaMessage = notification.botAdminNotificationMedia;
              let mediaMessageArray = mediaMessage.split(";;");

              mediaMessageArray.forEach((message: any) => {
                let splitMsg = message.split("::"); //xoxo

                let mediaObj: BotAdminNotification = new BotAdminNotification();
                mediaObj.mediaName = splitMsg[0];
                mediaObj.mediaType = splitMsg[1];
                mediaObj.mediaObjId = splitMsg[2];

                media.push(mediaObj);
              });

              notification.media = media;
            }
            this.newCustomConvoFlowNotifications.newNotifications.push(
              notification
            );

            if (this.grpNewConvTableBasedOnTitle === true) {
              this.groupNotifications();
            }
          } else if (
            notification.botAdminNotificationType === "custom convo flow notes"
          ) {
            this.tableNewCustomConvoFlowNotesExists = true;
            if (
              notification.botAdminNotificationMedia !== null &&
              notification.botAdminNotificationMedia !== undefined
            ) {
              let media: BotAdminNotification[] = [];

              let mediaMessage = notification.botAdminNotificationMedia;
              let mediaMessageArray = mediaMessage.split(";;");

              mediaMessageArray.forEach((message: any) => {
                let splitMsg = message.split("::");

                let mediaObj: BotAdminNotification = new BotAdminNotification();
                mediaObj.mediaName = splitMsg[0];
                mediaObj.mediaType = splitMsg[1];
                mediaObj.mediaObjId = splitMsg[2];

                media.push(mediaObj);
              });

              notification.media = media;
            }

            this.newCustomConvoFlowNotes.newNotifications.push(notification);
          } else if (
            notification.botAdminNotificationType ===
            "form response submission notification"
          ) {
            this.tableNewFormResSubNotifsExists = true;
            this.newFormResSubmissionNotifications.newNotifications.push(
              notification
            );
          } else if (
            notification.botAdminNotificationType === "manual chat notification"
          ) {
            this.tableNewManualChatNotifsExists = true;
            this.manualChatRequestNotifications.newNotifications.push(
              notification
            );
          }
        });

        this.showSpinner = false;
      });
  }

  viewedNotifications: BotAdminNotification = new BotAdminNotification();
  fromDateTime = null;
  toDateTime = null;
  getViewedAdminNotifications() {
    this.tableViewedNotifsExists = false;

    if (this.fromDateTime !== null || this.toDateTime !== null) {
      let obj: BotAdminNotification = new BotAdminNotification();
      obj.from = this.fromDateTime;
      obj.to = this.toDateTime;
      this.botResponseService
        .getViewedBotAdminNotifications(
          environment.apiUrl +
            "botConvo/getViewedBotAdminNotificationsByTimestamp",
          obj
        )
        .then((res) => {
          this.tableViewedNotifsExists = true;

          this.viewedNotifications.newNotifications = [];
          let resObj: BotAdminNotification = new BotAdminNotification();
          resObj = res;

          let fromT = resObj.from;
          fromT = Date.UTC(
            fromT[0],
            fromT[1] - 1,
            fromT[2],
            fromT[3],
            fromT[4]
          );
          fromT = this.datePipe.transform(fromT, "dd-MM-yy, h:mm a");

          let toT = resObj.to;
          toT = Date.UTC(toT[0], toT[1] - 1, toT[2], toT[3], toT[4]);
          toT = this.datePipe.transform(toT, "dd-MM-yy, h:mm a");

          this.viewedNotifications.newNotifications =
            resObj.viewedNotifications;
          this.viewedNotifications.from = fromT;
          this.viewedNotifications.to = toT;

          if (
            this.viewedNotifications.newNotifications !== null &&
            this.viewedNotifications.newNotifications.length > 0
          ) {
            this.viewedNotifications.newNotifications.forEach(
              (notification) => {
                if (
                  notification.timestamp !== null &&
                  notification.timestamp !== undefined
                ) {
                  let x = notification.timestamp;
                  // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
                  notification.formattedTimestamp = this.datePipe.transform(
                    x,
                    "dd/MM/yy, h:mm a"
                  );
                }
              }
            );
            this.fromDateTime = null;
            this.toDateTime = null;
          } else {
            this.toaster.success("No Viewed Notifications to display !");
          }
        });
    } else {
      this.toaster.error("Select proper date-time range");
    }
  }

  updateRemarks(id: any, remarks: any) {
    this.botResponseService
      .markAdminNotificationAsViewed(
        environment.apiUrl +
          "botConvo/updateAdminNotificationRemarks/" +
          id +
          "/" +
          remarks
      )
      .then((res) => {
        this.toaster.success("Remarks updated successfully !");
        // this.getAdminNotifications();
      });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  viewFormResponse(formRespondentId: any, formId: any, formName: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotFormResponsesComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      formRespondentId: formRespondentId,
      formId: formId,
      formName: formName,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  viewBotMedia(botMediaId: any, mediaType: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotMediaForAgentComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMediaId: botMediaId,
      mediaType: mediaType,
      officeId: this.userOfficeId,
      getByBOT_MEDIA_ID: true,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUser) => {}
    );
  }

  dataSource: BotAdminNotification[] = [];
  exportArrayGeneric(notifications: BotAdminNotification[]) {
    if (
      notifications !== null &&
      notifications !== undefined &&
      notifications.length > 0
    ) {
      this.dataSource = notifications;
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map((x) => ({
        Customer_Name: x.fkBotUserName,
        Customer_Phone: x.fkBotUserPhone,
        Date: x.formattedTimestamp,
        Message: x.botAdminNotificationMessage,
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "notifications");
    } else {
      this.toaster.error("No Notifications to download !");
    }
  }

  exportTableGeneric(groupedNotification: BotAdminNotification) {
    TableUtil.exportTableToExcel(
      groupedNotification.botAdminNotificationTitle,
      groupedNotification.botAdminNotificationTitle
    );
  }

  getBotAgents() {
    this.showSpinner = false;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBotAgentsAndManualChatsByOffice"
      )
      .then((res) => {
        this.botAgent = res;
      });
  }

  botAgentUserIdToAssign = "";
  assignAgentToManualChat(botUserId: any, botAdminNotificationId: any) {
    if (this.botAgentUserIdToAssign !== "") {
      this.botOfficeService
        .assignAgentToManualChat(
          environment.apiUrl +
            "botConvo/assignAgentToManualChat/" +
            botUserId +
            "/" +
            this.botAgentUserIdToAssign
        )
        .then((res) => {
          var removeIndex = -1;
          this.manualChatRequestNotifications.newNotifications.forEach(
            (obj, index) => {
              if (obj.botAdminNotificationId === botAdminNotificationId) {
                removeIndex = index;
              }
            }
          );
          this.manualChatRequestNotifications.newNotifications.splice(
            removeIndex,
            1
          );

          this.botResponseService
            .markAdminNotificationAsViewed(
              environment.apiUrl +
                "botConvo/markAdminNotificationAsViewed/" +
                botAdminNotificationId
            )
            .then((res) => {
              this.botAgentUserIdToAssign = "";
              this.toaster.success("Agent assigned successfully !");
            });
        });
    } else {
      this.toaster.error("Choose an Agent to assign !");
    }
  }

  activeTabIndex = 0;
  tabClick(tab: any) {
    this.activeTabIndex = tab.index;
    if (tab.index !== 6) {
      this.updateNotifications(tab.index);
    }
  }

  refreshNotifications() {
    this.updateNotifications(this.activeTabIndex);
  }

  updateNotifications(index: any) {
    this.showSpinner = true;

    let obj: BotAdminNotification = new BotAdminNotification();
    obj.from = null;
    obj.to = null;

    if (index === 1) {
      this.searchType = "new user notification";
      obj.botAdminNotificationType = this.searchType;
      this.getAdminNotifications(obj);
    } else if (index === 0) {
      this.searchType = "custom convo flow notification";
      obj.botAdminNotificationType = this.searchType;
      this.getAdminNotifications(obj);
    } else if (index === 2) {
      this.searchType = "form response submission notification";
      obj.botAdminNotificationType = this.searchType;
      this.getAdminNotifications(obj);
    } else if (index === 3) {
      this.searchType = "manual chat notification";
      obj.botAdminNotificationType = this.searchType;
      this.getAdminNotifications(obj);
    } else if (index === 4) {
      this.searchType = ""; //viewed notifications
      this.showSpinner = false;
    } else if (index === 5) {
      this.searchType = "custom convo flow notes";
      obj.botAdminNotificationType = this.searchType;
      this.getAdminNotifications(obj);
    } else if (index === 7) {
      this.showSpinner = false;
      this.tableUserNotifsExists = false;
      this.userNotifications = new BotAdminNotification();
      this.userNotifications.newNotifications = [];
      this.userNotifications.fkBotUserName = null;
      this.usersExist = false;
      this.searchValue = null;
    }
  }

  markNotificationAsViewed(botAdminNotificationId: any) {
    this.botResponseService
      .markAdminNotificationAsViewed(
        environment.apiUrl +
          "botConvo/markAdminNotificationAsViewed/" +
          botAdminNotificationId
      )
      .then((res) => {
        this.toaster.success("Notification marked as viewed successfully !");

        if (this.searchType === "new user notification") {
          var removeIndex = -1;
          this.newUserNotifications.newNotifications.forEach((obj, index) => {
            if (obj.botAdminNotificationId === botAdminNotificationId) {
              removeIndex = index;
            }
          });
          this.newUserNotifications.newNotifications.splice(removeIndex, 1);
        } else if (this.searchType === "custom convo flow notification") {
          var removeIndex = -1;
          this.newCustomConvoFlowNotifications.newNotifications.forEach(
            (obj, index) => {
              if (obj.botAdminNotificationId === botAdminNotificationId) {
                removeIndex = index;
              }
            }
          );
          this.newCustomConvoFlowNotifications.newNotifications.splice(
            removeIndex,
            1
          );
        } else if (
          this.searchType === "form response submission notification"
        ) {
          var removeIndex = -1;
          this.newFormResSubmissionNotifications.newNotifications.forEach(
            (obj, index) => {
              if (obj.botAdminNotificationId === botAdminNotificationId) {
                removeIndex = index;
              }
            }
          );
          this.newFormResSubmissionNotifications.newNotifications.splice(
            removeIndex,
            1
          );
        } else if (this.searchType === "manual chat notification") {
          var removeIndex = -1;
          this.manualChatRequestNotifications.newNotifications.forEach(
            (obj, index) => {
              if (obj.botAdminNotificationId === botAdminNotificationId) {
                removeIndex = index;
              }
            }
          );
          this.manualChatRequestNotifications.newNotifications.splice(
            removeIndex,
            1
          );
        } else if (this.searchType === "custom convo flow notes") {
          var removeIndex = -1;
          this.newCustomConvoFlowNotes.newNotifications.forEach(
            (obj, index) => {
              if (obj.botAdminNotificationId === botAdminNotificationId) {
                removeIndex = index;
              }
            }
          );
          this.newCustomConvoFlowNotes.newNotifications.splice(removeIndex, 1);
        }
      });
  }

  usersExist = false;
  botCustomerUser: BotCustomerUser = new BotCustomerUser();
  searchValue = null;
  searchBotUsersByNameOrPhone() {
    if (this.searchValue !== null) {
      this.showSpinner = true;
      this.botOfficeService
        .getBotCustomerUsersByOfficeId(
          environment.apiUrl +
            "botOffice/searchBotUsersByNameOrPhone/" +
            this.searchValue
        )
        .then((res) => {
          this.botCustomerUser = new BotCustomerUser();
          this.botCustomerUser = res;

          if (
            this.botCustomerUser.users !== undefined &&
            this.botCustomerUser.users !== null &&
            this.botCustomerUser.users.length > 0
          ) {
            this.usersExist = true;
          } else {
            this.usersExist = false;
            this.toaster.error("Users Not Found");
          }
          this.showSpinner = false;
        });
    } else {
      this.toaster.error("Enter a name or phone number to search");
    }
  }

  userNotifications: BotAdminNotification = new BotAdminNotification();
  tableUserNotifsExists = false;

  getBotAdminNotificationsByUserId(id: any) {
    this.tableUserNotifsExists = false;
    this.botResponseService
      .getAdminNotifications(
        environment.apiUrl + "botConvo/getBotAdminNotificationsByUserId/" + id
      )
      .then((res) => {
        this.tableUserNotifsExists = true;

        this.userNotifications.newNotifications = [];
        let resObj: BotAdminNotification = new BotAdminNotification();
        resObj = res;

        this.userNotifications.newNotifications = resObj.newNotifications;

        if (
          this.userNotifications.newNotifications !== null &&
          this.userNotifications.newNotifications.length > 0
        ) {
          this.userNotifications.newNotifications.forEach((notification) => {
            if (
              notification.timestamp !== null &&
              notification.timestamp !== undefined
            ) {
              let x = notification.timestamp;
              // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
              notification.formattedTimestamp = this.datePipe.transform(
                x,
                "dd/MM/yy, h:mm a"
              );
            }
          });
        } else {
          this.toaster.success("No User Notifications to display !");
        }
      });
  }

  // NOTIFICATIONS CONFIGURATIONS

  // notify orders placed
  configureNopSettings() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotAutoSchedulerSettingsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      botAutoSchedulerSettings: this.agentsNewOrdersPlacedNotificationSettings,
      context: "Configure NOP Settings",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotAutoSchedulerSettings) => {
        this.agentsNewOrdersPlacedNotificationSettings = receivedEntry;
      }
    );
  }

  // notify orders placed
  createAgentsNewOrdersPlacedNotificationSettings() {
    this.agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId =
      null;
    this.agentsNewOrdersPlacedNotificationSettings.context = "NOP";
    this.agentsNewOrdersPlacedNotificationSettings.scheduleAfterDays = 0;

    this.botOfficeService
      .createBotSchedule(
        environment.apiUrl + "botOffice/createOrEditBotAutoSchedulerSettings",
        this.agentsNewOrdersPlacedNotificationSettings
      )
      .then((res) => {
        this.agentsNewOrdersPlacedNotificationSettings = res;
        this.toaster.success("Configuration created successfully !");
      });
  }

  // template change
  editBotAutoSchedulerSettings(botAutoSchedulerSettings: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotAutoSchedulerSettingsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      botAutoSchedulerSettings: botAutoSchedulerSettings,
      context: "Edit Settings",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotAutoSchedulerSettings) => {
        this.getBotAutoSchedulerSettingsByOfficeId();
      }
    );
  }

  // get all settings
  getBotAutoSchedulerSettingsByOfficeId() {
    this.notifyManualChatRequestsSettings.botAutoSchedulerSettingsId = null;
    this.notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId = null;
    this.agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId =
      null;

    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botOffice/getBotAutoSchedulerSettingsForNotificationsByOfficeId"
      )
      .then((res) => {
        let resObj: BotAutoSchedulerSettings = new BotAutoSchedulerSettings();
        resObj = res;
        resObj.list.forEach((autoSchSetting) => {
          if (autoSchSetting.context === "NAA") {
            this.notifyAgentsAssignmentSettings = autoSchSetting;
          } else if (autoSchSetting.context === "NMC") {
            this.notifyManualChatRequestsSettings = autoSchSetting;
          } else if (autoSchSetting.context === "NOP") {
            this.agentsNewOrdersPlacedNotificationSettings = autoSchSetting;
          }
        });
      });
  }

  // create settings
  createBotAutoSchedulerSettings(context: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotAutoSchedulerSettingsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      context: context,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotAutoSchedulerSettingsByOfficeId();
    });
  }

  // notify manual chat requests
  create_NMC_Settings() {
    let botAutoSchSetting: BotAutoSchedulerSettings =
      new BotAutoSchedulerSettings();
    botAutoSchSetting.botAutoSchedulerSettingsId = null;
    botAutoSchSetting.context = "NMC";
    botAutoSchSetting.scheduleAfterDays = 0;
    this.botOfficeService
      .createBotSchedule(
        environment.apiUrl + "botOffice/createOrEditBotAutoSchedulerSettings",
        botAutoSchSetting
      )
      .then((res) => {
        botAutoSchSetting = res;
        this.toaster.success("Auto-Scheduler Settings created successfully !");
        this.getBotAutoSchedulerSettingsByOfficeId();
      });
  }

  // notify agent assignments
  create_NAA_Settings() {
    let botAutoSchSetting: BotAutoSchedulerSettings =
      new BotAutoSchedulerSettings();
    botAutoSchSetting.botAutoSchedulerSettingsId = null;
    botAutoSchSetting.context = "NAA";
    botAutoSchSetting.scheduleAfterDays = 0;
    this.botOfficeService
      .createBotSchedule(
        environment.apiUrl + "botOffice/createOrEditBotAutoSchedulerSettings",
        botAutoSchSetting
      )
      .then((res) => {
        botAutoSchSetting = res;
        this.toaster.success("Auto-Scheduler Settings created successfully !");
        this.getBotAutoSchedulerSettingsByOfficeId();
      });
  }

  // status update
  botAutoSchedulerSettingsStatusUpdate(settings: BotAutoSchedulerSettings) {
    this.botResponseService
      .markAdminNotificationAsViewed(
        environment.apiUrl +
          "botOffice/botAutoSchedulerSettingsStatusUpdate/" +
          settings.botAutoSchedulerSettingsId
      )
      .then((res) => {
        if (settings.context === "NAA") {
          this.notifyAgentsAssignmentSettings.botAutoSchedulerSettingsStatus =
            !this.notifyAgentsAssignmentSettings.botAutoSchedulerSettingsStatus;
        } else if (settings.context === "NMC") {
          this.notifyManualChatRequestsSettings.botAutoSchedulerSettingsStatus =
            !this.notifyManualChatRequestsSettings
              .botAutoSchedulerSettingsStatus;
        } else if (settings.context === "NOP") {
          this.agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsStatus =
            !this.agentsNewOrdersPlacedNotificationSettings
              .botAutoSchedulerSettingsStatus;
        }
      });
  }
}
