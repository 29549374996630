import { BotItem } from "./bot-item";
import { BotResponse } from "./bot-response";

export class BotCategory {
  botCategoryId: any;
  botCategoryStatus: any;
  name: any;
  description: any;
  parentId: any;
  fkOfficeId: any;

  list: BotCategory[] = [];
  itemList: BotItem[] = [];

  panelOpenState = true;
  headerInfo: any;
  categoryTotalPriceOfItemsInCart = 0;
  categoryTotalNoOfItemsAddedInCart = 0;

  invoiceOrderStatus: any;
  orderDeleted: any;
  orderPlacedResponse: BotResponse = new BotResponse();
}
