import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  authToken: string | null = '';
  constructor(private http: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }


  changeUserPassword(avenue: string, email: string, oldPassword: string, newPassword: string): Promise<any> {
  
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const params = new HttpParams().set('email', email).set('oldPassword', oldPassword).set('newPassword', newPassword);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.put(avenue, params,{headers: headers})
        .toPromise()
        .then((data: any) => {
          resolve(data);
        }).catch((err) => {
          reject(err)
      });
    });
  }

}
