<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Feedback Question</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form (ngSubmit)="onSubmit()" #aqForm="ngForm"> 
        
<div class="form-group row">
  <div class="col-md-3">
    <label class="required"  for="activityQuestionOrder">Order</label>
    <input #Order="ngModel" placeholder="Enter Order" required class="form-control" type="number" name="activityQuestionOrder"  id="activityQuestionOrder" [(ngModel)]="feedbackQuestion.questionOrder">
    <div class="alert alert-danger" *ngIf="Order.touched && !Order.valid">Order is required</div>
 </div>

    <div class="col-md-9">
    <label class="required" for="activityQuestion">Question</label>
    <input maxlength="250" #activityPlannerName="ngModel" required placeholder="Enter Question" required class="form-control" type="text" name="activityQuestion"  id="activityQuestion" [(ngModel)]="feedbackQuestion.question">
    <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Enter Question</div>
    </div>
</div>
  

    <br>
    <div>
       <label class="required" for="activityPlannerDescription">Description</label>
       <textarea  maxlength="5000" placeholder="Enter Description" required 
       class="form-control" type="text"
    name="activityPlannerDescription"  id="activityPlannerDescription"
     [(ngModel)]="feedbackQuestion.questionDescription"></textarea>
    </div> 
<br>



<!-- <div>
  <label class="required" for="selectedUroId">Answer</label> 
      <select required  class="form-control" [(ngModel)]="feedbackQuestion.fkAnswerId" id="selectedUroId" name="selectedUroId">
        <option value="" disabled >Choose Answer</option>
        <option  *ngFor="let ans of feedbackAnswers"  [ngValue]="ans.id">{{ans.answer}}</option>
      </select>
  </div>
  <br> -->
  <!-- <div>
    <a matTooltip="Create answer" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
    (click)="createFeedbackAnswer()">
    <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
  </a> 
  </div> -->
  


</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
</div>