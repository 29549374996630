<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4  style="color: white;" class="modal-title pull-left">Edit User</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
        <div class="card-body">
    <form (ngSubmit)="onSubmit" #adminForm="ngForm">         
      <br>

      <div *ngIf="isChildStudent!==true">

 
      <h3 style="text-align: center;font-weight: bold;">Basic Details</h3>
    
        <div class="form-group row">
         
          <div class="col-md-6">
          <label class="required" for="firstName">First Name</label>
          <input #firstName="ngModel"  maxlength="50" required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
          <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
          </div>
        
          <div class="col-md-6">
          <label class="required" for="lastName">Last Name</label>
          <input #lastName="ngModel"  maxlength="50" class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
          <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
        </div>


        
        
        </div>
        
        
        <div class="form-group row">
        
          <div class="col-md-6">
          <label class="required" for="email">Email</label>
          <input readonly #email="ngModel" maxlength="250" email required class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
          <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
          </div>
        
          <div class="col-md-2">
            <label class="required" for="countryDialCode"> Code</label>
            <select class="form-control" name="countryDialCode" id="countryDialCode"
            [(ngModel)]="combinedPTC.countryDialCode">
            <option value="" disabled selected>Select Country</option>
            <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
            </select>
            </div>
            
          <div class="col-md-4">
          <label class="required" for="phoneNumber">Phone Number</label>
          <input #phone="ngModel" required maxlength="250"  minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
          <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
          </div>

         
        
        </div>

        <div class="form-group row">

          <div class="col-md-6">
            <label for="dateOfBirth">Date of Birth</label>
            <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
            </div>

            <div class="col-md-6">
              <label >Gender </label> <br>
              <mat-radio-group aria-label="gender" [(ngModel)]="combinedPTC.gender">
                <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
                <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
                <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
              </mat-radio-group>
            </div>

        </div>
           
        <!-- <div class="form-group row">
        
          <div class="col-md-4" *ngFor="let ud of combinedPTC.userDetails">
            <div *ngIf="ud.mandatory">
              <label class="required" for="ud.displayName">{{ud.displayName}}</label>
            <input class="form-control" maxlength="250"  type="text"
            required placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
             [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
            </div>

            <div *ngIf="!ud.mandatory" >
              <label for="ud.displayName">{{ud.displayName}}</label>
            <input class="form-control" maxlength="250"  type="text"
             placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
             [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
            </div>
          
          </div>
        
  
        </div> -->

        <div *ngFor="let formConfigGroup of formConfigGroups">
          <br>
            <h3 style="text-align: center;font-weight: bold;">{{formConfigGroup.groupName}}</h3>
  
            <div class="form-group row">
  
              <div class="col-md-4" *ngFor="let ud of formConfigGroup.userDetails">
                <div *ngIf="ud.mandatory">
                  <label class="required" for="ud.displayName">{{ud.displayName}}</label>
                <input class="form-control" maxlength="250"  type="text"
                required placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                 [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                </div>
    
                <div *ngIf="!ud.mandatory" >
                  <label for="ud.displayName">{{ud.displayName}}</label>
                <input class="form-control" maxlength="250"  type="text"
                 placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                 [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                </div>
              
              </div>
            </div>
          </div>
          
          <br>
          <h3 style="text-align: center;font-weight: bold;">Address Details</h3>
        
        <div class="form-group row">
        
          <div class="col-md-4">
          <label for="addressLine1">Address Line 1</label>
          <input maxlength="250"  class="form-control" type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
          </div>
        
          <div class="col-md-4">
          <label for="addressLine2">Address Line 2</label>
          <input maxlength="250"  class="form-control" type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
          </div>

          <div class="col-md-4">
            <label for="city">City</label>
            <input maxlength="250"  class="form-control" type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="combinedPTC.city">
            </div>
          
        
        </div>
        
        <div class="form-group row">
        
          <div class="col-md-4">
            <label for="state">State</label>
            <input maxlength="250"  class="form-control" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="combinedPTC.state">
            </div>
          
         
          <div class="col-md-4">
          <label for="country">Country</label>
          <input maxlength="250"  class="form-control" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="combinedPTC.country">
          </div>

          <div class="col-md-4">
            <label for="zipCode">Zip Code</label>
            <input  maxlength="250" class="form-control" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
            </div>
          
        
        </div>
      </div>

      <div *ngIf="isChildStudent===true">

 
        <h3 style="text-align: center;font-weight: bold;">Basic Details</h3>
      
          <div class="form-group row">
           
            <div class="col-md-4">
            <label class="required" for="firstName">First Name</label>
            <input #firstName="ngModel"  maxlength="50" required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
            <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
            </div>
          
            <div class="col-md-4">
            <label class="required" for="lastName">Last Name</label>
            <input #lastName="ngModel"  maxlength="50" class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
            <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
          </div>

          <div class="col-md-4">
            <label class="required" for="lastName">Nick Name</label>
            <input maxlength="50" class="form-control" type="text" placeholder="Enter nick name" name="petname" id="petname" [(ngModel)]="combinedPTC.petname">
          </div>
  
          </div>
  
          <div class="form-group row">
  
            <div class="col-md-6">
              <label for="dateOfBirth">Date of Birth</label>
              <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
              </div>
  
              <div class="col-md-6">
                <label >Gender </label> <br>
                <mat-radio-group aria-label="gender" [(ngModel)]="combinedPTC.gender">
                  <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
                  <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
                  <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
                </mat-radio-group>
              </div>
  
          </div>
             
        </div>
                </form>
        </div>
    </div>
</div>

<div class="modal-footer">
  <button  [disabled]="clicked"  [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Update</button>
</div>