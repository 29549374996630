import { CRMTaskConfiguration } from "src/app/CRM_MODULE/classes/crmtask-configuration";
import { BotNotificationConfiguration } from "./bot-notification-configuration";

export class BotAdminNotification {
  botAdminNotificationId: any;
  botAdminNotificationStatus: any;
  timestamp: any;
  formattedTimestamp: any;
  utcTimestamp: any;
  botAdminNotificationMessage: any;
  fkBotUserId: any;
  fkBotResponseId: any;
  fkOfficeId: any;
  fkFormRespondentId: any;
  viewed: any;
  botAdminNotificationType: any;
  botAdminNotificationMedia: any;
  fkBotUserName: any;
  fkBotUserPhone: any;
  fkBotTemplateId: any;
  from: any;
  to: any;
  userIds: any[] = [];
  formName: any;
  formId: any;
  botNotificationConfigurations: BotNotificationConfiguration[] = [];
  newNotifications: BotAdminNotification[] = [];
  viewedNotifications: BotAdminNotification[] = [];

  header: any;
  botAdminNotificationTitle: any;
  splitMessage: any;

  headers: any[] = [];
  data: any[] = [];

  media: BotAdminNotification[] = [];

  mediaName: any;
  mediaType: any;
  mediaObjId: any;
  fkBotAutoSchedulerSettingsId: any;
  botAdminNotificationRemarks: any;
  crmTaskConfigs: CRMTaskConfiguration[] = [];
}
