import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router'; 
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { DataTableDirective } from 'angular-datatables';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { environment } from 'src/environments/environment';
import { CustomerProfileModalComponent } from '../crmDashboards/customer-profile-modal/customer-profile-modal.component';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { OfficesService } from 'src/app/services/office/offices.service';
import { AddCombinedParentTeacherComponent } from 'src/app/course-management/combinedParentTeacher/add-combined-parent-teacher/add-combined-parent-teacher.component';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { ConvertToCustomerUserComponent } from 'src/app/crm/compCustomer/convert-to-customer-user/convert-to-customer-user.component';
import { TableUtil } from 'src/app/table-util';
 
@Component({
  selector: 'app-crm-admin-dashboard',
  templateUrl: './crm-admin-dashboard.component.html',
  styleUrls: ['./crm-admin-dashboard.component.scss']
})
export class CrmAdminDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";
  closeResult !: string;
  compCustDet : CompleteCustomerDetails = new CompleteCustomerDetails();
  loggedInUser: any;
  loggedUser: any;

  rebindDataTable() {}

  userName!: string;
  userId!: string;
  customer : CompleteCustomerDetails = new CompleteCustomerDetails();
  ccdetails: CompleteCustomerDetails[] = [];
  roleOptions: any = {};
  unSubcriber!: Observable<any>;

  customerOptions : any;
  customerExists!: boolean;
  showSpinner = false;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  ccd: any;
  customerId!: string;
  addressId!: string;
  officeId!: string;

constructor(private ngbModalService: NgbModal,
            public router: Router,private officeService : OfficesService,
            private ccdetailsService:CompCustDetailsService,
            private sharedDataService: SharedDataService,
            public bsModalRef: BsModalRef ) { }
            
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    navbarTitle : any;

    officeObject : any = {
      activeStatus: '',
      backgroundColor: '',
      id:'',
      logo: '',
      oa_ParentMentor:'',
      oa_SubAdmin: '',
      oa_CRMAdmin : '',
      oa_CourseAdmin : '',
      oa_ClassAdmin : '',
      oa_SkillReport : '',
      officeLocation: '',
      officeName: '',
      themeColour: '',
      type:'',
      leadConversionType:''
    }
    
    leadConversionType : any;
    ccdetailsDataSource = this.ccdetails;

  ngOnInit(): void {
    
    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.officeService.getOfficeByIdForClassAdmin(environment.apiUrl + "admin/getOfficeByIdForClassAdmin").then(
      res=>{ 
      this.officeObject = res; 
      this.leadConversionType = this.officeObject.leadConversionType;
      this.getCcDetails();
    });

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 1000,
      lengthChange: false,
      processing: true, 
      info:false
    }; 
  } 
  exportCcdetails() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.ccdetailsDataSource.map(x => ({
      Name: x.firstName + " " + x.lastName,
      Email : x.email,
      Phone: x.phoneNumber,
      JoinedDate : x.leadCreationDate
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "leads");
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  addCombinedPTCUser(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCombinedParentTeacherComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
let data = {
  userOfficeId: this.officeId 
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
      
      let customer : CompleteCustomerDetails = new CompleteCustomerDetails();
      customer.customerId = receivedEntry.customerId;
      customer.firstName = receivedEntry.firstName;
      customer.lastName = receivedEntry.lastName;
      customer.dateOfBirth = receivedEntry.dateOfBirth;
      customer.phoneNumber = receivedEntry.phoneNumber;
      customer.email = receivedEntry.email;
      customer.activeStatus=true;
      this.ccdetails.push(customer);

    });  
  }

  getCcDetails(){
    this.showSpinner = true;
    this.ccdetailsService.getCcDetailsList(environment.apiUrl + "crmCustAdd/getall/" + this.officeId).then(data => {
      this.ccdetails = data;
      this.ccdetailsDataSource = this.ccdetails;
      this.customerExists = true;
      this.showSpinner = false;
    });
  }

// authorized
  updateCustomerStatus(customerId : string){
this.showSpinner= true;
    this.ccdetailsService.changeStatus(environment.apiUrl + "crmCustAdd/statusUpdate/" + customerId).then( data =>{
      this.ccdetails.forEach((customer)=>{
        if(customer.customerId===customerId){
          customer.activeStatus=!customer.activeStatus;
        }
        this.showSpinner=false;
      });
    });
  }

  openCustomerProfileModalComponent(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(CustomerProfileModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: id ,
          type : 'CUSTOMER'
        }
       
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
     this.showSpinner = true;
      this.ccdetails.forEach((customer)=>{
        if(customer.customerId===id){
          customer.firstName=receivedEntry.firstName;
          customer.lastName=receivedEntry.lastName;
          customer.email=receivedEntry.email;
          customer.phoneNumber=receivedEntry.phoneNumber;
        }
      });
      this.showSpinner = false;
    });  
  }

    addNewCustomerUser(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(ConvertToCustomerUserComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
  
        let data = {
          type : 'CUSTOMER',
          case : 'CREATE CUSTOMER AND USER'
        }
  
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
        this.showSpinner = true;
        this.getCcDetails();
        this.showSpinner = false;
      });  
      
    }

     switchDashboard(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
        {
          size : 'sm',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        userId : this.userId,
        currentRole : this.currentRole
  
      }
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });    
    }

    goToAccountPage(){
      this.router.navigate(['user-account',this.userId])
    }
    
  getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }


  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
  
  reloadPage() {
    window.location.reload();
  } 

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
          
  }