<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Schedule Form : {{feedbackForm.name}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form (ngSubmit)="onSubmit()" #aqForm="ngForm"> 

  <div class="form-group row">

    <div class="col-md-4">
    <label class="required" for="botScheduleTitle">Title</label>
    <input #botScheduleTitle="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
    placeholder="Enter Title" name="botScheduleTitle" id="botScheduleTitle" [(ngModel)]="formActive.botScheduleTitle">
    <div class="alert alert-danger" *ngIf="botScheduleTitle.touched && !botScheduleTitle.valid">Title is Required</div>
    </div>
  
    <div class="col-md-4">
<label class="required" for="executionTimestamp">Share Form At</label>
<input class="form-control"  required [(ngModel)]="formActive.executionTimestamp" 
type="datetime-local" id="executionTimestamp" name="executionTimestamp">
    </div>

    <div class="col-md-4">
      <label  class="required" for="targetEndDate">Form Expiry Date</label>
      <input required class="form-control" [(ngModel)]="formActive.toDate" type="date" id="targetEndDate"
       name="targetEndDate">
  </div>

</div>



<br>

<div>
  <label class="required" for="urlPrefixResponse">Message to send along Url</label>
  <textarea required class="form-control" type="text" name="urlPrefixResponse" 
   id="urlPrefixResponse" [(ngModel)]="formActive.urlPrefixResponse"></textarea>
</div> 

    

    <br>

    <div>
      <label for="botScheduleDescription">Description</label>
      <textarea class="form-control" type="text" name="botScheduleDescription" 
       id="botScheduleDescription" [(ngModel)]="formActive.botScheduleDescription"></textarea>
   </div> 
        
<br>


<div>
  <label class="required"> Users / Customers</label>
  <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
    <mat-label>Choose Users</mat-label>
    <mat-select [formControl]="toppingsControl" required
    (selectionChange)="multiSelectValueChange($event.value);" multiple>

    <!-- <input class="form-control" [(ngModel)]="stringValue" (keyup)="onKey($event)"> -->
<input class="form-control" type="text" name="search" [(ngModel)]="searchText" 
autocomplete="off" placeholder="Search users by name or phone number">

      <mat-option *ngFor="let cb of selectedUsers | filter:searchText" [value]="cb.botUserId">{{cb.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>



</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
</div>