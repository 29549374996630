import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Office } from 'src/app/models/office';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimeoutScreenComponent } from 'src/app/auth/timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from 'src/app/services/destroyService';
import {GlobalClass} from 'src/app/global/global-class';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-dashboard',
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeneralDashboardComponent extends DestroyService implements OnInit {

  loggedInUser;
  userName: string = 'User';
  navbarTitle : any;

  public screenWidth: any;
  public screenHeight: any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  constructor(public router: Router, private sharedDataService: SharedDataService,
    private modalService: BsModalService, private bnIdle: BnNgIdleService,
    private logoutService : LogoutService,
    public bsModalRef: BsModalRef) {
      super();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.sharedDataService.setfooterColor('#393B3C');
  }

  ngOnInit() {

    this.navbarTitle = GlobalClass.navbarTitle;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    // this.bnIdle.startWatching(600).pipe(takeUntil(this.unSubcriber)).subscribe((isTimedOut: boolean) => {
    //   this.bsModalRef = this.modalService.show(TimeoutScreenComponent, this.config);
    //   this.bsModalRef.content.closeBtnName = 'Close';
    //   const self = this;
    //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
    //     if (res.data === 'Logout') {
    //       this.bnIdle.stopTimer();
    //       this.router.navigate(['/login']);
    //     } else if (res.data === 'Successfully timed out') {
    //       this.bnIdle.stopTimer();
    //       this.router.navigate(['/login']);
    //     } else {          
    //     this.bnIdle.resetTimer();
    //     }
    //   }
    //   );
    // });  
  }
 
  logOut(): void {
    localStorage.removeItem('auth-token');
    localStorage.clear();
 
    // email to username change
    // this.logoutService.deleteUserSession(this.loggedInUser.email).subscribe(data=>{
    // })
    window.location.href=`${environment.authUrl}/logout`;

  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  setTheme(subscription: any) {
    for (let i = 0; i < this.loggedInUser.offices.length; i++) {
      if (this.loggedInUser.offices[i].id === subscription.officeId) {
        this.sharedDataService.setCardOfficeThemeDtls(this.loggedInUser.offices[i].theme);
        this.sharedDataService.setfooterColor(this.loggedInUser.offices[i].theme);
      }
    }
  }

  navigate(subscription: any) {

    if (subscription.role.dashboard === 'STUDENT_DASH_BOARD') {
      this.setTheme(subscription);
      this.router.navigate(['/dashboard/student']).then();
    }
    else if (subscription.role.dashboard === 'TEACHER_DASH_BOARD') {
      this.setTheme(subscription);
      this.router.navigate(['/dashboard/teacher']).then();
    }
    else {
      this.setTheme(subscription);
      this.router.navigate(['/dashboard/parent']).then();
    }

  }
  reloadPage() {
    window.location.reload();
  }
}
