import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActivityVideoLink } from 'src/app/courseManagementClasses/activity-video-link';
import { AddActPlnrCurrActPlnr } from 'src/app/courseManagementClasses/add-act-plnr-curr-act-plnr';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-activity-planner',
  templateUrl: './add-activity-planner.component.html',
  styleUrls: ['./add-activity-planner.component.scss']
})
export class AddActivityPlannerComponent implements OnInit {
  loggedInUser: any;
  theme: any;

 constructor(public bsModalRef: BsModalRef,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService, private sharedDataService : SharedDataService,
              private curriculumService : CurriculumService,
              private activityPlannerService : ActivityPlannerService,
              private imageService: FileServiceService ) { }
              
  clicked = false;
  selectedFile!: File;
  videoDivState : boolean = false;
  curriculums : Curriculum[] = []; 
  subId!: string;
  actPlnrCAP : AddActPlnrCurrActPlnr = new AddActPlnrCurrActPlnr();
  activityVideoLink : ActivityVideoLink = new ActivityVideoLink();
  activityVideoLinkList : ActivityVideoLink[] = [];
  videoCount : number = 0;

  oa_Assignment : any ;
        oa_ParentFeedback: any ;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.actPlnrCAP.curId = "";

    this.subId = this.fromParent.subId;
    this.oa_Assignment = this.fromParent.oa_Assignment;
    this.oa_ParentFeedback = this.fromParent.oa_ParentFeedback;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.curriculumService.getCurriculumListBySubId(this.subId).subscribe(
      data=>{
        this.curriculums = data;
      });

   }

   getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
   openVideoDiv(){
     this.videoDivState = true;
   }

   onVideoSubmission(){
     this.activityVideoLinkList.push(this.activityVideoLink);
     this.activityVideoLink = new ActivityVideoLink();
     this.videoDivState = false;
     this.videoCount = this.videoCount + 1;
     
   }

   imageUpload = false;
   onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
    this.imageUpload = true;
  }

   onSubmit(){
    this.actPlnrCAP.activityVideoLinkList = this.activityVideoLinkList;
    this.actPlnrCAP.fkSubjectId = this.subId;
    this.activityPlannerService.addActivityPlannerCAP(environment.apiUrl + 'currActPlanner/addCurrCAP', this.actPlnrCAP).then(
      data=>
      {
       let xyz = data;
       this.actPlnrCAP.activityPlannerId = xyz.activityPlannerId;
        if(this.imageUpload===false){
          this.passEntry.emit(this.actPlnrCAP.curId);
          this.activeModal.close();
          this.toaster.success("Activity / Assessment Created Successfully");  
        }else if(this.imageUpload===true){
          let body = new FormData();
          body.append("file", this.selectedFile);
  
          this.imageService.uploadActivityPlannerImage(body, this.actPlnrCAP.activityPlannerId).subscribe(
            data=>{
              this.passEntry.emit(this.actPlnrCAP.curId);
              this.activeModal.close();
              this.toaster.success("Activity / Assessment Created Successfully");
            });
        }


 
      });
    }

    closeUpdateModal(){
      this.activeModal.close();
    }  
} 
