    <div [ngStyle]="backgroundStyle" class="modal-header">
        <h4 style="color: white;" *ngIf="type !== 'order'" class="modal-title pull-left">View Invoice </h4>
        <h4 style="color: white;" *ngIf="type === 'order'" class="modal-title pull-left">View Order </h4>          <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div> 



    <div class="modal-body">
     <div class="card">
      <div class="card-body">
       <form>

 

 
            <div class="form-group row">
                <div class="col-md-6">
                <label style="font-weight: bold;" for="customerName">Customer : </label> <label> {{invoice.customerName}}</label>
                </div>

                <div class="col-md-6">
                    <label style="font-weight: bold;"  for="invoiceDate">Invoice Date : </label><label> {{invoice.invoiceDate}}</label>
                    </div>
              
                    <div class="col-md-6">
                        <label  style="font-weight: bold;"  for="invoiceNo">Invoice No : </label><label> {{invoice.invoiceNo}}</label>
                        </div>
        
                    <div  *ngIf="type !== 'order'" class="col-md-6">
                    <label style="font-weight: bold;"  for="paymentMethod" >Payment Method : </label><label> {{invoice.paymentMethod}}</label>
                    </div>

                    <div  *ngIf="type === 'order'" class="col-md-6">
                        <label style="font-weight: bold;"  for="orderStatus" >Order Status : </label><label> {{invoice.orderStatus}}</label>
                        </div>
            </div> 


           
            

            <div>
                <table border="1">
                 <thead>
                        <tr>
                            <th [ngStyle]="backgroundStyle"  >Item Name</th>
                            <th [ngStyle]="backgroundStyle" >Quantity</th>
                            <th [ngStyle]="backgroundStyle" >Price per Item</th>
                            <th [ngStyle]="backgroundStyle" >Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor = "let invDet of invDetails">
                            <td>{{invDet.itemName}}</td>
                            <td style="text-align: right;">{{invDet.quantity}}</td>
                            <td style="text-align: right;">{{invDet.pricePerItem}}</td>
                            <td style="text-align: right;">{{invDet.totalPrice}}</td>
                           
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td style="font-weight: bold; text-align: right;">Total</td>
                            <td style="font-weight: bold; text-align: right;">{{invoice.totalAmount}}</td>
                        </tr>
                    </tbody>
            </table>
        </div> <br> 


        <div>
            <label style="font-weight: bold;"  for="invoiceRemarks">Invoice Remarks : </label> <label> {{invoice.invoiceRemarks}}</label>
            
            </div>
            
        </form>
    </div>
    </div>
   </div>