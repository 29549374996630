import { DatePipe, formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Announcement } from 'src/app/BIZ_CRM/biz_crm_classes/announcement';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { ViewOrdersTableComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-orders-table/view-orders-table.component';
import { ViewTargetBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-target-based-invoices/view-target-based-invoices.component';
// import { ViewExecTargetsByPeriodComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/target/view-exec-targets-by-period/view-exec-targets-by-period.component';
import { AnnouncementService } from 'src/app/BIZ_CRM/biz_crm_services/announcement.service';
import { TargetService } from 'src/app/BIZ_CRM/biz_crm_services/target.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
// fix_target_name_error
@Component({
  selector: 'app-bcrm-exe-home',
  templateUrl: './bcrm-exe-home.component.html',
  styleUrls: ['./bcrm-exe-home.component.scss']
})

export class BcrmExeHomeComponent implements OnInit {

  gridColumns = 3;
  currentRole = "BCRM_EXE";
  officeId!: string;
  loggedInUser: any;
  userName!: string;
  userId!: string;
  navbarTitle : any;

  showSpinner = false;

  orderStatus : any = {

    totalOrders:'',

    deliveredOrderCount:'',
    invoicedOrderCount:'',
    pendingApprovalOrderCount:'',
    shippedOrderCount: '',
    approvedOrderCount:'',
    cancelledOrderCount:'',

    totalCustomers: '',
    totalInvoices: '',
    totalLeads:'',
    totalPayments:'',
    totalProducts:'',
    totalShipments:'',

    allOrders:'',

    invoicedOrders:'',
    shippedOrders:'',
    deliveredOrders:'',
    pendingApprovalOrders:'',
    approvedOrders:'',
    cancelledOrders:''
  }

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  
  target : Target = new Target();
  achievedPercentage : any;
  announcements : Announcement[] = [];
  constructor(
              private router : Router,
              private announcementService : AnnouncementService,
              private invoiceService : InvoiceService,
              private datePipe : DatePipe,
              private targetService : TargetService,
              private ngbModalService: NgbModal,
              private sharedDataService : SharedDataService ) {
  }
  
  ngOnInit(): void {

    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;

    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    this.getTarget();
    this.getOrderStatus();
    this.getCurrentAnnouncements();
  }

  getCurrentAnnouncements(){
    this.announcementService.getCurrentAnnouncementsForExec(environment.apiUrl + "announcements/getCurrentAnnouncementsForExec").then(
      data=>{
        this.announcements = data;
        this.showSpinner = false;
      });
   }
    //authorized
  viewTargetPeriodBasedOrders(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewTargetBasedInvoicesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      targetId : this.target.id,
      targetStartDate : this.target.targetStartDate,
      targetEndDate : this.target.targetEndDate,
      targetName : this.target.targetName,
      targetValue : this.target.targetValue,
      targetAchieved : this.target.targetAchieved
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  } 

  viewOrdersTable(invoices : any, header : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewOrdersTableComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      invoices : invoices,
      header : header
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  //authorized
  ViewExecTargetsByPeriod(){
    // this.config.class = 'modal-md';
    // const modalRef = this.ngbModalService.open( ViewExecTargetsByPeriodComponent,
    //   {
    //     size : 'lg',
    //     scrollable: true,
    //     windowClass: 'app-modal-window',
    //     keyboard: false
    //   });
    // let data = { 
    // }
    // modalRef.componentInstance.fromParent = data;
    //   modalRef.result.then((result) => {
    //   }, (reason) => {
    //   });
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    // });    
  }

color1: ThemePalette = 'accent';
mode1: ProgressSpinnerMode = 'determinate';
value1 = 50;

  //authorized
  getTarget(){
    this.targetService.getBcrmManagerTarget(environment.apiUrl + "target/getBcrmExecTarget").then(
      data=>{
        this.target = data;

        this.achievedPercentage = this.target.targetAchieved / this.target.targetValue;
        this.achievedPercentage = this.achievedPercentage * 100;
      this.achievedPercentage =  Math.round((this.achievedPercentage + Number.EPSILON) * 100) / 100
        this.value1 = this.achievedPercentage;

        this.target.formattedTargetValue = formatNumber(Number(this.target.targetValue), 'en-US', '1.0-0');
        this.target.formattedTargetAchieved = formatNumber(Number(this.target.targetAchieved), 'en-US', '1.0-0');
      
        
       if(this.target.incentiveTierDto !== null){
 this.target.incentiveTierDto.incentive = formatNumber(Number(this.target.incentiveTierDto.incentive), 'en-US', '1.0-0');
      }
              
      if(this.target.eligibleIncentive !== null){
        this.target.eligibleIncentive = formatNumber(Number(this.target.eligibleIncentive), 'en-US', '1.0-0');
             }

        if(this.target.targetName !== null && this.target.targetName !== undefined){

        let sd = this.target.targetStartDate;        
        let startDate = new Date(sd);        
        this.target.targetStartDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');   
        
        let ed = this.target.targetEndDate;
        let endDate = new Date(ed);
        this.target.targetEndDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');  

        }

        this.target.outstandingOverdue =  Math.round((this.target.outstandingOverdue + Number.EPSILON) * 100) / 100;

        
      });
  }
//authorized
  getOrderStatus(){
    this.invoiceService.getOrderStatusForExecDashboard(environment.apiUrl + "invoice/getOrderStatusForExecDashboard").then(
      data=>{
        
       this.orderStatus = data;
       this.orderStatus.totalOrders = this.orderStatus.cancelledOrderCount + this.orderStatus.approvedOrderCount + this.orderStatus.shippedOrderCount + this.orderStatus.pendingApprovalOrderCount + this.orderStatus.invoicedOrderCount + this.orderStatus.deliveredOrderCount;
       this.orderStatus.allOrders = [];

       this.orderStatus.pendingApprovalOrders.forEach((value : any)=>{
        this.orderStatus.allOrders.push(value);
        value.invoiceDate = new Date(value.invoiceDate);
        value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
        value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      });
      this.orderStatus.invoicedOrders.forEach((value : any)=>{
       this.orderStatus.allOrders.push(value);
       value.invoiceDate = new Date(value.invoiceDate);
       value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
       value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      });
     this.orderStatus.shippedOrders.forEach((value : any)=>{
       this.orderStatus.allOrders.push(value);
       value.invoiceDate = new Date(value.invoiceDate);
       value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
       value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      });
     this.orderStatus.deliveredOrders.forEach((value : any)=>{
       this.orderStatus.allOrders.push(value);
       value.invoiceDate = new Date(value.invoiceDate);
       value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
       value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      });
     this.orderStatus.approvedOrders.forEach((value : any)=>{
       this.orderStatus.allOrders.push(value);
       value.invoiceDate = new Date(value.invoiceDate);
       value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
       value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      });
     this.orderStatus.cancelledOrders.forEach((value : any)=>{
       this.orderStatus.allOrders.push(value);
       value.invoiceDate = new Date(value.invoiceDate);
       value.invoiceDate = this.datePipe.transform(value.invoiceDate, "dd-MM-yyyy");
       value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      });

     this.showSpinner = false;
        });
  }

  customerDashboard(){
    this.router.navigate(['/dashboard/bcrm-exe-customers']); 
  }

  LeadDashboard(){
    this.router.navigate(['/dashboard/bcrm-exe-leads']);
  }

  ordersDashboard(){
    this.router.navigate(['/dashboard/bcrm-exe-orders']);
  }

  dueOrdersDashboard(){
    this.router.navigate(['/dashboard/bcrm-exe-due-orders']);
  }

  exploreTargets(){
    this.router.navigate(['/dashboard/bcrm-exe-targets']);
  }

  goToTicketSupportPage(){
    this.router.navigate(['dashboard/ticket-support']);
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }
 
}
