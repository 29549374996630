import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-view-bot-media-for-agent",
  templateUrl: "./view-bot-media-for-agent.component.html",
  styleUrls: ["./view-bot-media-for-agent.component.scss"],
})
export class ViewBotMediaForAgentComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botMedia: BotMedia = new BotMedia();
  officeId: any;

  botMediaId: any;
  mediaType: any;
  mimeType: any;

  unsafeImageUrl: any;
  safeImageUrl: any;

  unsafeImageUrlTest: any;
  safeImageUrlTest: any;

  url: any = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  pdfFile!: SafeResourceUrl;
  txtFile: any;

  playVideo = false;
  mediaLoaded = false;

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botMediaId = this.fromParent.botMediaId;
    this.mediaType = this.fromParent.mediaType;
    this.officeId = this.fromParent.officeId;

    if (
      this.fromParent.getByBOT_MEDIA_ID === true &&
      this.mediaType !== "any"
    ) {
      this.getBotMediaAsByteArrayForPreviewByBotMediaId();
    } else if (
      this.fromParent.getByBOT_MEDIA_ID === true &&
      this.mediaType === "any"
    ) {
      this.getMediaByteArrayAlongWithMimeType();
    } else if (this.fromParent.getByBOT_MEDIA_ID !== true) {
      this.getBotMediaForPreviewByObjectId();
    }
  }

  getBotMediaForPreviewByObjectId() {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByObjectId/" +
          this.botMediaId +
          "/" +
          this.officeId
      )
      .subscribe((data) => {
        if (this.mediaType === "image") {
          this.unsafeImageUrl = URL.createObjectURL(data);
          this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(
            this.unsafeImageUrl
          );
          this.mediaLoaded = true;
          this.mimeType = this.mediaType;
        }

        if (this.mediaType === "video") {
          this.url = URL.createObjectURL(data);
          this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.url);
          this.playVideo = true;
          this.mediaLoaded = true;
          this.mimeType = this.mediaType;
        }

        if (this.mediaType === "document") {
          this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          this.mediaLoaded = true;
          this.mimeType = this.mediaType;
        }
      });
  }

  getBotMediaAsByteArrayForPreviewByBotMediaId() {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByBotMediaId/" +
          this.botMediaId +
          "/" +
          this.officeId
      )
      .subscribe((data) => {
        if (this.mediaType === "image") {
          this.unsafeImageUrl = URL.createObjectURL(data);
          this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(
            this.unsafeImageUrl
          );
          this.mimeType = this.mediaType;
          this.mediaLoaded = true;
        }

        if (this.mediaType === "video" || this.mediaType === "audio") {
          this.url = URL.createObjectURL(data);
          this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.url);
          this.playVideo = true;
          this.mediaLoaded = true;
          this.mimeType = this.mediaType;
        }

        if (this.mediaType === "document") {
          this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          this.mediaLoaded = true;
          this.mimeType = this.mediaType;
        }
      });
  }

  getMediaByteArrayAlongWithMimeType() {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByBotMediaId/" +
          this.botMediaId +
          "/" +
          this.officeId
      )
      .subscribe((data) => {
        this.botOfficeService
          .getMimeType(
            environment.apiUrl +
              "botMedia/getMediaByteArrayAndMimeTypeFromWcaByBotMediaId/" +
              this.botMediaId +
              "/" +
              this.officeId
          )
          .then((res) => {
            this.mimeType = res.mime_type;

            if (
              this.mimeType === "image/jpeg" ||
              this.mimeType === "image/png"
            ) {
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(
                this.unsafeImageUrl
              );
              this.mediaLoaded = true;
            }

            if (
              this.mimeType === "video/mp4" ||
              this.mimeType === "video/3gp" ||
              this.mimeType === "audio/aac" ||
              this.mimeType === "audio/mp4" ||
              this.mimeType === "audio/mpeg" ||
              this.mimeType === "audio/ogg"
            ) {
              this.url = URL.createObjectURL(data);
              this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.url);
              this.playVideo = true;
              this.mediaLoaded = true;
            }

            if (
              this.mimeType === "text/plain" ||
              this.mimeType === "application/pdf" ||
              this.mimeType === "application/vnd.ms-powerpoint" ||
              this.mimeType === "application/msword" ||
              this.mimeType === "application/vnd.ms-excel" ||
              this.mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              this.mimeType ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
              this.mimeType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
                window.URL.createObjectURL(data)
              );
              this.mediaLoaded = true;
            }
          });
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
