import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private loggedInUserDetails: any = null;
  private loggedInUserBehaviorSubject = new BehaviorSubject<any>(
    this.loggedInUserDetails
  );
  loggedInUserObservable = this.loggedInUserBehaviorSubject.asObservable();

  private loggedInUserOfficeDetails: any = null;
  private loggedInUserOfficeBehaviorSubject = new BehaviorSubject<any>(
    this.loggedInUserOfficeDetails
  );
  loggedInUserOfficeObservable = this.loggedInUserOfficeBehaviorSubject.asObservable();

  private footerColor: any = '#393B3C';
  private footerColorBehaviorSubject = new BehaviorSubject<any>(
    this.footerColor
  );
  footerColorObservable = this.footerColorBehaviorSubject.asObservable();

  private previousURL: any = '/login';
  private previousURLBehaviorSubject = new BehaviorSubject<any>(
    this.previousURL
  );
  previousURLObservable = this.previousURLBehaviorSubject.asObservable();

  private cardOfficeTheme: any = null;
  private cardOfficeThemeBehaviorSubject = new BehaviorSubject<any>(
    this.cardOfficeTheme
  );
  cardOfficeThemeObservable = this.cardOfficeThemeBehaviorSubject.asObservable();

  constructor() {

  }

  setfooterColor(color: any): void {
    this.footerColor = color;
    this.footerColorBehaviorSubject.next(color);
  }

  getfooterColor(): any {
    return this.footerColor;
  }

  setpreviousURL(url: any): void {
    this.previousURL = url;
    this.previousURLBehaviorSubject.next(url);
  }

  getpreviousURL(): any {
    return this.previousURL;
  }

  setLoggedInUserDtls(loggedInUserDetails: any): void {
    this.loggedInUserDetails = loggedInUserDetails;
    this.loggedInUserBehaviorSubject.next(loggedInUserDetails);
  }

  getLoggedInUserDtls(): any {
    return this.loggedInUserDetails;
  }

  setLoggedInUserOfficeDtls(loggedInUserOfficeDetails: any): void {
    this.loggedInUserOfficeDetails = loggedInUserOfficeDetails;
    this.loggedInUserOfficeBehaviorSubject.next(loggedInUserOfficeDetails);
  }

  getLoggedInUserOfficeDtls(): any {
    return this.loggedInUserOfficeDetails;
  }

  setCardOfficeThemeDtls(cardOfficeThemeeDetails: any): void {
    this.cardOfficeTheme = cardOfficeThemeeDetails;
    this.cardOfficeThemeBehaviorSubject.next(cardOfficeThemeeDetails);
  }

  getCardOfficeTheme(): any {
    return this.cardOfficeTheme;
  }

}
