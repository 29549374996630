import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSubjectComponent } from 'src/app/course-management/subject/add-subject/add-subject.component';
import { UpdateSubjectComponent } from 'src/app/course-management/subject/update-subject/update-subject.component';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { ProductService } from 'src/app/service/product.service';
import { SubjectService } from 'src/app/service/subject.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-super-course-admin-subject-dashboard',
  templateUrl: './super-course-admin-subject-dashboard.component.html',
  styleUrls: ['./super-course-admin-subject-dashboard.component.scss']
})
export class SuperCourseAdminSubjectDashboardComponent implements OnInit {
  showSpinner : boolean = true;
  loggedInUser: any;
  loggedUser: any;
  productId!: string;
  product : Product = new Product();
  subjectListByProdId: Subject[] = [];
  userName !: string;
  userId!: string;
  imageDataFromServer : any;
  unsafeImageUrl: any;
  safeImageUrl: any;

  abc : any;
  xyz : any;
  xxx : any;
  
  subjectListWithSafeUrl : any[] =[ { subId : '', 
  name : '',
  description : '',
  subjectStatus : '',
  fkProductId : '',
  subjectImage : '',
  safeUrl : '' 
} ];
safeImageUrl2: any;
unsafeImageUrl2: any;

constructor(private route : ActivatedRoute,
  private subjectService : SubjectService,
  private productService : ProductService,
  private ngbModalService: NgbModal,
  public router: Router, 
  private logoutService : LogoutService,
  private imageService: FileServiceService,
  private sanitizer : DomSanitizer ) { }

config = {
backdrop: true,
ignoreBackdropClick: true,
keyboard: false,
class: 'modal-md',
windowClass: 'app-modal-window'
};

navbarTitle : any;
  

 
ngOnInit(): void {

  this.navbarTitle = GlobalClass.navbarTitle;

  this.productId = this.route.snapshot.params['productId'];

  this.getSubListByProductId(this.productId);

  this.loggedUser = localStorage.getItem('loggedUser');
  this.loggedInUser = JSON.parse(this.loggedUser);
  this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
  this.userId = this.loggedInUser.userId;
}

// authorized
getSubListByProductId(productId : any){
    this.subjectService.getSubjectsByProductIdSA( environment.apiUrl +  "subject/getSubjectsByProductIdSA/" + productId).then(
    data=>{
      this.subjectListWithSafeUrl = [];

      for(var index in data){

          this.subjectListWithSafeUrl.push( { 'subId' : data[index].subId,
                                              'name' : data[index].name,
                                              'description' : data[index].description,
                                              'subjectStatus' : data[index].subjectStatus,
                                              'fkProductId' : data[index].fkProductId,
                                              'subjectImage' : data[index].subjectImage,
                                              'safeUrl' : this.xyz
                                             });
      }

      if(this.subjectListWithSafeUrl===undefined || this.subjectListWithSafeUrl.length===0){
        this.showSpinner = false;
      }

      let count = 0;
      this.subjectListWithSafeUrl.forEach( (value) => {
        
        count++;

        this.imageService.getSubjectImage( value.subjectImage ).subscribe(
          data=>{
            this.xyz = data;
            this.abc = URL.createObjectURL(data);
            this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

            value.safeUrl = this.xyz;

          });

          if(count === this.subjectListWithSafeUrl.length){
            this.showSpinner = false;
          }
      }); 

    });

 

}

add(){
  this.add1(this.productId);
}

// authorized
add1(productId : string){ 
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(AddSubjectComponent,
    {
      size : 'lg', scrollable: true, windowClass: 'app-modal-window', keyboard: false
    });
    
  let data = { productId : productId }

  modalRef.componentInstance.fromParent = data;
  modalRef.result.then((result) => {
  }, (reason) => {
  });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : Subject) => {
    this.subjectListWithSafeUrl.pop();
    this.getSubListByProductId(this.productId);
  }); 
}

// authorized
editSubject(subId : string){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(UpdateSubjectComponent,
    {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
      subId : subId 
        }

        modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Subject) => {
      this.subjectListWithSafeUrl.pop();
      this.getSubListByProductId(this.productId);
    }); 

    
}



// switchDashboard(){
//   this.config.class = 'modal-md';
//   const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
//     {
//       size : 'sm',
//       scrollable: true,
//       windowClass: 'app-modal-window',
//       keyboard: false
//     });
//   let data = { 
//     userId : this.userId,
//     currentRole : this.currentRole
//   }
//   modalRef.componentInstance.fromParent = data;
//     modalRef.result.then((result) => {
//     }, (reason) => {
//     });
//   modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
//   });    
// }
closeResult !:string;
open(content: any) {
  this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

logOut(): void {

  localStorage.removeItem('auth-token');
  localStorage.clear();
  window.location.href=`${environment.authUrl}/logout`;
    
}

exploreSubject(subId : string){
  this.router.navigate(['dashboard/super-course-admin/curriculum',subId]);
}

  reloadPage() {
    window.location.reload();
  } 

}
