import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotCategory } from "src/app/BOT_CODE/bot_classes/bot-category";
import { BotItem } from "src/app/BOT_CODE/bot_classes/bot-item";
import { CreateBotCategoryComponent } from "src/app/BOT_CODE/bot_modals/bot_category/create-bot-category/create-bot-category.component";
import { ViewBotCategoriesComponent } from "src/app/BOT_CODE/bot_modals/bot_category/view-bot-categories/view-bot-categories.component";
import { CreateBotItemComponent } from "src/app/BOT_CODE/bot_modals/bot_item/create-bot-item/create-bot-item.component";
import { FileServiceService } from "src/app/service/file-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { AddBotResponseComponent } from "src/app/BOT_CODE/bot_modals/bot_response/add-bot-response/add-bot-response.component";
import { AddBotResponseOptionsComponent } from "src/app/BOT_CODE/bot_modals/bot_response_options/add-bot-response-options/add-bot-response-options.component";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { BotItemsFileUploadComponent } from "src/app/BOT_CODE/bot_modals/bot_item/bot-items-file-upload/bot-items-file-upload.component";

@Component({
  selector: "app-bot-shop-home",
  templateUrl: "./bot-shop-home.component.html",
  styleUrls: ["./bot-shop-home.component.scss"],
})
export class BotShopHomeComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  categoriesExist = false;
  itemsExist = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
    fkBotLangId: "",
    multiLanguageSettings: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private ngbModalService: NgbModal,
    private botResponseService: BotResponseServiceService,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private botOfficeService: BotOfficeServiceService,
    private imageService: FileServiceService,
    private sanitizer: DomSanitizer
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  categories: BotCategory[] = [];
  mainCategories: BotCategory[] = [];
  items: BotItem[] = [];

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.orderPlacedResponse.botResponseId = null;

    this.getBotCategories();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  getBotCategories() {
    this.categoriesExist = false;
    this.botOfficeService
      .getBotCategories(environment.apiUrl + "botOfficeShop/getBotCategories")
      .then((res) => {
        let resObj: BotCategory = new BotCategory();
        resObj = res;
        this.categories = resObj.list;
        this.categoriesExist = true;
        if (this.categories !== null && this.categories.length > 0) {
          this.mainCategories = [];
          this.categories.forEach((catg) => {
            if (catg.parentId === null) {
              this.mainCategories.push(catg);
            }
          });
          this.getBotItems();

          this.orderPlacedResponse = resObj.orderPlacedResponse;
        } else {
          this.showSpinner = false;
        }
      });
  }

  botCategoryAndItemsStatusUpdate(id: any) {
    this.showSpinner = true;
    this.botOfficeService
      .botItemStatusUpdate(
        environment.apiUrl +
          "botOfficeShop/botCategoryAndItemsStatusUpdate/" +
          id
      )
      .then((res) => {
        this.getBotCategories();
        this.toaster.success("Status updated successfully !");
      });
  }

  botItemStatusUpdate(item: BotItem) {
    this.categories.forEach((catg) => {
      if (item.fkBotCategoryId === catg.botCategoryId) {
        if (catg.botCategoryStatus === false) {
          this.toaster.error(
            "Category of this item " +
              catg.name +
              " is deactivated, Please activate the category first !"
          );
        } else {
          this.botOfficeService
            .botItemStatusUpdate(
              environment.apiUrl +
                "botOfficeShop/botItemStatusUpdate/" +
                item.botItemId
            )
            .then((res) => {
              this.items.forEach((item) => {
                if (item.botItemId === item.botItemId) {
                  item.botItemStatus = !item.botItemStatus;
                }
              });
            });
        }
      }
    });
  }

  getBotItems() {
    this.itemsExist = false;
    this.botOfficeService
      .getBotItems(environment.apiUrl + "botOfficeShop/getBotItems")
      .then((res) => {
        let resObj: BotItem = new BotItem();
        resObj = res;
        this.items = resObj.list;

        this.itemsExist = true;

        this.items.forEach((item) => {
          this.categories.forEach((catg) => {
            if (catg.botCategoryId === item.fkBotCategoryId) {
              item.fkBotCategoryName = catg.name;
            }
          });

          this.imageService
            .getBotItemImage(item.botItemImage)
            .subscribe((data) => {
              let unsafeUrl = URL.createObjectURL(data);
              item.safeImageUrl =
                this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
            });
        });

        this.showSpinner = false;
      });
  }

  createBotCategory() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotCategoryComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "create parent category",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotCategory) => {
        this.categoriesExist = true;
        if (this.categories === null) {
          this.categories = [];
        }
        this.categories.push(receivedEntry);
        if (receivedEntry.parentId === null) {
          this.mainCategories.push(receivedEntry);
        }
        this.categoriesExist = true;
      }
    );
  }

  viewSubCategories(category: BotCategory) {
    category.list = [];
    this.categories.forEach((catg) => {
      if (catg.parentId !== null && catg.parentId === category.botCategoryId) {
        category.list.push(catg);
      }
    });
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotCategoriesComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "view subcategories",
      botCategory: category,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotCategory) => {}
    );
    modalRef.componentInstance.passEntryAddSubcategory.subscribe(
      (receivedEntry: BotCategory) => {
        this.categories.push(receivedEntry);
      }
    );
    modalRef.componentInstance.passEntryEditSubcategory.subscribe(
      (receivedEntry: BotCategory) => {
        this.categories.forEach((catg) => {
          if (catg.botCategoryId === receivedEntry.botCategoryId) {
            catg.name = receivedEntry.description;
            catg.description = receivedEntry.description;
          }
        });
      }
    );
    modalRef.componentInstance.passEntryGetCategories.subscribe(
      (receivedEntry: BotCategory) => {
        this.getBotCategories;
      }
    );
  }

  editBotCategory(category: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotCategoryComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "edit category",
      botCategory: category,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotCategory) => {
        this.categories.forEach((catg) => {
          if (catg.botCategoryId === receivedEntry.botCategoryId) {
            catg.name = receivedEntry.description;
            catg.description = receivedEntry.description;
          }
        });
        this.mainCategories.forEach((catg) => {
          if (catg.botCategoryId === receivedEntry.botCategoryId) {
            catg.name = receivedEntry.description;
            catg.description = receivedEntry.description;
          }
        });
      }
    );
  }

  createBotItem() {
    if (this.categories !== null && this.categories.length > 0) {
      this.config.class = "modal-md";
      const modalRef = this.ngbModalService.open(CreateBotItemComponent, {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      });
      let data = {
        context: "create item",
        botCategories: this.categories,
      };
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
      modalRef.componentInstance.passEntry.subscribe(
        (receivedEntry: BotItem) => {
          if (this.items === null) {
            this.items = [];
          }

          this.imageService
            .getBotItemImage(receivedEntry.botItemImage)
            .subscribe((data) => {
              let unsafeUrl = URL.createObjectURL(data);
              receivedEntry.safeImageUrl =
                this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
            });

          this.categories.forEach((catg) => {
            if (catg.botCategoryId === receivedEntry.fkBotCategoryId) {
              receivedEntry.fkBotCategoryName = catg.name;
            }
          });

          this.items.push(receivedEntry);
        }
      );
    } else {
      this.toaster.error("Please create categories first !");
    }
  }

  editBotItem(item: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotItemComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "edit item",
      botItem: item,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: BotItem) => {
      this.items.forEach((item) => {
        if (item.botItemId === receivedEntry.botItemId) {
          item.botItemName = receivedEntry.botItemName;
          item.pricePerItem = receivedEntry.pricePerItem;
          item.botItemDescription = receivedEntry.botItemDescription;
          if (receivedEntry.imageUpload === true) {
            this.imageService
              .getBotItemImage(receivedEntry.botItemImage)
              .subscribe((data) => {
                let unsafeUrl = URL.createObjectURL(data);
                item.safeImageUrl =
                  this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
              });
          }
        }
      });
    });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  // RESPONSE
  orderPlacedResponse: BotResponse = new BotResponse();

  createOrderPlacedResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createOrderPlacedResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.orderPlacedResponse = receivedEntry;
      }
    );
  }

  editBotResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editBotResponse",
      botResponse: this.orderPlacedResponse,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.orderPlacedResponse.response = receivedEntry.response;
        this.orderPlacedResponse.saveVar = receivedEntry.saveVar;
        this.orderPlacedResponse.var = receivedEntry.var;
        this.orderPlacedResponse.userResType = receivedEntry.userResType;
        this.orderPlacedResponse.resType = receivedEntry.resType;
        this.orderPlacedResponse.fkBotMediaId = receivedEntry.fkBotMediaId;
        this.orderPlacedResponse.botMedia = receivedEntry.botMedia;
        this.orderPlacedResponse.userResValidation =
          receivedEntry.userResValidation;
        this.orderPlacedResponse.userResValidationType =
          receivedEntry.userResValidationType;
        this.orderPlacedResponse.interactiveListButtonName =
          receivedEntry.interactiveListButtonName;
        this.orderPlacedResponse.userDetailsUpdateVar =
          receivedEntry.userDetailsUpdateVar;
      }
    );
  }

  addOptionToResponse(resP: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addOptionToResponse",
      botResponseId: resP.botResponseId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        if (this.orderPlacedResponse.botResponseId === resP.botResponseId) {
          if (this.orderPlacedResponse.options === null) {
            this.orderPlacedResponse.options = [];
          }
          this.orderPlacedResponse.options.push(receivedEntry);
        }
      }
    );
  }

  editOption(botResponseOption0: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editOption",
      botResponseOption: botResponseOption0,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        if (
          this.orderPlacedResponse.botResponseId ===
          receivedEntry.fkBotResponseId
        ) {
          this.orderPlacedResponse.options.forEach((option) => {
            if (
              option.botResponseOptionsId === receivedEntry.botResponseOptionsId
            ) {
              option.responseOption = receivedEntry.responseOption;
              option.responseOptionNumber = receivedEntry.responseOptionNumber;
              option.responseOptionDescription =
                receivedEntry.responseOptionDescription;
            }
          });
        }
      }
    );
  }

  linkNextResponseToOption(id: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkNextResponseToOption",
      botResponseOptionsId: id,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        if (this.orderPlacedResponse.options !== null) {
          this.orderPlacedResponse.options.forEach((optn) => {
            if (optn.botResponseOptionsId === id) {
              optn.nextBotResponseId = receivedEntry.botResponseId;
            }
          });
        }
      }
    );
  }

  unlinkNextResponseForOption(id: any) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl + "botConvo/unlinkNextResponseForOption/" + id
      )
      .then((res) => {
        this.toaster.success("Next response unlinked successfully");
        this.orderPlacedResponse.options.forEach((optn) => {
          if (optn.botResponseOptionsId === id) {
            optn.nextBotResponseId = null;
            optn.selected = false;
          }
        });
      });
  }

  BotItemsFileUpload() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotItemsFileUploadComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });

    let data = {};

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      // this.getAllBotCustomerUsersByOfficeId();
      this.getBotItems();
    });
  }
}
