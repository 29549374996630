<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Lead</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card">
        <div class="card-body">


<form #customerRegisteration="ngForm">

  <div *ngIf="type==='phone' && search === true" class="col-md-12">

    <label>Phone</label>
  <input class="col-md-12 form-control" required minlength="10"
  type="number" [(ngModel)]="value" placeholder="Enter phone" name="search">
 




</div>

<div *ngIf="type==='email' && search === true" class="col-md-12">
  <label>Email</label>
  <input class="col-md-12 form-control" required
  type="text" email [(ngModel)]="value" placeholder="Enter email" name="search">
 


</div>

  </form>

  <div *ngIf="search === false" style="margin-bottom: 5%;">

    <button *ngIf="type==='email'" class="btn btn-sm btn-primary float-left" style="width: auto;"
(click)="value=null;search=true">Use Different Email</button>

<button *ngIf="type==='phone'" class="btn btn-sm btn-primary float-left" style="width: auto;"
(click)="value=null;search=true">Use Different Phone</button>

<button *ngIf="type==='email'" class="btn btn-sm btn-primary float-right" style="width: auto;"
(click)="createCustomerWithSameEmailOrPhone();" >Create Customer with same Email</button>

<button *ngIf="type==='phone'" class="btn btn-sm btn-primary float-right" style="width: auto;"
(click)="createCustomerWithSameEmailOrPhone();" >Create Customer with same Phone</button>
  </div>

  <br>

  <table *ngIf="search === false">
    
   <thead>
     <tr>
       <th [ngStyle]="backgroundStyle" style="color: white;">Name</th>
       <th [ngStyle]="backgroundStyle" style="color: white;">Email</th>
       <th [ngStyle]="backgroundStyle" style="color: white;">Phone</th>
       <th [ngStyle]="backgroundStyle" style="color: white;">Type</th>
       <th [ngStyle]="backgroundStyle" style="color: white;">Action</th>
      </tr>
   </thead>
   <tbody>
     <tr *ngFor="let customer of profile.customers">
       <td>{{customer.firstName}} {{customer.lastName}}</td>
       <td>{{customer.email}}</td>
       <td>{{customer.phoneNumber}}</td>
       <td *ngIf="customer.customerType==='L'">Lead</td>
       <td *ngIf="customer.customerType==='C'">Customer</td>
       <td>
         <button style="width: auto;" class="btn btn-info"
          (click)="showProfile(customer.customerId, customer.customerType);">Continue with this user</button>
       </td>
     </tr>
   </tbody>

  </table>
   


</div>
    </div>
  </div>


  <div *ngIf="search === true" class="modal-footer">

<button *ngIf="search === true && type==='phone'" class="btn btn-sm btn-primary float-left" style="width: auto;"
(click)="type='email';value=null;">Use Email Instead</button>

<button *ngIf="search === true && type==='email'" class="btn btn-sm btn-primary float-left" style="width: auto;"
(click)="type='phone';value=null;">Use Phone Instead</button>

<button style="width: auto;color: black;"  [disabled]="!customerRegisteration.valid" 
(click)="searchFoUser();" class="btn btn-info float-right">Proceed</button>

  </div>