import { CustomerScoring } from "./customer-scoring";

export class Customer {

    customerId : any;
	firstName  : any;
	phoneNumber : any;
	email : any;
	customerType : any;
	leadType : any;
	entityType : any;
	customerAddress : any;
	gst : any;
	contactPerson : any;
	contactPersonEmail : any;
	contactPersonPhone : any;
	fkOfficeId : any;
	fkUserRoleOfficeId : any;
	activeStatus : any;
	notes : any;
	executiveName : any;
	createCustomer! : boolean;
	fkUserId : any;
	fkLocationGroupId : any;

	contactPersonCountryDialCode : any;
	countryDialCode : any;

	username : any;

	externalOutstandingAmount : any
	outstandingAmount : any

	customerScoring : CustomerScoring = new CustomerScoring();

	leads : Customer[] = [];

	nextActionDateTime : any;

	hotLeads:any
	coldLeads:any
	allLeads:any
	leadsToContact:any
	discardedLeads:any
	activeLeads : any;
    
}
