


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Feedback Answers</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 
             


        <div >
          <table  border="1">
              <thead>
                  <tr>
                      <th [style.background-color]='getColor()' >#</th>
                      <th [style.background-color]='getColor()' >Answers</th>
                      <th [style.background-color]='getColor()' >Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor = "let ans of feedbackAnswers, let i = index">
                      <td>{{i}}</td>
                      <td style="text-align: left;">{{ans.answer}}</td>
                      <td>
                          <button class="btn btn-info" (click)="editAnswer(ans);">Edit</button>
                      </td>
                  </tr>
                 
              </tbody>
      </table>
  </div> <br>

  
  
  
   


             
      </form>
      </div>
      </div>
      </div>
  
     