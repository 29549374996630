import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceService } from 'src/app/services/auth/login-service.service';
import { environment } from '../../../../src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TimeoutScreenComponent } from '../timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // busy = false;
  loginLoader = false;
  clicked = false;
  showSpinner : boolean = false;
  authCode : string|null = null;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  constructor(public router: Router, public loginService: LoginServiceService,
    private toastr: ToastrService, private sharedDataService: SharedDataService,
    private modalService: BsModalService,  public bsModalRef: BsModalRef,private bnIdle: BnNgIdleService,
    private spinner: NgxSpinnerService, private route:ActivatedRoute) {
      
        // modal.hide());
  }

  // ngOnInit(): void {
  //       // check if url contains authcode
  //       console.log(this.route.snapshot.paramMap);
  //       this.authCode = this.route.snapshot.paramMap.get("code");
  //       console.log("Going to check auth code from url", this.authCode);
  //       if(!this.authCode){
  //         console.log("Auth code null redirecting for null")
  //         // window.location.href='https://zoti-api-preview.omnileadz.com/oauth2/authorize?response_type=code&client_id=client'
  //       }else {
  //         console.log("Auth code found going to exchange for tokens")
  //         this.loginUserWithAuthCode(this.authCode);
  //       }
    
   
  // }

  ngOnInit(): void {
    // check if url contains authcode
    console.log(this.route.snapshot.queryParamMap);
    this.authCode = this.route.snapshot.queryParamMap.get("code");
    console.log("Going to check auth code from url", this.authCode);
    if (!this.authCode) {
        console.log("Auth code null redirecting for null")
        window.location.href=`${environment.authUrl}/oauth2/authorize?response_type=code&client_id=${environment.oauthClientId}&scope=write`
    } else {
        console.log("Auth code found going to exchange for tokens")
        this.loginUserWithAuthCode(this.authCode);
    }
}

  loginUserWithAuthCode(code: string|null ): void {
    if(!code) return;
    this.loginLoader = true;
    this.showSpinner = true; 
    this.loginService.exchangeCodeForToken(`${environment.authUrl}/oauth/token`, code).then(authResponse => {
      localStorage.setItem('auth-token', authResponse.access_token);      
      this.loginService.doLoginWithToken(environment.apiUrl + 'user/login', authResponse.access_token).then(response => {
        localStorage.setItem('loggedUser', JSON.stringify(response));
        this.sharedDataService.setLoggedInUserDtls(response);
        // this.loginLoader = false;
        this.clicked=true;
        this.loginLoading();
        if (response.passwordStatus) {
          this.router.navigate(['/auth/reset-password']).then();
        } else if (response.defaultRoleName === 'SUPER_ADMIN') {
          this.router.navigate(['/dashboard/super-admin']).then();
        } else if (response.defaultRoleName === 'OFFICE_ADMIN') {
          this.router.navigate(['/dashboard/office-admin']).then();
        } else if (response.defaultRoleName === 'CRM_ADMIN') { 
          this.router.navigate(['/dashboard/crm-admin']).then();
        } else if (response.defaultRoleName === 'CB_SUPER_ADMIN') { 
          this.router.navigate(['/dashboard/cb-super-admin']).then();
        } else if (response.defaultRoleName === 'CB_OFFICE_ADMIN') { 
          this.router.navigate(['/dashboard/cb-office-admin']).then();
        } else if (response.defaultRoleName === 'CB_AGENT') { 
          this.router.navigate(['/dashboard/cb-agent-convo-log-view']).then();
        } else if (response.defaultRoleName === 'CB_NOTIFICATIONS_MANAGER') { 
          this.router.navigate(['/dashboard/office/notifications']).then();
        } else if (response.defaultRoleName === 'CB_SHOP_ORDERS_MANAGER') { 
          this.router.navigate(['/dashboard/office/shop-orders']).then();
        } else if (response.defaultRoleName === 'CB_ORG_ADMIN') { 
          this.router.navigate(['/dashboard/cb-org-admin/home']).then();
        }else {  
        this.router.navigate(['/dashboard/user']).then();
        } 
      }).catch((err) => {
        this.loginLoader = false;
        this.showSpinner = false;
        this.loginLoading();
        // this.toastr.error(err.error.message, 'Error');
        this.toastr.error('Oops, Something went wrong !');
      });
    }).catch((err) => {
      this.loginLoader = false;
      this.showSpinner = false;
        this.loginLoading();
      // this.toastr.error(err.error.error_description == null? 'User Not Found' : err.error.error_description , 'Error');
      // this.toastr.error(err.error.error_description == 'Cannot invoke "com.course.basic.entity.User.getId()" because "user" is null'? 'User Not Found' : 'Bad Credentials' , 'Error');
      if(err.error.error_description === 'Cannot invoke "com.course.basic.entity.User.getId()" because "user" is null'){
        // this.toastr.error('User Not Found');
        this.toastr.error('Bad Credentials');
      }else if(err.error.error_description === 'User account has expired'){
        this.toastr.error('User account has expired');
      }else if(err.error.error_description === 'Bad credentials'){
        this.toastr.error('Bad Credentials');
      }else{
        this.toastr.error('Internal Server Error')
      }
    });
  }

  forgotPasswordClick(input: any): void {
    const data = {
      email: input.email
    };
    this.sharedDataService.setLoggedInUserDtls(data);
    this.router.navigate(['/auth/forgot-password']).then();
  }
  loginLoading() {
    this. loginLoader ? this.spinner.show() : this.spinner.hide();
  }
  loginClick(data: any): void {
    // this.busy = true;
    this.loginLoader = false;
    this.showSpinner = true; 
    this.loginLoading();
    this.loginService.getToken(environment.apiUrl + 'oauth/token', data.email, data.password).then(authResponse => {
      localStorage.setItem('auth-token', authResponse.access_token);      
      this.loginService.doLogin(environment.apiUrl + 'user/login', data.email, data.password, authResponse.access_token).then(response => {

       
        localStorage.setItem('loggedUser', JSON.stringify(response));
        this.sharedDataService.setLoggedInUserDtls(response);
        // this.loginLoader = false;
        this.clicked=true;
        this.loginLoading();
        if (response.passwordStatus) {
          this.router.navigate(['/auth/reset-password']).then();
        // } else if (response.roles[0].roleName === 'SUPER_ADMIN') {
        //   this.router.navigate(['/dashboard/super-admin']).then();
        } else if (response.defaultRoleName === 'SUPER_ADMIN') {
        this.router.navigate(['/dashboard/super-admin']).then();
        // } else if (response.roles[0].roleName === 'OFFICE_ADMIN') {
        //   this.router.navigate(['/dashboard/office-admin']).then();
        } else if (response.defaultRoleName === 'OFFICE_ADMIN') {
        this.router.navigate(['/dashboard/office-admin']).then();
        // } else if (response.roles[0].roleName === 'duplicate') { 
        //   this.router.navigate(['/dashboard/crm-admin']).then();
        } else if (response.defaultRoleName === 'CRM_ADMIN') { 
        this.router.navigate(['/dashboard/crm-admin']).then();
        } else if (response.defaultRoleName === 'COURSE_ADMIN') { 
        this.router.navigate(['/dashboard/curriculum-admin']).then();
        } else if (response.defaultRoleName === 'PARENT_MENTOR') { 
        this.router.navigate(['/dashboard/UserParent']).then();
        } else if (response.defaultRoleName === 'CLASS_ADMIN') { 
        this.router.navigate(['/dashboard/class-admin']).then();
        } else if (response.defaultRoleName === 'TEACHER') { 
        this.router.navigate(['/dashboard/teacher']).then();
        } else if (response.defaultRoleName === 'STUDENT') { 
          this.router.navigate(['/dashboard/student']).then();
        } else if (response.defaultRoleName === 'PARENT') { 
        this.router.navigate(['/dashboard/parent']).then();
        } else if (response.defaultRoleName === 'BCRM_OFFICE_ADMIN') { 
        this.router.navigate(['/dashboard/bcrm-office-admin']).then();
        } else if (response.defaultRoleName === 'BCRM_EXE') { 
        this.router.navigate(['/dashboard/bcrm-exe-dashboard']).then();        
        } else if (response.defaultRoleName === 'BCRM_MAN') { 
        this.router.navigate(['/dashboard/bcrm-man-dashboard']).then();
        } else if (response.defaultRoleName === 'BCRM_PM') { 
        this.router.navigate(['/dashboard/bcrm-product-management']).then();
        } else if (response.defaultRoleName === 'BCRM_DM') { 
        this.router.navigate(['/dashboard/bcrm-dispatch-management']).then();
        } else if (response.defaultRoleName === 'BCRM_FM') { 
        this.router.navigate(['/dashboard/bcrm-finance-management']).then();
        } else if (response.defaultRoleName === 'BCRM_TM') { 
        this.router.navigate(['/dashboard/bcrm-top-management']).then();
        } else if (response.defaultRoleName === 'BCRM_CUSTOMER') { 
        this.router.navigate(['/dashboard/bcrm-customer']).then();
        } else if (response.defaultRoleName === 'BCRM_DATA') { 
        this.router.navigate(['/dashboard/bcrm-data-dashboard']).then();
        } else if (response.defaultRoleName === 'BCRM_FEEDBACK_MANAGER') { 
        this.router.navigate(['/dashboard/bcrm-feedback-manager']).then();
        } else if (response.defaultRoleName === 'BCRM_SALES_COORDINATOR') { 
        this.router.navigate(['/dashboard/bcrm-sales-coordinator-dashboard']).then();
        } else if (response.defaultRoleName === 'CB_SUPER_ADMIN') { 
          this.router.navigate(['/dashboard/cb-super-admin']).then();
        } else if (response.defaultRoleName === 'CB_OFFICE_ADMIN') { 
          this.router.navigate(['/dashboard/cb-office-admin']).then();
        } else if (response.defaultRoleName === 'CB_AGENT') { 
          this.router.navigate(['/dashboard/cb-agent-convo-log-view']).then();
        } else if (response.defaultRoleName === 'CB_NOTIFICATIONS_MANAGER') { 
          this.router.navigate(['/dashboard/office/notifications']).then();
        } else if (response.defaultRoleName === 'CB_SHOP_ORDERS_MANAGER') { 
          this.router.navigate(['/dashboard/office/shop-orders']).then();
        } else if (response.defaultRoleName === 'CB_ORG_ADMIN') { 
          this.router.navigate(['/dashboard/cb-org-admin/home']).then();
        }else {  
        this.router.navigate(['/dashboard/user']).then();
        } 
      }).catch((err) => {
        this.loginLoader = false;
        this.showSpinner = false;
        this.loginLoading();
        // this.toastr.error(err.error.message, 'Error');
        this.toastr.error('Oops, Something went wrong !');
      });
    }).catch((err) => {
      this.loginLoader = false;
      this.showSpinner = false;
        this.loginLoading();
      // this.toastr.error(err.error.error_description == null? 'User Not Found' : err.error.error_description , 'Error');
      // this.toastr.error(err.error.error_description == 'Cannot invoke "com.course.basic.entity.User.getId()" because "user" is null'? 'User Not Found' : 'Bad Credentials' , 'Error');
      if(err.error.error_description === 'Cannot invoke "com.course.basic.entity.User.getId()" because "user" is null'){
        // this.toastr.error('User Not Found');
        this.toastr.error('Bad Credentials');
      }else if(err.error.error_description === 'User account has expired'){
        this.toastr.error('User account has expired');
      }else if(err.error.error_description === 'Bad credentials'){
        this.toastr.error('Bad Credentials');
      }else{
        this.toastr.error('Internal Server Error')
      }
    });
  }

  disableButton(){
    this.clicked=true;
  }

}
