import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-edit-subscription-modal',
  templateUrl: './edit-subscription-modal.component.html',
  styleUrls: ['./edit-subscription-modal.component.scss']
})
export class EditSubscriptionModalComponent implements OnInit {

  roles : any[] = ['TEACHER',
                    'STUDENT'];
                    clicked = false;
  firstName : any;
  lastName : any;
  userName : any;
  userId : any;
  subscriptionId : any;
  officeId!: string;
  products : Product[] = [];
        subscription : any = {  startedDate: "",
                  endedDate: "",
                  fkUserId: "",
                  fkRoleId: "",
                  roleName: "",
                  fkProductId: "",
                  productName: "",
                  userName: "",
                  subscriptionId : ""
                }

constructor(private productService : ProductService,
private activeModal : NgbActiveModal,
private toastr : ToastrService,
private subscriptionService : SubscriptionService,
private sharedDataService : SharedDataService
) { }
loggedInUser: any;
theme: any;

@Input() fromParent: any;

@Output() passEntry: EventEmitter<any> = new EventEmitter();

ngOnInit(): void {

  
this.subscription.subscriptionId = this.fromParent.subscriptionId;
this.subscription.roleName = this.fromParent.roleName;
this.subscription.productName = this.fromParent.productName;
this.subscription.startedDate = this.fromParent.startedDate;
this.subscription.endedDate = this.fromParent.endedDate;

this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
return this.loggedInUser.offices[0].theme + '!important';
}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

submit(){
  
this.subscriptionService.updateSubscription(environment.apiUrl + "user/updateSubscription", this.subscription).then(
data=>{
  
this.passEntry.emit(this.subscription);
this.activeModal.close();
this.toastr.success('User Subscription Updated successfully');
});
}

close(){
this.activeModal.close();
}

}
