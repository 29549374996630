<div class="modal-header">
  <h4 class="modal-title pull-left">{{headerText}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> 

<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="itemform" (ngSubmit)="saveToList(itemform)">

        
        <div class="form-group">
          <label for="if-role" class="required">Role Name</label>
          <input type="text" id="if-role" name="if-role" class="form-control" formControlName="name"
            placeholder="Enter Role Name.." autocomplete="role">
        </div>

        <div class="form-group">
          <label for="if-role" class="required">Display Name</label>
          <input type="text" id="if-role" name="if-role" class="form-control" formControlName="displayName"
            placeholder="Enter Display Name.." autocomplete="role">
        </div>


        <div class="form-group">
            <label for="if-dashboard" class="required">Dashboard</label>
            <select class="form-control" formControlName="dashboard" id="if-dashboard" name="if-dashboard">
              <option disabled value="">Choose Dashboard</option>
              <option *ngFor="let dashboard of dashboards" [ngValue]="dashboard.id">{{dashboard.value}}</option>
            </select>
        </div>


      </form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button [disabled]="clicked"  type="submit" class="btn btn-sm btn-primary float-right" (click)="saveToList(itemform);clicked=true">Save</button>
</div>
