import { BotNotificationConfiguration } from "./bot-notification-configuration";

export class BotAutoSchedulerSettings {
  botAutoSchedulerSettingsId: any;
  botAutoSchedulerSettingsStatus: any;
  fkOfficeId: any;
  autoScheduleVariable: any;
  fkBotTemplateId: any;
  scheduleAfterDays: any;
  list: BotAutoSchedulerSettings[] = [];
  fkBotTemplateName: any;
  context: any;
  botNotificationConfigurations: BotNotificationConfiguration[] = [];
}
