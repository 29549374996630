export class BotTreeDefinition {

    botTreeDefinitionId:any
	botTreeDefinitionStatus:any
	fkOfficeId:any
	definition:any
	fkBotResponseId:any

	botResponseId:any
	response:any
	slNo:any
	nextBotResponseId:any
}
