import { DatePipe, formatNumber } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { AddCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/add-customer-communications/add-customer-communications.component';
import { ViewCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/view-customer-communications/view-customer-communications.component';
import { SearchOrdersByPeriodComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/search-orders-by-period/search-orders-by-period.component';
import { ViewOrderDetailsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-order-details/view-order-details.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-man-due-orders',
  templateUrl: './bcrm-man-due-orders.component.html',
  styleUrls: ['./bcrm-man-due-orders.component.scss']
})
export class BcrmManDueOrdersComponent implements OnInit {
 

  totalOutstandingAmount : any = 0;
  currentRole = "BCRM_MAN";
  invoiceLoader = false;
  invoiceLoaderMessage!: string;
  invoiceExists!: boolean;
  invoiceOptions: any = {}; 
  invoice2Exists = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  loggedInUser: any;
  loggedUser: any;
  officeId : any;
  userName!: string;
  userId!: string;
  navbarTitle: any;
  closeResult!:string;
  showSpinner = false;
  totalOrders: any = 0;
  ordersPlaced: any = 0;
  ordersInvoiced: any = 0;
  ordersCancelled: any = 0;
  ordersApproved: any = 0;
  ordersShipped: any = 0;
  ordersDelivered: any = 0;


  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private datePipe : DatePipe,
              private invoiceService : InvoiceService, 
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;
    invoices: InvoiceList[] = [];
    invoices2: InvoiceList[] = [];

    fromDate : any;
    toDate : any;

    totalDue: any = 0;
    dueInOneWeek: any = 0;
    dueToday: any = 0;
    overdue: any = 0;
  
    totalDueAmt: any = 0;
    dueInOneWeekAmt: any = 0;
    dueTodayAmt: any = 0;
    overdueAmt: any = 0;


    allOutstandingInvoices: InvoiceList[] = [];
    invoicesDueInOneWeek: InvoiceList[] = [];
    invoicesDueToday: InvoiceList[] = [];
    invoicesOverdue: InvoiceList[] = [];

    status = "Invoices Due Today";

  ngOnInit(): void {

    this.showSpinner = true;

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    this.fromDate = new Date(y, m, 1);
    this.toDate = new Date(y, m + 1, 0);
    this.fromDate = this.datePipe.transform(this.fromDate,  "dd-MM-yyyy");
    this.toDate = this.datePipe.transform(this.toDate,  "dd-MM-yyyy");

    this.navbarTitle = GlobalClass.navbarTitle;
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

      this.getInvoiceDetails();

      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      this.invoiceOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        lengthChange: false,
        processing: true,
        info:false
      };

  }

  onclick(status : any){
    this.showSpinner = true;
    this.invoices2 = [];
    if(status === "All Orders"){
      this.invoices2 = this.invoices;
    }else{
      this.invoices.forEach((inv)=>{
        if(inv.orderStatus === status){
          this.invoices2.push(inv);
        }
      });      
    }
    this.showSpinner = false;
  }

  SearchOrdersByPeriod(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(SearchOrdersByPeriodComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
     role : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {

      this.invoices = [];
      this.invoices2 = [];      
      
     this.invoices = receivedEntry;
     this.invoices2 = receivedEntry;

     this.totalOrders = 0;
     this.ordersPlaced = 0;
     this.ordersInvoiced = 0;
     this.ordersShipped = 0;
     this.ordersDelivered = 0;
     this.ordersApproved = 0;
     this.ordersCancelled = 0;

     this.invoices2.forEach((val)=>{

       this.totalOrders = this.totalOrders + 1;

       if(val.orderStatus === "Order Placed"){
         this.ordersPlaced = this.ordersPlaced + 1;
       }else if(val.orderStatus === "Order Invoiced"){
         this.ordersInvoiced = this.ordersInvoiced + 1;
       }else if(val.orderStatus === "Order Approved"){
         this.ordersApproved = this.ordersApproved + 1;
       }else if(val.orderStatus === "Order Delivered"){
        this.ordersDelivered = this.ordersDelivered + 1;
       }else if(val.orderStatus === "Order Cancelled"){
         this.ordersCancelled = this.ordersCancelled + 1;
       }else if(val.orderStatus === "Order Shipped"){
         this.ordersShipped = this.ordersShipped + 1;
       }

       val.invoiceDate = new Date(val.invoiceDate);
       val.invoiceDate = this.datePipe.transform(val.invoiceDate, "dd-MM-yyyy");

     });

     this.invoice2Exists = true;
    });

    modalRef.componentInstance.passEntry2.subscribe((receivedEntry2 : Target) => {
      this.fromDate = receivedEntry2.targetStartDate;
      this.toDate = receivedEntry2.targetEndDate;
      this.fromDate = this.datePipe.transform(this.fromDate,  "dd-MM-yyyy");
      this.toDate = this.datePipe.transform(this.toDate,  "dd-MM-yyyy");
     });

  }

  // authorized
  getInvoiceDetails(){
    this.showSpinner= true;
      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getAmountDueOrderListForBcrmMan").then(
        data => {
          this.invoices= data;
          this.invoices2 = data;
          this.totalOutstandingAmount = 0;
          this.invoices2.forEach((val)=>{

            val.outstandingAmount =  Math.round((val.outstandingAmount + Number.EPSILON) * 100) / 100;
            val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;

            val.formattedActualInvoiceDate = new Date(val.actualInvoiceDate);
            val.formattedActualInvoiceDate = this.datePipe.transform(val.formattedActualInvoiceDate, "dd-MM-yyyy");
            
          this.totalOutstandingAmount = this.totalOutstandingAmount + val.outstandingAmount;
          this.totalDueAmt = this.totalDueAmt + val.outstandingAmount;
          this.totalDue = this.totalDue + 1;

const daysDiff = Math.floor(Math.abs(<any>new Date() - <any>new Date(val.actualInvoiceDate)) / (1000*60*60*24));


 if(daysDiff < val.officeCreditPeriod){

  let x = val.officeCreditPeriod - daysDiff;
  val.dueStatus = "due in " + x + " days";
  if(x < 5){
    val.overDue = "orange"

    this.dueInOneWeek = this.dueInOneWeek + 1;
    this.dueInOneWeekAmt = this.dueInOneWeekAmt + val.outstandingAmount;

    this.invoicesDueInOneWeek.push(val);

  }else{
    val.overDue = "black"
  }

}else if(daysDiff > val.officeCreditPeriod){

  let x = daysDiff - val.officeCreditPeriod;
  val.dueStatus = "overdue by " + x +" days";
  val.overDue = "red"

  this.overdue = this.overdue + 1;
  this.overdueAmt = this.overdueAmt + val.outstandingAmount;

  this.invoicesOverdue.push(val);

}  else{
  val.dueStatus = "due today";
  val.overDue = "red"

  this.dueToday = this.dueToday + 1;
  this.dueTodayAmt = this.dueTodayAmt + val.outstandingAmount;

  this.invoicesDueToday.push(val);

}

});

this.totalDueAmt = formatNumber(Number(this.totalDueAmt), 'en-US', '1.0-0');
this.overdueAmt = formatNumber(Number(this.overdueAmt), 'en-US', '1.0-0');
this.dueTodayAmt = formatNumber(Number(this.dueTodayAmt), 'en-US', '1.0-0');
this.dueInOneWeekAmt = formatNumber(Number(this.dueInOneWeekAmt), 'en-US', '1.0-0');
this.totalOutstandingAmount = formatNumber(Number(this.totalOutstandingAmount), 'en-US', '1.0-0');

this.invoiceExists = true;
this.showSpinner = false;
});
  } 

 

  viewInvoiceDetailsModal(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOrderDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });
      

    let data = {
      invoiceId: invoiceId ,
      type : "order"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
  
  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
    getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
        return this.loggedInUser.offices[0].theme + '!important';
    }

    reloadPage() {
      window.location.reload();
    }

    addNewCustomerCommunication(customerId : any){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddCustomerCommunicationsComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        customerId : customerId,
        context : "only followup"
      }
  
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      });
  }

  viewCustomerComms(customerId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewCustomerCommunicationsComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
      customerId : customerId
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });
}

}
