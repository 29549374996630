<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
          
 
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li> 
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>


  <div class="p-4">
    <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Ticket Supports
        </span>

      

      </div> 
    </div>
    <div>
      <mat-accordion class="example-headers-align">
     
        <mat-expansion-panel *ngFor="let ticketActual of ticketActuals" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="font-weight: bold;">
              {{ticketActual.ticketSupport}} - {{ticketActual.tickerRaiserName}}
            </mat-panel-title>
            <mat-panel-description>
              {{ticketActual.ticketActualStatus}}
              <mat-icon>feedback</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
          
          <a style="color: black;font-weight: bold;">Group </a> 
          <a style="color: black;"> : {{ticketActual.ticketSupportGroup}}</a> <br>
          <a style="color: black;font-weight: bold;">Type </a>
          <a style="color: black;"> : {{ticketActual.ticketSupportType}}</a> <br>
          <a style="color: black;font-weight: bold;">Date </a> 
          <a style="color: black;"> : {{ticketActual.timeStamp}}</a> <br>
          <a style="color: black;font-weight: bold;"> Description </a> 
          <a style="color: black;"> : {{ticketActual.ticketSupportDescription}}</a> <br> <br>
          <button mat-button color="primary" (click)="viewFormResponses(ticketActual.formRespondentDto)" >View Ticket Details</button>

        
          <div *ngIf="ticketActual.expansionPanel === true" style="margin-top: 2%;">

            <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
              <div class="col-md-12" style="color: rgb(248, 245, 245);">
        
                <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
                 Ticket Action History
                </span>
        
                <a (click)="expansionPanel(ticketActual.ticketActualId);" 
                class="float-right" matTooltip="Hide Action History" style="cursor: pointer;float: right;"> 
                 <img src="/assets/images/icons/hide.svg" width="26" height="26"
                  alt="" loading="lazy">
               </a>
         
        
              </div> 
            </div>
            <br>

            <div *ngFor="let ticketAction of ticketActual.ticketActions">

              <a style="color: black;font-weight: bold;">Action Type : </a>
              <a style="color: black;">{{ticketAction.actionType}}</a> <br>

              <a style="color: black;font-weight: bold;">Date-Time : </a> 
              <a style="color: black;"> {{ticketAction.timeStamp}}</a> <br>

              <a *ngIf="ticketAction.actionType === 'Take Up Ticket'" style="color: black;font-weight: bold;">
                Taken Up By : </a>
                <a *ngIf="ticketAction.actionType === 'Take Up Ticket'" style="color: black;">
                  {{ticketAction.takeUpTicketUserName}}</a> <br *ngIf="ticketAction.actionType === 'Take Up Ticket'">

                  <a *ngIf="ticketAction.actionType === 'Reassign'" style="color: black;font-weight: bold;">
                Reassigned By : </a>
                <a *ngIf="ticketAction.actionType === 'Reassign'" style="color: black;">
                  {{ticketAction.reassignedByUserName}}</a> <br *ngIf="ticketAction.actionType === 'Reassign'">
              
                  <a *ngIf="ticketAction.actionType === 'Reassign'" style="color: black;font-weight: bold;">
                Reassigned To : </a> 
                <a *ngIf="ticketAction.actionType === 'Reassign'" style="color: black;">
                {{ticketAction.reassignedToUserName}}</a> <br *ngIf="ticketAction.actionType === 'Reassign'">
            
                  <a style="color: black;font-weight: bold;">Remarks : </a> 
                  <a style="color: black;">{{ticketAction.remarks}}</a> <br>
                  <br>
            </div>

          </div>

          <mat-action-row>
            <button *ngIf="ticketActual.expansionPanel === false" mat-button color="primary" (click)="expansionPanel(ticketActual.ticketActualId)">View History</button>
            <button *ngIf="ticketActual.expansionPanel === true" mat-button color="primary" (click)="expansionPanel(ticketActual.ticketActualId)">Hide History</button>
          </mat-action-row>
        </mat-expansion-panel>
  
      </mat-accordion>
      
    </div>
</div>