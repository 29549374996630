// teacher / student activity & assignment evaluation
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityScore } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-score';
import { AddActActualActScore } from 'src/app/courseManagementClasses/combinedParentTeacher/add-act-actual-act-score';
import { GetCombinedPTCDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-combined-ptc-details';
import { GlobalClass } from 'src/app/global/global-class';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-show-questions-modal',
  templateUrl: './show-questions-modal.component.html',
  styleUrls: ['./show-questions-modal.component.scss']
})
export class ShowQuestionsModalComponent implements OnInit {
  clicked=false
  actQuestionsExists : boolean = false;
  actQuestionsExists2 : boolean = false;

  safeImageUrl : any;
  studentName : any;
  actQuestions : ActivityQuestions[] = [];
  isLinear = true;
  activityScore : ActivityScore = new ActivityScore();
  activityScoreList : ActivityScore[] = [];
 
  activityPlannerId! : string;

  inputType : any;
  questionOrder : any;

  combinedPTCDetails: GetCombinedPTCDetails = new GetCombinedPTCDetails;

  fkStudentId!: string;
  fkTeacherId!: string;

  addActActualActScore : AddActActualActScore = new AddActActualActScore();

  @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

   firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  isEditable = false;
  stepper: any;

 
  constructor(private combinedParentTeacherService : CombinedParentTeacherService,
    public activeModal: NgbActiveModal,private toaster: ToastrService,
    private _formBuilder: FormBuilder, private studentService : StudentService ,
    private sharedDataService : SharedDataService,private ngbModalService: NgbModal
    ) { }
    loggedInUser: any;
    theme: any;

  ngOnInit(): void {

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.fkStudentId = this.fromParent.fkStudentId;
    this.fkTeacherId = this.fromParent.fkTeacherId;

    this.safeImageUrl = this.fromParent.safeImageUrl;
    this.studentName = this.fromParent.studentName;
    
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.combinedParentTeacherService.getActivityQuestionListByActPlnrId(environment.apiUrl + "activityQuestion/getByActPlnrId/" + this.activityPlannerId).then(
      data=>{ 
        this.actQuestions=data;

        if (this.actQuestions === undefined || this.actQuestions.length == 0) {
          this.actQuestionsExists2 = true;
      }else{
        this.actQuestionsExists = true;

      }

        this.actQuestions.forEach((question) =>{

            if(question.activityQuestionType=='R'){
              this.inputType = 'Radio';
              this.questionOrder = question.activityQuestionOrder;
            }
        }); 


      });

     
      // this.combinedPTCDetails = this.combinedParentTeacherService.getLocalCombinedPTCDetails();
      // this.fkTeacherId = this.combinedPTCDetails.teacherId;

      this.addActActualActScore.fkTeacherId = this.fkTeacherId;
      this.addActActualActScore.fkActivityPlannerId = this.activityPlannerId;
   
  }
  
  closeResult!:string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }
  NoOfQsnsAnswered = 0;
  addToArray(activityQuestionId : string){

    this.NoOfQsnsAnswered = this.NoOfQsnsAnswered + 1;

    this.activityScore.fkActivityQuestionId = activityQuestionId;

    this.activityScore.fkStudentId = this.fkStudentId;
    
    if(this.activityScore.activityScoreValue == '1'){
      this.activityScore.activityScoreName = "Yes";
    } else {
      this.activityScore.activityScoreName = "No";
    }

    this.activityScoreList.push(this.activityScore);

    this.activityScore = new ActivityScore();

    this.stepper.selected.completed = true;
    this.stepper.next();
    

  }

  onSubmit(){
   
   
    this.addActActualActScore.fkStudentId = this.fkStudentId;
    this.addActActualActScore.activityScoreList = this.activityScoreList;


    // authorized

    //FOR TEACHER
        this.studentService.addStudentActualActivityScore(environment.apiUrl + 'student/addStudentActualActivityScore', this.addActActualActScore).then(
          data=>{
            this.passEntry.emit("complete");
            this.activeModal.close();
            this.toaster.success('Activity Completed Successfully');
          });
    
  }

  Change(evt: any) {
    this.activityScore.activityScoreName = evt;

  }
}
