import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeacherService } from 'src/app/service/teacher.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-teacher-class-subjects-modal',
  templateUrl: './teacher-class-subjects-modal.component.html',
  styleUrls: ['./teacher-class-subjects-modal.component.scss']
})
export class TeacherClassSubjectsModalComponent implements OnInit {
  closeResult!: string;

  constructor(private teacherService : TeacherService,
    public activeModal: NgbActiveModal,private ngbModalService : NgbModal,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;


  userId : any;
  subExists = false;
  classCardsWithSafeUrl : any[] =[ {  teacherId : '', 
  teacherStatus : '', 
  userRoleOfficeId : '',
  classId : '',
  className : '',
  classDescription : '',
  fkOfficeId : '',
  fkProductIdOfClass : '' ,
  subId : '' ,
  subName : '' ,
  subDescription : '' ,
  fkProductIdOfSubject : '' ,
  subjectImage : '' ,
  safeUrl : '' 
                                    } ];


  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {


    this.userId = this.fromParent.userId;

    this.getTeacherClassSubjects();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}
get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}
getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  getTeacherClassSubjects(){
    this.teacherService.getTeacherClassSubjects( environment.apiUrl + "teacher/getTeacherClassSubjectsForClassAdmin/" + this.userId).then(
      data=>{        

        this.classCardsWithSafeUrl.pop();

        for(var index in data){
                              this.classCardsWithSafeUrl.push( {  'teacherId' : data[index].teacherId, 
                                                                  'userRoleOfficeId' : data[index].userRoleOfficeId,
                                                                  'classId' : data[index].classId,
                                                                  'className' : data[index].className,
                                                                  'classDescription' : data[index].classDescription,
                                                                  'fkOfficeId' : data[index].fkOfficeId,
                                                                  'fkProductIdOfClass' : data[index].fkProductIdOfClass ,
                                                                  'subId' : data[index].subId ,
                                                                  'subName' : data[index].subName ,
                                                                  'subDescription' : data[index].subDescription ,
                                                                  'fkProductIdOfSubject' : data[index].fkProductIdOfSubject ,
                                                                  'subjectImage' : data[index].subjectImage ,
                                                                  'teacherStatus' : data[index].teacherStatus ,
                                                                        });
      }

      if( this.classCardsWithSafeUrl === undefined || this.classCardsWithSafeUrl.length === 0){
        
      }else{
        this.subExists = true;
      }


       });
  }

  teacherStatusUpdate(teacherId : any){

    this.teacherService.teacherStatusUpdate(teacherId).subscribe(
      data=>{

        this.classCardsWithSafeUrl.forEach((val)=>{
          if(val.teacherId === teacherId){
            val.teacherStatus = !val.teacherStatus;
          }

        });

      });
  }
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  closeUpdateModal(){
    this.activeModal.close();
  }  

}
