import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-activity-planner-for-teacher-modal',
  templateUrl: './view-activity-planner-for-teacher-modal.component.html',
  styleUrls: ['./view-activity-planner-for-teacher-modal.component.scss']
})
export class ViewActivityPlannerForTeacherModalComponent implements OnInit {
  showSpinner = true;

  curId!: string;
  actPlannersExists : boolean = true;
  @Input() fromParent: any;

  safeImageUrl: any;
  unsafeImageUrl: any;

  activityPlanners : any[] =[ {  activityPlannerId : '', 
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '' ,
                                      safeUrl : '' 
                                                                        } ];

  
  constructor(public activeModal: NgbActiveModal,
              private activityPlannerService : ActivityPlannerService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

  ngOnInit(): void {

    this.curId = this.fromParent.curId;

    this.getactivityPlanners();


    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  getactivityPlanners(){
    this.activityPlannerService.getActivityPlannerListByCurId(this.curId).subscribe(
      data=>{
        this.activityPlanners.pop();

        for(var index in data){
          this.activityPlanners.push( {  'activityPlannerId' : data[index].activityPlannerId, 
                                              'activityPlannerName' : data[index].activityPlannerName,
                                              'activityPlannerDescription' : data[index].activityPlannerDescription,
                                              'activityPlannerOrder' : data[index].activityPlannerOrder,
                                              'activityPlannerStatus' : data[index].activityPlannerStatus,
                                              'fkSubjectId' : data[index].fkSubjectId,
                                              'activityPlannerImage' : data[index].activityPlannerImage ,
                                              'safeUrl' : this.safeImageUrl 
                                                    });
}

this.activityPlanners.forEach( (value) => {
          
  this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
    data=>{
      this.unsafeImageUrl = URL.createObjectURL(data);
      this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);

      value.safeUrl = this.safeImageUrl;
     });
   });
   
   if (this.activityPlanners === undefined || this.activityPlanners.length == 0) {
    this.actPlannersExists = false;
}
 });

  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
