import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Payment } from 'src/app/crmClasses/payment';
import { GlobalClass } from 'src/app/global/global-class';
import { PaymentService } from 'src/app/service/payment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-payment-details',
  templateUrl: './view-payment-details.component.html',
  styleUrls: ['./view-payment-details.component.scss']
})
export class ViewPaymentDetailsComponent implements OnInit {
  
  loggedInUser : any;
  theme : any;

  paymentId!: string;

  @Input() fromParent: any;

  payment : Payment = new Payment();

  constructor(public activeModal: NgbActiveModal,
    private sharedDataService: SharedDataService,
              private paymentService : PaymentService,
              public bsModalRef: BsModalRef ) { }

  ngOnInit(): void {

    this.paymentId = this.fromParent.paymentId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.paymentService.getPaymentById(this.paymentId).subscribe(data=>{
      this.payment = data;
    }, error => console.log(error));

  }

  closeModal(){
    this.activeModal.close();
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 
}
