export class Shipment {

    shipmentId!: string;
	shipmentAddress!: string;
	shipmentPartner!: string;
	shippedDate!: Date;
	deliveryDate!: Date;
	fkInvoiceId!: string;
	shipmentRemarks!: string;
	shipmentStatus!: boolean;

	shippedDateTime : any;
	expectedDeliveryDateTime  : any;
	deliveryDateTime : any;
	deliveryRemarks : any;

	batchShipmentInvoiceIds : string[] = [];
}
