import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-student-assignment-submission-modal',
  templateUrl: './view-student-assignment-submission-modal.component.html',
  styleUrls: ['./view-student-assignment-submission-modal.component.scss']
})
export class ViewStudentAssignmentSubmissionModalComponent implements OnInit {

  submissionsExist = false;
  submissionsDontExist = false;
  ImageFileExist = false;
  pdfFileExist = false;
  VideoFileExist = false;
  txtFileExist = false;
  xlsFileExist = false;

  submissions : any[] = [{
                          data: "",
                          fileName: "",
                          fileType: "",
                          studentAssignmentDetailsId: "",
                          studentAssignmentDetailstimeStamp:  "",
  }]

  studentId : any;
  activityPlannerId : any;
  officeId : any;

  safeImageUrl: any;
  unsafeImageUrl: any;

  url: any = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  
  pdfFile !: SafeResourceUrl;

  txtFile : any;
  constructor(public bsModalRef: BsModalRef,
              public activeModal: NgbActiveModal,
              private imageService: FileServiceService,
              private sanitizer : DomSanitizer ,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

    @Input() fromParent: any;

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.studentId = this.fromParent.studentId;
    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.officeId = this.fromParent.officeId;

    this.imageService.getStudentAssignmentSubmissionFiles(this.studentId, this.activityPlannerId, this.officeId).subscribe(
      data=>{
        this.submissions = data;

        if(this.submissions.length === 0 || this.submissions === undefined){
          this.submissionsDontExist = true;
        }else{
          this.submissionsExist = true;
        }
      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  viewSubmission(fileName : any, fileType : any){

    if(fileType === "png" || fileType === "svg" || fileType === "jpg" || fileType === "jpeg"){
      this.imageService.getAssignmentSubmissionFile(fileName, this.officeId).subscribe(
        data=>{
          
          this.unsafeImageUrl = URL.createObjectURL(data);
          
          this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
   
          this.submissionsExist = false;
          this.ImageFileExist = true;
        });
    }

    if(fileType === "mp4"){
      this.imageService.getAssignmentSubmissionFile(fileName, this.officeId).subscribe(
        data=>{
          this.url = URL.createObjectURL(data);
          this.urlSafe = this.sanitizer.bypassSecurityTrustUrl( this.url);
          // this.url = data;
          // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          this.submissionsExist = false;
          this.VideoFileExist = true;
        });
    }

    if(fileType === "pdf"){
      this.imageService.getAssignmentSubmissionFile(fileName, this.officeId).subscribe(
        data=>{

          this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));

          // this.unsafeImageUrl = URL.createObjectURL(data);
        
          // this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeImageUrl)

          // this.safeImageUrl = this.blobToBase64(data);
         
          // this.pdfFile = this._base64ToArrayBuffer(this.blobToBase64(data));

          this.submissionsExist = false;
          this.pdfFileExist = true;
        });
    }

    if( fileType === "txt" || fileType === "xls" || fileType === "xlsx"){
      this.imageService.getAssignmentSubmissionFile(fileName, this.officeId).subscribe(
        data=>{          
          this.txtFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
        
          this.submissionsExist = false;
          this.txtFileExist = true;
        });
    }

    if(fileType === "xls" || fileType === "xlsx"){
      this.imageService.getAssignmentSubmissionFile(fileName, this.officeId).subscribe(
        data=>{          
          this.txtFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
        
          this.submissionsExist = false;
          this.xlsFileExist = true;
        });
    }

  }

  

  _base64ToArrayBuffer(base64 : any) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};





  back(){

    this.xlsFileExist = false;
    this.txtFileExist = false;
    this.pdfFileExist = false;
    this.VideoFileExist = false;
    this.ImageFileExist = false;
    this.submissionsExist = true;

  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
