<!-- MODAL CLOSE BUTTON -->
<div [ngStyle]="backgroundStyle"   class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Shipment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
   <!-- MODAL CLOSE BUTTON -->


   
    <div class="modal-body">
    <div class="card">
      <div class="card-body">
        
        <form (ngSubmit)="onEdit()" #shipmentForm="ngForm">  
            

<div *ngIf="fromParent.context !== 'DELIVERY'">


    <div class="form-group row">
        <div class="col-md-6">
        <label class="required" for="shipmentAddress">Address</label>
        <input #address="ngModel" required  class="form-control" type="text" name="shipmentAddress" id="shipmentAddress" [(ngModel)]="shipment.shipmentAddress">
        <div class="alert alert-danger" *ngIf="address.touched && !address.valid">Provide Delivery Address</div>
    </div>

        <div class="col-md-6">
        <label class="required" for="shipmentPartner">Shipment Partner</label>
        <input  #partner="ngModel" required  class="form-control" type="text" name="shipmentPartner" id="shipmentPartner" [(ngModel)]="shipment.shipmentPartner">
        <div class="alert alert-danger" *ngIf="partner.touched && !partner.valid">Provide Shipment Partner</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-4">
        <label class="required"  for="shippedDate">Shipped Date</label>
        <input #date="ngModel" required class="form-control" type="datetime-local" name="shippedDate"
         id="shippedDate" [(ngModel)]="shipment.shippedDateTime">
        <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provice Shipped Date</div>
    </div>

    <div class="col-md-4">
        <label for="expectedDeliveryDateTime">Expected Delivery Date</label>
        <input class="form-control" [(ngModel)]="shipment.expectedDeliveryDateTime" type="datetime-local" id="expectedDeliveryDateTime" name="expectedDeliveryDateTime">
    </div>

    <div class="col-md-4">
        <label  for="deliveryDateTime">Delivery Date</label>
        <input class="form-control" type="datetime-local" name="deliveryDateTime" id="deliveryDateTime" [(ngModel)]="shipment.deliveryDateTime">
    </div>
    </div>

    <div >
        <label  for="shipmentRemarks">Shipment Remarks</label>
        <textarea #remarks="ngModel" maxlength="244" class="form-control" type="text" name="shipmentRemarks" id="shipmentRemarks" [(ngModel)]="shipment.shipmentRemarks"></textarea>
        <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
    </div>

    <br>

    <div>
        <label  for="deliveryRemarks">Delivery Remarks</label>
        <textarea #deliveryRemarks="ngModel" maxlength="244" class="form-control" type="text" name="deliveryRemarks" id="deliveryRemarks" [(ngModel)]="shipment.deliveryRemarks"></textarea>
        <div class="alert alert-danger" *ngIf="deliveryRemarks.touched && !deliveryRemarks.valid">Max length exceeded</div>
    </div>

   
</div>

<div *ngIf="fromParent.context === 'DELIVERY'">




    <div>
        
    <div>
        <label class="required" for="deliveryDateTime">Delivery Date</label>
        <input required class="form-control" type="datetime-local" name="deliveryDateTime" id="deliveryDateTime" [(ngModel)]="shipment.deliveryDateTime">
    </div>
    </div>

  
    <br>

    <div>
        <label  for="deliveryRemarks">Delivery Remarks</label>
        <textarea #deliveryRemarks="ngModel" maxlength="244" class="form-control" type="text" name="deliveryRemarks" id="deliveryRemarks" [(ngModel)]="shipment.deliveryRemarks"></textarea>
        <div class="alert alert-danger" *ngIf="deliveryRemarks.touched && !deliveryRemarks.valid">Max length exceeded</div>
    </div>

   
</div>

</form>
</div>
    </div>
    </div>

    <div class="modal-footer">
        <button [disabled]="clicked"  [disabled]="!shipmentForm.valid"  type="submit" class="btn btn-sm btn-primary float-right" (click)="onEdit(); clicked = true;">Update</button>
    </div>
    

	