
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <div [style.display]="mainDivState ? 'block' : 'none'">
 
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Subject</li>
    </ol>
  </nav>
  

<div class="content" style="margin-right: 2.5%;margin-left: 2.5%;" >
  <div class="card-group row">

    <div class="card col-md-4 col-lg-2 mat-elevation-z12 Students-Card" style="border-radius: 25px;">
<br>
      <div class="sub-image-div" style="margin-bottom: 3%;">
      <img class="sub-image" [src]="safeSubUrl" height="75" width="75" alt="...">
      </div>
      <div class="card-body">
        <h5 style="margin: 0%;padding: 0%;" class="card-title">{{subjectObject.name}}</h5>
        <div *ngIf="subjectObject.description !== null && subjectObject.description !== undefined" 
        style="padding: 1em;text-align: justify;text-justify:inter-word;word-break: break-all;">
          {{(readMore0) ? subjectObject.description : subjectObject.description | slice:0:69}} <span *ngIf="!readMore0"></span>
          <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!readMore0 && subjectObject.description.length >69" (click)="readMore0=true">...</a>
          <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="readMore0 && subjectObject.description.length >69" (click)="readMore0=false">[Read less]</a>
      
      </div>
        <div onclick="history.back()" style="cursor: pointer;margin-bottom: 3%;" class="sub-image-div">
          <img class="sub-image" src="/assets/images/icons/back-track.svg" height="65" width="65" alt="...">
          </div>
      </div>
    </div>

        <div *ngIf="activityExists === true;" class="card col-md-6 col-lg-6 mat-elevation-z12 Activity-Card" style="border-radius: 20px;">
          <div  style="width: 100%; padding: 2%;">

            
            

           
             <a  matTooltip="Swap Activity" style="cursor:pointer; float: right; margin-left: 70%;" 
              (click)="openModalForActivityCardChange();">
              <img alt="" height="35" loading="lazy" src="/assets/images/icons/changeActivity1.png" width="35">
            </a>
          </div>
         

          <div style="text-align: center;width: 100%;">
            
<img class="Activity-Image" [src]="safeUrl" height="75" width="75" alt="Card image cap">
         
          </div>
          <br>



          <div  style="text-align: center; width: 100%;">
            <h4 class="card-title">{{info.activityPlannerName}}</h4>

            <button style="width: auto;margin: 2%;" (click)="onAssess(mainDivState,info.activityPlannerId)"
             [ngStyle]="backgroundStyle" class="btn btn-info">Go to Activity</button>
<br>
            <!-- <p class="card-text">{{info.activityPlannerDescription}}</p> -->
            <div *ngIf="info.activityPlannerDescription !== null && info.activityPlannerDescription !== undefined"
             style="padding: 1em;text-align: justify;text-justify:inter-word;word-break: break-all;">
              {{(readMore1) ? info.activityPlannerDescription : info.activityPlannerDescription | slice:0:87}} <span *ngIf="!readMore1"></span>
              <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!readMore1 && info.activityPlannerDescription.length >87" (click)="readMore1=true">...</a>
              <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="readMore1 && info.activityPlannerDescription.length >87" (click)="readMore1=false">[Read less]</a>
          
          </div>
          </div>
         <br>
         <br>
        
      </div>

      <div class="card col-md-4 col-lg-2 mat-elevation-z12 Students-Card" style="border-radius: 25px;">

        <br>
        <br>
          <div class="sub-image-div">
          <img class="sub-image" src="/assets/images/icons/students.svg" height="100" width="100" alt="...">
          </div>
          <div class="card-body">
            <h5 class="card-title">Students</h5>
            <p style="margin-bottom:13% ;" class="card-text">No of Students : {{info.studentCount}}</p>

           
            <button  style="margin: 3%;" [ngStyle]="backgroundStyle" (click)="goToStudentsPage()"
             class="btn btn-info">Explore</button>
            <button  style="margin: 3%;" [ngStyle]="backgroundStyle" (click)="goToClassActivities()"
             class="btn btn-info">Activities</button>

          
            <div class="button-div">
            </div>
          </div>
        </div>

        <div class="card col-md-4 col-lg-2 mat-elevation-z12 Curriculum-Card" style="border-radius: 25px;">
          <br>
          <br>
          <div class="sub-image-div">
          <img class="sub!-image" src="/assets/images/icons/curriculum.svg"  height="100" width="100" alt="...">
          </div>
          <div class="card-body">
            <h5 class="card-title">Curriculum</h5>

           
            <button style="margin: 3%;" [ngStyle]="backgroundStyle" (click)="goToActivityTimeline()"
             class="btn btn-info">Timeline</button>
            <button style="margin: 3%;" [ngStyle]="backgroundStyle" (click)="goToCurriculumPage()" 
            class="btn btn-info">Explore</button>

           
          
            
          </div>
        </div>

        <div class="card col-md-4 col-lg-2 mat-elevation-z12 Results-Card" style="border-radius: 25px;">
          <br>
          <br>
          <div class="sub-image-div">
          <img class="sub!-image" src="/assets/images/icons/results.svg" height="100" width="100" alt="...">
          </div>
          <div class="card-body">
            <h5 class="card-title">Report</h5>
           
            <!-- <button style="margin: 3%;" *ngIf="officeObject.oa_SkillReport === true" [ngStyle]="backgroundStyle" (click)="goToStudentsResults()" class="btn btn-info">Skill Report</button> -->
            <button style="margin: 3%;" *ngIf="officeObject.oa_SkillReport === true" [ngStyle]="backgroundStyle" (click)="goToClassSkillReport()"
             class="btn btn-info">Skill Report</button>

            <button style="margin: 3%;" [ngStyle]="backgroundStyle" (click)="goToAssessmentResultsPage()"
             class="btn btn-info">Snapshot</button>
            <button *ngIf="officeObject.oa_Assignment === true" (click)="goToAssignmentDashboard();" style="margin: 3%;"
             [ngStyle]="backgroundStyle" class="btn btn-info">Assignment</button>
            
           
            
           
          </div>
        </div>

      </div>
  </div>
</div>
  
<div [style.display]="!mainDivState ? 'block' : 'none'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" (click)=mainF(mainDivState)>Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Activity Assessment</li>
    </ol>
  </nav>


<div class="content" style="margin: 2.5%;" >
 
  <div class="card-group row">

   

     <div class="card col-lg-6 mat-elevation-z12 Activity-Card" style="border-radius: 20px;">
            <div  style="width: 100%; padding: 2%;">

        <mat-icon matTooltip="Back" (click)=mainF(mainDivState) 
        style="cursor: pointer; color: rgb(14, 13, 13); float: left;"
         aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>
        
       <div class="row" style="cursor:pointer; float: right; margin-left: 70%;">
        <a matTooltip="Active" style="color: rgb(26, 116, 26); font-weight: bold;float: right;"
        *ngIf="info.activityCompletionStatus === false && info.activityActiveStatus === true">
        <img alt="" height="35" loading="lazy" src="/assets/images/icons/inProgress.png" width="35">
      </a>
      <a matTooltip="Completed" style="color: rgb(26, 116, 26); font-weight: bold;float: right;"
      *ngIf="info.activityCompletionStatus === true">
      <img alt="" height="35" loading="lazy" src="/assets/images/icons/complete.png" width="35">
    </a>
        <a matTooltip="Swap Activity" (click)="openModalForActivityCardChange();">
        <img alt="" height="35" loading="lazy" src="/assets/images/icons/changeActivity1.png" width="35">
      </a>
       </div>
        
      </div>
     

      <div style="text-align: center;width: 100%;margin-bottom: 2%;">
<img class="Activity-Image" [src]="safeUrl" height="75" width="75" alt="Card image cap">
      </div>
      

      <div  style="text-align: center; width: 100%;">
        <h4 style="margin: 0%;" class="card-title">{{info.activityPlannerName}}</h4>
      
        <button *ngIf="info.activityCompletionStatus === false && info.activityActiveStatus === true"  
        matTooltip="Mark Activity as Complete" (click)="markActivityAsComplete(info.activityPlannerId)" 
        style="width: auto;margin-bottom: 3%;" class="btn btn-info" [ngStyle]="backgroundStyle" >
         Mark as Complete</button>

<button *ngIf="info.activityCompletionStatus === true"  matTooltip="Un-Mark Activity as Complete" (click)="unMarkActivityAsComplete(info.activityPlannerId)" style="width: auto;margin-bottom: 3%;" class="btn btn-info" >
  Mark as Incomplete</button>
  
        <div *ngIf="info.activityPlannerDescription !== null && info.activityPlannerDescription !== undefined" 
        style="padding: 0em;text-align: justify;text-justify:inter-word;word-break: break-all;">
          {{(readMore2) ? info.activityPlannerDescription : info.activityPlannerDescription | slice:0:125}} <span *ngIf="!readMore2"></span>
          <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!readMore2 && info.activityPlannerDescription.length >125" (click)="readMore2=true">...</a>
          <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="readMore2 && info.activityPlannerDescription.length >125" (click)="readMore2=false">[Read less]</a>
      
      </div>

      </div>
      <div class="card-body">
         
      <div *ngIf="collapsedState2" [@fadeInOut]>
       
        

          <mat-vertical-stepper>

            <div class="stepper-class">

            <mat-step [editable]="true" [completed]="true"  *ngFor="let video of info.activityVideoLinkList">
              <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                <p></p>

                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                </div>
                  <br>
                  <div style="text-align: justify;text-justify:inter-word;word-break: break-all;">
                    {{video.actVideoLinkDescription}}
                  </div>
                  <br>
            </mat-step>
        </div>
          </mat-vertical-stepper>
        </div>
      <button mat-button color="primary" (click)="collapseActivityCardContent2(collapsedState2)">
          {{collapsedState2 ? 'Close' : 'More'}}</button>
      </div> 
  </div>
  <div class="col-lg-6" style="margin-top: 2em;">
  <div class="header" [ngStyle]="backgroundStyle">
    <h3>Assessment</h3>
      </div>
  <div class="accor_dion " style="background-color: transparent;">
    <mat-accordion class="example-headers-align" *ngFor="let student of students">
      <mat-expansion-panel [expanded]="step === student.studentId" (opened)="setStep(student.studentId)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img class="student-image" alt="" height="30" loading="lazy" [src]="student.safeStudentUrl" width="30">

            {{student.studentName}}
          </mat-panel-title>
         
            
  <a *ngIf="student.studentActivityHistoryIndicator == 0" style="color: rgb(179, 20, 20);"><img class="student-image" alt="" height="25" loading="lazy" src="assets/images/icons/nonStarted.png" width="25">0 Attempts</a>
  <a *ngIf="student.studentActivityHistoryIndicator == 1" style="color: rgb(179, 140, 14);"><img class="student-image" alt="" height="25" loading="lazy" src="assets/images/icons/inProgress.png" width="25">In-Progress</a>
  <a *ngIf="student.studentActivityHistoryIndicator == 2" style="color: green;"><img class="student-image" alt="" height="25" loading="lazy" src="assets/images/icons/complete.png" width="25">Completed</a>
  <br>
            
        </mat-expansion-panel-header>
        <button *ngIf="student.studentActivityHistoryIndicator != 2 && student.studentActivityHistoryIndicator != 0" 
        style="float: left; width: auto;" (click)="MarkActivityCompletedForStudent(student.studentId)" 
        class="btn btn-success" >Mark As Completed</button>
        <button *ngIf="student.studentActivityHistoryIndicator == 2 " style="float: left; width: auto;" (click)="UnMarkActivityCompletedForStudent(student.studentId)" class="btn btn-info" >Mark As In-Progress</button>
        <button class="btn btn-light" style="color: black;background-color: white;width: auto;">Attempts : {{student.studentAttempts}}</button> 

        <button *ngIf="student.studentActivityHistoryIndicator != 2" 
        style="float: right; color: white; background-color: rgb(105, 15, 15);" 
        (click)="onEvaluate(info.activityPlannerId, student.studentId, student.safeStudentUrl, student.studentName)" 
        class="btn btn-info" [ngStyle]="backgroundStyle">Evaluate</button>
      </mat-expansion-panel>
    
    </mat-accordion>
  </div>
</div>
  </div>

</div>
</div>
<br>
<br>
</div>




