import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceDetailsList } from 'src/app/crmClasses/invoice-details-list';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-order-details',
  templateUrl: './view-order-details.component.html',
  styleUrls: ['./view-order-details.component.scss']
})
export class ViewOrderDetailsComponent implements OnInit {

  invoice : InvoiceList = new InvoiceList();
  invDetails: InvoiceDetailsList[] = [];
  invDetailsObject : InvoiceDetailsList = new InvoiceDetailsList();
  invoiceId!: string;
  type : any; 
 
  @Input() fromParent: any; 
  loggedInUser : any;
  theme : any;
  constructor(public activeModal: NgbActiveModal,
    private datePipe : DatePipe,
    private sharedDataService: SharedDataService,
    private invoiceService : InvoiceService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

  ngOnInit(): void { 

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.invoiceId = this.fromParent.invoiceId;
    this.type = this.fromParent.type;

    this.invoiceService.getInvoiceById(this.invoiceId).subscribe(data=>{
      this.invoice = data;
      this.invoice.invoiceDate = this.datePipe.transform(this.invoice.invoiceDate, 'dd-MM-yyyy'); 

    }, error => console.log(error));

    this.invoiceService.getAllInvDetails(this.invoiceId).subscribe(data =>{
      this.invDetails = data;
    });

  }

  closeModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  
} 