import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Subject } from '../courseManagementClasses/subject';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  baseURL2 = environment.apiUrl +  "subject/getSubListByProductId";
  baseURL3 =  environment.apiUrl + "subject/getById";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  addSubject(subject : Subject):Observable<Subject>{
    return this.httpClient.post<Subject>( environment.apiUrl + 'subject/add',subject);
  }

    //AUTHORIZED
  addNewSubject(avenue: string, product : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, product, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, product, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getSubListByProductId(fkProductId : string): Observable<Subject[]>{
    return this.httpClient.get<Subject[]>(`${this.baseURL2}/${fkProductId}`);
  }

      //AUTHORIZED
      getSubjectsByProductIdClassAdmin(avenue: string): Promise<any> {

        this.authToken = localStorage.getItem('auth-token');

        const self = this;
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.authToken
        });
        return new Promise((resolve, reject) => {
          self.httpClient.get(avenue, { headers: headers })
            .toPromise()
            .then((data) => {
              resolve(data);
            }).catch((err) => {
            });
        });
      }
  

    //AUTHORIZED
    getSubjectsByProductIdCourseAdmin(avenue: string): Promise<any> {

      this.authToken = localStorage.getItem('auth-token');

      const self = this;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authToken
      });
      return new Promise((resolve, reject) => {
        self.httpClient.get(avenue, { headers: headers })
          .toPromise()
          .then((data) => {
            resolve(data);
          }).catch((err) => {
          });
      });
    }

  //AUTHORIZED
  getSubjectsByProductIdSA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getSubById(subId : string): Observable<Subject>{
    return this.httpClient.get<Subject>(`${this.baseURL3}/${subId}`);
  }

    //AUTHORIZED
    getSubByIdForTeacher(avenue: string): Promise<any> {

      this.authToken = localStorage.getItem('auth-token');

      const self = this;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authToken
      });
      return new Promise((resolve, reject) => {
        self.httpClient.get(avenue, { headers: headers })
          .toPromise()
          .then((data) => {
            resolve(data);
          }).catch((err) => {
          });
      });
    }
  

     //AUTHORIZED
     updateSubject(avenue: string, product : any): Promise<any> {

      this.authToken = localStorage.getItem('auth-token');

      const self = this;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authToken
      });
      return new Promise((resolve, reject) => {
  
        const req = new HttpRequest('PUT', avenue, product, {
          reportProgress: true,
          responseType: 'text'
        });
  
        self.httpClient.put(avenue, product, { headers: headers })
          .toPromise()
          .then((data) => {
            resolve(data);
          }).catch((err) => {
          });
      });
    }
  

}
