import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompleteCurriculum } from '../courseManagementClasses/complete-curriculum';
import { Curriculum } from '../courseManagementClasses/curriculum';

@Injectable({
  providedIn: 'root'
})
export class CurriculumService {

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  private baseUrl =  environment.apiUrl + "curriculum/getCurriculumBySubId";
  private baseUrl2 =  environment.apiUrl + "curriculum/getMainCurr";
  private baseUrl3 =  environment.apiUrl + "curriculum/getCompCurrListBySubId";
  private baseUrl4 =  environment.apiUrl + "curriculum/getSubCurrBySubId";
  private baseUrl5 =  environment.apiUrl + "curriculum/getById";
  private baseUrl7 = environment.apiUrl + "curriculum/getSortedCurrListBySubId";
  private baseUrl9 = environment.apiUrl + "curriculum/deactivateCurriculum";

  deactivateCurriculum(currId : string): Observable<any>{
    return this.httpClient.delete<any>(`${this.baseUrl9}/${currId}`)
   }

  //Gets list of all main & sub currs of a subject
  getCurriculumListBySubId(subId : string): Observable<Curriculum[]>{
   return this.httpClient.get<Curriculum[]>(`${this.baseUrl}/${subId}`)
  }
  
 //Gets only main currs of a subject
  getMainCurrListBySubId(subId : string): Observable<Curriculum[]>{
    return this.httpClient.get<Curriculum[]>(`${this.baseUrl2}/${subId}`)
   }

   //Gets only sub currs of a subject
   getSubCurrListBySubId(subId : string): Observable<Curriculum[]>{
    return this.httpClient.get<Curriculum[]>(`${this.baseUrl4}/${subId}`)
   }

  //Gets [main curr + List of sub currs] of a subject
   getCompCurrListBySubId(subId : string): Observable<CompleteCurriculum[]>{
    return this.httpClient.get<CompleteCurriculum[]>(`${this.baseUrl3}/${subId}`)
   }

  addCurriculum(avenue: string, curriculum : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, curriculum, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post (avenue, curriculum, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateCurriculum(avenue: string, curriculum : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, curriculum, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put (avenue, curriculum, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

    // authorized
    updateCurriculumOrder(avenue: string, curriculum : any): Promise<any> {

      this.authToken = localStorage.getItem('auth-token');

      const self = this;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authToken
      });
      return new Promise((resolve, reject) => {
  
        const req = new HttpRequest('PUT', avenue, curriculum, {
          reportProgress: true,
          responseType: 'text'
        });
  
        self.httpClient.put (avenue, curriculum, { headers: headers })
          .toPromise()
          .then((data) => {
            resolve(data);
          }).catch((err) => {
          });
      });
    }

  getCurrById(curId : string): Observable<Curriculum>{
    return this.httpClient.get<Curriculum>(`${this.baseUrl5}/${curId}`)
   }

   getCurrByIdForSA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getCurrByIdForCA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getCurrByIdForTeacher(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getSortedCurrListBySubId(subId : string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.baseUrl7}/${subId}`)
   }

   getSortedCurrListBySubIdForSuperAdmin(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getSortedCurrListBySubIdForCourseAdmin(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getSortedCurrListBySubIdForTeacher(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }




   getSameLevelCurriculums(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
}
