import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotSchedule } from "src/app/BOT_CODE/bot_classes/bot-schedule";
import { BotScheduleDetails } from "src/app/BOT_CODE/bot_classes/bot-schedule-details";
import { DatePipe } from "@angular/common";
import { ScheduleMessagesComponent } from "../schedule-messages/schedule-messages.component";

@Component({
  selector: "app-view-schedule-details",
  templateUrl: "./view-schedule-details.component.html",
  styleUrls: ["./view-schedule-details.component.scss"],
})
export class ViewScheduleDetailsComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  constructor(
    private ngbModalService: NgbModal,
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botSchedule: BotSchedule = new BotSchedule();
  ALLbotSchDetails: BotScheduleDetails[] = [];
  botScheduleDetails: BotScheduleDetails[] = [];

  currentTimezone: any;
  searchText1: any;
  dataLoaded = false;

  totalCount = 0;
  successCount = 0;
  scheduledCount = 0;
  queuedCount = 0;
  failedCount = 0;
  currentContext = "all";

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botSchedule = this.fromParent.botSchedule;
    this.currentTimezone = this.fromParent.currentTimezone;

    this.getBotScheduleDetailsByBotScheduleId();
  }

  getBotScheduleDetailsByBotScheduleId() {
    this.botOfficeService
      .getBotScheduleDetailsByBotScheduleId(
        environment.apiUrl +
          "botOffice/getBotScheduleDetailsByBotScheduleId/" +
          this.botSchedule.botScheduleId
      )
      .then((res) => {
        let botSD: BotScheduleDetails = new BotScheduleDetails();
        botSD = res;
        this.botScheduleDetails = botSD.list;

        this.totalCount = this.botScheduleDetails.length;

        this.botScheduleDetails.forEach((details) => {
          if (
            details.executedTimestamp !== null &&
            details.executedTimestamp !== undefined
          ) {
            let x = details.executedTimestamp;
            // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
            details.executedTimestamp = this.datePipe.transform(
              x,
              "d/M/yy, h:mm a"
            );
          }

          if (details.botSchDetCompletionStatus === "q") {
            this.queuedCount = this.queuedCount + 1;
          }
          if (details.botSchDetExecStatus === "failure") {
            this.failedCount = this.failedCount + 1;
          }
          if (details.botSchDetExecStatus === "success") {
            this.successCount = this.successCount + 1;
          }
          if (
            details.botSchDetExecStatus === "scheduled" &&
            details.botSchDetCompletionStatus !== "q"
          ) {
            this.scheduledCount = this.scheduledCount + 1;
          }
        });
        this.dataLoaded = true;
        this.ALLbotSchDetails = this.botScheduleDetails;
      });
  }

  changeContext(context: any) {
    this.dataLoaded = false;
    this.botScheduleDetails = [];
    if (context === "all") {
      this.ALLbotSchDetails.forEach((details) => {
        this.botScheduleDetails.push(details);
      });
      this.dataLoaded = true;
    } else {
      this.ALLbotSchDetails.forEach((details) => {
        if (context === "queued" && details.botSchDetCompletionStatus === "q") {
          this.botScheduleDetails.push(details);
        } else if (
          context === "success" &&
          details.botSchDetExecStatus === "success"
        ) {
          this.botScheduleDetails.push(details);
        } else if (
          context === "scheduled" &&
          details.botSchDetExecStatus === "scheduled" &&
          details.botSchDetCompletionStatus !== "q"
        ) {
          this.botScheduleDetails.push(details);
        } else if (
          context === "failed" &&
          details.botSchDetExecStatus === "failure"
        ) {
          this.botScheduleDetails.push(details);
        }
      });
      this.dataLoaded = true;
    }
    this.currentContext = context;
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  addBotSchedule() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ScheduleMessagesComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Reschedule Template Message",
      botScheduleDetails: this.botScheduleDetails,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.passEntry.emit("rescheduled");
    });
  }
}
