<br>
<br>
<br>
<br>
<br>
<br>

<div class="container" style="align-self: center; text-align: center;">

<a> <img src="assets/images/invalidUrl.png" height="60" width="60"></a>

<h2 style="margin-top: 5%; align-self: center;"> Invalid url !</h2>

<h2 style="align-self: center;"> Page does not exist </h2>

<button type="button" (click)="logOut();" class="btn btn-primary">Login </button>

</div>