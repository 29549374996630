export class BotScheduleDetails {
  botScheduleDetailsId: any;
  botScheduleDetailsStatus: any;
  botSchDetExecStatus: any;
  fkBotScheduleId: any;
  fkBotUserId: any;
  botScheduleExecutionStatus: any;
  executedTimestamp: any;
  botSchDetErrorMessage: any;
  fkBotUserName: any;
  fkBotUserPhone: any;
  list: BotScheduleDetails[] = [];
  fkBotUserGroupDetailsId: any;
  duplicateStatus: any;
  botUserGroupName: any;
  botSchDetCompletionStatus: any;
}
