<div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Update Payment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>  

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onEdit()" #paymentForm="ngForm">
        <div>
            <label hidden  for="paymentId">Payment Id</label>
            <input hidden  type="text" name="paymentId" id="paymentId" [(ngModel)]="payment.paymentId">
        </div>

            <div>
                <label hidden for="fkInvoiceId">fkInvoiceId</label>
                <input hidden type="text" name="fkInvoiceId" id="fkInvoiceId" [(ngModel)]="payment.fkInvoiceId">
            </div>

            <div class="form-group row">
                <div class="col-md-6">
            <label class="required" for="paymentMethod">Method</label>
            <input #method="ngModel" required class="form-control" type="text" name="paymentMethod" id="paymentMethod" [(ngModel)]="payment.paymentMethod">
            <div class="alert alert-danger" *ngIf="method.touched && !method.valid">Provide Payment Method</div>
        </div>

        <div class="col-md-6">
            <label class="required" for="paymentDate">Date</label>
            <input #date="ngModel" required class="form-control" type="Date" name="paymentDate" id="paymentDate" [(ngModel)]="payment.paymentDate">
            <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Choose Date</div>
        </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6">
            <label class="required" for="paymentAmount">Amount</label>
            <input #amount="ngModel" required class="form-control" type="number" name="paymentAmount" id="paymentAmount" [(ngModel)]="payment.paymentAmount">
            <div class="alert alert-danger" *ngIf="amount.touched && !amount.valid">Enter Amount</div>
        </div>

        <div class="col-md-6">
            <label for="paymentDue">Amount Due</label>
            <input class="form-control" type="number" name="paymentDue" id="paymentDue" [(ngModel)]="payment.paymentDue">
        </div>
            </div>

            <div class="form-group row">
        <div class="col-md-6">
            <label for="paymentCompletion">Payment Completion</label>
            <input class="form-control" type="text" name="paymentCompletion" id="paymentCompletion" [(ngModel)]="payment.paymentCompletion">
        </div>
    </div>

    <div >
        <label for="paymentRemarks">Remarks</label>
        <textarea  #remarks="ngModel" maxlength="244" class="form-control" type="text" name="paymentRemarks" id="paymentRemarks" [(ngModel)]="payment.paymentRemarks"></textarea>
        <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
    </div>

        
    </form>
      </div>
    </div>
</div>

<div class="modal-footer"> 
    <button  [disabled]="clicked"  [disabled]="!paymentForm.valid" type="submit" class="btn btn-sm btn-primary float-right"  (click)="onEdit(); clicked = true;">Update</button>
</div>