import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Payment } from '../crmClasses/payment';
 
@Injectable({ 
  providedIn: 'root'
}) 
export class PaymentService {

  private getUrl =  environment.apiUrl + "payment/getDTOById";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }
  
addPayment(avenue: string, product : any): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {

    const req = new HttpRequest('POST', avenue, product, {
      reportProgress: true,
      responseType: 'text'
    });

    self.httpClient.post(avenue, product, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}

getPaymentsByPeriod(avenue: string, product : any): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {

    const req = new HttpRequest('POST', avenue, product, {
      reportProgress: true,
      responseType: 'text'
    });

    self.httpClient.post(avenue, product, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}


createPaymentAndUpdateInvoice(avenue: string, product : any): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {

    const req = new HttpRequest('POST', avenue, product, {
      reportProgress: true,
      responseType: 'text'
    });

    self.httpClient.post(avenue, product, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}

getAllPaymentsByOfficeId(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {
    self.httpClient.get(avenue, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}

getCurrentMonthPayments(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {
    self.httpClient.get(avenue, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}


getByCustomerId(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {
    self.httpClient.get(avenue, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}
 
getPaymentById(paymentId : string):Observable<Payment>{
  return this.httpClient.get<Payment>(`${this.getUrl}/${paymentId}`);
 } 

updatePayment(avenue: string, product : any): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {

    const req = new HttpRequest('PUT', avenue, product, {
      reportProgress: true,
      responseType: 'text'
    });

    self.httpClient.put(avenue, product, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}


// deleteProduct(productId : string) : Observable<Object>{
//   return this.httpClient.delete(`${this.deleteUrl}/${productId}`);
// } 

changeStatus(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {
    self.httpClient.delete(avenue, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}
 

}
