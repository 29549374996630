import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'src/app/crmClasses/address';
import { AddressService } from 'src/app/service/address.service';

@Component({
  selector: 'app-address-update',
  templateUrl: './address-update.component.html',
  styleUrls: ['./address-update.component.scss']
})
export class AddressUpdateComponent implements OnInit {

  addressId!: string;
  address : Address = new Address();


  constructor(private addressService : AddressService,
    private router : Router,
    private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.addressId = this.route.snapshot.params['addressId'];
    this.addressService.getAddressById(this.addressId).subscribe(data=>{
      this.address = data;
    }, error => console.log(error));
  }

  onUpdate(){
    this.addressService.updateAddress(this.address).subscribe(
      data =>{
        this.goToAddressList();
      }
      ,error => console.error()
      
    );
  }

  goToAddressList(){
    this.router.navigate(['/addressList']);
  }

} 
