

<!-- <div  [ngStyle]="backgroundStyle" class="modal-header">
  <h4  style="width: 50%;color: white;">
    <img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">
    {{details.studentName}}<h4 style="color: white;margin: 0%;">{{details.email}} </h4>  <h4 style="color: white;margin: 0%;">{{details.phone}}</h4>  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>  -->
  <!-- <div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4  style="width: 50%;color: white;">
      <img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">
      {{details.studentName}}<h4 style="color: white;margin: 0%;">{{details.email}} || {{details.phone}} </h4>  </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>  -->
    <div  [ngStyle]="backgroundStyle" class="modal-header">
      <h4  style="width: 50%;color: white;">
        <img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">
        {{details.studentName}} || {{details.email}} || {{details.phone}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> 

   


      <div class="modal-body">
 <!-- <h1 style="text-align: left;">Student Details</h1>

 <div style="margin-bottom: 6%;">

        <h4 *ngIf="details.dob!==null" style="text-align: left;margin: 0%;"><i class="fa fa-calendar" aria-hidden="true">
          {{details.dob}}
        </i></h4>

        <h4 style="text-align: left;margin: 0%;"><i class="fa fa-phone" aria-hidden="true">
          {{details.phone}}
        </i></h4>

        <h4 style="text-align: left;margin: 0%;"><i class="fa fa-envelope" aria-hidden="true">
          {{details.email}}
        </i></h4>
       
   
      </div> -->
        
<h1 style="text-align: left;">Timeline</h1>
        <table style="margin-bottom: 8%;">
          <thead>
            <th>Activity</th>
            <th>Completion Status</th>
            <th>Attempts</th>
          </thead>
          <tbody>
            <tr *ngFor="let act of allActivitiesList">
              <td>{{act.activityPlannerName}}</td>
              <td>

                <a *ngIf="act.studentActivityPlannerCompletionStatus === '0'" >
                <img class="student-image" alt="" height="17"  width="50"loading="lazy" 
                src="assets/images/icons/redRectangle.png"></a>

                <a *ngIf="act.studentActivityPlannerCompletionStatus === '1'" >
                  <img class="student-image" alt="" height="20" width="20" loading="lazy" 
                  src="assets/images/icons/inProgress.png" ></a>

                <a *ngIf="act.studentActivityPlannerCompletionStatus === '2'" >
                  <img class="student-image" alt="" height="20" width="20" loading="lazy" 
                  src="assets/images/icons/selected.png" ></a>  

              </td>

              <td>{{act.studentAttempts}}</td>
            </tr>

          </tbody>
        </table>

<br>



































<h1 *ngIf="skillReportNotReady === true" style="text-align: left;">Skill Report Not Ready</h1>

<h1 *ngIf="chartReady===true" style="text-align: left;">Skill Report</h1>
 
<div *ngIf="chartReady===true" class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let skillResult of actualResultlist">
        <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
        
          <div class="row col-md-12" style="margin: 0%; padding: 0%;">
            <div class="col-md-6">
               <h2 style="float: left;">{{skillResult.skillName}} </h2>
            </div>
            <div style="text-align: right;" class="col-md-6">
              <!-- <a matTooltip="View Skill Details"  style="cursor: pointer;" (click)="openSubSkillDetailsModal(skillResult)">
              <img style="float: right;" width="25" height="25" style="border-radius:50%;" alt="image" src="/assets/images/icons/eye.png">
            </a> -->
            <button style="width: auto;float: right;pointer-events: none;" [ngStyle]="backgroundStyle" class="btn btn-info">{{skillResult.parentSkillName}}</button>
            </div> 
          </div>

          <div class="row col-md-12">

          <div class="col-md-4" style="width: 100%;text-align: center;">
            <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="skillResult.safeUrl">
          </div>

            <div *ngIf="skillResult.resultHistory !== undefined && skillResult.resultHistory !== null" class="col-md-4" style="width: 100%;text-align: center;">

              <div class="chart-wrapper">
                <canvas baseChart
                    [datasets]="skillResult.lineChartData" 
                    [labels]="lineChartLabels" 
                    [options]="lineChartOptions"
                    [colors]="lineChartColors" 
                    [legend]="lineChartLegend" 
                    [chartType]="lineChartType" 
                    [plugins]="lineChartPlugins">
                </canvas>
            </div>

            </div>

            <div *ngIf="skillResult.resultHistory === undefined || skillResult.resultHistory === null" class="col-md-4" style="width: 100%;text-align: center;">

              <h1>
                No Data 
               </h1>

            </div>
        
            <div class="col-md-4" style="width: 100%;text-align: center;margin-top: 5%;">
           <h1 *ngIf="skillResult.skillScore > skillResult.lastScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
            <a style="font-size:1.8em;">
            <i class="fa fa-caret-up" style="color:green"></i></a>
           </h1>

           <h1 *ngIf="skillResult.lastScore > skillResult.skillScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
            <a style="font-size:1.8em;">
            <i class="fa fa-caret-down" style="color:red"></i></a>
           </h1>

           <h1 *ngIf="skillResult.lastScore === skillResult.skillScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
            <a style="font-size:1.8em;">
           </a>
           </h1>

           <h1 *ngIf="skillResult.lastScore === undefined"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}} -</a>
           </h1>

            </div>

          </div>
          <br>
            <div style="text-align: center;">
            
        </div>
          <br>
                         
          <div style="text-align: center;">
            <p>
              {{skillResult.resultSuggestion}}
            </p>
          </div> 

        </mat-card>
      </div>
    </div>
    </div>
  
</div>



  
    

  