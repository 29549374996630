import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ViewFeedbackQuestionComponent } from '../../feedback-question/view-feedback-question/view-feedback-question.component';

@Component({
  selector: 'app-edit-feedback-form',
  templateUrl: './edit-feedback-form.component.html',
  styleUrls: ['./edit-feedback-form.component.scss']
})

export class EditFeedbackFormComponent implements OnInit {

  clicked = false;
  feedbackForm : FeedbackForm = new FeedbackForm();
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  addedQuestionIds : string[] = [];

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
    private sanitizer : DomSanitizer,
    private imageService : FileServiceService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };
            safeImageUrl: any;
            unsafeImageUrl: any;
  ngOnInit(): void { 

    this.feedbackForm = this.fromParent.form;

    this.imageService.getFeedbackFormImage(this.feedbackForm.formImage).subscribe(
      data=>{
        this.safeImageUrl = data;
        this.unsafeImageUrl = URL.createObjectURL(this.safeImageUrl);
        this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
      });

    this.feedbackForm.feedbackQuestionsNew = [];

    this.feedbackForm.feedbackQuestions.forEach((val)=>{
      this.addedQuestionIds.push(val.id);
    });

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();    
  }

  changeMandatoryStatusToTrue(id : any){

      this.formService.changeMandatoryStatus(environment.apiUrl + "feedbackForm/changeMandatoryStatus/" + id).then(
        data=>{          
          this.feedbackForm.feedbackQuestions.forEach((val)=>{
            
            if(val.feedbackFormQuestionId === id){              
              val.mandatory = !val.mandatory;
            }
          });
        });
    

  }

  changeMandatoryStatusToFalse(id : any){

    let count = 0;
    this.feedbackForm.feedbackQuestions.forEach((qn)=>{
      if(qn.mandatory === true){
        count++;
      }
    });

    
    if(count===1){

      this.toaster.error('Make atleast one of the questions mandatory to proceed !');

    }else if(count>1){

      this.formService.changeMandatoryStatus(environment.apiUrl + "feedbackForm/changeMandatoryStatus/" + id).then(
        data=>{
          this.feedbackForm.feedbackQuestions.forEach((val)=>{
            if(val.feedbackFormQuestionId === id){
              val.mandatory = !val.mandatory;
            }
          })
        });
    }

  }


  addFeedbackQuestions(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewFeedbackQuestionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      addedQuestionIds : this.addedQuestionIds
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      
      receivedEntry.forEach((val:any)=>{
        this.feedbackForm.feedbackQuestions.push(val);
        this.feedbackForm.feedbackQuestionsNew.push(val);
        this.addedQuestionIds.push(val.id);
      });
    });   
  }

  selectedFile!: File;
  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
  }

  onSubmit(){


    let oneMandatoryQsnCheck = false;
    this.feedbackForm.feedbackQuestions.forEach((qn)=>{
      if(qn.mandatory === true){
        oneMandatoryQsnCheck = true;
      }
    });

    if(oneMandatoryQsnCheck===false){

      this.toaster.error('Make atleast one of the questions mandatory to proceed !');

    }else if(oneMandatoryQsnCheck===true){
      this.clicked = true;

          //authorized
    
    this.formService.updateFeedbackForm(environment.apiUrl + "feedbackForm/editFeedbackForm", this.feedbackForm).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Form updated successfully');
        this.passEntry.emit('success');

               //UPLOAD IMAGE
               let body = new FormData();
               body.append("file", this.selectedFile);
      
               this.imageService.uploadFeedbackFormImage(body, this.feedbackForm.id).subscribe(
                 data=>{
                 });
      });
    }



  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 