import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IncentiveTier } from 'src/app/BIZ_CRM/biz_crm_classes/incentive-tier';
import { IncentiveTierService } from 'src/app/BIZ_CRM/biz_crm_services/incentive-tier.service';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { EditIncentiveTierComponent } from '../edit-incentive-tier/edit-incentive-tier.component';

@Component({
  selector: 'app-view-grouped-incentive-tiers',
  templateUrl: './view-grouped-incentive-tiers.component.html',
  styleUrls: ['./view-grouped-incentive-tiers.component.scss']
})
export class ViewGroupedIncentiveTiersComponent implements OnInit {

  clicked = false;
  incentiveTiers : IncentiveTier[] = [];
  loggedInUser : any; 
  theme : any;
  roles : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  constructor(public activeModal: NgbActiveModal,
    private itService : IncentiveTierService,
    private ngbModalService: NgbModal,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.incentiveTiers = this.fromParent.incentiveTiers;

  }

  editIncentiveTier(incentiveTier : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditIncentiveTierComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });
      

let data = {
  incentiveTier : incentiveTier
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : IncentiveTier) => {

      this.incentiveTiers.forEach((val)=>{

        if(val.id === receivedEntry.id){
          val.incentive = receivedEntry.incentive;
          val.endValue = receivedEntry.endValue;
          val.startValue = receivedEntry.startValue;
        }
      });

      this.passEntry.emit("edited");
   
    }); 
    
  }


  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  close(){
    this.activeModal.close();
  }
  
}