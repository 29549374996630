<div  [style.background-color]='getColor()'  class="modal-header">
  <h4 *ngIf="fromParent.context==='Warning Component'"
    style="color: white;" class="modal-title pull-left">Variable Exists</h4>
    <h4 *ngIf="fromParent.context==='addResponseToResponse'"
    style="color: white;" class="modal-title pull-left">Create Next Response to Response</h4>
    <h4 *ngIf="fromParent.context==='createAdditionalSubResponse'"
    style="color: white;" class="modal-title pull-left">Create Sub-Response to Response</h4>
    <h4 *ngIf="fromParent.context==='createOrderPlacedResponse'"
    style="color: white;" class="modal-title pull-left">Create Response</h4>
    <h4 *ngIf="fromParent.context==='createFlowInitiationResponse'"
    style="color: white;" class="modal-title pull-left">Create Flow Initiation Response</h4>
    <h4 *ngIf="fromParent.context==='addConditionalResponseToResponse'"
    style="color: white;" class="modal-title pull-left">Create Next Conditional Response to Response</h4>
     <h4 *ngIf="fromParent.context==='addConditionalResponseToOption'"
    style="color: white;" class="modal-title pull-left">Create Next Conditional Response to Option</h4>
    <h4 *ngIf="fromParent.context==='addResponseToOption'"
    style="color: white;" class="modal-title pull-left">Create Next Response to Option</h4>
    <h4 *ngIf="fromParent.context==='add_callback_response_to_option'"
    style="color: white;" class="modal-title pull-left">Choose Callback Notification Flow</h4>
    <h4 *ngIf="fromParent.context==='deactivate_option_callback_response'"
    style="color: white;" class="modal-title pull-left">Deactivate Callback Notification</h4>
    <h4 *ngIf="fromParent.context==='setup_option_callback_function'"
    style="color: white;" class="modal-title pull-left"> Callback Notification Function</h4>
    <h4 *ngIf="fromParent.context==='setup_response_callback_function'"
    style="color: white;" class="modal-title pull-left"> Callback Notification Function</h4>
    <h4 *ngIf="fromParent.context==='linkConvoFlowToUserRegResOption'"
    style="color: white;" class="modal-title pull-left">Link Convo Flow</h4>
    <h4 *ngIf="fromParent.context==='linkBotUserGroupToUserRegResOption'"
    style="color: white;" class="modal-title pull-left">Link Group</h4>
    <h4 *ngIf="fromParent.context==='addConvoInitiationResponse'"
    style="color: white;" class="modal-title pull-left">Create Conversation Initiation Response</h4>
    <h4 *ngIf="fromParent.context==='linkNextResponseToOption'"
    style="color: white;" class="modal-title pull-left">Link Next Response to Option</h4>
    <h4 *ngIf="fromParent.context==='linkNextResponseToResponse'"
    style="color: white;" class="modal-title pull-left">Link Next Response to Option</h4>
    <h4 *ngIf="fromParent.context==='editBotResponse' || fromParent.context==='editErrorResponse'"
    style="color: white;" class="modal-title pull-left">Edit Response</h4>
    <h4 *ngIf="fromParent.context==='editTemplateResponse'"
    style="color: white;" class="modal-title pull-left">Edit Response</h4>
    <h4 *ngIf="fromParent.context==='setBackResponse'"
    style="color: white;" class="modal-title pull-left">Set Back Response</h4>
    <h4 *ngIf="fromParent.context==='addFirstUserRegistrationResponse'"
    style="color: white;" class="modal-title pull-left">User Registration Question</h4>
    <h4 *ngIf="fromParent.context==='addNextUserRegistrationResponse'"
    style="color: white;" class="modal-title pull-left">User Registration Question</h4>
    <h4 *ngIf="fromParent.context==='createErrorResponse'"
    style="color: white;" class="modal-title pull-left">Create Error Response</h4>
    <h4 *ngIf="fromParent.context==='createTemplateResponse'"
    style="color: white;" class="modal-title pull-left">Create Template Response</h4>
    <h4 *ngIf="fromParent.context==='setOfficeDefaultErrorResponse'"
    style="color: white;" class="modal-title pull-left">Set Default Error Response</h4>
    <h4 *ngIf="fromParent.context==='setErrorResponse'"
    style="color: white;" class="modal-title pull-left">Set Error Response</h4>

    <h4 *ngIf="fromParent.context==='linkFkBotTemplateResToBotTemplate'"
    style="color: white;" class="modal-title pull-left">Set Template Response</h4>
    
    <h4 *ngIf="fromParent.context==='setExtApi'"
    style="color: white;" class="modal-title pull-left">External API Call</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #botResponseForm="ngForm"> 


     <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='Warning Component'">
        <div>
          <label>{{message}}</label>  
        </div>
      </div>
        
   <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
        <div *ngIf="fromParent.context==='editBotResponse'">

          <div *ngIf="botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='OrderPlacedResponse'">
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button *ngIf="botResponse.type!=='subresponse'" (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button *ngIf="botResponse.type!=='subresponse'" (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button>
              <mat-radio-button *ngIf="botResponse.type!=='subresponse'" (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
          </div>  
    
          <br>
    
           <div *ngIf="botResponse.resType==='text'">
              <label class="required" for="response">Response 
                <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                  <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                </a>
              </label> <br>
              
              <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
              </textarea>
            </div>

            <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
            || botResponse.resType==='image with buttons' || botResponse.resType === 'audio' 
            || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
              <div>
                <label class="col-md-6" class="required" for="fkBotMediaId">Choose media to send</label> 
                <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
                id="fkBotMediaId" name="fkBotMediaId">
                  <option value="" disabled >Choose Media to send </option>
                  <option  *ngFor="let med of media"
                   [ngValue]="med.botMediaId"> {{med.title}}  
                  </option>
                </select>
              </div>

              <br>
            <div style="float: right;">
  <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
            </div>

              <br *ngIf="botResponse.resType !== 'audio'">
              <div *ngIf="botResponse.resType !== 'audio'">
                <label for="response">Caption
                  <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                    <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                  </a>
                </label> <br>
                <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
                </textarea>
              </div>
            </div>

           
            
    <br *ngIf="botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='subresponse'">
    
    <div *ngIf="botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='subresponse'">
      <label class="required">User Response Type </label> <br>
      <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" 
      [(ngModel)]="botResponse.userResType">
        <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
        <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
        <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
        <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
        <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
        <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
        <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
        <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
        <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
      </mat-radio-group>
    </div>

    <div>
      <br>
      <div *ngIf="botResponse.userResType===8">
        <label>Save User's Response As ?</label> <br>
        <mat-radio-group name="botResponse.advancedLocationConfig" id="botResponse.advancedLocationConfig" aria-label="advancedLocationConfig" 
        [(ngModel)]="botResponse.advancedLocationConfig">
        <mat-radio-button name="advancedLocationConfig" id="200" value="none" >None</mat-radio-button>
        <mat-radio-button name="advancedLocationConfig" id="201" value="save_as_del_addr" >Address to Last Location Shared by User</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div>
      <br>
      <div *ngIf="botResponse.userResType===2">
        <label>Mark Location As</label> <br>
        <mat-radio-group name="botResponse.saveLocationTypeAs" id="botResponse.saveLocationTypeAs" aria-label="saveLocationTypeAs" 
        [(ngModel)]="botResponse.saveLocationTypeAs">
        <mat-radio-button name="saveLocationTypeAs" id="100" value="none" >None</mat-radio-button>
        <mat-radio-button name="saveLocationTypeAs" id="101" value="order_delivery_address" >Order Delivery Address</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    
    <br *ngIf="botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='OrderPlacedResponse' && botResponse.type!=='subresponse'">
    
        <div  *ngIf="botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='OrderPlacedResponse' && botResponse.type!=='subresponse'" class="form-group row">
          <div class="col-md-6">
            <label class="required">Do you want to save customer's answer for this question ? </label> <br>
            <mat-radio-group name="botResponse.saveVar" id="botResponse.saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
              <mat-radio-button  name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
              <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
            </mat-radio-group>
          </div>  
    
        
    
          <div *ngIf="botResponse.saveVar===true && botResponse.type!=='UpdateNewUserDetails'" class="col-md-6">
          <label class="required" for="varV">Variable Name</label>
          <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
          name="varV" id="varV" [(ngModel)]="botResponse.var">
          <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
          </div>    
        </div>

        <div *ngIf="botResponse.userResType===1 && botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='OrderPlacedResponse' && botResponse.type!=='subresponse'" class="form-group row">
          <div class="col-md-6">
            <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
            <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
            required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
              <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
              <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
            </mat-radio-group>
          </div>  

          <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
          <label class="required" for="varV">Validation Type</label> <br>
          <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
            required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
              <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
              <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
            </mat-radio-group>
          </div>    
        </div>

        <div *ngIf="botResponse.type!=='UpdateNewUserDetails' && botResponse.type!=='OrderPlacedResponse' && botResponse.type!=='subresponse'">

          <br>
        <div class="form-group row">
          <div class="col-md-6">
            <label for="interactiveListButtonName">Interactive List Button Name</label>
            <input class="form-control" maxlength="19" type="text" placeholder="Enter Button Name" 
            name="interactiveListButtonName" id="interactiveListButtonName" [(ngModel)]="botResponse.interactiveListButtonName">
          </div>
        </div>

        <br>
        <div *ngIf="botResponse.userResType===1 || botResponse.userResType===9">
          <label for="userDetailsUpdateVar">Do you want to save customer answer to this response as customer details ?</label>
          <mat-form-field [style.width.%]=100 appearance="fill">
            <mat-label> Choose customer details to be updated</mat-label>
            <mat-select [(ngModel)]="botResponse.userDetailsUpdateVar">
              <mat-option value=null>None</mat-option>
              <mat-option value="name">Name</mat-option>
              <mat-option value="gender">Gender</mat-option>
              <mat-option value="alternatePhone">Alternate Phone</mat-option>
              <mat-option value="customVar1">Custom Variable 1</mat-option>
              <mat-option value="customVar2">Custom Variable 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        </div>
        


    </div>

       <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
       <div *ngIf="fromParent.context==='editErrorResponse'">

        <div *ngIf="botResponse.type!=='UpdateNewUserDetails'">
          <label class="required">Response Format </label> <br>
          <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
            <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
            <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
            <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
            <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
          </mat-radio-group>
          </div>  
  
        <br>
  
         <div *ngIf="botResponse.resType==='text'">
            <label class="required" for="response">Response 
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
            </textarea>
          </div>

          <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
          || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
          || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
            <div>
              <label class="col-md-6" class="required" for="fkBotMediaId">Choose media to send</label> 
              <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
              id="fkBotMediaId" name="fkBotMediaId">
                <option value="" disabled >Choose Media to send </option>
                <option  *ngFor="let med of media"
                 [ngValue]="med.botMediaId"> {{med.title}}  
                </option>
              </select>
            </div>

            <br>
          <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
          </div>

            <br *ngIf="botResponse.resType !== 'audio'">
            <div *ngIf="botResponse.resType !== 'audio'">
              <label for="response">Caption
                <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                  <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                </a>
              </label> <br>
              <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
              </textarea>
            </div>
          </div>
  


  </div>

         <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
         <div *ngIf="fromParent.context==='editTemplateResponse'">

          <div *ngIf="botResponse.type!=='UpdateNewUserDetails'">
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
            </div>  
    
          <br>
    
           <div *ngIf="botResponse.resType==='text'">
              <label class="required" for="response">Response 
                <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                  <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                </a>
              </label> <br>
              
              <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
              </textarea>
            </div>
  
            <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
            || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
            || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
              <div>
                <label class="col-md-6" class="required" for="fkBotMediaId">Choose media to send</label> 
                <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
                id="fkBotMediaId" name="fkBotMediaId">
                  <option value="" disabled >Choose Media to send </option>
                  <option  *ngFor="let med of media"
                   [ngValue]="med.botMediaId"> {{med.title}}  
                  </option>
                </select>
              </div>
  
              <br>
            <div style="float: right;">
  <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
            </div>
  
              <br *ngIf="botResponse.resType !== 'audio'">
              <div *ngIf="botResponse.resType !== 'audio'">
                <label for="response">Caption
                  <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                    <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                  </a>
                </label> <br>
                <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
                </textarea>
              </div>
            </div>
    
  
  
    </div>

    <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
        <div *ngIf="fromParent.context==='addResponseToOption'">
        

          <div>
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
            </div>  

          <br>

          <div *ngIf="botResponse.resType==='text'">
            <label class="required" for="response">Response
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
            </textarea>
          </div>

          <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
          || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
          || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
          <div>
            <label class="col-md-6" class="required" for="fkBotMediaId">Choose media to send</label> 
            <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
            id="fkBotMediaId" name="fkBotMediaId">
              <option value="" disabled >Choose Media to send </option>
              <option  *ngFor="let med of media"
               [ngValue]="med.botMediaId"> {{med.title}}  
              </option>
            </select>
          </div>

          <br>
            <div style="float: right;">
  <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
            </div>

          <br *ngIf="botResponse.resType !== 'audio'">
          <div *ngIf="botResponse.resType !== 'audio'">
            <label for="response">Caption
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
            </textarea>
          </div>
        </div>
          <br>

          <div>
            <label class="required">User Response Type </label> <br>
            <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
              <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
        <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
        <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
              <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
              <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
              <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
              <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
              <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
              <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
            </mat-radio-group>
          </div>

          <br>

          <div class="form-group row">
            <div class="col-md-6">
              <label class="required">Do you want to save customer's answer for this question/response ? </label> <br>
              <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
                <mat-radio-button name="saveVar" id="2" [value]=true>Yes</mat-radio-button>
                <mat-radio-button name="saveVar" id="1" [value]=false>No</mat-radio-button>
              </mat-radio-group>
            </div>  
  
            <div *ngIf="botResponse.saveVar===true" class="col-md-6">
            <label class="required" for="varV">Variable Name</label>
            <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
            name="varV" id="varV" [(ngModel)]="botResponse.var">
            <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
            </div>    
          </div>

          <div *ngIf="botResponse.userResType===1" class="form-group row">
            <div class="col-md-6">
              <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
              <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
              required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
                <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
                <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
              </mat-radio-group>
            </div>  
  
          
  
            <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
            <label class="required" for="varV">Validation Type</label> <br>
            <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
              required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
                <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
                <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
              </mat-radio-group>
            </div>    
          </div>

      </div>

       <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
       <div *ngIf="fromParent.context==='add_callback_response_to_option'">
        <div>
          <label for="treeDefId">Choose Flow To Initiate Callback Notifications for Agents</label> 
          <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.treeDefId" 
          id="treeDefId" name="treeDefId">
            <option value="" disabled >Choose Flow </option>
            <option  *ngFor="let med of treeDefs"
             [ngValue]="med.botTreeDefinitionId"> {{med.definition}}  
            </option>
          </select>
        </div>
        <br>
        <div>
          <label class="required" for="filterVar"> Agent Filter Variable</label>
          <input maxlength="15" required class="form-control" type="text" 
           name="filterVar" id="filterVar" [(ngModel)]="botResponse.filterVar">
        </div>
      </div>


      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='deactivate_option_callback_response'">
        <div>
          <label>Attached Callback Flow : </label>  <br>
          <label>{{botResOption.fkCallbackTreeDefDefinition}}</label>
           
          <br>
          <br>

          <label>Agent Filter Variable : </label>  <br>
          <label *ngIf="botResOption.callbackNotifFunctionAgentFilter===null">N/A</label>
          <label *ngIf="botResOption.callbackNotifFunctionAgentFilter!==null">{{botResOption.callbackNotifFunctionAgentFilter}}</label>
        </div>
      </div>

            <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='setup_option_callback_function'">

        <mat-form-field [style.width.%]=100 appearance="fill">
          <mat-label> Choose Function</mat-label>
          <mat-select [(ngModel)]="botResOption.callbackNotificationFunction">
            <mat-option value=null>None</mat-option>
            <mat-option value="takeUpCallbackReq">Take Up Callback Request</mat-option>
            <mat-option value="rejectCallbackReq">Reject Callback Request</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

                 <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
                 <div *ngIf="fromParent.context==='setup_response_callback_function'">

                  <mat-form-field [style.width.%]=100 appearance="fill">
                    <mat-label> Choose Function</mat-label>
                    <mat-select [(ngModel)]="botResponse.callbackNotifResFunction">
                      <mat-option value=null>None</mat-option>
                      <mat-option value="save remarks and complete request">Save remarks and complete request</mat-option>
                      <mat-option value="save remarks and initiate request again">Save remarks and initiate request again</mat-option>
                    </mat-select>
                  </mat-form-field>
          
                </div>

             <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
             <div *ngIf="fromParent.context==='linkConvoFlowToUserRegResOption'">
              <div>
                <label class="required" for="treeDefId">Choose Flow</label> 
                <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.treeDefId" 
                id="treeDefId" name="treeDefId">
                  <option value="" disabled >Choose Flow </option>
                  <option  *ngFor="let med of treeDefs"
                   [ngValue]="med.botTreeDefinitionId"> {{med.definition}}  
                  </option>
                </select>
              </div>
            </div>

                         <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
                         <div *ngIf="fromParent.context==='linkBotUserGroupToUserRegResOption'">
                          <div>
                            <label class="required" for="botUserGrpId">Choose Group</label> 
                            <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.botUserGrpId" 
                            id="botUserGrpId" name="botUserGrpId">
                              <option value="" disabled >Choose Group </option>
                              <option  *ngFor="let med of botUserGroup.list"
                               [ngValue]="med.botUserGrpId"> {{med.botUserGrpName}}  
                              </option>
                            </select>
                          </div>
                        </div>

            
      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
        <div *ngIf="fromParent.context==='addResponseToResponse'">

          <div>
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
            </div>  

          <br>

          <div *ngIf="botResponse.resType==='text'">
            <label class="required" for="response">Response
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
            </textarea>
          </div>

          <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
          || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
          || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">

          <div>
            <label class="required" for="fkBotMediaId">Choose media to send</label> 
            <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
            id="fkBotMediaId" name="fkBotMediaId">
              <option value="" disabled >Choose Media to send </option>
              <option  *ngFor="let med of media"
               [ngValue]="med.botMediaId"> {{med.title}}  
              </option>
            </select>
          </div>
          <br>
          <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
          </div>

          
          <br *ngIf="botResponse.resType !== 'audio'">
          <div *ngIf="botResponse.resType !== 'audio'">
            <label for="response">Caption
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
            </textarea>
          </div>
        </div>
          <br>

            <div>
              <label class="required">User Response Type </label> <br>
              <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
                <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
                <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
                <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
                <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
                <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
                <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
                <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
                <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
                <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
              </mat-radio-group>
            </div>

            <br>

        <div class="form-group row">



          <div class="col-md-6">
            <label class="required">Do you want to save customer's answer for this question ? </label> <br>
            <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
              <mat-radio-button name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
              <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
            </mat-radio-group>
          </div>  


          <div *ngIf="botResponse.saveVar===true" class="col-md-6">
          <label class="required" for="varV">Variable Name</label>
          <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
          name="varV" id="varV" [(ngModel)]="botResponse.var">
          <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
          </div>    
        </div>

          <div *ngIf="botResponse.userResType===1" class="form-group row">
            <div class="col-md-6">
              <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
              <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
              required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
                <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
                <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
              </mat-radio-group>
            </div>  
  
          
  
            <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
            <label class="required" for="varV">Validation Type</label> <br>
            <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
              required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
                <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
                <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
              </mat-radio-group>
            </div>    
          </div>

        </div>

              <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
              <div *ngIf="fromParent.context==='createAdditionalSubResponse'">

                <div>
                  <label class="required">Response Format </label> <br>
                  <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
                    <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
                    <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
                    <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
                    <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
                    <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
                  </mat-radio-group>
                  </div>  
      
                <br>
      
                <div *ngIf="botResponse.resType==='text'">
                  <label class="required" for="response">Response
                    <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                      <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                    </a>
                  </label> <br>
                  <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
                  </textarea>
                </div>
      
                <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
                || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
                || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
      
                <div>
                  <label class="required" for="fkBotMediaId">Choose media to send</label> 
                  <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
                  id="fkBotMediaId" name="fkBotMediaId">
                    <option value="" disabled >Choose Media to send </option>
                    <option  *ngFor="let med of media"
                     [ngValue]="med.botMediaId"> {{med.title}}  
                    </option>
                  </select>
                </div>
                <br>
                <div style="float: right;">
      <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
                </div>
      
                
                <br *ngIf="botResponse.resType !== 'audio'">
                <div *ngIf="botResponse.resType !== 'audio'">
                  <label for="response">Caption
                    <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                      <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                    </a>
                  </label> <br>
                  <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
                  </textarea>
                </div>
              </div>
                <br>
      
                  <!-- <div>
                    <label class="required">User Response Type </label> <br>
                    <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
                      <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
                      <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
                      <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
                      <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
                      <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
                      <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
                      <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
                      <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
                      <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
                    </mat-radio-group>
                  </div> -->
      
                  <!-- <br> -->
      
              <!-- <div class="form-group row">
      
      
      
                <div class="col-md-6">
                  <label class="required">Do you want to save customer's answer for this question ? </label> <br>
                  <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
                    <mat-radio-button name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
                    <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
                  </mat-radio-group>
                </div>  
      
      
                <div *ngIf="botResponse.saveVar===true" class="col-md-6">
                <label class="required" for="varV">Variable Name</label>
                <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
                name="varV" id="varV" [(ngModel)]="botResponse.var">
                <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
                </div>    
              </div> -->
      
                <!-- <div *ngIf="botResponse.userResType===1" class="form-group row">
                  <div class="col-md-6">
                    <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
                    <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
                    required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
                      <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
                      <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
                    </mat-radio-group>
                  </div>  
        
                
        
                  <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
                  <label class="required" for="varV">Validation Type</label> <br>
                  <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
                    required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
                      <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
                      <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
                    </mat-radio-group>
                  </div>    
                </div> -->
      
              </div>

        <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
        <div *ngIf="fromParent.context==='createOrderPlacedResponse'">

          <!-- <div>
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
            </div>  

          <br> -->

          <div *ngIf="botResponse.resType==='text'">
            <label class="required" for="response">Response
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
            </textarea>
          </div>

          <!-- <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
          || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
          || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">

          <div>
            <label class="required" for="fkBotMediaId">Choose media to send</label> 
            <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
            id="fkBotMediaId" name="fkBotMediaId">
              <option value="" disabled >Choose Media to send </option>
              <option  *ngFor="let med of media"
               [ngValue]="med.botMediaId"> {{med.title}}  
              </option>
            </select>
          </div>
          <br>
          <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
          </div>

          
          <br *ngIf="botResponse.resType !== 'audio'">
          <div *ngIf="botResponse.resType !== 'audio'">
            <label for="response">Caption
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
            </textarea>
          </div>
        </div> -->
          <br>

            <div>
              <label class="required">User Response Type </label> <br>
              <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
                <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
                <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
                <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
                <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
                <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
                <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
                <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
                <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
                <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
              </mat-radio-group>
            </div>

            <br>

        <!-- <div class="form-group row">



          <div class="col-md-6">
            <label class="required">Do you want to save customer's answer for this question ? </label> <br>
            <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
              <mat-radio-button name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
              <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
            </mat-radio-group>
          </div>  


          <div *ngIf="botResponse.saveVar===true" class="col-md-6">
          <label class="required" for="varV">Variable Name</label>
          <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
          name="varV" id="varV" [(ngModel)]="botResponse.var">
          <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
          </div>    
        </div>

          <div *ngIf="botResponse.userResType===1" class="form-group row">
            <div class="col-md-6">
              <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
              <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
              required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
                <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
                <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
              </mat-radio-group>
            </div>  
  
          
  
            <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
            <label class="required" for="varV">Validation Type</label> <br>
            <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
              required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
                <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
                <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
              </mat-radio-group>
            </div>    
          </div> -->

        </div>



         <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
         <div *ngIf="fromParent.context==='createFlowInitiationResponse'">

          <div>
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
            </div>  

          <br>

          <div *ngIf="botResponse.resType==='text'">
            <label class="required" for="response">Response
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
            </textarea>
          </div>

          <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
          || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
          || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">

          <div>
            <label class="required" for="fkBotMediaId">Choose media to send</label> 
            <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
            id="fkBotMediaId" name="fkBotMediaId">
              <option value="" disabled >Choose Media to send </option>
              <option  *ngFor="let med of media"
               [ngValue]="med.botMediaId"> {{med.title}}  
              </option>
            </select>
          </div>
          <br>
          <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
          </div>

          
          <br *ngIf="botResponse.resType !== 'audio'">
          <div *ngIf="botResponse.resType !== 'audio'">
            <label for="response">Caption
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
            </textarea>
          </div>
        </div>
          <br>

            <div>
              <label class="required">User Response Type </label> <br>
              <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
                <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
                <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
                <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
                <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
                <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
                <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
                <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
                <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
                <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
              </mat-radio-group>
            </div>

            <br>

        <div class="form-group row">



          <div class="col-md-6">
            <label class="required">Do you want to save customer's answer for this question ? </label> <br>
            <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
              <mat-radio-button name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
              <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
            </mat-radio-group>
          </div>  


          <div *ngIf="botResponse.saveVar===true" class="col-md-6">
          <label class="required" for="varV">Variable Name</label>
          <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
          name="varV" id="varV" [(ngModel)]="botResponse.var">
          <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
          </div>    
        </div>

          <div *ngIf="botResponse.userResType===1" class="form-group row">
            <div class="col-md-6">
              <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
              <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
              required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
                <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
                <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
              </mat-radio-group>
            </div>  
  
          
  
            <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
            <label class="required" for="varV">Validation Type</label> <br>
            <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
              required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
                <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
                <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
              </mat-radio-group>
            </div>    
          </div>

        </div>

        <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
        <div *ngIf="fromParent.context==='addConditionalResponseToResponse'">

          <div>
            <label class="required">Response Format </label> <br>
            <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
              <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
              <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
              <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button>
              <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
            </mat-radio-group>
            </div>  

          <br>

          <div>
            <label class="required" for="conditionValue">Condition Value</label>
            <input  maxlength="250" #conditionValue="ngModel" required class="form-control" type="text" 
            placeholder="Enter condition value" name="conditionValue" id="conditionValue" [(ngModel)]="botResponse.conditionValue">
            <div class="alert alert-danger" *ngIf="conditionValue.touched && !conditionValue.valid"> Option required</div>
            </div>

            <br>

            <div *ngIf="botResponse.resType==='text'">
              <label class="required" for="response">Response
                <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                  <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                </a>
              </label> <br>
              <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
              </textarea>
            </div>
  
            <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
            || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
            || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
  
            <div>
              <label class="required" for="fkBotMediaId">Choose media to send</label> 
              <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
              id="fkBotMediaId" name="fkBotMediaId">
                <option value="" disabled >Choose Media to send </option>
                <option  *ngFor="let med of media"
                 [ngValue]="med.botMediaId"> {{med.title}}  
                </option>
              </select>
            </div>

            <br>
            <div style="float: right;">
  <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
            </div>
  
            
            <br *ngIf="botResponse.resType !== 'audio'">
            <div *ngIf="botResponse.resType !== 'audio'">
              <label for="response">Caption
                <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                  <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                </a>
              </label> <br>
              <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
              </textarea>
            </div>
          </div>
            <br>

          <br>

          <div>
            <label class="required">User Response Type </label> <br>
            <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
              <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
        <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
        <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
              <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
              <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
              <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
              <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
              <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
              <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
            </mat-radio-group>
          </div>

          <br>

      <div class="form-group row">



        <div class="col-md-6">
          <label class="required">Do you want to save customer's answer for this question ? </label> <br>
          <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
            <mat-radio-button name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
            <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
          </mat-radio-group>
        </div>  


        <div *ngIf="botResponse.saveVar===true" class="col-md-6">
        <label class="required" for="varV">Variable Name</label>
        <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
        name="varV" id="varV" [(ngModel)]="botResponse.var">
        <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
        </div>    
      </div>

      <div *ngIf="botResponse.userResType===1" class="form-group row">
        <div class="col-md-6">
          <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
          <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
          required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
            <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
            <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
          </mat-radio-group>
        </div>  

      

        <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
        <label class="required" for="varV">Validation Type</label> <br>
        <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
          required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
            <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
            <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
          </mat-radio-group>
        </div>    
      </div>

      </div>
  
      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='addConditionalResponseToOption'">

        <div>
          <label class="required">Response Format </label> <br>
          <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
            <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
            <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
            <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
            <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
          </mat-radio-group>
        </div>  

        <br>

        <div>
          <label class="required" for="conditionValue">Condition Value</label>
          <input  maxlength="250" #conditionValue="ngModel" required class="form-control" type="text" 
          placeholder="Enter condition value" name="conditionValue" id="conditionValue" [(ngModel)]="botResponse.conditionValue">
          <div class="alert alert-danger" *ngIf="conditionValue.touched && !conditionValue.valid"> Option required</div>
          </div>

          <br>

          <div *ngIf="botResponse.resType==='text'">
            <label class="required" for="response">Response
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
            </textarea>
          </div>

          <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
          || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
          || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">

          <div>
            <label class="required" for="fkBotMediaId">Choose media to send</label> 
            <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
            id="fkBotMediaId" name="fkBotMediaId">
              <option value="" disabled >Choose Media to send </option>
              <option  *ngFor="let med of media"
               [ngValue]="med.botMediaId"> {{med.title}}  
              </option>
            </select>
          </div>

          <br>
          <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
          </div>

          
          <br *ngIf="botResponse.resType !== 'audio'">
          <div *ngIf="botResponse.resType !== 'audio'">
            <label for="response">Caption
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label> <br>
            <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
            </textarea>
          </div>
        </div>
          <br>

        <br>

        <div>
          <label class="required">User Response Type </label> <br>
          <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
            <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
        <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
        <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
            <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
            <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
            <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
            <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
            <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
            <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
          </mat-radio-group>
        </div>

        <br>

    <div class="form-group row">



      <div class="col-md-6">
        <label class="required">Do you want to save customer's answer for this question ? </label> <br>
        <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
          <mat-radio-button name="saveVar" id="1" [value]=true>Yes</mat-radio-button>
          <mat-radio-button name="saveVar" id="2" [value]=false>No</mat-radio-button>
        </mat-radio-group>
      </div>  


      <div *ngIf="botResponse.saveVar===true" class="col-md-6">
      <label class="required" for="varV">Variable Name</label>
      <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
      name="varV" id="varV" [(ngModel)]="botResponse.var">
      <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
      </div>    
    </div>

    <div *ngIf="botResponse.userResType===1" class="form-group row">
      <div class="col-md-6">
        <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
        <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
        required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
          <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
          <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
        </mat-radio-group>
      </div>  

    

      <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
      <label class="required" for="varV">Validation Type</label> <br>
      <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
        required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
          <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
          <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
        </mat-radio-group>
      </div>    
    </div>

    </div>

<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='addConvoInitiationResponse'">

        

            <div>
              <label class="required" for="response">Response
                <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                  <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                </a>
              </label> <br>
              <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
              </textarea>
            </div>
            
<br>

<div>
  <label class="required">User Response Type </label> <br>
  <mat-radio-group name="botResponse.userResType" id="botResponse.userResType" required aria-label="userResType" [(ngModel)]="botResponse.userResType">
    <!-- <mat-radio-button name="userResType" id="3" [value]=1 >Text (Free text / Buttons)</mat-radio-button> -->
    <mat-radio-button name="userResType" id="10" [value]=8 >Text Answer</mat-radio-button>
        <mat-radio-button name="userResType" id="3" [value]=1 >Buttons</mat-radio-button>
        <mat-radio-button name="userResType" id="11" [value]=9 >Text Answer / Buttons</mat-radio-button>
    <mat-radio-button name="userResType" id="4" [value]=2 >Location</mat-radio-button>
    <mat-radio-button name="userResType" id="5" [value]=3 >Image</mat-radio-button>
    <mat-radio-button name="userResType" id="8" [value]=6 >Audio</mat-radio-button>
    <mat-radio-button name="userResType" id="6" [value]=4 >Video</mat-radio-button>
    <mat-radio-button name="userResType" id="7" [value]=5 >Document</mat-radio-button>
    <mat-radio-button name="userResType" id="9" [value]=7 >Any</mat-radio-button>
  </mat-radio-group>
</div>

<br>

<div class="form-group row">
  <div class="col-md-6">
    <label class="required">Do you want to save customer's answer for this question ? </label> <br>
    <mat-radio-group name="botResponse.saveVar" id="saveVar" required aria-label="saveVar" [(ngModel)]="botResponse.saveVar">
      <mat-radio-button name="saveVar" id="2" [value]=true>Yes</mat-radio-button>
      <mat-radio-button name="saveVar" id="1" [value]=false>No</mat-radio-button>
    </mat-radio-group>
  </div>  



  <div *ngIf="botResponse.saveVar===true" class="col-md-6">
  <label class="required" for="varV">Variable Name</label>
  <input #varV="ngModel" required class="form-control" type="text" placeholder="Enter Variable Name" 
  name="varV" id="varV" [(ngModel)]="botResponse.var">
  <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Variable Name required</div>
  </div>    
</div>

<div *ngIf="botResponse.userResType===1" class="form-group row">
  <div class="col-md-6">
    <label class="required">Do you want to validate customer's answer for this question/response ? </label> <br>
    <mat-radio-group name="botResponse.userResValidation" id="userResValidation" 
    required aria-label="userResValidation" [(ngModel)]="botResponse.userResValidation">
      <mat-radio-button name="userResValidation" id="Yes Validate" value="Yes Validate">Yes</mat-radio-button>
      <mat-radio-button name="userResValidation" id="No Dont Validate" value="No Dont Validate">No</mat-radio-button>
    </mat-radio-group>
  </div>  



  <div *ngIf="botResponse.userResValidation==='Yes Validate'" class="col-md-6">
  <label class="required" for="varV">Validation Type</label> <br>
  <mat-radio-group name="botResponse.userResValidationType" id="userResValidationType" 
    required aria-label="userResValidationType" [(ngModel)]="botResponse.userResValidationType">
      <mat-radio-button name="userResValidationType" id="email" value="email">Email Validation</mat-radio-button>
      <mat-radio-button name="userResValidationType" id="phone" value="phone">Phone Number Validation</mat-radio-button>
    </mat-radio-group>
  </div>    
</div>

    </div>

    <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
    <div *ngIf="fromParent.context==='linkNextResponseToOption'" style="margin-bottom: 2%;">
     
      <mat-spinner style="margin:0 auto;" *ngIf="dataLoaded===false"></mat-spinner>
     
      <div *ngIf="dataLoaded===true">
        <label class="required" for="nextBotResponseId">Response</label> 
        <select #nextBotResponseId="ngModel" required  class="form-control" [(ngModel)]="botResponse.nextBotResponseId" id="nextBotResponseId" name="nextBotResponseId">
          <option value="" disabled >Choose Response</option>
          <option  *ngFor="let res of botResponses" 
           [ngValue]="res.botResponseId">Response {{res.slNo}} : {{res.response}}
          </option>
        </select>
      </div>
    </div>

    <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
    <div *ngIf="fromParent.context==='linkNextResponseToResponse'" style="margin-bottom: 2%;">
     
      <mat-spinner style="margin:0 auto;" *ngIf="dataLoaded===false"></mat-spinner>


     <div *ngIf="dataLoaded===true">
      <label class="required" for="nextBotResponseId">Response</label> 
      <select #nextBotResponseId="ngModel" required  class="form-control" [(ngModel)]="botResponse.nextBotResponseId" id="nextBotResponseId" name="nextBotResponseId">
        <option value="" disabled >Choose Response</option>
        <option  *ngFor="let res of botResponses" 
         [ngValue]="res.botResponseId">Response {{res.slNo}} : {{res.response}}
        </option>
      </select>
    </div>


    </div> 


<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
<div *ngIf="fromParent.context==='setBackResponse'" style="margin-bottom: 2%;">
  <label class="required" for="backResponseId">Choose Back Response</label> 
  <select #backResponseId="ngModel" required  class="form-control" [(ngModel)]="botResponse.backResponseId" id="backResponseId" name="backResponseId">
    <option value=null disabled >Choose Back Response</option>
    <option  *ngFor="let res of botResponses" 
     [ngValue]="res.botResponseId">Response {{res.slNo}} : {{res.response}}
    </option>
  </select>
</div>
 
<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
<div *ngIf="fromParent.context==='addFirstUserRegistrationResponse'">

  <div style="margin-bottom: 2%;">
    <label class="required" for="var"> Variable</label> <br> 
       <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botResponse.var" id="var" name="var">
         <option value="" disabled >Choose variable</option>
         <option name="varV" id1="name" value="name" >Name</option>
         <option name="varV" id1="gender" value="gender" >Gender</option>
         <option name="varV" id1="alternatePhone" value="alternatePhone" >Alternate Phone</option>
         <option name="varV" id1="language" value="language" >Language</option>
         <option name="varV" id1="customVar1" value="customVar1" >Custom Variable 1</option>
         <option name="varV" id1="customVar2" value="customVar2" >Custom Variable 2</option>
         <option name="varV" id1="opt-in" value="opt-in" >Opt-In Variable/Status</option>
        </select>
       <div class="alert alert-danger" *ngIf="invoice.touched && !invoice.valid">Select Variable</div>
   </div> 
    
    <div>
      <label class="required" for="response">Question</label> <br>
      <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
      </textarea>
    </div>

</div>

<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
<div *ngIf="fromParent.context==='addNextUserRegistrationResponse'">

  <div style="margin-bottom: 2%;">
    <label class="required" for="var"> Variable</label> <br> 
       <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botResponse.var" id="var" name="var">
         <option value="" disabled >Choose variable</option>
         <option *ngIf="nameQExists===false" name="varV" id1="name" value="name" >Name</option>
         <option *ngIf="genderQExists===false" name="varV" id1="gender" value="gender" >Gender</option>
         <option *ngIf="altPhoneQExists===false" name="varV" id1="alternatePhone" value="alternatePhone" >Alternate Phone</option>
         <option *ngIf="langQExists===false" name="varV" id1="language" value="language" >Language</option>
         <option *ngIf="customVar1Exists===false" name="varV" id1="customVar1" value="customVar1" >Custom Variable 1</option>
         <option *ngIf="customVar2Exists===false" name="varV" id1="customVar2" value="customVar2" >Custom Variable 2</option>
         <option name="varV" id1="opt-in" value="opt-in" >Opt-In Variable/Status</option>
        </select>
       <div class="alert alert-danger" *ngIf="invoice.touched && !invoice.valid">Select variable</div>
   </div> 


    <div>
      <label class="required" for="response">Question</label> <br>
      <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
      </textarea>
    </div>

</div>

<!-- DUMMY -->
<div *ngIf="fromParent.context==='setExtApi'">

  <div>
    <label class="required" for="response">Callback Url</label>
    <input #response="ngModel" required class="form-control" type="text" placeholder="Enter Callback Url" name="response" id="response" [(ngModel)]="botResponse.response">
    <div class="alert alert-danger" *ngIf="response.touched && !response.valid"> Response required</div>
    </div>        
<br>

<div>
  <label class="required" for="parentResponseId">Access Token</label>
  <input #parentResponseId="ngModel" required class="form-control" type="text" placeholder="Enter access token" name="parentResponseId" id="parentResponseId" [(ngModel)]="botResponse.parentResponseId">
  <div class="alert alert-danger" *ngIf="parentResponseId.touched && !parentResponseId.valid"> Acess token required</div>
  </div>
</div>

      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='createErrorResponse'">

        <div>
          <label class="required">Response Format </label> <br>
          <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
            <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
            <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
            <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button> 
            <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
          </mat-radio-group>
          </div>  

        <br>

        <div *ngIf="botResponse.resType==='text'">
          <label class="required" for="response">Response
            <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
          </label> <br>
          <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
          </textarea>
        </div>

        <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
        || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
        || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">

        <div>
          <label class="required" for="fkBotMediaId">Choose media to send</label> 
          <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
          id="fkBotMediaId" name="fkBotMediaId">
            <option value="" disabled >Choose Media to send </option>
            <option  *ngFor="let med of media"
             [ngValue]="med.botMediaId"> {{med.title}}  
            </option>
          </select>
        </div>
        <br>
        <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
        </div>

        
        <br *ngIf="botResponse.resType !== 'audio'">
        <div *ngIf="botResponse.resType !== 'audio'">
          <label for="response">Caption
            <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
          </label> <br>
          <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
          </textarea>
        </div>
      </div>

        

      </div>


            <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
            <div *ngIf="fromParent.context==='createTemplateResponse'">

              <div>
                <label class="required">Response Format </label> <br>
                <mat-radio-group name="botResponse.resType" id="resType" required aria-label="resType" [(ngModel)]="botResponse.resType">
                  <mat-radio-button name="resType" id="text" value="text">Text Response</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('image');" name="resType" id="image" value="image">Image</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('audio');" name="resType" id="audio" value="audio">Audio</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('video');" name="resType" id="video" value="video">Video</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('document');" name="resType" id="document" value="document">Document</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('image');" name="resType" id="image with buttons" value="image with buttons">Image With Buttons</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('video');" name="resType" id="video with buttons" value="video with buttons">Video With Buttons</mat-radio-button>
                  <mat-radio-button (change)="filterMedia('document');" name="resType" id="document with buttons" value="document with buttons">Document With Buttons</mat-radio-button>
                </mat-radio-group>
                </div>  
      
              <br>
      
              <div *ngIf="botResponse.resType==='text'">
                <label class="required" for="response">Response
                  <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                    <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                  </a>
                </label> <br>
                <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" required name="response" id="response">
                </textarea>
              </div>
      
              <div *ngIf="botResponse.resType==='image' || botResponse.resType==='video' || botResponse.resType==='document' 
              || botResponse.resType==='image with buttons' ||  botResponse.resType === 'audio' 
              || botResponse.resType==='video with buttons' || botResponse.resType==='document with buttons'">
      
              <div>
                <label class="required" for="fkBotMediaId">Choose media to send</label> 
                <select #fkBotMediaId="ngModel" required class="form-control" [(ngModel)]="botResponse.fkBotMediaId" 
                id="fkBotMediaId" name="fkBotMediaId">
                  <option value="" disabled >Choose Media to send </option>
                  <option  *ngFor="let med of media"
                   [ngValue]="med.botMediaId"> {{med.title}}  
                  </option>
                </select>
              </div>
              <br>
              <div style="float: right;">
      <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
              </div>
      
              
              <br *ngIf="botResponse.resType !== 'audio'">
              <div *ngIf="botResponse.resType !== 'audio'">
                <label for="response">Caption
                  <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                    <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
                  </a>
                </label> <br>
                <textarea minlength="2" maxlength="1024" [(ngModel)]="botResponse.response" class="form-control" rows="10" name="response" id="response">
                </textarea>
              </div>
            </div>
      
              
      
            </div>

      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='setOfficeDefaultErrorResponse'">
     
        <div *ngIf="botResponse !== null">
          <h3 style="font-weight: bold;">Current Default Error Response :</h3>
          <a style="float: left;font-weight: bold;color: black;">Response {{botResponse.slNo}}</a> <br>
          <a style="float: left;color: black;">{{botResponse.response}}</a>
      
          <br>
          <br>
          <h4 style="margin: 0%;" *ngIf="botResponse.options !== null && botResponse.options.length > 0">Options :</h4>
          <div *ngFor="let opt of botResponse.options">
              <a style="color:black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> <br>
          </div>

          <br>
        <button (click)="removeOfficeDefaultErrorResponse();"
         class="btn btn-danger" style="width: auto;float: right;">Remove Default Error Response</button>
         
          <br><br>

        </div>
     
     
        <div>
          <label for="defOfficeErrorResId">Choose a different response for Default Error Response</label>
          <select required class="form-control" [(ngModel)]="defOfficeErrorResId">
            <option value="chooseErrorRes" disabled>Choose Response</option>
            <option *ngFor="let errorRes of botResponses" [ngValue]="errorRes.botResponseId">Response {{errorRes.slNo}} : {{errorRes.response}}</option>
          </select>
        </div>

        
      </div>

      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
<div *ngIf="fromParent.context==='setErrorResponse'" style="margin-bottom: 2%;">
  <label class="required" for="errorResponseId">Choose Error Response</label> 
  <select #errorResponseId="ngModel" required  class="form-control" [(ngModel)]="botResponse.errorResponseId" 
  id="errorResponseId" name="errorResponseId">
    <option value=null disabled >Choose Error Response</option>
    <option  *ngFor="let res of botResponses" 
     [ngValue]="res.botResponseId">Response {{res.slNo}} : {{res.response}}
    </option>
  </select>
</div>




      <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
      <div *ngIf="fromParent.context==='linkFkBotTemplateResToBotTemplate'" style="margin-bottom: 2%;">
        <label class="required" for="botTLResId">Choose Template Response</label> 
        <select required class="form-control" [(ngModel)]="botTLResId" 
        id="botTLResId" name="botTLResId">
          <option value=null disabled >Choose Template Response</option>
          <option  *ngFor="let res of botResponses" 
           [ngValue]="res.botResponseId">Response {{res.slNo}} : {{res.response}}
          </option>
        </select>
      </div>
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='Warning Component'" (click)="varContinue('y');">Continue</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-danger float-right" 
*ngIf="fromParent.context==='Warning Component'" (click)="varContinue('n');">Cancel</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addResponseToResponse'" (click)="addResponseToResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='createAdditionalSubResponse'" (click)="createAdditionalSubResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='createOrderPlacedResponse'" (click)="createOrderPlacedResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='createFlowInitiationResponse'" (click)="createFlowInitiationResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addConditionalResponseToResponse'" (click)="addConditionalResponseToResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addConditionalResponseToOption'" (click)="addConditionalResponseToOption();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addResponseToOption'" (click)="addResponseToOption(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='add_callback_response_to_option'" (click)="add_callback_response_to_option(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-danger float-right" 
*ngIf="fromParent.context==='deactivate_option_callback_response'" (click)="deactivate_option_callback_response(); clicked=true">Deactivate</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='setup_option_callback_function'" (click)="setup_option_callback_function(); clicked=true">Update</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='setup_response_callback_function'" (click)="setup_response_callback_function(); clicked=true">Update</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='linkConvoFlowToUserRegResOption'" (click)="linkConvoFlowToUserRegResOption(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='linkBotUserGroupToUserRegResOption'" (click)="linkBotUserGroupToUserRegResOption(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addConvoInitiationResponse'" (click)="addConvoInitiationResponse(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='linkNextResponseToOption' && dataLoaded===true" (click)="linkNextResponseToOption(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='linkNextResponseToResponse' && dataLoaded===true" (click)="linkNextResponseToResponse(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='editBotResponse'" (click)="editBotResponse(); clicked=true">Update</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='setBackResponse'" (click)="setBackResponse();">Save</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addFirstUserRegistrationResponse'" (click)="addFirstUserRegistrationResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addNextUserRegistrationResponse'" (click)="addNextUserRegistrationResponse();">Save</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='createErrorResponse'" (click)="createErrorResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='createTemplateResponse'" (click)="createTemplateResponse();">Save</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='setExtApi'" (click)="setExtApi();">Verify Url</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='editErrorResponse'" (click)="editErrorResponse(); clicked=true">Update</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='editTemplateResponse'" (click)="editTemplateResponse(); clicked=true">Update</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='setOfficeDefaultErrorResponse'" (click)="setOfficeDefaultErrorResponse(); clicked=true">Save</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='setErrorResponse'" (click)="setErrorResponse();">Save</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='linkFkBotTemplateResToBotTemplate'" (click)="linkFkBotTemplateResToBotTemplate();">Save</button>
</div>


<ng-template #variableDetails let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Variables</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <table
    class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
    <thead class="tableheader" >
      <tr>
        <th>User Variables</th>
        <th>Detail that replaces variable</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>$!#userName$!# 
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userName$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User name</td>
      </tr>
      <tr>
        <td>$!#userPhone$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userPhone$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User phone number</td>
      </tr>
      <tr>
        <td>$!#userAlternatePhone$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userAlternatePhone$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User alternate phone number</td>
      </tr>
      <tr>
        <td>$!#userGender$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userGender$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User gender</td>
      </tr>
      <tr>
        <td>$!#customVar1$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#customVar1$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>Custom Variable 1</td>
      </tr>
      <tr>
        <td>$!#customVar2$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#customVar2$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>Custom Variable 2</td>
      </tr>
      <tr>
        <td>###zotishop###
          <a style="cursor: pointer;" cdkCopyToClipboard="###zotishop###" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>A link to shopping page</td>
      </tr>
      <tr>
        <td>###zotiquote###
          <a style="cursor: pointer;" cdkCopyToClipboard="###zotiquote###" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>A link to quotaion page</td>
      </tr>
      <tr>
        <td>###cus_address_list###
          <a style="cursor: pointer;" cdkCopyToClipboard="###cus_address_list###" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>A List of user's addresses</td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="eligibleVariableList!==null && eligibleVariableList!==undefined && eligibleVariableList.length>0"
  class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
  <thead class="tableheader" >
    <tr>
      <th> Response Variables</th>
      <th>Response No</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let elgVarRes of eligibleVariableList">
      <td>{{elgVarRes.var}}
        <a style="cursor: pointer;" [cdkCopyToClipboard]="elgVarRes.var" (click)="toastr.success('var copied !');"> 
          <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
        </a>
      </td>
      <td>{{elgVarRes.slNo}}</td>
    </tr>
  </tbody>
</table>
    
  </div> 
</ng-template>