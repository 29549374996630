 <div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;"  class="modal-title pull-left">Search User</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card">
        <div class="card-body">


<!-- <form>
<input type="text" [(ngModel)]="value" placeholder="Enter name/email/phone" name="search">
<button (click)="searchFoUser2();" type="submit"><i class="fa fa-search"></i></button>
</form> -->

<form>

  <div class="col-md-12">
  <input class="col-md-12 form-control" type="text" [(ngModel)]="value"
   placeholder="Enter first name / last name / email / phone" name="search">
 

  <!-- <a style="cursor:pointer;margin-left: 2%;" (click)="searchFoUser2();" class="float-right">
  </a> -->
<br>
  <div style="text-align:center;">
  <button style="width: auto;color: black;" (click)="searchFoUser2();" class="btn btn-info">
    Search
    <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" width="27" height="27" alt="" loading="lazy">
  
  </button>
</div>

</div>

  </form>

    
</div>
    </div>
  </div>


<!-- <div class="modal-footer"> -->
<!-- <button type="submit" class="btn btn-sm btn-primary float-right" (click)="closeUpdateModal()">Close</button> -->
<!-- </div> -->