export class BcrmData {
 
    id : any;
	activeStatus: any;
	uroId: any;
	officeId: any;
	startDate: any;
	endDate: any;
	timestamp: any;
	processStatus: any;
	salesLedger: any;
	customersLedger: any;
	fileProcessStatus : any;
	
	salesLedgerFile : any;
	customersLedgerFile : any;
}
