import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { FormConfiguation } from 'src/app/models/form-configuation';
import { UserDetails } from 'src/app/models/user-details';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { FormConfigurationService } from 'src/app/services/FormConfiguration/form-configuration.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-comp-cust-details',
  templateUrl: './update-comp-cust-details.component.html',
  styleUrls: ['./update-comp-cust-details.component.scss']
})
export class UpdateCompCustDetailsComponent implements OnInit {

  combinedPTC : CompleteCustomerDetails = new CompleteCustomerDetails();
  clicked = false;
  id!: string;
  type : any;
  loggedInUser : any;
  theme : any;

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  formConfigGroups : FormConfiguation[] =[];

  constructor(public activeModal: NgbActiveModal,
              public bsModalRef: BsModalRef,
              private formConfigService : FormConfigurationService,
              private sharedDataService : SharedDataService,
              private ccdetailsService : CompCustDetailsService,
              private datePipe : DatePipe,
              private toaster: ToastrService ) { }
  
              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
                this.countryCodes = GlobalClass.CountryCodes;

    this.type = this.fromParent.type;

    this.id = this.fromParent.id;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.ccdetailsService.getCustomerById(this.id).subscribe(data=>{
      this.combinedPTC = data;

      if(this.combinedPTC.leadCreationDate !== null && this.combinedPTC.leadCreationDate !== undefined){
      this.combinedPTC.leadCreationDate = new Date(this.combinedPTC.leadCreationDate);
      this.combinedPTC.leadCreationDate = this.datePipe.transform(this.combinedPTC.leadCreationDate, "yyyy-MM-dd");
    }

      if(this.type === 'CUSTOMER'){
      this.formConfigService.getByFormNameAndOfficeId(environment.apiUrl + "formConfig/getGroupedFormConfigByFormNameAndOfficeId/" + "Student Creation").then(
        data=>{            
          
          this.formConfigGroups = data;

          if(this.formConfigGroups.length !== 0  && this.formConfigGroups !== undefined && this.formConfigGroups !== null){
            
            this.formConfigGroups.forEach((formConfigGroup)=>{

              let uds : UserDetails[] = [];

              formConfigGroup.groupedformConfigs.forEach((formConfig)=>{

                    let ud : UserDetails = new UserDetails();
                    ud.fkFormConfigId = formConfig.formConfigId;
                    ud.mandatory = formConfig.mandatory;
                    ud.displayName = formConfig.displayName;
                    ud.columnName = formConfig.columnName;
                    ud.key = formConfig.columnName;

                    this.combinedPTC.userDetails.forEach((userDetail)=>{

                    if(userDetail.fkFormConfigId === formConfig.formConfigId){

                    ud.value = userDetail.value;
                    ud.userDetailsId = userDetail.userDetailsId;
                    ud.fkUserId = userDetail.fkUserId;
                    
                  }
                });
 
                uds.push(ud);

              });

              formConfigGroup.userDetails = uds;              

            });
          }
        });
      }
    });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
 
  onUpdate(){

    this.formConfigGroups.forEach((fcg)=>{
      fcg.userDetails.forEach((userDetails)=>{
        this.combinedPTC.userDetails.push(userDetails);
      });
    });
    
    this.ccdetailsService.updateEmployee(environment.apiUrl + "crmCustAdd/update" , this.combinedPTC).then(
      data =>{
        this.passEntry.emit(this.combinedPTC);
        this.activeModal.close();
        if(this.type === 'CUSTOMER'){
          this.toaster.success("Customer Updated Successfully");
        }else if(this.type === 'LEAD'){
          this.toaster.success("Lead Updated Successfully");
        }
      });
  }

   
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }  
  
  closeUpdateModal(){
    this.activeModal.close();
  }


 
}
