import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { GetCombinedPTCDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-combined-ptc-details';
import { GetCPTCDetails } from 'src/app/courseManagementClasses/get-cptcdetails';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { GlobalClass } from 'src/app/global/global-class';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ExtActivityFeedbackQsnsModalComponent } from '../ext-activity-feedback-qsns-modal/ext-activity-feedback-qsns-modal.component';

@Component({
  selector: 'app-ext-activity-assessment-page',
  templateUrl: './ext-activity-assessment-page.component.html',
  styleUrls: ['./ext-activity-assessment-page.component.scss'],
  animations: [
    trigger('bodyExpansion', [
      state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed, void => collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class ExtActivityAssessmentPageComponent implements OnInit {

  currentRole = "PARENT_MENTOR";
  collapsed : boolean = false;
  showSpinner : boolean = true;
  collapsedState = false ;
  value1: any;
  gridColumns = 3;
  combinedPtcDetails: GetCombinedPTCDetails = new GetCombinedPTCDetails;
  botPMExtActLinkId!: string;

  ptcDetails : GetCPTCDetails = new GetCPTCDetails;
  actPlnrList : ActivityPlanner[] = [];
  subjectList : Subject[] = [];

  fkStudentId!: string;
  fkTeacherId!: string;
  returnString!: string;

  childName! : any;

  showActivityCard = false;

  safeChildImageUrl : any;
  unsafeChildImageUrl : any;

  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;

  activityPlannerListWithSafeUrl : any[] =[ { activityPlannerId : '', 
                                              activityPlannerName : '',
                                              activityPlannerDescription : '',
                                              activityPlannerOrder : '',
                                              activityPlannerStatus : '',
                                              fkSubjectId : '',
                                              activityPlannerImage : '',
                                              safeUrl : '',
                                              
                          activityVideoLinkList : [{
                                                      actVideoLinkId: "",
                                                      actVideoLinkName: "",
                                                      actVideoLink: "",
                                                      actVideoLinkDescription: "",
                                                      fkActivityPlannerId: "",
                                                      actVideoLinkStatus: "",
                                                      urlSafe: ""
                                                    }]
                                            } ];
  xyz: any;
  abc: any;


  constructor(private route : ActivatedRoute,
              private combinedParentTeacherService : CombinedParentTeacherService,
              private ngbModalService: NgbModal,
              private imageService : FileServiceService,
              private toastr : ToastrService,
              private sanitizer : DomSanitizer ) { }

    config = { 
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

  panelOpenState = false;
  capacity: any;
  title = 'my-app';
  state = 'collapsed';
  navbarTitle : any;

  toggle(): void {
    this.state = this.state === 'collapsed' ? 'expanded' : 'collapsed';
  }

  ngOnInit(): void {

    this.collapsed = true;
    this.navbarTitle = "TOFOO";

    this.botPMExtActLinkId = this.route.snapshot.params['botPMExtActLinkId'];

     this.value1 = 100;

    this.getCPTCDetails();

  }

  collapsePanel(state : boolean){
    if(state === true){
      this.collapsed = false;
    } else if(state === false){
      this.collapsed = true;
    }
  }

  collapseActivityCardContent(){
    this.collapsedState = !this.collapsedState;
   }

  getCPTCDetails(){

    this.showSpinner = true;
    this.collapsedState = false;

    this.combinedParentTeacherService.getChildAndActivitiesForParentMentorForExtPage(this.botPMExtActLinkId).subscribe(
      data=>{

        this.showActivityCard = false;
        this.ptcDetails = data;
        

        if(this.ptcDetails.linkStatus === "active"){
        this.childName = this.ptcDetails.childFirstName + ' ' + this.ptcDetails.childLastName;

        this.value1 = this.ptcDetails.progressValue;

        this.actPlnrList = this.ptcDetails.activityPlannerList;
        this.fkStudentId = this.ptcDetails.studentId;
        this.fkTeacherId = this.ptcDetails.teacherId;

        this.activityPlannerListWithSafeUrl.pop();

          for(var index in this.actPlnrList){

            this.activityPlannerListWithSafeUrl.push( { 'activityPlannerId' : this.actPlnrList[index].activityPlannerId,
                                                        'activityPlannerName' : this.actPlnrList[index].activityPlannerName,
                                                        'activityPlannerDescription' : this.actPlnrList[index].activityPlannerDescription,
                                                        'activityPlannerOrder' : this.actPlnrList[index].activityPlannerOrder,
                                                        'activityPlannerStatus' : this.actPlnrList[index].activityPlannerStatus,
                                                        'fkSubjectId' : this.actPlnrList[index].fkSubjectId,
                                                        'activityPlannerImage' : this.actPlnrList[index].activityPlannerImage,
                                                        'safeUrl' : this.xyz,

                                                        'activityVideoLinkList' : this.actPlnrList[index].activityVideoLinkList
                                                      } );
        }

        this.activityPlannerListWithSafeUrl.forEach( (value) => {
          
          this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              value.safeUrl = this.xyz;
            });

        }); 

        this.activityPlannerListWithSafeUrl.forEach((info)=>{

          info.activityVideoLinkList.forEach((val : any)=>{
            
          this.url =  val.actVideoLink;
          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          val.urlSafe = this.urlSafe
    
        });
        });

        if(this.activityPlannerListWithSafeUrl.length === 0){
          if(this.ptcDetails.toasterMessage === "All Activities Completed !"){
            this.toastr.success(this.ptcDetails.toasterMessage);
          }else if(this.ptcDetails.toasterMessage === "No Activities to display !"){
            this.toastr.show(this.ptcDetails.toasterMessage);
          }
        }else{
          this.showActivityCard = true;
        }

        // GETS CHILD IMAGE
        this.imageService.getUserImage(this.ptcDetails.childImage).subscribe(
          data=>{
            this.unsafeChildImageUrl = URL.createObjectURL(data);
            this.safeChildImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeChildImageUrl);
            setTimeout(()=>{                           //timer
              this.showSpinner = false;
         }, 1500);

          });
        }
        this.showActivityCard = true;
        this.showSpinner = false;
      });
  }

  // authorized
  onAssess(activityPlannerId : string){
    this.onAssess2(activityPlannerId, this.fkStudentId, this.fkTeacherId );
    }
    onAssess2(activityPlannerId : string, fkStudentId : string, fkTeacherId : string ){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ExtActivityFeedbackQsnsModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      activityPlannerId: activityPlannerId ,
      fkStudentId : fkStudentId,
      fkTeacherId : fkTeacherId,
      safeImageUrl : this.safeChildImageUrl,
      studentName : this.childName,
      botPMExtActLinkId : this.botPMExtActLinkId,
      childPetname : this.ptcDetails.childPetname
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        }); 
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : "") => {
      this.ptcDetails.linkStatus = "activity completed now";
      this.collapsedState = false;
    });
    } 
}