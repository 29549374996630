<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='addOptionToResponse'"
    style="color: white;" class="modal-title pull-left">Create Option to Response</h4>
    <h4 *ngIf="fromParent.context==='addOptionToTemplateResponse'"
    style="color: white;" class="modal-title pull-left">Add Button to template</h4>
    <h4 *ngIf="fromParent.context==='addButtonsToTemplate'"
    style="color: white;" class="modal-title pull-left">Add Button to template</h4>
    <h4 *ngIf="fromParent.context==='addOptionToOption'"
    style="color: white;" class="modal-title pull-left">Create child-option</h4>
    <h4 *ngIf="fromParent.context==='editOption'"
    style="color: white;" class="modal-title pull-left">Edit option</h4>
    <h4 *ngIf="fromParent.context==='editTemplateButton'"
    style="color: white;" class="modal-title pull-left">Edit Button</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #botResponseOptionForm="ngForm">  

        <div *ngIf="fromParent.context==='addOptionToResponse'">

          <div class="form-group row">
            <div class="col-md-4">
              <label class="required" for="responseOptionNumber">Option Number</label>
              <input  #phone="ngModel" required maxlength="250" class="form-control" type="text" placeholder="Enter Option Number" name="responseOptionNumber" id="responseOptionNumber" [(ngModel)]="botResponseOption.responseOptionNumber">
              <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid"> Number required</div>
              </div>
      
              <div class="col-md-8">
              <label class="required" for="responseOption">Option</label>
              <input maxlength="19" #responseOption="ngModel" required class="form-control" type="text" placeholder="Enter Option" name="responseOption" id="responseOption" [(ngModel)]="botResponseOption.responseOption">
              <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Option required</div>
              </div>
          </div>
            
          <div>
            <label for="responseOptionDescription">Description
              <a style="cursor: pointer;"
              (click)="open(desDetails);"> <img src="/assets/images/icons/info.png" width="21" height="21"
             alt="" loading="lazy"> </a> 
            </label>
            <input  maxlength="250" #responseOptionDescription="ngModel" class="form-control" type="text" name="responseOptionDescription" id="responseOptionDescription" [(ngModel)]="botResponseOption.responseOptionDescription">
          </div>
          
        </div>

        <div *ngIf="fromParent.context==='addOptionToTemplateResponse'">

          <div>
            <label class="required" for="optInStatusUpdateVar">Button Type</label>
            <mat-form-field [style.width.%]=100 appearance="fill">
              <mat-label> Choose Button Type</mat-label>
              <mat-select required [(ngModel)]="botResponseOption.optionType">
                <mat-option value="QUICK_REPLY">Quick Reply</mat-option>
                <mat-option value="URL">Url</mat-option>
                <!-- <mat-option value="dynamic_url_btn">Visit Website (Dynamic Url)</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>

          <br>

              <div>
              <label class="required" for="responseOption">Button Label</label>
              <input maxlength="25" #responseOption="ngModel" required class="form-control" type="text" 
              name="responseOption" id="responseOption" [(ngModel)]="botResponseOption.responseOption">
              <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Button required</div>
              </div>
              

          <div *ngIf="botResponseOption.optionType==='URL'">
                <br>
              <div>
              <label class="required" for="mainUrl"> Url</label>
              <input #mainUrl="ngModel" class="form-control" type="text" 
              name="mainUrl" id="mainUrl" [(ngModel)]="botResponseOption.mainUrl">
              <div class="alert alert-danger" *ngIf="mainUrl.touched && !mainUrl.valid"> Url required</div>
              </div>

              <br>
              <div>
              <label for="dynamicUrl">Dynamic Url / Url Suffix / Dynamic Url Variable</label>
              <input #dynamicUrl="ngModel" class="form-control" type="text" 
              name="dynamicUrl" id="dynamicUrl" [(ngModel)]="botResponseOption.dynamicUrl">
              </div>
          </div>

              <br>
        <div>
          <label for="optInStatusUpdateVar">Update Opt-In Status of Customer ?</label>
          <mat-form-field [style.width.%]=100 appearance="fill">
            <mat-label> Update Status to</mat-label>
            <mat-select [(ngModel)]="botResponseOption.optInStatusUpdateVar">
              <mat-option value=null>None</mat-option>
              <mat-option value="Opt-In">Opted In</mat-option>
              <mat-option value="Opt-Out">Opted Out</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
          
        </div>

        <div *ngIf="fromParent.context==='addButtonsToTemplate'">

          <div>
            <label class="required" for="optInStatusUpdateVar">Button Type</label>
            <mat-form-field [style.width.%]=100 appearance="fill">
              <mat-label> Choose Button Type</mat-label>
              <mat-select required [(ngModel)]="botResponseOption.optionType">
                <mat-option value="QUICK_REPLY">Quick Reply</mat-option>
                <mat-option value="URL">Url</mat-option>
                <!-- <mat-option value="dynamic_url_btn">Visit Website (Dynamic Url)</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>

          <br>

              <div>
              <label class="required" for="responseOption">Button Label</label>
              <input maxlength="25" #responseOption="ngModel" required class="form-control" type="text" 
              name="responseOption" id="responseOption" [(ngModel)]="botResponseOption.responseOption">
              <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Button required</div>
              </div>

              <div *ngIf="botResponseOption.optionType==='URL'">
                <br>
              <div>
              <label class="required" for="mainUrl"> Url</label>
              <input #mainUrl="ngModel" class="form-control" type="text" 
              name="mainUrl" id="mainUrl" [(ngModel)]="botResponseOption.mainUrl">
              <div class="alert alert-danger" *ngIf="mainUrl.touched && !mainUrl.valid"> Url required</div>
              </div>

              <br>
              <div>
              <label for="dynamicUrl">Dynamic Url / Url Suffix / Dynamic Url Variable</label>
              <input #dynamicUrl="ngModel" class="form-control" type="text" 
              name="dynamicUrl" id="dynamicUrl" [(ngModel)]="botResponseOption.dynamicUrl">
              </div>
          </div>

              <br>
        <div>
          <label for="optInStatusUpdateVar">Update Opt-In Status of Customer ?</label>
          <mat-form-field [style.width.%]=100 appearance="fill">
            <mat-label> Update Status to</mat-label>
            <mat-select [(ngModel)]="botResponseOption.optInStatusUpdateVar">
              <mat-option value=null>None</mat-option>
              <mat-option value="Opt-In">Opted In</mat-option>
              <mat-option value="Opt-Out">Opted Out</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
          
        </div>

        

        <div *ngIf="fromParent.context==='addOptionToOption'">

          <div class="form-group row">
            <div class="col-md-4">
              <label class="required" for="responseOptionNumber">Option Number</label>
              <input  #phone="ngModel" required    maxlength="250" class="form-control" type="text" placeholder="Enter Option Number" name="responseOptionNumber" id="responseOptionNumber" [(ngModel)]="botResponseOption.responseOptionNumber">
              <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid"> Number required</div>
              </div>
      
              <div class="col-md-8">
              <label class="required" for="responseOption">Option</label>
              <input  maxlength="19" #responseOption="ngModel" required class="form-control" type="text" placeholder="Enter Option" name="responseOption" id="responseOption" [(ngModel)]="botResponseOption.responseOption">
              <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Option required</div>
              </div>
          </div>
          

          <div>
            <label for="responseOptionDescription">Description
              <a style="cursor: pointer;"
              (click)="open(desDetails);"> <img src="/assets/images/icons/info.png" width="21" height="21"
             alt="" loading="lazy"> </a> 
            </label>
            <input  maxlength="250" #responseOptionDescription="ngModel" class="form-control" type="text" name="responseOptionDescription" id="responseOptionDescription" [(ngModel)]="botResponseOption.responseOptionDescription">
          </div>
        
      </div>

      <div *ngIf="fromParent.context==='editOption'">

        <div class="form-group row">
          <div class="col-md-4">
            <label class="required" for="responseOptionNumber">Option Number</label>
            <input  #phone="ngModel" required    maxlength="250" class="form-control" type="text" placeholder="Enter Option Number" name="responseOptionNumber" id="responseOptionNumber" [(ngModel)]="botResponseOption.responseOptionNumber">
            <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid"> Number required</div>
            </div>
    
            <div class="col-md-8">
            <label class="required" for="responseOption">Option</label>
            <input  maxlength="19" #responseOption="ngModel" required class="form-control" type="text" placeholder="Enter Option" name="responseOption" id="responseOption" [(ngModel)]="botResponseOption.responseOption">
            <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Option required</div>
            </div>
        </div>
       
       
        <div>
          <label for="responseOptionDescription">Description
            <a style="cursor: pointer;"
            (click)="open(desDetails);"> <img src="/assets/images/icons/info.png" width="21" height="21"
           alt="" loading="lazy"> </a> 
          </label>
          <input  maxlength="250" #responseOptionDescription="ngModel" class="form-control" type="text" 
          name="responseOptionDescription" id="responseOptionDescription" [(ngModel)]="botResponseOption.responseOptionDescription">
        </div>

        <br>
        <div>
          <label for="optInStatusUpdateVar">Update Opt-In Status of Customer ?</label>
          <mat-form-field [style.width.%]=100 appearance="fill">
            <mat-label> Update Status to</mat-label>
            <mat-select [(ngModel)]="botResponseOption.optInStatusUpdateVar">
              <mat-option value=null>None</mat-option>
              <mat-option value="Opt-In">Opted In</mat-option>
              <mat-option value="Opt-Out">Opted Out</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

    </div>

    <div *ngIf="fromParent.context==='editTemplateButton'">

      
      <div>
        <label class="required" for="responseOption">Button</label>
        <input maxlength="19" #responseOption="ngModel" required class="form-control" type="text" 
        name="responseOption" id="responseOption" [(ngModel)]="botResponseOption.responseOption">
        <div class="alert alert-danger" *ngIf="responseOption.touched && !responseOption.valid"> Button required</div>
        </div>

        <br>
  <div>
    <label for="optInStatusUpdateVar">Update Opt-In Status of Customer ?</label>
    <mat-form-field [style.width.%]=100 appearance="fill">
      <mat-label> Update Status to</mat-label>
      <mat-select [(ngModel)]="botResponseOption.optInStatusUpdateVar">
        <mat-option value=null>None</mat-option>
        <mat-option value="Opt-In">Opted In</mat-option>
        <mat-option value="Opt-Out">Opted Out</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  </div>
 
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addOptionToResponse'" (click)="addOptionToResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addOptionToTemplateResponse'" (click)="addOptionToTemplateResponse();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addButtonsToTemplate'" (click)="addButtonsToTemplate();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='addOptionToOption'" (click)="addOptionToOption(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='editOption'" (click)="editOption(); clicked=true">Update</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='editTemplateButton'" (click)="editTemplateButton(); clicked=true">Update</button>
</div>


<ng-template #desDetails let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Description</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

  Description will be seen by whatsapp users only when you use Interactive Lists format for Options.
    
  </div> 
</ng-template>