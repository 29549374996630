import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalClass } from 'src/app/global/global-class';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { BotCategory } from 'src/app/BOT_CODE/bot_classes/bot-category';
import { BotItem } from 'src/app/BOT_CODE/bot_classes/bot-item';
import { FileServiceService } from 'src/app/service/file-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { BotInvoice } from 'src/app/BOT_CODE/bot_classes/bot-invoice';

@Component({
  selector: 'app-bot-shop-ext-home',
  templateUrl: './bot-shop-ext-home.component.html',
  styleUrls: ['./bot-shop-ext-home.component.scss']
})
export class BotShopExtHomeComponent implements OnInit {

  // showSpinner = true;
  // currentRole = "CB_OFFICE_ADMIN";
  // navbarTitle : any;
  // userId !: string;
  // closeResult! : string;

  // config = {
  //   backdrop: true,
  //   ignoreBackdropClick: true,
  //   keyboard: false,
  //   class: 'modal-md'
  // }; 
  
  // officeObject : any = {
  //                           activeStatus: '',
  //                           backgroundColor: '',
  //                           id:'',
  //                           logo: '',
  //                           oa_ParentMentor:'',
  //                           oa_SubAdmin: '',
  //                           oa_CRMAdmin! : '',
  //                           oa_CourseAdmin! : '',
  //                           oa_ClassAdmin! : '',
  //                           oa_SkillReport! : '',
  //                           officeLocation: '',
  //                           officeName: '',
  //                           themeColour: '',
  //                           fkBotLangId: '',
  //                           multiLanguageSettings: ''
  //                         }

  // modalRefs: BsModalRef[] = [];
  // table: any;

  // public screenWidth: any;
  // public screenHeight: any;

  // viewCart=false;

  constructor(
    // private ngbModalService: NgbModal, private botResponseService : BotResponseServiceService,
    // public router: Router, private sharedDataService: SharedDataService, private subscriptionService : SubscriptionService,
    // public bsModalRef: BsModalRef, private toaster: ToastrService,
    // private botOfficeService : BotOfficeServiceService,
    // private imageService : FileServiceService,
    // private route : ActivatedRoute,
    // private sanitizer : DomSanitizer
    ) {
    
      }

// allCategories : BotCategory[] = [];

// categories : BotCategory[] = [];
// items : BotItem[] = [];
// cartItems : BotItem[] = [];

// botInvoiceId:any;

// toppingsControl = new FormControl([]);
// totalNumberOfItemsInCart=0;

// dataLoaded = false;
// orderDeleted = true;
// orderPlaced = false;

  ngOnInit(): void {

    // this.navbarTitle = GlobalClass.navbarTitle;

    // this.botInvoiceId = this.route.snapshot.params['botInvoiceId'];

    // this.screenWidth = window.innerWidth;
    // this.screenHeight = window.innerHeight;

    // this.botOfficeService.getBotCategoriesAndItemsForShop(this.botInvoiceId).subscribe(
    //   res=>{
    //     let resObj : BotCategory = new BotCategory();
    //     resObj = res;
    //     this.orderDeleted = resObj.orderDeleted;

    //     if(resObj.orderDeleted === false && resObj.invoiceOrderStatus === null){

    //     this.allCategories = resObj.list;
    //     this.categories = resObj.list;
    //     this.items = resObj.itemList;

    //     let catgIds : any[] = [];
    //     catgIds.push("selected all");
    //     this.allCategories.forEach((catg)=>{
    //       catg.panelOpenState = true;
    //       catg.categoryTotalNoOfItemsAddedInCart = 0;
    //       catgIds.push(catg.botCategoryId);
    //     });
    //     this.toppingsControl.setValue(catgIds);

    //     this.items.forEach((item)=>{
    //       item.quantityAddedToCart = 0;
    //       this.imageService.getBotItemImage(item.botItemImage).subscribe(
    //         data=>{
    //           let unsafeUrl = URL.createObjectURL(data);
    //           item.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
    //         });
    //     });

    //     this.categories.forEach((catg)=>{
    //       catg.panelOpenState = true;
    //       catg.categoryTotalNoOfItemsAddedInCart = 0;
    //       catg.itemList = [];
    //       this.items.forEach((item)=>{
    //         if(item.fkBotCategoryId===catg.botCategoryId){
    //           catg.itemList.push(item);
    //         }
    //       })
    //     });
    //       this.showSpinner = false;
    //       this.dataLoaded = true;

    //   }else if(resObj.orderDeleted === false && resObj.invoiceOrderStatus === "order placed"){
    //     this.toaster.success("Order placed !");
    //   }else if(resObj.orderDeleted === true){
    //     this.toaster.error("Text 'shop' and get a new link by zoti");
    //   }

    //   });

  }

  // incrementItemQuantityAddedToCart(item:BotItem){

  //   this.totalNumberOfItemsInCart = this.totalNumberOfItemsInCart + 1;

  //   item.quantityAddedToCart = item.quantityAddedToCart + 1;
  //   item.totalPriceforQty = item.quantityAddedToCart * item.pricePerItem;

  //   this.categories.forEach((category)=>{
  //     category.headerInfo = "";
  //     category.categoryTotalPriceOfItemsInCart = 0;
  //     category.categoryTotalNoOfItemsAddedInCart = 0;
  //     category.itemList.forEach((item)=>{
  //       if(item.quantityAddedToCart > 0){
  //       if(category.headerInfo===""){
  //         category.headerInfo = item.botItemName + " - " + item.quantityAddedToCart;
  //       }else{
  //         category.headerInfo = category.headerInfo + ", " + item.botItemName + " - " + item.quantityAddedToCart;
  //       }
  //       category.categoryTotalPriceOfItemsInCart = category.categoryTotalPriceOfItemsInCart + item.totalPriceforQty;
  //       category.categoryTotalNoOfItemsAddedInCart = category.categoryTotalNoOfItemsAddedInCart + item.quantityAddedToCart;
  //     }
  //     });
  //   });
  // }

  // decrementItemQuantityAddedToCart(item:BotItem){

  //   this.totalNumberOfItemsInCart = this.totalNumberOfItemsInCart - 1;

  //   item.quantityAddedToCart = item.quantityAddedToCart - 1;
  //   if(item.quantityAddedToCart === 0){
  //     item.totalPriceforQty = 0;
  //   }else{
  //     item.totalPriceforQty = item.quantityAddedToCart * item.pricePerItem;
  //   }

  //   this.categories.forEach((category)=>{
  //     category.headerInfo = "";
  //     category.categoryTotalPriceOfItemsInCart = 0;
  //     category.categoryTotalNoOfItemsAddedInCart = 0;
  //     category.itemList.forEach((item)=>{
  //       if(item.quantityAddedToCart > 0){
  //         if(category.headerInfo===""){
  //           category.headerInfo = item.botItemName + " - " + item.quantityAddedToCart;
  //         }else{
  //           category.headerInfo = category.headerInfo + ", " + item.botItemName + " - " + item.quantityAddedToCart;
  //         }
  //         category.categoryTotalPriceOfItemsInCart = category.categoryTotalPriceOfItemsInCart + item.totalPriceforQty;
  //         category.categoryTotalNoOfItemsAddedInCart = category.categoryTotalNoOfItemsAddedInCart + item.quantityAddedToCart;
  //       }
  //     });
  //   });

  //   if(this.totalNumberOfItemsInCart === 0){
  //     this.viewCart = false;
  //   }
  // }

  // closeWindow(){
  //   let customWindow :any = window.open('', '_blank', '');
  //   customWindow.close();
  // }

  // selectedAll = true;
  // selectAll(){
  //   if(this.selectedAll===true){
  //     this.toppingsControl.setValue([]);
  //     this.multiSelectValueChange("");
  //     this.selectedAll = false;
  //   }else{
  //     let catgIds : any[] = [];
  //     catgIds.push("selected all");
  //     this.allCategories.forEach((catg)=>{
  //       catgIds.push(catg.botCategoryId);
  //     });
  //     this.toppingsControl.setValue(catgIds);
  //     this.multiSelectValueChange("");
  //     this.selectedAll = true;
  //   }
  // }

  // multiSelectValueChange(value : any){

  //   this.categories = [];
  // let catgIds : any[] = this.toppingsControl.value;
  // catgIds.forEach((id)=>{
  //   this.allCategories.forEach((catg)=>{
  //     if(id===catg.botCategoryId){
  //       this.categories.push(catg);
  //     }
  //   });
  // });

  // let x = this.allCategories.length + 1;
  
  // if(x > catgIds.length){
  //   catgIds = catgIds.filter(item => item !== "selected all");
  //   this.toppingsControl.setValue(catgIds);
  //   this.selectedAll = false;
  // }

  // if(this.allCategories.length === catgIds.length){
  //   catgIds.push("selected all");
  //   this.toppingsControl.setValue(catgIds);
  //   this.selectedAll = true;
  // }

  // }

  // totalCartSum=0;
  // openCart(){
  //   this.showSpinner = true;
  //   this.cartItems = [];
  //   this.totalCartSum = 0;

  //   this.allCategories.forEach((categ)=>{
  //     if(categ.itemList !== null && categ.itemList.length > 0){
  //       categ.itemList.forEach((item)=>{
  //         if(item.quantityAddedToCart > 0){
  //           this.cartItems.push(item);
  //           this.totalCartSum = this.totalCartSum + item.totalPriceforQty;
  //         }
  //       });
  //     }
  //   });

  //   if(this.cartItems.length > 0){
  //     this.viewCart = true;
  //   }else{
  //     this.toaster.error("Cart is empty !");
  //   }
  //   this.showSpinner = false;
  // }

  // clicked = false;

  // placeOrder(){
  //  let invoice : BotInvoice = new BotInvoice();
  //  invoice.invoiceId = this.botInvoiceId;
  //  invoice.cartItems = this.cartItems;

  //  if(invoice.cartItems !== null && invoice.cartItems.length > 0){
  //   this.clicked = true;

  //   this.botOfficeService.placeOrder(invoice).subscribe(
  //    res=>{
  //     this.orderPlaced = true;
  //      this.toaster.success("Order Placed Successfully");
  //    });
  //  }else{
  //   this.toaster.error("Add items to cart to proceed !");
  //  }


  // }


  // open(content: any) {
  //   this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }


}