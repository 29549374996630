import { Component, OnInit } from "@angular/core";
import { CrmServiceService } from "../../services/crm-service.service";
import { CrmList } from "../../classes/crm-list";
import { environment } from "src/environments/environment";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CrmListsModalComponent } from "../../modals/crm-lists-modal/crm-lists-modal.component";
import { DatePipe } from "@angular/common";
import { CrmTask } from "../../classes/crm-task";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { CrmTasksModalComponent } from "../../modals/crm-tasks-modal/crm-tasks-modal.component";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { SharedDataService } from "src/app/services/sharedData.service";
import { Router } from "@angular/router";
import { GlobalClass } from "src/app/global/global-class";
import { BotAdminNotification } from "src/app/BOT_CODE/bot_classes/bot-admin-notification";
import { ViewBotMediaForAgentComponent } from "src/app/BOT_CODE/bot_modals/bot-media/view-bot-media-for-agent/view-bot-media-for-agent.component";

@Component({
  selector: "app-crm-lists-dashboard",
  templateUrl: "./crm-lists-dashboard.component.html",
  styleUrls: ["./crm-lists-dashboard.component.scss"],
})
export class CrmListsDashboardComponent implements OnInit {
  showSpinner = true;
  loggedInUser: any;
  navbarTitle: any;
  userId: any;
  userOfficeId: any;
  crmLists: CrmList[] = [];
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  constructor(
    private crmServiceService: CrmServiceService,
    private ngbModalService: NgbModal,
    private datePipe: DatePipe,
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;
    this.getCrmLists();
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  getCrmLists() {
    this.crmServiceService
      .getMethod(environment.apiUrl + "bizCrm/getCrmListsAndTasksByOfficeId")
      .then((data) => {
        let resObj: CrmList = new CrmList();
        resObj = data;
        this.crmLists = resObj.lists;

        this.botOfficeService
          .getBotUsers(
            environment.apiUrl + "botOffice/getBotOfficeAdminsAndAgents"
          )
          .then((agentsRes) => {
            let botOfficeAdminsAndAgents: BotCustomerUser =
              new BotCustomerUser();
            botOfficeAdminsAndAgents = agentsRes;

            this.crmLists.forEach((crmLst) => {
              if (
                crmLst.crmListTasks !== null &&
                crmLst.crmListTasks !== undefined &&
                crmLst.crmListTasks.length > 0
              ) {
                crmLst.crmListTasks.forEach((crmTsk) => {
                  let x = crmTsk.creationTimestamp;
                  // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
                  crmTsk.creationTimestamp = this.datePipe.transform(
                    x,
                    "d/M/yy, h:mm a"
                  );

                  botOfficeAdminsAndAgents.users.forEach((botAgent) => {
                    if (botAgent.botUserId === crmTsk.fkAgentBotUserId) {
                      crmTsk.fkAgentBotUserName = botAgent.name;
                    }
                  });

                  if (
                    crmTsk.taskMediaMsg !== null &&
                    crmTsk.taskMediaMsg !== undefined
                  ) {
                    let media: BotAdminNotification[] = [];

                    let mediaMessage = crmTsk.taskMediaMsg;
                    let mediaMessageArray = mediaMessage.split(";;");

                    mediaMessageArray.forEach((message: any) => {
                      let splitMsg = message.split("::");

                      let mediaObj: BotAdminNotification =
                        new BotAdminNotification();
                      mediaObj.mediaName = splitMsg[0];
                      mediaObj.mediaType = splitMsg[1];
                      mediaObj.mediaObjId = splitMsg[2];

                      media.push(mediaObj);
                    });

                    crmTsk.media = media;
                  }
                });
              }
            });
          });

        this.showSpinner = false;
      });
  }

  viewBotMedia(botMediaId: any, mediaType: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotMediaForAgentComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMediaId: botMediaId,
      mediaType: mediaType,
      officeId: this.userOfficeId,
      getByBOT_MEDIA_ID: true,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  createCrmList() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CrmListsModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "create crmList",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: CrmList) => {
      this.showSpinner = true;
      if (this.crmLists === null || this.crmLists === undefined) {
        this.crmLists = [];
      }
      this.crmLists.push(receivedEntry);
      this.showSpinner = false;
    });
  }

  editCrmList(crmList: CrmList) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CrmListsModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "edit crmList",
      crmList: crmList,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: CrmList) => {
      this.showSpinner = true;
      this.crmLists.forEach((list) => {
        if (list.crmListId === receivedEntry.crmListId) {
          list = receivedEntry;
        }
      });
      this.showSpinner = false;
    });
  }

  viewCrmTaskDetails(crmTask: CrmTask) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CrmTasksModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "CrmTask Details",
      crmTask: crmTask,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      this.showSpinner = false;
    });
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  crmListIdToAttachTo: any = null;
  changeCrmTaskList(crmTask: CrmTask) {
    this.showSpinner = true;
    this.crmServiceService
      .getMethod(
        environment.apiUrl +
          "bizCrm/changeCrmTaskList/" +
          crmTask.crmTaskId +
          "/" +
          this.crmListIdToAttachTo
      )
      .then((res) => {
        this.crmLists.forEach((llist) => {
          if (llist.crmListId === crmTask.fkCrmListId) {
            llist.crmListTasks.splice(llist.crmListTasks.indexOf(crmTask), 1);
          }
          if (llist.crmListId === this.crmListIdToAttachTo) {
            if (
              llist.crmListTasks === null ||
              llist.crmListTasks === undefined
            ) {
              llist.crmListTasks = [];
            }
            llist.crmListTasks.push(crmTask);
            this.showSpinner = false;
          }
        });
      });
  }

  closeResult!: string;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
