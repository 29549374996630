import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityScore } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-score';
import { AddActActualActScore } from 'src/app/courseManagementClasses/combinedParentTeacher/add-act-actual-act-score';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { ParentServiceService } from 'src/app/service/parent-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recurring-activity-accessment-modal',
  templateUrl: './recurring-activity-accessment-modal.component.html',
  styleUrls: ['./recurring-activity-accessment-modal.component.scss']
})
export class RecurringActivityAccessmentModalComponent implements OnInit {
  inputType : any;
  questionOrder : any;
  actQuestionsExists : boolean = true;
  activityPlannerId! : string;
  fkStudentId!: string;
  parentUserId!: string;
  actQuestions : ActivityQuestions[] = [];
  clicked = true;
  score : ActivityScore = new ActivityScore();
  scoreList : ActivityScore[] = [];
  AddActActualActScore : AddActActualActScore = new AddActActualActScore();
  
  constructor(private combinedParentTeacherService : CombinedParentTeacherService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private parentServiceService : ParentServiceService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;
 
     @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 
  
  ngOnInit(): void {

    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.fkStudentId = this.fromParent.studentId;
    this.parentUserId = this.fromParent.parentUserId;

    // authorized
    this.combinedParentTeacherService.getActivityQuestionListByActPlnrId(environment.apiUrl + "activityQuestion/getByActPlnrId/" + this.activityPlannerId).then(
      data=>{ 
        this.actQuestions=data;
        
        this.actQuestions.forEach((value)=>{
          this.score.fkActivityQuestionId = value.activityQuestionId;
          this.score.activityScoreId
          this.score.activityScoreName = "No";
          this.score.activityScoreStatus = true;
          this.score.activityScoreValue = "0";
          this.score.fkActivityActualId 
          this.score.fkStudentId = this.fkStudentId;

          this.scoreList.push(this.score);

          this.score = new ActivityScore();
        })

      
        
        
        if (this.actQuestions === undefined || this.actQuestions.length == 0) {
          this.actQuestionsExists = false;
      }

        this.actQuestions.forEach((question) =>{
            if(question.activityQuestionType=='R'){
              this.inputType = 'Radio';
              this.questionOrder = question.activityQuestionOrder;
            }
        }); 

      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  onSubmit(){
    this.clicked = true;
    this.AddActActualActScore.activityScoreList = this.scoreList;
    this.AddActActualActScore.fkActivityPlannerId = this.activityPlannerId;
    this.AddActActualActScore.fkStudentId = this.fkStudentId;
    this.AddActActualActScore.parentUserId = this.parentUserId;

    this.parentServiceService.addParentRecurringActivityScore(environment.apiUrl + 'parent/addParentRecurringActivityScore', this.AddActActualActScore).then(
      data=>{
        this.activeModal.close();
        this.passEntry.emit('successfull');
        this.toaster.success('Assessment completed successfully')
      }
    )
    


  }

  closeUpdateModal(){
    this.activeModal.close();
  }

 handleClick(id : any) {

  this.clicked = false;

  this.scoreList.forEach((val)=>{
    if(val.fkActivityQuestionId === id){
      if(val.activityScoreName === "No"){
        val.activityScoreName = "Yes";
        val.activityScoreValue = "1";
      }else if(val.activityScoreName === "Yes"){
        val.activityScoreName = "No";
        val.activityScoreValue = "0";
      }
    }
  });
 

  }
}
