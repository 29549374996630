import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-username-check-modal',
  templateUrl: './username-check-modal.component.html',
  styleUrls: ['./username-check-modal.component.scss']
})
export class UsernameCheckModalComponent implements OnInit {
 
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  loggedInUser: any;
  theme : any;

  type : any = 'phone';
  email : any;
  phone : any;
  value : any;

  profile : CompleteCustomerDetails = new CompleteCustomerDetails();
 
  search = true;

  constructor(private subscriptionService : SubscriptionService,
    private sharedDataService : SharedDataService,
    private activeModal : NgbActiveModal) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }  

  searchFoUser(){
    
    this.subscriptionService.searchCustomerAndUserProfiles(environment.apiUrl + "customer/getCustomersByEmailOrPhone/" + this.value + "/" + this.type ).then(
      data =>{   

        this.profile = data;

        if(this.profile.customers.length !== 0){

          this.search = false;

        }else if(this.profile.customers.length === 0){

          if(this.type==="phone"){
            this.phone = this.value;
            this.createCustomer();
          }else if(this.type==="email"){
            this.email = this.value;
            this.createCustomer();
          }

        }
      });
  }

  useDifferentEmail(){

    this.email = '';
    this.search = true;

  }

  // authorized
  createCustomer(){ 

    this.activeModal.close();
    this.profile.email = this.email;
    this.profile.phoneNumber = this.phone;
    this.profile.createCustomer = true;
    this.passEntry.emit(this.profile);

  }

    createCustomerWithSameEmailOrPhone(){ 

      if(this.type==="phone"){
        this.phone = this.value;
      }else if(this.type==="email"){
        this.email = this.value;
      }
      this.activeModal.close();
      this.profile.email = this.email;
      this.profile.phoneNumber = this.phone;
      this.profile.createCustomer = true;
      this.passEntry.emit(this.profile);
  
    }

  showProfile(id : any, type : any){

    this.activeModal.close();
    this.profile.createCustomer = false;

    this.profile.customerId = id;
    this.profile.customerType = type;
    this.passEntry.emit(this.profile);

  }

}

