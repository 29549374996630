<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4  *ngIf="searchAttendance===true" style="color: white;" class="modal-title pull-left"> Attendance</h4>
    <h4  *ngIf="searchAttendance===false" style="color: white;" class="modal-title pull-left"> Attendance For Class : {{selectedClassName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
   

            <form #aplnrForm="ngForm">

                <div *ngIf="searchAttendance===true" >

            <div class="form-group row">
                <div class="col-md-6">
                    <label class="required" for="roleId">Select Class</label> 
                    <select #invoice="ngModel" required  class="form-control" [(ngModel)]="mainAttendance.fkClassId" id="selectedClassId" name="selectedClassId">
                      <option value="" disabled >Choose class</option>
                      <option  *ngFor="let class of classes" 
                       [ngValue]="class.classId">{{class.name}}
                      </option>
                    </select>
              </div> 
              <div class="col-md-6">
                <label class="required" for="attendanceDate"> Date</label>
                <input #date2="ngModel" required class="form-control" type="date" name="attendanceDate" 
                id="attendanceDate" [(ngModel)]="mainAttendance.attendanceDate">
                <div class="alert alert-danger" *ngIf="date2.touched && !date2.valid">Provide Date</div>            
              </div>

              <!-- <div class="col-md-4">
                <label class="required" for="">Select Date-Time</label>
                <input class="form-control"  required [(ngModel)]="mainAttendance.attendanceFromDateTime" 
                type="datetime-local" id="attendanceFromDateTime" name="attendanceFromDateTime">
            </div> -->

            </div>

        </div>

        

    


        </form>

        <div *ngIf="searchAttendance===false" >

<h4 style="margin: 0%;font-weight: bold;"> Class in Session : {{selectedClassName}}</h4>
<h4 style="margin: 0%;font-weight: bold;">Session Date & Time : {{mainAttendance.attendanceFromDateTimeFormatted}} to {{mainAttendance.attendanceToDateTimeFormatted}}</h4>

<br>

          <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
            <thead>
              <tr>
                <th [ngStyle]="backgroundStyle" >
                  Student
                </th>
                <th [ngStyle]="backgroundStyle">
                  Class
                </th>
                 <th [ngStyle]="backgroundStyle" >
                  Attendance
                </th>

                <th *ngIf="editAttendance===true" [ngStyle]="backgroundStyle">
                  Action
                </th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let att of mainAttendance.attendanceList">
                <td>
                  {{att.studentName}}
                </td>
                <td>
                  {{att.studentClassName}}
                </td>
                <td *ngIf="att.present===true" style="color: green;">
                  Present
                </td>
                <td *ngIf="att.present===false" style="color: red;">
                  Absent
                </td>
              
                <td *ngIf="editAttendance===true && att.present===false">
                  <button class="btn btn-success" (click)="markAttendancePresentForOldSession(att.fkStudentId);" style="width: auto;">Mark Present</button>
                </td>
                <td *ngIf="editAttendance===true && att.present===true">
                  <button class="btn btn-danger" (click)="markAttendanceAbsentForOldSession(att.attendanceId);" style="width: auto;">Mark Absent</button>
                </td>

              </tr>
              <tr *ngFor="let att of mainAttendance.absenteeList">
                <td>
                  {{att.studentName}}
                </td>
                <td>
                  {{att.studentClassName}}
                </td>
                <td *ngIf="att.present===true" style="color: green;">
                  Present
                </td>
                <td *ngIf="att.present===false" style="color: red;">
                  Absent
                </td>

                <td *ngIf="editAttendance===true && att.present===false">
                  <button class="btn btn-success" (click)="markAttendancePresentForOldSession(att.fkStudentId);" style="width: auto;">Mark Present</button>
                </td>
                <td *ngIf="editAttendance===true && att.present===true">
                  <button class="btn btn-danger" (click)="markAttendanceAbsentForOldSession(att.attendanceId);" style="width: auto;">Mark Absent</button>
                </td>

              </tr>
              <tr *ngFor="let att of mainAttendance.attendanceForDifferentClassSameSession">
                <td>
                  {{att.studentName}}
                </td>
                <td>
                  {{att.studentClassName}}
                </td>
                <td *ngIf="att.present===true" style="color: green;">
                  Present
                </td>
                <td *ngIf="att.present===false" style="color: red;">
                  Absent
                </td>

                <td *ngIf="editAttendance===true && att.present===false">
                  <button class="btn btn-success" (click)="markAttendancePresentForOldSession(att.fkStudentId);" style="width: auto;">Mark Present</button>
                </td>
                <td *ngIf="editAttendance===true && att.present===true">
                  <button class="btn btn-danger" (click)="markAttendanceAbsentForOldSession(att.attendanceId);" style="width: auto;">Mark Absent</button>
                </td>

              </tr>
            </tbody>
          </table>

        </div>


            

     

      </div>
    </div>
   </div>
   
   <div *ngIf="searchAttendance===true"  class="modal-footer">
    <button style="width: auto;" [disabled]="!aplnrForm.valid" 
    [disabled]="clicked" type="submit" class="btn btn-sm btn-info float-right" 
    (click)="getAttendance();">Get Attendance</button>
  </div>

  <div *ngIf="searchAttendance===false && editAttendance===false"  class="modal-footer">
    <button style="width: auto;"
    type="submit" class="btn btn-sm btn-primary float-right" 
    (click)="editAttendance=true;">Edit Attendance</button>
  </div>

  <!-- <div *ngIf="searchAttendance===false && editAttendance===true"  class="modal-footer">
    <button style="width: auto;"
    type="submit" class="btn btn-sm btn-success float-right" 
    (click)="editAttendance=false;">Cancel Edit</button>
  </div> -->

