<div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Change Owner</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #customerRegisteration="ngForm">

 
       
  <div class="form-group row">


    <div class="col-md-12">
      <label class="required" for="selectedExeUroId">Executive</label> <br>
          <select required  class="form-control" [(ngModel)]="selectedExeUroId" id="selectedExeUroId" name="selectedExeUroId">
            <option value="" disabled >Choose Executive</option>
            <option  *ngFor="let ex of executives"  [ngValue]="ex.exeUroId">{{ex.exeName}}</option>
          </select>
      </div>
      
   
  </div>
     
  <br>
    </form>

    <button [disabled]="clicked"   [disabled]="!customerRegisteration.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="change(); clicked = true;">Change</button>

    <br>

  
    </div>
    </div>
    
    
   