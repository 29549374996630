import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Attendance } from 'src/app/courseManagementClasses/attendance';
import { Student } from 'src/app/courseManagementClasses/student';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-course-activities-details',
  templateUrl: './student-course-activities-details.component.html',
  styleUrls: ['./student-course-activities-details.component.scss']
})
export class StudentCourseActivitiesDetailsComponent implements OnInit {

  constructor(  private datePipe: DatePipe,
    private teacherService : TeacherService,
              private ngbModalService: NgbModal,
              private studentService : StudentService,
              public activeModal: NgbActiveModal,
    private sanitizer : DomSanitizer,
    private imageService : FileServiceService ,
    private sharedDataService: SharedDataService,) { } 

  student : Student= new Student();

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  attendance : Attendance = new Attendance();
  classOptions : any;
  showAttendance = false;

  theme : any;
  loggedInUser: any;

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getAcademicDetailsByStudentId(this.fromParent.studentId);

    this.classOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    }; 
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getStudentAttendance(){
    this.teacherService.getAttendanceByStudentId(environment.apiUrl + "attendance/getAttendanceByStudentId/" + this.fromParent.studentId).then(
      res=>{
        this.attendance = res;
        this.attendance.attendanceList.forEach((att)=>{
          att.attendanceDate = new Date(att.attendanceDate);
          att.attendanceDate = this.datePipe.transform(att.attendanceDate, "dd-MM-yyyy");

          att.attendanceFromDateTimeFormatted = new Date(att.attendanceFromDateTime[0], att.attendanceFromDateTime[1] - 1, att.attendanceFromDateTime[2], att.attendanceFromDateTime[3], att.attendanceFromDateTime[4]);
          att.attendanceFromDateTimeFormatted = this.datePipe.transform(att.attendanceFromDateTimeFormatted, 'dd-MM-yyyy hh:mm'); 
    
          att.attendanceToDateTimeFormatted = new Date(att.attendanceToDateTime[0], att.attendanceToDateTime[1] - 1, att.attendanceToDateTime[2], att.attendanceToDateTime[3], att.attendanceToDateTime[4]);
          att.attendanceToDateTimeFormatted = this.datePipe.transform(att.attendanceToDateTimeFormatted, 'dd-MM-yyyy hh:mm'); 

            });
        this.showAttendance = true;
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  
  getAcademicDetailsByStudentId(studentId : any){
    this.student = new Student();
    this.studentService.getStudentResultsByStudentId(environment.apiUrl + "student/getStudentResultsByStudentId/" + studentId).then(
      res=>{
        this.student = res;
        this.student.subjects.forEach((sub)=>{
          sub.activityPlanners.forEach((ap)=>{
           let dates : any[] = [];
           let dateString : any = "";
           let count =0;
            ap.activityDates.forEach((date)=>{
              count++;
              date = new Date(date);
              date = this.datePipe.transform(date, "dd-MM-yyyy");
              dates.push(date);   
              if(count===1){
                dateString =  date 
              }else if(count>1){
                dateString = dateString + ", " + date
              }         
            });
            ap.activityDates = [];
            ap.activityDates = dates;
            ap.dateString = dateString;
          })
        })
        
      });
  }


  close(){
    this.activeModal.close();
  }
}
