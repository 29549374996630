import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OfficesService } from "src/app/services/office/offices.service";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { BotSchedule } from "src/app/BOT_CODE/bot_classes/bot-schedule";
import { DatePipe } from "@angular/common";
import { ViewScheduleDetailsComponent } from "src/app/BOT_CODE/bot_modals/schedule-messages/view-schedule-details/view-schedule-details.component";
import { BotAutoSchedulerSettings } from "src/app/BOT_CODE/bot_classes/bot-auto-scheduler-settings";
import { CreateBotAutoSchedulerSettingsComponent } from "src/app/BOT_CODE/bot_modals/bot_auto_scheduler_settings/create-bot-auto-scheduler-settings/create-bot-auto-scheduler-settings.component";

@Component({
  selector: "app-bot-auto-schedule-settings-dashboard",
  templateUrl: "./bot-auto-schedule-settings-dashboard.component.html",
  styleUrls: ["./bot-auto-schedule-settings-dashboard.component.scss"],
})
export class BotAutoScheduleSettingsDashboardComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  schedulesExists = false;
  settingsExists = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  schedules: BotSchedule[] = [];
  botAutoSchedulerSettings: BotAutoSchedulerSettings[] = [];
  shopifyAbandonedCartAutoSchSettings: BotAutoSchedulerSettings[] = [];

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private botOfficeService: BotOfficeServiceService,
    private datePipe: DatePipe,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;

    this.userId = this.loggedInUser.userId;
  }

  currentTimezone: any;

  searchText1: any;
  searchText2: any;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "admin/getOfficeByIdForBotOfficeAdmin"
      )
      .then((res) => {
        this.officeObject = res;
        this.currentTimezone = this.officeObject.officeTimezone;
      });

    this.getBotSchedulesByOfficeId();
    this.getBotAutoSchedulerSettingsByOfficeId();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  getBotSchedulesByOfficeId() {
    this.botOfficeService
      .getBotSchedulesByOfficeId(
        environment.apiUrl +
          "botOffice/getAutoSettingsSchedulesByOfficeIdAndType/" +
          "template"
      )
      .then((res) => {
        this.schedules = [];
        let sch: BotSchedule = new BotSchedule();
        sch = res;
        this.schedules = sch.list;

        if (
          this.schedules !== null &&
          this.schedules !== undefined &&
          this.schedules.length > 0
        ) {
          this.schedulesExists = true;
        }

        this.schedules.forEach((schedule) => {
          let x = schedule.executionTimestamp;
          // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
          schedule.executionTimestamp = this.datePipe.transform(
            x,
            "d/M/yy, h:mm a"
          );
        });

        this.showSpinner = false;
      });
  }

  getBotAutoSchedulerSettingsByOfficeId() {
    this.showSpinner = true;
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getBotAutoSchedulerSettingsByOfficeId"
      )
      .then((res) => {
        this.shopifyAbandonedCartAutoSchSettings = [];
        this.botAutoSchedulerSettings = [];

        let resObj: BotAutoSchedulerSettings = new BotAutoSchedulerSettings();
        resObj = res;

        resObj.list.forEach((obj) => {
          if (obj.context === "shopify_abandoned_cart_notifications") {
            this.shopifyAbandonedCartAutoSchSettings.push(obj);
          } else {
            this.botAutoSchedulerSettings.push(obj);
          }
        });

        // this.botAutoSchedulerSettings = resObj.list;
        this.settingsExists = true;
        this.showSpinner = false;
      });
  }

  viewScheduleDetails(botSchedule: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewScheduleDetailsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botSchedule: botSchedule,
      currentTimezone: this.currentTimezone,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUser) => {}
    );
  }

  createBotAutoSchedulerSettings() {
    let context = "Create Settings";
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotAutoSchedulerSettingsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      context: context,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.botAutoSchedulerSettings.push(receivedEntry);
      this.settingsExists = true;
    });
  }

  createShopifyAbandonedCartNotificationSchedules() {
    let context = "Create Shopify Abandoned Cart Notification Schedules";
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotAutoSchedulerSettingsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      context: context,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      this.shopifyAbandonedCartAutoSchSettings.push(receivedEntry);
      this.settingsExists = true;
      this.showSpinner = false;
    });
  }

  editBotAutoSchedulerSettings(botAutoSchedulerSettings: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateBotAutoSchedulerSettingsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      botAutoSchedulerSettings: botAutoSchedulerSettings,
      context: "Edit Settings",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotAutoSchedulerSettings) => {
        this.getBotAutoSchedulerSettingsByOfficeId();
      }
    );
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }
}
