import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateCompCustDetailsComponent } from 'src/app/crm/compCustomer/update-comp-cust-details/update-comp-cust-details.component';
import { AddInvoiceComponent } from 'src/app/crm/invoice/add-invoice/add-invoice.component';
import { UpdateInvoiceComponent } from 'src/app/crm/invoice/update-invoice/update-invoice.component';
import { ViewInvoiceDetailsComponent } from 'src/app/crm/invoice/view-invoice-details/view-invoice-details.component';
import { AddPaymentComponent } from 'src/app/crm/payment/add-payment/add-payment.component';
import { UpdatePaymentComponent } from 'src/app/crm/payment/update-payment/update-payment.component';
import { AddShipmentComponent } from 'src/app/crm/shipment/add-shipment/add-shipment.component';
import { UpdateShipmentComponent } from 'src/app/crm/shipment/update-shipment/update-shipment.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { Shipment } from 'src/app/crmClasses/shipment';
import { GlobalClass } from 'src/app/global/global-class';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { InvoiceService } from 'src/app/service/invoice.service';
import { PaymentService } from 'src/app/service/payment.service';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-profile-modal',
  templateUrl: './customer-profile-modal.component.html',
  styleUrls: ['./customer-profile-modal.component.scss']
})
export class CustomerProfileModalComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  selectedOption = '0';
  showPayments = false;
  showShipments = false;
  theme : any;
  officeId : any;
  loggedInUser: any;
  combinedPTC : CompleteCustomerDetails = new CompleteCustomerDetails();
  profile = true;
  id : any;
  showInvoices = false;
  invoices : any[] = [{
    deliveryAddress: '',
description: '',
fkCustomerId: '',
fkInvoiceId: '',
fkProductId: '',
invoiceDate: '',
invoiceDetId: '',
invoiceDetStatus: '',
invoiceId: '',
invoiceNo: '',
invoiceRemarks: '',
invoiceStatus: '',
itemName: '',
paymentMethod: '',
pricePerItem: '',
quantity: '',
totalAmount: '',
totalPrice: ''
  }];

  shipments : Shipment[] = [];

  payments : any[] = [{fkInvoiceId: "",
  paymentAmount: "",
  paymentCompletion: "",
  paymentDate: "",
  paymentDue: "",
  paymentId: "",
  paymentMethod: "",
  paymentRemarks: "",
  paymentStatus: ""

}]

  constructor(
    private datePipe: DatePipe,
              private ngbModalService: NgbModal,
              public activeModal: NgbActiveModal,
              private invoiceService : InvoiceService, 
              private paymentService : PaymentService,
              private shipmentService : ShipmentService,
              private sharedDataService: SharedDataService,
              private ccdetailsService : CompCustDetailsService ) { }

    @Input() fromParent: any;  

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.officeId = this.loggedInUser.offices[0].id;

    this.id = this.fromParent.id;

    this.ccdetailsService.getCustomerById(this.id).subscribe(data=>{
      this.combinedPTC = data;
      
      if(this.combinedPTC.dateOfBirth !== null){
        this.combinedPTC.dateOfBirth =this.datePipe.transform(this.combinedPTC.dateOfBirth, 'dd-MM-yyyy');
      }
      if(this.combinedPTC.leadCreationDate !== null){
        this.combinedPTC.leadCreationDate =this.datePipe.transform(this.combinedPTC.leadCreationDate, 'dd-MM-yyyy');
      }

});

    this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
      data=>{
        this.invoices = data;
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onChange(event : any){ 
    
    let value = this.selectedOption;
   
    this.showInvoices = false;
    this.showPayments = false;
    this.showShipments = false;

    if(value === '1'){

      this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
        data=>{
          this.invoices = data;
          this.showInvoices = true;
        });
    
    }else if(value === '2'){

this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.id).then(
  data=>{
    this.payments = data;
    this.showPayments = true;
  });
    
    }else if(value === '3'){
 
      this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.id).then(
        data=>{
          this.shipments = data;
          this.showShipments = true;
        });
     
    }else if(value === '4'){

  
      }

  }

  viewInvoiceDetailsModal(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewInvoiceDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });

    let data = {
      invoiceId: invoiceId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }

    // authorized
  addNewInvoice(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddInvoiceComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        customer : this.combinedPTC
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
          data=>{
            this.invoices = data;
            this.showInvoices = true;
          });
      
      });
  }

  // authorized
  addNewPayment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddPaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        customerId : this.id
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {});

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.id).then(
          data=>{
            this.payments = data;
            this.showPayments = true;
          });
      });  
  }

     // authorized
  editInvoice(invoiceId : string){

      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( UpdateInvoiceComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      invoiceId: invoiceId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
          this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
            data=>{
              this.invoices = data;
              this.showInvoices = true;
            });
        });
  }

  // authorized
  addNewShipment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      customerId : this.id
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
          this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.id).then(
            data=>{
              this.shipments = data;
              this.showShipments = true;
            });
        });   
   
  }

  // authorized
  editCustomer(){ 
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateCompCustDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: this.id ,
          type : 'CUSTOMER'
        }
       

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
      this.ccdetailsService.getCustomerById(this.id).subscribe(data=>{
        this.combinedPTC = data;
  
        if(this.combinedPTC.dateOfBirth !== null){
          this.combinedPTC.dateOfBirth =this.datePipe.transform(this.combinedPTC.dateOfBirth, 'dd-MM-yyyy');
        }
        if(this.combinedPTC.leadCreationDate !== null){
          this.combinedPTC.leadCreationDate =this.datePipe.transform(this.combinedPTC.leadCreationDate, 'dd-MM-yyyy');
        }
        
        this.passEntry.emit(this.combinedPTC);
      });
    });  
  }

   // authorized
   editPayment(paymentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdatePaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        paymentId: paymentId
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

          modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
            this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.id).then(
              data=>{
                this.payments = data;
                this.showPayments = true;
              });
          });  
  }

   // authorized
   editShipment(shipmentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      shipmentId: shipmentId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
      this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.id).then(
        data=>{
          this.shipments = data;
          this.showShipments = true;
        });
    });  
    
  }

 
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.passEntry.emit('close');
    this.activeModal.close();
  }

} 
