<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Activate Form : {{feedbackForm.name}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form (ngSubmit)="onSubmit()" #aqForm="ngForm"> 

    <div class="form-group row">
        <div class="col-md-6">
        <label class="required" for="targetStartDate">From Date</label>
        <input #date="ngModel" required class="form-control" type="date" name="targetStartDate" 
        id="targetStartDate" [(ngModel)]="formActive.fromDate">
        <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provide Start Date</div>
    </div>

    <div class="col-md-6">
        <label  class="required" for="targetEndDate">To Date</label>
        <input required class="form-control" [(ngModel)]="formActive.toDate" type="date" id="targetEndDate"
         name="targetEndDate">
    </div>
    </div>
        
<div class="form-group row">
  <div class="col-md-3">
    <label class="required"  for="activityQuestionOrder">Limit</label>
    <input #Order="ngModel" placeholder="Enter Order" required class="form-control" type="number" 
    name="activityQuestionOrder"  id="activityQuestionOrder" [(ngModel)]="formActive.formLimit">
    <div class="alert alert-danger" *ngIf="Order.touched && !Order.valid">Limit is required</div>
 </div>

    <div class="col-md-9">
      <label for="selectedUroId">Role</label> 
      <select class="form-control" [(ngModel)]="formActive.fkRoleId" id="selectedUroId" name="selectedUroId">
        <option value="" disabled >Choose Role</option>
        <option  *ngFor="let role of roles" 
         [ngValue]="role.roleId">{{role.displayName}}
        </option>
      </select>
</div>
</div>



<br>






</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
</div>