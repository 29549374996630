import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { AddUserModalComponent } from '../add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { ProfileModalComponent } from '../profile-modal/profile-modal.component';
import { TeacherClassSubjectsModalComponent } from '../teacher-class-subjects-modal/teacher-class-subjects-modal.component';

@Component({
  selector: 'app-class-admin-teacher-dashboard',
  templateUrl: './class-admin-teacher-dashboard.component.html',
  styleUrls: ['./class-admin-teacher-dashboard.component.scss']
})
export class ClassAdminTeacherDashboardComponent implements OnInit {
  teachersExist= false;
  navbarTitle : any;
  currentRole = "CLASS_ADMIN";
  showSpinner : boolean = true;
  classLoaderMessage!: string;
  classExists!: boolean;
  classOptions: any = {};
  loggedInUser: any;
  statusUpdateUserId : any
  officeId!: string;
  userName!: string;
  userId!: string;

  public screenWidth: any;
  public screenHeight: any;

  teachers : any[] = [{
    fkClass: "",
fkSubject: "",
fkUserRoleOffice: "",
teacherId: "",
teacherImage: "",
teacherName: "",
teacherStatus: "",
safeUrl : "",
collapsedState : false,
userId : "",
firstName : "",
lastName : "",
subjects : [{
                description:  "",
              fkProductId:  "",
              name:  "",
              subId:  "",
              subjectImage:  "",
              subjectStatus:  ""
              }]

  }]
  closeResult! : string;

  constructor(
              private ngbModalService: NgbModal,
              public router: Router, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private subscriptionService : SubscriptionService,
              private teacherService : TeacherService,
              private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    safeStudentUrl : any;
    unsafeStudentUrl : any;  

    ngOnInit(): void {

      this.navbarTitle = GlobalClass.navbarTitle;
      
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.officeId = this.loggedInUser.offices[0].id;
      this.userId = this.loggedInUser.userId;
      this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
      
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
  
      this.classOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        lengthChange: false,
        processing: true,
        info:false
      }; 

      this.getTeachersInOffice();
    }

    getTeachersInOffice(){

        this.teacherService.getTeachersInOfficeAuth(environment.apiUrl + "teacher/getAllTeachersInOfficeAuth").then(

        data=>{
          this.teachers = data;
          this.teachersExist = true;
          if(this.teachers === undefined || this.teachers.length === 0){
            setTimeout(()=>{                           //timer
              this.showSpinner = false;
         }, 1500);
          } 

          let count : number = 0;
          this.teachers.forEach( (value) => {
            value.collapsedState = false;
            count++;
            this.imageService.getUserImage(value.teacherImage).subscribe(
            data=>{
            this.unsafeStudentUrl = URL.createObjectURL(data);
            this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
            
            value.safeUrl = this.safeStudentUrl;
            
            });
        
            if(count === this.teachers.length){

              setTimeout(()=>{                           //timer
                this.showSpinner = false;
           }, 1500);

            }
            });
          
        });
    }

    open(content: any) {
      this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
      
    }
  

    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }
  
  

    collapseActivityCardContent(teacherId : string){
      
          this.teachers.forEach((value)=>{
      
            
            if(value.teacherId === teacherId){
      
              if(value.collapsedState === true){
                value.collapsedState = false
              } else if(value.collapsedState === false){
                value.collapsedState = true
              }
      
            }
          });
        }

        teacherStatusUpdate(teacherId : any){

          this.teacherService.teacherStatusUpdate(teacherId).subscribe(
            data=>{
              this.teachers.forEach((val)=>{
      
      
                val.subjects.forEach((value : any)=>{
      
                  if(value.teacherId === teacherId){
                    if(value.subjectStatus === true){
                      value.subjectStatus = false;
                    }else if(value.subjectStatus === false){
                      value.subjectStatus = true;
                    }
                  }
      
                })
      
      
      
              });
            }
          )
        }

        editUser(userId : any){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(EditUserModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        userId: userId 
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
            
            
          }); 
        }



        

        openProfileModal(userId : any){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(ProfileModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        userId: userId,
        role : "TEACHER"
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
            
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            
          }); 
        }

        addUser(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(AddUserModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        officeId: this.officeId,
        type : "TEACHER"
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            this.getTeachersInOffice();
          }); 
        }

        getTeacherClassSubjects(userId : string){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open( TeacherClassSubjectsModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
          let data = { 
            userId : userId,
          }
      
          modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
          }); 
        }

        updateUserStatus(){
          this.subscriptionService.userStatusUpdate(environment.apiUrl + "user/userStatusUpdate/" + this.statusUpdateUserId).then(
            data=>{
              this.teachers.forEach((value)=>{
                if(value.userId === this.statusUpdateUserId){
                  value.active = !value.active;
                }
              })


            });
        }
 
        switchDashboard(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
            {
              size : 'sm',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
          let data = { 
            userId : this.userId,
            currentRole : this.currentRole
          }
      
          modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
          });    
        }
    
        goToAccountPage(){
          this.router.navigate(['user-account',this.userId])
        }
        
        getColor(){
            this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
            return this.loggedInUser.offices[0].theme + '!important';
          }
      
          logOut(): void {
            this.config.class = 'modal-md';
            const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
              {
                size : 'md',
                scrollable: true,
                windowClass: 'app-modal-window',
                keyboard: false
              });
            let data = {
             email : this.loggedInUser.email 
            }
        
            modalRef.componentInstance.fromParent = data;
              modalRef.result.then((result) => {
              }, (reason) => {
              });
        
            modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            });  
          }
      
          changePassword(): void {
            this.router.navigate(['/auth/change-password']).then();
          }
              
        reloadPage() {
          window.location.reload();
        }
      

}
