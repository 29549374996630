import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/BIZ_CRM/biz_crm_services/user.service";
import { EditCombinedParentTeacherComponent } from "src/app/course-management/combinedParentTeacher/edit-combined-parent-teacher/edit-combined-parent-teacher.component";
import { AddCombinedParentTeacher } from "src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher";
import { ImageEditPopupComponent } from "src/app/dashboards/combinedParentTeacher/image-edit-popup/image-edit-popup.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { FileServiceService } from "src/app/service/file-service.service";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-bcrm-user-profile",
  templateUrl: "./bcrm-user-profile.component.html",
  styleUrls: ["./bcrm-user-profile.component.scss"],
})
export class BcrmUserProfileComponent implements OnInit {
  showSpinner = true;

  currentRole = "TEACHER";
  userId!: string;
  theme: string = "";
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  loggedInUser: any;
  loggedUser: any;
  unsafeUrl: any;
  safeUrl: any;

  centered = false;
  disabled = false;
  unbounded = false;

  radius!: number;
  color!: string;

  public screenWidth: any;
  public screenHeight: any;

  isOfficeAdmin = false;
  info: any = {
    username: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    courseDescription: "",
    courseName: "",
    dob: "",
    emailId: "",
    endedDate: "",
    phone: "",
    startedDate: "",
    state: "",
    userId: "",
    userImage: "",
    userName: "",
    zipCode: "",

    roleList: [
      {
        id: "",
        roleName: "",
        displayName: "",
        dashboard: "",
        active: "",
      },
    ],
  };
  navbarTitle: any;

  constructor(
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private sharedDataService: SharedDataService,
    private imageService: FileServiceService,
    private sanitizer: DomSanitizer,
    private bcrmUserService: UserService,
    private ngbModalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.loggedUser = localStorage.getItem("loggedUser");
    this.loggedInUser = JSON.parse(this.loggedUser);

    this.userId = this.route.snapshot.params["userId"];
    this.getTeacherProfileDetails();
  }

  getTeacherProfileDetails() {
    this.bcrmUserService
      .getUserAccountInfo(environment.apiUrl + "user/getUserAccountInfo")
      .then((data) => {
        this.info = data;
        this.info.roleList.forEach((role: any) => {
          if (role.roleName === "CB_OFFICE_ADMIN") {
            this.isOfficeAdmin = true;
          }
        });
        this.imageService
          .getUserImage(this.info.userImage)
          .subscribe((data) => {
            this.unsafeUrl = URL.createObjectURL(data);
            this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(
              this.unsafeUrl
            );
            this.showSpinner = false;
          });
      });
  }

  whatsappNo: any = null;
  updateWhatsappNo() {
    this.showSpinner = true;
    this.bcrmUserService
      .getUserAccountInfo(
        environment.apiUrl +
          "botOffice/updateWhatsappNoAndCreateBotUser/" +
          this.whatsappNo
      )
      .then((data) => {
        if (data === true) {
          this.info.whatsappNumber = this.whatsappNo;
          this.toaster.success("Update Successful !");
          this.showSpinner = false;
        } else if (data === false) {
          this.toaster.error(
            "Customer with Whatsapp number : " +
              this.whatsappNo +
              " already exists !"
          );
          this.showSpinner = false;
        }
      });
  }

  closeResult!: string;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  editImage(userId: string, image: string) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ImageEditPopupComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: userId,
      imageName: image,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      this.getTeacherProfileDetails();
    });
  }

  editDetails(UserId: string) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      EditCombinedParentTeacherComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );

    let data = {
      parentUserId: UserId,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: AddCombinedParentTeacher) => {
        this.getTeacherProfileDetails();
      }
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  changePassword(): void {
    this.router.navigate(["/auth/change-password"]).then();
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  goBack() {
    this.router.navigate(["/dashboard/user"]).then();
  }

  reloadPage() {
    window.location.reload();
  }
}
