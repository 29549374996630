// Angular
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { ProgressComponent } from '../components/progress/progress.component';
import { AddOfficeComponent } from './add-office/add-office.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddBulkUserComponent } from './add-bulk-users/add-bulk-users.component';
import { AddUserSubscriptionComponent } from './add-user-subscription/add-user-subscription.component';
import { DashboardModule } from './dashboards/dashboard.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { FileUploadInfoComponent } from './file-upload-info/file-upload-info.component';
import { OfficeAccessControlsComponent } from './office-access-controls/office-access-controls.component';
import { AddSettingsComponent } from './settings/add-settings/add-settings.component';
import { EditSettingsComponent } from './settings/edit-settings/edit-settings.component';
import { AddLinkDefinitionsComponent } from './linkDefinitions/add-link-definitions/add-link-definitions.component';
import { EditLinkDefinitionsComponent } from './linkDefinitions/edit-link-definitions/edit-link-definitions.component';
import { EditOfficeLinkDefinitionsComponent } from './officeLinkDefinitions/edit-office-link-definitions/edit-office-link-definitions.component';
import { AddOfficeLinkDefinitionsComponent } from './officeLinkDefinitions/add-office-link-definitions/add-office-link-definitions.component';
import { SubOfficesComponent } from './org/sub-offices/sub-offices.component';
import { AddBotOfficeComponent } from './add-bot-office/add-bot-office.component';
// import { AddFormConfigComponent } from './formConfig/add-form-config/add-form-config.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DashboardModule,
		ColorPickerModule
	],
	providers: [
	],
	exports: [],
	declarations: [
		AddOfficeComponent, AddProductComponent,
		FileUploadComponent, ProgressComponent,
		AddBulkUserComponent,
		AddUserSubscriptionComponent,
		FileUploadInfoComponent,
		OfficeAccessControlsComponent,
		AddSettingsComponent,
		EditSettingsComponent,
		AddLinkDefinitionsComponent,
		EditLinkDefinitionsComponent,
		EditOfficeLinkDefinitionsComponent,
		AddOfficeLinkDefinitionsComponent,
		SubOfficesComponent,
		AddBotOfficeComponent,
		// AddFormConfigComponent
	]
})

export class ViewsModule {
	static forRoot(): ModuleWithProviders<ViewsModule> {
		return {
			ngModule: ViewsModule,
			providers: [

			]
		};
	}
}
