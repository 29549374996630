import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cb-office-admin-home",
  templateUrl: "./cb-office-admin-home.component.html",
  styleUrls: ["./cb-office-admin-home.component.scss"],
})
export class CbOfficeAdminHomeComponent implements OnInit {
  ngOnInit(): void {}
}
