
   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">{{fromParent.header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">

       
<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
  <thead class="tableheader">
    <tr>
      <th>Executive</th>
      <th>Customer</th>

      <th>Date</th>
      <th>Total Alt Qty</th>
      <th>Grand Total</th>
    </tr>
  </thead>
  <tbody>
    <tr class="td-height" *ngFor="let ccd of invoices">
      <td class="td-height">{{ccd.executiveName}}</td>
      <td class="td-height">{{ccd.customerName}}</td>

      <td class="td-height">{{ccd.invoiceDate}}</td>
      <td  style="text-align: right;" class="td-height">{{ccd.totalAlternateQuantity}}</td>

      <td style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
      
      


   
   
    </tr>    

  </tbody>
</table>
  </div>
      </div>
      </div>