<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" *ngIf="type !== 'order'" class="modal-title pull-left">Edit Invoice</h4>
    <h4  style="color: white;" *ngIf="type === 'order'" class="modal-title pull-left">Edit Order</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
       <form (ngSubmit)="onUpdate()">
                
            <div class="form-group row">
                <div class="col-md-4">
                <label for="customerName">Customer Name</label>
                <input class="form-control" readonly type="text" id="customerName" name="customerName" [(ngModel)]="invoice.customerName">
                </div>

                <div class="col-md-4">
                    <label class="required" for="invoiceDate">Date</label>
                    <input  #date="ngModel" required class="form-control" type="date" id="invoiceDate" name="invoiceDate" [(ngModel)]="invoice.invoiceDate">
                    <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Date is required</div>
                    </div>
        
                   
                    <div class="col-md-4">
                      <label for="orderStatus" >Order Status</label>
                      <input readonly class="form-control" type="text" id="orderStatus" name="orderStatus" [(ngModel)]="invoice.orderStatus">
                      </div>

             


            </div> 

           
  
              
<br>
           
            

            <div >
                <table  border="1" datatable [dtOptions]="invoiceOptions">
                    <thead>
                        <tr>
                            <th [style.background-color]='getColor()'>Product</th>
                            <th [style.background-color]='getColor()'>Qty</th>
                            <th [style.background-color]='getColor()'>Alt Qty</th>

                            <th [style.background-color]='getColor()'>Price per Qty</th>
                            <th [style.background-color]='getColor()'>Sub-Total</th>
                            <th [style.background-color]='getColor()'> Discount per Alt Qty</th>
                            <th [style.background-color]='getColor()'>Total Discount</th>
                            <th [style.background-color]='getColor()'>Total</th>
                            <th [style.background-color]='getColor()'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor = "let invDet of invDetails">
                            <td  *ngIf="invDet.type === 'invoice detail'">{{invDet.itemName}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.quantity}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.alternateQuantity}}</td>

                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.pricePerItem}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.totalPriceBeforeDiscount}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.perAltQuantityDiscountRate}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.totalDiscountAmount}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: right;">{{invDet.totalPrice}}</td>
                            <td  *ngIf="invDet.type === 'invoice detail'"style="text-align: center;">
                                <a style="cursor:pointer;padding-left: 1rem;"
                      (click)="edit(invDet.invoiceDetId)">
                      <img src="/assets/images/icons/edit.svg" width="20" height="20" alt="" loading="lazy"> </a> 
                               
                            </td>
                           
                        </tr>
                        <tr *ngIf="discounted === true">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="font-weight: bold; text-align: right;">Product-Total</td>
                            <td style="font-weight: bold; text-align: right;">{{invoice.totalBeforeDiscount}}</td>
                            <td></td>
                        </tr>
                        <tr *ngIf="discounted === true">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="font-weight: bold; text-align: right;">Discount</td>
                            <td style="font-weight: bold; text-align: right;">{{discount}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="font-weight: bold; text-align: right;">Total</td>
                            <td style="font-weight: bold; text-align: right;">{{invoice.totalAmount}}</td>
                            <td></td>
                        </tr>
                        <tr *ngFor = "let invDet of invDetails">
                            <td *ngIf="invDet.type === 'tax'"></td>
                            <td *ngIf="invDet.type === 'tax'"></td>
                            <td *ngIf="invDet.type === 'tax'"></td>
                            <td *ngIf="invDet.type === 'tax'"></td>
                            <td *ngIf="invDet.type === 'tax'"></td>
                            <td *ngIf="invDet.type === 'tax'"></td>
                            <td *ngIf="invDet.type === 'tax'" style="text-align: right;">{{invDet.taxName}}</td>
                            <td *ngIf="invDet.type === 'tax'" style="text-align: right;">{{invDet.taxAmount}}</td>
                            <td *ngIf="invDet.type === 'tax'"></td>

                        </tr>
                        <tr *ngFor = "let invDet of invDetails">
                            <td *ngIf="invDet.type === 'cess'"></td>
                            <td *ngIf="invDet.type === 'cess'"></td>
                            <td *ngIf="invDet.type === 'cess'"></td>
                            <td *ngIf="invDet.type === 'cess'"></td>
                            <td *ngIf="invDet.type === 'cess'"></td>
                            <td *ngIf="invDet.type === 'cess'"></td>
                            <td *ngIf="invDet.type === 'cess'" style="text-align: right;">{{invDet.taxName}}</td>
                            <td *ngIf="invDet.type === 'cess'" style="text-align: right;">{{invDet.taxAmount}}</td>
                            <td *ngIf="invDet.type === 'cess'"></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td style="font-weight: bold; text-align: right;">Total Tax</td>
                            <td style="font-weight: bold; text-align: right;">{{invoice.totalTax}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td style="font-weight: bold; text-align: right;">Total Cess</td>
                            <td style="font-weight: bold; text-align: right;">{{invoice.totalCess}}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td style="font-weight: bold; text-align: right;">Grand Total</td>
                            <td style="font-weight: bold; text-align: right;">{{invoice.grandTotal}}</td>
                            <td></td>
                        </tr>
                        <tr>
                           

                           
                        </tr>
                        
                    </tbody>
            </table>
        </div> <br> 

       
        
      <a style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
      (click)="openADDNewInvDetailModal(invoice.invoiceId)"  matTooltip="Add Product">
      <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
    </a>
<br>
 <br>      

 <a *ngIf="fromParent.role === 'BCRM_FM' || fromParent.role === 'BCRM_MAN' || fromParent.role === 'BCRM_SALES_COORDINATOR' || fromParent.role === 'BCRM_TM'"
 style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
    (click)="open(addDiscount)" matTooltip="Add Discount">
    <img src="/assets/images/icons/discount.png" width="25" height="25" alt="" loading="lazy">
  </a>
  
  <br>
<br>


  <a *ngIf="fromParent.role === 'BCRM_FM' || fromParent.role === 'BCRM_MAN' || fromParent.role === 'BCRM_SALES_COORDINATOR' || fromParent.role === 'BCRM_TM'"
   style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
      (click)="recomputeTaxes()" matTooltip="Compute tax">
      <img src="/assets/images/icons/tax.svg" width="28" height="28" alt="" loading="lazy">
    </a>

    <br>

<br>
        <div>
          <label for="invoiceRemarks">Order Remarks</label>
          <textarea   #remarks="ngModel"  maxlength="244" class="form-control" type="text" id="invoiceRemarks" name="invoiceRemarks" [(ngModel)]="invoice.invoiceRemarks"></textarea>
          <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
        </div>
            
        </form>
    </div>
    </div>
   </div>

   <div class="modal-footer">
    <button [disabled]="clicked"   type="submit" class="btn btn-sm btn-primary float-right" (click)="onUpdate(); clicked = true;">Update</button>
</div>

<ng-template #addDiscount let-modal>
    <div  [style.background-color]='getColor()' class="modal-header">
        <h4 *ngIf="discounted === false" class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Add Discount</h4>
        <h4 *ngIf="discounted === true"  class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Edit Discount</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>Enter Dicount Amount :</label>
      <input class="form-control" type="number" id="discount" 
      name="discount" [(ngModel)]="discount">


    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button *ngIf="discounted === true" type="button" class="btn btn-success" style="width:auto;" (click)="editDiscount();modal.dismiss('Cross click')">Edit</button>
      <button *ngIf="discounted === false" type="button" class="btn btn-success" style="width:auto;" (click)="addDiscountToInvoice();modal.dismiss('Cross click')">Add</button>
    </div>
  </ng-template>