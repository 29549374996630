 <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a  style="cursor: pointer;color: #0275d8;" (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Assignment</li>
    </ol>
  </nav>



 
           
              
          
      

      
      
 <div [style.display]="chooseAssignmentButton ? 'block' : 'none'" style="float: right; text-align: center;width: 100%;">
                


    <div class="row">
  
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
      <mat-card class="example-card mat-elevation-z12" style=" margin:  2em;" >      
       <br>  <br> <br>      
        <mat-card-content >
  
          <div class="row"  style=" text-align: center;width: 100%;margin-left: 15%;margin-right: 15%;">
  
            <div style="float: left; text-align: center; padding: 5%;">
              <mat-card-title>Back</mat-card-title>
            <a style="cursor:pointer;align-self: center;"
            onclick="history.back()" >
            <img src="/assets/images/icons/back-track.svg" width="45" height="45" alt="" loading="lazy">
            </a> 
          </div>
  
  
          <div style="float: right; text-align: center; padding: 5%;">
            <mat-card-title>New</mat-card-title>
            <a style="cursor:pointer;align-self: center;"
            (click)="addInstantAssignment();" >
            <img src="/assets/images/icons/plus.png" width="45" height="45" alt="" loading="lazy">
          </a> 
        </div>
  
        </div>

        <br> <br>

        <mat-card-title>Choose Assignment</mat-card-title>
        <div style="width: 100%;color: black;text-align: center;" >
          <a style="cursor:pointer;" (click)="chooseAssignment();">
             <img style="border-radius: 50%;" src="/assets/images/icons/dropdown.png" width="45" height="45" 
             alt="" loading="lazy">
          </a>
        </div>


        </mat-card-content> 
        <br> <br> <br>
      </mat-card>
  
      
    </div>
  
        <!-- <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
          
          <mat-card class="example-card mat-elevation-z12" style=" margin:  2%;">
  
          
  

            <br>
           
              <mat-card-subtitle></mat-card-subtitle>
          
           
            
          </mat-card>
  
        
      </div> -->
    </div>



            </div>
      
            <div [style.display]="!chooseAssignmentButton ? 'block' : 'none'">
            
            <div class="content" style="margin-right: 2.5%;margin-left: 2.5%;" >
             
              <div class="card-group row">
            
                 <div class="card col-lg-6 mat-elevation-z12 Activity-Card" style="border-radius: 20px;">
                  
                 
                  <div  style="width: 100%; padding: 2%;">
                    <mat-icon (click)="showSelectionCard();" style="float: left; cursor: pointer; color: black; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

                     <a  matTooltip="Swap Assignment" style="cursor:pointer; float: right; margin-left: 70%;" 
                      (click)="chooseAssignment();">
                      <img alt="" height="35" loading="lazy" src="/assets/images/icons/changeActivity1.png" width="35">
                    </a>
                  </div>
                 
            
                  <div style="text-align: center;width: 100%;">
                    
            <img class="Activity-Image" [src]="safeUrl" height="75" width="75" alt="Card image cap">
                 
                  </div>
                  <br>
            
                  <div  style="text-align: center; width: 100%;">
                    <h6 style="color: black;" class="card-title">{{info.activityPlannerName}}</h6>
                    <h6 *ngIf="info.spontaneous === true" class="card-title">(My Assignment)</h6>
  
    <button (click)="startAssignment();" *ngIf="info.activityCompletionStatus === false && info.assignmentActiveStatus === false" style="width: auto;margin-bottom: 3%;" class="btn btn-info" >
                      Start Assignment</button>
                   
                      <a style="color: rgb(26, 116, 26); font-weight: bold;" *ngIf="info.activityCompletionStatus === false && info.assignmentActiveStatus === true">Active
                      </a>

                      <br *ngIf="info.activityCompletionStatus === false && info.assignmentActiveStatus === true">
                      <br *ngIf="info.activityCompletionStatus === false && info.assignmentActiveStatus === true">

                    <button (click)="markAssignmentAsComplete();" *ngIf="info.activityCompletionStatus === false && info.assignmentActiveStatus === true" style="width: auto;margin-bottom: 3%;" class="btn btn-info" >
                     Mark as Complete</button>

                     <br>

                     <button (click)="editActiveAssignment();" *ngIf="info.activityCompletionStatus === false && info.assignmentActiveStatus === true" style="width: auto;margin-bottom: 3%;" class="btn btn-success" >
                      Edit Active Assignment</button>
             
                     <a style="color: rgb(26, 116, 26); font-weight: bold;" *ngIf="info.activityCompletionStatus === true">Completed
                      <img style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
                    </a>
           <br *ngIf="info.activityCompletionStatus === true">
           <br *ngIf="info.activityCompletionStatus === true">
          
            <button (click)="unmarkAssignmentAsComplete();" *ngIf="info.activityCompletionStatus === true"  matTooltip="Un-Mark Assignment as Complete" style="width: auto;margin-bottom: 3%;" class="btn btn-info" >
              Un-Mark as Complete</button>
              <br>
              
            
                    <p class="card-text">{{info.activityPlannerDescription}}</p>
                  </div>
                  <div class="card-body">
                     
                  <div *ngIf="collapsedState" [@fadeInOut]>
                   
                    <button *ngIf="info.spontaneous === true" (click)="open(confirmAssignmentDeactivation)" style="width: auto;margin: 2%;" class="btn btn-danger">Deactivate</button>
<br>
                   
                    <button *ngIf="info.spontaneous === true" (click)="editactivityPlanner();" style="width: auto;margin: 2%;" class="btn btn-success">Edit Assignment</button>
                    <button *ngIf="info.spontaneous === true"  style="margin: 2px;width: auto;"  class="btn btn-info" (click)="addVideoModal(info.activityPlannerId)">Add Video Links</button> 

<br>
                    <button  *ngIf="info.spontaneous === true" (click)="addActivityQuestionsModal();" style="width: auto;margin: 2%;" class="btn btn-primary">Add Questions</button>
                    <button *ngIf="info.spontaneous === true" (click)="viewActivityQuestionsModal();" style="width: auto;margin: 2%;" class="btn btn-secondary">View Questions</button>
<br>

                    <h3 style="color: black;" *ngIf="info.activityVideoLinkList === undefined || info.activityVideoLinkList.length === 0">
                      No Videos to display !
                    </h3>
                      <mat-vertical-stepper>
            
                        <div class="stepper-class">
            
                        <mat-step [editable]="true" [completed]="true"  *ngFor="let video of info.activityVideoLinkList">
                          <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                            <p></p>
            
                            <div class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                            </div>
            
                              <br>
                    
                              {{video.actVideoLinkDescription}}
                              <br>

                              <button *ngIf="info.spontaneous === true"  style="margin: 2px;width: auto;" class="btn btn-dark" (click)="editVideo(video.actVideoLinkId)">Edit Video</button> 

                              
                        </mat-step>
                        
                    </div>
            
                    
                      </mat-vertical-stepper>
            
                      
                  
                    </div>
            
                    
                  <button mat-button color="primary" (click)="collapseActivityCardContent(collapsedState)">
                      {{collapsedState ? 'Close' : 'More'}}</button>
                  </div> 
              </div>
            
              <div class="col-lg-6" style="margin-top: 2em;">
              <div class="header" [style.background-color]='getColor()' style="margin: 0%;">
                <h3>Students</h3>
                  </div>
              <div class="accor_dion " style="background-color: transparent;">
                <mat-accordion class="example-headers-align" *ngFor="let student of students">
                  <mat-expansion-panel [expanded]="step === student.studentId" (opened)="setStep(student.studentId)" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <img class="student-image" alt="" height="30" loading="lazy" [src]="student.safeStudentUrl" width="30">
            
                        {{student.studentName}}
                      </mat-panel-title>
                     
                        
              <!-- <a *ngIf="student.studentActivityHistoryIndicator == 0" style="color: rgb(179, 20, 20);"><img class="student-image" alt="" height="25" loading="lazy" src="assets/images/icons/nonStarted.png" width="25">0 Attempts</a>
              <a *ngIf="student.studentActivityHistoryIndicator == 1" style="color: rgb(179, 140, 14);"><img class="student-image" alt="" height="25" loading="lazy" src="assets/images/icons/inProgress.png" width="25">In-Progress</a>
              <a *ngIf="student.studentActivityHistoryIndicator == 2" style="color: green;"><img class="student-image" alt="" height="25" loading="lazy" src="assets/images/icons/complete.png" width="25">Completed</a>
              <br> -->
                        
                    </mat-expansion-panel-header>
                    <!-- <button *ngIf="student.studentActivityHistoryIndicator != 2 && student.studentActivityHistoryIndicator != 0" style="float: left; width: auto;" (click)="MarkActivityCompletedForStudent(student.studentId)" class="btn btn-info" >Mark As Completed</button>
                    <button *ngIf="student.studentActivityHistoryIndicator == 2 " style="float: left; width: auto;" (click)="UnMarkActivityCompletedForStudent(student.studentId)" class="btn btn-info" >Mark As In-Progress</button>
                    <button class="btn btn-light" style="color: black;background-color: white;width: auto;">Attempts : {{student.studentAttempts}}</button> 
             -->
             <button *ngIf="student.assignmentCompletionStatus === true" style="float: left;width: auto; color: white; background-color: rgb(105, 15, 15);"
                     class="btn btn-info" (click)="viewAssignmentSubmissions(student.studentId)">View Submissions</button>
                  
                    <button *ngIf="student.assignmentCompletionStatus === true" style="float: right; color: white; background-color: rgb(105, 15, 15);"
                     class="btn btn-info" (click)="onEvaluate(student.studentId, student.safeStudentUrl, student.studentName)">Evaluate</button>
                  </mat-expansion-panel>
                
                </mat-accordion>
              </div>
            </div>
              </div>
            
            </div>
            </div>
            
            
            <ng-template #confirmAssignmentDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to Deactivate Assignment ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-outline-dark" (click)="deactivateAssignment();modal.dismiss('Cross click')" >Deactivate</button>
              </div>
            </ng-template>