import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Announcement } from 'src/app/BIZ_CRM/biz_crm_classes/announcement';
import { AnnouncementService } from 'src/app/BIZ_CRM/biz_crm_services/announcement.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-announcement',
  templateUrl: './edit-announcement.component.html',
  styleUrls: ['./edit-announcement.component.scss']
})
export class EditAnnouncementComponent implements OnInit {

  constructor(private activeModal : NgbActiveModal,
    private announcementService : AnnouncementService,
    private datePipe : DatePipe,
    private sharedDataService : SharedDataService,
    private toastrService : ToastrService) { }

    @Output() passEntry : EventEmitter<any> = new EventEmitter();
    @Input() fromParent : any;

  announcement : Announcement = new Announcement();
  clicked = false;
  loggedInUser : any; 
  theme : any;

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.announcement = this.fromParent.announcement;
    let sd = this.announcement.startDate;
    let startDate = new Date(sd);      
    this.announcement.startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');

    let ed = this.announcement.endDate;
    let endDate = new Date(ed);      
    this.announcement.endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    
  }

  onSubmit(){
    //authorized
    this.announcementService.updateAnnouncement(environment.apiUrl + "announcements/update", this.announcement).then(
      data=>{
        this.passEntry.emit("success");
        this.toastrService.success("Announcement created successfully");
        this.activeModal.close();
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
