<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Product</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div> 

 
 
<div class="modal-body">
 <div class="card">
  <div class="card-body">
    <form (ngSubmit)="onSubmit()" #invoiceDetForm="ngForm"> 

        <div>
            <label hidden for="invoiceDetId">invoiceDetId</label>
            <input hidden type="text" id="invoiceDetId" name="invoiceDetId" [(ngModel)]="invoiceDet.invoiceDetId">
        </div>










        <div class="form-group row">

            <div class="col-md-6">
            <label class="required"  for="fkProductId">Course / Product</label>
                <select #product="ngModel" (change)="onChange($event)" required class="form-control" [(ngModel)]="invoiceDet.fkProductId" id="fkProductId" name="fkProductId">
                    <option value="" disabled >Choose Course / Product</option>
                    <option  *ngFor="let product of products0"  [ngValue]="product.productId">{{product.productName}}</option>
                </select>
                <div class="alert alert-danger" *ngIf="product.touched && !product.valid">Select Product</div>
            </div>

            <div class="col-md-6">
            <label  class="required" for="itemName">Item Name</label>
            <input readonly  #name="ngModel" required class="form-control" type="text" id="itemName" name="itemName" [(ngModel)]="invoiceDet.itemName">
            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
        </div>

            
        </div>

        <div class="form-group row">

            <div class="col-md-6">
            <label class="required"  for="quantity">Quantity</label>
            <input (change)="onFileSelected($event)" #qty="ngModel" required class="form-control" type="number" id="quantity" name="quantity" [(ngModel)]="invoiceDet.quantity">
            <div class="alert alert-danger" *ngIf="qty.touched && !qty.valid">Enter the Quantity</div>
            </div>

            <div class="col-md-6">
            <label class="required"  for="pricePerItem">Price Per Item</label>
            <input (change)="onFileSelected($event)" #item="ngModel" required class="form-control" type="number" id="pricePerItem" name="pricePerItem" [(ngModel)]="invoiceDet.pricePerItem">
            <div class="alert alert-danger" *ngIf="item.touched && !item.valid">Enter the Price</div>
            </div>

            
        </div>


        <div class="form-group row">
            <div class="col-md-6">
            <label for="deliveryAddress">Delivery Address</label>
            <input class="form-control" type="text" id="deliveryAddress" name="deliveryAddress" [(ngModel)]="invoiceDet.deliveryAddress">
            </div>

            <div class="col-md-6">
            <label class="required"  for="totalPrice">Total Price</label>
            <input readonly  #tp="ngModel" required class="form-control" type="number" id="totalPrice" name="totalPrice" [(ngModel)]="invoiceDet.totalPrice">
            <div class="alert alert-danger" *ngIf="tp.touched && !tp.valid">Enter the Total Price</div>
            </div>
           
        </div>
        
            <div>
            <label for="description">Description</label>
            <textarea #remarks="ngModel" maxlength="244" class="form-control" type="text" id="description" name="description" [(ngModel)]="invoiceDet.description"></textarea>
            <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
            </div>
  
    </form>
</div>
</div>
</div>

<div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!invoiceDetForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
  </div>