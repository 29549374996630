<nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 

    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
  </nav>

  <!-- TOP ONE -->
  <div class="p-4">
  
    
    <div class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Search Student / Parent
        </span>


        <!-- MORE INFO -->
        <a  *ngIf="officeObject.oa_CRMAdmin === true" style="cursor:pointer;" 
        (click)="open(moreInfoModal);" class="float-right">
          <img  src="/assets/images/icons/moreInfo.svg" 
          width="25" height="25" alt="" loading="lazy">
        </a>

       
        <!-- ADD USER -->
        <a  *ngIf="officeObject.oa_CRMAdmin === false" style="cursor:pointer;" 
        (click)="open(emailSearch);" class="float-right">
          <img  src="/assets/images/icons/plus-circle.svg" 
          width="25" height="25" alt="" loading="lazy">
        </a>

        <!-- FILE UPLOAD -->
        <a  *ngIf="officeObject.leadConversionType==='Customer And User'" 
        style="cursor:pointer;margin-right: 2%;" matTooltip="File upload" 
        (click)="studentCreationFileUpload();" class="float-right">
          <img style="color: white;" src="/assets/images/icons/fileUpload.svg" 
          width="35" height="35" alt="" loading="lazy">
        </a>
 
        <!-- USER SEARCH -->
        <a style="cursor:pointer;margin-right: 2%;"
         (click)="userSearchModal();" class="float-right">
          <img style="color: white;" src="/assets/images/icons/searchIcon.svg"
           width="35" height="35" alt="" loading="lazy">
        </a>

        <!-- <button class="btn btn-info" style="width: auto;float: right; margin-right: 0.5%;" (click)="addUser();">Add User</button> -->

        <button *ngIf="officeObject.oa_ParentMentor === true" class="btn btn-info" style="width: auto;float: right; margin-right: 3%;" (click)="addCombinedPTCUser();">Add Parent-Mentor</button>

       

    

      </div> 
    </div>
    <div class="card" >
      <div class="card-body table-responsive">
      
        


        <div class="content"  *ngIf="classExists" >
          <div fxLayout="row wrap" fxLayoutGap="16px grid">

        
        
              <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="33%" *ngFor="let user of users">
        
                <mat-card class="example-card mat-elevation-z12">
  
                  <div style="text-align: center;border-radius: 50%;">
                    <img style="border-radius: 50%;" [src]="user.safeUrl" height="60" width="60" alt="...">
                    </div>
  
                    <br>
                
                    <mat-card-subtitle style="color: black;">{{user.firstName}} {{user.lastName}}</mat-card-subtitle>
                  
                    <mat-card-subtitle>{{user.email}}</mat-card-subtitle>

                    <mat-card-subtitle>({{user.roles}})</mat-card-subtitle>

  <div style="text-align: center;">

    <button *ngIf="user.isParent === true || user.isParentMentor === true" style="margin: 2%; width: auto;" (click)="openProfileModalForParent(user.userId);" class="btn btn-info mr-2">Parent Profile</button>
    <button *ngIf="user.isStudent === true" style="margin: 2%; width: auto;" (click)="openProfileModalForStudent(user.userId, user.parentId);" class="btn btn-info mr-2">Student Profile</button>
    <button *ngIf="user.isChildStudent === true" style="margin: 2%; width: auto;" (click)="openProfileModalForChildStudent(user.userId, user.parentId);" class="btn btn-info mr-2">Student Profile</button>

    <br>
                    <button style="margin: 2%;" *ngIf="user.status!== null && user.status" type="button"
                    class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation);statusUpdateUserId=user.userId;">Deactivate</button>
                  <button style="margin: 2%;" *ngIf="user.status !== null && !user.status" type="button"
                    class="btn btn-success mr-2" (click)="open(confirmActivation);statusUpdateUserId=user.userId;">Activate</button>
                  
         <br>
         <!-- <button style="margin: 2%; width: auto;" class="btn btn-secondary" *ngIf="user.isStudent === true && user.parentId === null" (click)="addParent(user.userId)">Add Parent</button>  -->


                
 
                  </div>
                </mat-card>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  

</div>


  <div class="p-4">
  
    
    <div class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          View Student / Parent
        </span>

        <!-- <mat-form-field style="background-color: white;float: right;" class="mat-select-value-text" appearance="fill">
          <mat-label style="color: black;">Select an option</mat-label>
          <mat-select  style="color: white;" disableRipple (selectionChange)="valueChange($event.value)">
            <mat-option value="2"> Students</mat-option>
            <mat-option value="3"> Parents</mat-option>
          </mat-select>
        </mat-form-field> -->
       
       

        <div style="float: right;">
          <select class="form-select form-select-sm"  [(ngModel)]="selectedOption" (change)="onChange($event)" aria-label=".form-select-sm example">
            <option value="0" selected>Choose an option</option>
            <option value="2">Students</option>
            <option value="3">Parents</option>
         
          </select>
        </div>
       

    

      </div> 
    </div>
    
    <div class="card" >
      <div class="card-body table-responsive">
      
        <table *ngIf="classExists2" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="classOptions" >
          <thead class="tableheader">
            <tr>
              <th style="color: rgb(76, 71, 71);font-weight: bold;">#</th>
              <th style="color: rgb(76, 71, 71);font-weight: bold;">Name</th>
              <th style="color: rgb(76, 71, 71);font-weight: bold;">Email</th>
              <th style="color: rgb(76, 71, 71);font-weight: bold;">Action</th>
            </tr>
          </thead> 
          <tbody *ngIf="tableUsersExist === true">
            <tr class="td-height" *ngFor="let user of usersForTable, let i = index">
              <td  style="color: rgb(76, 71, 71);margin:1%" class="td-height">{{i+1}}</td>
              <td  style="color: rgb(76, 71, 71);margin:1%" class="td-height">{{user.firstName}} {{user.lastName}}</td>
              <td  style="color: rgb(76, 71, 71);margin:1%">{{user.email}}</td>
              <td  style="color: rgb(76, 71, 71);" class="td-height" style="text-align: center;margin: 0%;">
               
                <div class="row" style="text-align: center;margin: 0%;display: block;
                margin: auto;">
                <button style="margin: 1%;" *ngIf="user.active!== null && user.active" type="button"
                class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation);statusUpdateUserId=user.userId;">Deactivate</button>
              <button style="margin: 1%;" *ngIf="user.active !== null && !user.active" type="button"
                class="btn btn-success mr-2" (click)="open(confirmActivation);statusUpdateUserId=user.userId;">Activate</button>
              
                
                <button *ngIf="showParents === true"style="margin: 1%; width: auto;"
                 (click)="openProfileModalForParent(user.userId);"
                  class="btn btn-secondary mr-2">Profile</button>
                <button *ngIf="showStudents === true"style="margin: 1%; width: auto;"
                 (click)="openProfileModalForStudent(user.userId, user.parentId);"
                  class="btn btn-secondary mr-2">Profile</button>

              </div>
              </td>
             
            </tr>
          </tbody>
        </table>


      </div>
    </div>
  

</div>


<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate user ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="updateUserStatus();modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template> 

<ng-template #moreInfoModal let-modal>
  <div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">CRM Module</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="color: black;">
    You can find Add or Update users, user-subscritions and other functionalities in the CRM_DASHBOARD !
  </div> 
</ng-template> 

<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate user ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="updateUserStatus();modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>

<ng-template #emailSearch let-modal>
  <div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">New User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


    <form #eForm="ngForm" *ngIf="searchInput===true">
      <div class="col-md-12">
        <label class="required" for="search">User Email</label>
      <input class="form-control" required email type="email" [(ngModel)]="searchValue" 
      placeholder="Enter name/email/phone" name="search">
    <br>
      <div style="text-align:center;">
      <button style="width: auto;color: black;" [disabled]="!eForm.valid" 
      (click)="searchFoUserByEmail();" class="btn btn-info">
        Proceed
        <!-- <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
        width="27" height="27" alt="" loading="lazy"> -->
      </button>
    </div>
    </div>
      </form>

      <div *ngIf="searchInput===false">

        Email you entered is already registered !  <br>
        <br>
        <br>
        <br>

        <button style="width: auto;float: right;" class="btn btn-primary"
         (click)="searchInput=true;searchValue=null;">Use different email</button>

      </div>


  </div> 
</ng-template>