import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddActivityQuestionsComponent } from 'src/app/course-management/activity-questions/add-activity-questions/add-activity-questions.component';
import { ViewActivityQuestionsComponent } from 'src/app/course-management/activity-questions/view-activity-questions/view-activity-questions.component';
import { UpdateActivityPlannerComponent } from 'src/app/course-management/activityPlanner/update-activity-planner/update-activity-planner.component';
import { AddActivityVideoComponent } from 'src/app/course-management/activityVideo/add-activity-video/add-activity-video.component';
import { EditActivityVideoComponent } from 'src/app/course-management/activityVideo/edit-activity-video/edit-activity-video.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { StudentService } from 'src/app/service/student.service';
import { SubjectService } from 'src/app/service/subject.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { ShowQuestionsModalComponent } from 'src/app/tryOutFolder/questionsModal/show-questions-modal/show-questions-modal.component';
import { environment } from 'src/environments/environment';
import { AddInstantAssignmentModalComponent } from '../add-instant-assignment-modal/add-instant-assignment-modal.component';
import { ChooseAssignmentModalComponent } from '../choose-assignment-modal/choose-assignment-modal.component';
import { EditActiveAssignmentComponent } from '../edit-active-assignment/edit-active-assignment.component';
import { StartAssignmentModalComponent } from '../start-assignment-modal/start-assignment-modal.component';
import { ViewStudentAssignmentSubmissionModalComponent } from '../view-student-assignment-submission-modal/view-student-assignment-submission-modal.component';

@Component({
  selector: 'app-assignment-dashboard',
  templateUrl: './assignment-dashboard.component.html',
  styleUrls: ['./assignment-dashboard.component.scss']
})
export class AssignmentDashboardComponent implements OnInit {

  showSpinner : boolean = true;
  chooseAssignmentButton = true;
  currentRole = 'TEACHER';
  public screenWidth: any;
  public screenHeight: any;
  loggedUser : any;
  loggedInUser : any;
  userId!: string;
  userName : any;
  theme: string = '';
  subjectId : any;
  teacherId : any;
  classId : any;
  officeId : any;
  navbarTitle: any;
  collapsedState : boolean = false ;
  deactivateApId : any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

    step = 0;
    iconP : string = 'account_circle';
    iconC: string = 'task_alt';
    icon : boolean = true;
  closeResult!: string;
  
    setStep(index: number) {
      this.step = index;
    }
  
    nextStep() {
      this.step++;
    }
  
    prevStep() {
      this.step--;
    }

  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  unsafeUrl : any;
  safeUrl : any;
  safeStudentUrl : any;
  unsafeStudentUrl : any;
  currentActivityPlannerId : any;
  info : any = {
    activityPlannerDescription: "",
    activityPlannerId: "",
    activityPlannerImage: "",
    activityPlannerName: "",
    activityPlannerOrder: "",
    activityPlannerStatus: "",
    fkSubjectId: "",
    studentCount: "",
    activityCompletionStatus : "",
    assignmentActiveStatus : "",
    spontaneous : "",
    teacherPlannerId : "",

    activityVideoLinkList : [{
                              actVideoLinkId: "",
                              actVideoLinkName: "",
                              actVideoLink: "",
                              actVideoLinkDescription: "",
                              fkActivityPlannerId: "",
                              actVideoLinkStatus: "",
                              urlSafe: ""
                            }]
}

students : any[] = [{
                        studentId: '',
                        fkClass: '',
                        fkUserRoleOffice: '',
                        studentStatus: '',
                        studentName: '',
                        studentImage : '',
                        studentActivityHistoryIndicator : '',
                        studentAttempts : '',
                        safeStudentUrl : '',
                        assignmentCompletionStatus : ''
                   }]

  constructor(private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              private logoutService : LogoutService,
              public router: Router,
              public route : ActivatedRoute,
              private teacherService : TeacherService,
              private studentService : StudentService,
              private activityPlannerService : ActivityPlannerService,
              private toaster : ToastrService,
              private subjectService : SubjectService,
              private officeService : OfficesService ) { } 

  ngOnInit(): void {

    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.subjectId = this.route.snapshot.params['subjectId'];
    this.teacherId = this.route.snapshot.params['teacherId'];
    this.classId = this.route.snapshot.params['classId'];

    this.loggedUser = localStorage.getItem('loggedUser'); 
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;
    this.officeId = this.loggedInUser.offices[0].id;

    this.showSpinner = false;
  }

  showSelectionCard(){
    this.chooseAssignmentButton = true;
  }
  viewAssignmentSubmissions(studentId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewStudentAssignmentSubmissionModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: this.info.activityPlannerId,
      studentId : studentId,
      officeId : this.officeId

      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  }); 
  }

  // authorized
  addVideoModal(activityPlannerId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddActivityVideoComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      activityPlannerId : activityPlannerId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.showSpinner = true;
      this.fetchAssessment(this.currentActivityPlannerId);
      this.fetchAssignmentWithStudentsForAssessment(this.currentActivityPlannerId);
    });    
  }
  
  // authorized
  editVideo(videoId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditActivityVideoComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      videoId : videoId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.showSpinner = true;
      this.fetchAssessment(this.currentActivityPlannerId);
      this.fetchAssignmentWithStudentsForAssessment(this.currentActivityPlannerId);
    });    
  }

  onEvaluate( studentId : string, safeImageUrl : any, studentName : any){
    this.onEvaluate2(this.info.activityPlannerId, studentId, this.teacherId , safeImageUrl, studentName);
    }

  onEvaluate2(activityPlannerId : string, fkStudentId : string, fkTeacherId : string, safeImageUrl : any, studentName : any ){

  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( ShowQuestionsModalComponent,

    {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
  let data = {
    activityPlannerId: activityPlannerId ,
    fkStudentId : fkStudentId,
    fkTeacherId : fkTeacherId,
    safeImageUrl : safeImageUrl,
    studentName : studentName
      }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      }); 
  modalRef.componentInstance.passEntry.subscribe((receivedEntry : "") => {
  });

  } 

  // authorized
  addActivityQuestionsModal(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddActivityQuestionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: this.info.activityPlannerId 
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  }); 
  }

   // authorized
  viewActivityQuestionsModal(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewActivityQuestionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: this.info.activityPlannerId 
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  }); 
  }

  // authorized
  editactivityPlanner(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateActivityPlannerComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: this.info.activityPlannerId
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    this.showSpinner = true;
    this.fetchAssessment(this.currentActivityPlannerId);
    this.fetchAssignmentWithStudentsForAssessment(this.currentActivityPlannerId);
  }); 
  }

   // authorized
  markAssignmentAsComplete(){
    this.activityPlannerService.markActivityActivityAsComplete(environment.apiUrl + "activityCompletion/markActivityActivityAsComplete/" + this.info.activityPlannerId + "/" + this.teacherId).then(
      data=>{
        this.info.activityCompletionStatus = true;
        this.info.assignmentActiveStatus = false;
      });
  }

   // authorized
  unmarkAssignmentAsComplete(){
    this.activityPlannerService.unMarkActivityAsComplete(environment.apiUrl + "activityCompletion/unMarkActivityAsComplete/" + this.info.activityPlannerId + "/" + this.teacherId).then(
      data=>{
        this.info.activityCompletionStatus = false;
        this.info.assignmentActiveStatus = true;
      });
  }

  editActiveAssignment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditActiveAssignmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      teacherPlannerId : this.info.teacherPlannerId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.info.assignmentActiveStatus = true;
    }); 
  }

  // authorized
  startAssignment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( StartAssignmentModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      teacherId : this.teacherId,
      activityPlannerId : this.info.activityPlannerId,
      assignmentName : this.info.activityPlannerName
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.info.assignmentActiveStatus = true;
      this.fetchAssessment(this.currentActivityPlannerId);
      this.fetchAssignmentWithStudentsForAssessment(this.currentActivityPlannerId);
    }); 
  }

  // authorized
  addInstantAssignment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddInstantAssignmentModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      teacherId : this.teacherId,
      subjectId : this.subjectId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.showSpinner = true;
      this.fetchAssessment(receivedEntry);
      this.fetchAssignmentWithStudentsForAssessment(receivedEntry);
    }); 
  }

  // authorized
  chooseAssignment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ChooseAssignmentModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      teacherId  : this.teacherId,
      subjectId : this.subjectId,
      classId : this.classId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.chooseAssignmentButton = false;
      this.showSpinner = true;
      this.currentActivityPlannerId = receivedEntry;
      this.fetchAssessment(receivedEntry);
      this.fetchAssignmentWithStudentsForAssessment(receivedEntry);
    });    
  }

 // authorized
  fetchAssessment(assignmentId : any){
    this.activityPlannerService.getActivityPlannerForSwapForTeacher(environment.apiUrl + "activityPlanner/getActivityPlannerForSwapForTeacher/" + assignmentId + "/" + this.teacherId).then(
      data=>{
        
          this.info = data;

          if(this.info.activityVideoLinkList === undefined || this.info.activityVideoLinkList.length === 0){
            this.showSpinner = false;
          }

          let count : number = 0;
          this.info.activityVideoLinkList.forEach((val : any)=>{
            count++;
          this.url =  val.actVideoLink;
          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          val.urlSafe = this.urlSafe
   

          if(count=== this.info.activityVideoLinkList.length){
            this.showSpinner = false;
          }

        });

          this.imageService.getActivityPlannerImage(this.info.activityPlannerImage).subscribe(
            res=>{
              this.unsafeUrl = URL.createObjectURL(res);
              this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
            });            
      });
  }

// authorized
  fetchAssignmentWithStudentsForAssessment(assignmentId : any){
    this.studentService.getStudentsForAssignmentEvaluation(environment.apiUrl +  "student/getStudentsForAssignmentEvaluation/" + this.classId + "/" + assignmentId).then(
      data=>{
        this.students = [];
        this.students.pop();

        for(var index in data){
          this.students.push( { 'studentId' : data[index].studentId, 
                                'fkClass' : data[index].fkClass,
                                'fkUserRoleOffice' : data[index].fkUserRoleOffice,
                                'studentStatus' : data[index].studentStatus,
                                'studentName' : data[index].studentName,
                                'studentImage' : data[index].studentImage,
                                'studentActivityHistoryIndicator' : data[index].studentActivityHistoryIndicator,
                                'studentAttempts' : data[index].studentAttempts,
                                'safeStudentUrl' : this.safeStudentUrl,                               
                                'assignmentCompletionStatus'  : data[index].assignmentCompletionStatus
                              });
}

if(this.students === undefined || this.students.length === 0){
  this.showSpinner = false;
}

let count : number = 0;
this.students.forEach( (value) => {
  count++;
    this.imageService.getUserImage(value.studentImage).subscribe(
      data=>{
        this.unsafeStudentUrl = URL.createObjectURL(data);
        this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
  
        value.safeStudentUrl = this.safeStudentUrl;

        if(count===this.students.length){
          setTimeout(()=>{  
            this.chooseAssignmentButton = false;
            this.showSpinner = false;
       }, 1500); }

       });  });
       setTimeout(()=>{  
        // this.toaster.success('Assignment Fetched Successfully');
   }, 1500);

      });
       
  }

  collapseActivityCardContent(state : boolean){
    if(state === true){
      this.collapsedState = false;
    } else if(state === false){
      this.collapsedState = true;
    }
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      userId : this.userId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
  

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  deactivateAssignment(){
    
    this.activityPlannerService.deactivateAssignment(this.info.activityPlannerId).subscribe(
      data=>{
        this.showSelectionCard();
      this.toaster.success('Assignment deactivated successfully');
      });
  }
  
  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  goBack() {
    this.router.navigate(['/dashboard/user']).then();
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }

  goToHomePage(){
    this.router.navigate(['/dashboard/teacher']);
  }

  reloadPage() {
    window.location.reload();
  }

}
