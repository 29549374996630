import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotLanguage } from 'src/app/BOT_CODE/bot_classes/bot-language';
import { BotSuperadminServiceService } from 'src/app/BOT_CODE/bot_services/bot-superadmin--service.service';
import { BotOfficeLanguage } from 'src/app/BOT_CODE/bot_classes/bot-office-language';

@Component({
  selector: 'app-set-up',
  templateUrl: './set-up.component.html',
  styleUrls: ['./set-up.component.scss']
})
export class SetUpComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any; 

  link = "https://zoti-api-preview.omnileadz.com/botResponse/ext/zoti/automated-res";


  constructor(private sharedDataService: SharedDataService,
    private botService : BotSuperadminServiceService,
    private botResponseService : BotResponseServiceService,
    public activeModal: NgbActiveModal, private ngbModalService : NgbModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    context : any;
    token : any;

    wcaPhoneNumberID:any;
    wcaPermanentToken:any;
    whatsappNumber:any;
    botLanguages : BotLanguage[] = [];

  ngOnInit(): void {

    
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

      if(this.context === "setupCallbackUrl"){
       this.token = this.fromParent.token;
      }

      if(this.context==="savePhoneNumberID"){
        this.wcaPhoneNumberID = this.fromParent.wcaPhoneNumberID;
      }

      if(this.context==="createPermanentToken"){
        this.wcaPermanentToken = this.fromParent.wcaPermanentToken;
      }

      if(this.context==="setupOfficeWhatsappNumber"){
        this.whatsappNumber = this.fromParent.whatsappNumber;        
      }

      if(this.context==="addLanguagesToOffice"){

        this.defLangId = "chooselanguage";
        this.botService.getAllLanguages(environment.apiUrl + "botConvo/getAllLanguages").then(
          res=>{
            let tempLangs  : BotLanguage[] = [];
            tempLangs = res;

            let botOfficeLanguagesE : BotOfficeLanguage[] = [];
            botOfficeLanguagesE = this.fromParent.botOfficeLanguages;

            if(botOfficeLanguagesE !== null && botOfficeLanguagesE.length > 0){
              let existingLangs : string[] = [];

              botOfficeLanguagesE.forEach((langE)=>{
                existingLangs.push(langE.fkBotLanguageName);
              });
  
              
              tempLangs.forEach((tLang)=>{
                if(existingLangs.includes(tLang.language)===false){
                  this.botLanguages.push(tLang);
                }
              });
            }else{
              this.botLanguages = tempLangs;
            }


          });
      }

      if(this.context==="setOfficeDefaultLanguage"){

        this.defLangId = "chooselanguage";
        this.botService.getAllLanguages(environment.apiUrl + "botConvo/getAllLanguages").then(
          res=>{
            this.botLanguages = res;
          });
      }
  }
  
  defLangId:any;
  defLangName:any;
  defLangSet:any;

  setOfficeDefaultLanguage(){
this.botService.setOfficeDefaultLanguage(environment.apiUrl + "botConvo/setOfficeDefaultLanguage/" + this.defLangId).then(
  res=>{
    this.toastr.success("Default Language Set !");
    this.passEntry.emit(this.defLangId);
    this.activeModal.close();
  });
  }

  addLanguagesToOffice(){
    this.botService.setOfficeDefaultLanguage(environment.apiUrl + "botConvo/addLanguagesToOffice/" + this.defLangId).then(
      res=>{
        this.toastr.success("Language Added Successfully !");
        this.passEntry.emit(this.defLangId);
        this.activeModal.close();
      }); 
  }

  toasterMessage(msg:string){
    this.toastr.success(msg);
  }

  
  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  submit(){
    
  }

  phnNoId:any;
  savePhoneNumberID(){
    this.botResponseService.setUp(environment.apiUrl + "botConvo/saveWcaPhoneNumberId/" + this.phnNoId).then(
      res=>{
        this.wcaPhoneNumberID = this.phnNoId;
        this.toastr.success("Phone Number ID saved successfully !")
        this.passEntry.emit(this.phnNoId);
        this.activeModal.close();
      });
  }

  savewhatsappNumber(){
    this.botResponseService.setUp(environment.apiUrl + "botConvo/saveWcaOfficePhoneNumber/" + this.whatsappNumber).then(
      res=>{
        this.toastr.success("Phone Number saved successfully !")
        this.passEntry.emit(this.whatsappNumber);
        this.activeModal.close();
      });
  }
  
  permanentToken:any;
  savePermanentToken(){
    this.botResponseService.setUp(environment.apiUrl + "botConvo/saveWcaPermanentToken/" + this.permanentToken).then(
      res=>{
        this.wcaPermanentToken = this.permanentToken;
        this.toastr.success("Permanent token saved successfully !")
        this.passEntry.emit(this.permanentToken);
        this.activeModal.close();
      });
  }

  closeResult!:string;

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
