<div [style.background-color]='getColor()' class="modal-header">
  <h4 style="color: white;" class="modal-title pull-left">{{fromParent.context}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card-body">
    <form #adminForm="ngForm">

      <div *ngIf="fromParent.context==='Create Schedule' || fromParent.context==='Reschedule Template Message'">
        Note : Scheduled template messages will be sent based on the messaging limit of your whatsapp account set by
        meta.
        Any template messages scheduled beyond the messaging limits will be queued and sent once new conversation
        windows open up.
        <!-- Your current messaging limit is 250. -->
        <br>
        <div>
          <label class="required" for="fkBotTemplateId">Template</label>
          <select (change)="chooseTemplate();" required class="form-control" [(ngModel)]="botSchedule.fkBotTemplateId">
            <option value="" disabled>Choose Template</option>
            <option *ngFor="let temp of botTemplates" [ngValue]="temp.botTemplateId">{{temp.templateDisplayName}}
            </option>
          </select>
        </div>

        <br>
        <div
          *ngIf="chosenTemplate.tempHeaderType==='IMAGE' || chosenTemplate.tempHeaderType==='VIDEO' || chosenTemplate.tempHeaderType==='DOCUMENT'">
          <div>
            <label class="col-md-6" class="required" for="fkBotMediaIdForTemplateHeader">Choose header media</label>
            <select required class="form-control" [(ngModel)]="botSchedule.fkBotMediaIdForTemplateHeader"
              id="fkBotMediaIdForTemplateHeader" name="fkBotMediaIdForTemplateHeader">
              <option value=null disabled>Choose header media</option>
              <option *ngFor="let med of media" [ngValue]="med.botMediaId"> {{med.title}}
              </option>
            </select>
          </div>

          <br>
          <div style="float: right;">
            <button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
          </div>
          <br>

        </div>

        <div *ngIf="chosenTemplate.tempHeaderType==='TEXT'">
          <label for="headerTextForTemplateHeader">Header Text</label>
          <input readonly class="form-control" type="text" name="headerTextForTemplateHeader"
            id="headerTextForTemplateHeader" [(ngModel)]="botSchedule.headerTextForTemplateHeader">
          <br>
        </div>

        <div>
          <label for="fkBotTemplateId">Template Body</label>
          <textarea readonly [(ngModel)]="chosenTemplate.tempBody" name="chosenTemplate.tempBody"
            id="chosenTemplate.tempBody" cols="30" rows="10" class="form-control"></textarea>
        </div>

        <br
          *ngIf="chosenTemplate.tempVarList !== null && chosenTemplate.tempVarList !== undefined && chosenTemplate.tempVarList.length > 0">
        <div
          *ngIf="chosenTemplate.tempVarList !== null && chosenTemplate.tempVarList !== undefined && chosenTemplate.tempVarList.length > 0">
          <label>Template Variables</label>
          <div class="form-group row">

            <div *ngFor="let templateVar of chosenTemplate.tempVarList" class="col-md-6">
              <label class="required">{{templateVar.botScheduleTempVarOrder}}</label>
              <input minlength="1" maxlength="50" required class="form-control" type="text"
                [name]="templateVar.botScheduleTempVarOrder" [id]="templateVar.botScheduleTempVarOrder"
                [(ngModel)]="templateVar.botScheduleTempVar">
            </div>

          </div>
        </div>

        <br>
        <div class="form-group row">

          <div class="col-md-6">
            <label class="required" for="botScheduleTitle">Title</label>
            <input #botScheduleTitle="ngModel" minlength="3" maxlength="255" required class="form-control" type="text"
              name="botScheduleTitle" id="botScheduleTitle" [(ngModel)]="botSchedule.botScheduleTitle">
            <div class="alert alert-danger" *ngIf="botScheduleTitle.touched && !botScheduleTitle.valid">Title is
              Required</div>
          </div>

          <div class="col-md-3">
            <label class="required" for="executionTimestamp">Schedule For</label>
            <input class="form-control" required [(ngModel)]="botSchedule.executionTimestamp" type="datetime-local"
              id="executionTimestamp" name="executionTimestamp">
          </div>
          <div class="col-md-3">
            <label class="required" for="timezone">Timezone</label>
            <input class="form-control" required [(ngModel)]="botSchedule.timezone" type="text"
              id="timezone" name="timezone">
          </div>

        </div>

        <div>
          <label for="botScheduleDescription">Description</label>
          <textarea class="form-control" type="text" name="botScheduleDescription" id="botScheduleDescription"
            [(ngModel)]="botSchedule.botScheduleDescription"></textarea>
        </div>


        <br *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
        <div *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
          <label>Selected Customers / Users :</label>
          <mat-select-trigger>
            <mat-chip-list style="display: flex;">
              <mat-chip *ngFor="let topping of selectedUsers" [removable]="true" (removed)="onToppingRemoved(topping)">
                {{ topping.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>

            </mat-chip-list>
          </mat-select-trigger>
        </div>
        <br>
        <div>
          <label>Customers / Users :</label>
          <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
            <mat-label>Choose Customers / Users</mat-label>
            <mat-select [formControl]="toppingsControl" (selectionChange)="multiSelectValueChange($event.value);"
              multiple>
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                placeholder="Start searching by name or phone number">

              <mat-option (click)="selectAll('Select All', null)" value="Select All">Select All</mat-option>
              <mat-option (click)="selectAll(cb.botUserId, cb)" *ngFor="let cb of allUsers | filter:searchText"
                [value]="cb.botUserId">{{cb.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <div *ngIf="fromParent.context==='Create Schedule'">
          <label>Users Groups :</label>
          <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
            <mat-label>Choose Groups</mat-label>
            <mat-select [formControl]="toppingsControlGrps" (selectionChange)="multiSelectValueChange($event.value);"
              multiple>

              <input class="form-control" type="text" name="search" [(ngModel)]="searchText1" autocomplete="off"
                placeholder="Start searching by name or description">

              <mat-option (click)="selectAllGrps('Select All')" value="Select All">Select All</mat-option>
              <mat-option (click)="selectAllGrps(cb.botUserGrpId)"
                *ngFor="let cb of botUserGroup.list | filter:searchText1"
                [value]="cb.botUserGrpId">{{cb.botUserGrpName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>



      </div>

      <div *ngIf="fromParent.context==='View Schedule Details'">

        <div>
          <label>Template Name</label>
          <input readonly class="form-control" type="text" [name]="botSchedule.fkBotTemplateName"
            [id]="botSchedule.fkBotTemplateName" [(ngModel)]="botSchedule.fkBotTemplateName">
        </div>

        <br>

        <div>
          <label for="fkBotTemplateId">Template Body</label>
          <textarea readonly [(ngModel)]="botSchedule.tempBody" name="chosenTemplate.tempBody"
            id="chosenTemplate.tempBody" cols="30" rows="10" class="form-control"></textarea>
        </div>

        <br>

        <div
          *ngIf="botScheduleTempVariableList !== null && botScheduleTempVariableList !== undefined && botScheduleTempVariableList.length > 0">
          <label>Template Variables</label>
          <div class="form-group row">

            <div *ngFor="let templateVar of botScheduleTempVariableList" class="col-md-6">
              <label class="required">{{templateVar.botScheduleTempVarOrder}}</label>
              <input readonly class="form-control" type="text" [name]="templateVar.botScheduleTempVarOrder"
                [id]="templateVar.botScheduleTempVarOrder" [(ngModel)]="templateVar.botScheduleTempVar">
            </div>

          </div>
        </div>

      </div>

    </form>
  </div>
</div>

<div *ngIf="fromParent.context==='Create Schedule' || fromParent.context==='Reschedule Template Message'"
  class="modal-footer">
  <button [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right"
    (click)="onSubmit();">save</button>
</div>