export class GetAccDetails {

 // Parent details
 parentUserId!: string;
 parentFirstName!: string;
 parentLastName!: string;
 parentEmailId!: string;
 parentPhone!: string;
 parentImage!: string;

children : GetAccDetails[] = [];
subscriptions : GetAccDetails[] = [];

roles : string[] = [];

 // Child details
 childUserId!: string;
 childFirstName!: string;
 childLastName!: string;
 childDoB!: string;
 childImage!: string;

 safeUrl:any;

// Address Details
addressLine1!: string;
addressLine2!: string;
city!: string;
country!: string;
state!: string;
zipCode!: string;

// subscription details
startedDate: any;
endedDate: any;
courseName: any;
courseDescription: any;


}
