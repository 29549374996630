import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { Attendance } from 'src/app/courseManagementClasses/attendance';
import { Class } from 'src/app/courseManagementClasses/class';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-view-attendance',
  templateUrl: './view-attendance.component.html',
  styleUrls: ['./view-attendance.component.scss']
})
export class ViewAttendanceComponent implements OnInit {

  selectedClassName : any;
  searchAttendance = true;
  editAttendance = false;
  closeResult !: string;
  clicked = false;

  mainAttendance : Attendance = new Attendance();

  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe,
    private toaster: ToastrService,
    private teacherService : TeacherService,private ngbModalService: NgbModal,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;

    @Input() fromParent: any;

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    
    classes : Class[] = [];
    
  ngOnInit(): void {

    this.mainAttendance.fkClassId = "";

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getTeacherClasses();

}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

markAttendanceAbsentForOldSession(id:any){
  this.teacherService.markAttendanceAbsentForOldSession( environment.apiUrl + "attendance/markAttendanceAbsentForOldSession/" + id).then(
    data=>{
      this.mainAttendance.absenteeList.forEach((att)=>{
        if(att.attendanceId === id){
          att.present = !att.present;
        }
      });
      this.mainAttendance.attendanceList.forEach((att)=>{
        if(att.attendanceId === id){
          att.present = !att.present;
        }
      });
      this.mainAttendance.attendanceForDifferentClassSameSession.forEach((att)=>{
        if(att.attendanceId === id){
          att.present = !att.present;
        }
      });
    });
}

markAttendancePresentForOldSession(studentId:any){

  let attendance : Attendance = new Attendance();
  attendance.fkStudentId = studentId

  this.mainAttendance.attendanceList.forEach((att)=>{
    if(att.present === true){
      attendance.attendanceId = att.attendanceId;
    }
  });
  this.mainAttendance.absenteeList.forEach((att)=>{
    if(att.present === true){
      attendance.attendanceId = att.attendanceId;
    }
  });
  this.mainAttendance.attendanceForDifferentClassSameSession.forEach((att)=>{
    if(att.present === true){
      attendance.attendanceId = att.attendanceId;
    }
  });


  this.teacherService.markAttendancePresentForOldSession( environment.apiUrl + "attendance/markAttendancePresentForOldSession", attendance).then(
    data=>{
      attendance = data;

      this.mainAttendance.absenteeList.forEach((att)=>{
        if(att.fkStudentId === studentId){
          att.present = !att.present;
          att.attendanceId = attendance.attendanceId;
        }
      });
      this.mainAttendance.attendanceList.forEach((att)=>{
        if(att.fkStudentId === studentId){
          att.present = !att.present;
          att.attendanceId = attendance.attendanceId;
        }
      });
      this.mainAttendance.attendanceForDifferentClassSameSession.forEach((att)=>{
        if(att.fkStudentId === studentId){
          att.present = !att.present;
          att.attendanceId = attendance.attendanceId;
        }
      });
    });
}


getTeacherClasses(){
  this.teacherService.getTeacherClasssesWithStudents( environment.apiUrl + "teacher/getTeacherClasssesWithStudents").then(
    data=>{
      this.classes = data;
    });
}

getAttendance(){
  this.clicked = true;
  this.teacherService.getAttendanceByDateAndDateTime( environment.apiUrl + "attendance/getAttendanceByDateAndDateTime", this.mainAttendance).then(
    data=>{
      this.mainAttendance = data;

      if(this.mainAttendance.noAttendance=== true){

        this.toaster.error("No attendance to display !");
        this.clicked = false;

       }else{

        this.mainAttendance.attendanceFromDateTimeFormatted = new Date(this.mainAttendance.attendanceFromDateTime[0], this.mainAttendance.attendanceFromDateTime[1] - 1, this.mainAttendance.attendanceFromDateTime[2], this.mainAttendance.attendanceFromDateTime[3], this.mainAttendance.attendanceFromDateTime[4]);
        this.mainAttendance.attendanceFromDateTimeFormatted = this.datePipe.transform(this.mainAttendance.attendanceFromDateTimeFormatted, 'hh:mm dd-MM-yyyy'); 
  
        this.mainAttendance.attendanceToDateTimeFormatted = new Date(this.mainAttendance.attendanceToDateTime[0], this.mainAttendance.attendanceToDateTime[1] - 1, this.mainAttendance.attendanceToDateTime[2], this.mainAttendance.attendanceToDateTime[3], this.mainAttendance.attendanceToDateTime[4]);
        this.mainAttendance.attendanceToDateTimeFormatted = this.datePipe.transform(this.mainAttendance.attendanceToDateTimeFormatted, 'hh:mm dd-MM-yyyy'); 
  
        this.selectedClassName = this.mainAttendance.studentClassName;      
  
        this.searchAttendance = false;

       }


    });
}



open(content: any) {
  this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
  
}





getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}


closeUpdateModal(){
  this.activeModal.close();
} 

}
