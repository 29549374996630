<div class="modal-header">
  <h4 class="modal-title pull-left">{{headerText}}</h4>
  <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form (ngSubmit)="saveToList(addUserForm)" [formGroup]="addUserForm">

        <div class="form-group row"> 

          <div class="col-md-6">
            <label class="required"  for="if-first_name">First Name</label>
            <input required autocomplete="first_name" class="form-control" formControlName="first_name" id="if-first_name"
              name="if-first_name" placeholder="Enter first name.." type="text">
          </div>
          <div class="col-md-6">
            <label class="required" for="if-last_name">Last Name</label>
            <input required autocomplete="last_name" class="form-control" formControlName="last_name" id="if-last_name"
              name="if-last_name" placeholder="Enter last name.." type="text">
          </div>

        </div>

        <div class="form-group row">

          <div class="col-md-6">
            <label for="if-last_name" class="required">Email</label>
            <input autocomplete="email" class="form-control" [readonly]="isReadonly" formControlName="email" id="if-email" name="if-email"
              placeholder="Enter Email" type="text">
          </div>
          <div class="col-md-6">
            <label for="if-phone_number">Phone Number</label>
            <input autocomplete="phone_number" class="form-control" formControlName="phone_number" id="if-phone_number"
              name="if-phone_number" placeholder="Enter phone number.." type="text">
          </div>

        </div>

        <div class="form-group row">

          <div class="col-md-6">
            <label for="gender">Gender</label>
            <div class="custom-control custom-radio">
              <input class="custom-control-input" formControlName="gender" id="male" name="gender" type="radio"
                value="Male">
              <label class="custom-control-label" for="male">Male</label>
            </div>
            <div class="custom-control custom-radio">
              <input class="custom-control-input" formControlName="gender" id="female" name="gender" type="radio"
                value="Female">
              <label class="custom-control-label" for="female">Female</label>
            </div>
          </div>
          <div *ngIf="this.source === 'OFFICE_ADMIN' && this.officeAdminState?.userId !== null" class="col-md-6">
            <label for="if-date_of_birth">Date of Birth</label>
            <input class="form-control" formControlName="date_of_birth" id="if-date_of_birth" name="if-date_of_birth"
              placeholder="Date fo birth" type="date">
          </div>

        </div>

        <div *ngIf="this.source === 'SUPER_ADMIN' || this.officeAdminState?.userId === null" class="form-group row">

          <div class="col-md-6">
            <label for="if-date_of_birth">Date of Birth</label>
            <input class="form-control" formControlName="date_of_birth" id="if-date_of_birth" name="if-date_of_birth"
              placeholder="Date fo birth" type="date">
          </div>

          <div *ngIf="this.source === 'SUPER_ADMIN'" class="col-md-6">
            <label class="required"  for="if-office" class="required">Office</label>
            <select required class="form-control" formControlName="office" id="if-office" name="if-office">
              <option disabled value="">Choose Office</option>
              <option *ngFor="let office of offices" [ngValue]="office.id">
                {{office.officeName}},{{office.officeLocation}}</option>
            </select>
          </div>

          <div *ngIf="this.source === 'OFFICE_ADMIN' && this.officeAdminState?.userId === null" class="col-md-6">
            <label class="required"  for="if-role" class="required">Role</label>
            <select class="form-control" formControlName="role" id="if-role" name="if-role">
              <option disabled value="">Choose Role</option>
              <option *ngFor="let role of roles.roles" [ngValue]="role.roleId">{{role.roleName}}</option>
            </select>
          </div>

        </div>

        <div class="form-group row">

          <div class="col-md-4">
            <label for="if-addressLine1">Address Line 1</label>
            <input autocomplete="addressLine1" class="form-control" formControlName="addressLine1" id="if-addressLine1"
              name="if-addressLine1" placeholder="Enter address line 1.." type="text">
          </div>

          <div class="col-md-4">
            <label for="if-addressLine2">Address Line 2</label>
            <input autocomplete="addressLine2" class="form-control" formControlName="addressLine2" id="if-addressLine2"
              name="if-addressLine2" placeholder="Enter address line 2.." type="text">
          </div>

          <div class="col-md-4">
            <label for="if-city">City</label>
            <input autocomplete="city" class="form-control" formControlName="city" id="if-city" name="if-city"
              placeholder="Enter city.." type="text">
          </div>

        </div>

        <div class="form-group row">

          <div class="col-md-4">
            <label for="if-state">State</label>
            <input autocomplete="state" class="form-control" formControlName="state" id="if-state" name="if-state"
              placeholder="Enter state.." type="text">
          </div>

          <div class="col-md-4">
            <label for="if-country">Country</label>
            <input autocomplete="country" class="form-control" formControlName="country" id="if-country"
              name="if-country" placeholder="Enter country.." type="text">
          </div>

          <div class="col-md-4">
            <label for="if-zipCode">Zip Code</label>
            <input autocomplete="zipCode" class="form-control" formControlName="zipCode" id="if-zipCode"
              name="if-zipCode" placeholder="Enter zip code.." type="text">
          </div>

        </div>

        <!-- <div class="form-group row">

        

         

        </div> -->

        <div *ngIf="this.source === 'OFFICE_ADMIN' && this.officeAdminState?.userId === null"
          formArrayName="subscriptionDTOS" style="margin-right: -2rem;">
          <div *ngFor="let data of subscriptions.controls; let i = index;" [formGroupName]="i"
            class="col-md-12 form-group row" style="padding-right: 0px !important;
          padding-left: 0px !important;">
            <div class="col-md-4">
              <label for="if-zipCode">Course / Product</label>
              <select class="form-control col-md-12" formControlName="productId" id="if-productId" name="if-productId">
                <option disabled value="">Choose Course / Product</option>
                <option *ngFor="let product of products" [ngValue]="product.productId">{{product.productName}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="if-zipCode">Start Date</label>
              <input class="col-md-12 form-control" formControlName="startDate" type="date">
            </div>
            <div class="col-md-4">
              <label for="if-zipCode">End Date</label>
              <input class="col-md-12 form-control" formControlName="endDate" type="date">
            </div>
          </div>
        </div>

      </form>
      
    </div>
  </div>
</div>

<div class="modal-footer">
  <button [disabled]="clicked"  (click)="saveToList(addUserForm);clicked=true;" class="btn btn-sm btn-primary float-right" type="submit">
    Save
  </button>
</div>
