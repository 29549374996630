import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { AddInvoiceComponent } from 'src/app/crm/invoice/add-invoice/add-invoice.component';
import { UpdateInvoiceComponent } from 'src/app/crm/invoice/update-invoice/update-invoice.component';
import { AddPaymentComponent } from 'src/app/crm/payment/add-payment/add-payment.component';
import { UpdatePaymentComponent } from 'src/app/crm/payment/update-payment/update-payment.component';
import { AddShipmentComponent } from 'src/app/crm/shipment/add-shipment/add-shipment.component';
import { UpdateShipmentComponent } from 'src/app/crm/shipment/update-shipment/update-shipment.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { Shipment } from 'src/app/crmClasses/shipment';
import { InvoiceService } from 'src/app/service/invoice.service';
import { PaymentService } from 'src/app/service/payment.service';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { AddCustomerCommunicationsComponent } from '../../customerCommunication/add-customer-communications/add-customer-communications.component';
import { EditComponent } from '../../customerCommunication/edit/edit.component';
import { ViewOrderDetailsComponent } from '../../orders/view-order-details/view-order-details.component';
import { EditCustomerComponent } from '../edit-customer/edit-customer.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  type : any;
  selectedOption = '0';
  showPayments = false;
  showShipments = false;
  showComs = false;
  theme : any;
  officeId : any;
  loggedInUser: any;
  combinedPTC : Customer = new Customer();
  profile = true;
  id : any;
  showInvoices = false;
  invoices : any[] = [{
    deliveryAddress: '',
description: '',
fkCustomerId: '',
fkInvoiceId: '',
fkProductId: '',
invoiceDate: '',
invoiceDetId: '',
invoiceDetStatus: '',
invoiceId: '',
invoiceNo: '',
invoiceRemarks: '',
invoiceStatus: '',
itemName: '',
paymentMethod: '',
pricePerItem: '',
quantity: '',
totalAmount: '',
totalPrice: ''
  }];

  shipments : Shipment[] = [];
coms : CustomerCommunications[] = [];
  payments : any[] = [{fkInvoiceId: "",
  paymentAmount: "",
  paymentCompletion: "",
  paymentDate: "",
  paymentDue: "",
  paymentId: "",
  paymentMethod: "",
  paymentRemarks: "",
  paymentStatus: ""

}]

  constructor(
              private datePipe: DatePipe,
              private ngbModalService: NgbModal,
              public activeModal: NgbActiveModal,
              private invoiceService : InvoiceService, 
              private customerService : CustomerService,
              private paymentService : PaymentService,
              private shipmentService : ShipmentService,
              private sharedDataService: SharedDataService,
) { }

    @Input() fromParent: any;  

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.officeId = this.loggedInUser.offices[0].id;

    this.id = this.fromParent.id;
    this.type = this.fromParent.type;

    this.customerService.getCustomerById(environment.apiUrl + "bcrmCustomer/getCustomerById/" + this.id).then(
      data=>{
      this.combinedPTC = data;
    });

    this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
      data=>{
        this.invoices = data;
      });
  }

  onChange(event : any){ 
    
    let value = this.selectedOption;
   
    this.showInvoices = false;
    this.showPayments = false;
    this.showShipments = false;
    this.showComs = false;

    if(value === '1'){

      this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
        data=>{
          this.invoices = data;
          this.showInvoices = true;
        });
    
    }else if(value === '2'){

this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.id).then(
  data=>{
    this.payments = data;
    this.showPayments = true;
  });
    
    }else if(value === '3'){
 
      this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.id).then(
        data=>{
          this.shipments = data;
          this.showShipments = true;
        });
     
    }else if(value === '4'){

      this.customerService.getCustomerCommunicationsByCustomerId(environment.apiUrl + "bcrmCustomerCommunications/getCustomerCommunicationsByCustomerId/" +  this.id).then(
        data=>{
          this.coms = data;

          this.coms.forEach((value)=>{

            let sd = value.currentActionDateTime;
            let ed = value.nextActionDateTime;
  
           let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
           let endDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);

           value.currentActionDateTime = startDate;   
           value.nextActionDateTime = endDate;           
        
           value.currentActionDateTime = this.datePipe.transform(startDate, 'yyyy-MM-dd hh:mm');
           value.nextActionDateTime = this.datePipe.transform(endDate, 'yyyy-MM-dd hh:mm');
  
          })
        
      
          this.showComs = true;
        });
      
  
      }

  }

  viewInvoiceDetailsModal(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOrderDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });

    let data = {
      invoiceId: invoiceId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }

    // authorized
  addNewInvoice(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddInvoiceComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        customer : this.combinedPTC
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
          data=>{
            this.invoices = data;
            this.showInvoices = true;
          });
      
      });
  }

  // authorized
  addNewPayment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddPaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        customerId : this.id
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {});

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.id).then(
          data=>{
            this.payments = data;
            this.showPayments = true;
          });
      });  
  }

     // authorized
  editInvoice(invoiceId : string){

      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( UpdateInvoiceComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      invoiceId: invoiceId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
          this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.id).then(
            data=>{
              this.invoices = data;
              this.showInvoices = true;
            });
        });
  }

  // authorized
  addNewShipment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      customerId : this.id
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
          this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.id).then(
            data=>{
              this.shipments = data;
              this.showShipments = true;
            });
        });   
   
  }

  // authorized
  editCustomer(){ 
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditCustomerComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: this.id ,
      custObject : this.combinedPTC,
          type : this.type
        }
       

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
      this.customerService.getCustomerById(environment.apiUrl + "bcrmCustomer/getCustomerById/" + this.id).then(data=>{
        this.combinedPTC = data;

      }, error => console.log(error));
      
    });  
  }

   // authorized
   editPayment(paymentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdatePaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        paymentId: paymentId
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

          modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
            this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.id).then(
              data=>{
                this.payments = data;
                this.showPayments = true;
              });
          });  
  }

   // authorized
   editShipment(shipmentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      shipmentId: shipmentId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
      this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.id).then(
        data=>{
          this.shipments = data;
          this.showShipments = true;
        });
    });  
    
  }

  editCommunications(coms : CustomerCommunications){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      coms : coms 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
      this.customerService.getCustomerCommunicationsByCustomerId(environment.apiUrl + "bcrmCustomerCommunications/getCustomerCommunicationsByCustomerId/" +  this.id).then(
        data=>{
          
          this.coms = data;

          this.coms.forEach((value)=>{

            let sd = value.currentActionDateTime;
            let ed = value.nextActionDateTime;
  
           let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
           let endDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);
  
           value.currentActionDateTime = startDate;   
           value.nextActionDateTime = endDate;           
        
           value.currentActionDateTime = this.datePipe.transform(startDate, 'yyyy-MM-dd hh:mm');
           value.nextActionDateTime = this.datePipe.transform(endDate, 'yyyy-MM-dd hh:mm');
  
          })
        
          this.showComs = true;
        });
      
    });  
    
  }

  addNewCustomerCommunication(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCustomerCommunicationsComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
      customerId : this.combinedPTC.customerId
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.customerService.getCustomerCommunicationsByCustomerId(environment.apiUrl + "bcrmCustomerCommunications/getCustomerCommunicationsByCustomerId/" +  this.id).then(
        data=>{
          
          this.coms = data;

          this.coms.forEach((value)=>{

            let sd = value.currentActionDateTime;
            let ed = value.nextActionDateTime;
  
           let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
           let endDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);
  
           value.currentActionDateTime = startDate;   
           value.nextActionDateTime = endDate;           
        
           value.currentActionDateTime = this.datePipe.transform(startDate, 'yyyy-MM-dd hh:mm');
           value.nextActionDateTime = this.datePipe.transform(endDate, 'yyyy-MM-dd hh:mm');
  
          })
        
          this.showComs = true;
        });
      
    });
}


 
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.passEntry.emit('close');
    this.activeModal.close();
  }

} 