import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../crmClasses/product';
 
@Injectable({
  providedIn: 'root'
}) 
export class ProductService {

  private getUrl =  environment.apiUrl + "crmproduct/getById";
  private deleteUrl =  environment.apiUrl + "crmproduct/delete";
  private plbiURL =  environment.apiUrl + "crmproduct/getAllByOfficeId";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }
  
  addProduct(prod : Product):Observable<Product>{
    return this.httpClient.post<Product>( environment.apiUrl + 'crmproduct/add', prod);
  }

  // authorized
  addNewProduct(avenue: string, product : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, product, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, product, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getProductListByOfficeId(officeId : string): Observable<Product[]>{
    return this.httpClient.get<Product[]>(`${this.plbiURL}/${officeId}`);
  }

  getProductListByOfficeIdForCrmAdmin(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getProductListByOfficeIdForCourseAdmin(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  // Authorized
  getProductsByOfficeIdForSA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  getProductById(productId : string):Observable<Product>{
    return this.httpClient.get<Product>(`${this.getUrl}/${productId}`);
   } 

     // Authorized
     getProductByIdForCrmAdmin(avenue: string): Promise<any> {

      this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }



    // authorized
    updateProduct(avenue: string, product : any): Promise<any> {

      this.authToken = localStorage.getItem('auth-token');

      const self = this;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authToken
      });
      return new Promise((resolve, reject) => {
  
        const req = new HttpRequest('PUT', avenue, product, {
          reportProgress: true,
          responseType: 'text'
        });
  
        self.httpClient.put(avenue, product, { headers: headers })
          .toPromise()
          .then((data) => {
            resolve(data);
          }).catch((err) => {
          });
      });
    }
  

  deleteProduct(productId : string) : Observable<Object>{
    return this.httpClient.delete(`${this.deleteUrl}/${productId}`);
  }

      // Authorized
      changeStatus(avenue: string): Promise<any> {

        this.authToken = localStorage.getItem('auth-token');

        const self = this;
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.authToken
        });
        return new Promise((resolve, reject) => {
          self.httpClient.delete(avenue, { headers: headers })
            .toPromise()
            .then((data) => {
              resolve(data);
            }).catch((err) => {
            });
        });
      }
    

}
 