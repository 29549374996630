import { BotInvoiceDetails } from "src/app/BOT_CODE/bot_classes/bot-invoice-details";
import { BotItemExt } from "./bot-item-ext";
import { BotUserLocation } from "src/app/BOT_CODE/bot_classes/bot-user-location";

export class BotCategoryExt {
  botCategoryId: any;
  botCategoryStatus: any;
  name: any;
  description: any;
  parentId: any;
  fkOfficeId: any;

  list: BotCategoryExt[] = [];
  itemList: BotItemExt[] = [];
  invoiceDetails: BotInvoiceDetails[] = [];
  botUserLocations: BotUserLocation[] = [];

  panelOpenState = true;
  headerInfo: any;
  categoryTotalPriceOfItemsInCart = 0;
  categoryTotalNoOfItemsAddedInCart = 0;

  invoiceOrderStatus: any;
  orderDeleted: any;
  invoiceLinkType: any;
  officeName: any;
  officeLocation: any;
  activeStatus: any;
  themeColour: any;
  logo: any;
  backgroundColor: any;

  fkBotUserLocationId: any;
  fkBotUserLocationAddress: any;
}
