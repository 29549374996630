import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OfficesService } from "src/app/services/office/offices.service";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { SetUpComponent } from "src/app/BOT_CODE/bot_modals/app_set_up/set-up/set-up.component";
import { BotOfficeLanguage } from "src/app/BOT_CODE/bot_classes/bot-office-language";
import { GlobalClass } from "src/app/global/global-class";
import { ToastrService } from "ngx-toastr";
import { BotOfficeControls } from "src/app/BOT_CODE/bot_classes/bot-office-controls";

@Component({
  selector: "app-app-setup",
  templateUrl: "./app-setup.component.html",
  styleUrls: ["./app-setup.component.scss"],
})
export class AppSetupComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  // userOfficeId: any;
  loggedInUser: any;
  usersExists = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  // officeObject: any = {
  //   activeStatus: "",
  //   backgroundColor: "",
  //   id: "",
  //   logo: "",
  //   oa_ParentMentor: "",
  //   oa_SubAdmin: "",
  //   oa_CRMAdmin: "",
  //   oa_CourseAdmin: "",
  //   oa_ClassAdmin: "",
  //   oa_SkillReport: "",
  //   officeLocation: "",
  //   officeName: "",
  //   themeColour: "",
  //   wcaCallbackUrlVerificationStatus: "",
  //   wcaPermanentToken: "",
  //   wcaPhoneNumberID: "",
  //   whatsappNumber: "",
  //   fkBotLangId: "",
  //   multiLanguageSettings: "",
  // };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  officeControlsData: BotOfficeControls = new BotOfficeControls();
  botOfficeLanguageDetails: BotOfficeLanguage = new BotOfficeLanguage();
  newKeyword: any = null;

  constructor(
    private ngbModalService: NgbModal,
    public toastr: ToastrService,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userId = this.loggedInUser.userId;
  }

  theme: any;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getOfficeLanguageDetails();
  }

  getOfficeLanguageDetails() {
    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "botOffice/getOfficeDataControlsData"
      )
      .then((res) => {
        this.officeControlsData = res;
        this.botOfficeLanguageDetails =
          this.officeControlsData.botOfficeLanguageDto;
        this.showSpinner = false;
      });
  }

  setOfficeDefaultLanguage() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SetUpComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setOfficeDefaultLanguage",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      this.getOfficeLanguageDetails();
    });
  }

  addLanguagesToOffice() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SetUpComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addLanguagesToOffice",
      botOfficeLanguages: this.botOfficeLanguageDetails.botOfficeLanguages,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      this.getOfficeLanguageDetails();
    });
  }

  updateMultiLanguageSettingsStatus() {
    this.showSpinner = true;
    this.officeService
      .updateAccessControlStatuses(
        environment.apiUrl + "botOffice/officeMultiLanguageSettingsStatusUpdate"
      )
      .then((res) => {
        this.botOfficeLanguageDetails.multiLanguageSettings =
          !this.botOfficeLanguageDetails.multiLanguageSettings;
        this.showSpinner = false;
      });
  }

  updateOfficeLanguageStatus(id: any) {
    this.showSpinner = true;
    this.officeService
      .updateAccessControlStatuses(
        environment.apiUrl + "botConvo/updateOfficeLanguageStatus/" + id
      )
      .then((res) => {
        this.botOfficeLanguageDetails.botOfficeLanguages.forEach((offLang) => {
          if (offLang.botOfficeLangId === id) {
            offLang.active = !offLang.active;
          }
        });
        this.showSpinner = false;
      });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  setupCallbackUrl() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SetUpComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setupCallbackUrl",
      // token: this.loggedInUser.offices[0].id,
      token: this.officeControlsData.callbackUrlVerifyToken,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  createPermanentToken() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SetUpComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createPermanentToken",
      // token: this.loggedInUser.offices[0].id,
      // wcaPermanentToken: this.officeObject.wcaPermanentToken,
      wcaPermanentToken: this.officeControlsData.wcaPermanentToken,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      // this.officeObject.wcaPermanentToken = receivedEntry;
      this.officeControlsData.wcaPermanentToken = receivedEntry;
      this.showSpinner = false;
    });
  }

  savePhoneNumberID() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SetUpComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "savePhoneNumberID",
      // token: this.loggedInUser.offices[0].id,
      // wcaPhoneNumberID: this.officeObject.wcaPhoneNumberID,
      wcaPhoneNumberID: this.officeControlsData.wcaPhoneNumberID,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      // this.officeObject.wcaPhoneNumberID = receivedEntry;
      this.officeControlsData.wcaPhoneNumberID = receivedEntry;
      this.showSpinner = false;
    });
  }

  setupOfficeWhatsappNumber() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SetUpComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setupOfficeWhatsappNumber",
      // whatsappNumber: this.officeObject.whatsappNumber,
      whatsappNumber: this.officeControlsData.whatsappNumber,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.showSpinner = true;
      // this.officeObject.whatsappNumber = receivedEntry;
      this.officeControlsData.whatsappNumber = receivedEntry;
      this.showSpinner = false;
    });
  }

  addConvoInitiatorKeyword() {
    this.showSpinner = true;
    if (this.newKeyword !== null) {
      this.officeService
        .addConvoInitiatorKeyword(
          environment.apiUrl +
            "botOffice/addConvoInitiatorKeyword/" +
            this.newKeyword
        )
        .then((res) => {
          if (this.botOfficeLanguageDetails.officeKeywords === null) {
            this.botOfficeLanguageDetails.officeKeywords = [];
          }
          this.botOfficeLanguageDetails.officeKeywords.push(this.newKeyword);
          this.toastr.success("Keyword added successfully");
          this.showSpinner = false;
          this.newKeyword = null;
        });
    } else {
      this.toastr.error("Enter keyword !");
      this.showSpinner = false;
    }
  }

  removeConvoInitiatorKeyword(kWord: any) {
    this.showSpinner = true;
    this.officeService
      .addConvoInitiatorKeyword(
        environment.apiUrl + "botOffice/removeConvoInitiatorKeyword/" + kWord
      )
      .then((res) => {
        const index: number =
          this.botOfficeLanguageDetails.officeKeywords.indexOf(kWord);
        if (index !== -1) {
          this.botOfficeLanguageDetails.officeKeywords.splice(index, 1);
        }
        this.toastr.success("Keyword removed successfully");
        this.showSpinner = false;
      });
  }
}
