<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4  style="color: white;" class="modal-title pull-left">Subjects</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
   
<table>
    <thead>
        <tr>
        <th>Class</th>
        <th>Subject</th>
        <th>Action</th>
    </tr>
    </thead>
    <tbody *ngIf="subExists === true">
        <tr *ngFor="let sub of classCardsWithSafeUrl">
        <td>{{sub.className}}</td>
        <td>{{sub.subName}}</td>
        <td>

            <button style="margin: 1%;" *ngIf="sub.teacherStatus!== null && sub.teacherStatus" type="button"
            class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
          <button style="margin: 1%;" *ngIf="sub.teacherStatus !== null && !sub.teacherStatus" type="button"
            class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
          
        </td>


<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate subject ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="teacherStatusUpdate(sub.teacherId);modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template> 


<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate subject ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="teacherStatusUpdate(sub.teacherId);modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>
    </tr>
    </tbody>
</table>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button type="submit" (click)="closeUpdateModal()" class="btn btn-sm btn-primary float-right" >Close</button>
  </div>