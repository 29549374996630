export class BotOfficeLanguage {
  botOfficeLangId: any;
  fkOfficeId: any;
  fkBotLanguageId: any;
  langOrder: any;
  langNumber: any;
  active: any;
  fkBotLanguageName: any;
  fkBotLangId: any;
  fkBotLangName: any;
  multiLanguageSettings: any;
  officeKeywords: any[] = [];
  botOfficeLanguages: BotOfficeLanguage[] = [];
}
