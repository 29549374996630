<div style="overflow: hidden;position: fixed;right: 0%;left: 0%;">

<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li *ngIf="crmAccess===true" class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToCrmTasksPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-paper-plane" aria-hidden="true"></i> Tasks</a>
        </li>

       <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="sendTemplateMessage(null,false);" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-paper-plane" aria-hidden="true"></i> Send Template Msg</a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="getManualChatReqsByUroId();open(manualChatReqsModal);" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bell"></i> Manual Chat Requests </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
       
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 15vh;">
       
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
 
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="chatsContext='NM';filterUsersBasedOnContext();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
           <h4 *ngIf="chatsContext!=='NM'" class="grey-f" style="text-align: center;font-weight: bold;">
            {{countObj.newManualChatMessagesUsersCount}} <br> New Manual Chat Msgs</h4>
            <h4 *ngIf="chatsContext==='NM'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
              {{countObj.newManualChatMessagesUsersCount}} <br> New Manual Chat Msgs</h4>
           
          </mat-card>
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="chatsContext='NA';filterUsersBasedOnContext();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
           <h4 *ngIf="chatsContext!=='NA'" class="grey-f" style="text-align: center;font-weight: bold;">
            {{countObj.newAutomatedChatMessagesUsersCount}} <br> New Automated Chat Msgs</h4>
            <h4 *ngIf="chatsContext==='NA'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
              {{countObj.newAutomatedChatMessagesUsersCount}} <br> New Automated Chat Msgs</h4>
           
          </mat-card>
      </div>


      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="chatsContext='M';filterUsersBasedOnContext();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          
           <h4 *ngIf="chatsContext!=='M'" class="grey-f" style="text-align: center;font-weight: bold;">
            {{countObj.manualChatUsersCount}}  <br>All Manual Chats</h4>
            <h4 *ngIf="chatsContext==='M'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
              {{countObj.manualChatUsersCount}}  <br>All Manual Chats</h4>
           
          

          </mat-card>
        </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card (click)="chatsContext='A';filterUsersBasedOnContext();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
         
          
           <h4 *ngIf="chatsContext!=='A'" class="grey-f" style="text-align: center;font-weight: bold;">
            {{countObj.automatedChatUsersCount}} <br>All Automated Chats</h4>
            <h4 *ngIf="chatsContext==='A'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
              {{countObj.automatedChatUsersCount}} <br> All Automated Chats</h4>
           
           

          </mat-card>
        </div>

        <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
          <mat-card (click)="chatsContext='AC';filterUsersBasedOnContext();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
           
            
             <h4 *ngIf="chatsContext!=='AC'" class="grey-f" style="text-align: center;font-weight: bold;">
              {{botUsersObject.users.length}} <br>All Chats</h4>
              <h4 *ngIf="chatsContext==='AC'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
                {{botUsersObject.users.length}} <br> All Chats</h4>
             
             
  
            </mat-card>
          </div>
        

          
    
           

      </div>
  </div>


  
    <div class="row rounded-lg overflow-hidden shadow">
    
      <div class="col-5 px-0">

        <div class="bg-white">
  
          <div class="bg-gray px-4 py-2 bg-light search-hero">
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" 
              placeholder="Search for a chat by name or number">
          </div>
          
    <div class="messages-box" style="overflow-y: auto;height: 66vh;">

 <div class="list-group rounded-0" *ngFor="let user of botUsersObject.users | filter:searchText">

<a *ngIf="user.chatWindowOpen===false && user.userVisible===true" 
class="list-group-item list-group-item-action list-group-item-light rounded-0"
(click)="openChatWindow(user);" >
<div class="media">
<img style="cursor: pointer;" matTooltip="Manual Chat Mode" *ngIf="user.convoMode==='manual'" src="/assets/images/icons/bot_user.png" 
alt="user" width="50" class="rounded-circle">
<img style="cursor: pointer;" matTooltip="Automated Chat Mode" *ngIf="user.convoMode==='bot'" src="/assets/images/icons/bot.png" 
alt="user" width="50" class="rounded-circle">
<div class="media-body ml-4">
<div class="d-flex align-items-center justify-content-between mb-1">
<h6  style="font-weight: bold;" class="mb-0">{{user.firstName}} 
  <a style="color: rgb(6, 80, 6);" *ngIf="user.botConvoLog.unseenLogCount > 0">({{user.botConvoLog.unseenLogCount}})</a>
</h6>
<small class="small font-weight-bold">{{user.botConvoLog.lastLogItemDate}}</small>
</div>
<p style="text-align: left;" class="text-muted mb-0 text-small">{{user.botConvoLog.lastLogItemMessage}}</p>
</div>
</div>
</a>


<a *ngIf="user.chatWindowOpen===true && user.userVisible===true" (click)="openChatWindow(user);"
 class="list-group-item list-group-item-action active text-white rounded-0">
<div class="media">
<img style="cursor: pointer;" matTooltip="Manual Chat Mode" *ngIf="user.convoMode==='manual'" src="/assets/images/icons/bot_user.png" 
    alt="user" width="50" class="rounded-circle">
    <img  style="cursor: pointer;" matTooltip="Automated Chat Mode" *ngIf="user.convoMode==='bot'" src="/assets/images/icons/bot.png" 
    alt="user" width="50" class="rounded-circle">
<div class="media-body ml-4">
  <div class="d-flex align-items-center justify-content-between mb-1">
    <h6 style="color: white;" class="mb-0">{{user.firstName}} 
      <a style="color: white;" *ngIf="user.botConvoLog.unseenLogCount > 0">({{user.botConvoLog.unseenLogCount}})</a>
    </h6>
    <small class="small font-weight-bold">{{user.botConvoLog.lastLogItemDate}}</small>
  </div>
  <p style="text-align: left;" class="mb-0 text-small">{{user.botConvoLog.lastLogItemMessage}}</p>
</div>
</div>
</a>
  
            </div>
          </div>
        </div>

      </div>
     
      <div class="col-7 px-0" >

<div style="color: #074383;background-color: white;" class="px-4 py-2">
 
  <p *ngIf="botUserForChatWindow.firstName !== null && botUserForChatWindow.firstName !== undefined" 
  class="h5 mb-0 py-1">
 
 {{botUserForChatWindow.firstName}}

 <img *ngIf="chatsContext==='NA' || chatsContext==='NM'" (click)="open(markConvoAsReadWarningModal)" 
 style="cursor: pointer;margin-left: 0.3%;" matTooltip="Mark as read" 
src="/assets/images/icons/double_check.png" 
alt="user" height="20" width="20">

  <img style="cursor: pointer;margin-right: 1%;float: right;" [matMenuTriggerFor]="beforeMenu" matTooltip="Menu" 
  src="/assets/images/icons/three-dots.png" 
 alt="user" height="30" width="30" class="rounded-circle">
 <mat-menu #beforeMenu="matMenu" xPosition="before">
  <button (click)="open(botUserDetailsModal);" mat-menu-item>
    <mat-icon>info</mat-icon>User Info</button>
  <button *ngIf="botUserForChatWindow.manualChatAccess===false" (click)="open(startChatInfoModal);" mat-menu-item>
    <mat-icon>sms</mat-icon>Start Manual Chat</button>
  <button *ngIf="botUserForChatWindow.manualChatAccess===true" (click)="open(deactivateManualChatModal);" mat-menu-item>
    <mat-icon>stop</mat-icon>Stop Manual Chat</button>
  <button *ngIf="botUserForChatWindow.manualChatAccess===true" (click)="getBotAgents();open(assignAgentModal);" mat-menu-item>
    <mat-icon>fast_forward</mat-icon>Assign Agent</button>
    <button (click)="sendTemplateMessage(botUserForChatWindow,false);" mat-menu-item>
      <mat-icon>comment</mat-icon>Send Template Msg</button>
</mat-menu>
</p>

  <p *ngIf="botUserForChatWindow.firstName === null || botUserForChatWindow.firstName === undefined"
  class="h5 mb-0 py-1">Click on a user to view conversation</p>
</div>
      
<!-- CHAT WINDOW -->
    <div class="px-4 py-5 chat-box" style="height: 59.5vh;overflow-y: scroll;overflow-x: hidden;background-color: rgb(233, 236, 240);">
          
<div *ngIf="botUserForChatWindow.messages !== null && botUserForChatWindow.messages !== undefined && botUserForChatWindow.messages.length > 0" 
  style="text-align: center;">
  <button (click)="loadOlderMessages();" mat-raised-button>Load older messages</button>
</div>
<br>

          <div *ngFor="let messg of botUserForChatWindow.messages">

           

          <!-- REQUEST [NEW]-->
    <div *ngIf="messg.action==='request' && messg.message !== null" class="media w-50 mb-3">
      <img src="/assets/images/icons/botuser.png" alt="user" width="50" class="rounded-circle">
      <div class="media-body ml-3">
        <div class="bg-light rounded py-2 px-3 mb-2">

          <div *ngIf="messg.mediaLoaded===true">

            <div *ngIf="messg.mimeType === 'image'  
            || messg.mimeType === 'image/jpeg'
            || messg.mimeType === 'image/png'"
            style="text-align: center;">
            <a style="text-align: center;">
            <img [src]="messg.safeImageUrl" height="250" width="250">
            </a>
            </div>
  
          <div *ngIf="messg.mimeType === 'video' || messg.mimeType === 'audio'
          || messg.mimeType === 'video/mp4' || messg.mimeType === 'audio/mp4'
          || messg.mimeType === 'video/3gp' || messg.mimeType === 'audio/aac'
          || messg.mimeType === 'audio/mpeg'
          || messg.mimeType === 'audio/ogg'"
          style="text-align: center;" >
            <a style="text-align: center;">
             <video *ngIf="messg.playVideo" muted autoplay width="300" height="225" controls>
              <source [src]="messg.urlSafe" type="video/mp4">
              <source [src]="messg.urlSafe" type="video/ogg">
            </video>
            </a>
          </div>
  
          <div *ngIf="messg.mimeType === 'document' || messg.mimeType === 'text/plain' || messg.mimeType === 'application/pdf' || messg.mimeType === 'application/vnd.ms-powerpoint'
          || messg.mimeType === 'application/msword' || messg.mimeType === 'application/vnd.ms-excel' || messg.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          || messg.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || messg.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
          style="text-align: center;" >
            <a [href]="messg.pdfFile" id="link" style="color: rgb(12, 12, 46);"
            download="file.pdf">Click here to Download File</a> 
          </div>
  
        </div>

          <p *ngIf=" messg.userRespondedType==='text' || messg.userRespondedType==='interactive' || messg.userRespondedType==='button'" 
          style="text-align: justify;text-justify: inter-word;white-space: pre-line;" 
          class="text-small mb-0 text-muted">{{messg.message}}</p>

          <p *ngIf="messg.userRespondedType==='location'" 
          style="text-align: justify;text-justify: inter-word;white-space: pre-line;" 
          class="text-small mb-0 text-muted">
          <a style="color: blue;" [href]="messg.locationUrl" target="_blank">{{messg.locationUrl}}</a>
        </p>
        
        <p *ngIf="messg.userRespondedType==='image' || messg.userRespondedType==='video' || messg.userRespondedType==='document' || messg.userRespondedType==='audio'" 
                  style="text-align: justify;text-justify: inter-word;white-space: pre-line;" 
          class="text-small mb-0 text-muted">
          
          <!-- <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='image'" 
          (click)="viewBotMedia(messg.fkRequestBotMediaId, 'image');">Click here to view image</a> -->
          <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='image' && messg.mediaLoaded!==true" 
          (click)="viewBotMediaInChatWindow(messg.fkRequestBotMediaId, 'image', messg);">Click here to view image</a>

          <!-- <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='audio'" 
          (click)="viewBotMedia(messg.fkRequestBotMediaId, 'audio');">Click here to listen to audio</a> -->
          <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='audio' && messg.mediaLoaded!==true" 
          (click)="viewBotMediaInChatWindow(messg.fkRequestBotMediaId, 'audio', messg);">Click here to listen to audio</a>

          <!-- <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='video'" 
          (click)="viewBotMedia(messg.fkRequestBotMediaId, 'video');">Click here to view video</a> -->
          <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='video' && messg.mediaLoaded!==true" 
          (click)="viewBotMediaInChatWindow(messg.fkRequestBotMediaId, 'video', messg);">Click here to view video</a>

          <!-- <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='document'" 
          (click)="viewBotMedia(messg.fkRequestBotMediaId, 'document');">Click here to view document</a> -->
          <a style="color: rgb(12, 12, 46);" *ngIf="messg.userRespondedType==='document' && messg.mediaLoaded!==true" 
          (click)="viewBotMediaInChatWindow(messg.fkRequestBotMediaId, 'document', messg);">Click here to view document</a>
        </p>

        </div>
        <p style="float: right;" class="small text-muted">{{messg.timestamp}}</p>
      </div>
    </div>

           <!-- RESPONSE [NEW]-->
          <div *ngIf="messg.action==='response' && messg.message !== null" class="media w-50 ml-auto mb-3">
            <div class="media-body">
              <div class="bg-primary rounded py-2 px-3 mb-2">
                
                <!-- <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='image'" 
                (click)="viewBotMedia(messg.fkResponseBotMediaId, messg.botRespondedType);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to view image</p> -->
                <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='image' && messg.mediaLoaded!==true" 
                (click)="viewBotMediaInChatWindow(messg.fkResponseBotMediaId, messg.botRespondedType, messg);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to view image</p>

                <!-- <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='audio'" 
                (click)="viewBotMedia(messg.fkResponseBotMediaId, messg.botRespondedType);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to listen to audio</p> -->
                <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='audio' && messg.mediaLoaded!==true" 
                (click)="viewBotMediaInChatWindow(messg.fkResponseBotMediaId, messg.botRespondedType, messg);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to listen to audio</p>

                <!-- <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='video'" 
                (click)="viewBotMedia(messg.fkResponseBotMediaId, messg.botRespondedType);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to view video</p> -->
                <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='video' && messg.mediaLoaded!==true" 
                (click)="viewBotMediaInChatWindow(messg.fkResponseBotMediaId, messg.botRespondedType, messg);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to view video</p>

                <!-- <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='document'" 
                (click)="viewBotMedia(messg.fkResponseBotMediaId, messg.botRespondedType);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to view document</p> -->
                <p *ngIf="messg.fkResponseBotMediaId!==null && messg.botRespondedType==='document' && messg.mediaLoaded!==true" 
                (click)="viewBotMediaInChatWindow(messg.fkResponseBotMediaId, messg.botRespondedType, messg);"
                 style="text-align: center;cursor: pointer;text-justify: inter-word;white-space: pre-line;color: rgb(12, 12, 46);" 
                class="text-small mb-0">Click here to view document</p>

                <div *ngIf="messg.mediaLoaded===true">

                  <div *ngIf="messg.mimeType === 'image'  
                  || messg.mimeType === 'image/jpeg'
                  || messg.mimeType === 'image/png'"
                  style="text-align: center;">
                  <a style="text-align: center;">
                  <img [src]="messg.safeImageUrl" height="250" width="250">
                  </a>
                  </div>
        
                <div *ngIf="messg.mimeType === 'video' || messg.mimeType === 'audio'
                || messg.mimeType === 'video/mp4' || messg.mimeType === 'audio/mp4'
                || messg.mimeType === 'video/3gp' || messg.mimeType === 'audio/aac'
                || messg.mimeType === 'audio/mpeg'
                || messg.mimeType === 'audio/ogg'"
                style="text-align: center;" >
                  <a style="text-align: center;">
                   <video *ngIf="messg.playVideo" muted autoplay width="300" height="225" controls>
                    <source [src]="messg.urlSafe" type="video/mp4">
                    <source [src]="messg.urlSafe" type="video/ogg">
                  </video>
                  </a>
                </div>
        
                <div *ngIf="messg.mimeType === 'document' || messg.mimeType === 'text/plain' || messg.mimeType === 'application/pdf' || messg.mimeType === 'application/vnd.ms-powerpoint'
                || messg.mimeType === 'application/msword' || messg.mimeType === 'application/vnd.ms-excel' || messg.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                || messg.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || messg.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                style="text-align: center;" >
                  <a [href]="messg.pdfFile" id="link" style="color: rgb(12, 12, 46);"
                  download="file.pdf">Click here to Download File</a> 
                </div>
        
              </div>
              
                <br *ngIf="messg.fkResponseBotMediaId!==null">
                <p style="text-align: justify;text-justify: inter-word;white-space: pre-line;" 
                class="text-small mb-0 text-white">{{messg.message}} </p>
              </div>
              <p  style="float: right;" class="small text-muted">{{messg.timestamp}} [{{messg.respondedBy}}]</p>
            </div>
          </div>
          
        </div>
  
        </div>
        




<form (keydown)="EnterSubmit($event)" *ngIf="botUserForChatWindow.manualChatAccess === true" action="#" class="bg-light">
  <div class="input-group" style="background-color: white;padding-top: 0.5rem;padding-left: 1rem;padding-right: 1rem;">

    

    <a style="cursor: pointer;border-radius: 0%;margin-right: 1%;margin-top: 1%;" [matMenuTriggerFor]="aboveMenu" matTooltip="Attach" >
      <img src="/assets/images/icons/paperclip.png" 
      alt="user" height="25" width="25">
    </a>
    <mat-menu #aboveMenu="matMenu" yPosition="above">
      <button (click)="sendMediaResponse('image');" mat-menu-item>Image</button>
      <button (click)="sendMediaResponse('audio');" mat-menu-item>Audio</button>
      <button (click)="sendMediaResponse('video');" mat-menu-item>Video</button>
      <button (click)="sendMediaResponse('document');" mat-menu-item>Document</button>
      <button (click)="sendLocationResponse();" mat-menu-item>Location</button>
      <button (click)="shareVoiceNote();" mat-menu-item>Voice Note</button>
      <button (click)="open(shareCatalogMessageModal);" mat-menu-item>Catalog</button>
    </mat-menu>
    

    <input style="background-color: white;border-radius: 18px;" class="form-control search-hero" type="text" name="search" 
    [(ngModel)]="manualResponseByAgent" autocomplete="off" 
    placeholder="Type a message">

    <a style="cursor: pointer;border-radius: 0%;margin-right: 1%;margin-left: 1%;margin-top: 1%;" (click)="sendManualResponseByAgent();" 
    matTooltip="Send" >
      <img src="/assets/images/icons/send.png" 
      alt="user" height="28" width="28">
    </a>

  </div>
</form>

<form *ngIf="botUserForChatWindow.manualChatAccess === false" 
style="text-align: center;color: rgb(14, 14, 65);" action="#" class="bg-light">
  <br>
  AUTOMATED CHAT MODE
</form>


  



      </div>
    </div>

  </div>




























  <ng-template #manualChatReqsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Manual Chat Requests</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div style="margin-bottom: 2%;">
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead>
              <tr>
                <th>User Name</th>
                <th>User Phone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let agent of botManChatReqUsers">
                <td>{{agent.name}}</td>
                <td>{{agent.userIdentifier}}</td>
                <td>
                  <button (click)="assignManualChatToSelf(agent.userId);modal.dismiss('Cross click');" style="width: auto;" class="btn btn-info">Assign To Self</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        
      </div>
    </div> 
  </ng-template>

  <ng-template #assignAgentModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Agent</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div style="margin-bottom: 2%;">
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead>
              <tr>
                <th>Agent Name</th>
                <th>Users in "Manual Chat Mode" with Agent</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let agent of botAgent.users">
                <td>{{agent.firstName}} {{agent.lastName}}</td>
                <td>{{agent.manualChatBotUserNames}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="margin-bottom: 2%;">
          <label class="required" for="var">Agents</label> <br> 
             <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botAgentUserIdToAssign" 
                          id="botAgentUserIdToAssign" name="botAgentUserIdToAssign">
               <option value="" disabled >Choose Agent to assign</option>
               <option *ngFor="let agent of botAgent.users" name="varV" id="India" [value]="agent.userId">{{agent.firstName}} {{agent.lastName}}</option>
              </select>
         </div> 
      </div>
    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-info" 
      (click)="assignAgentToManualChat();modal.dismiss('Cross click');">Assign</button>
    </div>
  </ng-template>

  <ng-template #markConvoAsReadWarningModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
        Are you sure to mark this conversation as read ?
      </h3>

      <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="markNewMessagesAsSeen();modal.dismiss('Cross click')"
      mat-raised-button>Mark as Read</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div> 
   
  </ng-template>

  <ng-template #deactivateManualChatModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Deactivate Manual Chat</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to Stop Manual chat ?
    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-danger" 
      (click)="modal.dismiss('Cross click');">Cancel</button>
      <button style="width: auto;float: right;" class="btn btn-info" 
      (click)="deactivateManualChat();modal.dismiss('Cross click');">Stop Manual Chat</button>
    </div>
  </ng-template>

  <ng-template #startChatInfoModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Start Manual Chat</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
      The user {{botUserForChatWindow.firstName}} is in "Automated Chat Mode".  <br> <br>
      Are you sure you want to start "Manual Chat" with {{botUserForChatWindow.firstName}} ?

    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-danger" 
      (click)="modal.dismiss('Cross click');">Cancel</button>

      <button *ngIf="botUserForChatWindow.customerServiceWindowOpen===true" style="width: auto;float: right;" class="btn btn-primary" 
      (click)="assignManualChatToSelf(botUserForChatWindow.userId);modal.dismiss('Cross click');">Yes, Start Manual Chat</button>

      <button *ngIf="botUserForChatWindow.customerServiceWindowOpen===false" style="width: auto;float: right;" class="btn btn-primary" 
      (click)="sendTemplateMessage(botUserForChatWindow,true);modal.dismiss('Cross click');">Yes, Start Manual Chat</button>
    </div>
  </ng-template>

  <ng-template #botUserDetailsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">User Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div style="color: black;" class="modal-body">
      Name : {{botUserForChatWindow.firstName}} <br>
      Phone : {{botUserForChatWindow.phoneNumber}} <br>
      Alternate Phone : {{botUserForChatWindow.alternatePhone}} <br>
      Gender : {{botUserForChatWindow.gender}} <br>
      Custom Variable 1 : {{botUserForChatWindow.customVar1}} <br>
      Custom Variable 2 : {{botUserForChatWindow.customVar2}} <br>
    </div> 
  </ng-template>

  <ng-template #shareCatalogMessageModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
      Send Products Catalog Message
      </h3>

      <div>
        <label class="required" for="bodyText">Message Body</label>
        <textarea maxlength="255" required class="form-control" type="text" 
        name="bodyText" id="bodyText" [(ngModel)]="bodyText" cols="30" rows="5"></textarea>
      </div>
      <br> 


      <div>
        <label for="footerText">Footer (Optional)</label>
        <input maxlength="255" required class="form-control" type="text" 
         name="footerText" id="footerText" [(ngModel)]="footerText">
      </div>
      <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="shareCatalogMessage();modal.dismiss('Cross click')"
      mat-raised-button>Share</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div>
  </ng-template>