import { Student } from "./student";

export class Class {

    classId!: string;

	name!: string;

	description!: string;

	classStatus! : boolean;

	fkOfficeId! : string;

	fkProduct! : string;

	courseName!: string;

	students : Student[] = [];
}
