
 <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
    
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <div class="p-4">
  
    
    <div class="table-header row" [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>


        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Targets
        </span>
      

      </div> 
    </div>
    <div class="card">
      <div class="card-body table-responsive">
       
        <table *ngIf="usersExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="userOptions" >
          <thead class="tableheader">
            <tr>
              <th>Target Name</th>
              <th>Period</th>
              <th>Value</th>
              <th>Achieved</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let target of targets">
                <td class="td-height">{{target.targetName}}</td>
                <td class="td-height">{{target.targetStartDate}} to {{target.targetEndDate}}</td>
                <td class="td-height">{{target.targetValue}}</td>
                <td class="td-height">{{target.targetAchieved}}</td>
                <td>
                    <a matTooltip="View Details"> 
                        <img src="/assets/images/icons/explore.svg" 
                        width="25" height="25" 
                        (click)="checkForChildTargets(target.id, target.targetValue, target.targetAchieved, target.targetName, target.targetStartDate, target.targetEndDate, target.executiveName)"
                         alt="" loading="lazy">
                      </a>
                </td>
                
              
              </tr>

          </tbody>
        </table>

      </div>
    </div>
  

</div>