import { DatePipe, formatNumber } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { AddCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/add-customer-communications/add-customer-communications.component';
import { ViewCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/view-customer-communications/view-customer-communications.component';
import { AddNewOrderComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/add-new-order/add-new-order.component';
import { EditOrderComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/edit-order/edit-order.component';
import { SearchOrdersByPeriodComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/search-orders-by-period/search-orders-by-period.component';
import { ViewOldInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-old-invoices/view-old-invoices.component';
import { ViewOrderDetailsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-order-details/view-order-details.component';
import { ViewProductBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-product-based-invoices/view-product-based-invoices.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { Invoice } from 'src/app/crmClasses/invoice';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-exe-orders',
  templateUrl: './bcrm-exe-orders.component.html',
  styleUrls: ['./bcrm-exe-orders.component.scss']
})

export class BcrmExeOrdersComponent implements OnInit {

  showOutstandingInvoices = false;
  currentRole = "BCRM_EXE";
  invoiceLoader = false;
  invoiceLoaderMessage!: string;
  invoiceExists!: boolean;
  invoiceOptions: any = {}; 
  invoiceOptions2: any = {}; 

  invoice2Exists = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  loggedInUser: any;
  loggedUser: any;
  officeId : any;
  userName!: string;
  userId!: string;
  navbarTitle: any;
  closeResult!:string;
  
  totalOrders: any = 0;
  ordersPlaced: any = 0;
  ordersInvoiced: any = 0;
  ordersCancelled: any = 0;
  ordersApproved: any = 0;
  ordersShipped: any = 0;
  ordersDelivered: any = 0;

  showSpinner = false;
  
  totalDue: any = 0;
  dueInOneWeek: any = 0;
  dueToday: any = 0;
  overdue: any = 0;

  totalDueAmt: any = 0;
  dueInOneWeekAmt: any = 0;
  dueTodayAmt: any = 0;
  overdueAmt: any = 0;

  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private datePipe : DatePipe,
              private invoiceService : InvoiceService, 
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    totalOutstandingAmount : any = 0;

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;
    invoices: InvoiceList[] = [];
    outstandingInvoices: InvoiceList[] = [];
    invoices2: InvoiceList[] = [];
    oldInvoices: InvoiceList[] = [];

    invoiceListDto : InvoiceList = new InvoiceList();
    fromDate : any;
    toDate : any;

  ngOnInit(): void {

    this.showSpinner = true;

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    this.fromDate = new Date(y, m, 1);
    this.toDate = new Date(y, m + 1, 0);
    this.fromDate = this.datePipe.transform(this.fromDate,  "dd-MM-yyyy");
    this.toDate = this.datePipe.transform(this.toDate,  "dd-MM-yyyy");

    this.navbarTitle = GlobalClass.navbarTitle;
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

      this.getInvoiceDetails();
      this.getOutstandingInvoiceDetails();
      this.getOldInvoiceListByUroId();

      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      this.invoiceOptions = {
        pagingType: 'full_numbers',
        pageLength: 500,
        lengthChange: false,
        processing: true,
        info:false
      };

      this.invoiceOptions2 = {
        pagingType: 'full_numbers',
        pageLength: 500,
        lengthChange: false,
        processing: true,
        info:false
      };

  }
  
  addNewCustomerCommunication(customerId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCustomerCommunicationsComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
      customerId : customerId,
      context : "only followup"
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });
}

viewCustomerComms(customerId : any){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(ViewCustomerCommunicationsComponent,
   {
    size : 'lg',
    scrollable: true,
    windowClass: 'app-modal-window',
    keyboard: false
  });
  
  let data = {
    customerId : customerId
  }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  });
}
    // authorized
    getOutstandingInvoiceDetails(){
    
      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getAmountDueOrderListByUroId").then(data => {
          
          this.outstandingInvoices= data;
          this.totalOutstandingAmount = 0;
          this.outstandingInvoices.forEach((val)=>{

            val.formattedActualInvoiceDate = new Date(val.actualInvoiceDate);
            val.formattedActualInvoiceDate = this.datePipe.transform(val.formattedActualInvoiceDate, "dd-MM-yyyy");
            
          this.totalOutstandingAmount = this.totalOutstandingAmount + val.outstandingAmount;
          this.totalDueAmt = this.totalDueAmt + val.outstandingAmount;
          this.totalDue = this.totalDue + 1;

const daysDiff = Math.floor(Math.abs(<any>new Date() - <any>new Date(val.actualInvoiceDate)) / (1000*60*60*24));

 if(daysDiff < val.officeCreditPeriod){

  let x = val.officeCreditPeriod - daysDiff;
  val.dueStatus = "due in " + x + " days";
  if(x < 7){
    val.overDue = "orange"

    this.dueInOneWeek = this.dueInOneWeek + 1;
    this.dueInOneWeekAmt = this.dueInOneWeekAmt + val.outstandingAmount;

  }else{
    val.overDue = "black"
  }

}else if(daysDiff > val.officeCreditPeriod){

  let x = daysDiff - val.officeCreditPeriod;
  val.dueStatus = "overdue by " + x +" days";
  val.overDue = "red"

  this.overdue = this.overdue + 1;
  this.overdueAmt = this.overdueAmt + val.outstandingAmount;

}  else{
  val.dueStatus = "due today";
  val.overDue = "red";

  this.dueToday = this.dueToday + 1;
  this.dueTodayAmt = this.dueTodayAmt + val.outstandingAmount;

}

});
this.dataSource2 = this.outstandingInvoices;

this.totalDueAmt = formatNumber(Number(this.totalDueAmt), 'en-US', '1.0-0');
this.overdueAmt = formatNumber(Number(this.overdueAmt), 'en-US', '1.0-0');
this.dueTodayAmt = formatNumber(Number(this.dueTodayAmt), 'en-US', '1.0-0');
this.dueInOneWeekAmt = formatNumber(Number(this.dueInOneWeekAmt), 'en-US', '1.0-0');

this.invoiceExists = true;
});
  } 

  dataSource2 = this.outstandingInvoices;
  exportArray2() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource2.map(x => ({
      Customer: x.customerName,
      Status: x.orderStatus,
      Date: x.invoiceDate,
      TotalAltQty: x.totalAlternateQuantity,
      TotalAmt: x.grandTotal,
      OutstandingAmt: x.outstandingAmount,
      Contact: x.customerContact
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

  viewProductBasedOrders(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewProductBasedInvoicesComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });

    let data = {
      invoices : this.invoices2
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
 });
}

viewOldInvoices(){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(ViewOldInvoicesComponent,
   {
    size : 'lg',
    scrollable: true,
    windowClass: 'app-modal-window',
    keyboard: false
  });

  let data = {
    invoices : this.oldInvoices,
    currentRole : this.currentRole
  }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
});
}

showDeliveryStatus = false;

  onclick(status : any){
    this.showSpinner = true;
    if(status === "Order Delivered"){
      this.showDeliveryStatus = true;
    }else{
      this.showDeliveryStatus = false;
    }
    this.invoices2 = [];
    if(status === "All Orders"){
      this.invoices2 = this.invoices;
      this.dataSource = this.invoices2
    }else{
      this.invoices.forEach((inv)=>{
        if(inv.orderStatus === status){
          this.invoices2.push(inv);
        }
      });  
      this.dataSource = this.invoices2    
    }
    this.showSpinner = false;
  }

  SearchOrdersByPeriod(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(SearchOrdersByPeriodComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
     role : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
this.showSpinner = true;
      this.invoices = [];
      this.invoices2 = [];      
      
     this.invoices = receivedEntry;
     this.invoices2 = receivedEntry;
     this.dataSource = this.invoices2

     this.totalOrders = 0;
     this.ordersPlaced = 0;
     this.ordersInvoiced = 0;
     this.ordersShipped = 0;
     this.ordersDelivered = 0;
     this.ordersApproved = 0;
     this.ordersCancelled = 0;

     this.invoices2.forEach((val)=>{

       this.totalOrders = this.totalOrders + 1;

       if(val.orderStatus === "Order Placed"){
         this.ordersPlaced = this.ordersPlaced + 1;
       }else if(val.orderStatus === "Order Invoiced"){
         this.ordersInvoiced = this.ordersInvoiced + 1;
       }else if(val.orderStatus === "Order Approved"){
         this.ordersApproved = this.ordersApproved + 1;
       }else if(val.orderStatus === "Order Delivered"){
        this.ordersDelivered = this.ordersDelivered + 1;
       }else if(val.orderStatus === "Order Cancelled"){
         this.ordersCancelled = this.ordersCancelled + 1;
       }else if(val.orderStatus === "Order Shipped"){
         this.ordersShipped = this.ordersShipped + 1;
       }

       val.invoiceDate = new Date(val.invoiceDate);
       val.invoiceDate = this.datePipe.transform(val.invoiceDate, "dd-MM-yyyy");

       val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;
     });

     this.invoice2Exists = true;
     this.showSpinner = false;
    });

    modalRef.componentInstance.passEntry2.subscribe((receivedEntry2 : Target) => {
      this.fromDate = receivedEntry2.targetStartDate;
      this.toDate = receivedEntry2.targetEndDate;
      this.fromDate = this.datePipe.transform(this.fromDate,  "dd-MM-yyyy");
      this.toDate = this.datePipe.transform(this.toDate,  "dd-MM-yyyy");
     });

  }

    // authorized
    getOldInvoiceListByUroId(){
      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getOldInvoiceListByUroId").then(
        data => {

        this.oldInvoices = data;

          this.oldInvoices.forEach((val)=>{
            
            val.invoiceDate = new Date(val.invoiceDate);
            val.invoiceDate = this.datePipe.transform(val.invoiceDate, "dd-MM-yyyy");

            val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;

          });
          this.dataSource = this.invoices2;
          this.invoiceExists = true;
        });
  } 

  // authorized
  getInvoiceDetails(){
    this.showSpinner = true;

      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getOrderListByUroId").then(
        data => {
                
          this.invoiceListDto = data;
        this.invoices= this.invoiceListDto.invoices;
        this.invoices2 = this.invoiceListDto.invoices;

          this.totalOrders = 0;
          this.ordersPlaced = 0;
          this.ordersInvoiced = 0;
          this.ordersShipped = 0;
          this.ordersDelivered = 0;
          this.ordersApproved = 0;
          this.ordersCancelled = 0;

          this.invoices2.forEach((val)=>{

            this.totalOrders = this.totalOrders + 1;

            if(val.orderStatus === "Order Placed"){
              this.ordersPlaced = this.ordersPlaced + 1;
            }else if(val.orderStatus === "Order Invoiced"){
              this.ordersInvoiced = this.ordersInvoiced + 1;
            }else if(val.orderStatus === "Order Approved"){
              this.ordersApproved = this.ordersApproved + 1;
            }else if(val.orderStatus === "Order Cancelled"){
              this.ordersCancelled = this.ordersCancelled + 1;
            }else if(val.orderStatus === "Order Shipped"){
              this.ordersShipped = this.ordersShipped + 1;
            }else if(val.orderStatus === "Order Delivered"){
              this.ordersDelivered = this.ordersDelivered + 1;
            } 
            
            val.invoiceDate = new Date(val.invoiceDate);
            val.invoiceDate = this.datePipe.transform(val.invoiceDate, "dd-MM-yyyy");

            val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;
            
          });
          this.dataSource = this.invoices2;
          this.invoiceExists = true;
          this.showSpinner = false;
        });
  }
  
  dataSource = this.invoices2;
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      Customer: x.customerName,
      Status: x.orderStatus,
      Date: x.invoiceDate,
      TotalAltQty: x.totalAlternateQuantity,
      TotalAmt: x.grandTotal
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  } 
 
   // authorized
  addNewInvoice(){
        this.config.class = 'modal-md';
        const modalRef = this.ngbModalService.open(AddNewOrderComponent,
         {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
  
        let data = {
          customer : null,
          role : this.currentRole
        }
  
        modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });
    
        modalRef.componentInstance.passEntry.subscribe((receivedEntry : Invoice) => {
         this.showSpinner = true;
        let inv = new InvoiceList();
        inv.grandTotal = receivedEntry.grandTotal;
        inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
        inv.orderStatus = receivedEntry.orderStatus;
        inv.invoiceId = receivedEntry.invoiceId;
        inv.invoiceDate = receivedEntry.invoiceDate;
        inv.totalAmount = receivedEntry.totalAmount;
        inv.totalAlternateQuantity = receivedEntry.totalAlternateQuantity;
        inv.invoiceRemarks = receivedEntry.invoiceRemarks;
        inv.fkCustomerId = receivedEntry.fkCustomerId;
        inv.paymentMethod = receivedEntry.paymentMethod;
        inv.totalTax = receivedEntry.totalTax;
        inv.totalCess = receivedEntry.totalCess;
        inv.outstandingAmount = receivedEntry.outstandingAmount;
        inv.totalQuantity = receivedEntry.totalQuantity;
        inv.type = receivedEntry.type;
        inv.customerName = receivedEntry.customerName;

        this.invoices.push(inv);
        // this.invoices2.push(inv);
        this.ordersPlaced = this.ordersPlaced + 1;
         this.showSpinner = false;
        });
    } 

    // authorized
  editInvoice(invoiceId : string){

      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( EditOrderComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      invoiceId: invoiceId ,
      type : "order"
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceList) => {
          this.showSpinner = true;
         this.invoices.forEach((inv)=>{
           if(inv.invoiceId === receivedEntry.invoiceId){
            inv.grandTotal = receivedEntry.grandTotal;
            inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
            inv.orderStatus = receivedEntry.orderStatus;
            inv.invoiceDate = receivedEntry.invoiceDate;
            inv.totalAmount = receivedEntry.totalAmount;
            inv.totalAlternateQuantity = receivedEntry.totalAlternateQuantity;
            inv.invoiceRemarks = receivedEntry.invoiceRemarks;
            inv.paymentMethod = receivedEntry.paymentMethod;
            inv.totalTax = receivedEntry.totalTax;
            inv.totalCess = receivedEntry.totalCess;
            inv.outstandingAmount = receivedEntry.outstandingAmount;
            inv.totalQuantity = receivedEntry.totalQuantity;
           }
         });
         this.showSpinner = false;
        });
  }

  // authorized
  updateInvoiceStatus(invoiceId : string){
    this.invoiceService.changeStatus(environment.apiUrl + "invoice/statusUpdate/" + invoiceId).then( data =>{
          this.invoices.forEach((inv)=>{
            inv.invoiceStatus = !inv.invoiceStatus;
          });
     });
}

  viewInvoiceDetailsModal(invoiceId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOrderDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      invoiceId: invoiceId ,
      type : "order"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
    reloadPage() {
      window.location.reload();
    }
}