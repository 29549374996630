import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UploadBotMediaComponent } from "../upload-bot-media/upload-bot-media.component";

@Component({
  selector: "app-view-bot-media",
  templateUrl: "./view-bot-media.component.html",
  styleUrls: ["./view-bot-media.component.scss"],
})
export class ViewBotMediaComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botMedia: BotMedia = new BotMedia();
  officeId: any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botMedia = this.fromParent.botMedia;
    this.officeId = this.fromParent.officeId;

    this.getBotMediaForPreview();
  }

  reuploadBotMedia() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Reupload Media",
      botMedia: this.botMedia,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.mediaLoaded = false;
      this.botMedia = receivedEntry;
      this.passEntry.emit(this.botMedia);
      this.getBotMediaForPreview();
    });
  }

  unsafeImageUrl: any;
  safeImageUrl: any;

  unsafeImageUrlTest: any;
  safeImageUrlTest: any;

  url: any = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  pdfFile!: SafeResourceUrl;
  txtFile: any;

  playVideo = false;
  mediaLoaded = false;

  getBotMediaForPreview() {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByObjectId/" +
          this.botMedia.wcaMediaId +
          "/" +
          this.officeId
      )
      .subscribe((data) => {
        if (
          this.botMedia.mediaType === "png" ||
          this.botMedia.mediaType === "svg" ||
          this.botMedia.mediaType === "jpg" ||
          this.botMedia.mediaType === "jpeg"
        ) {
          this.unsafeImageUrl = URL.createObjectURL(data);
          this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(
            this.unsafeImageUrl
          );
          this.mediaLoaded = true;
        }

        if (
          this.botMedia.mediaType === "mp4" ||
          this.botMedia.mediaType === "mp3"
        ) {
          this.url = URL.createObjectURL(data);
          this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.url);
          this.playVideo = true;
          this.mediaLoaded = true;
        }

        if (this.botMedia.mediaType === "pdf") {
          this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          this.mediaLoaded = true;
        }

        if (
          this.botMedia.mediaType === "txt" ||
          this.botMedia.mediaType === "xls" ||
          this.botMedia.mediaType === "xlsx"
        ) {
          this.txtFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          this.mediaLoaded = true;
        }

        if (
          this.botMedia.mediaType === "xls" ||
          this.botMedia.mediaType === "xlsx"
        ) {
          this.txtFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          this.mediaLoaded = true;
        }
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  closeResult!: string;

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
