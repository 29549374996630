import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-list-invoice-details',
  templateUrl: './list-invoice-details.component.html',
  styleUrls: ['./list-invoice-details.component.scss']
})
export class ListInvoiceDetailsComponent implements OnInit {

  

  constructor() { }

  ngOnInit(): void {
  
  }
}
