import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotCategory } from "src/app/BOT_CODE/bot_classes/bot-category";
import { BotItem } from "src/app/BOT_CODE/bot_classes/bot-item";
import { FileServiceService } from "src/app/service/file-service.service";

@Component({
  selector: "app-create-bot-item",
  templateUrl: "./create-bot-item.component.html",
  styleUrls: ["./create-bot-item.component.scss"],
})
export class CreateBotItemComponent implements OnInit {
  matSpinnerActive = false;
  clicked = false;
  loggedInUser: any;
  theme: any;
  context: any;

  constructor(
    private sharedDataService: SharedDataService,
    private botOfficeService: BotOfficeServiceService,
    public activeModal: NgbActiveModal,
    private imageService: FileServiceService,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  @Input() fromParent: any;

  botCategories: BotCategory[] = [];
  botItem: BotItem = new BotItem();
  imageUpload = false;
  selectedFile!: File;
  safeImageUrl: any;

  ngOnInit(): void {
    this.context = this.fromParent.context;

    if (this.context === "create item") {
      this.botCategories = this.fromParent.botCategories;
      this.botItem.fkBotCategoryId = "";
    }

    if (this.context === "edit item") {
      let tempItem: BotItem = new BotItem();
      tempItem = this.fromParent.botItem;
      this.botItem.botItemDescription = tempItem.botItemDescription;
      this.botItem.botItemId = tempItem.botItemId;
      this.botItem.botItemImage = tempItem.botItemImage;
      this.botItem.botItemName = tempItem.botItemName;
      this.botItem.pricePerItem = tempItem.pricePerItem;
      this.botItem.safeImageUrl = tempItem.safeImageUrl;
      this.botItem.availability = tempItem.availability;
      this.botItem.image_url = tempItem.image_url;
      this.botItem.condition = tempItem.condition;
      this.botItem.website_link = tempItem.website_link;
      this.botItem.retailerId = tempItem.retailerId;
      this.botItem.brand = tempItem.brand;
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.imageUpload = true;
  }

  createBotItem() {
    this.clicked = true;
    this.botOfficeService
      .createBotItem(
        environment.apiUrl + "botOfficeShop/createBotItem",
        this.botItem
      )
      .then((res) => {
        this.botItem = res;

        if (this.imageUpload === false) {
          this.botItem.imageUpload = true; // image is to be displayed anyways since new item is created
          this.activeModal.close();
          this.passEntry.emit(this.botItem);
          this.toastr.success("Item added successfully !");
        } else if (this.imageUpload === true) {
          this.botItem.imageUpload = true;

          let body = new FormData();
          body.append("file", this.selectedFile);

          this.imageService
            .botItemImageUpdate(body, this.botItem.botItemId)
            .subscribe((data) => {
              let resDto: BotItem = new BotItem();
              resDto = data;

              this.botItem.botItemImage = resDto.botItemImage;
              this.activeModal.close();
              this.passEntry.emit(this.botItem);
              this.toastr.success("Item added successfully !");
            });
        }
      });
  }

  editBotItem() {
    this.clicked = true;
    this.botOfficeService
      .editBotItem(
        environment.apiUrl + "botOfficeShop/editBotItem",
        this.botItem
      )
      .then((res) => {
        this.botItem = res;

        if (this.imageUpload === false) {
          this.botItem.imageUpload = false;
          this.activeModal.close();
          this.passEntry.emit(this.botItem);
          this.toastr.success("Item updated successfully !");
        } else if (this.imageUpload === true) {
          this.botItem.imageUpload = true;

          let body = new FormData();
          body.append("file", this.selectedFile);

          this.imageService
            .botItemImageUpdate(body, this.botItem.botItemId)
            .subscribe((data) => {
              let resDto: BotItem = new BotItem();
              resDto = data;

              this.botItem.botItemImage = resDto.botItemImage;
              this.activeModal.close();
              this.passEntry.emit(this.botItem);
              this.toastr.success("Item updated successfully !");
            });
        }
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
