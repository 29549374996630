import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { Payment } from 'src/app/crmClasses/payment';
import { InvoiceService } from 'src/app/service/invoice.service';
import { PaymentService } from 'src/app/service/payment.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
}) 

export class AddPaymentComponent implements OnInit {

  loggedInUser : any;
  theme : any;

  customerId : any;
  payment : Payment = new Payment();
  clicked = false;
  invoices: InvoiceList[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private invoiceService : InvoiceService,
              public activeModal: NgbActiveModal,
              private paymentService : PaymentService,
              private sharedDataService: SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService ) { }

              @Input() fromParent: any;

  ngOnInit(): void {

    this.payment.fkInvoiceId = "";

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.customerId = this.fromParent.customerId;

    if(this.customerId === null){
    this.getInvoiceNo(); 
    }else if(this.customerId !== null){
      this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.customerId).then(
        data=>{
          this.invoices = data;
        });
    }
  }

  onSubmit(){
    this.paymentService.addPayment(environment.apiUrl + 'payment/add', this.payment).then( 
      data =>{
      this.payment = data;  
      this.passEntry.emit(this.payment);
      this.activeModal.close();
      this.toaster.success("Payment Created Successfully"); 
    });
  }

  getInvoiceNo(){
    // this.invoiceService.getInvoiceList().subscribe(data => {
    //   this.invoices= data;
    // }); 
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

}
