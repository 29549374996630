
<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
    <img style="margin: 10px"
    src="/assets/images/courseManagementSystem.svg"
        class="me-2"
        height="30" 
        alt=""
        loading="lazy"
      /> 
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Super Admin Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="open(logOutModal)" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  <ng-template #logOutModal let-modal>

    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" 
      id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
      <h2 style="text-align: center;color: black;">Are you sure you want to logout ?</h2>
  
    </div> 
    <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-danger" (click)="logOut();modal.dismiss('Cross click')">Logout</button>
  </div>
  </ng-template>



<br>

<div class="content">

<div class="header">
    <h3>Skill Report</h3>
      </div>

      <button style="cursor:pointer;width: auto;float: right;margin: 1%;" class="btn btn-info" (click)="openStudentSelectionModal()">Select Student</button>

      <button style="cursor:pointer;width: auto;float: right;margin: 1%;" class="btn btn-info" (click)="openAddResultModal()">Add Results</button>

      <table>
        <thead class="tableheader" >
          <tr>
            <th>#</th>
            <th>Skill Image</th>
            <th>Skill Name</th>
            <th>History</th>
            <th>Suggestion</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr >
            <td style="width: 5rem;">
              <span>
               #
              </span>
            </td>
            <td style="width: 17rem;">skillName</td>
            <td style="width: 17rem;">skillName</td>
            <td style="width: 17rem;">resultHistory</td>
            <td style="width: 17rem;">esult</td>
          </tr> -->
          <tr *ngFor="let result of results">
            <td>
              <span>
               #
              </span>
            </td>
            <td class="td-height" style="width: 17rem;">
            
              <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="result.safeUrl">
            
            </td>
            <td class="td-height" style="width: 17rem;">{{result.skillName}}</td>
            <td class="td-height" style="width: 17rem;">{{result.resultHistory}}</td>
            <td class="td-height" style="width: 17rem;">{{result.resultSuggestion}}</td>
            <td class="td-height" style=" height: 2rem;">
              <button style="margin: 2%;" *ngIf="result.resultStatus!== null && result.resultStatus" type="button"
                class="btn btn-danger mr-2  btn-sm" (click)="updateResultStatus(result.resultId)">Deactivate</button>
              <button  style="margin: 2%;" *ngIf="result.resultStatus !== null && !result.resultStatus" type="button"
                class="btn btn-success mr-2" (click)="updateResultStatus(result.resultId)">Activate</button>
            
                <button class="btn btn-primary" (click)="editResult(result);" style="margin: 2%;" >Edit</button>
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>