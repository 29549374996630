import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacherComponent } from 'src/app/course-management/combinedParentTeacher/add-combined-parent-teacher/add-combined-parent-teacher.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { AddParentComponent } from '../add-parent/add-parent.component';
import { AddUserModalComponent } from '../add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { ProfileModalComponent } from '../profile-modal/profile-modal.component';
import { StudentCreationFileUploadModalComponent } from '../student-creation-file-upload-modal/student-creation-file-upload-modal.component';
import { UserSearchModalComponent } from '../user-search-modal/user-search-modal.component';

@Component({
  selector: 'app-class-admin-subscription-dashboard',
  templateUrl: './class-admin-subscription-dashboard.component.html',
  styleUrls: ['./class-admin-subscription-dashboard.component.scss']
})
export class ClassAdminSubscriptionDashboardComponent implements OnInit {
 
  selectedOption : any = '0';
  navbarTitle : any;
  currentRole = "CLASS_ADMIN";
  showSpinner : boolean = false;
  classLoaderMessage!: string;
  classExists: boolean = false;
  classExists2: boolean = false;
  showStudents: boolean = false;
  showParents: boolean = false;
  classOptions: any = {};
  loggedInUser: any;
  value : any
  tableUsersExist = false;
  officeId!: string;
  userName!: string;
  userId!: string;
  subscriptions : any[] = [];
  statusUpdateUserId : any;
  users : any[] =[{
                    address: '',
                    dateOfBirth: '',
                    email: '',
                    firstName: '',
                    gender: '',
                    lastName:'',
                    office: '',
                    phoneNumber: '',
                    role:'',
                    userId: '',
                    parentId : '',
                    userImage : '',
                    status: '',
                    safeUrl: '',
                    roles : '',
                    isParent : '',
                    isStudent : '',
                    isParentMentor : '',
                    isChildStudent : ''
                  }]

  usersForTable : any[] =[{
    active:'',
    dateOfBirth: '',
    email:'',
    firstName: '',
    gender: '',
    lastName: '',
    phoneNumber:'',
    userId: '',
    parentId : ''
  }]
  closeResult! : string;

  officeObject : any = {
    activeStatus: '',
    backgroundColor: '',
    id:'',
    logo: '',
    oa_ParentMentor:'',
    oa_SubAdmin: '',
    oa_CRMAdmin : '',
    oa_CourseAdmin : '',
    oa_ClassAdmin : '',
    oa_SkillReport : '',
    officeLocation: '',
    officeName: '',
    themeColour: '',
    type:'',
    leadConversionType:''
  }

  // officeObject : office = new Office();

  public screenWidth: any;
  public screenHeight: any;
  
  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private toaster: ToastrService,
              private officeService : OfficesService,
              private sharedDataService : SharedDataService,
              private subscriptionService : SubscriptionService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    safeStudentUrl : any;
    unsafeStudentUrl : any;

    ngOnInit(): void {

      this.showSpinner = true;

      this.navbarTitle = GlobalClass.navbarTitle;
      
          this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
          this.officeId = this.loggedInUser.offices[0].id;
          this.userId = this.loggedInUser.userId;
          this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
          
    this.officeService.getOfficeByIdForClassAdmin(environment.apiUrl + "admin/getOfficeByIdForClassAdmin").then(
      res=>{ 
      this.officeObject = res; 
      this.showSpinner = false;
    });

          this.screenWidth = window.innerWidth;
          this.screenHeight = window.innerHeight;
      
          this.classOptions = {
            pagingType: 'full_numbers',
            pageLength: 5,
            lengthChange: false,
            processing: true,
            info:false
          }; 
        }

        get backgroundStyle(){
          let globalClass : GlobalClass = new GlobalClass() ;
          return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
        }

        valueChange(value : any){

          this.showSpinner = true;
          this.classExists2 = false;
          this.showStudents = false;
          this.showParents = false;


          if(value === '1'){

            this.usersForTable = [];

            this.subscriptionService.getTeachersInOfficeForClassAdmin(environment.apiUrl + "teacher/getTeachersInOfficeForClassAdmin/" + this.officeId).then(
              res=>{
                this.usersForTable = res;
                this.tableUsersExist = true;
                this.classExists2 = true;
              });

              this.showSpinner = false;

          }else if(value === '2'){

            this.usersForTable = [];

            this.subscriptionService.getStudentsInOfficeForClassAdmin(environment.apiUrl + "teacher/getStudentsInOfficeForClassAdmin/" + this.officeId).then(
              res=>{
                
                this.usersForTable = res;
                this.tableUsersExist = true;
                this.classExists2 = true;
                this.showStudents = true;
              });

              this.showSpinner = false;
           
          }else if(value === '3'){

            this.usersForTable = [];

            this.subscriptionService.getParentsInOfficeForClassAdmin(environment.apiUrl + "teacher/getParentsInOfficeForClassAdmin/" + this.officeId).then(
              res=>{

                this.usersForTable = res;
                this.tableUsersExist = true;
                this.classExists2 = true;
                this.showParents = true;
              });

              this.showSpinner = false;
            
          }
         
        }

        onChange(event : any){ 

          let value = this.selectedOption;

          this.showSpinner = true;
          this.classExists2 = false;
          this.showStudents = false;
          this.showParents = false;


          if(value === '1'){

            this.usersForTable = [];

            this.subscriptionService.getTeachersInOfficeForClassAdmin(environment.apiUrl + "teacher/getTeachersInOfficeForClassAdmin/" + this.officeId).then(
              res=>{
                this.usersForTable = res;
                this.tableUsersExist = true;
                this.classExists2 = true;
              });

              this.showSpinner = false;

          }else if(value === '2'){

            this.usersForTable = [];

            this.subscriptionService.getStudentsInOfficeForClassAdmin(environment.apiUrl + "teacher/getStudentsInOfficeForClassAdmin/" + this.officeId).then(
              res=>{
                
                this.usersForTable = res;
                this.tableUsersExist = true;
                this.classExists2 = true;
                this.showStudents = true;
              });

              this.showSpinner = false;
           
          }else if(value === '3'){

            this.usersForTable = [];

            this.subscriptionService.getParentsInOfficeForClassAdmin(environment.apiUrl + "teacher/getParentsInOfficeForClassAdmin/" + this.officeId).then(
              res=>{

                this.usersForTable = res;
                this.tableUsersExist = true;
                this.classExists2 = true;
                this.showParents = true;
              });

              this.showSpinner = false;
            
          }

          if(value === '0'){
            this.showSpinner = false;
          }
        }

        openProfileModalForParent(userId : any){
          
          let parentType = "PARENT";
          if(this.officeObject.leadConversionType==="Customer And ParentMentor"){
            parentType = "PARENT_MENTOR";
          }

          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(ProfileModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      let data = {
        userId: userId,
        role : "PARENT",
        parentType : parentType
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
            
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            
          }); 
        }

        openProfileModalForStudent(userId : any, parentId : any){

          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(ProfileModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        userId: userId,
        role : "STUDENT",
        parentUserId : parentId
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
            
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            
          }); 
        }
        
        openProfileModalForChildStudent(userId : any, parentId : any){

          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(ProfileModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        userId: userId,
        role : "STUDENT",
        parentUserId : parentId,
        noSubsciptionsOption : true,
        isChildStudent : true
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
            
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            
          }); 
        }
 

        searchFoUser(){
          this.showSpinner = true;
          this.subscriptionService.getUsersByEmailOrPhone(environment.apiUrl + "user/getUsersByEmailOrPhone/" + this.value + "/" + this.officeId).then(
            data=>{
              this.users = data;

              if(this.users.length === 0 || this.users === undefined){
                setTimeout(()=>{                           //timer
                  this.showSpinner = false;
             }, 1500);
              }

              let count : number = 0;
              this.users.forEach( (value) => {
                count++;
                this.imageService.getUserImage(value.userImage).subscribe(
                data=>{
                this.unsafeStudentUrl = URL.createObjectURL(data);
                this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
                
                value.safeUrl = this.safeStudentUrl;
                if(count === this.users.length){
                  setTimeout(()=>{                           //timer
                    this.showSpinner = false;
               }, 1500);
                }
                });

                value.roles.forEach((val : any)=>{
                  if(val === "PARENT"){
                    value.isParent = true;
                  }
                  if(val === "STUDENT"){
                    value.isStudent = true;
                  }
                  if(val === "PARENT_MENTOR"){
                    value.isParentMentor = true;
                  }
                  if(val === "CHILD_STUDENT"){
                    value.isChildStudent = true;
                  }
                })





                });
              this.classExists = true;
              
              if(this.users === undefined || this.users.length === 0 ){
              this.toaster.error('User not found !')
              this.showSpinner = false;
              }

              
            });
        }

        userSearchModal(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open( UserSearchModalComponent,
            {
              size : 'md',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
          let data = { 
            officeId : this.officeId,
          }
      
          modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
            this.showSpinner = true;
            this.subscriptionService.getUsersByEmailOrPhone(environment.apiUrl + "user/getUsersByEmailOrPhone/" + receivedEntry + "/" + this.officeId).then(
              data=>{
                
                this.users = data;
  
                if(this.users.length === 0 || this.users === undefined){
                  setTimeout(()=>{                           //timer
                    this.showSpinner = false;
               }, 1500);
                }
  
                let count : number = 0;
                this.users.forEach( (value) => {
                  count++;
                  this.imageService.getUserImage(value.userImage).subscribe(
                  data=>{
                  this.unsafeStudentUrl = URL.createObjectURL(data);
                  this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
                  
                  value.safeUrl = this.safeStudentUrl;
                  if(count === this.users.length){
                    setTimeout(()=>{                           //timer
                      this.showSpinner = false;
                 }, 1500);
                  }
                  });


                  value.roles.forEach((val : any)=>{
                    if(val === "PARENT"){
                      value.isParent = true;
                    }
                    if(val === "STUDENT"){
                      value.isStudent = true;
                    }
                    if(val === "PARENT_MENTOR"){
                      value.isParentMentor = true;
                    }
                    if(val === "CHILD_STUDENT"){
                      value.isChildStudent = true;
                    }
                  })
  

                  });
                this.classExists = true;
                
                if(this.users === undefined || this.users.length === 0 ){
                this.toaster.error('User not found !')
                this.showSpinner = false;
                }
              });
          }); 
        }



        searchValue = null;
        searchInput = true;
        userExists = true;
        profile : CompleteCustomerDetails = new CompleteCustomerDetails();
        searchFoUserByEmail(){

          this.subscriptionService.searchUserByEmail(this.searchValue).subscribe(
            res=>{
              this.userExists = res;
              if(this.userExists == true){
                this.searchInput = false;
              }else{
                this.ngbModalService.dismissAll();
                this.addUser();
              }
            });

        }

        updateUserStatus(){
          this.subscriptionService.userStatusUpdate(environment.apiUrl + "user/userStatusUpdate/" + this.statusUpdateUserId).then(
            data=>{
              this.users.forEach((value)=>{
                if(value.userId === this.statusUpdateUserId){
                  if(value.status === true){
                    value.status = false;
                  }else if(value.status === false){
                    value.status = true
                  }
                }
              });

              this.usersForTable.forEach((value)=>{
                if(value.userId === this.statusUpdateUserId){
                  if(value.active === true){
                    value.active = false;
                  }else if(value.active === false){
                    value.active = true
                  }
                }
              });


            });
        }
 
        
        editUser(userId : any){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(EditUserModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        userId: userId 
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
            
            
          }); 
        }

        addUser(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(AddUserModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        officeId: this.officeId ,
        type : "STUDENT",
        userEmail : this.searchValue
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            this.value = receivedEntry ;
            this.searchFoUser();
          }); 
        }

        studentCreationFileUpload(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(StudentCreationFileUploadModalComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        officeId: this.officeId ,
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
         
          }); 
        }

        addParent(studentUserId : any){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(AddParentComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        officeId: this.officeId ,
        studentUserId : studentUserId
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            this.value = receivedEntry ;
            this.searchFoUser();
          }); 
        }

        addCombinedPTCUser(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open(AddCombinedParentTeacherComponent,
            {
              size : 'lg',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
            
      
      let data = {
        userOfficeId: this.officeId 
          }
      
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
            
            
          });  
        } 

        switchDashboard(){
          this.config.class = 'modal-md';
          const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
            {
              size : 'sm',
              scrollable: true,
              windowClass: 'app-modal-window',
              keyboard: false
            });
          let data = { 
            userId : this.userId,
            currentRole : this.currentRole
          }
      
          modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });
      
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
          });    
        }
    
        goToAccountPage(){
          this.router.navigate(['user-account',this.userId])
        }
        
        open(content: any) {
          this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
        private getDismissReason(reason: any): string {
          this.searchValue = null;
          this.searchInput = true;
          if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
          } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
          } else {
            return `with: ${reason}`;
          }
          
        }
      
      
      
      
        getColor(){
            this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
            return this.loggedInUser.offices[0].theme + '!important';
          }
      
          logOut(): void {
            this.config.class = 'modal-md';
            const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
              {
                size : 'md',
                scrollable: true,
                windowClass: 'app-modal-window',
                keyboard: false
              });
            let data = {
             email : this.loggedInUser.email 
            }
        
            modalRef.componentInstance.fromParent = data;
              modalRef.result.then((result) => {
              }, (reason) => {
              });
        
            modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
            });  
          }
              
        reloadPage() {
          window.location.reload();
        }
      
}
