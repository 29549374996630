<div class="modal-header">
  <h4 class="modal-title pull-left">{{headerText}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="addOffice" method="post" enctype="multipart/form-data" (ngSubmit)="saveToList(addOffice)">
        <div class="form-group">
          <label for="if-office" class="required">Office Name</label>
          <input type="text" id="if-office" name="if-office" class="form-control" formControlName="name"
            placeholder="Enter Office Name..">
        </div>
        <div class="form-group">
          <label for="if-office-location" class="required">Office Location</label>
          <input type="text" id="if-office-location" name="if-office-location" class="form-control"
            formControlName="location" placeholder="Enter Office Location..">
        </div>
<div class="form-group">
<label for="type" class="required">Type</label>
<div class="custom-control custom-radio">
<input class="custom-control-input" formControlName="type" id="eOffice" name="type" type="radio" value="Edu_Office">
<label class="custom-control-label" for="eOffice">Edu Office</label>
</div>
<div class="custom-control custom-radio">
<input class="custom-control-input" formControlName="type" id="bOffice" name="type" type="radio" value="Biz_Office">
<label class="custom-control-label" for="bOffice">Biz Office</label>
</div>
<div class="custom-control custom-radio">
  <input class="custom-control-input" formControlName="type" id="cbOffice" name="type" type="radio" value="CB_Office">
  <label class="custom-control-label" for="cbOffice">Convo Bot Office</label>
  </div>
<div class="custom-control custom-radio">
<input class="custom-control-input" formControlName="type" id="Organisation" name="type" type="radio" value="Organisation">
<label class="custom-control-label" for="Organisation">Organisation</label>
</div>
<div class="custom-control custom-radio">
  <input class="custom-control-input" formControlName="type" id="CB_Organisation" name="type" type="radio" value="CB_Organisation">
  <label class="custom-control-label" for="CB_Organisation">CB Organisation</label>
  </div>
</div>
<div class="form-group">
  <label for="leadConversionType" class="required">Lead Conversion :</label>
  <div class="custom-control custom-radio">
  <input class="custom-control-input" formControlName="leadConversionType" id="Customer Only" name="leadConversionType" type="radio" value="Customer Only">
  <label class="custom-control-label" for="Customer Only">Customer Only</label>
  </div>
  <div class="custom-control custom-radio">
  <input class="custom-control-input" formControlName="leadConversionType" id="Customer And User" name="leadConversionType" type="radio" value="Customer And User">
  <label class="custom-control-label" for="Customer And User">Customer And User</label>
  </div>
  <div class="custom-control custom-radio">
  <input class="custom-control-input" formControlName="leadConversionType" id="Customer And ParentMentor" name="leadConversionType" type="radio" value="Customer And ParentMentor">
  <label class="custom-control-label" for="Customer And ParentMentor">Customer And ParentMentor</label>
  </div>
  </div>
        <div class="form-group row">
          <div *ngIf="officeObject?.logo === null" class="col-md-12">
            <label for="if-office-logo" class="required">Office Logo</label>
            <app-file-upload formControlName="image" [progress]="progress"></app-file-upload>
          </div>
          <div *ngIf="officeObject?.logo !== null" class="col-md-9">
            <label for="if-office-logo" class="required">Office Logo</label>
            <app-file-upload formControlName="image" [progress]="progress"></app-file-upload>
          </div>
          <div *ngIf="officeObject?.logo !== null" class="col-md-3">
            <img class="logo-image" [src]="'data:image/jpeg;base64,'+officeObject.logo" />
          </div>
        </div>
        <div class="form-group">
          <label for="if-office-theme">Office Theme</label>
          <!-- <input type="text" id="if-office-theme" name="if-office=theme" class="form-control" formControlName="theme"
            placeholder="Enter Office Theme.."> -->
          <!-- <input [colorPicker]="form.get('logo').value" [value]="form.get('logo').value"
            (colorPickerChange)="colorChanged($event)" class="colorPicker" formControlName="logo"> -->
            <input [(colorPicker)]="color1" [style.background]="color1"  class="form-control"  />

        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button [disabled]="clicked"  type="submit" class="btn btn-sm btn-primary float-right" (click)="saveToList(addOffice);clicked=true">
    Save</button>
</div>
