import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Address } from '../crmClasses/address';
 
@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private deleteUrl =  environment.apiUrl + "crmaddress/delete";
  private getUrl =  environment.apiUrl + "crmaddress/getById";
  private updateUrl = environment.apiUrl +  "crmaddress/update";

  constructor(private httpClient : HttpClient) { } 

  deleteAddress(addressId : string) : Observable<Object>{
    return this.httpClient.delete(`${this.deleteUrl}/${addressId}`);
  }

  addCustomer(address : Address):Observable<Address>{
    return this.httpClient.post<Address>( environment.apiUrl + 'crmaddress/add', address);
  }

  getAddressById(addressId : string):Observable<Address>{
    return this.httpClient.get<Address>(`${this.getUrl}/${addressId}`);
   } 
   updateAddress(address:Address):Observable<Object>{
     return this.httpClient.put(`${this.updateUrl}/`,address);
   }

} 
