<div  [style.background-color]='getColor()' class="modal-header">
    <h4 *ngIf="context==='Upload New Media'" style="color: white;" class="modal-title pull-left"> Upload media</h4>
    <h4 *ngIf="context==='uploadMediaToResumableUploadApi'" style="color: white;" class="modal-title pull-left"> Upload media</h4>
    <h4 *ngIf="context==='Reupload Media'" style="color: white;" class="modal-title pull-left"> Media Reupload </h4>
    <h4 *ngIf="context==='Edit Media'" style="color: white;" class="modal-title pull-left"> Edit media</h4>
    
    <h4 *ngIf="context==='Send Response With Media And Caption'" 
    style="color: white;" class="modal-title pull-left"> Share {{botMedia.mediaType}} to {{botMedia.receiverBotUsername}}</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  


   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form #aplnrForm="ngForm"> 

    <div *ngIf="context==='Reupload Media'">
        
      <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

      <div *ngIf="matSpinnerActive===false">

        <div>
            <label>Choose media file to submit</label>
            <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
         </div>

         <br>
         <a *ngIf="!supportedMediaTypesVisible" style="color: black;">
          <a (click)="supportedMediaTypesVisible=!supportedMediaTypesVisible;" style="color: blue;cursor: pointer;">Click here</a>
           to see supported media types and size limits</a>

         <div *ngIf="supportedMediaTypesVisible">
          <a>
            <img src="/assets/images/snapshots/metaSupportedMediaTypes.PNG" width="740" height="500"
            alt="" loading="lazy">
         </a>
        </div> 

      </div> 

        </div>

        <div *ngIf="context==='Upload New Media'">
        
          <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

          <div *ngIf="matSpinnerActive===false">
         

          <div>
              <label>Choose media file to submit</label>
              <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
           </div>
           <br>
              <div>
              <label class="required" for="title">Title</label>
              <input #title="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
              placeholder="Enter Title" name="title" id="title" [(ngModel)]="botMedia.title">
              <div class="alert alert-danger" *ngIf="title.touched && !title.valid">Title is Required</div>
              </div>
  <br>
          <div>
              <label for="description">Description</label>
              <textarea class="form-control" type="text" name="description" 
               id="description" [(ngModel)]="botMedia.description"></textarea>
           </div> 
           <br>
           <a *ngIf="!supportedMediaTypesVisible" style="color: black;">
            <a (click)="supportedMediaTypesVisible=!supportedMediaTypesVisible;" style="color: blue;cursor: pointer;">Click here</a>
             to see supported media types and size limits</a>
           <div *ngIf="supportedMediaTypesVisible">
            <a>
              <img src="/assets/images/snapshots/metaSupportedMediaTypes.PNG" width="740" height="500"
              alt="" loading="lazy">
           </a>
          </div> 

        </div>
          </div>

          <div *ngIf="context==='uploadMediaToResumableUploadApi'">
        
            <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>
  
            <div *ngIf="matSpinnerActive===false">
           
  
            <div>
                <label>Choose media file to submit</label>
                <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
             </div>
             <br>
                <div>
                <label class="required" for="title">Title</label>
                <input #title="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
                placeholder="Enter Title" name="title" id="title" [(ngModel)]="botMedia.title">
                <div class="alert alert-danger" *ngIf="title.touched && !title.valid">Title is Required</div>
                </div>
    <br>
            <div>
                <label for="description">Description</label>
                <textarea class="form-control" type="text" name="description" 
                 id="description" [(ngModel)]="botMedia.description"></textarea>
             </div> 
             <br>
             <a *ngIf="!supportedMediaTypesVisible" style="color: black;">
              <a (click)="supportedMediaTypesVisible=!supportedMediaTypesVisible;" style="color: blue;cursor: pointer;">Click here</a>
               to see supported media types and size limits</a>
             <div *ngIf="supportedMediaTypesVisible">
              <a>
                <img src="/assets/images/snapshots/metaSupportedMediaTypes.PNG" width="740" height="500"
                alt="" loading="lazy">
             </a>
            </div> 
  
          </div>
            </div>
   
        <div *ngIf="context==='Edit Media'">


          <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

          <div *ngIf="matSpinnerActive===false">
          


        <div>
              <label class="required" for="title">Title</label>
              <input #title="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
              placeholder="Enter Title" name="title" id="title" [(ngModel)]="botMedia.title">
              <div class="alert alert-danger" *ngIf="title.touched && !title.valid">Title is Required</div>
              </div>           
  <br>
          <div>
              <label for="description">Description</label>
              <textarea class="form-control" type="text" name="description" 
               id="description" [(ngModel)]="botMedia.description"></textarea>
           </div> 

          </div>
          </div>

          <div *ngIf="context==='Send Response With Media And Caption'">

            <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

          <div *ngIf="matSpinnerActive===false">
          
        
            <div *ngIf="botMedia.mediaType==='image'">
                <label>Choose image</label>
                <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control"
                 type="file" accept=".png,.jpeg">
             </div>
             <div *ngIf="botMedia.mediaType==='audio'">
              <label>Choose audio</label>
              <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control"
               type="file" accept=".mp4,.mp3,.ogg,.amr,.mpeg,.aac">
           </div>
             <div *ngIf="botMedia.mediaType==='video'">
              <label>Choose video</label>
              <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" 
              type="file" accept=".mp4, .3gp">
           </div>
           <div *ngIf="botMedia.mediaType==='document'">
            <label>Choose document</label>
            <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" 
            type="file" accept=".pdf, .text, .xls, .xlsx">
         </div>
             <br>
            <div>
                <label for="description">Caption (Optional)</label>
                <textarea class="form-control" type="text" name="description" 
                 id="description" [(ngModel)]="botMedia.description"></textarea>
             </div> 
    
            </div>
            </div>

    </form>
      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button *ngIf="context==='Upload New Media'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
    (click)="matSpinnerActive=true;uploadNewMedia();">Upload</button>
    <button *ngIf="context==='uploadMediaToResumableUploadApi'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
    (click)="matSpinnerActive=true;uploadMediaToResumableUploadApi();">Upload</button>
    <button *ngIf="context==='Reupload Media'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
    (click)="matSpinnerActive=true;reuploadMedia();">Upload</button>
    <button *ngIf="context==='Edit Media'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
    (click)="matSpinnerActive=true;updateMedia();">Update</button>
    <button *ngIf="context==='Send Response With Media And Caption'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
    (click)="matSpinnerActive=true;sendMediaResponse();">Send</button>
  </div>