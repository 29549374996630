import { InvoiceDetails } from "./invoice-details";
import { Product } from "./product";

export class InvoiceList {

    //class to get invoice list
    invoiceNo!: string;
	invoiceId!: string;
	invoiceDate!: any;
	actualInvoiceDate : any;
	formattedActualInvoiceDate : any;
	totalQuantity : any;
	totalAlternateQuantity : any
	outstandingAmount : any;
	creditPeriod : any;
	customerContact : any;
	customerEmail : any;

	updateCustomerOutstanding : any;

	subTotalAmount : any;

	deliveryRemarks : any;

	totalBeforeDiscount : any;
	totalAmount!: any;
	grandTotal : any;

	totalCess : any
	totalTax : any

	incentiveEligibity : any;

	discount : any = 0;

	taxed : any;
	dueStatus : any; // statement
	overDue : any; // color

	products : Product[] =[]

	invoices : InvoiceList[] = [];

	officeCreditPeriod : any;

	invoiceRemarks!: string;
	invoiceStatus!: boolean;
	fkCustomerId!: string;
	customerName!: string; 
	paymentMethod!: string;
	orderStatus! : string;
	type ! : string;
	executiveName : any;
	customerAddress : any;
	shipmentExists : any;
	shipmentId : any;

	locationGroupName: any;
	locationGroupCode: any;
	locationGroupId: any;

	taxesAndCess: InvoiceDetails[] = [];

	batchShipment : any = false;

	verifiedByUroId : any;
	verifiedUserName : any;

}
