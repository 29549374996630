import { FeedbackAnswerValue } from "./feedback-answer-value";

export class FeedbackAnswer {

    id:any;
	activeStatus:any;
	answer:any;
	
	answerValuesList : FeedbackAnswerValue[] = [];
	answerValuesListNew : FeedbackAnswerValue[] = [];

}
