import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { ProfileComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/Customer/profile/profile.component';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bcrm-man-leads',
  templateUrl: './bcrm-man-leads.component.html',
  styleUrls: ['./bcrm-man-leads.component.scss']
})
export class BcrmManLeadsComponent implements OnInit {

  currentRole = "BCRM_MAN";
  customerExists: boolean = false;
  userName ! : string;
  customerOptions : any;

  ccdetails: CompleteCustomerDetails[] = [];
  showSpinner = false;
  leads : Customer[] = [];

  customer : CompleteCustomerDetails = new CompleteCustomerDetails();
  totalCount : any = 0;

  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  
  customerId: any;
  userId!: string
  navbarTitle: any;

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private spinner: NgxSpinnerService, 
              private toaster: ToastrService,
              public bsModalRef: BsModalRef,
              private ccdetailsService:CompCustDetailsService, 
              private CustomerService : CustomerService,
              private sharedDataService : SharedDataService,
              private logoutService : LogoutService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

  ngOnInit(): void {
    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getCcDetails();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

  // authorized
  getCcDetails(){
    this.showSpinner = true;
    this.CustomerService.getLeadsForBCRM_MANByUroId(environment.apiUrl + 'bcrmLead/getLeadsForBCRM_MANByUroId').then(
      data=>{
        this.leads = data;
        this.dataSource = this.leads
       this.totalCount = this.leads.length;
        this.customerExists = true;
        this.showSpinner = false;
      });
  }
  dataSource = this.leads;
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      Executive: x.executiveName,
      Customer: x.firstName,
      Email: x.email,
      GST: x.gst,
      Phone: x.phoneNumber,
      Address: x.customerAddress
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  } 

  openCustomerProfileModalComponent(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ProfileComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: id ,
          type : 'LEAD'
        }
       
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {

    });  
  }

   // authorized
  updateCustomerStatus(customerId : string){
    this.showSpinner = true;
 this.ccdetailsService.changeStatus(environment.apiUrl + "crmCustAdd/statusUpdate/" + customerId).then( 
   data =>{
      this.leads.forEach((lead)=>{
        if(lead.customerId===customerId){
          lead.activeStatus=!lead.activeStatus;
        }
      });
      this.showSpinner = false;
      this.toaster.success("Customer status updated Successfully");
      });
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
 
  // authorized
    convertToCustomer(customerId : string){
      this.showSpinner = true;
      this.ccdetailsService.changeCustomerType(environment.apiUrl + "crmCustAdd/changeType/" + customerId).then(
        data=>{
        this.removeLeadFromList(customerId);
        this.toaster.success('Lead Changed Successfully');
        this.ngbModalService.dismissAll();
        this.showSpinner = false;
        });
    }

    removeLeadFromList(id : any){
      this.leads.forEach( (lead, index) => {
        if(lead.customerId === id) this.leads.splice(index,1);
      });
    }

      open(content: any) {
        this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
        
      }

    getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }

    reloadPage() {
      window.location.reload();
    }
}  