import { Options } from "@angular-slider/ngx-slider";
import { FeedbackAnswer } from "./feedback-answer";
import { FeedbackAnswerValue } from "./feedback-answer-value";

export class FormResponse {
    id:any;
	activeStatus:any;
	fkQuestionId:any;
	campaignId:any;
	fkCustomerId:any;
	fkAnswerValueId:any;
    answerValue:any;

	mandatory : any;

	answerValueTimestamp : any;
	answerType : any;
	answerValueTimestampFormatted : any;

	answers : string[] = [];

	answer : any;
	
	question : any;
	questionDescription : any;

	feedbackAnswerDto : FeedbackAnswer = new FeedbackAnswer();

	feedbackAnswerValuesDto : FeedbackAnswerValue[] = [];

	sliderValue : any;
	sliderOptions : Options = {
		showTicksValues: true,
		stepsArray: [
		  { value: 1 },
		  { value: 2 },
		  { value: 3 },
		  { value: 4 },
		  { value: 5 },
		  { value: 6 },
		  { value: 7 },
		  { value: 8 },
		  { value: 9 },
		],
	  };

	  validResponse : any;
}
