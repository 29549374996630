import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
import { ChooseTaxesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/tax/choose-taxes/choose-taxes.component';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { InvoiceDetailsList } from 'src/app/crmClasses/invoice-details-list';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { InvoiceService } from 'src/app/service/invoice.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { AddInvoiceDetailsComponent } from '../add-invoice-details/add-invoice-details.component';
import { UpdateInvoiceDetailsComponent } from '../update-invoice-details/update-invoice-details.component';

@Component({ 
  selector: 'app-update-invoice',
  templateUrl: './update-invoice.component.html',
  styleUrls: ['./update-invoice.component.scss']
})
export class UpdateInvoiceComponent implements OnInit {

  type : any;
  officeId!: string;
  clicked = false;
  invoiceLoader = false;
  invoiceLoaderMessage!: string;
  invoiceExists!: boolean;
  invoiceOptions: any = {};
  totalAmount : any = 0;
  totalQuantity : any = 0;
  amount : any;

    invoice : InvoiceList = new InvoiceList();
    invDetails: InvoiceDetailsList[] = [];
    invDetailsObject : InvoiceDetailsList = new InvoiceDetailsList();
    invoiceId!: string;

    @Input() fromParent: any; 
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    invoiceDetailsList: InvoiceDetails[] = [];
    closeResult!: string;
    invoiceDet : InvoiceDetails = new InvoiceDetails;
    prod : Product = new Product();
    products: Product[] = [];
    loggedInUser: any;
    theme: any;

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private invoiceService : InvoiceService, 
              private  productService : ProductService,
              private sharedDataService : SharedDataService,
              private ngbModalService: NgbModal, 
              private spinner: NgxSpinnerService,
              public bsModalRef: BsModalRef ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;
    addedProductIds : string[] = [];
    taxesAndCess : Tax[] = [];
    discount : any;
    discountObj : InvoiceDetailsList = new InvoiceDetailsList();
    discounted = false;

  ngOnInit(): void { 

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.theme = this.sharedDataService.getCardOfficeTheme();

      this.invoiceId = this.fromParent.invoiceId;
      this.type = this.fromParent.type;

      this.invoiceService.getInvoiceById(this.invoiceId).subscribe(data=>{
      this.invoice = data;
       });

      this.invoiceService.getAllInvDetails(this.invoiceId).subscribe(
        data =>{
        this.invDetails = [];
      this.invDetails = data;
      
      this.taxesAndCess=[];
      this.invDetails.forEach((invDet)=>{
        if(invDet.type === "tax" || invDet.type === "cess"){
          this.taxesAndCess.push(invDet.taxObject);
        }else if(invDet.type === "invoice detail"){
          this.addedProductIds.push(invDet.fkProductId);
        }else if(invDet.type === "discount"){
          this.discount = invDet.taxAmount;
          this.discounted = true;
          this.discountObj = invDet;
          this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;
        }
      });

      this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;

      this.invoiceExists = true;
       });

      this.getProducts();

      this.invoiceOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        lengthChange: false,
        processing: true,
        info:false
      };
  } 
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  // authorized
  onUpdate(){

    this.invoice.updateCustomerOutstanding = true;
    
    this.invoiceService.updateInvoice(environment.apiUrl + "invoice/update", this.invoice).then(
      data =>{
        this.passEntry.emit(this.invoice);
        this.activeModal.close();
        this.toaster.success("Invoice Updated Successfully");
      }, error => console.error());
  } 

  closeModal(){
      this.activeModal.close();
 }

  edit(invoiceDetId : string){
       
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateInvoiceDetailsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        invoiceDetId: invoiceDetId,
        officeId : this.officeId,
        type : this.type
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceDetails) => {

        // this.invoiceService.getInvoiceListById(this.invoiceId).subscribe(data =>{
        //   this.invDetails = data;
        //   this.addedProductIds = [];
        //   this.invDetails.forEach((invDet)=>{
        //     if(invDet.type === "invoice detail"){
        //        this.addedProductIds.push(invDet.fkProductId);
        //      }
        //    });
        //   this.getTotalAmount();
        //   this.getTotalQuantity();
        //   this.getTotalAlternateQuantity();

        //   this.invoiceExists = true;

        //   this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
        //     data=>{

        //     });

          
        //     }, error => console.log(error));
        this.invoiceService.getAllInvDetails(this.invoiceId).subscribe(data =>{

          this.invDetails = [];
          this.invDetails = data;
          this.taxesAndCess = [];
          this.addedProductIds = [];

          this.invDetails.forEach((invDet)=>{
            if(invDet.type === "tax" || invDet.type === "cess"){
              this.taxesAndCess.push(invDet.taxObject);
            }else if(invDet.type === "invoice detail"){
              this.addedProductIds.push(invDet.fkProductId);
            }else if(invDet.type === "discount"){
              this.discount = invDet.taxAmount;
              this.discounted = true;
              this.discountObj = invDet;
              this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;
            }
          });
          // this.getTotalAmount();
          this.totalAmount = 0;
     
          this.invDetails.forEach((value)=>{
           this.amount = value.totalPrice;
           this.totalAmount = this.totalAmount + this.amount;
          })
          this.invoice.totalAmount = this.totalAmount;
          this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;

          if(this.discounted === true){
            this.invoice.totalAmount =  this.invoice.totalAmount - this.discount;
            this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;

          }

          // this.getTotalQuantity();
          this.totalQuantity = 0;
    
          this.invDetails.forEach((value)=>{
           this.totalQuantity = this.totalQuantity + value.quantity;
          })
          this.invoice.totalQuantity = this.totalQuantity;

          // this.getTotalAlternateQuantity();
          this.invoice.totalAlternateQuantity  = 0;
    
          this.invDetails.forEach((value)=>{
            this.invoice.totalAlternateQuantity  = this.invoice.totalAlternateQuantity  + value.alternateQuantity;
          })

          this.invoiceExists = true;

          this.invoice.totalTax = 0;
          this.invoice.totalCess = 0;
          this.invoice.grandTotal = 0;

          let count = 0;
          this.taxesAndCess.forEach((value)=>{
            count = count + 1;
            if(value.type === "tax"){
             let val = value.value / 100;
             let tax = this.invoice.totalAmount * val;
             value.calculatedTax = tax;
             this.invoice.totalTax = this.invoice.totalTax + tax;               
            }
          });

          if(this.taxesAndCess.length === count){
            this.taxesAndCess.forEach((value)=>{
              if(value.type === "cess"){
                let val = value.value / 100;
                let cess = this.invoice.totalTax * val;
                value.calculatedTax = cess;
                this.invoice.totalCess = this.invoice.totalCess + cess;               
              }
            });
          }
          
          this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

          this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
            data=>{

            });
            
   let taxList : InvoiceDetails[] = [];
       this.taxesAndCess.forEach((tax)=>{
         let inv = new InvoiceDetails();
         inv.taxId = tax.id;
         inv.taxType = tax.type;
         inv.taxAmount = tax.calculatedTax;
         taxList.push(inv);
       });

       this.invoice.taxesAndCess = taxList;

       this.invDetails.forEach((invDet)=>{
        if(invDet.type === "tax" || invDet.type === "cess"){
          this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
            data=>{
            });
        }
      });

      this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
        data=>{

          this.getInvoiceDetails();

        });
          
            });
      }); 
   }

   getTotalAmount(){
     this.totalAmount = 0;
     
     this.invDetails.forEach((value)=>{
      this.amount = value.totalPrice;
      this.totalAmount = this.totalAmount + this.amount;
     })
     this.invoice.totalAmount = this.totalAmount;
     this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;
   }

   getTotalQuantity(){
    this.totalQuantity = 0;
    
    this.invDetails.forEach((value)=>{
     this.totalQuantity = this.totalQuantity + value.quantity;
    })
    this.invoice.totalQuantity = this.totalQuantity;
  }

  getTotalAlternateQuantity(){
    this.invoice.totalAlternateQuantity  = 0;
    
    this.invDetails.forEach((value)=>{
      this.invoice.totalAlternateQuantity  = this.invoice.totalAlternateQuantity  + value.alternateQuantity;
    })
  }

   openADDNewInvDetailModal(invoiceId : string){
         
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(  AddInvoiceDetailsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        invoiceId: invoiceId,
        officeId : this.officeId,
        type : this.type,
        addedProductIds : this.addedProductIds
          } 

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceDetails) => {

        // this.invoiceService.getInvoiceListById(this.invoiceId).subscribe(data =>{
        //   this.invDetails = data;
        //   this.addedProductIds = [];
        //   this.invDetails.forEach((invDet)=>{
        //     if(invDet.type === "invoice detail"){
        //        this.addedProductIds.push(invDet.fkProductId);
        //      }
        //    });
        //   this.getTotalAmount();
        //   this.getTotalQuantity();
        //   this.getTotalAlternateQuantity();
        //   this.invoiceExists = true;

        //   this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
        //     data=>{

        //     });

        //    }, error => console.log(error));


        this.invoiceService.getAllInvDetails(this.invoiceId).subscribe(data =>{
          this.invDetails = [];
          this.invDetails = data;
          this.taxesAndCess=[];
          this.addedProductIds = [];
          this.invDetails.forEach((invDet)=>{
            if(invDet.type === "tax" || invDet.type === "cess"){
              this.taxesAndCess.push(invDet.taxObject);
            }else if(invDet.type === "invoice detail"){
              this.addedProductIds.push(invDet.fkProductId);
            }else if(invDet.type === "discount"){
              this.discount = invDet.taxAmount;
              this.discounted = true;
              this.discountObj = invDet;
              this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;
            }
          });
  // this.getTotalAmount();
  this.totalAmount = 0;
     
  this.invDetails.forEach((value)=>{
   this.amount = value.totalPrice;
   this.totalAmount = this.totalAmount + this.amount;
  })
  this.invoice.totalAmount = this.totalAmount;
  this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;

  if(this.discounted === true){
    this.invoice.totalAmount = this.invoice.totalAmount - this.discount;
    this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;

  }

  // this.getTotalQuantity();
  this.totalQuantity = 0;

  this.invDetails.forEach((value)=>{
   this.totalQuantity = this.totalQuantity + value.quantity;
  })
  this.invoice.totalQuantity = this.totalQuantity;

  // this.getTotalAlternateQuantity();
  this.invoice.totalAlternateQuantity  = 0;

  this.invDetails.forEach((value)=>{
    this.invoice.totalAlternateQuantity  = this.invoice.totalAlternateQuantity  + value.alternateQuantity;
  })

          this.invoiceExists = true;

          this.invoice.totalTax = 0;
          this.invoice.totalCess = 0;
          this.invoice.grandTotal = 0;

          let count = 0;
          this.taxesAndCess.forEach((value)=>{
            count = count + 1;
            if(value.type === "tax"){
             let val = value.value / 100;
             let tax = this.invoice.totalAmount * val;
             value.calculatedTax = tax;
             this.invoice.totalTax = this.invoice.totalTax + tax;               
            }
          });

          if(this.taxesAndCess.length === count){
            this.taxesAndCess.forEach((value)=>{
              if(value.type === "cess"){
                let val = value.value / 100;
                let cess = this.invoice.totalTax * val;
                value.calculatedTax = cess;
                this.invoice.totalCess = this.invoice.totalCess + cess;               
              }
            });
          }
          
          this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            


          this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
            data=>{

            });


   let taxList : InvoiceDetails[] = [];
       this.taxesAndCess.forEach((tax)=>{
         let inv = new InvoiceDetails();
         inv.taxId = tax.id;
         inv.taxType = tax.type;
         inv.taxAmount = tax.calculatedTax;
         taxList.push(inv);
       });

       this.invoice.taxesAndCess = taxList;

       this.invDetails.forEach((invDet)=>{
        if(invDet.type === "tax" || invDet.type === "cess"){
          this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
            data=>{
            });
        }
      });

      this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
        data=>{

          this.getInvoiceDetails();

        });
           });
      }); 
   }

  getProducts(){
    this.productService.getProductListByOfficeId(this.officeId).subscribe(data => {
      this.products= data;
    });
  }

  startInvoiceLoading() {
    this.invoiceLoader ? this.spinner.show() : this.spinner.hide();
  } 

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  getInvoiceDetails(){
    this.invoiceService.getAllInvDetails(this.invoiceId).subscribe(data =>{
      this.invDetails = [];
      this.addedProductIds = [];
      this.invDetails = data;
      this.taxesAndCess=[];
      this.addedProductIds = [];
      this.invDetails.forEach((invDet)=>{
        if(invDet.type === "tax" || invDet.type === "cess"){
          this.taxesAndCess.push(invDet.taxObject);
        }else if(invDet.type === "invoice detail"){
          this.addedProductIds.push(invDet.fkProductId);
        }else if(invDet.type === "discount"){
          this.discount = invDet.taxAmount;
          this.discounted = true;
          this.discountObj = invDet;
          this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;
        }
      });

      this.invoiceExists = true;
       });
  }

  recomputeTaxes(){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ChooseTaxesComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {



       this.taxesAndCess = receivedEntry;

          this.invoice.totalTax = 0;
          this.invoice.totalCess = 0;
          this.invoice.grandTotal = 0;

          let count = 0;
          this.taxesAndCess.forEach((value)=>{
            count = count + 1;
            if(value.type === "tax"){
             let val = value.value / 100;
             let tax = this.invoice.totalAmount * val;
             value.calculatedTax = tax;
             this.invoice.totalTax = this.invoice.totalTax + tax;               
            }
          });

          if(this.taxesAndCess.length === count){
            this.taxesAndCess.forEach((value)=>{
              if(value.type === "cess"){
                let val = value.value / 100;
                let cess = this.invoice.totalTax * val;
                value.calculatedTax = cess;
                this.invoice.totalCess = this.invoice.totalCess + cess;               
              }
            });
          }
          
          this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

   let taxList : InvoiceDetails[] = [];
       this.taxesAndCess.forEach((tax)=>{
         let inv = new InvoiceDetails();
         inv.taxId = tax.id;
         inv.taxType = tax.type;
         inv.taxAmount = tax.calculatedTax;
         taxList.push(inv);
       });

       this.invoice.taxesAndCess = taxList;

       this.invDetails.forEach((invDet)=>{
        if(invDet.type === "tax" || invDet.type === "cess"){
          this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
            data=>{
            });
        }
      });

      this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
        data=>{

          this.getInvoiceDetails();

        });




        });  

 
 
}

editDiscount(){
    
  this.invoice.totalAmount = this.invoice.totalAmount + this.discountObj.taxAmount;
  this.invoice.totalAmount = this.invoice.totalAmount - this.discount;
  this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;

  this.invoice.totalTax = 0;
  this.invoice.totalCess = 0;
  this.invoice.grandTotal = 0;

  let count = 0;
  this.taxesAndCess.forEach((value)=>{
    count = count + 1;
    if(value.type === "tax"){
     let val = value.value / 100;
     let tax = this.invoice.totalAmount * val;
     value.calculatedTax = tax;
     this.invoice.totalTax = this.invoice.totalTax + tax;               
    }
  });

  if(this.taxesAndCess.length === count){
    this.taxesAndCess.forEach((value)=>{
      if(value.type === "cess"){
        let val = value.value / 100;
        let cess = this.invoice.totalTax * val;
        value.calculatedTax = cess;
        this.invoice.totalCess = this.invoice.totalCess + cess;               
      }
    });
  }
  
  this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

let taxList : InvoiceDetails[] = [];
this.taxesAndCess.forEach((tax)=>{
 let inv = new InvoiceDetails();
 inv.taxId = tax.id;
 inv.taxType = tax.type;
 inv.taxAmount = tax.calculatedTax;
 taxList.push(inv);
});

this.invoice.taxesAndCess = taxList;

this.invDetails.forEach((invDet)=>{
if(invDet.type === "tax" || invDet.type === "cess"){
  this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
    data=>{
    });
}
});

let discount : InvoiceDetails = new InvoiceDetails();
discount.taxAmount = this.discount;
discount.invoiceDetId = this.discountObj.invoiceDetId;

this.invoiceService.updateDiscount(environment.apiUrl + "relatedInvDet/updateDiscount", discount).then(
data=>{

  this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
    data=>{
  
      this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
        data=>{

          this.toaster.success("Discount and Invoice updated successfully !")
          this.getInvoiceDetails();
          this.discounted = true;

          this.invoice.totalBeforeDiscount = this.invoice.totalAmount - this.discount;

        });
  
    });

});




}

addDiscountToInvoice(){

  this.invoice.totalAmount = this.invoice.totalAmount - this.discount;
  this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;

  this.invoice.totalTax = 0;
  this.invoice.totalCess = 0;
  this.invoice.grandTotal = 0;

  let count = 0;
  this.taxesAndCess.forEach((value)=>{
    count = count + 1;
    if(value.type === "tax"){
     let val = value.value / 100;
     let tax = this.invoice.totalAmount * val;
     value.calculatedTax = tax;
     this.invoice.totalTax = this.invoice.totalTax + tax;               
    }
  });

  if(this.taxesAndCess.length === count){
    this.taxesAndCess.forEach((value)=>{
      if(value.type === "cess"){
        let val = value.value / 100;
        let cess = this.invoice.totalTax * val;
        value.calculatedTax = cess;
        this.invoice.totalCess = this.invoice.totalCess + cess;               
      }
    });
  }
  
  this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

let taxList : InvoiceDetails[] = [];
this.taxesAndCess.forEach((tax)=>{
 let inv = new InvoiceDetails();
 inv.taxId = tax.id;
 inv.taxType = tax.type;
 inv.taxAmount = tax.calculatedTax;
 taxList.push(inv);
});

this.invoice.taxesAndCess = taxList;

this.invDetails.forEach((invDet)=>{
if(invDet.type === "tax" || invDet.type === "cess"){
  this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
    data=>{
    });
}
});

let discount : InvoiceDetails = new InvoiceDetails();
discount.taxAmount = this.discount;
discount.fkInvoiceId = this.invoice.invoiceId;

this.invoiceService.addDiscountDetails(environment.apiUrl + "completeInvoice/addDiscountDetails", discount).then(
data=>{

  this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
    data=>{
  
      this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
        data=>{

          this.toaster.success("Discount applied and Invoice updated successfully !")
          this.getInvoiceDetails();
          this.discounted = true;

          this.invoice.totalBeforeDiscount = this.invoice.totalAmount - this.discount;

        });
  
    });

});




}
} 
