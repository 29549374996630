import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})

export class AddProductComponent implements OnInit {
  clicked = false;
  prod : Product = new Product();
  role : any;
  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  FKoffice!: string;
  loggedInUser : any;
  theme : any;
  constructor(private productService : ProductService,
              public activeModal: NgbActiveModal,
              private sharedDataService: SharedDataService,
              public bsModalRef: BsModalRef,
              private imageService: FileServiceService,
              private toaster: ToastrService) { }

  ngOnInit(): void {
   
    this.FKoffice = this.fromParent.officeId;
    this.role = this.fromParent.role;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
  } 

  selectedFile!: File;
  fileChange : boolean = false;
  onFileSelected(event: any){
    this.fileChange = true;
    this.selectedFile = event.target.files[0];
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  // authorized
  onSubmit(){

    this.prod.fkOfficeId = this.FKoffice;

    this.productService.addNewProduct(environment.apiUrl + 'crmproduct/add', this.prod).then(
      data => {

        this.prod = data;
            this.passEntry.emit(this.prod);
            this.activeModal.close();
            this.toaster.success("Product Added Successfully");

            if(this.fileChange === true){
              let body = new FormData();
              body.append("file", this.selectedFile);
  
              this.imageService.uploadProductImage(body, this.prod.productId).subscribe(
                data=>{
                  
                });
              }
          });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeModal(){
    this.activeModal.close();
  }

}
 