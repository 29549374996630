<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">File Upload</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
       <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
        <div class="card-body">
    <form (ngSubmit)="onSubmit" #adminForm="ngForm">   
      

      <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

      <div *ngIf="showResults===false && matSpinnerActive===false">

        Please note that phone and name are mandatory to create customers/users. <br>
        Altering the column positions or deleting any columns will cause the process to fail. <br>
        The correct format of phone number includes country code along the phone number without + sign and without spaces. <br>
        Correct format - 919887885858 <br>
        Incorrect formats - +919774225487, +91 966 098 8087, 91 9885447878 etc <br>
        You can download a sample file that can be used to know the format of the file you upload. <br>
        <a style="color: blue;"
        href="assets/bot_user_creation_sample_file.csv" 
        target="_blank" 
        download="sample-file.csv">
        Download Sample File
        </a>
    <br>
        
        <br>
        <div>
            <label  class="required">File</label>
            <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
             class="form-control" accept=".csv" >
         </div>

        </div>
        <div *ngIf="showResults===true && matSpinnerActive===false">

          <h3 style="font-weight: bold;"> Users failed to be added({{resultDto.existingStudents.length}}) :</h3>
          <div *ngFor="let email of resultDto.existingStudents">
            <a style="color: black;">{{email}}</a>
          </div>

          <br>
          <br>

          <h3 style="font-weight: bold;">Users created/updated successfully({{resultDto.createdStudents.length}}) :</h3>
          <div *ngFor="let email of resultDto.createdStudents">
            <a style="color: black;">{{email}}</a>
          </div>
         
          <br>

        


        </div>
                </form>
        </div>
    </div>
</div>

<div class="modal-footer">
  <button *ngIf="showResults===false" [disabled]="clicked"  [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Submit</button>
</div>