<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='send location object to user'"
    style="color: white;" class="modal-title pull-left">Send Location</h4>
    <h4 *ngIf="fromParent.context==='create new location object'"
    style="color: white;" class="modal-title pull-left">Create Location</h4>
     <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
  <div class="card-body"> 
  <form #botResponseOptionForm="ngForm">

<div *ngIf="context==='send location object to user'">

  <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerEnabled===true"></mat-spinner>


  <div *ngIf="matSpinnerEnabled===false">

    <div>
        <label style="text-align: left;" for="botUserLocationId">Locations</label>
        <select required class="form-control" [(ngModel)]="botUserLocationObj.botUserLocationId">
          <option value="" disabled>Choose Location</option>
          <option *ngFor="let loc of officeBotUserLocations" [ngValue]="loc.botUserLocationId">{{loc.name}}</option>
        </select>
      </div>

      <br>

      <button (click)="createLocationObject();" style="width: auto;float: right;" class="btn btn-info">Add New Location</button>

</div>
  </div>

  <div *ngIf="context==='create new location object'">
    <div class="form-group row">

      <div class="col-md-4">
          <label class="required" for="name">Name</label>
          <input #name="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
           name="name" id="name" [(ngModel)]="botUserLocationObj.name">
          <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is Required</div>
          </div>

          <div class="col-md-4">
            <label class="required" for="latitude">Latitude</label>
            <input #latitude="ngModel" minlength="3" maxlength="255" required class="form-control" type="number" 
             name="latitude" id="latitude" [(ngModel)]="botUserLocationObj.latitude">
            <div class="alert alert-danger" *ngIf="latitude.touched && !latitude.valid">Latitude is Required</div>
            </div>

            <div class="col-md-4">
              <label class="required" for="longitude">Longitude</label>
              <input #longitude="ngModel" minlength="3" maxlength="255" required class="form-control" type="number" 
               name="longitude" id="longitude" [(ngModel)]="botUserLocationObj.longitude">
              <div class="alert alert-danger" *ngIf="longitude.touched && !longitude.valid">Longitude is Required</div>
              </div>

  </div>
  <br>
  <div>
    <label for="address">Address</label>
    <textarea class="form-control" type="text" name="address" 
     id="address" [(ngModel)]="botUserLocationObj.address"></textarea>
 </div> 
  </div>

  </form>
  </div>
</div>

  <div class="modal-footer">
    <button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
    *ngIf="context==='send location object to user'" (click)="sendLocationToUser(); clicked=true">Send</button>
    <button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
    *ngIf="context==='create new location object'" (click)="createOrEditBotLocationObject(); clicked=true">Save</button>
</div>