

 
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>


  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">


   

    
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page"><i class="fa fa-home" aria-hidden="true"></i> Home</li>
      </ol>
    </nav>
    
    
      <div class="content" *ngIf="cardsExist === true" style="margin: 2.5%;">
    
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
          
      
             <div  *ngFor="let class of classCards">
    
              <div class="col mb-4">
                
            <div class="card mat-elevation-z12">
    
              <div class="sub-image-div" *ngIf="this.loggedInUser.offices[0].logo !== null">
                <img class="sub-image" height="75" width="75" class="logo-image"
                  [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
              </div>
              <div class="sub-image-div"  *ngIf="this.loggedInUser.offices[0].logo === null">
                <img class="sub-image" alt="" height="75" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="75">
              </div>
              <div class="card-body">
                <p class="card-text" style="color: black; font-weight: bold;">{{class.className}}</p>
                <h5 class="card-title">{{class.courseName}}</h5>
                <div class="button-div">
                <button [style.background-color]='getColor()' (click)="explore(class.fkProductIdOfClass, class.studentId, class.classId);" class="btn btn-info">Explore</button>
                </div>
              </div>
            </div>
          </div>
      
          </div>
    
         </div>
      
      
      
      
      </div>
      
    
      </div>
    
    
    
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-dashboard-spinner></app-dashboard-spinner>
          </div>
        </div>
      
        <div class="loaded-content" [class.blurred]="showSpinner">
          <ng-content></ng-content>
        </div>
      </div>