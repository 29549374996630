export class Result {

    resultId!: string;
	fkStudentId!: string;
	fkSubjectId!: string;
	fkSkillId!: string;
	skillScore!: string;
	resultStatus!: boolean;
	resultHistory!: string;
	resultSuggestion!: string;

	skillName!: string;
	parentSkillName!: string;
	skillImage : any;
	safeUrl : any;

	studentName : any;
	subjectName : any;

	uroId : any;
}
