import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-bot-response',
  templateUrl: './delete-bot-response.component.html',
  styleUrls: ['./delete-bot-response.component.scss']
})
export class DeleteBotResponseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
