export class NotificationDetails {

    notificationDetailsId : any
	notificationDetailsActiveStatus : any
	fkNotificationMasterId : any
	
	type : any
	name : any
	messageBody : any
	messageSubject : any
	messageHeader : any
	messageFooter : any

}
