import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotResponse } from 'src/app/BOT_CODE/bot_classes/bot-response';
import { BotLanguage } from 'src/app/BOT_CODE/bot_classes/bot-language';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { BotLangResponse } from 'src/app/BOT_CODE/bot_classes/bot-lang-response';
import { BotSuperadminServiceService } from 'src/app/BOT_CODE/bot_services/bot-superadmin--service.service';
import { CreateResLangVariationComponent } from '../create-res-lang-variation/create-res-lang-variation.component';

@Component({
  selector: 'app-view-res-lang-variations',
  templateUrl: './view-res-lang-variations.component.html',
  styleUrls: ['./view-res-lang-variations.component.scss']
})
export class ViewResLangVariationsComponent implements OnInit {

 
  clicked = false;
  loggedInUser : any;
  theme : any; 

  constructor(private sharedDataService: SharedDataService,
    private ngbModalService: NgbModal,
    private botService : BotSuperadminServiceService,
    private botOfficeService : BotOfficeServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    botLangResponses : BotLangResponse[] = [];
    botResponse : BotResponse = new BotResponse();
    botLanguages : BotLanguage[] = [];

    context : any;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md'
    }; 

    ngOnInit(): void {      
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botResponse = this.fromParent.botResponse;
    this.getAllLangResponsesByResponseId();

   }

  //  getAllLanguages(){
  //   this.botService.getAllLanguages(environment.apiUrl + "botConvo/getAllLanguages").then(
  //     res=>{
  //       this.botLanguages = res;
  //     });
  //  }

  getAllLangResponsesByResponseId(){
    
    this.botOfficeService.getAllLangVariations(environment.apiUrl + "botOffice/getAllLangResponsesByResponseId/" + this.botResponse.botResponseId).then(
      res=>{
        let resObj : BotLangResponse = new BotLangResponse();
        resObj = res;
        this.botLangResponses = resObj.list;
      });

  }

  // edit(){
    
  //   this.botOfficeService.editLanguageVariation(environment.apiUrl + "botConvo/editBotLangResponse", this.botLangResponse).then(
  //     res=>{
  //       this.passEntry.emit(this.botLangResponse);
  //       this.activeModal.close();
  //       this.toastr.success("Response updated successfully !");
  //     });

  // }

  

  edit(langResponse0:BotLangResponse){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateResLangVariationComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botLangResponse : langResponse0,
      context : "edit"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotLangResponse) => {
      this.botLangResponses.forEach((langRes)=>{
        if(langRes.botLangResId === langResponse0.botLangResId){
          langRes.langResponse = receivedEntry.langResponse;
          langRes.interactiveListButtonName = receivedEntry.interactiveListButtonName;
        }
      })
    }); 
  }

  createLanguageVariationForResponse(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateResLangVariationComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botResponse : this.botResponse,
      context : "create"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotResponse) => {
      this.getAllLangResponsesByResponseId();
    });    
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}
