import { TicketSupportRole } from "./ticket-support-role";

export class TicketSupportDefinition {

    ticketSupportDefinitionId : any;
    ticketSupportDefinitionActiveStatus : any;
    fkFormId : any;
    fkOfficeId : any;
    ticketSupport : any;
    ticketSupportDescription : any;
    ticketSupportType : any;
    ticketSupportGroup : any;
 
    ticketSupportRoles : TicketSupportRole[] = [];

    roleIds : string[] = [];
    
}