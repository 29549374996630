import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityScore } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-score';
import { AddActActualActScore } from 'src/app/courseManagementClasses/combinedParentTeacher/add-act-actual-act-score';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { StudentService } from 'src/app/service/student.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-mark-student-activity-score-modal',
  templateUrl: './mark-student-activity-score-modal.component.html',
  styleUrls: ['./mark-student-activity-score-modal.component.scss']
})
export class MarkStudentActivityScoreModalComponent implements OnInit {
  showSpinner = false;
  fkStudentId!: string;
  fkTeacherId!: string;
  activityPlannerId!: string;
  actQuestions : ActivityQuestions[] = [];
  inputType : any;
  questionOrder : any;
  index : number = 0;
  addActActualActScore : AddActActualActScore = new AddActActualActScore();
  activityScore : ActivityScore = new ActivityScore();
  activityScoreList : ActivityScore[] = [];
   
  button : any;
  @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 
  
  constructor(private combinedParentTeacherService : CombinedParentTeacherService,
              public activeModal: NgbActiveModal,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

  ngOnInit(): void {

    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.fkStudentId = this.fromParent.fkStudentId;
    this.fkTeacherId = this.fromParent.fkTeacherId;

    this.combinedParentTeacherService.getActivityQuestionListByActPlnrId(environment.apiUrl + "activityQuestion/getByActPlnrId/" + this.activityPlannerId).then(
      data=>{
        this.actQuestions=data;
        
        this.actQuestions.forEach((question) =>{
            if(question.activityQuestionType=='R'){
              this.inputType = 'Radio';
              this.questionOrder = question.activityQuestionOrder;
            }
        }); 

      });
 
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeUpdateModal(){

    this.activeModal.close();

  }

}
