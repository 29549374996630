import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-edit-bcrm-user',
  templateUrl: './edit-bcrm-user.component.html',
  styleUrls: ['./edit-bcrm-user.component.scss']
})
export class EditBcrmUserComponent implements OnInit {

  clicked : boolean = false;
  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  userId : any;
  products : Product[] = [];
  roles : any[] = ["TEACHER", "STUDENT"];
  loggedInUser : any;
  theme : any;

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  constructor(private toaster : ToastrService,
              public activeModal: NgbActiveModal,
              private sharedDataService: SharedDataService,
              private subscriptionService  : SubscriptionService ) { }

              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
    this.countryCodes = GlobalClass.CountryCodes;
    
this.userId =  this.fromParent.userId;

this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.theme = this.sharedDataService.getCardOfficeTheme();

    this.subscriptionService.getUserForEdit(this.userId).subscribe(
      data=>{
        this.combinedPTC = data;
      });
  }

  onSubmit(){

    this.subscriptionService.updateUser(environment.apiUrl + "user/updateUser", this.combinedPTC).then(
      data=>{
        this.combinedPTC = data;
        this.passEntry.emit(this.combinedPTC);
        this.activeModal.close();
        this.toaster.success("User updated successfully");
      });
   
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

}