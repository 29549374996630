import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-add-new-invoice-details',
  templateUrl: './add-new-invoice-details.component.html',
  styleUrls: ['./add-new-invoice-details.component.scss']
})
export class AddNewInvoiceDetailsComponent implements OnInit {
  clicked = false; 
  invoiceDetailsList: InvoiceDetails[] = []; 
  invoiceDet : InvoiceDetails = new InvoiceDetails;
  prod : Product = new Product();
  products: Product[] = [];
  products0: Product[] = [];
  invoiceId!: string;
  officeId!: string;
  qty! : any;
  price! : any;
  tp!: any;
  loggedInUser : any;
  theme : any;
  @Input() fromParent: any; 
  addedProductIds : string[] = [];
  productIds : string[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private productService : ProductService, 
    private sharedDataService: SharedDataService,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void { 

    this.invoiceDet.fkProductId = "";

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.addedProductIds = this.fromParent.addedProductIds;

    this.officeId = this.fromParent.officeId;
    this.getProducts();
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getFilteredProducts(){
    let count = 0;
    this.addedProductIds.forEach((addedProductId)=>{
      count++;
      this.productIds.forEach((productId,index)=>{
        if(productId==addedProductId)  this.productIds.splice(index,1);
     });
    });  
    if(count === this.addedProductIds.length){
      this.productIds.forEach((val)=>{
        this.products.forEach((prod)=>{
          if(val === prod.productId){
            this.products0.push(prod);
          }
        });
      });
    }
  }

  getProducts(){
    this.productService.getProductListByOfficeId(this.officeId).subscribe(data => {
      this.products= data;

      let count = 0;
      this.products.forEach((val)=>{
        count++;
        this.productIds.push(val.productId);
      });

      if(count === this.products.length){
        this.getFilteredProducts();
      }
    });
  }

  onChange(event : any){
    this.products.forEach((prod)=>{
      if(prod.productId === this.invoiceDet.fkProductId){
        this.invoiceDet.itemName = prod.productName;
        this.invoiceDet.pricePerItem = prod.productPrice;
      }
    });
  }

  onFileSelected(event: any){
    this.qty = this.invoiceDet.quantity;
    this.price = this.invoiceDet.pricePerItem;

     this.tp = this.qty * this.price;

     this.invoiceDet.totalPrice = this.tp;
  }

  onSubmit(){ 
    this.passEntry.emit(this.invoiceDet);
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 
  
  closeModal(){
    this.activeModal.close();
}

}
