<!-- MODAL CLOSE BUTTON -->
<div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Payment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
   <!-- MODAL CLOSE BUTTON -->




   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form >
        <div>
            <label hidden  for="paymentId">Payment Id</label>
            <input readonly hidden  type="text" name="paymentId" id="paymentId" [(ngModel)]="payment.paymentId">
        </div>

        <!-- <div>
            <label for="fkInvoiceId">fkInvoiceId</label>
                <select  class="dropdown" [(ngModel)]="payment.fkInvoiceId" id="fkInvoiceId" name="fkInvoiceId">
                  <option value="" disabled >Choose Invoice No</option>
                  <option  *ngFor="let invoice of invoices"  [ngValue]="invoice.invoiceId">{{invoice.invoiceNo}}</option>
                </select>
            </div> -->

            <div>
                <label hidden for="fkInvoiceId">fkInvoiceId</label>
                <input class="form-control" readonly hidden type="text" name="fkInvoiceId" id="fkInvoiceId" [(ngModel)]="payment.fkInvoiceId">
            </div>

            <div class="form-group row">
                <div class="col-md-6">
            <label for="paymentMethod">Method</label>
            <input class="form-control" readonly type="text" name="paymentMethod" id="paymentMethod" [(ngModel)]="payment.paymentMethod">
        </div>

        <div class="col-md-6">
            <label for="paymentDate">Date</label>
            <input class="form-control" readonly type="Date" name="paymentDate" id="paymentDate" [(ngModel)]="payment.paymentDate">
        </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6">
            <label for="paymentAmount">Amount</label>
            <input class="form-control" readonly type="text" name="paymentAmount" id="paymentAmount" [(ngModel)]="payment.paymentAmount">
        </div>

        <div class="col-md-6">
            <label for="paymentDue">Amount Due</label>
            <input class="form-control" readonly type="text" name="paymentDue" id="paymentDue" [(ngModel)]="payment.paymentDue">
        </div>
            </div>

            <div class="form-group row">
        <div class="col-md-6">
            <label for="paymentCompletion">Payment Completion</label>
            <input class="form-control" readonly type="text" name="paymentCompletion" id="paymentCompletion" [(ngModel)]="payment.paymentCompletion">
        </div>

    
    </div>

    <div >
        <label for="paymentRemarks">Remarks</label>
        <textarea class="form-control" readonly type="text" name="paymentRemarks" id="paymentRemarks" [(ngModel)]="payment.paymentRemarks"></textarea>
    </div>

        
    </form>
      </div>
    </div>
</div>

<div class="modal-footer"> 
    <button type="submit" class="btn btn-sm btn-primary float-right"  (click)="closeModal()">Close</button>
</div>