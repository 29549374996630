import { Subject } from "../subject";

export class GetCombinedPTCDetails {

    // Parent details
    teacherId!: string;
	parentUserRoleOfficeId!: string;
	parentUserId!: string;
	parentFirstName!: string;
	parentLastName!: string;

	// Child details
    studentId!: string;
	childUserRoleOfficeId!: string;
	childUserId!: string;
	childFirstName!: string;
	childLastName!: string;

	// Class Details
    classId!: string;

	// Product Details
    productId!: string;

	// Subject Details
    subjectList: Subject[] = [];

	// Progress Details
	progressValue : any;

}
