<div  [ngStyle]="backgroundStyle" class="modal-header">
   
  <h4 style="color: white;" class="modal-title pull-left">Switch Activity</h4>

  <div class="row pull-right">

    <div class="search">
      <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Start searching">
    </div>
  
 <!-- <mat-form-field appearance="fill">
      <mat-label>Select an option</mat-label>
      <mat-select [(value)]="selected" disableRipple (selectionChange)="valueChange($event.value)">
        <mat-option  value="1">New Activities</mat-option>
        <mat-option  value="2">All Activities</mat-option>
        <mat-option  value="3">Completed Activities</mat-option>
        <mat-option  value="4">Active Activities</mat-option>
      </mat-select>
    </mat-form-field> -->

    <select style="margin-right: 1em;" class="form-select form-select-sm"  [(ngModel)]="selectedOption" (change)="onChange($event)" aria-label=".form-select-sm example">
      <!-- <option  value="0" selected>Choose an option</option> -->
      <option  value="1">New Activities</option>>
        <option  value="2">All Activities</option>
          <option  value="3">Completed Activities</option>
            <option  value="4">Active Activities</option>
    </select>

</div>

 
   


  </div> 

  <div class="modal-body">

  
   
    <!-- Show All Activities -->
    <div class="content" [style.display]="allActivities ? 'block' : 'none'">
      <h3 *ngIf="allActivitiesList === undefined || allActivitiesList.length == 0"> No Activities to display</h3>
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of allActivitiesList | filter:searchText ">
          <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
            <div style="float: left;" *ngIf="card.selectedState === true">
              <img style="border-radius: 50%;margin-left: 2%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
            </div> 
            <a matToolTip="Completed" style="color: rgb(26, 116, 26);font-weight: bold;float: right;" *ngIf="card.activityPlannerCompletionStatus === true">
              <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
            </a>
<br>
<br>

            <div style="width: 100%;text-align: center;margin-bottom: 1%;">
              <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
            </div>
          
              <div style="text-align: center;">
              <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2> 

              <button (click)="select(card.activityPlannerId)" [ngStyle]="backgroundStyle" class="btn btn-info">Select</button>
<br>
              
             
          </div>
          
         
            <div *ngIf="card.collapsedState === true" >
            <div style="text-align: center;text-align: justify;text-justify:inter-word;word-break: break-all;">
              <p>
                {{card.activityPlannerDescription}}
              </p>
            </div>
          </div>
          <div style="text-align: center;margin: 0%;">
            <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent(card.activityPlannerId)">
              {{card.collapsedState ? 'Close' : 'View Details'}}</button>
            </div>
          </mat-card>
        </div>
      </div>
      </div>

      <!-- Show New Activities -->
      <div class="content" [style.display]="newActivities ? 'block' : 'none'">
        <h3 *ngIf="newActivitiesList === undefined || newActivitiesList.length == 0"> No Activities to display</h3>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of newActivitiesList">
            <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
              <div style="float: left;" *ngIf="card.selectedState === true">
                <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
              </div> 
  <br>
  <br>
              <div style="width: 100%;text-align: center;margin-bottom: 1%;">
                <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
              </div>
              
              <div style="text-align: center;">
                <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2>
                <!-- <a style="color: rgb(26, 116, 26);font-weight: bold;" *ngIf="card.activityPlannerCompletionStatus === true">Completed
                  <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
                </a> -->
                <button (click)="select(card.activityPlannerId)" [ngStyle]="backgroundStyle" class="btn btn-info">Select</button>

              </div>
              

           
            <div *ngIf="card.collapsedState === true" >
              <div style="text-align: justify;text-justify:inter-word;">
                <p>
                  {{card.activityPlannerDescription}}
                </p>
              </div>
            </div>
            <div style="text-align: center;">
              <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent2(card.activityPlannerId)">
                {{card.collapsedState ? 'Close' : 'View Details'}}</button>
              </div>
            </mat-card>
          </div>
        </div>
        </div>  

      <!-- Show Completed Activities -->
      <div class="content" [style.display]="completedActivities ? 'block' : 'none'">
        <h3 *ngIf="completedActivitiesList === undefined || completedActivitiesList.length == 0"> Completed Activities will be shown here</h3>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of completedActivitiesList">
            <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
              <div style="float: left;" *ngIf="card.selectedState === true">
                <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
              </div> 

              <a style="color: rgb(26, 116, 26); font-weight: bold;float: right;" *ngIf="card.activityPlannerCompletionStatus === true">
                <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
              </a>
              <br>
              <br>
             
              <div style="width: 100%;text-align: center;margin-bottom: 1%;">
                <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
              </div>
             
              <div style="text-align: center;" >
                <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2>
                <button (click)="select(card.activityPlannerId)" [ngStyle]="backgroundStyle" class="btn btn-info">Select</button>

              </div>
             
           
            <div *ngIf="card.collapsedState === true" >
              <div style="text-align: center;text-align: justify;text-justify:inter-word;word-break: break-all;">
                <p>
                  {{card.activityPlannerDescription}}
                </p>
              </div>
            </div>
            <div style="text-align: center;">
              <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent3(card.activityPlannerId)">
                {{card.collapsedState ? 'Close' : 'View Details'}}</button>
              </div>
            </mat-card>
          </div>
        </div>
        </div>  

         <!-- Show Active Activities -->
      <div class="content" [style.display]="activeActivities ? 'block' : 'none'">
        <h3 *ngIf="activeActivitiesList === undefined || activeActivitiesList.length == 0"> Active Activities will be shown here</h3>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of activeActivitiesList">
            <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
              <div style="float: left;" *ngIf="card.selectedState === true">
                <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
              </div> 

              <a matToolTip="Active" style="color: rgb(26, 116, 26); font-weight: bold;float: right;">
                <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/inProgress.png" >
              </a>
              <br>
              <br>
             
              <div style="width: 100%;text-align: center;margin-bottom: 1%;">
                <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
              </div>
             
              <div style="text-align: center;" >
                <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2>
                <button (click)="select(card.activityPlannerId)" [ngStyle]="backgroundStyle" class="btn btn-info">Select</button>

              </div>
             
          
            <div *ngIf="card.collapsedState === true" >
              <div style="text-align: center;text-align: justify;text-justify:inter-word;word-break: break-all;">
                <p>
                  {{card.activityPlannerDescription}}
                </p>
              </div>
            </div>
            <div style="text-align: center;">
              <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent4(card.activityPlannerId)">
                {{card.collapsedState ? 'Close' : 'View Details'}}</button>
              </div>
            </mat-card>
          </div>
        </div>
        </div> 

    </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-danger float-right" (click)="closeUpdateModal()">Cancel</button>
    <button type="submit" class="btn btn-sm btn-success float-right" (click)="swap()">Proceed</button>
  </div>





  <!-- <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div> -->