import { Component, OnInit } from '@angular/core';
import { WebSocketAPI } from '../bot_classes/web-socket-api';

@Component({
  selector: 'app-web-socket-test',
  templateUrl: './web-socket-test.component.html',
  styleUrls: ['./web-socket-test.component.scss']
})
export class WebSocketTestComponent implements OnInit {
  title = 'CourseManagement-UI';
  constructor() { }

  //stomp
  webSocketAPI!: WebSocketAPI;
  greeting: any;
  name!: string;
  ngOnInit() {
    this.webSocketAPI = new WebSocketAPI(new WebSocketTestComponent());
  }

  connect(){
    this.webSocketAPI._connect();
  } 

  disconnect(){
    this.webSocketAPI._disconnect();
  }

  sendMessage(){
    this.webSocketAPI._send(this.name);
  }

  handleMessage(message:any){
    this.greeting = message;
  }
  //stomp

}
