import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormConfigurationService {

  authToken: string | null = '';
  constructor(private http: HttpClient) {
    // this.authToken = localStorage.getItem('auth-token');
  }

  getByFormNameAndOfficeId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
  getAllFormConfigs(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  // updateStatus(avenue: string): Promise<any> {

  //   this.authToken = localStorage.getItem('auth-token');

  //   const self = this;
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + this.authToken
  //   });
  //   return new Promise((resolve, reject) => {
  //     self.http.delete(avenue, { headers: headers })
  //       .toPromise()
  //       .then((data) => {
  //         resolve(data);
  //       }).catch((err) => {
  //       });
  //   });
  // }


  addFormConfiguration(avenue: string, setting : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, setting, {
        reportProgress: true,
        responseType: 'text'
      });

      self.http.post(avenue, setting, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  editFormConfiguration(avenue: string, setting : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, setting, {
        reportProgress: true,
        responseType: 'text'
      });

      self.http.put(avenue, setting, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

}
 