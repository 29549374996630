<div class="modal-header" style=" max-height: 20% !important;" [ngStyle]="backgroundStyle">
  <h2 style="color: white;" class="modal-title pull-left">{{combinedPTC.firstName}} {{combinedPTC.lastName}}</h2>
 

 
  <div class="row pull-right">

    <button style=" color: black;background-color: white; ;" class="btn btn-info" (click)="editCustomer();">Edit Profile</button> 

  <button type="button" class="close" aria-label="Close" (click)="closeUpdateModal()">
    <span aria-hidden="true">&times;</span>
  </button> 


</div>
 
</div> 

 <div class="modal-body">
  <div class="card">
    <div class="card-body" style="padding: 0.25em;">

    
     
     <div class="content" style="margin: 0%;padding: 0%;" [style.display]="profile ? 'block' : 'none'">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
           
              <div style="text-align: center;" class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

                  <a style="cursor:pointer;text-align: center;">
                     <img src="/assets/images/icons/user.png" width="100" height="100"
                     alt="" loading="lazy">
                  </a>
          

         

          </div>
           
  

          </mat-card>
        </div>

        <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="50%" >
          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
           
              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">

              

              <div *ngIf="combinedPTC.entityType==='Individual'">
                <a><i class="fa fa-birthday-cake" aria-hidden="true"> {{combinedPTC.dateOfBirth}}</i>
                </a> <br>
              <a style="color: black;" *ngIf="combinedPTC.gender === 'male' || combinedPTC.gender === 'Male'"><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.gender}}</i>
                </a> 
                <a style="color: black;" *ngIf="combinedPTC.gender === 'female' || combinedPTC.gender === 'Female'"><i class="fa fa-female" aria-hidden="true"> {{combinedPTC.gender}}</i>
                </a> 
                <a style="color: black;" *ngIf="combinedPTC.gender === 'other' || combinedPTC.gender === 'Other'"><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.gender}}</i>
                </a> 
                <a><i class="fa fa-calendar" aria-hidden="true"> Date of Joining : {{combinedPTC.leadCreationDate}}</i>
                </a>
              </div>

              <div *ngIf="combinedPTC.entityType==='Organisation'" class="email"
               style="text-align: center; color: black; word-wrap: break-word;" >
                <a>Contact Person :</a> <br>
                <a><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.contactPerson}}</i></a> <br>
                <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.contactPersonEmail}}</i></a> <br>
                <a style="word-wrap: break-word;"><i class="fa fa-phone" aria-hidden="true"> {{combinedPTC.contactPersonPhone}}</i></a>
              </div>

             
<br>

              
           

          </div>
           
  

          </mat-card>
        </div>

        <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
           
          
           
            <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
              <a><i class="fa fa-phone" aria-hidden="true"> {{combinedPTC.phoneNumber}}</i></a> <br>
              <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.email}}</i></a>
          </div>
           
  

          </mat-card>

          
        </div>

        <div fxFlex="30%" fxFlex.xs="50%" fxFlex.sm="50%" >
         

          <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
                       
            <div style="text-align: left; color: black;" >
              <a><i class="fa fa-address-card" aria-hidden="true"> Address : </i></a> <br>
              <a>{{combinedPTC.addressLine1}}</a> <a style="color: black;" *ngIf="combinedPTC.addressLine1 !== undefined && combinedPTC.addressLine1 !== null">,</a>
              <a> {{ combinedPTC.addressLine2}}</a> <a style="color: black;" *ngIf="combinedPTC.addressLine2 !== undefined && combinedPTC.addressLine2 !== null">,</a> <br>
              <a> {{ combinedPTC.city}}</a> <a style="color: black;" *ngIf="combinedPTC.city !== undefined && combinedPTC.city !== null">,</a> 
              <a> {{ combinedPTC.state}}</a> <a style="color: black;" *ngIf="combinedPTC.state !== undefined && combinedPTC.state !== null">,</a> <br>
              <a> {{ combinedPTC.country}}</a> <a style="color: black;" *ngIf="combinedPTC.country !== undefined && combinedPTC.country !== null">,</a> 
              <a> {{ combinedPTC.zipCode}}</a> <a style="color: black;" *ngIf="combinedPTC.zipCode !== undefined && combinedPTC.zipCode !== null">.</a> <br>
         
            </div>
           
  

          </mat-card>
        </div>
      </div>
      </div> 

      <br>

     

      

      <div *ngIf="fromParent.type!=='LEAD'" class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
        <div class="col-md-12" style="color: rgb(248, 245, 245);">
  



           <div style="float: left;">
            <select class="form-select form-select-sm"  [(ngModel)]="selectedOption" 
            (change)="onChange($event)" aria-label=".form-select-sm example">
              <option  value="0" selected>Choose an option</option>
              <option  value="1"> Invoices</option>
              <option  value="2">Payments </option>
              <option  value="3">Shipments</option>
           
            </select>
          </div>
  
          <a *ngIf="showInvoices === true" style="cursor:pointer;" class="float-right" (click)="addNewInvoice()">
            <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>
         <a *ngIf="showInvoices === true" style="cursor:pointer;color: white; margin-right: 3%;" 
         class="float-right">
         Outstanding Amt :  {{combinedPTC.outstandingAmount}} 
         </a>
 
          <a *ngIf="showPayments === true" style="cursor:pointer;" class="float-right" (click)="addNewPayment()">
            <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>

          <a *ngIf="showShipments === true" style="cursor:pointer;" class="float-right" (click)="addNewShipment()">
            <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>
          
         
        </div> 
      </div>

   <div  *ngIf="showInvoices === true">
  
    
        
    <div class="card">
      <div class="card-body table-responsive">
      
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead class="tableheader">
            <tr>
              <th>Invoice No.</th>
              <th>Date</th>
              <th>Total Amt</th>
              <th>Outstanding Amount</th>
              <th>Action</th>
            </tr>
          </thead> 
          <tbody>
            <tr class="td-height" *ngFor="let subs of invoices">
              <td class="td-height">{{subs.invoiceNo}}</td>
              <td class="td-height">{{subs.invoiceDate}}</td>
              <td class="td-height">{{subs.grandTotal}}</td>
              <td class="td-height">{{subs.outstandingAmount}}</td>
              <td *ngIf="invoices.length !== 0 && invoices !== undefined">
                 <button style="margin: 1%;" (click)="editInvoice(subs.invoiceId)" class="btn btn-primary">Edit</button>
                 <button style="margin: 1%;" (click)="viewInvoiceDetailsModal(subs.invoiceId)" class="btn btn-dark">View</button>

                </td>
              
            
            </tr>
          </tbody>
        </table> 

      </div>
    </div>
  

</div>  
      
    
   <div  *ngIf="showPayments === true">
  
    
        
    <div class="card">
      <div class="card-body table-responsive">
      
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead class="tableheader">
            <tr>
              <th>Date</th>
              <th>Method</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead> 
          <tbody>
            <tr class="td-height" *ngFor="let subs of payments">
              <td class="td-height">{{subs.paymentDate}}</td>
              <td class="td-height">{{subs.paymentMethod}}</td>
              <td class="td-height">{{subs.paymentAmount}}</td>
              <td>
                <button class="btn btn-primary" (click)="editPayment(subs.paymentId);">Edit</button>
              </td>
              
            
            </tr>
          </tbody>
        </table> 

      </div>
    </div>
  

</div>

         <div  *ngIf="showShipments === true">
  
    
        
          <div class="card">
            <div class="card-body table-responsive">
            
              <table class="table table-striped table-bordered table-sm row-border hover">
                <thead class="tableheader">
                  <tr>
                    <th>Shipment Partner</th>
                    <th>Shipped Date</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let subs of shipments">
                    <td class="td-height">{{subs.shipmentPartner}}</td>
                    <td class="td-height">{{subs.shippedDate}}</td>
                    <td class="td-height">{{subs.shipmentAddress}}</td>
                    <td>
                      <button style="margin: 1%;" (click)="editShipment(subs.shipmentId);" class="btn btn-primary">Edit</button>
                    </td>
                  
                  </tr>
                </tbody>
              </table> 
      
            </div>
          </div>
        
      
      </div>
 
       

    </div>
  </div>
 </div>
 
