<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Communications</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  
   
  
  
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          <table *ngIf="communicationsExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
           >
            <thead class="tableheader">
              <tr>
                <th [ngStyle]="backgroundStyle" >Action date</th>
                <th [ngStyle]="backgroundStyle" >Next Action Date</th>
                <th [ngStyle]="backgroundStyle" >Message</th>
                <th [ngStyle]="backgroundStyle" >Remarks</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead> 
            <tbody>
              <tr class="td-height" *ngFor="let subs of coms">
                <td class="td-height">{{subs.currentActionDateTime}}</td>
                <td class="td-height">{{subs.nextActionDateTime}}</td>
                <td class="td-height">{{subs.message}}</td>
                <td class="td-height">{{subs.remarks}}</td>
  
                <!-- <td>
                  <button style="margin: 1%;" (click)="editCommunications(subs);" class="btn btn-primary">Edit</button>
                </td> -->
              
              </tr>
            </tbody>
          </table> 

          <br>

          <button class="btn btn-primary" (click)="addNewCustomerCommunication();" 
           *ngIf="fromParent.context==='SHOW_ADD_COMMUNICATION'" style="float: right;width: auto;">Add Communication</button>
    </div>
  </div>
  </div>
  
  