export class Announcement {

    id : any;
	activeStatus : any;
	startDate : any;
	endDate : any;
	createdUroId : any;
	createdUserId : any;
	message : any;
	timestamp : any;
	formattedStartDate : any;
	formattedEndDate : any;
}
