<nav class="navbar navbar-expand-md bg-dark navbar-dark " >

    <div>
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
          
 
       
  
      </ul>
    </div>  
  </nav>


  <div class="p-4">
    <div class="table-header row bg-dark navbar-dark "   style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Ticket Supports
        </span>

      

      </div> 
    </div>

    <div>
        <mat-accordion class="example-headers-align">
       
          <mat-expansion-panel *ngFor="let ticketSupportDef of ticketSupportDefinitions" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ticketSupportDef.ticketSupport}}
              </mat-panel-title>
              <mat-panel-description>
                {{ticketSupportDef.ticketSupportType}}
                <mat-icon>contact_support</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
        
            <a style="color: black;">{{ticketSupportDef.ticketSupportDescription}}</a>
          
            <mat-action-row>
              <button mat-button color="primary" (click)="raiseTicket(ticketSupportDef)" >Raise Ticket</button>
            </mat-action-row>
          </mat-expansion-panel>
  
        </mat-accordion>
        
      </div>
</div>



