// Angular
import {Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
// Layout
// Auth
@Component({
	selector: 'zui-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
	// Public properties
	today: number = Date.now();

	/**
	 * Component constructor
	 *
	 * @param el: ElementRef
	 * @param render: Renderer2
	 * @param translationService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
	}
}
