<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Swap Order of {{curriculum.name}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>  
  
  
  <div class="modal-body">
    <div class="card">
      <div class="card-body"> 
      <form (ngSubmit)="onSubmit()" #curForm="ngForm">  
  
              <div>
              <label for="curriculumOrder">Current Order</label>
              <input readonly required type="text" id="curriculumOrder" name="curriculumOrder" class="form-control" placeholder="Enter Curriculum Order" [(ngModel)]="curriculum.curriculumOrder">
              </div>

              <br>

              <div>
                <label class="required" for="curId">Swap With</label> <br>
                    <select #curId="ngModel" required class="form-control" [(ngModel)]="curriculum.selectedCurriculumId" id="curId" name="curId">
                      <option  value="" disabled >Choose Curriculum</option>
                      <option  *ngFor="let cur of curriculums"  [ngValue]="cur.curId">{{cur.name}}</option>
                    </select>
                    <div class="alert alert-danger" *ngIf="curId.touched && !curId.valid">Select Curriculum to Swap</div>
                </div>

      </form> 
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button [disabled]="clicked"  [disabled]="!curForm.valid"  class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Swap</button>
  </div> 