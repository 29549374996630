<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;"  class="modal-title pull-left">Global Template Responses</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card">
        <div class="card-body">


<form>

  <div *ngIf="search===true" class="col-md-12">
  <input class="col-md-12 form-control" type="text" [(ngModel)]="gtt"
   placeholder="Eg : location / premise / date" name="gtt">
 
<br>
  <div style="text-align:center;">
  <button style="width: auto;color: black;" (click)="getGlobalTemplateResponses();" class="btn btn-info">
    Search
    <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" width="27" height="27" alt="" loading="lazy">
  
  </button>
</div>

</div>

<div *ngIf="search===false">

    <div *ngFor="let res of globalTemplResponses,let i = index">

        <h3 style="margin: 0%;font-weight: bold;">
            <input type="radio" name="gender" [id]="res.botResponseId" [(ngModel)]="selectedGtrId" [value]="res.botResponseId">
            {{i}}] {{res.response}} 
        </h3>

    <div *ngIf="res.optionsExist === true">
        <div *ngFor="let opt of res.options">
            <a style="color: black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> 
        </div>
    </div>
    <br>

    </div>
    
    

</div>

  </form>

    
</div>
    </div>
  </div>


  <div *ngIf="search === false" class="modal-footer">
    <button *ngIf="context==='RESPONSE'" [disabled]="clicked" class="btn btn-sm btn-primary float-right" style="width: auto;"
     (click)="LinkGlobalTemplateResponseToResponse();">Copy Global Template Response</button>
     <button *ngIf="context==='OPTION'" [disabled]="clicked" class="btn btn-sm btn-primary float-right" style="width: auto;"
     (click)="LinkGlobalTemplateResponseToOption();">Copy Global Template Response</button>
</div>