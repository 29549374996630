 <div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left"> Teachers of : {{name}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  

<div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of classTeachers">
        <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
            
          <mat-card-header >
            <mat-card-title>{{card.teacherName}} </mat-card-title>
          </mat-card-header>
          <div style="width: 100%;text-align: center;">
          <img width="75" height="75" style="border-radius:50%;" alt="image" src="/assets/images/icons/selected.png">
        </div>
        <br>
          <mat-card-content>

            Subjects :
            <p>
              {{card.teacherId}}
            </p>
          </mat-card-content>
         
        </mat-card>
      </div>
    </div>
    </div> 