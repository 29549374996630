import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-class-results-page',
  templateUrl: './class-results-page.component.html',
  styleUrls: ['./class-results-page.component.scss']
})
export class ClassResultsPageComponent implements OnInit {
  showSpinner : boolean = true;
  toppingsControl = new FormControl([]);
  toppingList: any[] = [];
  navbarTitle: any;

  onToppingRemoved(topping: string) {
    const toppings = this.toppingsControl.value as string[];
    this.removeFirst(toppings, topping);
    this.toppingsControl.setValue(toppings); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  currentRole="TEACHER";
  index : number = 0;
  userId!: string;
  resultsExist = false;
  userName : any;
  loggedUser : any;
  loggedInUser : any;
  theme: string = '';
  public screenWidth: any;
  public screenHeight: any;
  classId!: string;
  selectedSkillNames: string[] = [];  
  unsafeImageUrl : any;
  safeImageUrl : any;
 
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  resultSkill :any[] = [{

                        parentSkillId: '',
                        skillId: '',
                        skillName: '',

                        studentsList : [{
                                          fkClass: '',
                                          fkUserRoleOffice:'',
                                          skillScore:'',
                                          studentActivityHistoryIndicator: '',
                                          studentAttempts:'',
                                          studentId: '',
                                          studentImage: '',
                                          studentName: '',
                                          studentStatus:'',
                                          safeUrl : ''
                                        }]

  }]

  resultSkillVisible :any[] = [{

    parentSkillId: '',
    skillId: '',
    skillName: '',

    studentsList : [{
                      fkClass: '',
                      fkUserRoleOffice:'',
                      skillScore:'',
                      studentActivityHistoryIndicator: '',
                      studentAttempts:'',
                      studentId: '',
                      studentImage: '',
                      studentName: '',
                      studentStatus:'',
                      safeUrl : ''
                    }]

}]
  constructor(private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              public router: Router,
              public route : ActivatedRoute,
              private toastr : ToastrService,
              private studentService : StudentService ) { }

ngOnInit(): void {

  this.navbarTitle = GlobalClass.navbarTitle;

this.screenWidth = window.innerWidth;
this.screenHeight = window.innerHeight;

this.classId = this.route.snapshot.params['classId'];

this.loggedUser = localStorage.getItem('loggedUser');
this.loggedInUser = JSON.parse(this.loggedUser);
this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
this.theme = this.sharedDataService.getCardOfficeTheme();
this.userId = this.loggedInUser.userId;

this.getStudentSkillResultsList();

}

getStudentSkillResultsList(){
this.studentService.getStudentSkillResultsList(this.classId).subscribe(
  data=>{
this.resultSkill = data;

if(this.resultSkill === undefined || this.resultSkill.length === 0){
  this.showSpinner=false;
  this.toastr.show('No Skill Results to display !')
}

let count : number = 0;
this.resultSkill.forEach((value)=>{
  count++;
  this.toppingList.push(value.skillName);

  value.studentsList.forEach((value2 : any)=>{
    
    this.imageService.getUserImage(value2.studentImage).subscribe(
      data=>{
        this.unsafeImageUrl = URL.createObjectURL(data);
        this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
        value2.safeUrl = this.safeImageUrl;

        if(count === this.resultSkill.length){
          this.showSpinner = false;
        }
      });
    });
 });
 this.resultsExist = true;
 this.resultSkillVisible = this.resultSkill;
  });
}

switchDashboard(){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
    {
      size : 'sm',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
    userId : this.userId,
    currentRole : this.currentRole
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });    
}

valueChange(value : any){

  this.resultSkillVisible = [];
  this.selectedSkillNames = [];

  value.forEach((data:any)=>{
    
    this.resultSkill.forEach((info)=>{

      if(info.skillId === data){
        this.resultSkillVisible.push(info);
        this.selectedSkillNames.push(info.skillName)
      }

    });
  });

}

goToAccountPage(){
  this.router.navigate(['user-account',this.userId])
}

goToHomePage(){
  this.router.navigate(['/dashboard/teacher']);
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}
changePassword(): void {
  this.router.navigate(['/auth/change-password']).then();
}
logOut(): void {
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
   email : this.loggedInUser.email 
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });  
}
goBack() {
  this.router.navigate(['/dashboard/user']).then();
}
reloadPage() {
  window.location.reload();
}
}
