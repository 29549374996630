<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="context==='createBotUserGroup'" style="color: white;" class="modal-title pull-left"> Create Group</h4>
    <h4 *ngIf="context==='editBotUserGroup'" style="color: white;" class="modal-title pull-left"> Edit Group</h4>
    <h4 *ngIf="context==='viewBotUserGroupDetails'" style="color: white;" class="modal-title pull-left">Group Members</h4>
    <h4 *ngIf="context==='assignMultipleUsersToAgent'" style="color: white;" class="modal-title pull-left"> Assign Users To Agent</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #adminForm="ngForm">  

        

<div *ngIf="context==='createBotUserGroup' || context==='editBotUserGroup'">

          <div>
          <label class="required" for="botUserGrpName">Name</label>
          <input #botUserGrpName="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
           name="botUserGrpName" id="botUserGrpName" [(ngModel)]="botUserGroup.botUserGrpName">
          <div class="alert alert-danger" *ngIf="botUserGrpName.touched && !botUserGrpName.valid">Title is Required</div>
          </div>

          <br>
          
          <div>
            <label for="botUserGrpDescription">Description</label>
            <textarea class="form-control" type="text" name="botUserGrpDescription" 
             id="botUserGrpDescription" [(ngModel)]="botUserGroup.botUserGrpDescription"></textarea>
         </div> 

<br *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
<div *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
  <label>Selected Customers / Users :</label>
  <mat-select-trigger>
    <mat-chip-list  style="display: flex;">
      <mat-chip *ngFor="let topping of selectedUsers"
        [removable]="true" (removed)="onToppingRemoved(topping)">
        {{ topping.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      
    </mat-chip-list>
  </mat-select-trigger>
</div>
     
<br>

<div>
  <label>Users :</label>

  <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

  <br *ngIf="allEligibleUsers===null || allEligibleUsers===undefined || allEligibleUsers.length===0">
  <div *ngIf="allEligibleUsers===null || allEligibleUsers===undefined || allEligibleUsers.length===0">
  <button style="margin: 0.5%;" (click)="open(getAllUsersWarning);" 
  mat-raised-button>Get All Users</button>
  <button style="margin: 0.5%;" (click)="open(searchByNameNumberModal);"
  mat-raised-button>Search For Users</button>
  <button style="margin: 0.5%;" (click)="getBotUserNamesAndBotUserIdsOfBotUSersWithoutGroupsByOfficeId();"
  mat-raised-button>Get Users Not Part Of Any Group</button>
  </div>


  <mat-form-field *ngIf="allEligibleUsers!==null && allEligibleUsers!==undefined && allEligibleUsers.length>0" 
    [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
    <mat-label>Choose Customers / Users</mat-label>
    <mat-select [formControl]="toppingsControl"
    (selectionChange)="multiSelectValueChange($event.value);" multiple>
      <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" 
    placeholder="Start searching by name or phone number">
    <mat-option (click)="selectAll('Select All', '')" value="Select All">Select All</mat-option>
      <mat-option (click)="selectAll(cb.botUserId, cb)" *ngFor="let cb of allEligibleUsers | filter:searchText" [value]="cb.botUserId">{{cb.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

      
</div> 

<div style="overflow-x: auto;" *ngIf="context==='viewBotUserGroupDetails'">

  <mat-spinner style="margin:0 auto;" *ngIf="dataLoaded===false"></mat-spinner>

  <button *ngIf="dataLoaded===true && botUserGroup !==null && botUserGroup !== undefined
  && botUserGroup.groupDetails !==null && botUserGroup.groupDetails !== undefined  && botUserGroup.groupDetails.length > 0 " style="float: left;" (click)="exportUsersArray(botUserGroup.groupDetails)"
   matTooltip="Download as excel file" mat-icon-button>
    <mat-icon>file_download</mat-icon> {{botUserGroup.groupDetails.length}} Users
</button>

<div *ngIf="dataLoaded===true" class="search-hero">
  <input class="form-control" type="text" name="search" [(ngModel)]="searchText1" autocomplete="off" 
  placeholder="Start searching by name or number">
</div>
<br>
  <table  *ngIf="dataLoaded===true" class="table table-striped table-sm hover">
  <thead class="tableheader" >
    <tr>
      <th>User Name</th>
      <th>User Phone</th>
      <th>Member Status</th>

      
      <th>Action</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let details of botUserGroup.groupDetails | filter:searchText1">
      <td class="td-height">{{details.userName}}</td>
      <td class="td-height">{{details.userIdentifier}}</td>
      <td *ngIf="details.botUserGrpDetailsStatus === true" style="font-weight: bold;" class="td-height">ACTIVE</td>
      <td *ngIf="details.botUserGrpDetailsStatus === false" style="font-weight: bold;" class="td-height">INACTIVE</td>

      

      <td>
        <button *ngIf="details.botUserGrpDetailsStatus === false" 
        (click)="botUserGroupDetailsStatusUpdate(details.botUserGrpDetailsId);" class="btn btn-success">Activate</button>
        <button *ngIf="details.botUserGrpDetailsStatus === true" 
        (click)="botUserGroupDetailsStatusUpdate(details.botUserGrpDetailsId);" class="btn btn-danger">Dectivate</button>
      </td>
    </tr>
  </tbody>
</table>

</div>
  
<div *ngIf="context==='assignMultipleUsersToAgent'">


  <div *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
<br>
<div>
<label>Selected Customers / Users :</label>
<mat-select-trigger>
<mat-chip-list  style="display: flex;">
<mat-chip *ngFor="let topping of selectedUsers"
[removable]="true" (removed)="onToppingRemoved(topping)">
{{ topping.name }}
<mat-icon matChipRemove>cancel</mat-icon>
</mat-chip>

</mat-chip-list>
</mat-select-trigger>
</div>
<br>
  </div>

<div>
<label *ngIf="allEligibleUsers!==null && allEligibleUsers!==undefined && allEligibleUsers.length>0">Choose Users :</label>

<mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

<div *ngIf="usersFetched===false">
  <div *ngIf="allEligibleUsers===null || allEligibleUsers===undefined || allEligibleUsers.length===0">
    <br>
  <button style="margin: 0.5%;" (click)="open(getAllUsersWarning2);" 
  mat-raised-button>Get All Users</button>

  <button style="margin: 0.5%;" (click)="open(searchByNameNumberModal2);"
  mat-raised-button>Search For Users</button>

  <button style="margin: 0.5%;" (click)="open(getAllUsersWarning3);" 
  mat-raised-button>Get Users Without Agents</button>

  <button style="margin: 0.5%;" (click)="open(selectAgentModal);"
  mat-raised-button>Get By Agent Assigned</button>

  <button style="margin: 0.5%;" (click)="open(searchByCustomVar1Modal);"
  mat-raised-button>Search By Custom Variable 1</button>

  <button style="margin: 0.5%;" (click)="open(searchByCustomVar2Modal);"
  mat-raised-button>Search By Custom Variable 2</button>
  </div>
</div>



<mat-form-field *ngIf="allEligibleUsers!==null && allEligibleUsers!==undefined && allEligibleUsers.length>0" 
[style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
<mat-label>Choose Customers / Users</mat-label>
<mat-select [formControl]="toppingsControl"
(selectionChange)="multiSelectValueChange($event.value);" multiple>
<input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" 
placeholder="Start searching by name or phone number">
<mat-option (click)="selectAll('Select All', '')" value="Select All">Select All</mat-option>
<mat-option (click)="selectAll(cb.botUserId, cb)" *ngFor="let cb of allEligibleUsers | filter:searchText" 
[value]="cb.botUserId">{{cb.name}} ( {{cb.phone}} ) (Agent : {{cb.assignedAgentName}} )</mat-option>
</mat-select>
</mat-form-field>
</div>


</div> 
  

 
</form>
</div>
</div>

<div class="modal-footer">
<button *ngIf="context==='createBotUserGroup'" [disabled]="clicked"   
[disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="createOrEditBotUserGroup();">Save</button>

<button *ngIf="context==='assignMultipleUsersToAgent'" [disabled]="clicked"   
[disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="assignOnSubmit();">Assign</button>

<button *ngIf="context==='editBotUserGroup'" [disabled]="clicked"   
[disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="createOrEditBotUserGroup();">Update</button>
</div>

<ng-template #getAllUsersWarning let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
      Get All Users ?
    </h3>

    Are you sure to get all users ? <br>
    This might take a while <br> <br>

    <button style="float: right;width: auto;margin: 0.5%;" (click)="getAllBotCustomerUsersByOfficeId();modal.dismiss('Cross click')"
    mat-raised-button>Get All Users</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div> 
 
</ng-template>

<ng-template #getAllUsersWarning2 let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
      Get All Users ?
    </h3>

    Are you sure to get all users ? <br>
    This might take a while <br> <br>

    <button style="float: right;width: auto;margin: 0.5%;" (click)="getCustomerUsersForAgentReassignment('get_all');modal.dismiss('Cross click')"
    mat-raised-button>Get All Users</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div> 
 
</ng-template>

<ng-template #getAllUsersWarning3 let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
      Get All Users Without Agents ?
    </h3>

    All the users without an agent assigned to them will be listed. <br>
    This might take a while <br> <br>

    <button style="float: right;width: auto;margin: 0.5%;" (click)="getCustomerUsersForAgentReassignment('without_agent');modal.dismiss('Cross click')"
    mat-raised-button>Get All Users</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div> 
 
</ng-template>

<ng-template #searchByNameNumberModal let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
     Search Users By Name or Phone
    </h3>

    <div>
      <label class="required" for="botUserGrpName">Name or Phone</label>
      <input maxlength="255" required class="form-control" type="text" 
       name="searchValue" id="searchValue" [(ngModel)]="searchValue">
    </div>
    <br> <br>

    <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByNameOrPhone();modal.dismiss('Cross click')"
    mat-raised-button>Search</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div>
</ng-template>

<ng-template #searchByNameNumberModal2 let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
     Search Users By Name or Phone
    </h3>

    <div>
      <label class="required">Search Type </label> <br>
      <mat-radio-group name="searchType" id="searchType" required aria-label="searchType" [(ngModel)]="searchType">
        <mat-radio-button name="searchType" id="sw" value="starting with">Starting with (Name or Number Starting with)</mat-radio-button>
        <mat-radio-button name="searchType" id="cn" value="contains">Contains (Name or Number that contains)</mat-radio-button>
      </mat-radio-group>
    </div> 

      <br>

    <div>
      <label class="required" for="botUserGrpName">Name or Phone</label>
      <input maxlength="255" required class="form-control" type="text" 
       name="searchValue" id="searchValue" [(ngModel)]="searchValue">
    </div>
    <br> <br>

    <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByNameOrPhoneForAgentReassignment();modal.dismiss('Cross click')"
    mat-raised-button>Search</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div>
</ng-template>

<ng-template #selectAgentModal let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
     Choose Agent to get Users assigned to them
    </h3>

    <mat-form-field [style.width.%]=100>
      <mat-label>Choose Agent</mat-label>
      <mat-select [(value)]="agentUroSelected">
        <mat-option></mat-option>
        <mat-option [value]="option.agentUroId" *ngFor="let option of agents">{{option.firstName}} {{option.lastName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br> <br>

    <button style="float: right;width: auto;margin: 0.5%;" (click)="getUsersByAgent('get_by_agent_uro_id');modal.dismiss('Cross click')"
    mat-raised-button>Get Users</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div>
</ng-template>

<ng-template #searchByCustomVar1Modal let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
     Search Users By Custom Var 1
    </h3>

    <div>
      <label class="required" for="botUserGrpName">Value</label>
      <input maxlength="255" required class="form-control" type="text" 
       name="searchValue" id="searchValue" [(ngModel)]="searchValue">
    </div>
    <br> 

    Note : To get Users with empty 'Custom Var 1', please leave the above input box empty and click 'Search'.
    <br> <br>
    <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByCustomVarForAgentReassignment('1');modal.dismiss('Cross click')"
    mat-raised-button>Search</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div>
</ng-template>

<ng-template #searchByCustomVar2Modal let-modal>
  <div class="modal-body" style="color: black;">

    <h3 style="font-weight: bold;">
     Search Users By Custom Var 2
    </h3>

    <div>
      <label class="required" for="botUserGrpName">Value</label>
      <input maxlength="255" required class="form-control" type="text" 
       name="searchValue" id="searchValue" [(ngModel)]="searchValue">
    </div>
    <br> 
    Note : To get Users with empty 'Custom Var 2', please leave the above input box empty and click 'Search'.

    <br> <br>
    <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByCustomVarForAgentReassignment('2');modal.dismiss('Cross click')"
    mat-raised-button>Search</button>
    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
    
  </div>
</ng-template>