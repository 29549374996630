 <div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Activity / Assessment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div> 

  

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #apForm="ngForm"> 

      <div style="width: 100%;color: black;text-align: center;" >
         <a style="cursor:pointer;">
            <img style="border-radius: 50%;" [src]="safeImageUrl" width="75" height="75"
            alt="" loading="lazy">
         </a>
       </div>
       <br>

       <div class="form-group row">

        <div *ngIf="activityPlanner.type === 'assignment' " class="col-md-12">
          <label  for="type"> Type</label>
          <input readonly placeholder="Type" class="form-control" value="Assignment" type="text" name="type"  id="type" >
        </div>

        <div *ngIf="activityPlanner.type === 'activityPlanner' " class="col-md-12">
          <label  for="type"> Type</label>
          <input readonly placeholder="Type" class="form-control" value="Activity Planner" type="text" name="type"  id="type" >
        </div>

        <div *ngIf="activityPlanner.type === 'parentRecurringActivity' " class="col-md-12">
          <label  for="type"> Type</label>
          <input readonly placeholder="Type" class="form-control" value="Parent Activity / Feedback" type="text" name="type"  id="type" >
        </div>

       </div>

       <br>

      <div class="form-group row">

         <div class="col-md-6">
            <label class="required"  for="activityPlannerName"> Name</label>
            <input  maxlength="50"  #activityPlannerName="ngModel" required placeholder="Enter Activity Name" required class="form-control" type="text" name="activityPlannerName"  id="activityPlannerName" [(ngModel)]="activityPlanner.activityPlannerName">
            <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Name is required</div>
         </div>



         <div class="col-md-6">
            <label for="name"> Image</label>
            <input type="file" class="form-control" (change)="onFileSelected($event)" placeholder=" Choose File To Upload" >
          </div>

      </div>

     

      

         <div>
            <label class="required"  for="activityPlannerDescription">Description</label>
            <textarea  maxlength="5000" placeholder="Enter Description" required class="form-control" type="text" name="activityPlannerDescription"  id="activityPlannerDescription" [(ngModel)]="activityPlanner.activityPlannerDescription"></textarea>
         </div>

        <br>


  

         

  
     
       

         

    </form>
      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button  [disabled]="clicked"  [disabled]="!apForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true">Update</button>
  </div>