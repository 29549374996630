<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">ADD {{type}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
     
          <form (ngSubmit)="onSubmit" #customerRegisteration="ngForm">
           
            <div class="form-group row">
              <div class="col-md-4">
  
                <label class="required">Entity Type </label> <br>
              <mat-radio-group name="entityType" required aria-label="entityType" [(ngModel)]="combinedPTC.entityType">
                <mat-radio-button name="entityType" id="Individual" value="Individual"  >Individual</mat-radio-button>
                <mat-radio-button name="entityType" id="Organisation" value="Organisation"  >Organisation</mat-radio-button>
              </mat-radio-group>
      
              </div>
      
              <div *ngIf="type === 'LEAD'" class="col-md-4">
  
                <label class="required">Lead Type </label> <br>
              <mat-radio-group name="leadType" required aria-label="leadType" [(ngModel)]="combinedPTC.leadType">
                <mat-radio-button name="leadType" id="Hot" value="Hot"  >Hot Lead</mat-radio-button>
                <mat-radio-button name="leadType" id="Cold" value="Cold"  >Cold Lead</mat-radio-button>
              </mat-radio-group>
      
              </div>

              <div class="col-md-4">
  
                <label >GST </label> <br>
                <input #gst="ngModel"  maxlength="50" minlength="3" class="form-control" type="text" placeholder="Enter gst" name="gst" id="gst" [(ngModel)]="combinedPTC.gst">
                <div class="alert alert-danger" *ngIf="gst.touched && !gst.valid"> Required</div>
               
              </div>
      
            </div>
              
              <div class="form-group row">
              
                <div class="col-md-6">
                <label class="required" for="firstName"> Name</label>
                <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
                </div>
              
                <div class="col-md-6">
                  <label for="email">Email</label>
                  <input readonly #email="ngModel" maxlength="250" email class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
                  <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
                  </div>
        
              </div>
              
              <div class="form-group row">
              
                <div class="col-md-6">
                <label for="contactPerson"> Contact Person</label>
                <input #contactPerson="ngModel"  maxlength="50" minlength="3" class="form-control" type="text" placeholder="Enter Name" name="contactPerson" id="contactPerson" [(ngModel)]="combinedPTC.contactPerson">
                <div class="alert alert-danger" *ngIf="contactPerson.touched && !contactPerson.valid"> Name is Required</div>
                </div>
              
                <div class="col-md-6">
                  <label for="contactPersonEmail">Contact Person Email</label>
                  <input #contactPersonEmail="ngModel" maxlength="250"    class="form-control" type="contactPersonEmail" placeholder="Enter Email" name="contactPersonEmail" id="contactPersonEmail" [(ngModel)]="combinedPTC.contactPersonEmail">
                  <div class="alert alert-danger" *ngIf="contactPersonEmail.touched && !contactPersonEmail.valid">Email is required</div>
                  </div>
      
              </div> 
              
              <div class="form-group row">

                <div class="col-md-2">
                  <label class="required" for="countryDialCode"> Code</label>
                  <select class="form-control" name="countryDialCode" required id="countryDialCode"
                  [(ngModel)]="combinedPTC.countryDialCode">
                  <option value="" disabled selected>Select Country</option>
                  <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                  </select>
                  </div>

                <div class="col-md-4">
                  <label for="phoneNumber">Customer Phone</label>
                  <input readonly #phone="ngModel" maxlength="250" minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                  <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
                  </div>

                  <div class="col-md-2">
                    <label for="contactPersonCountryDialCode"> Code</label>
                    <select class="form-control" name="contactPersonCountryDialCode" id="contactPersonCountryDialCode"
                    [(ngModel)]="combinedPTC.contactPersonCountryDialCode">
                    <option value="" disabled selected>Select Country</option>
                    <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                    </select>
                    </div>

                <div class="col-md-4">
                  <label for="contactPersonPhone">Contact Person Phone</label>
                  <input #contactPersonPhone="ngModel" maxlength="250"    minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="contactPersonPhone" id="contactPersonPhone" [(ngModel)]="combinedPTC.contactPersonPhone">
                  <div class="alert alert-danger" *ngIf="contactPersonPhone.touched && !contactPersonPhone.valid">Invalid Phone Number </div>
                  </div>

              </div>
      
      
              <div>
                <label for="fkLocationGroupId">Location Group</label> 
                    <select class="form-control" [(ngModel)]="combinedPTC.fkLocationGroupId" id="fkLocationGroupId" name="fkLocationGroupId">
                      <option value="" disabled >Choose Location Group</option>
                      <option  *ngFor="let lg of locationGroups"  [ngValue]="lg.id">{{lg.groupName}} ( {{lg.groupCode}} )</option>
                    </select>
                </div>
              
             
              <br>
              
              <div>
                <label class="required" for="customerAddress">Address</label>
                <textarea  maxlength="5000" placeholder="Enter Address" required class="form-control" type="text" name="customerAddress"  id="customerAddress" [(ngModel)]="combinedPTC.customerAddress"></textarea>
             </div> 
             
              
             <br>
      
              <div>
                <label for="notes">Notes</label>
                <textarea  maxlength="5000" placeholder="Enter notes" class="form-control" type="text" name="notes"  id="notes" [(ngModel)]="combinedPTC.notes"></textarea>
             </div> 
              
              
                      </form>
  
    </div>
  </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!customerRegisteration.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
  </div>
  