import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-parent-or-child-details-modal',
  templateUrl: './view-parent-or-child-details-modal.component.html',
  styleUrls: ['./view-parent-or-child-details-modal.component.scss']
})
export class ViewParentOrChildDetailsModalComponent implements OnInit {
  
  constructor() { }

 
  ngOnInit(): void {
}

}
