import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Shipment } from 'src/app/crmClasses/shipment';
import { GlobalClass } from 'src/app/global/global-class';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-shipment-details',
  templateUrl: './view-shipment-details.component.html',
  styleUrls: ['./view-shipment-details.component.scss']
})
export class ViewShipmentDetailsComponent implements OnInit {

  shipment : Shipment = new Shipment();

  shipmentId!: string;
  loggedInUser : any;
  theme : any;
  @Input() fromParent: any; 

  constructor(private shipmentService : ShipmentService, private datePipe :DatePipe,
    private sharedDataService: SharedDataService,
              private activeModal: NgbActiveModal ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.shipmentId = this.fromParent.shipmentId;

    this.shipmentService.getShipmentById(this.shipmentId).subscribe(data=>{
      this.shipment = data;
 
    //   let sd = this.shipment.shippedDateTime;
    //   let ed = this.shipment.expectedDeliveryDateTime;
    //   let dd = this.shipment.deliveryDateTime;

    //  let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
    //  let expectedDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);
    //  let deliveryDate =  new Date(dd[0], dd[1] - 1, dd[2], dd[3], dd[4]);

    //  this.shipment.shippedDateTime = startDate;   
    //  this.shipment.expectedDeliveryDateTime = expectedDate;
    //  this.shipment.deliveryDateTime = deliveryDate;           

    //  this.shipment.shippedDateTime = this.datePipe.transform(startDate, 'yyyy-MM-ddThh:mm');   
    //  this.shipment.expectedDeliveryDateTime = this.datePipe.transform(expectedDate, 'yyyy-MM-ddThh:mm');
    //  this.shipment.deliveryDateTime = this.datePipe.transform(deliveryDate, 'yyyy-MM-ddThh:mm');    

    if(this.shipment.shippedDateTime !== null && this.shipment.shippedDateTime !== undefined){
      let sd = this.shipment.shippedDateTime;
      let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
      this.shipment.shippedDateTime = startDate;   
      this.shipment.shippedDateTime = this.datePipe.transform(startDate, 'yyyy-MM-ddThh:mm');   
      }


      if(this.shipment.expectedDeliveryDateTime !== null && this.shipment.expectedDeliveryDateTime !== undefined){
      let ed = this.shipment.expectedDeliveryDateTime;
      let expectedDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);
      this.shipment.expectedDeliveryDateTime = expectedDate;
      this.shipment.expectedDeliveryDateTime = this.datePipe.transform(expectedDate, 'yyyy-MM-ddThh:mm');
      }

      if(this.shipment.deliveryDateTime !== null && this.shipment.deliveryDateTime !== undefined){
      let dd = this.shipment.deliveryDateTime;
     let deliveryDate =  new Date(dd[0], dd[1] - 1, dd[2], dd[3], dd[4]);
     this.shipment.deliveryDateTime = deliveryDate;           
     this.shipment.deliveryDateTime = this.datePipe.transform(deliveryDate, 'yyyy-MM-ddThh:mm');    
      }
    }, error => console.log(error));
  }

  closeModal(){
    this.activeModal.close();
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

}
