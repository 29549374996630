export class BotConditionalResponse {
    botCondResId:any;
	botCondResStatus:any;
	fkOfficeId:any;
	fkBotResponseId:any;
	nextBotResponseId:any;
	conditionValue:any;
	conditionType:any;
	list : BotConditionalResponse[] = [];
	selected : any;
	slNo=0;
}
