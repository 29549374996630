export enum MODAL_HEADERS {
	ADD_ROLE = 'Add Role',
	MODIFY_ROLE = 'Edit Role',

    ADD_USER = 'Add User',
    EDIT_USER = 'Edit User',

    ADD_BULK_USER = 'Add Bulk User',
    EDIT_BULK_USER = 'Edit Bulk User',

    ADD_OFFICE = 'Add Office',
    EDIT_OFFICE = 'Edit Office',

    ADD_PRODUCT = 'Add Product',
    EDIT_PRODUCT = 'Edit Product',

    ADD_USER_SUBSCRIPTION = 'Add User Subscription',
    EDIT_USER_SUBSCRIPTION = 'Edit User Subscription'

}