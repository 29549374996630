
  <nav class="navbar navbar-expand-md navbar-dark " 
  [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  


  <div style="height: 100%;position: absolute;width: 100%;margin: 0%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;"  (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;"  onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Activity Assessment</li>
    </ol>
  </nav>


   



  <div class="col-lg-12" style="margin-top: 1em;">

      
  
    <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style=" cursor: pointer; color: white; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>
        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Assessment
        </span>
        <a style="cursor:pointer;margin-right: 2%;" class="float-right" (click)="open(dRemarks)">
          <img src="/assets/images/icons/searchIcon.svg" width="25" height="25" alt="" loading="lazy">
        </a>
      </div> 
    </div>
  <div class="accor_dion " style="background-color: transparent;">
    <mat-accordion class="example-headers-align" *ngFor="let student of students2">
      <mat-expansion-panel [expanded]="step === student.studentId" (opened)="setStep(student.studentId)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <img class="student-image" alt="" height="30" loading="lazy" [src]="student.safeStudentUrl" width="30">

            {{student.studentName}}
          </mat-panel-title>
         
            
   
        </mat-expansion-panel-header>

       
            <div class="container">
  
              <div class="table-responsive">          
              <table class="table">
                <thead>
                  <tr>
                    <th>Activities</th>
                    <th>Attempts</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                
                 <tr *ngFor="let activity of student.activityPlanners">
                   <td style="text-align: left;">
                    <a style="font-weight: normal;color: black;">

                      <img style="margin: 2%; border-radius: 50%;" class="student-image" alt="" height="23" loading="lazy" 
                      [src]="activity.activityPlannerImageUrl" width="23">

                      {{activity.activityPlannerName}}</a>

                      <img (click)="viewAPDetails(activity.activityPlannerId);" style="margin: 2%; border-radius: 50%;cursor: pointer;" 
                      alt="" height="20" loading="lazy" 
                      src="assets/images/icons/info.png" width="20">

                   </td>
                   <td>

                    <a *ngIf="activity.studentAttempts === 0" style="color: rgb(179, 20, 20);">
                      <img class="student-image" alt="" height="25" loading="lazy"
                       src="assets/images/icons/nonStarted.png" width="25">0 Attempts</a>
                 
                       <a *ngIf="activity.studentAttempts === 1" style="color: rgb(179, 140, 14);">
                        <img class="student-image" alt="" height="25" loading="lazy"
                         src="assets/images/icons/inProgress.png" width="25">{{activity.studentAttempts}} Attempt</a>
  
                    <a *ngIf="activity.studentAttempts > 1" style="color: rgb(179, 140, 14);">
                      <img class="student-image" alt="" height="25" loading="lazy"
                       src="assets/images/icons/inProgress.png" width="25">{{activity.studentAttempts}} Attempts</a>

                   </td>
                   <td>

          <button *ngIf="activity.activityCompletionStatus === false"  style="margin: 3%;"
          (click)="onEvaluate(activity.activityPlannerId, student.studentId, student.safeStudentUrl, student.studentName)"
           class="btn btn-info" [ngStyle]="backgroundStyle">Evaluate</button>

           <button  *ngIf="activity.studentAttempts > 0 && activity.activityCompletionStatus === false" style="width: auto;margin: 3%;" 
            (click)="MarkActivityCompletedForStudent(student.studentId, activity.activityPlannerId)" class="btn btn-success" >Mark As Completed</button>

           <button *ngIf="activity.activityCompletionStatus === true" style="width: auto;margin: 3%;"
            (click)="UnMarkActivityCompletedForStudent(student.studentId, activity.activityPlannerId)" class="btn btn-primary" >Mark As In-Progress</button>
                   </td>
                 </tr>
                
                </tbody>
              </table>
              </div>
            </div>
      </mat-expansion-panel>
    
    </mat-accordion>
  </div>
</div>
  <!-- </div>

</div> -->
<br>
<br>
</div>




<ng-template #dRemarks let-modal>
  <div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title"> Activities</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-form-field [style.width.%]=100 appearance="fill" style=" color: white; margin-right: 2%; border-top: 0;">
      <mat-label>Select Activities</mat-label>
      <mat-select [formControl]="toppingsControl" multiple>
  
  
        <div class="input-icons">
        <i class="fa fa-search icon"></i>
        <input class="input-field" style="width: 100%;" (keyup)="onKey($any($event.target).value)">
      </div>
       
  
        <mat-option [value]="0" (click)="selectAll(ev)"   
        #ev>Select All</mat-option>
  
        <mat-option *ngFor="let topping of activityPlanners" 
        [value]="topping.activityPlannerId">{{topping.activityPlannerName}}</mat-option>
    
      </mat-select>
    </mat-form-field>
    
   <button style="float: right;" (click)="apply();modal.dismiss('Cross click')" class="btn btn-info" >Search</button>
  </div> 

</ng-template> 