import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { DatePipe } from '@angular/common';
import { BotInvoiceDetails } from 'src/app/BOT_CODE/bot_classes/bot-invoice-details';

@Component({
  selector: 'app-invoice-details-view',
  templateUrl: './invoice-details-view.component.html',
  styleUrls: ['./invoice-details-view.component.scss']
})
export class InvoiceDetailsViewComponent implements OnInit {

  clicked = false;
  userExists = false;
  loggedInUser : any;
  theme : any; 

  constructor(private botOfficeService : BotOfficeServiceService,
     private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal, private datePipe : DatePipe,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    invoiceId:any;
    invoiceDetails : BotInvoiceDetails[] = [];
    ngOnInit(): void {

    this.invoiceId = this.fromParent.invoiceId;
               
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getBotInvoiceDetailsByInvoiceId();

  }

  getBotInvoiceDetailsByInvoiceId(){
    this.botOfficeService.getBotInvoiceDetailsByInvoiceId(environment.apiUrl + "botOfficeShop/getBotInvoiceDetailsByInvoiceId/" + this.invoiceId
    ).then(
      res=>{        
        let resObj : BotInvoiceDetails = new BotInvoiceDetails();
        resObj = res;
       this.invoiceDetails = resObj.invoiceDetails;       
      });
  }



  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}