<!-- <section class="height-100vh d-flex align-items-center page-section-ptb login">
  <div class="container">
    <div class="row justify-content-center no-gutters vertical-align">
      <div class="col-lg-4 col-md-6 login-fancy-bg">
        <div class="login-fancy">
          <h2 class="text-white mb-3">Change Password</h2>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 bg-white">
        <div class="login-fancy pb-4 clearfix">
          <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm)" class="customerRegisteration-form">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="current-password" class="required">Current Password</label>
                <input class="form-control" id="current-password" name="current-password" placeholder="Current Password"
                       type="password" required formControlName="currentPassword"/>
              </div>

              <div class="form-group col-md-12">
                <label for="new-password" class="required">New Password</label>
                <input class="form-control" id="new-password" name="new-password" placeholder="New Password" required
                       type="password" formControlName="newPassword"/>
                       <div *ngIf="changePasswordForm.controls['newPassword'] .hasError('minlength') && !changePasswordForm.controls['currentPassword'] .hasError('valid') " class="alert alert-danger">Use 8 or more characters Required</div>
              </div>

              <div class="form-group col-md-12">
                <label for="confirm-new-password" class="required">Confirm New Password</label>
                <input class="form-control" id="confirm-new-password" name="confirm-new-password" placeholder="Confirm New Password"
                       required
                       type="password" formControlName="confirmNewPassword"/>
                       <div *ngIf="changePasswordForm.controls['confirmNewPassword'].hasError('minlength') && !changePasswordForm.controls['currentPassword'] .hasError('valid') " class="alert alert-danger">Use 8 or more characters Required</div>
                       <div *ngIf="f.confirmNewPassword.touched && f.confirmNewPassword.invalid" class="alert alert-danger">
                        <div *ngIf="f.confirmNewPassword.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
                    </div>
              </div>

            </div>
            <div class="form-group">
              <button class="btn button" type="submit">
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section> -->


<div style="width: 100%; height: 100%; display: block;"
 [style.background-image]="'url(/assets/images/outerBg.png'" 
 style="background-position: right; background-repeat: no-repeat; background-size: cover;">

<!-- <div class="login-wrap"  [style.background-image]="'url(/assets/images/innerBg.png'" 
style="background-position: center; background-repeat: no-repeat; background-size: cover;" > -->

<div class="login-wrap" 
style="background-position: center; background-repeat: no-repeat; background-size: cover;" >
	

	<div class="login-html">

    <div style="text-align: center;">
      <img style="border-radius: 50%;" height="100" width="100" class="logo-image"
        src="/assets/images/logo.png" />
    </div>
    <br>
    <br>
		<input id="tab-1"  type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">Change Password</label>
		<input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab"></label>
		<div class="login-form">
			<div class="sign-in-htm">
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm)" class="customerRegisteration-form">
				<div class="group">
					<label for="user"  style="color: white; font-weight: bold;font-size: 12px;"  class="label">Current Password</label>
          <input class="form-control input" id="current-password" name="current-password" placeholder="Current Password"
          type="password" required formControlName="currentPassword"/>
              
				</div>

				<div class="group">
					<label for="pass"  style="color: white; font-weight: bold;font-size: 12px;"  class="label">New Password</label>
         <input class="form-control input" id="new-password" name="new-password" placeholder="New Password" required
                       type="password" formControlName="newPassword"/>
                       <div *ngIf="changePasswordForm.controls['newPassword'] .hasError('minlength') && !changePasswordForm.controls['currentPassword'] .hasError('valid') " class="alert alert-danger">Use 8 or more characters Required</div>
                   	</div>

  <div class="group">
  <label for="pass"  style="color: white; font-weight: bold;font-size: 12px;"  class="label">Confirm New Password</label>
  <input class="form-control input" id="confirm-new-password" name="confirm-new-password" placeholder="Confirm New Password"
  required
  type="password" formControlName="confirmNewPassword"/>
  <div *ngIf="changePasswordForm.controls['confirmNewPassword'].hasError('minlength') && !changePasswordForm.controls['currentPassword'] .hasError('valid') " class="alert alert-danger">Required, Use 8 or more characters </div>
  <div *ngIf="f.confirmNewPassword.touched && f.confirmNewPassword.invalid" class="alert alert-danger">
    <div *ngIf="f.confirmNewPassword.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
  </div>
  </div>

				
       <br>
       <br>
				<div class="group">
					<input type="submit" class="button"  value="Submit">
          
				</div>
      </form>
				<div class="hr"></div>
				<!-- <div class="foot-lnk">
					<a style="color: white; cursor: pointer;" ></a>
				</div> -->
       
			</div>
			
	</div>
</div>
</div>

</div>