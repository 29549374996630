<p>Update Customer</p>

<div>
  <form >
 
    <div>
      <label for="customerId">Id</label>
      <input type="text" name="customerId" id="customerId" [(ngModel)]="customer.customerId">
    </div>

    <div>
      <label for="firstName">First Name</label>
      <input type="text" name="firstName" id="firstName" [(ngModel)]="customer.firstName">
    </div>

    <div>
      <label for="lastName">Last Name</label>
      <input type="text" name="lastName" id="lastName" [(ngModel)]="customer.lastName">
    </div>

    <div>
      <label for="customerGender">Gender</label>
      <input type="text" name="customerGender" id="customerGender" [(ngModel)]="customer.customerGender">
    </div>

    <div>
      <label for="customerDob">Date of birth</label>
      <input type="date" name="customerDob" id="customerDob" [(ngModel)]="customer.customerDob">
    </div>

    <div>
      <label for="customerPhone">Phone</label>
      <input type="text" name="customerPhone" id="customerPhone" [(ngModel)]="customer.customerPhone">
    </div>

    <div>
      <label for="customerAltPhone">Alternate Phone</label>
      <input type="text" name="customerAltPhone" id="customerAltPhone" [(ngModel)]="customer.customerAltPhone">
    </div>

    <div>
      <label for="customerEmailId">EmailId</label>
      <input type="text" name="customerEmailId" id="customerEmailId" [(ngModel)]="customer.customerEmailId">
    </div>

    <div>
      <label for="customerStatus">Status</label>
      <input type="text" name="customerStatus" id="customerStatus" [(ngModel)]="customer.customerStatus">
    </div>

    <div>
      <button (click)="onUpdate()">Update</button>
    </div>

  </form>
</div>
