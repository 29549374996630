import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackAnswer } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-answer';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ViewFeedbackQuestionComponent } from '../../feedback-question/view-feedback-question/view-feedback-question.component';
import { EditFeedbackAnswerComponent } from '../edit-feedback-answer/edit-feedback-answer.component';

@Component({
  selector: 'app-view-feedback-answer',
  templateUrl: './view-feedback-answer.component.html',
  styleUrls: ['./view-feedback-answer.component.scss']
})
export class ViewFeedbackAnswerComponent implements OnInit {

  clicked = false;
  feedbackForm : FeedbackForm = new FeedbackForm();
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  feedbackAnswers : FeedbackAnswer[] = [];

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    this.getAnswers();

  }

  editAnswer(ans : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFeedbackAnswerComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      answer : ans
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAnswers();
    });  
  }

  getAnswers(){
    this.formService.getFeedbackAnswersByOfficeId(environment.apiUrl + "feedbackAnswer/getByOfficeId").then(
      data=>{
        this.feedbackAnswers = data;
      });
  }


  onSubmit(){
 
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 