import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

// TODO: Add Angular decorator.
@Directive()
export abstract class DestroyService implements OnDestroy {
	// unsubscrber to collect all subscriptions and clean it up on destroy
	private _unsubscriber: Subject<void> = new Subject<void>();

	// Using property to delay creation of the object until it is needed
	get unSubcriber() {
		if (this._unsubscriber === null) {
			this._unsubscriber = new Subject<void>();
		}
		return this._unsubscriber;
	}

	ngOnDestroy(): void {
		// clean up for all registered subscriptions
		// console.log('Destroy.................');
		if (this._unsubscriber !== null) {
			this._unsubscriber.next();
		}
	}
}
