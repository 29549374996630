<!-- <nav class="navbar navbar-expand-sm bg-primary navbar-dark"> 
   <ul class="navbar-nav">
      <li class="nav-item">
         <a routerLink="completeCustomerDetailsList" routerLinkActive="active" class="nav-link">Customer List</a>
      </li>
      <li class="nav-item">
         <a routerLink="addCompleteCustomerDetails" routerLinkActive="active" class="nav-link">Create Customer</a>
      </li>
   </ul>
</nav>   -->


<div class="container">
   <router-outlet></router-outlet>
</div>

<app-footer class="mt-auto"></app-footer>
<!-- new code
<nav class="navbar navbar-expand-sm bg-primary navbar-dark"> 
   <ul class="navbar-nav">
      <li class="nav-item">
         <a routerLink="customerList" routerLinkActive="active" class="nav-link">Customer List</a>
      </li>
      <li class="nav-item">
         <a routerLink="createCustomer" routerLinkActive="active" class="nav-link">Create Customer</a>
      </li>
   </ul>
</nav> -->



