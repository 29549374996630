import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotResponse } from 'src/app/BOT_CODE/bot_classes/bot-response';

@Component({
  selector: 'app-get-global-temp-res',
  templateUrl: './get-global-temp-res.component.html',
  styleUrls: ['./get-global-temp-res.component.scss']
})
export class GetGlobalTempResComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any; 

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 

  constructor(private sharedDataService: SharedDataService,
    private botResponseService : BotResponseServiceService,
    private ngbModalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    globalTemplResponses : BotResponse[] = [];
    parentResponseId : any;
    parentOptionId : any;

    search = true;
    context : any;

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;
    if(this.context==="RESPONSE"){
      this.parentResponseId = this.fromParent.parentResponseId;
    }else  if(this.context==="OPTION"){
      this.parentOptionId = this.fromParent.parentOptionId;
    }


  }

  gtt !: string;
  getGlobalTemplateResponses(){
    this.botResponseService.getGlobalTemplateResponses(environment.apiUrl + "botConvo/getGTRs/" + this.gtt).then(
      res=>{
       let botRes : BotResponse = new BotResponse();
       botRes = res;
       if(botRes !== null && botRes !== undefined 
        && botRes.responses !== null && botRes.responses !== undefined 
        && botRes.responses.length > 0){
        this.globalTemplResponses = botRes.responses;
        this.search = false;
       }else{
        this.toastr.error("Search did not bring up results ! try with a different tag");
       }
      });
  }

  

   closeResult!:string;
   open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectedGtrId = null;
  LinkGlobalTemplateResponseToResponse(){
    if(this.selectedGtrId === null){
this.toastr.error("Choose a response to proceed !")
    }else{
      this.clicked = true;
      let selectedGTR : BotResponse = new BotResponse();
      this.globalTemplResponses.forEach((gtr)=>{
        if(gtr.botResponseId === this.selectedGtrId){
          selectedGTR = gtr;
        }
      });
      selectedGTR.parentResponseId = this.parentResponseId;
      this.botResponseService.copyGlobalTemplateResponse(environment.apiUrl + "botConvo/LinkGlobalTemplateResponseToResponse", selectedGTR).then(
        res=>{
          selectedGTR = res;
          this.toastr.success("Response copied successfully");
          this.passEntry.emit(selectedGTR.botResponseId);
          this.activeModal.close();
        });
     }
  }

  LinkGlobalTemplateResponseToOption(){
    if(this.selectedGtrId === null){
this.toastr.error("Choose a response to proceed !")
    }else{
      this.clicked = true;
      let selectedGTR : BotResponse = new BotResponse();
      this.globalTemplResponses.forEach((gtr)=>{
        if(gtr.botResponseId === this.selectedGtrId){
          selectedGTR = gtr;
        }
      });
      selectedGTR.parentOptionId = this.parentOptionId;
      this.botResponseService.copyGlobalTemplateResponse(environment.apiUrl + "botConvo/LinkGlobalTemplateResponseToOption", selectedGTR).then(
        res=>{
          selectedGTR = res;
          this.toastr.success("Response copied successfully");
          this.passEntry.emit(selectedGTR);
          this.activeModal.close();
        });
    }

    

  }

 
  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
}