<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Option Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body">


          <div>
            <h4>
              <a style="color: black;font-weight: bold;">Option Number : </a>
              <a style="color: black;"> {{botResponseOption.responseOptionNumber}}</a>
           <br>
              <a style="color: black;font-weight: bold;">Option :  </a>
              <a style="color: black;"> {{botResponseOption.responseOption}}</a>
              <a matTooltip="Edit" style="cursor: pointer;" (click)="editOption()"> 
                <img src="/assets/images/icons/edit.svg" width="21" height="21" 
                alt="" loading="lazy"> </a>
            </h4>
          </div>
        
<br>
<h4 *ngIf="botResponseOption.nextBotResponseId!==null" style="margin: 0%;font-weight: bold;">Next Response : <h4 style="margin: 0%;font-weight: normal;">{{botResponseOption.nextBotResponseResponse}}</h4> </h4>
<br *ngIf="botResponseOption.nextBotResponseId!==null">
<!-- HIDDEN FOR NOW -->
<!-- <h4 *ngIf="botResponseOption.childOptions.length!==0" style="margin: 0%;font-weight: bold;">Child-Options : </h4>
<h4 *ngFor="let cOpt of botResponseOption.childOptions" style="margin: 0%;">
  {{cOpt.responseOptionNumber}}. {{cOpt.responseOption}}
  <a (click)="ViewBotOptionDetails(cOpt.botResponseOptionsId)"> <img src="/assets/images/icons/eye.png" width="25" height="25" 
    alt="" loading="lazy"> </a>
  </h4> -->

  <!-- <button (click)="editOption();" class="btn btn-success" 
  style="width: auto;margin:2%;">Edit</button>  -->

<!-- HIDDEN FOR NOW -->
        <!-- <button (click)="addChildOptionToOption();" class="btn btn-info" style="width: auto;margin:2%;">Create Child Option</button>  -->
       
        <button *ngIf="botResponseOption.nextBotResponseId===null" (click)="addNextResponseToOption();" 
        class="btn btn-primary" style="width: auto;margin:2%;">Create Next Response</button> 
        <button *ngIf="botResponseOption.nextBotResponseId===null" (click)="linkNextResponseToOption();"
        class="btn btn-secondary" style="width: auto;margin:2%;">Link Next Response</button> 
        <button *ngIf="botResponseOption.nextBotResponseId===null" (click)="getTemplateResponsesForOption(botResponseOption.botResponseOptionsId);"
        class="btn btn-secondary" style="width: auto;margin:2%;">Link Global Response</button>
        <button *ngIf="botResponseOption.nextBotResponseId!==null" (click)="open(confirmUnlinkNextResponse);"
        class="btn btn-danger" style="width: auto;margin:2%;">Unlink Next Response</button>

</div>
</div>



<ng-template #confirmUnlinkNextResponse let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to unlink next response to the option <a style="font-weight: bold;">{{botResponseOption.responseOption}}</a> ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="unlinkNextResponse();modal.dismiss('Cross click')">Unlink</button>
  </div>
</ng-template>