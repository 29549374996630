import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
import { TaxService } from 'src/app/BIZ_CRM/biz_crm_services/tax.service';
import { GlobalClass } from 'src/app/global/global-class';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-choose-taxes',
  templateUrl: './choose-taxes.component.html',
  styleUrls: ['./choose-taxes.component.scss']
})
export class ChooseTaxesComponent implements OnInit {

  clicked = false;
  taxes : Tax[] = [];
  cess : Tax[] = [];
  taxesAndCess : Tax[] = [];
  taxesAndCessFinal : Tax[] = [];
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private taxService : TaxService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    this.getTaxesAndCess();
  }

  getTaxesAndCess(){

    this.taxService.getTaxesByOfficeId(environment.apiUrl + "tax/getTaxesByOfficeId").then(
      data=>{
        this.taxesAndCess = data;
        
        this.taxesAndCess.forEach((val)=>{
          if(val.type === "tax"){
            this.taxes.push(val);
          }else if(val.type === "cess"){
            this.cess.push(val);
          }
        });
      });
   
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  handleClick(taxCess : any) {
 
    let exists = false;
    let count = 0;
    this.taxesAndCessFinal.forEach((val)=>{
      count++;
      if(val.id === taxCess.id){
        exists = true;
      }
    });

    if(count === this.taxesAndCessFinal.length){
      if(exists === false){
        this.taxesAndCessFinal.push(taxCess);
        
      }else if(exists === true){

        this.taxesAndCessFinal = this.taxesAndCessFinal.filter(obj => obj !== taxCess);   
    }
    }
  }

  onSubmit(){

    this.passEntry.emit(this.taxesAndCessFinal);
    this.activeModal.close();

  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
 