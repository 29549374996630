<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Assignments</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">

        <h3 *ngIf="submissionsDontExist === true">No Submissions to display !</h3>

        <div class="row col-md-12" style="margin: 0%;" *ngIf="submissionsExist === true">

            <div *ngFor="let sub of submissions" style="padding: 2%;" class="col-md-4 col-sm-6 col-xs-6">

                <div class="col-md-12 " style="text-align: center;">
                    <a style="margin-bottom: 5%;">
                        <img *ngIf="sub.fileType === 'mp4'" class="sub-image" src="/assets/images/icons/videoFile.png" height="65" width="65" alt="...">
                        <img *ngIf="sub.fileType === 'svg' || sub.fileType === 'png' || sub.fileType === 'jpg' || sub.fileType === 'jpeg'" class="sub-image" src="/assets/images/icons/imageFile.png" height="65" width="65" alt="...">
                        <img *ngIf="sub.fileType === 'doc'" class="sub-image" src="/assets/images/icons/wordFile.png" height="65" width="65" alt="...">
                        <img *ngIf="sub.fileType === 'pdf'" class="sub-image" src="/assets/images/icons/pdfFile.png" height="65" width="65" alt="...">
                        <img *ngIf="sub.fileType === 'xls' || sub.fileType === 'xlsx'" class="sub-image" src="/assets/images/icons/excelFile.png" height="65" width="65" alt="...">
                        <img *ngIf="sub.fileType === 'txt'" class="sub-image" src="/assets/images/icons/textFile.png" height="65" width="65" alt="...">
                        <img *ngIf="sub.fileType === 'csv'" class="sub-image" src="/assets/images/icons/csvFile.png" height="65" width="65" alt="...">

                    </a>
                    <br>
                    <button (click)="viewSubmission(sub.fileName , sub.fileType);" style="margin: 6%;" class="btn btn-info">View</button>
                </div>

            </div>
       
        </div>

        <div class="col-md-12" style="text-align: center;" *ngIf="ImageFileExist === true">
          <a style="text-align: center;">
            <img [src]="safeImageUrl" height="200" width="200">
          </a>

          <br>



   
         
          <button style="margin: 10%;" (click)="back();" class="btn btn-dark">Back</button>
        </div>

        <div class="col-md-12" style="text-align: center;" *ngIf="VideoFileExist === true">
          <a style="text-align: center;">
            
            <!-- <div class="container">
              <div class="embed-responsive embed-responsive-16by9">
                 <iframe class="embed-responsive-item" [src]="urlSafe" allowfullscreen></iframe>
               </div> 
           </div> -->

           <video width="320" height="240" controls>
            <source [src]="urlSafe" type="video/mp4">
            <source [src]="urlSafe" type="video/ogg">
          </video>

          </a>

          <br>

          <button style="margin: 10%;" (click)="back();" class="btn btn-dark">Back</button>
        </div>

        <div class="col-md-12" style="text-align: center;" *ngIf="pdfFileExist === true">
          <div class="col-md-12">
            <div>
              <pdf-viewer
                [src]="pdfFile"
                [render-text]="true"
                style="display: block;"
              ></pdf-viewer>
            </div>
          </div>

          <a [href]="pdfFile" id="link" download="whatever.pdf">download me</a>
          <a [href]="pdfFile" download="file.txt">DownloadFile</a>

          <iframe [src]='pdfFile' width="100%" height="1000px"></iframe>

          <object [data]="pdfFile" type="application/pdf"></object>

          <button style="margin: 10%;" (click)="back();" class="btn btn-dark">Back</button>

        </div>

        <div class="col-md-12" style="text-align: center;" *ngIf="txtFileExist === true">
          <!-- <div class="col-md-12">
            <div>
              <pdf-viewer
                [src]="pdfFile"
                [render-text]="true"
                style="display: block;"
              ></pdf-viewer>
            </div>
          </div> -->

          <!-- <a [href]="pdfFile" id="link" download="whatever.pdf">download me</a> -->
          <a style="color : blue" [href]="txtFile" download="file.txt">Download File</a>

          <iframe [src]='txtFile' width="100%" height="1000px"></iframe>

          <!-- <object [data]="txtFile" type="application/xls"></object> -->

          <button style="margin: 10%;" (click)="back();" class="btn btn-dark">Back</button>

        </div>
   
        <div class="col-md-12" style="text-align: center;" *ngIf="xlsFileExist === true">
         

          <!-- <a [href]="pdfFile" id="link" download="whatever.pdf">download me</a> -->
          <a style="color : blue" [href]="txtFile" download="file.xls">Download File</a>

          <iframe [src]='txtFile' width="100%" height="1000px"></iframe>

          <!-- <object [data]="txtFile" type="application/xls"></object> -->

          <button style="margin: 10%;" (click)="back();" class="btn btn-dark">Back</button>

        </div>
   
      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary float-right" (click)="closeUpdateModal();">Close</button>
  </div>