<div class="modal-header">
    <h4 class="modal-title pull-left">Select Student</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  <div class="modal-body">
    

                  
                  <mat-vertical-stepper orientation="vertical" #stepper>

                    <mat-step>
                      <form>
                        <ng-template matStepLabel> Office</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Office</mat-label>
                          <mat-select [(ngModel)]="selectedOfficeId" >
                            <mat-option *ngFor="let office of offices" [value]="office.id">
                              {{office.officeName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div>
                          <button (click)="chooseProduct()" mat-button matStepperNext>Next</button>
                        </div>
                      </form>
                    </mat-step>

                    <mat-step>
                      <form>
                        <ng-template matStepLabel>Course / Product</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Course / Product</mat-label>
                          <mat-select [(ngModel)]="selectedProductId" >
                            <mat-option *ngFor="let product of products" [value]="product.productId">
                              {{product.productName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div>
                          <button mat-button matStepperPrevious>Back</button>
                          <button (click)="chooseSubject()" mat-button matStepperNext>Next</button>
                        </div>
                      </form>
                    </mat-step>

                    <!-- <mat-step>
                      <form>
                        <ng-template matStepLabel>Subject</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Subject</mat-label>
                          <mat-select [(ngModel)]="selectedSubjectId" >
                            <mat-option *ngFor="let subject of subjects" [value]="subject.subId">
                              {{subject.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div>
                          <button mat-button matStepperPrevious>Back</button>
                          <button mat-button matStepperNext>Next</button>
                        </div>
                      </form>
                    </mat-step> -->

                    <mat-step>
                      <form>
                        <ng-template matStepLabel>Student</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Student</mat-label>
                          <mat-select [(ngModel)]="selectedStudentId" >
                            <mat-option *ngFor="let student of students" [value]="student.studentId">
                              {{student.studentName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div>
                          <button mat-button matStepperPrevious>Back</button>
                          <button (click)="onSubmit()" class="btn btn-info" matStepperNext>Proceed</button>
                        </div>
                      </form>
                    </mat-step>

                  </mat-vertical-stepper>
                  
  </div>

  <div class="modal-footer">
    <button class="btn btn-sm btn-primary float-right" (click)="closeUpdateModal()">Cancel</button>
  </div> 