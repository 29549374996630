import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from 'src/app/crmClasses/address';
import { AddressService } from 'src/app/service/address.service';

@Component({
  selector: 'app-create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./create-address.component.scss']
})
export class CreateAddressComponent implements OnInit {
 
  address : Address = new Address();

  constructor(private addressService : AddressService,
    private router : Router) { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.addressService.addCustomer(this.address).subscribe(
            data=>{
        // this.customers.push(data);
        this.goToAddressList();
      }
    )
    // this.customer = new Customer();
  }

  goToAddressList(){
    this.router.navigate(['/addressList']);
  }

}
