
   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">
      Orders [ {{fromParent.targetStartDate}} to {{fromParent.targetEndDate}} ]
      <label style="color: white;">Achieved % :</label> <label style="font-weight: bold;color: white;"> {{achievedPercentage}}%</label>
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">

          <div class="col-md-12 row">
            <div class="col-md-4">
              <label> Name :</label> <label style="font-weight: bold;">{{fromParent.targetName}}</label>
            </div>
            <div  class="col-md-4">
              <label> Goal :</label> <label style="font-weight: bold;">{{fromParent.targetValue}}</label>
            </div>
            <div  class="col-md-4">
              <label> Achieved :</label> <label style="font-weight: bold;">{{fromParent.targetAchieved}}</label>
            </div>
          </div>
        


<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
  <thead class="tableheader">
    <tr>
      <th>Date</th>
      <th>Customer</th>
      <th>Total Amt Before Tax</th>
      <th>Grand Total</th>
      <th>Status</th>
      <th  *ngIf="fromParent.context === 'incentive'" >Incentive Eligibility</th>
    </tr>
  </thead>
  <tbody>
    <tr class="td-height" *ngFor="let ccd of invoices">
      <td class="td-height">{{ccd.invoiceDate}}</td>
      <td class="td-height">{{ccd.customerName}}</td>
      <td style="text-align: right;" class="td-height">{{ccd.totalAmount}}</td>
      <td style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
      <td class="td-height">{{ccd.orderStatus}}</td>
      <td *ngIf="ccd.incentiveEligibity === 'Considered' && fromParent.context === 'incentive'" style="color: green;" class="td-height">{{ccd.incentiveEligibity}}</td>
      <td *ngIf="ccd.incentiveEligibity !== 'Considered' && fromParent.context === 'incentive'" style="color: red;" class="td-height">{{ccd.incentiveEligibity}}</td>

      


   
   
    </tr>    

  </tbody>
</table>
  </div>
      </div>
      </div>