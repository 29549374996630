import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalClass } from 'src/app/global/global-class';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-student-activity-timeline-page',
  templateUrl: './student-activity-timeline-page.component.html',
  styleUrls: ['./student-activity-timeline-page.component.scss']
})
export class StudentActivityTimelinePageComponent implements OnInit {
  showSpinner = true;

  test : any[] = [1,2,3,4,5,6,7,8]
  dirs : any[] = ['direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l']

  currentRole="TEACHER";
  index : any = 0;
  userId!: string;
  userName : any;
  loggedUser : any;
  loggedInUser : any;
  theme: string = '';
  public screenWidth: any;
  public screenHeight: any;
  activitiesExist = true;
  showTimeline = false
  unsafeUrl : any;
  safeUrl : any;

  
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  allActivitiesList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    studentActivityPlannerCompletionStatus : '',
    safeUrl : '',
    selectedState : '',
    studentAttempts : ''

  }]

  details : any ={
    dob: "",
    email: "",
    phone: "",
    studentImage: "",
    studentName: ""
  }
  safeImageUrl: any;
  unsafeImageUrl: any;
  
  studentId!: string;
  subId!: string;

  @Input() fromParent: any; 

   constructor(public activeModal: NgbActiveModal, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private activityPlannerService : ActivityPlannerService,
              private sharedDataService : SharedDataService
              ) { }

ngOnInit(): void {

this.studentId = this.fromParent.studentId;
this.subId =  this.fromParent.subId;
this.details.studentName = this.fromParent.name;
this.safeImageUrl = this.fromParent.image;

// this.getStudentDetails();
this.getActivitiesForTimeline();


this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
return this.loggedInUser.offices[0].theme + '!important';
}

getActivitiesForTimeline(){
  this.activityPlannerService.getStudentAPsForTimeline(environment.apiUrl + "activityPlanner/getStudentAPsForTimeline/" + this.studentId + "/" + this.subId).then(
    data=>{
      this.allActivitiesList = data;

      if(this.allActivitiesList === undefined || this.allActivitiesList.length === 0){
        this.activitiesExist = false;
      }else {
        this.showTimeline = true;
      }

      this.allActivitiesList.forEach( (value) => {
      
        this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
          data=>{
            this.unsafeUrl = URL.createObjectURL(data);
            this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
  
            value.safeUrl = this.safeUrl;
          });
      }); 
      
    });

}



get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}


closeUpdateModal(){
  this.activeModal.close();
}

}
