

<nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
  <div *ngIf="this.loggedInUser.offices[0].logo !== null">
    <img height="30" width="30" class="logo-image"
      [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
  </div>
  <div *ngIf="this.loggedInUser.offices[0].logo === null">
    <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
  </div>
  <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button> 

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-user"></i> Account </a>
      </li>

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
      </li>


      <li class="nav-item" style="float: right;">
        <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
          <i class="fa fa-sign-out" ></i>Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>

<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-dashboard-spinner></app-dashboard-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>

<div class="p-4">
  <div class="row col-md-12 col-lg-12">
    <!-- <div class="col-md-12 col-lg-12"> -->
   
    
 
  <div style="height: 100%;" class="card mat-elevation-z12">

    <br>

   
    <div class="row col-md-12 col-lg-12">

      <div class="col-md-4 col-lg-4">
        <h2 style="text-align: left;color: black;font-weight: bold;" class="table-caption">OFFICE DASHBOARD</h2>
      </div>

      <div class="col-md-4 col-lg-4">

      </div>

      <div class="col-md-4 col-lg-4">

      </div>
    </div>
      

      <div class="row col-md-12 col-lg-12">

        <div class="col-md-4 col-lg-4">
          <h4><i class="fa fa-lightbulb-o" aria-hidden="true"> This is 'Office Admin Dashboard' and can be used to add/update/remove 'Admin' users </i></h4>
        </div>

        <div class="col-md-4 col-lg-4">
          <h4><i class="fa fa-lightbulb-o" aria-hidden="true"> Use 'Add Role' button to assign additional 'Admin' roles to the user </i></h4>
        </div>

        <div class="col-md-4 col-lg-4">
          <h4><i class="fa fa-lightbulb-o" aria-hidden="true"> Use 'Switch Dashboard' button on the header, to switch between roles of the logged-in user </i></h4>
        </div>

      </div>
      <div class="row col-md-12 col-lg-12">

        <div class="col-md-4 col-lg-4">
          <h4><i class="fa fa-lightbulb-o" aria-hidden="true"> If you have opted for the 'Basic' package, only 'Office Admin' and 'Class Admin' roles will be available </i></h4>
        </div>

        <div class="col-md-4 col-lg-4">
          <h4><i class="fa fa-lightbulb-o" aria-hidden="true"> Use 'Account' button to view/edit office profile </i></h4>
        </div>

        <div class="col-md-4 col-lg-4">
          <h4><i class="fa fa-lightbulb-o" aria-hidden="true"> Contact us for support at support@eduwudi.com </i></h4>
        </div>

      </div>
    
   
   


  </div>

  
<!-- </div> -->
   
  </div>
</div>

<div class="p-4">
  <div class="row">
    <div class="col-md-12">
      <div class="table-header row" [ngStyle]="backgroundStyle">
        <div class="col-md-12">
          <span class="table-caption">
            Users
          </span>
        
          <a style="float: right;">
             <button *ngIf="officeObject.oa_SubAdmin === true" type="button" 
             style="cursor:pointer;float: right;margin: 1%;" class="btn btn-info"
              (click)="openModalForAddAdmin();">Add Admin</button>
          </a>

          <a style="float: right;margin-right: 2%;">
            <button type="button" 
            style="cursor:pointer; float: right; width: auto; margin: 1%;" class="btn btn-primary"
             (click)="computeSkillReport();">Compute Skill Report</button>
         </a>

        </div>
      </div>
      <div class="card">
        <div class="card-body table-responsive">
          <ngx-spinner *ngIf=" userLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
          [fullScreen]="false">
          <p style="color: white">{{userLoaderMessage}} </p>
        </ngx-spinner>
          <table id="userTable" *ngIf="officeUsersExists"
            class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="productOptions">
            <thead class="tableheader" >
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <!-- <th>Office</th> -->
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr #rowInstance *ngFor="let user of officeAdminUsers,let i = index">
                <td class="td-height">{{i+1}}</td>
                <td class="td-height">{{user.firstName}} {{user.lastName}}</td>
                <td class="td-height">{{user.email}}</td>
                <!-- <td class="td-height">{{user.officeDTO[0]?.officeName}},{{user.officeDTO[0]?.officeLocation}}</td> -->
                <td class="td-height" >

                  <button style="margin: 2%;" *ngIf="user.active" class="btn btn-danger" 
                    (click)="open(confirmDeactivation)">Deactivate</button>
                  <button style="margin: 2%;" *ngIf="!user.active" class="btn btn-success" 
                    (click)="open(confirmActivation)">Activate</button>
 
                  <button  style="margin: 2%;"  class="btn btn-info"
                  (click)="AssignRole(user.userId,user.firstName,user.lastName)">Add Role</button>
                  <button  style="margin: 2%;"  class="btn btn-primary" 
                  (click)="editOfficeUser(user);">Edit</button>
                 
                </td>

                                                
                                  
                <ng-template #confirmActivation let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Are you sure to activate User ?
                  </div> 
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                    <button type="button" class="btn btn-success" (click)="updateUserStatus(user,'activate');modal.dismiss('Cross click')">Activate</button>
                  </div>
                </ng-template> 
                
                
                <ng-template #confirmDeactivation let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Are you sure to deactivate User ?
                  </div> 
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                    <button type="button" class="btn btn-success" (click)="updateUserStatus(user,'activate');modal.dismiss('Cross click')">Deactivate</button>
                  </div>
                </ng-template>
             

        

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<br>