import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-activity-assessment-page',
  templateUrl: './activity-assessment-page.component.html',
  styleUrls: ['./activity-assessment-page.component.scss']
})
export class ActivityAssessmentPageComponent implements OnInit {
 
  currentRole="TEACHER";
  showSpinner : boolean = true;
  index : any = 0;
  userId!: string;
  userName : any;
  loggedUser : any;
  loggedInUser : any;
  theme: string = '';
  public screenWidth: any;
  public screenHeight: any;
  resultsExist = false;
  unsafeImageUrl : any;
  safeImageUrl : any;

  classId!: string;
  subId!: string;
  teacherId!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  resultData : any[] = [{
                    activityPlanner: {
                                            activityPlannerCompletionStatus: '',
                                            activityPlannerDescription: '',
                                            activityPlannerId: '',
                                            activityPlannerImage: '',
                                            activityPlannerName: '',
                                            activityPlannerOrder: '',
                                            activityPlannerStatus: '',
                                            fkSubjectId: '',
                                            activityPlannerImageUrl : ''
                                          },

                   studentsList: [{
                                            fkClass: '',
                                            fkUserRoleOffice: '',
                                            studentActivityHistoryIndicator: '',
                                            studentAttempts: '',
                                            studentId: '',
                                            studentImage: '',
                                            studentName: '',
                                            studentStatus: '',
                                            studentImageUrl : ''
                                          }]
  }]
  navbarTitle: any;

  constructor(private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              public router: Router,
              private toastr : ToastrService,
              public route : ActivatedRoute,
              private studentService : StudentService ) { }

ngOnInit(): void {

this.navbarTitle = GlobalClass.navbarTitle;

this.screenWidth = window.innerWidth;
this.screenHeight = window.innerHeight;

this.subId = this.route.snapshot.params['subjectId'];
this.classId = this.route.snapshot.params['classId'];
this.teacherId = this.route.snapshot.params['teacherId'];

this.loggedUser = localStorage.getItem('loggedUser');
this.loggedInUser = JSON.parse(this.loggedUser);
this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
this.theme = this.sharedDataService.getCardOfficeTheme();
this.userId = this.loggedInUser.userId;

this.getStudentResultsForAPs();

} 

getStudentResultsForAPs(){
  this.studentService.getStudentResultsForAPs(this.subId, this.classId, this.teacherId).subscribe(
    data=>{
      this.resultData = data;

      if(this.resultData === undefined || this.resultData.length === 0){
        this.showSpinner = false;
        this.toastr.show('No Results to display !')
      }

      this.resultData.forEach((value)=>{
        this.imageService.getActivityPlannerImage(value.activityPlanner.activityPlannerImage).subscribe(
          data=>{
            this.unsafeImageUrl = URL.createObjectURL(data);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
            value.activityPlanner.activityPlannerImageUrl = this.safeImageUrl;
          });
        });

        this.resultsExist = true;
      let count : number = 0;
      this.resultData.forEach((value)=>{
        count++;
        value.studentsList.forEach((student: any)=>{
          this.imageService.getUserImage(student.studentImage).subscribe(
            data=>{
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
              student.studentImageUrl = this.safeImageUrl;

              if(count === this.resultData.length){
                setTimeout(()=>{                           //timer
                  this.showSpinner = false;
             }, 1500);
              }
          });
        });
      }); 
    });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
switchDashboard(){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
    {
      size : 'sm',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
    userId : this.userId,
    currentRole : this.currentRole
  }
  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });    
}

goToAccountPage(){
  this.router.navigate(['user-account',this.userId])
}

goToHomePage(){
  this.router.navigate(['/dashboard/teacher']);
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

changePassword(): void {
  this.router.navigate(['/auth/change-password']).then();
}

logOut(): void {
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
   email : this.loggedInUser.email 
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });  
}

goBack() {
  this.router.navigate(['/dashboard/user']).then();
}

reloadPage() {
  window.location.reload();
}

}
