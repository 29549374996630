import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { MarkAttendanceComponent } from 'src/app/dashboards/teacherDashboards/mark-attendance/mark-attendance.component';
import { ViewAttendanceComponent } from 'src/app/dashboards/teacherDashboards/view-attendance/view-attendance.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { DestroyService } from 'src/app/services/destroyService';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss'],
})
export class TeacherDashboardComponent extends DestroyService implements OnInit {

  currentRole = "TEACHER";
  showSpinner : boolean = true;
  classesExist = false;
  // classCards : GetTeacherClassSubjects[] = [];

  classCardsWithSafeUrl : any[] =[ {  teacherId : '', 
                                      userRoleOfficeId : '',
                                      classId : '',
                                      className : '',
                                      classDescription : '',
                                      fkOfficeId : '',
                                      fkProductIdOfClass : '' ,
                                      subId : '' ,
                                      subName : '' ,
                                      subDescription : '' ,
                                      fkProductIdOfSubject : '' ,
                                      subjectImage : '' ,
                                      safeUrl : '' 
                                                                        } ];

  unsafeImageUrl: any;
  safeImageUrl: any;

  abcd = [1,2,3,4];
  userId!: string;
  loggedInUser;
  userName: string = 'User';
  theme: string = '';
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  navbarTitle : any;

  public screenWidth: any;
  public screenHeight: any;

  constructor(private sharedDataService: SharedDataService, 
    private imageService : FileServiceService,
    private sanitizer : DomSanitizer,
    private ngbModalService: NgbModal,
    private toastr : ToastrService,
    public router: Router,
    private teacherService : TeacherService,
    public bsModalRef: BsModalRef ) {
      super();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;

  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getTeacherClassSubjects();
  }

  getTeacherClassSubjects(){
    this.teacherService.getTeacherClassSubjects( environment.apiUrl + "teacher/getTeacherClassSubjectsAuth").then(
      data=>{

        this.classCardsWithSafeUrl.pop();

        for(var index in data){
                              this.classCardsWithSafeUrl.push( {  'teacherId' : data[index].teacherId, 
                                                                  'userRoleOfficeId' : data[index].userRoleOfficeId,
                                                                  'classId' : data[index].classId,
                                                                  'className' : data[index].className,
                                                                  'classDescription' : data[index].classDescription,
                                                                  'fkOfficeId' : data[index].fkOfficeId,
                                                                  'fkProductIdOfClass' : data[index].fkProductIdOfClass ,
                                                                  'subId' : data[index].subId ,
                                                                  'subName' : data[index].subName ,
                                                                  'subDescription' : data[index].subDescription ,
                                                                  'fkProductIdOfSubject' : data[index].fkProductIdOfSubject ,
                                                                  'subjectImage' : data[index].subjectImage ,
                                                                  'safeUrl' : this.safeImageUrl 
                                                                        });
      }

      if( this.classCardsWithSafeUrl === undefined || this.classCardsWithSafeUrl.length === 0){
        setTimeout(()=>{                           //timer
          this.showSpinner = false;
          this.toastr.show('No classes to display !')
     }, 1500);
      }

      let count : number = 0;
      this.classCardsWithSafeUrl.forEach( (value) => {
          count++;
        this.imageService.getSubjectImage( value.subjectImage ).subscribe(
          data=>{
            this.unsafeImageUrl = URL.createObjectURL(data);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);

            value.safeUrl = this.safeImageUrl;

            if(count===this.classCardsWithSafeUrl.length){
              setTimeout(()=>{                           //timer
                this.showSpinner = false;
           }, 1500);
             }
           });
         });   
         this.classesExist = true;
       });
  }

  markAttendance(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( MarkAttendanceComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  viewAttendance(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewAttendanceComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  onExplore(subjectId : string, teacherId : string, classId : string){
    this.router.navigate(['teacher-subject',subjectId,teacherId,classId]);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }


  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      userId : this.userId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  reloadPage() {
    window.location.reload();
  }
  
}
