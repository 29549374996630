import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Result } from '../courseManagementClasses/result';
import { Office } from '../models/office';

@Injectable({
  providedIn: 'root'
})
export class ResultServiceService {

  private getUrl = environment.apiUrl + 'result/getAllOffices';
  private getUrl2 = environment.apiUrl + 'result/findResultsByStudentIdAndSubjectId';
  private getUrl3 = environment.apiUrl + 'result/findResultsByUserRoleOfficeIdAndSubjectId';
  private getUrl4 = environment.apiUrl + 'result/findResultsByUserRoleOfficeId';
  private getUrl5 = environment.apiUrl + 'result/findResultsByStudentIdRA';

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }
  
  addResult(result : Result):Observable<Result>{
    return this.httpClient.post<Result>( environment.apiUrl + 'result/add', result);
  } 

  editResult(result : Result):Observable<Result>{
    return this.httpClient.put<Result>( environment.apiUrl + 'result/update', result);
  } 
  
  getAllOffices():Observable<Office[]>{
    return this.httpClient.get<Office[]>(`${this.getUrl}`);
  }

  getResultsByStudentIdAndSubjectId(studentId : string, subjectId : string):Observable<Result[]>{
    return this.httpClient.get<Result[]>(`${this.getUrl2}/${studentId}/${subjectId}`);
  }

  findResultsByUserRoleOfficeIdAndSubjectId(uroId : string, subjectId : string):Observable<Result[]>{
    return this.httpClient.get<Result[]>(`${this.getUrl3}/${uroId}/${subjectId}`);
  }

  findResultsByUserRoleOfficeId(uroId : string):Observable<Result[]>{
    return this.httpClient.get<Result[]>(`${this.getUrl4}/${uroId}`);
  }

  findResultsByStudentIdRA(studentId : string):Observable<Result[]>{
    return this.httpClient.get<Result[]>(`${this.getUrl5}/${studentId}`);
  }

  updateResultStatus(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers }).toPromise()
        .then((data) => {resolve(data);
        }).catch((err) => {
        });
    });
  }
}
