import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotApiConfiguration } from "src/app/BOT_CODE/bot_classes/bot-api-configuration";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";

@Component({
  selector: "app-create-bot-api-config",
  templateUrl: "./create-bot-api-config.component.html",
  styleUrls: ["./create-bot-api-config.component.scss"],
})
export class CreateBotApiConfigComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private sharedDataService: SharedDataService,
    private botResponseService: BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botApiConfig: BotApiConfiguration = new BotApiConfiguration();
  botResponse: BotResponse = new BotResponse();
  context: any;
  apiConfigs: BotApiConfiguration[] = [];
  botResOption: BotResponseOptions = new BotResponseOptions();

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

    if (this.context === "addBotApiConfigAndLinkToResponseForOptions") {
      this.botResponse = this.fromParent.botResponse;
      this.botApiConfig.botResponseId = this.botResponse.botResponseId;
    }

    if (this.context === "addBotApiConfigAndLinkToResponseForVariables") {
      this.botResponse = this.fromParent.botResponse;
      this.botApiConfig.botResponseId = this.botResponse.botResponseId;
      this.botApiConfig.apiCallTo = "others";
    }

    if (this.context === "addBotApiConfigurationForCondtitonalResponses") {
      this.botResponse = this.fromParent.botResponse;
      this.botApiConfig.botResponseId = this.botResponse.botResponseId;
    }

    if (
      this.context === "addBotApiConfigurationToOptionForCondtitonalResponses"
    ) {
      this.botResOption = this.fromParent.botResOption;
      this.botApiConfig.botResponseOptionsId =
        this.botResOption.botResponseOptionsId;
    }

    if (this.context === "editBotApiConfiguration") {
      this.botApiConfig = this.fromParent.botApiConfig;
    }
  }

  addBotApiConfigAndLinkToResponseForOptions() {
    this.clicked = true;
    this.botResponseService
      .addBotApiConfig(
        environment.apiUrl +
          "botConvo/addBotApiConfigAndLinkToResponseForOptions",
        this.botApiConfig
      )
      .then((res) => {
        this.botApiConfig = res;
        if (this.botApiConfig.titleExists === false) {
          this.passEntry.emit(this.botApiConfig);
          this.activeModal.close();
          this.toastr.success("API configuration added successfully !");
        } else {
          this.toastr.error("API configuration title already exists !");
          this.clicked = false;
        }
      });
  }

  addBotApiConfigAndLinkToResponseForVariables() {
    this.clicked = true;
    if (this.botApiConfig.apiCallTo === "others") {
      this.botApiConfig.apiCallTo = null;
    }
    this.botResponseService
      .addBotApiConfig(
        environment.apiUrl +
          "botConvo/addBotApiConfigAndLinkToResponseForVariables",
        this.botApiConfig
      )
      .then((res) => {
        if (res.titleExists === false) {
          this.botApiConfig = res;
          this.passEntry.emit(this.botApiConfig);
          this.activeModal.close();
          this.toastr.success("API configuration added successfully !");
        } else {
          if (this.botApiConfig.apiCallTo === null) {
            this.botApiConfig.apiCallTo = "others";
          }
          this.toastr.error("API configuration title already exists !");
          this.clicked = false;
        }
      });
  }

  addBotApiConfigurationForCondtitonalResponses() {
    this.clicked = true;
    this.botResponseService
      .addBotApiConfig(
        environment.apiUrl +
          "botConvo/addBotApiConfigurationForCondtitonalResponses",
        this.botApiConfig
      )
      .then((res) => {
        this.botApiConfig = res;
        if (this.botApiConfig.titleExists === false) {
          this.passEntry.emit(this.botApiConfig);
          this.activeModal.close();
          this.toastr.success("API configuration added successfully !");
        } else {
          this.toastr.error("API configuration title already exists !");
          this.clicked = false;
        }
      });
  }

  addBotApiConfigurationToOptionForCondtitonalResponses() {
    this.clicked = true;
    this.botResponseService
      .addBotApiConfig(
        environment.apiUrl +
          "botConvo/addBotApiConfigurationToOptionForCondtitonalResponses",
        this.botApiConfig
      )
      .then((res) => {
        this.botApiConfig = res;
        if (this.botApiConfig.titleExists === false) {
          this.passEntry.emit(this.botApiConfig);
          this.activeModal.close();
          this.toastr.success("API configuration added successfully !");
        } else {
          this.toastr.error("API configuration title already exists !");
          this.clicked = false;
        }
      });
  }

  editBotApiConfiguration() {
    this.botResponseService
      .addBotApiConfig(
        environment.apiUrl + "botConvo/editBotApiConfiguration",
        this.botApiConfig
      )
      .then((res) => {
        this.botApiConfig = res;
        this.passEntry.emit(this.botApiConfig);
        this.activeModal.close();
        this.toastr.success("API configuration updated successfully !");
      });
  }

  linkCntxt: any;

  linkExistingAPIConfiguration() {
    if (this.linkCntxt === "addBotApiConfigAndLinkToResponseForOptions") {
      this.botResponseService
        .addBotApiConfig(
          environment.apiUrl +
            "botConvo/linkExistingAPIConfigurationToResponseForOptions",
          this.botApiConfig
        )
        .then((res) => {
          this.botApiConfig = res;

          this.apiConfigs.forEach((apiC) => {
            if (apiC.botApiConfigId === this.botApiConfig.botApiConfigId) {
              this.botApiConfig.title = apiC.title;
            }
          });
          this.passEntry.emit(this.botApiConfig);
          this.activeModal.close();
          this.toastr.success("API configuration added successfully !");
        });
    } else if (
      this.linkCntxt === "addBotApiConfigAndLinkToResponseForVariables"
    ) {
      this.botResponseService
        .addBotApiConfig(
          environment.apiUrl +
            "botConvo/linkExistingAPIConfigurationToResponseForVariables",
          this.botApiConfig
        )
        .then((res) => {
          this.passEntry.emit(this.botApiConfig);
          this.activeModal.close();
          this.toastr.success("API configuration linked successfully !");
        });
    } else if (
      this.linkCntxt === "addBotApiConfigurationForCondtitonalResponses"
    ) {
    }
  }

  getBotApiConfigs() {
    this.botResponseService
      .getBotApiConfigsByOfficeId(
        environment.apiUrl + "botConvo/getBotApiConfigsByOfficeId"
      )
      .then((res) => {
        let resObj: BotApiConfiguration = new BotApiConfiguration();
        resObj = res;
        this.apiConfigs = resObj.list;
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
