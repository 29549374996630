export class InvoiceDetails {

	//class while sending invoice details as an array
    invoiceDetId!: string;
	itemName!: string;
	quantity!: any;
	pricePerItem!: any;
    totalPrice!: any;
	alternateQuantity : any;
	description!: string;
	paymentMethod!: string;
	deliveryAddress!: string;
	fkInvoiceId!: string;
	fkProductId!: string;
	invoiceDetStatus!: boolean;
	productName : any
	taxId : any;
	taxAmount : any;
	taxType : any;
	taxName :any;

	totalDiscountAmount : any;

	perQuantityDiscountRate : any = 0;
	perAltQuantityDiscountRate : any = 0;
	totalPriceBeforeDiscount : any;


}
