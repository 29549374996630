import { ActivityScore } from "./activity-score";

export class AddActActualActScore {

   activityScoreList : ActivityScore[] = [];

   fkTeacherId!: string;		
   fkStudentId!: string;				
   fkActivityPlannerId!: string;	
   
   parentUserId!: String;
   currentActivityDate: any;

   botPMExtActLinkId : any;
   
   }