import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { Customer } from 'src/app/customer';
import { CustomerService } from 'src/app/service/customer.service';
 
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
 
  customers: Customer[] = [];

  constructor(private customerService :CustomerService ,
    private router : Router) { }
  
  ngOnInit(): void {
    this.getCustomers();
  }

  private getCustomers(){
    // this.customerService.getCustomerList().subscribe(data => {
    //   this.customers= data;
    // });
  }
  
  deleteCustomer(customerId : string){
    this.customerService.deleteCustomer(customerId).subscribe( data => {
      this.getCustomers();
    })
  }
 
  updateCustomer(customerId : string){
    this.router.navigate(['updateCustomer',customerId]);
  }
} 
