import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OfficesService} from 'src/app/services/office/offices.service';
import {environment} from 'src/environments/environment';
import {RolesServiceService} from '../../services/role/roles-service.service';
import {SharedDataService} from '../../services/sharedData.service';
import { Roles } from 'src/app/models/role';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimeoutScreenComponent } from '../timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import {MODAL_HEADERS} from '../../utils/constants/app-constants';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-bot-user',
  templateUrl: './add-bot-user.component.html',
  styleUrls: ['./add-bot-user.component.scss']
})
export class AddBotUserComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };
  clicked=false;
  offices: any;
  roles = new Roles();
  officeAdminState: any;
  data: any;
  isReadonly= false;
  public source: any;
  sourceObject: any;
  userOfficeId: any;
  products: Product[] = [];
  public event: EventEmitter<any> = new EventEmitter();
  headerText = MODAL_HEADERS.ADD_USER; // 'Add User';

  addUserForm = this.formBuilder.group({
    userId: new FormControl(null),
    first_name: new FormControl(null, Validators.required),
    last_name: new FormControl(null),
    email: new FormControl(null, Validators.required),
    phone_number: new FormControl(null, Validators.required),
    gender: new FormControl(null, Validators.required),
    date_of_birth: new FormControl(null, Validators.required),
    role: new FormControl(null, Validators.required),
    office: new FormControl(null, Validators.required),
    addressLine1: new FormControl(null, Validators.required),
    addressLine2: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required),
    country: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    zipCode: new FormControl(null, Validators.required),
    subscriptionDTOS: this.formBuilder.array([
      this.formBuilder.group({
        productId: new FormControl(null),
        startDate:new FormControl(null),
        endDate: new FormControl(null)
      })
    ])
  });

  loggedInUser : any;

  constructor(
    private formBuilder: FormBuilder, public bsModalRef: BsModalRef, private productService: ProductService,
    private sharedDataService: SharedDataService, 
    private officesService: OfficesService, private roleService: RolesServiceService,private modalService: BsModalService, 
    private bnIdle: BnNgIdleService, private router: Router) {
    this.officesService.getAllOffices(environment.apiUrl + 'cbOrgAdmin/get/allOrganisationOffices').then(officesRes => {
      this.offices = officesRes;
    });

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());

    this.roleService.getAllRoles(environment.apiUrl + 'cbOrgAdmin/get/roles').then(rolesRes => {
      this.roles.roles = rolesRes;
      this.roles.roles = this.roles.roles.filter(role => role.activeStatus && !role?.roleName?.includes('ADMIN'));
      // this.roles = rolesRes;
    });

  //   const loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
  //   this.userOfficeId = loggedInUser.offices[0]?.id;
  //   if (this.userOfficeId !== null && this.userOfficeId !== undefined ) {
  //   this.productService.getProductsByOffice(environment.apiUrl + 'product/get/' + this.userOfficeId + '/products').then(productsRes => {
  //     this.products = productsRes;
  //   });
  // }
  const loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
  this.userOfficeId = loggedInUser.offices[0]?.id;
    // this.productService.getProductsByOffice(environment.apiUrl + 'product/get/' + this.userOfficeId + '/products').then(productsRes => {
    //   this.products = productsRes;
    // }); 
    this.productService.getProductListByOfficeId(this.userOfficeId).subscribe(data=>{
      this.products = data;
    })
  }

  get subscriptions(): FormArray {
    return this.addUserForm.get('subscriptionDTOS') as FormArray;
  }

    
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  newSubscription(): FormGroup {
    return this.formBuilder.group(
      {
        productId: '',
        startDate: '',
        endDate: ''
      }
    );
  }

  addSubscriptions(): void {
    this.subscriptions.push(this.newSubscription());
  }

  removeSubscription(i: number): void {
    this.subscriptions.removeAt(i);
  }

  officesDisplay:any;
  ngOnInit() {
    this.source = this.sourceObject.source;
    this.officesDisplay = this.sourceObject.officesDisplay;
    this.addUserForm = this.formBuilder.group({
      userId: (this.officeAdminState) ? this.officeAdminState.userId : '',
      first_name: (this.officeAdminState) ? this.officeAdminState.firstName : '',
      last_name: (this.officeAdminState) ? this.officeAdminState.lastName : '',
      email: (this.officeAdminState) ? this.officeAdminState.email : '',
      phone_number: (this.officeAdminState) ? this.officeAdminState.phoneNumber : '',
      gender: (this.officeAdminState) ? this.officeAdminState.gender : '',
      date_of_birth: (this.officeAdminState) ? this.officeAdminState.dateOfBirth : '',
      role: (this.officeAdminState) ? this.officeAdminState.roleResponseDTO[0].roleId: '',
      office: (this.officeAdminState) ? this.officeAdminState.officeDTO[0].id: '',
      addressLine1: (this.officeAdminState) ? this.officeAdminState.addressDTO.addressLine1 : '',
      addressLine2: (this.officeAdminState) ? this.officeAdminState.addressDTO.addressLine2 : '',
      city: (this.officeAdminState) ? this.officeAdminState.addressDTO.city : '',
      country: (this.officeAdminState) ? this.officeAdminState.addressDTO.country : '',
      state: (this.officeAdminState) ? this.officeAdminState.addressDTO.state : '',
      zipCode: (this.officeAdminState) ? this.officeAdminState.addressDTO.zipCode : '',
      subscriptionDTOS:  (this.officeAdminState) ? this.officeAdminState.subscriptionDTOS : this.formBuilder.array([this.formBuilder.group({
        productId: new FormControl(''),
        startDate: new FormControl(''),
        endDate: new FormControl('')
      })])
    });

    if (this.officeAdminState?.userId) {
      this.headerText = MODAL_HEADERS.EDIT_USER; // 'Edit User';
    }
    if(this.officeAdminState?.userId) {
      this.isReadonly = true;
    }
  }

  saveToList(form: any): void {
    if (form.value) {
      this.triggerEvent(form.value);
      this.bsModalRef.hide();
    }
  }

  triggerEvent(item: any): void {
    this.event.emit({data: item, res: 200});
  }

}
