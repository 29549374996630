 <!-- MODAL CLOSE BUTTON -->
 <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">{{name}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
   <!-- MODAL CLOSE BUTTON -->

<table>
    <thead>
        <tr>
            <th>Students</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor = "let classStudent of classStudents">
            <td>{{classStudent.studentName}}</td>
        </tr>
    </tbody>
</table>