<!-- <section [style.background-image]="'url(/assets/images/geometry.png)'" class="height-100vh d-flex align-items-center page-section-ptb login">
  <div class="container">
    <div class="row justify-content-center no-gutters vertical-align">
      <div class="col-lg-4 col-md-6 login-fancy-bg">
        <div class="login-fancy">
          <h2 class="text-white mb-3">Forgot Password</h2>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 bg-white">
        <div class="login-fancy pb-4 clearfix">
          <h3 class="mb-4">Forgot Password</h3>
          <form class="customerRegisteration-form" #forgotPassword="ngForm" (ngSubmit)="resetPassword(forgotPassword.value)">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="email" class="required">Email*</label>
                <input type="email" required ngModel name="email" id="email" class="form-control" placeholder="Email"
                  #email="ngModel" />
                <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is incorrect</div>
              </div>

            </div>
            <div class="form-group row">

              <div class="remember-checkbox col-lg-6 col-md-6">
              <button  type="submit" class="btn button" [disabled]="!forgotPassword.valid">
                <span>Submit</span>
              </button>
            </div>
              <div class="remember-checkbox col-lg-5 col-md-5">
                <a class='btn float-rightt' style="cursor:pointer; width: 10rem !important;"
                 (click)="goToLoginPage()" >Login</a>
              </div>
            </div>
           
          </form>
        </div>
      </div>
    </div>
  </div>
</section> -->


<div style="width: 100%; height: 100%; display: block;"
 [style.background-image]="'url(/assets/images/outerBg.png'" 
 style="background-position: right; background-repeat: no-repeat; background-size: cover;">
<!-- 
<div class="login-wrap"  [style.background-image]="'url(/assets/images/innerBg.png'" 
style="background-position: center; background-repeat: no-repeat; background-size: cover;" > -->


<div class="login-wrap" 
style="background-position: center; background-repeat: no-repeat; background-size: cover;" >

	<div class="login-html">

    <div style="text-align: center;">
      <img style="border-radius: 50%;" height="100" width="100" class="logo-image"
        src="/assets/images/logo.png" />
    </div>
    <br>
    <br>
		<input id="tab-1" type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">Forgot Password</label>
		<input id="tab-2" type="radio" disabled name="tab" class="sign-up"><label for="tab-2" class="tab"></label>
		<div class="login-form">
			<div class="sign-in-htm">
        <form class="customerRegisteration-form" #forgotPassword="ngForm" (ngSubmit)="resetPassword(forgotPassword.value)">

				<div class="group">
					<label for="user"  style="color: white; font-weight: bold;font-size: 12px;"  class="label">Email</label>
          <input type="email" required ngModel name="email" id="email" class="form-control input" placeholder="Email"
          #email="ngModel" />
        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is incorrect</div>
				</div>
		
        
			
        <br>
        <br>
				<div class="group">
					<input type="submit" class="button" [disabled]="!forgotPassword.valid" value="Submit">
          
				</div>
      </form>
				<div class="hr"></div>
				<div class="foot-lnk">
					<a style="color: white; cursor: pointer;" (click)="goToLoginPage()">Go to Login page</a>
				</div>
       
			</div>
			
	</div>
</div>
</div>

</div>
