<div class="modal-header">
    <h4 class="modal-title pull-left">Edit Skill</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
      <div class="card">
         <div class="card-body">

    <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm"> 
 
      <div style="width: 100%;color: black;text-align: center;" >
         <a style="cursor:pointer;">
            <img style="border-radius: 50%;" [src]="safeUrl" width="75" height="75"
            alt="" loading="lazy">
         </a>
       </div>
       <br>


         <div>
            <label class="required" for="skillName"> Name</label>
            <input  maxlength="50"  #skillName="ngModel" required placeholder="Enter Skill Name" required class="form-control" type="text" name="skillName"  id="skillName" [(ngModel)]="skill.skillName">
            <div class="alert alert-danger" *ngIf="skillName.touched && !skillName.valid">Name is required</div>
         </div>

         <br>
         <div>
            <label>  Image</label>
            <input placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
         </div>

   <br>
      <div>
         <label class="required" for="skillDescription">Description</label>
         <textarea   maxlength="250" placeholder="Enter Description" required class="form-control" type="text" name="skillDescription"  id="skillDescription" [(ngModel)]="skill.skillDescription"></textarea>
      </div>
<br>

    </form>
   
         </div>
      </div>
   </div>
   
   <div class="modal-footer">
    <button  [disabled]="clicked"  [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Update</button>
  </div>