import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-timeout-screen',
  templateUrl: './timeout-screen.component.html',
  styleUrls: ['./timeout-screen.component.scss']
})
export class TimeoutScreenComponent implements OnInit, OnDestroy  {

  public event: EventEmitter<any> = new EventEmitter();


  constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) { }

  count = 1;
  ngOnInit(): void {
    this.setSessionTimeout();
    
  }

  setSessionTimeout() {

  //   if(this.count === 1){
  // setTimeout(() => {
  //   // this.event.emit({ data: 'Successfully timed out', res: 200 });
  //   // this.bsModalRef.hide(); 
  //   this.saveToList('Successfully timed out')
  // }, 3000);
  // }
}

  saveToList(value: any): void {
    if (value) {
      this.triggerEvent(value);
      this.count = this.count + 1;
      this.bsModalRef.hide();
    }
  }

  ngOnDestroy() {
    const modalCount = this.bsModalService.getModalsCount();
    this.bsModalService._hideModal(modalCount);
  }

  triggerEvent(item: any): void {
    this.event.emit({ data: item, res: 200 });
  }

}
