<div class="modal-header">
  <h4 class="modal-title pull-left">{{headetText}}</h4>
  <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form (ngSubmit)="saveToList(addUserSubscriptionForm)" [formGroup]="addUserSubscriptionForm">

        <div class="form-group row">          
          <div class="col-md-6">
            <label for="if-user">User</label>
            <select class="form-control" formControlName="userId" id="if-user" name="if-role">
              <option disabled value="">Choose User</option>
              <option *ngFor="let user of users" [ngValue]="user.userId">{{user.firstName}} {{user.lastName}}</option>
            </select>
          </div>
        </div> 

        <div class="form-group row">          
          <div class="col-md-6">
            <label for="if-role">Role</label>
            <select class="form-control" formControlName="roleId" id="if-role" name="if-role">
              <option disabled value="">Choose Role</option>
              <option *ngFor="let role of roles.roles" [ngValue]="role.roleId">{{role.roleName}}</option>
            </select>
          </div>

          <div class="col-md-6">
            <label for="if-productId">Course / Product</label>
            <select class="form-control" formControlName="productId" id="if-productId" name="if-productId">
              <option disabled value="">Choose Course / Product</option>
              <option *ngFor="let product of products" [ngValue]="product.productId">{{product.productName}}</option>
            </select>
          </div> 
        </div>         
 
        <div class="form-group row">
          <div class="col-md-6">
            <label for="if-startDate">Start Date</label>
            <input autocomplete="startDate" class="form-control" formControlName="startDate" id="if-startDate" name="if-startDate"
                   placeholder="Enter start date.." type="date">
          </div>
 
          <div class="col-md-6">
            <label for="if-endDate">End Date</label>
            <input autocomplete="endDate" class="form-control" formControlName="endDate" id="if-endDate"
                   name="if-endDate"
                   placeholder="Enter end date.." type="date">
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button [disabled]="clicked"  (click)="saveToList(addUserSubscriptionForm);clicked=true" class="btn btn-sm btn-primary float-right" type="submit">
    Save
  </button>
</div>
