<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Admin</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form (ngSubmit)="onSubmit()" #adminForm="ngForm">  
  
        <div class="form-group row">

            <div class="col-md-6">
            <label class="required" for="firstName">First Name</label>
            <input #firstName="ngModel" minlength="3"   maxlength="50"  required class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="admin.firstName">
            <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
            </div>
          
            <div class="col-md-6">
            <label class="required" for="lastName">Last Name</label>
            <input  #last="ngModel" minlength="3"   maxlength="50" required class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="admin.lastName">
            <div class="alert alert-danger" *ngIf="last.touched && !last.valid">Last Name is Required</div>

          </div>

        </div>


        <div class="form-group row">

          <div class="col-md-6">
          <label class="required" for="email">Email</label>
          <input  maxlength="250"  #email="ngModel" required class="form-control" email type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="admin.email">
          <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
          </div>

          <div class="col-md-2">
            <label class="required" for="countryDialCode"> Code</label>
            <select class="form-control" name="countryDialCode" id="countryDialCode"
             [(ngModel)]="admin.countryDialCode">
              <option value="" disabled selected>Select Country</option>
              <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
         </select>
          </div>

          <div class="col-md-4">
          <label class="required" for="phoneNumber">Phone Number</label>
          <input  #phone="ngModel" required minlength="10"   maxlength="250" class="form-control" type="number" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="admin.phoneNumber">
          <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
          </div>

      </div>

        <div class="form-group row">

          <div class="col-md-6">
          <label for="dateOfBirth">Date of Birth</label>
          <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="admin.dateOfBirth">
          </div>
          
          <div class="col-md-6">
          <label >Gender </label> <br>
          <mat-radio-group aria-label="gender" [(ngModel)]="admin.gender">
          <mat-radio-button name="gender" id="male" value="male"  >Male</mat-radio-button>
          <mat-radio-button name="gender" id="female" value="female"  >Female</mat-radio-button>
          <mat-radio-button name="gender" id="other" value="other"  >Other</mat-radio-button>
          </mat-radio-group>
          </div>

        </div>




    


      <div class="form-group row">

        <div class="col-md-4">
        <label for="addressLine1">Address Line 1</label>
        <input   maxlength="250" class="form-control" type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="admin.addressLine1">
        </div>
      
        <div class="col-md-4">
        <label for="addressLine2">Address Line 2</label>
        <input   maxlength="250" class="form-control" type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="admin.addressLine2">
        </div>
      
        <div class="col-md-4">
          <label for="city">City</label>
          <input   maxlength="250" class="form-control" type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="admin.city">
          </div>
        
      </div>
      
      <div class="form-group row">
      
        <div class="col-md-4">
          <label for="state">State</label>
          <input   maxlength="250" class="form-control" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="admin.state">
          </div>
      
        <div class="col-md-4">
        <label for="country">Country</label>
        <input   maxlength="250" class="form-control" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="admin.country">
        </div>

        <div class="col-md-4">
          <label for="zipCode">Zip Code</label>
          <input   maxlength="250" class="form-control" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="admin.zipCode">
          </div>
      
      </div>
      

  
      <label for="roleId" class="required" >Role : </label> <br>
      <mat-radio-group required aria-label="roleId" [(ngModel)]="admin.roleId" name="radioControl" ngModel>
        <mat-radio-button name="roleId" id1="course_admin" value="course_admin"  >Course Admin</mat-radio-button>
        <mat-radio-button *ngIf="oa_CRMAdmin === true" name="roleId" id1="crm_admin" value="crm_admin"  >CRM Admin</mat-radio-button>
        <mat-radio-button name="roleId" id1="class_admin" value="class_admin"  >Class Admin</mat-radio-button>
      </mat-radio-group>

 
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   
[disabled]="!adminForm.valid" [ngStyle]="backgroundStyle" class="btn btn-sm btn-primary float-right" 
(click)="onSubmit(); clicked=true">save</button>
</div>

