import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { SuperAdminUserService } from 'src/app/services/user/super-admin-user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class OfficeAdminDashboardResolve implements Resolve<any> {

	constructor(
		private superAdminUserService: SuperAdminUserService, private sharedDataService: SharedDataService) {
	}

	resolve(route: ActivatedRouteSnapshot): Promise<any> {
		const loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());

		const userOfficeId = loggedInUser.offices[0].id;
		return new Promise<any>(async (resolve, reject) => {
			this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/usersForOfficeAdmin/' + userOfficeId).then(usersRes => {
				resolve(usersRes);
			});
		});
	}
}
