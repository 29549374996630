import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDetailsList } from 'src/app/crmClasses/invoice-details-list';
import { GlobalClass } from 'src/app/global/global-class';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-update-invoice-details',
  templateUrl: './update-invoice-details.component.html',
  styleUrls: ['./update-invoice-details.component.scss']
})
export class UpdateInvoiceDetailsComponent implements OnInit {
  
  clicked = false;
  invoiceDetId!: string;
  type : any;
  invoiceId!: string;
  officeId!: string;
  invoiceDet : InvoiceDetailsList = new InvoiceDetailsList();
  qty : any;
  price : any;
  tp : any;

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  loggedInUser : any;
  theme : any;

  constructor(private invoiceService : InvoiceService,
              public activeModal: NgbActiveModal,
              private sharedDataService: SharedDataService,
              private toaster: ToastrService ) { }

  ngOnInit(): void {
   
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.invoiceDetId = this.fromParent.invoiceDetId;
    this.officeId = this.fromParent.officeId;
    this.type = this.fromParent.type;

    this.invoiceService.getInvoiceDetailsById(this.invoiceDetId).subscribe(data=>{
      this.invoiceDet = data;

      this.invoiceDet.totalDiscountAmount = this.invoiceDet.perQuantityDiscountRate * this.invoiceDet.quantity;
      this.invoiceDet.totalPriceBeforeDiscount = this.invoiceDet.totalPrice + this.invoiceDet.totalDiscountAmount;

      let x =  this.invoiceDet.alternateQuantity /  this.invoiceDet.quantity;

      this.invoiceDet.prodDefaultQty = x;

      this.invoiceDet.perAltQuantityDiscountRate =  this.invoiceDet.perQuantityDiscountRate / x;
      
    }, error => console.log(error));
  } 

  onUpdate(){
    this.invoiceService.updateInvoiceDetails(this.invoiceDet).subscribe(
      data =>{
        this.passEntry.emit(this.invoiceDet);
        this.activeModal.close();
        this.toaster.success("Invoice Details Updated Successfully");
      }
      ,error => console.error()
      
    );
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onFileSelected(event: any){
    this.qty = this.invoiceDet.quantity;
    this.price = this.invoiceDet.pricePerItem;

     this.tp = this.qty * this.price;

    //  this.invoiceDet.totalPrice = this.tp;
    this.invoiceDet.totalPriceBeforeDiscount = this.tp;

     this.invoiceDet.alternateQuantity = this.invoiceDet.productDefaultQuantity * this.invoiceDet.quantity;

     if(this.invoiceDet.perAltQuantityDiscountRate === 0){
      this.invoiceDet.totalPrice = this.invoiceDet.totalPriceBeforeDiscount;
      this.invoiceDet.perQuantityDiscountRate = 0;
    }else{

   let totalDiscountAmount = this.invoiceDet.perAltQuantityDiscountRate * this.invoiceDet.alternateQuantity;

   this.invoiceDet.totalPrice = this.invoiceDet.totalPriceBeforeDiscount - totalDiscountAmount;

   this.invoiceDet.perQuantityDiscountRate = this.invoiceDet.prodDefaultQty * this.invoiceDet.perAltQuantityDiscountRate;

    }
 
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeModal(){
    this.activeModal.close();
  }
}
