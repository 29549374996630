import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { EditFormActiveComponent } from 'src/app/BIZ_CRM_FEEDBACK/modals/form-active/edit-form-active/edit-form-active.component';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { CreateBotFormActiveComponentComponent } from '../create-bot-form-active-component/create-bot-form-active-component.component';

@Component({
  selector: 'app-view-bot-form-actives',
  templateUrl: './view-bot-form-actives.component.html',
  styleUrls: ['./view-bot-form-actives.component.scss']
})
export class ViewBotFormActivesComponent implements OnInit {

  clicked = false;
  formActives : FormActive[] = [];
  feedbackForm : FeedbackForm = new FeedbackForm();
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private router : Router,
    private datePipe : DatePipe,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    
    this.feedbackForm = this.fromParent.form;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    this.getFormActives();

  }

  explore(formActiveId : any){
    this.activeModal.close();
    this.router.navigate(['dashboard/bot/forms/form-history',formActiveId]);
  } 


  addFormActive(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateBotFormActiveComponentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : this.feedbackForm
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getFormActives();
    });   
  }
  
  editFormActive(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFormActiveComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getFormActives();
    });  
  }

  getFormActives(){
   
    this.formService.getAllFormActivesByFormId(environment.apiUrl +"formActive/getAllBotFormActivesByFormId/" + this.feedbackForm.id).then(
      res=>{
        this.formActives = res;
        this.formActives.forEach((fa)=>{
          fa.formattedFromDate = new Date(fa.fromDate);
          fa.formattedFromDate = this.datePipe.transform(fa.formattedFromDate, "dd-MM-yyyy");
          fa.formattedToDate = new Date(fa.toDate);
          fa.formattedToDate = this.datePipe.transform(fa.formattedToDate, "dd-MM-yyyy");

          let dd = fa.executionTimestamp;
          fa.executionTimestamp = new Date(dd[0], dd[1] - 1, dd[2], dd[3], dd[4]);
          fa.executionTimestamp = this.datePipe.transform(fa.executionTimestamp, 'dd-MM-yyyy hh:mm');
        })        
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
