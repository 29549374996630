import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Office } from 'src/app/models/office';
import { environment } from 'src/environments/environment';
import { AddOfficeComponent } from '../../add-office/add-office.component';

@Component({
  selector: 'app-sub-offices',
  templateUrl: './sub-offices.component.html',
  styleUrls: ['./sub-offices.component.scss']
})
export class SubOfficesComponent implements OnInit {
  offices : Office[]=[];
  clicked = false;
  modalRefs: BsModalRef[] = [];
  
  constructor( private modalService: BsModalService,
    private toastr : ToastrService,
    public bsModalRef: BsModalRef,
    private activeModal : NgbActiveModal) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md'
    };

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {
    
    this.offices = this.fromParent.offices;
   
  }

  // openModalForOfficeAdd(): void {
  //   this.config.class = 'modal-md';
  //   this.bsModalRef = this.modalService.show(AddOfficeComponent, this.config);
  //   this.bsModalRef.content.closeBtnName = 'Close';
  //   this.modalRefs.push(this.bsModalRef);
  //   const self = this;
  //   this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
  //     self.addNewOffice(res.data, res.data.image);
  //   });
  // }

  // addNewOffice(info: any, logo: File): void {
  //   const self = this;
  //   const infoDtls = [
  //     info[`location`],
  //     info[`name`],
  //     info[`bgcolor`],
  //     info[`theme`],
  //     info['type']
  //   ];

  //   const data = {
  //     info: infoDtls,
  //     logo: logo as File
  //   };
  //   this.officeLoader = true;
  //   this.startOfficeLoading();
  //   this.officeLoaderMessage = 'Adding new Office';
  //   this.officesService.createOffice(environment.apiUrl + 'admin/add/office', toFormData(data)).then(addOfficeRes => {
  //     self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
  //       self.offices.offices = officesRes;
  //       this.toaster.success("Office Added Successfully");
  //       this.officeLoader = false;
  //       this.startOfficeLoading();
  //     });
  //   }).catch((err) => {
  //     this.toaster.error(err.error.message);
  //     this.officeLoader = false;
  //       this.startOfficeLoading();
  //   }).catch((err) => {
  //     this.toaster.error(err.error.message);
  //     this.roleLoader = false;
  //     this.startRoleLoading();
  //   });
  // }
 
  close(){
    this.activeModal.close();
  }

}