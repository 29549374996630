import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-new-subscription-modal',
  templateUrl: './add-new-subscription-modal.component.html',
  styleUrls: ['./add-new-subscription-modal.component.scss']
})
export class AddNewSubscriptionModalComponent implements OnInit {

  clicked = false;
  roles : any[] = ['TEACHER',
                   'STUDENT'];
  firstName : any;
  lastName : any;
  userName : any;
  userId : any;
  officeId!: string;
  products : Product[] = [];
    subscription : any = {  startedDate: "",
                            endedDate: "",
                            fkUserId: "",
                            fkRoleId: "",
                            roleName: "",
                            fkProductId: "",
                            productName: "",
                            userName: "",
                          }

  constructor(private productService : ProductService,
    private activeModal : NgbActiveModal,
    private toastr : ToastrService,
    private subscriptionService : SubscriptionService,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;

    @Input() fromParent: any;

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    
  ngOnInit(): void {

    this.userId = this.fromParent.userId;
    this.officeId = this.fromParent.officeId;
    this.firstName = this.fromParent.firstName;
    this.lastName = this.fromParent.lastName;

    this.subscription.roleName = "";
    this.subscription.fkProductId = "";
    
    this.userName = this.firstName + ' ' + this.lastName;

    this.productService.getProductListByOfficeId(this.officeId).subscribe(
      data=>{
        this.products = data;
      });
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  submit(){

    this.subscription.fkUserId = this.userId;
    
    this.subscriptionService.addUserSubscriptions( environment.apiUrl + "user/addUserSubscriptions" , this.subscription).then(
      data=>{
        this.passEntry.emit(this.subscription);
        this.activeModal.close();
        this.toastr.success('User Subscription created successfully');
      });
  }

  close(){
    this.activeModal.close();
  }
}
