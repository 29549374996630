export class BotApiConfiguration {
  botApiConfigId: any;
  botApiConfigStatus: any;
  title: any;
  fkOfficeId: any;
  urlPath: any;
  resultProcessType: any;
  botResponseOptionsId: any;
  apiCallTo: any;
  context: any;
  apiKey: any;
  httpMethodType: any;
  jsonBody: any;
  titleExists: any;
  list: BotApiConfiguration[] = [];
  botResponseId: any;
}
