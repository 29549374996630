<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

       

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>   
        
      </ul>
    </div>  
  </nav>


  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>


  <mat-tab-group style="padding: 1em;" dynamicHeight>
   
    <mat-tab>

      <ng-template mat-tab-label>
        Offices
         <button matTooltip="Create Office" mat-icon-button>
           <mat-icon>add_box</mat-icon>
       </button>
       </ng-template>

      <div class="p-4">
       
    
        <div class="search-hero">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Start searching for an agent by name, email or phone number">
          </div>
          <br>
    
       
    
         
              <table *ngIf="agentsExists" class="table table-striped table-sm hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Name</th>
                    <th> Whatsapp No</th>
                    <th> Email</th>
                    <th>Filter Var 1</th>
                    <th>Users Assigned</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                 
                </tbody>
              </table>
     
            
      </div>
    </mat-tab>

    <mat-tab>

      <ng-template mat-tab-label>
        Admins
         <button matTooltip="Create Admin" mat-icon-button>
           <mat-icon>add_box</mat-icon>
       </button>
       </ng-template>

      <div class="p-4">
           
              <div class="search-hero">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off"
                 placeholder="Start searching for an admin by name, email or phone number">
              </div>
              
    <br>
            
              <table *ngIf="adminsExist" class="table table-striped table-sm hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Name</th>
                    <th> Whatsapp No</th>
                    <th> Email</th>
                    <th>Filter Var 1</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                 
                </tbody>
              </table>
       
      </div>
    </mat-tab>

  </mat-tab-group>
  
  

  



 

