import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NotificationSetting } from 'src/app/NOTIFICATION/classes/notification-setting';
import { NotificationService } from 'src/app/NOTIFICATION/services/notification.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-create-notification-setting',
  templateUrl: './create-notification-setting.component.html',
  styleUrls: ['./create-notification-setting.component.scss']
})
export class CreateNotificationSettingComponent implements OnInit {

  clicked = false;
  notificationSetting : NotificationSetting = new NotificationSetting();
  
  constructor(private notificationService : NotificationService,
    private toastr : ToastrService,    private sharedDataService: SharedDataService,
    private activeModal : NgbActiveModal) { }

  loggedInUser : any;
  theme : any;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

  }

  add(){

    this.notificationService.addNotificationSetting(environment.apiUrl + "notification/addNotificationSetting", this.notificationSetting).then(
      data=>{
        this.toastr.success("Setting saved successfully");
        this.activeModal.close();
        this.passEntry.emit("success");
      });

  }


  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  close(){
    this.activeModal.close();
  }

}
