import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";

@Component({
  selector: "app-add-bot-response-options",
  templateUrl: "./add-bot-response-options.component.html",
  styleUrls: ["./add-bot-response-options.component.scss"],
})
export class AddBotResponseOptionsComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private sharedDataService: SharedDataService,
    private botResponseService: BotResponseServiceService,
    private ngbModalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  @Input() fromParent: any;

  botResponseOption: BotResponseOptions = new BotResponseOptions();

  context: any;

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

    if (this.context === "addOptionToResponse") {
      this.botResponseOption.fkBotResponseId = this.fromParent.botResponseId;
    }
    if (this.context === "addOptionToTemplateResponse") {
      this.botResponseOption.fkBotResponseId = this.fromParent.botResponseId;
      this.botResponseOption.fkBotTemplateId = this.fromParent.fkBotTemplateId;
    }
    if (this.context === "addOptionToOption") {
      this.botResponseOption.parentOptionId =
        this.fromParent.botResponseOptionsId;
    }
    if (
      this.context === "editOption" ||
      this.context === "editTemplateButton"
    ) {
      this.botResponseOption = this.fromParent.botResponseOption;
    }
  }

  addOptionToResponse() {
    this.clicked = true;
    this.context = "";
    this.botResponseService
      .addOption(
        environment.apiUrl + "botConvo/addOptionToResponse",
        this.botResponseOption
      )
      .then((res) => {
        this.botResponseOption = res;
        if (this.botResponseOption.optionOrNumberExists === false) {
          this.passEntry.emit(this.botResponseOption);
          this.activeModal.close();
          this.toastr.success("Option added successfully !");
        } else {
          this.clicked = false;
          this.toastr.error("Option name or option number already exists !");
        }
      });
  }

  addButtonsToTemplate() {
    this.clicked = true;
    this.context = "";
    this.botResponseOption.responseOptionNumber =
      this.botResponseOption.responseOption;
    this.passEntry.emit(this.botResponseOption);
    this.activeModal.close();
  }
  
  addOptionToTemplateResponse() {
    this.clicked = true;
    this.context = "";
    this.botResponseOption.responseOptionNumber =
      this.botResponseOption.responseOption;
    this.botResponseService
      .addOption(
        environment.apiUrl + "botConvo/addOptionToTemplateResponse",
        this.botResponseOption
      )
      .then((res) => {
        this.botResponseOption = res;
        if (this.botResponseOption.optionOrNumberExists === false) {
          this.passEntry.emit(this.botResponseOption);
          console.log(this.botResponseOption);

          this.activeModal.close();
          this.toastr.success("Button added successfully !");
        } else {
          this.clicked = false;
          this.toastr.error("Button name already exists !");
        }
      });
  }

  addOptionToOption() {
    this.botResponseService
      .addOption(
        environment.apiUrl + "botConvo/addOptionToOption",
        this.botResponseOption
      )
      .then((res) => {
        this.botResponseOption = res;
        this.activeModal.close();
        this.passEntry.emit(this.botResponseOption);
        this.toastr.success("Option added successfully !");
      });
  }

  editOption() {
    this.botResponseService
      .editOption(
        environment.apiUrl + "botConvo/editBotResponseOption",
        this.botResponseOption
      )
      .then((res) => {
        this.botResponseOption = res;
        if (this.botResponseOption.optionOrNumberExists === false) {
          this.activeModal.close();
          this.passEntry.emit(this.botResponseOption);
          this.toastr.success("Option updated successfully !");
        } else {
          this.clicked = false;
          this.toastr.error("Option name or option number already exists !");
        }
      });
  }

  editTemplateButton() {
    this.botResponseService
      .editOption(
        environment.apiUrl + "botConvo/editTemplateButton",
        this.botResponseOption
      )
      .then((res) => {
        this.botResponseOption = res;
        if (this.botResponseOption.optionOrNumberExists === false) {
          this.activeModal.close();
          this.passEntry.emit(this.botResponseOption);
          this.toastr.success("Option updated successfully !");
        } else {
          this.clicked = false;
          this.toastr.error("Option name or option number already exists !");
        }
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  closeResult!: string;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
