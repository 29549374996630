  <div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="width: 50%;color: white;">Assess</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> 
    
    
    <div class="modal-body">
  
      <h2 style="text-align: center;" *ngIf="actQuestionsExists === false" >No questions to display !</h2>
     <div class="questions-modal">
        <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm" *ngIf="actQuestionsExists === true">  
   
  
            <div class="form-check" *ngFor="let question of actQuestions">
                <input (click)="handleClick(question.activityQuestionId);" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  {{question.activityQuestion}}
                </label>
              </div>
  
        </form>
      </div>  
    </div>
  
    
  
    <div class="modal-footer">
        <button [disabled]="clicked" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
      </div>