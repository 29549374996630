
<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
    <img style="margin: 10px"
    src="/assets/images/courseManagementSystem.svg"
        class="me-2"
        height="30" 
        alt=""
        loading="lazy"
      /> 
    <a class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="changePassword();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Change Password </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="open(logOutModal)" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="p-4">  
    
  <br>
  
    <div class="row">  
  
      <div class="col-md-6">  
  
        <div class="table-header row" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
  
            <span class="table-caption">
              Languages
            </span>
            <a style="cursor:pointer" class="float-right" (click)="open(addL);">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
  
          </div> 
        </div>
        
        <div class="card">
          <div class="card-body table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
              [dtOptions]="userOptions">
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let lang of languages,let i = index">
                  <td class="td-height">{{i+1}}</td>
                  <td class="td-height">{{lang.language}}</td>
                  <td class="td-height" style=" height: 2rem;">
                    <a style="cursor:pointer;padding-left: 1rem;" (click)="open(editL);">
                      <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                  </td>

                  <ng-template #editL let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Edit Language</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="col-md-12">
                          <label class="required" for="search">Language</label>
                        <input class="form-control" required type="text" [(ngModel)]="lang.language" 
                        placeholder="Enter language" name="lang.language" id="lang.language">        
                      </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
                      <button type="button" class="btn btn-success" (click)="editLanguage(lang);modal.dismiss('Cross click')">Update</button>
                    </div>
                  </ng-template>

                </tr>
              </tbody>
            </table>
   
          </div>
        </div>
  
      </div>

      <div class="col-md-6 office-table">
        <div class="table-header row" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
  
            <span class="table-caption">
              Offices
            </span>
            <a style="cursor:pointer" class="float-right" (click)="openModalForOfficeAdd();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
  
          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <table *ngIf="officesExists" class="table table-striped table-bordered table-sm row-border hover" datatable
              [dtOptions]="officeOptions">
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Office</th>
                  <th>Type</th>
                  <th>Action</th>
                  <th>Access Controls</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let office of offices.offices,let i = index">
                  <td class="td-height">{{i+1}}</td>
                  <td class="td-height">
                    {{office.officeName}}, {{office.officeLocation}}
                  </td>
                  <td class="td-height">{{office.type}}</td>
                  <td class="td-height" style=" height: 2rem;">
                    <button *ngIf="office.activeStatus!== null && office.activeStatus" type="button"
                      class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeStatus(office)">Deactivate</button>
                    <button *ngIf="office.activeStatus!== null && !office.activeStatus" type="button"
                      class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeStatus(office)">Activate</button>
                    <a *ngIf="office.activeStatus !== null" style="cursor:pointer;padding-left: 1rem;"
                      (click)="editOffice(office);">
                      <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                    <a *ngIf="office.type === 'Organisation'" style="float: right;cursor:pointer" (click)="openModalForOrgSubOfficeAdd(office.id);">
                      <img src="/assets/images/icons/add.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                    <a *ngIf="office.type === 'Organisation'" style="cursor:pointer;padding-left: 1rem;"
                      (click)="viewSubOffices();">
                      <img src="/assets/images/icons/eye.png" width="25" height="25" alt="" loading="lazy">
                    </a>
                  </td>
                  <td>
                    <button class="btn  btn-info mr-2 btn-sm" (click)="accessControls(office.id)">Access</button>
                  </td>
                </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      </div>

    </div> 

    <div class="user-padding">
      <div class="row">
        <div class="col-md-12">
          <div class="table-header row" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
            <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
              <span class="table-caption">
                Users
              </span>
              <a style="cursor:pointer" class="float-right" (click)="openModalForOfficeUserAdd();">
                <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
              </a>
    
            </div> 
          </div>
         
          
          <div class="card"> 
            <div class="card-body table-responsive">
              <table *ngIf="officeAdminsExists" class="table table-striped table-bordered table-sm row-border hover"
                datatable [dtOptions]="officeAdminOptions">  
                <thead class="tableheader">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Office</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let officeAdmin of superAdminUsers,let i = index">
                    <td class="td-height" style="width: 5rem;">
                      <span *ngIf="officeAdmin.active !== null">
                        {{i+1}}
                      </span>
                    </td>
                    <td class="td-height" style="width: 17rem;">{{officeAdmin.firstName}} {{officeAdmin.lastName}}</td>
                    <td class="td-height" style="width: 17rem;">{{officeAdmin.email}}</td>
                    <td class="td-height" style="width: 17rem;">
                      <span *ngIf="officeAdmin.userId !== null">
                        {{officeAdmin.officeDTO[0]?.officeName}}, {{officeAdmin.officeDTO[0]?.officeLocation}}
                      </span>
                    </td>
                    <td class="td-height" style="width: 17rem; height: 2rem;">

                      <button (click)="open(resetPwdModal);" class="btn btn-info" style="width: auto;">Reset Password</button>

                      <button *ngIf="officeAdmin.active!== null && officeAdmin.active" type="button"
                        class="btn btn-danger mr-2  btn-sm"
                        (click)="updateUserStatus(officeAdmin,'activate')">Deactivate</button>
                      <button *ngIf="officeAdmin.active!== null && !officeAdmin.active" type="button"
                        class="btn btn-success mr-2" (click)="updateUserStatus(officeAdmin,'activate')">Activate</button>
                      <a *ngIf="officeAdmin.active !== null" style="cursor:pointer;padding-left: 1rem;"
                        (click)="editOfficeAdmin(officeAdmin);"> 
                        <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                      </a>
                    </td>


                    <ng-template #resetPwdModal let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Reset Password for {{officeAdmin.firstName}} {{officeAdmin.lastName}}
                        </h3>
                  
                        <div>
                          <label class="required" for="newPassword">Enter new passowrd</label>
                          <input maxlength="255" required class="form-control" type="text" 
                           name="newPassword" id="newPassword" [(ngModel)]="newPassword">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="resetPassword(officeAdmin.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Reset</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
  
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-template #addL let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Add Language</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="col-md-12">
          <label class="required" for="search">Language</label>
        <input class="form-control" required type="text" [(ngModel)]="botLanguage.language" 
        placeholder="Enter language" name="botLanguage.language" id="botLanguage.language">        
      </div>
        </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addLanguage();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>


  <ng-template #logOutModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to logout ?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="logOut();modal.dismiss('Cross click')">Logout</button>
    </div>
  </ng-template>