export class Skill {

    skillId!: string;
	skillName!: string;
	skillDescription!: string;
	skillStatus!: boolean;
	parentSkillId!: any;
	safeUrl : any;
	skillImage : any;

	questionSkillMappingId : any;
	level:any;
	parentSkillName!: any;
}
