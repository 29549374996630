 <div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Activity Planners</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 




 
   
 
        <!-- <form>

      
         <div *ngFor="let act of activityPlanners">

            <div style="width: 100%;color: black;text-align: center;" >
              <a style="cursor:pointer;">
                 <img style="border-radius: 50%;" [src]="act.safeUrl" width="75" height="75"
                 alt="" loading="lazy">
              </a>
            </div>
       

            <br>
      
            <div class="modal-body">
             
        <table>
          
          <tr>
          <td style="width: 20%; color: black;">Name :</td>
          <td style="width: 80%; color: black;">{{act.activityPlannerName}}</td>
          </tr>
      
         

          <tr>
            <td style="width: 20%; color: black;">Order :</td>
            <td style="width: 80%; color: black;">{{act.activityPlannerOrder}}</td>
          </tr>

          
        
          <tr>
          <td style="width: 20%; color: black;">Description :</td>
          <td style="width: 80%; color: black;">{{act.activityPlannerDescription}}</td>
          </tr>
      
         
      
        </table>
      
      
      </div>
    </div>
      </form>
 -->
 <div class="modal-body">
 <div class="content">

  <h2 style="text-align: center;" *ngIf="actPlannersExists === false">No Activities to display !</h2>
  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of activityPlanners">
      <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
       
        <div style="width: 100%;text-align: center;">
          <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
        </div>
        <br>
          <div style="text-align: center;">
          <h2>{{card.activityPlannerName}} </h2> 
      </div>
        <br>
     
        <div style="text-align: center;">
          <p>
            {{card.activityPlannerDescription}}
          </p>
        </div>
       
      </mat-card>
    </div>
  </div>
  </div>
 </div>
   <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary float-right" (click)="closeUpdateModal()">Close</button>
  </div>






  <!-- <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div> -->