<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='create item'"
    style="color: white;" class="modal-title pull-left">Create item</h4>
    <h4 *ngIf="fromParent.context==='edit item'"
    style="color: white;" class="modal-title pull-left">Edit item</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card-body"> 
    <form #adminForm="ngForm">  

      <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>
   
    <div *ngIf="matSpinnerActive===false">

      <div *ngIf="fromParent.context==='create item'">
        <label for="fkBotCategoryId">Category</label>
        <select required class="form-control" [(ngModel)]="botItem.fkBotCategoryId">
          <option value="" disabled>Choose Category</option>
          <option *ngFor="let temp of botCategories" [ngValue]="temp.botCategoryId">{{temp.name}}</option>
        </select>
        <br>
      </div>
      
      
      <div *ngIf="fromParent.context==='edit item'" style="width: 100%;color: black;text-align: center;" >
        <a style="cursor:pointer;">
           <img style="border-radius: 50%;" [src]="botItem.safeImageUrl" width="75" height="75"
           alt="" loading="lazy">
        </a>
      </div>

      <br *ngIf="fromParent.context==='edit item'">

      <div class="form-group row">

     
        <div class="col-md-6">
            <label class="required" for="botItemName">Name</label>
            <input #botItemName="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
             name="botItemName" id="botItemName" [(ngModel)]="botItem.botItemName">
            <div class="alert alert-danger" *ngIf="botItemName.touched && !botItemName.valid">Name is Required</div>
            </div>

            <div class="col-md-6">
              <label class="required" for="pricePerItem">Price Per Item</label>
              <input #pricePerItem="ngModel" minlength="3" maxlength="255" required class="form-control" type="number" 
               name="pricePerItem" id="pricePerItem" [(ngModel)]="botItem.pricePerItem">
              <div class="alert alert-danger" *ngIf="pricePerItem.touched && !pricePerItem.valid">Price is Required</div>
              </div>

    </div>

            <br>
    
            <div>
              <label>Image</label>
              <input placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
           </div>

           <br>

          <div>
              <label for="botItemDescription">Description</label>
              <textarea class="form-control" type="text" name="botItemDescription" 
               id="botItemDescription" [(ngModel)]="botItem.botItemDescription"></textarea>
           </div> 

           <br>
           <div class="form-group row">

     
            <div class="col-md-4">
              <label for="retailerId">Retailer Id</label>
              <input #retailerId="ngModel" minlength="3" maxlength="255" class="form-control" type="text" 
               name="retailerId" id="retailerId" [(ngModel)]="botItem.retailerId">
              </div>

                <div class="col-md-4">
                <label for="availability">Availability</label>
                <input #availability="ngModel" minlength="3" maxlength="255" class="form-control" type="text" 
                 name="availability" id="availability" [(ngModel)]="botItem.availability">
                </div>
    
                <div class="col-md-4">
                  <label for="condition">Condition</label>
                  <input #condition="ngModel" minlength="3" maxlength="255" class="form-control" type="text" 
                   name="condition" id="condition" [(ngModel)]="botItem.condition">
                  </div>
    
        </div>

        <br>
        <div class="form-group row">

          <div class="col-md-4">
            <label for="brand">Brand</label>
            <input #brand="ngModel" minlength="3" maxlength="255" class="form-control" type="text" 
             name="brand" id="brand" [(ngModel)]="botItem.brand">
            </div>
  
         <div class="col-md-4">
             <label for="image_url">Image Url</label>
             <input #image_url="ngModel" minlength="3" maxlength="255" class="form-control" type="text" 
              name="image_url" id="image_url" [(ngModel)]="botItem.image_url">
             </div>
 
             <div class="col-md-4">
               <label for="website_link">Website Link</label>
               <input #website_link="ngModel" minlength="3" maxlength="255" class="form-control" type="text" 
                name="website_link" id="website_link" [(ngModel)]="botItem.website_link">
               </div>
 
     </div>
    
    </div>


    





</form>
</div>
</div>

<div class="modal-footer">
<button *ngIf="fromParent.context==='create item'" [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="matSpinnerActive=true;createBotItem();">save</button>
<button *ngIf="fromParent.context==='edit item'" [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="matSpinnerActive=true;editBotItem();">Update</button>
</div>