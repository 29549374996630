import { Curriculum } from "./curriculum";

export class CompleteCurriculum {

    mainCurriculum: Curriculum = new Curriculum;

    subCurriculumList: Curriculum[] = [];

    level0: Curriculum[] = [];
    level1: Curriculum[] = [];
    level2: Curriculum[] = [];

    

}

