export class BotLangResOptions {
    botLangResOptionsId:any;
    botLangResOptionsStatus:any;
	fkBotLanguageId:any;
	fkBotResOptionId:any;
	langResOption:any;
	langResOptionNumber:any;

	fkBotLanguage:any;
	list : BotLangResOptions[] = [];

	interactiveListSectionName:any;
	responseOptionDescription:any;
}
