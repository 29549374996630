import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { GlobalClass } from "src/app/global/global-class";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";

@Component({
  selector: "app-add-bot-user",
  templateUrl: "./add-bot-user.component.html",
  styleUrls: ["./add-bot-user.component.scss"],
})
export class AddBotUserComponent implements OnInit {
  clicked = false;
  botUser: BotUser = new BotUser();
  userExists = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  countryCodes: any[] = [];
  chooseAdmin = false;

  ngOnInit(): void {
    this.countryCodes = GlobalClass.CountryCodes;

    this.botUser.roleName = this.fromParent.roleName;
    if (this.botUser.roleName === "CHOOSE_ADMIN") {
      this.chooseAdmin = true;
      this.botUser.roleName = null;
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    if (this.fromParent.context === "EDIT_BOT_USER") {
      this.botUser = this.fromParent.botUser;
    }
  }

  onSubmit() {
    if (this.fromParent.context === "ADD_BOT_USER") {
      this.clicked = true;
      this.botOfficeService
        .addBotUser(environment.apiUrl + "botOffice/addUser", this.botUser)
        .then((res) => {
          this.botUser = res;
          if (
            this.botUser.userExists === true &&
            this.botUser.botUserExists === false
          ) {
            this.clicked = false;
            this.toastr.error("email already registered !");
          }
          if (
            this.botUser.userExists === false &&
            this.botUser.botUserExists === true
          ) {
            this.clicked = false;
            this.toastr.error("whatsapp number already registered !");
          }
          if (
            this.botUser.userExists === true &&
            this.botUser.botUserExists === true
          ) {
            this.clicked = false;
            this.toastr.error("email and whatsapp number already registered !");
          } else if (
            this.botUser.userExists === false &&
            this.botUser.botUserExists === false
          ) {
            this.toastr.success("User created successfully !");
            this.activeModal.close();
            this.passEntry.emit(this.botUser);
          }
        });
    } else if (this.fromParent.context === "EDIT_BOT_USER") {
      this.clicked = true;
      this.botOfficeService
        .editBotUser(environment.apiUrl + "botOffice/editBotUser", this.botUser)
        .then((res) => {
          this.toastr.success("User updated successfully !");
          this.activeModal.close();
          this.passEntry.emit(this.botUser);
        });
    }
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
