import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
 
@Component({
  selector: 'app-add-order-details',
  templateUrl: './add-order-details.component.html',
  styleUrls: ['./add-order-details.component.scss']
})
export class AddOrderDetailsComponent implements OnInit {
 
  theme : any
  clicked = false; 
  invoiceDetailsList: InvoiceDetails[] = []; 
  invoiceDet : InvoiceDetails = new InvoiceDetails;
  prod : Product = new Product();
  products: Product[] = [];
  products0: Product[] = [];

  invoiceId!: string;
  officeId!: string;
  qty! : any;
  price! : any;
  tp!: any;
  loggedInUser: any;
  addedProductIds : string[] = [];
  productIds : string[] = [];

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private productService : ProductService, 
              public activeModal: NgbActiveModal,
              private sharedDataService : SharedDataService,
              private toaster : ToastrService ) { }

  ngOnInit(): void { 

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.officeId = this.fromParent.officeId;
    this.invoiceDet.fkProductId = "";

    this.addedProductIds = this.fromParent.addedProductIds;
    
    this.getProducts();

    this.getFilteredProducts();

  }

  getFilteredProducts(){
    let count = 0;
    this.addedProductIds.forEach((addedProductId)=>{
      count++;
      this.productIds.forEach((productId,index)=>{
        if(productId==addedProductId)  this.productIds.splice(index,1);
     });
    });  
    if(count === this.addedProductIds.length){
      this.productIds.forEach((val)=>{
        this.products.forEach((prod)=>{
          if(val === prod.productId){
            this.products0.push(prod);
          }
        });
      });
    }
  }

  getProducts(){
    this.productService.getProductListByOfficeId(this.officeId).subscribe(data => {
      this.products= data;

      let count = 0;
      this.products.forEach((val)=>{
        count++;
        this.productIds.push(val.productId);
      });

      if(count === this.products.length){
        this.getFilteredProducts();
      }
    });
  }

  onFileSelected(event: any){

    this.qty = this.invoiceDet.quantity;
    this.price = this.invoiceDet.pricePerItem;

     this.tp = this.qty * this.price;

     this.products.forEach((prod)=>{
      if(prod.productId === this.invoiceDet.fkProductId){
        this.invoiceDet.alternateQuantity = prod.defaultQuantity * this.invoiceDet.quantity;
      }
    });

     this.invoiceDet.totalPriceBeforeDiscount = this.tp;

     if(this.invoiceDet.perAltQuantityDiscountRate === 0){
       this.invoiceDet.totalPrice = this.invoiceDet.totalPriceBeforeDiscount;
       this.invoiceDet.totalDiscountAmount = 0;
     }else{

    let totalDiscountAmount = this.invoiceDet.perAltQuantityDiscountRate * this.invoiceDet.alternateQuantity;

    this.invoiceDet.totalDiscountAmount = totalDiscountAmount;

    this.invoiceDet.totalPrice = this.invoiceDet.totalPriceBeforeDiscount - totalDiscountAmount;

     }



  }

  onChange(event : any){
    this.products.forEach((prod)=>{
      if(prod.productId === this.invoiceDet.fkProductId){
        this.invoiceDet.itemName = prod.productName;
        this.invoiceDet.pricePerItem = prod.productPrice;
        // this.invoiceDet.alternateQuantity = prod.defaultQuantity;
      }
    });
  }

  onSubmit(){ 
    this.passEntry.emit(this.invoiceDet);
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeModal(){
    this.activeModal.close();
  }

}
