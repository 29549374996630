import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Settings } from 'src/app/models/settings';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-settings',
  templateUrl: './edit-settings.component.html',
  styleUrls: ['./edit-settings.component.scss']
})
export class EditSettingsComponent implements OnInit {
  offices : any;
  clicked = false;
  setting : Settings = new Settings();

  constructor(private settingsService : SettingsService,
    private datePipe : DatePipe,
    private toastr : ToastrService,
    private activeModal : NgbActiveModal) { }

    
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {
    
   this.setting = this.fromParent.setting;

   this.setting.dateValue = new Date(this.setting.dateValue);      
   this.setting.dateValue = this.datePipe.transform(this.setting.dateValue, 'yyyy-MM-dd');   
   
  }

  add(){

    this.settingsService.editSettings(environment.apiUrl + "settings/update", this.setting).then(
      data=>{
        this.toastr.success("Setting updated successfully");
        this.activeModal.close();
        this.passEntry.emit("success");
      }
    )

  }

  close(){
    this.activeModal.close();
  }

}