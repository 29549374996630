import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Settings } from 'src/app/models/settings';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-add-settings',
  templateUrl: './add-settings.component.html',
  styleUrls: ['./add-settings.component.scss']
})
export class AddSettingsComponent implements OnInit {

  roles : any;
  clicked = false;
  setting : Settings = new Settings();

  constructor(private settingsService : SettingsService,
    private toastr : ToastrService,
    private activeModal : NgbActiveModal) { }

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.setting.officeId = "";
    
   this.roles = this.fromParent.offices;
   
  }

  add(){

    this.settingsService.addSettings(environment.apiUrl + "settings/add", this.setting).then(
      data=>{
        this.toastr.success("Setting saved successfully");
        this.activeModal.close();
        this.passEntry.emit("success");
      });

  }

  close(){
    this.activeModal.close();
  }

}
