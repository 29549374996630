import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AddCombinedParentTeacher } from "src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher";
import { SubscriptionService } from "src/app/service/subscription.service";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { GlobalClass } from "src/app/global/global-class";

@Component({
  selector: "app-bot-user-file-upload",
  templateUrl: "./bot-user-file-upload.component.html",
  styleUrls: ["./bot-user-file-upload.component.scss"],
})
export class BotUserFileUploadComponent implements OnInit {
  matSpinnerActive = false;
  showResults = false;
  clicked = false;
  combinedPTC: AddCombinedParentTeacher = new AddCombinedParentTeacher();
  userExists = false;
  parentExists = false;
  type: any;
  selectedFile!: File;
  fileSelected = false;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  resultDto: AddCombinedParentTeacher = new AddCombinedParentTeacher();

  constructor(
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private subscriptionService: SubscriptionService,
    private sharedDataService: SharedDataService
  ) {}

  loggedInUser: any;
  theme: any;

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
  get backgroundStyle() {
    let globalClass: GlobalClass = new GlobalClass();
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onFileSelected1(event: any) {
    this.selectedFile = event.target.files[0];
    this.fileSelected = true;
  }

  onSubmit() {
    this.clicked = true;
    if (this.fileSelected === true) {
      this.matSpinnerActive = true;
      let body = new FormData();
      body.append("file", this.selectedFile);

      this.subscriptionService
        .createStudentsByFileUpload(
          environment.apiUrl + "botMedia/botUsersCreationFileUpload",
          body
        )
        .then((data) => {
          this.resultDto = data;
          this.passEntry.emit("success");
          this.toaster.success("File uploaded successfully");
          this.showResults = true;
          this.matSpinnerActive = false;
        });
    } else {
      this.toaster.error("Select file");
      this.clicked = false;
    }
  }

  closeUpdateModal() {
    this.activeModal.close();
  }
}
