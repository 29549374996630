<!-- MODAL CLOSE BUTTON -->
<div  [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Class</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
   <!-- MODAL CLOSE BUTTON -->

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #clsForm="ngForm"> 
  
      <div class="form-group row">
        <div class="col-md-6">
              <label class="required" for="fkProduct">Course / Product</label> <br>
              <select #fkProduct="ngModel" class="form-control" required  [(ngModel)]="class.fkProduct" id="fkProduct" name="fkProduct">
              <option value="" disabled>Choose Course/Product</option>
                <option *ngFor="let product of products"  [ngValue]="product.productId">{{product.productName}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="fkProduct.touched && !fkProduct.valid">Select Product</div>
        </div>

        <div class="col-md-6">
          <label class="required" for="name">Name</label>
          <input  maxlength="50"  #clsName="ngModel" required class="form-control" type="text" placeholder="Enter Class Name" name="name" id="name" [(ngModel)]="class.name">
          <div class="alert alert-danger" *ngIf="clsName.touched && !clsName.valid">Name is required</div>
        </div>
      </div>

  

      <br>

    <div>
        <label for="description">Description</label>
        <textarea   maxlength="250" class="form-control" type="text" placeholder="Enter Description" name="description" id="description" [(ngModel)]="class.description"></textarea>
    </div>

    

        </form>
      </div>
    </div>
   </div>

   <div class="modal-footer">
    <button  [disabled]="clicked"  [disabled]="!clsForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Add</button>
  </div>