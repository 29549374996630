<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Generate Questions</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">

    <div *ngIf="generate === true">
        <br>
      <h4 style="text-align: center;color: black;font-weight: bold;">Would you like to generate questions for the activity ?</h4>
      <button (click)="closeUpdateModal();" class="btn btn-info" style="width: auto;margin: 2%;float: left;">Cancel</button>
        <button  (click)="generateQ();" style="margin: 2%;float: right;" class="btn btn-success">Yes</button>
    </div>

    <div style="text-align: center;" *ngIf="generated === true">
     
    
    
    
      <h3 style="font-weight: bold;">Questions</h3>
      <h4 *ngFor="let qsn of generatedQuestions">{{qsn}}</h4>
      <br>
      <h4 style="text-align: center;color: black;font-weight: bold;">Would you like to add generated questions to the activity ?</h4>
      <button (click)="closeUpdateModal();" class="btn btn-info" style="width: auto;margin: 2%;float: left;">Cancel</button>
        <button  (click)="save();" style="margin: 2%;float: right;" class="btn btn-success">Yes</button>
   
    
    
    </div>

    <div *ngIf="error === true">
      <br>
    <h4 *ngFor="let qsn of generatedQuestions" style="text-align: center;color: black;font-weight: bold;">{{qsn}}</h4>
      <button  (click)="closeUpdateModal();" style="margin: 2%;float: right;" class="btn btn-success">Close</button>
  </div>
</div>
</div>
</div>

