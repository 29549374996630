import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Class } from 'src/app/courseManagementClasses/class';
import { GlobalClass } from 'src/app/global/global-class';
import { ClassService } from 'src/app/service/class.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
 
@Component({
  selector: 'app-update-class',
  templateUrl: './update-class.component.html',
  styleUrls: ['./update-class.component.scss']
})
export class UpdateClassComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  constructor(public activeModal: NgbActiveModal,
              private classService : ClassService,
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService,
              private toaster: ToastrService ) { }
  clicked=false;
  class : Class = new Class();
  classId!: string;

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.classId = this.fromParent.classId;
    
    this.classService.getClassById(this.classId).subscribe(data=>{
      this.class = data;
    }, error => console.log(error));
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  onSubmit(){
    this.classService.updateClass(this.class).subscribe(
      data =>{
        this.passEntry.emit(this.class);
        this.activeModal.close();
        this.toaster.success("Class updated successfully");
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
