<div class="modal-header">
  <h4 class="modal-title pull-left">{{headerText}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="addProduct" method="post" (ngSubmit)="saveToList(addProduct)">
        <div class="form-group">
          <label for="if-product">Product Name</label>
          <input type="text" id="if-product" name="if-product" class="form-control" formControlName="productName"
          placeholder="Enter Product Name..">
        </div>
      
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button [disabled]="clicked"  type="submit" class="btn btn-sm btn-primary float-right" (click)="saveToList(addProduct);clicked=true">
    Save</button>
</div>
