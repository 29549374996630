import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { Shipment } from 'src/app/crmClasses/shipment';
import { PaymentService } from 'src/app/service/payment.service';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { TicketSupportService } from 'src/app/BIZ_CRM_TICKET_SUPPORT/services/ticket-support.service';
import { TicketSupportDefinition } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-support-definition';
import { TicketActual } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-actual';
import { ViewFormResponsesComponent } from 'src/app/BIZ_CRM_FEEDBACK/modals/form-response/view-form-responses/view-form-responses.component';
import { CreateTicketActionComponent } from 'src/app/BIZ_CRM_TICKET_SUPPORT/modals/ticket-action/create-ticket-action/create-ticket-action.component';

@Component({
  selector: 'app-bcrm-feedback-manager-ticket-support',
  templateUrl: './bcrm-feedback-manager-ticket-support.component.html',
  styleUrls: ['./bcrm-feedback-manager-ticket-support.component.scss']
})
export class BcrmFeedbackManagerTicketSupportComponent implements OnInit {


  customerId : any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  gridColumns = 3;
  currentRole = "BCRM_FEEDBACK_MANAGER";
  officeId!: string;
  loggedInUser: any;
  userName!: string;
  userId!: string;
  navbarTitle : any;
  loggedUser: any ;
  combinedPTC : Customer = new Customer();
  invoices : any[] = [{
    deliveryAddress: '',
description: '',
fkCustomerId: '',
fkInvoiceId: '',
fkProductId: '',
invoiceDate: '',
invoiceDetId: '',
invoiceDetStatus: '',
invoiceId: '',
invoiceNo: '',
invoiceRemarks: '',
invoiceStatus: '',
itemName: '',
paymentMethod: '',
pricePerItem: '',
quantity: '',
totalAmount: '',
totalPrice: ''
  }];
  shipments : Shipment[] = [];
  payments : any[] = [{   fkInvoiceId: "",
                          paymentAmount: "",
                          paymentCompletion: "",
                          paymentDate: "",
                          paymentDue: "",
                          paymentId: "",
                          paymentMethod: "",
                          paymentRemarks: "",
                          paymentStatus: ""
                        }];

invoiceExists!: boolean;
invoiceOptions: any = {};

paymentsExists!: boolean;
paymentOptions: any = {};

shipmentExists!: boolean;
shipmentOptions: any = {};

communicationsExists!: boolean;
communicationsOptions: any = {};

coms : CustomerCommunications[] = [];

  constructor(
    private router : Router,private route : ActivatedRoute,
    private ticketService : TicketSupportService,
    private datePipe : DatePipe,
    private ngbModalService: NgbModal, private paymentService : PaymentService,
    private sharedDataService : SharedDataService ) {
}

color: ThemePalette = 'primary';
mode: ProgressSpinnerMode = 'determinate';
value = 50;

color0: ThemePalette = 'warn';
mode0: ProgressSpinnerMode = 'determinate';
value0 = 50;

color1: ThemePalette = 'accent';
mode1: ProgressSpinnerMode = 'determinate';
value1 = 50;

ticketSupportDefinitions : TicketSupportDefinition[] = [];
ticketActuals : TicketActual[] = [];

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;
 
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.getAllTicketActualsForFeedbackManager();

  }

  action(ticketActual : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateTicketActionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      ticketActual : ticketActual
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllTicketActualsForFeedbackManager();
    });  
  }

  viewFormResponses(formRespondent : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewFormResponsesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      formRespondent : formRespondent
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
  
  getAllTicketActualsForFeedbackManager(){
    this.ticketService.getAllTicketActualsForFeedbackManager(environment.apiUrl + "ticketSupport/getAllTicketActualsForFeedbackManager").then(
      data=>{
        
        this.ticketActuals = data;

        this.ticketActuals.forEach((ta)=>{

          ta.expansionPanel = false;

          let sd = ta.timeStamp;
          ta.timeStamp = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
          ta.timeStamp = this.datePipe.transform(ta.timeStamp, 'yyyy-MM-dd hh:mm');

          ta.ticketActions.forEach((tAction)=>{

            let ab = tAction.timeStamp;
            tAction.timeStamp = new Date(ab[0], ab[1] - 1, ab[2], ab[3], ab[4]);
            tAction.timeStamp = this.datePipe.transform(tAction.timeStamp, 'yyyy-MM-dd hh:mm');
  
          });

        });

      });
  }

  expansionPanel(ticketActualId : any){
    this.ticketActuals.forEach((ta)=>{
      if(ta.ticketActualId === ticketActualId){
        ta.expansionPanel = !ta.expansionPanel;
      }
     });
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

  raiseTicket(ticketSupportDef : TicketSupportDefinition){
    this.router.navigate(['ticket-support/raise-ticket/form', ticketSupportDef.ticketSupportDefinitionId, this.customerId])
  }
 
}
