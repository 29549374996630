import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';

@Component({
  selector: 'app-view-location-group-based-invoices',
  templateUrl: './view-location-group-based-invoices.component.html',
  styleUrls: ['./view-location-group-based-invoices.component.scss']
})
export class ViewLocationGroupBasedInvoicesComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser : any;
  theme : any;
  invoices: InvoiceList[] = [];
  achievedPercentage : any;

  constructor(private invoiceService : InvoiceService, 
              private activeModal : NgbActiveModal,
              private sharedDataService: SharedDataService,

            ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.invoices = this.fromParent.locationGroupBasedInvoices;
    
  }



  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 


  closeUpdateModal(){
    this.activeModal.close();
  }
}