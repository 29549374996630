import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotUserLocation } from "src/app/BOT_CODE/bot_classes/bot-user-location";

@Component({
  selector: "app-bot-user-location-modal",
  templateUrl: "./bot-user-location-modal.component.html",
  styleUrls: ["./bot-user-location-modal.component.scss"],
})
export class BotUserLocationModalComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;
  matSpinnerEnabled = false;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  constructor(
    private sharedDataService: SharedDataService,
    private botResponseService: BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  context: any;
  botUserLocationObj: BotUserLocation = new BotUserLocation();
  officeBotUserLocations: BotUserLocation[] = [];

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

    if (this.context === "send location object to user") {
      this.getLocObjects();
      this.botUserLocationObj.fkBotUserId = this.fromParent.fkBotUserId;
      this.botUserLocationObj.botUserLocationId = "";
    }

    if (this.context === "create new location object") {
    }
  }

  sendLocationToUser() {
    this.matSpinnerEnabled = true;
    this.botResponseService
      .createOrEditBotLocationObject(
        environment.apiUrl + "botResponse/sendLocationResponseByAgent",
        this.botUserLocationObj
      )
      .then((res) => {
        if (res === true) {
          this.passEntry.emit("success");
          this.toastr.success("Location shared successfully !");
          this.matSpinnerEnabled = false;
          this.activeModal.close();
        } else {
          this.toastr.error("Could not share Location !");
          this.matSpinnerEnabled = false;
          this.activeModal.close();
        }
      });
  }

  createOrEditBotLocationObject() {
    this.botResponseService
      .createOrEditBotLocationObject(
        environment.apiUrl + "botOffice/createOrEditBotLocationObject",
        this.botUserLocationObj
      )
      .then((res) => {
        this.passEntry.emit(res);
        this.toastr.success("Location created successfully !");
        this.activeModal.close();
      });
  }

  getLocObjects() {
    this.botResponseService
      .getLocations(environment.apiUrl + "botOffice/getLocationsByOfficeId")
      .then((res) => {
        let resLocObj: BotUserLocation = new BotUserLocation();
        resLocObj = res;
        this.officeBotUserLocations = resLocObj.list;
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  createLocationObject() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserLocationModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "create new location object",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUserLocation) => {
        this.officeBotUserLocations.push(receivedEntry);
      }
    );
  }
}
