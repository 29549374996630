import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditCombinedParentTeacherComponent } from 'src/app/course-management/combinedParentTeacher/edit-combined-parent-teacher/edit-combined-parent-teacher.component';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ImageEditPopupComponent } from '../../combinedParentTeacher/image-edit-popup/image-edit-popup.component';
  
@Component({
  selector: 'app-teacher-user-profile',
  templateUrl: './teacher-user-profile.component.html',
  styleUrls: ['./teacher-user-profile.component.scss']
})
export class TeacherUserProfileComponent implements OnInit {
  showSpinner = true; 

  currentRole = "TEACHER";
  userId!: string;
  theme: string = '';
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  loggedInUser : any;
  loggedUser : any;
  unsafeUrl : any;
  safeUrl : any;

  centered = false;
  disabled = false;
  unbounded = false;

  radius!: number;
  color!: string;

  public screenWidth: any;
  public screenHeight: any;

  info : any = {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    country: "",
                    courseDescription: "",
                    courseName: "",
                    dob: "",
                    emailId: "",
                    endedDate: "",
                    phone: "",
                    startedDate: "",
                    state: "", 
                    userId: "",
                    userImage: "",
                    userName: "",
                    zipCode: "",

                    roleList : [{
                                  id : "",
                                  roleName : "",
                                  dashboard : "",
                                  active : ""
                                }]
  }
  navbarTitle: any;

  constructor(private route : ActivatedRoute,
              private teacherService : TeacherService,
              private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              private router : Router) { }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);

   this.userId = this.route.snapshot.params['userId'];

   this.getTeacherProfileDetails();

  }

  getTeacherProfileDetails(){
      this.teacherService.getAccountInfoForProfile(environment.apiUrl + "teacher/getTeacherAccountInfo").then(
      data=>{
      this.info = data;
      this.imageService.getUserImage(this.info.userImage).subscribe(
        data=>{
          this.unsafeUrl = URL.createObjectURL(data);
          this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
          this.showSpinner=false;
        });
      });
  }
 
  editImage(userId : string , image : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ImageEditPopupComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : userId,
      imageName : image
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getTeacherProfileDetails();
    });  
  }

  editDetails(UserId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditCombinedParentTeacherComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      parentUserId: UserId 
        }
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
    this.getTeacherProfileDetails();
    });  

  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  goBack() {
    this.router.navigate(['/dashboard/user']).then();
  }
  
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  reloadPage() {
    window.location.reload();
  }
}
