export class Attendance {
    attendanceId:any;
	 fkClassId:any;
	 fkTeacherUroId:any;
	 fkStudentId:any;
	timeStamp:any;
	attendanceFromDateTime:any;
	attendanceToDateTime:any;
	attendanceDate:any;
	attendanceStatus:any;

	noAttendance : any;

	markAttendance : any;
	studentName : any;

	studentClassId : any;
	studentClassName : any;

	attendanceFromDateTimeFormatted:any;
	attendanceToDateTimeFormatted:any;

	present : any;

	attendanceList : Attendance[] = [];

	attendanceForDifferentClassSameSession : Attendance[] = [];

	absenteeList : Attendance[] = [];
}
