import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormConfiguation } from 'src/app/models/form-configuation';
import { FormConfigurationService } from 'src/app/services/FormConfiguration/form-configuration.service';
import { environment } from 'src/environments/environment';
  
@Component({
  selector: 'app-add-form-config',
  templateUrl: './add-form-config.component.html',
  styleUrls: ['./add-form-config.component.scss']
})
export class AddFormConfigComponent implements OnInit {

  offices : any[] = [];
  clicked = false;
  formConfig : FormConfiguation = new FormConfiguation();

   constructor(private formConfigService : FormConfigurationService,
    private toastr : ToastrService,
    private activeModal : NgbActiveModal) { }

    
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {
    
   this.offices = this.fromParent.offices;

   this.formConfig.columnName = "";
   this.formConfig.fkOfficeId = "";
   this.formConfig.formName = "";
   this.formConfig.groupName = "";

  }

  add(){
  this.formConfigService.addFormConfiguration(environment.apiUrl + "formConfig/addFormConfiguration", this.formConfig).then(
    data=>{
            this.activeModal.close();
            this.passEntry.emit("success");
            this.toastr.success("Form configuration added successfully")
    });
  }

  close(){
    this.activeModal.close();
  }

}

