import { FormRespondent } from "src/app/BIZ_CRM_FEEDBACK/classes/form-respondent";
import { TicketAction } from "./ticket-action";

export class TicketActual {

    ticketActualId : any;
    ticketActualActiveStatus : any;
    fkTicketSupportDefinitionId : any;
    fkFormRespondentId : any;
    ticketActualStatus : any;
    ticketRaiserInfo : any;
    assignedToUroId : any;

    tickerRaiserName : any;
    
    ticketActions : TicketAction[] = [];
    
    timeStamp : any;
    ticketSupport : any;
    ticketSupportDescription : any;
    ticketSupportType : any;
    ticketSupportGroup : any;

    formRespondentDto : FormRespondent = new FormRespondent();

    expansionPanel : any;
}
