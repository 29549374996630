import { SafeResourceUrl } from "@angular/platform-browser";
import { ActivityVideoLink } from "./activity-video-link";

export class ActivityPlanner {

    activityPlannerId!: string;
 
	activityPlannerName!: string;

	activityPlannerDescription!: string;

	activityPlannerOrder!: number;

	activityPlannerStatus!: boolean;
	activityPlannerImageUrl : any;
	fkSubjectId!: string;

	activityPlannerImage!: string;

	activityPlannerCompletionStatus!: boolean;
	activityCompletionStatus : any;

	studentActivityPlannerCompletionStatus : any;

	safeUrl : any;

	activityVideoLinkList : ActivityVideoLink[] = [];

	assignmentActiveStatus : any;

	type! : string;

	studentAttempts : any = 0;

	studentActivityHistoryIndicator : any;

	activityDates : any[] = [];
	dateString : any
}
