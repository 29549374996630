import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotResponse } from 'src/app/BOT_CODE/bot_classes/bot-response';
import { BotLanguage } from 'src/app/BOT_CODE/bot_classes/bot-language';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { BotLangResponse } from 'src/app/BOT_CODE/bot_classes/bot-lang-response';
import { BotSuperadminServiceService } from 'src/app/BOT_CODE/bot_services/bot-superadmin--service.service';

@Component({
  selector: 'app-create-res-lang-variation',
  templateUrl: './create-res-lang-variation.component.html',
  styleUrls: ['./create-res-lang-variation.component.scss']
})
export class CreateResLangVariationComponent implements OnInit {

 
  clicked = false;
  loggedInUser : any;
  theme : any; 

  constructor(private sharedDataService: SharedDataService,
    private botService : BotSuperadminServiceService,
    private botOfficeService : BotOfficeServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    botLangResponses : BotLangResponse[] = [];
    botLangResponse : BotLangResponse = new BotLangResponse();
    botResponse : BotResponse = new BotResponse();
    botLanguages : BotLanguage[] = [];

    context : any;

    ngOnInit(): void {
      this.botLangResponse.fkBotLanguageId = "chooselanguage";
      this.context = this.fromParent.context;

      if(this.context === "create"){
        this.botResponse = this.fromParent.botResponse;
        this.botLangResponse.fkBotResponseId = this.botResponse.botResponseId;
        this.getAllLanguagesAndAllLangResponsesAndFilterLanguages();
      }

      if(this.context === "edit"){
        let x : BotLangResponse = new BotLangResponse();
        x = this.fromParent.botLangResponse;

        this.botLangResponse.botLangResId = x.botLangResId;
        this.botLangResponse.botLangResStatus = x.botLangResStatus;
        this.botLangResponse.fkBotLanguage = x.fkBotLanguage;
        this.botLangResponse.fkBotLanguageId = x.fkBotLanguageId;
        this.botLangResponse.fkBotResponseId = x.fkBotResponseId;
        this.botLangResponse.langResponse = x.langResponse;
        this.botLangResponse.interactiveListButtonName = x.interactiveListButtonName;
      }

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

   }

   getAllLanguagesAndAllLangResponsesAndFilterLanguages(){
    this.botService.getAllLanguages(environment.apiUrl + "botConvo/getBotOfficeSubscribedLanguages").then(
      res=>{
        let tempList : BotLanguage[] = []; 
        tempList = res;        

        this.botOfficeService.getAllLangVariations(environment.apiUrl + "botOffice/getAllLangResponsesByResponseId/" + this.botResponse.botResponseId).then(
          res=>{
            let resObj : BotLangResponse = new BotLangResponse();
            resObj = res;
            this.botLangResponses = resObj.list;
    
            if(this.botLangResponses !== null && this.botLangResponses !== undefined && this.botLangResponses.length > 0){
            let addedLanguages : string[] = [];
            this.botLangResponses.forEach((lRes)=>{
              addedLanguages.push(lRes.fkBotLanguage);
            });

            tempList.forEach((tempL)=>{
              if(!addedLanguages.includes(tempL.language)){
                this.botLanguages.push(tempL);
              }
            });
          }else{
            this.botLanguages = tempList;
          }
    
          });
      });
   }


   create(){
    
    this.botOfficeService.createLanguageVariation(environment.apiUrl + "botOffice/createBotLangResponse", this.botLangResponse).then(
      res=>{
        this.activeModal.close();
        this.toastr.success("Response added successfully !");
        this.passEntry.emit("success");
      });

  }

  edit(){
    
    this.botOfficeService.editLanguageVariation(environment.apiUrl + "botOffice/editBotLangResponse", this.botLangResponse).then(
      res=>{
        this.passEntry.emit(this.botLangResponse);
        this.activeModal.close();
        this.toastr.success("Response updated successfully !");
      });

  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}
