import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { OfficesService } from "src/app/services/office/offices.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-office-access-controls",
  templateUrl: "./office-access-controls.component.html",
  styleUrls: ["./office-access-controls.component.scss"],
})
export class OfficeAccessControlsComponent implements OnInit {
  officeId!: string;

  office: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    oa_ParentFeedback: "",
    oa_Assignment: "",
    oa_ActQuesSkillMapping: "",

    officeLocation: "",
    officeName: "",
    themeColour: "",

    multiLanguageSettings: "",
    fkBotLangId: "",
    defaultLanguageName: "",
    globalTemplateResCreation: "",
    officeTimezone: "",
    whatsappNumber: "",
    adminsAccess: "",
    agentsAccess: "",
    medSpecialistsAccess: "",
    shopAccess: "",
    templatesAccess: "",
    scheduleMsgsAccess: "",
    formsAccess: "",
    autoschedulerEnabled: "",
    botCallbackAccess: "",
    multiConvoFLowsConfigAccess: "",
  };

  constructor(
    private officeService: OfficesService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService
  ) {}

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.officeId = this.fromParent.officeId;
    this.getOffControls();
  }

  getOffControls() {
    this.officeService
      .getOfficeByIdBotAcessControls(this.officeId)
      .subscribe((data) => {
        this.office = data;
        console.log(this.office);
      });
  }

  updateOfficeAccessControlStatus(accessControl: any) {
    this.officeService
      .updateAccessControlStatuses(
        environment.apiUrl +
          "admin/updateOfficeAccessControlStatus/" +
          this.officeId +
          "/" +
          accessControl +
          "/" +
          null
      )
      .then((res) => {
        this.toaster.success("Status Updated Successfully!");
        this.getOffControls();
      });
  }

  close() {
    this.activeModal.close();
  }
}
