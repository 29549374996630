import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AddCombinedParentTeacher } from "../courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private baseUrl6 = environment.apiUrl + "user/getUserForEdit";
  private baseUrl9 = environment.apiUrl + "user/userRoleOfficeStatusUpdate";
  private baseUrl10 = environment.apiUrl + "user/searchUserByEmail";

  authToken: string | null = "";
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem("auth-token");
  }

  searchUserByEmail(email: any): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseUrl10}/${email}`);
  }

  searchUserByEmailAuth(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  searchUserByEmailOfficeAdmin(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getCustomerUsersByEmailOrPhone(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getUsersByEmailOrPhone(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  searchIfUsernameExistsByEmailOrPhone(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getProductWiseSubscriptions(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  searchCustomerAndUserProfiles(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getTeachersInOfficeForClassAdmin(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getStudentsInOfficeForClassAdmin(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getParentsInOfficeForClassAdmin(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  // getUsersByEmailOrPhone(value : string, officeId : string): Observable<any[]>{
  //   return this.httpClient.get<any[]>(`${this.baseUrl}/${value}/${officeId}`);
  // }

  // userStatusUpdate(userId : string): Observable<any>{
  //   return this.httpClient.delete<any>(`${this.baseUrl2}/${userId}`);
  // }

  userStatusUpdate(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  updateAssignedRoleStatus(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  userRoleOfficeStatusUpdate(id: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.baseUrl9}/${id}`);
  }

  getSubscriptionsByUserId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  // getSubscriptionsBySubscriptionId(subscriptionId : string): Observable<any[]>{
  //   return this.httpClient.get<any[]>(`${this.baseUrl8}/${subscriptionId}`);
  // }

  // addUserSubscriptions(): Observable<any>{
  //   return this.httpClient.post<any>(`${this.baseUrl4}`, subscription);
  // }

  addUserSubscriptions(avenue: string, subscription: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, subscription, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, subscription, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createStudentsByFileUpload(avenue: string, file: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, file, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, file, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  // updateSubscription(subscription : any): Observable<any>{
  //   return this.httpClient.put<any>(`${this.baseUrl8}`, subscription);
  // }

  updateSubscription(avenue: string, subscription: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("PUT", avenue, subscription, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, subscription, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  // addUser(user : AddCombinedParentTeacher): Observable<AddCombinedParentTeacher>{
  //   return this.httpClient.post<AddCombinedParentTeacher>(`${this.baseUrl5}`, user);
  // }

  // updateUser(avenue: string, user : AddCombinedParentTeacher): Promise<any> {
  //   const self = this;
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + this.authToken
  //   });
  //   return new Promise((resolve, reject) => {
  //     self.httpClient.put(avenue, { headers: headers })
  //       .toPromise()
  //       .then((data) => {
  //         resolve(data);
  //       }).catch((err) => {
  //       });
  //   });
  // }

  updateUser(avenue: string, user: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("PUT", avenue, user, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, user, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  addUser(avenue: string, user: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, user, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, user, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  linkExistingUserAsParent(avenue: string, user: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, user, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, user, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  addParent(avenue: string, user: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, user, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, user, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getUserForEdit(userId: string): Observable<AddCombinedParentTeacher> {
    return this.httpClient.get<AddCombinedParentTeacher>(
      `${this.baseUrl6}/${userId}`
    );
  }

  getChildren(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getMetaUrl(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  searchSubscriptionsByCustomerDetails(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getProductWiseSubscriptionsAboutToEnd(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getSubscriptionsByPeriod(avenue: string, subscription: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, subscription, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, subscription, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  passwordUpdate(avenue: string, subscription: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, subscription, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, subscription, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }
}
