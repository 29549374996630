


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Update Location Group</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form (ngSubmit)="onSubmit()" #invoiceForm="ngForm"> 
             
        <div class="form-group row">
          <div class="col-md-6">
            <label class="required"  for="groupName">Group Name</label>
            <input #no="ngModel" required class="form-control" type="text" id="groupName" 
            name="groupName" [(ngModel)]="lg.groupName">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Name is required</div>
          </div>

          <div class="col-md-6">
            <label class="required"  for="groupCode">Group Code</label>
            <input #no="ngModel" required class="form-control" type="text" id="groupCode" name="groupCode" 
            [(ngModel)]="lg.groupCode">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid"> Code is required</div>
          </div>

        </div>
      
  
             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Update</button>
      </div>