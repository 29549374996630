export class Customer {
    customerId!: string;
    firstName!: string;
    lastName!: string;
    customerGender!: string;
    customerDob!: Date;
    customerPhone!: string;
    customerAltPhone!: string;
    customerEmailId!: string;
    customerStatus!: boolean;
 
}
