<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
       
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
 
  <div class="p-4">

    <h2 style="text-align: left; font-weight: bold;">Callback Url, Phone number ID & Permanent Token</h2>

    <br> 

    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
            
         <a *ngIf="officeControlsData.whatsappNumber === null || officeControlsData.whatsappNumber === undefined"
         matTooltip="Phone Number Not Set" 
          style="cursor:pointer; float: right;">
          <img src="/assets/images/icons/unverified.png" width="27" height="27" alt="" loading="lazy">
         </a>

         

         
  
     <a *ngIf="officeControlsData.whatsappNumber !== null && officeControlsData.whatsappNumber !== undefined"
     matTooltip="Phone Number Set Successfully"  
     style="cursor:pointer; float: right;">
      <img src="/assets/images/icons/verified.png" width="27" height="27" alt="" loading="lazy">
     </a>

     <a *ngIf="officeControlsData.whatsappNumber !== null && officeControlsData.whatsappNumber !== undefined"
         (click)="open(offWaNoModal);"
         matTooltip="View Whatsapp Number" 
          style="cursor:pointer; float: right;">
          <img src="/assets/images/icons/info.png" width="27" height="27" alt="" loading="lazy">
         </a>
    
     <a matTooltip="Steps" *ngIf="officeControlsData.whatsappNumber === null || officeControlsData.whatsappNumber === undefined" (click)="setupOfficeWhatsappNumber();" style="cursor:pointer; float: right;">
      <img src="/assets/images/icons/info.png" width="27" height="27" alt="" loading="lazy">
  </a>
    </div> 
<br>

          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/whatsapp.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Setup Phone Number</h3>
      </mat-card>
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
            
         <a matTooltip="Callback Url Not Set" 
         *ngIf="officeControlsData.wcaCallbackUrlVerificationStatus === false"
          style="cursor:pointer; float: right;">
          <img src="/assets/images/icons/unverified.png" width="27" height="27" alt="" loading="lazy">
         </a>
  
     <a matTooltip="Callback Url Set Successfully" 
     *ngIf="officeControlsData.wcaCallbackUrlVerificationStatus === true" 
     style="cursor:pointer; float: right;">
      <img src="/assets/images/icons/verified.png" width="27" height="27" alt="" loading="lazy">
     </a>
    
     <a matTooltip="Steps" (click)="setupCallbackUrl();" style="cursor:pointer; float: right;">
      <img src="/assets/images/icons/info.png" width="27" height="27" alt="" loading="lazy">
  </a>
    </div> 
<br>

          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/url.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Setup Callback url</h3>
      </mat-card>
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
            
            <a matTooltip="Phone Number ID Not Set" *ngIf="officeControlsData.wcaPhoneNumberID === null || officeControlsData.wcaPhoneNumberID === undefined"
             style="cursor:pointer; float: right;">
             <img src="/assets/images/icons/unverified.png" width="27" height="27" alt="" loading="lazy">
            </a>
     
        <a matTooltip="Phone Number ID Set Successfully" *ngIf="officeControlsData.wcaPhoneNumberID !== null && officeControlsData.wcaPhoneNumberID !== undefined" 
        style="cursor:pointer; float: right;">
         <img src="/assets/images/icons/verified.png" width="27" height="27" alt="" loading="lazy">
        </a>
       
        <a matTooltip="Steps" (click)="savePhoneNumberID();" style="cursor:pointer; float: right;">
         <img src="/assets/images/icons/info.png" width="27" height="27" alt="" loading="lazy">
     </a>
       </div> 
   <br>
          <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/id.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Save Phone Number ID</h3>
      </mat-card>
      </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          <div class="row" style="margin: 0%;padding: 0%;float: right;width: 100%;display: block;">
            
            <a matTooltip="Permanent Token Not Set" *ngIf="officeControlsData.wcaPermanentToken === null || officeControlsData.wcaPermanentToken === undefined"
             style="cursor:pointer; float: right;">
             <img src="/assets/images/icons/unverified.png" width="27" height="27" alt="" loading="lazy">
            </a>
     
        <a matTooltip="Permanent Token Set Successfully" *ngIf="officeControlsData.wcaPermanentToken !== null && officeControlsData.wcaPermanentToken !== undefined" 
        style="cursor:pointer; float: right;">
         <img src="/assets/images/icons/verified.png" width="27" height="27" alt="" loading="lazy">
        </a>
       
        <a matTooltip="Steps" (click)="createPermanentToken();" style="cursor:pointer; float: right;">
         <img src="/assets/images/icons/info.png" width="27" height="27" alt="" loading="lazy">
     </a>
       </div> 
   <br>
   <div style="width: 100%;color: black;text-align: center;" >
            <a style="cursor:pointer;">
               <img src="/assets/images/icons/token.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          <br>
           <h3 class="grey-f" style="margin: 0%;text-align: center;font-weight: bold;">Save Permanent Token</h3>
      </mat-card>
      </div>

    </div>
  </div>

  <div class="p-4">

    <h2 style="text-align: left; font-weight: bold;">Convo Initiator Keywords</h2>

    <br> 

    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
        <mat-card class="example-card mat-elevation-z12" >

          <div fxLayout="row wrap" fxLayoutGap="16px grid">
         
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >

            <table class="table table-striped table-sm hover">
              <thead>
                <th style="text-align: center;">Keywords
                  <button matTooltip="Add Keyword" (click)="open(addConvoInitiatorKeyWordModal);" mat-icon-button>
                    <mat-icon>add_box</mat-icon>
                  </button>
                </th>
                
              </thead>
              <tr *ngFor="let kWord of botOfficeLanguageDetails.officeKeywords">
                <td>
                  {{kWord}} 

                  <button (click)="open(removeKeywordModal);"
                  matTooltip="Delete" mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>

                </td>


                <ng-template #removeKeywordModal let-modal>
                  <div class="modal-body" style="color: black;">
              
                    <h3 style="font-weight: bold;">
                     Remove keyword 
                     </h3>

                    <h4 style="font-weight: bold;">
                     Are you sure to remove the keyword : "{{kWord}}"
                    </h4>
                            
                    <button style="float: right;width: auto;margin: 0.5%;" (click)="removeConvoInitiatorKeyword(kWord);modal.dismiss('Cross click')"
                    mat-raised-button>Remove</button>
                    <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                    
                  </div>
                </ng-template>
              </tr>
             
            </table>

          </div>

        </div>
          
        
      </mat-card>
      </div>

    </div>
  </div>

  <div class="p-4">

    <h2 style="text-align: left; font-weight: bold;">Language Settings</h2>

    <br> 

    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
        <mat-card class="example-card mat-elevation-z12" >

          <div fxLayout="row wrap" fxLayoutGap="16px grid">
         
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >

           Default Language : {{botOfficeLanguageDetails.fkBotLangName}}
            <a  (click)="setOfficeDefaultLanguage();"
              style="cursor:pointer; ">
              <img src="/assets/images/icons/edit.svg" width="22" height="22" alt="" loading="lazy">
            </a>

            
            
            
            <button *ngIf="botOfficeLanguageDetails.multiLanguageSettings!== null && botOfficeLanguageDetails.multiLanguageSettings" type="button"
            class="btn btn-danger mr-2 btn-sm" style="width: auto;float: right;" (click)="updateMultiLanguageSettingsStatus()">Deactivate Multi-Language Access</button>
          <button *ngIf="botOfficeLanguageDetails.multiLanguageSettings!== null && !botOfficeLanguageDetails.multiLanguageSettings" type="button"
            class="btn  btn-success mr-2 btn-sm" style="width: auto; float: right;" (click)="updateMultiLanguageSettingsStatus()">Activate Multi-Language Access</button>


            <table class="table table-striped table-sm hover">
              <thead>
                <th style="text-align: center;">Language
                  <button matTooltip="Add Language" (click)="addLanguagesToOffice();" mat-icon-button>
                    <mat-icon>add_box</mat-icon>
                  </button>
                </th>
                <th style="text-align: center;">Action</th>
              </thead>
              <tr *ngFor="let lang of botOfficeLanguageDetails.botOfficeLanguages">
                <td>
                  {{lang.fkBotLanguageName}}
                </td>
                <td>
  
                  <button *ngIf="lang.active!== null && lang.active" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeLanguageStatus(lang.botOfficeLangId)">Deactivate</button>
                <button *ngIf="lang.active!== null && !lang.active" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeLanguageStatus(lang.botOfficeLangId)">Activate</button>
  
                </td>
              </tr>
             
            </table>

          </div>

        </div>
          
        
      </mat-card>
      </div>

    </div>
  </div>


  <ng-template #offWaNoModal let-modal>
    <div class="modal-body" style="color: black;">

      <br>

      <h3 style="font-weight: bold;">
       Whatsapp Number : {{officeControlsData.whatsappNumber}} 
       <a [cdkCopyToClipboard]="officeControlsData.whatsappNumber" (click)="toastr.show('Whatsapp Number copied !')" matTooltip="Copy Whatsapp Number" style="cursor:pointer;">
        <img src="/assets/images/icons/copyText.png" width="20" height="20"
        alt="" loading="lazy">
     </a>
      </h3>

      
    </div>
  </ng-template>

  <ng-template #addConvoInitiatorKeyWordModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
       Enter Keyword
      </h3>

      <div>
        <label class="required" for="newKeyword">Keyword</label>
        <input maxlength="255" required class="form-control" type="text" 
         name="newKeyword" id="newKeyword" [(ngModel)]="newKeyword">
      </div>
      <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="addConvoInitiatorKeyword();modal.dismiss('Cross click')"
      mat-raised-button>Save</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div>
  </ng-template>
