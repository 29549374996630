import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Class } from 'src/app/courseManagementClasses/class';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { Teacher } from 'src/app/courseManagementClasses/teacher';
import { ClassService } from 'src/app/service/class.service';
import { SubjectService } from 'src/app/service/subject.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-teacher-class-assign-modal',
  templateUrl: './teacher-class-assign-modal.component.html',
  styleUrls: ['./teacher-class-assign-modal.component.scss']
})
export class TeacherClassAssignModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
    public classService : ClassService,
    private teacherService : TeacherService,
    public subService : SubjectService,
    private toaster: ToastrService
    ,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;

    teacher : Teacher = new Teacher();
    userId : any;
    officeId : any;
    firstName : any;
    lastName : any;
    classes : Class[] = [];
    subjects : Subject[] = [];
    selectedClassId : any = "";
    selectedSubjectId : any = "";
    selectedProductId : any;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.userId = this.fromParent.userId;
    this.officeId = this.fromParent.officeId;

    this.teacher.userId = this.userId;
    this.teacher.officeId = this.officeId;

    this.firstName = this.fromParent.firstName;
    this.lastName = this.fromParent.lastName;
    
    this.classService.getClassListByOfficeIdCA(environment.apiUrl + "class/getListByOfficeIdCA/" + this.officeId).then(
      data=>{
        this.classes = data;
      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  chooseSubject(){

    this.subjects = [];

    this.classes.forEach((value)=>{
      if(this.selectedClassId === value.classId){
        this.selectedProductId = value.fkProduct;

        this.subService.getSubjectsByProductIdClassAdmin(environment.apiUrl +  "subject/getSubjectsByProductIdClassAdmin/" + this.selectedProductId).then(
          data=>{
            this.subjects = data;
          });
      }
    });

 
  }

  assignClassSubject(){
    this.teacher.fkClass = this.selectedClassId;
    this.teacher.fkSubject = this.selectedSubjectId;

    this.teacherService.addTeacherUsingUserIdCA(this.teacher).subscribe(
      data=>{
      this.activeModal.close();
      this.passEntry.emit('Teacher added successfully')
      this.toaster.success('Teacher added successfully');
    }); 
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
