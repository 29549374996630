import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/courseManagementClasses/role';
import { Uro } from 'src/app/courseManagementClasses/uro';
import { GlobalClass } from 'src/app/global/global-class';
import { RoleService } from 'src/app/service/courseManagementServices/role.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.scss']
})
 
export class AssignRoleComponent implements OnInit {
  clicked = false;
  @Input() fromParent: any; 
  loggedInUser : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  usersExists = false;
  userOptions: any;
  userId! : string;
  officeId! : string;
  roleIdToAssign! : string;
  userFirstName! : string;
  userLastName! : string;
  assignableRoles : Role[] = [];
  assignedRoles : Role[] = [];
  uro : Uro = new Uro();
  theme : any;

  constructor(private roleService : RoleService,
              private sharedDataService: SharedDataService,
              private subscriptionService : SubscriptionService,
              public activeModal: NgbActiveModal,
              private toastr : ToastrService ) { }

  ngOnInit(): void {

    this.roleIdToAssign = "";

    this.assignableRoles = [];
    this.assignableRoles.pop();

    this.userId = this.fromParent.userId;
    this.officeId = this.fromParent.officeId;
    this.userFirstName = this.fromParent.userFirstName;
    this.userLastName =this.fromParent.userLastName;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    // authorized
    this.roleService.getUserAssignedRoles(environment.apiUrl + "user/getUserAssignedRoles/" + this.userId).then(
      data=>{
        this.assignedRoles = data; 
      });

    // authorized
    this.roleService.getUserAssignableRoles(environment.apiUrl + "role/getAssignableRoles/" + this.userId + "/" + this.officeId).then(
      data =>{
        this.assignableRoles = data;
      });

  }

  assignRole(){

    this.uro.userId = this.userId;
    this.uro.roleId = this.roleIdToAssign;
    this.uro.officeId = this.officeId;

    // authorized
    this.roleService.assignAdminRole(environment.apiUrl + "user/assignAdminRole", this.uro).then(
      data=>{
            this.activeModal.close();
            this.assignableRoles = [];
            this.toastr.success('Role Assigned Successfully');
          });
  }

  // authorized
  updateUserRoleOfficeStatus(uroId : any){

    this.subscriptionService.updateAssignedRoleStatus(environment.apiUrl + "user/updateAssignedRoleStatus/" + uroId).then(
    data=>{      
    this.assignedRoles.forEach((value)=>{
          if(value.userRoleOfficeId === uroId){
            if(value.userRoleOfficeStatus === true){
              value.userRoleOfficeStatus = false
            }else if(value.userRoleOfficeStatus === false){
              value.userRoleOfficeStatus = true
            }
          }
        });
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

}
