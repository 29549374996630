import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddActivityQuestionsComponent } from 'src/app/course-management/activity-questions/add-activity-questions/add-activity-questions.component';
import { ViewActivityQuestionsComponent } from 'src/app/course-management/activity-questions/view-activity-questions/view-activity-questions.component';
import { AddActivityPlannerComponent } from 'src/app/course-management/activityPlanner/add-activity-planner/add-activity-planner.component';
import { UpdateActivityPlannerComponent } from 'src/app/course-management/activityPlanner/update-activity-planner/update-activity-planner.component';
import { AddActivityVideoComponent } from 'src/app/course-management/activityVideo/add-activity-video/add-activity-video.component';
import { EditActivityVideoComponent } from 'src/app/course-management/activityVideo/edit-activity-video/edit-activity-video.component';
import { AddMainCurrComponent } from 'src/app/course-management/curriculum/add-main-curr/add-main-curr.component';
import { ChangeCurriculumOrderComponent } from 'src/app/course-management/curriculum/change-curriculum-order/change-curriculum-order.component';
import { UpdateMainCurrComponent } from 'src/app/course-management/curriculum/update-main-curr/update-main-curr.component';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { CompleteCurriculum } from 'src/app/courseManagementClasses/complete-curriculum';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SubjectService } from 'src/app/service/subject.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

interface curr {
  curriculumName: string;
  curriculumId : string;
  children?: curr[]; 
}

const TREE_DATA: any[] = [
 {
    name: 'Vegetables',
    children: [{name: 'Green',
                children: [
                            {name: 'Broccoli'},
                            {name: 'Brussels sprouts'},
                          ]
              }
    ]
  },
];

 const currList = [
  {
     name : '',
     id : '',
     children: [{name : '',
                 id : '',
                 children: [{name: '',
                              id : ''}]
               }]
  }];
 

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  curriculumId : string,
  level: number;
}
@Component({
  selector: 'app-super-course-admin-curriculum-dashboard',
  templateUrl: './super-course-admin-curriculum-dashboard.component.html',
  styleUrls: ['./super-course-admin-curriculum-dashboard.component.scss']
})
export class SuperCourseAdminCurriculumDashboardComponent implements OnInit {

  private _transformer = (node: curr, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.curriculumName,
      curriculumId : node.curriculumId,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  showSpinner : boolean = true;
  activityPlannerLoaderMessage!: string;
  activityPlannerExists!: boolean;
  activityPlannerOptions : any;
  activityPlannerLoader = false;
  loggedInUser: any;
  loggedUser: any;
  apafExist = false;
  userName!: string;
  userId!: string;
  asDontExist = false;
  apDontExist : boolean = false;
  apafDontExist : boolean = false;
  oneExist = false;
  twoExist = false;
  threeExist = false;
  mainCurrState : boolean = true;
  mainCurrState2 : boolean = false;
  mainCurrState3 : boolean = false;
  prevParentId : string = '';
  prevParentId2 : string = '';
  currentCurriculum: any;
  curriculumExists = false;
  actionCollapse = false;
  deactivateApId : any;
  
  activityPlannerListWithSafeUrl : any[] =[ { activityPlannerId : '', 
                                              activityPlannerName : '',
                                              activityPlannerDescription : '',
                                              activityPlannerOrder : '',
                                              activityPlannerStatus : '',
                                              fkSubjectId : '',
                                              activityPlannerImage : '',
                                              safeUrl : '' 
                                            } ];
  xyz: any;
  abc: any;
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  // NEW CODE VARIABLES

  centered = false;
  disabled = false;
  unbounded = false;

  radius!: number;
  color!: string;
  closeResult!: string;

 
  constructor(private route : ActivatedRoute, 
              private subjectService : SubjectService,
              private ngbModalService: NgbModal,
              private toaster: ToastrService,
              private curService : CurriculumService,
              private activityPlannerService : ActivityPlannerService,
              public bsModalRef: BsModalRef,
              private logoutService : LogoutService,
              private router : Router, 
              private sharedDataService : SharedDataService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer ) { }

  subId!: string;
  subject : Subject = new Subject();
  CurriculumListBySubId : Curriculum[] = [];
  MainCurrListBySubId : Curriculum[] = [];
  CompCurrListBySubId : CompleteCurriculum[] = [];
  CurriculumId!: string;
  ActivityPlannerList : ActivityPlanner[] = [];
  panelOpenState = false; 
  subCurriculums : Curriculum[] = [];
  subCurriculums2 : Curriculum[] = [];
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  modalRefs: BsModalRef[] = [];
  public screenWidth: any;
  public screenHeight: any;

  showActivities : boolean = true;
  showAssignments = false;
  showParentActivities = false;
  navbarTitle : any;
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  safeImageUrl: any;
  unsafeImageUrl: any;
  infoState : boolean = false;

  activityPlanners2 : any[] =[ {
    activityPlannerId : '', 
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '' ,
    safeUrl : '',
    type : '',
    collapsedState : '',
          
          activityVideoLinkList : [{
                                      actVideoLinkId: "",
                                      actVideoLinkName: "",
                                      actVideoLink: "",
                                      actVideoLinkDescription: "",
                                      fkActivityPlannerId: "",
                                      actVideoLinkStatus: "",
                                      urlSafe: ""
                                    }]
                                            } ];
  activityPlanners : any[] =[ {
                                activityPlannerId : '', 
                                activityPlannerName : '',
                                activityPlannerDescription : '',
                                activityPlannerOrder : '',
                                activityPlannerStatus : '',
                                fkSubjectId : '',
                                activityPlannerImage : '' ,
                                safeUrl : '',
                                type : '',
                                collapsedState : '',
                                      
                                      activityVideoLinkList : [{
                                                                  actVideoLinkId: "",
                                                                  actVideoLinkName: "",
                                                                  actVideoLink: "",
                                                                  actVideoLinkDescription: "",
                                                                  fkActivityPlannerId: "",
                                                                  actVideoLinkStatus: "",
                                                                  urlSafe: ""
                                                                }]
                                                                        } ];

  parentRecurringActivityPlanners : any[] =[ {
    activityPlannerId : '', 
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '' ,
    safeUrl : '',
    type : '',
    collapsedState : '',
          
          activityVideoLinkList : [{
                                      actVideoLinkId: "",
                                      actVideoLinkName: "",
                                      actVideoLink: "",
                                      actVideoLinkDescription: "",
                                      fkActivityPlannerId: "",
                                      actVideoLinkStatus: "",
                                      urlSafe: ""
                                    }]
                                            } ];

 curriculumObject : Curriculum = new Curriculum();
 selected = '1';

assignments : any[] =[ {  activityPlannerId : '', 
                          activityPlannerName : '',
                          activityPlannerDescription : '',
                          activityPlannerOrder : '',
                          activityPlannerStatus : '',
                          fkSubjectId : '',
                          activityPlannerImage : '' ,
                          safeUrl : '',
                          type : '',
                          collapsedState : '',
                          
                          activityVideoLinkList : [{
                                                      actVideoLinkId: "",
                                                      actVideoLinkName: "",
                                                      actVideoLink: "",
                                                      actVideoLinkDescription: "",
                                                      fkActivityPlannerId: "",
                                                      actVideoLinkStatus: "",
                                                      urlSafe: ""
                                                    }]
                                                            }];

 
                                                            curriculum : CompleteCurriculum = new CompleteCurriculum();

  currm : curr[] = [
    {
      curriculumName : '',
      curriculumId : '',
       children: [{curriculumName : '',
                   curriculumId : '',
                   children: [{curriculumName: '',
                               curriculumId : ''}]
                 }]
    }];


  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

      this.activityPlannerExists = true;

      this.subId = this.route.snapshot.params['subjectId'];

      this.subjectService.getSubById(this.subId).subscribe(
        data=>{
          this.subject=data;
        });
  
        this.getCurriculum();
    
  }


 // authorized
  getAssignmentListByCurId(curId : string){
   this.activityPlannerService.getAssignmentListByCurIdForSA(environment.apiUrl + "activityPlanner/getAssignmentListByCurIdForSA/" + curId).then(
     data=>{
              this.assignments = data;   
              

if(this.assignments === undefined || this.assignments.length === 0){

  this.asDontExist = true;

  setTimeout(()=>{                           //timer
    this.showSpinner = false;
}, 1000);


}else{
  this.twoExist = true;
}
              this.assignments.forEach( (value) => {
                          
                this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
                  data=>{
                    this.xyz = data;
                    this.abc = URL.createObjectURL(data);
                    this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

                    value.safeUrl = this.xyz;
                    value.collapsedState = false;
                  });
      
              }); 

              
     });

   }
   valueChange(value : any){

  if (value === '1') {
    this.showSpinner=true;

    this.showAssignments = false;
    this.showParentActivities = false;
    this.showActivities = true;

    this.showSpinner=false;
    
  } else if (value === '2') {

    this.showSpinner=true;

    this.showParentActivities = false;
    this.showActivities = false;
    this.showAssignments = true;


    
    this.showSpinner=false;
    
  } else if (value === '3') {

    this.showSpinner=true;

    this.showActivities = false;
    this.showAssignments = false;
    this.showParentActivities = true;

    
    this.showSpinner=false;
    
  } 

  }
   show(){
     this.showSpinner = true;
     if(this.showActivities === true){
      this.showActivities = false;
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
    }, 1000);
     }else  if(this.showActivities === false){
      this.showActivities = true;
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
    }, 1000);
     }
   }

// authorized
  getDetails(currId : any){
    this.parentRecurringActivityPlanners =[];
    this.currentCurriculum = currId;
    this.showSpinner = true;
    this.apDontExist = false;
    this.asDontExist = false;
    this.curService.getCurrByIdForSA(environment.apiUrl + "curriculum/getCurrByIdForSA/" + currId).then(
      data=>{
        this.curriculumObject = data;
        this.infoState = true;
this.getactivityPlanners(currId);
this.getAssignmentListByCurId(currId);
      });
  }

  // authorized
  getactivityPlanners(currId : any){
    this.activityPlannerService.getActivityPlannerListByCurIdForSA(environment.apiUrl + "activityPlanner/getActivityPlannerListByCurIdForSA/" + currId).then(
      data=>{
        this.apafDontExist = false;
        this.apafExist = false;
        this.activityPlanners = [];
        this.activityPlanners2 = [];

        this.activityPlanners.pop();

        for(var index in data){
          this.activityPlanners.push( {  'activityPlannerId' : data[index].activityPlannerId, 
                                              'activityPlannerName' : data[index].activityPlannerName,
                                              'activityPlannerDescription' : data[index].activityPlannerDescription,
                                              'activityPlannerOrder' : data[index].activityPlannerOrder,
                                              'activityPlannerStatus' : data[index].activityPlannerStatus,
                                              'fkSubjectId' : data[index].fkSubjectId,
                                              'activityPlannerImage' : data[index].activityPlannerImage ,
                                              'safeUrl' : this.safeImageUrl ,
                                              'collapsedState' : false,
                                              'activityVideoLinkList' : data[index].activityVideoLinkList,
                                              'type' : data[index].type
                                                    });
}


this.activityPlanners.forEach( (value) => {
          
  this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
    data=>{
      this.unsafeImageUrl = URL.createObjectURL(data);
      this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);

      value.safeUrl = this.safeImageUrl;
     });

     if(value.activityVideoLinkList === undefined || value.activityVideoLinkList.length === 0){
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
   }, 1000);
     }

     value.activityVideoLinkList.forEach((val : any)=>{
    this.url =  val.actVideoLink;
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    val.urlSafe = this.urlSafe

    setTimeout(()=>{                           //timer
      this.showSpinner = false;
 }, 1000);

  });
   });
   
   
if(this.activityPlanners === undefined || this.activityPlanners.length === 0){

  this.apDontExist = true;
  setTimeout(()=>{                           //timer
    this.showSpinner = false;
}, 1000);
}else {
  this.oneExist = true;
}

let count = 0;

this.activityPlanners.forEach((value2)=>{
  count++;  
  if(value2.type === "parentRecurringActivity"){
    this.parentRecurringActivityPlanners.push(value2);
  }else if(value2.type === "activityPlanner"){
    this.activityPlanners2.push(value2);
  }
})


if(this.activityPlanners === undefined || this.activityPlanners.length === 0){

  this.apDontExist = true;
  
}

if(count === this.activityPlanners.length){
if(this.parentRecurringActivityPlanners === undefined || this.parentRecurringActivityPlanners.length === 0){
     this.apafDontExist = true;
}else {
  this.threeExist = true;
  this.apafExist = true;
}
}

 });

  }

  collapseActivityCardContent(activityPlannerId : string){

    this.activityPlanners.forEach((value)=>{

      if(value.activityPlannerId === activityPlannerId){

        if(value.collapsedState === true){
          value.collapsedState = false
        } else if(value.collapsedState === false){
          value.collapsedState = true
        }

      }
    }); 

  }

  collapseActivityCardContent2(activityPlannerId : string){

  this.assignments.forEach((value)=>{

    if(value.activityPlannerId === activityPlannerId){

      if(value.collapsedState === true){
        value.collapsedState = false;
      } else if(value.collapsedState === false){
        value.collapsedState = true;
      }

    }
  });
}

// authorized
  getCurriculum(){
    this.curService.getSortedCurrListBySubIdForSuperAdmin( environment.apiUrl + "curriculum/getSortedCurrListBySubIdForSuperAdmin/" + this.subId).then(
      data=>{
this.currm= data;
this.dataSource.data = this.currm;
this.curriculumExists = true;
setTimeout(()=>{                           //timer
  this.showSpinner = false;
}, 1000);
            });

            if(this.currm === undefined || this.currm.length === 0){
              setTimeout(()=>{                           //timer
                this.showSpinner = false;
           }, 1000);
            }
  }

 // authorized 
  addNewActPlnr(){
       this.addNewActivityPlanner(this.subId);
  }
  // authorized
  addNewActivityPlanner(subId : string){ 
        this.config.class = 'modal-md';
        const modalRef = this.ngbModalService.open(AddActivityPlannerComponent,
          {
            size : 'lg', 
            scrollable: true,
            windowClass: 'app-modal-window',
            keyboard: false
          });
      
      let data = {
        subId: subId 
        }

      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
          this.getDetails(receivedEntry);
        }); 
    }

//  updateactivityPlannerStatus(activityPlannerId : string){
//      this.activityPlannerService.activityPlannerStatusUpdate(activityPlannerId).subscribe(
//        data=>{
//          this.toaster.success("Activity Planner Status Updated Successfully");
//            });
//   }
 
  // authorized
  changeCurriculumOrder(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ChangeCurriculumOrderComponent,
      {
        size : 'md', 
        scrollable: true,
        windowClass: 'modal-xl',
        keyboard: false
      });
  
  let data = {
    curriculum : this.curriculumObject
    }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityPlanner) => {
    this.getDetails(receivedEntry);
    }); 
   }

// authorized
 editactivityPlanner(activityPlannerId : string){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(UpdateActivityPlannerComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
        keyboard: false
        });

      let data = {
        activityPlannerId: activityPlannerId 
        }

      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityPlanner) => {
      this.getDetails(this.currentCurriculum);
    }); 
  }

   // authorized
  addActivityQuestionsModal(activityPlannerId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddActivityQuestionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: activityPlannerId 
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityPlanner) => {
  }); 
  }

   //authorized
  viewActivityQuestionsModal(activityPlannerId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewActivityQuestionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: activityPlannerId 
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityQuestions) => {
  }); 
  }

 //authorized
   addNewCurr(){
    this.addNewCurr2(this.subId);
  }
   //authorized
    addNewCurr2(subId : string){ 
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddMainCurrComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
  
        let data = {
          subId: subId 
          }
  
        modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Curriculum) => {
        this.getCurriculum();
      }); 
    }

 //authorized
     addSubCurr(curId : string){
      this.addSubCurr2(curId, this.subId)
    }
     //authorized
    addSubCurr2(curId : string, subId : string){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddMainCurrComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      
    let data = {
      subId: subId ,
      curId: curId
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Curriculum) => {
     this.getCurriculum();
     
      });
          
        }

         //authorized
    editCurriculum(curId : string){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(UpdateMainCurrComponent,
        {
          size : 'lg', 
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });

        let data = {
          curId: curId
          }

        modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          })

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {

        
        // this.getCompCurrListBySubId();
        this.getCurriculum();
        this.getDetails(receivedEntry);
      });

  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  

 
    //authorized   
  addVideoModal(activityPlannerId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddActivityVideoComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      activityPlannerId : activityPlannerId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getDetails(this.currentCurriculum);
    });    
  }
   //authorized
  editVideo(videoId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditActivityVideoComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      videoId : videoId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getDetails(this.currentCurriculum);
    });    
  }
  logOut(): void {

    localStorage.removeItem('auth-token');
    localStorage.clear();
    window.location.href=`${environment.authUrl}/logout`;
      
  }

  reloadPage() {
    window.location.reload();
  } 

  open(content: any) {
    this.actionCollapse = false
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  deactivateActivityPlanner(){
    
    this.activityPlannerService.deactivateActivityPlanner(this.deactivateApId).subscribe(
      data=>{
      this.getDetails(this.currentCurriculum);
      this.toaster.success('Activity deactivated successfully');
      });
  }

  deactivateAssignment(){
    
    this.activityPlannerService.deactivateAssignment(this.deactivateApId).subscribe(
      data=>{
      this.getDetails(this.currentCurriculum);
      this.toaster.success('Assignment deactivated successfully');
      });
  }

  deactivateParentPlanner(){

    this.activityPlannerService.deactivateParentPlanner(this.deactivateApId).subscribe(
      data=>{
      this.getDetails(this.currentCurriculum);
      this.toaster.success('Assignment deactivated successfully');
      });
  
  }

  deactivateCurriculum(){
  
    this.curService.deactivateCurriculum(this.deactivateApId).subscribe(
      data=>{
        this.infoState = false;
        this.getCurriculum();
        this.toaster.success('Curriculum deactivated successfully');
      });
  
  }
  
 

}
