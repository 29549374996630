<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="p-4">
   
     
    <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          {{combinedPTC.firstName}}
        </span>

        <a style="cursor:pointer;color: white;margin-left: 1%;" class="float-right" matTooltip="Edit Customer"
        (click)="editCustomer();" >
        <img src="/assets/images/icons/editIcon.svg" width="26" height="26" alt="" loading="lazy">
      </a>

      <a style="cursor:pointer;color: white;" class="float-right" matTooltip="Communications"
      (click)="addNewCustomerCommunication();" >
      <img src="/assets/images/icons/communications.svg" width="26" height="26" alt="" loading="lazy">
    </a>

      </div> 
    </div>
 
    <div class="card">
      <div class="card-body table-responsive">
        
        <div class="col-md-12 row" style="margin: 0%;">

          <div class="content col-md-2" style="margin: 0%;">
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
          
              <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="33%" style="padding: 0%;">
              
                <mat-card class="example-card mat-elevation-z12" style="margin: 0%;padding: 0%;display: flex;
                justify-content: center;
                align-items: center;">
                
    
    
               
   
    
                <div style="padding : 0%">
                  <h2 class="example-h2">Credit Score</h2>
                  <mat-progress-spinner
                      class="example-margin"
                      [color]="color1"
                      [mode]="mode1"
                      [value]="value1">
                  </mat-progress-spinner>
                  <div style="position:relative; top: -60px; left: 45px;">
                    {{value1}}
                </div>
              </div>
            
    
    
                 
              </mat-card>
               
              </div>   
        
              <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="33%" >
          
                <mat-card class="example-card mat-elevation-z12" style="margin: 0%;margin-bottom: 0%;">
          
                  <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >

                    <a style="color: black;" *ngIf="combinedPTC.username!==null"><i class="fa fa-sign-in" aria-hidden="true"> Login Username : {{combinedPTC.username}}</i></a> <br> <br>

                    <a><i class="fa fa-phone" aria-hidden="true"> {{combinedPTC.phoneNumber}}</i></a> <br>
                    <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.email}}</i></a>
                </div>
  
               
                               
                <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
                  <a><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.contactPerson}}</i></a> <br>
                  <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.contactPersonEmail}}</i></a> <br>
                  <a style="word-wrap: break-word;"><i class="fa fa-phone" aria-hidden="true"> {{combinedPTC.contactPersonPhone}}</i></a>
                </div>
  
              
  
                <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
                  <a><i class="fa fa-address-card" aria-hidden="true"> {{combinedPTC.customerAddress}} </i></a> <br>
              </div>
               
              </mat-card>
          
              </div>
          
             
          
            </div>
            </div>
            <div class="content col-md-2" style="margin: 0%;">
              <div fxLayout="row wrap" fxLayoutGap="16px grid">
            
            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="33%" style="padding: 0%;">
            
              <mat-card class="example-card mat-elevation-z12" style="margin: 0%;padding: 0%;display: flex;
              justify-content: center;
              align-items: center;">
              
                  <div style="padding: 0%;">
                    <h2 class="example-h2">Overall Score</h2>
                  <mat-progress-spinner
                      class="example-margin"
                      [color]="color"
                      [mode]="mode"
                      [value]="value">
                  </mat-progress-spinner>
                  <div style="position:relative; top: -60px; left: 45px;">
                    {{value}}
                </div>
              </div>
  
            </mat-card>
             
            </div>

            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="33%" style="padding: 0%;">
            
              <mat-card class="example-card mat-elevation-z12" style="margin: 0%;padding: 0%;display: flex;
              justify-content: center;
              align-items: center;">
              
  
               
                  
           
  
              <div style="padding : 0%">
                <h2 class="example-h2">Sales Score</h2>
                  <mat-progress-spinner
                      class="example-margin"
                      [color]="color0"
                      [mode]="mode0"
                      [value]="value0">
                     
                  </mat-progress-spinner>
                  <div style="position:relative; top: -60px; left: 45px;">
                    {{value0}}
                </div>
              </div> 
  
         
             
  
  
               
            </mat-card>
             
            </div>

              </div>
              </div>
  
                   <div class="content col-md-8" style="margin: 0%;">
            <div fxLayout="row wrap" fxLayoutGap="16px grid">
          
             
        

        
             
            
           <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
    
              <div style="text-align: center;">
                <a> 
                  <img src="/assets/images/icons/period.png" width="45" height="45"
                   alt="" loading="lazy">
                </a>
              </div>
             
                <h2 style="text-align: center;margin: 0%;color: rgb(76, 71, 71);font-size: 1em;">
                  Last Purchase Date
                </h2>
               
                <h2 style="font-size: 2em; text-align: center; color: rgb(76, 71, 71);">
                  {{combinedPTC.customerScoring.lastPurchaseDate}}
                </h2>
            

             

            </mat-card>
      
           
          </div>
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
              <div style="text-align: center;">
                <a> 
                  <img src="/assets/images/icons/category.png" width="45" height="45"
                   alt="" loading="lazy">
                </a>
              </div>
            
                <h2 style="text-align: center;margin: 0%;color: rgb(76, 71, 71);font-size: 1em;">
                  Category
                </h2>
               
                <h2 style="font-size: 2em; text-align: center; color: rgb(76, 71, 71);">
                  {{combinedPTC.customerScoring.segment}}
                </h2>

            </mat-card>
      
           
          </div>
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
              <div style="text-align: center;">
                <a> 
                  <img src="/assets/images/icons/period.png" width="45" height="45"
                   alt="" loading="lazy">
                </a>
              </div>

                <h2 style="text-align: center;margin: 0%;color: rgb(76, 71, 71);font-size: 1em;">
                  Skipped Period
                </h2>
              
                <h2 style="font-size: 2em; text-align: center; color: rgb(76, 71, 71);">
                 {{combinedPTC.customerScoring.skippedPeriod}}
                </h2>                
                
            

             

            </mat-card>
      
           
          </div>  
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
              <div style="text-align: center;">
                <a> 
                  <img src="/assets/images/icons/period.png" width="45" height="45"
                   alt="" loading="lazy">
                </a>
              </div>
            

               
                <h2 style="text-align: center;margin: 0%;color: rgb(76, 71, 71);font-size: 1em;">
                  Credit Period
                </h2>
               
               <h2 style="font-size: 2em; text-align: center; color: rgb(76, 71, 71);">
                  {{combinedPTC.customerScoring.creditPeriod}} days
                </h2>               
 

            </mat-card>
      
           
          </div>  
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
              <div style="text-align: center;">
                <a> 
                  <img src="/assets/images/icons/profit.png" width="45" height="45"
                   alt="" loading="lazy">
                </a>
              </div>
             

               
                <h2 style="text-align: center;margin: 0%;color: rgb(76, 71, 71);font-size: 1em;">
                  Profit
                </h2>
              
                <h2 style="font-size: 2em; text-align: center; color: rgb(76, 71, 71);">
                  <i class="fa fa-inr" aria-hidden="true"></i> {{combinedPTC.customerScoring.profit}}
                </h2>
              
            
                
              

             

            </mat-card>
      
           
          </div>  
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
              <div style="text-align: center;">
                <a> 
                  <img src="/assets/images/icons/debit.png" width="45" height="45"
                   alt="" loading="lazy">
                </a>
              </div>
             

                
                <h2 style="text-align: center;margin: 0%;color: rgb(76, 71, 71);font-size: 1em;">
                  Debit
                </h2>
                
                <h2 style="font-size: 2em; text-align: center; color: rgb(76, 71, 71);">
                  <i class="fa fa-inr" aria-hidden="true"></i> {{combinedPTC.customerScoring.debit}}
                </h2>
            
               

             

            </mat-card>
      
           
          </div>  
         
            </div>
            </div>
          

        </div>
       
      
          <mat-tab-group  style="padding: 1em;" dynamicHeight>
            <mat-tab label="Invoices">   
           
              <br>
      
              <!-- <div class="card"> -->
                <div class="card-body table-responsive">
              <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
              datatable [dtOptions]="invoiceOptions" >
                <thead class="tableheader">
                  <tr>
                    <th>Date</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let subs of invoices">
                    <td class="td-height">{{subs.invoiceDate}}</td>
                    <td class="td-height">{{subs.invoiceRemarks}}</td>
                    <td *ngIf="invoices.length !== 0 && invoices !== undefined">
                       <button style="margin: 1%;" (click)="viewInvoiceDetailsModal(subs.invoiceId)" class="btn btn-dark">View</button>
      
                      </td>
                    
                  
                  </tr>
                </tbody>
              </table> 
                </div>
              <!-- </div> -->
      
          
                  
          </mat-tab>
            
           
               
            <mat-tab label="Shipments" > 
            <br>
      
      
                <!-- <div class="card"> -->
                <div class="card-body table-responsive">
              <table *ngIf="shipmentExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
              datatable [dtOptions]="shipmentOptions" >
                <thead class="tableheader">
                  <tr>
                    <th>Shipment Partner</th>
                    <th>Shipped Date</th>
                    <th>Address</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let subs of shipments">
                    <td class="td-height">{{subs.shipmentPartner}}</td>
                    <td class="td-height">{{subs.shippedDateTime}}</td>
                    <td class="td-height">{{subs.shipmentAddress}}</td>
                    <!-- <td>
                      <button style="margin: 1%;" (click)="editShipment(subs.shipmentId);" class="btn btn-primary">Edit</button>
                    </td> -->
                  
                  </tr>
                </tbody>
              </table> 
                </div>
                <!-- </div> -->
         
            </mat-tab>
      
             
            <mat-tab label="Communications" > 
            <br>
      
      
       <!-- <div class="card"> -->
                <div class="card-body table-responsive">
              <table *ngIf="communicationsExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
              datatable [dtOptions]="communicationsOptions" >
                <thead class="tableheader">
                  <tr>
                    <th>Action date</th>
                    <th>Next Action Date</th>
                    <th>Message</th>
                    <th>Remarks</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let subs of coms">
                    <td class="td-height">{{subs.currentActionDateTime}}</td>
                    <td class="td-height">{{subs.nextActionDateTime}}</td>
                    <td class="td-height">{{subs.message}}</td>
                    <td class="td-height">{{subs.remarks}}</td>
      
                    <!-- <td>
                      <button style="margin: 1%;" (click)="editCommunications(subs);" class="btn btn-primary">Edit</button>
                    </td> -->
                  
                  </tr>
                </tbody>
              </table> 
                </div>
       <!-- </div> -->
              
         
            </mat-tab>
            
            </mat-tab-group>
      </div>
    </div>
  

</div>



  