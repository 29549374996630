export class CustomerScoring {

actualProfit: any;
address: any;
contactNumber: any;
cpPercentile: any;
creditPeriod: any; 
creditWorthiness: any; // credit score
customerName: any;
customerScoreId: any;
customerScoreStatus: any;
debit:any;
debit2: any;
debitPercentile: any; // salesScore
fkOfficeId:any;
fkProductId: any;
interest: any;
lastPurchaseDate: any = new Date();
meanPeriod:any;
overallScore:any; //
profit: any;
profitPercentile: any; //profitScore
profitRatio:any;
profitRatioPercentile: any;
salePrice: any;
segment: any;
skippedPeriod: any;
std: any;


segmentType : any;
segmentTypeCustomerScorings : CustomerScoring[] = [];
}
