<div class="modal-header">
    <h4 class="modal-title pull-left">Edit Communications</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  
   
  
  
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
     
          <form (ngSubmit)="onSubmit" #customerRegisteration="ngForm">   
        
           
              
            <div class="form-group row">

              <div class="col-md-4">
                <label class="required" for="messageType">Type</label> <br>
                    <select required  class="form-control" [(ngModel)]="coms.messageType" id="messageType" name="messageType">
                      <option value="" disabled >Choose Type</option>
                      <option value="Information">Information</option>
                      <option value="Complaint">Complaint</option>
                       <option value="FollowUp">Follow Up</option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label class="required" for="">Next Action Date</label>
                    <input class="form-control"  required [(ngModel)]="coms.nextActionDateTime" type="datetime-local" id="nextActionDateTime" name="nextActionDateTime">
                
                </div>
               
            </div>

            <div>
                <label class="required" for="message">Message</label>
                <textarea  maxlength="5000" placeholder="Enter message" required class="form-control" type="text" name="message"  id="message" [(ngModel)]="coms.message"></textarea>
             </div> 
             
              
             <br>
      
              <div>
                <label for="remarks">Remarks</label>
                <textarea  maxlength="5000" placeholder="Enter remarks" class="form-control" type="text" name="remarks"  id="remarks" [(ngModel)]="coms.remarks"></textarea>
             </div> 
              
                      </form>
  
    </div>
  </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!customerRegisteration.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Update</button>
  </div>
  