<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='addBotApiConfigAndLinkToResponseForOptions'"
    style="color: white;" class="modal-title pull-left">Create Configuration To Get API based options</h4>
    <h4 *ngIf="fromParent.context==='addBotApiConfigAndLinkToResponseForVariables'"
    style="color: white;" class="modal-title pull-left">Create Configuration To Get Variables</h4>
    <h4 *ngIf="fromParent.context==='addBotApiConfigurationForCondtitonalResponses'"
    style="color: white;" class="modal-title pull-left">Create Configuration For Condtitonal Responses</h4>
    <h4 *ngIf="fromParent.context==='addBotApiConfigurationToOptionForCondtitonalResponses'"
    style="color: white;" class="modal-title pull-left">Create Configuration For Condtitonal Responses</h4>
    <h4 *ngIf="fromParent.context==='editBotApiConfiguration'"
    style="color: white;" class="modal-title pull-left">Edit Configuration</h4>
     <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  
  <div class="modal-body">
      <div class="card-body"> 
      <form #botResponseOptionForm="ngForm">  

        <div *ngIf="context==='addBotApiConfigAndLinkToResponseForOptions'">

          <div>
          <label class="required" for="title">Title</label>
          <input  #Title="ngModel" required maxlength="250" class="form-control" type="text" 
           name="title" id="title" [(ngModel)]="botApiConfig.title">
          <div class="alert alert-danger" *ngIf="Title.touched && !Title.valid"> Title required</div>
          </div>
  <br>
          <div>
          <label class="required" for="urlPath">URL Path</label>
          <input  maxlength="250" #urlPath="ngModel" required class="form-control" type="text"
          name="urlPath" id="urlPath" [(ngModel)]="botApiConfig.urlPath">
          <div class="alert alert-danger" *ngIf="urlPath.touched && !urlPath.valid"> Option required</div>
          </div>
<br>

<div>
  <label class="required">HTTP Method Type </label> <br>
  <mat-radio-group name="botApiConfig.httpMethodType" id="httpMethodType" required aria-label="httpMethodType" 
  [(ngModel)]="botApiConfig.httpMethodType">
    <mat-radio-button name="httpMethodType" id="GET" value="GET">GET</mat-radio-button>
    <mat-radio-button name="httpMethodType" id="POST" value="POST">POST</mat-radio-button>
    <mat-radio-button name="httpMethodType" id="PUT" value="PUT">PUT</mat-radio-button>
   </mat-radio-group>
</div> 

<br>
    
           <div *ngIf="botApiConfig.httpMethodType==='POST' || botApiConfig.httpMethodType==='PUT'">
              <label class="required" for="jsonBody">JSON Body</label> <br>
              <textarea minlength="2" [(ngModel)]="botApiConfig.jsonBody" class="form-control" rows="8" 
              required name="jsonBody" id="jsonBody">
              </textarea>
            </div>
            
 <br>

  <button style="width: auto;float: right;" 
  (click)="getBotApiConfigs();linkCntxt='addBotApiConfigAndLinkToResponseForOptions';context='linkExistingAPIConfiguration';botApiConfig.botApiConfigId='';" 
  class="btn btn-info">Use an Existing API Configuration
</button>
        
      </div>

      <div *ngIf="context==='linkExistingAPIConfiguration'">

        <div>
            <label for="botApiConfigId">Configuration</label>
            <select required class="form-control" [(ngModel)]="botApiConfig.botApiConfigId">
              <option value="" disabled>Choose API Configuration</option>
              <option *ngFor="let confg of apiConfigs" [ngValue]="confg.botApiConfigId">{{confg.title}}</option>
            </select>
          </div>

      </div>

      <div *ngIf="context==='addBotApiConfigAndLinkToResponseForVariables'">

        <div>
          <label class="required">API Call To </label> <br>
          <mat-radio-group name="botApiConfig.apiCallTo" id="apiCallTo" required aria-label="apiCallTo" 
          [(ngModel)]="botApiConfig.apiCallTo">
            <mat-radio-button name="apiCallTo" id="chat-gpt" value="chat-gpt">Chat-GPT</mat-radio-button>
            <mat-radio-button name="apiCallTo" id="others" value="others">Others</mat-radio-button>
           </mat-radio-group>
        </div>

        <br>

        <div>
        <label class="required" for="title">Title</label>
        <input  #Title="ngModel" required maxlength="250" class="form-control" type="text" 
         name="title" id="title" [(ngModel)]="botApiConfig.title">
        <div class="alert alert-danger" *ngIf="Title.touched && !Title.valid"> Title required</div>
        </div>
<br *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">
        <div *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">
        <label class="required" for="urlPath">URL Path</label>
        <input  maxlength="250" #urlPath="ngModel" required class="form-control" type="text"
        name="urlPath" id="urlPath" [(ngModel)]="botApiConfig.urlPath">
        <div class="alert alert-danger" *ngIf="urlPath.touched && !urlPath.valid"> Option required</div>
        </div>
       
        <br *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">

        <div *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">
          <label class="required">HTTP Method Type </label> <br>
          <mat-radio-group name="botApiConfig.httpMethodType" id="httpMethodType" required aria-label="httpMethodType" 
          [(ngModel)]="botApiConfig.httpMethodType">
            <mat-radio-button name="httpMethodType" id="GET" value="GET">GET</mat-radio-button>
            <mat-radio-button name="httpMethodType" id="POST" value="POST">POST</mat-radio-button>
            <mat-radio-button name="httpMethodType" id="PUT" value="PUT">PUT</mat-radio-button>
           </mat-radio-group>
        </div> 
        
        <br>
            
          <div *ngIf="botApiConfig.httpMethodType==='POST' && botApiConfig.apiCallTo!=='chat-gpt'
           || botApiConfig.httpMethodType==='PUT' && botApiConfig.apiCallTo!=='chat-gpt'">
            <label class="required" for="jsonBody">JSON Body</label> <br>
            <textarea minlength="2" [(ngModel)]="botApiConfig.jsonBody" class="form-control" rows="8" 
            required name="jsonBody" id="jsonBody">
            </textarea>
          </div>

          <div *ngIf="botApiConfig.apiCallTo==='chat-gpt'">
            <label class="required" for="context">Context</label> <br>
            <textarea minlength="2" [(ngModel)]="botApiConfig.context" class="form-control" rows="10" 
            required name="context" id="context">
            </textarea>
          </div>


          <br>

          <button style="width: auto;float: right;" 
          (click)="getBotApiConfigs();linkCntxt='addBotApiConfigAndLinkToResponseForVariables';context='linkExistingAPIConfiguration';botApiConfig.botApiConfigId='';" 
          class="btn btn-info">Use an Existing API Configuration
        </button>
                    
         
      
    </div>

    
    <div *ngIf="context==='editBotApiConfiguration'">

      <div>
        <label class="required">API Call To </label> <br>
        <mat-radio-group name="botApiConfig.apiCallTo" id="apiCallTo" required aria-label="apiCallTo" 
        [(ngModel)]="botApiConfig.apiCallTo">
          <mat-radio-button name="apiCallTo" id="chat-gpt" value="chat-gpt">Chat-GPT</mat-radio-button>
          <mat-radio-button name="apiCallTo" id="others" value="others">Others</mat-radio-button>
         </mat-radio-group>
      </div>

      <br>

      <div>
      <label class="required" for="title">Title</label>
      <input  #Title="ngModel" required maxlength="250" class="form-control" type="text" 
       name="title" id="title" [(ngModel)]="botApiConfig.title">
      <div class="alert alert-danger" *ngIf="Title.touched && !Title.valid"> Title required</div>
      </div>

<br *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">
      <div *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">
      <label class="required" for="urlPath">URL Path</label>
      <input  maxlength="250" #urlPath="ngModel" required class="form-control" type="text"
      name="urlPath" id="urlPath" [(ngModel)]="botApiConfig.urlPath">
      <div class="alert alert-danger" *ngIf="urlPath.touched && !urlPath.valid"> Option required</div>
      </div>
     
      <br *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">

      <div *ngIf="botApiConfig.apiCallTo!=='chat-gpt'">
        <label class="required">HTTP Method Type </label> <br>
        <mat-radio-group name="botApiConfig.httpMethodType" id="httpMethodType" required aria-label="httpMethodType" 
        [(ngModel)]="botApiConfig.httpMethodType">
          <mat-radio-button name="httpMethodType" id="GET" value="GET">GET</mat-radio-button>
          <mat-radio-button name="httpMethodType" id="POST" value="POST">POST</mat-radio-button>
          <mat-radio-button name="httpMethodType" id="PUT" value="PUT">PUT</mat-radio-button>
         </mat-radio-group>
      </div> 
      
      <br>
          
        <div *ngIf="botApiConfig.httpMethodType==='POST' && botApiConfig.apiCallTo!=='chat-gpt'
         || botApiConfig.httpMethodType==='PUT' && botApiConfig.apiCallTo!=='chat-gpt'">
          <label class="required" for="jsonBody">JSON Body</label> <br>
          <textarea minlength="2" [(ngModel)]="botApiConfig.jsonBody" class="form-control" rows="8" 
          required name="jsonBody" id="jsonBody">
          </textarea>
        </div>

        <div *ngIf="botApiConfig.apiCallTo==='chat-gpt'">
          <label class="required" for="context">Context</label> <br>
          <textarea minlength="2" [(ngModel)]="botApiConfig.context" class="form-control" rows="10" 
          required name="context" id="context">
          </textarea>
        </div>
                  
      
    
  </div>
    
    <div *ngIf="context==='addBotApiConfigurationForCondtitonalResponses'">

      <div>
      <label class="required" for="title">Title</label>
      <input  #Title="ngModel" required maxlength="250" class="form-control" type="text" 
       name="title" id="title" [(ngModel)]="botApiConfig.title">
      <div class="alert alert-danger" *ngIf="Title.touched && !Title.valid"> Title required</div>
      </div>
<br>
      <div>
      <label class="required" for="urlPath">URL Path</label>
      <input  maxlength="250" #urlPath="ngModel" required class="form-control" type="text"
      name="urlPath" id="urlPath" [(ngModel)]="botApiConfig.urlPath">
      <div class="alert alert-danger" *ngIf="urlPath.touched && !urlPath.valid"> Option required</div>
      </div>
      <br>

      <div>
        <label class="required">HTTP Method Type </label> <br>
        <mat-radio-group name="botApiConfig.httpMethodType" id="httpMethodType" required aria-label="httpMethodType" 
        [(ngModel)]="botApiConfig.httpMethodType">
          <mat-radio-button name="httpMethodType" id="GET" value="GET">GET</mat-radio-button>
          <mat-radio-button name="httpMethodType" id="POST" value="POST">POST</mat-radio-button>
          <mat-radio-button name="httpMethodType" id="PUT" value="PUT">PUT</mat-radio-button>
         </mat-radio-group>
      </div> 
      
      <br>
          
                 <div *ngIf="botApiConfig.httpMethodType==='POST' || botApiConfig.httpMethodType==='PUT'">
                    <label class="required" for="jsonBody">JSON Body</label> <br>
                    <textarea minlength="2" [(ngModel)]="botApiConfig.jsonBody" class="form-control" rows="8" 
                    required name="jsonBody" id="jsonBody">
                    </textarea>
                  </div>
                  
       <br>
    
  </div>

  <div *ngIf="context==='addBotApiConfigurationToOptionForCondtitonalResponses'">

    <div>
    <label class="required" for="title">Title</label>
    <input  #Title="ngModel" required maxlength="250" class="form-control" type="text" 
     name="title" id="title" [(ngModel)]="botApiConfig.title">
    <div class="alert alert-danger" *ngIf="Title.touched && !Title.valid"> Title required</div>
    </div>
<br>
    <div>
    <label class="required" for="urlPath">URL Path</label>
    <input  maxlength="250" #urlPath="ngModel" required class="form-control" type="text"
    name="urlPath" id="urlPath" [(ngModel)]="botApiConfig.urlPath">
    <div class="alert alert-danger" *ngIf="urlPath.touched && !urlPath.valid"> Option required</div>
    </div>
    <br>

    <div>
      <label class="required">HTTP Method Type </label> <br>
      <mat-radio-group name="botApiConfig.httpMethodType" id="httpMethodType" required aria-label="httpMethodType" 
      [(ngModel)]="botApiConfig.httpMethodType">
        <mat-radio-button name="httpMethodType" id="GET" value="GET">GET</mat-radio-button>
        <mat-radio-button name="httpMethodType" id="POST" value="POST">POST</mat-radio-button>
        <mat-radio-button name="httpMethodType" id="PUT" value="PUT">PUT</mat-radio-button>
       </mat-radio-group>
    </div> 
    
    <br>
        
               <div *ngIf="botApiConfig.httpMethodType==='POST' || botApiConfig.httpMethodType==='PUT'">
                  <label class="required" for="jsonBody">JSON Body</label> <br>
                  <textarea minlength="2" [(ngModel)]="botApiConfig.jsonBody" class="form-control" rows="8" 
                  required name="jsonBody" id="jsonBody">
                  </textarea>
                </div>
                
     <br>
  
</div>

 
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="context==='addBotApiConfigAndLinkToResponseForOptions'" (click)="addBotApiConfigAndLinkToResponseForOptions();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="context==='addBotApiConfigAndLinkToResponseForVariables'" (click)="addBotApiConfigAndLinkToResponseForVariables();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="context==='addBotApiConfigurationForCondtitonalResponses'" (click)="addBotApiConfigurationForCondtitonalResponses();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="context==='addBotApiConfigurationToOptionForCondtitonalResponses'" (click)="addBotApiConfigurationToOptionForCondtitonalResponses();">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="context==='linkExistingAPIConfiguration'" (click)="linkExistingAPIConfiguration(); clicked=true">Save</button>
<button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="context==='editBotApiConfiguration'" (click)="editBotApiConfiguration(); clicked=true">Update</button>
</div>
