
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Subscriptions</li>
    </ol>
  </nav>





















  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">


<div fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
<mat-card (click)="open(searchSubscriptionsModal);" style="cursor: pointer;" class="example-card mat-elevation-z12" >
<div class="card-icon" >
<a> 
<img src="/assets/images/icons/calendar.png" width="70" height="70"
alt="" loading="lazy">
</a>
</div>
<br>
<mat-card-subtitle style="margin-bottom:6%">Search By Period</mat-card-subtitle> 
</mat-card>
</div>

<div fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
  <mat-card (click)="open(searchExpiringSubscriptionsModal);" style="cursor: pointer;" class="example-card mat-elevation-z12" >
  <div class="card-icon" >
  <a> 
  <img src="/assets/images/icons/calendarExp.png" width="70" height="70"
  alt="" loading="lazy">
  </a>
  </div>
  <br>
  <mat-card-subtitle style="margin-bottom:6%">Search Expiring</mat-card-subtitle> 
  </mat-card>
  </div>

<div fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
  <mat-card (click)="open(searchCustomerSubscriptionsModal);" style="cursor: pointer;" class="example-card mat-elevation-z12" >
  <div class="card-icon" >
  <a> 
  <img src="/assets/images/icons/user.png" width="70" height="70"
  alt="" loading="lazy">
  </a>
  </div>
  <br>
  <mat-card-subtitle style="margin-bottom:6%">Search By User</mat-card-subtitle> 
  </mat-card>
  </div>

<!-- DO NOT DELETE, HIDDEN FOR NOW -->
<!-- <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
    <mat-card (click)="open(searchUserModal);" style="cursor: pointer;" class="example-card mat-elevation-z12" >
    <div class="card-icon" >
    <a> 
    <img src="/assets/images/icons/plus.png" width="70" height="70"
    alt="" loading="lazy">
    </a>
    </div>
    <br>
    <mat-card-subtitle style="margin-bottom:6%">Add New Subscriptions</mat-card-subtitle> 
    </mat-card>
    </div> -->

    <div fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
      <mat-card (click)="getProductWiseSubscriptionsAboutToEnd();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
      <div class="card-icon" >
      <a> 
      <img src="/assets/images/icons/dueToday.png" width="70" height="70"
      alt="" loading="lazy">
      </a>
      </div>
      <br>
      <mat-card-subtitle style="margin-bottom:6%">Expiring in a week</mat-card-subtitle> 
      </mat-card>
      </div>

<div fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
<mat-card style="cursor: pointer;" (click)="filterSubscriptions('All Subscriptions');" class="example-card mat-elevation-z12" >
<div class="card-icon" >
<a> 
<img src="/assets/images/icons/subscription.svg" width="70" height="70"
alt="" loading="lazy">
</a>
</div>
<br>
<mat-card-subtitle style="margin-bottom:6%">Show All [{{totalCount}}]</mat-card-subtitle> 
<!-- <mat-card-subtitle class="click" (click)="filterSubscriptions('All Subscriptions');"></mat-card-subtitle> -->
</mat-card>
</div>

<div *ngIf="viewProducts===false" fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
  <mat-card style="cursor: pointer;" (click)="viewProducts=!viewProducts" class="example-card mat-elevation-z12" >
  <div class="card-icon" >
  <a> 
  <img src="/assets/images/icons/eye.png" width="70" height="70"
  alt="" loading="lazy">
  </a>
  </div>
  <br>
  <mat-card-subtitle style="margin-bottom:6%">Filter By Products</mat-card-subtitle> 
  <!-- <mat-card-subtitle ></mat-card-subtitle> -->
  </mat-card>
  </div>

  <div *ngIf="viewProducts===true" fxFlex="16.666%" fxFlex.xs="100%" fxFlex.sm="100%" >
    <mat-card style="cursor: pointer;" (click)="viewProducts=!viewProducts" class="example-card mat-elevation-z12" >
    <div class="card-icon" >
    <a> 
    <img src="/assets/images/icons/eye.png" width="70" height="70"
    alt="" loading="lazy">
    </a>
    </div>
    <br>
    <mat-card-subtitle style="margin-bottom:6%">Hide Products</mat-card-subtitle> 
    <!-- <mat-card-subtitle></mat-card-subtitle> -->
    </mat-card>
    </div>



    </div>
    </div>


    <div *ngIf="viewProducts===true" class="content">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div *ngFor="let prod of productBasedSubscriptions" fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
          <mat-card  class="example-card mat-elevation-z12" >
            <div class="card-icon" >
              <a *ngIf="prod.productImage!=='defaultImage.jpg'"> 
                <img [src]="prod.safeUrl" width="70" height="70" style="border-radius: 50%;"
                 alt="" loading="lazy">
              </a>
              <a *ngIf="prod.productImage==='defaultImage.jpg'">
                  <img [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" 
                   width="70" height="70" style="border-radius: 50%;"
                 alt="" loading="lazy">
              </a>
            </div>
             <br>
             <mat-card-subtitle style="margin-bottom:6%">{{prod.productName}}</mat-card-subtitle> 
             <mat-card-subtitle class="click" (click)="filterSubscriptions(prod.productId);">[{{prod.prodSubsCount}}]</mat-card-subtitle>
        </mat-card>
        </div>
      </div>
    </div>





    <div class="p-4">
   
     
        <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
             Subscriptions : {{status}}
            </span>

            <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
              <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
            </a>
            
            <!-- <button hidden style="margin-left: 56rem;" (click)="open(content)" mat-flat-button color="primary">Convert to Customer</button>

            <a style="cursor:pointer;" class="float-right" (click)="openEmailCheckModalComponent();">
              <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
            </a> -->

          </div> 
        </div>

        <div class="card">
          <div class="card-body table-responsive">
            
            <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="customerOptions" >
              <thead class="tableheader">
                <tr>
                  <th style="text-align: left;">Name</th> 
                  <th style="text-align: left;">Email Id</th>
                  <th>Phone</th>
                  <th>Product</th>
                  <th>From Date</th>
                  <th>To Date</th>

                 
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of subs">
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.userName}}</td>
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.userEmail}}</td>
                  <td class="td-height">{{ccd.userPhone}}</td>
                  <td class="td-height">{{ccd.productName}}</td>
                  <td class="td-height">{{ccd.startedDate}}</td>
                  <td class="td-height">{{ccd.endedDate}}</td>

                 

              </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>













  <ng-template #searchUserModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search User to Add Subscription</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form *ngIf="searchInput===true">
    <div class="col-md-12">
    <input class="col-md-12 form-control" type="text" [(ngModel)]="searchValue" 
    placeholder="Enter first name/last name/email/phone" name="search">
  <br>
    <div style="text-align:center;">
    <button style="width: auto;color: black;" (click)="searchFoUsers();" class="btn btn-info">
      Search
      <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
      width="27" height="27" alt="" loading="lazy">
    
    </button>
  </div>
  </div>
    </form>
    <div *ngIf="searchInput===false">
      <!-- <div *ngFor="let user of users">
        <a  style="color: black; background-color: white;border: white;"> 
          {{user.firstName}} {{user.lastName}} 
          <img src="/assets/images/icons/add.svg" 
          width="25" height="25" alt="" loading="lazy">
        </a> 
      
      </div> -->
      <button style="width: auto;color: black;margin-bottom: 5%;float: right;" (click)="searchInput=!searchInput;" class="btn btn-info">
        Search again
        <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
        width="27" height="27" alt="" loading="lazy">
      </button>


      <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" >
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>
              {{user.firstName}} {{user.lastName}} 
            </td>
            <td>
              {{user.email}}
            </td>
            <td>
              <img (click)="addNewSubscription(user.userId, user.firstName, user.lastName);"
               src="/assets/images/icons/add.svg" 
          width="25" height="25" alt="" loading="lazy">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
 
    </div>
  </ng-template>




  <ng-template #searchSubscriptionsModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search Subscriptions</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form #adminForm="ngForm">
    <div class="form-group row">
      <div class="col-md-6">
        <label for="subscription.startedDate">Start Date</label>
        <input required class="form-control" id="subscriptionForSearch.startedDate" name="subscriptionForSearch.startedDate" [(ngModel)]="subscriptionForSearch.startedDate" type="date">
      </div>

      <div class="col-md-6">
        <label for="">End Date</label>
        <input required class="form-control" id="subscriptionForSearch.endedDate" name="subscriptionForSearch.endedDate" [(ngModel)]="subscriptionForSearch.endedDate" type="date">
      </div>
    </div>

    </form>
   
 
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!adminForm.valid" 
      class="btn btn-sm btn-primary float-right" 
      (click)="searchSubscriptions();modal.dismiss('Cross click')">Search</button>
    </div>
  </ng-template>





  <ng-template #searchExpiringSubscriptionsModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search Expiring Subscriptions</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form #adminForm="ngForm">
    <div class="form-group row">
      <div class="col-md-6">
        <label for="subscription.startedDate">Start Date</label>
        <input required class="form-control" id="subscriptionForSearch.startedDate" name="subscriptionForSearch.startedDate" [(ngModel)]="subscriptionForSearch.startedDate" type="date">
      </div>

      <div class="col-md-6">
        <label for="">End Date</label>
        <input required class="form-control" id="subscriptionForSearch.endedDate" name="subscriptionForSearch.endedDate" [(ngModel)]="subscriptionForSearch.endedDate" type="date">
      </div>
    </div>

    </form>
   
 
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!adminForm.valid" 
      class="btn btn-sm btn-primary float-right" 
      (click)="getExpiringSubscriptionsByPeriod();modal.dismiss('Cross click')">Search</button>
    </div>
  </ng-template>













  <ng-template #searchCustomerSubscriptionsModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search Customer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form *ngIf="searchInputCustomerSubscription===true" #paymentForm="ngForm">
    <div class="col-md-12">
    <input class="col-md-12 form-control" required type="text" [(ngModel)]="searchValueCustomerSubscription" 
    placeholder="Enter first name / last name / email / phone" name="search">
  <br>
    <div style="text-align:center;">
    <button  [disabled]="!paymentForm.valid"  style="width: auto;color: black;"
     (click)="searchCustomerSubscriptionsByCustomer();" class="btn btn-info">
      Search Customer
      <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
      width="27" height="27" alt="" loading="lazy">
    
    </button>
  </div>
  </div>
    </form>
    <div *ngIf="searchInputCustomerSubscription===false">
      
      <button style="width: auto;color: black;" (click)="searchInputCustomerSubscription=!searchInputCustomerSubscription;" class="btn btn-info">
        Search
        <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
        width="27" height="27" alt="" loading="lazy">
      </button>
<br>
<br>

<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" >
  <thead>
    <tr>
      <th>Customer</th>
      <th>Email</th>
      <th> Number</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let cust of ccdetails2">
      <td>
        {{cust.firstName}} {{cust.lastName}}
      </td>
      <td>
        {{cust.email}}
            </td>
      <td>
        {{cust.phoneNumber}}
            </td>
      <td>
        <button style="width: auto;" (click)="getSubscriptionsByCustomerUserId(cust.userId);modal.dismiss('Cross click');" 
        class="btn btn-info">Get Subscriptions</button>
      </td>
    </tr>
  </tbody>
</table>

     
    
    </div>
 
    </div>
  </ng-template>