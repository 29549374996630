import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { GlobalClass } from 'src/app/global/global-class';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-edit-combined-parent-teacher',
  templateUrl: './edit-combined-parent-teacher.component.html',
  styleUrls: ['./edit-combined-parent-teacher.component.scss']
})
export class EditCombinedParentTeacherComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  constructor(private combinedPTCService : CombinedParentTeacherService,private sharedDataService : SharedDataService,
    public activeModal: NgbActiveModal,private toaster: ToastrService) { }

  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  clicked=false;
  parentUserId!: string; 

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  ngOnInit(): void {

    this.parentUserId = this.fromParent.parentUserId;   
    this.combinedPTCService.getCombinedDetailsForEdit(this.parentUserId).subscribe(
      data=>{
        this.combinedPTC = data;
      });   
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
    }
  
    getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
  
    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }

  onUpdate(){ 

    this.combinedPTC.fkUserId = this.parentUserId;

    this.combinedPTCService.updateCombinedParentTeacher(this.combinedPTC).subscribe(
      data=>{
        this.passEntry.emit(this.combinedPTC);
        this.activeModal.close();
        this.toaster.success('Details Updated');
      }
    )
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
