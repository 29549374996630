import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Settings } from 'src/app/models/settings';
import { LinkDefinitionService } from 'src/app/services/linkDefinition/link-definition.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-office-link-definitions',
  templateUrl: './edit-office-link-definitions.component.html',
  styleUrls: ['./edit-office-link-definitions.component.scss']
})
export class EditOfficeLinkDefinitionsComponent implements OnInit {

  linkDefinitions : any[] = [{
    activeStatus : '',
feature:  '',
id:  '',
link:  '',
roleName:  '',
subFeature: ''
  }]

  offices : any[] = [];
 
  linkDef : any= {
    activeStatus:'',
displayName: '',
displayStatus: '',
fkLinkSecurityDefinitionId: '',
id: '',
officeId: '',
officeName:''
  }

  roles : any;
  clicked = false;
  setting : Settings = new Settings();

  constructor(private settingsService : SettingsService,
    private ldService : LinkDefinitionService,
    private toastr : ToastrService,
    private activeModal : NgbActiveModal) { }

    
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

this.linkDef = this.fromParent.linkDef;
   
  }

  add(){

  this.ldService.addLinkDefinition(environment.apiUrl + "linkDefinition/updateUroLinkDefinition", this.linkDef).then(
    data=>{
this.activeModal.close();
this.passEntry.emit("success");
this.toastr.success("Link definition added successfully")
    }
  )

  }

  close(){
    this.activeModal.close();
  }

}


