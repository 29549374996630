














<div style="width: 100%; height: 100%; display: block;"
 [style.background-image]="'url(/assets/images/outerBg.png'" 
 style="background-position: right; background-repeat: no-repeat; background-size: cover;">


<div class="login-wrap"  
style="background-position: center; background-repeat: no-repeat; background-size: cover;" >

	<div class="login-html">

    <div style="text-align: center;">
      <img style="border-radius: 50%;" height="100" width="100" class="logo-image"
        src="/assets/images/logo.png" />
    </div>

    <br>
    <br>
		<input id="tab-1"  type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">Reset Password</label>
		<input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab"></label>
		<div class="login-form">
			<div class="sign-in-htm">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm)" class="customerRegisteration-form">

				<div class="group">
					<label for="user" style="color: white; font-weight: bold;font-size: 12px;"  class="label">New Password</label>
          <input type="password" required name="password" id="password" class="input"
            formControlName="password"/>
				</div>
				<!-- <div class="group">
					<label for="pass" style="color: white; font-weight: bold;font-size: 12px;"  class="label">Confirm New Password</label>
          <input type="password" required name="confirmPassword" id="confirmPassword" class="form-control input"
                  placeholder="Confirm New Password" formControlName="confirmPassword" />		
      	</div> -->
        
			
        <br>
        <br>
				<div class="group">
					<input type="submit" style="background-color: rgb(9, 9, 104);" (ngSubmit)="resetPassword(resetPasswordForm)" class="button" value="Submit">
         
				</div>
      </form>
			
       
			</div>
			
	</div>
</div>
</div>

</div>