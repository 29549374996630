import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalClass } from "src/app/global/global-class";
import { FileServiceService } from "src/app/service/file-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { FormControl } from "@angular/forms";
import { ExtShopServiceService } from "src/app/BOT_CODE_EXT_SHOP/bot_service/ext-shop-service.service";
import { BotCategoryExt } from "src/app/BOT_CODE_EXT_SHOP/bot_classes/bot-category-ext";
import { BotItemExt } from "src/app/BOT_CODE_EXT_SHOP/bot_classes/bot-item-ext";
import { BotInvoiceExt } from "src/app/BOT_CODE_EXT_SHOP/bot_classes/bot-invoice-ext";
import { BotUserLocation } from "src/app/BOT_CODE/bot_classes/bot-user-location";

@Component({
  selector: "app-ext-bot-shopping-page",
  templateUrl: "./ext-bot-shopping-page.component.html",
  styleUrls: ["./ext-bot-shopping-page.component.scss"],
})
export class ExtBotShoppingPageComponent implements OnInit {
  showSpinner = true;
  navbarTitle: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  viewCart = false;

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private extShopService: ExtShopServiceService,
    private imageService: FileServiceService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  allCategories: BotCategoryExt[] = [];

  categories: BotCategoryExt[] = [];
  items: BotItemExt[] = [];
  cartItems: BotItemExt[] = [];

  botInvoiceId: any;

  toppingsControl = new FormControl([]);
  totalNumberOfItemsInCart = 0;

  dataLoaded = false;
  orderDeleted = true;
  newOrder = false;

  orderStatus: any;

  pageFunction = "place order";
  linkType: any = "";
  botUserLocations: BotUserLocation[] = [];

  logo: any = null;
  officeName: any;
  loadLogo = false;

  ngOnInit(): void {
    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.botInvoiceId = this.route.snapshot.params["botInvoiceId"];

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.extShopService
      .getBotCategoriesAndItemsForShop(this.botInvoiceId)
      .subscribe((res) => {
        let resObj: BotCategoryExt = new BotCategoryExt();
        resObj = res;

        this.botUserLocations = resObj.botUserLocations;
        if (
          this.botUserLocations !== null &&
          this.botUserLocations !== undefined &&
          this.botUserLocations.length > 0
        ) {
          this.botUserLocations.forEach((userLoc) => {
            if (
              resObj.fkBotUserLocationId !== null &&
              resObj.fkBotUserLocationId !== undefined &&
              resObj.fkBotUserLocationId === userLoc.botUserLocationId
            ) {
              userLoc.checked = true;
            } else {
              userLoc.checked = false;
            }
          });
        }

        this.linkType = resObj.invoiceLinkType;

        this.orderStatus = resObj.invoiceOrderStatus;
        this.orderDeleted = resObj.orderDeleted;

        if (
          resObj.orderDeleted === false &&
          resObj.invoiceOrderStatus === null
        ) {
          this.officeName = resObj.officeName;
          this.logo = resObj.logo;
          this.loadLogo = true;

          this.allCategories = resObj.list;
          this.categories = resObj.list;
          this.items = resObj.itemList;

          let catgIds: any[] = [];
          catgIds.push("selected all");
          this.allCategories.forEach((catg) => {
            catg.panelOpenState = true;
            catg.categoryTotalNoOfItemsAddedInCart = 0;
            catgIds.push(catg.botCategoryId);
          });
          this.toppingsControl.setValue(catgIds);

          this.items.forEach((item) => {
            item.quantityAddedToCart = 0;
            this.imageService
              .getBotItemImage(item.botItemImage)
              .subscribe((data) => {
                let unsafeUrl = URL.createObjectURL(data);
                item.safeImageUrl =
                  this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
              });
          });

          this.categories.forEach((catg) => {
            catg.panelOpenState = true;
            catg.categoryTotalNoOfItemsAddedInCart = 0;
            catg.itemList = [];
            this.items.forEach((item) => {
              if (item.fkBotCategoryId === catg.botCategoryId) {
                catg.itemList.push(item);
              }
            });
          });

          this.newOrder = true;
          this.showSpinner = false;
          this.dataLoaded = true;
        } else if (
          resObj.orderDeleted === false &&
          resObj.invoiceOrderStatus === "order placed"
        ) {
          this.officeName = resObj.officeName;
          this.logo = resObj.logo;
          this.loadLogo = true;

          this.allCategories = resObj.list;
          this.categories = resObj.list;
          this.items = resObj.itemList;

          let catgIds: any[] = [];
          catgIds.push("selected all");
          this.allCategories.forEach((catg) => {
            catg.panelOpenState = true;
            catg.categoryTotalNoOfItemsAddedInCart = 0;
            catgIds.push(catg.botCategoryId);
          });
          this.toppingsControl.setValue(catgIds);

          this.items.forEach((item) => {
            item.quantityAddedToCart = 0;
            this.imageService
              .getBotItemImage(item.botItemImage)
              .subscribe((data) => {
                let unsafeUrl = URL.createObjectURL(data);
                item.safeImageUrl =
                  this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
              });
          });

          this.categories.forEach((catg) => {
            catg.panelOpenState = true;
            catg.categoryTotalNoOfItemsAddedInCart = 0;
            catg.itemList = [];
            this.items.forEach((item) => {
              if (item.fkBotCategoryId === catg.botCategoryId) {
                catg.itemList.push(item);
              }
            });
          });

          //
          this.categories.forEach((catg) => {
            catg.itemList.forEach((item) => {
              resObj.invoiceDetails.forEach((invDet) => {
                if (item.botItemId === invDet.fkBotItemId) {
                  for (let index = 0; index < invDet.itemQuantity; index++) {
                    this.incrementItemQuantityAddedToCart(item);
                  }
                }
              });
            });
          });
          //

          this.showSpinner = false;
          this.dataLoaded = true;
        } else if (
          (resObj.orderDeleted === false &&
            resObj.invoiceOrderStatus === "order processed") ||
          (resObj.orderDeleted === false &&
            resObj.invoiceOrderStatus === "order cancelled")
        ) {
          this.officeName = resObj.officeName;
          this.logo = resObj.logo;
          this.loadLogo = true;

          this.showSpinner = false;
          this.dataLoaded = true;
        } else if (resObj.orderDeleted === true) {
          this.toaster.error(
            "Link expired ! Please use Zoti to get a new link"
          );
          this.showSpinner = false;
          this.dataLoaded = true;
        }
        this.orderLoaded = true;
      });
  }

  radioChange(id: any) {
    this.botUserLocations.forEach((userLoc) => {
      if (userLoc.botUserLocationId === id) {
        userLoc.checked = true;
      } else {
        userLoc.checked = false;
      }
    });
  }

  incrementItemQuantityAddedToCart(item: BotItemExt) {
    this.totalNumberOfItemsInCart = this.totalNumberOfItemsInCart + 1;

    item.quantityAddedToCart = item.quantityAddedToCart + 1;
    item.totalPriceforQty = item.quantityAddedToCart * item.pricePerItem;

    this.categories.forEach((category) => {
      category.headerInfo = "";
      category.categoryTotalPriceOfItemsInCart = 0;
      category.categoryTotalNoOfItemsAddedInCart = 0;
      category.itemList.forEach((item) => {
        if (item.quantityAddedToCart > 0) {
          if (category.headerInfo === "") {
            category.headerInfo =
              // item.botItemName + " - " + item.quantityAddedToCart;
              item.botItemName + "(" + item.quantityAddedToCart + ") ";
          } else {
            category.headerInfo =
              category.headerInfo +
              ", " +
              item.botItemName +
              // " - " +
              // item.quantityAddedToCart;
              "(" +
              item.quantityAddedToCart +
              ") ";
          }
          category.categoryTotalPriceOfItemsInCart =
            category.categoryTotalPriceOfItemsInCart + item.totalPriceforQty;
          category.categoryTotalNoOfItemsAddedInCart =
            category.categoryTotalNoOfItemsAddedInCart +
            item.quantityAddedToCart;
        }
      });
    });

    //
    this.cartItems = [];
    this.totalCartSum = 0;

    this.allCategories.forEach((categ) => {
      if (categ.itemList !== null && categ.itemList.length > 0) {
        categ.itemList.forEach((item) => {
          if (item.quantityAddedToCart > 0) {
            this.cartItems.push(item);
            this.totalCartSum = this.totalCartSum + item.totalPriceforQty;
          }
        });
      }
    });
    //
  }

  decrementItemQuantityAddedToCart(item: BotItemExt) {
    this.totalNumberOfItemsInCart = this.totalNumberOfItemsInCart - 1;

    item.quantityAddedToCart = item.quantityAddedToCart - 1;
    if (item.quantityAddedToCart === 0) {
      item.totalPriceforQty = 0;
    } else {
      item.totalPriceforQty = item.quantityAddedToCart * item.pricePerItem;
    }

    this.categories.forEach((category) => {
      category.headerInfo = "";
      category.categoryTotalPriceOfItemsInCart = 0;
      category.categoryTotalNoOfItemsAddedInCart = 0;
      category.itemList.forEach((item) => {
        if (item.quantityAddedToCart > 0) {
          if (category.headerInfo === "") {
            category.headerInfo =
              // item.botItemName + " - " + item.quantityAddedToCart;
              item.botItemName + "(" + item.quantityAddedToCart + ") ";
          } else {
            category.headerInfo =
              category.headerInfo +
              ", " +
              item.botItemName +
              // " - " +
              // item.quantityAddedToCart;
              "(" +
              item.quantityAddedToCart +
              ") ";
          }
          category.categoryTotalPriceOfItemsInCart =
            category.categoryTotalPriceOfItemsInCart + item.totalPriceforQty;
          category.categoryTotalNoOfItemsAddedInCart =
            category.categoryTotalNoOfItemsAddedInCart +
            item.quantityAddedToCart;
        }
      });
    });

    if (this.totalNumberOfItemsInCart === 0) {
      this.viewCart = false;
    }

    //
    this.cartItems = [];
    this.totalCartSum = 0;

    this.allCategories.forEach((categ) => {
      if (categ.itemList !== null && categ.itemList.length > 0) {
        categ.itemList.forEach((item) => {
          if (item.quantityAddedToCart > 0) {
            this.cartItems.push(item);
            this.totalCartSum = this.totalCartSum + item.totalPriceforQty;
          }
        });
      }
    });
    //
  }

  closeWindow() {
    let customWindow: any = window.open("", "_blank", "");
    customWindow.close();
  }

  selectedAll = true;
  selectAll() {
    if (this.selectedAll === true) {
      this.toppingsControl.setValue([]);
      this.multiSelectValueChange("");
      this.selectedAll = false;
    } else {
      let catgIds: any[] = [];
      catgIds.push("selected all");
      this.allCategories.forEach((catg) => {
        catgIds.push(catg.botCategoryId);
      });
      this.toppingsControl.setValue(catgIds);
      this.multiSelectValueChange("");
      this.selectedAll = true;
    }
  }

  multiSelectValueChange(value: any) {
    this.categories = [];
    let catgIds: any[] = this.toppingsControl.value;
    catgIds.forEach((id) => {
      this.allCategories.forEach((catg) => {
        if (id === catg.botCategoryId) {
          this.categories.push(catg);
        }
      });
    });

    let x = this.allCategories.length + 1;

    if (x > catgIds.length) {
      catgIds = catgIds.filter((item) => item !== "selected all");
      this.toppingsControl.setValue(catgIds);
      this.selectedAll = false;
    }

    if (this.allCategories.length === catgIds.length) {
      catgIds.push("selected all");
      this.toppingsControl.setValue(catgIds);
      this.selectedAll = true;
    }
  }

  totalCartSum = 0;
  openCart() {
    this.showSpinner = true;
    this.cartItems = [];
    this.totalCartSum = 0;

    this.allCategories.forEach((categ) => {
      if (categ.itemList !== null && categ.itemList.length > 0) {
        categ.itemList.forEach((item) => {
          if (item.quantityAddedToCart > 0) {
            this.cartItems.push(item);
            this.totalCartSum = this.totalCartSum + item.totalPriceforQty;
          }
        });
      }
    });

    if (this.cartItems.length > 0) {
      this.viewCart = true;
      this.showSpinner = false;
    } else {
      this.toaster.error("Cart is empty! Add items to proceed ");
      this.showSpinner = false;
    }
  }

  clicked = false;
  orderLoaded = false;

  placeOrder() {
    this.clicked = true;
    this.showSpinner = true;

    let chosenbotUserLoc: BotUserLocation = new BotUserLocation();
    chosenbotUserLoc.botUserLocationId = null;
    this.botUserLocations.forEach((userLoc) => {
      if (userLoc.checked === true) {
        chosenbotUserLoc = userLoc;
      }
    });

    let invoice: BotInvoiceExt = new BotInvoiceExt();
    invoice.chosenbotUserLoc = chosenbotUserLoc;

    invoice.invoiceId = this.botInvoiceId;
    invoice.cartItems = this.cartItems;
    if (invoice.cartItems !== null && invoice.cartItems.length > 0) {
      this.extShopService.placeOrder(invoice).subscribe((res) => {
        this.newOrder = false;
        if (this.linkType === "s") {
          this.toaster.success("Order Placed Successfully");
          this.clicked = false;
          this.ngOnInit();
          this.viewCart = false;
          this.showSpinner = false;
        } else if (this.linkType === "q") {
          this.toaster.success("Quotation Request Placed Successfully");
          this.clicked = false;
          this.ngOnInit();
          this.viewCart = false;
          this.showSpinner = false;
        }
      });
    } else {
      this.clicked = false;
      this.toaster.error("Add items to cart to proceed !");
      this.showSpinner = false;
    }
  }

  updateOrder() {
    this.clicked = true;
    this.showSpinner = true;

    let chosenbotUserLoc: BotUserLocation = new BotUserLocation();
    chosenbotUserLoc.botUserLocationId = null;
    this.botUserLocations.forEach((userLoc) => {
      if (userLoc.checked === true) {
        chosenbotUserLoc = userLoc;
      }
    });

    let invoice: BotInvoiceExt = new BotInvoiceExt();
    invoice.chosenbotUserLoc = chosenbotUserLoc;

    invoice.invoiceId = this.botInvoiceId;
    invoice.cartItems = this.cartItems;
    if (invoice.cartItems !== null && invoice.cartItems.length > 0) {
      this.extShopService.updateOrder(invoice).subscribe((res) => {
        this.newOrder = false;
        this.orderStatus = "order edited";
        this.pageFunction = "";
        if (this.linkType === "s") {
          this.toaster.success("Order Updated Successfully");
          this.clicked = false;
          this.showSpinner = false;
        } else if (this.linkType === "q") {
          this.toaster.success("Quotation Request Updated Successfully");
          this.clicked = false;
          this.showSpinner = false;
        }
      });
    } else {
      this.clicked = false;
      this.toaster.error("Add items to cart to proceed !");
      this.showSpinner = false;
    }
  }

  editOrder() {
    this.showSpinner = true;
    this.pageFunction = "edit order";
    this.orderStatus = "order placed";
    this.viewCart = false;
    this.showSpinner = false;
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
