<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Reporting Head : {{userFirstName}} {{userLastName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #customerRegisteration="ngForm">

 
       
  <div class="form-group row">


    <div class="col-md-5">
      <label class="required" for="selectedUroId">Role</label> 
          <select required  class="form-control" (change)="onChange($event)" [(ngModel)]="selectedUroId" id="selectedUroId" name="selectedUroId">
            <option value="" disabled >Choose Role</option>
            <option  *ngFor="let role of assignedRoles"  [ngValue]="role.userRoleOfficeId">{{role.displayName}}</option>
          </select>
      </div>
      
      <div class="col-md-5">
  <label class="required" for="fkUserRoleOffice">Reporting Head</label> 
      <select required  class="form-control" [(ngModel)]="selectedReportingHead" id="selectedReportingHead" name="selectedReportingHead">
        <option value="" disabled >Choose User to add as Reporting Head</option>
        <option  *ngFor="let user of reportingHeads"  [ngValue]="user.fkParentUroId">{{user.fkParentName}}</option>
      </select>
  </div>

  <div  class="col-md-2">
  <br>
  <br>
    <button [disabled]="clicked"   [disabled]="!customerRegisteration.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="addReportingHead(); clicked = true;">Save</button>
  </div>
  </div>
     
  <br>
    </form>


    <br *ngIf="showTable === true">
    <div  *ngIf="showTable === true" class="table-header row" [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Reporting Heads
        </span>
        
      </div> 
    </div>
    <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
     *ngIf="showTable === true" style="width: 100%;">
      <thead class="tableheader">
        <tr>
          <th>Reporting Head</th>
          <th>Role</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          

      </tr>
      </thead>
      <tbody>
        <tr style="color: black;" *ngFor="let rhead of existingReportingHeads">
            <td>{{rhead.fkParentName}}</td>
            <td>{{rhead.roleName}}</td>
            <td>{{rhead.startDate}}</td>
            <td>{{rhead.endDate}}</td>
            <td>

              <a *ngIf="rhead.activeStatus === false" style="color: red;" >Inactive</a>

              <button (click)="open(confirmDeactivation);"
               *ngIf="rhead.activeStatus === true" style="width: auto;" class="btn btn-danger">Deactivate</button>
             
            </td>

         
            
            <ng-template #confirmDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to deactivate reporting head ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="deactivateReportingHead(rhead.hierarchyId);modal.dismiss('Cross click')">Deactivate</button>
              </div>
            </ng-template>
        </tr>
    </tbody>
    </table>

    <br  *ngIf="showTable2 === true" >
    <div *ngIf="showTable2 === true" class="table-header row" [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Reporting Users
        </span>
        
      </div> 
    </div>
    <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
    *ngIf="showTable2 === true" style="width: 100%;">
     <thead class="tableheader">
       <tr>
         <th>Reporting User Name</th>
         <th>Reporting User Role</th>
         <!-- <th>Start Date</th>
         <th>End Date</th>
         <th>Status</th> -->
         

     </tr>
     </thead>
     <tbody>
       <tr style="color: black;" *ngFor="let rhead of reportingUsers">
           <td>{{rhead.reportingUserName}}</td>
           <td>{{rhead.reportingUserRole}}</td>
           <!-- <td>{{rhead.startDate}}</td>
           <td>{{rhead.endDate}}</td>
           <td>

             <a *ngIf="rhead.activeStatus === false" style="color: red;" >Inactive</a>

             <button (click)="open(confirmDeactivation);"
              *ngIf="rhead.activeStatus === true" style="width: auto;" class="btn btn-danger">Deactivate</button>
            
           </td> -->

        
<!--            
           <ng-template #confirmDeactivation let-modal>
             <div class="modal-header">
               <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
               <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
             <div class="modal-body">
               Are you sure to deactivate reporting head ?
             </div> 
             <div class="modal-footer">
               <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
               <button type="button" class="btn btn-success" (click)="deactivateReportingHead(rhead.hierarchyId);modal.dismiss('Cross click')">Deactivate</button>
             </div>
           </ng-template> -->
       </tr>
   </tbody>
   </table>
    </div>
    </div>
   
    
   