import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddAdmin } from 'src/app/courseManagementClasses/add-admin';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SuperAdminUserService } from 'src/app/services/user/super-admin-user.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  oa_CRMAdmin : any;
  admin : AddAdmin = new AddAdmin();
  clicked = false;
  
  userExists = false;

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  officeId! : string;
  closeResult!: string;

  constructor(public activeModal: NgbActiveModal,
              private sharedDataService : SharedDataService,
              private SuperAdminUserService : SuperAdminUserService,
              private subscriptionService : SubscriptionService,
              private ngbModalService: NgbModal,
              private toastr : ToastrService ) { }

              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
    this.countryCodes = GlobalClass.CountryCodes;

    this.officeId = this.fromParent.userOfficeId;    
    this.oa_CRMAdmin = this.fromParent.oa_CRMAdmin;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  onSubmit(){

    // AUTHORIZED
    this.subscriptionService.searchUserByEmailOfficeAdmin(environment.apiUrl + "user/searchUserByEmailOfficeAdmin/" + this.admin.email).then(
      data=>{
        this.userExists = data;

        if(this.userExists === true){

          this.toastr.error('email already registered ! Use a different email')
          this.clicked = false;

        }else if(this.userExists === false){

          // AUTHORIZED
          this.admin.officeId = this.officeId;

          this.SuperAdminUserService.addAdmin(environment.apiUrl + 'subAdmin/add', this.admin).then(
            res=>{
              this.passEntry.emit(this.admin);
                  this.activeModal.close();
                  this.toastr.success('Admin Added Successfully');
            });
         }
      });

  }

}
