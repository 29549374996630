export class Subscription {
    startedDate:any
	 endedDate:any
	 fkUserId:any
	 fkRoleId:any
	 roleName:any
	 fkProductId:any
	 userName:any
	 subscriptionId:any

	 className:any

	 productId:any
	 productName:any
	 productImage:any
	 safeUrl : any

	 userPhone:any
	 userEmail:any

	subscriptions : Subscription[] = [];
	prodSubsCount : any = 0;
}
