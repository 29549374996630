import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import * as RecordRTC from "recordrtc";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedDataService } from "src/app/services/sharedData.service";
import { AudioRecordingService } from "src/app/BOT_CODE/bot_classes/audio-recording-service";
import { environment } from "src/environments/environment";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";

@Component({
  selector: "app-voice-recorder-modal",
  templateUrl: "./voice-recorder-modal.component.html",
  styleUrls: ["./voice-recorder-modal.component.scss"],
})
export class VoiceRecorderModalComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  clicked = false;
  matSpinnerEnabled = false;
  isRecording = false;
  recordedTime: any;
  blobUrl: any;
  teste: any;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private botOfficeService: BotOfficeServiceService,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer
  ) {
    this.audioRecordingService
      .recordingFailed()
      .subscribe(() => (this.isRecording = false));
    this.audioRecordingService
      .getRecordedTime()
      .subscribe((time) => (this.recordedTime = time));
    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.teste = data;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(data.blob)
      );
    });
  }

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  botMedia: BotMedia = new BotMedia();

  ngOnInit() {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botMedia.receiverBotUserId = this.fromParent.receiverBotUserId;
    this.botMedia.receiverBotUsername = this.fromParent.receiverBotUsername;
    this.botMedia.mediaType = "audio";
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  download(): void {
    const url = window.URL.createObjectURL(this.teste.blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.teste.title;
    link.click();
  }

  selectedMediaFile!: File;
  uploadVoiceToWhatsappCloudiApiAndShareToUSer() {
    this.matSpinnerEnabled = true;
    this.clicked = true;
    this.selectedMediaFile = new File([this.teste.blob], "audio.wav");

    let body = new FormData();
    body.append("multipartMedia", this.selectedMediaFile);
    body.append("mediaAsString", JSON.stringify(this.botMedia));

    this.botOfficeService
      .whatsappCloudApiMediaUpload(
        environment.apiUrl + "botMedia/sendVoiceNote",
        body
      )
      .then((res) => {
        if (res === false) {
          this.matSpinnerEnabled = false;
          this.toastr.error("Could not share voice note !");
          this.activeModal.close();
        } else if (res === true) {
          this.matSpinnerEnabled = false;
          this.toastr.success("Voice note shared successfull !");
          this.passEntry.emit("success");
          this.activeModal.close();
        }
      });
  }
}
