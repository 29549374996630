import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { BotCategory } from 'src/app/BOT_CODE/bot_classes/bot-category';
import { CreateBotCategoryComponent } from '../create-bot-category/create-bot-category.component';

@Component({
  selector: 'app-view-bot-categories',
  templateUrl: './view-bot-categories.component.html',
  styleUrls: ['./view-bot-categories.component.scss']
})
export class ViewBotCategoriesComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any; 
  context:any;

  constructor(private sharedDataService: SharedDataService,
    private botOfficeService : BotOfficeServiceService,
    private ngbModalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Output() passEntryAddSubcategory: EventEmitter<any> = new EventEmitter();
    @Output() passEntryEditSubcategory: EventEmitter<any> = new EventEmitter();
    @Output() passEntryGetCategories: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    botCategory : BotCategory = new BotCategory();

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md'
    }; 

  ngOnInit(): void {

    this.context = this.fromParent.context;

    if(this.context==="view subcategories"){
      this.botCategory = this.fromParent.botCategory;
    }

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  createSubCategory(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(CreateBotCategoryComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      context : "create sub category",
      parentId : this.botCategory.botCategoryId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotCategory) => {
      if(this.botCategory.list === null){
        this.botCategory.list = [];
      }
      this.botCategory.list.push(receivedEntry);
      this.passEntryAddSubcategory.emit(receivedEntry);
    }); 
  }

  editBotCategory(botSubCategory : BotCategory){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(CreateBotCategoryComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      context : "edit category",
      botCategory : botSubCategory
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotCategory) => {
      this.botCategory.list.forEach((catg)=>{
        if(catg.botCategoryId === receivedEntry.botCategoryId){
          catg.name = receivedEntry.description;
          catg.description = receivedEntry.description;
        }
      });
      this.passEntryEditSubcategory.emit(receivedEntry);
    }); 
  } 

  botCategoryAndItemsStatusUpdate(id:any){
    if(this.botCategory.botCategoryStatus === true){
      this.botOfficeService.botItemStatusUpdate(environment.apiUrl + "botOfficeShop/botCategoryAndItemsStatusUpdate/" + id).then(
        res=>{
          this.passEntryGetCategories.emit("success");
          this.toastr.success("Status updated successfully !");
        });
    }else if(this.botCategory.botCategoryStatus === false){
      this.toastr.error("Main Category " + this.botCategory.name + " is deactivated, Please activate the main category first !");
    }
  }

  closeResult!:string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

}