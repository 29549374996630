import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Skill } from 'src/app/courseManagementClasses/skill';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-parent-skill',
  templateUrl: './add-parent-skill.component.html',
  styleUrls: ['./add-parent-skill.component.scss']
})
export class AddParentSkillComponent implements OnInit {

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  clicked=false;

  skill : Skill = new Skill();
  skillId!: any;
  selectedFile!: File;

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private imageService: FileServiceService,
              private skillService : SkillServiceService,) { } 

  ngOnInit(): void {

  }

  skillImage = false;
  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
    this.skillImage = true;
  }

  onSubmit(){

    this.skillId = this.fromParent.skillId;
    this.skill.parentSkillId = this.skillId;

  // AUTHORIZED
  this.skillService.addSkill(environment.apiUrl + 'skill/add' , this.skill).then(
    res=>{
      this.skill = res;
     
      if(this.skillImage===false){
        this.activeModal.close();
        this.toaster.success("Skill added successfully");
        this.passEntry.emit(this.skill);
      }else if(this.skillImage===true){
        let body = new FormData();
        body.append("file", this.selectedFile);
  
        this.imageService.uploadSkillImage(body, this.skill.skillId).subscribe(
          data=>{
  
            this.activeModal.close();
            this.toaster.success("Skill added successfully");
            this.passEntry.emit(this.skill);
  
          });
      }
     

    });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }  
}
