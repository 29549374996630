
 
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
 
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Payments</li>
    </ol>
  </nav>
 
 
 
 
 
 
 
 
 
 
 
 
  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">




<div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
    <mat-card (click)="getTodaysMonthPayments();showInvoices=false;" style="cursor: pointer;" class="example-card mat-elevation-z12" >
    <div class="card-icon" >
    <a> 
    <img src="/assets/images/icons/paymentToday.png" width="70" height="70"
    alt="" loading="lazy">
    </a>
    </div>
    <br>
    <mat-card-subtitle style="margin-bottom:6%">Payments Received Today [{{todaysPaymentCount}}]</mat-card-subtitle> 
    </mat-card>
    </div>

<div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
<mat-card (click)="showCurrentMonthsPayments();showInvoices=false;" style="cursor: pointer;" class="example-card mat-elevation-z12" >
<div class="card-icon" >
<a> 
<img src="/assets/images/icons/paymentMonth.png" width="70" height="70"
alt="" loading="lazy">
</a>
</div>
<br>
<mat-card-subtitle style="margin-bottom:6%">Payments of current month [{{monthsPaymentCount}}]</mat-card-subtitle> 
</mat-card>
</div>

<div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
  <mat-card (click)="getOutstandingInvoiceListByOfficeId();" style="cursor: pointer;" class="example-card mat-elevation-z12" >
  <div class="card-icon" >
  <a> 
  <img src="/assets/images/icons/ordersInvoiced.png" width="70" height="70"
  alt="" loading="lazy">
  </a>
  </div>
  <br>
  <mat-card-subtitle style="margin-bottom:6%">View Outstanding Invoices</mat-card-subtitle> 
  </mat-card>
  </div>

<div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
  <mat-card (click)="open(searchPaymentsByPeriodModal);showInvoices=false;" style="cursor: pointer;" class="example-card mat-elevation-z12" >
  <div class="card-icon" >
  <a> 
  <img src="/assets/images/icons/calendar.png" width="70" height="70"
  alt="" loading="lazy">
  </a>
  </div>
  <br>
  <mat-card-subtitle style="margin-bottom:6%">Get Payments from a Period</mat-card-subtitle> 
  </mat-card>
  </div>

<div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
  <mat-card (click)="open(searchCustomerPaymentsModal);" style="cursor: pointer;" class="example-card mat-elevation-z12" >
  <div class="card-icon" >
  <a> 
  <img src="/assets/images/icons/user.png" width="70" height="70"
  alt="" loading="lazy">
  </a>
  </div>
  <br>
  <mat-card-subtitle style="margin-bottom:6%">Get Customer Payments</mat-card-subtitle> 
  </mat-card>
  </div>




    </div>
    </div>

 
 
 
 
 
 
 
 
 
  <div class="p-4" *ngIf="showInvoices===false">
   
     
        <div class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              {{status}}
            </span>

            <!-- <a style="cursor:pointer;" class="float-right" (click)="addNewPayment();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a> -->
            <a style="cursor:pointer;" class="float-right" (click)="open(searchCustomerModal);">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
            <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
              <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
            </a>
          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
           
            <table *ngIf="paymentExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="paymentOptions" >
              <thead class="tableheader">
                <tr>
                  <th style="text-align: left;">Customer</th>
                  <th style="text-align: left;">Payment Method</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Action</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let pay of payments">
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{pay.customerName}}</td>
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{pay.paymentMethod}}</td>
                  <td class="td-height">{{pay.paymentDate}}</td>
                  <td class="td-height" style="text-align: right;">{{pay.paymentAmount}}</td>
                  <td class="td-height" style=" height: 2rem;">
                    <button *ngIf="pay.paymentStatus!== null && pay.paymentStatus" type="button"
                      class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
                    <button *ngIf="pay.paymentStatus !== null && !pay.paymentStatus" type="button"
                      class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
                    
                    <button (click)="editPayment(pay.paymentId)" class="btn btn-secondary">Edit</button>
                    <button (click)="viewPaymentDetailsModal(pay.paymentId)" class="btn btn-info">View</button>
                  </td>
                  


                                    

  <ng-template #confirmActivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;"  id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to activate payment ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updatePaymentStatus(pay.paymentId);modal.dismiss('Cross click')">Activate</button>
    </div>
  </ng-template> 
  
  
  <ng-template #confirmDeactivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to deactivate payment ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updatePaymentStatus(pay.paymentId);modal.dismiss('Cross click')">Deactivate</button>
    </div>
  </ng-template>

                </tr> 
              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>
  
 
 

  <div class="p-4" *ngIf="showInvoices===true">
   
     
    <div class="table-header row"  [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Outstanding Invoices
        </span>

       
        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArrayInvoices()">
          <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
        </a>
      </div> 
    </div>
    <div class="card">
      <div class="card-body table-responsive">
        <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="paymentOptions" >
          <thead class="tableheader">
            <tr>
              <th>Invoice No</th>
              <th style="text-align: left;">Customer</th>
              <th style="text-align: left;">Email</th>
              <th style="text-align: left;">Phone</th>
              <th>Date</th>
              <th> Total Amt</th>
              <th>Outstanding Amt</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of invoices">
              <td class="td-height">{{ccd.invoiceNo}}</td>
              <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.customerName}}</td>
              <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.customerEmail}}</td>
              <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.customerContact}}</td>
              <td class="td-height">{{ccd.invoiceDate}}</td>

              <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
              <td  style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>

              <td class="td-height" style=" height: 2rem;">
                 <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  
              </td>
              
                                


         
              
              
            
           
            </tr>    

          </tbody>
        </table>

      </div>
    </div>
  

</div>










  <ng-template #searchCustomerModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search Customer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form *ngIf="searchInput===true" #paymentForm="ngForm">
    <div class="col-md-12">
    <input class="col-md-12 form-control" required type="text" [(ngModel)]="searchValue" 
    placeholder="Enter first name / last name / email / phone" name="search">
  <br>
    <div style="text-align:center;">
    <button  [disabled]="!paymentForm.valid"  style="width: auto;color: black;"
     (click)="searchCustomers();" class="btn btn-info">
      Search Customer
      <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
      width="27" height="27" alt="" loading="lazy">
    
    </button>
  </div>
  </div>
    </form>
    <div *ngIf="searchInput===false">
      
      <button style="width: auto;color: black;" (click)="searchInput=!searchInput;" class="btn btn-info">
        Search
        <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
        width="27" height="27" alt="" loading="lazy">
      </button>
<br>
<br>
      <div *ngFor="let user of ccdetails">

       <h3 style="margin: 0%;">{{user.firstName}} {{user.lastName}}</h3>
<br>
<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" >
  <thead>
    <tr>
      <th>Invoice No</th>
      <th>Date</th>
      <th>Grand Total</th>
      <th>Outstanding Amt</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let inv of user.invoices">
      <td>
        {{inv.invoiceNo}}
      </td>
      <td>
        {{inv.invoiceDate}}
      </td>
      <td>
        {{inv.grandTotal}}
      </td>
      <td>
        {{inv.outstandingAmount}}
      </td>
      <td>
        <img matTooltip="Create Payment" (click)="addNewPayment(inv.invoiceId, user.firstName, user.lastName);modal.dismiss('Cross click')"
         src="/assets/images/icons/add.svg" 
    width="25" height="25" alt="" loading="lazy">
      </td>
    </tr>
  </tbody>
</table>
<br>
<br>
      </div>
    
    </div>
 
    </div>
  </ng-template>
 
  
  
  <ng-template #searchPaymentsByPeriodModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search Payments</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form #adminForm="ngForm">
    <div class="form-group row">
      <div class="col-md-6">
        <label for="subscription.fromDate">From Date</label>
        <input required class="form-control" id="payment.fromDate"
         name="payment.fromDate" [(ngModel)]="payment.fromDate" type="date">
      </div>

      <div class="col-md-6">
        <label for="toDate">To Date</label>
        <input required class="form-control" id="payment.toDate" 
        name="payment.toDate" [(ngModel)]="payment.toDate" type="date">
      </div>
    </div>

    </form>
   
 
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!adminForm.valid" 
      class="btn btn-sm btn-primary float-right" 
      (click)="getpaymentsByPeriod();modal.dismiss('Cross click')">Get</button>
    </div>
  </ng-template>


  <ng-template #searchCustomerPaymentsModal let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Search Customer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
  <form *ngIf="searchInputPayment===true" #paymentForm="ngForm">
    <div class="col-md-12">
    <input class="col-md-12 form-control" required type="text" [(ngModel)]="searchValuePayment" 
    placeholder="Enter first name / last name / email / phone" name="search">
  <br>
    <div style="text-align:center;">
    <button  [disabled]="!paymentForm.valid"  style="width: auto;color: black;"
     (click)="searchCustomerPayments();" class="btn btn-info">
      Search Customer
      <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
      width="27" height="27" alt="" loading="lazy">
    
    </button>
  </div>
  </div>
    </form>
    <div *ngIf="searchInputPayment===false">
      
      <button style="width: auto;color: black;" (click)="searchInputPayment=!searchInputPayment;" class="btn btn-info">
        Search
        <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
        width="27" height="27" alt="" loading="lazy">
      </button>
<br>
<br>

<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" >
  <thead>
    <tr>
      <th>Customer</th>
      <th>Email</th>
      <th> Number</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let cust of ccdetails2">
      <td>
        {{cust.firstName}} {{cust.lastName}}
      </td>
      <td>
        {{cust.email}}
            </td>
      <td>
        {{cust.phoneNumber}}
            </td>
      <td>
        <button style="width: auto;" (click)="getPaymentsByCustomerId(cust.customerId);modal.dismiss('Cross click');" 
        class="btn btn-info">Get Payments</button>
      </td>
    </tr>
  </tbody>
</table>

     
    
    </div>
 
    </div>
  </ng-template>