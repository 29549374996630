import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BotConvoLog } from 'src/app/BOT_CODE/bot_classes/bot-convo-log';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { environment } from 'src/environments/environment';

// function randint(min:any, max:any) {
//   return Math.random() * (max - min) + min;
// }

@Component({
  selector: 'app-combined-parent-teacher-dashboard',
  templateUrl: './combined-parent-teacher-dashboard.component.html',
  styleUrls: ['./combined-parent-teacher-dashboard.component.scss']
})
export class CombinedParentTeacherDashboardComponent implements OnInit {


  // public user = {
  //   name: 'Dustin',
  //   avatar: 'https://randomuser.me/api/portraits/men/30.jpg'
  // };
  // public contact = {
  //   name: 'Kylie',
  //   avatar: 'https://randomuser.me/api/portraits/women/76.jpg'
  // };

  // msg(user:any, contact:any, message:any, timestamp = Date.now()) {
  //   return {
  //     user,
  //     contact,
  //     message,
  //     timestamp,
  //   }
  // }

  // send(msg:any) {
  //   return this.msg(this.user, this.contact, msg)
  // }
  // recv(msg:any) {
  //   return this.msg(this.contact, this.user, msg)
  // }

  // messages: any[] = []

  // msgInterval = randint(500, 1500)

  // msgs :any = [];
 
  // nextMsg() {
  //   if (this.msgs.length) {
  //     const msg = this.msgs[0];
  //     let msgP = {action :"",
  //                 msg : ""}
  //     msgP.action = msg.action;
  //     msgP.msg = msg.msg;
  //     this.messages.push(msgP);
  //     // this.msgs = this.msgs.splice(1, this.msgs.length)
  //   } else {
  //     clearInterval(this.autoSender)
  //   }
  // }
  // autoSender : any;

  // lastLogItem : BotConvoLog = new BotConvoLog();
  // convoLogObject : BotConvoLog = new BotConvoLog();
  // updateCurrentLog = false;

  constructor(private botResponseService : BotResponseServiceService,
    private datePipe : DatePipe) { }

  ngOnInit(): void {
    // this.nextMsg()
    // this.autoSender = setInterval(() =>
    //   this.nextMsg(), this.msgInterval
    // );
    // this.getConvoLogObject();



    
    // this.myLoop();

  }


//  myLoop() {   
// let i = 1;                  
//     setTimeout(()=>{                           //timer
//       this.getConvoLogObject(); 
//       i = i + 1;                    
//       if (i < 10) {           
//         this.myLoop();          
//       }   
//  }, 10000);

//     // setTimeout(function() {   
//     //   console.log(i);   
//     //   i++;                    
//     //   if (i < 10) {           
//     //     myLoop();            
//     //   }                     
//     // }, 3000)

//   }

//   getConvoLogObject(){
//     if(this.updateCurrentLog === false){
//       this.botResponseService.getBotChatLogByUserId(environment.apiUrl + "botResponse/getBotChatLogByUserId").then(
//         res=>{
//           this.convoLogObject = res;

//          this.convoLogObject.list.forEach((obj)=>{
//           let req = {
//             action: '',
//             msg: ''
//           }
//           req.action = "request";
//           req.msg = obj.request;
//           this.msgs.push(req);
//           let resp = {
//             action: '',
//             msg: ''
//           }
//           resp.action = "response";
//           resp.msg = obj.response;
//           this.msgs.push(resp);
//          });

//           this.updateCurrentLog = true;

//           this.lastLogItem.lastLogItemId = this.convoLogObject.lastLogItemId;

//         });
//     }else if(this.updateCurrentLog === true){
//       this.botResponseService.getBotChatLogByUserIdAndTimestamp(environment.apiUrl + "botResponse/getBotChatLogByUserIdAndTimestamp", this.lastLogItem).then(
//         res=>{
//           let nextLog : BotConvoLog = new BotConvoLog();
//           nextLog = res;

//           if(nextLog !== null && nextLog !== undefined && nextLog.list !== null && nextLog.list !== undefined && nextLog.list.length !== 0){
//           nextLog.list.forEach((obj)=>{
//             let req = {
//               action: '',
//               msg: ''
//             }
//             req.action = "request";
//             req.msg = obj.request;
//             this.msgs.push(req);
//             let resp = {
//               action: '',
//               msg: ''
//             }
//             resp.action = "response";
//             resp.msg = obj.response;
//             this.msgs.push(resp);
//            });
//           }

//           this.lastLogItem.lastLogItemId = nextLog.lastLogItemId;

//           nextLog.list.forEach((log)=>{
//             this.convoLogObject.list.push(log);
//           });

//         });
//     }
//   }

//   ngOnDestroy() {
//     clearInterval(this.autoSender)
//   }

}