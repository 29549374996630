<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">{{fromParent.context}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>  

  <div class="modal-body">
      <div class="card-body"> 
      <form #adminForm="ngForm">  

        <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

        <div *ngIf="matSpinnerActive===false">

        <div class="form-group row">

          <div class="col-md-6" *ngIf="fromParent.context==='Create Template'">
            <label>Create Template on Meta Whatsapp Manager ?  
              <a style="cursor: pointer;" (click)="open(metaTemplatesManagerInfo);"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
              </label> <br> 
            <mat-radio-group name="botTemplate.createTemplateOnMeta" id="botTemplate.createTemplateOnMeta" required
             aria-label="createTemplateOnMeta" [(ngModel)]="botTemplate.createTemplateOnMeta">
              <mat-radio-button name="createTemplateOnMeta" id="11" [value]=true>Yes</mat-radio-button>
              <mat-radio-button name="createTemplateOnMeta" id="12" [value]=false >No</mat-radio-button>
            </mat-radio-group>
          </div>
           
            <div class="col-md-6">
            <label class="required" for="templateName">Template Name</label>
            <input #templateName="ngModel" maxlength="255" required class="form-control" type="text" 
             name="templateName" id="templateName" [(ngModel)]="botTemplate.templateName">
            <div class="alert alert-danger" *ngIf="templateName.touched && !templateName.valid">Template Name is Required</div>
            </div>

        </div>



        <div class="form-group row">

          

          <div class="col-md-6">
            <label class="required">Template Category </label> <br> 
            <mat-radio-group name="botTemplate.tempCategory" id="botTemplate.tempCategory" required
             aria-label="tempCategory" [(ngModel)]="botTemplate.tempCategory">
              <mat-radio-button name="tempCategory" id="51" value="MARKETING" >Marketing</mat-radio-button>
              <mat-radio-button name="tempCategory" id="52" value="UTILITY" >Utility</mat-radio-button>
              <mat-radio-button name="tempCategory" id="53" value="AUTHENTICATION" >Authentication</mat-radio-button>
            </mat-radio-group>
          </div> 
          
            <div class="col-md-6">
              <label class="required" for="langDef">Choose Language</label> 
              <select #fkBotMediaId="ngModel" (change)="langChangeFunc()" required class="form-control" [(ngModel)]="chosenLanguage" 
              id="langDef">
                <option value="" disabled >Choose Template Language </option>
                <option *ngFor="let langCode of languageCodes"
                 [ngValue]="langCode"> {{langCode.lang}}  
                </option>
              </select>
            </div>

            
        </div>

        <div>
          <label class="required">Header Type </label> <br> 
          <mat-radio-group name="botTemplate.tempHeaderType" id="botTemplate.tempHeaderType" required
           aria-label="tempHeaderType" [(ngModel)]="botTemplate.tempHeaderType">
            <mat-radio-button name="tempHeaderType" id="4" value="none" >No Header</mat-radio-button>
            <mat-radio-button name="tempHeaderType" id="8" value="TEXT" >Text</mat-radio-button>
            <mat-radio-button (change)="filterMedia('image');" name="tempHeaderType" id="5" value="IMAGE" >Image</mat-radio-button>
            <mat-radio-button (change)="filterMedia('video');" name="tempHeaderType" id="6" value="VIDEO" >Video</mat-radio-button>
            <mat-radio-button (change)="filterMedia('document');" name="tempHeaderType" id="7" value="DOCUMENT" >Document</mat-radio-button>
          </mat-radio-group>
          </div>
       


        <div *ngIf="botTemplate.tempHeaderType==='TEXT'">

          <div>
          <label class="required" for="tempHeaderText">Header Text</label>
          <input #tempHeaderText="ngModel" maxlength="255" required class="form-control" type="text" 
           name="tempHeaderText" id="tempHeaderText" [(ngModel)]="botTemplate.tempHeaderText">
          <div class="alert alert-danger" *ngIf="tempHeaderText.touched && !tempHeaderText.valid">Header Text is Required</div>
          </div>
    <br>
          <div class="form-group row">

            <div class="col-md-6">
            <label for="tempHeaderSample">Header Sample</label>
            <input #tempHeaderSample="ngModel" maxlength="255" class="form-control" type="text" 
             name="tempHeaderSample" id="tempHeaderSample" [(ngModel)]="botTemplate.tempHeaderSample">
            </div>
      
            <div class="col-md-6">
            <label for="tempHeaderVariable">Header Variable</label>
            <input #tempHeaderVariable="ngModel" maxlength="255" class="form-control" type="text" 
             name="tempHeaderVariable" id="tempHeaderVariable" [(ngModel)]="botTemplate.tempHeaderVariable">
            </div>
  
        </div>

      </div>


      <div *ngIf="botTemplate.createTemplateOnMeta===true && botTemplate.fkDefaultHeaderBotMediaId === null
      || botTemplate.createTemplateOnMeta===true && botTemplate.fkDefaultHeaderBotMediaId === undefined">
        <button *ngIf="botTemplate.tempHeaderType==='IMAGE'" style="width: 100%;" 
        (click)="uploadMediaToResumableUploadApi();" class="btn btn-info">Upload Sample Image For Header</button>
        <button *ngIf="botTemplate.tempHeaderType==='VIDEO'" style="width: 100%;" 
        (click)="uploadMediaToResumableUploadApi();" class="btn btn-info">Upload Sample Video For Header</button>
        <button *ngIf="botTemplate.tempHeaderType==='DOCUMENT'" style="width: 100%;" 
        (click)="uploadMediaToResumableUploadApi();" class="btn btn-info">Upload Sample Document For Header</button>
        <br><br>
      </div>
 
      <div *ngIf="botTemplate.createTemplateOnMeta===true && botTemplate.fkDefaultHeaderBotMediaId !== null
      && botTemplate.fkDefaultHeaderBotMediaId !== undefined">
        <button *ngIf="botTemplate.tempHeaderType==='IMAGE'" style="width: 100%;" 
        (click)="uploadMediaToResumableUploadApi();" class="btn btn-primary">Upload successful, Click to Reupload Sample Image For Header</button>
        <button *ngIf="botTemplate.tempHeaderType==='VIDEO'" style="width: 100%;" 
        (click)="uploadMediaToResumableUploadApi();" class="btn btn-primary">Upload successful, Click to Reupload Sample Video For Header</button>
        <button *ngIf="botTemplate.tempHeaderType==='DOCUMENT'" style="width: 100%;" 
        (click)="uploadMediaToResumableUploadApi();" class="btn btn-primary">Upload successful, Click to Reupload Sample Document For Header</button>
        <br><br>
      </div>



        <div *ngIf="botTemplate.createTemplateOnMeta===false && botTemplate.tempHeaderType==='IMAGE' 
        || botTemplate.createTemplateOnMeta===false && botTemplate.tempHeaderType==='VIDEO' 
        || botTemplate.createTemplateOnMeta===false && botTemplate.tempHeaderType==='DOCUMENT'">
         
          <div *ngIf="botTemplate.tempHeaderType==='DOCUMENT'">
            <label for="headerFilename">File Name</label> 
            <input class="form-control" type="text" 
            name="headerFilename" id="headerFilename" [(ngModel)]="botTemplate.headerFilename">
            <br>
          </div>

          <div>
            <label class="required" for="fkDefaultHeaderBotMediaId">Choose header media</label> 
            <select required class="form-control" [(ngModel)]="botTemplate.fkDefaultHeaderBotMediaId" 
            id="fkDefaultHeaderBotMediaId" name="fkDefaultHeaderBotMediaId">
              <option value=null disabled >Choose header media</option>
              <option  *ngFor="let med of media" [ngValue]="med.botMediaId"> {{med.title}} </option>
            </select>
          </div>

          <br>
        <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
        </div>
        <br>

        </div>

        <div>
          <label class="required" for="tempBody">Template Body</label>
          <textarea class="form-control" type="text" name="tempBody" required
          cols="30" rows="8" id="tempBody" [(ngModel)]="botTemplate.tempBody"></textarea>
          <br>
          <button style="float: right;" mat-raised-button (click)="addVariableToBody();">Add Variable</button>
       </div> 
      
       <br>
        
         <div>
          <label class="required" for="tempVarCount">Variable Count in Body</label>
          <input (change)="tempVarCountChange()" #tempVarCount="ngModel" maxlength="255" required class="form-control" type="number" 
           name="tempVarCount" id="tempVarCount" [(ngModel)]="botTemplate.tempVarCount">
          <div class="alert alert-danger" *ngIf="tempVarCount.touched && !tempVarCount.valid">Count is Required</div>
          </div>

          
        <div *ngIf="botTemplate.tempVarList !== null && botTemplate.tempVarList !== undefined && botTemplate.tempVarList.length > 0">
          <br>
          <label class="required">Default Variables</label>
          <div class="form-group row">

            <div *ngFor="let templateVar of botTemplate.tempVarList" class="col-md-4">
            <label class="required">
              {{templateVar.botScheduleTempVarOrder}}
              <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label>
            <input minlength="1" maxlength="50" required class="form-control" type="text" 
             [name]="templateVar.botScheduleTempVarOrder" [id]="templateVar.botScheduleTempVarOrder" 
             [(ngModel)]="templateVar.botScheduleTempVar">
            </div>
          
        </div>
        </div>

        
        <div *ngIf="botTemplate.tempVarSampleContentList !== null && botTemplate.tempVarSampleContentList !== undefined && botTemplate.tempVarSampleContentList.length > 0">
          <br>
          <label class="required">Sample Content For Variables</label>
          <div class="form-group row">

            <div *ngFor="let templateVar of botTemplate.tempVarSampleContentList" class="col-md-4">
            <label class="required">
              {{templateVar.botScheduleTempVarOrder}}
            </label>
            <input minlength="1" maxlength="50" required class="form-control" type="text" 
             [name]="templateVar.botScheduleTempVarOrder" [id]="templateVar.botScheduleTempVarOrder" 
             [(ngModel)]="templateVar.botScheduleTempVar">
            </div>
          
        </div>
        </div>

        <div *ngIf="fromParent.context==='Create Template'">
          <br>
        <button class="btn btn-info" style="width: 100%;" 
        (click)="addButtonsToTemplate();" >Add Buttons</button>
       <br> <br>
       <table *ngIf="templateButtons!==null && templateButtons!==undefined && templateButtons.length>0"
         class="table table-striped table-sm hover">
        <thead>
          <th>BUTTON LABEL</th>
          <th>TYPE</th>
          <!-- <th>LABEL</th> -->
        </thead>
        <tbody>
          <tr *ngFor="let optn of templateButtons">
            <td>{{optn.responseOption}}</td>
            <td>{{optn.optionType}}</td>
          </tr>
        </tbody>
      </table>
      </div>

    </div>
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="matSpinnerActive=true;onSubmit();">save</button>
</div>



<ng-template #metaTemplatesManagerInfo let-modal>
 
  <div class="modal-body">
    <h3>Create Template on Meta Whatsapp Manager ? </h3>

    Select "No" if template is already manually created on facebook templates manager and is active or <br>
    select "No" if you want to manually create the template on facebook templates manager. <br>
    When "No" is selected, template will be created only on Zoti. <br> <br>
         To create template on meta simultaneously, select "Yes". 
         After creating, use the "Update Status" button in Status column to update status. 
        Templates can be used once status changes to "APPROVED". <br> <br>
        <a style="color: blue;" 
        href="https://business.facebook.com/wa/manage/message-templates/" target="_blank">Click here</a> to go to Meta Whatsapp Manager.
      
 <br>

  </div> 
 
</ng-template>

<ng-template #variableDetails let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Variables</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <table
    class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
    <thead class="tableheader" >
      <tr>
        <th>User Variables</th>
        <th>Detail that replaces variable</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>$!#userName$!# 
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userName$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User name</td>
      </tr>
      <tr>
        <td>$!#userPhone$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userPhone$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User phone number</td>
      </tr>
      <tr>
        <td>$!#userAlternatePhone$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userAlternatePhone$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User alternate phone number</td>
      </tr>
      <tr>
        <td>$!#userGender$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#userGender$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>User gender</td>
      </tr>
      <tr>
        <td>$!#customVar1$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#customVar1$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>Custom Variable 1</td>
      </tr>
      <tr>
        <td>$!#customVar2$!#
          <a style="cursor: pointer;" cdkCopyToClipboard="$!#customVar2$!#" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>Custom Variable 2</td>
      </tr>
    </tbody>
  </table>
<br>

<table
class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
<thead class="tableheader" >
  <tr>
    <th>Shopify Abandoned Cart Variables</th>
    <th>Detail that replaces variable</th>
</tr>
</thead>
<tbody>
  <tr>
    <td>$!#created_at$!# 
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#created_at$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Created Timestamp</td>
  </tr>
  <tr>
    <td>$!#abandoned_checkout_url$!#
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#abandoned_checkout_url$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Abandoned Checkout Url</td>
  </tr>
  <tr>
    <td>$!#total_price$!#
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#total_price$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Total Price</td>
  </tr>
  <tr>
    <td>$!#customer_first_name$!#
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#customer_first_name$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Customer First Name</td>
  </tr>
  <tr>
    <td>$!#customer_last_name$!#
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#customer_last_name$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Customer Last Name</td>
  </tr>
  <tr>
    <td>$!#customer_email$!#
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#customer_email$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Customer Email</td>
  </tr>
  <tr>
    <td>$!#customer_phone$!#
      <a style="cursor: pointer;" cdkCopyToClipboard="$!#customer_phone$!#" (click)="toastr.success('var copied !');"> 
        <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
      </a>
    </td>
    <td>Customer Phone</td>
  </tr>
</tbody>
</table>
  
    
  </div> 
</ng-template>