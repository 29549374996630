import { Component, EventEmitter, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxMaterialColorPickerModule } from "ngx-material-color-picker";
import { GlobalClass } from "src/app/global/global-class";
import { SharedDataService } from "src/app/services/sharedData.service";
import { MODAL_HEADERS } from "../../utils/constants/app-constants";

@Component({
  selector: "app-add-bot-office",
  templateUrl: "./add-bot-office.component.html",
  styleUrls: ["./add-bot-office.component.scss"],
})
export class AddBotOfficeComponent implements OnInit {
  clicked = false;
  numberOfItems = 0;
  progress = 0;
  officeObject: any;
  imageurl: any;
  headerText = MODAL_HEADERS.ADD_OFFICE; // 'Add Office';

  addOffice = new FormGroup({
    name: new FormControl(null, Validators.required),
    location: new FormControl(null),
    image: new FormControl(null, [Validators.required]),
    color: new FormControl(""),
    intensity: new FormControl(500),
    theme: new FormControl(null),
    id: new FormControl(null),
    type: new FormControl(null, Validators.required),
    leadConversionType: new FormControl(null, Validators.required),
  });

  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private domSanitizer: DomSanitizer,
    private sharedDataService: SharedDataService
  ) {}

  loggedInUser: any;

  ngOnInit() {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.addOffice = this.formBuilder.group({
      name: this.officeObject ? this.officeObject.officeName : "",
      location: this.officeObject ? this.officeObject.officeLocation : "",
      image: this.officeObject ? this.officeObject.logo : File,
      color: new FormControl(""),
      intensity: new FormControl(500),
      theme: this.officeObject ? this.officeObject.theme : "",
      id: this.officeObject ? this.officeObject.id : null,
      activeStatus: this.officeObject ? this.officeObject.activeStatus : null,
      type: this.officeObject ? this.officeObject.type : "",
      leadConversionType: this.officeObject
        ? this.officeObject.leadConversionType
        : "",
    });

    if (this.officeObject?.id) {
      this.headerText = MODAL_HEADERS.EDIT_OFFICE; // 'Edit Office';
    }
  }

  public get color1(): string {
    return this.addOffice.controls["theme"]?.value;
  }
  public set color1(value: string) {
    this.addOffice.controls["theme"].setValue(value);
  }

  get backgroundStyle() {
    let globalClass: GlobalClass = new GlobalClass();
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  saveToList(form: FormGroup) {
    if (form.value) {
      this.triggerEvent(form.value);
      this.bsModalRef.hide();
    }
  }

  triggerEvent(item: FormGroup) {
    this.event.emit({ data: item, res: 200 });
  }
}
