


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Ticket Action</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 
             
        <div class="form-group row">

        <div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Choose an option</mat-label>
            <mat-select (selectionChange)="selectionChange($event)" required id="xxx" name="xxx"
            disableRipple [(ngModel)]="ticketAction.actionType">
              <mat-option id="Take Up Ticket" value="Take Up Ticket" *ngIf="ticketActual.ticketActualStatus === 'Ticket Raised'"> Take Up Ticket </mat-option>
              <mat-option id="Reassign" value="Reassign"> Reassign </mat-option>
              <mat-option id="Work In Progress" value="Work In Progress" *ngIf="ticketActual.ticketActualStatus === 'Work In Progress'"> Work In Progress </mat-option>
              <mat-option id="Awaiting Response" value="Awaiting Response" *ngIf="ticketActual.ticketActualStatus === 'Work In Progress'"> Awaiting Response </mat-option>
              <mat-option id="Resolved" value="Resolved" *ngIf="ticketActual.ticketActualStatus === 'Work In Progress'"> Resolved </mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <!-- <div class="col-md-6" *ngIf="reassign === true">
          <mat-form-field appearance="fill">
            <mat-label>Choose an option</mat-label>
            <mat-select disableRipple [(ngModel)]="ticketAction.reassignedToUroId" required id="yyy" name="yyy">
              <mat-option *ngFor="let cb of reAssignUsers" [value]="cb.userUroId"> {{cb.userName}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <div class="col-md-6" *ngIf="reassign === true">
        <mat-form-field  [style.width.%]=100 class="example-full-width" appearance="fill">
          <mat-label>Choose an option</mat-label>
          <input type="text"
                 placeholder="Pick User"
                 aria-label="Number"
                 matInput
                 [formControl]="myControl"
                 [matAutocomplete]="auto"
                 required id="yyy" name="yyy">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option  *ngFor="let cb of filteredOptions | async" [value]="cb.userName"
            (click)="setUser(cb.userUroId)" >
              {{cb.userName}} 
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        </div>

      </div>
<br>

<div>
  <label for="remarks">Remarks</label>
  <textarea maxlength="5000" type="text"
   id="remarks" name="remarks"  rows="8"
   class="form-control" placeholder="Enter remarks" 
   [(ngModel)]="ticketAction.remarks"></textarea>
  </div>
         


      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button [disabled]="clicked" [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
      </div>