import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Result } from 'src/app/courseManagementClasses/result';
import { Skill } from 'src/app/courseManagementClasses/skill';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ResultServiceService } from 'src/app/service/result-service.service';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { StudentService } from 'src/app/service/student.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-results-modal',
  templateUrl: './add-results-modal.component.html',
  styleUrls: ['./add-results-modal.component.scss']
})
export class AddResultsModalComponent implements OnInit {
 
  clicked = false;

  result : Result = new Result();
  
  skillList : Skill[] = [];

  studentName : any;
  subjectName : any;
  
  idObject :any = {
    studentId : '',
    subjectId : '',
    selectedStudentName : '',
    selectedSubjectName : ''
  }

  classCards : any[] = [{
                          classDescription: '',
                          classId: '',
                          className: '',
                          courseName: '',
                          fkOfficeId: '',
                          fkProductIdOfClass:  '',
                          studentId:  '',
                          userRoleOfficeId:  ''
                      }]

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  constructor(public activeModal : NgbActiveModal,
    private skillService : SkillServiceService,
    private studentService : StudentService,
    private toaster : ToastrService,
    private resultService : ResultServiceService) { }

  ngOnInit(): void {

    this.idObject.studentId = this.fromParent.studentId;
    this.idObject.subjectId = this.fromParent.subjectId;
    this.idObject.selectedStudentName = this.fromParent.selectedStudentName;
    this.idObject.selectedSubjectName = this.fromParent.selectedSubjectName;

    // this is userRoleOfficeId
    // this.result.fkStudentId = this.idObject.studentId;
    this.result.fkStudentId = '';
    this.result.fkSubjectId = this.idObject.subjectId;

    this.studentService.getStudentClassesByUserRoleOfficeId(this.fromParent.studentId).subscribe(
      data=>{        
        this.classCards = data;
      });
      
    this.getAllSkills();

  }

  getAllSkills(){
        this.skillService.getAllSkills(environment.apiUrl + 'skill/getAll').then(
          res=>{            
            this.skillList = res;
          });
      }

  closeUpdateModal(){
    this.activeModal.close();
  }

  onSubmit(){
    this.result.fkStudentId = this.fromParent.studentId;
    this.resultService.addResult(this.result).subscribe(
      data=>{
        this.passEntry.emit(this.idObject);
        this.activeModal.close();
        this.toaster.success('Result Added Successfully');
      });
  }


}
