<div class="modal-header">
    <h4 class="modal-title pull-left">Edit URO Link definition</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="add()" #aplnrForm="ngForm"> 

      
   

           <div class="form-group row">
 
       

            <div class="col-md-4">
                <label for="key"> Display Name</label>
                <input #key="ngModel" required placeholder="Enter key" required class="form-control" type="text" name="key"  id="key" [(ngModel)]="linkDef.displayName">
                <div class="alert alert-danger" *ngIf="key.touched && !key.valid">Display Name is required</div>
             </div> 
             
             <div class="col-md-4">
                <label for="value"> Display Status</label>
                <input  #value="ngModel" required placeholder="Enter value" required class="form-control" type="text" name="value"  id="value" [(ngModel)]="linkDef.displayStatus">
                <div class="alert alert-danger" *ngIf="value.touched && !value.valid">Display Status is required</div>
             </div>
 

             <div class="col-md-4">
              <label for="value"> Group Name</label>
              <input  #gName="ngModel" required placeholder="Enter Name" required class="form-control" type="text" name="groupName"  id="groupName" [(ngModel)]="linkDef.groupName">
              <div class="alert alert-danger" *ngIf="gName.touched && !gName.valid">Group Name is required</div>
           </div>
      </div>

     
     






    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="add(); clicked=true">Save</button>
  </div>