


   <div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white; font-weight: bold;" class="modal-title pull-left">View Tiers</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
   
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">

      <div class="card">
        <div class="card-body table-responsive">
        
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead class="tableheader">
              <tr>
                  <th>Role Name</th>
                  <!-- <th>Role Level</th> -->
                <th>Incentive</th>
                <th>Start Value</th>
                <th>End Value</th>
                <th>Action</th>
              </tr>
            </thead> 
            <tbody>
              <tr class="td-height" *ngFor="let target of incentiveTiers">
                <td class="td-height">{{target.roleDisplayName}}</td>
                <!-- <td class="td-height">{{target.roleLevel}}</td> -->

                <td class="td-height">{{target.incentive}}</td>
                <td class="td-height">{{target.startValue}}</td>
                <td class="td-height">{{target.endValue}}</td>
                <td  style="text-align: center;">
              <button class="btn btn-info" (click)="editIncentiveTier(target)">Edit</button>
                </td>
                
              
              </tr>
            </tbody>
          </table> 
  
        </div>
      </div>
    
  
   
      </div>
      </div>
      </div>
  