import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { Invoice } from 'src/app/crmClasses/invoice';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { InvoiceService } from 'src/app/service/invoice.service';
import { ModalDismissReasons, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { AddNewInvoiceDetailsComponent } from '../add-new-invoice-details/add-new-invoice-details.component';
import { environment } from 'src/environments/environment';
import { InvoiceDetailsList } from 'src/app/crmClasses/invoice-details-list';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
import { ChooseTaxesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/tax/choose-taxes/choose-taxes.component';
import { GlobalClass } from 'src/app/global/global-class';

@Component({ 
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent implements OnInit {
 
  customer : any;
  customers: CompleteCustomerDetails[] = [];
  invoice : Invoice = new Invoice ();
  invoiceDetailsList: InvoiceDetails[] = [];
  closeResult!: string;
  invoiceDet : InvoiceDetails = new InvoiceDetails;
  prod : Product = new Product();
  products: Product[] = [];
  testList: InvoiceDetails[] = []; 
  officeId!: string;
  loggedInUser: any;
  theme : any;
  invDetails: InvoiceDetailsList[] = [];
  totalAmount : any = 0;
  pricePerItem : any;
  clicked = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  addedProductIds : string[] = [];

  constructor(private  productService : ProductService, 
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private compCustDetailsService : CompCustDetailsService,
              private invoiceService : InvoiceService,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef,
              private ngbModalService: NgbModal, 
              private toaster: ToastrService ) { } 

  
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    @Input() fromParent: any;

  ngOnInit(): void { 

    this.invoice.fkCustomerId = "";
    this.invoice.totalAmount = 0;
    this.invoice.subTotalAmount = 0;
    this.invoice.totalTax = 0;
    this.invoice.totalCess = 0;
    this.invoice.grandTotal = 0;
    this.discount = 0;
    
    this.customer = this.fromParent.customer;
    if(this.customer !== null){
    this.customers.push(this.customer);
    this.invoice.fkCustomerId = this.customer.customerId;
    }
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.theme = this.sharedDataService.getCardOfficeTheme();

    if(this.customer === null){
    this.getCustomers();
    }

    this.getProducts();
  }
 
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

// To generate Products in the dropdown
  getProducts(){
    this.productService.getProductListByOfficeId(this.officeId).subscribe(data => {
      this.products= data;
    });
  }

  // To generate Customers in the dropdown
  getCustomers(){
    this.compCustDetailsService.getCcDetailsList(environment.apiUrl + "crmCustAdd/getall/" + this.officeId).then(data => {
      this.customers= data;
    }); 
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }  

  addInvoiceDetails(){
    this.config.class = 'modal-md';
    const modalRef = this.modalService.open(AddNewInvoiceDetailsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      officeId : this.officeId,
      addedProductIds : this.addedProductIds
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceDetails) => {
          this.invoiceDetailsList.push(receivedEntry);
          this.addedProductIds.push(receivedEntry.fkProductId);

        this.pricePerItem = receivedEntry.totalPrice;
        this.totalAmount = this.totalAmount + this.pricePerItem;
        this.invoice.totalAmount = this.totalAmount;
        this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;

     if(this.discounted === true){
      this.invoice.totalAmount = this.invoice.totalAmount - this.discount;
      this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;
     }

        this.invoice.totalTax = 0;
        this.invoice.totalCess = 0;
        this.invoice.grandTotal = 0;

        let count = 0;
        this.taxesAndCess.forEach((value)=>{
          count = count + 1;
          if(value.type === "tax"){
           let val = value.value / 100;
           let tax = this.invoice.totalAmount * val;
           value.calculatedTax = tax;
           this.invoice.totalTax = this.invoice.totalTax + tax;               
          }
        });

        if(this.taxesAndCess.length === count){
          this.taxesAndCess.forEach((value)=>{
            if(value.type === "cess"){
              let val = value.value / 100;
              let cess = this.invoice.totalTax * val;
              value.calculatedTax = cess;
              this.invoice.totalCess = this.invoice.totalCess + cess;               
            }
          });
        }
        
        this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

 let taxList : InvoiceDetails[] = [];
     this.taxesAndCess.forEach((tax)=>{
       let inv = new InvoiceDetails();
       inv.taxId = tax.id;
       inv.taxType = tax.type;
       inv.taxAmount = tax.calculatedTax;
       inv.taxName = tax.name;
       taxList.push(inv);
     });

     this.invoice.taxesAndCess = taxList;


        });  
  }

  onSubmit(){
    
     
    if(this.invoiceDetailsList.length !== 0 && this.invoiceDetailsList !== undefined){

      this.clicked = true;
      
      this.invoice.type = "invoice";
      this.invoice.invDetList = this.invoiceDetailsList;
      
      this.invoice.updateCustomerOutstanding = true;
      
      // authorized
      this.invoiceService.addInvoice(environment.apiUrl + 'completeInvoice/createInvoiceAndSubscriptions', this.invoice).then(
        data=>{
          this.passEntry.emit(this.invoice);
          this.activeModal.close();
          this.toaster.success("Invoice Created Successfully");
        });

    }else{

      this.toaster.error("Add Products to proceed!")

    }
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  recomputeTaxes(){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ChooseTaxesComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {



       this.taxesAndCess = receivedEntry;

          this.invoice.totalTax = 0;
          this.invoice.totalCess = 0;
          this.invoice.grandTotal = 0;

          let count = 0;
          this.taxesAndCess.forEach((value)=>{
            count = count + 1;
            if(value.type === "tax"){
             let val = value.value / 100;
             let tax = this.invoice.totalAmount * val;
             value.calculatedTax = tax;
             this.invoice.totalTax = this.invoice.totalTax + tax;               
            }
          });

          if(this.taxesAndCess.length === count){
            this.taxesAndCess.forEach((value)=>{
              if(value.type === "cess"){
                let val = value.value / 100;
                let cess = this.invoice.totalTax * val;
                value.calculatedTax = cess;
                this.invoice.totalCess = this.invoice.totalCess + cess;               
              }
            });
          }
          
          this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

   let taxList : InvoiceDetails[] = [];
       this.taxesAndCess.forEach((tax)=>{
         let inv = new InvoiceDetails();
         inv.taxId = tax.id;
         inv.taxType = tax.type;
         inv.taxAmount = tax.calculatedTax;
         inv.taxName = tax.name;
         taxList.push(inv);
       });

       this.invoice.taxesAndCess = taxList;

  //      this.invDetails.forEach((invDet)=>{
  //       if(invDet.type === "tax" || invDet.type === "cess"){
  //         this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
  //           data=>{
  //           });
  //       }
  //     });

  //     this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
  //       data=>{

  //         // this.getInvoiceDetails();

  //       });




        });  

 
 
}


  discount : any;
  discountObj : InvoiceDetailsList = new InvoiceDetailsList();
  discounted = false;
  taxesAndCess : Tax[] = [];

  editDiscount(){

    this.invoice.totalAmount = 0;
    this.invoiceDetailsList.forEach((invDet)=>{
      this.invoice.totalAmount = this.invoice.totalAmount + invDet.totalPrice;
    })
    this.invoice.totalAmount = this.invoice.totalAmount - this.discount;
    this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;

    this.invoice.totalTax = 0;
    this.invoice.totalCess = 0;
    this.invoice.grandTotal = 0;

    let count = 0;
    this.taxesAndCess.forEach((value)=>{
      count = count + 1;
      if(value.type === "tax"){
       let val = value.value / 100;
       let tax = this.invoice.totalAmount * val;
       value.calculatedTax = tax;
       this.invoice.totalTax = this.invoice.totalTax + tax;               
      }
    });

    if(this.taxesAndCess.length === count){
      this.taxesAndCess.forEach((value)=>{
        if(value.type === "cess"){
          let val = value.value / 100;
          let cess = this.invoice.totalTax * val;
          value.calculatedTax = cess;
          this.invoice.totalCess = this.invoice.totalCess + cess;               
        }
      });
    }
    
    this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

let taxList : InvoiceDetails[] = [];
 this.taxesAndCess.forEach((tax)=>{
   let inv = new InvoiceDetails();
   inv.taxId = tax.id;
   inv.taxType = tax.type;
   inv.taxAmount = tax.calculatedTax;
   inv.taxName = tax.name;
   taxList.push(inv);
 });

 this.invoice.taxesAndCess = taxList;

//  this.invDetails.forEach((invDet)=>{
//   if(invDet.type === "tax" || invDet.type === "cess"){
//     this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
//       data=>{
//       });
//   }
// });

let discount : InvoiceDetails = new InvoiceDetails();
discount.taxAmount = this.discount;
discount.fkInvoiceId = this.invoice.invoiceId;

this.invoice.discountObj = discount;
// this.discountObj.taxAmount = discount.taxAmount;

  }

  addDiscountToInvoice(){

    this.invoice.totalAmount = this.invoice.totalAmount - this.discount;
    this.invoice.subTotalAmount = this.invoice.totalAmount + this.discount;

    this.invoice.totalTax = 0;
    this.invoice.totalCess = 0;
    this.invoice.grandTotal = 0;

    let count = 0;
    this.taxesAndCess.forEach((value)=>{
      count = count + 1;
      if(value.type === "tax"){
       let val = value.value / 100;
       let tax = this.invoice.totalAmount * val;
       value.calculatedTax = tax;
       this.invoice.totalTax = this.invoice.totalTax + tax;               
      }
    });

    if(this.taxesAndCess.length === count){
      this.taxesAndCess.forEach((value)=>{
        if(value.type === "cess"){
          let val = value.value / 100;
          let cess = this.invoice.totalTax * val;
          value.calculatedTax = cess;
          this.invoice.totalCess = this.invoice.totalCess + cess;               
        }
      });
    }
    
    this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

let taxList : InvoiceDetails[] = [];
 this.taxesAndCess.forEach((tax)=>{
   let inv = new InvoiceDetails();
   inv.taxId = tax.id;
   inv.taxType = tax.type;
   inv.taxAmount = tax.calculatedTax;
   inv.taxName = tax.name;
   taxList.push(inv);
 });

 this.invoice.taxesAndCess = taxList;

//  this.invDetails.forEach((invDet)=>{
//   if(invDet.type === "tax" || invDet.type === "cess"){
//     this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
//       data=>{
//       });
//   }
// });

let discount : InvoiceDetails = new InvoiceDetails();
discount.taxAmount = this.discount;
discount.fkInvoiceId = this.invoice.invoiceId;

this.invoice.discountObj = discount;
this.discountObj.taxAmount = discount.taxAmount;
this.discounted = true;

// this.invoiceService.addDiscountDetails(environment.apiUrl + "completeInvoice/addDiscountDetails", discount).then(
//   data=>{

//     this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
//       data=>{
    
//         this.invoiceService.updatePartialInvoiceDetails(environment.apiUrl + "invoice/updatePartialInvoiceDetails", this.invoice).then(
//           data=>{

//             this.toaster.success("Discount applied and Invoice updated successfully !")
//             this.getInvoiceDetails();
//             this.discounted = true;

//             this.invoice.totalBeforeDiscount = this.invoice.totalAmount - this.discount;

//           });
    
//       });

//   });




  }
}
