import { ActivityPlanner } from "./activity-planner";
import { Subject } from "./subject";

export class GetCPTCDetails { 
    
    // Parent details 
    teacherId!: string;
	parentUserRoleOfficeId!: string;
	parentUserId!: string;
	parentFirstName!: string;
	parentLastName!: string;
	parentImage!: string;
	nextActivityDate : any;

	toasterMessage : any;
	todaysActivityComplete:any
	// Child details
    studentId!: string;
	childUserRoleOfficeId!: string;
	childUserId!: string;
	childFirstName!: string;
	childPetname : any;
	childLastName!: string;
	childImage!: string;
	skillReport : any;
	lastSkillReportTimestamp : any;
	
	// Class Details
    classId!: string;

	// Product Details
    productId!: string;

	// Subject Details
    subjectList: Subject[] = [];

    // Activity Planners
    activityPlannerList : ActivityPlanner[] = [];

	// Progress Details
	progressValue : any;

	activityLinkMessage : any;
	linkStatus : any;

}
