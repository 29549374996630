import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from '../customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService { 

  private deleteUrl = environment.apiUrl +  "customer/delete";
  private getUrl =  environment.apiUrl + "customer/getById";

  constructor(private httpClient : HttpClient) { }
 
  deleteCustomer(customerId : string) : Observable<Object>{
    return this.httpClient.delete(`${this.deleteUrl}/${customerId}`);
  }
  addCustomer(customer : Customer):Observable<Customer>{
    return this.httpClient.post<Customer>( environment.apiUrl + 'customer/add', customer);
  }
  getCustomerById(customerId : string):Observable<Customer>{
   return this.httpClient.get<Customer>(`${this.getUrl}/${customerId}`);
  } 

} 
