import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { FormControl } from "@angular/forms";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotUserGroup } from "src/app/BOT_CODE/bot_classes/bot-user-group";
import { TableUtil } from "src/app/table-util";
import { BotUserGroupDetails } from "src/app/BOT_CODE/bot_classes/bot-user-group-details";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";

@Component({
  selector: "app-bot-user-group-modal",
  templateUrl: "./bot-user-group-modal.component.html",
  styleUrls: ["./bot-user-group-modal.component.scss"],
})
export class BotUserGroupModalComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;
  closeResult!: string;

  constructor(
    private ngbModalService: NgbModal,
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  allEligibleUsers: BotCustomerUser[] = [];
  selectedUsers: BotCustomerUser[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botCustomerUser: BotCustomerUser = new BotCustomerUser();
  searchText: any;
  botUserGroup: BotUserGroup = new BotUserGroup();
  context: any;
  matSpinnerActive = false;

  agentUroToAssign: any;
  agents: BotUser[] = [];
  usersFetched = false;
  agentUroSelected = null;
  dataLoaded = false;
  searchText1: any;
  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;
    if (this.context === "createBotUserGroup") {
      this.botUserGroup.botUserGrpId = null;
    } else if (this.context === "editBotUserGroup") {
      this.botUserGroup = this.fromParent.botUserGroup;
    } else if (this.context === "viewBotUserGroupDetails") {
      this.botUserGroup = this.fromParent.botUserGroup;
      this.botOfficeService
        .getGroups(
          environment.apiUrl +
            "botConvo/getBotUserGroupDetailsByFkBotUserGroupId/" +
            this.botUserGroup.botUserGrpId
        )
        .then((res) => {
          this.botUserGroup = res;
          this.dataLoaded = true;
        });
    } else if (this.context === "assignMultipleUsersToAgent") {
      this.agents = this.fromParent.agents;
      this.agentUroToAssign = this.fromParent.agentUroToAssign;
    }
  }

  botUserGroupDetailsStatusUpdate(id: any) {
    this.botOfficeService
      .statusUpdate(
        environment.apiUrl + "botConvo/botUserGroupDetailsStatusUpdate/" + id
      )
      .then((res) => {
        this.botUserGroup.groupDetails.forEach((det) => {
          if (det.botUserGrpDetailsId === id) {
            det.botUserGrpDetailsStatus = !det.botUserGrpDetailsStatus;
          }
        });
      });
  }

  toppingsControl = new FormControl([]);
  multiSelectValueChange(value: any) {}

  onToppingRemoved(user: any) {
    this.allEligibleUsers.push(user);
    for (var i = this.selectedUsers.length - 1; i >= 0; --i) {
      if (this.selectedUsers[i].botUserId == user.botUserId) {
        this.selectedUsers.splice(i, 1);
      }
    }

    if (this.selectedAll === true) {
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
    }
  }

  selectedAll = false;
  selectAll(value: any, botUserObj: any) {
    // case 1
    if (value === "Select All" && this.selectedAll === false) {
      let allUserIds: any[] = [];
      allUserIds.push(value);
      this.allEligibleUsers.forEach((user) => {
        this.selectedUsers.push(user);
        allUserIds.push(user.botUserId);
      });
      this.allEligibleUsers = [];
      this.toppingsControl.setValue(allUserIds);
      this.selectedAll = true;
      // case 2
    } else if (value === "Select All" && this.selectedAll === true) {
      this.selectedUsers.forEach((user) => {
        this.allEligibleUsers.push(user);
      });
      this.selectedUsers = [];
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
      // case 3
    } else if (value !== "Select All" && this.selectedAll === true) {
      this.selectedUsers.push(botUserObj);
      for (var i = this.allEligibleUsers.length - 1; i >= 0; --i) {
        if (this.allEligibleUsers[i].botUserId == botUserObj.botUserId) {
          this.allEligibleUsers.splice(i, 1);
        }
      }
      let selectedUserIds: any[] = this.toppingsControl.value;
      selectedUserIds.splice(selectedUserIds.indexOf("Select All"), 1);
      this.selectedAll = false;
      this.toppingsControl.setValue(selectedUserIds);
      // case 4
    } else if (value !== "Select All" && this.selectedAll === false) {
      this.selectedUsers.push(botUserObj);
      for (var i = this.allEligibleUsers.length - 1; i >= 0; --i) {
        if (this.allEligibleUsers[i].botUserId == botUserObj.botUserId) {
          this.allEligibleUsers.splice(i, 1);
        }
      }
    }
  }

  createOrEditBotUserGroup() {
    let userIds: string[] = [];
    this.selectedUsers.forEach((user) => {
      userIds.push(user.botUserId);
    });

    const index = userIds.indexOf("Select All", 0);
    if (index > -1) {
      userIds.splice(index, 1);
    }

    // if (
    //   userIds !== null &&
    //   userIds.length > 0 &&
    //   this.context === "createBotUserGroup"
    // ) {
    //   this.botUserGroup.grpMemberBotUserIds = userIds;
    //   this.clicked = true;
    //   this.botOfficeService
    //     .createOrEditGroups(
    //       environment.apiUrl + "botConvo/create/edit/botUserGroup",
    //       this.botUserGroup
    //     )
    //     .then((res) => {
    //       this.botUserGroup = res;
    //       this.toastr.success("Group created successfully !");
    //       this.passEntry.emit(this.botUserGroup);
    //       this.activeModal.close();
    //     });
    // }

    if (this.context === "createBotUserGroup") {
      this.botUserGroup.grpMemberBotUserIds = userIds;
      this.clicked = true;
      this.botOfficeService
        .createOrEditGroups(
          environment.apiUrl + "botConvo/create/edit/botUserGroup",
          this.botUserGroup
        )
        .then((res) => {
          this.botUserGroup = res;
          this.toastr.success("Group created successfully !");
          this.passEntry.emit(this.botUserGroup);
          this.activeModal.close();
        });
    }

    // if (
    //   (userIds === null && this.context === "createBotUserGroup") ||
    //   (this.context === "createBotUserGroup" &&
    //     userIds !== null &&
    //     userIds.length === 0)
    // ) {
    //   this.clicked = false;
    //   this.toastr.error("Choose Users to proceed !");
    // }

    if (this.context === "editBotUserGroup") {
      this.botUserGroup.grpMemberBotUserIds = userIds;
      this.clicked = true;
      this.botOfficeService
        .createOrEditGroups(
          environment.apiUrl + "botConvo/create/edit/botUserGroup",
          this.botUserGroup
        )
        .then((res) => {
          this.botUserGroup = res;
          this.toastr.success("Group updated successfully !");
          this.passEntry.emit(this.botUserGroup);
          this.activeModal.close();
        });
    }
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  dataSource: BotUserGroupDetails[] = [];
  exportUsersArray(users: BotUserGroupDetails[]) {
    if (users !== null && users !== undefined && users.length > 0) {
      this.dataSource = this.botUserGroup.groupDetails;
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map((x) => ({
        Name: x.userName,
        Phone: x.userIdentifier,
        Last_Msg_From: x.lastMessageFrom,
        Last_Msg: x.lastMessage,
        Last_Msg_Timestamp: x.lastMessageTimestamp,
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "members");
    } else {
      this.toastr.error("No Users to download !");
    }
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getAllBotCustomerUsersByOfficeId() {
    this.matSpinnerActive = true;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBotUserNamesAndBotUserIdsByOfficeId"
      )
      .then((res) => {
        this.botCustomerUser = res;
        this.allEligibleUsers = this.botCustomerUser.users;
        this.selectedUsers = [];
        this.matSpinnerActive = false;

        if (this.context === "editBotUserGroup") {
          let existingUsers: any[] = [];
          this.botOfficeService
            .getGroups(
              environment.apiUrl +
                "botConvo/getBotUserGroupDetailsByFkBotUserGroupId/" +
                this.botUserGroup.botUserGrpId
            )
            .then((res) => {
              let resGrp: BotUserGroup = res;
              resGrp.groupDetails.forEach((det) => {
                existingUsers.push(det.fkBotUserId);
              });

              let allBotUsers = this.allEligibleUsers;
              this.allEligibleUsers = [];

              allBotUsers.forEach((user) => {
                if (!existingUsers.includes(user.botUserId)) {
                  this.allEligibleUsers.push(user);
                }
              });
            });
        }
      });
  }

  getBotUserNamesAndBotUserIdsOfBotUSersWithoutGroupsByOfficeId() {
    this.matSpinnerActive = true;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl +
          "botOffice/getBotUserNamesAndBotUserIdsOfBotUSersWithoutGroupsByOfficeId"
      )
      .then((res) => {
        this.botCustomerUser = res;
        this.allEligibleUsers = this.botCustomerUser.users;
        this.selectedUsers = [];
        this.matSpinnerActive = false;

        // if (this.context === "editBotUserGroup") {
        //   let existingUsers: any[] = [];
        //   this.botOfficeService
        //     .getGroups(
        //       environment.apiUrl +
        //         "botConvo/getBotUserGroupDetailsByFkBotUserGroupId/" +
        //         this.botUserGroup.botUserGrpId
        //     )
        //     .then((res) => {
        //       let resGrp: BotUserGroup = res;
        //       resGrp.groupDetails.forEach((det) => {
        //         existingUsers.push(det.fkBotUserId);
        //       });

        //       let allBotUsers = this.allEligibleUsers;
        //       this.allEligibleUsers = [];

        //       allBotUsers.forEach((user) => {
        //         if (!existingUsers.includes(user.botUserId)) {
        //           this.allEligibleUsers.push(user);
        //         }
        //       });
        //     });
        // }
      });
  }

  searchValue = null;
  searchType = "starting with";
  searchBotUsersByNameOrPhone() {
    if (this.searchValue !== null && this.searchValue !== "") {
      this.botOfficeService
        .getBotUsers(
          environment.apiUrl +
            "botOffice/searchBotUsersByNameOrPhone/" +
            this.searchValue
        )
        .then((res) => {
          this.botCustomerUser = res;
          this.allEligibleUsers = this.botCustomerUser.users;
          this.selectedUsers = [];
          this.matSpinnerActive = false;

          if (this.allEligibleUsers.length > 0) {
            if (this.context === "editBotUserGroup") {
              let existingUsers: any[] = [];
              this.botOfficeService
                .getGroups(
                  environment.apiUrl +
                    "botConvo/getBotUserGroupDetailsByFkBotUserGroupId/" +
                    this.botUserGroup.botUserGrpId
                )
                .then((res) => {
                  let resGrp: BotUserGroup = res;
                  resGrp.groupDetails.forEach((det) => {
                    existingUsers.push(det.fkBotUserId);
                  });

                  let allBotUsers = this.allEligibleUsers;
                  this.allEligibleUsers = [];

                  allBotUsers.forEach((user) => {
                    if (!existingUsers.includes(user.botUserId)) {
                      this.allEligibleUsers.push(user);
                    }
                  });
                });
            }
          } else {
            this.toastr.error("Users Not Found");
          }
        });
    } else {
      this.toastr.error("Enter name or phone number to search");
    }
  }

  getUsersByAgent(type: any) {
    if (this.agentUroSelected !== null && this.agentUroSelected !== undefined) {
      this.getCustomerUsersForAgentReassignment(type);
    } else {
      this.toastr.error("Choose Agent to proceed !");
    }
  }

  // Users - Agents Reassignment
  getCustomerUsersForAgentReassignment(type: any) {
    this.matSpinnerActive = true;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl +
          "botOffice/agentReassignment/getBotUserNamesAndBotUserIdsByOfficeId/" +
          type +
          "/" +
          this.agentUroSelected
      )
      .then((res) => {
        this.agentUroSelected = null;
        this.botCustomerUser = res;
        if (
          this.botCustomerUser.users !== null &&
          this.botCustomerUser.users !== undefined &&
          this.botCustomerUser.users.length > 0
        ) {
          this.allEligibleUsers = this.botCustomerUser.users;

          this.allEligibleUsers.forEach((eUser) => {
            this.agents.forEach((agent) => {
              if (eUser.fkUroIdManualChat === agent.agentUroId) {
                eUser.assignedAgentName =
                  agent.firstName + " " + agent.lastName;
              }
            });
          });

          this.selectedUsers = [];
          this.matSpinnerActive = false;
          this.usersFetched = true;
        } else {
          this.usersFetched = false;
          this.toastr.error("Users not found !");
          this.matSpinnerActive = false;
        }
      });
  }

  searchBotUsersByNameOrPhoneForAgentReassignment() {
    this.matSpinnerActive = true;
    if (this.searchValue !== null && this.searchValue !== "") {
      this.botOfficeService
        .getBotUsers(
          environment.apiUrl +
            // "botOffice/searchBotUsersByNameOrPhone/" +
            // this.searchValue
            "botOffice/searchBotUsersByNameOrPhoneAndSearchType/" +
            this.searchValue +
            "/" +
            this.searchType
        )
        .then((res) => {
          this.botCustomerUser = res;
          this.allEligibleUsers = this.botCustomerUser.users;

          this.allEligibleUsers.forEach((eUser) => {
            this.agents.forEach((agent) => {
              if (eUser.fkUroIdManualChat === agent.agentUroId) {
                eUser.assignedAgentName =
                  agent.firstName + " " + agent.lastName;
              }
            });
          });

          this.selectedUsers = [];
          if (this.allEligibleUsers.length > 0) {
            this.usersFetched = true;
          } else {
            this.usersFetched = false;
            this.toastr.error("Users Not Found");
          }
          this.matSpinnerActive = false;
        });
    } else {
      this.matSpinnerActive = false;
      this.toastr.error("Enter name or phone number to search");
    }
  }

  assignOnSubmit() {
    this.clicked = true;
    this.matSpinnerActive = true;
    let userIds: string[] = [];
    this.selectedUsers.forEach((user) => {
      userIds.push(user.botUserId);
    });

    const index = userIds.indexOf("Select All", 0);
    if (index > -1) {
      userIds.splice(index, 1);
    }

    if (userIds !== null && userIds.length > 0) {
      let bUser: BotUser = new BotUser();
      bUser.agentUroId = this.agentUroToAssign;
      bUser.userIds = userIds;
      this.botOfficeService
        .createOrEditGroups(
          environment.apiUrl +
            "botOffice/agentReassignment/assignMultipleUsers",
          bUser
        )
        .then((res) => {
          this.toastr.success("Users assigned successfully !");
          this.passEntry.emit("success");
          this.activeModal.close();
        });
    } else {
      this.clicked = false;
      this.matSpinnerActive = false;
      this.toastr.error("Choose Users to proceed !");
    }
  }

  searchBotUsersByCustomVarForAgentReassignment(varNo: any) {
    this.matSpinnerActive = true;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl +
          "botOffice/searchBotUsersByCustomVarForAgentReassignment/" +
          varNo +
          "/" +
          this.searchValue
      )
      .then((res) => {
        this.botCustomerUser = res;
        this.allEligibleUsers = this.botCustomerUser.users;

        this.allEligibleUsers.forEach((eUser) => {
          this.agents.forEach((agent) => {
            if (eUser.fkUroIdManualChat === agent.agentUroId) {
              eUser.assignedAgentName = agent.firstName + " " + agent.lastName;
            }
          });
        });

        this.selectedUsers = [];
        if (this.allEligibleUsers.length > 0) {
          this.usersFetched = true;
        } else {
          this.usersFetched = false;
          this.toastr.error("Users Not Found");
        }
        this.matSpinnerActive = false;
      });
  }
}
