
<nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
  <div *ngIf="this.loggedInUser.offices[0].logo !== null">
    <img height="30" width="30" class="logo-image"
      [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
  </div>
  <div *ngIf="this.loggedInUser.offices[0].logo === null">
    <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
  </div>
  <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button> 

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-user"></i> Account </a>
      </li>

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
      </li>


      <li class="nav-item" style="float: right;">
        <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
          <i class="fa fa-sign-out" ></i> Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>

<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" (click)="goToHomePage();" ><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
    <li style="cursor: pointer;" class="breadcrumb-item"  onclick="history.back()" aria-current="page">Subjects</li>
    <li style="cursor: pointer;" class="breadcrumb-item active" onclick="history.back()" aria-current="page">Curriculum</li>
  </ol>
</nav>
<br>

<div *ngIf="curriculumsCreated === true" class="row col-lg-12 col-md-12" >



<div class="col-lg-3 col-md-12" style="padding: 0%;">


  <div class="table-header row col-lg-12 col-md-12" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">

      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Curriculum
      </span>
      <a style="cursor:pointer;" class="float-right" (click)="addNewCurr();">
        <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
      </a>


    </div> 
  </div>

 
  <div *ngIf="curriculumExists === true" class="card mat-elevation-z12 col-lg-12 col-md-12" >
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" >
      <mat-tree-node style="cursor: pointer;" (click)="getDetails(node.curriculumId)" *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        {{node.name}}
      </mat-tree-node>
      
      <mat-tree-node style="cursor: pointer;" (click)="getDetails(node.curriculumId)" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </mat-tree-node>
    </mat-tree>
  </div>



</div>

<div class="col-lg-9 col-md-12" style="padding: 0%;" *ngIf="infoState === false">


  
      

  <div style="text-align: center;margin-top: 5%;" *ngIf="infoState === false">

    <a style="text-align: center;font-size: 1.3em; font-weight: bold; color: black;">
      Click on a Curriculum name to explore.
    </a>
  </div>





</div>

<div class="col-lg-9 col-md-12" style="padding: 0%;" *ngIf="infoState === true">


  <div class="table-header row col-lg-12 col-md-12" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        {{curriculumObject.name}}
      </span>
      <a class="float-right" style="color: white;float: right;cursor: pointer;" (click)="actionCollapse = !actionCollapse" cdkOverlayOrigin #trigger0="cdkOverlayOrigin">
        <img src="/assets/images/icons/settings.svg" width="25" height="25" alt="" loading="lazy">
      </a>
      <ng-template
cdkConnectedOverlay
[cdkConnectedOverlayOrigin]="trigger0"
[cdkConnectedOverlayOpen]="actionCollapse" [cdkConnectedOverlayHasBackdrop]="true"
[cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" 
(backdropClick)="actionCollapse = !actionCollapse">

<ul class="example-list">
  <li style="cursor: pointer;" (click)="editCurriculum(curriculumObject.curId)" >Edit Curriculum</li>
  <li style="cursor: pointer;" *ngIf="curriculumObject.curriculumLevel !== '3'" (click)="addSubCurr(curriculumObject.curId);">Add Sub-Curriculum</li>
  <li style="cursor: pointer;" (click)="changeCurriculumOrder();">Swap Order</li>
  <li style="cursor: pointer;" (click)="addNewActPlnr();">Add Activity</li>
  <li style="cursor: pointer;" *ngIf="curriculumObject.subCurriculumsExist === false" (click)="open(confirmCurriculumDeactivation);deactivateApId=curriculumObject.curId">Delete</li>
  <li style="cursor: pointer;" *ngIf="curriculumObject.subCurriculumsExist === true" (click)="open(subCurriculumDeactivationMessage);">Delete</li>

</ul>
</ng-template>


    </div> 
  </div>

 
<div class="card mat-elevation-z12 ">
 
  <!-- <h4 style="padding: 1em;">{{curriculumObject.description}}</h4> -->
  <div *ngIf="curriculumObject.description !== null && curriculumObject.description !== undefined" style="padding: 1em;">
    {{(readMore) ? curriculumObject.description : curriculumObject.description | slice:0:150}} <span *ngIf="!readMore"></span>
    <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!readMore && curriculumObject.description.length >150" (click)="readMore=true">...</a>
    <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="readMore && curriculumObject.description.length >150" (click)="readMore=false">[Read less]</a>

</div>

  <div>
  
<mat-tab-group  style="padding: 1em;" dynamicHeight>
<mat-tab label="Activities">   
  <br *ngIf="apDontExist === true">
      <h3 *ngIf="apDontExist === true" style="color: black;">No Activity to display !</h3>
      <br *ngIf="apDontExist === true">
  <div fxLayout="row wrap" fxLayoutGap="16px grid" >
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let card of activityPlanners2">
      <mat-card class="mat-elevation-z12" style="border-radius: 25px;margin: 1em;">
       
      <div style="width: 100%; cursor:pointer;margin-bottom: 10%; ">  
        
      <a style="float: right; cursor: pointer;" id="card.activityPlannerName" (click)="card.collapsedState2 = !card.collapsedState2" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <img src="/assets/images/icons/settingsA.svg" width="25" height="25" alt="" loading="lazy">
      </a>

<ng-template
cdkConnectedOverlay
[cdkConnectedOverlayOrigin]="trigger"
[cdkConnectedOverlayOpen]="card.collapsedState2" [cdkConnectedOverlayHasBackdrop]="true"
[cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" 
(backdropClick)="card.collapsedState2 = !card.collapsedState2">

<ul class="example-list">
<li style="cursor: pointer;" (click)="editactivityPlanner(card.activityPlannerId);card.collapsedState2=false;">Edit</li>
<li style="cursor: pointer;" (click)="addActivityQuestionsModal(card.activityPlannerId);card.collapsedState2=false;">Add Question</li>
<li style="cursor: pointer;" (click)="viewActivityQuestionsModal(card.activityPlannerId);card.collapsedState2=false;">View Questions</li>
<li style="cursor: pointer;" (click)="addVideoModal(card.activityPlannerId);card.collapsedState2=false;">Add Video</li>
<li style="cursor: pointer;" (click)="generateQuestions(card.activityPlannerId, card.activityPlannerDescription);card.collapsedState2=false;">Generate Questions</li>
<!-- <li style="cursor: pointer;" (click)="clickMethod('name')">Deactivate</li> -->
<li style="cursor: pointer;" (click)="open(confirm);card.collapsedState2=false;deactivateApId=card.activityPlannerId" >Delete</li>

</ul>
</ng-template>
    </div>
    <br>
    
      
        <div style="width: 100%;text-align: center;">
          <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
        </div>

        <br>
       
          <div style="text-align: center;">
          <h3 style="color: black;">{{card.activityPlannerName}} </h3> 
      </div>
  
      
        <!-- <div style="text-align: center;">
          <p>
            {{card.activityPlannerDescription}}
          </p>
        </div>  -->
        <div class="mat-card-subtitle" *ngIf="card.activityPlannerDescription !== null && card.activityPlannerDescription !== undefined" >
          {{(card.readMore) ? card.activityPlannerDescription : card.activityPlannerDescription | slice:0:70}} <span *ngIf="!card.readMore"></span>
          <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=true">...</a>
          <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=false">[Read less]</a>
       
      </div>
        <div class="card-body">
     
          <div *ngIf="card.collapsedState === true" >
           
            
  
              <mat-vertical-stepper>
    
                <div class="stepper-class">
    
                <mat-step [editable]="true" [completed]="true"  *ngFor="let video of card.activityVideoLinkList">
                  <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                    </div>
    
                      <br>
            
                      {{video.actVideoLinkDescription}}
                      <br>
                      
                      <button style="margin: 2px;width: auto;" class="btn btn-dark" (click)="editVideo(video.actVideoLinkId)">Edit Video</button> 
                      
                </mat-step>
                
            </div>
    
            
              </mat-vertical-stepper>
    
       
          
            </div>
     
            
          <button mat-button color="primary" (click)="collapseActivityCardContent(card.activityPlannerId)">
              {{card.collapsedState ? 'Close' : 'More'}}</button>
          </div> 
      </mat-card>
    </div>
  </div> </mat-tab>

<mat-tab label="Assignments" *ngIf="officeObject.oa_Assignment === true"> 

  
  
  <br *ngIf="asDontExist === true">
  <h3 *ngIf="asDontExist === true" style="color: black;">No Assignments to display !</h3>
  <br *ngIf="asDontExist === true">
  <div fxLayout="row wrap" fxLayoutGap="16px grid" >
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let card of assignments">
      <mat-card class="mat-elevation-z12" style="border-radius: 25px;margin: 1em;">
       
      <div style="width: 100%; cursor:pointer;margin-bottom: 10%;">  

        <a style="float: right;cursor: pointer;" (click)="card.collapsedState2 = !card.collapsedState2" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
          <img src="/assets/images/icons/settingsA.svg" width="25" height="25" alt="" loading="lazy">
        </a>


<ng-template
cdkConnectedOverlay
[cdkConnectedOverlayOrigin]="trigger"
[cdkConnectedOverlayOpen]="card.collapsedState2"  [cdkConnectedOverlayHasBackdrop]="true"
[cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" 
(backdropClick)="card.collapsedState2 = !card.collapsedState2"
>
<ul class="example-list">
  <li style="cursor: pointer;" (click)="editactivityPlanner(card.activityPlannerId);card.collapsedState2=false;">Edit</li>
  <li style="cursor: pointer;" (click)="addActivityQuestionsModal(card.activityPlannerId);card.collapsedState2=false;">Add Question</li>
  <li style="cursor: pointer;" (click)="viewActivityQuestionsModal(card.activityPlannerId);card.collapsedState2=false;">View Questions</li>
  <li style="cursor: pointer;" (click)="addVideoModal(card.activityPlannerId);card.collapsedState2=false;">Add Video</li>
  <li style="cursor: pointer;" (click)="open(confirmAssignmentDeactivation);card.collapsedState2=false;deactivateApId=card.activityPlannerId" >Delete</li>
</ul>
</ng-template>

       
    </div>
      <br>
        <div style="width: 100%;text-align: center;">
          <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
        </div>
        <br>
          <div style="text-align: center;">
          <h3 style="color: black;">{{card.activityPlannerName}} </h3> 
      </div>
       
     
        <!-- <div style="text-align: center;">
          <p>
            {{card.activityPlannerDescription}}
          </p>
        </div> -->

        <div class="mat-card-subtitle" *ngIf="card.activityPlannerDescription !== null && card.activityPlannerDescription !== undefined" >
          {{(card.readMore) ? card.activityPlannerDescription : card.activityPlannerDescription | slice:0:70}} <span *ngIf="!card.readMore"></span>
          <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=true">...</a>
          <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=false">[Read less]</a>
       
      </div>

        <div class="card-body">
     
          <div *ngIf="card.collapsedState === true" >
           
  
              <mat-vertical-stepper>
    
                <div class="stepper-class">
    
                <mat-step [editable]="true" [completed]="true"  *ngFor="let video of card.activityVideoLinkList">
                  <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                    <p></p>
    
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                    </div>
    
                      <br>
            
                      {{video.actVideoLinkDescription}}
                      <br>
                      
                </mat-step>
                
            </div>
    
            
              </mat-vertical-stepper>
    
       
          
            </div>
     
            
          <button mat-button color="primary" (click)="collapseActivityCardContent2(card.activityPlannerId)">
              {{card.collapsedState ? 'Close' : 'More'}}</button>
          </div> 
      </mat-card>
    </div>
  </div> </mat-tab>

<mat-tab label="Parent Planners" *ngIf="officeObject.oa_ParentFeedback === true" > 
  
  <br *ngIf="apafDontExist === true" >
  <h3 *ngIf="apafDontExist === true" style="color: black;">No Parent Activity / Feedback to display !</h3>
  <br *ngIf="apafDontExist === true" >
 
  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let card of parentRecurringActivityPlanners">
      <mat-card  *ngIf="apafExist === true"  class="mat-elevation-z12" style="border-radius: 25px;margin: 1em;">
       
        <div style="width: 100%; cursor:pointer;margin-bottom: 10%;">  

          <a style="float: right;cursor: pointer;" (click)="card.collapsedState2 = !card.collapsedState2" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <img src="/assets/images/icons/settingsA.svg" width="25" height="25" alt="" loading="lazy">
          </a>


<!-- This template displays the overlay content and is connected to the button -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="card.collapsedState2"  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" 
  (backdropClick)="card.collapsedState2 = !card.collapsedState2"
>
  <ul class="example-list">
    <li style="cursor: pointer;" (click)="editactivityPlanner(card.activityPlannerId);card.collapsedState2=false;">Edit</li>
    <li style="cursor: pointer;" (click)="addActivityQuestionsModal(card.activityPlannerId);card.collapsedState2=false;">Add Question</li>
    <li style="cursor: pointer;" (click)="viewActivityQuestionsModal(card.activityPlannerId);card.collapsedState2=false;">View Questions</li>
    <li style="cursor: pointer;" (click)="addVideoModal(card.activityPlannerId);card.collapsedState2=false;">Add Video</li>
    <li style="cursor: pointer;" (click)="open(confirmParentPlannerDeactivation);card.collapsedState2=false;deactivateApId=card.activityPlannerId" >Delete</li>
</ul>
</ng-template>

         
      </div>
      <br>
        <div style="width: 100%;text-align: center;">
          <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
        </div>
       <br>
          <div style="text-align: center;">
          <h3 style="color: black;">{{card.activityPlannerName}} </h3> 
      </div>
        
     
        <!-- <div style="text-align: center;">
          <p>
            {{card.activityPlannerDescription}}
          </p>
        </div> -->

        <div class="mat-card-subtitle" *ngIf="card.activityPlannerDescription !== null && card.activityPlannerDescription !== undefined" >
          {{(card.readMore) ? card.activityPlannerDescription : card.activityPlannerDescription | slice:0:70}} <span *ngIf="!card.readMore"></span>
          <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=true">...</a>
          <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=false">[Read less]</a>
       
      </div>

        <div class="card-body">
     
          <div *ngIf="card.collapsedState === true" >
           
  
              <mat-vertical-stepper>
    
                <div class="stepper-class">
    
                <mat-step [editable]="true" [completed]="true"  *ngFor="let video of card.activityVideoLinkList">
                  <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                    <p></p>
    
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                    </div>
    
                      <br>
            
                      {{video.actVideoLinkDescription}}
                      <br>
                      
                </mat-step>
                
            </div>
    
            
              </mat-vertical-stepper>
    
       
          
            </div>
     
            
          <button mat-button color="primary" (click)="collapseActivityCardContent2(card.activityPlannerId)">
              {{card.collapsedState ? 'Close' : 'More'}}</button>
          </div> 
      </mat-card>
    </div>
  </div> </mat-tab>
</mat-tab-group>
  </div>
  </div>



</div>

</div>

<div *ngIf="createFirstCurriculum === true" style="text-align: center;margin-top: 10%;" class="col-lg-12 col-md-12">

  <a style="cursor:pointer;" style="color: black;" (click)="addNewCurr();"> 
    <img src="/assets/images/icons/plus.png" width="100" height="100" alt="" loading="lazy"> <br> <br>
    Click to start creating a curriculum
  </a>

</div>

<br>
<br>
<br>


<ng-template #confirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to delete Activity?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-outline-dark" (click)="deactivateActivityPlanner();modal.dismiss('Cross click')" >Delete</button>
  </div>
</ng-template>

<ng-template #confirmAssignmentDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to delete Assignment ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-outline-dark" (click)="deactivateAssignment();modal.dismiss('Cross click')" >Delete</button>
  </div>
</ng-template>

<ng-template #confirmParentPlannerDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to delete parent planner ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-outline-dark" (click)="deactivateParentPlanner();modal.dismiss('Cross click')" >Delete</button>
  </div>
</ng-template>

<ng-template #confirmCurriculumDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Deleting this curriculum will delete all activities, 
    assignments and parent planners associated with it.
    Are you sure to delete curriculum ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-outline-dark" (click)="deactivateCurriculum();modal.dismiss('Cross click')" >Delete</button>
  </div>
</ng-template>

<ng-template #subCurriculumDeactivationMessage let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Please first delete sub-curriculums under this curriculum !
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')" >Close</button>
  </div>
</ng-template>

