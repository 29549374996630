<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

<main class="content">
    <div class="container p-0">

        <!-- <br>
        <button class="btn btn-info" style="width: auto;"
        (click)="createCrmList();">Create List</button>
        <br> -->

        <!-- <h1 class="h3 mb-3">Kanban Board</h1> -->
<br>

<div class="row">
    <div *ngFor="let crmList of crmLists" class="col-12 col-lg-6 col-xl-3">
        <div class="card card-border-primary">
            <div class="card-header">
                <div class="card-actions float-right">
                    <div class="dropdown show">
                        <a href="#" data-toggle="dropdown" data-display="static" [matMenuTriggerFor]="menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal align-middle">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="19" cy="12" r="1"></circle>
                                <circle cx="5" cy="12" r="1"></circle>
                            </svg>
                        </a>

                        <mat-menu #menu="matMenu">
                            <button mat-menu-item
                            (click)="editCrmList(crmList);">Edit List</button>
                            <button mat-menu-item>Filter By Users</button>
                          </mat-menu>

                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                    </div>
                </div>
                <h5 class="card-title">{{crmList.listName}}</h5>
                <h6 class="card-subtitle text-muted">{{crmList.listDescription}}</h6>
            </div>
            <div class="card-body p-3">

               
                <div class="card mb-3 bg-light" style="border:0.7px solid rgb(201, 203, 228);" *ngFor="let crmTsk of crmList.crmListTasks">
                    <div class="card-body p-3">
                        
                 
                        <a style="font-weight: bold;">
                           <img style="margin-right: 1%;" src="/assets/images/icons/bot_user.png" width="17" height="17">User</a> <br>
                        <a> Name : {{crmTsk.fkBotUserName}}</a> <br>
                        <a> Phone : {{crmTsk.fkBotUserPhone}}</a>
                 
                       
                        <br><br>

                        <a style="font-weight: bold;">
                            <img style="margin-right: 1%;" src="/assets/images/icons/scheduled.png" width="17" height="17">Created On</a> <br>
                        <a>{{crmTsk.creationTimestamp}}</a>
                 
                        <br><br>
                 
                        <a style="font-weight: bold;">
                            <img style="margin-right: 1%;" src="/assets/images/icons/document.png" width="17" height="17">Message</a> <br>
                        <a>{{crmTsk.taskMsg}}</a> 
                        
                        <br><br>
                        <a style="float: left;" class="btn btn-outline-primary btn-sm">Action</a>
                        

                    </div>
                </div>

                <a href="#" class="btn btn-primary btn-block">Add new</a>

            </div>
        </div>
    </div>
</div>
</div>
</main>