


   <div  [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Tax</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form (ngSubmit)="onSubmit()" #invoiceForm="ngForm"> 
             
        <div style="margin-bottom: 2%;">
 
            <label class="required" for="roleId"> Type</label> <br> 
               <select #invoice="ngModel" required  class="form-control" [(ngModel)]="tax.type" id="type" name="type">
                 <option value="" disabled >Choose Type</option>
                 <option name="type" id1="tax" value="tax" >Tax</option>
                 <option name="type" id1="cess" value="cess" >Cess</option>
               </select>
               <div class="alert alert-danger" *ngIf="invoice.touched && !invoice.valid">Select type</div>
           </div> 

        <div class="form-group row">
          <div class="col-md-6">
            <label class="required"  for="targetName"> Name</label>
            <input #no="ngModel" required class="form-control" type="text" id="targetName" 
            name="targetName" [(ngModel)]="tax.name">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Name is required</div>
          </div>

          <div class="col-md-6">
            <label class="required"  for="value"> Value</label>
            <input #no="ngModel" required class="form-control" type="number" id="value" name="value" 
            [(ngModel)]="tax.value">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid"> value is required</div>
          </div>

        </div>
       
  
             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
      </div>