<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Notification Configuration</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #adminForm="ngForm">  

        
        

        <div *ngIf="fromParent.context!=='Configure NOP Settings'">
          <label class="required" for="fkBotTemplateId">Template</label>
          <select id="Temp" required class="form-control" [(ngModel)]="botAutoSchSetting.fkBotTemplateId">
            <option value="" disabled>Choose Template</option>
            <option *ngFor="let temp of botTemplates" [id]="temp.botTemplateId" [ngValue]="temp.botTemplateId">{{temp.templateDisplayName}}</option>
          </select>
        </div>

        <div *ngIf="fromParent.context==='Configure NOP Settings'">

          <h3>Admins & Agents being notified :</h3>
          <table *ngIf="showMatSpinner===false" class="table table-striped table-sm hover">
            <thead class="tableheader">
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let config of botAutoSchSetting.botNotificationConfigurations">
                <td>{{config.fkBotUserName}}</td>
                <td>
                  <button
                  (click)="deleteBotNotificationConfig(config);" matTooltip="Deactivate" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <br>
          <button class="btn btn-primary" style="width: auto;"
          (click)="getBotOfficeAdminsAndAgents();open(assignAdminsAndAgentForWaNotificationsModal);"
          >Add Admins & Agents</button>

        </div>

        <br>

        <div *ngIf="botAutoSchSetting.context==='shopify_abandoned_cart_notifications'">
          <label class="required" for="scheduleAfterDays">Schedule After Days</label>
          <input #scheduleAfterDays="ngModel" minlength="3" maxlength="255" required class="form-control" type="number" 
           name="scheduleAfterDays" id="scheduleAfterDays" [(ngModel)]="botAutoSchSetting.scheduleAfterDays">
          <div class="alert alert-danger" *ngIf="scheduleAfterDays.touched && !scheduleAfterDays.valid">Required</div>
          </div>
        
      
        <br *ngIf="botAutoSchSetting.context==='AS'">
        <div *ngIf="botAutoSchSetting.context==='AS'" class="form-group row">

            <div class="col-md-6">
            <label class="required" for="autoScheduleVariable">Auto Schedule Variable</label>
            <input #autoScheduleVariable="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
             name="autoScheduleVariable" id="autoScheduleVariable" [(ngModel)]="botAutoSchSetting.autoScheduleVariable">
            <div class="alert alert-danger" *ngIf="autoScheduleVariable.touched && !autoScheduleVariable.valid">Required</div>
            </div>
          
            <div class="col-md-6">
                <label class="required" for="scheduleAfterDays">Schedule After Days</label>
                <input #scheduleAfterDays="ngModel" minlength="3" maxlength="255" required class="form-control" type="number" 
                 name="scheduleAfterDays" id="scheduleAfterDays" [(ngModel)]="botAutoSchSetting.scheduleAfterDays">
                <div class="alert alert-danger" *ngIf="scheduleAfterDays.touched && !scheduleAfterDays.valid">Required</div>
                </div>

        </div>

 
</form>
</div>
</div>

<div *ngIf="fromParent.context!=='Configure NOP Settings'" class="modal-footer">
<button [disabled]="clicked"   
[disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="onSubmit();">save</button>
</div>


<ng-template #assignAdminsAndAgentForWaNotificationsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Notifications to Admins & Agents</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
     

       <br *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
       <div *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
         <label>Selected Admins & Agents :</label>
         <mat-select-trigger>
           <mat-chip-list  style="display: flex;">
             <mat-chip *ngFor="let topping of selectedUsers"
               [removable]="true" (removed)="onToppingRemoved(topping)">
               {{ topping.name }}
               <mat-icon matChipRemove>cancel</mat-icon>
             </mat-chip>
             
           </mat-chip-list>
         </mat-select-trigger>
       </div>
       
       
       <br>

       <div>
        <label>Admins & Agents</label>
        <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
          <mat-label>Choose Admins & Agents</mat-label>
          <mat-select [formControl]="toppingsControl"
          (selectionChange)="multiSelectValueChange($event.value);" multiple>
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" 
          placeholder="Start searching by name or phone number">
      
          <mat-option (click)="selectAll('Select All', null)" value="Select All">Select All</mat-option>
          <mat-option (click)="selectAll(cb.botUserId, cb)" *ngFor="let cb of allUsers | filter:searchText" [value]="cb.botUserId">{{cb.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </div> 
  <div class="modal-footer">
    <button style="width: auto;float: right;" class="btn btn-info" 
    (click)="createWhatsappNotificationConfigs();modal.dismiss('Cross click');">Save</button>
  </div>
</ng-template>