import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BotConvoLog } from 'src/app/BOT_CODE/bot_classes/bot-convo-log';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { environment } from 'src/environments/environment';

function randint(min:any, max:any) {
  return Math.random() * (max - min) + min;
}

@Component({
  selector: 'app-parent-dashboard',
  templateUrl: './parent-dashboard.component.html',
  styleUrls: ['./parent-dashboard.component.scss']
})
export class ParentDashboardComponent implements OnInit { 

  fileList = [
    {
      "name": "New Folder1",
      "children": [
        {
          "name": "New Folder4",
          "children": [
            {
              "name": "New File",
              "children": []
            },
            {
              "name": "New File",
              "children": []
            }
          ]
        },
        {
          "name": "New Folder7",
          "children": []
        }
      ]
    },
    {
      "name": "New Folder2",
      "children": [
        {
          "name": "File1",
          "children": []
        }
      ]
    },
    {
      "name": "New Folder3",
      "children": [
        {
          "name": "New Folder5",
          "children": [
            {
              "name": "New File",
              "children": []
            },
            {
              "name": "New File",
              "children": []
            }
          ]
        }
      ]
    },
    {
      "name": "File1",
      "children": []
    }
  ]

  constructor(private botResponseService : BotResponseServiceService,
    private datePipe : DatePipe) { }

  ngOnInit(): void {
  

  }


}