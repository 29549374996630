<div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 *ngIf="case==='CONVERT LEAD TO CUSTOMER USER'" style="color: white;" class="modal-title pull-left">Convert to Customer</h4>
    <h4 *ngIf="case==='CREATE CUSTOMER AND USER'" style="color: white;" class="modal-title pull-left">Create Customer</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
     
          <form #customerRegisteration="ngForm">
            
            <!-- Customer And User // conversion -->
            <div *ngIf="leadConversionType==='Customer And User' && case==='CONVERT LEAD TO CUSTOMER USER' || leadConversionType==='Customer Only' && case==='CONVERT LEAD TO CUSTOMER USER'">
  
     <br>
     <h3 style="text-align: center;font-weight: bold;">Basic Details</h3>
              
              <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
              
                <div class="col-md-6">
                <label class="required" for="firstName">First Name</label>
                <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
                </div>
              
                <div class="col-md-6">
                <label class="required" for="lastName">Last Name</label>
                <input #lastName="ngModel"  maxlength="50"  required class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
                <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
              </div>
    
              </div>
              
              <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
              
                
      
                <div class="col-md-6">
                  <label class="required" for="email">Email</label>
                  <input #email="ngModel" maxlength="250" email required class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
                  <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
                  </div>

                <div class="col-md-2">
                  <label class="required" for="countryDialCode"> Code</label>
                  <select class="form-control" name="countryDialCode" id="countryDialCode"
                   [(ngModel)]="combinedPTC.countryDialCode">
                    <option value="" disabled selected>Select Country</option>
                    <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
               </select>
                </div>

              
                <div class="col-md-4">
                  <label class="required" for="phoneNumber">Phone Number</label>
                  <input #phone="ngModel" maxlength="250" required minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                  <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
                  </div>
              
              </div>

              <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                
                <div class="col-md-4">
                  <label for="dateOfBirth">Date of Birth</label>
                  <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
                  </div>

                  <div class="col-md-4">
                    <label >Gender </label> <br>
                  <mat-radio-group name="parentGender" aria-label="gender" [(ngModel)]="combinedPTC.gender">
                    <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
                    <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
                    <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
                  </mat-radio-group>
                  </div>

                  <div class="col-md-4">
                    <label for="leadCreationDate">Date of Creation</label>
                    <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                     [(ngModel)]="combinedPTC.leadCreationDate">
                    </div> 
  

              </div>
              
              <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
            
                <div class="col-md-6">
                  <label class="required" for="firstName"> Name</label>
                  <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                  <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid"> Name is Required</div>
                  </div>
                 
                 

                    <div class="col-md-6">
                      <label class="required" for="email">Email</label>
                      <input #email="ngModel" maxlength="250" email required class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
                      <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
                      </div>
          
              </div>
              
              <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
              
                <div class="col-md-4">
                  <label for="contactPerson"> Contact Person</label>
                  <input #contactPerson="ngModel"  maxlength="50" minlength="3" class="form-control" type="text" placeholder="Enter Name" name="contactPerson" id="contactPerson" [(ngModel)]="combinedPTC.contactPerson">
                  <div class="alert alert-danger" *ngIf="contactPerson.touched && !contactPerson.valid"> Name is Required</div>
                  </div>
                
                  <div class="col-md-4">
                    <label for="contactPersonEmail">Contact Person Email</label>
                    <input #contactPersonEmail="ngModel" maxlength="250"    class="form-control" type="contactPersonEmail" placeholder="Enter Email" name="contactPersonEmail" id="contactPersonEmail" [(ngModel)]="combinedPTC.contactPersonEmail">
                    <div class="alert alert-danger" *ngIf="contactPersonEmail.touched && !contactPersonEmail.valid">Email is required</div>
                    </div>
          
                    <div class="col-md-4">
                      <label for="leadCreationDate">Date of Creation</label>
                      <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                       [(ngModel)]="combinedPTC.leadCreationDate">
                      </div> 
    
              </div>

              <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">

                <div class="col-md-2">
                <label class="required" for="countryDialCode"> Code</label>
                <select class="form-control" name="countryDialCode" id="countryDialCode" required
                [(ngModel)]="combinedPTC.countryDialCode">
                <option value="" disabled selected>Select Country</option>
                <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                </select>
                </div>
  
                

                  <div class="col-md-4">
                    <label class="required" for="phoneNumber">Customer Phone</label>
                    <input #phone="ngModel" maxlength="250" required minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                    <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
                    </div>
  
                <div class="col-md-2">
                <label for="contactPersonCountryDialCode"> Code</label>
                <select class="form-control" name="contactPersonCountryDialCode" id="contactPersonCountryDialCode"
                [(ngModel)]="combinedPTC.contactPersonCountryDialCode">
                <option value="" disabled selected>Select Country</option>
                <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                </select>
                </div>
  
                <div class="col-md-4">
                  <label for="contactPersonPhone">Contact Person Phone</label>
                  <input #contactPersonPhone="ngModel" maxlength="250"    minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="contactPersonPhone" id="contactPersonPhone" [(ngModel)]="combinedPTC.contactPersonPhone">
                  <div class="alert alert-danger" *ngIf="contactPersonPhone.touched && !contactPersonPhone.valid">Invalid Phone Number </div>
                  </div>
            
              </div>

              <br>
              <div *ngFor="let formConfigGroup of formConfigGroups">
                <br>
                  <h3 style="text-align: center;font-weight: bold;">{{formConfigGroup.groupName}}</h3>
        
                  <div class="form-group row">
        
                    <div class="col-md-4" *ngFor="let ud of formConfigGroup.userDetails">
                      <div *ngIf="ud.mandatory">
                        <label class="required" for="ud.displayName">{{ud.displayName}}</label>
                      <input class="form-control" maxlength="250"  type="text"
                      required placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                       [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                      </div>
          
                      <div *ngIf="!ud.mandatory" >
                        <label for="ud.displayName">{{ud.displayName}}</label>
                      <input class="form-control" maxlength="250"  type="text"
                       placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                       [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                      </div>
                    
                    </div>
        
                  </div>
                
                </div>

                <br>
                <h3 style="text-align: center;font-weight: bold;">Address Details</h3>
              
              <div class="form-group row">
              
                <div class="col-md-4">
                <label for="addressLine1">Address Line 1</label>
                <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
                </div>
              
                <div class="col-md-4">
                <label for="addressLine2">Address Line 2</label>
                <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
                </div>
      
                <div class="col-md-4">
                  <label for="city">City</label>
                  <input class="form-control" maxlength="250"  type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="combinedPTC.city">
                  </div>
              
              </div>
              
              <div class="form-group row">
              
                <div class="col-md-4">
                  <label for="state">State</label>
                  <input class="form-control" maxlength="250" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="combinedPTC.state">
                  </div>
             
              
                <div class="col-md-4">
                <label for="country">Country</label>
                <input class="form-control" maxlength="250" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="combinedPTC.country">
                </div>
      
                <div class="col-md-4">
                  <label for="zipCode">Zip Code</label>
                  <input class="form-control" maxlength="250" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
                  </div>
              
              </div>
              
              <div>
                <label for="notes">Notes</label>
                <textarea  maxlength="5000" placeholder="Enter notes" class="form-control" type="text" name="notes"  id="notes" [(ngModel)]="combinedPTC.notes"></textarea>
             </div>
             
            </div>
              
            <!-- Customer And ParentMentor // conversion -->
            <div *ngIf="leadConversionType==='Customer And ParentMentor' && case==='CONVERT LEAD TO CUSTOMER USER'">
          <br>
          <h3 style="text-align: center;font-weight: bold;">Parent Details</h3>
                    
                        <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                        
                          <div class="col-md-6">
                          <label class="required" for="firstName">First Name</label>
                          <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                          <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
                          </div>
                        
                          <div class="col-md-6">
                          <label class="required" for="lastName">Last Name</label>
                          <input #lastName="ngModel"  maxlength="50"  required class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
                          <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
                        </div>
              
                        </div>
                        
                        <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                        
                          <div class="col-md-6">
                            <label class="required" for="email">Email</label>
                            <input #email="ngModel" maxlength="250" email required class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
                            </div>
          
                          <div class="col-md-2">
                            <label class="required" for="countryDialCode"> Code</label>
                            <select class="form-control" name="countryDialCode" id="countryDialCode"
                              [(ngModel)]="combinedPTC.countryDialCode">
                              <option value="" disabled selected>Select Country</option>
                              <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                          </select>
                          </div>
          
                        
                          <div class="col-md-4">
                            <label class="required" for="phoneNumber">Phone Number</label>
                            <input #phone="ngModel" maxlength="250" required minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                            <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
                            </div>
                        
                        </div>
          
                        <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                          
                          <div class="col-md-4">
                            <label for="dateOfBirth">Date of Birth</label>
                            <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
                            </div>
          
                            <div class="col-md-4">
                              <label >Gender </label> <br>
                            <mat-radio-group name="parentGender" aria-label="gender" [(ngModel)]="combinedPTC.gender">
                              <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
                              <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
                              <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
                            </mat-radio-group>
                            </div>
          
                            <div class="col-md-4">
                              <label for="leadCreationDate">Date of Creation</label>
                              <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                                [(ngModel)]="combinedPTC.leadCreationDate">
                              </div> 
          
                        </div>

                        <br>
                        <h3 style="text-align: center;font-weight: bold;">Student Details</h3>


<div class="form-group row">

  <div class="col-md-4">
  <label class="required" for="studentFirstName">First Name</label>
  <input maxlength="50" required class="form-control" type="text" placeholder="Enter First Name" name="studentFirstName" 
  id="studentFirstName" [(ngModel)]="combinedPTC.studentFirstName">
  </div>

  <div class="col-md-4">
  <label class="required" for="studentLastName">Last Name</label>
  <input required maxlength="50"  class="form-control" type="text" placeholder="Enter Last name" name="studentLastName"
   id="studentLastName" [(ngModel)]="combinedPTC.studentLastName">
  </div>

  <div class="col-md-4">
    <label for="childPetname">Nick Name</label>
    <input maxlength="50"  class="form-control" type="text" placeholder="Enter nick name" name="childPetname"
     id="childPetname" [(ngModel)]="combinedPTC.childPetname">
    </div>

</div>

<div class="form-group row">
<div class="col-md-6">
  <label >Gender </label> <br>
  <mat-radio-group aria-label="studentGender" [(ngModel)]="combinedPTC.studentGender">
    <mat-radio-button name="studentGender" id1="male" value1="male">Male</mat-radio-button>
    <mat-radio-button name="studentGender" id1="female" value1="female">Female</mat-radio-button>
    <mat-radio-button name="studentGender" id1="other" value1="other">Other</mat-radio-button>
  </mat-radio-group>
</div>

<div class="col-md-6">
  <label for="studentDateOfBirth">Date of Birth</label>
  <input class="form-control" type="date"  name="studentDateOfBirth" id="studentDateOfBirth" [(ngModel)]="combinedPTC.studentDateOfBirth">
  </div>
</div>

<br>
<h3 style="text-align: center;font-weight: bold;">Course Details</h3>
<div class="form-group row"> 

  <div class="col-md-4">
    <label class="required" for="fkProductId">Course / Product</label>
    <select #product="ngModel" required class="form-control col-md-12" 
    [(ngModel)]="combinedPTC.fkProductId" id="fkProductId" name="fkProductId">
      <option disabled value="">Choose Course</option>
      <option *ngFor="let product of products" [ngValue]="product.productId">{{product.productName}}</option>
    </select>
    <div class="alert alert-danger" *ngIf="product.touched && !product.valid">Select Product</div>
  </div>
  <div class="col-md-4">
    <label class="required"  for="startedDate">Start Date</label>
    <input required class="col-md-12 form-control" type="date" id="startedDate" name="startedDate" [(ngModel)]="combinedPTC.startedDate">
  </div>
  <div class="col-md-4">
    <label class="required"  for="endedDate">End Date</label>
    <input required class="col-md-12 form-control" type="date" id="endedDate" name="endedDate" [(ngModel)]="combinedPTC.endedDate">
  </div>
  
  </div>

          <br>
          <h3 style="text-align: center;font-weight: bold;">Address Details</h3>
                     
                        <div class="form-group row">
                        
                          <div class="col-md-4">
                          <label for="addressLine1">Address Line 1</label>
                          <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
                          </div>
                        
                          <div class="col-md-4">
                          <label for="addressLine2">Address Line 2</label>
                          <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
                          </div>
                
                          <div class="col-md-4">
                            <label for="city">City</label>
                            <input class="form-control" maxlength="250"  type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="combinedPTC.city">
                            </div>
                        
                        </div>
                        
                        <div class="form-group row">
                        
                          <div class="col-md-4">
                            <label for="state">State</label>
                            <input class="form-control" maxlength="250" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="combinedPTC.state">
                            </div>
                        
                        
                          <div class="col-md-4">
                          <label for="country">Country</label>
                          <input class="form-control" maxlength="250" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="combinedPTC.country">
                          </div>
                
                          <div class="col-md-4">
                            <label for="zipCode">Zip Code</label>
                            <input class="form-control" maxlength="250" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
                            </div>
                        
                        </div>

            </div>

                        <!-- Customer And User // creation -->
            <div *ngIf="case==='CREATE CUSTOMER AND USER'">

              <!-- <div class="form-group row" style="margin-bottom: 2%;">
                <div class="col-md-6">
                  <label class="required">Entity Type </label> <br>
                  <mat-radio-group name="entityType" required aria-label="entityType" [(ngModel)]="combinedPTC.entityType">
                    <mat-radio-button name="entityType" id="Individual" value="Individual"  >Individual</mat-radio-button>
                    <mat-radio-button name="entityType" id="Organisation" value="Organisation"  >Organisation</mat-radio-button>
                  </mat-radio-group>
                </div>
            </div>  -->

              <br>
              <h3 style="text-align: center;font-weight: bold;">Basic Details</h3>
                        
                        <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                        
                          <div class="col-md-4">
                            <label class="required">Entity Type </label> <br>
                            <mat-radio-group name="entityType" required aria-label="entityType" [(ngModel)]="combinedPTC.entityType">
                              <mat-radio-button name="entityType" id="Individual" value="Individual"  >Individual</mat-radio-button>
                              <mat-radio-button name="entityType" id="Organisation" value="Organisation"  >Organisation</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-md-4">
                          <label class="required" for="firstName">First Name</label>
                          <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                          <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
                          </div>
                        
                          <div class="col-md-4">
                          <label class="required" for="lastName">Last Name</label>
                          <input #lastName="ngModel"  maxlength="50"  required class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
                          <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
                        </div>
              
                        </div>
                        
                        <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                        
                          
                
                          <div class="col-md-6">
                            <label class="required" for="email">Email</label>
                            <input #email="ngModel" maxlength="250" email required class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
                            </div>
          
                          <div class="col-md-2">
                            <label class="required" for="countryDialCode"> Code</label>
                            <select class="form-control" name="countryDialCode" id="countryDialCode"
                            [(ngModel)]="combinedPTC.countryDialCode">
                              <option value="" disabled selected>Select Country</option>
                              <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                        </select>
                          </div>
          
                        
                          <div class="col-md-4">
                            <label class="required" for="phoneNumber">Phone Number</label>
                            <input #phone="ngModel" maxlength="250" required minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                            <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
                            </div>
                        
                        </div>
          
                        <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
                          
                          <div class="col-md-4">
                            <label for="dateOfBirth">Date of Birth</label>
                            <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
                            </div>
          
                            <div class="col-md-4">
                              <label >Gender </label> <br>
                            <mat-radio-group name="parentGender" aria-label="gender" [(ngModel)]="combinedPTC.gender">
                              <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
                              <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
                              <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
                            </mat-radio-group>
                            </div>
          
                            <div class="col-md-4">
                              <label for="leadCreationDate">Date of Creation</label>
                              <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                              [(ngModel)]="combinedPTC.leadCreationDate">
                              </div> 
            
          
                        </div>
                        
                        <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
                      
                          <div class="col-md-4">
                            <label class="required">Entity Type </label> <br>
                            <mat-radio-group name="entityType" required aria-label="entityType" [(ngModel)]="combinedPTC.entityType">
                              <mat-radio-button name="entityType" id="Individual" value="Individual"  >Individual</mat-radio-button>
                              <mat-radio-button name="entityType" id="Organisation" value="Organisation"  >Organisation</mat-radio-button>
                            </mat-radio-group>
                          </div>

                          <div class="col-md-4">
                            <label class="required" for="firstName"> Name</label>
                            <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                            <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid"> Name is Required</div>
                            </div>
                          
                          
                              <div class="col-md-4">
                                <label class="required" for="email">Email</label>
                                <input #email="ngModel" maxlength="250" email required class="form-control" type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
                                <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
                                </div>
                    
                        </div>
                        
                        <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
                        
                          <div class="col-md-4">
                            <label for="contactPerson"> Contact Person</label>
                            <input #contactPerson="ngModel"  maxlength="50" minlength="3" class="form-control" type="text" placeholder="Enter Name" name="contactPerson" id="contactPerson" [(ngModel)]="combinedPTC.contactPerson">
                            <div class="alert alert-danger" *ngIf="contactPerson.touched && !contactPerson.valid"> Name is Required</div>
                            </div>
                          
                            <div class="col-md-4">
                              <label for="contactPersonEmail">Contact Person Email</label>
                              <input #contactPersonEmail="ngModel" maxlength="250"    class="form-control" type="contactPersonEmail" placeholder="Enter Email" name="contactPersonEmail" id="contactPersonEmail" [(ngModel)]="combinedPTC.contactPersonEmail">
                              <div class="alert alert-danger" *ngIf="contactPersonEmail.touched && !contactPersonEmail.valid">Email is required</div>
                              </div>
                    
                              <div class="col-md-4">
                                <label for="leadCreationDate">Date of Creation</label>
                                <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                                [(ngModel)]="combinedPTC.leadCreationDate">
                                </div> 
              
                        </div>
          
                        <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
          
                          <div class="col-md-2">
                          <label class="required" for="countryDialCode"> Code</label>
                          <select class="form-control" name="countryDialCode" id="countryDialCode" required
                          [(ngModel)]="combinedPTC.countryDialCode">
                          <option value="" disabled selected>Select Country</option>
                          <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                          </select>
                          </div>
            
                          
          
                            <div class="col-md-4">
                              <label class="required" for="phoneNumber">Customer Phone</label>
                              <input #phone="ngModel" maxlength="250" required minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                              <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
                              </div>
            
                          <div class="col-md-2">
                          <label for="contactPersonCountryDialCode"> Code</label>
                          <select class="form-control" name="contactPersonCountryDialCode" id="contactPersonCountryDialCode"
                          [(ngModel)]="combinedPTC.contactPersonCountryDialCode">
                          <option value="" disabled selected>Select Country</option>
                          <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
                          </select>
                          </div>
            
                          <div class="col-md-4">
                            <label for="contactPersonPhone">Contact Person Phone</label>
                            <input #contactPersonPhone="ngModel" maxlength="250"    minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="contactPersonPhone" id="contactPersonPhone" [(ngModel)]="combinedPTC.contactPersonPhone">
                            <div class="alert alert-danger" *ngIf="contactPersonPhone.touched && !contactPersonPhone.valid">Invalid Phone Number </div>
                            </div>
                      
                        </div>
          
                        <br>
                        <div *ngFor="let formConfigGroup of formConfigGroups">
                          <br>
                            <h3 style="text-align: center;font-weight: bold;">{{formConfigGroup.groupName}}</h3>
                  
                            <div class="form-group row">
                  
                              <div class="col-md-4" *ngFor="let ud of formConfigGroup.userDetails">
                                <div *ngIf="ud.mandatory">
                                  <label class="required" for="ud.displayName">{{ud.displayName}}</label>
                                <input class="form-control" maxlength="250"  type="text"
                                required placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                                [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                                </div>
                    
                                <div *ngIf="!ud.mandatory" >
                                  <label for="ud.displayName">{{ud.displayName}}</label>
                                <input class="form-control" maxlength="250"  type="text"
                                placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                                [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                                </div>
                              
                              </div>
                  
                            </div>
                          
                          </div>
          
                          <br>
                          <h3 style="text-align: center;font-weight: bold;">Address Details</h3>
                        
                        <div class="form-group row">
                        
                          <div class="col-md-4">
                          <label for="addressLine1">Address Line 1</label>
                          <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
                          </div>
                        
                          <div class="col-md-4">
                          <label for="addressLine2">Address Line 2</label>
                          <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
                          </div>
                
                          <div class="col-md-4">
                            <label for="city">City</label>
                            <input class="form-control" maxlength="250"  type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="combinedPTC.city">
                            </div>
                        
                        </div>
                        
                        <div class="form-group row">
                        
                          <div class="col-md-4">
                            <label for="state">State</label>
                            <input class="form-control" maxlength="250" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="combinedPTC.state">
                            </div>
                      
                        
                          <div class="col-md-4">
                          <label for="country">Country</label>
                          <input class="form-control" maxlength="250" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="combinedPTC.country">
                          </div>
                
                          <div class="col-md-4">
                            <label for="zipCode">Zip Code</label>
                            <input class="form-control" maxlength="250" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
                            </div>
                        
                        </div>
                        
                        <div>
                          <label for="notes">Notes</label>
                          <textarea  maxlength="5000" placeholder="Enter notes" class="form-control" type="text" name="notes"  id="notes" [(ngModel)]="combinedPTC.notes"></textarea>
                      </div>
                      
            </div>
                       </form>
  
    </div>
  </div>
  </div> 
  <div class="modal-footer">
    <button [disabled]="clicked" style="width: auto;" [disabled]="!customerRegisteration.valid" type="submit" 
    class="btn btn-sm btn-primary float-right" (click)="open(confirmUsernameCreation);">Create Username</button>
  </div>



  <ng-template #confirmUsernameCreation let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Username</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
     <div *ngIf="emailUnavailable === false || this.phoneUnavailable === false">
      <label>     Create Username using :      </label> <br>
    <mat-radio-group aria-label="choice" required [(ngModel)]="choice">
      <mat-radio-button name="choice" id="Email" value="Email"  >Email</mat-radio-button>
      <mat-radio-button name="choice" id="Phone" value="Phone"  >Phone</mat-radio-button>
    </mat-radio-group>
    </div>

    <form #usernameForm="ngForm">  
    <div *ngIf="emailUnavailable === true && this.phoneUnavailable === true">
      <label>Username : </label>
      <input class="col-md-12 form-control" required #username="ngModel"
  type="text" minlength="5" [(ngModel)]="value" placeholder="Try firstname@email or lastname@email or firstname@phone" name="search">
  <div class="alert alert-danger" *ngIf="username.touched && !username.valid"> username must have minimum of 5 characters</div>  </div>
    </form>

    </div> 
    <div class="modal-footer">
      <button *ngIf="emailUnavailable === false || this.phoneUnavailable === false"
      type="button" class="btn btn-success"  [disabled]="clicked" 
      (click)="checkUsernameAvailability();">Proceed</button>

      <button *ngIf="emailUnavailable === true && this.phoneUnavailable === true"
      type="button" class="btn btn-success"  [disabled]="clicked" [disabled]="!usernameForm.valid"
      (click)="checkCustomUsernameAvailability();">Proceed</button>
    </div>
  </ng-template>
  


