
<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
  <img style="margin: 10px"
  src="/assets/images/courseManagementSystem.svg"
      class="me-2"
      height="30" 
      alt=""
      loading="lazy"
    /> 
  <a (click)="reloadPage()" class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;">{{navbarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button> 

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">


      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Super Admin Dashboard </a>
      </li>


      <li class="nav-item" style="float: right;">
        <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
          <i class="fa fa-sign-out" ></i>Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>

<!-- <div class="user-padding">
  <div class="row">
    <div class="col-md-12">

      <div class="table-header row">
        <div class="col-md-12">
          <span class="table-caption">
            Results
          </span>

          <a (click)="openAddResultModal()" style="cursor:pointer" style="float: right;" >
            <img style="cursor:pointer" src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>
          <button style="cursor:pointer;width: auto;float: right;margin-right: 5%;" class="btn btn-info" (click)="openStudentSelectionModal()">Select Student</button>

        
        </div>
      </div>
      
      <div class="card"> 
        <div class="card-body table-responsive">
          
          <table class="table table-striped table-bordered table-sm row-border hover"
            datatable >  
            <thead class="tableheader">
              <tr>
                <th>#</th>
                <th>Skill Image</th>
                <th>Skill Name</th>
                <th>History</th>
                <th>Suggestion</th>
              </tr>
            </thead> 
            <tbody>
              <tr *ngFor="let result of results">
                <td class="td-height" style="width: 5rem;">
                  <span>
                   #
                  </span>
                </td>
                <td class="td-height" style="width: 17rem;">{{result.skillName}}</td>
                <td class="td-height" style="width: 17rem;">{{result.skillName}}</td>
                <td class="td-height" style="width: 17rem;">{{result.resultHistory}}</td>
                <td class="td-height" style="width: 17rem;">{{result.resultSuggestion}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="p-4">
  
    
  <div class="table-header row" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">
    

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Results
      </span>
      <a style="cursor:pointer;" class="float-right" (click)="openAddResultModal();">
        <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
      </a>
      <button style="cursor:pointer;width: auto;float: right;margin-right: 5%;" class="btn btn-info" (click)="openStudentSelectionModal()">Select Student</button>


    </div> 
  </div>
  <div class="card">
    <div class="card-body table-responsive">
      <table class="table table-striped table-bordered table-sm row-border hover" 
      datatable >
        <thead class="tableheader" >
          <tr>
            <th>#</th>
            <th>Skill Image</th>
            <th>Skill Name</th>
            <th>History</th>
            <th>Suggestion</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of results" >
            <td class="td-height" style="width: 5rem;">
              <span>
               #
              </span>
            </td>
            <td class="td-height" style="width: 17rem;">skillName</td>
            <td class="td-height" style="width: 17rem;">skillName</td>
            <td class="td-height" style="width: 17rem;">resultHistory</td>
            <td class="td-height" style="width: 17rem;">esult</td>
          </tr>
          <!-- <tr *ngFor="let result of results">
            <td class="td-height" style="width: 5rem;">
              <span>
               #
              </span>
            </td>
            <td class="td-height" style="width: 17rem;">{{result.skillName}}</td>
            <td class="td-height" style="width: 17rem;">{{result.skillName}}</td>
            <td class="td-height" style="width: 17rem;">{{result.resultHistory}}</td>
            <td class="td-height" style="width: 17rem;">{{result.resultSuggestion}}</td>
          </tr> -->
        </tbody>
      </table>

    </div>
  </div>

  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

  
  
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="33%" *ngFor="let result of results">
  
          <div class="example-card mat-elevation-z12">

            <div style="text-align: center;border-radius: 50%;">
              <img style="border-radius: 50%;" src="/assets/images/courseManagementSystem.svg" height="60" width="60" alt="...">
              </div>

              <br>
          
              <h2 style="color: black;">{{result.skillName}}</h2>
            
              <h2>{{result.skillScore}}</h2>
<br>

            </div>
        
      </div>
    </div>
  </div>

</div>
