import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { OfficesService } from "src/app/services/office/offices.service";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { AddBotUserComponent } from "src/app/BOT_CODE/bot_modals/bot_user/add-bot-user/add-bot-user.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user"; 
import { SubscriptionService } from "src/app/service/subscription.service";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotUserGroupModalComponent } from "src/app/BOT_CODE/bot_modals/bot_user_group/bot-user-group-modal/bot-user-group-modal.component";

@Component({
  selector: "app-cb-organization-admin-home",
  templateUrl: "./cb-organization-admin-home.component.html",
  styleUrls: ["./cb-organization-admin-home.component.scss"],
})
export class CbOrganizationAdminHomeComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  agentsExists = false;
  adminsExist = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
    fkBotLangId: "",
    multiLanguageSettings: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botCustomerUser: BotCustomerUser = new BotCustomerUser();

  languageCardOverlay = false;

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    private sharedDataService: SharedDataService,
    private subscriptionService: SubscriptionService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private botOfficeService: BotOfficeServiceService,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;

    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "admin/getOfficeByIdForBotOfficeAdmin"
      )
      .then((res) => {
        this.officeObject = res;
      });

    this.userId = this.loggedInUser.userId;
  }

  botUser: BotUser = new BotUser();
  botAdmin: BotUser = new BotUser();

  searchText: any;
  searchText2: any;
  agentsListType = "tabular";

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  newPassword: any = null;
  resetPassword(userId: any) {
    if (
      this.newPassword !== undefined &&
      this.newPassword !== null &&
      this.newPassword.length > 5
    ) {
      let botUser: BotUser = new BotUser();
      botUser.password = this.newPassword;
      botUser.userId = userId;

      this.subscriptionService
        .passwordUpdate(
          environment.apiUrl + "user/resetPasswordByUserId",
          botUser
        )
        .then((data) => {
          this.toaster.success("Password updated successfully !");
          this.newPassword = null;
        });
    } else {
      this.toaster.error(
        "Length of the new password must be atleast 6 characters"
      );
      this.newPassword = null;
    }
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }
}
