import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { ViewSkillComponent } from 'src/app/course-management/skill/view-skill/view-skill.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { StudentService } from 'src/app/service/student.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-skill-report-page',
  templateUrl: './student-skill-report-page.component.html',
  styleUrls: ['./student-skill-report-page.component.scss']
})
export class StudentSkillReportPageComponent implements OnInit {

 
  showDropDown = true;
  results = false;
  mainSKillSelected = false;
  mainSkills = false;
  showSpinner = true;
  currentRole="TEACHER";
  index : number = 0;
  userId!: string;
  resultsExist = false;
  userName : any;
  loggedUser : any;
  loggedInUser : any;
  theme: string = '';
  public screenWidth: any;
  public screenHeight: any;
  classId!: string;
  selectedSkillNames: string[] = [];  
  unsafeImageUrl : any;
  safeImageUrl : any;
  safeStudentUrl : any;
  unsafeStudentUrl : any;

  safeUrl : any;
  unsafeUrl : any;

  datae: any[] = [];  

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  skillResults : any[] =[{

                              parentSkillId : '',
                              skillDescription: '',
                              skillId: '',
                              skillName:'',
                              skillStatus: '',
                              collapsedState: '',
                              safeUrl : '',
                              skillImage : '',

                              resultlist: [{
                                            fkSkillId: '',
                                            fkStudentId:'',
                                            fkSubjectId: '',
                                            parentSkillName: '',
                                            resultHistory: '',
                                            resultId: '',
                                            resultStatus: '',
                                            resultSuggestion: '',
                                            skillDescription: '',
                                            skillName: '',
                                            skillScore:'',
                                            safeUrl : '',
                                            skillImage : '',
                                            lastScore : ''
                                            }]
                                }]

  selectedMainSkill : any ={
                                parentSkillId : '',
                                skillDescription: '',
                                skillId: '',
                                skillName:'',
                                skillStatus: '',
                                collapsedState: '',

                                resultlist: [{
                                              fkSkillId: '',
                                              fkStudentId:'',
                                              fkSubjectId: '',
                                              parentSkillName: '',
                                              resultHistory: '',
                                              resultId: '',
                                              resultStatus: '',
                                              resultSuggestion: '',
                                              skillDescription: '',
                                              skillName: '',
                                              skillScore:'',
                                              lineChartData :[{ 
                                                data: [1,2,2,1,2,2,1], 
                                                label : 'progress'
                                              }]
                                              }]
  }

  lineChartData: any[] = [
    { data: [8, 7, 9, 8, 7, 8], label : 'progress'},
  ];
  

  lineChartLabels: Label[] = ['', '', '', '', '', ''];

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: '#b9d8f3',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType : ChartType = 'line';

  lineChartOptions = {
    
    legend: {
      onClick: (e: { stopPropagation: () => any; }) => e.stopPropagation()
  },
 
    elements: {
      line: {
          tension: 0
      }
  },
    responsive: true,
    scales: {
        xAxes: [{
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
               display: false
            }
        }],
        yAxes: [{
          
            display: false
       ,
            gridLines: {
              drawBorder: false,
              display: false,
                color: "rgba(0, 0, 0, 0)",
                
            }   
        }]
    }
}
  showMainPanel = false;

  navbarTitle : any

  selectedStudentId : any;
  students : any[] = [{
    studentId: '',
    fkClass: '',
    fkUserRoleOffice: '',
    studentStatus: '',
    studentName: '',
    studentImage : '',
    dob : '',
    email : '',
    phone : '',
    safeStudentUrl : ''
}]

selectedStudent : any = {
  studentId: '',
  fkClass: '',
  fkUserRoleOffice: '',
  studentStatus: '',
  studentName: '',
  studentImage : '',
  dob : '',
  email : '',
  phone : '',
  safeStudentUrl : ''
}

  subjectId: any;
  studentId: any;

  studentObject :any ={
    dob: '',
    email: '',
    fkClass: '',
    fkUserRoleOffice: '',
    phone: '',
    skillScore: '',
    studentActivityHistoryIndicator: '',
    studentAttempts: '',
    studentId: '',
    studentImage: '',
    studentName: '',
    studentStatus: ''
}

  constructor(private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              public router: Router,
              private skillService : SkillServiceService,
              public route : ActivatedRoute,
              private toastr : ToastrService,
              private logoutService : LogoutService,
              private teacherService : TeacherService,
              private studentService : StudentService ) { }


  ngOnInit(): void {

  this.navbarTitle = GlobalClass.navbarTitle;

this.screenWidth = window.innerWidth;
this.screenHeight = window.innerHeight;

// this.subjectId = this.route.snapshot.params['subjectId'];
// this.classId = this.route.snapshot.params['classId'];
this.studentId = this.route.snapshot.params['studentId'];

this.loggedUser = localStorage.getItem('loggedUser');
this.loggedInUser = JSON.parse(this.loggedUser);
this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
this.theme = this.sharedDataService.getCardOfficeTheme();
this.userId = this.loggedInUser.userId;

this.getSkillResults();

this.getStudentDetailsByUserId(this.userId);

}

getStudentDetailsByUserId(userId : any){
  this.studentService.getStudentDetailsByUserId(userId).subscribe(
    data=>{
      this.studentObject = data;  

      this.imageService.getUserImage(this.studentObject.studentImage).subscribe(
        data=>{
          this.unsafeStudentUrl = URL.createObjectURL(data);
          this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeStudentUrl);
        });
    });
}

openSubSkillDetailsModal(skillResult : any){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( ViewSkillComponent,
    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
    skillResult : skillResult
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });    
}

showMainSkillResult(){

  this.results = false;
  this.mainSKillSelected = false;
  this.mainSkills = true;

}

exploreMainSkillResult(skillId : any){

  this.skillResults.forEach((val)=>{
    if(val.skillId === skillId){
      this.selectedMainSkill = val;
      this.mainSKillSelected = true;
      this.mainSkills = false;
      this.results = true;
    }
  });
}

showDropDownToChoose(){
  this.showMainPanel=false
  this.showDropDown = true;
}

collapseActivityCardContent2(id : string){
      
  this.skillResults.forEach((value)=>{

    if(value.skillId === id){

      if(value.collapsedState === true){
        value.collapsedState = false
      } else if(value.collapsedState === false){
        value.collapsedState = true
      }

    }
  });
}

// authorized
getSkillResults(){
this.showSpinner = true;
this.showDropDown = false;
this.showMainPanel = false;
this.mainSKillSelected = false;
this.results = false;
  // this.students.forEach((value)=>{
  //   if(value.studentId === this.selectedStudentId){
  //     this.selectedStudent = value;
  //     this.showMainPanel = true;
  //     this.showSpinner = false;
  //   }
  // });

  this.showMainPanel = true;
  this.showSpinner = false;

  this.skillService.getStudentSkillResultForReport(environment.apiUrl + "result/getStudentSkillResultForReport/" + this.studentId).then(
    res=>{
      this.skillResults = res;
      this.mainSkills = true;

      this.skillResults.forEach((val)=>{
        val.collapsedState = false;

        this.imageService.getSkillImage( val.skillImage ).subscribe(
          data=>{
            this.unsafeUrl = URL.createObjectURL(data);
            this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);

            val.safeUrl = this.safeUrl;
          });

        val.resultlist.forEach((value : any)=>{

          this.imageService.getSkillImage( value.skillImage ).subscribe(
            data=>{
              this.unsafeUrl = URL.createObjectURL(data);
              this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
  
              value.safeUrl = this.safeUrl;
            });

            if(value.resultHistory !== undefined && value.resultHistory !== null){

          var myArray = value.resultHistory.split(",");
  for(var i=0; i<myArray.length; i++) { 
    this.datae[i] = +myArray[i]; 
  } 

  value.lastScore = this.datae[this.datae.length - 1] 
    
          value.lineChartData = [
            { data: this.datae,
               label : 'progress'},
          ];
          this.datae = [];

        }
        });
      })
      
    });
}







































goToAccountPage(){
  this.router.navigate(['user-account', this.userId]);
}

goToHomePage(){
  this.router.navigate(['/dashboard/teacher']);
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

 logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

goBack() {
  this.router.navigate(['/dashboard/user']).then();
}

switchDashboard(){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
    {
      size : 'sm',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
    userId : this.userId,
    currentRole : this.currentRole
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });    
}
reloadPage() {
  window.location.reload();
}
}
