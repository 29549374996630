<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
      
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

 

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <mat-tab-group style="padding: 1em;" dynamicHeight>
  
    <mat-tab>
        <ng-template mat-tab-label>
          Auto-Schedule Settings
          <button (click)="createBotAutoSchedulerSettings();" matTooltip="Create Auto-Schedule Settings" mat-icon-button>
            <mat-icon>add_box</mat-icon>
        </button>
        </ng-template>
        <div class="p-4">
   
  
  
        
      
              <div class="search-hero">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off" 
                placeholder="Start searching by title, type or description">
              </div>
      
              <br>
      
              <table *ngIf="settingsExists"
                class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
                <thead class="tableheader" >
                  <tr>
                    <th>Template Name</th>
                    <th>Variable</th>
                    <th>scheduleAfterDays</th>
                    <th class="tble-action">MENU</th>
                  </tr>
                </thead>
                <tbody>
                  <tr #rowInstance *ngFor="let med of botAutoSchedulerSettings | filter:searchText2">
                    <td class="td-height">{{med.fkBotTemplateName}}</td>
                    <td class="td-height">{{med.autoScheduleVariable}}</td>
                    <td class="td-height">{{med.scheduleAfterDays}}</td>
                    <td class="tble-action">
                      
  
                      <button (click)="editBotAutoSchedulerSettings(med);" matTooltip="Edit" mat-icon-button>
                        <mat-icon>mode_edit_outline</mat-icon>
                    </button>
                    
                    </td>
      
                  </tr>
                </tbody>
              </table>
            
      
      </div>
      </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
       Schedules
      </ng-template>
      <div class="p-4">
 
    

        
  
            <div class="search-hero">
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText1" autocomplete="off" 
              placeholder="Start searching by schedule title or template name">
            </div>
    
            <br>
            <table *ngIf="schedulesExists"
              class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
              <thead class="tableheader" >
                <tr>
                  <th>SCHEDULE TITLE</th>
                  <th>TEMPLATE NAME</th>
                  <th>DESCRIPTION</th>
                  <th>SCHEDULED FOR (Time)</th>
                  <th class="tble-action">MENU</th>
                </tr>
              </thead>
              <tbody>
                <tr #rowInstance *ngFor="let schedule of schedules | filter:searchText1">
                  <td class="td-height">{{schedule.botScheduleTitle}}</td>
                  <td class="td-height">{{schedule.fkBotTemplateName}}</td>
                  <td class="td-height">{{schedule.botScheduleDescription}}</td>
                  <td class="td-height">{{schedule.executionTimestamp}}
                  </td>
                  <td class="td-height tble-action">
  
                  
                   
                      <button (click)="viewScheduleDetails(schedule);" matTooltip="Explore" mat-icon-button>
                        <mat-icon>info</mat-icon>
                    </button>
                   
                   
                  </td>
  
                </tr>
              </tbody>
            </table>

          
  
  </div>
    </mat-tab>
   

    <mat-tab>
      <ng-template mat-tab-label>
        Shopify Abandoned Cart Notification Schedules
        <button (click)="createShopifyAbandonedCartNotificationSchedules();" matTooltip="Create Auto-Schedule Settings" mat-icon-button>
          <mat-icon>add_box</mat-icon>
      </button>
      </ng-template>
      <div class="p-4">
 


      
    
            <div class="search-hero">
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off" 
              placeholder="Start searching by title, type or description">
            </div>
    
            <br>
    
            <table *ngIf="settingsExists"
              class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
              <thead class="tableheader" >
                <tr>
                  <th>Template Name</th>
                  <!-- <th>Variable</th> -->
                  <th>scheduleAfterDays</th>
                  <th class="tble-action">MENU</th>
                </tr>
              </thead>
              <tbody>
                <tr #rowInstance *ngFor="let med of shopifyAbandonedCartAutoSchSettings | filter:searchText2">
                  <td class="td-height">{{med.fkBotTemplateName}}</td>
                  <!-- <td class="td-height">{{med.autoScheduleVariable}}</td> -->
                  <td class="td-height">{{med.scheduleAfterDays}}</td>
                  <td class="tble-action">
                    

                    <button (click)="editBotAutoSchedulerSettings(med);" matTooltip="Edit" mat-icon-button>
                      <mat-icon>mode_edit_outline</mat-icon>
                  </button>
                  
                  </td>
    
                </tr>
              </tbody>
            </table>
          
    
    </div>
    </mat-tab>

  </mat-tab-group>
