import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { GlobalClass } from 'src/app/global/global-class';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { AddCustomerCommunicationsComponent } from '../add-customer-communications/add-customer-communications.component';

@Component({
  selector: 'app-view-customer-communications',
  templateUrl: './view-customer-communications.component.html',
  styleUrls: ['./view-customer-communications.component.scss']
})
export class ViewCustomerCommunicationsComponent implements OnInit {

  clicked = false;
  coms : CustomerCommunications[] =[];
   
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
  communicationsExists = false;
  
    constructor(public bsModalRef: BsModalRef, 
      private sharedDataService : SharedDataService,
      private datePipe : DatePipe,
                private customerService : CustomerService,
                private ngbModalService: NgbModal,
                public activeModal: NgbActiveModal,
                private toaster: ToastrService) {}
  
                @Input() fromParent: any;
  
                customerId : any
                communicationsOptions : any;
                loggedInUser : any; 
                theme : any;

    ngOnInit(): void {
  
      this.customerId = this.fromParent.customerId;

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
   
      this.getCommunications();

    this.communicationsOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };
    }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }

    addNewCustomerCommunication(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddCustomerCommunicationsComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        customerId : this.customerId
      }
  
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  this.getCommunications();
  this.passEntry.emit("Success");
      });
  }
  

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
    getCommunications(){
      this.customerService.getCustomerCommunicationsByCustomerId(environment.apiUrl + "bcrmCustomerCommunications/getCustomerCommunicationsByCustomerId/" +  this.customerId).then(
        data=>{
          this.coms = data;
          this.communicationsExists = true;
  
          this.coms.forEach((value)=>{
  
            if(value.currentActionDateTime !== null){
              let sd = value.currentActionDateTime;
              let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
              value.currentActionDateTime = startDate;   
              value.currentActionDateTime = this.datePipe.transform(startDate, 'yyyy-MM-dd hh:mm');  
            }
          
           if(value.nextActionDateTime !== null){
            let ed = value.nextActionDateTime;
           let endDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);
           value.nextActionDateTime = endDate;           
           value.nextActionDateTime = this.datePipe.transform(endDate, 'yyyy-MM-dd hh:mm');
           }

  
          })      });
      
    }
    
    closeUpdateModal(){
      this.activeModal.close();
    }
  
  }