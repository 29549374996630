import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-skill',
  templateUrl: './view-skill.component.html',
  styleUrls: ['./view-skill.component.scss']
})
export class ViewSkillComponent implements OnInit {

  skill: any = {
                        fkSkillId: '',
                        fkStudentId:'',
                        fkSubjectId: '',
                        parentSkillName: '',
                        resultHistory: '',
                        resultId: '',
                        resultStatus: '',
                        resultSuggestion: '',
                        skillDescription: '',
                        skillName: '',
                        skillScore:'',
                                        }
  
      @Input() fromParent: any;

      @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.skill = this.fromParent.skillResult;

  }

  closeUpdateModal(){
    this.activeModal.close();
  }  


}
