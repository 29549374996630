import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { WCASubscription } from '../../../classes/wca-subscription';
import { WcaSubscriptionService } from '../../../service/wca-subscription.service';
import { GlobalClass } from 'src/app/global/global-class';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-wca-subscription',
  templateUrl: './add-wca-subscription.component.html',
  styleUrls: ['./add-wca-subscription.component.scss']
})
export class AddWcaSubscriptionComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any; 

  constructor(private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,private subscriptionService : WcaSubscriptionService,
    private toastr: ToastrService, private datePipe : DatePipe) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    context : any;
    subscription : WCASubscription = new WCASubscription();

    ngOnInit(): void {

      this.context = this.fromParent.context;

      if(this.context==="Add Subscription"){
        this.subscription.fkOfficeId = this.fromParent.officeId;
      }else if(this.context==="Update Subscription"){
        this.subscription = this.fromParent.subscription;
        let x = new Date(this.subscription.startDate);
        this.subscription.startDate = this.datePipe.transform(x, "yyyy-MM-dd");
        let y = new Date(this.subscription.endDate);
        this.subscription.endDate = this.datePipe.transform(y, "yyyy-MM-dd");
      }
     

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

   }

   addWcaSubscription(){  
    this.subscriptionService.addWcaSubscription(environment.apiUrl + "cbOrgAdmin/addNewWcaSubscription" ,this.subscription).then(
      res=>{
        this.passEntry.emit(this.subscription);
        this.toastr.success("Subscription added successfully !");
        this.activeModal.close();
      });
   }

   editWcaSubscription(){  
    this.subscriptionService.editWcaSubscription(environment.apiUrl + "cbOrgAdmin/updateWcaSubscription" ,this.subscription).then(
      res=>{
        this.passEntry.emit(this.subscription);
        this.toastr.success("Subscription updated successfully !");
        this.activeModal.close();
      });
   }

   get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }


  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}


