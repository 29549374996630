
 
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
 
 
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Products</li>
    </ol>
  </nav>
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  <div class="p-4">
   
     
        <div class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              Products
            </span>

            <a style="cursor:pointer;float: right;" (click)="openModalForProductAdd();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>

  
          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <table *ngIf="productExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="productOptions" >
              <thead class="tableheader">
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Description</th>
                  <th>Action</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of products">
                  <td class="td-height">{{ccd.productName}}</td>
                  <td class="td-height"  style="text-align: right;">{{ccd.productPrice}}</td>
                  <td class="td-height">{{ccd.productDescription}}</td>
                  <td class="td-height" style="width: 17rem; height: 2rem;">
                    <button *ngIf="ccd.productStatus!== null && ccd.productStatus" type="button"
                      class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
                    <button *ngIf="ccd.productStatus !== null && !ccd.productStatus" type="button"
                      class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
  
                   
                   
                    <button (click)="editProduct(ccd.productId)" class="btn btn-secondary">Edit</button>
                  </td>
                  

                                    

  <ng-template #confirmActivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;"  id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to activate product ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updateProductStatus(ccd.productId);modal.dismiss('Cross click')">Activate</button>
    </div>
  </ng-template> 
  
  
  <ng-template #confirmDeactivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to deactivate product ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updateProductStatus(ccd.productId);modal.dismiss('Cross click')">Deactivate</button>
    </div>
  </ng-template>


                </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>
  
 
  
  
  













  <div class="p-4">
   
     
    <div class="table-header row" [ngStyle]="backgroundStyle" style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Taxes
        </span>

        <a style="cursor:pointer;float: right;" (click)="addTax();">
          <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
        </a>
        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportTaxArray()">
          <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
        </a>

      </div> 
    </div>
    <div class="card">
      <div class="card-body table-responsive">
       
        <table *ngIf="taxesExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="productOptions" >
          <thead class="tableheader">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Value</th>
             
              <th>Action</th>
             
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of taxes">
              <td class="td-height">{{ccd.name}}</td>
              <td class="td-height">{{ccd.type}}</td>
              <td style="text-align: right;" class="td-height">{{ccd.value}}</td>
              <td class="td-height" style="width: 17rem; height: 2rem;">
                <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
                  class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivationTax)">Deactivate</button>
                <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
                  class="btn btn-success mr-2" (click)="open(confirmActivationTax)">Activate</button>

               
                <button (click)="editTax(ccd)" class="btn btn-info">Edit</button>
              </td>
              

<ng-template #confirmActivationTax let-modal>
<div class="modal-header">
  <h4 class="modal-title" style="font-weight: bold;"  id="modal-basic-title">Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure to activate tax ?
</div> 
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
  <button type="button" class="btn btn-success" (click)="taxStatusUpdate(ccd.id);modal.dismiss('Cross click')">Activate</button>
</div>
</ng-template> 


<ng-template #confirmDeactivationTax let-modal>
<div class="modal-header">
  <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure to deactivate tax ?
</div> 
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
  <button type="button" class="btn btn-success" (click)="taxStatusUpdate(ccd.id);modal.dismiss('Cross click')">Deactivate</button>
</div>
</ng-template>



            </tr>
          </tbody>
        </table>

      </div>
    </div>
  

</div>