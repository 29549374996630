<div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Assign Role : {{userFirstName}} {{userLastName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form>

  <div class="row col-md-12"> 

    <label class="col-md-3">New Role :</label> 

    <form class="col-md-6" #aplnrForm="ngForm"> 
          <select #curId="ngModel" required class="form-control" [(ngModel)]="roleIdToAssign">
           <option value="" disabled>Choose Role</option>
            <option  class="form-control" [ngValue]="assignableRole.id" *ngFor="let assignableRole of assignableRoles">{{assignableRole.displayName}}</option>
          </select>
          <div class="alert alert-danger" *ngIf="curId.touched && !curId.valid">Select Role</div>
          <br>
        </form>


      <button class="col-md-2" [disabled]="!aplnrForm.valid" class="btn btn-grad-custom" style="float: right;" [ngStyle]="backgroundStyle" (click)="assignRole()" > Assign </button>
          
      
  </div>
 
       
        <br>

       

        <table>
          <thead>
            <tr>
              <th>Current Roles</th>
              <th>Action</th>

          </tr>
          </thead>
          <tbody>
            <tr style="color: black;" *ngFor="let role of assignedRoles">
                <td>{{role.displayName}}</td>
                <td>
                  <button *ngIf="role.userRoleOfficeStatus!== null && role.userRoleOfficeStatus && role.roleName !== 'OFFICE_ADMIN'" type="button"
                    class="btn btn-danger mr-2  btn-sm" (click)="updateUserRoleOfficeStatus(role.userRoleOfficeId)">Deactivate</button>
                  <button *ngIf="role.userRoleOfficeStatus !== null && !role.userRoleOfficeStatus && role.roleName !== 'OFFICE_ADMIN'" type="button"
                    class="btn btn-success mr-2" (click)="updateUserRoleOfficeStatus(role.userRoleOfficeId)">Activate</button>
                  
                </td>
            </tr>
        </tbody>
        </table>

       
    </form>
    </div>
    </div>
    
   