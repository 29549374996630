import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { TargetGroup } from 'src/app/BIZ_CRM/biz_crm_classes/target-group';
import { ViewTargetBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-target-based-invoices/view-target-based-invoices.component';
// import { ViewTargetsTableComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/target/view-targets-table/view-targets-table.component';
import { TargetService } from 'src/app/BIZ_CRM/biz_crm_services/target.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
// fix_target_name_error

@Component({
  selector: 'app-bcrm-man-targets',
  templateUrl: './bcrm-man-targets.component.html',
  styleUrls: ['./bcrm-man-targets.component.scss']
})
export class BcrmManTargetsComponent implements OnInit {

  showSpinner = false;
  gridColumns = 3;
  currentRole = "BCRM_MAN";
  officeId!: string;
  loggedInUser: any;
  userName!: string;
  userId!: string;
  navbarTitle : any;
  targetGroup : TargetGroup = new TargetGroup();

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  userOptions: any;
  usersExists = false;

  targets : Target[] = [];

  constructor(
              private router : Router,
              private datePipe : DatePipe,
              private targetService : TargetService,
              private ngbModalService: NgbModal,
              private sharedDataService : SharedDataService ) {
  }
  
  ngOnInit(): void {

    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;

    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.getTargets();

    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

  }

  getTargets(){
this.showSpinner = true
    this.targetService.getCurrentUserTargets(environment.apiUrl + "target/getCurrentUserTargets").then(
      data=>{
        this.targets = data;
        this.usersExists = true;
        this.targets.forEach((target)=>{

          let sd = target.targetStartDate;
          let startDate = new Date(sd);
          target.targetStartDate = startDate;
          target.targetStartDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');   
      
          let ed = target.targetEndDate;
          let endDate = new Date(ed);
          target.targetEndDate = endDate;
          target.targetEndDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');  
      
          if(target.targetAchieved !== 0 && target.targetAchieved !== null){
            target.targetAchieved =  Math.round((target.targetAchieved + Number.EPSILON) * 100) / 100;
          }

          });
          this.showSpinner = false;
      });
  }

  checkForChildTargets(targetId : any, targetValue : any, targetAchieved : any, targetName : any, targetStartDate : any, targetEndDate : any, executiveName : any){
    this.targetService.checkForChildTargets(environment.apiUrl + "target/checkForChildTargets/" + targetId).then(
      data=>{
        this.targetGroup = data;        
        if(this.targetGroup.childTargetsExist === false){
          this.viewTargetPeriodBasedOrders(targetId, targetValue, targetAchieved, targetName, targetStartDate, targetEndDate);
        }else if(this.targetGroup.childTargetsExist === true){
          this.viewTargetsTable(this.targetGroup.targets, executiveName,targetValue, targetAchieved,targetName);
        }
      });
  }
  
  viewTargetsTable(targets : any , executiveName : any, targetValue : any, targetAchieved : any, targetName : any){
    // this.config.class = 'modal-md';
    // const modalRef = this.ngbModalService.open( ViewTargetsTableComponent,
    //   {
    //     size : 'lg',
    //     scrollable: true,
    //     windowClass: 'app-modal-window',
    //     keyboard: false
    //   });
    // let data = { 
    // targets : targets,
    // manager : executiveName,
    // targetValue : targetValue,
    // targetAchieved : targetAchieved,
    // targetName : targetName
    // }
    // modalRef.componentInstance.fromParent = data;
    //   modalRef.result.then((result) => {
    //   }, (reason) => {
    //   });
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    // });    
  } 

  viewTargetPeriodBasedOrders(targetId : any,targetValue : any, targetAchieved : any, targetName : any, targetStartDate : any, targetEndDate : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewTargetBasedInvoicesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      targetId : targetId,
      targetStartDate : targetStartDate,
      targetEndDate : targetEndDate,
      targetName : targetName,
      targetValue : targetValue,
      targetAchieved : targetAchieved
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  } 

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }
 

}
