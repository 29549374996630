<h2 class="container">Customer  List</h2>
<div class="container">
<table class="table table-striped" hover="true">
    <thead class="black white-text">
        <tr class="tr"> 
            <th>Customer Id</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Gender</th>
            <th>Date of Birth</th>
            <th>Phone</th>
            <th>Alternate Phone</th>
            <th>Email Id</th>
            <th>Status</th>
            <th>Action:Update</th>
            <th>Action:Delete</th>
        </tr> 
    </thead>
    <tbody>
        <tr *ngFor = "let cust of customers" class="tr">
            <td>{{cust.customerId}}</td>
            <td>{{cust.firstName}}</td>
            <td>{{cust.lastName}}</td>
            <td>{{cust.customerGender}}</td>
            <td>{{cust.customerDob}}</td>
            <td>{{cust.customerPhone}}</td>
            <td>{{cust.customerAltPhone}}</td>
            <td>{{cust.customerEmailId}}</td>
            <td>{{cust.customerStatus}}</td>
            <td>
                <button (click) = "updateCustomer(cust.customerId)" onclick="return confirm('Are you sure you want to update this item?');" style="margin-left: 10px">Update</button>
            </td>
            <td>    
                <button (click) = "deleteCustomer(cust.customerId)" onclick="return confirm('Are you sure you want to delete this item?');" class="btn btn-danger" style="margin-left: 10px">Delete</button>
            </td>
        </tr>
    </tbody> 
</table>
</div>
