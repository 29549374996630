import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
import { TaxService } from 'src/app/BIZ_CRM/biz_crm_services/tax.service';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { InvoiceDetailsList } from 'src/app/crmClasses/invoice-details-list';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compute-tax-to-approve-order',
  templateUrl: './compute-tax-to-approve-order.component.html',
  styleUrls: ['./compute-tax-to-approve-order.component.scss']
})
export class ComputeTaxToApproveOrderComponent implements OnInit {

  clicked = false;
  taxes : Tax[] = [];
  cess : Tax[] = [];
  taxesAndCess : Tax[] = [];
  taxesAndCessFinal : Tax[] = [];
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,private invoiceService : InvoiceService, 
    private taxService : TaxService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             invoiceId : any;
             invoice : InvoiceList = new InvoiceList();
             invDetails: InvoiceDetailsList[] = [];
             addedProductIds : string[] = [];
             discount : any;
             discountObj : InvoiceDetailsList = new InvoiceDetailsList();
             discounted = false;

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    this.getTaxesAndCess();

    this.invoiceId = this.fromParent.invoiceId;
    

    this.invoiceService.getInvoiceById(this.invoiceId).subscribe(data=>{
    this.invoice = data;
     });

    this.invoiceService.getAllInvDetails(this.invoiceId).subscribe(data =>{
      this.invDetails = [];
    this.invDetails = data;

    this.taxesAndCess=[];

    this.invDetails.forEach((invDet)=>{
      if(invDet.type === "tax" || invDet.type === "cess"){
        this.taxesAndCess.push(invDet.taxObject);
      }else if(invDet.type === "invoice detail"){
        this.addedProductIds.push(invDet.fkProductId);

        invDet.totalDiscountAmount = invDet.perQuantityDiscountRate * invDet.quantity;
        invDet.totalPriceBeforeDiscount = invDet.totalPrice + invDet.totalDiscountAmount;
        let x =  invDet.alternateQuantity /  invDet.quantity;
        invDet.perAltQuantityDiscountRate =  invDet.perQuantityDiscountRate / x;

      }else if(invDet.type === "discount"){
        this.discount = invDet.taxAmount;
        this.discounted = true;
        this.discountObj = invDet;
        this.invoice.totalBeforeDiscount = this.invoice.totalAmount + this.discount;
      }
    });
     });
  }

  getTaxesAndCess(){

    this.taxService.getTaxesByOfficeId(environment.apiUrl + "tax/getTaxesByOfficeId").then(
      data=>{
        this.taxesAndCess = data;
        
        this.taxesAndCess.forEach((val)=>{
          if(val.type === "tax"){
            this.taxes.push(val);
          }else if(val.type === "cess"){
            this.cess.push(val);
          }
        });
      });
   
  }

  handleClick(taxCess : any) {
 
    let exists = false;
    let count = 0;
    this.taxesAndCessFinal.forEach((val)=>{
      count++;
      if(val.id === taxCess.id){
        exists = true;
      }
    });

    if(count === this.taxesAndCessFinal.length){
      if(exists === false){
        this.taxesAndCessFinal.push(taxCess);
        
      }else if(exists === true){

        this.taxesAndCessFinal = this.taxesAndCessFinal.filter(obj => obj !== taxCess);   
    }
    }
  }

  onSubmit(){

    // this.passEntry.emit(this.taxesAndCessFinal);
    // this.activeModal.close();

    this.taxesAndCess = this.taxesAndCessFinal;
    this.invoice.totalTax = 0;
    this.invoice.totalCess = 0;
    this.invoice.grandTotal = 0;

    let count = 0;
    this.taxesAndCess.forEach((value)=>{
      count = count + 1;
      if(value.type === "tax"){
       let val = value.value / 100;
       let tax = this.invoice.totalAmount * val;
       value.calculatedTax = tax;
       this.invoice.totalTax = this.invoice.totalTax + tax;               
      }
    });

    if(this.taxesAndCess.length === count){
      this.taxesAndCess.forEach((value)=>{
        if(value.type === "cess"){
          let val = value.value / 100;
          let cess = this.invoice.totalTax * val;
          value.calculatedTax = cess;
          this.invoice.totalCess = this.invoice.totalCess + cess;               
        }
      });
    }
    
    this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

let taxList : InvoiceDetails[] = [];
 this.taxesAndCess.forEach((tax)=>{
   let inv = new InvoiceDetails();
   inv.taxId = tax.id;
   inv.taxType = tax.type;
   inv.taxAmount = tax.calculatedTax;
   taxList.push(inv);
 });

 this.invoice.taxesAndCess = taxList;

 this.invDetails.forEach((invDet)=>{
  if(invDet.type === "tax" || invDet.type === "cess"){
    this.invoiceService.deleteOldTaxDetails(environment.apiUrl + "relatedInvDet/deleteOldTaxDetails/" + invDet.invoiceDetId).then(
      data=>{
      });
  }
});

this.invoiceService.addNewTaxDetails(environment.apiUrl + "completeInvoice/addNewTaxDetails", this.invoice).then(
  data=>{

    this.invoiceService.updateInvoice(environment.apiUrl + "invoice/update", this.invoice).then(
      data =>{
        this.invoice = data;
        this.passEntry.emit(this.invoice);
        this.activeModal.close();
      });

  });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
 