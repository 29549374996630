import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { GetAccDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-acc-details';
import { GetCombinedPTCDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-combined-ptc-details';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CombinedParentTeacherService {

  localCombinedPTCDetails: GetCombinedPTCDetails = new GetCombinedPTCDetails;

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }
  private getUrl2 = environment.apiUrl + "combinedPTC/getAccountDetails";
  private getUrl3 = environment.apiUrl + "getActPlannerListBySubId";
  private getUrl5 = environment.apiUrl + "combinedPTC/getCombinedDetailsForEdit";
  private getUrl6 = environment.apiUrl + "combinedPTC/getChildAndActivitiesForParentMentorForExtPage";
  private getUrl7 = environment.apiUrl + "activityQuestion/getByActPlnrIdExt";

  markActivityAsComplete(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  addCombinedParentTeacher(avenue: string, user : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, user, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, user, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getAccountDetails(parentUserId : string):Observable<GetAccDetails>{
    return this.httpClient.get<GetAccDetails>(`${this.getUrl2}/${parentUserId}`);
  }

  getActPlannersListBySubId(subId : string):Observable<ActivityPlanner[]>{
    return this.httpClient.get<ActivityPlanner[]>(`${this.getUrl3}/${subId}`);
   } 

getDetails(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {
    self.httpClient.get(avenue, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}

getActivityQuestionListByActPlnrIdExt(id : string):Observable<any[]>{
  return this.httpClient.get<any[]>(`${this.getUrl7}/${id}`);
 } 

  getActivityQuestionListByActPlnrId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   setLocalServiceCombinedPTCDetails( obj : GetCombinedPTCDetails){
      this.localCombinedPTCDetails = obj;
   }  

   getLocalCombinedPTCDetails(){
     return this.localCombinedPTCDetails;
   }

   addPtcActActualActScoreExt(add : any):Observable<AddCombinedParentTeacher>{
    return this.httpClient.post<AddCombinedParentTeacher>(environment.apiUrl + 'combinedPTC/addActActualActScoreExt', add);
  }

  addPtcActActualActScore(avenue: string, obj : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, obj, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getCombinedDetailsForEdit(parentUserId : string):Observable<AddCombinedParentTeacher>{
    return this.httpClient.get<AddCombinedParentTeacher>(`${this.getUrl5}/${parentUserId}`);
  }

  updateCombinedParentTeacher(add : AddCombinedParentTeacher):Observable<AddCombinedParentTeacher>{
    return this.httpClient.put<AddCombinedParentTeacher>(environment.apiUrl + 'combinedPTC/updateDetails', add);
  }
  
  getChildAndActivitiesForParentMentorForExtPage(botPMExtActLinkId : string):Observable<any>{
    return this.httpClient.get<any>(`${this.getUrl6}/${botPMExtActLinkId}`);
  }

  getCombinedPTCActPlnrDtoByUserId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
}
