import { BotItem } from "./bot-item";

export class BotInvoice {
  invoiceId: any;
  invoiceNo: any;
  orderDate: any;
  fkBotUserId: any;
  fkOfficeId: any;
  invoiceRemarks: any;
  invoiceStatus: any;
  type: any;
  orderStatus: any;
  subTotal: any;
  grandTotal: any;
  linkType: any;
  cartItems: BotItem[] = [];
  invoices: BotInvoice[] = [];
  fkBotUserLocationId: any;
  fkBotUserLocationAddress: any;
  fkBotUserName: any;
  fkBotUserPhone: any;

  orderCreationDate: any;
  orderPlacedDate: any;
  invoiceDate: any;

  fromDate: any;
  toDate: any;

  ordersPlacedCount: any;
  ordersCancelledCount: any;
  ordersProcessedCount: any;
  ordersInvoicedCount: any;
}
