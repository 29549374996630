import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-choose-assignment-modal',
  templateUrl: './choose-assignment-modal.component.html',
  styleUrls: ['./choose-assignment-modal.component.scss']
})
export class ChooseAssignmentModalComponent implements OnInit {
  selectedOption : any = "2";
  selected = "2";
  safeUrl : any;
  unsafeUrl : any;
  subId!: string;
  teacherId!: string
  classId!: string;
  selectedId! : string;
  completedActivities : boolean = false;
  newActivities : boolean = false;
  allActivities : boolean = false;

  allAssignmentList : any[] = [{
                                      activityPlannerId : '',
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '',
                                      activityPlannerCompletionStatus : '',
                                      safeUrl : '',
                                      selectedState : '',
                                      spontaneous : '',
                                      assignmentActiveStatus : '',
                                      collapsedState : ''

                                    }]

  activeAssignmentList : any[] = [{
                                      activityPlannerId : '',
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '',
                                      activityPlannerCompletionStatus : '',
                                      safeUrl : '',
                                      selectedState : '',
                                      spontaneous : '',
                                      assignmentActiveStatus : '',
                                      collapsedState : ''

                                    }]

  completedAssignmentList : any[] = [{
                                      activityPlannerId : '',
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '',
                                      activityPlannerCompletionStatus : '',
                                      safeUrl : '',
                                      selectedState : '',
                                      spontaneous : '',
                                      assignmentActiveStatus : '',
                                      collapsedState : ''

                                    }]

  NewAssignmentList : any[] = [{
                                      activityPlannerId : '',
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '',
                                      activityPlannerCompletionStatus : '',
                                      safeUrl : '',
                                      selectedState : '',
                                      spontaneous : '',
                                      assignmentActiveStatus : '',
                                      collapsedState : ''

                                    }]

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  value1: any;

   constructor( public activeModal: NgbActiveModal,
                private toaster: ToastrService,
                private activityPlannerService : ActivityPlannerService,
                private sanitizer : DomSanitizer,
                private imageService : FileServiceService,
                private sharedDataService : SharedDataService
                ) { }
                loggedInUser: any;
                theme: any;

  ngOnInit(): void {

    this.selectedId = 'null';

    this.subId = this.fromParent.subjectId;
    this.teacherId = this.fromParent.teacherId;
    this.classId = this.fromParent.classId;
    
    this.getAssignmentListBySubId();
 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  getAssignmentListBySubId(){

    this.activityPlannerService.getAssignmentsForSwapBySubId(environment.apiUrl + "activityPlanner/getAssignmentsForSwapBySubId/" + this.teacherId + "/" + this.subId).then(
      data=>{
        this.allAssignmentList = data;

        this.allActivities = true;

        this.allAssignmentList.forEach( (value) => {
        
          value.collapsedState = false;

          this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
            data=>{
              this.unsafeUrl = URL.createObjectURL(data);
              this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
  
              value.safeUrl = this.safeUrl;
            });
        });
        this.showNewAssignments();
      });
  }

  select(id : string){
    this.selectedId = id;
  
    this.allAssignmentList.forEach((value)=>{
       if(value.activityPlannerId === id){
        value.selectedState = true;
       }else {
        value.selectedState = false;
       }
    });
  
    this.activeAssignmentList.forEach((value)=>{
      if(value.activityPlannerId === id){
       value.selectedState = true;
      }else {
       value.selectedState = false;
      }
   });
  
   this.completedAssignmentList.forEach((value)=>{
    if(value.activityPlannerId === id){
     value.selectedState = true;
    }else {
     value.selectedState = false;
    }
  });

  this.NewAssignmentList.forEach((value)=>{
    if(value.activityPlannerId === id){
     value.selectedState = true;
    }else {
     value.selectedState = false;
    }
  });
  }

  onChange(event : any){ 

    this.value1 = this.selectedOption;
    if(this.value1 === "1"){
      this.showActiveAssignments();
    }else if(this.value1 === "2"){
      this.showNewAssignments();
    } else if(this.value1 === "3"){
      this.showCompletedAssignments();
    } else if(this.value1 === "4"){
      this.showNewAssignments();
    }
  }

valueChange(value : any){
  this.value1 = value;
  if(this.value1 === "1"){
    this.showActiveAssignments();
  }else if(this.value1 === "2"){
    this.showNewAssignments();
  } else if(this.value1 === "3"){
    this.showCompletedAssignments();
  } else if(this.value1 === "4"){
    this.showNewAssignments();
  }
}

collapseActivityCardContent(id : string){
      
  this.allAssignmentList.forEach((value)=>{

    
    if(value.activityPlannerId === id){

      if(value.collapsedState === true){
        value.collapsedState = false
      } else if(value.collapsedState === false){
        value.collapsedState = true
      }

    }
  });
}

showCompletedAssignments(){

  this.completedAssignmentList.pop();
  this.completedAssignmentList = [];

  this.allAssignmentList.forEach((value)=>{
    if(value.activityPlannerCompletionStatus === true){
      this.completedAssignmentList.push(value);
    }
  });

  this.newActivities = false;
  this.allActivities = false;
  this.completedActivities = true;

}

showAllAssignments(){

  this.newActivities = false;
  this.completedActivities = false;
  this.allActivities = true;
}

showActiveAssignments(){

  this.activeAssignmentList.pop();
  this.activeAssignmentList = [];

  this.allAssignmentList.forEach((value)=>{
    if(value.activityPlannerCompletionStatus === false && value.assignmentActiveStatus === true){
      this.activeAssignmentList.push(value);
    }
  });

  this.allActivities = false;
  this.completedActivities = false;
  this.newActivities = true;

}

showNewAssignments(){

  this.NewAssignmentList.pop();
  this.NewAssignmentList = [];

  this.allAssignmentList.forEach((value)=>{
    if(value.activityPlannerCompletionStatus === false && value.assignmentActiveStatus === false){
      this.NewAssignmentList.push(value);
    }
  });

  this.newActivities = false;
  this.completedActivities = false;
  this.allActivities = true;

}

closeUpdateModal(){
  this.activeModal.close();
}

swap(){

  if(this.selectedId === 'null'){
    this.toaster.error('Select Activity to Swap');
  } else {

    this.passEntry.emit(this.selectedId);
    this.activeModal.close();

  }
  
}
}
