import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-product-details',
  templateUrl: './view-product-details.component.html',
  styleUrls: ['./view-product-details.component.scss']
})
export class ViewProductDetailsComponent implements OnInit {

  prod : Product = new Product();
  productId!: string;

  @Input() fromParent: any; 

  constructor(private productService : ProductService,
              public activeModal: NgbActiveModal ) { }

  ngOnInit(): void {

    this.productId = this.fromParent.productId;

    this.productService.getProductByIdForCrmAdmin(environment.apiUrl + "crmproduct/getProductByIdForCrmAdmin/" + this.productId).then(data=>{
      this.prod = data;
    }, error => console.log(error));
  }

  closeModal(){
    this.activeModal.close();
  }


}