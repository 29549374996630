<div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add {{header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card">
        <div class="card-body">


<form *ngIf="search === true">

  <div class="col-md-12">
    <label for="">Email</label>
  <input class="col-md-12 form-control"
   email type="email" [(ngModel)]="email" placeholder="Enter email" name="search">
 


<br>
  <div style="text-align:center;">
  <button style="width: auto;color: black;" (click)="searchFoUser();" class="btn btn-info">
    Proceed  
  </button>
</div>

</div>

  </form>

  <h4 *ngIf="customerExists === true" style="font-weight: bold; text-align: center;color: black;">Customer Exists ! </h4>
<br *ngIf="customerExists === true">

<h4 *ngIf="userExists === true" style="font-weight: bold;text-align: center;color: black;">User Profile Exists ! </h4>
<br *ngIf="userExists === true">

<h4 *ngIf="userAndCustomerProfileExist === true" style="font-weight: bold;text-align: center;color: black;">User & Customer Profiles Exist ! </h4>
<br *ngIf="userAndCustomerProfileExist === true">

  <table *ngIf="search === false">
    
    <tr>
    <td>Name</td>
    <td>{{profile.firstName}} {{profile.lastName}}</td>
    </tr>

    <tr *ngIf="profile.gender !== null && profile.gender !== undefined">
      <td>Gender</td>
      <td>{{profile.gender}}</td>
      </tr>

  
    <tr *ngIf="profile.dateOfBirth !== null && profile.dateOfBirth !== undefined">
    <td>DoB</td>
    <td>{{profile.dateOfBirth}}</td>
    </tr>

    <tr>
      <td>Phone</td>
      <td>{{profile.phoneNumber}}</td>
    </tr>

    <tr>
    <td>Email</td>
    <td>{{profile.email}}</td>
    </tr>

  </table>
   
  <div *ngIf="customerExists === true">
    <br>
  <h4 style="text-align: center;color: black;font-weight: bold;">Would you like to continue with the same profile ?</h4>
  <button (click)="useDifferentEmail();" class="btn btn-info" style="width: auto;margin: 2%;float: left;">Use different email</button>
    <button  (click)="showProfile();" style="margin: 2%;float: right;" class="btn btn-success">Yes</button>
</div>
<div *ngIf="userExists === true">
  <br>
<h4 style="text-align: center;color: black;font-weight: bold;">Would you like to Create Customer Profile the same user ?</h4>
<button (click)="useDifferentEmail();" class="btn btn-info" style="width: auto;margin: 2%;float: left;">Use different email</button>
  <button (click)="createCustomer();" style="margin: 2%;float: right;" class="btn btn-success">Yes</button>
</div>
<div *ngIf="userAndCustomerProfileExist === true">
  <br>
<h4 style="text-align: center;color: black;font-weight: bold;">Would you like to try a different email ?</h4>
<button (click)="useDifferentEmail();" class="btn btn-info" style="width: auto;margin: 2%;float: right;">Use different email</button>
<button (click)="closeUpdateModal();" class="btn btn-info" style="width: auto;margin: 2%;float: left;">Cancel</button>

</div>

</div>
    </div>
  </div>


