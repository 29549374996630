<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'choose_sections'" style="color: white;" class="modal-title pull-left">Choose sections</h4>
    <h4 *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'assign_sections'" style="color: white;" class="modal-title pull-left">Assign sections</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body">

        <div *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'choose_sections'">

            <button (click)="open(addSection);" style="width: auto;" class="btn btn-info">Add Sections</button>
<br>
<br>

            <div class="form-check" *ngFor="let section of sections">
                <input (click)="section.selected=!section.selected" class="form-check-input"
                 type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    {{section.sectionName}} 
                </label>
              </div>




        </div>

        <div *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'assign_sections'">

            <div *ngFor="let option of options">
                <label style="font-weight: bold;">{{option.responseOptionNumber}} {{option.responseOption}}</label> <br>

                <mat-radio-group required aria-label="userResType" 
                [(ngModel)]="option.interactiveListSectionName">
                    <mat-radio-button *ngFor="let section of option.sections" [value]="section.sectionName" >{{section.sectionName}}</mat-radio-button>
                  </mat-radio-group>

            </div>



        </div>

       

</div>
</div>

<div class="modal-footer">

    <button *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'choose_sections'"
    (click)="proceedToAssignSections();" style="width: auto;" class="btn btn-success">Proceed</button>

    <button class="btn btn-sm btn-primary float-right" 
    *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'assign_sections'" 
    style="width: auto;" (click)="resetSectionsSelection();">Change sections</button>

    <button class="btn btn-sm btn-primary float-right" 
    *ngIf="context === 'assign_sections_&_make_options_interactive_lists' && subcontext === 'assign_sections'" 
    (click)="assignSections();">Save</button>

    </div>

<ng-template #addSection let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Add Section</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label for="newSectionName">New Section</label>
<input class="form-control" type="text" id="newSectionName" [(ngModel)]="newSectionName" placeholder="Enter Section Name">
</div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="addNewSectionToList();modal.dismiss('Cross click')">Add</button>
  </div>
</ng-template>