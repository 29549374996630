import { Component } from '@angular/core';
import { WebSocketAPI } from './BOT_CODE/bot_classes/web-socket-api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CourseManagement-UI';

  // //stomp
  // webSocketAPI!: WebSocketAPI;
  // greeting: any;
  // name!: string;
  // ngOnInit() {
  //   this.webSocketAPI = new WebSocketAPI(new AppComponent());
  // }

  // connect(){
  //   this.webSocketAPI._connect();
  // }

  // disconnect(){
  //   this.webSocketAPI._disconnect();
  // }

  // sendMessage(){
  //   this.webSocketAPI._send(this.name);
  // }

  // handleMessage(message:any){
  //   this.greeting = message;
  // }
  // //stomp
}
