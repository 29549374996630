<div  [ngStyle]="backgroundStyle" class="modal-header">
  <h4  style="width: 50%;color: white;"><img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">{{details.studentName}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">

    <h2 style="text-align: center;" *ngIf="this.resultExists === false">No Results to Display !</h2>
  <div class="container mt-2 " >

    <div class="row" *ngIf="this.showResults === true">
  
 

        <div class="col-md-4 col-sm-6 item" *ngFor="let result of resultListWithChartData">


        <div class="card item-card card-block">


        <span class="product-price">
           <b>{{result.parentSkillName}}</b>
        </span>

        <br>
        <br>

        <p style="color: black; text-align: center;margin-top: 10px;margin-bottom: 0%;" >{{result.skillName}}</p> 
        <p style="text-align: center;margin-top: 0%; color: black;" >Score : {{result.skillScore}}</p> 

      <div style="align-self: center;">
        <img style=" border-radius: 50%; width: 85px; height: 85px;" src="https://static.pexels.com/photos/262550/pexels-photo-262550.jpeg" alt="Avatar" >
      </div>

      <br>
      
      <div class="chart-wrapper">
        <canvas baseChart 
            [datasets]="result.lineChartData" 
            [labels]="lineChartLabels" 
            [options]="lineChartOptions"
            [colors]="lineChartColors" 
            [legend]="lineChartLegend" 
            [chartType]="lineChartType" 
            [plugins]="lineChartPlugins">
        </canvas>
    </div>
        
    

      
    <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Suggestion
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>{{result.resultSuggestion}}
    </p>
  </mat-expansion-panel>
    
    
  </div>
 
  
<br>
<br>


      </div>
   <br>
  
    
    
  </div>
  
  </div>
</div>

  