<div [style.background-color]='getColor()'  class="modal-header">
   
    <h4 style="color: white;" class="modal-title pull-left">Choose Assignment</h4>
  
     
  
    
      <select style="margin-right: 1em;" class="form-select form-select-sm"  [(ngModel)]="selectedOption" (change)="onChange($event)" aria-label=".form-select-sm example">
        <option  value="1">Active Assignments</option>>
          <option  value="2">New Assignments</option>
            <option  value="3">Completed Assignments</option>
      </select>
  
  
  
    </div> 
  
    <div class="modal-body">
  
    
      
      <!-- Show All Activities -->
      <div class="content" [style.display]="allActivities ? 'block' : 'none'">
        <h3 *ngIf="allAssignmentList === undefined || allAssignmentList.length == 0"> No Assignments to display</h3>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of NewAssignmentList">
            <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
              <div style="float: left;" *ngIf="card.selectedState === true">
                <img style="border-radius: 50%;margin-left: 2%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
              </div> 
              <a style="color: rgb(26, 116, 26);font-weight: bold;float: right;" *ngIf="card.activityPlannerCompletionStatus === true">
                <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
              </a>
  
  <br>
  <br>
              <div style="width: 100%;text-align: center;margin-bottom: 1%;">
                <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
              </div>
             
                <div style="text-align: center;">
                <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2> 
                
                <button (click)="select(card.activityPlannerId)" class="btn btn-info">Select</button>

            </div>
              
           
              <div *ngIf="card.collapsedState === true" >
                <div style="text-align: center;">
                  <p>
                    {{card.activityPlannerDescription}}
                  </p>
                </div>
              </div>
              <div style="text-align: center;">
                <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent(card.activityPlannerId)">
                  {{card.collapsedState ? 'Close' : 'View Details'}}</button>
                </div>
            </mat-card>
          </div>
        </div>
        </div>
  
        <!-- Show New Activities -->
        <div class="content" [style.display]="newActivities ? 'block' : 'none'">
          <h3 *ngIf="activeAssignmentList === undefined || activeAssignmentList.length == 0">Active Assignments will be displayed here</h3>
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of activeAssignmentList">
              <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
                <div style="float: left;" *ngIf="card.selectedState === true">
                  <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
                </div> 
                <a style="color: rgb(26, 116, 26);font-weight: bold;float: right;" *ngIf="card.activityPlannerCompletionStatus === true">Completed
                  <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
                </a>
    <br>
    <br>
                <div style="width: 100%;text-align: center;margin-bottom: 1%;">
                  <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
                </div>
                
                <div style="text-align: center;">
                  <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2>
                  <button (click)="select(card.activityPlannerId)" class="btn btn-info">Select</button>

                </div>
                
              <div *ngIf="card.collapsedState === true" >
                <div style="text-align: center;">
                  <p>
                    {{card.activityPlannerDescription}}
                  </p>
                </div>
              </div>
              <div style="text-align: center;">
                <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent(card.activityPlannerId)">
                  {{card.collapsedState ? 'Close' : 'View Details'}}</button>
                </div>
               
              </mat-card>
            </div>
          </div>
          </div>  
  
        <!-- Show Completed Activities -->
        <div class="content" [style.display]="completedActivities ? 'block' : 'none'">
          <h3 *ngIf="completedAssignmentList === undefined || completedAssignmentList.length == 0"> Completed Assignments will be displayed here</h3>
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of completedAssignmentList">
              <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
                <div style="float: left;" *ngIf="card.selectedState === true">
                  <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
                </div> 
                <a style="color: rgb(26, 116, 26); font-weight: bold;float: right;" *ngIf="card.activityPlannerCompletionStatus === true">Completed
                  <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
                </a>
                
                <br>
                <br>
                <div style="width: 100%;text-align: center;margin-bottom: 1%;">
                  <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
                </div>
               
                <div style="text-align: center;" >
                  <h2 style="margin: 0%;">{{card.activityPlannerName}} </h2>
                  <button (click)="select(card.activityPlannerId)" class="btn btn-info">Select</button>
                </div>
               
              <div *ngIf="card.collapsedState === true" >
                <div style="text-align: center;">
                  <p>
                    {{card.activityPlannerDescription}}
                  </p>
                </div>
              </div>
              <div style="text-align: center;">
                <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent(card.activityPlannerId)">
                  {{card.collapsedState ? 'Close' : 'View Details'}}</button>
                </div>
                
              </mat-card>
            </div>
          </div>
          </div>  
  
  
      </div>
  
    <div class="modal-footer">
      <button type="submit" class="btn btn-sm btn-danger float-right" (click)="closeUpdateModal()">Cancel</button>
      <button type="submit" class="btn btn-sm btn-success float-right" (click)="swap()">Proceed</button>
    </div>
  
  
  
  
  