<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
      
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>  
        
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <mat-tab-group style="padding: 1em;" dynamicHeight>
   
    <mat-tab>

      <ng-template mat-tab-label>
        Categories
         <button (click)="createBotCategory();" matTooltip="Create Category" mat-icon-button>
           <mat-icon>add_box</mat-icon>
       </button>
       </ng-template>

      <div class="p-4">
        <table *ngIf="categoriesExist" class="table table-striped table-sm hover" datatable
        [dtOptions]="userOptions">
        <thead class="tableheader">
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let category of mainCategories">
            <td class="td-height">{{category.name}}</td>
            <td class="td-height">{{category.description}}</td>
            <td>

              <a *ngIf="category.botCategoryStatus!== null && category.botCategoryStatus"
              style="cursor:pointer;color: #007E59;font-weight: bold;" (click)="open(confirmCategoryDeactivation);">
                Active
              </a>
              <a *ngIf="category.botCategoryStatus!== null && !category.botCategoryStatus"
              style="cursor:pointer;color: rgb(173, 52, 52);font-weight: bold;" (click)="open(confirmCategoryActivation);">
                Inactive
              </a>

               <!-- <button *ngIf="category.botCategoryStatus!== null && category.botCategoryStatus" type="button"
        class="btn btn-danger mr-2  btn-sm" (click)="open(confirmCategoryDeactivation);">Deactivate</button>
      <button *ngIf="category.botCategoryStatus !== null && !category.botCategoryStatus" type="button"
        class="btn btn-success mr-2" (click)="open(confirmCategoryActivation);">Activate</button> -->

            </td>
            <td class="td-height" style=" height: 2rem;">
              <a style="cursor:pointer;padding-left: 1rem;" matTooltip="Edit" (click)="editBotCategory(category);">
                <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
              </a>

              <a style="cursor:pointer;padding-left: 1rem;" matTooltip="Subcategories" (click)="viewSubCategories(category);">
                <img src="/assets/images/icons/category.png" width="25" height="25" alt="" loading="lazy">
              </a>

              
            </td>

            <ng-template #confirmCategoryActivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation Activation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to activate category "{{category.name}}" ? <br> <br>

                <div style="font-weight: bold;">Warning !</div> 
                All subcategories and items coming under the category "{{category.name}}" will also be activated !
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="botCategoryAndItemsStatusUpdate(category.botCategoryId);modal.dismiss('Cross click')">Activate</button>
              </div>
            </ng-template>

            <ng-template #confirmCategoryDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation Deactivation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to deactivate category "{{category.name}}" ? <br> <br>
                <div style="font-weight: bold;">Warning !</div> 
                All subcategories and items coming under the category "{{category.name}}" will also be deactivated !
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="botCategoryAndItemsStatusUpdate(category.botCategoryId);modal.dismiss('Cross click')">Deactivate</button>
              </div>
            </ng-template>

          </tr>
        </tbody>
      </table>
      </div>

    </mat-tab>

    <mat-tab>

      <ng-template mat-tab-label>
        Items
         <button mat-icon-button [matMenuTriggerFor]="menu" mat-icon-button>
           <mat-icon>add_box</mat-icon>
       </button>
    <mat-menu #menu="matMenu">
      <button (click)="createBotItem();" mat-menu-item>
        <mat-icon>library_add</mat-icon>
        <span>Create Manually</span>
      </button>
      <button (click)="BotItemsFileUpload();" mat-menu-item>
        <mat-icon>insert_drive_file</mat-icon>
        <span>File Upload</span>
      </button>
    </mat-menu>
       </ng-template>

      <div class="p-4">
        <table *ngIf="itemsExist" class="table table-striped table-sm hover" datatable
        [dtOptions]="userOptions">
        <thead class="tableheader">
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Retailer Id</th>
            <th>Category</th>
            <th>Description</th>
            <th>Price Per Item</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let item of items">
            <td class="td-height">
              <a>
                <img style="border-radius: 50%;" [src]="item.safeImageUrl" width="40" height="40" alt="" loading="lazy">
              </a>
            </td>
            <td class="td-height">{{item.botItemName}}</td>
            <td class="td-height">{{item.retailerId}}</td>
            <td class="td-height">{{item.fkBotCategoryName}}</td>
            <td class="td-height">{{item.botItemDescription}}</td>
            <td class="td-height">{{item.pricePerItem}}</td>

            <td>

              <button *ngIf="item.botItemStatus!== null && item.botItemStatus" type="button"
       class="btn btn-danger mr-2  btn-sm" (click)="open(confirmItemDeactivation);">Deactivate</button>
     <button *ngIf="item.botItemStatus !== null && !item.botItemStatus" type="button"
       class="btn btn-success mr-2" (click)="open(confirmItemActivation);">Activate</button>

           </td>

            <td class="td-height" style=" height: 2rem;">
              <a style="cursor:pointer;padding-left: 1rem;" (click)="editBotItem(item);">
                <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
              </a>
            </td>

            <ng-template #confirmItemActivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to activate item "{{item.botItemName}}" ? <br> <br>

                <div style="font-weight: bold;">Warning !</div> 
                Customers will agaim be able to see the item "{{item.botItemName}}".
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="botItemStatusUpdate(item);modal.dismiss('Cross click')">Activate</button>
              </div>
            </ng-template>

            <ng-template #confirmItemDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to deactivate item "{{item.botItemName}}" ? <br> <br>

                <div style="font-weight: bold;">Warning !</div> 
                Customers will not be able to see the item "{{item.botItemName}}", You can activate it again later.
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="botItemStatusUpdate(item);modal.dismiss('Cross click')">Deactivate</button>
              </div>
            </ng-template>

          </tr>
        </tbody>
      </table>
      </div>

    </mat-tab>

    <mat-tab>

      <ng-template mat-tab-label>
        Configuration
       </ng-template>

      <div class="p-4">
        <table class="table table-striped table-sm hover">
        <thead class="tableheader">
          <tr>
            <th>Context</th>
            <th>Description</th>
            <th>Action</th>
            
          </tr>
        </thead>
        <tbody>
          <tr class="td-height">
            <td class="td-height">
              Response sent to your customers when they place orders using whatsapp catalog 
            </td>
            <td class="td-height">
              <div *ngIf="orderPlacedResponse.botResponseId!==null">
                {{orderPlacedResponse.response}}
              </div>
            </td>
            <td class="td-height">
              <button style="width: auto;" class="btn btn-info" 
              *ngIf="orderPlacedResponse.botResponseId===null"
              (click)="createOrderPlacedResponse();">Create Response</button>
              
              <button style="width: auto;" class="btn btn-info" 
              *ngIf="orderPlacedResponse.botResponseId!==null"
              (click)="open(orderPlacedResponseModal);" >View Response</button>
            </td>
          </tr>

          <!-- <tr class="td-height">
            <td class="td-height">
              Currency
            </td>
            <td class="td-height">
             
            </td>
            <td class="td-height">
              <button style="width: auto;" class="btn btn-info" 
              *ngIf="orderPlacedResponse.botResponseId===null"
              (click)="createOrderPlacedResponse();">Create Response</button>
              
              <button style="width: auto;" class="btn btn-info" 
              *ngIf="orderPlacedResponse.botResponseId!==null"
              (click)="open(orderPlacedResponseModal);" >View Response</button>
            </td>
          </tr>

          <tr class="td-height">
            <td class="td-height">
             Ask Customers to choose address ?
            </td>
            <td class="td-height">
             
            </td>
            <td class="td-height">
              <button style="width: auto;" class="btn btn-info" 
              *ngIf="orderPlacedResponse.botResponseId===null"
              (click)="createOrderPlacedResponse();">Create Response</button>
              
              <button style="width: auto;" class="btn btn-info" 
              *ngIf="orderPlacedResponse.botResponseId!==null"
              (click)="open(orderPlacedResponseModal);" >View Response</button>
            </td>
          </tr> -->
        </tbody>
      </table>
      </div>

    </mat-tab>

  </mat-tab-group>

  <ng-template #orderPlacedResponseModal let-modal>
    <div class="modal-body">
      <h3 style="font-weight: bold;">Order Placed Response</h3>
       {{orderPlacedResponse.response}}  <img (click)="editBotResponse();" style="margin-left: 0.5%;cursor: pointer;" matTooltip="Edit" 
        src="/assets/images/icons/edit.svg" 
       width="15" height="15" 
       alt="" loading="lazy">
       <br> <br>
      <div style="font-weight: bold;">Options <img (click)="addOptionToResponse(orderPlacedResponse);" style="margin-left: 0.5%;cursor: pointer;" matTooltip="Edit" 
        src="/assets/images/icons/add.png" 
       width="15" height="15" 
       alt="" loading="lazy"> :</div> 
      <div *ngFor="let opt of orderPlacedResponse.options">
        <a style="color:black;"> 
          <img  style="margin-left: 0.5%;" matTooltip="Inactive" 
          *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/disable.png" 
          width="15" height="15" 
          alt="" loading="lazy">
          <img  style="margin-left: 0.5%;" matTooltip="Active" 
            *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" 
            width="15" height="15" 
            alt="" loading="lazy">
             {{opt.responseOptionNumber}}. {{opt.responseOption}} 
        
             <img (click)="editOption(opt);" style="margin-left: 0.5%;" matTooltip="Inactive" 
              src="/assets/images/icons/edit.svg" 
             width="15" height="15" 
             alt="" loading="lazy">
             <img (click)="linkNextResponseToOption(opt.botResponseOptionsId);" style="margin-left: 0.5%;" matTooltip="Link Next Response" 
               *ngIf="opt.nextBotResponseId===null" src="/assets/images/icons/paperclip.png" 
               width="15" height="15" 
               alt="" loading="lazy">
               <img (click)="unlinkNextResponseForOption(opt.botResponseOptionsId);" style="margin-left: 0.5%;" matTooltip="Active" 
               *ngIf="opt.nextBotResponseId!==null" src="/assets/images/icons/broken-link.png" 
               width="15" height="15" 
               alt="" loading="lazy">
    
        </a> <br> 
    </div>
    </div> 
  </ng-template>