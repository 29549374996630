import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { OfficesService } from "src/app/services/office/offices.service";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { BotSchedule } from "src/app/BOT_CODE/bot_classes/bot-schedule";
import { ScheduleMessagesComponent } from "src/app/BOT_CODE/bot_modals/schedule-messages/schedule-messages/schedule-messages.component";
import { DatePipe } from "@angular/common";
import { ViewScheduleDetailsComponent } from "src/app/BOT_CODE/bot_modals/schedule-messages/view-schedule-details/view-schedule-details.component";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { UploadBotMediaComponent } from "src/app/BOT_CODE/bot_modals/bot-media/upload-bot-media/upload-bot-media.component";
import { ViewBotMediaComponent } from "src/app/BOT_CODE/bot_modals/bot-media/view-bot-media/view-bot-media.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-bot-schedule-home",
  templateUrl: "./bot-schedule-home.component.html",
  styleUrls: ["./bot-schedule-home.component.scss"],
})
export class BotScheduleHomeComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  schedulesExists = false;
  mediaExists = false;
  inactiveMediaExists = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  schedules: BotSchedule[] = [];
  media: BotMedia[] = [];
  inactiveMedia: BotMedia[] = [];

  constructor(
    private ngbModalService: NgbModal,
    private toaster: ToastrService,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private botOfficeService: BotOfficeServiceService,
    private datePipe: DatePipe,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  currentTimezone: any;

  searchText1: any;
  searchText2: any;
  searchText3: any;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "admin/getOfficeByIdForBotOfficeAdmin"
      )
      .then((res) => {
        this.officeObject = res;
        this.currentTimezone = this.officeObject.officeTimezone;
      });

    this.getBotSchedulesByOfficeId();
    this.getBotMediaByOfficeId();
    this.getInactiveBotMediaByOfficeId();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  getBotSchedulesByOfficeId() {
    this.botOfficeService
      .getBotSchedulesByOfficeId(
        environment.apiUrl +
          "botOffice/getBotSchedulesByOfficeIdAndType/" +
          "template"
      )
      .then((res) => {
        this.schedules = [];
        let sch: BotSchedule = new BotSchedule();
        sch = res;
        this.schedules = sch.list;

        if (
          this.schedules !== null &&
          this.schedules !== undefined &&
          this.schedules.length > 0
        ) {
          this.schedulesExists = true;
        }

        this.schedules.forEach((schedule) => {
          let x = schedule.executionTimestamp;
          // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
          schedule.executionTimestamp = this.datePipe.transform(
            x,
            "d/M/yy, h:mm a"
          );
        });

        this.showSpinner = false;
      });
  }

  botMediaStatusUpdate(statusUpdateMedia: BotMedia, fn: any) {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/botMediaStatusUpdate/" +
          statusUpdateMedia.botMediaId
      )
      .then((res) => {
        if (fn === "Deactivate") {
          this.inactiveMedia.push(statusUpdateMedia);
          this.inactiveMediaExists = true;
          this.media.forEach((element, index) => {
            if (element.botMediaId == statusUpdateMedia.botMediaId)
              this.media.splice(index, 1);
          });
          this.toaster.success("Media deactivated successfully !");
        } else if (fn === "Activate") {
          this.media.push(statusUpdateMedia);
          this.mediaExists = true;
          this.inactiveMedia.forEach((element, index) => {
            if (element.botMediaId == statusUpdateMedia.botMediaId)
              this.inactiveMedia.splice(index, 1);
          });
          this.toaster.success("Media activated successfully !");
        }
      });
  }

  botScheduleStatusUpdate(sch: BotSchedule) {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/botScheduleStatusUpdate/" +
          sch.botScheduleId
      )
      .then((res) => {
        this.toaster.success("Status updated successfully !");
        this.schedules.forEach((schedule) => {
          if (schedule.botScheduleId === sch.botScheduleId) {
            schedule.botScheduleStatus = !schedule.botScheduleStatus;
          }
        });
      });
  }

  getInactiveBotMediaByOfficeId() {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getInactiveBotMediaByOfficeId"
      )
      .then((res) => {
        this.inactiveMedia = [];
        let md: BotMedia = new BotMedia();
        md = res;
        this.inactiveMedia = md.list;

        if (
          this.inactiveMedia !== null &&
          this.inactiveMedia !== undefined &&
          this.inactiveMedia.length > 0
        ) {
          this.inactiveMedia.forEach((med) => {
            let x = med.timestamp;
            // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
            med.timestamp = this.datePipe.transform(x, "d/M/yy, h:mm a");
          });

          this.inactiveMediaExists = true;
        }
        this.showSpinner = false;
      });
  }

  getBotMediaByOfficeId() {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getBotMediaByOfficeId"
      )
      .then((res) => {
        this.media = [];
        let md: BotMedia = new BotMedia();
        md = res;
        this.media = md.list;

        if (
          this.media !== null &&
          this.media !== undefined &&
          this.media.length > 0
        ) {
          this.media.forEach((med) => {
            let x = med.timestamp;
            // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
            med.timestamp = this.datePipe.transform(x, "d/M/yy, h:mm a");
          });

          this.mediaExists = true;
        }
        this.showSpinner = false;
      });
  }

  addBotSchedule() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ScheduleMessagesComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Create Schedule",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: BotUser) => {
      this.getBotSchedulesByOfficeId();
    });
  }

  viewBotScheduleVarDetails(botSch: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ScheduleMessagesComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "View Schedule Details",
      botSchedule: botSch,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUser) => {}
    );
  }

  uploadBotMedia() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Upload New Media",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotMediaByOfficeId();
    });
  }

  uploadMediaToResumableUploadApi() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "uploadMediaToResumableUploadApi",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotMediaByOfficeId();
    });
  }

  editBotMedia(botMediaToEdit: BotMedia) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMedia: botMediaToEdit,
      context: "Edit Media",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotMedia) => {
        if (botMediaToEdit.activeStatus === true) {
          this.media.forEach((med) => {
            if (med.botMediaId === botMediaToEdit.botMediaId) {
              med.title = receivedEntry.title;
              med.description = receivedEntry.description;
            }
          });
        } else if (botMediaToEdit.activeStatus === false) {
          this.inactiveMedia.forEach((med) => {
            if (med.botMediaId === botMediaToEdit.botMediaId) {
              med.title = receivedEntry.title;
              med.description = receivedEntry.description;
            }
          });
        }
      }
    );
  }

  viewBotMedia(botMediaToView: BotMedia) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotMediaComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMedia: botMediaToView,
      officeId: this.userOfficeId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotMedia) => {
        if (botMediaToView.activeStatus === true) {
          this.media.forEach((med) => {
            if (med.botMediaId === botMediaToView.botMediaId) {
              med.mediaType = receivedEntry.mediaType;
              med.wcaMediaId = receivedEntry.wcaMediaId;
            }
          });
        } else if (botMediaToView.activeStatus === false) {
          this.inactiveMedia.forEach((med) => {
            if (med.botMediaId === botMediaToView.botMediaId) {
              med.mediaType = receivedEntry.mediaType;
              med.wcaMediaId = receivedEntry.wcaMediaId;
            }
          });
        }
      }
    );
  }

  viewScheduleDetails(botSchedule: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewScheduleDetailsComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botSchedule: botSchedule,
      currentTimezone: this.currentTimezone,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotSchedulesByOfficeId();
    });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  send() {}

  selectedFile1!: File;
  onFileSelected1(event: any) {
    this.selectedFile1 = event.target.files[0];
  }

  data = {
    object: "invoice",
    token: "eEZBZBKpHchcQiFZCGv",
    customer: {
      name: "Maan",
      phone: "919731294626",
    },
    invoice: {
      type: "pdf",
      number: "123123",
      amount: "23566",
      date: "19-06-2022",
    },
  };

  dataUrl = {
    object: "invoice",
    token: "eEZBZBKpHchcQiFZCGv",
    customer: {
      name: "Maan",
      phone: "919731294626",
    },
    invoice: {
      type: "url",
      number: "123123",
      amount: "23566",
      date: "19-06-2022",
      url: "https://www.zoti.in/inv/getInvoice/ids?invoice_num=12e7854a5",
    },
  };

  testUrlLocal = "https://zoti-api-preview.omnileadz.com/zotibot/ext/invoice/testApi";
  testUrlDeployed = "https://zoti-api-preview.omnileadz.com/zotibot/ext/invoice/testApi";

  test() {
    let body = new FormData();
    body.append("file", this.selectedFile1);

    body.append("body", JSON.stringify(this.dataUrl));

    this.officeService.createOffice(this.testUrlLocal, body).then((res) => {});
  }

  test2() {
    let body = new FormData();
    body.append("file", this.selectedFile1);

    body.append("body", JSON.stringify(this.data));

    this.officeService.createOffice(this.testUrlLocal, body).then((res) => {});
  }
}
