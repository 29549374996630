
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a  style="cursor: pointer;color: #0275d8;" (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Skills</li>
    </ol>
  </nav>

  <div class="container">
  <mat-form-field>
    <mat-label>Skills</mat-label>
    <mat-select [formControl]="toppingsControl"  (selectionChange)="valueChange($event.value)" multiple>
  
      <mat-select-trigger>
        <mat-chip-list  style="display: flex;">
          <mat-chip *ngFor="let topping of selectedSkillNames"
            [removable]="true" (removed)="onToppingRemoved(topping)">
            {{ topping }}
            <!-- <mat-icon matChipRemove>cancel</mat-icon> -->
          </mat-chip>
          
        </mat-chip-list>
      </mat-select-trigger>
  
      <mat-option *ngFor="let topping of resultSkill" [value]="topping.skillId">{{topping.skillName}}</mat-option>
  
    </mat-select>
  </mat-form-field>
</div>

  <!-- <div class="container">
  
    <div class="table-responsive">          
    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th *ngFor="let res of resultSkill[index].studentsList">
            <a style="color: black;">
              <img style="margin: 2%; border-radius: 50%;" class="student-image" alt="" height="23" loading="lazy" [src]="res.safeUrl" width="23">
              {{res.studentName}}
            </a>
          </th> 
          
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let res of resultSkill">
          
          <td >{{res.skillName}}</td>

          <td *ngFor="let res1 of res.studentsList">{{res1.skillScore}}</td>
         
        </tr>
      </tbody>
    </table>
    
   








    
    
  </div>
  </div> -->
  <div class="container">
  
    <div class="table-responsive">          
    <table class="table" *ngIf="resultsExist === true">
      <thead>
        <tr>
          <th>#</th>
          <th *ngFor="let res of resultSkill[index].studentsList">
            <a style="color: black;">
              <img style="margin: 2%; border-radius: 50%;" class="student-image" alt="" height="23" loading="lazy" [src]="res.safeUrl" width="23">
              {{res.studentName}}
            </a>
          </th> 
          
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let res of resultSkillVisible">
          
          <td >{{res.skillName}}</td>

          <td *ngFor="let res1 of res.studentsList">{{res1.skillScore}}</td>
         
        </tr>
      </tbody>
    </table>
    
   








    
    
  </div>
  </div>

  </div>



  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>