<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Feedback Answer</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form #aqForm="ngForm"> 
        



    <label class="required" for="activityAnswer">Answer</label>
    <input maxlength="250" #activityPlannerName="ngModel" required placeholder="Enter Answer" required class="form-control" type="text" name="activityAnswer"  id="activityAnswer" [(ngModel)]="feedbackAnswer.answer">
    <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Enter answer</div>

    <br>
    <div>
        <table  border="1">
            <thead>
                <tr>
                    <th [style.background-color]='getColor()' >#</th>
                    <th [style.background-color]='getColor()' >Option / Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor = "let qsn of answerValuesList, let i = index">
                    <td>{{i}}</td>
                    <td style="text-align: left;">{{qsn.answerValue}}</td>
                   
                </tr>
               
            </tbody>
    </table>
    </div> 
<br>
    <!-- <a matTooltip="Add answer value" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
    (click)="open(addAnswerValue)">
    <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
  </a>  -->

  <button (click)="open(addAnswerValue);" style="float: right;width: auto;" class="btn btn-info">Add Answer Value</button>

</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
</div>


<ng-template #addAnswerValue let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Add option</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

        <div class="col-md-12">
            <label class="required" for="ansValue">Answer / Option</label>
            <input maxlength="250" required placeholder="Enter option / answer"
             class="form-control" type="text" name="ansValue"
               id="value" [(ngModel)]="ansValue">
            </div>


    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addFeedbackAnswerValues();modal.dismiss('Cross click')">Add</button>
    </div>
  </ng-template>