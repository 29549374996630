

<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Responses</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 

    <table class="table table-striped table-bordered table-sm row-border hover">
        <thead class="tableheader">
          <tr>
            <th>Question</th>
            <th>Answer</th>
          </tr>
        </thead> 
        <tbody>
          <tr class="td-height" *ngFor="let fa of formRespondent.formResponsesDto">
            <td class="td-height">{{fa.question}}</td>
            <td *ngIf="fa.answerType !== 'timestamp'" class="td-height">{{fa.answer}}</td>
            <td *ngIf="fa.answerType === 'timestamp'" class="td-height">{{fa.answerValueTimestampFormatted}}</td>
          </tr>
        </tbody>
      </table> 
             
      </form>
      </div>
      </div>
      </div>
  
