import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityScore } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-score';
import { AddActActualActScore } from 'src/app/courseManagementClasses/combinedParentTeacher/add-act-actual-act-score';
import { GetCombinedPTCDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-combined-ptc-details';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';

@Component({
  selector: 'app-ext-activity-feedback-qsns-modal',
  templateUrl: './ext-activity-feedback-qsns-modal.component.html',
  styleUrls: ['./ext-activity-feedback-qsns-modal.component.scss']
})
export class ExtActivityFeedbackQsnsModalComponent implements OnInit {
  clicked=false
  actQuestionsExists : boolean = false;
  actQuestionsDoesntExists = false
  safeImageUrl : any;
  studentName : any;
  actQuestions : ActivityQuestions[] = [];

  activityScore : ActivityScore = new ActivityScore();
  activityScoreList : ActivityScore[] = [];
 
  activityPlannerId! : string;

  inputType : any;
  questionOrder : any;

  combinedPTCDetails: GetCombinedPTCDetails = new GetCombinedPTCDetails;

  fkStudentId!: string;
  fkTeacherId!: string;

  addActActualActScore : AddActActualActScore = new AddActActualActScore();

  isEditable = false;
  stepper: any;

  @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 
 
  constructor(private combinedParentTeacherService : CombinedParentTeacherService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService              ) { }

  ngOnInit(): void {

 
    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.fkStudentId = this.fromParent.fkStudentId;
    this.fkTeacherId = this.fromParent.fkTeacherId;

    this.addActActualActScore.botPMExtActLinkId = this.fromParent.botPMExtActLinkId;

    this.safeImageUrl = this.fromParent.safeImageUrl;
    this.studentName = this.fromParent.studentName;
    
// authorized
    this.combinedParentTeacherService.getActivityQuestionListByActPlnrIdExt(this.activityPlannerId).subscribe(
      data=>{ 
        this.actQuestions=data;

        if (this.actQuestions === undefined || this.actQuestions.length == 0) {
          this.actQuestionsDoesntExists = true;
      }else{
        this.actQuestionsExists = true;

        if(this.fromParent.childPetname !== null){
          this.actQuestions.forEach((que)=>{
            que.activityQuestion = que.activityQuestion.split('$$child_pet_name$$').join(this.fromParent.childPetname);
          });
        }

      }

        this.actQuestions.forEach((question) =>{
            if(question.activityQuestionType=='R'){
              this.inputType = 'Radio';
              this.questionOrder = question.activityQuestionOrder;
            }
        }); 

      });

      this.addActActualActScore.fkTeacherId = this.fkTeacherId;
      this.addActActualActScore.fkActivityPlannerId = this.activityPlannerId;
   
  }




  closeUpdateModal(){
    this.activeModal.close();
  }

  addToArray(activityQuestionId : string){

    this.activityScore.fkActivityQuestionId = activityQuestionId;

    this.activityScore.fkStudentId = this.fkStudentId;
    
    if(this.activityScore.activityScoreValue == '1'){
      this.activityScore.activityScoreName = "Yes";
    } else {
      this.activityScore.activityScoreName = "No";
    }

    this.activityScoreList.push(this.activityScore);

    this.activityScore = new ActivityScore();

    this.stepper.selected.completed = true;
    this.stepper.next();
    

  }

  onSubmit(){
   
    this.addActActualActScore.fkStudentId = this.fkStudentId;
    this.addActActualActScore.activityScoreList = this.activityScoreList;

    // FOR PARENT-MENTOR
    this.combinedParentTeacherService.addPtcActActualActScoreExt(this.addActActualActScore).subscribe(
      data=>{
        this.passEntry.emit("complete");
        this.activeModal.close();
        this.toaster.success('Activity Completed Successfully');
      });
    
  }

  Change(evt: any) {
    this.activityScore.activityScoreName = evt;

  }
}
