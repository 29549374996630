<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='create parent category'"
    style="color: white;" class="modal-title pull-left">Subcategoryies of {{botCategory.name}}</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card-body"> 
    

        <table
        class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
        <thead class="tableheader" >
          <tr>
            <th>Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let category of botCategory.list">
            <td class="td-height">{{category.name}}</td>
                    <td class="td-height">{{category.description}}</td>

                    <td>

                      <button *ngIf="category.botCategoryStatus!== null && category.botCategoryStatus" type="button"
               class="btn btn-danger mr-2  btn-sm" (click)="open(confirmCategoryDeactivation);">Deactivate</button>
             <button *ngIf="category.botCategoryStatus !== null && !category.botCategoryStatus" type="button"
               class="btn btn-success mr-2" (click)="open(confirmCategoryActivation);">Activate</button>

                   </td>

                    <td class="td-height" style=" height: 2rem;">
                      <a style="cursor:pointer;padding-left: 1rem;" (click)="editBotCategory(category);">
                        <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                      </a>
                    </td>

                    <ng-template #confirmCategoryActivation let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        Are you sure to activate category "{{category.name}}" ? <br>
                        All subcategories and items coming under the category "{{category.name}}" will also be activated !
                      </div> 
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                        <button type="button" class="btn btn-success" (click)="botCategoryAndItemsStatusUpdate(category.botCategoryId);modal.dismiss('Cross click')">Activate</button>
                      </div>
                    </ng-template>

                    <ng-template #confirmCategoryDeactivation let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        Are you sure to deactivate category "{{category.name}}" ? <br>
                        All subcategories and items coming under the category "{{category.name}}" will also be deactivate !
                      </div> 
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                        <button type="button" class="btn btn-success" (click)="botCategoryAndItemsStatusUpdate(category.botCategoryId);modal.dismiss('Cross click')">Deactivate</button>
                      </div>
                    </ng-template>
                    
          </tr>
        </tbody>
      </table>

      <a style="cursor:pointer" class="float-right" (click)="createSubCategory();">
        <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
      </a>


</div>
</div>

