<div  [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title pull-left">Choose</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>    
  </div> 

 
 
    <mat-divider style="color: black;"></mat-divider>

  

  <mat-divider style="color: black;"></mat-divider>


    <div *ngFor="let du of displayUrls" style="text-align: left;">

      <div style="margin-bottom: 0.5rem; margin-top: 0.5rem;text-align: center;" class="roless" >
       
      <a  style="color: black;font-weight: bold;
       cursor:pointer; background-color: white;border: white;"
       (click)="switchRole(du);"> 
        {{du.displayName}}
      </a> 
    </div>

      <mat-divider style="color: black;"></mat-divider>
  
</div>

  