export class Role {
    roleId = null;
    roleName: string = '';
    dashBoard = null;
    activeStatus = null;
    userRoleOfficeId : any;
    displayName : any;

    orgDisplayStatus : any;
    eduOfficeDisplayStatus : any;
    bizOfficeDisplayStatus : any;
    
}

export class Roles {
    roles: Role[] = [];
}

