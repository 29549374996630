import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { AddCustomerComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/Customer/add-customer/add-customer.component';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { UsernameCheckModalComponent } from 'src/app/dashboards/crmDashboards/username-check-modal/username-check-modal.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-exe-customers',
  templateUrl: './bcrm-exe-customers.component.html',
  styleUrls: ['./bcrm-exe-customers.component.scss']
})
export class BcrmExeCustomersComponent implements OnInit {

  currentRole = "BCRM_EXE";

  customerLoaderMessage!: string;

  customerExists: boolean = false;

  customerLoader: boolean = false;

  showSpinner = false;
  
  roleLoader = false;

  userName ! : string;

  customerOptions : any;

  ccdetails: CompleteCustomerDetails[] = [];

  customers : Customer[] = [];

  customer : CompleteCustomerDetails = new CompleteCustomerDetails();

  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  
  customerId: any;
  userId!: string
  navbarTitle: any;
  totalCount : any = 0;

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private spinner: NgxSpinnerService, 
              private subscriptionService : SubscriptionService,
              private toaster: ToastrService,
              public bsModalRef: BsModalRef,
              private ccdetailsService:CompCustDetailsService, 
              private CustomerService : CustomerService,
              private sharedDataService : SharedDataService) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

  ngOnInit(): void {

    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getCcDetails();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

  // authorized
  getCcDetails(){
    this.showSpinner = true;
    this.CustomerService.getCustomersByUroId(environment.apiUrl + 'bcrmCustomer/getCustomersByUroId').then(
      data=>{
        this.customers = data;
        this.customers.forEach((cust)=>{
          cust.outstandingAmount =  Math.round((cust.outstandingAmount + Number.EPSILON) * 100) / 100;
        });
        this.dataSource = this.customers
        this.totalCount = this.customers.length;
        this.customerExists = true;
        this.showSpinner = false;
      });
  }
  dataSource = this.customers;
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      Customer: x.firstName,
      Email: x.email,
      GST: x.gst,
      Phone: x.phoneNumber,
      Address: x.customerAddress
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  } 

   // authorized
  addNewLead(custObject : Customer){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCustomerComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        type : 'CUSTOMER',
        custObject : custObject
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Customer) => {
      this.showSpinner = true;
      receivedEntry.activeStatus = true;
      this.customers.push(receivedEntry)
      this.showSpinner = false;  
    });  
    
  }

  openCustomerDetailsPage(id : any){
    this.router.navigate(['/dashboard/bcrm-customer-profile', id]);
  }

  openEmailCheckModalComponent(){
    this.config.class = 'modal-md';
    // const modalRef = this.ngbModalService.open(EmailCheckComponent,
    const modalRef = this.ngbModalService.open(UsernameCheckModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        officeId : this.officeId,
        header : "Customer"
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Customer) => {
       if(receivedEntry.createCustomer === true){
        this.addNewLead(receivedEntry);
       }else if(receivedEntry.createCustomer === false){
        this.openCustomerDetailsPage(receivedEntry.customerId);
      }
      });  
  }

  updateCustomerStatus(customerId : string){
    this.showSpinner = true;
    this.ccdetailsService.changeStatus(environment.apiUrl + "crmCustAdd/statusUpdate/" + customerId).then( data =>{
      this.customers.forEach((cust)=>{
        if(cust.customerId===customerId){
          cust.activeStatus = !cust.activeStatus;
          this.showSpinner = false;
        }
      });
      this.toaster.success("Customer status updated Successfully");
    });
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

      open(content: any) {
        this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
        
      }

    getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }

    reloadPage() {
      window.location.reload();
    }

        //authorized
        createCustomerLogin(id : any, username : any){

          this.clicked = true;
          this.CustomerService.createUserForCustomerLogin(environment.apiUrl + "bcrmCustomer/createUserForCustomerLogin/" + id + "/" + username).then(
            data=>{
              let cust = new Customer();
              cust = data;
              this.customers.forEach((customer)=>{
                if(customer.customerId===id){
                  customer.fkUserId = cust.fkUserId;
                }
              });
              this.toaster.success("Customer login created successfully");
              this.emailUnavailable = false;
              this.phoneUnavailable = false;
              this.usernameExists = false;
              this.value = null;
              this.ngbModalService.dismissAll();
            });
            
        }

        choice : any;
        value : any;
      emailUnavailable = false;
      phoneUnavailable = false;
      usernameExists = false;

      clicked = false;

      crmCustomerId : any;
      crmCustomerEmail : any;
      crmCustomerPhone : any;

        checkUsernameAvailability(){
      
          if(this.emailUnavailable === true && this.phoneUnavailable === true){
            this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
          }else{
      
          if(this.choice === "Email"){
            this.value = this.crmCustomerEmail;
          }else if(this.choice === "Phone"){
            this.value = this.crmCustomerPhone;
          }
      
          this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
            res=>{
      
              this.usernameExists = res;
      
              if(this.usernameExists === true){
      
                if(this.choice === "Email"){
                 this.emailUnavailable = true;
                }else if(this.choice === "Phone"){
                  this.phoneUnavailable = true;
                }
      
                if(this.emailUnavailable === true && this.phoneUnavailable === true){
                  this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
                  this.value = null;
                }else{
                  this.toaster.error("Username exists !");
                }
      
              }else if(this.usernameExists === false){
  
                this.toaster.success("Username available !");
                
                this.createCustomerLogin(this.crmCustomerId, this.value);
      
              }
            });
          }
        }

        emailPhoneMissingWarning(type:any){
          this.toaster.error(type + " is missing !");
        }
      
        checkCustomUsernameAvailability(){
          this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
            res=>{
              this.usernameExists = res;
              if(this.usernameExists === true){
                this.toaster.error("Username exists !");
              }else if(this.usernameExists === false){
                this.toaster.success("Username available !");
                this.createCustomerLogin(this.crmCustomerId, this.value);
              }
            });
        }
}  