import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout-warning-modal',
  templateUrl: './logout-warning-modal.component.html',
  styleUrls: ['./logout-warning-modal.component.scss']
})
export class LogoutWarningModalComponent implements OnInit {

  email: any;
  username: any;

  loggedInUser : any;
  theme : any; 
  
  constructor(     private sharedDataService: SharedDataService,
    private logoutService : LogoutService,
    public router: Router,
    private activeModal : NgbActiveModal
  ) { }

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.email = this.fromParent.email;
    this.username = this.loggedInUser.username;

  }

  logOut(): void {
    localStorage.removeItem('auth-token');
    localStorage.clear();
    window.location.href=`${environment.authUrl}/logout`;
  
    this.passEntry.emit("User Logged Out");
    // this.router.navigate(['/auth/login']).then();
    // this.activeModal.close();
  }

  closeModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
}
