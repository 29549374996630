import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

   updateUserSessionOnRoleChange(email : string, userRoleOfficeId : string): Observable<any[]>{
    return this.httpClient.get<any[]>(environment.apiUrl + "user/updateUserSessionOnRoleChange/" + email + "/" + userRoleOfficeId);
  }


}
