export class Teacher {

    teacherId!: string;
    fkClass!: string;
    fkUserRoleOffice!: string;
    fkSubject!: string;
    teacherStatus!: boolean;

    userId : any;
    officeId : any;
}
