 <div class="modal-header">
    <h4 class="modal-title pull-left">Access Control</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   

   <div class="modal-body">


    <table>
        <tr style="color: black; font-weight: bold;">
          <th>Scope</th>
          <th>Access</th>
        </tr>
    


          <tr>
            <td>Multi-Language Access</td>
            <td>
              <button *ngIf="office.multiLanguageSettings!== null && office.multiLanguageSettings" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('multi_language_settings')">Deactivate</button>
            <button *ngIf="office.multiLanguageSettings!== null && !office.multiLanguageSettings" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('multi_language_settings')">Activate</button>
            </td>
          </tr>
          
          <tr>
            <td>Multi ConvoFLows Config Access</td>
            <td>
              <button *ngIf="office.multiConvoFLowsConfigAccess!== null && office.multiConvoFLowsConfigAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('multi_convo_flows_config_access')">Deactivate</button>
            <button *ngIf="office.multiConvoFLowsConfigAccess!== null && !office.multiConvoFLowsConfigAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('multi_convo_flows_config_access')">Activate</button>
            </td>
          </tr>



          <tr>
            <td>Forms Access</td>
            <td>
              <button *ngIf="office.formsAccess!== null && office.formsAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('forms_access')">Deactivate</button>
            <button *ngIf="office.formsAccess!== null && !office.formsAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('forms_access')">Activate</button>
            </td>
          </tr>

          <tr>
            <td>Schedule Msgs Access</td>
            <td>
              <button *ngIf="office.scheduleMsgsAccess!== null && office.scheduleMsgsAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('schedule_msgs_access')">Deactivate</button>
            <button *ngIf="office.scheduleMsgsAccess!== null && !office.scheduleMsgsAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('schedule_msgs_access')">Activate</button>
            </td>
          </tr>

          <tr>
            <td> Templates(Meta) Access</td>
            <td>
              <button *ngIf="office.templatesAccess!== null && office.templatesAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('templates_access')">Deactivate</button>
            <button *ngIf="office.templatesAccess!== null && !office.templatesAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('templates_access')">Activate</button>
            </td>
          </tr>

          <tr>
            <td>Shop (Cart) Access</td>
            <td>
              <button *ngIf="office.shopAccess!== null && office.shopAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('shop_access')">Deactivate</button>
            <button *ngIf="office.shopAccess!== null && !office.shopAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('shop_access')">Activate</button>
            </td>
          </tr>

          <tr>
            <td>Agents Access</td>
            <td>
              <button *ngIf="office.agentsAccess!== null && office.agentsAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('agents_access')">Deactivate</button>
            <button *ngIf="office.agentsAccess!== null && !office.agentsAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('agents_access')">Activate</button>
            </td>
          </tr>

          <tr>
            <td>Admins Access</td>
            <td>
              <button *ngIf="office.adminsAccess!== null && office.adminsAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('admins_access')">Deactivate</button>
            <button *ngIf="office.adminsAccess!== null && !office.adminsAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('admins_access')">Activate</button>
            </td>
          </tr>

          <tr>
            <td>Bot-Callback Access</td>
            <td>
              <button *ngIf="office.botCallbackAccess!== null && office.botCallbackAccess" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('bot_callback_access')">Deactivate</button>
            <button *ngIf="office.botCallbackAccess!== null && !office.botCallbackAccess" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('bot_callback_access')">Activate</button>
            </td>
          </tr>
  
          <tr>
            <td>Autoscheduler</td>
            <td>
              <button *ngIf="office.autoschedulerEnabled!== null && office.autoschedulerEnabled" type="button"
              class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('autoscheduler_enabled')">Deactivate</button>
            <button *ngIf="office.autoschedulerEnabled!== null && !office.autoschedulerEnabled" type="button"
              class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('autoscheduler_enabled')">Activate</button>
            </td>
          </tr>
          
            <tr>
              <td>Global Template Res Creation Access</td>
              <td>
                <button *ngIf="office.globalTemplateResCreation!== null && office.globalTemplateResCreation" type="button"
                class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('global_templ_res_creation')">Deactivate</button>
              <button *ngIf="office.globalTemplateResCreation!== null && !office.globalTemplateResCreation" type="button"
                class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeAccessControlStatus('global_templ_res_creation')">Activate</button>
              </td>
            </tr>
        </table>
      


</div>
   
<div class="modal-footer">
 <button type="submit" class="btn btn-sm btn-primary float-right" (click)="close();">Close</button>
</div>