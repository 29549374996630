import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import {style, animate, transition, trigger} from '@angular/animations';
import { StudentService } from 'src/app/service/student.service';
import { ShowQuestionsModalComponent } from 'src/app/tryOutFolder/questionsModal/show-questions-modal/show-questions-modal.component';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { ActivitySwapModalComponent } from '../activity-swap-modal/activity-swap-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SubjectService } from 'src/app/service/subject.service';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { ActivityTimelinePageComponent } from '../activity-timeline-page/activity-timeline-page.component';
import { GlobalClass } from 'src/app/global/global-class';
import { OfficesService } from 'src/app/services/office/offices.service';
import { environment } from 'src/environments/environment';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';

@Component({
  selector: 'app-teacher-subject-dashboard',
  templateUrl: './teacher-subject-dashboard.component.html',
  styleUrls: ['./teacher-subject-dashboard.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(250, style({opacity:1})) 
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({opacity:0})) 
      ])
    ])
  ]
})
export class TeacherSubjectDashboardComponent implements OnInit {

  readMore0 = false;
  readMore1 = false;
  readMore2 = false;
  showSpinner : boolean = true;
  activityExists = false;
  currentRole = 'TEACHER';
  collapsedState : boolean = false ;
  collapsedState2 : boolean = false ;
  public screenWidth: any;
  public screenHeight: any;
  loggedUser : any;
  loggedInUser : any;
  userId!: string;
  userName : any;
  theme: string = '';
  subjectId : any;
  teacherId : any;
  classId : any;
  officeId : any;
  unsafeUrl : any;
  safeUrl : any;
  safeStudentUrl : any;
  unsafeStudentUrl : any;
  subjectObject : Subject = new Subject();
  unsafeSubUrl : any;
  safeSubUrl : any;

  centered = false;
  disabled = false;
  unbounded = false;

  radius!: number;
  color!: string;

  isLinear = false;
  
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;

  // div open / close states
  mainDivState : boolean = true;
  assessmentDivState : boolean = false;

  // assessment panel
  step = 0;
  iconP : string = 'account_circle';
  iconC: string = 'task_alt';
  icon : boolean = true;
  navbarTitle: any;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //configuration
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  officeObject : any = {
                        activeStatus: '',
                        backgroundColor: '',
                        id:'',
                        logo: '',
                        oa_ParentMentor:'',
                        oa_SubAdmin: '',
                        oa_SkillReport: '',
                        oa_ParentFeedback: '',
                        oa_Assignment: '',
                        officeLocation: '',
                        officeName: '',
                        themeColour: ''
                      }
                      
  info : any = {
                    activityPlannerDescription: "",
                    activityPlannerId: "",
                    activityPlannerImage: "",
                    activityPlannerName: "",
                    activityPlannerOrder: "",
                    activityPlannerStatus: "",
                    fkSubjectId: "",
                    studentCount: "",
                    activityCompletionStatus : "",
                    activityActiveStatus : "",

                    activityVideoLinkList : [{
                                              actVideoLinkId: "",
                                              actVideoLinkName: "",
                                              actVideoLink: "",
                                              actVideoLinkDescription: "",
                                              fkActivityPlannerId: "",
                                              actVideoLinkStatus: "",
                                              urlSafe: ""
                                            }]
  }
 
  students : any[] = [{
                      studentId: '',
                      fkClass: '',
                      fkUserRoleOffice: '',
                      studentStatus: '',
                      studentName: '',
                      studentImage : '',
                      studentActivityHistoryIndicator : '',
                      studentAttempts : '',
                      safeStudentUrl : ''
  }]



  

  constructor(private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              public router: Router,
              public route : ActivatedRoute,
              private teacherService : TeacherService,
              private studentService : StudentService,
              private activityPlannerService : ActivityPlannerService,
              private toaster : ToastrService,
              private subjectService : SubjectService,
              private officeService : OfficesService ) { }

  ngOnInit(): void {
 
    this.showSpinner = true;
    
    this.loggedUser = localStorage.getItem('loggedUser'); 
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;
    this.officeId = this.loggedInUser.offices[0].id;


    // authorized
    this.officeService.getOfficeByIdForTeacher(environment.apiUrl + "teacher/getOfficeByIdForTeacher/" + this.officeId).then(
      data=>{        
        this.officeObject = data;

        this.getSubjectObject();
        this.getDetailsForTeacherSubjectDashboard();

      });

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.subjectId = this.route.snapshot.params['subjectId'];
    this.teacherId = this.route.snapshot.params['teacherId'];
    this.classId = this.route.snapshot.params['classId'];
   
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onAssess(openState : boolean, activityPlannerId : string){
    this.collapsedState = false;
    this.mainDivState = false;
    this.assessmentDivState = true;
    this.fetchStudentsForAssessment(this.classId);
   } 

  onEvaluate(activityPlannerId : string, studentId : string, safeImageUrl : any, studentName : any){
    this.onEvaluate2(activityPlannerId, studentId, this.teacherId , safeImageUrl, studentName);
    }

  onEvaluate2(activityPlannerId : string, fkStudentId : string, fkTeacherId : string, safeImageUrl : any, studentName : any ){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( ShowQuestionsModalComponent,
    {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false,
      backdrop : 'static',
    });
  let data = {
    activityPlannerId: activityPlannerId ,
    fkStudentId : fkStudentId,
    fkTeacherId : fkTeacherId,
    safeImageUrl : safeImageUrl,
    studentName : studentName
      }
  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      }); 
  modalRef.componentInstance.passEntry.subscribe((receivedEntry : "") => {
    this.info.activityActiveStatus = true;
    this.fetchStudentsForAssessment(this.classId);
  });
  }

  getDetailsForTeacherSubjectDashboard(){
    this.teacherService.getDetailsForTeacherSubjectDashboard(environment.apiUrl + "teacher/getDetailsForTeacherSubjectDashboard/" + this.teacherId + "/" + this.subjectId).then(
      data=>{
              this.info = data;
              if(data === undefined || data === null){
                this.activityExists = false;
                this.showSpinner = false;
                this.toaster.show("No Activity to display !")
              } else {
                this.activityExists = true;
              }
              if(this.info.activityPlannerId === undefined || this.info.activityPlannerId === null){
                this.activityExists = false;
                this.showSpinner = false;
                this.toaster.show("No Activity to display !")
              }
              if(this.info.activityPlannerId !== undefined && this.info.activityPlannerId !== null){
                this.imageService.getActivityPlannerImage(this.info.activityPlannerImage).subscribe(
                  res=>{
                    this.unsafeUrl = URL.createObjectURL(res);
                    this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
                  });
              }
                if(this.info.activityVideoLinkList===undefined ||  this.info.activityVideoLinkList.length===0){
                  this.showSpinner = false;
                }
                let count : number = 0;
                this.info.activityVideoLinkList.forEach((val : any)=>{
                  count++;
                this.url =  val.actVideoLink;
                this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
                val.urlSafe = this.urlSafe
                if(count=== this.info.activityVideoLinkList.length){
                  setTimeout(()=>{                           //timer
                    this.showSpinner = false;
               }, 1000);
                }
              });
              this.activityExists = true;
             });
  }

  getSubjectObject(){
    this.subjectService.getSubByIdForTeacher(environment.apiUrl + "subject/getSubjectByIdForTeacher/" + this.subjectId).then(
      data=>{
        this.subjectObject = data;
        this.imageService.getSubjectImage(this.subjectObject.subjectImage).subscribe(
          data=>{
            this.unsafeSubUrl = URL.createObjectURL(data);
            this.safeSubUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeSubUrl);
          });
       });
     }

  goToStudentsResults(){
    this.router.navigate(['class-results',this.classId]);
  }

  goToClassActivities(){
    this.router.navigate(['class-activities',this.subjectId, this.teacherId, this.classId]); 
  }

  goToClassSkillReport(){
    this.router.navigate(['/dashboard/class-skill-report', this.subjectId,this.classId]);
  }

  collapseActivityCardContent(state : boolean){
    if(state === true){
      this.collapsedState = false;
    } else if(state === false){
      this.collapsedState = true;
    }
   }

   collapseActivityCardContent2(state : boolean){
    if(state === true){
      this.collapsedState2 = false;
    } else if(state === false){
      this.collapsedState2 = true;
    }
   }

  goToStudentsPage(){
    this.router.navigate(['class-students',this.classId,this.subjectId]);
  }

  goToCurriculumPage(){
    this.router.navigate(['curriculum',this.subjectId]);
  }

// authorized
   fetchStudentsForAssessment(classId : string){

    this.showSpinner = true;

    this.studentService.getStudentsForAssessment(environment.apiUrl +  "student/getStudentsForAssessment/" + classId + "/" + this.info.activityPlannerId).then(
      data=>{
        this.students = [];
        this.students.pop();

                for(var index in data){
                  this.students.push( { 'studentId' : data[index].studentId, 
                                        'fkClass' : data[index].fkClass,
                                        'fkUserRoleOffice' : data[index].fkUserRoleOffice,
                                        'studentStatus' : data[index].studentStatus,
                                        'studentName' : data[index].studentName,
                                        'studentImage' : data[index].studentImage,
                                        'studentActivityHistoryIndicator' : data[index].studentActivityHistoryIndicator,
                                        'studentAttempts' : data[index].studentAttempts,
                                        'safeStudentUrl' : this.safeStudentUrl                                
                                              });
        }

        if(this.students===undefined || this.students.length===0){
          this.showSpinner = false;
        }

        let count : number = 0;
        this.students.forEach( (value) => {
          count++;
            this.imageService.getUserImage(value.studentImage).subscribe(
              data=>{
                this.unsafeStudentUrl = URL.createObjectURL(data);
                this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
          
                value.safeStudentUrl = this.safeStudentUrl;

                if(count===this.students.length){
                  setTimeout(()=>{                           //timer
                    this.showSpinner = false;
               }, 1500);
                }
               });
             });  
      });
//      this.students = [];
//     this.studentService.getStudentsInaClass(classId).subscribe(
//       data=>{
//         this.students.pop();

//         for(var index in data){
//           this.students.push( { 'studentId' : data[index].studentId, 
//                                 'fkClass' : data[index].fkClass,
//                                 'fkUserRoleOffice' : data[index].fkUserRoleOffice,
//                                 'studentStatus' : data[index].studentStatus,
//                                 'studentName' : data[index].studentName,
//                                 'studentImage' : data[index].studentImage,
//                                 'safeStudentUrl' : this.safeStudentUrl                                
//                                       });
// }

// this.students.forEach( (value) => {
          
//   this.imageService.getUserImage(value.studentImage).subscribe(
//     data=>{
//       this.unsafeStudentUrl = URL.createObjectURL(data);
//       this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);

//       value.safeStudentUrl = this.safeStudentUrl;
//      });
//    });   
// });
// this.showSpinner = false;
  }

  // authorized
  MarkActivityCompletedForStudent(studentId : string){

    this.studentService.setStudentActivityCompletionStatus(environment.apiUrl +  "studentActivity/setStudentActivityCompletionStatus/" + studentId + "/" + this.info.activityPlannerId).then(
      data=>{
        this.students.forEach((value)=>{
          if(value.studentId === studentId){
            value.studentActivityHistoryIndicator = '2';
          }
        });
        this.toaster.success('Activity marked as complete');
      });

  }

  // authorized
  UnMarkActivityCompletedForStudent(studentId : string){

    this.studentService.UnMarkActivityCompletedForStudent(environment.apiUrl +  "studentActivity/UnMarkActivityCompletedForStudent/" + studentId + "/" + this.info.activityPlannerId).then(
      data=>{
        this.students.forEach((value)=>{
          if(value.studentId === studentId){
            value.studentActivityHistoryIndicator = '1';
          }
        });
        this.toaster.success('Activity un-marked as complete');
      });

  }

   // authorized
  markActivityAsComplete(activityPlannerId : string){
    this.activityPlannerService.markActivityActivityAsComplete(environment.apiUrl + "activityCompletion/markActivityActivityAsComplete/" + activityPlannerId + "/" + this.teacherId).then(
      data=>{
        this.info.activityCompletionStatus = true;
        this.info.activityActiveStatus = false;
        this.toaster.success('Activity marked as complete');
      });
  }

   // authorized
  unMarkActivityAsComplete(activityPlannerId : string){
    this.activityPlannerService.unMarkActivityAsComplete(environment.apiUrl + "activityCompletion/unMarkActivityAsComplete/" + activityPlannerId + "/" + this.teacherId).then(
      data=>{
        this.info.activityCompletionStatus = false;
        this.info.activityActiveStatus = true;
        this.toaster.success('Activity un-marked as complete');
      });
  }


   mainF(main: boolean){
     if(main === true){
       this.mainDivState = false;
       this.collapsedState2 = false;
     } else if(main === false){
      this.mainDivState = true;
      this.collapsedState2 = false;
    } 
    this.step = 0;
   }

   // authorized
   openModalForActivityCardChange(){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ActivitySwapModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      subId : this.subjectId,
      teacherId : this.teacherId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {

      this.showSpinner = true;

      // authorized
      this.activityPlannerService.getActivityPlannerForSwapForTeacher(environment.apiUrl + "activityPlanner/getActivityPlannerForSwapForTeacher/" + receivedEntry + "/" + this.teacherId).then(
        data=>{
            this.info = data;

            let count : number = 0;
            this.info.activityVideoLinkList.forEach((val : any)=>{
              count++;
            this.url =  val.actVideoLink;
            this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            val.urlSafe = this.urlSafe
     

            if(count=== this.info.activityVideoLinkList.length){
              this.showSpinner = false;
            }

          });

            this.imageService.getActivityPlannerImage(this.info.activityPlannerImage).subscribe(
              res=>{
                this.unsafeUrl = URL.createObjectURL(res);
                this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
              });
              this.toaster.success('Activity Changed Successfully');
              this.fetchStudentsForAssessment(this.classId);
              this.collapsedState2 = false;
        });
    });    

   }


  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      userId : this.userId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  goToAssignmentDashboard(){
    this.router.navigate(['dashboard/teacher/assignment',this.teacherId,this.classId,this.subjectId]);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
 
  goToAssessmentResultsPage(){
    this.router.navigate(['assessment-results',this.classId,this.subjectId,this.teacherId])
  }

// authorized
  goToActivityTimeline(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ActivityTimelinePageComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      classId : this.classId,
      subjectId : this.subjectId,
      teacherId : this.teacherId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }
  
logOut(): void {
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
   email : this.loggedInUser.email 
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });  
}
  
  goBack() {
    this.router.navigate(['/dashboard/user']).then();
  }
  reloadPage() {
    window.location.reload();
  }

 
}
