import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Roles } from 'src/app/models/role';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-bulk-users',
  templateUrl: './add-bulk-users.component.html',
  styleUrls: ['./add-bulk-users.component.scss']
})
export class AddBulkUserComponent implements OnInit {
  clicked=false
  numberOfItems = 0;
  progress = 0;
  bulkUserObject: any;
  roles = new Roles();
  csvFile = 'csv';

  addBulkUsers = new FormGroup({
    role: new FormControl(null, Validators.required),
    file: new FormControl(null, [Validators.required])
  });

  public event: EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: FormBuilder, public bsModalRef: BsModalRef,
    private roleService: RolesServiceService) {
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/roles').then(rolesRes => {
      this.roles.roles = rolesRes;
      this.roles.roles = this.roles.roles.filter(role => role.activeStatus && !role?.roleName?.includes('ADMIN'));
    });
  }

  ngOnInit() {
    this.addBulkUsers = this.formBuilder.group({
      role: (this.bulkUserObject) ? this.bulkUserObject.role : '',
      file: (this.bulkUserObject) ? this.bulkUserObject.logo : File
    })

  }

  saveToList(form: FormGroup) {
    if (form.value) {
      this.triggerEvent(form.value);
      this.bsModalRef.hide();
    }
  }

  triggerEvent(item: FormGroup) {
    this.event.emit({ data: item, res: 200 });
  }
}
