export class BotUserLocation {
  botUserLocationId: any;
  botUserLocationStatus: any;
  fkBotUserId: any;
  fkBotUser: any;
  latitude: any;
  longitude: any;
  name: any;
  url: any;
  var: any;
  address: any;
  list: BotUserLocation[] = [];
  locSharedBy: any;
  locationType: any;
  addressByBotUser: any;
  checked = false;
}
