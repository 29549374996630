


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Feedback Form</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
   
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 
             
        <div class="form-group row">
          <div class="col-md-6">
            <label class="required"  for="targetName"> Name</label>
            <input #no="ngModel" required class="form-control" type="text" id="targetName" 
            name="targetName" [(ngModel)]="feedbackForm.name">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Name is required</div>
          </div>

          <div class="col-md-6">
            <label class="required"  for="targetValue"> Type</label>
            <input #type="ngModel" required class="form-control" type="text" id="targetValue" name="targetValue" 
            [(ngModel)]="feedbackForm.type">
            <div class="alert alert-danger" *ngIf="type.touched && !type.valid">Type is required</div>
          </div>
        </div>
        <br>
        <div>
          <label>Image</label>
          <input placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
       </div>
<br>

<!-- <a matTooltip="Add question" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
(click)="addFeedbackQuestions()">
<img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
</a>  -->

<button (click)="addFeedbackQuestions()" style="float: right;width: auto;" class="btn btn-info">Add question</button>

<br> <br>
        <div >
          <table  border="1">
              <thead>
                  <tr>
                      <th [style.background-color]='getColor()' >#</th>
                      <th [style.background-color]='getColor()' >Question</th>
                      <th [style.background-color]='getColor()' >Mandatory Status</th>
                      <th [style.background-color]='getColor()' >Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor = "let qsn of feedbackQuestions, let i = index">
                      <td>{{i}}</td>
                      <td style="text-align: left;">{{qsn.question}}</td>
                      <td>
                        <a *ngIf="qsn.mandatory===false" style="color: red;">No</a>
                        <a *ngIf="qsn.mandatory===true" style="color: green;">Yes</a>
                      </td>
                      <td>
                        <button *ngIf="qsn.mandatory===false" (click)="qsn.mandatory=!qsn.mandatory"
                         style="width: auto;" class="btn btn-success">Make mandatory</button>
                        <button *ngIf="qsn.mandatory===true" (click)="qsn.mandatory=!qsn.mandatory"
                         style="width: auto;" class="btn btn-danger">Make non-mandatory</button>
                      </td>
                  </tr>
                 
              </tbody>
      </table>
  </div> <br>

  
  
  
   

 

             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();">Save</button>
      </div>