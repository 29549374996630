import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/add-customer-communications/add-customer-communications.component';
import { ViewCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/view-customer-communications/view-customer-communications.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddCompCustDetailsComponent } from 'src/app/crm/compCustomer/add-comp-cust-details/add-comp-cust-details.component';
import { ConvertToCustomerUserComponent } from 'src/app/crm/compCustomer/convert-to-customer-user/convert-to-customer-user.component';
import { UpdateCompCustDetailsComponent } from 'src/app/crm/compCustomer/update-comp-cust-details/update-comp-cust-details.component';
import { ViewCompCustomerDetailsComponent } from 'src/app/crm/compCustomer/view-comp-customer-details/view-comp-customer-details.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';
import { CustomerProfileModalComponent } from '../crmDashboards/customer-profile-modal/customer-profile-modal.component';
import { EmailCheckModalComponent } from '../crmDashboards/email-check-modal/email-check-modal.component';
import { UsernameCheckModalComponent } from '../crmDashboards/username-check-modal/username-check-modal.component';

@Component({
  selector: 'app-crm-lead-info-dashboard',
  templateUrl: './crm-lead-info-dashboard.component.html',
  styleUrls: ['./crm-lead-info-dashboard.component.scss']
}) 
export class CrmLeadInfoDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";
  customerExists: boolean = false;
  userName ! : string;
  customerOptions : any;
  customer : CompleteCustomerDetails = new CompleteCustomerDetails();
  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  userId!: string
  navbarTitle: any;
  discardReason : any = null;
  showSpinner = false;
  customerId : any;

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private officeService : OfficesService,
              private datePipe : DatePipe,
              private toaster: ToastrService,
              public bsModalRef: BsModalRef,
              private ccdetailsService:CompCustDetailsService, 
              private sharedDataService : SharedDataService) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

status : any = "Leads to be contacted";

leadsToBeContacted: CompleteCustomerDetails[] = []; leadsToBeContactedFetched = false;
ccdetails: CompleteCustomerDetails[] = [];           ccdetailsFetched = false;
hotLeadList: CompleteCustomerDetails[] = [];           hotLeadListFetched = false;
coldLeadList: CompleteCustomerDetails[] = [];           coldLeadListFetched = false;
discardedLeadList: CompleteCustomerDetails[] = [];           discardedLeadListFetched = false;

mainObj : CompleteCustomerDetails = new CompleteCustomerDetails();
countObj : CompleteCustomerDetails = new CompleteCustomerDetails();

leadsToContact : any = 0;
coldLeads : any = 0;
discarded : any = 0;
hotLeads : any = 0;
allLeads : any = 0;
activeLeads:any = 0;

officeObject : any = {
  activeStatus: '',
  backgroundColor: '',
  id:'',
  logo: '',
  oa_ParentMentor:'',
  oa_SubAdmin: '',
  oa_CRMAdmin : '',
  oa_CourseAdmin : '',
  oa_ClassAdmin : '',
  oa_SkillReport : '',
  officeLocation: '',
  officeName: '',
  themeColour: '',
  type:'',
  leadConversionType:''
}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

  ngOnInit(): void {

    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.officeService.getOfficeByIdForClassAdmin(environment.apiUrl + "admin/getOfficeByIdForClassAdmin").then(
      res=>{ 
      this.officeObject = res; 
      this.getLeadsToBeContacted();
    });

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

leadsToBeContactedDataSource = this.leadsToBeContacted;
ccdetailsDataSource = this.ccdetails;
hotLeadListDataSource = this.hotLeadList;
coldLeadListDataSource = this.coldLeadList;
discardedLeadListDataSource = this.discardedLeadList;
exportLeadsToBeContacted() {
  const onlyNameAndSymbolArr: Partial<any>[] = this.leadsToBeContactedDataSource.map(x => ({
    Name: x.firstName + " " + x.lastName,
    Email : x.email,
    Phone: x.phoneNumber,
    JoinedDate : x.leadCreationDate
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "leads");
}
exportCcdetails() {
  const onlyNameAndSymbolArr: Partial<any>[] = this.ccdetailsDataSource.map(x => ({
    Name: x.firstName + " " + x.lastName,
    Email : x.email,
    Phone: x.phoneNumber,
    JoinedDate : x.leadCreationDate
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "leads");
}
exportHotLeads() {
  const onlyNameAndSymbolArr: Partial<any>[] = this.hotLeadListDataSource.map(x => ({
    Name: x.firstName + " " + x.lastName,
    Email : x.email,
    Phone: x.phoneNumber,
    JoinedDate : x.leadCreationDate
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "leads");
}
exportColdLeads() {
  const onlyNameAndSymbolArr: Partial<any>[] = this.coldLeadListDataSource.map(x => ({
    Name: x.firstName + " " + x.lastName,
    Email : x.email,
    Phone: x.phoneNumber,
    JoinedDate : x.leadCreationDate
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "leads");
}
exportDiscardedLeads() {
  const onlyNameAndSymbolArr: Partial<any>[] = this.discardedLeadListDataSource.map(x => ({
    Name: x.firstName + " " + x.lastName,
    Email : x.email,
    Phone: x.phoneNumber,
    JoinedDate : x.leadCreationDate
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "leads");
}

  getCcDetails(){
    this.showSpinner = true

    if(this.ccdetailsFetched === false){
      this.ccdetailsService.getLeadList(environment.apiUrl + "crmCustAdd/getActiveLeads/" + this.officeId).then(data => {
        this.ccdetails= data;
        this.ccdetailsDataSource = this.ccdetails;
        this.ccdetails.forEach((lead)=>{
          if(lead.leadCreationDate !== null && lead.leadCreationDate !== undefined){
            lead.leadCreationDate = new Date(lead.leadCreationDate);
            lead.leadCreationDate = this.datePipe.transform(lead.leadCreationDate, "dd-MM-yyyy");
          }
        });
        this.showSpinner = false

        this.status = "All Leads";
        this.ccdetailsFetched = true;

        this.customerExists = true;
      });
    }else if(this.ccdetailsFetched === true){
      this.status = "All Leads";
      this.showSpinner = false

    }
  }


  getHotLeads(){
    this.showSpinner = true

    if(this.hotLeadListFetched === false){
      this.ccdetailsService.getLeadList(environment.apiUrl + "crmCustAdd/getLeadsByType/" + "Hot Lead").then(data => {
        this.hotLeadList= data;
        this.hotLeadListDataSource = this.hotLeadList;
        this.hotLeadList.forEach((lead)=>{
          if(lead.leadCreationDate !== null && lead.leadCreationDate !== undefined){
            lead.leadCreationDate = new Date(lead.leadCreationDate);
            lead.leadCreationDate = this.datePipe.transform(lead.leadCreationDate, "dd-MM-yyyy");
          }
        });
        this.showSpinner = false

        this.status = "Hot Leads";
        this.hotLeadListFetched = true;

        this.customerExists = true;
      });
    }else if(this.hotLeadListFetched === true){
      this.status = "Hot Leads";
      this.showSpinner = false

    }
  }

  getColdLeads(){
    this.showSpinner = true

    if(this.coldLeadListFetched === false){
      this.ccdetailsService.getLeadList(environment.apiUrl + "crmCustAdd/getLeadsByType/" + "Cold Lead").then(data => {
        this.coldLeadList= data;
        this.coldLeadListDataSource = this.coldLeadList;
        this.coldLeadList.forEach((lead)=>{
          if(lead.leadCreationDate !== null && lead.leadCreationDate !== undefined){
            lead.leadCreationDate = new Date(lead.leadCreationDate);
            lead.leadCreationDate = this.datePipe.transform(lead.leadCreationDate, "dd-MM-yyyy");
          }
        });
        this.showSpinner = false

        this.status = "Cold Leads";
        this.coldLeadListFetched = true;

        this.customerExists = true;
      });
    }else if(this.coldLeadListFetched === true){
      this.status = "Cold Leads";
      this.showSpinner = false

    }
  }

  getDiscardedLeads(){
    this.showSpinner = true
    if(this.discardedLeadListFetched === false){
      this.ccdetailsService.getLeadList(environment.apiUrl + "crmCustAdd/getLeadsByType/" + "Discarded Lead").then(data => {
        this.discardedLeadList= data;
        this.discardedLeadListDataSource = this.discardedLeadList;
        this.discardedLeadList.forEach((lead)=>{
          if(lead.leadCreationDate !== null && lead.leadCreationDate !== undefined){
            lead.leadCreationDate = new Date(lead.leadCreationDate);
            lead.leadCreationDate = this.datePipe.transform(lead.leadCreationDate, "dd-MM-yyyy");
          }
        });
        this.showSpinner = false
        this.status = "Discarded Leads";
        this.discardedLeadListFetched = true;

        this.customerExists = true;
      });
    }else if(this.discardedLeadListFetched === true){
      this.status = "Discarded Leads";
      this.showSpinner = false

    }
  }

  getLeadsToBeContacted(){
    this.showSpinner =true;
    if(this.leadsToBeContactedFetched === false){
      this.ccdetailsService.getLeadsToBeContacted(environment.apiUrl + "crmCustAdd/getLeadsToBeContacted").then(data => {
        this.mainObj = data;
        this.leadsToBeContacted= this.mainObj.leads;
        this.leadsToBeContactedDataSource = this.leadsToBeContacted;
        this.leadsToBeContacted.forEach((lead)=>{
          if(lead.leadCreationDate !== null && lead.leadCreationDate !== undefined){
            lead.leadCreationDate = new Date(lead.leadCreationDate);
            lead.leadCreationDate = this.datePipe.transform(lead.leadCreationDate, "dd-MM-yyyy");
          }
        });
        this.showSpinner = false;
        this.status = "Leads to be contacted";
        this.leadsToBeContactedFetched = true;
        
        this.hotLeads = this.mainObj.hotLeads;
        this.coldLeads = this.mainObj.coldLeads
        this.allLeads = this.mainObj.allLeads
        this.discarded = this.mainObj.discardedLeads;
        this.leadsToContact = this.mainObj.leadsToContact;
        this.activeLeads = this.mainObj.activeLeads;

        this.leadsToBeContacted.forEach((lead)=>{
          let sd = lead.nextActionDateTime;
          let date = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);      
          lead.nextActionDateTime = this.datePipe.transform(date, 'dd-MM-yyyy hh:mm');
  
        })      
        this.customerExists = true;
      });
    }else if(this.leadsToBeContactedFetched === true){
      this.status = "Leads to be contacted";
      this.showSpinner = false;
    }

  }

  discardLead(id:any){
    if(this.discardReason === null){
      this.toaster.error("Enter reason !")
    }else{
      this.showSpinner = true;
      this.ccdetailsService.discardLead(environment.apiUrl + "crmCustAdd/discardLead/" + id + "/" + this.discardReason).then(data => {
        this.toaster.success("Lead discarded successfully");
        this.discardReason = null;
        this.getLeadCount();
        this.refreshOnAddOrEdit();
        this.showSpinner=false;
       });
    }
  }

  getLeadCount(){
    this.showSpinner = true;
    this.ccdetailsService.getLeadList(environment.apiUrl + "crmCustAdd/getLeadCount").then(data => {
      this.countObj = data;
      this.hotLeads = this.countObj.hotLeads;
      this.coldLeads = this.countObj.coldLeads
      this.allLeads = this.countObj.allLeads
      this.discarded = this.countObj.discardedLeads;
      this.leadsToContact = this.countObj.leadsToContact;
      this.activeLeads = this.countObj.activeLeads;
      this.showSpinner = false;
    });
  }

  refreshOnAddOrEdit(){
    this.showSpinner = true;
    this.hotLeadListFetched = false;
    this.coldLeadListFetched = false;
    this.ccdetailsFetched = false;
    this.discardedLeadListFetched = false;
    this.leadsToBeContactedFetched = false;
    if(this.status==="Hot Leads"){
      this.getHotLeads();
    }else if(this.status==="Cold Leads"){
      this.getColdLeads();
    }else if(this.status==="All Leads"){
      this.getCcDetails();
    }else if(this.status==="Discarded Leads"){
      this.getDiscardedLeads();
    }else if(this.status==="Leads to be contacted"){
      this.getLeadsToBeContacted();
    }
    this.showSpinner = false;
  }


  addNewCustomerCommunication(customerId:any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCustomerCommunicationsComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
      customerId : customerId
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.refreshOnAddOrEdit();
      this.getLeadCount();
    });
}

viewCustomerCommunication(customerId:any){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(ViewCustomerCommunicationsComponent,
   {
    size : 'lg',
    scrollable: true,
    windowClass: 'app-modal-window',
    keyboard: false
  });
  
  let data = {
    customerId : customerId,
    context : "SHOW_ADD_COMMUNICATION"
  }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    this.refreshOnAddOrEdit();
    this.getLeadCount();
  });
}

   // authorized
  addNewLead(custObject : CompleteCustomerDetails){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCompCustDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        type : 'LEAD',
        custObject : custObject
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
      this.showSpinner = true;
      this.getLeadCount();
      this.refreshOnAddOrEdit();
      this.showSpinner = false;
    });  
    
  }

  openEmailCheckModalComponent(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EmailCheckModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        officeId : this.officeId
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
       if(receivedEntry.createCustomer === true){
        this.addNewLead(receivedEntry);
       }else if(receivedEntry.createCustomer === false){
        this.openCustomerProfileModalComponent(receivedEntry.customerId);
       }
      });  
  }

  openEmailPhoneCheckModalComponent(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UsernameCheckModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
       if(receivedEntry.createCustomer === true){
        this.addNewLead(receivedEntry);
       }else if(receivedEntry.createCustomer === false){
        this.openCustomerProfileModalComponent2(receivedEntry.customerId, receivedEntry.customerType);
       }
      });  
  }

  openCustomerProfileModalComponent2(id : any, type : any){

    let type0 : any;

    if(type==='C'){
      type0="CUSTOMER";
    }else if(type==='L'){
      type0="LEAD";
    }

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(CustomerProfileModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: id ,
          type : type0
        }
       

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {

    });  
  }


  openCustomerProfileModalComponent(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(CustomerProfileModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: id ,
          type : 'LEAD'
        }
       

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {

    });  
  }

   // authorized
  updateCustomerStatus(customerId : string){
    this.showSpinner = true;
    this.ccdetailsService.changeStatus(environment.apiUrl + "crmCustAdd/statusUpdate/" + customerId).then( 
      data =>{
        this.ccdetails.forEach((customer)=>{
          if(customer.customerId===customerId){
            customer.activeStatus=!customer.activeStatus;
          }
        });
      this.toaster.success("Customer status updated Successfully");
      this.showSpinner = false;
      });
  }

   // authorized
  editCustomer(id : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateCompCustDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        
      keyboard: false
      });

      let data = {
            id: id ,
            type : "LEAD"
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
        this.showSpinner = true;
        this.getLeadCount();
        this.refreshOnAddOrEdit();
        this.showSpinner = false;
      });
  }

  viewLeadDetailsModal(addressId : string){
 
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewCompCustomerDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
          addressId: addressId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
        this.bsModalRef.content.closeBtnName = 'Close';
        this.modalRefs.push(this.bsModalRef);
        const self = this;
  }

// authorized
  changeToCustomer(customerId : string){
  
    this.ccdetailsService.changeCustomerType(environment.apiUrl + "crmCustAdd/changeType/" + customerId).then(
      data=>{
        this.toaster.success('Lead Converted Successfully');
       this.ngbModalService.dismissAll();
      });

      this.toaster.success("Successfully Added New Role");

  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  // authorized
    convertToCustomer(customerId : string, email : any, phoneNumber : any){
    
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( ConvertToCustomerUserComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
        customerId : customerId,
        leadConversionType : this.officeObject.leadConversionType,
        case : "CONVERT LEAD TO CUSTOMER USER"
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
        this.showSpinner = true;
          this.getLeadCount();
          this.refreshOnAddOrEdit();
          this.showSpinner = false;
      }); 
    }

      open(content: any) {
        this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
        
      }

    getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    } 

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }

    reloadPage() {
      window.location.reload();
    }
}  