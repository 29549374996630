import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {MODAL_HEADERS} from '../../utils/constants/app-constants';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  clicked=false
  public event: EventEmitter<any> = new EventEmitter();
  productObject: any;
  headerText = MODAL_HEADERS.ADD_PRODUCT; // 'Add Product';

  addProduct = new FormGroup({
    productName: new FormControl(null, Validators.required),
    productId: new FormControl(null)
  });

  constructor(private formBuilder: FormBuilder, public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.addProduct = this.formBuilder.group({
      productName: (this.productObject) ? this.productObject.productName : '',
      productId: (this.productObject) ? this.productObject.productId : null
    });

    if (this.productObject?.productId) {
      this.headerText = MODAL_HEADERS.EDIT_PRODUCT; // 'Edit Product';
    }
  }

  saveToList(form: FormGroup): void {
    if (form.value) {
      this.triggerEvent(form.value);
      this.bsModalRef.hide();
    }
  }

  triggerEvent(item: FormGroup): void {
    this.event.emit({ data: item, res: 200 });
  }

}
