<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Address</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <!-- <div class="card"> -->
      <div class="card-body"> 
      <form (ngSubmit)="onUpdate()"> 

        <div class="form-group row">

            <div class="col-md-4">
            <label for="addressLine1">Address Line 1</label>
            <input  maxlength="250"  class="form-control" type="text" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
            </div>
          
            <div class="col-md-4">
            <label for="addressLine2">Address Line 2</label>
            <input   maxlength="250" class="form-control" type="text" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
            </div>

            <div class="col-md-4">
              <label for="city">City</label>
              <input   maxlength="250" class="form-control" type="text" name="city" id="city" [(ngModel)]="combinedPTC.city">
              </div>
          
          </div>

          <br>
          
      
          
          <div class="form-group row">
          
            <div class="col-md-4">
            <label for="state">State</label>
            <input   maxlength="250" class="form-control" type="text" name="state" id="state" [(ngModel)]="combinedPTC.state">
            </div>

            <div class="col-md-4">
              <label for="country">Country</label>
              <input   maxlength="250" class="form-control" type="text" name="country" id="country" [(ngModel)]="combinedPTC.country">
              </div>
          
            <div class="col-md-4">
            <label for="zipCode">Zip Code</label>
            <input   maxlength="250" class="form-control" type="text" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
            </div>
          
          </div>
          

      </form>
      </div>
    </div>
  <!-- </div> -->

  <div class="modal-footer">
    <button [disabled]="clicked"  class="btn btn-sm btn-primary float-right" (click)="onUpdate();clicked=true">Update</button>
  </div>