<div class="modal-header" [ngStyle]="backgroundStyle" >
  <h4 style="color: white;"  class="modal-title pull-left">New Subscription : {{firstName}} {{lastName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div> 




<div class="modal-body">
    <!-- <div class="card">
      <div class="card-body"> -->
        <form #adminForm="ngForm">
  
          <!-- <div class="form-group row">          
            <div class="col-md-6">
              <label for="if-user">User</label>
              <input readonly type="text" [(ngModel)]="userName">
            </div>
          </div>  -->
  
          <div class="form-group row">          
            <div class="col-md-6">
              <label for="subscription.roleName">Role</label>
              <select required class="form-control" [(ngModel)]="subscription.roleName">
                <option value="" disabled>Choose Role</option>
                <option *ngFor="let role of roles" [ngValue]="role">{{role}}</option>
              </select>
            </div>
  
            <div class="col-md-6">
              <label for="subscription.fkProductId">Course / Product</label>
              <select required class="form-control" [(ngModel)]="subscription.fkProductId">
                <option value="" disabled>Choose Course/Product</option>
                <option *ngFor="let product of products" [ngValue]="product.productId">{{product.productName}}</option>
              </select>
            </div> 
          </div>         
   
          <div class="form-group row">
            <div class="col-md-6">
              <label for="subscription.startedDate">Start Date</label>
              <input required class="form-control" id="subscription.startedDate" name="subscription.startedDate" [(ngModel)]="subscription.startedDate" type="date">
            </div>
   
            <div class="col-md-6">
              <label for="">End Date</label>
              <input required class="form-control" id="subscription.endedDate" name="subscription.endedDate" [(ngModel)]="subscription.endedDate" type="date">
            </div>
          </div>
  
        </form>
      <!-- </div>
    </div> -->
  </div>



  <div class="modal-footer">
    <button type="submit" [disabled]="clicked"  [disabled]="!adminForm.valid" 
    class="btn btn-sm btn-primary float-right" (click)="submit();clicked=true">Add</button>
  </div>