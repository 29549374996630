import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './handlers/guards/auth.guards';
import { SubjectDashboardComponent } from './dashboards/courseManagementDashboards/subject-dashboard/subject-dashboard.component';
import { SubjectCurriculumDashboardComponent } from './dashboards/courseManagementDashboards/subject-curriculum-dashboard/subject-curriculum-dashboard.component';
import { ShowAccountPageComponent } from './tryOutFolder/accountPage/show-account-page/show-account-page.component';
import { WrongUrlMessageComponent } from './course-management/wrong-url-message/wrong-url-message.component';
import { TeacherSubjectDashboardComponent } from './dashboards/teacherDashboards/teacher-subject-dashboard/teacher-subject-dashboard.component';
import { ViewStudentsPageComponent } from './dashboards/teacherDashboards/view-students-page/view-students-page.component';
import { CurriculumOfSubjectPageComponent } from './dashboards/teacherDashboards/curriculum-of-subject-page/curriculum-of-subject-page.component';
import { TeacherUserProfileComponent } from './dashboards/teacherDashboards/teacher-user-profile/teacher-user-profile.component';
import { ClassResultsPageComponent } from './dashboards/teacherDashboards/class-results-page/class-results-page.component';
import { ActivityAssessmentPageComponent } from './dashboards/teacherDashboards/activity-assessment-page/activity-assessment-page.component';
import { BcrmUserProfileComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_user/bcrm-user-profile/bcrm-user-profile.component';
import { ExternalFeedbackPageComponent } from './BIZ_CRM_FEEDBACK/dashboards/external-feedback-page/external-feedback-page.component';
import { TicketSupportFormComponent } from './BIZ_CRM_TICKET_SUPPORT/dashboard/ticket-support-form/ticket-support-form.component';
import { ExternalTicketSupportFormComponent } from './BIZ_CRM_TICKET_SUPPORT/dashboard/external-ticket-support-form/external-ticket-support-form.component';
import { ClassActivitiesDashboardComponent } from './dashboards/teacherDashboards/class-activities-dashboard/class-activities-dashboard.component';
import { ParentDashboardComponent } from './tryOutFolder/dashboards-tryout/parent-dashboard/parent-dashboard.component';
import { CombinedParentTeacherDashboardComponent } from './tryOutFolder/dashboards-tryout/combined-parent-teacher-dashboard/combined-parent-teacher-dashboard.component';
import { UserParentComponent } from './tryOutFolder/user-parent/user-parent.component';
import { ExtActivityAssessmentPageComponent } from './dashboards/combinedParentTeacher/ext/ext-activity-assessment-page/ext-activity-assessment-page.component';
import { ExtBotShoppingPageComponent } from './BOT_CODE_EXT_SHOP/bot_pages/ext-shop/ext-bot-shopping-page/ext-bot-shopping-page.component';
import { BotUserExtFormPageComponent } from './BOT_CODE_EXT_FORMS/bot_dashboard/bot-user-ext-form-page/bot-user-ext-form-page.component';
import { ExtMediaViewPageComponent } from './BOT_CODE_EXT_MEDIA/dashboards/ext-media-view-page/ext-media-view-page.component';
import { WebSocketTestComponent } from './BOT_CODE/web-socket-test/web-socket-test.component';
  
const routes: Routes = [ 
  {path: 'account/:parentUserId', component : ShowAccountPageComponent,
  canActivate: [AuthGuard],
  data: { roles: ["PARENT_MENTOR"]}},
  {path: 'InvalidUrl', component : WrongUrlMessageComponent},
  {path: 'subjectDashboard/:productId', component : SubjectDashboardComponent,
  canActivate: [AuthGuard],
  data: { roles: ["COURSE_ADMIN"]}},

  {path: 'subjectCurriculumDashboard/:subId', component : SubjectCurriculumDashboardComponent,
				canActivate: [AuthGuard],
				data: { roles: ["COURSE_ADMIN"]}},

  {path: 'teacher-subject/:subjectId/:teacherId/:classId', component : TeacherSubjectDashboardComponent,
				canActivate: [AuthGuard],
				data: { roles: ["TEACHER"]}}
        ,

			{
				path: 'class-activities/:subjectId/:teacherId/:classId',
				component: ClassActivitiesDashboardComponent,
				canActivate: [AuthGuard],
				data: { roles: ["TEACHER"]}

			},


  {path: 'class-students/:classId/:subjectId', component : ViewStudentsPageComponent,
  canActivate: [AuthGuard],
  data: { roles: ["TEACHER"]}},

  {path: 'curriculum/:subjectId', component : CurriculumOfSubjectPageComponent,
				canActivate: [AuthGuard],
				data: { roles: ["TEACHER"]}},
  
  
        
        {path: 'web-socket-test', component : WebSocketTestComponent},

  {path: 'login', component : LoginComponent},

  {path: 'feedback/extFeedback/:formActiveId', component : ExternalFeedbackPageComponent},

  {path: 'bot/ext-form/:formActiveId/:botUserId', component : BotUserExtFormPageComponent},

  {path: 'bot/ext-media/download/:officeId/:botMediaId', component : ExtMediaViewPageComponent},

  {path: 'ticketSupport/ext-ticket-support/raise-ticket/form/:ticketSupportDefinitionId/:officeId', component : ExternalTicketSupportFormComponent},

  {path: 'ticket-support/raise-ticket/form/:ticketSupportDefinitionId/:customerId', component : TicketSupportFormComponent},

  {path: 'user-account/:userId', component : TeacherUserProfileComponent,
  canActivate: [AuthGuard],
  data: { roles: ["OFFICE_ADMIN", "CLASS_ADMIN", "COURSE_ADMIN", 
  "CRM_ADMIN","TEACHER", "STUDENT", "PARENT","BCRM_PM", "BCRM_OFFICE_ADMIN",
  "BCRM_MAN", "BCRM_EXE", "BCRM_DM", "BCRM_FM", "BCRM_TM", "BCRM_DATA", "BCRM_FEEDBACK_MANAGER",
  "BCRM_SALES_COORDINATOR", "BCRM_CUSTOMER"
 ]}
},

{path: 'bcrm-user-account/:userId', component : BcrmUserProfileComponent,
canActivate: [AuthGuard],
data: { roles: ["BCRM_PM", "BCRM_OFFICE_ADMIN",
"BCRM_MAN", "BCRM_EXE", "BCRM_DM", "BCRM_FM", "BCRM_TM", 
"BCRM_DATA", "BCRM_FEEDBACK_MANAGER", "BCRM_SALES_COORDINATOR",
"BCRM_CUSTOMER", "CB_ORG_ADMIN", "CB_OFFICE_ADMIN", "CB_SHOP_ORDERS_MANAGER", "CB_AGENT", "CB_NOTIFICATIONS_MANAGER"
]}
},
{path: 'chat-window-try', component : ParentDashboardComponent},

{path: 'chat-list-try', component : CombinedParentTeacherDashboardComponent},

{path: 'tree-str', component : UserParentComponent},

{
  path: 'ext/p-m/activity-assessment/:botPMExtActLinkId',
  component: ExtActivityAssessmentPageComponent
},

  {path: 'class-results/:classId', component : ClassResultsPageComponent,
  canActivate: [AuthGuard],
  data: { roles: ["TEACHER"]}},

  {path: 'assessment-results/:classId/:subjectId/:teacherId', component : ActivityAssessmentPageComponent,
				canActivate: [AuthGuard],
				data: { roles: ["TEACHER"]}},

        {
          path: 'cb-ext/shop/:botInvoiceId',
          component : ExtBotShoppingPageComponent
        },
        

 { path: 'auth', loadChildren: () => import('../app/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      // {
      // 	path: 'dashboard',
      // 	loadChildren: () => import('../app/superadmindashboard/superadmindashboard.module').then(m => m.SuperadmindashboardModule)
      // }

      {
        path: 'dashboard',
        loadChildren: () => import('../app/views/dashboards/dashboard.module').then(m => m.DashboardModule)
      }
    ] 
  },
  {
    path: '**',pathMatch: 'full', redirectTo: '/InvalidUrl'
  } 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
