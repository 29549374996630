import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { DatePipe } from "@angular/common";
import { Message } from "src/app/BOT_CODE/bot_classes/message";
import { ViewBotMediaForAgentComponent } from "src/app/BOT_CODE/bot_modals/bot-media/view-bot-media-for-agent/view-bot-media-for-agent.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { SendTemplateMessageComponent } from "src/app/BOT_CODE/bot_modals/bot_template_modals/send-template-message/send-template-message.component";
import { UploadBotMediaComponent } from "src/app/BOT_CODE/bot_modals/bot-media/upload-bot-media/upload-bot-media.component";
import { BotConvoLog } from "src/app/BOT_CODE/bot_classes/bot-convo-log";
import { BotUserLocationModalComponent } from "src/app/BOT_CODE/bot_modals/bot_user_location/bot-user-location-modal/bot-user-location-modal.component";
import { VoiceRecorderModalComponent } from "src/app/BOT_CODE/bot_modals/voice_recording/voice-recorder-modal/voice-recorder-modal.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-cb-a-convo-log-view",
  templateUrl: "./cb-a-convo-log-view.component.html",
  styleUrls: ["./cb-a-convo-log-view.component.scss"],
})
export class CbAConvoLogViewComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_AGENT";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botUsersObject: BotUser = new BotUser();
  botUserForChatWindow: BotUser = new BotUser();
  countObj: BotUser = new BotUser();
  chatsContext = "NM";

  constructor(
    private ngbModalService: NgbModal,
    private botResponseService: BotResponseServiceService,
    private datePipe: DatePipe,
    public router: Router,
    private sharedDataService: SharedDataService,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private botOfficeService: BotOfficeServiceService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  locationUrl: any;
  searchText: any;
  exitLoop = false;
  crmAccess = false;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.botUserForChatWindow.userId = null;
    this.botUserForChatWindow.botConvoLog.latestFetchedConvoLogId = null;

    this.getUsersForChatWindow();
    this.loopForBasicDetailsAndChatWindowConvoLog();
  }

  loopForBasicDetailsAndChatWindowConvoLog() {
    if (
      this.exitLoop === false &&
      this.router.url === "/dashboard/cb-agent-convo-log-view"
    ) {
      let i = 1;
      setTimeout(() => {
        this.updateUserListAndChatWindow();
        i = i + 1;
        if (i < 10) {
          this.loopForBasicDetailsAndChatWindowConvoLog();
        }
      }, 5000);
    }
  }

  botAgent: BotUser = new BotUser();
  getBotAgents() {
    this.showSpinner = false;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBotAgentsAndManualChatsByOffice"
      )
      .then((res) => {
        this.botAgent = res;
      });
  }

  botAgentUserIdToAssign = "";
  assignAgentToManualChat() {
    if (this.botAgentUserIdToAssign !== "") {
      this.botOfficeService
        .assignAgentToManualChat(
          environment.apiUrl +
            // "botConvo/assignAgentToManualChat/" +
            "botConvo/reassignAgent/" +
            this.botUserForChatWindow.userId +
            "/" +
            this.botAgentUserIdToAssign
        )
        .then((res) => {
          this.showSpinner = true;
          this.toaster.success("Manual chat mode deactivated !");
          this.botUserForChatWindow = new BotUser();
          this.updateUserListAndChatWindow();
        });
    } else {
      this.toaster.error("Choose an Agent to assign !");
    }
  }

  filterUsersBasedOnContext() {
    this.showSpinner = true;
    this.botUsersObject.users.forEach((user) => {
      user.userVisible = false;

      if (this.chatsContext === "AC") {
        user.userVisible = true;
      }

      if (this.chatsContext === "NM") {
        if (
          user.convoMode === "manual" &&
          user.botConvoLog.unseenLogCount > 0
        ) {
          user.userVisible = true;
        }
      }

      if (this.chatsContext === "NA") {
        if (user.convoMode === "bot" && user.botConvoLog.unseenLogCount > 0) {
          user.userVisible = true;
        }
      }

      if (this.chatsContext === "A") {
        if (user.convoMode === "bot") {
          user.userVisible = true;
        }
      }

      if (this.chatsContext === "M") {
        if (user.convoMode === "manual") {
          user.userVisible = true;
        }
      }
    });
    this.showSpinner = false;
  }

  getUsersForChatWindow() {
    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl + "botConvoLog/getUserConvoBasicDetailsByUroId"
      )
      .then((res) => {
        this.botUsersObject = res;
        this.crmAccess = this.botUsersObject.crmAccess;

        this.countObj = new BotUser();

        this.botUsersObject.users.forEach((user) => {
          user.userVisible = false;

          if (user.convoMode === "bot") {
            this.countObj.automatedChatUsersCount =
              this.countObj.automatedChatUsersCount + 1;
            if (user.botConvoLog.unseenLogCount > 0) {
              this.countObj.newAutomatedChatMessagesUsersCount =
                this.countObj.newAutomatedChatMessagesUsersCount + 1;
            }
          } else if (user.convoMode === "manual") {
            this.countObj.manualChatUsersCount =
              this.countObj.manualChatUsersCount + 1;
            if (user.botConvoLog.unseenLogCount > 0) {
              this.countObj.newManualChatMessagesUsersCount =
                this.countObj.newManualChatMessagesUsersCount + 1;
              user.userVisible = true;
            }
          }

          user.botConvoLog.lastLogItemId = null;

          if (
            user.botConvoLog.lastLogItemTimestamp !== null &&
            user.botConvoLog.lastLogItemTimestamp !== undefined
          ) {
            let t = user.botConvoLog.lastLogItemTimestamp;
            let ft = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]);
            user.botConvoLog.lastLogItemDate = this.datePipe.transform(
              ft,
              "MMM d, y"
            );
          }
        });

        this.showSpinner = false;
      });
  }

  loadOlderMessages() {
    this.showSpinner = true;

    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/getOlderConvoLogs/" +
          this.botUserForChatWindow.userId +
          "/" +
          this.botUserForChatWindow.messages[0].botConvoLogId
      )
      .then((res) => {
        let logObject: BotConvoLog = res;

        this.botUsersObject.users.forEach((existingUser) => {
          if (this.botUserForChatWindow.userId === existingUser.userId) {
            if (
              logObject.list !== null &&
              logObject.list !== undefined &&
              logObject.list.length > 0
            ) {
              let olderMessages: Message[] = [];

              logObject.list.forEach((log) => {
                let logt = log.timestamp;
                let logFt = Date.UTC(
                  logt[0],
                  logt[1] - 1,
                  logt[2],
                  logt[3],
                  logt[4]
                );
                let formattedT = this.datePipe.transform(
                  logFt,
                  "dd/MM/yyyy, h:mm a"
                );

                let req: Message = new Message();
                req.action = "request";
                req.message = log.request;
                req.timestamp = formattedT;
                req.erroneousResFromUser = log.erroneousResFromUser;
                req.userRespondedType = log.userRespondedType;
                req.locationUrl = log.locationUrl;
                req.fkRequestBotMediaId = log.fkRequestBotMediaId;
                req.botRespondedType = log.botRespondedType;
                req.botConvoLogId = log.botConvoLogId;
                olderMessages.push(req);

                let res: Message = new Message();
                res.action = "response";
                res.message = log.response;
                res.timestamp = formattedT;
                res.erroneousResFromUser = log.erroneousResFromUser;
                res.userRespondedType = log.userRespondedType;
                res.locationUrl = log.locationUrl;
                res.respondedBy = log.respondedBy;
                res.fkResponseBotMediaId = log.fkResponseBotMediaId;
                res.botRespondedType = log.botRespondedType;
                res.botConvoLogId = log.botConvoLogId;
                olderMessages.push(res);
              });

              let existingMessages: Message[] = existingUser.messages;

              existingUser.messages = olderMessages;
              existingMessages.forEach((eMessage) => {
                existingUser.messages.push(eMessage);
              });

              this.botUserForChatWindow.messages = existingUser.messages;

              this.showSpinner = false;
            } else {
              this.toaster.show("No Older Messages Found");
              this.showSpinner = false;
            }
          }
        });
      });
  }

  openChatWindow(botUser: BotUser) {
    this.showSpinner = true;
    this.botUserForChatWindow = botUser;
    this.updateUserListAndChatWindow();
  }

  updateUserListAndChatWindow() {
    let chatWindowUserId: any;
    let latestFetchedConvoLogId: any;

    if (this.botUserForChatWindow.userId === null) {
      chatWindowUserId = null;
      latestFetchedConvoLogId = null;
    } else {
      chatWindowUserId = this.botUserForChatWindow.userId;
      latestFetchedConvoLogId =
        this.botUserForChatWindow.botConvoLog.latestFetchedConvoLogId;
    }

    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/updateUsersAndChatWindowConvoLogByUroId/" +
          chatWindowUserId +
          "/" +
          latestFetchedConvoLogId
      )
      .then((res) => {
        let newObjUsers: BotUser = res;

        this.countObj = new BotUser();

        newObjUsers.users.forEach((updatedUser) => {
          //COUNT UPDATE
          if (updatedUser.convoMode === "bot") {
            this.countObj.automatedChatUsersCount =
              this.countObj.automatedChatUsersCount + 1;
            if (updatedUser.botConvoLog.unseenLogCount > 0) {
              this.countObj.newAutomatedChatMessagesUsersCount =
                this.countObj.newAutomatedChatMessagesUsersCount + 1;
            }
          } else if (updatedUser.convoMode === "manual") {
            this.countObj.manualChatUsersCount =
              this.countObj.manualChatUsersCount + 1;
            if (updatedUser.botConvoLog.unseenLogCount > 0) {
              this.countObj.newManualChatMessagesUsersCount =
                this.countObj.newManualChatMessagesUsersCount + 1;
              updatedUser.userVisible = true;
            }
          }

          //FILTER USERS
          if (this.chatsContext === "NM") {
            if (
              updatedUser.convoMode === "manual" &&
              updatedUser.botConvoLog.unseenLogCount > 0
            ) {
              updatedUser.userVisible = true;
            } else {
              updatedUser.userVisible = false;
            }
          }
          if (this.chatsContext === "NA") {
            if (
              updatedUser.convoMode === "bot" &&
              updatedUser.botConvoLog.unseenLogCount > 0
            ) {
              updatedUser.userVisible = true;
            } else {
              updatedUser.userVisible = false;
            }
          }
          if (this.chatsContext === "A") {
            if (updatedUser.convoMode === "bot") {
              updatedUser.userVisible = true;
            } else {
              updatedUser.userVisible = false;
            }
          }
          if (this.chatsContext === "M") {
            if (updatedUser.convoMode === "manual") {
              updatedUser.userVisible = true;
            } else {
              updatedUser.userVisible = false;
            }
          }
          if (this.chatsContext === "AC") {
            updatedUser.userVisible = true;
          }

          updatedUser.messages = [];

          if (
            updatedUser.botConvoLog.lastLogItemTimestamp !== null &&
            updatedUser.botConvoLog.lastLogItemTimestamp !== undefined
          ) {
            let t = updatedUser.botConvoLog.lastLogItemTimestamp;
            let ft = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]);
            updatedUser.botConvoLog.lastLogItemDate = this.datePipe.transform(
              ft,
              "MMM d, y"
            );
          }

          // CHAT WINDOW USER
          if (
            this.botUserForChatWindow.userId !== null &&
            this.botUserForChatWindow.userId !== undefined &&
            this.botUserForChatWindow.userId === updatedUser.userId
          ) {
            updatedUser.chatWindowOpen = true;

            updatedUser.messages = [];
            if (
              this.botUserForChatWindow.messages !== null &&
              this.botUserForChatWindow.messages !== undefined
            ) {
              this.botUserForChatWindow.messages.forEach((messg) => {
                updatedUser.messages.push(messg);
              });
            }

            if (
              updatedUser.botConvoLog !== null &&
              updatedUser.botConvoLog !== undefined &&
              updatedUser.botConvoLog.list !== null &&
              updatedUser.botConvoLog.list !== undefined &&
              updatedUser.botConvoLog.list.length > 0
            ) {
              updatedUser.botConvoLog.list.forEach((log) => {
                let logt = log.timestamp;
                let logFt = Date.UTC(
                  logt[0],
                  logt[1] - 1,
                  logt[2],
                  logt[3],
                  logt[4]
                );
                let formattedT = this.datePipe.transform(
                  logFt,
                  "dd/MM/yyyy, h:mm a"
                );

                let req: Message = new Message();
                req.action = "request";
                req.message = log.request;
                req.timestamp = formattedT;
                req.erroneousResFromUser = log.erroneousResFromUser;
                req.userRespondedType = log.userRespondedType;
                req.locationUrl = log.locationUrl;
                req.fkRequestBotMediaId = log.fkRequestBotMediaId;
                req.botRespondedType = log.botRespondedType;
                req.botConvoLogId = log.botConvoLogId;
                updatedUser.messages.push(req);

                let res: Message = new Message();
                res.action = "response";
                res.message = log.response;
                res.timestamp = formattedT;
                res.erroneousResFromUser = log.erroneousResFromUser;
                res.userRespondedType = log.userRespondedType;
                res.locationUrl = log.locationUrl;
                res.respondedBy = log.respondedBy;
                res.fkResponseBotMediaId = log.fkResponseBotMediaId;
                res.botRespondedType = log.botRespondedType;
                res.botConvoLogId = log.botConvoLogId;
                updatedUser.messages.push(res);
              });
            }

            this.botUserForChatWindow = updatedUser;
            this.showSpinner = false;
            updatedUser.chatWindowOpen = true;
          } else {
            // copy existing messages & botConvoLog from old array of users
            var removeIndex = -1;
            this.botUsersObject.users.forEach((existingUser, index) => {
              if (
                updatedUser.userId === existingUser.userId &&
                existingUser.messages !== null &&
                existingUser.messages !== undefined
              ) {
                updatedUser.messages = existingUser.messages;
                updatedUser.botConvoLog.latestFetchedConvoLogId =
                  existingUser.botConvoLog.latestFetchedConvoLogId;
                removeIndex = index;
              }
            });
            this.botUsersObject.users.splice(removeIndex, 1);
            updatedUser.chatWindowOpen = false;
          }
        });

        this.botUsersObject = newObjUsers;
        this.showSpinner = false;
      });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      this.exitLoop = true;
    });
  }

  viewBotMedia(botMediaId: any, mediaType: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotMediaForAgentComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMediaId: botMediaId,
      mediaType: mediaType,
      officeId: this.userOfficeId,
      getByBOT_MEDIA_ID: true,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUser) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
    this.exitLoop = true;
  }

  goToCrmTasksPage() {
    this.router.navigate(["dashboard/cb-agent/crm/tasks"]);
    this.exitLoop = true;
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  EnterSubmit(event: any) {
    if (event.keyCode === 13) {
      if (
        this.manualResponseByAgent !== "" &&
        this.manualResponseByAgent !== undefined &&
        this.manualResponseByAgent !== null
      ) {
        this.sendManualResponseByAgent();
      }
    }
  }

  manualResponseByAgent = "";
  sendManualResponseByAgent() {
    if (
      this.manualResponseByAgent !== "" &&
      this.manualResponseByAgent !== undefined &&
      this.manualResponseByAgent !== null
    ) {
      this.botUserForChatWindow.manualChatAgentResponse =
        this.manualResponseByAgent;
      this.manualResponseByAgent = "";
      this.botResponseService
        .sendManualResponseByAgent(
          environment.apiUrl + "botResponse/sendManualResponseByAgent",
          this.botUserForChatWindow
        )
        .then((res) => {
          this.updateUserListAndChatWindow();
          this.manualResponseByAgent = "";
        });
    } else {
      this.toaster.error("Enter response to send !");
    }
  }

  markNewMessagesAsSeen() {
    this.botResponseService
      .deactivateManualChat(
        environment.apiUrl +
          "botConvoLog/markNewMessagesAsSeen/" +
          this.botUserForChatWindow.userId
      )
      .then((res) => {
        this.showSpinner = true;
        this.toaster.success("Conversation marked as seen !");
        this.updateUserListAndChatWindow();
      });
  }

  deactivateManualChat() {
    this.botResponseService
      .deactivateManualChat(
        environment.apiUrl +
          "botConvo/deactivateManualChat/" +
          this.botUserForChatWindow.userId
      )
      .then((res) => {
        this.showSpinner = true;
        this.toaster.success("Manual chat mode deactivated !");
        this.updateUserListAndChatWindow();
      });
  }

  botManChatReqUsers: BotUser[] = [];
  getManualChatReqsByUroId() {
    this.botManChatReqUsers = [];
    this.botResponseService
      .getManualChatReqsByUroId(
        environment.apiUrl + "botConvo/getManualChatReqsByUroId"
      )
      .then((res) => {
        this.botManChatReqUsers = res;
        if (
          this.botManChatReqUsers === null ||
          this.botManChatReqUsers === undefined ||
          (this.botManChatReqUsers !== null &&
            this.botManChatReqUsers !== undefined &&
            this.botManChatReqUsers.length === 0)
        ) {
          this.toaster.success("No Manual Chat Requests !");
        }
      });
  }

  assignManualChatToSelf(botUserId: any) {
    this.showSpinner = true;
    this.botOfficeService
      .assignAgentToManualChat(
        environment.apiUrl + "botConvo/assignManualChatToSelf/" + botUserId
      )
      .then((res) => {
        this.showSpinner = true;
        this.toaster.success("Manual chat mode assigned !");
        this.updateUserListAndChatWindow();
      });
  }

  sendTemplateMessage(botUser: any, chatWindowMessage: any) {
    let currentContext = "Send Template Message To a User";

    if (botUser !== null && chatWindowMessage === true) {
      this.toaster.error(
        "Customer Service Window Closed ! You can start a conversation using template messages"
      );
      currentContext = "Send Template Message To Particular User";
    }

    if (botUser !== null && chatWindowMessage === false) {
      currentContext = "Send Template Message To Particular User";
    }

    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SendTemplateMessageComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: currentContext,
      usersList: this.botUsersObject.users,
      receiverBotUser: botUser,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === "success") {
        this.updateUserListAndChatWindow();
      }
    });
  }

  sendMediaResponse(mediaType: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Send Response With Media And Caption",
      receiverBotUserId: this.botUserForChatWindow.userId,
      receiverBotUsername: this.botUserForChatWindow.firstName,
      mediaType: mediaType,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === "success") {
        this.updateUserListAndChatWindow();
      }
    });
  }

  // recordAndShareVoiceNote(mediaType: any) {
  //   this.config.class = "modal-md";
  //   const modalRef = this.ngbModalService.open(VoiceRecorderModalComponent, {
  //     size: "md",
  //     scrollable: true,
  //     windowClass: "app-modal-window",
  //     keyboard: false,
  //   });
  //   let data = {
  //     // context: "Send Response With Media And Caption",
  //     receiverBotUserId: this.botUserForChatWindow.userId,
  //     receiverBotUsername: this.botUserForChatWindow.firstName,
  //     mediaType: mediaType,
  //   };
  //   modalRef.componentInstance.fromParent = data;
  //   modalRef.result.then(
  //     (result) => {},
  //     (reason) => {}
  //   );
  //   modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
  //     if (receivedEntry === "success") {
  //       this.updateUserListAndChatWindow();
  //     }
  //   });
  // }

  sendLocationResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserLocationModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "send location object to user",
      fkBotUserId: this.botUserForChatWindow.userId,
      receiverBotUsername: this.botUserForChatWindow.firstName,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === "success") {
        this.updateUserListAndChatWindow();
      }
    });
  }

  shareVoiceNote() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(VoiceRecorderModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "share voice note to user",
      receiverBotUserId: this.botUserForChatWindow.userId,
      receiverBotUsername: this.botUserForChatWindow.firstName,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === "success") {
        this.updateUserListAndChatWindow();
      }
    });
  }

  viewBotMediaInChatWindow(botMediaId: any, mediaType: any, message: Message) {
    if (mediaType === "any") {
      message = this.getMediaByteArrayAlongWithMimeType(botMediaId, message);
    } else if (mediaType !== "any") {
      message = this.getBotMediaAsByteArrayForPreviewByBotMediaId(
        botMediaId,
        mediaType,
        message
      );
    }
  }

  getMediaByteArrayAlongWithMimeType(
    botMediaId: any,
    message: Message
  ): Message {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByBotMediaId/" +
          botMediaId +
          "/" +
          this.userOfficeId
      )
      .subscribe((data) => {
        this.botOfficeService
          .getMimeType(
            environment.apiUrl +
              "botMedia/getMediaByteArrayAndMimeTypeFromWcaByBotMediaId/" +
              botMediaId +
              "/" +
              this.userOfficeId
          )
          .then((res) => {
            message.mimeType = res.mime_type;

            if (
              message.mimeType === "image/jpeg" ||
              message.mimeType === "image/png"
            ) {
              let unsafeImageUrl = URL.createObjectURL(data);
              message.safeImageUrl =
                this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
              message.mediaLoaded = true;
            }

            if (
              message.mimeType === "video/mp4" ||
              message.mimeType === "video/3gp" ||
              message.mimeType === "audio/aac" ||
              message.mimeType === "audio/mp4" ||
              message.mimeType === "audio/mpeg" ||
              message.mimeType === "audio/ogg"
            ) {
              let url = URL.createObjectURL(data);
              message.urlSafe = this.sanitizer.bypassSecurityTrustUrl(url);
              message.playVideo = true;
              message.mediaLoaded = true;
            }

            if (
              message.mimeType === "text/plain" ||
              message.mimeType === "application/pdf" ||
              message.mimeType === "application/vnd.ms-powerpoint" ||
              message.mimeType === "application/msword" ||
              message.mimeType === "application/vnd.ms-excel" ||
              message.mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              message.mimeType ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
              message.mimeType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              message.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
                window.URL.createObjectURL(data)
              );
              message.mediaLoaded = true;
            }
          });
      });

    return message;
  }

  getBotMediaAsByteArrayForPreviewByBotMediaId(
    botMediaId: any,
    mediaType: any,
    message: Message
  ): Message {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByBotMediaId/" +
          botMediaId +
          "/" +
          this.userOfficeId
      )
      .subscribe((data) => {
        if (mediaType === "image") {
          let unsafeImageUrl = URL.createObjectURL(data);
          message.safeImageUrl =
            this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          message.mediaLoaded = true;
          message.mimeType = mediaType;
        }

        if (mediaType === "video" || mediaType === "audio") {
          let url = URL.createObjectURL(data);
          message.urlSafe = this.sanitizer.bypassSecurityTrustUrl(url);
          message.mediaLoaded = true;
          message.playVideo = true;
          message.mimeType = mediaType;
        }

        if (mediaType === "document") {
          message.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          message.mediaLoaded = true;
          message.mimeType = mediaType;
        }
      });
    return message;
  }

  footerText: any;
  bodyText: any;
  shareCatalogMessage() {
    if (
      this.bodyText !== null &&
      this.bodyText !== undefined &&
      this.bodyText.length !== 0
    ) {
      this.botUserForChatWindow.bodyText = this.bodyText;
      this.botUserForChatWindow.footerText = this.footerText;
      this.botResponseService
        .sendManualResponseByAgent(
          environment.apiUrl + "botResponse/sendCatalogueMessageByAgent",
          this.botUserForChatWindow
        )
        .then((res) => {
          let resObj: BotUser = res;
          if (resObj.bodyText === "Catalogue shared successfully !") {
            this.toaster.success("Catalogue shared successfully !");
            this.updateUserListAndChatWindow();
            this.bodyText = "";
            this.footerText = "";
          } else {
            this.toaster.error(resObj.bodyText);
          }
        });
    } else {
      this.toaster.error("Body is required !");
    }
  }
}
