<div [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="context==='Swap Order'" style="color: white;" class="modal-title pull-left">Swap Order</h4>
    <h4 *ngIf="context==='swapMainResAndSubResOrder'" style="color: white;" class="modal-title pull-left">Swap Order</h4>
    <h4 *ngIf="context==='User Registration'" style="color: white;" class="modal-title pull-left">User Details Registration Flow</h4>
    <h4 *ngIf="context==='Create New Conversation Flow'" style="color: white;" class="modal-title pull-left">Create New Conversation Flow</h4>
    <h4 *ngIf="context==='view additional responses'" style="color: white;" class="modal-title pull-left">View Additional Sub-Responses</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body">
        
        <div style="text-align: center;" *ngIf="context==='view additional responses'">
        
          <button style="width: auto;float: right;" class="btn btn-info"
         (click)="createAdditionalSubResponse();" >Add Additional Sub-Response</button>

         <br><br>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" >
            
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let res of botResponses">

              <mat-card class="example-card mat-elevation-z12" style="text-align: left;">              
          <h3>    <a style="font-weight: bold;color: black;text-align: left;">
           
            Order - {{res.additionalSubResOrder}}</a> 


            <a matTooltip="Menu" [matMenuTriggerFor]="beforeMenu" style="cursor: pointer;margin-right: 0.5%;float: right;">
              <img src="/assets/images/icons/three-dots.png" width="21" height="21" 
               alt="" loading="lazy"> </a>

               <mat-menu #beforeMenu="matMenu" xPosition="after">
                <button (click)="editBotResponse(res);" mat-menu-item>
                  Edit
                </button>
                <button (click)="viewLanguageVariationsForResponse(res);" mat-menu-item>
                  Language Variation
                </button>
                <button (click)="swapMainResAndSubResOrder(res.botResponseId);" mat-menu-item>
                  Swap Order
                </button>
                <button (click)="open(unlinkSubResponseFromMainResponseConfirmModal);" mat-menu-item>
                  Remove
                </button>
               </mat-menu>

               <ng-template #unlinkSubResponseFromMainResponseConfirmModal let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation Removal</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure to remove the sub-response ? 
                </div> 
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
                  <button type="button" class="btn btn-success" (click)="unlinkSubResponseFromMainResponse(res.botResponseId);modal.dismiss('Cross click')">Remove</button>
                </div>
              </ng-template>
                

                  
                 
          </h3>

        <h3><a style="color: black;text-align: left;"> {{res.response}} </a> </h3>  

                <div *ngIf="res.options !== null && res.options.length>0">
                  <h3 style="margin: 0%;font-weight: bold;">Options : </h3>
                  <h3 *ngFor="let opt of res.options" style="margin: 0%;color: black;cursor: pointer;">
                    {{opt.responseOptionNumber}}. {{opt.responseOption}} 
      <a *ngIf="res.var !== 'opt-in'" matTooltip="Edit" style="cursor:pointer;margin-right: 1%;" (click)="editOption(opt);">
        <img src="/assets/images/icons/edit.svg" width="21" height="21" alt="" loading="lazy">
      </a>
      <a *ngIf="opt.botResponseOptionsStatus===true && res.var !== 'opt-in'" matTooltip="Deactivate" style="margin-right: 1%;" (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
        <img src="/assets/images/icons/delete.png" width="21" height="21" 
        alt="" loading="lazy"> </a>
        <a *ngIf="opt.botResponseOptionsStatus===false && res.var !== 'opt-in'" matTooltip="Deactivated" style="margin-right: 1%;" (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
          <img src="/assets/images/icons/disable.png" width="21" height="21" 
          alt="" loading="lazy"> </a>
          <a *ngIf="opt.fkDefaultConvoFlowBotTreeDefinitionId === null && res.var !== 'opt-in'" matTooltip="Link Convo Flow" style="cursor:pointer;margin-right: 1%;" 
          (click)="linkConvoFlowToUserRegResOption(res.botResponseId,opt);">
            <img src="/assets/images/icons/paperclip.png" width="21" height="21" alt="" loading="lazy">
          </a>
          <a *ngIf="opt.fkDefaultConvoFlowBotTreeDefinitionId !== null && res.var !== 'opt-in'" matTooltip="View Linked Convo Flow" 
          style="cursor:pointer;margin-right: 1%;" (click)="open(linkedConvoFlow);">
            <img src="/assets/images/icons/eye.png" width="21" height="21" alt="" loading="lazy">
          </a>
          <!-- GROUP -->
          <a *ngIf="opt.fkBotUserGrpIdToAdd === null && res.var !== 'opt-in'" matTooltip="Link Group To Add User" style="cursor:pointer;margin-right: 1%;" 
          (click)="linkBotUserGroupToUserRegResOption(res.botResponseId,opt);">
            <img src="/assets/images/icons/group.png" width="21" height="21" alt="" loading="lazy">
          </a>
          <a *ngIf="opt.fkBotUserGrpIdToAdd !== null && res.var !== 'opt-in'" matTooltip="View Linked Group" style="cursor:pointer;margin-right: 1%;" 
          (click)="open(linkedGroupNameModal);">
            <img src="/assets/images/icons/group.png" width="21" height="21" alt="" loading="lazy">
          </a>


                        <ng-template #linkedConvoFlow let-modal>
                          <div class="modal-header">
                            <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Linked Convo Flow</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                           Flow Name : {{opt.fkDefaultConvoFlowBotTreeDef.definition}}
                          </div> 
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="unlinkFkConvoFlowFromOption(res.botResponseId,opt.botResponseOptionsId);modal.dismiss('Cross click')">Unlink Flow</button>
                          </div>
                        </ng-template> 

                        <ng-template #linkedGroupNameModal let-modal>
                          <div class="modal-header">
                            <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Linked Group</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                           Group Name : {{opt.fkBotUserGrpNameToAdd}}
                          </div> 
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="unlinkFkGroupIdFromOption(res.botResponseId,opt.botResponseOptionsId);modal.dismiss('Cross click')">Remove</button>
                          </div>
                        </ng-template> 


                  </h3>
                </div>
               
</mat-card>

              </div>
        </div>
      </div>

        <div style="text-align: center;" *ngIf="context==='Swap Order'">
          
          <mat-form-field [style.width.%]=100 appearance="fill">
            <mat-label>Swap Order With</mat-label>
            <mat-select disableRipple [(ngModel)]="swapOrderRes2">
              <mat-option *ngFor="let cb of botResponses" [value]="cb.botResponseId"> {{cb.response}} </mat-option>
            </mat-select>
          </mat-form-field>
        
        </div>

        <div style="text-align: center;" *ngIf="context==='swapMainResAndSubResOrder'">
          
          <mat-form-field [style.width.%]=100 appearance="fill">
            <mat-label>Swap Order With</mat-label>
            <mat-select disableRipple [(ngModel)]="swapOrderRes2">
              <mat-option *ngFor="let cb of botResponses" [value]="cb.botResponseId"> Order : {{cb.additionalSubResOrder}}, {{cb.response}} </mat-option>
            </mat-select>
          </mat-form-field>
        
        </div>

        <div style="text-align: center;" *ngIf="context==='User Registration'">
        
          <div *ngIf="botResponses===null || botResponses===undefined || botResponses.length === 0">
          <a style="cursor: pointer;" (click)="addFirstUserRegistrationResponse();">
              <img src="/assets/images/icons/add.png" width="100" height="100"
           alt="" loading="lazy"> </a> <br> <br>
           <h3>Add User Registration Question</h3>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="16px grid" >
            
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let res of botResponses">

              <mat-card class="example-card mat-elevation-z12" style="text-align: left;">              
          <h3>    <a style="font-weight: bold;color: black;text-align: left;">
            <img matTooltip="Active" *ngIf="res.botResponseStatus===true" src="/assets/images/icons/complete.png" width="17" height="17" alt="" loading="lazy"> 
            <img matTooltip="Inactive" *ngIf="res.botResponseStatus===false" src="/assets/images/icons/nonStarted.png" width="17" height="17" alt="" loading="lazy"> 
            Question {{res.userRegResSlNo}} [{{res.var}}] </a> 


            <a matTooltip="Menu" [matMenuTriggerFor]="beforeMenu" style="cursor: pointer;margin-right: 0.5%;float: right;">
              <img src="/assets/images/icons/three-dots.png" width="21" height="21" 
               alt="" loading="lazy"> </a>

               <mat-menu #beforeMenu="matMenu" xPosition="after">
                <button (click)="editBotResponse(res);" mat-menu-item>
                  Edit
                </button>
                <button (click)="viewLanguageVariationsForResponse(res);" mat-menu-item>
                  Language Variation
                </button>
                <button *ngIf="res.var==='customVar1' || res.var==='customVar2'" 
                (click)="addOptionToResponse(res.botResponseId);" mat-menu-item>
                  Add Options
                </button>
                <button (click)="swapOrder(res.botResponseId, res.userRegResSlNo);" mat-menu-item>
                  Swap Order
                </button>
                <button *ngIf="res.botResponseStatus===true" 
                (click)="responseStatusUpdate(res);" mat-menu-item>
                Deactivate
                </button>
                <button *ngIf="res.botResponseStatus===false" 
                (click)="responseStatusUpdate(res);" mat-menu-item>
                Activate
                </button>
               </mat-menu>


               <ng-template #viewErrResModal let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Linked Error Response</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="color: black;">
                 Response : {{res.errorResponseSlNo}} <br>
                 {{res.errorResponseResponse}}
                </div> 
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" style="width: auto;"
                  (click)="unlinkErrorResponseForResponse(res.botResponseId);modal.dismiss('Cross click')">Unlink Error Response</button>
                </div>
              </ng-template> 

                  
                 
          </h3>

        <h3><a style="color: black;text-align: left;"> {{res.response}} </a> </h3>  

                <div *ngIf="res.options !== null && res.options.length>0">
                  <h3 style="margin: 0%;font-weight: bold;">Options : </h3>
                  <h3 *ngFor="let opt of res.options" style="margin: 0%;color: black;cursor: pointer;">
                    {{opt.responseOptionNumber}}. {{opt.responseOption}} 
      <a *ngIf="res.var !== 'opt-in'" matTooltip="Edit" style="cursor:pointer;margin-right: 1%;" (click)="editOption(opt);">
        <img src="/assets/images/icons/edit.svg" width="21" height="21" alt="" loading="lazy">
      </a>
      <a *ngIf="opt.botResponseOptionsStatus===true && res.var !== 'opt-in'" matTooltip="Deactivate" style="margin-right: 1%;" (click)="open(confirmOptionDeactivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
        <img src="/assets/images/icons/delete.png" width="21" height="21" 
        alt="" loading="lazy"> </a>
        <a *ngIf="opt.botResponseOptionsStatus===false && res.var !== 'opt-in'" matTooltip="Deactivated" style="margin-right: 1%;" (click)="open(confirmOptionActivation);optionToDeactivate=opt.responseOption;botResponseOptionIdToDeactivate=opt.botResponseOptionsId"> 
          <img src="/assets/images/icons/disable.png" width="21" height="21" 
          alt="" loading="lazy"> </a>
          <a *ngIf="opt.fkDefaultConvoFlowBotTreeDefinitionId === null && res.var !== 'opt-in'" matTooltip="Link Convo Flow" style="cursor:pointer;margin-right: 1%;" 
          (click)="linkConvoFlowToUserRegResOption(res.botResponseId,opt);">
            <img src="/assets/images/icons/paperclip.png" width="21" height="21" alt="" loading="lazy">
          </a>
          <a *ngIf="opt.fkDefaultConvoFlowBotTreeDefinitionId !== null && res.var !== 'opt-in'" matTooltip="View Linked Convo Flow" 
          style="cursor:pointer;margin-right: 1%;" (click)="open(linkedConvoFlow);">
            <img src="/assets/images/icons/eye.png" width="21" height="21" alt="" loading="lazy">
          </a>
          <!-- GROUP -->
          <a *ngIf="opt.fkBotUserGrpIdToAdd === null && res.var !== 'opt-in'" matTooltip="Link Group To Add User" style="cursor:pointer;margin-right: 1%;" 
          (click)="linkBotUserGroupToUserRegResOption(res.botResponseId,opt);">
            <img src="/assets/images/icons/group.png" width="21" height="21" alt="" loading="lazy">
          </a>
          <a *ngIf="opt.fkBotUserGrpIdToAdd !== null && res.var !== 'opt-in'" matTooltip="View Linked Group" style="cursor:pointer;margin-right: 1%;" 
          (click)="open(linkedGroupNameModal);">
            <img src="/assets/images/icons/group.png" width="21" height="21" alt="" loading="lazy">
          </a>


                        <ng-template #linkedConvoFlow let-modal>
                          <div class="modal-header">
                            <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Linked Convo Flow</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                           Flow Name : {{opt.fkDefaultConvoFlowBotTreeDef.definition}}
                          </div> 
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="unlinkFkConvoFlowFromOption(res.botResponseId,opt.botResponseOptionsId);modal.dismiss('Cross click')">Unlink Flow</button>
                          </div>
                        </ng-template> 

                        <ng-template #linkedGroupNameModal let-modal>
                          <div class="modal-header">
                            <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Linked Group</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                           Group Name : {{opt.fkBotUserGrpNameToAdd}}
                          </div> 
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" (click)="unlinkFkGroupIdFromOption(res.botResponseId,opt.botResponseOptionsId);modal.dismiss('Cross click')">Remove</button>
                          </div>
                        </ng-template> 


                  </h3>
                </div>
               
</mat-card>
<br>
                  <div *ngIf="res.nextBotResponseId===null && botResponses.length < 7">
                    <button style="width: auto;float: right;"
                    (click)="addNextUserRegistrationResponse(res.botResponseId);" 
                    class="btn btn-info">Add Next Question</button>
                  </div>

              </div>
        </div>
      </div>
        
      <div *ngIf="context==='Create New Conversation Flow'">

        <div>
          <label class="required" for="definition">Flow Name</label>
          <input #varV="ngModel" required class="form-control" type="text"
          name="definition" id="definition" [(ngModel)]="botTreeDef.definition">
          <div class="alert alert-danger" *ngIf="varV.touched && !varV.valid"> Name required</div>
        </div>

      </div>

       
</div>
</div>







<div *ngIf="fromParent.context==='Swap Order'" class="modal-footer">
  <button [disabled]="clicked" class="btn btn-sm btn-primary float-right" 
   (click)="saveOrderSwap();">Save</button>
</div>

<div *ngIf="fromParent.context==='swapMainResAndSubResOrder'" class="modal-footer">
  <button [disabled]="clicked" class="btn btn-sm btn-primary float-right" 
   (click)="saveSwappedMainResAndSubResOrder();">Save</button>
</div>



<div *ngIf="fromParent.context==='Create New Conversation Flow'" class="modal-footer">
  <button [disabled]="clicked" class="btn btn-sm btn-primary float-right" 
   (click)="createNewConversationFlow();">Save</button>
</div>


<ng-template #confirmOptionDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation Deactivation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate the option <a style="font-weight: bold;">{{optionToDeactivate}}</a> ? <br>
    Upon deactivation, this option will not appear in the whatsapp conversation. <br>
    You can activate it again later.
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="botResOptionDeactivation();modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template> 
<ng-template #confirmOptionActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Option Activation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to activate the option <a style="font-weight: bold;">{{optionToDeactivate}}</a> ? <br>
    Upon activation, this option will appear in the whatsapp conversation. <br>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="botResOptionDeactivation();modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template>