
 
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
 <br>

 <div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>

 <div class="content"  *ngIf="showOutstandingInvoices === false">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >

      
      <mat-card  class="example-card mat-elevation-z12" >
     
        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/inv.svg" width="65" height="65"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title style="margin-bottom: 6%;">Total <br>
          Orders</mat-card-title>
         <mat-card-title (click)="onclick('All Orders');"
          style="margin-top: 6%;color: blue;cursor: pointer;">{{totalOrders}}</mat-card-title>
      
    </mat-card>
    
         
        </div>


    <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="50%" >

      
  <mat-card  class="example-card mat-elevation-z12" >
 
    <div class="card-icon" >
      <a> 
        <img src="/assets/images/icons/ordersPlaced.png" width="65" height="65"
         alt="" loading="lazy">
      </a>
    </div>
     <br>
     <mat-card-title style="margin-bottom: 6%;">Orders Placed</mat-card-title>
     <mat-card-title (click)="onclick('Order Placed');"
      style="margin-top: 6%;color: blue;cursor: pointer;">{{ordersPlaced}}</mat-card-title>
  
</mat-card>

     
    </div>

    <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="50%" >

      
      <mat-card  class="example-card mat-elevation-z12" >
     
        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/ordersApproved.png" width="65" height="65"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title style="margin-bottom: 6%;">Orders Approved</mat-card-title>
         <mat-card-title (click)="onclick('Order Approved');"
          style="margin-top: 6%;color: blue;cursor: pointer;">{{ordersApproved}}</mat-card-title>
      
    </mat-card>
    
         
        </div>
    
        <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="50%" >

      
          <mat-card  class="example-card mat-elevation-z12" >
         
            <div class="card-icon" >
              <a> 
                <img src="/assets/images/icons/ordersInvoiced.png" width="65" height="65"
                 alt="" loading="lazy">
              </a>
            </div>
             <br>
             <mat-card-title style="margin-bottom: 6%;">Orders Invoiced</mat-card-title>
             <mat-card-title (click)="onclick('Order Invoiced');"
              style="margin-top: 6%;color: blue;cursor: pointer;">{{ordersInvoiced}}</mat-card-title>
          
        </mat-card>
        
             
            </div>
      
            <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="50%" >

      
              <mat-card  class="example-card mat-elevation-z12" >
             
                <div class="card-icon" >
                  <a> 
                    <img src="/assets/images/icons/ordersShipped.png" width="65" height="65"
                     alt="" loading="lazy">
                  </a>
                </div>
                 <br>
                 <mat-card-title style="margin-bottom: 6%;">Orders Shipped</mat-card-title>
                 <mat-card-title (click)="onclick('Order Shipped');"
                  style="margin-top: 6%;color: blue;cursor: pointer;">{{ordersShipped}}</mat-card-title>
              
            </mat-card>
            
                 
                </div>
                <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="50%" >

      
                  <mat-card  class="example-card mat-elevation-z12" >
                 
                    <div class="card-icon" >
                      <a> 
                        <img src="/assets/images/icons/ordersDelivered.png" width="65" height="65"
                         alt="" loading="lazy">
                      </a>
                    </div>
                     <br>
                     <mat-card-title style="margin-bottom: 6%;">Orders Delivered</mat-card-title>
                     <mat-card-title (click)="onclick('Order Delivered');"
                      style="margin-top: 6%;color: blue;cursor: pointer;">{{ordersDelivered}}</mat-card-title>
                  
                </mat-card>
                
                     
                    </div>
              
                    <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="50%" >

      
                      <mat-card  class="example-card mat-elevation-z12" >
                     
                        <div class="card-icon" >
                          <a> 
                            <img src="/assets/images/icons/ordersCancelled.png" width="65" height="65"
                             alt="" loading="lazy">
                          </a>
                        </div>
                         <br>
                         <mat-card-title style="margin-bottom: 6%;">Orders Cancelled</mat-card-title>
                         <mat-card-title (click)="onclick('Order Cancelled');"
                          style="margin-top: 6%;color: blue;cursor: pointer;">{{ordersCancelled}}</mat-card-title>
                      
                    </mat-card>
                    
                         
                        </div>
                  

  </div>
  </div>
 
  <div class="p-4" *ngIf="showOutstandingInvoices === false">
   
     
        <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              Orders / Invoices [ {{fromDate}} to {{toDate}} ]
            </span>

            <a style="cursor:pointer;" class="float-right" (click)="SearchOrdersByPeriod();">
              <img src="/assets/images/icons/searchIcon.svg" width="25" height="25" alt="" loading="lazy">
            </a>

            <a style="cursor:pointer;margin-right: 2%;" class="float-right" (click)="viewProductBasedOrders();">
              <img src="/assets/images/icons/productSvg.svg" width="25" height="25" alt="" loading="lazy">
            </a>
          
            <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
              <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
            </a>
          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
           
            <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="invoiceOptions" >
              <thead class="tableheader">
                <tr>
                  <th>Executive</th>
                  <th>Customer</th>
                  <th>Status</th>
                  <th>Date</th> 
                  <th>Alt Qty</th>

                  <th>Amt</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of invoices2">
                  <td class="td-height">{{ccd.executiveName}}</td>
                  <td class="td-height">{{ccd.customerName}}</td>

                  <td class="td-height">{{ccd.orderStatus}}</td>
                  <td class="td-height">{{ccd.invoiceDate}}</td>    
                  <td  style="text-align: right;" class="td-height">{{ccd.totalAlternateQuantity}}</td>

                  <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
                 
                  <td class="td-height" style=" height: 2rem;">
                   

                     <a *ngIf="ccd.verifiedByUroId !== null" style="cursor:pointer;" matTooltip="Order Verified" 
            (click)="showOutstandingInvoices=!showOutstandingInvoices;">
              <img src="/assets/images/icons/verified.png" width="30" height="30" alt="" loading="lazy">
            </a>

            <a *ngIf="ccd.verifiedByUroId === null" style="cursor:pointer;" matTooltip="Order Unverified" 
            (click)="showOutstandingInvoices=!showOutstandingInvoices;">
              <img src="/assets/images/icons/unverified.png" width="30" height="30" alt="" loading="lazy">
            </a>

            <button *ngIf="ccd.orderStatus === 'Order Placed' && ccd.verifiedByUroId === null" 
            (click)="open(confirmInvoiceVerification);" class="btn btn-primary">Verify</button>

            <button *ngIf="ccd.orderStatus === 'Order Placed'" (click)="editInvoice(ccd.invoiceId)" class="btn btn-secondary">Edit</button>
                
                     <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  

                    </td>
                  

                    <ng-template #confirmInvoiceVerification let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Verify Order</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        Are you sure to verify Order ? 
                      </div> 
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                        <button type="button" class="btn btn-success" style="width:auto;" (click)="verifyOrder(ccd.invoiceId);modal.dismiss('Cross click')">Verify</button>
                      </div>
                    </ng-template> 
                
               
                </tr>    

              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>

  <div class="content"  *ngIf="showOutstandingInvoices === true">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
  
  
  
  
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
    <mat-card  class="example-card mat-elevation-z12" >
   
      <div class="card-icon" >
        <a> 
          <img src="/assets/images/icons/totalDue.png" width="65" height="65"
           alt="" loading="lazy">
        </a>
      </div>
       <br>
       <mat-card-title style="margin-bottom: 6%;">Total Outstanding</mat-card-title>
       <mat-card-title>{{totalDue}}</mat-card-title>
        <mat-card-subtitle>({{totalDueAmt}})</mat-card-subtitle>
    
  </mat-card>
  
       
      </div>
  
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
        <mat-card  class="example-card mat-elevation-z12" >
       
          <div class="card-icon" >
            <a> 
              <img src="/assets/images/icons/dueInOneWeek.png" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>
           <br>
           <mat-card-title style="margin-bottom: 6%;">Due in one week</mat-card-title>
           <mat-card-title>{{dueInOneWeek}}</mat-card-title>
           <mat-card-subtitle>({{dueInOneWeekAmt}})</mat-card-subtitle>
  
      </mat-card>
      
           
          </div>
      
          <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
            <mat-card  class="example-card mat-elevation-z12" >
           
              <div class="card-icon" >
                <a> 
                  <img src="/assets/images/icons/dueToday.png" width="65" height="65"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Due Today</mat-card-title>
               <mat-card-title>{{dueToday}}</mat-card-title>
               <mat-card-subtitle>({{dueTodayAmt}})</mat-card-subtitle>
            
          </mat-card>
          
               
              </div>
        
              <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
                <mat-card  class="example-card mat-elevation-z12" >
               
                  <div class="card-icon" >
                    <a> 
                      <img src="/assets/images/icons/overdue.png" width="65" height="65"
                       alt="" loading="lazy">
                    </a>
                  </div>
                   <br>
                   <mat-card-title style="margin-bottom: 6%;">Overdue</mat-card-title>
                   <mat-card-title>{{overdue}}</mat-card-title>
               <mat-card-subtitle>({{overdueAmt}})</mat-card-subtitle>
                
              </mat-card>
              
                   
                  </div>
                    
  
    </div>
    </div>
   
  <div class="p-4" *ngIf="showOutstandingInvoices === true">
   
     
    <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon (click)="showOutstandingInvoices=!showOutstandingInvoices;" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Outstanding Invoices 
        </span>

        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Show Outstanding Invoices" class="float-right" 
        (click)="showOutstandingInvoices=!showOutstandingInvoices;">
          <img src="/assets/images/icons/switchBI.svg" width="25" height="25" alt="" loading="lazy">
        </a>
        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray2()">
          <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
        </a>
        <a style="cursor:pointer;margin-right: 2%;" matTooltip="View Old Invoices" class="float-right" 
        (click)="viewOldInvoices();">
          <img src="/assets/images/icons/e-invoice.svg" width="25" height="25" alt="" loading="lazy">
        </a>
      </div> 
    </div>
    <div class="card">
      <div class="card-body table-responsive">
        
        <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="invoiceOptions2" >
          <thead class="tableheader">
            <tr>
              <th>Date</th> 
              <th>Executive</th>
              <th>Customer</th>
              <th>Amt</th>
              <th>Outstanding Amt</th>
              <th>Contact</th>
              <th>Status</th>
            
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of outstandingInvoices">
              <td class="td-height">{{ccd.formattedActualInvoiceDate}}</td>
              <td class="td-height">{{ccd.executiveName}}</td>
              <td class="td-height">{{ccd.customerName}}</td>
              <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
              <td  style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>
              <td class="td-height">{{ccd.customerContact}}</td>
              <td *ngIf="ccd.overDue === 'black'" style="color: black;" class="td-height">{{ccd.dueStatus}} <br>
                <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
              </td>
              <td *ngIf="ccd.overDue === 'orange'" style="color: orange;" class="td-height">{{ccd.dueStatus}} <br>
                <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
              </td>
              <td *ngIf="ccd.overDue === 'red'" style="color: red;" class="td-height">{{ccd.dueStatus}} <br>
                <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
              </td>


             
              <td class="td-height" style=" height: 2rem;">
                <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  
                <!-- <button (click)="addNewCustomerCommunication(ccd.fkCustomerId)" class="btn btn-primary">Action</button>  <br> -->
                 <button (click)="viewCustomerComms(ccd.fkCustomerId)" style="width: auto;" class="btn btn-secondary">View Communications</button>  
              </td>
              


    
           
            </tr>    

          </tbody>
        </table>

      </div>
    </div>
  

</div>
 
 
  
  
  