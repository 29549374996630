<div class="modal-header">
    <h4 style="width: 50%"><img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">{{studentName}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> 
    
    
    <div class="modal-body">
  
      <h2 style="text-align: center;" *ngIf="actQuestionsDoesntExists === true" >No questions to display !</h2>
     
        <form *ngIf="actQuestionsExists === true">  
   
  <div class="questions-modal">

 
          <mat-vertical-stepper>
  
              <div class="stepper-class" *ngFor="let question of actQuestions">
  
              <mat-step [editable]="false" >
                <ng-template matStepLabel> {{question.activityQuestion}} </ng-template>
                  <p></p>
                <form #ngFrm="ngForm">
                   <div *ngIf="inputType=='Radio'; else elseBlock">
                      <mat-radio-group #ngForm="ngModel" required aria-label="Select an option" [(ngModel)]="activityScore.activityScoreValue" >
                          <mat-radio-button (ngModelChange)="Change($event)"  name="activityScoreValue" id="Yes" value="1">Yes</mat-radio-button>
                          <mat-radio-button  (ngModelChange)="Change($event)" name="activityScoreValue" id="No" value="0">No</mat-radio-button>
                        </mat-radio-group>  
                  </div>
                  <ng-template #elseBlock>OTHER INPUTS</ng-template> 
                </form>
                  <button [disabled]="!ngFrm.valid" (click)="addToArray(question.activityQuestionId)" mat-raised-button matStepperNext>Next</button>
              </mat-step>
              
          </div>
  
          <mat-step>
            <ng-template matStepLabel>Complete</ng-template>
            <p> 
  
              <button  [disabled]="clicked" *ngIf="actQuestionsExists === true" class="btn btn-sm btn-primary float-right" mat-raised-button color="primary" (click)="onSubmit();clicked=true">Save</button>
  
            </p>
          </mat-step>
          
            </mat-vertical-stepper>
  
          </div>
          
        </form>
        
    </div>
  
    
  
   