import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-activity-swap-modal',
  templateUrl: './activity-swap-modal.component.html',
  styleUrls: ['./activity-swap-modal.component.scss']
})
export class ActivitySwapModalComponent implements OnInit {
  
  selectedOption : any ='2';
  searchText : any;
  selected = "2";
  showSpinner = true;
  safeUrl : any;
  unsafeUrl : any;
  subId!: string;
  teacherId!: string
  selectedId! : string;
  completedActivities : boolean = false;
  newActivities : boolean = false;
  activeActivities : boolean = false;
  allActivities : boolean = false;

  allActivitiesList : any[] = [{
                                  activityPlannerId : '',
                                  activityPlannerName : '',
                                  activityPlannerDescription : '',
                                  activityPlannerOrder : '',
                                  activityPlannerStatus : '',
                                  fkSubjectId : '',
                                  activityPlannerImage : '',
                                  activityPlannerCompletionStatus : '',
                                  activityActiveStatus : '',
                                  safeUrl : '',
                                  selectedState : '',
                                  collapsedState : ''

                                }]

  newActivitiesList : any[] = [{
                                  activityPlannerId : '',
                                  activityPlannerName : '',
                                  activityPlannerDescription : '',
                                  activityPlannerOrder : '',
                                  activityPlannerStatus : '',
                                  fkSubjectId : '',
                                  activityPlannerImage : '',
                                  activityPlannerCompletionStatus : '',
                                  activityActiveStatus : '',
                                  safeUrl : '',
                                  selectedState : '',
                                  collapsedState : ''

                                }]

  completedActivitiesList : any[] = [{
                                      activityPlannerId : '',
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '',
                                      activityPlannerCompletionStatus : '',
                                      activityActiveStatus : '',
                                      safeUrl : '',
                                      selectedState : '',
                                      collapsedState : ''

                                    }]

  activeActivitiesList : any[] = [{
                                      activityPlannerId : '',
                                      activityPlannerName : '',
                                      activityPlannerDescription : '',
                                      activityPlannerOrder : '',
                                      activityPlannerStatus : '',
                                      fkSubjectId : '',
                                      activityPlannerImage : '',
                                      activityPlannerCompletionStatus : '',
                                      activityActiveStatus : '',
                                      safeUrl : '',
                                      selectedState : '',
                                      collapsedState : ''

                                    }]                                 

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  value1: any;
  
  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private activityPlannerService : ActivityPlannerService,
              private sanitizer : DomSanitizer,
              private imageService : FileServiceService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

  ngOnInit(): void {

    this.selectedId = 'null';

    this.subId = this.fromParent.subId;
    this.teacherId = this.fromParent.teacherId;

    this.getActivityPlannerListBySubId();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  getActivityPlannerListBySubId(){
    this.activityPlannerService.getAPsWithCompletionStatusBySubId(environment.apiUrl + "activityPlanner/getAPsWithCompletionStatusBySubId/" + this.subId + "/" + this.teacherId).then(
      data=>{
        
        this.allActivitiesList.pop();

        for(var index in data){

          this.allActivitiesList.push( { 'activityPlannerId' : data[index].activityPlannerId,
                                                      'activityPlannerName' : data[index].activityPlannerName,
                                                      'activityPlannerDescription' : data[index].activityPlannerDescription,
                                                      'activityPlannerOrder' : data[index].activityPlannerOrder,
                                                      'activityPlannerStatus' : data[index].activityPlannerStatus,
                                                      'fkSubjectId' : data[index].fkSubjectId,
                                                      'activityPlannerImage' : data[index].activityPlannerImage,
                                                      'activityPlannerCompletionStatus' : data[index].activityPlannerCompletionStatus,
                                                      'safeUrl' : this.safeUrl,
                                                      'selectedState' : false,
                                                      'activityActiveStatus' : data[index].activityActiveStatus
                                                    } );
      }

      this.allActivitiesList.forEach( (value) => {

        value.collapsedState = false;
        
        this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
          data=>{
            this.unsafeUrl = URL.createObjectURL(data);
            this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);

            value.safeUrl = this.safeUrl;
          });
      }); 

      this.allActivities = true;

      // this.newActivitiesList = [];

      // this.allActivitiesList.forEach((value)=>{
      //   if(value.activityPlannerCompletionStatus === false){
      //     this.newActivitiesList.push(value);
      //   }
      // });

      // this.completedActivitiesList = [];

      // this.allActivitiesList.forEach((value)=>{
      //   if(value.activityPlannerCompletionStatus === true){
      //     this.completedActivitiesList.push(value);
      //   }
      // });

      });
      
}

onChange(event : any){ 

  this.value1 = this.selectedOption;

  if(this.value1 === "1"){
    this.showNewActivities();
  }else if(this.value1 === "2"){
    this.showAllActivities();
  } else if(this.value1 === "3"){
    this.showCompletedActivities();
  } else if(this.value1 === "4"){
    this.showActiveActivities();
  }

}


select(id : string){
  this.selectedId = id;

  this.allActivitiesList.forEach((value)=>{
     if(value.activityPlannerId === id){
      value.selectedState = true;
     }else {
      value.selectedState = false;
     }
  });

  this.newActivitiesList.forEach((value)=>{
    if(value.activityPlannerId === id){
     value.selectedState = true;
    }else {
     value.selectedState = false;
    }
 });

 this.completedActivitiesList.forEach((value)=>{
  if(value.activityPlannerId === id){
   value.selectedState = true;
  }else {
   value.selectedState = false;
  }
});
}

valueChange(value : any){

  this.value1 = value;

  if(this.value1 === "1"){
    this.showNewActivities();
  }else if(this.value1 === "2"){
    this.showAllActivities();
  } else if(this.value1 === "3"){
    this.showCompletedActivities();
  } else if(this.value1 === "4"){
    this.showActiveActivities();
  }

}

collapseActivityCardContent(id : string){
      
  this.allActivitiesList.forEach((value)=>{
    if(value.activityPlannerId === id){

      if(value.collapsedState === true){
        value.collapsedState = false
      } else if(value.collapsedState === false){
        value.collapsedState = true
      }

    }
  });
}

collapseActivityCardContent2(id : string){
      
  this.newActivitiesList.forEach((value)=>{

    
    if(value.activityPlannerId === id){

      if(value.collapsedState === true){
        value.collapsedState = false
      } else if(value.collapsedState === false){
        value.collapsedState = true
      }

    }
  });
}

collapseActivityCardContent3(id : string){
      
  this.completedActivitiesList.forEach((value)=>{

    
    if(value.activityPlannerId === id){

      if(value.collapsedState === true){
        value.collapsedState = false
      } else if(value.collapsedState === false){
        value.collapsedState = true
      }

    }
  });
}

collapseActivityCardContent4(id : string){
      
  this.activeActivitiesList.forEach((value)=>{

    
    if(value.activityPlannerId === id){

      if(value.collapsedState === true){
        value.collapsedState = false
      } else if(value.collapsedState === false){
        value.collapsedState = true
      }

    }
  });
}

swap(){

  if(this.selectedId === 'null'){
    this.toaster.error('Select Activity to Swap');
  } else {

    this.passEntry.emit(this.selectedId);
    this.activeModal.close();

  }
  
}

showCompletedActivities(){

  this.completedActivitiesList.pop();
  this.completedActivitiesList = [];

  this.allActivitiesList.forEach((value)=>{
    if(value.activityPlannerCompletionStatus === true){
      this.completedActivitiesList.push(value);
    }
  });

  this.activeActivities = false;
  this.newActivities = false;
  this.allActivities = false;
  this.completedActivities = true;

}

showAllActivities(){

  this.activeActivities = false;
  this.newActivities = false;
  this.completedActivities = false;
  this.allActivities = true;
}

showNewActivities(){

  this.newActivitiesList.pop();
  this.newActivitiesList = [];

  this.allActivitiesList.forEach((value)=>{
    if(value.activityPlannerCompletionStatus === false && value.activityActiveStatus === false){
      this.newActivitiesList.push(value);
    }
  });

  this.activeActivities = false;
  this.allActivities = false;
  this.completedActivities = false;
  this.newActivities = true;

}

showActiveActivities(){

  this.activeActivitiesList.pop();
  this.activeActivitiesList = [];

  this.allActivitiesList.forEach((value)=>{
    if(value.activityActiveStatus === true){
      this.activeActivitiesList.push(value);
    }
  });

  this.allActivities = false;
  this.completedActivities = false;
  this.newActivities = false;
  this.activeActivities = true;


}

closeUpdateModal(){
  this.activeModal.close();
}
}
