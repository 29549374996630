import { BotUser } from "./bot-user";

export class BotCustomerUser {
  botUserId: any;
  botUserStatus: any;
  fkOfficeId: any;
  name: any;
  phone: any;
  userIdentifier: any;
  fkBotLangId: any;
  gender: any;
  alternatePhone: any;
  customVar1: any;
  customVar2: any;
  convoMode: any;
  fkUroIdManualChat: any;
  users: BotCustomerUser[] = [];
  agents: BotUser[] = [];
  assignedAgentName: any;
  creationTimestamp: any;
  creationTimestampFormatted: any;
  fromDateTime: any;
  toDateTime: any;
  optInVar: any;
}
