// Angular
import {Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
// Layout
// Auth
@Component({
	selector: 'zui-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();

	/**
	 * Component constructor
	 *
	 * @param el: ElementRef
	 * @param render: Renderer2
	 * @param translationService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
