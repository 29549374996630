import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms'; 
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RolesServiceService } from '../services/role/roles-service.service';
import { OfficesService } from '../services/office/offices.service';
import { AddRoleComponent } from '../auth/add-role/add-role.component';
import { AddOfficeComponent } from '../views/add-office/add-office.component';
import { AddUserComponent } from '../auth/add-user/add-user.component';
import { SuperAdminUserService } from '../services/user/super-admin-user.service';
import { SharedDataService } from '../services/sharedData.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr'; 
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Role, Roles } from '../models/role';
import { Office, Offices } from '../models/office';
import { User } from '../models/user';
import { TimeoutScreenComponent } from '../auth/timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DestroyService } from '../services/destroyService';
import {takeUntil} from 'rxjs/operators';
import { LogoutService } from '../service/loginLogout/logout.service';
import { SkillServiceService } from '../service/skill-service.service';
import { Skill } from '../courseManagementClasses/skill';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddParentSkillComponent } from '../course-management/skill/add-parent-skill/add-parent-skill.component';
import { EditSkillComponent } from '../course-management/skill/edit-skill/edit-skill.component';
import {GlobalClass} from '../global/global-class';
import { OfficeAccessControlsComponent } from '../views/office-access-controls/office-access-controls.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FileServiceService } from '../service/file-service.service';
import { LogoutWarningModalComponent } from '../logout/logout-warning-modal/logout-warning-modal.component';
import { Settings } from '../models/settings';
import { SettingsService } from '../services/settings/settings.service';
import { AddSettingsComponent } from '../views/settings/add-settings/add-settings.component';
import { EditSettingsComponent } from '../views/settings/edit-settings/edit-settings.component';
import { LinkDefinitionService } from '../services/linkDefinition/link-definition.service';
import { AddLinkDefinitionsComponent } from '../views/linkDefinitions/add-link-definitions/add-link-definitions.component';
import { AddOfficeLinkDefinitionsComponent } from '../views/officeLinkDefinitions/add-office-link-definitions/add-office-link-definitions.component';
import { EditLinkDefinitionsComponent } from '../views/linkDefinitions/edit-link-definitions/edit-link-definitions.component';
import { EditOfficeLinkDefinitionsComponent } from '../views/officeLinkDefinitions/edit-office-link-definitions/edit-office-link-definitions.component';
import { FormConfiguation } from '../models/form-configuation';
import { FormConfigurationService } from '../services/FormConfiguration/form-configuration.service';
import { AddFormConfigComponent } from '../views/formConfig/add-form-config/add-form-config.component';
import { EditFormConfigComponent } from '../views/formConfig/edit-form-config/edit-form-config.component';
import { SubOfficesComponent } from '../views/org/sub-offices/sub-offices.component';


@Component({
  selector: 'app-superadmindashboard',
  templateUrl: './superadmindashboard.component.html',
  styleUrls: ['./superadmindashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class SuperadmindashboardComponent extends DestroyService implements OnInit{
  roles = new Roles();
  offices = new Offices();
  superAdminUsers: any;
  closeResult = '';
  isReadonly = false;
  userName = '';
  rolesLoading = true;
  officesLoading = true;
  usersLoading = true;
  adminUserLoader = false;
  roleLoader = false;
  roleLoaderMessage = '';
  userLoaderMessage = '';
  officeLoaderMessage = '';
  officeLoader = false; 
  roleOptions: any = {};
  officeOptions: any = {};
  officeAdminOptions: any = {};
  rolesExists = false;
  officesExists = false;
  officeAdminsExists = false;
  commaSep = '';
  safeUrl : any;
  unsafeUrl : any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  public screenWidth: any;
  public screenHeight: any;

  linkDefinitions : any[] = [{
    activeStatus : '',
feature:  '',
id:  '',
link:  '',
roleName:  '',
subFeature: ''
  }]

  uroLinkDefinitions : any[] = [{
    activeStatus:'',
displayName: '',
displayStatus: '',
fkLinkSecurityDefinitionId: '',
id: '',
officeId: '',
officeName:''
  }]

  modalRefs: BsModalRef[] = [];

  skills : Skill[] = [];
  settings : Settings[] = [];
  formConfigs : FormConfiguation[] = [];
  navbarTitle : any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  loggedInUser: any;
  
  constructor(public router: Router, private roleService: RolesServiceService,
    private settingService : SettingsService,private linkDefinitionService : LinkDefinitionService,
    private officesService: OfficesService, private modalService: BsModalService,
    public bsModalRef: BsModalRef, private formBuilder: FormBuilder,
    private formConfigService : FormConfigurationService,
    private superAdminUserService: SuperAdminUserService,
    private sharedDataService: SharedDataService,
    private spinner: NgxSpinnerService, private toaster: ToastrService,
    private bnIdle: BnNgIdleService, private logoutService : LogoutService,
    private skillService : SkillServiceService,private ngbModalService: NgbModal,
    private sanitizer : DomSanitizer,
              private imageService : FileServiceService 
    ) {

      super();
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/roles').then(rolesRes => {
      this.roles.roles = rolesRes;
      this.rolesLoading = false;
      this.rolesExists = true;
    });

    this.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
      
      this.offices.offices = officesRes;

      this.officesLoading = false;
      this.officesExists = true;
    });

    const officeId = null;
    this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/users/' + officeId).then(usersRes => {
      this.superAdminUsers = usersRes;
      if (usersRes.length > 0) {
        this.commaSep = ',';
      }

      this.usersLoading = false;
      this.officeAdminsExists = true;
    });

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    const modalCount = this.modalService.getModalsCount();
    this.modalService._hideModal(modalCount);
  }

  openModalForRoleAdd(): void {
    this.config.class = 'modal-md';
    this.bsModalRef = this.modalService.show(AddRoleComponent, this.config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      self.addNewRoles(res.data);
    });
  }

  openModalForOfficeAdd(): void {
    this.config.class = 'modal-md';
    this.bsModalRef = this.modalService.show(AddOfficeComponent, this.config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      self.addNewOffice(res.data, res.data.image);
    });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
  openModalForOrgSubOfficeAdd(orgOfficeId : any): void {
    this.config.class = 'modal-md';
    this.bsModalRef = this.modalService.show(AddOfficeComponent, this.config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      self.addNewOrgSubOffice(res.data, res.data.image, orgOfficeId);
    });
  }


  startUserLoading() {
    this.adminUserLoader ? this.spinner.show() : this.spinner.hide();
  }
  startRoleLoading() {
    this.roleLoader ? this.spinner.show() : this.spinner.hide();
  }
  startOfficeLoading() {
    this.officeLoader ? this.spinner.show() : this.spinner.hide();
  } 

  openModalForOfficeUserAdd(): void {
    if (this.offices.offices.length > 1) {
      this.config.class = 'modal-lg';
      const data = { source: 'SUPER_ADMIN' };
      const initialState = {
        sourceObject: data,
        config: this.config
      };
      this.bsModalRef = this.modalService.show(AddUserComponent, {
        class: 'modal-lg', backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false, initialState
      });
      this.bsModalRef.content.closeBtnName = 'Close';
      this.modalRefs.push(this.bsModalRef);
      const self = this;
      this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
        this.addNewOfficeAdmin(res.data);
      });
    } else {
      this.toaster.info('Please add office before create office admin');
    }
  }
  addNewRoles(role: any): void {
    const self = this;
    this.roleLoader = true;
    this.startRoleLoading();
    this.roleLoaderMessage = 'Adding new Role';
    this.roleService.createRole(environment.apiUrl + 'admin/add/role', role.name, role.dashboard, role.displayName).then(addRoleRes => {
      self.roleService.getAllRoles(environment.apiUrl + 'admin/get/roles').then(rolesRes => {
        self.roles.roles = rolesRes;
        this.toaster.success("Successfully Added New Role");
        this.roleLoader = false;
        this.startRoleLoading();
      }).catch((err) => {
        this.toaster.error(err.error.message);
        this.roleLoader = false;
        this.startRoleLoading(); 
      });
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.roleLoader = false;
      this.startRoleLoading();
    });
  }

  editRoleInDB(role: any, rowIndex: number): void {
    
    const self = this;
    this.roleLoader = true;
    this.startRoleLoading();
    this.roleLoaderMessage = 'Updating Role Details'
    this.roleService.updateRoleName(environment.apiUrl + 'admin/role/edit?' + 'roleId=' +
      role.id + '&roleName=' + role.name + '&dashboard=' + role.dashboard).then(addRoleRes => {
        self.roleService.getAllRoles(environment.apiUrl + 'admin/get/roles').then(rolesRes => {
          this.roles = new Roles();
          for (let i = 0; i < 5; i++) {
            this.roles.roles.push(rolesRes[i]);
          }
          // self.roles.roles = rolesRes;
          this.toaster.success("Role updated successfully");
          this.roleLoader = false;
        this.startRoleLoading();
        });
      }).catch((err) => {
        this.toaster.error(err.error.message);
        this.roleLoader = false;
        this.startRoleLoading();
      }).catch((err) => {
        this.toaster.error(err.error.message);
        this.roleLoader = false;
        this.startRoleLoading();
      });
  }

  editRole(role: Role, rowIndex: number): void {
    this.config.class = 'modal-md';
    const initialState = {
      roleObject: role
    };
    this.bsModalRef = this.modalService.show(AddRoleComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      self.editRoleInDB(res.data, rowIndex);
    });
  }

  getRowNumber(index: number, role = null): number | null {
    if (role !== null && (role?.[`roleId`] === null || role?.[`id`] === null)) {
      return null;
    }
    return ++index;
  }

  addNewOffice(info: any, logo: File): void {
    const self = this;
    const infoDtls = [
      info[`location`],
      info[`name`],
      info[`bgcolor`],
      info[`theme`],
      info['type'],
      info['leadConversionType']
    ];

    const data = {
      info: infoDtls,
      logo: logo as File
    };
    this.officeLoader = true;
    this.startOfficeLoading();
    this.officeLoaderMessage = 'Adding new Office';
    //xoadd
    
    this.officesService.createOffice(environment.apiUrl + 'admin/add/office', toFormData(data)).then(addOfficeRes => {
      self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
        self.offices.offices = officesRes;
        this.toaster.success("Office Added Successfully");
        this.officeLoader = false;
        this.startOfficeLoading();
      });
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.officeLoader = false;
        this.startOfficeLoading();
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.roleLoader = false;
      this.startRoleLoading();
    });
  }

  addNewOrgSubOffice(info: any, logo: File, orgOfficeId : any): void {
    const self = this;
    const infoDtls = [
      info[`location`],
      info[`name`],
      info[`bgcolor`],
      info[`theme`],
      info['type']
    ];

    const data = {
      info: infoDtls,
      logo: logo as File
    };
    this.officeLoader = true;
    this.startOfficeLoading();
    this.officeLoaderMessage = 'Adding new Office';
    this.officesService.createOffice(environment.apiUrl + 'admin/add/orgSubOffice/'+ orgOfficeId , toFormData(data)).then(addOfficeRes => {
      self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
        self.offices.offices = officesRes;
        this.toaster.success("Office Added Successfully");
        this.officeLoader = false;
        this.startOfficeLoading();
      });
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.officeLoader = false;
        this.startOfficeLoading();
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.roleLoader = false;
      this.startRoleLoading();
    });
  }
 
  addNewOfficeAdmin(adminInfo: any): void {
    const self = this;
    const newOfficeAdmin = {
      userId: adminInfo.userId,
      firstName: adminInfo.first_name,
      lastName: adminInfo.last_name,
      email: adminInfo.email,
      phoneNumber: adminInfo.phone_number,
      gender: adminInfo.gender,
      dateOfBirth: adminInfo.date_of_birth,
      role: this.roles.roles.filter(role => role.roleName=== 'OFFICE_ADMIN')[0].roleId,
      office: adminInfo.office,
      address: {
        addressLine1: adminInfo.addressLine1,
        addressLine2: adminInfo.addressLine2,
        city: adminInfo.city,
        country: adminInfo.country,
        state: adminInfo.state,
        zipCode: adminInfo.zipCode
      }
    };
    this.adminUserLoader = true;
    this.startUserLoading();
    this.userLoaderMessage = newOfficeAdmin.userId.length <1 ? 'Adding new User' : 'Updating User';
    
    this.superAdminUserService.createOfficeAdmin(environment.apiUrl + 'admin/add/office/admin', newOfficeAdmin).then(addOfficeAdminRes => {
      self.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/users/null').then(officeAdmins => {
        self.superAdminUsers = officeAdmins;
        this.adminUserLoader = false;
        this.startUserLoading;
        this.toaster.success( newOfficeAdmin.userId.length <1 ? 'Successfully Added New User' : 'Successfully Updated UserDetails');
      })
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.adminUserLoader = false;
      this.startUserLoading();
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.adminUserLoader = false;
      this.startUserLoading();
    });
  }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight; 

    this.roleOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false,
    };
    this.officeOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false

    };
    this.officeAdminOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.getAllSkills();
    this.getAllSettings();
    this.getAllLinkDefinitions();
    this.getAllUroLinkDefinitions();
    this.getAllFormConfigs();
   
  }

  getAllFormConfigs(){
    this.formConfigService.getAllFormConfigs(environment.apiUrl + "formConfig/getAllFormConfigs").then(
      data=>{
        this.formConfigs = data;
      });
  }
 
  adddFormConfig(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddFormConfigComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    offices : this.offices.offices,
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllFormConfigs();
    });  
  }

  editFormConfig(formConfig : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFormConfigComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      formConfig : formConfig
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllFormConfigs();
    });  
  }

  getAllUroLinkDefinitions(){
    this.linkDefinitionService.getAllUroLinkDefinitions(environment.apiUrl + "linkDefinition/getAllUroLinkDefinitions").then(
      data=>{        
        this.uroLinkDefinitions = data;
      });
  }

  getAllLinkDefinitions(){
    this.linkDefinitionService.getAllLinkDefinitions(environment.apiUrl + "linkDefinition/getAllLinkDefinitions").then(
      data=>{
        this.linkDefinitions = data;
      });
  }
  
  AddOfficeLinkDefinitions(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddOfficeLinkDefinitionsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    offices : this.offices.offices,
    linkDefs : this.linkDefinitions
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllUroLinkDefinitions();
    });  
  }

  EditOfficeLinkDefinitions(officeLinkDef : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditOfficeLinkDefinitionsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    linkDef : officeLinkDef
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllUroLinkDefinitions();
    });  
  }

  EditLinkDefinitions(linkDef : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditLinkDefinitionsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      linkDef : linkDef
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllLinkDefinitions();
    });  
  }


  AddLinkDefinitions(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddLinkDefinitionsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    roles : this.roles.roles
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllLinkDefinitions();
    });  
  }

  addSetting(){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddSettingsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    offices : this.offices
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllSettings();
    });  
  }


  editSetting(setting : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditSettingsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
    setting : setting
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAllSettings();
    });  
  }

  updateSettingStatus(id : any){

    this.settingService.updateStatus(environment.apiUrl + "settings/statusUpdate/" + id).then(
      data=>{
        this.settings.forEach((val)=>{
          if(val.id === id){
            if(val.activeStatus === true){
              val.activeStatus = false;
            }else if(val.activeStatus === false){
              val.activeStatus = true;
            }
          }
        });
      });

  }

  updateLinkDefinitionStatus(id : any){

    this.linkDefinitionService.updateStatus(environment.apiUrl + "linkDefinition/linkDefinitionStatusUpdate/" + id).then(
      data=>{
        this.linkDefinitions.forEach((val)=>{
          if(val.id === id){
            if(val.activeStatus === true){
              val.activeStatus = false;
            }else if(val.activeStatus === false){
              val.activeStatus = true;
            }
          }
        });
      });

  }

  updateUroLinkDefinitionStatus(id : any){

    this.linkDefinitionService.updateStatus(environment.apiUrl + "linkDefinition/uroLinkDefinitionStatusUpdate/" + id).then(
      data=>{
        this.uroLinkDefinitions.forEach((val)=>{
          if(val.id === id){
            if(val.activeStatus === true){
              val.activeStatus = false;
            }else if(val.activeStatus === false){
              val.activeStatus = true;
            }
          }
        });
      });

  }

  getAllSettings(){
    this.settingService.getAllSettings(environment.apiUrl + "settings/getAll").then(
      data=>{
        this.settings = data;        
      });
  }

  logOut(): void {

    localStorage.removeItem('auth-token');
    localStorage.clear();
    window.location.href=`${environment.authUrl}/logout`;
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  updateRoleStatus(value: any): void {
    this.roleLoader = true;
    this.startRoleLoading();
    this.roleLoaderMessage = 'Updating Role Status ';
    this.roleService.updateRoleStatus(environment.apiUrl + 'admin/role/update?' + 'roleId=' +
      value.roleId + '&status=' + !value.activeStatus).then(response => {
        this.roleService.getAllRoles(environment.apiUrl + 'admin/get/roles').then(rolesRes => {
          this.roles.roles = rolesRes;
          this.toaster.success("Role status updated Successfully"); 
          this.roleLoader = false;
        this.startRoleLoading();
        }).catch((err) => {
          this.toaster.error(err.error.message);
          this.roleLoader = false;
          this.startRoleLoading();
        }).catch((err) => {
          this.toaster.error(err.error.message);
          this.roleLoader = false;
          this.startRoleLoading();
        });
      });
  }


  updateRoleDisplayStatus(id: any, type : any): void {
    this.roleService.updateRoleStatus(environment.apiUrl + 'admin/role/updateRoleDisplayStatus/' + type + "/" + id).then(response => {
     this.toaster.success("Display status changed successfully")
      this.roles.roles.forEach((role)=>{
        if(id===role.roleId){
          if(type==="Biz_Office"){
          role.bizOfficeDisplayStatus = !role.bizOfficeDisplayStatus;
          }else if(type==="Edu_Office"){
          role.eduOfficeDisplayStatus = !role.eduOfficeDisplayStatus;
          }else if(type==="Organisation"){
          role.orgDisplayStatus = !role.orgDisplayStatus;
          }
        }
      })
      });
  }

  updateOfficeStatus(value: any): void {
    const self = this;
    this.officeLoader = true;
    this.startOfficeLoading();
    this.officeLoaderMessage = 'Updating Office Status';
    this.officesService.updateOfficeStatus(environment.apiUrl + 'admin/office/status/update?' + 'officeId='
      + value.id + '&status=' + !value.activeStatus).then(response => {
        self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
          self.offices.offices = officesRes;
          this.rebindDataTable();
          this.toaster.success("Office status changed Successfully");
          this.officeLoader = false;
          this.startOfficeLoading();
        }).catch((err) => {
          this.toaster.error(err.error.message);
          this.officeLoader = false;
          this.startOfficeLoading();
      })
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.officeLoader= false;
      this.startOfficeLoading();
    });
  }

  editOffice(office: any): void {
    this.config.class = 'modal-md';
    const initialState = {
      officeObject: office
    };
    
    this.bsModalRef = this.modalService.show(AddOfficeComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      
      self.editOfficeInDB(res.data);
    });
  }

  updateUserStatus(officeAdmin: any, value: string) {
    const self = this;
    this.adminUserLoader = true;
    this.startUserLoading();
    this.userLoaderMessage = 'Updating User Status'
    this.superAdminUserService.updateUserStatus(environment.apiUrl + 'user/update/status?userId=' + officeAdmin.userId + '&value=' + value + '&status=' + !officeAdmin.active).then(userResponse => {
      self.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'admin/all/users/null').then(officeAdmins => {
        this.superAdminUsers = officeAdmins;
        this.toaster.success("UserStatus Updated Successfully")
        this.adminUserLoader = false;
        this.startUserLoading();
      })
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.adminUserLoader = false;
      this.startUserLoading();
    });

  }

  editOfficeAdmin(officeAdmin: any): void { 
    this.config.class = 'modal-lg';
    const data = { source: 'SUPER_ADMIN' };
    const initialState = {
      officeAdminState: officeAdmin,
      sourceObject: data,
      config: this.config
    };
    this.bsModalRef = this.modalService.show(AddUserComponent, {
      class: 'modal-lg', backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false, initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      this.addNewOfficeAdmin(res.data);
    });
  }

  editOfficeInDB(office: any) {
    this.officeLoader = true;
    this.startOfficeLoading();
    this.officeLoaderMessage = 'Updating Office Details';
    const officeObj = new Office();
    officeObj.id = office.id;
    officeObj.logo = office.image;
    officeObj.officeLocation = office.location;
    officeObj.officeName = office.name;
    officeObj.theme = office.theme;
    officeObj.activeStatus = office.activeStatus;
    officeObj.leadConversionType = office.leadConversionType;
    officeObj.type = office.type;
    const self = this;

    // my edit
    const off =[
      officeObj.id,
      officeObj.officeLocation,
      officeObj.officeName,
      officeObj.leadConversionType,
      officeObj.type,
      officeObj.theme,
    ]
    
    let logo0: File = office.image;

    const data = {
      info : off,
      logo : logo0 as File
    }

    if(data.logo instanceof File){
      // this.toaster.success("Is a File !");
      this.officesService.updateOffice(environment.apiUrl + 'admin/office/update2', toFormData(data)).then(updateOffice => {
        self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
          self.offices.offices = officesRes;
          this.toaster.success("Office updated Successfully");
          this.officeLoader = false;
          this.startOfficeLoading();
        });
      }).catch((err) => {
        this.toaster.error(err.error.message);
        this.officeLoader = false;
        this.startOfficeLoading();
      }).catch((err) => {
        this.toaster.error(err.error.message);
        this.officeLoader = false;
        this.startOfficeLoading();
      });
    }else{
      // this.toaster.error("Not a FIle");
      this.officesService.updateOffice(environment.apiUrl + 'admin/office/update', officeObj).then(updateOffice => {
      self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
        self.offices.offices = officesRes;
        this.toaster.success("Office updated Successfully");
        this.officeLoader = false;
        this.startOfficeLoading();
      });
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.officeLoader = false;
      this.startOfficeLoading();
    }).catch((err) => {
      this.toaster.error(err.error.message);
      this.officeLoader = false;
      this.startOfficeLoading();
    });
    }
    
    //xoupdate    

    // this.officesService.updateOffice(environment.apiUrl + 'admin/office/update', officeObj).then(updateOffice => {
    //   self.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
    //     self.offices.offices = officesRes;
    //     this.toaster.success("Office updated Successfully");
    //     this.officeLoader = false;
    //     this.startOfficeLoading();
    //   });
    // }).catch((err) => {
    //   this.toaster.error(err.error.message);
    //   this.officeLoader = false;
    //   this.startOfficeLoading();
    // }).catch((err) => {
    //   this.toaster.error(err.error.message);
    //   this.officeLoader = false;
    //   this.startOfficeLoading();
    // });
  }

//   updateOA_ParentMentorStatus(officeId : any){
// this.officesService.updateOA_ParentMentorStatus(officeId).subscribe(
//   res=>{
// this.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
//   this.offices.offices = officesRes;
// });
//   }
// )
//   }

//   updateOA_SubAdminStatus(officeId : any){
// this.officesService.updateOA_SubAdminStatus(officeId).subscribe(
//   res=>{
//     this.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(officesRes => {
//       this.offices.offices = officesRes;
//     });
//   }
// )
//   }

  reloadPage() {
    window.location.reload();
  }

  goToResultsDashboard(){
    // this.router.navigate(['/dashboard/results-dashboard']);
    this.router.navigate(['/dashboard/super-skill-report']);
  }

  getAllSkills(){

    this.skillService.getAllSkills(environment.apiUrl + 'skill/getAll').then(
      res=>{
        this.skills = res;

        this.skills.forEach((value)=>{

          this.imageService.getSkillImage( value.skillImage ).subscribe(
            data=>{
              this.unsafeUrl = URL.createObjectURL(data);
              this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
  
              value.safeUrl = this.safeUrl;

              this.skills.forEach((valueC)=>{
                if(value.parentSkillId !== null && value.parentSkillId === valueC.skillId){
                  value.parentSkillName = valueC.skillName;
                }
              });
            });
        })
      });
  }

  updateSkillStatus(skillId : string){
this.skillService.updateSkillStatus(environment.apiUrl + 'skill/updateStatus/' + skillId).then(
  data=>{
this.skills.forEach((value)=>{
  if(value.skillId === skillId){
    if(value.skillStatus===true){
      value.skillStatus=false;
    }else if(value.skillStatus===false){
      value.skillStatus=true;
    }
  }
});
  });
  }

  
  addParentSkill(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddParentSkillComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      skillId : null
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Skill) => {
     this.getAllSkills();
    }); 
  }

  editSkill(skillId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditSkillComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      skillId : skillId
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Skill) => {
      this.getAllSkills();
    }); 
  }

  addChildSkill(skillId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddParentSkillComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      skillId : skillId
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Skill) => {
     this.getAllSkills();
    }); 
  }

  viewSubOffices(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SubOfficesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Skill) => {
    }); 
  }

  goToSuperCourseAdminDashboard(){
    this.router.navigate(['dashboard/super-course-admin/offices']);
  }

  accessControls(officeId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( OfficeAccessControlsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      officeId : officeId
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      
    }); 
  }

  rebindDataTable() {
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   // Destroy the table first
    //   dtInstance.destroy();
    //   // Call the dtTrigger to rerender again
    //   this.dtTrigger.next();
    // });
}
  
}


export function toFormData(formValue: any): FormData {
  const formData = new FormData();
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }  
  return formData;
}
