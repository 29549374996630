<div class="modal-header">
    <h4 class="modal-title pull-left">Create Settings</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="add()" #aplnrForm="ngForm"> 

      
      <div class="form-group row">
       
        <div class="col-md-6">
          <label for="roleName">Role</label> <br>
              <select class="form-control" [(ngModel)]="linkDef.roleName" id="roleName" name="roleName">
                <option  value="" disabled >Choose Role</option>
                <option  *ngFor="let off of roles"  [ngValue]="off.roleName">{{off.roleName}}</option>
              </select>
          </div>

          <div class="col-md-6">
            <label for="key"> Link</label>
            <input #key="ngModel" required placeholder="Enter Link" required class="form-control" type="text" name="key"  id="key" [(ngModel)]="linkDef.link">
            <div class="alert alert-danger" *ngIf="key.touched && !key.valid">Link is required</div>
         </div> 
        
      </div>
     

<br>

           <div class="form-group row">
 
       

            <div class="col-md-6">
                <label for="key"> Feature</label>
                <input #key="ngModel" required placeholder="Enter key" required class="form-control" type="text" name="key"  id="key" [(ngModel)]="linkDef.feature">
                <div class="alert alert-danger" *ngIf="key.touched && !key.valid">Feature is required</div>
             </div> 
             
             <div class="col-md-6">
                <label for="value"> Sub Feature</label>
                <input  #value="ngModel" required placeholder="Enter value" required class="form-control" type="text" name="value"  id="value" [(ngModel)]="linkDef.subFeature">
                <div class="alert alert-danger" *ngIf="value.touched && !value.valid">value is required</div>
             </div>
 

   
      </div>

     
     






    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="add(); clicked=true">Save</button>
  </div>