<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Reset Password : {{firstName}} {{lastName}}</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
        <div class="card-body">
    <form (ngSubmit)="onSubmit" #resetForm="ngForm"> 

        <div class="form-group">

        <div class="col-md-12">

            <label class="required" for="email">New Password</label>
            <input #newPassword="ngModel" minlength="4" maxlength="25" required class="form-control"
            type="password" name="x" id="x" [(ngModel)]="npw">
            <div class="alert alert-danger" *ngIf="newPassword.touched && !newPassword.valid">Password is required, minimum length should be 4</div>
            
        </div>
<br>
    <div class="col-md-12">
        
        <label class="required" for="email">Confirm New Password</label>
        <input minlength="4" maxlength="25" #confirmNewPassword="ngModel" required 
        class="form-control" type="password"
         name="y" id="y" [(ngModel)]="cnpw">
        <div class="alert alert-danger" *ngIf="confirmNewPassword.touched && !confirmNewPassword.valid">Confirm new password</div>
        
    </div>

        </div>


    </form>
</div>
</div>
</div>

<div class="modal-footer">
    <button  [disabled]="clicked"  [disabled]="!resetForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Update</button>
    </div>