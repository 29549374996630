import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotSchedule } from "src/app/BOT_CODE/bot_classes/bot-schedule";
import { BotTemplate } from "src/app/BOT_CODE/bot_classes/bot-template";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotScheduleTempVariable } from "src/app/BOT_CODE/bot_classes/bot-schedule-temp-variable";
import { BotUserGroup } from "src/app/BOT_CODE/bot_classes/bot-user-group";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { UploadBotMediaComponent } from "../../bot-media/upload-bot-media/upload-bot-media.component";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";

@Component({
  selector: "app-send-template-message",
  templateUrl: "./send-template-message.component.html",
  styleUrls: ["./send-template-message.component.scss"],
})
export class SendTemplateMessageComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private toastr: ToastrService
  ) {}

  selectedUsers: BotCustomerUser[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botSchedule: BotSchedule = new BotSchedule();
  botTemplates: BotTemplate[] = [];
  botCustomerUser: BotUser = new BotUser();
  searchText: any;
  searchText1: any;
  media: BotMedia[] = [];
  allMedia: BotMedia[] = [];

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  chosenTemplate: BotTemplate = new BotTemplate();
  botUserGroup: BotUserGroup = new BotUserGroup();

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getWhatsappCloudApiTemplatesByOfficeId();
    this.getBotMediaByOfficeId();

    this.botSchedule.fkBotTemplateId = null;
    this.chosenTemplate.tempBody = "Choose a template to see template body";
    this.botSchedule.fkBotMediaIdForTemplateHeader = null;

    if (this.fromParent.context === "Send Template Message To a User") {
      this.botCustomerUser.users = this.fromParent.usersList;
      this.botSchedule.receiverBotUserId = null;
    } else if (
      this.fromParent.context === "Send Template Message To Particular User"
    ) {
      this.botCustomerUser = this.fromParent.receiverBotUser;
      this.botSchedule.receiverBotUserId = this.botCustomerUser.userId;
    }
  }

  chooseTemplate() {
    this.botTemplates.forEach((temp) => {
      if (temp.botTemplateId === this.botSchedule.fkBotTemplateId) {
        this.chosenTemplate.tempVarList = [];

        this.chosenTemplate.botTemplateId = temp.botTemplateId;
        this.chosenTemplate.templateName = temp.templateName;
        this.chosenTemplate.templateDisplayName = temp.templateDisplayName;
        this.chosenTemplate.tempBody = temp.tempBody;
        this.chosenTemplate.tempVarCount = temp.tempVarCount;
        this.chosenTemplate.tempVarList = temp.tempVarList;
        this.chosenTemplate.fkBotTemplateRes = temp.fkBotTemplateRes;
        this.chosenTemplate.fkBotTemplateResId = temp.fkBotTemplateResId;
        this.chosenTemplate.tempHeaderType = temp.tempHeaderType;

        this.filterMedia(this.chosenTemplate.tempHeaderType);

        this.chosenTemplate.fkOfficeId = temp.fkOfficeId;
        this.chosenTemplate.options = temp.options;
        this.chosenTemplate.tempLanguage = temp.tempLanguage;
        this.chosenTemplate.tempLanguageCode = temp.tempLanguageCode;
        this.chosenTemplate.updateUserSessionCurrentQueryId =
          temp.updateUserSessionCurrentQueryId;
        this.botSchedule.fkBotMediaIdForTemplateHeader =
          temp.fkDefaultHeaderBotMediaId;

        if (temp.tempVarCount > 0) {
          if (temp.tempVarList === null || temp.tempVarList === undefined) {
            let count = this.chosenTemplate.tempVarCount + 1;
            for (let i = 1; i < count; i++) {
              let tempVar: BotScheduleTempVariable =
                new BotScheduleTempVariable();
              tempVar.botScheduleTempVarOrder = i;
              this.chosenTemplate.tempVarList.push(tempVar);
            }
          } else {
            if (temp.tempVarList.length === temp.tempVarCount) {
              this.chosenTemplate.tempVarList = temp.tempVarList;
            } else if (temp.tempVarList.length !== temp.tempVarCount) {
              let count = this.chosenTemplate.tempVarCount + 1;
              for (let i = 1; i < count; i++) {
                let tempVar: BotScheduleTempVariable =
                  new BotScheduleTempVariable();
                tempVar.botScheduleTempVarOrder = i;
                this.chosenTemplate.tempVarList.push(tempVar);
              }
            }
          }
        }
      }
    });
  }

  getWhatsappCloudApiTemplatesByOfficeId() {
    this.botOfficeService
      .getWhatsappCloudApiTemplatesByOfficeId(
        environment.apiUrl + "botOffice/getWhatsappCloudApiTemplatesByOfficeId"
      )
      .then((res) => {
        let bTemp: BotTemplate = new BotTemplate();
        bTemp = res;
        this.botTemplates = bTemp.list;
      });
  }

  onSubmit() {
    this.clicked = true;
    this.botSchedule.tempVarList = this.chosenTemplate.tempVarList;
    if (
      this.botSchedule.fkBotTemplateId !== null &&
      this.botSchedule.receiverBotUserId !== null
    ) {
      this.botSchedule.botScheduleType = "template";
      this.botOfficeService
        .createBotSchedule(
          environment.apiUrl + "botOffice/sendTemplateMessageToBotUser",
          this.botSchedule
        )
        .then((res) => {
          if (res === true) {
            this.toastr.success("Template message sent successfully !");
            this.passEntry.emit("success");
            this.activeModal.close();
          } else {
            this.toastr.error("Failed to send Template message !");
            this.passEntry.emit("failure");
            this.activeModal.close();
          }
        });
    } else if (this.botSchedule.fkBotTemplateId === null) {
      this.toastr.error("Choose Template !");
      this.clicked = false;
    } else if (this.botSchedule.receiverBotUserId === null) {
      this.toastr.error("Choose User !");
      this.clicked = false;
    }
  }

  getBotMediaByOfficeId() {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getBotMediaByOfficeId"
      )
      .then((res) => {
        this.allMedia = [];
        let md: BotMedia = new BotMedia();
        md = res;
        this.allMedia = md.list;

        this.filterMedia(this.chosenTemplate.tempHeaderType);
      });
  }

  filterMedia(type: any) {
    this.media = [];
    if (type === "IMAGE" || type === "image" || type === "image with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "png" ||
          med.mediaType === "PNG" ||
          med.mediaType === "jpeg" ||
          med.mediaType === "JPEG" ||
          med.mediaType === "jpg" ||
          med.mediaType === "JPG"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "VIDEO" || type === "video" || type === "video with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "MP4" ||
          med.mediaType === "3gp" ||
          med.mediaType === "3GP"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "audio") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "mpeg" ||
          med.mediaType === "aac" ||
          med.mediaType === "ogg" ||
          med.mediaType === "amr" ||
          med.mediaType === "MP4" ||
          med.mediaType === "MPEG" ||
          med.mediaType === "AAC" ||
          med.mediaType === "OGG" ||
          med.mediaType === "AMR"
        ) {
          this.media.push(med);
        }
      });
    }
    if (
      type === "DOCUMENT" ||
      type === "document" ||
      type === "document with buttons"
    ) {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "pdf" ||
          med.mediaType === "text" ||
          med.mediaType === "xls" ||
          med.mediaType === "xlsx" ||
          med.mediaType === "csv" ||
          med.mediaType === "pptx" ||
          med.mediaType === "doc" ||
          med.mediaType === "docx" ||
          med.mediaType === "PDF" ||
          med.mediaType === "TEXT" ||
          med.mediaType === "XLS" ||
          med.mediaType === "XLSX" ||
          med.mediaType === "CSV" ||
          med.mediaType === "PPTX" ||
          med.mediaType === "DOC" ||
          med.mediaType === "DOCX"
        ) {
          this.media.push(med);
        }
      });
    }
  }

  uploadBotMedia() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Upload New Media",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotMediaByOfficeId();
    });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
