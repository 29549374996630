
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" onclick="history.back()" aria-current="page">Classes</li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Class Dashboard</li>
    </ol>
  </nav>

  <div class="row col-md-12" style="padding-right: 30px;padding-left: 30px;margin-bottom: 1%;">
    <h1 class="sub-header-h1">Class : {{class.name}} </h1>
  </div>

  <div class="row col-md-12">

<div class="col-md-12 col-lg-6">
  <div class="header" [ngStyle]="backgroundStyle">
    <mat-icon onclick="history.back()" style="margin-left: 2%;margin-right: 4%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

    <h3> {{classStudentsActive.length}} Active Students </h3>
      </div>

      <div class="card content mat-elevation-z12">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
      
          <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="33%">
      
            <mat-card class="example-card mat-elevation-z12">

              <div style="text-align: center;border-radius: 50%; margin-top: 9%;">
                <img (click)="addNewStudent()" style="border-radius: 50%;cursor: pointer;" src="/assets/images/icons/plus.png" height="60" width="60" alt="...">
                </div>
                 
                <br>
                               <mat-card-subtitle style="color: black; font-weight: bold;">Add Student</mat-card-subtitle>
                <br>
               
               
            </mat-card>
          
        </div>
      
      
            <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="33%" *ngFor="let student of classStudentsActive">
      
              <mat-card *ngIf="studentsExist === true" class="example-card mat-elevation-z12">

                <div style="text-align: center;border-radius: 50%;">
                  <img style="border-radius: 50%;" [src]="student.safeUrl" height="60" width="60" alt="...">
                  </div>

                  <br>
              
                  <mat-card-subtitle style="color: black; font-weight: bold;">{{student.studentName}}</mat-card-subtitle>
                <div style="width: 100%;text-align: center;">
                  <button *ngIf="student.studentStatus!== null && student.studentStatus" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
                <button *ngIf="student.studentStatus!== null && !student.studentStatus" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="open(confirmActivation)" >Activate</button>
                </div>
                              </mat-card>
            

<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="color: black;" class="modal-body">
    Are you sure to activate {{student.studentName}} ?
    <br>
    <br>
    <div>
      <label class="required" for="description">Notes :</label>
      <textarea maxlength="244" class="form-control" type="text" cols="30" rows="7"
      name="student.notes" id="student.notes" [(ngModel)]="student.notes"></textarea>
      </div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="studentStatusUpdate(student.studentId,student.notes);modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template> 


<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="color: black;" class="modal-body">
    Are you sure to deactivate {{student.studentName}} ?
    <br>
    <br>
    <div>
      <label class="required" for="description">Notes :</label>
      <textarea maxlength="244" class="form-control" type="text" cols="30" rows="7"
      name="student.notes" id="student.notes" [(ngModel)]="student.notes"></textarea>
      </div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="studentStatusUpdate(student.studentId, student.notes);modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>
          </div>
        </div>
      </div>
</div>

<div class="col-md-12 col-lg-6">
  <div class="header" [ngStyle]="backgroundStyle">
    <mat-icon onclick="history.back()" style="margin-left: 2%;margin-right: 4%;  float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

    <h3>Teachers </h3>
      </div>

      <div class="card content mat-elevation-z12">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
      
          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="33%">
      
            <mat-card class="example-card mat-elevation-z12">

              <div style="text-align: center;border-radius: 50%; margin-top: 7%;">
                <img (click)="addNewTeacher()" style="border-radius: 50%;cursor: pointer;" src="/assets/images/icons/plus.png" height="60" width="60" alt="...">
                </div>
                 
                <br>
                               <mat-card-subtitle style="color: black; font-weight: bold;">Add Teacher</mat-card-subtitle>
                <br>
            </mat-card>
          
        </div>
      
      
            <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="50%" fxFlex.md="33%" *ngFor="let teacher of classTeachers">
      
              <mat-card *ngIf="teachersExist === true" class="example-card mat-elevation-z12">

                <div style="text-align: center;border-radius: 50%;">
                  <img style="border-radius: 50%;" [src]="teacher.safeUrl" height="60" width="60" alt="...">
                  </div>

                  <br>
              
                  <mat-card-subtitle style="color: black; font-weight: bold;">{{teacher.teacherName}}</mat-card-subtitle>
                

                  <div style="width: 100%;" *ngIf="teacher.collapsedState === true" >
                    
                    <div class="row col-md-12" style="text-align: left; width: 100%; margin-right: 1%; margin-left: 1%;" *ngFor="let subject of teacher.subjects">
                    <mat-card-subtitle class="col-md-7" style="float: left;color: black;">{{subject.name}}</mat-card-subtitle>
                    <button (click)="open(confirmActivation)" class="btn btn-info col-md-5" style="float: right;">{{subject.subjectStatus ? 'Deactivate' : 'Activate'}}</button>
                    

<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to {{subject.subjectStatus ? 'Deactivate' : 'Activate'}} subject ?
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="teacherStatusUpdate(subject.teacherId);modal.dismiss('Cross click')">{{subject.subjectStatus ? 'Deactivate' : 'Activate'}}</button>
  </div>
</ng-template> 



                  </div>

                      </div>

                      <div style="text-align: center;">
                      <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent(teacher.teacherId)">
                        {{teacher.collapsedState ? 'Close' : 'Subjects'}}</button>
                      </div>
              </mat-card>
            
          </div>
        </div>
      </div>
</div>

<div style="margin-top: 3%;" class="col-md-12 col-lg-12">

  <div class="header" [ngStyle]="backgroundStyle">
    <h3 style="text-align: left;margin: 1%;">{{classStudentsInActive.length}} Inactive Students </h3>
      </div>

      <div class="card content mat-elevation-z12">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
      
            <div fxFlex="16.666%" fxFlex.xs="50%" fxFlex.sm="50%" fxFlex.md="16.666%" *ngFor="let student of classStudentsInActive">
      
              <mat-card *ngIf="studentsExist === true" class="example-card mat-elevation-z12">

                <div style="text-align: center;border-radius: 50%;">
                  <img style="border-radius: 50%;" [src]="student.safeUrl" height="60" width="60" alt="...">
                  </div>

                  <br>
              
                  <mat-card-subtitle style="color: black; font-weight: bold;">{{student.studentName}}</mat-card-subtitle>
                <div style="width: 100%;text-align: center;">
                  <button *ngIf="student.studentStatus!== null && student.studentStatus" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
                <button *ngIf="student.studentStatus!== null && !student.studentStatus" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="open(confirmActivation)" >Activate</button>
                </div>
                              </mat-card>
            

<ng-template #confirmActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="color: black;">
    Are you sure to activate {{student.studentName}} ?
    <br>
    <br>
    <div>
      <label class="required" for="description">Notes :</label>
      <textarea maxlength="244" class="form-control" type="text" cols="30" rows="7"
      name="student.notes" id="student.notes" [(ngModel)]="student.notes"></textarea>
      </div>
      
  </div> 

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="studentStatusUpdate(student.studentId, student.notes);modal.dismiss('Cross click')">Activate</button>
  </div>
</ng-template>

<ng-template #confirmDeactivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure to deactivate {{student.studentName}} ?
    <br>
    <br>
    <div>
      <label class="required" for="description">Notes :</label>
      <textarea maxlength="244" class="form-control" type="text" cols="30" rows="7"
      name="student.notes" id="student.notes" [(ngModel)]="student.notes"></textarea>
      </div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="studentStatusUpdate(student.studentId, student.notes);modal.dismiss('Cross click')">Deactivate</button>
  </div>
</ng-template>
          </div>

        </div>
      </div>
</div>

  </div>


  <br>
  <br>
  <br>