import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private http: HttpClient) { }

  doLogin(avenue: string, email: string, password: string, token: string): Promise<any> {
    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return new Promise((resolve, reject) => {
      self.http.post(avenue, { email, password }, {headers})
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  doLoginWithToken(url: string, token: string): Promise<any> {
    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return new Promise((resolve, reject) => {
      self.http.post(url, { }, {headers})
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }
  exchangeCodeForToken(url: string, code: string): Promise<any> {
    const self = this;
    const body = new HttpParams()
      .set('code',code)
      .set('authorization_code', 'write')
      .set('grant_type', 'authorization_code');
      const headers = {
        'Authorization': 'Basic ' + btoa('wudi-app:course123'),
      }
    return new Promise((resolve, reject) => {
      self.http.post(url,body,{headers})
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  getToken(avenue: string, email: string, password: string): Promise<any> {
    const self = this;
    const body = new HttpParams()
      .set('username',email)
      .set('password', password)
      .set('grant_type', 'password');

      const headers = {
        'Authorization': 'Basic ' + btoa('wudi-app:course123'),
      }
    return new Promise((resolve, reject) => {
      self.http.post(avenue,body,{headers})
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }
}
