import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { Role } from 'src/app/models/role';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-form-active',
  templateUrl: './create-form-active.component.html',
  styleUrls: ['./create-form-active.component.scss']
})
export class CreateFormActiveComponent implements OnInit {

  clicked = false;
  formActive : FormActive = new FormActive();
  feedbackForm : FeedbackForm = new FeedbackForm();
  loggedInUser : any; 
  roles : Role[] = [];
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private roleService : RolesServiceService,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 

    this.formActive.fkRoleId = "";
    
    this.feedbackForm = this.fromParent.form;
    this.formActive.fkFormId = this.feedbackForm.id;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    this.getRoles();

  }

  getRoles(){
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/bizOfficeRoles').then(
      rolesRes => {
      this.roles = rolesRes;
    }); 
  }

  onSubmit(){
    //authorized
    this.formService.createFormActive(environment.apiUrl + "formActive/createFormActive", this.formActive).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Form activated successfully');
        this.passEntry.emit('success');
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}