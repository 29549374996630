import { FeedbackQuestion } from "./feedback-question";

export class FeedbackForm {

    id:any;
	activeStatus:any;
    name:any
	type:any
	fkOfficeId:any;
	formImage:any;

	feedbackQuestions : FeedbackQuestion[] = [];
	feedbackQuestionsNew : FeedbackQuestion[] = [];
	
	expiryStatus : any;

	formActiveResponseMessage : any;
	feedbackQuestionsForAnalytics : FeedbackQuestion[] = [];

	botScheduleId:any;
	botScheduleDetailsId:any;

}
