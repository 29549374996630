<!-- <nav class="navbar navbar-expand-lg navbar-light bg-dark">
  <a class="navbar-brand" href="#">
    <img src="/assets/images/courseManagementSystem.svg" width="30" height="30" alt="" loading="lazy">
  </a>
  <a class="navbar-brand text-white" href="#">Course Management</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
 
  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="this.screenWidth > 768">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
      </li>
    </ul> 
 
    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item dropdown center-align" dropdown placement="bottom right">
        <a class="nav-link text-white" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false" dropdownToggle (click)="false">
          <img alt="" height="20" loading="lazy" src="/assets/images/icons/settings.svg" width="25">
          {{userName}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item" style="cursor:pointer" (click)="changePassword();"><i class="fa fa-shield"
              style="padding-right: 0.7rem;"></i>Change
            Password</a>
          <a class='dropdown-item' style="cursor:pointer;" (click)="logOut();">
            <i class="fa fa-power-off" style="padding-right: 0.7rem;"></i>Logout</a>
        </div>
      </li>
    </ul>
  </div>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="this.screenWidth <= 768">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
      </li>
    </ul>

    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item center-align" placement="bottom right">
        <a class="nav-link text-white" href="#" role="button" aria-haspopup="true" aria-expanded="false"
          (click)="false"><i class="fa fa-user" style="padding-right: 0.7rem;"></i>
          {{userName}}
        </a>
      </li>
      <li class="nav-item center-align">
        <a class="nav-link text-white" data-toggle="dropdown" style="cursor:pointer" (click)="changePassword();"><i
            class="fa fa-shield" style="padding-right: 0.7rem;"></i>Change
          Password</a>
      </li>
      <li class="nav-item center-align">
        <a class='nav-link text-white' data-toggle="dropdown" style="cursor:pointer;" (click)="logOut();">
          <i class="fa fa-power-off" style="padding-right: 0.7rem;"></i>Logout</a>
      </li>
    </ul>
  </div>

</nav> -->
<!-- NAV BAR CODE TILL HERE -->
<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
  <img style="margin: 10px"
  src="/assets/images/courseManagementSystem.svg"
      class="me-2"
      height="30" 
      alt=""
      loading="lazy"
    /> 
  <a (click)="reloadPage()" class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;"> {{navbarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button> 

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="changePassword();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-user"></i> Change Password </a>
      </li>

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="goToResultsDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Skill Report Dashboard </a>
      </li>

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="goToSuperCourseAdminDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Super Course Dashboard </a>
      </li>


      <li class="nav-item" style="float: right;">
        <a (click)="open(logOutModal);" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
          <i class="fa fa-sign-out" ></i> Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>
<div class="p-4">
  <!-- DIV 1 -->


  
<br>

  <div class="row">
  <!-- DIV 2 -->

 
    <div class="col-md-6 role-table">
  <!-- DIV 3 -->


      <div class="table-header row">
        <div class="col-md-12">

          <span class="table-caption">
            Roles
          </span>
          <a style="cursor:pointer" class="float-right" (click)="openModalForRoleAdd();">
            <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>

        </div> 
      </div>

      
      <div class="card">
        <div class="card-body table-responsive">
          <ngx-spinner *ngIf="roleLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
          [fullScreen]="false">
          <p style="color: white">{{roleLoaderMessage}}</p>
        </ngx-spinner>
          <table *ngIf="rolesExists" class="table table-striped table-bordered table-sm row-border hover" datatable
            [dtOptions]="roleOptions">
            <thead class="tableheader">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr class="td-height" *ngFor="let role of roles.roles,let i = index">
                <td class="td-height">{{i+1}}</td>
                <td class="td-height">{{role.roleName}}</td>
                <td class="td-height" style=" height: 2rem;">
                  <button *ngIf="role.activeStatus!== null && role.activeStatus" type="button"
                    class="btn btn-danger mr-2  btn-sm" (click)="updateRoleStatus(role)">Deactivate</button>
                  <button *ngIf="role.activeStatus !== null && !role.activeStatus" type="button"
                    class="btn btn-success mr-2" (click)="updateRoleStatus(role)">Activate</button>
                  <a *ngIf="role.activeStatus !== null" style="cursor:pointer;padding-left: 1rem;"
                    (click)="editRole(role, i);">
                    <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                  </a>
                  <a style="cursor:pointer;"
                  (click)="open(displayStatus);">
                  <img src="/assets/images/icons/eye.png" width="25" height="25" alt="" loading="lazy">
                </a>
                </td>

                <ng-template #displayStatus let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" 
                    id="modal-basic-title">Display Status : {{role.roleName}}</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                  <table class="table table-striped table-bordered table-sm row-border hover">
                    <thead style="background-color: black;" class="tableheader">
                      <tr>
                        <th>Office Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Edu Office</td>
                      <td>
                        <button *ngIf="role.eduOfficeDisplayStatus!== null && role.eduOfficeDisplayStatus" type="button"
                        class="btn btn-danger mr-2  btn-sm" (click)="updateRoleDisplayStatus(role.roleId, 'Edu_Office')">Deactivate</button>
                      <button *ngIf="role.eduOfficeDisplayStatus !== null && !role.eduOfficeDisplayStatus" type="button"
                        class="btn btn-success mr-2"  (click)="updateRoleDisplayStatus(role.roleId, 'Edu_Office')">Activate</button>    
                      </td>
                    </tr>
                    <tr>
                      <td>Biz Office</td>
                      <td>
                        <button *ngIf="role.bizOfficeDisplayStatus!== null && role.bizOfficeDisplayStatus" type="button"
                        class="btn btn-danger mr-2  btn-sm"  (click)="updateRoleDisplayStatus(role.roleId, 'Biz_Office')">Deactivate</button>
                      <button *ngIf="role.bizOfficeDisplayStatus !== null && !role.bizOfficeDisplayStatus" type="button"
                        class="btn btn-success mr-2"  (click)="updateRoleDisplayStatus(role.roleId, 'Biz_Office')">Activate</button>    
                      </td>
                    </tr>
                    <tr>
                      <td>Organisation</td>
                      <td>
                        <button *ngIf="role.orgDisplayStatus!== null && role.orgDisplayStatus" type="button"
                        class="btn btn-danger mr-2  btn-sm" (click)="updateRoleDisplayStatus(role.roleId, 'Organisation')">Deactivate</button>
                      <button *ngIf="role.orgDisplayStatus !== null && !role.orgDisplayStatus" type="button"
                        class="btn btn-success mr-2" (click)="updateRoleDisplayStatus(role.roleId, 'Organisation')">Activate</button>    
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  </div> 
                  
                </ng-template> 

              </tr>
            </tbody>
          </table>
 
        </div>
      </div>

      <!-- DIV 3 -->
    </div>


    <div class="col-md-6 office-table">
      <div class="table-header row">
        <div class="col-md-12">
          <span class="table-caption">
            Offices
          </span>
          <a style="cursor:pointer" style="float: right;" (click)="openModalForOfficeAdd();">
            <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>
        </div>
      </div>
      <div class="card">
        <div class="card-body table-responsive">
          <ngx-spinner *ngIf="officeLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
          [fullScreen]="false">
          <p style="color: white">{{officeLoaderMessage}} </p>
        </ngx-spinner>
          <table *ngIf="officesExists" class="table table-striped table-bordered table-sm row-border hover" datatable
            [dtOptions]="officeOptions">
            <thead class="tableheader">
              <tr>
                <th>#</th>
                <th>Office</th>
                <th>Type</th>
                <th>Action</th>
                <th>Access Controls</th>
                
              </tr>
            </thead>
            <tbody>
              <tr class="td-height" *ngFor="let office of offices.offices,let i = index">
                <td class="td-height">{{i+1}}</td>
                <td class="td-height">
                  {{office.officeName}}, {{office.officeLocation}}
                </td>
                <td class="td-height">{{office.type}}</td>
                <td class="td-height" style=" height: 2rem;">
                  <button *ngIf="office.activeStatus!== null && office.activeStatus" type="button"
                    class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeStatus(office)">Deactivate</button>
                  <button *ngIf="office.activeStatus!== null && !office.activeStatus" type="button"
                    class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeStatus(office)">Activate</button>
                  <a *ngIf="office.activeStatus !== null" style="cursor:pointer;padding-left: 1rem;"
                    (click)="editOffice(office);">
                    <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                  </a>
                  <a *ngIf="office.type === 'Organisation'" style="float: right;cursor:pointer" (click)="openModalForOrgSubOfficeAdd(office.id);">
                    <img src="/assets/images/icons/add.svg" width="25" height="25" alt="" loading="lazy">
                  </a>
                  <a *ngIf="office.type === 'Organisation'" style="cursor:pointer;padding-left: 1rem;"
                    (click)="viewSubOffices();">
                    <img src="/assets/images/icons/eye.png" width="25" height="25" alt="" loading="lazy">
                  </a>
                </td>
                <td>
                  <button class="btn  btn-info mr-2 btn-sm" (click)="accessControls(office.id)">Access</button>
                </td>
                <!-- <td>
                  <button *ngIf="office.oa_ParentMentor!== null && office.oa_ParentMentor" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="updateOA_ParentMentorStatus(office.id)">Deactivate</button>
                <button *ngIf="office.oa_ParentMentor!== null && !office.oa_ParentMentor" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="updateOA_ParentMentorStatus(office.id)">Activate</button>
                
                </td> -->
                <!-- <td>
                  <button *ngIf="office.oa_SubAdmin!== null && office.oa_SubAdmin" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="updateOA_SubAdminStatus(office.id)">Deactivate</button>
                <button *ngIf="office.oa_SubAdmin!== null && !office.oa_SubAdmin" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="updateOA_SubAdminStatus(office.id)">Activate</button>
                
                </td> -->
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  <!-- DIV 2 -->
  </div>

  <!-- USERS -->
  <div class="user-padding">
    <div class="row">
      <div class="col-md-12">
        <div class="table-header row">
          <div class="col-md-12">
            <span class="table-caption">
              Users
            </span>
            <a style="cursor:pointer" style="float: right;" (click)="openModalForOfficeUserAdd();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
          </div>
        </div>
        
        <div class="card"> 
          <div class="card-body table-responsive">
            <ngx-spinner *ngIf="adminUserLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
              [fullScreen]="false">
              <p style="color: white">{{userLoaderMessage}} </p>
            </ngx-spinner>
            <table *ngIf="officeAdminsExists" class="table table-striped table-bordered table-sm row-border hover"
              datatable [dtOptions]="officeAdminOptions">  
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Office</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let officeAdmin of superAdminUsers,let i = index">
                  <td class="td-height" style="width: 5rem;">
                    <span *ngIf="officeAdmin.active !== null">
                      {{i+1}}
                    </span>
                  </td>
                  <td class="td-height" style="width: 17rem;">{{officeAdmin.firstName}} {{officeAdmin.lastName}}</td>
                  <td class="td-height" style="width: 17rem;">{{officeAdmin.email}}</td>
                  <td class="td-height" style="width: 17rem;">
                    <span *ngIf="officeAdmin.userId !== null">
                      {{officeAdmin.officeDTO[0]?.officeName}}, {{officeAdmin.officeDTO[0]?.officeLocation}}
                    </span>
                  </td>
                  <td class="td-height" style="width: 17rem; height: 2rem;">
                    <button *ngIf="officeAdmin.active!== null && officeAdmin.active" type="button"
                      class="btn btn-danger mr-2  btn-sm"
                      (click)="updateUserStatus(officeAdmin,'activate')">Deactivate</button>
                    <button *ngIf="officeAdmin.active!== null && !officeAdmin.active" type="button"
                      class="btn btn-success mr-2" (click)="updateUserStatus(officeAdmin,'activate')">Activate</button>
                    <a *ngIf="officeAdmin.active !== null" style="cursor:pointer;padding-left: 1rem;"
                      (click)="editOfficeAdmin(officeAdmin);"> 
                      <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>


      <!-- Form Configuration -->
      <div class="user-padding"> 
        <div class="row">
          <div class="col-md-12">
            <div class="table-header row">
              <div class="col-md-12">
                <span class="table-caption">
                  Form Configuration
                </span>
                <a style="cursor:pointer" style="float: right;cursor: pointer;" (click)="adddFormConfig();">
                  <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
                </a>
              </div>
            </div>
            
            <div class="card"> 
              <div class="card-body table-responsive">
                <ngx-spinner *ngIf="adminUserLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
                  [fullScreen]="false">
                  <p style="color: white">{{userLoaderMessage}} </p>
                </ngx-spinner>
                <table *ngIf="officeAdminsExists" class="table table-striped table-bordered table-sm row-border hover"
                  datatable [dtOptions]="officeAdminOptions">  
                  <thead class="tableheader">
                    <tr>
                      <th>#</th>
                      <th>Office</th>
                      <th>Display Name</th>
                      <th>Group Name</th>
                      <th>Form</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let setting of formConfigs,let i = index">
                      <td class="td-height" style="width: 5rem;">
                        <span>
                          {{i+1}}
                        </span>
                      </td>
                      <td class="td-height" style="width: 17rem;">
                         
                        {{setting.officeName}}
    
                      </td>
                      
                      <td class="td-height" style="width: 17rem; height: 2rem;">
                        {{setting.displayName}}
                      </td>
  
                      <td class="td-height" style="width: 17rem;">{{setting.groupName}}</td>
                      <td class="td-height" style="width: 17rem;">{{setting.formName}}</td>
  
                      <td class="td-height" style="width: 17rem;">
    
                        <!-- <button *ngIf="setting.activeStatus!== null && setting.activeStatus" type="button"
                          class="btn btn-danger mr-2  btn-sm"
                          (click)="updateSettingStatus(setting.id)">Deactivate</button>
                        <button *ngIf="setting.activeStatus!== null && !setting.activeStatus" type="button"
                          class="btn btn-success mr-2" (click)="updateSettingStatus(setting.id)">Activate</button>
                      
     -->
                        <button style="width: auto;" (click)="editFormConfig(setting);" class="btn btn-info">Edit</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
    
              </div>
            </div>
          </div>
        </div>
      </div>

  <br>
  <!-- SETTINGS -->
  <div class="user-padding"> 
    <div class="row">
      <div class="col-md-12">
        <div class="table-header row">
          <div class="col-md-12">
            <span class="table-caption">
              Settings
            </span>
            <a style="cursor:pointer" style="float: right;cursor: pointer;" (click)="addSetting();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
          </div>
        </div>
        
        <div class="card"> 
          <div class="card-body table-responsive">
            <ngx-spinner *ngIf="adminUserLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
              [fullScreen]="false">
              <p style="color: white">{{userLoaderMessage}} </p>
            </ngx-spinner>
            <table *ngIf="officeAdminsExists" class="table table-striped table-bordered table-sm row-border hover"
              datatable [dtOptions]="officeAdminOptions">  
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Office</th>
                  <th>Key</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let setting of settings,let i = index">
                  <td class="td-height" style="width: 5rem;">
                    <span>
                      {{i+1}}
                    </span>
                  </td>
                  <td class="td-height" style="width: 17rem;">
                     
                    {{setting.officeName}}

                  </td>
                  <td class="td-height" style="width: 17rem;">{{setting.key}}</td>
                  
                  <td class="td-height" style="width: 17rem; height: 2rem;">
                    {{setting.value}}
                    
                  </td>
                  <td class="td-height" style="width: 17rem;">

                    <button *ngIf="setting.activeStatus!== null && setting.activeStatus" type="button"
                      class="btn btn-danger mr-2  btn-sm"
                      (click)="updateSettingStatus(setting.id)">Deactivate</button>
                    <button *ngIf="setting.activeStatus!== null && !setting.activeStatus" type="button"
                      class="btn btn-success mr-2" (click)="updateSettingStatus(setting.id)">Activate</button>
                  

                    <button style="width: auto;" (click)="editSetting(setting);" class="btn btn-info">Edit</button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>

  <br>
  <!-- LINK DEFINITIONS -->

  <div class="row">
    <!-- DIV 2 -->
    <div class="col-md-6 office-table">
      <div class="table-header row">
        <div class="col-md-12">
          <span class="table-caption">
             Link Definitions
          </span>
          <a style="cursor:pointer" style="float: right;" (click)="AddLinkDefinitions();">
            <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
          </a>
        </div>
      </div>
      <div class="card">
        <div class="card-body table-responsive">
          <ngx-spinner *ngIf="officeLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
          [fullScreen]="false">
          <p style="color: white">{{officeLoaderMessage}} </p>
        </ngx-spinner>
          <table *ngIf="officesExists" class="table table-striped table-bordered table-sm row-border hover" datatable
            [dtOptions]="officeOptions">
            <thead class="tableheader">
              <tr>
                <th>#</th>
                <th>Feature</th>
                <th>Sub-Feature</th>
                <th>Role</th>
                <th>Action</th>
                <!-- <th>Access Controls</th> -->
                
              </tr>
            </thead>
            <tbody>
              <tr class="td-height" *ngFor="let ldf of linkDefinitions,let i = index">
                <td class="td-height">{{i+1}}</td>
                <td class="td-height">
                  {{ldf.feature}} 
                </td>
                <td class="td-height"> {{ldf.subFeature}} </td>
                <td>{{ldf.roleName}}</td>
                <td class="td-height" style=" height: 2rem;">
                  <button *ngIf="ldf.activeStatus!== null && ldf.activeStatus" type="button"
                    class="btn btn-danger mr-2 btn-sm" (click)="updateLinkDefinitionStatus(ldf.id)">Deactivate</button>
                  <button *ngIf="ldf.activeStatus!== null && !ldf.activeStatus" type="button"
                    class="btn  btn-success mr-2 btn-sm" (click)="updateLinkDefinitionStatus(ldf.id)">Activate</button>
                  <a style="cursor:pointer;padding-left: 1rem;"
                    (click)="EditLinkDefinitions(ldf);">
                    <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                  </a>
                </td>
                <!-- <td>
                </td> -->
                <!-- <td>
                  <button *ngIf="office.oa_ParentMentor!== null && office.oa_ParentMentor" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="updateOA_ParentMentorStatus(office.id)">Deactivate</button>
                <button *ngIf="office.oa_ParentMentor!== null && !office.oa_ParentMentor" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="updateOA_ParentMentorStatus(office.id)">Activate</button>
                
                </td> -->
                <!-- <td>
                  <button *ngIf="office.oa_SubAdmin!== null && office.oa_SubAdmin" type="button"
                  class="btn btn-danger mr-2 btn-sm" (click)="updateOA_SubAdminStatus(office.id)">Deactivate</button>
                <button *ngIf="office.oa_SubAdmin!== null && !office.oa_SubAdmin" type="button"
                  class="btn  btn-success mr-2 btn-sm" (click)="updateOA_SubAdminStatus(office.id)">Activate</button>
                
                </td> -->
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  
      <div class="col-md-6 role-table">
    <!-- DIV 3 -->
  
  
        <div class="table-header row">
          <div class="col-md-12">
  
            <span class="table-caption">
              Office Link Definitions
            </span>
            <a style="cursor:pointer" class="float-right" (click)="AddOfficeLinkDefinitions();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
  
          </div> 
        </div>
  
        
        <div class="card">
          <div class="card-body table-responsive">
            <ngx-spinner *ngIf="roleLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
            [fullScreen]="false">
            <p style="color: white">{{roleLoaderMessage}}</p>
          </ngx-spinner>
            <table *ngIf="rolesExists" class="table table-striped table-bordered table-sm row-border hover" datatable
              [dtOptions]="roleOptions">
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Office Name</th>
                  <th>Display Name</th>
                  <th>Display Status</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let role of uroLinkDefinitions,let i = index">
                  <td class="td-height">{{i+1}}</td>
                  <td class="td-height">{{role.officeName}}</td>
                  <td class="td-height">{{role.displayName}}</td>
                  <td class="td-height">{{role.displayStatus}}</td>

                  <td class="td-height" style=" height: 2rem;">
                    <button *ngIf="role.activeStatus!== null && role.activeStatus" type="button"
                      class="btn btn-danger mr-2  btn-sm" (click)="updateUroLinkDefinitionStatus(role.id)">Deactivate</button>
                    <button *ngIf="role.activeStatus !== null && !role.activeStatus" type="button"
                      class="btn btn-success mr-2" (click)="updateUroLinkDefinitionStatus(role.id)">Activate</button>
                    <a  style="cursor:pointer;padding-left: 1rem;"
                      (click)="EditOfficeLinkDefinitions(role);">
                      <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
   
          </div>
        </div>
  
        <!-- DIV 3 -->
      </div>
  
  
     
    <!-- DIV 2 -->
    </div>

      <!--SKILLS-->
  <div class="user-padding"> 
    <div class="row">
      <div class="col-md-12">
        <div class="table-header row">
          <div class="col-md-12">
            <span class="table-caption">
              Skills
            </span>
            <a style="cursor:pointer" style="float: right;cursor: pointer;" (click)="addParentSkill();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
          </div>
        </div>
        
        <div class="card"> 
          <div class="card-body table-responsive">
            <ngx-spinner *ngIf="adminUserLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
              [fullScreen]="false">
              <p style="color: white">{{userLoaderMessage}} </p>
            </ngx-spinner>
            <table *ngIf="officeAdminsExists" class="table table-striped table-bordered table-sm row-border hover"
              datatable [dtOptions]="officeAdminOptions">  
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Skill</th>
                  <th>Parent Skill</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let skill of skills,let i = index">
                  <td class="td-height" style="width: 5rem;">
                    <span>
                      {{i+1}}
                    </span>
                  </td>
                  <td class="td-height" style="width: 17rem;">
                     
                    <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="skill.safeUrl">

                  </td>
                  <td class="td-height" style="width: 17rem;">{{skill.skillName}}</td>
                  <td class="td-height" style="width: 17rem;">{{skill.parentSkillName}}</td>
                  
                  <td class="td-height" style="width: 17rem; height: 2rem;">
                    <button *ngIf="skill.skillStatus!== null && skill.skillStatus" type="button"
                      class="btn btn-danger mr-2  btn-sm"
                      (click)="updateSkillStatus(skill.skillId)">Deactivate</button>
                    <button *ngIf="skill.skillStatus!== null && !skill.skillStatus" type="button"
                      class="btn btn-success mr-2" (click)="updateSkillStatus(skill.skillId)">Activate</button>
                    <a *ngIf="skill.skillStatus !== null" style="cursor:pointer;padding-left: 1rem;"
                      (click)="editSkill(skill.skillId);"> 
                      <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                  </td>
                  <td class="td-height" style="width: 17rem;">
                    <button style="width: auto;" *ngIf="skill.parentSkillId === null" (click)="addChildSkill(skill.skillId);" class="btn btn-info">Add Child Skill</button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<br>






<ng-template #logOutModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" 
    id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <h2 style="text-align: center;color: black;">Are you sure you want to logout ?</h2>

  </div> 
  <div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">No</button>
  <button type="button" class="btn btn-danger" (click)="logOut();modal.dismiss('Cross click')">Logout</button>
</div>
</ng-template>