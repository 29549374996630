 <div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;"  class="modal-title pull-left">Subscriptions : {{firstName}} {{lastName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div> 

  

   <div class="modal-body">
   
    <div class="p-4">
  
    

      
          
            <table *ngIf="context==='SUBSCRIPTIONS'" class="table table-striped table-bordered table-sm row-border hover"  >
              <thead class="tableheader">
                <tr>
                  <th>Role</th>
                  <th>Product</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead> 
              <tbody>
                <tr class="td-height" *ngFor="let subs of subscriptions">
                  <td class="td-height">{{subs.displayName}}</td>
                  <td class="td-height">{{subs.productName}}</td>
                  <td class="td-height">{{subs.startedDate}}</td>
                  <td class="td-height">{{subs.endedDate}}</td>
                
                </tr>
              </tbody>
            </table>
    
            <table *ngIf="context==='INVOICES'" class="table table-striped table-bordered table-sm row-border hover">
              <thead class="tableheader">
                <tr>
                  <th>Invoice No.</th>
                  <th>Date</th>
                  <th>Total Amt</th>
                  <th>Outstanding Amount</th>
                  <th>Action</th>
                </tr>
              </thead> 
              <tbody>
                <tr class="td-height" *ngFor="let subs of invoices">
                  <td class="td-height">{{subs.invoiceNo}}</td>
                  <td class="td-height">{{subs.invoiceDate}}</td>
                  <td class="td-height">{{subs.grandTotal}}</td>
                  <td class="td-height">{{subs.outstandingAmount}}</td>
                <td>
                  <button style="margin: 1%;" (click)="viewInvoiceDetailsModal(subs.invoiceId)" class="btn btn-dark">View</button>

                </td>
                </tr>
              </tbody>
            </table> 
      
            <table *ngIf="context==='PAYMENTS'" class="table table-striped table-bordered table-sm row-border hover">
              <thead class="tableheader">
                <tr>
                  <th>Date</th>
                  <th>Method</th>
                  <th>Amount</th>
                </tr>
              </thead> 
              <tbody>
                <tr class="td-height" *ngFor="let subs of payments">
                  <td class="td-height">{{subs.paymentDate}}</td>
                  <td class="td-height">{{subs.paymentMethod}}</td>
                  <td class="td-height">{{subs.paymentAmount}}</td>
                 
                
                </tr>
              </tbody>
            </table> 
    
    </div>


   </div>
   
  