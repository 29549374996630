<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>


  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
 

  <mat-tab-group (selectedTabChange)="tabClick($event)" style="padding: 1em;" dynamicHeight>
    <mat-tab> 
      <ng-template mat-tab-label>
        Users
          <button (click)="botUsersCreationFileUpload();" matTooltip="File upload" mat-icon-button>
            <mat-icon>person_add</mat-icon>
        </button>
        <button (click)="exportUsersArray(botCustomerUser.users)" matTooltip="Download as excel file" mat-icon-button>
          <mat-icon>file_download</mat-icon>
      </button>
      <button mat-button [matMenuTriggerFor]="menu" matTooltip="Search" mat-icon-button>
        <mat-icon>search</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="open(searchByNameNumberModal);" mat-menu-item>Search By Name/Phone</button>
      <button (click)="open(searchByCreationDateModal);" mat-menu-item>Search By Joining Date</button>
      <button (click)="open(getAllUsersWarning);" mat-menu-item>Get All Users</button>
    </mat-menu>
      </ng-template>
     
      <div class="p-4">

        <div style="font-weight: bold;text-align: center;" *ngIf="botCustomerUser.users === null || botCustomerUser.users === undefined
        || botCustomerUser.users !== null && botCustomerUser.users !== undefined && botCustomerUser.users.length === 0">
        Use Search Icon to search for Users
        </div>
    
      <div *ngIf="usersExist" class="search-hero">
        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Start searching for a user by name or phone number">
        <br>
      </div>
      
        
          <div *ngIf="usersExist" class="card">
            <div class="card-body table-responsive">
              <table *ngIf="usersExist" class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th> NAME</th>
                    <th> PHONE</th>
                    <th> OPT IN STATUS</th>
                    <th>JOINED ON</th>
                    <th>ALT PHN</th>
                    <th>GENDER</th>
                    <th>CUSTOM VAR 1</th>
                    <th>CUSTOM VAR 2</th>
                    <th>CONVO MODE</th>
                    <th>AGENT</th>
                    <th style="text-align: center;">MENU</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let ccd of botCustomerUser.users | filter:searchText">
                    <td class="td-height">{{ccd.name}}</td>
                    <td class="td-height">{{ccd.userIdentifier}}</td>
                    
                    <td style="color: black;" *ngIf="ccd.optInVar===null" class="td-height">N/A</td>
                    <td style="color: green;" *ngIf="ccd.optInVar==='Opt-In'" class="td-height">Opted In</td>
                    <td style="color: red;" *ngIf="ccd.optInVar==='Opt-Out'" class="td-height">Opted Out</td>

                    <td class="td-height">{{ccd.creationTimestampFormatted}}</td>
                    <td class="td-height">{{ccd.alternatePhone}}</td>
                    <td class="td-height">{{ccd.gender}}</td>
                    <td class="td-height">{{ccd.customVar1}}</td>
                    <td class="td-height">{{ccd.customVar2}}</td>
                    <td *ngIf="ccd.convoMode==='manual'" class="td-height">Manual Chat</td>
                    <td *ngIf="ccd.convoMode==='bot'" class="td-height">Automated Chat</td>
                    <td class="td-height">{{ccd.assignedAgentName}}</td>
                    
                    <td class="td-height" style="text-align: center;">

                      <a>
                        <img style="cursor:pointer;" [matMenuTriggerFor]="beforeMenu" matTooltip="Menu" 
                        src="/assets/images/icons/three-dots.png" alt="user" height="30" width="30" class="rounded-circle">
                      </a>

    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <button (click)="botGroupsByBotUserIdLoaded=false;getBotUserGroupsByBotUserId(ccd.botUserId);open(userGroupsInfoModal);" mat-menu-item>
        <mat-icon>group</mat-icon>View User Groups</button>
     <button *ngIf="ccd.fkUroIdManualChat===null" (click)="getBotAgents();open(assignAgentModal);" mat-menu-item>
    <mat-icon>assignment_ind</mat-icon>Assign Agent</button>
  <button *ngIf="ccd.fkUroIdManualChat!==null" (click)="getBotAgents();open(assignAgentModal);" mat-menu-item>
    <mat-icon>assignment_ind</mat-icon>Reassign Agent</button>
  <button (click)="open(deleteUser);" mat-menu-item>
    <mat-icon>delete</mat-icon>Delete User</button>
  <button (click)="open(deleteUserChatSessionWarning);" mat-menu-item>
    <mat-icon>delete_outline</mat-icon>Delete User Data</button>
    
</mat-menu>

             
                    </td>
                   
                    <ng-template #deleteUser let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="color: black;">
                        Are you sure to delete the user "{{ccd.name}}({{ccd.userIdentifier}})" ? <br> <br>
                        This would delete following data : <br>
                        The invoices, message schedules, admin notifications & notes created for this user. <br>
                        The form responses submitted by this user. <br>
                        Any media including the locations shared by this user. <br>
                        The user, user's chat session, conversation log. <br>
                        This would also remove user from all the user-groups, the user is part of. <br> <br>
                        This action is not reversible.
                      </div> 
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
                        <button type="button" class="btn btn-success" (click)="deleteBotCustomerUser(ccd.botUserId, 'user and entire user data');modal.dismiss('Cross click')">Delete</button>
                      </div>
                    </ng-template> 
                    
                    
                    <ng-template #deleteUserChatSessionWarning let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                     
                      <div class="modal-body">
                       <table class="table table-striped table-sm hover">
                        <thead>
                          <th>Data Type</th>
                          <th>Data being deleted</th>
                          <th class="tbl-action">Action</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Convo Log</td> 
                            <td>
                        The user's conversation log and any media including the locations shared by this user
                            </td>
                            <td class="tbl-action"> 
                              <button (click)="open(deleteDataFinalConfirmation1);" mat-icon-button>
                                <mat-icon>delete</mat-icon></button>
                            </td>
                          </tr>
                          <tr>
                            <td>Invoices</td>
                            <td>All the Invoices created for this user</td>
                            <td>
                              <button type="button" mat-icon-button
                              (click)="open(deleteDataFinalConfirmation2);"><mat-icon>delete</mat-icon></button>
                            </td>
                          </tr>
                          <tr>
                            <td>Admin Notifications & Notes</td>
                            <td>All admin notifications and notes created by this user's interaction</td>
                            <td>
                              <button type="button" mat-icon-button
                              (click)="open(deleteDataFinalConfirmation3);"><mat-icon>delete</mat-icon></button>
                            </td>
                          </tr>
                          <tr>
                            <td>Form Responses</td>
                            <td>All form responses made by this user</td>
                            <td>
                              <button type="button" mat-icon-button
                              (click)="open(deleteDataFinalConfirmation4);"><mat-icon>delete</mat-icon></button>
                            </td>
                          </tr>
                          <tr>
                            <td>User Groups</td>
                            <td>User will be removed from all the groups the User is part of</td>
                            <td>
                              <button type="button" mat-icon-button
                              (click)="open(deleteDataFinalConfirmation5);"><mat-icon>delete</mat-icon></button>
                            </td>
                          </tr>
                          <tr>
                            <td>Schedules</td>
                            <td>All message schedules including form schedules created for this user</td>
                            <td>
                              <button type="button" mat-icon-button
                              (click)="open(deleteDataFinalConfirmation6);"><mat-icon>delete</mat-icon></button>
                            </td>
                          </tr>
                        </tbody>
                       </table>
                      </div> 
                      
                    </ng-template>

                    

                    <ng-template #deleteDataFinalConfirmation1 let-modal>
                      <div class="modal-body" style="color: black;">
                        <h3 style="font-weight: bold;">
                          Delete user's conversation log and any media including the locations shared by this user ?
                        </h3>
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="deleteBotCustomerUser(ccd.botUserId, 'convo log');modal.dismiss('Cross click')"
                        mat-raised-button>Delete</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      </div>
                    </ng-template>
                    <ng-template #deleteDataFinalConfirmation2 let-modal>
                      <div class="modal-body" style="color: black;">
                        <h3 style="font-weight: bold;">
                          Delete all the Invoices created for this user ?
                        </h3>
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="deleteBotCustomerUser(ccd.botUserId, 'invoices');modal.dismiss('Cross click')"
                        mat-raised-button>Delete</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      </div>
                    </ng-template>
                    <ng-template #deleteDataFinalConfirmation3 let-modal>
                      <div class="modal-body" style="color: black;">
                        <h3 style="font-weight: bold;">
                          Delete all admin notifications and notes created by this user's interaction ?
                        </h3>
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="deleteBotCustomerUser(ccd.botUserId, 'admin notifications');modal.dismiss('Cross click')"
                        mat-raised-button>Delete</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      </div>
                    </ng-template>
                    <ng-template #deleteDataFinalConfirmation4 let-modal>
                      <div class="modal-body" style="color: black;">
                        <h3 style="font-weight: bold;">
                          Delete all form responses made by this user ?
                        </h3>
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="deleteBotCustomerUser(ccd.botUserId, 'form responses');modal.dismiss('Cross click')"
                        mat-raised-button>Delete</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      </div>
                    </ng-template>
                    <ng-template #deleteDataFinalConfirmation5 let-modal>
                      <div class="modal-body" style="color: black;">
                        <h3 style="font-weight: bold;">
                          Remove user from all the groups the User is part of ?
                        </h3>
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="deleteBotCustomerUser(ccd.botUserId, 'user group details');modal.dismiss('Cross click')"
                        mat-raised-button>Delete</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      </div>
                    </ng-template>
                    <ng-template #deleteDataFinalConfirmation6 let-modal>
                      <div class="modal-body" style="color: black;">
                        <h3 style="font-weight: bold;">
                          Delete all message schedules including form-schedules created for this user ?
                        </h3>
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="deleteBotCustomerUser(ccd.botUserId, 'schedules');;modal.dismiss('Cross click')"
                        mat-raised-button>Delete</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                      </div>
                    </ng-template>


                    <ng-template #assignAgentModal let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Agent</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div>
                          <div style="margin-bottom: 2%;">
                            <table class="table table-striped table-sm hover">
                              <thead>
                                <tr>
                                  <th>Agent</th>
                                  <th>Users in "Manual Chat Mode" with Agent</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let agent of botAgent.users">
                                  <td>{{agent.firstName}} {{agent.lastName}}</td>
                                  <td>{{agent.manualChatBotUserNames}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                  
                          <div style="margin-bottom: 2%;">
                            <label class="required" for="var">Agents</label> <br> 
                               <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botAgentUserIdToAssign" 
                                            id="botAgentUserIdToAssign" name="botAgentUserIdToAssign">
                                 <option value="" disabled >Choose Agent to assign</option>
                                 <option *ngFor="let agent of botAgent.users" name="varV" id="India" [value]="agent.userId">{{agent.firstName}} {{agent.lastName}}</option>
                                </select>
                           </div> 
                        </div>
                      </div> 
                      <div class="modal-footer">
                        <button style="width: auto;float: right;" class="btn btn-info" 
                        (click)="assignAgentToManualChat(ccd.botUserId, ccd.convoMode);modal.dismiss('Cross click');">Assign</button>
                      </div>
                    </ng-template>
     </tr>
                </tbody>
              </table>


            </div>
          </div>
    
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        User Groups
        <button (click)="createBotUserGroup();" matTooltip="Create Groups" mat-icon-button>
          <mat-icon>add_box</mat-icon>
      </button>
      </ng-template>

      <div class="p-4">

        <button (click)="botGroupsByBotUserIdLoaded=false;getUsersNotPartOfGroupsByOfficeId();open(botusersWithoutGroupsInfoModal);" 
        class="btn btn-info" style="width: auto;float: left;">View Users Not Part Of Any Group</button>

        <br>
    
      <div class="search-hero">
        <input class="form-control" type="text" name="search" [(ngModel)]="searchTextGrp" autocomplete="off" 
        placeholder="Start searching for a group by name or description">
      </div>
      <br>
    
          <div class="card">
            <div class="card-body table-responsive">
              <table *ngIf="usersGroupsExist" class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th> NAME</th>
                    <th> DESCRIPTION</th>
                    <th style="text-align: center;">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let ccd of botUserGroup.list | filter:searchTextGrp">
                    <td class="td-height">{{ccd.botUserGrpName}}</td>
                    <td class="td-height">{{ccd.botUserGrpDescription}}</td>
                    <td style="text-align: center;">
    
                      <button (click)="editBotUserGroup(ccd);" matTooltip="Edit" mat-icon-button>
                        <mat-icon>mode_edit_outline</mat-icon>
                    </button>
                    <button (click)="viewBotUserGroupDetails(ccd);" matTooltip="Explore" mat-icon-button>
                      <mat-icon>density_small</mat-icon>
                  </button>
           
    
                    </td>
                
     </tr>
                </tbody>
              </table>
     
            </div>
          </div>
    
      </div>
    </mat-tab>

    

  </mat-tab-group>


  <ng-template #getAllUsersWarning let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
        Get All Users ?
      </h3>

      Are you sure to get all users ? <br>
      This might take a while <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="getAllBotCustomerUsersByOfficeId();modal.dismiss('Cross click')"
      mat-raised-button>Get All Users</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div> 
   
  </ng-template>

  <ng-template #searchByNameNumberModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
       Search Users By Name or Phone
      </h3>

      <div>
        <label class="required" for="botUserGrpName">Name or Phone</label>
        <input maxlength="255" required class="form-control" type="text" 
         name="searchValue" id="searchValue" [(ngModel)]="searchValue">
      </div>
      <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByNameOrPhone();modal.dismiss('Cross click')"
      mat-raised-button>Search</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div>
  </ng-template>

  <ng-template #searchByCreationDateModal let-modal>
    <div class="modal-body">
      
      <h3 style="font-weight: bold;">
        Search Users By Joining / Creation Date
       </h3>

  <div class="form-group row">
    <div class="col-md-6">
      <label class="required" for="">From Date-Time</label>
      <input class="form-control" required [(ngModel)]="fromDateTime" 
      type="datetime-local" id="fromDateTime" name="fromDateTime">
    </div>
  
    <div class="col-md-6">
      <label class="required" for="">To Date-Time</label>
      <input class="form-control" required [(ngModel)]="toDateTime" 
      type="datetime-local" id="toDateTime" name="toDateTime">
    </div>
  </div>

  <br> <br>

  <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByCreationTimestamp();modal.dismiss('Cross click')"
  mat-raised-button>Search</button>
  <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
  
  
    </div>
  </ng-template>



  <ng-template #userGroupsInfoModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">User Groups</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
   
    <div class="modal-body">
      <mat-spinner style="margin:0 auto;" *ngIf="botGroupsByBotUserIdLoaded===false"></mat-spinner>
     <table *ngIf="botGroupsByBotUserIdLoaded===true" class="table table-striped table-sm hover">
      <thead>
        <th style="text-align: center;">Group Name</th>
      </thead>
      <tbody>
        <tr class="td-height" *ngFor="let ccd of botGroupsByBotUserId.list">
          <td style="text-align: center;" class="td-height">{{ccd.botUserGrpName}}</td>
</tr>
      </tbody>
     </table>
    </div> 
    
  </ng-template>

  <ng-template #botusersWithoutGroupsInfoModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Users Not Part Of Groups</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
   
    <div class="modal-body">
      <mat-spinner style="margin:0 auto;" *ngIf="botGroupsByBotUserIdLoaded===false"></mat-spinner>

      <button *ngIf="botGroupsByBotUserIdLoaded===true"
      (click)="exportUsersArray(botCustomerUsersWithoutGroups.users)" matTooltip="Download as excel file" mat-icon-button>
        <mat-icon>file_download</mat-icon>
    </button>

      <div *ngIf="botGroupsByBotUserIdLoaded===true" class="search-hero">
        <input class="form-control" type="text" name="search" [(ngModel)]="searchTextUsersWithoutGrps" autocomplete="off" placeholder="Start searching for a user by name or phone number">
        <br>
      </div>

     <table *ngIf="botGroupsByBotUserIdLoaded===true" class="table table-striped table-sm hover">
      <thead>
        <th style="text-align: center;">User Name</th>
        <th style="text-align: center;">User Phone</th>
      </thead>
      <tbody>
        <tr class="td-height" *ngFor="let ccd of botCustomerUsersWithoutGroups.users | filter:searchTextUsersWithoutGrps">
          <td style="text-align: center;" class="td-height">{{ccd.name}}</td>
          <td style="text-align: center;" class="td-height">{{ccd.phone}}</td>
</tr>
      </tbody>
     </table>
    </div> 
    
  </ng-template>
  



