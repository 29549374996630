import { Skill } from "../skill";

export class ActivityQuestions {
 
    activityQuestionId:any;
	activityQuestion:any;
	activityQuestionDescription:any;
	activityQuestionOrder:any;
	activityQuestionType:any;
	fkActivityPlannerId:any;
	activityQuestionStatus:any;
    answer : string = "No";
	objective : any;

	skills : Skill[] = [];
	skillsToMap : Skill[] = [];
	questionSkillMappingId : any;
	fkActivityQuestionId: any;
	fkSkillId: any;
	activeStatus: any;
	level: any;
}
