<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4  *ngIf="viewClasses===true" style="color: white;" class="modal-title pull-left"> Attendance</h4>
    <h4  *ngIf="viewClasses===false" style="color: white;" class="modal-title pull-left"> Attendance For Class : {{selectedClassName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
   

            <form #aplnrForm="ngForm">

                <div *ngIf="viewClasses===true" >

            <div class="form-group row">
                <div class="col-md-6">
                    <label class="required" for="roleId">Class of current session</label> 
                    <select #invoice="ngModel" required  class="form-control" [(ngModel)]="selectedClassId" id="selectedClassId" name="selectedClassId">
                      <option value="" disabled >Choose class</option>
                      <option  *ngFor="let class of classes" 
                       [ngValue]="class.classId">{{class.name}}
                      </option>
                    </select>
              </div>
              <div class="col-md-6">
                <label class="required" for="attendanceDate"> Date</label>
                <input #date2="ngModel" required class="form-control" type="date" name="attendanceDate" 
                id="attendanceDate" [(ngModel)]="mainAttendance.attendanceDate">
                <div class="alert alert-danger" *ngIf="date2.touched && !date2.valid">Provide Date</div>            
              </div>

            </div>

            <div class="form-group row">
                <div class="col-md-6">
                    <label class="required" for="">From Date-Time</label>
                    <input class="form-control"  required [(ngModel)]="mainAttendance.attendanceFromDateTime" 
                    type="datetime-local" id="attendanceFromDateTime" name="attendanceFromDateTime">
                </div>
                <div class="col-md-6">
                    <label class="required" for="">To Date-Time</label>
                    <input class="form-control"  required [(ngModel)]="mainAttendance.attendanceToDateTime" 
                    type="datetime-local" id="attendanceToDateTime" name="attendanceToDateTime">
                </div>
            </div>

        </div>

        <div *ngIf="viewClasses===false" >
          <div class="form-check" *ngFor="let att of attendanceList">
            <input (click)="handleClick(att.fkStudentId);" [checked] = "att.markAttendance"
            class="form-check-input"
             type="checkbox" value="" name="att.markAttendance" id="att.markAttendance">
            <label class="form-check-label" for="flexCheckDefault">
              <a style="color: black;">
              {{att.studentName}} 
            </a> 
            </label>
          </div>
        </div>

        <br>
        <br>

        <div *ngIf="viewAdditionalStudents===true" >
<h3>Students from different classes attending current session</h3>
          <div class="form-check" *ngFor="let att of attendanceListForDifferentClass">
            <input (click)="handleClick(att.fkStudentId);" [checked] = "att.markAttendance"
            class="form-check-input"
             type="checkbox" value="" name="att.markAttendance" id="att.markAttendance">
            <label class="form-check-label" for="flexCheckDefault">
              <a style="color: black;">
              {{att.studentName}} 
            </a> 
            </label>
          </div>
        </div>

        </form>

            

     

      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button *ngIf="viewClasses===false" style="width: auto;" type="submit" (click)="open(getStudentsForThisSession);" class="btn btn-sm btn-primary float-right">Search and add student for this session</button>
    <button *ngIf="viewClasses===true" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="showStudents();">Proceed</button>
    <button *ngIf="viewClasses===false" style="width: auto;" [disabled]="!aplnrForm.valid" [disabled]="clicked" type="submit" class="btn btn-sm btn-success float-right" (click)="onSubmit(); clicked=true">Mark Attendance</button>
  </div>

  <ng-template #getStudentsForThisSession let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Search Students</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form *ngIf="searchInput===true">
        <div class="col-md-12">
        <input class="col-md-12 form-control" type="text" [(ngModel)]="searchValue" 
        placeholder="Enter name/email/phone" name="search">
      <br>
        <div style="text-align:center;">
        <button style="width: auto;color: black;" (click)="getStudentsFromSubscriptionsForAttendance();modal.dismiss('Cross click');" class="btn btn-info">
          Search
          <img style="color: white;" src="/assets/images/icons/searchIconBlack.svg" 
          width="27" height="27" alt="" loading="lazy">
        
        </button>
      </div>
      </div>
        </form>
    </div> 
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Activate</button>
    </div> -->
  </ng-template> 