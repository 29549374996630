<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a  onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-arrow-left"></i> Go back </a>
        </li>
        
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

 
   

    
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" (click)="goHome();"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
          <li style="cursor: pointer;" class="breadcrumb-item" style="cursor: pointer;color: #0275d8;"  onclick="history.back()" aria-current="page"> Student </li>
          <li style="cursor: pointer;" style="cursor: pointer;color: #0275d8;" onclick="history.back()" class="breadcrumb-item" aria-current="page"> Classes </li>
          <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Course</li>
        </ol>
      </nav>
    
    
      <div class="content" *ngIf="cardsExist === true"  style="margin: 2.5%;">
    
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
          
          <div class="col mb-4">
            <div style="cursor: pointer;" onclick="history.back()" class="card example-ripple-container mat-elevation-z12"
             matRipple
             [matRippleCentered]="centered"
             [matRippleDisabled]="disabled"
             [matRippleUnbounded]="unbounded"
             [matRippleRadius]="radius"
             [matRippleColor]="color">
             <br> <br> <br>
             <div class="sub-image-div">
              <img class="sub-image" src="/assets/images/icons/back-track.svg"  height="100" width="100" alt="...">
              </div>
        <br>  <br>  <br>  <br>
        </div>
        </div>
      
             <div  *ngFor="let sub of subjectListWithSafeUrl">
    
              <div class="col mb-4">
                
            <div class="card mat-elevation-z12">
    
              <div class="sub-image-div" >
                <img class="sub-image" height="75" width="75" 
                  [src]="sub.safeUrl" />
              </div>
              
              <div class="card-body">
                <h5 class="card-title">{{sub.name}}</h5>
                <!-- <p class="card-text">{{sub.description}}</p> -->
                <div class="mat-card-subtitle" *ngIf="sub.description !== null && sub.description !== undefined" >
                  {{(sub.readMore) ? sub.description : sub.description | slice:0:45}} <span *ngIf="!sub.readMore"></span>
                  <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!sub.readMore && sub.description.length >45" (click)="sub.readMore=true">...</a>
                  <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="sub.readMore && sub.description.length >45" (click)="sub.readMore=false">[Read less]</a>
              
              </div>
              <br>
                <div class="button-div">
                <button [style.background-color]='getColor()' (click)="explore(sub.subId);" class="btn btn-info">Explore</button>
                </div>
              </div>
            </div>
          </div>
      
          </div>
    
         </div>
      
      
      
      
      </div>
      
    
      </div>
    
    
    