import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-orders-by-period',
  templateUrl: './search-orders-by-period.component.html',
  styleUrls: ['./search-orders-by-period.component.scss']
})

export class SearchOrdersByPeriodComponent implements OnInit {

  clicked = false;
  loggedInUser: any;
  theme : any;
  invoices : InvoiceList[] = [];
  target : Target = new Target();
  role : any;

  constructor(
    public activeModal: NgbActiveModal,
    private datePipe : DatePipe,
    private sharedDataService : SharedDataService,
    private invoiceService : InvoiceService) { }

  @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() passEntry2: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.role = this.fromParent.role;

  }

  getInvoicesForExec(){
    this.invoiceService.getInvoicesByPeriod(environment.apiUrl + "bcrmUser/getInvoicesByPeriodForExec", this.target).then(
      data=>{      

        this.invoices = data;

        this.passEntry.emit(this.invoices);
        this.passEntry2.emit(this.target);
        this.activeModal.close();
      });
  }

  getInvoicesForTM(){
    this.invoiceService.getInvoicesByPeriod(environment.apiUrl + "bcrmUser/getInvoicesByPeriodForTM", this.target).then(
      data=>{      

        this.invoices = data;
        // this.invoices.forEach((inv)=>{

        //   inv.invoiceDate = this.datePipe.transform(inv.invoiceDate, 'dd-MM-yyyy');

        // });
        this.passEntry.emit(this.invoices);
        this.passEntry2.emit(this.target);
        this.activeModal.close();
      });
  }

  getInvoicesForMan(){
    this.invoiceService.getInvoicesByPeriod(environment.apiUrl + "bcrmUser/getInvoicesByPeriodForMan", this.target).then(
      data=>{      

        this.invoices = data;
        // this.invoices.forEach((inv)=>{

        //   inv.invoiceDate = this.datePipe.transform(inv.invoiceDate, 'dd-MM-yyyy');

        // });
        this.passEntry.emit(this.invoices);
        this.passEntry2.emit(this.target);
        this.activeModal.close();
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeModal(){
    this.activeModal.close();
}

}
