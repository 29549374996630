<p>Add Address</p>

<div>
    <form>
        <div>
            <label for="addressId">Address Id</label>
            <input type="text" placeholder="Enter Address Id" name="addressId" id="addressId" [(ngModel)]="address.addressId">
        </div>

        <div>
            <label for="landmark">Landmark</label>
            <input type="text" placeholder="Enter Landmark" name="landmark" id="landmark" [(ngModel)]="address.landmark">
        </div>

        <div>
            <label for="locality">Locality</label>
            <input type="text" placeholder="Enter Locality" name="locality" id="locality" [(ngModel)]="address.locality">
        </div>

        <div>
            <label for="city">City</label>
            <input type="text" placeholder="Enter City" name="city" id="city" [(ngModel)]="address.city">
        </div>

        <div>
            <label for="state">State</label>
            <input type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="address.state">
        </div>

        <div>
            <label for="country">Country</label>
            <input type="text" placeholder="Enter Country" name="country" id="country" [(ngModel)]="address.country">
        </div>

        <div>
            <label for="postalcode">Postal Code</label>
            <input type="text" placeholder="Enter Postal Code" name="postalcode" id="postalcode" [(ngModel)]="address.postalcode">
        </div>

        <div>
            <label for="addressType">Address Type</label>
            <input type="text" placeholder="Enter Address Type" name="addressType" id="addressType" [(ngModel)]="address.addressType">
        </div>

        <div>
            <label for="fkCustomerId">FK Customer Id</label>
            <input type="text" placeholder="Enter FK Customer Id" name="fkCustomerId" id="fkCustomerId" [(ngModel)]="address.fkCustomerId">
        </div>

        <div>
            <label for="addressStatus">Address Status</label>
            <input type="text" placeholder="Enter Address Status" name="addressStatus" id="addressStatus" [(ngModel)]="address.addressStatus">
        </div>

        <div>
            <button (click)="onSubmit()">Save</button>
        </div>
    </form>
</div>

