import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalClass } from 'src/app/global/global-class';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-timeline-page',
  templateUrl: './activity-timeline-page.component.html',
  styleUrls: ['./activity-timeline-page.component.scss']
})
export class ActivityTimelinePageComponent implements OnInit {
  
  showSpinner = true;
  activitiesDontExist = false;
  showTimeline = false;
  test : any[] = [1,2,3,4,5,6,7,8]
  dirs : any[] = ['direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l',
                  'direction-r', 'direction-l', 'direction-r', 'direction-l']
 

  unsafeUrl : any;
  safeUrl : any;

  classId!: string;
  subId!: string;
  teacherId!: string;

 
  allActivitiesList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    activityActiveStatus : '',
    safeUrl : '',
    selectedState : ''

  }]

  @Input() fromParent: any;

   constructor( 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              public router: Router,
              public route : ActivatedRoute,
              private activityPlannerService : ActivityPlannerService,
              public activeModal: NgbActiveModal,
              
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;
ngOnInit(): void { 


this.subId = this.fromParent.subjectId;
this.classId = this.fromParent.classId;
this.teacherId = this.fromParent.teacherId;


this.getActivitiesForTimeline();

this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.theme = this.sharedDataService.getCardOfficeTheme();
}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

getColor(){
this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
return this.loggedInUser.offices[0].theme + '!important';
}

getActivitiesForTimeline(){
  this.activityPlannerService.getAPsWithCompletionStatusBySubId(environment.apiUrl + "activityPlanner/getAPsWithCompletionStatusBySubId/" + this.subId + "/" + this.teacherId).then(
    data=>{
      this.allActivitiesList.pop();

      for(var index in data){

        this.allActivitiesList.push( { 'activityPlannerId' : data[index].activityPlannerId,
                                                    'activityPlannerName' : data[index].activityPlannerName,
                                                    'activityPlannerDescription' : data[index].activityPlannerDescription,
                                                    'activityPlannerOrder' : data[index].activityPlannerOrder,
                                                    'activityPlannerStatus' : data[index].activityPlannerStatus,
                                                    'fkSubjectId' : data[index].fkSubjectId,
                                                    'activityPlannerImage' : data[index].activityPlannerImage,
                                                    'activityPlannerCompletionStatus' : data[index].activityPlannerCompletionStatus,
                                                    'safeUrl' : this.safeUrl,
                                                    'selectedState' : false
                                                  } );
    }

    if(this.allActivitiesList === undefined || this.allActivitiesList.length === 0){
      this.activitiesDontExist = true;
    }else {
      this.showTimeline = true;
    }

    this.allActivitiesList.forEach( (value) => {
      
      this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
        data=>{
          this.unsafeUrl = URL.createObjectURL(data);
          this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);

          value.safeUrl = this.safeUrl;
        });
    }); 
    
    });

}






closeUpdateModal(){
  this.activeModal.close(); 
}

}
