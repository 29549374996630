import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LocationGroup } from 'src/app/BIZ_CRM/biz_crm_classes/location-group';
import { LocationGroupService } from 'src/app/BIZ_CRM/biz_crm_services/location-group.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-location-group',
  templateUrl: './add-location-group.component.html',
  styleUrls: ['./add-location-group.component.scss']
})
export class AddLocationGroupComponent implements OnInit {

  lg : LocationGroup = new LocationGroup();
  clicked = false;

  @Output() passEntry : EventEmitter<any> = new EventEmitter;
  @Input() fromParent : any;
  loggedInUser: any;
  theme : any;

  constructor(private locationGroupService : LocationGroupService,
    private activeModal : NgbActiveModal,
    private sharedDataService : SharedDataService,
    private toastr : ToastrService) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

  }

  onSubmit(){
    this.locationGroupService.addLocationGroup(environment.apiUrl + "locationGroup/addLocationGroup" , this.lg).then(
      data=>{
        this.toastr.success("LocationGroup added successfully");
        this.passEntry.emit("success");
        this.activeModal.close();
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
