
<nav [ngStyle]="backgroundStyle" class="navbar navbar-expand-md navbar-dark " >
    <img style="margin: 10px"
    src="/assets/images/courseManagementSystem.svg"
        class="me-2"
        height="30" 
        alt=""
        loading="lazy"
      /> 
    <a class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
            <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-fw fa-user"></i> Account </a>
          </li>
    
          <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
            <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
          </li> -->
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut();" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="p-4">  
    
  <br>
  
    <div class="row" style="margin-bottom: 3%;">  
  
      

      <div class="col-md-6 office-table">
        <div class="table-header row" [ngStyle]="backgroundStyle" style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
  
            <span class="table-caption">
              Offices
            </span>
            <a style="cursor:pointer" class="float-right" (click)="openModalForOrgSubOfficeAdd();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
  
          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <table *ngIf="officesExists" class="table table-striped table-bordered table-sm row-border hover" datatable
              [dtOptions]="officeOptions">
              <thead class="tableheader">
                <tr>
                  <th>#</th>
                  <th>Office</th>
                  <th>Type</th>
                  <th>Action</th>
                
                  
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let office of offices.offices,let i = index">
                  <td class="td-height">{{i+1}}</td>
                  <td class="td-height">
                    {{office.officeName}}, {{office.officeLocation}}
                  </td>
                  <td class="td-height">{{office.type}}</td>
                  <td class="td-height" style=" height: 2rem;">
                    <button *ngIf="office.activeStatus!== null && office.activeStatus" type="button"
                      class="btn btn-danger mr-2 btn-sm" (click)="updateOfficeStatus(office)">Deactivate</button>
                    <button *ngIf="office.activeStatus!== null && !office.activeStatus" type="button"
                      class="btn  btn-success mr-2 btn-sm" (click)="updateOfficeStatus(office)">Activate</button>
                    <a *ngIf="office.activeStatus !== null" style="cursor:pointer;padding-left: 1rem;"
                      (click)="editOffice(office);">
                      <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                    </a>
                    <a style="cursor:pointer" class="float-right" (click)="addWcaSubscription(office.id, office.officeName);">
                      <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
                    </a>
                  </td>
                 
                </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      </div>

      <div class="col-md-6">  
  
        <div class="table-header row" [ngStyle]="backgroundStyle" style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
  
            <span class="table-caption">
              Subscriptions
            </span>
           
  
          </div> 
        </div>
        
        <div class="card">
          <div class="card-body table-responsive">
            <table *ngIf="subsExist" class="table table-striped table-bordered table-sm row-border hover" datatable
              [dtOptions]="userOptions">
              <thead class="tableheader">
                <tr>
                  
                  <th>Office</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let lang of wca_subscriptions,let i = index">
                 
                  <td class="td-height">{{lang.officeName}}</td>
                  <td class="td-height">{{lang.startDateFormatted}}</td>
                  <td class="td-height">{{lang.endDateFormatted}}</td>
                  <td class="td-height"> 
                    <a *ngIf="lang.expiryStatus === 'Expired'" style="color: red;">Expired</a>
                    <a *ngIf="lang.expiryStatus === 'Expiring'" style="color: yellow;">Expiring in {{lang.daysLeft}} days</a>
                  </td>
                  <td>
                    <a style="cursor:pointer;padding-left: 1rem;"
                    (click)="editWcaSubscription(lang);">
                    <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                  </a>
                  </td>
                  

                </tr>
              </tbody>
            </table>
   
          </div>
        </div>
  
      </div>

    </div> 

    <div class="user-padding">
      <div class="row">
        <div class="col-md-12">
          <div class="table-header row" [ngStyle]="backgroundStyle" style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
            <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
              <span class="table-caption">
                Users
              </span>
              <a style="cursor:pointer" class="float-right" (click)="openModalForOfficeUserAdd();">
                <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
              </a>
    
            </div> 
          </div>
         
          
          <div class="card"> 
            <div class="card-body table-responsive">
              <table *ngIf="officeAdminsExists" class="table table-striped table-bordered table-sm row-border hover"
                datatable [dtOptions]="officeAdminOptions">  
                <thead class="tableheader">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Office</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let officeAdmin of superAdminUsers,let i = index">
                    <td class="td-height" style="width: 5rem;">
                      <span *ngIf="officeAdmin.active !== null">
                        {{i+1}}
                      </span>
                    </td>
                    <td class="td-height" style="width: 17rem;">{{officeAdmin.firstName}} {{officeAdmin.lastName}}</td>
                    <td class="td-height" style="width: 17rem;">{{officeAdmin.email}}</td>
                    <td class="td-height" style="width: 17rem;">
                      <span *ngIf="officeAdmin.userId !== null">
                        {{officeAdmin.officeDTO[0]?.officeName}}, {{officeAdmin.officeDTO[0]?.officeLocation}}
                      </span>
                    </td>
                    <td class="td-height" style="width: 17rem; height: 2rem;">
                      <button *ngIf="officeAdmin.active!== null && officeAdmin.active" type="button"
                        class="btn btn-danger mr-2  btn-sm"
                        (click)="updateUserStatus(officeAdmin,'activate')">Deactivate</button>
                      <button *ngIf="officeAdmin.active!== null && !officeAdmin.active" type="button"
                        class="btn btn-success mr-2" (click)="updateUserStatus(officeAdmin,'activate')">Activate</button>
                      <a *ngIf="officeAdmin.active !== null" style="cursor:pointer;padding-left: 1rem;"
                        (click)="editOfficeAdmin(officeAdmin);"> 
                        <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
  
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-template #addL let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Add Language</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="col-md-12">
          <label class="required" for="search">Language</label>
        <input class="form-control" required type="text" [(ngModel)]="botLanguage.language" 
        placeholder="Enter language" name="botLanguage.language" id="botLanguage.language">        
      </div>
        </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" (click)="addLanguage();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>

