import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { Class } from 'src/app/courseManagementClasses/class';
import { TeacherService } from 'src/app/service/teacher.service';
import { Attendance } from 'src/app/courseManagementClasses/attendance';
import { Student } from 'src/app/courseManagementClasses/student';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-mark-attendance',
  templateUrl: './mark-attendance.component.html',
  styleUrls: ['./mark-attendance.component.scss']
})
export class MarkAttendanceComponent implements OnInit {

  closeResult !: string;
  clicked = false;
  selectedClassId:any;
  classProductId : any;
  selectedClassName:any;
  mainAttendance : Attendance = new Attendance();

  attendanceList : Attendance[] = [];
  attendanceListForDifferentClass : Attendance[] = [];

  classes : Class[] = [];
  viewClasses = true;

  additionalStudents : Student[] = [];
  viewAdditionalStudents = false;
  
  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe,
    private toaster: ToastrService,
    private teacherService : TeacherService,private ngbModalService: NgbModal,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;

    @Input() fromParent: any;

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    
  ngOnInit(): void {
    this.selectedClassId = "";

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getTeacherClasses();

}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

getTeacherClasses(){
  this.teacherService.getTeacherClasssesWithStudents( environment.apiUrl + "teacher/getTeacherClasssesWithStudents").then(
    data=>{
      this.classes = data;
    });
}

searchInput = true;
searchValue : any;

getStudentsFromSubscriptionsForAttendance(){
  this.teacherService.getStudentsFromSubscriptionsForAttendance( environment.apiUrl + "teacher/getStudentsFromSubscriptionsForAttendance/" + this.searchValue + "/" + this.classProductId).then(
    data=>{
      this.additionalStudents = data;      
     
      if(this.additionalStudents.length !== 0  && this.additionalStudents !== undefined){

        let ids : string[] = [];
        this.attendanceList.forEach((att)=>{
          ids.push(att.fkStudentId);
        });

        this.attendanceListForDifferentClass.forEach((att)=>{
          ids.push(att.fkStudentId);
        });
        
        let uniqueStudents : Student[] = [];
        this.additionalStudents.forEach((addS)=>{
          if(ids.includes(addS.studentId)===false){
            uniqueStudents.push(addS);
          }
        });
  
        this.additionalStudents = [];
        this.additionalStudents = uniqueStudents;
        
        if(this.additionalStudents.length !== 0 && this.additionalStudents !== undefined){

          this.additionalStudents.forEach((student)=>{
            let att : Attendance = new Attendance();
            att.attendanceDate = this.mainAttendance.attendanceDate;
            att.attendanceFromDateTime = this.mainAttendance.attendanceFromDateTime;
            att.attendanceToDateTime = this.mainAttendance.attendanceToDateTime;
            att.fkClassId = this.selectedClassId;
            att.fkStudentId = student.studentId;
            att.studentName = student.studentName;
            att.markAttendance = true;
    
            this.attendanceListForDifferentClass.push(att);
          });
  
          this.viewAdditionalStudents = true;

        }else{
          this.toaster.error("Student not found !");
        }

      }else{
        this.toaster.error("Student not found !");
      }
    
    });
}

open(content: any) {
  this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
  
}



showStudents(){

  this.classes.forEach((classObj)=>{
    if(this.selectedClassId === classObj.classId){
      this.selectedClassName = classObj.name;
      this.classProductId = classObj.fkProduct;
      classObj.students.forEach((student)=>{
        let att : Attendance = new Attendance();
        att.attendanceDate = this.mainAttendance.attendanceDate;
        att.attendanceFromDateTime = this.mainAttendance.attendanceFromDateTime;
        att.attendanceToDateTime = this.mainAttendance.attendanceToDateTime;
        att.fkClassId = this.selectedClassId;
        att.fkStudentId = student.studentId;
        att.studentName = student.studentName;
        att.markAttendance = true;

        this.attendanceList.push(att);
      });

      this.viewClasses = false;
    }
  })

}

handleClick(studentId:any){
  this.attendanceList.forEach((att)=>{
    if(att.fkStudentId === studentId){
      att.markAttendance = !att.markAttendance;
    }
  });
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

onSubmit(){

  let finalAttendance : Attendance = new Attendance();
  finalAttendance.attendanceList = [];

  this.attendanceList.forEach((att)=>{
    if(att.markAttendance === true){
      finalAttendance.attendanceList.push(att);
    }
  });

  if(this.attendanceListForDifferentClass.length !== 0 && this.attendanceListForDifferentClass !== undefined){
    this.attendanceListForDifferentClass.forEach((att)=>{
      if(att.markAttendance === true){
        finalAttendance.attendanceList.push(att);
      }
    });
  }
 

  this.teacherService.markAttendance( environment.apiUrl + "attendance/markAttendance", finalAttendance).then(
    data=>{
      this.passEntry.emit("success");
      this.activeModal.close();
      this.toaster.success("Attendance marked successfully");
    });
}

closeUpdateModal(){
  this.activeModal.close();
} 

}
