import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TicketAction } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-action';
import { TicketActual } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-actual';
import { TicketSupportService } from 'src/app/BIZ_CRM_TICKET_SUPPORT/services/ticket-support.service';
import { Role } from 'src/app/models/role';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-create-ticket-action',
  templateUrl: './create-ticket-action.component.html',
  styleUrls: ['./create-ticket-action.component.scss']
})
export class CreateTicketActionComponent implements OnInit {

  clicked = false;
  ticketAction : TicketAction = new TicketAction();
  ticketActual : TicketActual = new TicketActual();
  reAssignUsers : any[] = [];
  loggedInUser : any; 
  theme : any;
  reassign = false;
  roles : Role[] = [];
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private sharedDataService : SharedDataService,
    private roleService : RolesServiceService,
    private ticketSupportService : TicketSupportService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

            filteredOptions!: Observable<any[]>;

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.ticketActual = this.fromParent.ticketActual;
    this.ticketAction.fkTicketActualId = this.ticketActual.ticketActualId;

  }

  myControl = new FormControl();

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.reAssignUsers.filter(option => option.userName.toLowerCase().includes(filterValue));
  }

  selectionChange(value : any){

    this.reassign = false;

   if(this.ticketAction.actionType === "Take Up Ticket"){

   }else if(this.ticketAction.actionType === "Reassign"){

    this.ticketSupportService.getUsersForTicketsReassignment(environment.apiUrl + "ticketSupport/getUsersForTicketsReassignment/" + this.ticketActual.ticketActualId).then(
      data=>{
     this.reAssignUsers = data;

     
     this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

     this.reassign = true;
      });
    
   }else if(this.ticketAction.actionType === "Work In Progress"){

  }else if(this.ticketAction.actionType === "Awaiting Response"){

  }else if(this.ticketAction.actionType === "Resolved"){

  }
    
  }

  setUser(userUroId : any){
    this.ticketAction.reassignedToUroId = userUroId;
  }

  onSubmit(){
        
    this.ticketSupportService.createTicketAction(environment.apiUrl + "ticketSupport/createTicketAction", this.ticketAction).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Ticket Action created successfully');
        this.passEntry.emit('success');
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
 