import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackQuestion } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-question';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { CreateFeedbackQuestionComponent } from '../create-feedback-question/create-feedback-question.component';
import { EditFeedbackQuestionComponent } from '../edit-feedback-question/edit-feedback-question.component';

@Component({
  selector: 'app-view-feedback-question',
  templateUrl: './view-feedback-question.component.html',
  styleUrls: ['./view-feedback-question.component.scss']
})
export class ViewFeedbackQuestionComponent implements OnInit {

  clicked = false;
  feedbackQuestions0 : FeedbackQuestion[] = [];
  feedbackQuestions1 : FeedbackQuestion[] = [];
  feedbackQuestions : FeedbackQuestion[] = [];
  addedQuestionIds : string[] = [];
  questionIds : string[] = [];
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();  
    
    this.addedQuestionIds = this.fromParent.addedQuestionIds;

    this.getQuestions();
  }

  getQuestions(){
    this.formService.getFeedbackQuestionsByOfficeId(environment.apiUrl + "feedbackQuestion/getByOfficeId").then(
      data=>{
        this.feedbackQuestions0 = [];
        this.questionIds = [];
        this.feedbackQuestions1 = [];
        this.feedbackQuestions = [];
        
        this.feedbackQuestions0 = data;

        let count = 0;
        this.feedbackQuestions0.forEach((val)=>{
          count++;
          this.questionIds.push(val.id);
        });
        
        if(count === this.feedbackQuestions0.length){
          this.getFilteredQuestions();
        }
      });
  }

  getFilteredQuestions(){
    let count = 0;
    this.addedQuestionIds.forEach((addedQuestionId)=>{
      count++;
      this.questionIds.forEach((productId,index)=>{
        if(productId==addedQuestionId)  this.questionIds.splice(index,1);
     });
    });  
    if(count === this.addedQuestionIds.length){
      this.questionIds.forEach((val)=>{
        this.feedbackQuestions0.forEach((qsn)=>{
          if(val === qsn.id){
            this.feedbackQuestions1.push(qsn);
          }
        });
      });
    }
    
  }

  handleClick(qsn : any) {
    let exists = false;
    let count = 0;
    this.feedbackQuestions.forEach((val)=>{
      count++;
      if(val.id === qsn.id){
        exists = true;
      }
    });
    if(count === this.feedbackQuestions.length){
      if(exists === false){

        this.feedbackQuestions.push(qsn);
        
      }else if(exists === true){
        this.feedbackQuestions = this.feedbackQuestions.filter(obj => obj !== qsn);   
    }
    
    }
  }

  editFeedbackQuestions(qsn : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFeedbackQuestionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      question : qsn
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getQuestions();
    });
  }

  createFeedbackQuestions(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateFeedbackQuestionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getQuestions();
    });
  }

  onSubmit(){
    this.passEntry.emit(this.feedbackQuestions);
  this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 
