import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { AddBotResponseComponent } from "../add-bot-response/add-bot-response.component";
import { CreateBotApiConfigComponent } from "../../bot_api_configuration/create-bot-api-config/create-bot-api-config.component";
import { BotApiConfiguration } from "src/app/BOT_CODE/bot_classes/bot-api-configuration";
import { Router } from "@angular/router";
import { FeedbackFormService } from "src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service";
import { FeedbackForm } from "src/app/BIZ_CRM_FEEDBACK/classes/feedback-form";
import { ResponseOptionSettingsComponent } from "../../bot_response_options/response-option-settings/response-option-settings.component";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotAdminNotification } from "src/app/BOT_CODE/bot_classes/bot-admin-notification";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { FormControl } from "@angular/forms";
import { BotNotificationConfiguration } from "src/app/BOT_CODE/bot_classes/bot-notification-configuration";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { BotValidationConfiguration } from "src/app/BOT_CODE/bot_classes/bot-validation-configuration";
import { CRMTaskConfiguration } from "src/app/CRM_MODULE/classes/crmtask-configuration";
import { CrmServiceService } from "src/app/CRM_MODULE/services/crm-service.service";
import { CrmList } from "src/app/CRM_MODULE/classes/crm-list";

@Component({
  selector: "app-bot-response-details",
  templateUrl: "./bot-response-details.component.html",
  styleUrls: ["./bot-response-details.component.scss"],
})
export class BotResponseDetailsComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  eligibleVariableList: BotResponse[] = [];
  showMatSpinner = false;
  crmMatSpinner = false;
  matSpinnerValidations = false;
  newBotvalidationConfig: BotValidationConfiguration =
    new BotValidationConfiguration();
  advConfigsVisible = false;
  crmAccess: any = false;

  constructor(
    private sharedDataService: SharedDataService,
    private botResponseService: BotResponseServiceService,
    private ngbModalService: NgbModal,
    public router: Router,
    private formService: FeedbackFormService,
    private botOfficeService: BotOfficeServiceService,
    public activeModal: NgbActiveModal,
    public toastr: ToastrService,
    private crmServiceService: CrmServiceService
  ) {}

  @Output() passEntryEdit: EventEmitter<any> = new EventEmitter();
  @Output() passEntrySetInteractiveButtons: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryRemoveInteractiveButtons: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryAliasUpdate: EventEmitter<any> = new EventEmitter();
  @Output() passEntrySetAdminNotificationMessage: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntrySetAdminNotesMessage: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryDeactivateAdminNotification: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryDeactivateAdminNotes: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryGlobalTempResponse: EventEmitter<any> = new EventEmitter();
  @Output() passEntryExtApiCallForResVariables: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryExtApiCallForResVariablesRemoval: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntrySetInteractiveLists: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntrySetManualChatNotification: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryDeactivateManualChatNotification: EventEmitter<any> =
    new EventEmitter();
  @Output()
  passEntryBotNotificationConfigurationStatusUpdate: EventEmitter<any> =
    new EventEmitter();
  @Output()
  passEntryBotValidationConfigurationStatusUpdate: EventEmitter<any> =
    new EventEmitter();
  @Output()
  passEntryBotValidationConfigurationAdd: EventEmitter<any> =
    new EventEmitter();
  @Output()
  passEntryCrmTaskConfigurationStatusUpdate: EventEmitter<any> =
    new EventEmitter();
  @Output() passEntryCrmListUpdate: EventEmitter<any> = new EventEmitter();

  @Input() fromParent: any;

  botResponse: BotResponse = new BotResponse();
  globalTemplateResCreation: any;
  botAdminNotif: BotAdminNotification = new BotAdminNotification();
  toppingsControl = new FormControl([]);
  searchText: any;
  officeWhatsappNumber: any;
  responseLink: any;
  existingNotifConfigAdminsAndAgents: any[] = [];
  existingCrmTaskConfigAdminsAndAgents: any[] = [];

  multiSelectValueChange(value: any) {}

  ngOnInit(): void {
    this.eligibleVariableList = this.fromParent.eligibleVariableList;

    this.newBotvalidationConfig.validationFor = "n";
    this.newBotvalidationConfig.validationType = "l";
    this.newBotvalidationConfig.maxCheck = 0;
    this.newBotvalidationConfig.minCheck = 0;

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.officeWhatsappNumber = this.fromParent.officeWhatsappNumber;
    this.crmAccess = this.fromParent.crmAccess;

    this.botAdminNotif.fkBotTemplateId = null;
    this.botResponse = this.fromParent.botResponse;

    if (
      this.botResponse.resAlias !== null &&
      this.botResponse.resAlias !== undefined
    ) {
      this.responseLink =
        "https://wa.me/" +
        this.officeWhatsappNumber +
        "?text=" +
        this.botResponse.resAlias;
    }

    this.botResponse.activeOptions = [];
    if (
      this.botResponse.options !== null &&
      this.botResponse.options.length > 0
    ) {
      this.botResponse.options.forEach((option) => {
        if (option.botResponseOptionsStatus === true) {
          this.botResponse.activeOptions.push(option);
        }
      });
    }

    if (
      this.botResponse.botNotificationConfigurations !== null &&
      this.botResponse.botNotificationConfigurations !== undefined &&
      this.botResponse.botNotificationConfigurations.length > 0
    ) {
      this.botResponse.botNotificationConfigurations.forEach((config) => {
        this.existingNotifConfigAdminsAndAgents.push(config.fkBotUserId);
      });
    }

    if (
      this.botResponse.crmTaskConfigs !== null &&
      this.botResponse.crmTaskConfigs !== undefined &&
      this.botResponse.crmTaskConfigs.length > 0
    ) {
      this.botResponse.crmTaskConfigs.forEach((config) => {
        this.existingCrmTaskConfigAdminsAndAgents.push(config.fkBotUserId);
      });
    }

    this.globalTemplateResCreation = this.fromParent.globalTemplateResCreation;

    this.getForms();

    this.formId = "";
  }

  createWhatsappNotificationConfigs() {
    this.showMatSpinner = true;
    if (
      this.selectedUsers !== null &&
      this.selectedUsers !== undefined &&
      this.selectedUsers.length > 0
    ) {
      let userIds: string[] = [];
      this.selectedUsers.forEach((user) => {
        userIds.push(user.botUserId);
      });

      this.botAdminNotif.fkBotResponseId = this.botResponse.botResponseId;
      this.botAdminNotif.userIds = userIds;

      this.botResponseService
        .assignSections(
          environment.apiUrl + "botOffice/createWhatsappNotificationConfigs",
          this.botAdminNotif
        )
        .then((res) => {
          userIds.forEach((uId) => {
            this.existingNotifConfigAdminsAndAgents.push(uId);
          });

          this.botAdminNotif = res;

          if (
            this.botResponse.botNotificationConfigurations === null ||
            this.botResponse.botNotificationConfigurations === undefined ||
            (this.botResponse.botNotificationConfigurations !== null &&
              this.botResponse.botNotificationConfigurations !== undefined &&
              this.botResponse.botNotificationConfigurations.length === 0)
          ) {
            this.botResponse.botNotificationConfigurations =
              this.botAdminNotif.botNotificationConfigurations;
          } else if (
            this.botResponse.botNotificationConfigurations !== null &&
            this.botResponse.botNotificationConfigurations !== undefined &&
            this.botResponse.botNotificationConfigurations.length > 0
          ) {
            this.botAdminNotif.botNotificationConfigurations.forEach(
              (config) => {
                this.botResponse.botNotificationConfigurations.push(config);
              }
            );
          }
          this.toastr.success("Notifications assigned successfully!");
          this.showMatSpinner = false;
        });
    } else {
      this.toastr.error("Choose Admins/Agents to proceed");
      this.showMatSpinner = false;
    }
  }

  gtt: any;
  makeGlobalTemplateResponse() {
    this.botResponseService
      .makeGlobalTemplateResponse(
        environment.apiUrl +
          "botConvo/makeGlobalTemplateResponse/" +
          this.botResponse.botResponseId +
          "/" +
          this.gtt
      )
      .then((res) => {
        this.toastr.success(
          "Response marked as Global Template Response successfully !"
        );
        this.botResponse.globalTemplateRes = true;
        this.botResponse.globalTemplateTag = this.gtt;
        this.passEntryGlobalTempResponse.emit(this.botResponse);
      });
  }

  unmarkGlobalTemplateResponse() {
    this.botResponseService
      .makeGlobalTemplateResponse(
        environment.apiUrl +
          "botConvo/unmarkGlobalTemplateResponse/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success(
          "Response un-marked as Global Template Response successfully !"
        );
        this.botResponse.globalTemplateRes = false;
        this.botResponse.globalTemplateTag = null;
        this.passEntryGlobalTempResponse.emit(this.botResponse);
      });
  }

  closeResult!: string;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  formId: any = "";
  attachFormLinkToResponse() {
    this.botResponseService
      .attachFormLinkToResponse(
        environment.apiUrl +
          "botConvo/attachFormLinkToResponse/" +
          this.botResponse.botResponseId +
          "/" +
          this.formId
      )
      .then((res) => {
        let resBotRes: BotResponse = new BotResponse();
        resBotRes = res;
        this.toastr.success("Form linked to Response successfully !");
        this.botResponse.fkFormActiveId = resBotRes.fkFormActiveId;
        this.botResponse.formActiveBaseUrl = resBotRes.formActiveBaseUrl;
        this.botResponse.fkFormName = resBotRes.fkFormName;
        this.passEntryGlobalTempResponse.emit(this.botResponse);
      });
  }

  unlinkAttachedFormFromResponse() {
    this.botResponseService
      .attachFormLinkToResponse(
        environment.apiUrl +
          "botConvo/unlinkAttachedFormFromResponse/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("Form unlinked to Response successfully !");
        this.botResponse.fkFormActiveId = null;
        this.botResponse.formActiveBaseUrl = null;
        this.botResponse.fkFormName = null;
        this.passEntryGlobalTempResponse.emit(this.botResponse);
      });
  }

  feedbackForms: FeedbackForm[] = [];
  getForms() {
    this.formService
      .getFeedbackFormsByOfficeId(
        environment.apiUrl + "feedbackForm/getByOfficeId"
      )
      .then((data) => {
        this.feedbackForms = data;
      });
  }

  goToAdminNotificationPage() {
    this.activeModal.close();
    this.router.navigate(["dashboard/cb-admin-notification"]);
  }

  editBotResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editBotResponse",
      botResponse: this.botResponse,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse = receivedEntry;
        this.passEntryEdit.emit(this.botResponse);
      }
    );
  }

  makeOptionsInteractiveListsWithoutGrouping() {
    if (
      this.botResponse.activeOptions !== null &&
      this.botResponse.activeOptions.length > 0 &&
      this.botResponse.activeOptions.length < 11
    ) {
      let valid = true;
      this.botResponse.activeOptions.forEach((optn) => {
        if (optn.responseOption.length > 20) {
          valid = false;
        }
      });
      if (valid === true) {
        let botResOption: BotResponseOptions = new BotResponseOptions();
        botResOption.interactiveListSectionsExist = false;
        botResOption.fkBotResponseId = this.botResponse.botResponseId;

        this.botResponseService
          .assignSections(
            environment.apiUrl +
              "botConvo/assignInteractiveListSectionsForResOptions",
            botResOption
          )
          .then((res) => {
            this.activeModal.close();
            this.toastr.success(
              "Options will be shared in the form of Interactive Lists"
            );
            this.passEntrySetInteractiveLists.emit(this.botResponse);
          });
      } else {
        this.toastr.error(
          "Length of active option should be equal to or less than 20"
        );
      }
    } else {
      this.toastr.error(
        "Number of active options should be equal to or less than 10"
      );
    }
  }

  makeOptionsInteractiveLists() {
    if (
      this.botResponse.activeOptions !== null &&
      this.botResponse.activeOptions.length > 0 &&
      this.botResponse.activeOptions.length < 11
    ) {
      let valid = true;
      this.botResponse.activeOptions.forEach((optn) => {
        if (optn.responseOption.length > 20) {
          valid = false;
        }
      });
      if (valid === true) {
        this.config.class = "modal-md";
        const modalRef = this.ngbModalService.open(
          ResponseOptionSettingsComponent,
          {
            size: "lg",
            scrollable: true,
            windowClass: "app-modal-window",
            keyboard: false,
          }
        );
        let data = {
          options: this.botResponse.options,
          context: "assign_sections_&_make_options_interactive_lists",
          botResponseId: this.botResponse.botResponseId,
        };
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
        modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
          this.passEntrySetInteractiveLists.emit(this.botResponse);
        });
      } else {
        this.toastr.error(
          "Length of active option should be equal to or less than 20"
        );
      }
    } else {
      this.toastr.error(
        "Number of active options should be equal to or less than 10"
      );
    }
  }

  makeOptionsInteractive() {
    if (
      this.botResponse.activeOptions !== null &&
      this.botResponse.activeOptions.length > 0 &&
      this.botResponse.activeOptions.length < 4
    ) {
      let valid = true;
      this.botResponse.activeOptions.forEach((optn) => {
        if (optn.responseOption.length > 20) {
          valid = false;
        }
      });
      if (valid === true) {
        this.botResponseService
          .makeOptionsTypeInteractive(
            environment.apiUrl +
              "botConvo/makeOptionsTypeInteractive/" +
              this.botResponse.botResponseId
          )
          .then((res) => {
            this.toastr.success(
              "Options display type set to Interactive buttons"
            );
            this.botResponse.resOptionsType = "interactive_buttons";
            this.passEntrySetInteractiveButtons.emit(this.botResponse);
          });
      } else {
        this.toastr.error(
          "Length of active option should be equal to or less than 20"
        );
      }
    } else {
      this.toastr.error(
        "Number of active options should be equal to or less than 3"
      );
    }
  }

  removeInteractiveOptionsType() {
    this.botResponseService
      .makeOptionsTypeInteractive(
        environment.apiUrl +
          "botConvo/removeInteractiveOptionsType/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("Sections removed");
        this.botResponse.resOptionsType = null;
        this.passEntryRemoveInteractiveButtons.emit(this.botResponse);
      });
  }

  adminNotificationMessage = null;
  adminNotificationTitle: any;
  adminNotificationMedia: any;
  saveAdminNotificationMessage() {
    let resp = new BotResponse();
    resp.botResponseId = this.botResponse.botResponseId;
    resp.adminNotificationMessage = this.adminNotificationMessage;
    resp.adminNotificationTitle = this.adminNotificationTitle;
    resp.adminNotificationMedia = this.adminNotificationMedia;

    if (
      this.adminNotificationMessage === null ||
      this.adminNotificationMessage === undefined
    ) {
      this.toastr.error("Message cannot be empty");
    } else {
      this.botResponseService
        .saveAdminNotificationMessage(
          environment.apiUrl + "botConvo/saveAdminNotificationMessage",
          resp
        )
        .then((res) => {
          if (res === true) {
            this.toastr.success("Message saved successfully");
            this.botResponse.createAdminNotification = true;
            this.botResponse.adminNotificationMessage =
              this.adminNotificationMessage;
            this.botResponse.adminNotificationTitle =
              this.adminNotificationTitle;
            this.botResponse.adminNotificationMedia =
              this.adminNotificationMedia;
            this.passEntrySetAdminNotificationMessage.emit(this.botResponse);
          } else {
            this.toastr.error("Title already exists !");
          }
        });
    }
  }

  saveManualChatNotificationMessage() {
    let resp = new BotResponse();
    resp.botResponseId = this.botResponse.botResponseId;
    this.botResponseService
      .saveAdminNotesMessage(
        environment.apiUrl + "botConvo/saveManualChatNotificationMessage",
        resp
      )
      .then((res) => {
        this.toastr.success("Notification saved successfully");
        this.botResponse.createManualChatNotification = true;
        this.passEntrySetManualChatNotification.emit(this.botResponse);
      });
  }

  deactivateManualChatNotification() {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/deactivateManualChatNotification/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("Manual Chat Notification Deactivated !");
        this.passEntryDeactivateManualChatNotification.emit(this.botResponse);
        this.botResponse.createManualChatNotification = false;
      });
  }

  adminNotesMessage = null;
  adminNotesTitle: any;
  adminNotesMedia: any;
  saveAdminNotesMessage() {
    let resp = new BotResponse();
    resp.botResponseId = this.botResponse.botResponseId;
    resp.adminNotesMessage = this.adminNotesMessage;
    resp.adminNotesTitle = this.adminNotesTitle;
    resp.adminNotesMedia = this.adminNotesMedia;

    if (
      this.adminNotesMessage === null ||
      this.adminNotesMessage === undefined
    ) {
      this.toastr.error("Message cannot be empty");
    } else {
      this.botResponseService
        .saveAdminNotesMessage(
          environment.apiUrl + "botConvo/saveAdminNotesMessage",
          resp
        )
        .then((res) => {
          if (res === true) {
            this.toastr.success("Message saved successfully");
            this.botResponse.createAdminNotes = true;
            this.botResponse.adminNotesMessage = this.adminNotesMessage;
            this.botResponse.adminNotesTitle = this.adminNotesTitle;
            this.botResponse.adminNotesMedia = this.adminNotesMedia;
            this.passEntrySetAdminNotesMessage.emit(this.botResponse);
          } else {
            this.toastr.error("Title already exists !");
          }
        });
    }
  }

  editAdminNotificationMessage() {
    let resp = new BotResponse();
    resp.botResponseId = this.botResponse.botResponseId;
    resp.adminNotificationMessage = this.botResponse.adminNotificationMessage;
    resp.adminNotificationTitle = this.botResponse.adminNotificationTitle;
    resp.adminNotificationMedia = this.botResponse.adminNotificationMedia;

    if (
      this.botResponse.adminNotificationMessage === null ||
      this.botResponse.adminNotificationMessage === undefined
    ) {
      this.toastr.error("Message cannot be empty");
    } else {
      this.botResponseService
        .saveAdminNotificationMessage(
          environment.apiUrl + "botConvo/saveAdminNotificationMessage",
          resp
        )
        .then((res) => {
          if (res === true) {
            this.toastr.success("Message edited successfully");
            this.passEntrySetAdminNotificationMessage.emit(this.botResponse);
          } else {
            this.toastr.error("Title already exists !");
          }
        });
    }
  }

  editAdminNotesMessage() {
    let resp = new BotResponse();
    resp.botResponseId = this.botResponse.botResponseId;
    resp.adminNotesMessage = this.botResponse.adminNotesMessage;
    resp.adminNotesTitle = this.botResponse.adminNotesTitle;
    resp.adminNotesMedia = this.botResponse.adminNotesMedia;

    if (
      this.botResponse.adminNotesMessage === null ||
      this.botResponse.adminNotesMessage === undefined
    ) {
      this.toastr.error("Message cannot be empty");
    } else {
      this.botResponseService
        .saveAdminNotificationMessage(
          environment.apiUrl + "botConvo/saveAdminNotesMessage",
          resp
        )
        .then((res) => {
          if (res === true) {
            this.toastr.success("Message edited successfully");
            this.passEntrySetAdminNotesMessage.emit(this.botResponse);
          } else {
            this.toastr.error("Title already exists !");
          }
        });
    }
  }

  createOrLinkApiConfigurationForResVars() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotApiConfigComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponse: this.botResponse,
      context: "addBotApiConfigAndLinkToResponseForVariables",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotApiConfiguration) => {
        this.botResponse.callExtApiForResVariables = true;
        this.botResponse.fkApiConfigIdForResVariables =
          receivedEntry.botApiConfigId;
        this.botResponse.fkApiConfigIdForResVariablesTitle =
          receivedEntry.title;
        this.passEntryExtApiCallForResVariables.emit(receivedEntry);
      }
    );
  }

  removeApiConfigForResVariables() {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/removeApiConfigForResVariables/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("API Configuration removed successfully !");
        this.botResponse.callExtApiForResVariables = false;
        this.botResponse.fkApiConfigIdForResVariables = null;
        this.botResponse.fkApiConfigIdForResVariablesTitle = null;
        this.passEntryExtApiCallForResVariablesRemoval.emit("remove");
      });
  }

  deactivateAdminNotification() {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/deactivateAdminNotification/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("Admin Notification Deactivated !");
        this.passEntryDeactivateAdminNotification.emit(this.botResponse);
        this.botResponse.createAdminNotification = false;
        this.botResponse.adminNotificationTitle = null;
        this.botResponse.adminNotificationMessage = null;
        this.botResponse.adminNotificationMedia = null;
        this.botResponse.botNotificationConfigurations = [];
        this.adminNotificationMessage = null;
        this.adminNotificationTitle = null;
        this.adminNotificationMedia = null;
      });
  }

  deactivateAdminNotes() {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/deactivateAdminNotes/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("Admin Notes Deactivated !");
        this.passEntryDeactivateAdminNotes.emit(this.botResponse);
        this.botResponse.createAdminNotes = false;
        this.botResponse.adminNotesTitle = null;
        this.botResponse.adminNotesMessage = null;
        this.botResponse.adminNotesMedia = null;

        this.adminNotesMessage = null;
        this.adminNotesTitle = null;
        this.adminNotesMedia = null;
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  selectedAll = false;
  selectedUsers: BotCustomerUser[] = [];
  allUsers: BotCustomerUser[] = [];
  selectAll(value: any, botUserObj: any) {
    // case 1
    if (value === "Select All" && this.selectedAll === false) {
      //
      let allUserIds: any[] = [];
      allUserIds.push(value);
      this.allUsers.forEach((user) => {
        this.selectedUsers.push(user);
        allUserIds.push(user.botUserId);
      });
      this.allUsers = [];
      this.toppingsControl.setValue(allUserIds);
      this.selectedAll = true;
      // case 2
    } else if (value === "Select All" && this.selectedAll === true) {
      //
      this.selectedUsers.forEach((user) => {
        this.allUsers.push(user);
      });
      this.selectedUsers = [];
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
      // case 3
    } else if (value !== "Select All" && this.selectedAll === true) {
      //
      this.selectedUsers.push(botUserObj);

      for (var i = this.allUsers.length - 1; i >= 0; --i) {
        if (this.allUsers[i].botUserId == botUserObj.botUserId) {
          this.allUsers.splice(i, 1);
        }
      }

      let selectedUserIds: any[] = this.toppingsControl.value;
      selectedUserIds.splice(selectedUserIds.indexOf("Select All"), 1);
      this.selectedAll = false;
      this.toppingsControl.setValue(selectedUserIds);
      // case 4
    } else if (value !== "Select All" && this.selectedAll === false) {
      //
      this.selectedUsers.push(botUserObj);
      for (var i = this.allUsers.length - 1; i >= 0; --i) {
        if (this.allUsers[i].botUserId == botUserObj.botUserId) {
          this.allUsers.splice(i, 1);
        }
      }
    }
  }

  onToppingRemoved(user: any) {
    this.allUsers.push(user);
    for (var i = this.selectedUsers.length - 1; i >= 0; --i) {
      if (this.selectedUsers[i].botUserId == user.botUserId) {
        this.selectedUsers.splice(i, 1);
      }
    }

    if (this.selectedAll === true) {
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
    }
  }

  resAlias: any = null;
  updateResponseAlias() {
    if (this.resAlias !== null) {
      this.botResponseService
        .makeOptionsTypeInteractive(
          environment.apiUrl +
            "botConvo/updateResponseAlias/" +
            this.botResponse.botResponseId +
            "/" +
            this.resAlias
        )
        .then((res) => {
          if (res === true) {
            this.toastr.success("Alias updated successfully !");
            this.botResponse.resAlias = this.resAlias;

            this.responseLink =
              "https://wa.me/" +
              this.officeWhatsappNumber +
              "?text=" +
              this.botResponse.resAlias;

            this.resAlias = null;
            this.passEntryAliasUpdate.emit(this.botResponse.resAlias);
          } else if (res === false) {
            this.toastr.error(
              "Alias already assigned for a different response, try a different alias"
            );
          }
        });
    } else {
      this.toastr.error("Add Alias to proceed !");
    }
  }

  botAgent: BotUser = new BotUser();
  getBotAgents() {
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBasicAgentDetailsByOffice"
      )
      .then((res) => {
        this.botAgent = new BotUser();
        this.botAgent = res;
      });
  }

  botAgentUroIdToAssign: any = "";
  setAgentAssignmentConfigToResponse() {
    if (
      this.botAgentUroIdToAssign !== "" &&
      this.botAgentUroIdToAssign !== null
    ) {
      this.botOfficeService
        .assignAgentToManualChat(
          environment.apiUrl +
            "botConvo/setAgentAssignmentConfigToResponse/" +
            this.botAgentUroIdToAssign +
            "/" +
            this.botResponse.botResponseId
        )
        .then((res) => {
          this.toastr.success("Configured successfully!");
          this.botAgent.users.forEach((agUser) => {
            if (agUser.uroId === this.botAgentUroIdToAssign) {
              this.botResponse.fkAgentUroIdToAssign =
                this.botAgentUroIdToAssign;
              this.botResponse.fkAgentFullNameToAssign = agUser.name;
              this.botAgentUroIdToAssign = null;
            }
          });
        });
    } else {
      this.toastr.error("Choose an Agent to assign !");
    }
  }

  removeAgentAssignmentConfigFromResponse() {
    this.botOfficeService
      .assignAgentToManualChat(
        environment.apiUrl +
          "botConvo/removeAgentAssignmentConfigFromResponse/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.toastr.success("Configuration updated successfully!");
        this.botResponse.fkAgentUroIdToAssign = null;
        this.botResponse.fkAgentFullNameToAssign = "";
        this.botAgentUroIdToAssign = null;
      });
  }

  deleteBotValidationConfig(vConfig: BotValidationConfiguration) {
    this.matSpinnerValidations = true;
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/deleteBotValidationConfig/" +
          vConfig.botValidationConfigId
      )
      .then((res) => {
        let newConfigs: BotValidationConfiguration[] = [];

        this.botResponse.resValidationConfigs.forEach((validConfig) => {
          if (
            validConfig.botValidationConfigId !== vConfig.botValidationConfigId
          ) {
            newConfigs.push(validConfig);
          }
        });

        this.botResponse.resValidationConfigs = [];
        this.botResponse.resValidationConfigs = newConfigs;

        this.toastr.success("Configuration updated successfully !");
        this.passEntryBotValidationConfigurationStatusUpdate.emit(newConfigs);
        this.matSpinnerValidations = false;
      });
  }

  createBotValidationConfig() {
    this.matSpinnerValidations = true;
    this.newBotvalidationConfig.fkBotResponseId =
      this.botResponse.botResponseId;
    this.botOfficeService
      .createBotValidationConfig(
        environment.apiUrl + "botConvo/createBotValidationConfig",
        this.newBotvalidationConfig
      )
      .then((res) => {
        let resConfig: BotValidationConfiguration =
          new BotValidationConfiguration();

        resConfig = res;

        if (
          this.botResponse.resValidationConfigs === null ||
          this.botResponse.resValidationConfigs === undefined
        ) {
          this.botResponse.resValidationConfigs = [];
        }
        this.botResponse.resValidationConfigs.push(resConfig);

        this.passEntryBotValidationConfigurationAdd.emit(
          this.botResponse.resValidationConfigs
        );

        this.newBotvalidationConfig.validationFor = "n";
        this.newBotvalidationConfig.validationType = "l";
        this.newBotvalidationConfig.maxCheck = 0;
        this.newBotvalidationConfig.minCheck = 0;

        this.toastr.success("Configured successfully!");
        this.matSpinnerValidations = false;
      });
  }

  goToAPIConfigsPage() {
    this.activeModal.close();
    this.router.navigate(["dashboard/bot/api-config"]);
  }

  getBotOfficeAdminsAndAgents() {
    this.botOfficeService
      .getBotUsers(environment.apiUrl + "botOffice/getBotOfficeAdminsAndAgents")
      .then((res) => {
        this.allUsers = [];
        this.selectedUsers = [];
        this.toppingsControl.setValue([]);

        let botOfficeAdminsAndAgents: BotCustomerUser = new BotCustomerUser();
        botOfficeAdminsAndAgents = res;
        if (this.existingNotifConfigAdminsAndAgents.length === 0) {
          this.allUsers = botOfficeAdminsAndAgents.users;
        } else {
          botOfficeAdminsAndAgents.users.forEach((user) => {
            if (
              this.existingNotifConfigAdminsAndAgents.includes(
                user.botUserId
              ) === false
            ) {
              this.allUsers.push(user);
            }
          });
        }
        this.selectedUsers = [];
      });
  }

  getBotOfficeAdminsAndAgentsForCrmTaskConfig() {
    this.botOfficeService
      .getBotUsers(environment.apiUrl + "botOffice/getBotOfficeAdminsAndAgents")
      .then((res) => {
        this.allUsers = [];
        this.selectedUsers = [];
        this.toppingsControl.setValue([]);

        let botOfficeAdminsAndAgents: BotCustomerUser = new BotCustomerUser();
        botOfficeAdminsAndAgents = res;
        if (this.existingCrmTaskConfigAdminsAndAgents.length === 0) {
          this.allUsers = botOfficeAdminsAndAgents.users;
        } else {
          botOfficeAdminsAndAgents.users.forEach((user) => {
            if (
              this.existingCrmTaskConfigAdminsAndAgents.includes(
                user.botUserId
              ) === false
            ) {
              this.allUsers.push(user);
            }
          });
        }
        this.selectedUsers = [];
      });
  }

  createCrmTaskConfigs() {
    this.crmMatSpinner = true;
    if (
      this.selectedUsers !== null &&
      this.selectedUsers !== undefined &&
      this.selectedUsers.length > 0
    ) {
      let userIds: string[] = [];
      this.selectedUsers.forEach((user) => {
        userIds.push(user.botUserId);
      });

      this.botAdminNotif.fkBotResponseId = this.botResponse.botResponseId;
      this.botAdminNotif.userIds = userIds;

      this.botResponseService
        .assignSections(
          environment.apiUrl + "botOffice/createCrmTaskConfigs",
          this.botAdminNotif
        )
        .then((res) => {
          userIds.forEach((uId) => {
            this.existingCrmTaskConfigAdminsAndAgents.push(uId);
          });

          this.botAdminNotif = res;

          if (
            this.botResponse.crmTaskConfigs === null ||
            this.botResponse.crmTaskConfigs === undefined ||
            (this.botResponse.crmTaskConfigs !== null &&
              this.botResponse.crmTaskConfigs !== undefined &&
              this.botResponse.crmTaskConfigs.length === 0)
          ) {
            this.botResponse.crmTaskConfigs = this.botAdminNotif.crmTaskConfigs;
          } else if (
            this.botResponse.crmTaskConfigs !== null &&
            this.botResponse.crmTaskConfigs !== undefined &&
            this.botResponse.crmTaskConfigs.length > 0
          ) {
            this.botAdminNotif.crmTaskConfigs.forEach((config) => {
              this.botResponse.crmTaskConfigs.push(config);
            });
          }
          this.toastr.success("Notifications assigned successfully!");
          this.crmMatSpinner = false;
        });
    } else {
      this.toastr.error("Choose Admins/Agents to proceed");
      this.crmMatSpinner = false;
    }
  }

  deleteBotNotificationConfig(notifConfig: BotNotificationConfiguration) {
    this.showMatSpinner = true;
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/deleteBotNotificationConfig/" +
          notifConfig.botNotificationConfigId
      )
      .then((res) => {
        const index: number = this.existingNotifConfigAdminsAndAgents.indexOf(
          notifConfig.fkBotUserId
        );
        if (index !== -1) {
          this.existingNotifConfigAdminsAndAgents.splice(index, 1);
        }

        let botNotificationConfigurations: BotNotificationConfiguration[] = [];
        this.botResponse.botNotificationConfigurations.forEach((config) => {
          if (
            config.botNotificationConfigId !==
            notifConfig.botNotificationConfigId
          ) {
            botNotificationConfigurations.push(config);
          }
        });
        this.botResponse.botNotificationConfigurations = [];
        this.botResponse.botNotificationConfigurations =
          botNotificationConfigurations;
        this.toastr.success("Configuration updated successfully !");
        this.passEntryBotNotificationConfigurationStatusUpdate.emit(
          notifConfig.botNotificationConfigId
        );
        this.showMatSpinner = false;
      });
  }

  deleteCrmTaskConfig(crmConfigToDelete: CRMTaskConfiguration) {
    this.crmMatSpinner = true;
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/deleteCrmTaskConfig/" +
          crmConfigToDelete.crmTaskConfigId
      )
      .then((res) => {
        const index: number = this.existingCrmTaskConfigAdminsAndAgents.indexOf(
          crmConfigToDelete.fkBotUserId
        );
        if (index !== -1) {
          this.existingCrmTaskConfigAdminsAndAgents.splice(index, 1);
        }

        let crmTaskConfigs: CRMTaskConfiguration[] = [];
        this.botResponse.crmTaskConfigs.forEach((config) => {
          if (config.crmTaskConfigId !== crmConfigToDelete.crmTaskConfigId) {
            crmTaskConfigs.push(config);
          }
        });
        this.botResponse.crmTaskConfigs = [];
        this.botResponse.crmTaskConfigs = crmTaskConfigs;
        this.toastr.success("Configuration updated successfully !");
        this.passEntryCrmTaskConfigurationStatusUpdate.emit(crmTaskConfigs);
        this.crmMatSpinner = false;
      });
  }

  crmLists: CrmList[] = [];
  getCrmLists() {
    this.crmServiceService
      .getMethod(environment.apiUrl + "bizCrm/getCrmListsByOfficeId")
      .then((data) => {
        let resObj: CrmList = new CrmList();
        resObj = data;
        this.crmLists = resObj.lists;
      });
  }

  crmListIdToAttach: any = null;
  linkCrmListToBotResponse() {
    if (
      this.crmListIdToAttach !== null &&
      this.crmListIdToAttach !== undefined
    ) {
      this.botResponse.fkCrmListId = this.crmListIdToAttach;
      this.botResponseService
        .assignSections(
          environment.apiUrl + "botConvo/linkCrmListToBotResponse",
          this.botResponse
        )
        .then((res) => {
          this.crmLists.forEach((llist) => {
            if (llist.crmListId === this.crmListIdToAttach) {
              this.botResponse.fkCrmListName = llist.listName;
            }
          });
          this.toastr.success("List linked successfully !");
          this.passEntryCrmListUpdate.emit(this.botResponse);
        });
    } else {
      this.toastr.error("Please choose List to proceed !");
    }
  }

  deactivateCrmTaskCreation() {
    this.crmMatSpinner = true;
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/deactivateCrmTaskCreation/" +
          this.botResponse.botResponseId
      )
      .then((res) => {
        this.existingCrmTaskConfigAdminsAndAgents = [];
        this.botResponse.crmTaskConfigs = [];
        this.botResponse.fkCrmListId = null;
        this.botResponse.fkCrmListName = null;
        this.toastr.success("Configuration updated successfully !");
        this.passEntryCrmListUpdate.emit(this.botResponse);
        this.crmMatSpinner = false;
      });
  }
}
