<div  [style.background-color]='getColor()'  class="modal-header">
   
    <h4 *ngIf="fromParent.context==='setupCallbackUrl'"
    style="color: white;" class="modal-title pull-left">Setup Callback Url for your app</h4>

    <h4 *ngIf="fromParent.context==='savePhoneNumberID'"
    style="color: white;" class="modal-title pull-left">Save Phone Number Id</h4>

    <h4 *ngIf="fromParent.context==='setupOfficeWhatsappNumber'"
    style="color: white;" class="modal-title pull-left">Save Phone Number</h4>

    <h4 *ngIf="fromParent.context==='createPermanentToken'"
    style="color: white;" class="modal-title pull-left">Save Permanent Token</h4>

    <h4 *ngIf="fromParent.context==='setOfficeDefaultLanguage'"
    style="color: white;" class="modal-title pull-left">Set Default Language for Office</h4>

    <h4 *ngIf="fromParent.context==='addLanguagesToOffice'"
    style="color: white;" class="modal-title pull-left">Add Language Option to Office</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 








  <div class="modal-body">
      <div class="card-body"> 

      <div *ngIf="fromParent.context==='setupCallbackUrl'">

        <div>
          <a>
            <img src="/assets/images/snapshots/setCallbackUrlSS.PNG" width="650" height="300"
            alt="" loading="lazy">
         </a>
        </div>
<br>

<a>
  Link : 
</a>
<a style="color: blue;" href="https://developers.facebook.com/apps/2396741533813500/whatsapp-business/wa-settings/" target="_blank">
   https://developers.facebook.com/apps/2396741533813500/whatsapp-business/wa-settings/
</a>

        <br>
        <br>
       
       <a style="font-weight: bold;"> Step 1 </a>: In Whatsapp settings page, Go to Whatsapp -> Configuration. <br>
       <a style="font-weight: bold;"> Step 2 </a>: In Configuration section, click "Edit", enter the Callback Url and the token provided below to verify the callback url, click "Verify and save" <br> <br>
       <a style="font-weight: bold;"> Step 3 </a>: Click "Manage" below Edit button, click "Subscribe" of messages, then click "Done" <br> <br>

        <a> <a style="font-weight: bold;"> Callback Url </a>: {{link}}
          <a [cdkCopyToClipboard]="link" matTooltip="Copy Callback Url" style="cursor:pointer;">
            <img src="/assets/images/icons/copyText.png" width="22" height="22"
            alt="" loading="lazy">
         </a>
        </a>
<br>
        <a> 
          <a style="font-weight: bold;"> Token </a>: {{token}}
          <a [cdkCopyToClipboard]="token" matTooltip="Copy Token" style="cursor:pointer;">
            <img src="/assets/images/icons/copyText.png" width="22" height="22"
            alt="" loading="lazy">
         </a>
        </a>

      </div>

      <div *ngIf="fromParent.context==='savePhoneNumberID'">

        <div>
          <a>
            <img src="/assets/images/snapshots/phoneNumberId.PNG" width="650" height="300"
            alt="" loading="lazy">
         </a>
        </div>
<br>

<a>
  Link : 
</a>
<a style="color: blue;" href="https://developers.facebook.com/apps/2396741533813500/whatsapp-business/wa-dev-console/" target="_blank">
  https://developers.facebook.com/apps/2396741533813500/whatsapp-business/wa-dev-console/
</a>

        <br>
        <br>
       
       <a style="font-weight: bold;"> Step 1 </a>: In Whatsapp settings page, Go to Whatsapp -> Getting Started. <br>
       <a style="font-weight: bold;"> Step 2 </a>: In "Send and receive messages" card, choose your phone number from the drop-down in the "From" field <br>
       <a style="font-weight: bold;"> Step 3 </a>: Copy the "Phone number ID" present below the drop-down and click here to 
       <a *ngIf="wcaPhoneNumberID === null || wcaPhoneNumberID === undefined" style="color: blue;cursor: pointer;" 
       (click)="open(savePhnNoId);"> Save Phone Number Id </a> <br>
       <a *ngIf="wcaPhoneNumberID !== null && wcaPhoneNumberID !== undefined" style="color: blue;cursor: pointer;" 
       (click)="toasterMessage('Phone Number ID already set !');"> Save Phone Number Id </a> <br>

      </div>

      <div *ngIf="fromParent.context==='setupOfficeWhatsappNumber'">

  


     
       
<a style="font-weight: bold;"> </a> Enter the number along with the country code. <br>
       <a style="font-weight: bold;"> </a> Click here to <br>
       <a *ngIf="whatsappNumber === null || whatsappNumber === undefined" style="color: blue;cursor: pointer;" 
       (click)="open(savewhatsappNumberModal);"> Save Phone Number </a> <br>
       <a *ngIf="whatsappNumber !== null && whatsappNumber !== undefined" style="color: blue;cursor: pointer;" 
       (click)="toasterMessage('Phone Number already set !');"> Save Phone Number </a> <br>

      </div>

      <div *ngIf="fromParent.context==='createPermanentToken'">

<br>    <br>    <br>
       
       <a style="font-weight: bold;"> Step 1 </a>: 
       Go to
       <a style="color: blue;" href="https://developers.facebook.com/" target="_blank">
         https://developers.facebook.com/ 
       </a>
       and click on "My Apps" in the header <br>
       <a style="font-weight: bold;"> Step 2 </a>: Click on the Business name in "Business : Test Business" of the app, you will be taken to the below screen <br>
       <div>
        <a>
          <img src="/assets/images/snapshots/businessSettings.PNG" width="650" height="300"
          alt="" loading="lazy">
       </a>
      </div>
<br>
       <a style="font-weight: bold;"> Step 3 </a>: Click on "System Users", then click "Add" button in System users section, a pop-up will open. <br>
       <a style="font-weight: bold;"> Step 4 </a>: Enter email address, choose type of access and add system user. <br>
       <a style="font-weight: bold;"> Step 5 </a>: Click on the user, click on "Add assets"<br>
       <a style="font-weight: bold;"> Step 6 </a>: Click on the "Apps", choose the app , select the permissions and click on "Save changes"<br>
       <a style="font-weight: bold;"> Step 7 </a>: Click on the "Generate New Token", 
       choose the app, make sure "whatsapp_business_management" and "whatsapp_business_messaging" are selected
        and click on generate token<br>
        <a style="font-weight: bold;"> Step 8 </a>: Copy the generated token and click here to
        <a *ngIf="wcaPermanentToken === null || wcaPermanentToken === undefined" style="color: blue;cursor: pointer;" 
        (click)="open(savePermtToken);"> Save Permanent Token </a> <br>
        <a *ngIf="wcaPermanentToken !== null && wcaPermanentToken !== undefined" style="color: blue;cursor: pointer;" 
        (click)="toasterMessage('Permanent Token already set !');"> Save Permanent Token </a> <br>




      
    
      </div>


      <form #botResponseOptionForm="ngForm">  
        <div *ngIf="fromParent.context==='setOfficeDefaultLanguage'">
          <div>
            <label for="fkBotLanguageId">Language</label>
            <select required class="form-control" [(ngModel)]="defLangId">
              <option value="chooselanguage" disabled>Choose Language</option>
              <option *ngFor="let lang of botLanguages" [ngValue]="lang.botLanguageId">{{lang.language}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="fromParent.context==='addLanguagesToOffice'">
          <div>
            <label for="fkBotLanguageId">Language</label>
            <select required class="form-control" [(ngModel)]="defLangId">
              <option value="chooselanguage" disabled>Choose Language</option>
              <option *ngFor="let lang of botLanguages" [ngValue]="lang.botLanguageId">{{lang.language}}</option>
            </select>
          </div>
        </div>
      </form>


      </div>
  </div>


  <ng-template #savePhnNoId let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Phone Number ID</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="savePhoneNumberID()" #aqForm="ngForm"> 
        
          <div class="col-md-12">
          <label class="required" for="phnNoId">Phone Number ID</label>
          <input maxlength="250" minlength="15" #activityPlannerName="ngModel" required class="form-control" type="text" name="phnNoId"  id="phnNoId" [(ngModel)]="phnNoId">
          <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Enter Phone Number ID</div>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="savePhoneNumberID();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>

  <ng-template #savewhatsappNumberModal let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Phone Number ID</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="savewhatsappNumber()" #aqForm="ngForm"> 
        
          <div class="col-md-12">
          <label class="required" for="whatsappNumber">Phone Number</label>
          <input #activityPlannerName="ngModel" required class="form-control" type="text"
           name="whatsappNumber"  id="whatsappNumber" [(ngModel)]="whatsappNumber">
          <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Enter Phone Number</div>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="savewhatsappNumber();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>

  <ng-template #savePermtToken let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Permanent Token</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="savePermanentToken()" #aqForm="ngForm"> 
        
          <div class="col-md-12">
          <label class="required" for="permanentToken">Permanent Token</label>
          <input maxlength="1500" minlength="15" #permanentToken0="ngModel" required class="form-control" type="text" name="permanentToken"  id="permanentToken" [(ngModel)]="permanentToken">
          <div class="alert alert-danger" *ngIf="permanentToken0.touched && !permanentToken0.valid">Enter Permanent Token</div>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="savePermanentToken();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>


  <div *ngIf="fromParent.context==='setOfficeDefaultLanguage'" class="modal-footer">
    <button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
     (click)="setOfficeDefaultLanguage(); clicked=true">Save</button>
  </div>

  <div *ngIf="fromParent.context==='addLanguagesToOffice'" class="modal-footer">
    <button [disabled]="clicked" [disabled]="!botResponseOptionForm.valid" class="btn btn-sm btn-primary float-right" 
     (click)="addLanguagesToOffice(); clicked=true">Save</button>
  </div>