<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Record & Share Voice Note</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body" style="text-align: center;">
    <div class="card-body">

      <div style="text-align: center;">

        <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerEnabled===true"></mat-spinner>

        <div *ngIf="matSpinnerEnabled===false">

        <a style="cursor: pointer;" *ngIf="!isRecording && !blobUrl" (click)="startRecording()"
    matTooltip="Start Recording" matTooltipPosition="above">
      <img src="/assets/images/icons/microphone.png" 
      alt="user" height="60" width="60">
    </a>

    <a style="cursor: pointer;" *ngIf="isRecording && !blobUrl" (click)="stopRecording()"
    matTooltip="Stop Recording" matTooltipPosition="above">
      <img src="/assets/images/icons/stop-button.png" 
      alt="user" height="60" width="60">
    </a>

        
    <a style="cursor: pointer;" *ngIf="!isRecording && blobUrl" (click)="clearRecordedData()"
    matTooltip="Clear Recording" matTooltipPosition="above">
      <img src="/assets/images/icons/bin.png" 
      alt="user" height="60" width="60">
    </a>
       
      
       
      
        <!-- <br>
        <button style="width: auto;" class="btn btn-info" *ngIf="!isRecording && blobUrl" (click)="download()">Download Recording</button> -->
      
       
        <br *ngIf="isRecording && !blobUrl">
        <br *ngIf="isRecording && !blobUrl">
        <div style="font-weight: bold;" *ngIf="isRecording && !blobUrl"> {{recordedTime}} </div>
       
        <div *ngIf="!isRecording && blobUrl">
          <br>
          <audio controls>
            <source [src]="blobUrl" type="audio/webm">
          </audio>
        </div>

        <div *ngIf="!isRecording && blobUrl">
          <br>
          <button [disabled]="clicked" (click)="uploadVoiceToWhatsappCloudiApiAndShareToUSer();" style="width: 100%;" class="btn btn-info">Send Voice Note</button>
        </div>
     
      </div>

      </div>
      
      
    
      

    </div>
  </div>


  