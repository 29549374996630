export class BotLangResponse {
 
    botLangResId:any;
	botLangResStatus:any;
	fkBotLanguageId:any;
	fkBotResponseId:any;
	langResponse:any;

	fkBotLanguage:any;
	list : BotLangResponse[] = [];
	interactiveListButtonName:any;
}
