
  <div [ngStyle]="backgroundStyle" class="modal-header">
<h4  style="width: 50%;color: white;"><img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">{{details.studentName}}</h4>


    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

 
<div class="modal-body" style="overflow-x: hidden;">
 
  
<h3 *ngIf="activitiesExist === false">No Timeline to display !</h3>
<ul class="timeline" *ngIf="showTimeline === true">
  <li *ngFor="let act of allActivitiesList">
    <div class="direction-l">
      <div class="flag-wrapper">

        <span *ngIf="act.studentActivityPlannerCompletionStatus === '0'" class="hexa"></span>
        <span *ngIf="act.studentActivityPlannerCompletionStatus === '2'" class="hex1"></span>
        <span *ngIf="act.studentActivityPlannerCompletionStatus === '1'" class="hex2"></span>

        <span class="flag">
           <a><img style=" border-radius: 50%;" height="25" width="25" [src]="act.safeUrl" alt="activity image">{{act.activityPlannerName}}</a>
        </span>
       
      </div>
      <div style="border: transparent;" *ngIf="act.studentAttempts > 0" class="desc">Attempts : {{act.studentAttempts}}</div>
      <div class="desc">{{act.activityPlannerDescription}}</div>
    </div>
  </li>

 
</ul>

</div>

