import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { CrmTask } from "../../classes/crm-task";

@Component({
  selector: "app-crm-tasks-modal",
  templateUrl: "./crm-tasks-modal.component.html",
  styleUrls: ["./crm-tasks-modal.component.scss"],
})
export class CrmTasksModalComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;
  crmTask: CrmTask = new CrmTask();

  constructor(
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  ngOnInit(): void {
    if (this.fromParent.context === "CrmTask Details") {
      this.crmTask = this.fromParent.crmTask;
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
