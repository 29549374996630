import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditCombinedParentTeacherComponent } from 'src/app/course-management/combinedParentTeacher/edit-combined-parent-teacher/edit-combined-parent-teacher.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { GetAccDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-acc-details';
import { SubscriptionModalComponent } from 'src/app/dashboards/classDashboards/subscription-modal/subscription-modal.component';
import { ImageEditPopupComponent } from 'src/app/dashboards/combinedParentTeacher/image-edit-popup/image-edit-popup.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-account-page',
  templateUrl: './show-account-page.component.html',
  styleUrls: ['./show-account-page.component.scss']
})
export class ShowAccountPageComponent implements OnInit {

  showSpinner : boolean = true;

  safeParentImageUrl : any;
  safeChildImageUrl : any;
  unsafeParentImageUrl : any;
  unsafeChildImageUrl : any;
  loggedUser: any;
  loggedInUser : any;
  navBarTitle : any;
  constructor(private combinedPTCService : CombinedParentTeacherService,
              private route : ActivatedRoute,
              private router : Router,
              private datePipe : DatePipe,
              private ngbModalService: NgbModal,
              public bsModalRef: BsModalRef,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    currentRole = "PARENT_MENTOR";

  gridColumns = 3;

  parentUserId!: string; 

  accDetails: GetAccDetails = new GetAccDetails;

  viewAddress = false;

  ngOnInit(): void {

    this.navBarTitle = GlobalClass.navbarTitle;
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);

  this.parentUserId =  this.route.snapshot.params[('parentUserId')];
  
  this.getAccDetails();

  } 

  getAccDetails(){
this.showSpinner=true;
    this.combinedPTCService.getDetails( environment.apiUrl + "combinedPTC/getAccountDetails/" + this.parentUserId).then(
      data=>{
        this.accDetails=data;

        this.imageService.getUserImage(this.accDetails.parentImage).subscribe(
          data=>{
            this.unsafeParentImageUrl = URL.createObjectURL(data);
            this.safeParentImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeParentImageUrl);
          });

          this.accDetails.children.forEach((child)=>{
            this.imageService.getUserImage(child.childImage).subscribe(
              data=>{
                this.unsafeChildImageUrl = URL.createObjectURL(data);
                child.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeChildImageUrl);

                child.subscriptions.forEach((sub)=>{
                  if(sub.startedDate!==null){
                    sub.startedDate = this.datePipe.transform(sub.startedDate, "dd-MM-yyyy");
                  }
                  if(sub.endedDate!== null){
                    sub.endedDate = this.datePipe.transform(sub.endedDate, "dd-MM-yyyy");
                  }
                });
              

              });  
          });
          this.showSpinner=false;
      });

  }

  editDetails(parentUserId : string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditCombinedParentTeacherComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      parentUserId: parentUserId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
this.getAccDetails();
    });  

  }

  viewSubscriptions(parentUserId : string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SubscriptionModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      userId : parentUserId,
      firstName : this.accDetails.parentFirstName,
      lastName : this.accDetails.parentLastName,
      context  : "SUBSCRIPTIONS"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
    });  

  }

  viewInvoices(parentUserId : string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SubscriptionModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      userId : parentUserId,
      firstName : this.accDetails.parentFirstName,
      lastName : this.accDetails.parentLastName,
      context  : "INVOICES"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
    });  

  }

  viewPayments(parentUserId : string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SubscriptionModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      userId : parentUserId,
      firstName : this.accDetails.parentFirstName,
      lastName : this.accDetails.parentLastName,
      context  : "PAYMENTS"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : AddCombinedParentTeacher) => {
    });  

  }

  editImage(userId : string , image : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ImageEditPopupComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : userId,
      imageName : image
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getAccDetails();
    });  
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.parentUserId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  reloadPage() {
    window.location.reload(); 
 }

 logOut(): void {
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
   email : this.loggedInUser.email 
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });  
}

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
}
