import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/app/customer';
import { CustomerService } from 'src/app/service/customer.service';
  
@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  customerId!: string;
customer : Customer = new Customer();

  constructor(private customerService : CustomerService,
    private route : ActivatedRoute,
    private router : Router) { }

  ngOnInit(): void {
    this.customerId = this.route.snapshot.params['customerId'];
    this.customerService.getCustomerById(this.customerId).subscribe(data=>{
      this.customer = data;
    }, error => console.log(error));
  }
 
  onUpdate(){

  }
  goToCustomerList(){
    this.router.navigate(['/customerList']);
  }
}
