<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Preview</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body" style="text-align: center;">
   

    <mat-spinner style="margin:0 auto;" *ngIf="mediaLoaded===false"></mat-spinner>

       <div *ngIf="mediaLoaded===true">

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="mimeType === 'image' || mimeType === 'image/jpeg' || mimeType === 'image/png'">
        <a style="text-align: center;">
            <img [src]="safeImageUrl" height="200" width="200">
          </a>
        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="mimeType === 'video' || mimeType === 'audio'
        || mimeType === 'video/mp4' || mimeType === 'audio/mp4'
        || mimeType === 'video/3gp' || mimeType === 'audio/aac'
        || mimeType === 'audio/mpeg'
        || mimeType === 'audio/ogg'">
          <a style="text-align: center;">
            
           <video *ngIf="playVideo" muted autoplay width="320" height="240" controls>
            <source [src]="urlSafe" type="video/mp4">
            <source [src]="urlSafe" type="video/ogg">
          </video>

          </a>
        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="mimeType === 'document' || mimeType === 'text/plain' || mimeType === 'application/pdf' || mimeType === 'application/vnd.ms-powerpoint'
        || mimeType === 'application/msword' || mimeType === 'application/vnd.ms-excel' || mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        || mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">

          <a [href]="pdfFile" id="link" style="color: blue;"
          download="file.pdf">Click here to Download File for Preview</a> 

        </div>
      </div>
      
   </div>
   
  