import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { environment } from 'src/environments/environment';
import { TableUtil } from 'src/app/table-util';
import { EditFeedbackFormComponent } from 'src/app/BIZ_CRM_FEEDBACK/modals/feedback-form/edit-feedback-form/edit-feedback-form.component';
import { CreateFeedbackFormComponent } from 'src/app/BIZ_CRM_FEEDBACK/modals/feedback-form/create-feedback-form/create-feedback-form.component';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { ViewBotFormActivesComponent } from 'src/app/BOT_CODE/bot_modals/bot_forms/view-bot-form-actives/view-bot-form-actives.component';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { DatePipe } from '@angular/common';
import { CreateBotFormActiveComponentComponent } from 'src/app/BOT_CODE/bot_modals/bot_forms/create-bot-form-active-component/create-bot-form-active-component.component';

@Component({
  selector: 'app-bot-form-management-home',
  templateUrl: './bot-form-management-home.component.html',
  styleUrls: ['./bot-form-management-home.component.scss']
})
export class BotFormManagementHomeComponent implements OnInit {

  currentRole = "BOT_OFFICE_ADMIN";
  customerLoaderMessage!: string;
  customerExists: boolean = false;
  ticketsExists: boolean = false;
  extTicketSupportUrl : any;
  customerLoader: boolean = false;
  roleLoader = false;
  userName ! : string;
  feedbackOptions : any;
  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  customerId: any;
  userId!: string
  navbarTitle: any;
  feedbackForms : FeedbackForm[] = [];

  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private formService : FeedbackFormService,
              private datePipe: DatePipe, 
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService ) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    formActives : FormActive[] = [];

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getForms();
    this.getFormActives();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.feedbackOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false,
      paging: false,
      searching: false 
    };
  }

  historyExists=false;
  getFormActives(){
   
    this.formService.getAllFormActivesByFormId(environment.apiUrl +"formActive/getAllBotFormActivesByOfficeId").then(
      res=>{
        this.formActives = res;

        if(this.formActives !== null && this.formActives.length > 0){
          this.historyExists = true;
        }
        this.formActives.forEach((fa)=>{
          fa.formattedFromDate = new Date(fa.fromDate);
          fa.formattedFromDate = this.datePipe.transform(fa.formattedFromDate, "dd-MM-yyyy");
          fa.formattedToDate = new Date(fa.toDate);
          fa.formattedToDate = this.datePipe.transform(fa.formattedToDate, "dd-MM-yyyy");

          let dd = fa.executionTimestamp;
          fa.executionTimestamp = new Date(dd[0], dd[1] - 1, dd[2], dd[3], dd[4]);
          fa.executionTimestamp = this.datePipe.transform(fa.executionTimestamp, 'dd-MM-yyyy hh:mm');
        })        
      });
  }

  explore(formActiveId : any){
    this.router.navigate(['dashboard/bot/forms/form-history',formActiveId]);
  }

  exploreFormCombinedHistory(formId : any){
    this.router.navigate(['dashboard/bot/forms/form-response-history',formId]);
  }

  getForms() {
    this.formService.getFeedbackFormsByOfficeId(environment.apiUrl + "feedbackForm/getByOfficeId").then(
      data=>{
        this.feedbackForms = data; 
        if(this.feedbackForms !== null && this.feedbackForms.length > 0){
          this.customerExists = true;
        }
      });
  }

  dataSource = this.feedbackForms
  exportArray() {
    this.dataSource = this.feedbackForms;   
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      FormName: x.name,
      Type: x.type,
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

 

  addForm(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateFeedbackFormComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getForms();
    });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
  viewFormActives(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewBotFormActivesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });   
  }

  addFormActive(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateBotFormActiveComponentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getFormActives();
    });   
  }

  editForm(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFeedbackFormComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getForms();
    });   
  }


  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
  }
    
  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

}