


   <div  [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Compute Tax</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form (ngSubmit)="onSubmit()" #invoiceForm="ngForm"> 
             

     
  
          <div class="form-group row">

            <div class="col-md-6">
                <label>Tax</label>
                <div class="form-check" *ngFor="let tax of taxes">
                    <input (click)="handleClick(tax);" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{tax.name}}
                    </label>
                  </div>
            </div>

            <div class="col-md-6">
                <label>Cess</label>
                <div class="form-check" *ngFor="let tax of cess">
                    <input (click)="handleClick(tax);" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{tax.name}}
                    </label>
                  </div>
            </div>

          </div>
             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Compute</button>
      </div>