import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';
import { BotUser } from 'src/app/BOT_CODE/bot_classes/bot-user';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-timezone-selector-modal',
  templateUrl: './timezone-selector-modal.component.html',
  styleUrls: ['./timezone-selector-modal.component.scss']
})
export class TimezoneSelectorModalComponent implements OnInit {

  loggedInUser : any;
  theme : any; 

  constructor(private botOfficeService : BotOfficeServiceService,
     private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    countryCodes : any[] = [];

    timezone_json0 : any;
     
    ngOnInit(): void {
               
    this.countryCodes = GlobalClass.CountryCodes;
    this.timezone_json0 = GlobalClass.timezone_json;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
  }



  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}