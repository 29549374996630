import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotCategory } from "src/app/BOT_CODE/bot_classes/bot-category";

@Component({
  selector: "app-create-bot-category",
  templateUrl: "./create-bot-category.component.html",
  styleUrls: ["./create-bot-category.component.scss"],
})
export class CreateBotCategoryComponent implements OnInit {
  matSpinnerActive = false;
  clicked = false;
  loggedInUser: any;
  theme: any;
  context: any;

  constructor(
    private sharedDataService: SharedDataService,
    private botOfficeService: BotOfficeServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  @Input() fromParent: any;

  botCategory: BotCategory = new BotCategory();

  ngOnInit(): void {
    this.context = this.fromParent.context;

    if (this.context === "create parent category") {
      this.botCategory.parentId = null;
    }

    if (this.context === "create sub category") {
      this.botCategory.parentId = this.fromParent.parentId;
    }

    if (this.context === "edit category") {
      let tempCatg: BotCategory = new BotCategory();
      tempCatg = this.fromParent.botCategory;
      this.botCategory.botCategoryId = tempCatg.botCategoryId;
      this.botCategory.name = tempCatg.name;
      this.botCategory.description = tempCatg.description;
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  createBotCategory() {
    this.clicked = true;
    this.botOfficeService
      .createBotCategory(
        environment.apiUrl + "botOfficeShop/createBotCategory",
        this.botCategory
      )
      .then((res) => {
        this.botCategory = res;
        this.activeModal.close();
        this.passEntry.emit(this.botCategory);
        this.toastr.success("Category added successfully !");
      });
  }

  editBotCategory() {
    this.clicked = true;
    this.botOfficeService
      .editBotCategory(
        environment.apiUrl + "botOfficeShop/editBotCategory",
        this.botCategory
      )
      .then((res) => {
        this.botCategory = res;
        this.activeModal.close();
        this.passEntry.emit(this.botCategory);
        this.toastr.success("Category updated successfully !");
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
