import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-check-modal',
  templateUrl: './email-check-modal.component.html',
  styleUrls: ['./email-check-modal.component.scss']
})
export class EmailCheckModalComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  loggedInUser: any;
  theme : any;
  officeId : any;
  email : any;
  profile : CompleteCustomerDetails = new CompleteCustomerDetails();
  search = true;
  customerExists = false;
  userExists = false;
  userAndCustomerProfileExist = false;

  constructor(private subscriptionService : SubscriptionService,
    private sharedDataService : SharedDataService,
    private activeModal : NgbActiveModal) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  ngOnInit(): void {

    this.officeId = this.fromParent.officeId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  closeUpdateModal(){
    this.activeModal.close();
  }


  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }  
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  searchFoUser(){

    this.profile.email = this.email;
    
    this.subscriptionService.searchCustomerAndUserProfiles(environment.apiUrl + "customer/searchCustomerAndUserProfiles/" + this.email + "/" + this.officeId).then(
      data =>{        
        this.profile = data;        
        
        if(this.profile.customerProfileExists === true){

          this.search = false;
          this.customerExists = true;

        }else if(this.profile.userProfileExists === true){

          this.search = false;
          this.userExists = true;

        }else if(this.profile.userAndCustomerProfileExist === true){

          this.search = false;
          this.userAndCustomerProfileExist = true;          

        }else{

          this.createCustomer();

        }
      });



  }

  useDifferentEmail(){

    this.email = '';
    this.userExists = false;
    this.customerExists = false;
    this.userAndCustomerProfileExist = false;
    this.search = true;

  }


  // authorized
  createCustomer(){ 

    this.activeModal.close();
    this.profile.email = this.email;
    this.profile.createCustomer = true;
    this.passEntry.emit(this.profile);
  } 

  showProfile(){

    this.activeModal.close();
    this.profile.createCustomer = false;
    this.passEntry.emit(this.profile);

  }


}
