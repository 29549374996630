
 
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
  
 
 
 <br>
 <div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>
 
 
 <div class="content">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">


    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >
      <mat-card style="cursor: pointer;" (click)="status='Invoices Due Today'" class="example-card mat-elevation-z12" >
     
        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/dueToday.png" width="65" height="65"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title style="margin-bottom: 6%;">Due Today</mat-card-title>
         <mat-card-title>{{dueToday}}</mat-card-title>
         <mat-card-subtitle>({{dueTodayAmt}})</mat-card-subtitle>
    </mat-card>
        </div>
  



    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >

      
      <mat-card style="cursor: pointer;" (click)="status='Invoices Due In One Week'" class="example-card mat-elevation-z12" >
     
        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/dueInOneWeek.png" width="65" height="65"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title style="margin-bottom: 6%;">Due in one week</mat-card-title>
         <mat-card-title>{{dueInOneWeek}}</mat-card-title>
         <mat-card-subtitle>({{dueInOneWeekAmt}})</mat-card-subtitle>

    </mat-card>
    
         
        </div>
    
     
            <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >

      
              <mat-card style="cursor: pointer;" (click)="status='Invoices Overdue'" class="example-card mat-elevation-z12" >
             
                <div class="card-icon" >
                  <a> 
                    <img src="/assets/images/icons/overdue.png" width="65" height="65"
                     alt="" loading="lazy">
                  </a>
                </div>
                 <br>
                 <mat-card-title style="margin-bottom: 6%;">Overdue</mat-card-title>
                 <mat-card-title>{{overdue}}</mat-card-title>
             <mat-card-subtitle>({{overdueAmt}})</mat-card-subtitle>
              
            </mat-card>
            
                 
                </div>
                  
                <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="100%" >

      
                  <mat-card style="cursor: pointer;" (click)="status='All Outstanding Invoices'" class="example-card mat-elevation-z12" >
                 
                    <div class="card-icon" >
                      <a> 
                        <img src="/assets/images/icons/totalDue.png" width="65" height="65"
                         alt="" loading="lazy">
                      </a>
                    </div>
                     <br>
                     <mat-card-title style="margin-bottom: 6%;">Total Outstanding</mat-card-title>
                     <mat-card-title>{{totalDue}}</mat-card-title>
                      <mat-card-subtitle>({{totalDueAmt}})</mat-card-subtitle>
                  
                </mat-card>
                
                     
                    </div>
  </div>
  </div>
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  <div *ngIf="status === 'All Outstanding Invoices'" class="p-4">
   
     
        <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              Outstanding Invoices (Total Outstanding Amount : {{totalOutstandingAmount}})
            </span>

          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
         
            <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="invoiceOptions" >
              <thead class="tableheader">
                <tr>
                  <th>Date</th> 

                  <th>Customer</th>
                  <th>Amt</th>
                  <th>Outstanding Amt</th>
                  <th>Contact</th>
                  <th>Status</th>
                
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of invoices2">
                  <td class="td-height">{{ccd.formattedActualInvoiceDate}}</td>
                  <td class="td-height">{{ccd.customerName}}</td>
                  <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
                  <td  style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>
                  <td class="td-height">{{ccd.customerContact}}</td>
                  <td *ngIf="ccd.overDue === 'black'" style="color: black;" class="td-height">
                    {{ccd.dueStatus}} <br>
                    <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
                  </td>
                  <td *ngIf="ccd.overDue === 'orange'" style="color: orange;" class="td-height">
                    {{ccd.dueStatus}} <br>
                    <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
                  </td>
                  <td *ngIf="ccd.overDue === 'red'" style="color: red;" class="td-height">
                    {{ccd.dueStatus}} <br>
                    <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
                  </td>


                 
                  <td class="td-height" style=" height: 2rem;">
                    <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  
                    <button (click)="addNewCustomerCommunication(ccd.fkCustomerId)" class="btn btn-primary">Action</button>  <br>
                     <button (click)="viewCustomerComms(ccd.fkCustomerId)" style="width: auto;" class="btn btn-secondary">View Communications</button>  
                  </td>
                  


        
               
                </tr>    

              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>
  
  
 
 
  







  <div *ngIf="status === 'Invoices Due In One Week'" class="p-4">
   
     
    <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Invoices Due In One Week 
        </span>

      </div> 
    </div>
    <div class="card">
      <div class="card-body table-responsive">
     
        <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="invoiceOptions" >
          <thead class="tableheader">
            <tr>
              <th>Date</th> 

              <th>Customer</th>
              <th>Amt</th>
              <th>Outstanding Amt</th>
              <th>Contact</th>
              <th>Status</th>
            
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of invoicesDueInOneWeek">
              <td class="td-height">{{ccd.formattedActualInvoiceDate}}</td>
              <td class="td-height">{{ccd.customerName}}</td>
              <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
              <td  style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>
              <td class="td-height">{{ccd.customerContact}}</td>
              <td *ngIf="ccd.overDue === 'black'" style="color: black;" class="td-height">
                {{ccd.dueStatus}} <br>
                <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
              </td>
              <td *ngIf="ccd.overDue === 'orange'" style="color: orange;" class="td-height">
                {{ccd.dueStatus}} <br>
                <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
              </td>
              <td *ngIf="ccd.overDue === 'red'" style="color: red;" class="td-height">
                {{ccd.dueStatus}} <br>
                <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
              </td>


             
              <td class="td-height" style=" height: 2rem;">
                <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  
                <button (click)="addNewCustomerCommunication(ccd.fkCustomerId)" class="btn btn-primary">Action</button>  <br>
                 <button (click)="viewCustomerComms(ccd.fkCustomerId)" style="width: auto;" class="btn btn-secondary">View Communications</button>  
              </td>
              


    
           
            </tr>    

          </tbody>
        </table>

      </div>
    </div>
  

</div>

  
  


<div *ngIf="status === 'Invoices Due Today'" class="p-4">
   
     
  <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">
      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
       Invoices Due Today
      </span>

    </div> 
  </div>
  <div class="card">
    <div class="card-body table-responsive">
   
      <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="invoiceOptions" >
        <thead class="tableheader">
          <tr>
            <th>Date</th> 

            <th>Customer</th>
            <th>Amt</th>
            <th>Outstanding Amt</th>
            <th>Contact</th>
            <th>Status</th>
          
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of invoicesDueToday">
            <td class="td-height">{{ccd.formattedActualInvoiceDate}}</td>
            <td class="td-height">{{ccd.customerName}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>
            <td class="td-height">{{ccd.customerContact}}</td>
            <td *ngIf="ccd.overDue === 'black'" style="color: black;" class="td-height">
              {{ccd.dueStatus}} <br>
              <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
            </td>
            <td *ngIf="ccd.overDue === 'orange'" style="color: orange;" class="td-height">
              {{ccd.dueStatus}} <br>
              <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
            </td>
            <td *ngIf="ccd.overDue === 'red'" style="color: red;" class="td-height">
              {{ccd.dueStatus}} <br>
              <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
            </td>


           
            <td class="td-height" style=" height: 2rem;">
              <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  
              <button (click)="addNewCustomerCommunication(ccd.fkCustomerId)" class="btn btn-primary">Action</button>  <br>
               <button (click)="viewCustomerComms(ccd.fkCustomerId)" style="width: auto;" class="btn btn-secondary">View Communications</button>  
            </td>
            


  
         
          </tr>    

        </tbody>
      </table>

    </div>
  </div>


</div>



<div *ngIf="status === 'Invoices Overdue'" class="p-4">
   
     
  <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">
      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
       Invoices Overdue
      </span>

    </div> 
  </div>
  <div class="card">
    <div class="card-body table-responsive">
   
      <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="invoiceOptions" >
        <thead class="tableheader">
          <tr>
            <th>Date</th> 

            <th>Customer</th>
            <th>Amt</th>
            <th>Outstanding Amt</th>
            <th>Contact</th>
            <th>Status</th>
          
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of invoicesOverdue">
            <td class="td-height">{{ccd.formattedActualInvoiceDate}}</td>
            <td class="td-height">{{ccd.customerName}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
            <td  style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>
            <td class="td-height">{{ccd.customerContact}}</td>
            <td *ngIf="ccd.overDue === 'black'" style="color: black;" class="td-height">
              {{ccd.dueStatus}} <br>
              <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
            </td>
            <td *ngIf="ccd.overDue === 'orange'" style="color: orange;" class="td-height">
              {{ccd.dueStatus}} <br>
              <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
            </td>
            <td *ngIf="ccd.overDue === 'red'" style="color: red;" class="td-height">
              {{ccd.dueStatus}} <br>
              <a style="background-color: greenyellow;color: black;" *ngIf="ccd.orderStatus === 'Invoiced' && ccd.invoiceRemarks ==='Old Invoice'">(System generated invoice)</a>
            </td>


           
            <td class="td-height" style=" height: 2rem;">
              <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-info">View</button>  
              <button (click)="addNewCustomerCommunication(ccd.fkCustomerId)" class="btn btn-primary">Action</button>  <br>
               <button (click)="viewCustomerComms(ccd.fkCustomerId)" style="width: auto;" class="btn btn-secondary">View Communications</button>  
            </td>
            


  
         
          </tr>    

        </tbody>
      </table>

    </div>
  </div>


</div>