import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewInvoiceDetailsComponent } from 'src/app/crm/invoice/view-invoice-details/view-invoice-details.component';
import { GlobalClass } from 'src/app/global/global-class';
import { InvoiceService } from 'src/app/service/invoice.service';
import { PaymentService } from 'src/app/service/payment.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit {

  userId : any;
  officeId : any;
  firstName : any;
  lastName : any;
  isParent : any;

  subscriptions : any[] = [{
                              endedDate: "",
                              fkProductId: "",
                              fkRoleId: "",
                              fkUserId: "",
                              productName: "",
                              roleName: "",
                              startedDate: "",
                              userName: "",
                              subscriptionId: "",
                              displayName : ""
                            }];
  classOptions: any = {};
  loggedInUser: any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  constructor(private activeModal : NgbActiveModal,
    private ngbModalService : NgbModal,
              private invoiceService : InvoiceService, 
              private paymentService : PaymentService,
              private sharedDataService : SharedDataService,
              private subscriptionService : SubscriptionService) { }
 
              theme: any;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  context : any;

  payments : any[] = [{fkInvoiceId: "",
  paymentAmount: "",
  paymentCompletion: "",
  paymentDate: "",
  paymentDue: "",
  paymentId: "",
  paymentMethod: "",
  paymentRemarks: "",
  paymentStatus: ""

}]

  invoices : any[] = [{
    deliveryAddress: '',
description: '',
fkCustomerId: '',
fkInvoiceId: '',
fkProductId: '',
invoiceDate: '',
invoiceDetId: '',
invoiceDetStatus: '',
invoiceId: '',
invoiceNo: '',
invoiceRemarks: '',
invoiceStatus: '',
itemName: '',
paymentMethod: '',
pricePerItem: '',
quantity: '',
totalAmount: '',
totalPrice: ''
  }];

  
  ngOnInit(): void {

    this.classOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    }; 

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.userId = this.fromParent.userId;
    this.firstName = this.fromParent.firstName;
    this.lastName = this.fromParent.lastName;
    this.context = this.fromParent.context;

    if(this.context==="SUBSCRIPTIONS"){
      this.subscriptionService.getSubscriptionsByUserId(environment.apiUrl + "user/getSubscriptionsByUserId/" + this.userId).then(
        data=>{
          this.subscriptions = data;
        });
    }else if(this.context==="INVOICES"){
      this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByUserId").then(
        data=>{
          this.invoices = data;
        });
    }else if(this.context==="PAYMENTS"){
      this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByUserId").then(
        data=>{
          this.payments = data;
        });
    }

  }

  
 
  viewInvoiceDetailsModal(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewInvoiceDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });

    let data = {
      invoiceId: invoiceId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }


  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  close(){
    this.activeModal.close();
  }
}
