export class NotificationSetting {

    notificationSettingId : any;
    notificationSettingActiveStatus : any;
    officeId : any;
    officeName : any;
    key : any;
    value : any;

}
