import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from 'src/app/crmClasses/address';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { Customer } from 'src/app/customer';
import { AddressService } from 'src/app/service/address.service';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { CustomerService } from 'src/app/service/customer.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-list-comp-cust-details',
  templateUrl: './list-comp-cust-details.component.html',
  styleUrls: ['./list-comp-cust-details.component.scss']
})
export class ListCompCustDetailsComponent implements OnInit {

  ccdetails: CompleteCustomerDetails[] = [];
  aaa : CompleteCustomerDetails = new CompleteCustomerDetails();
  loggedInUser: any;
  constructor( private ccdetailsService : CompCustDetailsService, private sharedDataService : SharedDataService,
    private router : Router) { }

    officeId!: string;
 
  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    
    this.getCcDetails();
  }
 
  getCcDetails(){
    // this.ccdetailsService.getCcDetailsList(this.officeId).subscribe(data => {
    //   this.ccdetails= data;
    // });
  } 

  updateCustomer(addressId : string){
    this.router.navigate(['updateCcDetails',addressId]);
  }
 
  deleteCustomer(addressId : string){
    this.ccdetailsService.deleteCustomer(addressId).subscribe( data => {
      this.getCcDetails();
    }) 
  
  } 

  addNewCustomer(){
    this.router.navigate(['/addCompleteCustomerDetails']);
  }
  show(){
  }
}
 