import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { FormConfigurationService } from 'src/app/services/FormConfiguration/form-configuration.service';
import { FormConfiguation } from 'src/app/models/form-configuation';
import { UserDetails } from 'src/app/models/user-details';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent implements OnInit {

  clicked : boolean = false;
  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  officeId : any;
  products : Product[] = [];
  roles : any[] = ["TEACHER", "STUDENT"];
  userExists = false;
  parentExists = false;
  type : any;
  formConfigs : FormConfiguation[] =[];
  formConfigGroups : FormConfiguation[] =[];
  @Input() fromParent: any;  
  userDetails : UserDetails[] = [];
  closeResult!:string;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  constructor(private productService : ProductService,
              private toaster : ToastrService,
              public activeModal: NgbActiveModal,
              private ngbModalService: NgbModal,
              private formConfigService : FormConfigurationService,
              private subscriptionService  : SubscriptionService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
    this.countryCodes = GlobalClass.CountryCodes;
    
    this.combinedPTC.fkProductId = "";
    
this.officeId =  this.fromParent.officeId;
this.type = this.fromParent.type;

this.combinedPTC.email = this.fromParent.userEmail;

this.combinedPTC.roleName = this.type;

    this.productService.getProductListByOfficeId(this.officeId).subscribe(
      data=>{
      this.products = data;
      });

      if(this.combinedPTC.roleName === "STUDENT"){
        
        // this.formConfigService.getByFormNameAndOfficeId(environment.apiUrl + "formConfig/getByFormNameAndOfficeId/" + "Student Creation").then(
        //   data=>{            
        //     this.formConfigs = data;
        //     if(this.formConfigs.length !== 0  && this.formConfigs !== undefined && this.formConfigs !== null){
        //     this.formConfigs.forEach((formConfig)=>{
        //       let ud : UserDetails = new UserDetails();
        //       ud.fkFormConfigId = formConfig.formConfigId;
        //       ud.mandatory = formConfig.mandatory;
        //       ud.displayName = formConfig.displayName;
        //       ud.columnName = formConfig.columnName;
        //       ud.key = formConfig.columnName;
        //       this.userDetails.push(ud);
        //     });
        //   }
        //   });

          this.formConfigService.getByFormNameAndOfficeId(environment.apiUrl + "formConfig/getGroupedFormConfigByFormNameAndOfficeId/" + "Student Creation").then(
            data=>{            
              
              this.formConfigGroups = data;
              if(this.formConfigGroups.length !== 0  && this.formConfigGroups !== undefined && this.formConfigGroups !== null){
                this.formConfigGroups.forEach((formConfigGroup)=>{

                  let uds : UserDetails[] = [];

                  formConfigGroup.groupedformConfigs.forEach((formConfig)=>{
                    let ud : UserDetails = new UserDetails();
                    ud.fkFormConfigId = formConfig.formConfigId;
                    ud.mandatory = formConfig.mandatory;
                    ud.displayName = formConfig.displayName;
                    ud.columnName = formConfig.columnName;
                    ud.key = formConfig.columnName;
                    uds.push(ud);
                  });

                  formConfigGroup.userDetails = uds;

                });
              }              
            });
      }

        
       this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onSubmit(){

    if(this.combinedPTC.roleName === "STUDENT"){

      // this.combinedPTC.userDetails = this.userDetails;

      this.formConfigGroups.forEach((fcg)=>{
        fcg.userDetails.forEach((userDetails)=>{
          this.combinedPTC.userDetails.push(userDetails);
        });
      })

      this.subscriptionService.searchUserByEmail(this.combinedPTC.email).subscribe(
        data=>{
          this.userExists = data;

          if(this.userExists === true){
            this.toaster.error('Student email is already registered !')
            this.clicked = false;
          }
          else if(this.userExists === false){
            this.subscriptionService.searchUserByEmail(this.combinedPTC.parentEmail).subscribe(
              data=>{
                this.parentExists = data;

                if(this.parentExists === true){
                  this.toaster.error('Parent email is already registered !')
                  this.clicked = false;
                }
                else if(this.parentExists === false){

                  this.combinedPTC.fkOfficeId = this.officeId;

                  this.subscriptionService.addUser(environment.apiUrl + "user/addUser", this.combinedPTC).then(
                    data=>{
                      this.combinedPTC = data;
                      this.passEntry.emit(this.combinedPTC.email);
                      this.activeModal.close();
                      this.toaster.success("User Added Successfully");
                    });
                }
              });
          }
        });
    }else if(this.combinedPTC.roleName === "TEACHER"){
      this.subscriptionService.searchUserByEmailOfficeAdmin(environment.apiUrl + "user/searchUserByEmailOfficeAdmin/" + this.combinedPTC.email).then(
        data=>{
          this.userExists = data;

          if(this.userExists === true){
            this.toaster.error('email is already registered ! Use a different email')
            this.clicked = false;
          }
          else if(this.userExists === false){
            this.combinedPTC.fkOfficeId = this.officeId;

            this.subscriptionService.addUser(environment.apiUrl + "user/addUser", this.combinedPTC).then(
              data=>{
                this.combinedPTC = data;
                this.passEntry.emit(this.combinedPTC.email);
                this.activeModal.close();
                this.toaster.success("User Added Successfully");
              });
           }
        });
    }
  }



  usernameExists = false;
  choice : any;
  value : any;
emailUnavailable = false;
phoneUnavailable = false;


  checkUsernameAvailability(){

    if(this.emailUnavailable === true && this.phoneUnavailable === true){
      this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
    }else{

    if(this.choice === "Email"){
      this.value = this.combinedPTC.email;
    }else if(this.choice === "Phone"){
      this.value = this.combinedPTC.phoneNumber;
    }

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{

        this.usernameExists = res;

        if(this.usernameExists === true){

          if(this.choice === "Email"){
           this.emailUnavailable = true;
          }else if(this.choice === "Phone"){
            this.phoneUnavailable = true;
          }

          if(this.emailUnavailable === true && this.phoneUnavailable === true){
            this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
            this.value = null;
          }else{
            this.toaster.error("Username exists !");
          }

        }else if(this.usernameExists === false){

          this.toaster.success("Username available !");

          this.createStudent();

        }
      });

    }
  }

  checkCustomUsernameAvailability(){

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{
        this.usernameExists = res;
        if(this.usernameExists === true){
          this.toaster.error("Username exists !");
        }else if(this.usernameExists === false){
          this.toaster.success("Username available !")
          this.createStudent();
        }
      });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  createStudent(){

    this.formConfigGroups.forEach((fcg)=>{
      fcg.userDetails.forEach((userDetails)=>{
        this.combinedPTC.userDetails.push(userDetails);
      });
    })

    this.combinedPTC.username = this.value;

    this.combinedPTC.fkOfficeId = this.officeId;

    this.subscriptionService.addUser(environment.apiUrl + "user/addUser", this.combinedPTC).then(
      data=>{
        this.combinedPTC = data;
        this.passEntry.emit(this.combinedPTC.email);
        this.activeModal.close();
        this.ngbModalService.dismissAll();
        this.toaster.success("User Added Successfully");
      });

      // 

  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
