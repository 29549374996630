import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/courseManagementClasses/role';
import { Uro } from 'src/app/courseManagementClasses/uro';
import { RoleService } from 'src/app/service/courseManagementServices/role.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assign-bcrm-role',
  templateUrl: './assign-bcrm-role.component.html',
  styleUrls: ['./assign-bcrm-role.component.scss']
})
export class AssignBcrmRoleComponent implements OnInit {

  clicked = false;
  @Input() fromParent: any; 
  loggedInUser : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  userId! : string;
  officeId! : string;
  roleIdToAssign! : string;
  userFirstName! : string;
  userLastName! : string;
  assignableRoles : Role[] = [];
  assignedRoles : Role[] = [];
  uro : Uro = new Uro();
  theme : any;
  userOptions : any;
  usersExists = false;
  closeResult !: string;

  constructor(private roleService : RoleService,
    private sharedDataService: SharedDataService,
    private ngbModalService: NgbModal,
              private subscriptionService : SubscriptionService,
              public activeModal: NgbActiveModal,
              private toastr : ToastrService ) { }

  ngOnInit(): void {

    this.roleIdToAssign = "";

    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.assignableRoles = [];
    this.assignableRoles.pop();

    this.userId = this.fromParent.userId;
    this.officeId = this.fromParent.officeId;
    this.userFirstName = this.fromParent.userFirstName;
    this.userLastName =this.fromParent.userLastName;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    // authorized
    this.roleService.getUserAssignedRoles(environment.apiUrl + "user/getUserAssignedRoles/" + this.userId).then(
      data=>{
        this.assignedRoles = data; 
        this.usersExists = true;
      });

    // authorized
    this.roleService.getUserAssignableRoles(environment.apiUrl + "role/getAssignableRolesForBcrmUsers/" + this.userId + "/" + this.officeId).then(
      data =>{
        this.assignableRoles = data;
      });

  }

  getUserAssignedRoles(){
 // authorized
 this.roleService.getUserAssignedRoles(environment.apiUrl + "user/getUserAssignedRoles/" + this.userId).then(
  data=>{
    this.assignedRoles = [];
    this.assignedRoles = data; 
    this.usersExists = true;
  });
  }

  getUserAssignableRoles(){
     // authorized
     this.roleService.getUserAssignableRoles(environment.apiUrl + "role/getAssignableRolesForBcrmUsers/" + this.userId + "/" + this.officeId).then(
      data =>{
        this.assignableRoles = [];
        this.assignableRoles = data;
      });
  }

  assignRole(){

    this.uro.userId = this.userId;
    this.uro.roleId = this.roleIdToAssign;
    this.uro.officeId = this.officeId;

    // authorized
    this.roleService.assignAdminRole(environment.apiUrl + "user/assignAdminRole", this.uro).then(
      data=>{
            // this.activeModal.close();
            // this.assignableRoles = [];
            this.roleIdToAssign = "";
            this.toastr.success('Role Assigned Successfully');
            this.getUserAssignableRoles();
            this.getUserAssignedRoles();

          });
  }

  // authorized
  updateUserRoleOfficeStatus(uroId : any){

    this.subscriptionService.updateAssignedRoleStatus(environment.apiUrl + "user/updateAssignedRoleStatus/" + uroId).then(
    data=>{      
    this.assignedRoles.forEach((value)=>{
          if(value.userRoleOfficeId === uroId){
            if(value.userRoleOfficeStatus === true){
              value.userRoleOfficeStatus = false
            }else if(value.userRoleOfficeStatus === false){
              value.userRoleOfficeStatus = true
            }
          }
        });
      });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }


}
