import { ActivityVideoLink } from "./activity-video-link";

export class AddActPlnrCurrActPlnr {

    activityPlannerId!: string;

	activityPlannerName!: string;

	activityPlannerDescription!: string;

	activityPlannerOrder!: number;

	activityPlannerStatus!: boolean;

	curId!: string;

	fkSubjectId!: string;

	activityVideoLinkList : ActivityVideoLink[] = [];

	type! : string;

	fkTeacherId! : string;
	
}
