
 <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
  <div *ngIf="this.loggedInUser.offices[0].logo !== null">
    <img height="30" width="30" class="logo-image"
      [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
  </div>
  <div *ngIf="this.loggedInUser.offices[0].logo === null">
    <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
  </div>
  <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button> 

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-user"></i> Account </a>
      </li>

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
      </li>


      <li class="nav-item" style="float: right;">
        <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
          <i class="fa fa-sign-out" ></i>Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>



<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a style="color: #0275d8;"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
    <!-- <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Subject</li> -->
  </ol>
</nav>
 

 <div class="content" style="margin-top : 2%">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      <mat-card class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img class="card-icon-image" src="/assets/images/icons/customers.svg" width="85" height="85"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title>Subscriptions</mat-card-title>
         <mat-card-subtitle>[{{dashboardData.totalActiveSubscriptions}}]</mat-card-subtitle>

      <mat-card-content>
        <p>
          <button class="btn btn-info" [ngStyle]="backgroundStyle" (click)="subscriptionDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>
     
    </mat-card>

    </div>

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      <mat-card class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img class="card-icon-image" src="/assets/images/icons/customers.svg" width="85" height="85"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title>Customer      </mat-card-title>
         <mat-card-subtitle>[{{dashboardData.totalCustomers}}]</mat-card-subtitle>
         <mat-card-content>
        <p >
          <button class="btn btn-info" [ngStyle]="backgroundStyle" (click)="customerDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>
     
    </mat-card>

     
    </div>

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      <mat-card class="example-card mat-elevation-z12" >
     
        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/leads.svg" width="85" height="85"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title>Leads 
      </mat-card-title>
      <mat-card-subtitle>[{{dashboardData.totalLeads}}]</mat-card-subtitle>
      <mat-card-content>
        <p >
          <button [ngStyle]="backgroundStyle" class="btn btn-info" (click)="LeadDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>
      
    </mat-card>
     
    </div>

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      
  <mat-card  class="example-card mat-elevation-z12" >
 
    <div class="card-icon" >
      <a> 
        <img src="/assets/images/icons/inv.svg" width="85" height="85"
         alt="" loading="lazy">
      </a>
    </div>
     <br>
     <mat-card-title>Invoice
  </mat-card-title>
  <mat-card-subtitle>[{{dashboardData.totalInvoices}}]</mat-card-subtitle>
  <mat-card-content>
    <p >
      <button class="btn btn-info" [ngStyle]="backgroundStyle" (click)="invoiceDashboard()" mat-button>Explore</button>
    </p>
  </mat-card-content>
  
</mat-card>

     
    </div>

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      <mat-card class="example-card mat-elevation-z12" >
   
        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/payment.svg" width="85" height="85"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title>Payment
      </mat-card-title>
      <mat-card-subtitle>[{{dashboardData.totalPayments}}]</mat-card-subtitle>
      <mat-card-content>
        <p >
          <button class="btn btn-info" [ngStyle]="backgroundStyle" (click)="paymentDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>
      
      </mat-card>

     
    </div>

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      <mat-card  class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/shipment.svg" width="85" height="85"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title>Shipment
      </mat-card-title>
      <mat-card-subtitle>[{{dashboardData.totalShipments}}]</mat-card-subtitle>
      <mat-card-content>
        <p >
          <button class="btn btn-info" [ngStyle]="backgroundStyle"  (click)="shipmentDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>
      
      </mat-card>

     
    </div>

    <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >

      <mat-card class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img src="/assets/images/icons/product.svg" width="85" height="85"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
         <mat-card-title >Product
      </mat-card-title> 
      <mat-card-subtitle>[{{dashboardData.totalProducts}}]</mat-card-subtitle>
      <mat-card-content>
        <p >
          <button class="btn btn-info" [ngStyle]="backgroundStyle" (click)="productDashboard()" mat-button>Explore</button>
        </p>
        
      
      </mat-card-content>
      
      </mat-card>

     
    </div>

  </div>
  </div>

