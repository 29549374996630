import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/BIZ_CRM/biz_crm_services/user.service';
import { Role } from 'src/app/models/role';
import { RoleService } from 'src/app/service/courseManagementServices/role.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assign-reporting-head',
  templateUrl: './assign-reporting-head.component.html',
  styleUrls: ['./assign-reporting-head.component.scss']
})
  
export class AssignReportingHeadComponent implements OnInit {

  showTable = false;
  selectedUroId : any = "";
  selectedReportingHead : any = "";
  userFirstName! : string;
  userLastName! : string;
  assignedRoles : Role[] = [];
  assignableUsers : any[] = [];
  clicked = false;
  showTable2 = false;
  @Input() fromParent: any; 
  loggedInUser : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  theme: any;

  reportingHead : any ={
    activeStatus: '',
    hierarchyId : '',
    childUroId: '',
    endDate: '',
    fkParentName: '',
    fkParentUroId: '',
    fkUserRoleOfficeId: '',
    roleName: '',
    startDate: ''
  }

  existingReportingHeads : any[] = [{
    activeStatus: '',
    hierarchyId : '',
    childUroId: '',
    endDate: '',
    fkParentName: '',
    fkParentUroId: '',
    fkUserRoleOfficeId: '',
    roleName: '',
    startDate: ''
  }];

  reportingHeads : any[] = [{
    activeStatus: '',
    childUroId: '',
    endDate: '',
    fkParentName: '',
    fkParentUroId: '',
    hierarchyId : '',
    fkUserRoleOfficeId: '',
    roleName: '',
    startDate: ''
  }];

    reportingUsers : any[] = [{
      reportingUserName: '',
	    reportingUserRole: '',

    }]
  closeResult ! : string;

  constructor(private roleService : RoleService,
    private datePipe: DatePipe,
    private userService : UserService,  private ngbModalService: NgbModal,
              private sharedDataService: SharedDataService,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.reportingHeads = [];
    this.selectedReportingHead = "";
    
    this.userFirstName = this.fromParent.userFirstName;
    this.userLastName =this.fromParent.userLastName;

      // authorized
    this.userService.getReportingHeadsByUserId(environment.apiUrl + "bcrmUser/getReportingHeadsByUserId/" + this.fromParent.userId).then(
      data=>{
        this.existingReportingHeads = data;

        if(this.existingReportingHeads.length === 0 || this.existingReportingHeads === undefined ){
          this.showTable = false;
        }else{
          this.showTable = true;
        }

        this.existingReportingHeads.forEach((value)=>{

          let sd = value.startDate;
         let startDate = new Date(sd);      
         value.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');

         if(value.endDate !== null && value.endDate !== undefined){
         let ed = value.endDate;
         let endDate = new Date(ed);      
         value.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
         }
        });

      });

    // authorized
    this.roleService.getUserAssignedRoles(environment.apiUrl + "user/getUserAssignedRoles/" + this.fromParent.userId).then(
      data=>{
        this.assignedRoles = data; 
      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());

      this.getReportingUsers();
  }

  getReportingUsers(){
    this.userService.getReportingUsers(environment.apiUrl + "bcrmUser/getReportingUsers/" + this.fromParent.userId).then(
      data=>{        
      this.reportingUsers = data;
      if(this.reportingUsers.length !== 0 && this.reportingUsers !== undefined){
        this.showTable2 = true;
      }
      });

  }

  onChange(event : any){
    
    this.reportingHeads = [];
    this.selectedReportingHead = "";

    // authorized
    this.userService.getUsersForReportingHeadsAdd(environment.apiUrl + "bcrmUser/getUsersForReportingHeadsAdd/" + this.selectedUroId).then(
      data=>{        
      this.reportingHeads = data;
      });

  }

  addReportingHead(){

   
    this.reportingHead.fkUserRoleOfficeId = this.selectedUroId;
    this.reportingHead.fkParentUroId = this.selectedReportingHead;

     // authorized
    this.userService.addReportingHead(environment.apiUrl + "bcrmUser/addReportingHead", this.reportingHead).then(
      data =>{
          // authorized
        this.userService.getReportingHeadsByUserId(environment.apiUrl + "bcrmUser/getReportingHeadsByUserId/" + this.fromParent.userId).then(
          data=>{
           this.existingReportingHeads = data;
           this.showTable = true;

           this.existingReportingHeads.forEach((value)=>{

            let sd = value.startDate;
           let startDate = new Date(sd);      
           value.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');

           if(value.endDate !== null && value.endDate !== undefined){
            let ed = value.endDate;
            let endDate = new Date(ed);      
            value.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
            }
          });
          });

          this.selectedUroId = "";
          this.clicked = false;

          this.reportingHeads = [];
          this.selectedReportingHead = "";
      });
  }

  deactivateReportingHead(id : any){
     // authorized
    this.userService.deactivateReportingHead(environment.apiUrl + "bcrmUser/deactivateReportingHead/" + id).then(
      data=>{
        // authorized
        this.userService.getReportingHeadsByUserId(environment.apiUrl + "bcrmUser/getReportingHeadsByUserId/" + this.fromParent.userId).then(
          data=>{
           this.existingReportingHeads = data;
           this.showTable = true;

           this.existingReportingHeads.forEach((value)=>{

            let sd = value.startDate;
           let startDate = new Date(sd);      
           value.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');

           if(value.endDate !== null && value.endDate !== undefined){
            let ed = value.endDate;
            let endDate = new Date(ed);      
            value.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
            }
          });
          });
      }
    )
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
}
