import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.scss']
})
export class UpdateProductComponent implements OnInit {

  prod : Product = new Product();
  productId!: string;
  officeId!: string;
  clicked = false;
  @Input() fromParent: any; 
  loggedInUser : any;
  theme : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  unsafeImageUrl! : any;
  safeImageUrl! : any;

  constructor(private productService : ProductService,
    private sharedDataService: SharedDataService,
              public activeModal: NgbActiveModal,
              private imageService: FileServiceService,
              private sanitizer : DomSanitizer,
              private toaster: ToastrService ) { }



  ngOnInit(): void {

    this.productId = this.fromParent.productId;
    
    this.officeId = this.fromParent.officeId;

    this.productService.getProductById(this.productId).subscribe(data=>{
      this.prod = data;
              // Get image
              this.imageService.getProductImage(this.prod.productImage).subscribe(
                data=>{
                  this.safeImageUrl = data;
                  this.unsafeImageUrl = URL.createObjectURL(this.safeImageUrl);
                  this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
                });
    });
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  } 

  selectedFile!: File;
  fileChange : boolean = false;
  onFileSelected(event: any){
    this.fileChange = true;
    this.selectedFile = event.target.files[0];
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  update(){

    // authorized
    this.productService.updateProduct( environment.apiUrl + "crmproduct/update", this.prod).then(
      data =>{
        if(this.fileChange === true){
          let body = new FormData();
          body.append("file", this.selectedFile);

          this.imageService.uploadProductImage(body, this.prod.productId).subscribe(
            data=>{
              this.passEntry.emit(this.prod);
              this.activeModal.close();
              this.toaster.success("Product Updated Successfully");
            });
          }else{
            this.passEntry.emit(this.prod);
            this.activeModal.close();
            this.toaster.success("Product Updated Successfully");
          }
      }
      ,error => console.error()
      );
  } 

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeModal(){
    this.activeModal.close();
  }
}
