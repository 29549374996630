import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Class } from 'src/app/courseManagementClasses/class';
import { Student } from 'src/app/courseManagementClasses/student';
import { ClassService } from 'src/app/service/class.service';
import { StudentService } from 'src/app/service/student.service';
import { SubjectService } from 'src/app/service/subject.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-student-modal',
  templateUrl: './add-student-modal.component.html',
  styleUrls: ['./add-student-modal.component.scss']
})
export class AddStudentModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
    public classService : ClassService,
    private teacherService : TeacherService,
    public subService : SubjectService,
    private toaster: ToastrService,
    private studentService : StudentService,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;

    student : Student = new Student();
    userId : any;
    fkProductId : any;
    officeId : any;
    firstName : any;
    lastName : any;
    classes : Class[] = [];
    selectedClassId : any;
    selectedSubjectId : any;
    selectedProductId : any;
    clicked = false;
    
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.userId = this.fromParent.userId;
    this.fkProductId = this.fromParent.fkProductId;
    this.officeId = this.fromParent.officeId;

    this.student.officeId = this.officeId;
    this.student.userId = this.userId;

    this.firstName = this.fromParent.firstName;
    this.lastName = this.fromParent.lastName;
    
    this.classService.getListByProductIdCA(environment.apiUrl + "class/getListByProductIdCA/" + this.fkProductId).then(
      data=>{
        this.classes = data;
      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }


  addStudent(){

    this.student.fkClass = this.selectedClassId;
    this.studentService.addStudentCA(environment.apiUrl + 'student/addStudentByUserId', this.student).then(data=> {
      this.activeModal.close();
      this.passEntry.emit('Student added successfully');
      this.toaster.success('Student added successfully')

    });

  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
