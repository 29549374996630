import { Component, OnInit } from "@angular/core";
import { CrmServiceService } from "../../services/crm-service.service";
import { CrmList } from "../../classes/crm-list";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CrmListsModalComponent } from "../../modals/crm-lists-modal/crm-lists-modal.component";
import { DatePipe } from "@angular/common";
import { CrmTask } from "../../classes/crm-task";
import { CrmTasksModalComponent } from "../../modals/crm-tasks-modal/crm-tasks-modal.component";
import { SharedDataService } from "src/app/services/sharedData.service";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { Router } from "@angular/router";
import { GlobalClass } from "src/app/global/global-class";

@Component({
  selector: "app-crm-agent-lists-dashboard",
  templateUrl: "./crm-agent-lists-dashboard.component.html",
  styleUrls: ["./crm-agent-lists-dashboard.component.scss"],
})
export class CrmAgentListsDashboardComponent implements OnInit {
  showSpinner = true;
  crmLists: CrmList[] = [];
  navbarTitle: any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };
  loggedInUser: any;
  userId: any;

  constructor(
    private crmServiceService: CrmServiceService,
    private ngbModalService: NgbModal,
    private datePipe: DatePipe,
    private sharedDataService: SharedDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;
    this.getCrmLists();
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userId = this.loggedInUser.userId;
  }

  getCrmLists() {
    this.crmServiceService
      // .getMethod(environment.apiUrl + "bizCrm/getCrmListsByOfficeId")
      .getMethod(
        environment.apiUrl + "bizCrm/getCrmListsAndTasksByAgentBotUserId"
      )
      .then((data) => {
        let resObj: CrmList = new CrmList();
        resObj = data;
        this.crmLists = resObj.lists;
        this.crmLists.forEach((crmLst) => {
          if (
            crmLst.crmListTasks !== null &&
            crmLst.crmListTasks !== undefined &&
            crmLst.crmListTasks.length > 0
          ) {
            crmLst.crmListTasks.forEach((crmTsk) => {
              let x = crmTsk.creationTimestamp;
              // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
              crmTsk.creationTimestamp = this.datePipe.transform(
                x,
                "d/M/yy, h:mm a"
              );
            });
          }
        });
        this.showSpinner = false;
      });
  }

  createCrmList() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CrmListsModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "create crmList",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: CrmList) => {
      this.showSpinner = true;
      if (this.crmLists === null || this.crmLists === undefined) {
        this.crmLists = [];
      }
      this.crmLists.push(receivedEntry);
      this.showSpinner = false;
    });
  }

  editCrmList(crmList: CrmList) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CrmListsModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "edit crmList",
      crmList: crmList,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: CrmList) => {
      this.showSpinner = true;
      this.crmLists.forEach((list) => {
        if (list.crmListId === receivedEntry.crmListId) {
          list = receivedEntry;
        }
      });
      this.showSpinner = false;
    });
  }

  viewCrmTaskDetails(crmTask: CrmTask) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CrmTasksModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "CrmTask Details",
      crmTask: crmTask,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
    });
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }
}
