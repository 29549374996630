import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';
 
@Component({
  selector: 'app-update-activity-planner',
  templateUrl: './update-activity-planner.component.html',
  styleUrls: ['./update-activity-planner.component.scss']
})
export class UpdateActivityPlannerComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;
  selectedFile!: File;
  activityPlannerId!: string;
  activityPlanner : ActivityPlanner = new ActivityPlanner();
  subId!: string;
  videoDivState : boolean = true;
  // index : number[] = [];
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  safeImageUrl: any;
  unsafeImageUrl: any;
 
  constructor(public bsModalRef: BsModalRef,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService, private sharedDataService : SharedDataService,
              private activityPlannerService : ActivityPlannerService,
              private sanitizer : DomSanitizer,
              private imageService : FileServiceService ) { } 

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.activityPlannerService.getActivityPlannerById(this.activityPlannerId).subscribe(
      data=>{
        this.activityPlanner = data;

         this.imageService.getActivityPlannerImage(this.activityPlanner.activityPlannerImage).subscribe(
          data=>{
            this.safeImageUrl = data;
            this.unsafeImageUrl = URL.createObjectURL(this.safeImageUrl);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
          });
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  editImage=false;
  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
    this.editImage = true;
  }

  closeUpdateModal(){
    this.activeModal.close(); 
  }

  onSubmit(){
    // authorized
    this.activityPlannerService.updateActivityPlanner(environment.apiUrl + 'activityPlanner/update', this.activityPlanner).then(
      data=>{

        if(this.editImage===false){
          this.passEntry.emit(this.activityPlanner);
          this.activeModal.close();
          this.toaster.success("Activity Planner Updated Successfully");  
        }else if(this.editImage===true){
          let body = new FormData();
          body.append("file", this.selectedFile);
 
          this.imageService.uploadActivityPlannerImage(body, this.activityPlanner.activityPlannerId).subscribe(
            data=>{
              this.passEntry.emit(this.activityPlanner);
              this.activeModal.close();
              this.toaster.success("Activity Planner Updated Successfully"); 
            });
        }
 
      });

     
  }

  
}
