import { Subject } from "./subject";

export class Student {

    studentId!: string;
    fkClass!: string;
    fkUserRoleOffice!: string;
    studentStatus!: boolean;
    studentImage!: string;
    studentName : any

    userId : any;
    officeId : any;

    subjects : Subject[] = [];

}
