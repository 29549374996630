import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { FeedbackFormService } from '../../services/feedback-form.service';
import { FeedbackForm } from '../../classes/feedback-form';
import { CreateFeedbackFormComponent } from '../../modals/feedback-form/create-feedback-form/create-feedback-form.component';
import { EditFeedbackFormComponent } from '../../modals/feedback-form/edit-feedback-form/edit-feedback-form.component';
import { CreateFormActiveComponent } from '../../modals/form-active/create-form-active/create-form-active.component';
import { ViewFormActivesComponent } from '../../modals/form-active/view-form-actives/view-form-actives.component';
import { CreateTicketSupportDefinitionComponent } from 'src/app/BIZ_CRM_TICKET_SUPPORT/modals/TicketSupportDefinition/create-ticket-support-definition/create-ticket-support-definition.component';
import { TicketSupportDefinition } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-support-definition';
import { TicketSupportService } from 'src/app/BIZ_CRM_TICKET_SUPPORT/services/ticket-support.service';
import { EditTicketSupportDefinitionComponent } from 'src/app/BIZ_CRM_TICKET_SUPPORT/modals/TicketSupportDefinition/edit-ticket-support-definition/edit-ticket-support-definition.component';
import { ViewTicketSupportDefinitionComponent } from 'src/app/BIZ_CRM_TICKET_SUPPORT/modals/TicketSupportDefinition/view-ticket-support-definition/view-ticket-support-definition.component';
import { TableUtil } from 'src/app/table-util';

@Component({
  selector: 'app-bcrm-feedback-dashboard',
  templateUrl: './bcrm-feedback-dashboard.component.html',
  styleUrls: ['./bcrm-feedback-dashboard.component.scss']
})
export class BcrmFeedbackDashboardComponent implements OnInit {

  currentRole = "BCRM_FEEDBACK_MANAGER";
  customerLoaderMessage!: string;
  customerExists: boolean = false;
  ticketsExists: boolean = false;
  extTicketSupportUrl : any;
  customerLoader: boolean = false;
  roleLoader = false;
  userName ! : string;
  feedbackOptions : any;
  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  customerId: any;
  userId!: string
  navbarTitle: any;
  feedbackForms : FeedbackForm[] = [];
  ticketSupportDefinitions : TicketSupportDefinition[] = [];

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private ticketService : TicketSupportService,
              private datePipe : DatePipe,
              private formService : FeedbackFormService,
              private spinner: NgxSpinnerService, 
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService ) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    startCustomerLoading(){
      this.customerLoader ? this.spinner.show() : this.spinner.hide();
    }

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getForms();
    this.getTicketSupportDefinitions();
    this.getExtTicketSupportUrl();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.feedbackOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };
  }


  goToTicketSupportPage(){
    this.router.navigate(['dashboard/bcrm-feedback-manager-ticketsupport-dashboard']);
   }
   
  getExtTicketSupportUrl(){
      this.ticketService.getTicketSupportDefinitionsByOfficeId(environment.apiUrl + "ticketSupport/getExtTicketSupportUrl").then(
        data=>{
          this.extTicketSupportUrl = data.extTicketSupportBaseUrl;
        });
  }

  getTicketSupportDefinitions(){

    this.ticketService.getTicketSupportDefinitionsByOfficeId(environment.apiUrl + "ticketSupport/getTicketSupportDefinitionsByOfficeId").then(
      data=>{
        this.ticketSupportDefinitions = data;
        this.dataSourceT = this.ticketSupportDefinitions;
        this.ticketsExists = true;
      });
  }
  dataSourceT = this.ticketSupportDefinitions
  exportArrayT() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSourceT.map(x => ({
      TicketSupport: x.ticketSupport,
      Type: x.ticketSupportType,
      Group: x.ticketSupportGroup,
      Description: x.ticketSupportDescription
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }


  getForms() {
    this.formService.getFeedbackFormsByOfficeId(environment.apiUrl + "feedbackForm/getByOfficeId").then(
      data=>{
        this.feedbackForms = data; 
        this.dataSource = this.feedbackForms;   
        this.customerExists = true;
      });
  }

  dataSource = this.feedbackForms
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      FormName: x.name,
      Type: x.type,
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

  addTicketSupport(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateTicketSupportDefinitionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getTicketSupportDefinitions();
    });   
  }

  editTicketSupport(ticketSupportDefinition : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditTicketSupportDefinitionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      ticketSupportDefinition : ticketSupportDefinition
    }
    
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getTicketSupportDefinitions();
    });   
  }

  viewTicketSupport(ticketSupportDefinition : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewTicketSupportDefinitionComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      ticketSupportDefinition : ticketSupportDefinition
    }
    
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getTicketSupportDefinitions();
    });   
  }

  addForm(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateFeedbackFormComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getForms();
    });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
  viewFormActives(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewFormActivesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });   
  }

  addFormActive(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateFormActiveComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });   
  }

  editForm(form : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditFeedbackFormComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      form : form
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getForms();
    });   
  }


  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
  }
    
  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

}
