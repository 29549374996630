import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OfficesService } from "src/app/services/office/offices.service";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { AddBotResponseOptionsComponent } from "src/app/BOT_CODE/bot_modals/bot_response_options/add-bot-response-options/add-bot-response-options.component";
import { AddBotResponseComponent } from "src/app/BOT_CODE/bot_modals/bot_response/add-bot-response/add-bot-response.component";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { ViewBotOptionDetailsComponent } from "src/app/BOT_CODE/bot_modals/bot_response_options/view-bot-option-details/view-bot-option-details.component";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { ViewDefinitionsComponent } from "src/app/BOT_CODE/bot_modals/bot_tree_definitions/view-definitions/view-definitions.component";
import { BotResponseDetailsComponent } from "src/app/BOT_CODE/bot_modals/bot_response/bot-response-details/bot-response-details.component";
import { GetGlobalTempResComponent } from "src/app/BOT_CODE/bot_modals/global_template_response/get-global-temp-res/get-global-temp-res.component";
import { ViewResLangVariationsComponent } from "src/app/BOT_CODE/bot_modals/language_variation/res_language_variation/view-res-lang-variations/view-res-lang-variations.component";
import { ViewOptionLangVariationsComponent } from "src/app/BOT_CODE/bot_modals/language_variation/option_language_variation/view-option-lang-variations/view-option-lang-variations.component";
import { CreateBotApiConfigComponent } from "src/app/BOT_CODE/bot_modals/bot_api_configuration/create-bot-api-config/create-bot-api-config.component";
import { BotApiConfiguration } from "src/app/BOT_CODE/bot_classes/bot-api-configuration";
import { BotConditionalResponse } from "src/app/BOT_CODE/bot_classes/bot-conditional-response";
import { ViewBotMediaComponent } from "src/app/BOT_CODE/bot_modals/bot-media/view-bot-media/view-bot-media.component";
import { OfficeAccessControls } from "src/app/BOT_CODE/bot_classes/office-access-controls";
import { BotTreeDefinition } from "src/app/BOT_CODE/bot_classes/bot-tree-definition";
import { BotValidationConfiguration } from "src/app/BOT_CODE/bot_classes/bot-validation-configuration";
import { CRMTaskConfiguration } from "src/app/CRM_MODULE/classes/crmtask-configuration";

@Component({
  selector: "app-cb-office-admin-dashboard",
  templateUrl: "./cb-office-admin-dashboard.component.html",
  styleUrls: ["./cb-office-admin-dashboard.component.scss"],
})
export class CbOfficeAdminDashboardComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  officeObject: any = {
    activeStatus: "",
    backgroundColor: "",
    id: "",
    logo: "",
    oa_ParentMentor: "",
    oa_SubAdmin: "",
    oa_CRMAdmin: "",
    oa_CourseAdmin: "",
    oa_ClassAdmin: "",
    oa_SkillReport: "",
    officeLocation: "",
    officeName: "",
    themeColour: "",
    fkBotLangId: "",
    multiLanguageSettings: "",
    autoschedulerEnabled: "",
    botCallbackAccess: "",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  languageCardOverlay = false;

  officeAccessControls: OfficeAccessControls = new OfficeAccessControls();

  constructor(
    private ngbModalService: NgbModal,
    private botResponseService: BotResponseServiceService,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    public toaster: ToastrService,
    private officeService: OfficesService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;

    this.officeService
      .getOfficeByIdForOfficeAdmin(
        environment.apiUrl + "admin/getOfficeByIdForBotOfficeAdmin"
      )
      .then((res) => {
        this.officeObject = res;
      });

    this.userId = this.loggedInUser.userId;
  }

  templateResponse: BotResponse = new BotResponse();
  errorResponse: BotResponse = new BotResponse();
  botResponse: BotResponse = new BotResponse();
  allResponses: BotResponse = new BotResponse();
  convoInitiationTreeDef: BotResponse = new BotResponse();
  UpdateNewUserDetailsTreeDefs: BotResponse[] = [];
  convoInitiationExists = false;
  context = "Definitions";
  botAgentsReceived = false;
  viewResponses = false;
  // viewResponses = true;
  viewErrorResponses = false;
  viewTemplateResponses = false;
  activeFlowDefiniton = "ConversationInitiation";

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getResponsesByOfficeId();
  }

  viewBotMedia(botMedia: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMedia: botMedia,
      officeId: this.userOfficeId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUser) => {}
    );
  }

  optionToDeactivate: any;
  botResponseOptionIdToDeactivate: any;

  botResOptionDeactivation() {
    let optionStatusUpdateSuccess = false;

    let continueStatusChange = true;
    let parentResponse: BotResponse = new BotResponse();
    let childOption: BotResponseOptions = new BotResponseOptions();

    this.botResponse.responses.forEach((resp) => {
      if (resp.options !== null && resp.options !== undefined) {
        resp.options.forEach((optn) => {
          if (
            optn.botResponseOptionsId === this.botResponseOptionIdToDeactivate
          ) {
            parentResponse = resp;
            childOption = optn;
          }
        });
      }
    });

    if (
      parentResponse.resType === "image with buttons" ||
      parentResponse.resType === "video with buttons"
    ) {
      let count = 0;
      parentResponse.options.forEach((optn) => {
        if (optn.botResponseOptionsStatus === true) {
          count++;
        }
      });

      if (childOption.botResponseOptionsStatus === true && count === 1) {
        this.toaster.error(
          "Response of the format 'Image/Video With Buttons' should have atleast 1 active option !"
        );
        continueStatusChange = false;
      } else if (
        childOption.botResponseOptionsStatus === false &&
        count === 3
      ) {
        continueStatusChange = false;
        this.toaster.error(
          "Response of the format 'Image/Video With Buttons' can have upto 3 active options only !"
        );
      }
    }

    if (continueStatusChange === true) {
      this.botResponseService
        .botResOptionStatusChange(
          environment.apiUrl +
            "botConvo/botResOptionStatusChange/" +
            this.botResponseOptionIdToDeactivate
        )
        .then((res) => {
          this.toaster.success("Status changed successfully");
          this.botResponse.responses.forEach((resp) => {
            resp.options.forEach((optn) => {
              if (
                optn.botResponseOptionsId ===
                this.botResponseOptionIdToDeactivate
              ) {
                optn.botResponseOptionsStatus = !optn.botResponseOptionsStatus;
                optionStatusUpdateSuccess = true;
              }
            });
          });
        });
    }

    if (
      optionStatusUpdateSuccess === false &&
      this.errorResponse !== null &&
      this.errorResponse.responses !== null &&
      this.errorResponse.responses !== undefined
    ) {
      this.errorResponse.responses.forEach((resp) => {
        if (resp.options !== null && resp.options !== undefined) {
          resp.options.forEach((optn) => {
            if (
              optn.botResponseOptionsId === this.botResponseOptionIdToDeactivate
            ) {
              optn.botResponseOptionsStatus = !optn.botResponseOptionsStatus;
              optionStatusUpdateSuccess = true;
            }
          });
        }
      });
    }

    if (
      optionStatusUpdateSuccess === false &&
      this.templateResponse !== null &&
      this.templateResponse.responses !== null &&
      this.templateResponse.responses !== undefined
    ) {
      this.templateResponse.responses.forEach((resp) => {
        if (resp.options !== null && resp.options !== undefined) {
          resp.options.forEach((optn) => {
            if (
              optn.botResponseOptionsId === this.botResponseOptionIdToDeactivate
            ) {
              optn.botResponseOptionsStatus = !optn.botResponseOptionsStatus;
              optionStatusUpdateSuccess = true;
            }
          });
        }
      });
    }
  }

  removeBotApiConfigForOptionsFromResponse(resId: any) {
    this.showSpinner = true;
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/removeBotApiConfigForOptionsFromResponse/" +
          resId
      )
      .then((res) => {
        let bRes: BotResponse = new BotResponse();
        bRes = res;
        this.toaster.success("API removed successfully");
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === resId) {
            resp.optionsExist = bRes.optionsExist;
            resp.getOptionsByExtApi = false;
            resp.fkApiConfigId = null;
          }
        });
        this.showSpinner = false;
      });
  }

  unlinkNextResponse(botResponse: BotResponse) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/unlinkNextResponseForResponse/" +
          botResponse.botResponseId
      )
      .then((res) => {
        this.toaster.success("Next response unlinked successfully");
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === botResponse.botResponseId) {
            resp.nextBotResponseId = null;
          }
        });

        let removeFrom = botResponse.responseCountNumber + 1;
        let removeTill = this.botResponse.responses.length + 1;

        for (let i = removeFrom; i < removeTill; i++) {
          this.botResponse.responses.forEach((element, index) => {
            if (element.responseCountNumber == i)
              this.botResponse.responses.splice(index, 1);
          });
        }
      });
  }

  refreshFlow() {
    this.showSpinner = true;
    this.activeFlowDefiniton = "ConversationInitiation";
    this.getResponsesByOfficeId();
  }

  getResponsesByOfficeId() {
    this.showSpinner = true;
    this.botResponseService
      .getResponsesByOfficeId(
        environment.apiUrl + "botConvo/getBasicResponsesForOfficeAdmin"
      )
      .then((data) => {
        this.botResponse = new BotResponse();
        this.errorResponse = new BotResponse();
        this.templateResponse = new BotResponse();

        this.convoInitiationTreeDef = new BotResponse();
        this.UpdateNewUserDetailsTreeDefs = [];

        this.botResponse = data;

        this.errorResponse.responses = [];
        this.templateResponse.responses = [];

        this.errorResponse.responses = this.botResponse.errorResponses;
        this.templateResponse.responses = this.botResponse.templateResponses;

        // ERROR RESPONSES
        if (
          this.errorResponse.responses !== null &&
          this.errorResponse.responses.length > 0
        ) {
          this.errorResponse.responses.forEach((res) => {
            res.viewOptions = true;
            res.qsnAnswered = false;
            if (
              res.options !== null &&
              res.options !== undefined &&
              res.options.length !== 0
            ) {
              res.options.forEach((opt) => {
                opt.selected = false;
              });
            }
          });
        }

        // TEMPLATE RESPONSES
        if (
          this.templateResponse.responses !== null &&
          this.templateResponse.responses.length > 0
        ) {
          this.templateResponse.responses.forEach((res) => {
            res.viewOptions = true;
            res.qsnAnswered = false;
            if (
              res.options !== null &&
              res.options !== undefined &&
              res.options.length !== 0
            ) {
              res.options.forEach((opt) => {
                opt.selected = false;
              });
            }
          });
        }

        this.allResponses.responses = [];

        if (
          this.botResponse.responses !== null &&
          this.botResponse.responses.length > 0
        ) {
          this.botResponse.responses.forEach((respo) => {
            this.allResponses.responses.push(respo);
          });
        }

        if (
          this.botResponse.responses.length === 0 ||
          this.botResponse.responses === null ||
          this.botResponse.responses === undefined
        ) {
          this.convoInitiationExists = false;
        }
        // RESPONSES
        if (
          this.botResponse.responses !== null &&
          this.botResponse.responses.length > 0
        ) {
          this.botResponse.responses.forEach((res) => {
            res.viewOptions = true;
            res.qsnAnswered = false;

            if (res.backResponseId !== null) {
              this.botResponse.responses.forEach((res2) => {
                if (res2.botResponseId === res.backResponseId) {
                  res.backResponseResponse = res2.response;
                  res.backResponseSlNo = res2.slNo;
                }
              });
            }

            if (res.errorResponseId !== null) {
              this.errorResponse.responses.forEach((errRes) => {
                if (errRes.botResponseId === res.errorResponseId) {
                  res.errorResponseResponse = errRes.response;
                  res.errorResponseSlNo = errRes.slNo;
                }
              });
            }

            if (
              res.options !== null &&
              res.options !== undefined &&
              res.options.length > 0
            ) {
              res.options.forEach((option) => {
                if (option.fkDefaultConvoFlowBotTreeDefinitionId !== null) {
                  this.botResponse.customizableTreeDefList.forEach(
                    (treeDef) => {
                      if (
                        treeDef.botTreeDefinitionId ===
                        option.fkDefaultConvoFlowBotTreeDefinitionId
                      ) {
                        option.fkDefaultConvoFlowBotTreeDef = treeDef;
                      }
                    }
                  );
                }
              });
            }
          });
        }
        // TREE DEFINITION
        if (
          this.botResponse.treeDefList !== null &&
          this.botResponse.treeDefList.length > 0
        ) {
          this.botResponse.treeDefList.forEach((treeDef) => {
            this.botResponse.responses.forEach((res) => {
              if (treeDef.fkBotResponseId === res.botResponseId) {
                treeDef.response = res.response;
                treeDef.slNo = res.slNo;
                treeDef.nextBotResponseId = res.nextBotResponseId;
                treeDef.botResponseId = res.botResponseId;
              }
            });

            if (
              treeDef.definition === "DefaultErrorResponse" &&
              this.errorResponse !== null &&
              this.errorResponse !== undefined &&
              this.errorResponse.responses !== null &&
              this.errorResponse.responses !== undefined
            ) {
              this.errorResponse.responses.forEach((errorRes) => {
                if (treeDef.fkBotResponseId === errorRes.botResponseId) {
                  errorRes.isDefaultErrorResponse = true;
                } else {
                  errorRes.isDefaultErrorResponse = false;
                }
              });
            }
          });
        }
        // TREE DEFINITION RESPONSES
        if (
          this.botResponse.responses !== null &&
          this.botResponse.responses.length > 0
        ) {
          this.botResponse.responses.forEach((treeDefRes) => {
            if (treeDefRes.type === "UpdateNewUserDetails") {
              this.UpdateNewUserDetailsTreeDefs.push(treeDefRes);
            } else if (treeDefRes.type === "ConversationInitiation") {
              this.convoInitiationTreeDef = treeDefRes;
              this.convoInitiationExists = true;
            }
          });
        }

        this.botResponse.responses = [];
        if (
          this.convoInitiationTreeDef.options !== null &&
          this.convoInitiationTreeDef.options !== undefined &&
          this.convoInitiationTreeDef.options.length !== 0
        ) {
          this.convoInitiationTreeDef.options.forEach((opt) => {
            opt.selected = false;
          });
        }

        if (
          this.convoInitiationTreeDef.botResponseId !== null &&
          this.convoInitiationTreeDef.botResponseId !== undefined &&
          this.convoInitiationTreeDef.botResponseId !== ""
        ) {
          this.convoInitiationTreeDef.responseCountNumber = 1;
          this.botResponse.responses.push(this.convoInitiationTreeDef);
        }
        this.showSpinner = false;
      });
  }

  getNextLinkedResponse(resId: any) {
    let bRes: BotResponse = new BotResponse();
    bRes.currentQueryId = resId;
    this.botResponseService
      .getNextResponse(
        environment.apiUrl + "botConvo/office/getNextLinkedResponse",
        bRes
      )
      .then((res) => {
        let retRes: BotResponse = new BotResponse();
        retRes = res;

        if (retRes.type === "UpdateNewUserDetails") {
          this.toaster.success(
            "This response is connected to User Registration Questions !"
          );
        } else {
          if (
            retRes.options !== null &&
            retRes.options !== undefined &&
            retRes.options.length !== 0
          ) {
            retRes.options.forEach((opt) => {
              opt.selected = false;
            });
          }
          retRes.viewOptions = true;
          retRes.qsnAnswered = false;

          let responseCountNumber = 0;
          responseCountNumber = this.botResponse.responses.length;
          retRes.responseCountNumber = responseCountNumber + 1;

          this.botResponse.responses.push(retRes);
          this.botResponse.responses.forEach((resp) => {
            if (resp.botResponseId === resId) {
              resp.qsnAnswered = true;
            }
          });
        }
      });
  }

  getNextResponseForResponse(resId: any) {
    let bRes: BotResponse = new BotResponse();
    bRes.currentQueryId = resId;
    this.botResponseService
      .getNextResponse(
        environment.apiUrl + "botConvo/office/getNextResponseForResponse",
        bRes
      )
      .then((res) => {
        let retRes: BotResponse = new BotResponse();
        retRes = res;

        if (retRes.type === "UpdateNewUserDetails") {
          this.toaster.success(
            "This response is connected to User Registration Questions !"
          );
        } else {
          if (
            retRes.options !== null &&
            retRes.options !== undefined &&
            retRes.options.length !== 0
          ) {
            retRes.options.forEach((opt) => {
              opt.selected = false;
            });
          }
          retRes.viewOptions = true;
          retRes.qsnAnswered = false;

          let responseCountNumber = 0;
          responseCountNumber = this.botResponse.responses.length;
          retRes.responseCountNumber = responseCountNumber + 1;

          this.botResponse.responses.push(retRes);
          this.botResponse.responses.forEach((resp) => {
            if (resp.botResponseId === resId) {
              resp.qsnAnswered = true;
            }
          });
        }
      });
  }

  getBotCondResponsesByBotResId(resId: any) {
    this.botResponseService
      .getBotCondResponsesByBotResId(
        environment.apiUrl + "botConvo/getBotCondResponsesByBotResId/" + resId
      )
      .then((res) => {
        let retRes: BotConditionalResponse = new BotConditionalResponse();
        retRes = res;

        if (retRes.list.length > 0) {
          this.botResponse.responses.forEach((respE) => {
            if (
              respE.nextConditionalResponsesExist === true &&
              respE.botResponseId === resId
            ) {
              respE.botCondResponses = [];
              let count = 1;
              retRes.list.forEach((condRes) => {
                condRes.selected = false;
                condRes.slNo = count;
                count++;
                respE.botCondResponses.push(condRes);
              });
              respE.conditionsFetched = true;
            }
            this.showSpinner = false;
          });
        } else {
          this.toaster.error("Conditions do not exist !");
          this.showSpinner = false;
        }
      });
  }

  getBotCondResponsesByBotResOptionsId(resId: any, resOptionId: any) {
    this.botResponseService
      .getBotCondResponsesByBotResId(
        environment.apiUrl +
          "botConvo/getBotCondResponsesByBotResOptionsId/" +
          resOptionId
      )
      .then((res) => {
        let retRes: BotConditionalResponse = new BotConditionalResponse();
        retRes = res;

        this.botResponse.responses.forEach((response) => {
          if (response.botResponseId === resId) {
            response.options.forEach((option) => {
              if (option.botResponseOptionsId === resOptionId) {
                option.botCondResponses = [];
                let count = 1;
                retRes.list.forEach((condRes) => {
                  condRes.selected = false;
                  condRes.slNo = count;
                  count++;
                  option.botCondResponses.push(condRes);
                });
              }
            });
          }
        });
      });
  }

  getNextResponseForCondition(resId: any, condition: BotConditionalResponse) {
    this.botResponseService
      .getNextResponseForCondition(
        environment.apiUrl +
          "botConvo/office/getNextResponseForCondition/" +
          condition.nextBotResponseId
      )
      .then((res) => {
        let retRes: BotResponse = new BotResponse();
        retRes = res;

        if (retRes.type === "UpdateNewUserDetails") {
          this.toaster.success(
            "This response is connected to User Registration Questions !"
          );
        } else {
          if (
            retRes.options !== null &&
            retRes.options !== undefined &&
            retRes.options.length !== 0
          ) {
            retRes.options.forEach((opt) => {
              opt.selected = false;
            });
          }
          retRes.viewOptions = true;
          retRes.qsnAnswered = false;

          let responseCountNumber = 0;
          responseCountNumber = this.botResponse.responses.length;
          retRes.responseCountNumber = responseCountNumber + 1;

          retRes.botCondResponses = [];

          this.botResponse.responses.push(retRes);

          this.botResponse.responses.forEach((response0) => {
            if (response0.botResponseId === resId) {
              response0.qsnAnswered = true;
              if (
                response0.botCondResponses !== null &&
                response0.botCondResponses !== undefined &&
                response0.botCondResponses.length > 0
              ) {
                response0.botCondResponses.forEach((botCondRes) => {
                  if (botCondRes.botCondResId === condition.botCondResId) {
                    botCondRes.selected = true;
                  }
                });
              }
            }
            this.showSpinner = false;
          });
        }
      });
  }

  getNextResponseForDifferentCondition(
    responseCountNumber: any,
    condition: BotConditionalResponse,
    botResponseId: any
  ) {
    this.showSpinner = true;

    let removeFrom = responseCountNumber + 1;
    let removeTill = this.botResponse.responses.length + 1;

    for (let i = removeFrom; i < removeTill; i++) {
      this.botResponse.responses.forEach((element, index) => {
        if (element.responseCountNumber == i)
          this.botResponse.responses.splice(index, 1);
      });
    }

    this.botResponse.responses.forEach((resp) => {
      if (resp.botResponseId === botResponseId) {
        resp.qsnAnswered = false;
        if (
          resp.botCondResponses !== null &&
          resp.botCondResponses !== undefined &&
          resp.botCondResponses.length > 0
        ) {
          resp.botCondResponses.forEach((cond) => {
            cond.selected = false;
          });
        }
      }
    });

    this.getNextResponseForCondition(botResponseId, condition);
  }

  nextResponseForErrorResOption: BotResponse = new BotResponse();
  getNextResponseForErrorResOption(resId: any, option: BotResponseOptions) {
    if (option.linkToLastUserErrorResponse === true) {
      this.toaster.success(
        "This option is connected to User's Previous Error Response !"
      );
    } else if (option.nextBotResponseId === null) {
      this.toaster.error("Link next response to proceed !");
      this.showSpinner = false;
    } else {
      this.botResponseService
        .getNextResponse(
          environment.apiUrl + "botConvo/office/getNextResponseForOption",
          option
        )
        .then((res) => {
          this.nextResponseForErrorResOption = new BotResponse();
          this.nextResponseForErrorResOption = res;

          if (
            this.nextResponseForErrorResOption.type === "UpdateNewUserDetails"
          ) {
            this.toaster.success(
              "This option is connected to User Registration Questions !"
            );
            this.showSpinner = false;
          } else {
          }
        });
    }
  }

  nextResponseForTemplateResOption: BotResponse = new BotResponse();
  getNextResponseForTemplateResOption(resId: any, option: BotResponseOptions) {
    if (option.linkToLastUserErrorResponse === true) {
      this.toaster.success(
        "This option is connected to User's Previous Error Response !"
      );
    } else if (option.nextBotResponseId === null) {
      this.toaster.error("Link next response to proceed !");
      this.showSpinner = false;
    } else {
      this.botResponseService
        .getNextResponse(
          environment.apiUrl + "botConvo/office/getNextResponseForOption",
          option
        )
        .then((res) => {
          this.nextResponseForTemplateResOption = new BotResponse();
          this.nextResponseForTemplateResOption = res;

          if (
            this.nextResponseForTemplateResOption.type ===
            "UpdateNewUserDetails"
          ) {
            this.toaster.success(
              "This option is connected to User Registration Questions !"
            );
            this.showSpinner = false;
          } else {
          }
        });
    }
  }

  getNextResponseForOption(resId: any, option: BotResponseOptions) {
    if (option.nextBotResponseId === null) {
      this.toaster.error("Create / Link next response to proceed !");
      this.showSpinner = false;
    } else {
      this.botResponseService
        .getNextResponse(
          environment.apiUrl + "botConvo/office/getNextResponseForOption",
          option
        )
        .then((res) => {
          let retRes: BotResponse = new BotResponse();
          retRes = res;

          if (retRes.type === "UpdateNewUserDetails") {
            this.toaster.success(
              "This option is connected to User Registration Questions !"
            );
            this.showSpinner = false;
          } else {
            if (
              retRes.options !== null &&
              retRes.options !== undefined &&
              retRes.options.length !== 0
            ) {
              retRes.options.forEach((opt) => {
                opt.selected = false;
              });
            }
            retRes.viewOptions = true;
            retRes.qsnAnswered = false;

            let responseCountNumber = 0;
            responseCountNumber = this.botResponse.responses.length;
            retRes.responseCountNumber = responseCountNumber + 1;
            retRes.botCondResponses = [];
            this.botResponse.responses.push(retRes);
            this.botResponse.responses.forEach((response0) => {
              if (response0.botResponseId === resId) {
                response0.qsnAnswered = true;
                if (
                  response0.options !== null &&
                  response0.options !== undefined &&
                  response0.options.length !== 0
                ) {
                  response0.options.forEach((option0) => {
                    if (
                      option0.botResponseOptionsId ===
                      option.botResponseOptionsId
                    ) {
                      option0.selected = true;
                    }
                  });
                }
              }
              this.showSpinner = false;
            });
          }
        });
    }
  }

  switchFlow(botTreeDef: BotTreeDefinition) {
    this.showSpinner = true;
    this.botResponse.responses = [];
    this.botResponseService
      .getBotResponseByTreeDefId(
        environment.apiUrl +
          "botConvo/getBotResponseByTreeDefId/" +
          botTreeDef.botTreeDefinitionId
      )
      .then((res) => {
        if (res === null || res === undefined) {
          this.toaster.error(
            "Create Flow Initiation Response for the flow : " +
              botTreeDef.definition
          );
          this.showSpinner = false;
        } else {
          let firstRes: BotResponse = new BotResponse();
          firstRes = res;
          firstRes.viewOptions = true;
          firstRes.qsnAnswered = false;
          firstRes.backResponseResponse = firstRes.response;
          firstRes.backResponseSlNo = firstRes.slNo;
          firstRes.responseCountNumber = 1;

          if (firstRes.errorResponseId !== null) {
            this.errorResponse.responses.forEach((errRes) => {
              if (errRes.botResponseId === firstRes.errorResponseId) {
                firstRes.errorResponseResponse = errRes.response;
                firstRes.errorResponseSlNo = errRes.slNo;
              }
            });
          }

          if (
            firstRes.options !== null &&
            firstRes.options !== undefined &&
            firstRes.options.length > 0
          ) {
            firstRes.optionsExist = true;
            firstRes.options.forEach((opt) => {
              opt.selected = false;
            });
          }

          this.botResponse.responses.push(firstRes);
          this.showSpinner = false;
        }
      });
  }

  createFlowInitiationResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createFlowInitiationResponse",
      definition: this.activeFlowDefiniton,
      // eligibleVariableList : this.createEligibleVariableList()
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        let firstRes: BotResponse = new BotResponse();
        firstRes = receivedEntry;
        firstRes.viewOptions = true;
        firstRes.qsnAnswered = false;
        firstRes.backResponseResponse = firstRes.response;
        firstRes.backResponseSlNo = firstRes.slNo;
        firstRes.responseCountNumber = 1;

        if (firstRes.errorResponseId !== null) {
          this.errorResponse.responses.forEach((errRes) => {
            if (errRes.botResponseId === firstRes.errorResponseId) {
              firstRes.errorResponseResponse = errRes.response;
              firstRes.errorResponseSlNo = errRes.slNo;
            }
          });
        }

        if (
          firstRes.options !== null &&
          firstRes.options !== undefined &&
          firstRes.options.length > 0
        ) {
          firstRes.options.forEach((opt) => {
            opt.selected = false;
          });
        }

        this.botResponse.responses.push(firstRes);
        this.showSpinner = false;
      }
    );
  }

  createNewConversationFlow() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewDefinitionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Create New Conversation Flow",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotTreeDefinition) => {
        this.botResponse.customizableTreeDefList.push(receivedEntry);
      }
    );
  }

  viewConvoInitiationTreeDef() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewDefinitionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Conversation Initiation",
      convoInitiationExists: this.convoInitiationExists,
      treeDef: this.convoInitiationTreeDef,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getResponsesByOfficeId();
      this.viewResponses = true;
    });
  }

  viewUserRegDefs() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewDefinitionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "User Registration",
      treeDefs: this.UpdateNewUserDetailsTreeDefs,
      flows: this.botResponse.customizableTreeDefList,
      errorResponses: this.errorResponse.responses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === "Swapped Order") {
        this.showSpinner = true;
        this.refreshFlow();
      }
    });

    modalRef.componentInstance.passEntry1.subscribe(
      (receivedEntry: BotResponse[]) => {
        this.UpdateNewUserDetailsTreeDefs = receivedEntry;
      }
    );

    modalRef.componentInstance.passEntry2.subscribe((receivedEntry: any) => {
      this.UpdateNewUserDetailsTreeDefs.forEach((res) => {
        if (
          res.options !== null &&
          res.options !== undefined &&
          res.options.length > 0
        ) {
          res.options.forEach((option) => {
            if (option.botResponseOptionsId === receivedEntry) {
              option.fkDefaultConvoFlowBotTreeDefinitionId = null;
              option.fkDefaultConvoFlowBotTreeDef = new BotTreeDefinition();
            }
          });
        }
      });
    });

    modalRef.componentInstance.passEntry3.subscribe((receivedEntry: any) => {
      this.UpdateNewUserDetailsTreeDefs.forEach((res) => {
        if (res.botResponseId === receivedEntry) {
          res.botResponseStatus = !res.botResponseStatus;
        }
      });
    });

    modalRef.componentInstance.passEntry4.subscribe(
      (receivedEntry: BotResponse) => {
        this.UpdateNewUserDetailsTreeDefs.forEach((res) => {
          if (res.botResponseId === receivedEntry) {
            res = receivedEntry;
          }
        });
      }
    );

    modalRef.componentInstance.passEntry5.subscribe(
      (receivedEntry: BotResponse) => {
        this.UpdateNewUserDetailsTreeDefs.forEach((res) => {
          if (res.botResponseId === receivedEntry) {
            res.errorResponseId = null;
            res.errorResponseResponse = "";
            res.errorResponseSlNo = null;
          }
        });
      }
    );

    modalRef.componentInstance.passEntry5.subscribe(
      (receivedEntry: BotResponse) => {
        this.UpdateNewUserDetailsTreeDefs.forEach((res) => {
          if (res.botResponseId === receivedEntry) {
            if (
              res.options !== null &&
              res.options !== undefined &&
              res.options.length > 0
            ) {
              res.options.forEach((option) => {
                if (option.botResponseOptionsId === receivedEntry) {
                  option.fkBotUserGrpIdToAdd = null;
                  option.fkBotUserGrpNameToAdd = null;
                }
              });
            }
          }
        });
      }
    );
  }

  addFirstUserRegistrationResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addFirstUserRegistrationResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  addNextUserRegistrationResponse(id: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addNextUserRegistrationResponse",
      botResponseId: id,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  addConvoInitiationResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addConvoInitiationResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getResponsesByOfficeId();
    });
  }

  addResponseToResponse(pBotResponseId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addResponseToResponse",
      botResponseId: pBotResponseId,
      eligibleVariableList: this.createEligibleVariableList(null),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        receivedEntry.viewOptions = true;
        receivedEntry.qsnAnswered = false;
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === pBotResponseId) {
            resp.nextBotResponseId = receivedEntry.botResponseId;
            resp.qsnAnswered = true;
          }
        });

        let responseCountNumber = 0;
        responseCountNumber = this.botResponse.responses.length;
        receivedEntry.responseCountNumber = responseCountNumber + 1;

        this.botResponse.responses.push(receivedEntry);
      }
    );
  }

  addBotApiConfigurationForCondtitonalResponses(botRes: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotApiConfigComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponse: botRes,
      context: "addBotApiConfigurationForCondtitonalResponses",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotApiConfiguration) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === botRes.botResponseId) {
            res.nextConditionalResponsesExist = true;
            res.fkApiConfigIdForCondRes = receivedEntry.botApiConfigId;
          }
        });
        this.addConditionalResponseToResponse(botRes.botResponseId);
      }
    );
  }

  addBotApiConfigurationToOptionForCondtitonalResponses(
    botResOption: BotResponseOptions
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotApiConfigComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResOption: botResOption,
      context: "addBotApiConfigurationToOptionForCondtitonalResponses",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotApiConfiguration) => {
        this.botResponse.responses.forEach((res) => {
          if (res.options !== null) {
            res.options.forEach((optn) => {
              if (
                optn.botResponseOptionsId === botResOption.botResponseOptionsId
              ) {
                optn.nextConditionalResponsesExist = true;
                optn.fkApiConfigIdForCondRes = receivedEntry.botApiConfigId;
              }
            });
          }
        });
        this.addConditionalResponseToOption(botResOption.botResponseOptionsId);
      }
    );
  }

  addConditionalResponseToResponse(pBotResponseId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addConditionalResponseToResponse",
      botResponseId: pBotResponseId,
      eligibleVariableList: this.createEligibleVariableList(null),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {}
    );
  }

  addConditionalResponseToOption(optionsId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addConditionalResponseToOption",
      botResponseOptionsId: optionsId,
      eligibleVariableList: this.createEligibleVariableList(null),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {}
    );
  }

  unlinkBackResponseForResponse(botResponseId: any) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/unlinkBackResponseForResponse/" +
          botResponseId
      )
      .then((res) => {
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === botResponseId) {
            resp.backResponseId = null;
            resp.backResponseResponse = "";
            this.toaster.success("Back Response unlinked successfully !");
          }
        });
      });
  }

  unlinkErrorResponseForResponse(botResponseId: any) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/unlinkErrorResponseForResponse/" +
          botResponseId
      )
      .then((res) => {
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === botResponseId) {
            resp.errorResponseId = null;
            resp.errorResponseResponse = "";
            this.toaster.success("Error Response unlinked successfully !");
          }
        });
      });
  }

  linkNextResponseToResponse(res: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkNextResponseToResponse",
      botResponseId: res.botResponseId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === res.botResponseId) {
            resp.nextBotResponseId = receivedEntry.nextBotResponseId;
          }
        });
      }
    );
  }

  getNextResponseForDifferentOption(
    responseCountNumber: any,
    option: BotResponseOptions,
    botResponseId: any
  ) {
    if (option.nextBotResponseId === null) {
      this.toaster.error("Create / Link next response to proceed !");
    } else {
      this.showSpinner = true;

      let removeFrom = responseCountNumber + 1;
      let removeTill = this.botResponse.responses.length + 1;

      for (let i = removeFrom; i < removeTill; i++) {
        this.botResponse.responses.forEach((element, index) => {
          if (element.responseCountNumber == i)
            this.botResponse.responses.splice(index, 1);
        });
      }

      this.botResponse.responses.forEach((resp) => {
        if (resp.botResponseId === botResponseId) {
          resp.qsnAnswered = false;
          resp.options.forEach((optn) => {
            optn.selected = false;
          });
        }
      });

      this.getNextResponseForOption(botResponseId, option);
    }
  }

  setBackResponse(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setBackResponse",
      botResponse: botResponse,
      botResponses: this.botResponse.responses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === botResponse.botResponseId) {
            res.backResponseId = receivedEntry.botResponseId;
            res.backResponseResponse = receivedEntry.response;
            res.backResponseSlNo = receivedEntry.slNo;
          }
        });
      }
    );
  }

  setErrorResponse(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setErrorResponse",
      botResponse: botResponse,
      errorResponses: this.errorResponse.responses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === botResponse.botResponseId) {
            res.errorResponseId = receivedEntry.botResponseId;
            res.errorResponseResponse = receivedEntry.response;
            res.errorResponseSlNo = receivedEntry.slNo;
          }
        });
      }
    );
  }

  editBotResponse(botResponse: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editBotResponse",
      botResponse: botResponse,
      eligibleVariableList: this.createEligibleVariableList(botResponse),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.response = receivedEntry.response;
            res1.saveVar = receivedEntry.saveVar;
            res1.var = receivedEntry.var;
            res1.userResType = receivedEntry.userResType;
            res1.resType = receivedEntry.resType;
            res1.fkBotMediaId = receivedEntry.fkBotMediaId;
            res1.botMedia = receivedEntry.botMedia;
            res1.userResValidation = receivedEntry.userResValidation;
            res1.userResValidationType = receivedEntry.userResValidationType;
            res1.interactiveListButtonName =
              receivedEntry.interactiveListButtonName;
            res1.userDetailsUpdateVar = receivedEntry.userDetailsUpdateVar;
            res1.saveLocationTypeAs = receivedEntry.saveLocationTypeAs;
          }
        });
      }
    );
  }

  editErrorResponse(botResponse: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editErrorResponse",
      botResponse: botResponse,
      eligibleVariableList: this.createEligibleVariableList(botResponse),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.errorResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.response = receivedEntry.response;
            res1.saveVar = receivedEntry.saveVar;
            res1.var = receivedEntry.var;
            res1.userResType = receivedEntry.userResType;
            res1.resType = receivedEntry.resType;
            res1.fkBotMediaId = receivedEntry.fkBotMediaId;
            res1.botMedia = receivedEntry.botMedia;
            res1.userResValidation = receivedEntry.userResValidation;
            res1.userResValidationType = receivedEntry.userResValidationType;
            res1.interactiveListButtonName =
              receivedEntry.interactiveListButtonName;
          }
        });
      }
    );
  }

  editTemplateResponse(botResponse: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editTemplateResponse",
      botResponse: botResponse,
      eligibleVariableList: this.createEligibleVariableList(botResponse),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.errorResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.response = receivedEntry.response;
            res1.saveVar = receivedEntry.saveVar;
            res1.var = receivedEntry.var;
            res1.userResType = receivedEntry.userResType;
            res1.resType = receivedEntry.resType;
            res1.fkBotMediaId = receivedEntry.fkBotMediaId;
            res1.botMedia = receivedEntry.botMedia;
            res1.userResValidation = receivedEntry.userResValidation;
            res1.userResValidationType = receivedEntry.userResValidationType;
            res1.interactiveListButtonName =
              receivedEntry.interactiveListButtonName;
          }
        });
      }
    );
  }

  viewResponseDetails(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotResponseDetailsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponse: botResponse,
      globalTemplateResCreation: this.officeObject.globalTemplateResCreation,
      officeWhatsappNumber: this.officeObject.whatsappNumber,
      crmAccess: this.officeObject.crmAccess,
      eligibleVariableList: this.createEligibleVariableList(botResponse),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntryEdit.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1 = receivedEntry;
          }
        });
      }
    );

    modalRef.componentInstance.passEntrySetInteractiveButtons.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.resOptionsType = receivedEntry.resOptionsType;
          }
        });
      }
    );

    modalRef.componentInstance.passEntrySetInteractiveLists.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.resOptionsType = "interactive_lists";
          }
        });
      }
    );

    modalRef.componentInstance.passEntryRemoveInteractiveButtons.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.resOptionsType = null;
          }
        });
      }
    );

    modalRef.componentInstance.passEntrySetAdminNotificationMessage.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.adminNotificationMessage =
              receivedEntry.adminNotificationMessage;
            res1.createAdminNotification =
              receivedEntry.createAdminNotification;
            res1.adminNotificationTitle = receivedEntry.adminNotificationTitle;
            res1.adminNotificationMedia = receivedEntry.adminNotificationMedia;
          }
        });
      }
    );

    modalRef.componentInstance.passEntrySetAdminNotesMessage.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.adminNotesMessage = receivedEntry.adminNotesMessage;
            res1.createAdminNotes = receivedEntry.createAdminNotes;
            res1.adminNotesTitle = receivedEntry.adminNotesTitle;
            res1.adminNotesMedia = receivedEntry.adminNotesMedia;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryDeactivateAdminNotification.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.createAdminNotification = false;
            res1.adminNotificationTitle = null;
            res1.adminNotificationMessage = null;
            res1.adminNotificationMedia = null;

            res1.botNotificationConfigurations = [];
          }
        });
      }
    );

    modalRef.componentInstance.passEntryDeactivateAdminNotes.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.createAdminNotes = false;
            res1.adminNotesTitle = null;
            res1.adminNotesMessage = null;
            res1.adminNotesMedia = null;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryGlobalTempResponse.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            res1.globalTemplateRes = receivedEntry.globalTemplateRes;
            res1.globalTemplateTag = receivedEntry.globalTemplateTag;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryExtApiCallForResVariables.subscribe(
      (receivedEntry: BotApiConfiguration) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            res1.callExtApiForResVariables = true;
            res1.fkApiConfigIdForResVariables = receivedEntry.botApiConfigId;
            res1.fkApiConfigIdForResVariablesTitle = receivedEntry.title;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryExtApiCallForResVariablesRemoval.subscribe(
      (receivedEntry: any) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            res1.callExtApiForResVariables = false;
            res1.fkApiConfigIdForResVariables = null;
            res1.fkApiConfigIdForResVariablesTitle = null;
          }
        });
      }
    );

    modalRef.componentInstance.passEntrySetManualChatNotification.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.createManualChatNotification = true;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryDeactivateManualChatNotification.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1.createManualChatNotification = false;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryBotNotificationConfigurationStatusUpdate.subscribe(
      (receivedEntry: any) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            if (
              res1.botNotificationConfigurations !== null &&
              res1.botNotificationConfigurations !== undefined &&
              res1.botNotificationConfigurations.length > 0
            ) {
              res1.botNotificationConfigurations.forEach((conf) => {
                if (conf.botNotificationConfigId === receivedEntry) {
                  conf.botNotificationConfigStatus =
                    !conf.botNotificationConfigStatus;
                }
              });
            }
          }
        });
      }
    );

    modalRef.componentInstance.passEntryCrmTaskConfigurationStatusUpdate.subscribe(
      (receivedEntry: CRMTaskConfiguration[]) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            res1.crmTaskConfigs = receivedEntry;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryAliasUpdate.subscribe(
      (receivedEntry: any) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            res1.resAlias = receivedEntry;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryBotValidationConfigurationAdd.subscribe(
      (receivedEntry: BotValidationConfiguration[]) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            if (
              res1.resValidationConfigs === null ||
              res1.resValidationConfigs === undefined
            ) {
              res1.resValidationConfigs = [];
            }
            res1.resValidationConfigs = receivedEntry;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryBotValidationConfigurationStatusUpdate.subscribe(
      (receivedEntry: BotValidationConfiguration[]) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === botResponse.botResponseId) {
            res1.resValidationConfigs = receivedEntry;
          }
        });
      }
    );

    modalRef.componentInstance.passEntryCrmListUpdate.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res1) => {
          if (res1.botResponseId === receivedEntry.botResponseId) {
            res1 = receivedEntry;
          }
        });
      }
    );
  }

  getTemplateResponses(id: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(GetGlobalTempResComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      parentResponseId: id,
      context: "RESPONSE",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((resp) => {
          if (resp.botResponseId === id) {
            resp.nextBotResponseId = receivedEntry.botResponseId;
          }
        });
      }
    );
  }

  addOptionToErrorResponse(resP: BotResponse) {
    let continueOptionCreation = true;
    if (
      resP.resType === "image with buttons" ||
      resP.resType === "video with buttons"
    ) {
      if (resP.options !== null && resP.options !== undefined) {
        let optionsCount = 0;
        resP.options.forEach((optn) => {
          if (optn.botResponseOptionsStatus === true) {
            optionsCount++;
          }
        });
        if (optionsCount >= 3) {
          this.toaster.error(
            "Number of active options can be upto 3 only to use 'Image/Video With Buttons' Format, change format to add more options"
          );
          continueOptionCreation = false;
        }
      }
    }

    if (continueOptionCreation === true) {
      this.config.class = "modal-md";
      const modalRef = this.ngbModalService.open(
        AddBotResponseOptionsComponent,
        {
          size: "lg",
          scrollable: true,
          windowClass: "app-modal-window",
          keyboard: false,
        }
      );
      let data = {
        context: "addOptionToResponse",
        botResponseId: resP.botResponseId,
      };
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
      modalRef.componentInstance.passEntry.subscribe(
        (receivedEntry: BotResponseOptions) => {
          this.errorResponse.responses.forEach((res) => {
            if (res.botResponseId === resP.botResponseId) {
              if (res.options === null) {
                res.options = [];
              }
              res.optionsExist = true;
              res.viewOptions = true;
              receivedEntry.selected = false;
              res.options.push(receivedEntry);
            }
          });
        }
      );
    }
  }

  addOptionToTemplateResponse(resP: BotResponse) {
    let continueOptionCreation = true;
    if (
      resP.resType === "image with buttons" ||
      resP.resType === "video with buttons"
    ) {
      if (resP.options !== null && resP.options !== undefined) {
        let optionsCount = 0;
        resP.options.forEach((optn) => {
          if (optn.botResponseOptionsStatus === true) {
            optionsCount++;
          }
        });
        if (optionsCount >= 3) {
          this.toaster.error(
            "Number of active options can be upto 3 only to use 'Image/Video With Buttons' Format, change format to add more options"
          );
          continueOptionCreation = false;
        }
      }
    }

    if (continueOptionCreation === true) {
      this.config.class = "modal-md";
      const modalRef = this.ngbModalService.open(
        AddBotResponseOptionsComponent,
        {
          size: "lg",
          scrollable: true,
          windowClass: "app-modal-window",
          keyboard: false,
        }
      );
      let data = {
        context: "addOptionToResponse",
        botResponseId: resP.botResponseId,
      };
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
      modalRef.componentInstance.passEntry.subscribe(
        (receivedEntry: BotResponseOptions) => {
          this.templateResponse.responses.forEach((res) => {
            if (res.botResponseId === resP.botResponseId) {
              if (res.options === null) {
                res.options = [];
              }
              res.optionsExist = true;
              res.viewOptions = true;
              receivedEntry.selected = false;
              res.options.push(receivedEntry);
            }
          });
        }
      );
    }
  }

  addOptionToResponse(resP: BotResponse) {
    let continueOptionCreation = true;
    if (
      resP.resType === "image with buttons" ||
      resP.resType === "video with buttons"
    ) {
      if (resP.options !== null && resP.options !== undefined) {
        let optionsCount = 0;
        resP.options.forEach((optn) => {
          if (optn.botResponseOptionsStatus === true) {
            optionsCount++;
          }
        });
        if (optionsCount >= 3) {
          this.toaster.error(
            "Number of active options can be upto 3 only to use 'Image/Video With Buttons' Format, change format to add more options"
          );
          continueOptionCreation = false;
        }
      }
    }

    if (continueOptionCreation === true) {
      this.config.class = "modal-md";
      const modalRef = this.ngbModalService.open(
        AddBotResponseOptionsComponent,
        {
          size: "lg",
          scrollable: true,
          windowClass: "app-modal-window",
          keyboard: false,
        }
      );
      let data = {
        context: "addOptionToResponse",
        botResponseId: resP.botResponseId,
      };
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
      modalRef.componentInstance.passEntry.subscribe(
        (receivedEntry: BotResponseOptions) => {
          this.botResponse.responses.forEach((res) => {
            if (res.botResponseId === resP.botResponseId) {
              if (res.options === null) {
                res.options = [];
              }
              res.optionsExist = true;
              res.viewOptions = true;
              receivedEntry.selected = false;
              res.options.push(receivedEntry);
            }
          });
        }
      );
    }
  }

  showFlow() {
    if (
      this.botResponse.responses !== null &&
      this.botResponse.responses.length > 0
    ) {
      this.viewResponses = true;
    } else {
      this.toaster.error("Create Conversation initiation response first");
      // this.viewConvoInitiationTreeDef();
      this.addConvoInitiationResponse();
    }
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  goToSetupPage() {
    this.router.navigate(["dashboard/cb-app-setup"]);
  }

  goToAdminManagementPage() {
    this.router.navigate(["dashboard/bot-admin/management"]);
  }

  goToMedicalSpecialistsManagementPage() {
    this.router.navigate([
      "dashboard/bot-admin/medical-specialists-management",
    ]);
  }

  goToUsersConversationsPage() {
    this.router.navigate(["dashboard/user-conversations"]);
  }

  goToCustomerUsersPage() {
    this.router.navigate(["dashboard/cb-office-customers"]);
  }

  goToSchedulesPage() {
    this.router.navigate(["dashboard/cb-office-schedules"]);
  }

  goToAutoScheduleSettingsPage() {
    this.router.navigate(["dashboard/bot/auto-scheduler-settings"]);
  }

  goToTemplatesPage() {
    this.router.navigate(["dashboard/cb-template-management"]);
  }

  goToAPIConfigsPage() {
    this.router.navigate(["dashboard/bot/api-config"]);
  }

  goToAdminNotificationPage() {
    this.router.navigate(["dashboard/cb-admin-notification"]);
  }

  goToShopPage() {
    this.router.navigate(["dashboard/cb-office-shop"]);
  }

  goToShopOrdersPage() {
    this.router.navigate(["dashboard/cb-office-shop/orders"]);
  }

  goToFormsManagementPage() {
    this.router.navigate(["dashboard/cb-office/forms"]);
  }

  goToCrmListsDashboard() {
    this.router.navigate(["dashboard/biz-crm/lists"]);
  }

  viewLanguageVariationsForResponse(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewResLangVariationsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponse: botResponse,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {}
    );
  }

  viewLanguageVariationsForOptions(botResOption: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      ViewOptionLangVariationsComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      botResOption: botResOption,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {}
    );
  }

  createOrLinkApiConfigurationToGetOptions(botRes: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotApiConfigComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponse: botRes,
      context: "addBotApiConfigAndLinkToResponseForOptions",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotApiConfiguration) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === botRes.botResponseId) {
            res.optionsByExtApi = [];
            res.optionsExist = true;
            res.getOptionsByExtApi = true;
            res.fkApiConfigId = receivedEntry.botApiConfigId;
            res.optionsByExtApiTitle = receivedEntry.title;
          }
        });
      }
    );
  }

  // disableInitiateCallbackNotifsForOption(resId: any, optionId: any) {
  //   this.botResponseService
  //     .getApiBasedOptionsByConfigId(
  //       environment.apiUrl +
  //         "botConvo/botResOptionInitiateCallbackNotifsStatusUpdate/" +
  //         optionId
  //     )
  //     .then((data) => {
  //       this.toaster.success(
  //         "Callback Notifications Status updated successfully"
  //       );
  //       this.botResponse.responses.forEach((resp) => {
  //         if (
  //           resp.botResponseId === resId &&
  //           resp.options !== null &&
  //           resp.options !== undefined &&
  //           resp.options.length > 0
  //         ) {
  //           resp.options.forEach((optn) => {
  //             if (optn.botResponseOptionsId === optionId) {
  //               optn.initiateCallbackNotifications =
  //                 !optn.initiateCallbackNotifications;
  //             }
  //           });
  //         }
  //       });
  //     });
  // }

  enableInitiateCallbackNotifsForOption(resId: any, optionId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "add_callback_response_to_option",
      botResponseOptionsId: optionId,
      flows: this.botResponse.customizableTreeDefList,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((resp) => {
          if (
            resp.botResponseId === resId &&
            resp.options !== null &&
            resp.options !== undefined &&
            resp.options.length > 0
          ) {
            resp.options.forEach((optn) => {
              if (optn.botResponseOptionsId === optionId) {
                optn.initiateCallbackNotifications = true;
                this.botResponse.customizableTreeDefList.forEach((treeDef) => {
                  if (treeDef.botTreeDefinitionId === receivedEntry.treeDefId) {
                    optn.fkCallbackTreeDefDefinition = treeDef.definition;
                    optn.callbackNotifFunctionAgentFilter =
                      receivedEntry.filterVar;
                  }
                });
              }
            });
          }
        });
      }
    );
  }

  deactivateOptionCallbackResponse(resId: any, option: BotResponseOptions) {
    this.botResponse.customizableTreeDefList.forEach((treeDef) => {
      if (treeDef.botTreeDefinitionId === option.fkCallbackTreeDefinitionId) {
        option.fkCallbackTreeDefDefinition = treeDef.definition;
      }
    });

    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "deactivate_option_callback_response",
      botResponseOption: option,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.botResponse.responses.forEach((resp) => {
        if (
          resp.botResponseId === resId &&
          resp.options !== null &&
          resp.options !== undefined &&
          resp.options.length > 0
        ) {
          resp.options.forEach((optn) => {
            if (optn.botResponseOptionsId === option.botResponseOptionsId) {
              optn.initiateCallbackNotifications = false;
              optn.fkCallbackTreeDefDefinition = null;
              optn.callbackNotifFunctionAgentFilter = null;
            }
          });
        }
      });
    });
  }

  setupOptionCallbackFunction(resId: any, option: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setup_option_callback_function",
      botResponseOption: option,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.botResponse.responses.forEach((resp) => {
        if (
          resp.botResponseId === resId &&
          resp.options !== null &&
          resp.options !== undefined &&
          resp.options.length > 0
        ) {
          resp.options.forEach((optn) => {
            if (optn.botResponseOptionsId === option.botResponseOptionsId) {
              optn.callbackNotificationFunction = receivedEntry;
            }
          });
        }
      });
    });
  }

  setupResponseCallbackFunction(response: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setup_response_callback_function",
      botResponse: response,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.botResponse.responses.forEach((resp) => {
        if (resp.botResponseId === response.botResponseId) {
          resp.callbackNotifResFunction = receivedEntry;
        }
      });
    });
  }

  getApiBasedOptionsByConfigId(botRes: BotResponse) {
    this.botResponseService
      .getApiBasedOptionsByConfigId(
        environment.apiUrl +
          "botConvo/getApiBasedOptionsByConfigId/" +
          botRes.fkApiConfigId
      )
      .then((data) => {
        let optnObj: BotResponseOptions = new BotResponseOptions();
        optnObj = data;

        if (optnObj.successfulApiCall === true) {
          this.botResponse.responses.forEach((res) => {
            if (res.botResponseId === botRes.botResponseId) {
              if (
                res.optionsByExtApi === null ||
                res.optionsByExtApi === undefined
              ) {
                res.optionsByExtApi = [];
              }

              optnObj.childOptions.forEach((opt) => {
                res.optionsByExtApi.push(opt);
              });
            }
          });
        } else if (optnObj.successfulApiCall === false) {
          this.toaster.error("Could not complete the request !");
        }
      });
  }

  getMembersBasedOptions(botRes: BotResponse) {
    this.botResponseService
      .getApiBasedOptionsByConfigId(
        environment.apiUrl +
          "botConvo/getMembersBasedOptions/" +
          botRes.optionsFromBotMembersInfoDetail
      )
      .then((data) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === botRes.botResponseId) {
            if (res.options === null) {
              res.options = [];
            }
            let optnObj: BotResponseOptions = new BotResponseOptions();
            optnObj = data;
            if (
              res.optionsByMembersInfo === null ||
              res.optionsByMembersInfo === undefined
            ) {
              res.optionsByMembersInfo = [];
            }
            if (
              optnObj.childOptions !== null &&
              optnObj.childOptions !== undefined &&
              optnObj.childOptions.length > 0
            ) {
              optnObj.childOptions.forEach((opt) => {
                // res.options.push(opt);
                res.optionsByMembersInfo.push(opt);
              });
            } else {
              this.toaster.error("Options Not Found !");
            }
          }
        });
      });
  }

  linkNextResponseToOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkNextResponseToOption",
      botResponseOptionsId: id,
      botResponses: this.allResponses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res) => {
          if (res.options !== null) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );
  }

  linkNextResponseToErrResOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkNextResponseToOption",
      botResponseOptionsId: id,
      botResponses: this.allResponses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.errorResponse.responses.forEach((res) => {
          if (res.options !== null) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );
  }

  linkNextResponseToTemplateResOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkNextResponseToOption",
      botResponseOptionsId: id,
      botResponses: this.allResponses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.templateResponse.responses.forEach((res) => {
          if (res.options !== null && res.options !== undefined) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );
  }

  linkErrResOptionToUserLastErrResponse(optionId: any, resId: any) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/linkErrResOptionToUserLastErrResponse/" +
          optionId
      )
      .then((res) => {
        this.toaster.success(
          "Linked To User's Previous Error Response successfully"
        );
        this.errorResponse.responses.forEach((res) => {
          if (res.botResponseId === resId && res.options !== null) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === optionId) {
                optn.linkToLastUserErrorResponse =
                  !optn.linkToLastUserErrorResponse;
              }
            });
          }
        });
      });
  }

  addNextResponseToOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addResponseToOption",
      botResponseOptionsId: id,
      eligibleVariableList: this.createEligibleVariableList(null),
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((resp) => {
          if (
            resp.options !== null &&
            resp.options !== undefined &&
            resp.options.length !== 0
          ) {
            resp.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );
  }

  editOption(botResponseOption0: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editOption",
      botResponseOption: botResponseOption0,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === receivedEntry.fkBotResponseId) {
            res.options.forEach((option) => {
              if (
                option.botResponseOptionsId ===
                receivedEntry.botResponseOptionsId
              ) {
                option.responseOption = receivedEntry.responseOption;
                option.responseOptionNumber =
                  receivedEntry.responseOptionNumber;
                option.responseOptionDescription =
                  receivedEntry.responseOptionDescription;
              }
            });
          }
        });
      }
    );
  }

  editBotErrResOption(botResponseOption0: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editOption",
      botResponseOption: botResponseOption0,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.errorResponse.responses.forEach((res) => {
          if (res.botResponseId === receivedEntry.fkBotResponseId) {
            res.options.forEach((option) => {
              if (
                option.botResponseOptionsId ===
                receivedEntry.botResponseOptionsId
              ) {
                option.responseOption = receivedEntry.responseOption;
                option.responseOptionNumber =
                  receivedEntry.responseOptionNumber;
                option.responseOptionDescription =
                  receivedEntry.responseOptionDescription;
              }
            });
          }
        });
      }
    );
  }

  editBotTemplateResOption(botResponseOption0: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editOption",
      botResponseOption: botResponseOption0,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.templateResponse.responses.forEach((res) => {
          if (res.botResponseId === receivedEntry.fkBotResponseId) {
            res.options.forEach((option) => {
              if (
                option.botResponseOptionsId ===
                receivedEntry.botResponseOptionsId
              ) {
                option.responseOption = receivedEntry.responseOption;
                option.responseOptionNumber =
                  receivedEntry.responseOptionNumber;
                option.responseOptionDescription =
                  receivedEntry.responseOptionDescription;
              }
            });
          }
        });
      }
    );
  }

  getTemplateResponsesForOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(GetGlobalTempResComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      parentOptionId: id,
      context: "OPTION",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res) => {
          if (res.options !== null) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
                optn.nextBotResponseResponse = receivedEntry.response;
              }
            });
          }
        });
      }
    );
  }

  unlinkNextResponseForOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl + "botConvo/unlinkNextResponseForOption/" + id
      )
      .then((res) => {
        this.toaster.success("Next response unlinked successfully");

        let currResponse: BotResponse = new BotResponse();
        this.botResponse.responses.forEach((res) => {
          if (
            res.options !== null &&
            res.options !== undefined &&
            res.options.length > 0
          ) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = null;
                optn.selected = false;
              }
            });
          }

          if (res.botResponseId === responseId) {
            currResponse = res;
          }
        });

        if (qsnAnswered === true && optionSelected == true) {
          let removeFrom = currResponse.responseCountNumber + 1;
          let removeTill = this.botResponse.responses.length + 1;

          for (let i = removeFrom; i < removeTill; i++) {
            this.botResponse.responses.forEach((element, index) => {
              if (element.responseCountNumber == i)
                this.botResponse.responses.splice(index, 1);
            });
          }
        }
      });
  }

  unlinkNextResponseForErrResOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl + "botConvo/unlinkNextResponseForOption/" + id
      )
      .then((res) => {
        this.toaster.success("Next response unlinked successfully");

        this.errorResponse.responses.forEach((res) => {
          if (
            res.options !== null &&
            res.options !== undefined &&
            res.options.length > 0
          ) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = null;
                optn.selected = false;
              }
            });
          }
        });
      });
  }

  unlinkNextResponseForTemplateResOption(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl + "botConvo/unlinkNextResponseForOption/" + id
      )
      .then((res) => {
        this.toaster.success("Next response unlinked successfully");

        this.templateResponse.responses.forEach((res) => {
          if (
            res.options !== null &&
            res.options !== undefined &&
            res.options.length > 0
          ) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = null;
                optn.selected = false;
              }
            });
          }
        });
      });
  }

  ViewBotOptionDetails(
    id: any,
    qsnAnswered: any,
    optionSelected: any,
    responseId: any
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotOptionDetailsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponseOptionsId: id,
      botResponses: this.allResponses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getResponsesByOfficeId();
    });

    modalRef.componentInstance.passEntry4.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((resp) => {
          if (
            resp.options !== null &&
            resp.options !== undefined &&
            resp.options.length !== 0
          ) {
            resp.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );

    modalRef.componentInstance.passEntry2.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === receivedEntry.fkBotResponseId) {
            res.options.forEach((option) => {
              if (
                option.botResponseOptionsId ===
                receivedEntry.botResponseOptionsId
              ) {
                option.responseOption = receivedEntry.responseOption;
                option.responseOptionNumber =
                  receivedEntry.responseOptionNumber;
              }
            });
          }
        });
      }
    );

    modalRef.componentInstance.passEntry3.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res) => {
          if (res.options !== null) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
              }
            });
          }
        });
      }
    );

    //unlink next response
    modalRef.componentInstance.passEntry5.subscribe((resOptionId: string) => {
      let currResponse: BotResponse = new BotResponse();
      this.botResponse.responses.forEach((res) => {
        if (
          res.options !== null &&
          res.options !== undefined &&
          res.options.length > 0
        ) {
          res.options.forEach((optn) => {
            if (optn.botResponseOptionsId === resOptionId) {
              optn.nextBotResponseId = null;
              optn.selected = false;
            }
          });
        }

        if (res.botResponseId === responseId) {
          currResponse = res;
        }
      });

      if (qsnAnswered === true && optionSelected == true) {
        let removeFrom = currResponse.responseCountNumber + 1;
        let removeTill = this.botResponse.responses.length + 1;

        for (let i = removeFrom; i < removeTill; i++) {
          this.botResponse.responses.forEach((element, index) => {
            if (element.responseCountNumber == i)
              this.botResponse.responses.splice(index, 1);
          });
        }
      }
    });

    modalRef.componentInstance.passEntry6.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse.responses.forEach((res) => {
          if (res.options !== null) {
            res.options.forEach((optn) => {
              if (optn.botResponseOptionsId === id) {
                optn.nextBotResponseId = receivedEntry.botResponseId;
                optn.nextBotResponseResponse = receivedEntry.response;
              }
            });
          }
        });
      }
    );
  }

  selectedFile1!: File;
  onFileSelected1(event: any) {
    this.selectedFile1 = event.target.files[0];
  }

  data = {
    object: "invoice",
    token: "eEZBZBKpHchcQiFZCGv",
    customer: {
      name: "Maan",
      phone: "919731294626",
    },
    invoice: {
      type: "pdf",
      number: "123123",
      amount: "23566",
      date: "19-06-2022",
    },
  };

  dataUrl = {
    object: "invoice",
    token: "eEZBZBKpHchcQiFZCGv",
    customer: {
      name: "Maan",
      phone: "919731294626",
    },
    invoice: {
      type: "url",
      number: "123123",
      amount: "23566",
      date: "19-06-2022",
      url: "https://www.zoti.in/inv/getInvoice/ids?invoice_num=12e7854a5",
    },
  };

  testUrlLocal = "https://zoti-api-preview.omnileadz.com/zotibot/ext/invoice/testApi";
  testUrlDeployed = "https://zoti-api-preview.omnileadz.com/zotibot/ext/invoice/testApi";

  test() {
    let body = new FormData();
    body.append("file", this.selectedFile1);

    body.append("body", JSON.stringify(this.dataUrl));

    this.officeService.createOffice(this.testUrlLocal, body).then((res) => {});
  }

  test2() {
    let body = new FormData();
    body.append("file", this.selectedFile1);

    body.append("body", JSON.stringify(this.data));

    this.officeService.createOffice(this.testUrlLocal, body).then((res) => {});
  }

  optionsFromBotMembersInfoDetailType = null;
  optionsFromBotMembersInfoResponseId: any;

  setOptionsFromBotMembersInfoType() {
    if (this.optionsFromBotMembersInfoDetailType !== null) {
      this.officeService
        .setOptionsFromBotMembersInfoType(
          environment.apiUrl +
            "botConvo/setOptionsFromBotMembersInfoType/" +
            this.optionsFromBotMembersInfoResponseId +
            "/" +
            this.optionsFromBotMembersInfoDetailType
        )
        .then((res) => {
          this.botResponse.responses.forEach((res) => {
            if (
              res.botResponseId === this.optionsFromBotMembersInfoResponseId
            ) {
              res.optionsExist = true;
              res.getOptionsFromBotMembersInfo = true;
              res.optionsFromBotMembersInfoDetail =
                this.optionsFromBotMembersInfoDetailType;
              res.options = [];
              this.optionsFromBotMembersInfoDetailType = null;
            }
          });
        });
    } else {
      this.toaster.error("Options type required !");
    }
  }

  removeOptionsFromBotMembersInfoType(resId: any) {
    this.showSpinner = true;
    this.officeService
      .setOptionsFromBotMembersInfoType(
        environment.apiUrl +
          "botConvo/removeOptionsFromBotMembersInfoType/" +
          resId
      )
      .then((data) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === resId) {
            res.optionsExist = true;
            res.getOptionsFromBotMembersInfo = false;
            res.optionsFromBotMembersInfoDetail = [];
            res.options = [];
            this.optionsFromBotMembersInfoDetailType = null;
            this.showSpinner = false;
          }
        });
      });
  }

  createErrorResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createErrorResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        receivedEntry.viewOptions = true;
        receivedEntry.qsnAnswered = false;
        this.errorResponse.responses.push(receivedEntry);
      }
    );
  }

  createTemplateResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createTemplateResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        receivedEntry.viewOptions = true;
        receivedEntry.qsnAnswered = false;
        if (
          this.templateResponse.responses === null ||
          this.templateResponse.responses === undefined
        ) {
          this.templateResponse.responses = [];
        }
        this.templateResponse.responses.push(receivedEntry);
      }
    );
  }

  setOfficeDefaultErrorResponse() {
    let currentDefErrRes = null;
    this.errorResponse.responses.forEach((errorRes) => {
      if (errorRes.isDefaultErrorResponse === true) {
        currentDefErrRes = errorRes;
      }
    });
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setOfficeDefaultErrorResponse",
      errorResponses: this.errorResponse.responses,
      currentDefaultErrorRes: currentDefErrRes,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.errorResponse.responses.forEach((resp) => {
        if (resp.botResponseId === receivedEntry) {
          resp.isDefaultErrorResponse = true;
        } else {
          resp.isDefaultErrorResponse = false;
        }
      });
    });
    modalRef.componentInstance.passEntryRemoveDefErrorRes.subscribe(
      (receivedEntry: any) => {
        this.errorResponse.responses.forEach((resp) => {
          resp.isDefaultErrorResponse = false;
        });
      }
    );
  }

  addQuickReplyButtons(botResponse: any) {
    this.botResponseService
      .addQuickReplyButtons(
        environment.apiUrl + "botConvo/addQuickReplyButtonsToResponse",
        botResponse
      )
      .then((data) => {
        let resDto: BotResponseOptions = new BotResponseOptions();
        resDto = data;
        this.botResponse.responses.forEach((resA) => {
          if (resA.botResponseId === botResponse.botResponseId) {
            if (resA.options === null || resA.options === undefined) {
              resA.options = [];
            }
            resA.optionsExist = true;
            resA.viewOptions = true;
            resDto.list.forEach((newOption) => {
              newOption.selected = false;
              resA.options.push(newOption);
            });
          }
        });
        this.toaster.success("Buttons added successfully !");
      });
  }

  updateCondResponse(botCondRes: BotConditionalResponse) {
    this.botResponseService
      .addQuickReplyButtons(
        environment.apiUrl + "botConvo/editConditionalResponse",
        botCondRes
      )
      .then((data) => {
        this.toaster.success("Condition updated successfully !");
      });
  }

  viewAdditionalResponses(botRes: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewDefinitionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "view additional responses",
      botRes: botRes,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.botResponse.responses.forEach((resp) => {
        if (resp.botResponseId === botRes.botResponseId) {
          resp.additionalSubResOrder = receivedEntry;
        }
      });
    });
  }

  createEligibleVariableList(removeVarRes: any) {
    let eligibleVariableList: BotResponse[] = [];

    this.botResponse.responses.forEach((res) => {
      if (res.saveVar === true) {
        let addToList = true;
        if (
          removeVarRes !== null &&
          removeVarRes !== undefined &&
          removeVarRes.botResponseId === res.botResponseId
        ) {
          addToList = false;
        }
        if (addToList === true) {
          let newElgVar: BotResponse = new BotResponse();
          newElgVar.var = "###" + res.var + "###";
          newElgVar.response = res.response;
          newElgVar.slNo = res.slNo;
          eligibleVariableList.push(newElgVar);
        }
      }
    });

    return eligibleVariableList;
  }
}
