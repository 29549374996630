export class Payment {

    paymentId!: string;
	paymentMethod!: string;
	paymentDate: any;
	paymentAmount!: DoubleRange;
	paymentCompletion!: string;
	paymentDue!: DoubleRange;
	fkInvoiceId!: string;
	paymentRemarks!: string;
	paymentStatus!: boolean;
	customerName : any
	fkOfficeId : any;

	todaysPayments : Payment[] = [];
	monthsPayments : Payment[] = [];

	fromDate : any;
	toDate : any;


}
