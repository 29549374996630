<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='Send Template Message To a User'" style="color: white;" 
    class="modal-title pull-left">Send Template Message</h4>
    <h4 *ngIf="fromParent.context==='Send Template Message To Particular User'" style="color: white;" 
    class="modal-title pull-left">Send Template Message To {{botCustomerUser.firstName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #adminForm="ngForm">  

        <div *ngIf="fromParent.context==='Send Template Message To a User'">
          <label class="required" for="receiverBotUserId">User</label>
          <select class="form-control" [(ngModel)]="botSchedule.receiverBotUserId">
            <option value=null disabled>Choose User</option>
            <option *ngFor="let user of botCustomerUser.users" [ngValue]="user.userId">{{user.firstName}}</option>
          </select>
        </div>
        <br *ngIf="fromParent.context==='Send Template Message To a User'">

        <div>
          <label class="required" for="fkBotTemplateId">Template</label>
          <select (change)="chooseTemplate();" required class="form-control" [(ngModel)]="botSchedule.fkBotTemplateId">
            <option value=null disabled>Choose Template</option>
            <option *ngFor="let temp of botTemplates" [ngValue]="temp.botTemplateId">{{temp.templateDisplayName}}</option>
          </select>
        </div>

        <br>
        <div *ngIf="chosenTemplate.tempHeaderType==='IMAGE' || chosenTemplate.tempHeaderType==='VIDEO' || chosenTemplate.tempHeaderType==='DOCUMENT'">
          <div>
            <label class="col-md-6" class="required" for="fkBotMediaIdForTemplateHeader">Choose header media</label> 
            <select required class="form-control" [(ngModel)]="botSchedule.fkBotMediaIdForTemplateHeader" 
            id="fkBotMediaIdForTemplateHeader" name="fkBotMediaIdForTemplateHeader">
              <option value=null disabled >Choose header media</option>
              <option  *ngFor="let med of media"
               [ngValue]="med.botMediaId"> {{med.title}}  
              </option>
            </select>
          </div>

          <br>
        <div style="float: right;">
<button style="width: auto;" (click)="uploadBotMedia();" class="btn btn-info">Upload New Media</button>
        </div>
        <br>

        </div>
        
        <div *ngIf="chosenTemplate.tempHeaderType==='TEXT'">
          <label class="required" for="headerTextForTemplateHeader">Header Text</label>
          <input #headerTextForHeader="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
          name="headerTextForTemplateHeader" id="headerTextForTemplateHeader" 
          [(ngModel)]="botSchedule.headerTextForTemplateHeader">
          <div class="alert alert-danger" *ngIf="headerTextForHeader.touched && !headerTextForHeader.valid">Header Text is Required</div>
       <br>
        </div>

        <div>
        <label for="fkBotTemplateId">Template Body</label>
        <textarea readonly [(ngModel)]="chosenTemplate.tempBody" name="chosenTemplate.tempBody" id="chosenTemplate.tempBody" 
        cols="30" rows="10" class="form-control"></textarea>
        </div>

        <br *ngIf="chosenTemplate.tempVarList !== null && chosenTemplate.tempVarList !== undefined && chosenTemplate.tempVarList.length > 0">
        <div *ngIf="chosenTemplate.tempVarList !== null && chosenTemplate.tempVarList !== undefined && chosenTemplate.tempVarList.length > 0">
          <label>Template Variables</label>
          <div class="form-group row">

            <div *ngFor="let templateVar of chosenTemplate.tempVarList" class="col-md-6">
            <label class="required">{{templateVar.botScheduleTempVarOrder}}</label>
            <input minlength="1" maxlength="50" required class="form-control" type="text" 
             [name]="templateVar.botScheduleTempVarOrder" [id]="templateVar.botScheduleTempVarOrder" 
             [(ngModel)]="templateVar.botScheduleTempVar">
            </div>
          
        </div>
        </div>
  


       

        
     



    
  
  

 
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   
[disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="onSubmit();">Send</button>
</div>