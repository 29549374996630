import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperadmindashboardComponent } from './superadmindashboard.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from "ngx-spinner";
import {ButtonModule} from 'primeng/button';
import {DataTablesModule} from 'angular-datatables';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule, ButtonModule,
    BsDropdownModule.forRoot(),
    NgxSpinnerModule,
    DataTablesModule,
    RouterModule.forChild([
      {
        path: '',
        component: SuperadmindashboardComponent
      }
    ])
  ],
  declarations: [SuperadmindashboardComponent]
})
export class SuperadmindashboardModule { }
