


   <div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Order</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 
             
              <div class="form-group row">
                  <div class="col-md-6">
                        <label class="required" for="fkCustomerId">Customer</label> <br>
                        <select #customer="ngModel" class="form-control" required  [(ngModel)]="invoice.fkCustomerId" id="fkCustomerId" name="fkCustomerId">
                          <option value="" disabled >Choose Customer</option>
                        <option *ngFor="let customer of customers"  [ngValue]="customer.customerId">{{customer.firstName}}</option>
                        </select>
                        <div class="alert alert-danger" *ngIf="customer.touched && !customer.valid">Select Customer</div>
                  </div>
                
  
                    

                  <div class="col-md-6">
                    <label class="required" for="invoiceDate">Order Date</label>
                    <input #date="ngModel" required class="form-control"  type="date" id="invoiceDate" name="invoiceDate" [(ngModel)]="invoice.invoiceDate">
                    <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Date is required</div>
                  </div>
              </div>
    
  <br>
               <div >
                  <table  border="1">
                      <thead>
                          <tr>
                              <th [style.background-color]='getColor()' >Product</th>
                              <th [style.background-color]='getColor()' >Qty</th>
                              <th [style.background-color]='getColor()'>Alt Qty</th>

                              <th [style.background-color]='getColor()' >Price per Qty</th>
                              
                              <th [style.background-color]='getColor()' >Sub-Total</th>
                              <th [style.background-color]='getColor()' >Discount per alt qty</th>
                              <th [style.background-color]='getColor()' >Total Discount</th>
                              <th [style.background-color]='getColor()' >Total</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor = "let invDet of invoiceDetailsList">
                              <td>{{invDet.itemName}}</td>
                              <td style="text-align: right;">{{invDet.quantity}}</td>
                              <td style="text-align: right;">{{invDet.alternateQuantity}}</td>

                              <td style="text-align: right;">{{invDet.pricePerItem}}</td>
                              <td style="text-align: right;">{{invDet.totalPriceBeforeDiscount}}</td>
                              <td style="text-align: right;">{{invDet.perAltQuantityDiscountRate}}</td>
                              <td style="text-align: right;">{{invDet.totalDiscountAmount}}</td>
                              <td style="text-align: right;">{{invDet.totalPrice}}</td>
                          </tr>
                          <tr *ngIf="invoiceDetailsList !== undefined && invoiceDetailsList.length !== 0">
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;font-weight: bold;">Sub-Total</td>
                            <td style="text-align: right;font-weight: bold;">{{invoice.totalAmount}}</td>

                          </tr>
                          <tr  *ngFor="let tax of taxesAndCess">
                            <!-- <div *ngIf="tax.type === 'tax'"> -->
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;"></td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;"></td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;"></td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;"></td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;"></td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;"></td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;">{{tax.name}}</td>
                            <td *ngIf="tax.type === 'tax'" style="text-align: right;">{{tax.calculatedTax}}</td>
                          <!-- </div> -->
                          </tr>
                          <tr  *ngFor="let tax of taxesAndCess">
                            <!-- <div *ngIf="tax.type === 'cess'"> -->
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;"></td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;"></td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;"></td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;"></td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;"></td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;"></td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;">{{tax.name}}</td>
                            <td  *ngIf="tax.type === 'cess'" style="text-align: right;">{{tax.calculatedTax}}</td>
                          <!-- </div> -->
                          </tr>
                          <tr  *ngIf="invoiceDetailsList !== undefined && invoiceDetailsList.length !== 0">
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;font-weight: bold;">Total Tax</td>
                            <td style="text-align: right;font-weight: bold;">{{invoice.totalTax}}</td>

                          </tr>
                          <tr  *ngIf="invoiceDetailsList !== undefined && invoiceDetailsList.length !== 0">
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;font-weight: bold;">Total Cess</td>
                            <td style="text-align: right;font-weight: bold;">{{invoice.totalCess}}</td>

                          </tr>
                          <tr *ngIf="invoiceDetailsList !== undefined && invoiceDetailsList.length !== 0">
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;"></td>
                            <td style="text-align: right;font-weight: bold;">Grand-Total</td>
                            <td style="text-align: right;font-weight: bold;">{{invoice.grandTotal}}</td>

                          </tr>
                      </tbody>
              </table>
          </div> <br>
  
        
          <a matTooltip="Add product" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
          (click)="addInvoiceDetails()">
          <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
        </a> 

        <br>
        <br>

        <!-- <button *ngIf="fromParent.role === 'BCRM_FM'" (click)="addTax();" class="btn btn-primary" style="float: right;width: auto;">Compute Tax</button> -->
   
        <br>

          <div>
          <label for="invoiceRemarks">Order Remarks</label>
          <textarea  #remarks="ngModel" maxlength="244" class="form-control" maxlength="244" type="text" id="invoiceRemarks" name="invoiceRemarks" [(ngModel)]="invoice.invoiceRemarks"></textarea>
          <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
          </div>
             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();">Save</button>
      </div>