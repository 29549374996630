


   <div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white; font-weight: bold;" class="modal-title pull-left">Announcements</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
   
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
 
      <div class="card">
        <div class="card-body table-responsive">
        
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead class="tableheader">
              <tr>
                  <th [style.background-color]='getColor()' >Message</th>
                <th [style.background-color]='getColor()' >Start Date</th>
                <th [style.background-color]='getColor()' >End Date</th>
                <th [style.background-color]='getColor()' >Action</th>
              </tr>
            </thead> 
            <tbody>
              <tr class="td-height" *ngFor="let target of announcements">
                <td class="td-height">{{target.message}}</td>
                <td class="td-height">{{target.formattedStartDate}}</td>
                <td class="td-height">{{target.formattedEndDate}}</td>
               
                <td  style="text-align: center;">
                  

                      <button (click)="editAnnouncements(target);" class="btn btn-info">Edit</button> 
                      <button (click)="open(confirmActivation);" class="btn btn-danger">Delete</button> 

                </td>
                
                <ng-template #confirmActivation let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Are you sure to delete announcement ?
                  </div> 
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                    <button type="button" class="btn btn-success" (click)="deleteAnnouncement(target.id);modal.dismiss('Cross click')">Delete</button>
                  </div>
                </ng-template> 
              
              </tr>
            </tbody>
          </table> 
  
        </div>
      </div>
    
  
   
      </div>
      </div>
      </div>
  