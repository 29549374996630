<nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active"><a style="cursor: pointer;" ><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      </ol>
    </nav>
  
    <div class="content">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
  

        <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
    
          <mat-card class="example-card mat-elevation-z12" style="border-radius: 25px;">
  
              <br>

              <div class="card-icon" >
                <a> 
                  <img class="card-icon-image"  src="assets/images/icons/subscription.svg"  alt="class" width="100" height="100"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
              <mat-card-title>Students / Parents</mat-card-title>
              <!-- <mat-card-subtitle>(Students / Teachers / Parents)</mat-card-subtitle> -->

            <mat-card-content style="margin-top: 8.5%;">
              <p>
                <button (click)="exploreSubscriptions();" style="width: 75%;" class="btn btn-info" [ngStyle]="backgroundStyle" mat-button>Explore</button>
              </p>
    
            </mat-card-content>
           
          </mat-card>
  
       
      </div>

        <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
          <mat-card class="example-card mat-elevation-z12" style="border-radius: 25px;">
    
            <br>

            <div class="card-icon" >
              <a> 
                <img class="card-icon-image" src="assets/images/icons/class.svg" alt="class" width="100" height="100"
                 alt="" loading="lazy">
              </a>
            </div>
             <br>
            <mat-card-title>Classes</mat-card-title>
            
              
     
          <mat-card-content style="margin-top: 8.5%;">
            <p>
              <button (click)="exploreClasses();" style="width: 75%;" class="btn btn-info" [ngStyle]="backgroundStyle" mat-button>Explore</button>
            </p>
  
          </mat-card-content>
         
        </mat-card>
    
         
        </div>
    
      
       
        <div fxFlex="33.3333%" fxFlex.xs="100%" fxFlex.sm="50%" >
    
            <mat-card class="example-card mat-elevation-z12" style="border-radius: 25px;">
    
                <br>

                <div class="card-icon" >
                  <a> 
                    <img class="card-icon-image" src="assets/images/icons/teacher.svg" alt="class" width="100" height="100"
                     alt="" loading="lazy">
                  </a>
                </div>
                 <br>
                 <mat-card-title>Teachers</mat-card-title>
                <!-- <mat-card-subtitle>(view only)</mat-card-subtitle> -->
              <mat-card-content style="margin-top: 8.5%;">
                <p>
                  <button (click)="exploreTeachers();" style="width: 75%;" class="btn btn-info" [ngStyle]="backgroundStyle" mat-button>Explore</button>
                </p>
      
              </mat-card-content>
             
            </mat-card>
    
         
        </div>
  
        <!-- <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
    
            <mat-card class="example-card mat-elevation-z12" style="border-radius: 25px;">
    
                <br>

                <div class="card-icon" >
                  <a> 
                    <img class="card-icon-image" src="assets/images/icons/students.svg" alt="class" width="100" height="100"
                     alt="" loading="lazy">
                  </a>
                </div>
                 <br>
                <mat-card-subtitle>Students</mat-card-subtitle>
         
              <mat-card-content>
                <p>
                  <button (click)="exploreStudents();" style="width: 75%;" class="btn btn-info" [ngStyle]="backgroundStyle" mat-button>Explore</button>
                </p>
      
              </mat-card-content>
             
            </mat-card>
         
        </div> -->
  
  
  
       
    
    
      </div>
      </div>
    </div>