import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LocationGroup } from 'src/app/BIZ_CRM/biz_crm_classes/location-group';
import { OptionsModalComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_options/options-modal/options-modal.component';
import { AddLocationGroupComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/locatinGroup/add-location-group/add-location-group.component';
import { UpdateLocationGroupComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/locatinGroup/update-location-group/update-location-group.component';
import { ViewLocationGroupBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-location-group-based-invoices/view-location-group-based-invoices.component';
import { ViewOrderDetailsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-order-details/view-order-details.component';
import { ViewProductBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-product-based-invoices/view-product-based-invoices.component';
import { LocationGroupService } from 'src/app/BIZ_CRM/biz_crm_services/location-group.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddBcrmShipmentComponent } from 'src/app/crm/shipment/add-bcrm-shipment/add-bcrm-shipment.component';
import { UpdateShipmentComponent } from 'src/app/crm/shipment/update-shipment/update-shipment.component';
import { ViewShipmentDetailsComponent } from 'src/app/crm/shipment/view-shipment-details/view-shipment-details.component';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-dm-home',
  templateUrl: './bcrm-dm-home.component.html',
  styleUrls: ['./bcrm-dm-home.component.scss']
})
export class BcrmDmHomeComponent implements OnInit {

  currentRole = "BCRM_DM";
  currentOrders = "Awaiting shipments";
  batchShipment=true;
  invoiceLoader = false;
  invoiceLoaderMessage!: string;
  invoiceExists!: boolean;
  invoiceOptions: any = {};
  showSpinner = false;

  selectedInvoiceId : any;
  selectedShipmentId : any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  loggedInUser: any;
  loggedUser: any;
  officeId : any;
  userName!: string;
  userId!: string;
  navbarTitle: any;
  closeResult!: string;


  constructor(private ngbModalService: NgbModal,
              private datePipe : DatePipe,
              public router: Router,
              private locationGroupService : LocationGroupService,
              private toaster: ToastrService,
              private invoiceService : InvoiceService, 
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    invoices: InvoiceList[] = [];
    locationGroupBasedInvoices: InvoiceList[] = [];

    locationGroups : LocationGroup[] = [];

  ngOnInit(): void {

    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

      this.getInvoiceDetails();
      this.getLocationGroups();

      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      this.invoiceOptions = {
        pagingType: 'full_numbers',
        pageLength: 5000,
        lengthChange: false,
        processing: true,
        info:false
      };
  }



  viewLocationGroupBasedOrders(locationGroupId : any, groupName : any){
    this.locationGroupBasedInvoices = [];
    this.invoices.forEach((value)=>{
      value.totalAmount =  Math.round((value.totalAmount + Number.EPSILON) * 100) / 100;
      value.grandTotal =  Math.round((value.grandTotal + Number.EPSILON) * 100) / 100;
      if(value.locationGroupId === locationGroupId){
        this.locationGroupBasedInvoices.push(value);
      }
    });
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewLocationGroupBasedInvoicesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      locationGroupBasedInvoices : this.locationGroupBasedInvoices,
      groupName : groupName,
      currentOrders : this.currentOrders
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        });   
  }

  getLocationGroups(){
    this.showSpinner = true;
    this.locationGroupService.getLocationGroupsByOfficeId(environment.apiUrl + "locationGroup/getLocationGroupsByOfficeId").then(
      data=>{
        this.locationGroups = data;
        this.locationGroups.forEach((lg)=>{
          lg.totalAmount = 0;
          lg.totalQuantity = 0;
          lg.totalNoOfOrders = 0;
          lg.totalAlternateQuantity = 0;
          lg.grandTotal = 0;
              this.invoices.forEach((inv)=>{
                inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
                inv.totalAmount =  Math.round((inv.totalAmount + Number.EPSILON) * 100) / 100;
                if(inv.locationGroupId === lg.id){
                  lg.totalAmount = lg.totalAmount + inv.totalAmount;
                  lg.totalQuantity = lg.totalQuantity + inv.totalQuantity;
                  lg.totalNoOfOrders = lg.totalNoOfOrders + 1;
                  lg.grandTotal = lg.grandTotal + inv.grandTotal;
                  lg.totalAlternateQuantity = lg.totalAlternateQuantity + inv.totalAlternateQuantity;
                }
              });
        });
        this.locationGroups.forEach((lg)=>{
          lg.totalAmount =  Math.round((lg.totalAmount + Number.EPSILON) * 100) / 100;
          lg.grandTotal =  Math.round((lg.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.dataSourceLG = this.locationGroups;
        this.showSpinner = false;
      });
  }

  dataSourceLG = this.locationGroups;
  exportArrayLG() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSourceLG.map(x => ({
      GroupName: x.groupName,
      GroupCode: x.groupCode,
      NumberOfOrders: x.totalNoOfOrders,
      TotalAltQty: x.totalAlternateQuantity,
      GrandTotal: x.grandTotal,
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

  exportArrayLG2() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSourceLG.map(x => ({
      GroupName: x.groupName,
      GroupCode: x.groupCode,
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

  statusUpdate(id : any){
    this.showSpinner = true;
    this.locationGroupService.updateStatus(environment.apiUrl + "locationGroup/statusUpdate/" + id).then(
      data=>{
       this.locationGroups.forEach((val)=>{
        if(val.id === id){
          val.activeStatus = !val.activeStatus;
        }
       });
       this.showSpinner = false;
      });
  }

  editLocationGroup(lg : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateLocationGroupComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     lg : lg
      }
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : LocationGroup) => {
          this.showSpinner = true;
        this.updateLocationGroupObjectById(receivedEntry);
        this.showSpinner = false;
        });   
  }

  updateLocationGroupObjectById(lg : LocationGroup){
    this.showSpinner = true;
    this.locationGroups.forEach((lGroup)=>{
      if(lGroup.id === lg.id){
        lGroup.groupName = lg.groupName;
        lGroup.groupCode = lg.groupCode;
      }
    });
    this.showSpinner = false;
  }
  
  addLocationGroup(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddLocationGroupComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
         this.getLocationGroups();
        });   
   
  }
 
   // authorized
   createShipment(invoice : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddBcrmShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      customerId : null,
      invoice : invoice,
      type : "single invoice shipment"
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
          this.showSpinner = true;
          this.invoiceService.changeOrderStatusToShipped(environment.apiUrl + "invoice/changeOrderStatusToShipped/" + invoice.invoiceId).then(
            data=>{
              this.removeInvoiceObjectFromInvoiceList(invoice.invoiceId);
              this.showSpinner = false;
            });
        });   
  }

  createBatchShipment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddBcrmShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      customerId : null,
      invoices : this.invoices,
      type : "batch shipment"
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : string[]) => {
          this.showSpinner = true;
          receivedEntry.forEach((invoiceId)=>{
            this.removeInvoiceObjectFromInvoiceList(invoiceId);
          });
          this.showSpinner = false;
        });   
  }

  removeInvoiceObjectFromInvoiceList(invoiceId : any){
    this.showSpinner = true;
    this.invoices.forEach( (invoice, index) => {
      if(invoice.invoiceId === invoiceId) this.invoices.splice(index,1);
    });
    this.rearrangeLocationGroups();
    this.showSpinner = false;
  }

  rearrangeLocationGroups(){  
    this.showSpinner = true;  
    this.locationGroups.forEach((lg)=>{
      lg.totalAmount = 0;
      lg.totalQuantity = 0;
      lg.totalNoOfOrders = 0;
      lg.totalAlternateQuantity = 0;
      lg.grandTotal = 0;
          this.invoices.forEach((inv)=>{
            inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
            inv.totalAmount =  Math.round((inv.totalAmount + Number.EPSILON) * 100) / 100;
            if(inv.locationGroupId === lg.id){
              lg.totalAmount = lg.totalAmount + inv.totalAmount;
              lg.totalQuantity = lg.totalQuantity + inv.totalQuantity;
              lg.totalNoOfOrders = lg.totalNoOfOrders + 1;
              lg.grandTotal = lg.grandTotal + inv.grandTotal;
              lg.totalAlternateQuantity = lg.totalAlternateQuantity + inv.totalAlternateQuantity;
            }
          });
    });
    this.locationGroups.forEach((lg)=>{
      lg.totalAmount =  Math.round((lg.totalAmount + Number.EPSILON) * 100) / 100;
      lg.grandTotal =  Math.round((lg.grandTotal + Number.EPSILON) * 100) / 100;
    });
    this.showSpinner = false;
  }

    viewProductBasedOrders(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewProductBasedInvoicesComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  
    let data = {
      invoices : this.invoices
    }
  
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  });
  }

  getInvoiceDetails(){
    this.showSpinner = true;
      this.invoiceService.getInvoicesByOfficeIdForBcrmDm(environment.apiUrl +  "invoice/getInvoicesByOfficeIdForBcrmDm").then(
        data => {
        this.invoices= data;
        this.getLocationGroups();
        this.invoices.forEach((val)=>{
          val.invoiceDate = this.datePipe.transform(val.invoiceDate, 'dd-MM-yyyy'); 
          val.totalAmount =  Math.round((val.totalAmount + Number.EPSILON) * 100) / 100;
          val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;
        });
        this.dataSource = this.invoices;
          this.invoiceExists = true;
          this.showSpinner = false;
        });
  }

  dataSource = this.invoices;

    exportArray() {
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
        Executive: x.executiveName,
        Customer: x.customerName,
        Status: x.orderStatus,
        Date: x.invoiceDate,
        TotalAltQty: x.totalAlternateQuantity,
        TotalAmt: x.grandTotal
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
    } 


  viewShipmentDetailsModal(shipmentId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewShipmentDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        shipmentId: shipmentId 
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  }


   // authorized
   editShipment(shipmentId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      shipmentId: shipmentId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });  
    
  }


  editShipmentForDelivery(shipmentId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      shipmentId: shipmentId,
      context : "DELIVERY"
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {

      this.showSpinner = true;

      this.invoiceService.markOrderAsDelivered(environment.apiUrl + "invoice/changeOrderStatusToDelivered/" + this.selectedInvoiceId ).then(
        data=>{
          this.toaster.success("Order status changed successfully");
          this.removeInvoiceObjectFromInvoiceList(this.selectedInvoiceId);
          this.showSpinner = false;
        });
       });  
  }

  viewInvoiceDetailsModal(invoiceId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOrderDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });

    let data = {
      invoiceId: invoiceId ,
      type : "order"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  }

 openOptions(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( OptionsModalComponent,
        {
          size : 'sm',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        userId : this.userId,
        currentRole : this.currentRole
      }
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  this.showSpinner = true;
        this.invoiceService.getInvoicesByOfficeIdForBcrmDm(environment.apiUrl + receivedEntry.link).then(data => {
          this.invoices = [];
          this.invoices = data;
          this.invoices.forEach((val)=>{
            val.invoiceDate = this.datePipe.transform(val.invoiceDate, 'dd-MM-yyyy'); 
            val.totalAmount =  Math.round((val.totalAmount + Number.EPSILON) * 100) / 100;
            val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;
          });
          this.rearrangeLocationGroups();
          this.dataSource = this.invoices;
            this.invoiceExists = true;
            this.currentOrders = receivedEntry.displayName;
            if(this.currentOrders==="Awaiting shipments"){
              this.batchShipment = true;
            }else{
              this.batchShipment = false;
            }
            this.toaster.success(this.currentOrders);
            this.showSpinner = false;
          });
      });
    }

    markOrderAsDelivered(){
      this.editShipmentForDelivery(this.selectedShipmentId);
    }

    getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
        return this.loggedInUser.offices[0].theme + '!important';
    }
  
    reloadPage() {
      window.location.reload();
    }
    
    goToTicketSupportPage(){
      this.router.navigate(['dashboard/ticket-support']);
    }

    open(content: any) {
      this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
      
    }


  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

}