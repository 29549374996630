<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Question</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form (ngSubmit)="onSubmit()" #aqForm="ngForm"> 
        

  <div class="col-md-6">
    <label class="required"  for="activityQuestionOrder">Order</label>
    <input #Order="ngModel" placeholder="Enter Order" required class="form-control" type="number" name="activityQuestionOrder"  id="activityQuestionOrder" [(ngModel)]="actQuestion.activityQuestionOrder">
    <div class="alert alert-danger" *ngIf="Order.touched && !Order.valid">Order is required</div>
 </div>

 <br>
    <div class="col-md-12">
    <label class="required" for="activityQuestion">Question</label>
    <input maxlength="250" #activityPlannerName="ngModel" required placeholder="Enter Question" required class="form-control" type="text" name="activityQuestion"  id="activityQuestion" [(ngModel)]="actQuestion.activityQuestion">
    <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Enter Question</div>
    </div>

    <!-- <br> -->

    <div class="form-group row">

   

     <!-- <div class="col-md-6">
     <label class="required">Answer Type</label> <br>
        <mat-radio-group required aria-label="customerGender" [(ngModel)]="actQuestion.activityQuestionType">
          <mat-radio-button selected name="activityQuestionType" id="R" value="R"  >Radio</mat-radio-button>
          <mat-radio-button name="activityQuestionType" id="others" value="others"  >Others</mat-radio-button>
        </mat-radio-group>
    </div> -->

</div>


    <!-- <div>
        <label for="activityQuestionDescription">Description</label>
        <textarea placeholder="Enter Description" class="form-control" type="text" name="activityQuestionDescription"  id="activityQuestionDescription" [(ngModel)]="actQuestion.activityQuestionDescription"></textarea>
    </div> -->

</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
</div>