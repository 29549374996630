import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
import { AddTaxComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/tax/add-tax/add-tax.component';
import { EditTaxComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/tax/edit-tax/edit-tax.component';
import { TaxService } from 'src/app/BIZ_CRM/biz_crm_services/tax.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddProductComponent } from 'src/app/crm/product/add-product/add-product.component';
import { UpdateProductComponent } from 'src/app/crm/product/update-product/update-product.component';
import { ViewProductDetailsComponent } from 'src/app/crm/product/view-product-details/view-product-details.component';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crm-product-dashboard',
  templateUrl: './crm-product-dashboard.component.html',
  styleUrls: ['./crm-product-dashboard.component.scss']
})
export class CrmProductDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";
  closeResult!:string;
  productLoader = false;

  productLoaderMessage!: string;

  productExists!: boolean;

  productOptions: any = {};

  products: Product[] = [];
  loggedInUser: any;
  officeId!: string;

  userName ! : string;
  userId!: string;
  loggedUser: any;
  navbarTitle: any;
 
  constructor(private ngbModalService: NgbModal,
    public router: Router,
    private productService: ProductService,
    private taxService : TaxService,
    public bsModalRef: BsModalRef,
    private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;
    
    taxes : Tax[] = [];
    taxesExists = false;
    showSpinner = false;

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser.firstName + ' ' + this.loggedInUser.lastName;
    this.officeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;


    this.getProductDetails();
    this.getTaxesByOfficeId();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.productOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    }; 
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

   // authorized
  openModalForProductAdd(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddProductComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });
      

let data = {
  officeId: this.loggedInUser.offices[0].id,
  officeType : "EDU"
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {

      this.getProductDetails();

    }); 
    
  }
  
  // authorized
  getProductDetails(){
    this.showSpinner = true;
    this.productService.getProductListByOfficeIdForCrmAdmin(environment.apiUrl + "crmproduct/getProductListByOfficeIdForCrmAdmin/" + this.officeId).then(data => {
      this.products = [];
      this.products= data;
      this.productExists = true;
      this.showSpinner = false;
    });
  }

 // authorized
  updateProductStatus(productId : string): void {
    this.showSpinner = true;
      this.productService.changeStatus(environment.apiUrl + "crmproduct/statusUpdate/" + productId).then(
      data =>{
        this.products.forEach((prod)=>{
          if(prod.productId===productId){
            prod.productStatus = !prod.productStatus;
          }
        })
        this.showSpinner = false;
      });
  } 

  // authorized
  editProduct(productId: string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateProductComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        
      keyboard: false
      });
      

let data = {
  productId: productId ,
  officeId: this.loggedInUser.offices[0].id,
  officeType : "EDU"
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {
      this.getProductDetails();
    }); 
  } 

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

    changePassword(): void {
      this.router.navigate(['/auth/change-password']).then();
  }


  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  
  reloadPage() {
    window.location.reload();
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    open(content: any) {
      this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
      
    }

    getTaxesByOfficeId(){
      this.taxService.getTaxesByOfficeId(environment.apiUrl + "tax/getTaxesByOfficeId").then(
        data=>{
          this.taxes = data;
          this.dataSourceTaxes = this.taxes;
          this.taxesExists = true;
        });
    }
    dataSourceTaxes = this.taxes;
    exportTaxArray() {
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSourceTaxes.map(x => ({
        Name: x.name,
        Type: x.type,
        Value: x.value
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "taxes");
    }

    addTax(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddTaxComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
        keyboard: false
        });
        
  
  let data = {
  
      }
  
  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  
        this.getTaxesByOfficeId();
     
      }); 
      
    }

    editTax(tax : any){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(EditTaxComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
        keyboard: false
        });
        
  
  let data = {
   tax : tax
      }
  
  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  
        this.getTaxesByOfficeId();
     
      }); 
      
    }

    taxStatusUpdate(id : any){
      this.taxService.statusUpdate(environment.apiUrl + "tax/statusUpdate/" + id).then(
        data=>{
          this.taxes.forEach((tax)=>{
            if(tax.id === id){
              tax.activeStatus = !tax.activeStatus;
            }
          });
        });
    }

}
