<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 

    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <mat-tab-group style="padding: 1em;" dynamicHeight>
  
    <mat-tab>
      <ng-template mat-tab-label>
       Forms
        <button (click)="addForm();" matTooltip="Create Form" mat-icon-button>
          <mat-icon>add_box</mat-icon>
      </button>
      <button (click)="exportArray()" matTooltip="Download as excel file" mat-icon-button>
        <mat-icon>file_download</mat-icon>
    </button>
      </ng-template>
      <div class="p-4">
   <table *ngIf="customerExists" class="table table-striped table-sm hover" 
        datatable [dtOptions]="feedbackOptions" >
          <thead class="tableheader">
            <tr>
              <th>NAME</th>
              <th>TYPE</th> 
              <th class="tble-action">MENU</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of feedbackForms">
              <td class="td-height">{{ccd.name}}</td>
              <td class="td-height">{{ccd.type}}</td>
              <td class="td-height tble-action">

                <button (click)="editForm(ccd);" matTooltip="Edit" mat-icon-button>
                  <mat-icon>mode_edit_outline</mat-icon>
              </button>

              <button (click)="addFormActive(ccd);" matTooltip="Schedule" mat-icon-button>
                <mat-icon>av_timer</mat-icon>
            </button>

              <button (click)="exploreFormCombinedHistory(ccd.id);" matTooltip="History" mat-icon-button>
                <mat-icon>history</mat-icon>
            </button>
              </td>
          </tr>
          </tbody>
        </table>
</div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Forms Schedule History
      </ng-template>
      <div class="p-4">
   
       
        <table *ngIf="historyExists" class="table table-striped table-sm hover" 
        datatable [dtOptions]="feedbackOptions" >
        <thead class="tableheader">
          <tr>
            <!-- <th> Form sharing Date-Time</th> -->
            <th>FORM NAME</th>
            <th> ACTIVE DATE-EXPIRY DATE</th>
            <!-- <th> No of Users</th> -->
            <th> RESPONSE COUNT</th>
            <!-- <th>Url</th> -->
            <th> STATUS</th>
            <th class="tble-action">MENU</th>
          
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let fa of formActives">
            
            <!-- <td class="td-height">{{fa.executionTimestamp}}</td> -->
            <td class="td-height">{{fa.formName}}</td>
            <td class="td-height">{{fa.formattedFromDate}} to {{fa.formattedToDate}}</td>
            <!-- <td style="text-align: right;" class="td-height">{{fa.formLimit}}</td> -->
            <td style="text-align: right;" class="td-height">{{fa.formCount}}</td>
  
            <!-- <td class="td-height">{{fa.url}}</td> -->
            <td *ngIf="fa.expiryStatus === true" class="td-height">Expired  
            </td>
            <td *ngIf="fa.expiryStatus === false" class="td-height">Active 
            </td>
  
            <td class="tble-action">
              <button (click)="explore(fa.id)" matTooltip="Explore Analytics" mat-icon-button>
                <mat-icon>timeline</mat-icon>
            </button>
            </td>
  
            
          
          </tr>
        </tbody>
        </table>
  
     
  
  
  </div>
    </mat-tab>

  </mat-tab-group>
  




