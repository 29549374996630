<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>



  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
 



  <mat-tab-group style="padding: 1em;" dynamicHeight>
    <mat-tab>
      <ng-template mat-tab-label>
        Templates
        <button (click)="createBotTemplate();" matTooltip="Create Template" mat-icon-button>
          <mat-icon>add_box</mat-icon>
      </button>
      </ng-template>
      
      <div class="p-4">

          <div class="search-hero">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText1" autocomplete="off" 
            placeholder="Start searching by name or language">
          </div>
  
          <br>
          <table *ngIf="schedulesExists"
            class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
            <thead class="tableheader" >
              <tr>
                <!-- <th>DISPLAY NAME</th> -->
                <th>TEMPLATE NAME</th>
                <th>TEMPLATE ID</th>
                <th>STATUS</th>
                <th>CATEGORY</th>
                <th>HEADER TYPE</th>
                <th>LANGUAGE</th>
                <th>LANGUAGE CODE</th>
                <th>VARIABLE COUNT </th>
               
                <th class="tble-action">MENU</th>
               
              </tr>
            </thead>
            <tbody>
              <tr #rowInstance *ngFor="let templ of botTemplates | filter:searchText1">
                <!-- <td class="td-height">{{templ.templateDisplayName}}</td> -->
                <td class="td-height">{{templ.templateName}}</td>
                <td class="td-height">{{templ.whatsappMsgTemplateId}}</td>
                <td class="td-height">{{templ.whatsappMsgTemplateStatus}}

                  <a style="cursor: pointer;" *ngIf="templ.whatsappMsgTemplateId!==null && templ.whatsappMsgTemplateId!==undefined"
                  matTooltip="Update Status" (click)="updateWhatsappTemplateStatusAndCategory(templ);">
                  <img src="/assets/images/icons/rotate.png" width="17" height="17" alt="" loading="lazy"> </a>

                </td>
                <td class="td-height">{{templ.tempCategory}}</td>
                <td class="td-height">{{templ.tempHeaderType}}</td>
                <td class="td-height">{{templ.tempLanguage}}</td>
                <td class="td-height">{{templ.tempLanguageCode}}</td>
                <td class="td-height tble-action">{{templ.tempVarCount}}</td>
               

                <td class="td-height">
                 
                  <a>
                    <img style="cursor:pointer;" [matMenuTriggerFor]="beforeMenu" matTooltip="Menu" 
                    src="/assets/images/icons/three-dots.png" alt="user" height="30" width="30" class="rounded-circle">
                  </a>
  
                  <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button *ngIf="templ.whatsappMsgTemplateId===null || templ.whatsappMsgTemplateId===undefined" (click)="editBotTemplate(templ);" mat-menu-item>
                      <mat-icon>mode_edit_outline</mat-icon>Edit Template</button>
                   <button (click)="open(buttonsModal);" mat-menu-item>
                  <mat-icon>grid_view</mat-icon>View Buttons</button>
                  <button (click)="updateBotTemplateStatus(templ, 'actives');" mat-menu-item>
                    <mat-icon>refresh</mat-icon>Deactivate</button>
              </mat-menu>

                </td>
 
               <ng-template #buttonsModal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title"> Buttons for Template : {{templ.templateDisplayName}}</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    
                    <button class="btn btn-danger" style="width: auto;float: right;margin-left: 1%;" *ngIf="templ.fkBotTemplateResId !== null"
                    (click)="unlinkFkBotTemplateResToBotTemplate(templ.botTemplateId);modal.dismiss('Cross click');">Delete All Buttons</button>
                    <button class="btn btn-info" style="width: auto;float: right;" 
                    (click)="addOptionToTemplateResponse(templ.botTemplateId, templ.fkBotTemplateResId);" >Add Button</button>
                   
                    <br> <br> <br>
                    <table class="table table-striped table-sm hover">
                      <thead>
                        <th>BUTTON</th>
                        <th>ACTION</th>
                        <th>NEXT RESPONSE</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let optn of templ.options">
                          <td>

            <img matTooltip="Active" *ngIf="optn.botResponseOptionsStatus===true" 
            src="/assets/images/icons/complete.png" width="19" height="19" alt="" loading="lazy"> 
            <img matTooltip="Inactive" *ngIf="optn.botResponseOptionsStatus===false" 
            src="/assets/images/icons/nonStarted.png" width="19" height="19" alt="" loading="lazy">
                            
                            {{optn.responseOption}}

                            
                          </td>
                          
                          <td>

                            <button (click)="editTemplateButton(templ.botTemplateId,optn);" matTooltip="Edit" mat-icon-button>
                              <mat-icon>mode_edit_outline</mat-icon>
                          </button>

                            <!-- <button *ngIf="optn.botResponseOptionsStatus===true" class="btn btn-danger"
                            (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);">Deactivate</button>
                            
                            <button *ngIf="optn.botResponseOptionsStatus===false" class="btn btn-success"
                            (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);">Deactivate</button> -->


      <a *ngIf="optn.botResponseOptionsStatus===true" matTooltip="Deactivate" 
      (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);" style="cursor: pointer;">
        <img src="/assets/images/icons/disable.png" width="21" height="21" 
        alt="" loading="lazy"> </a>
    <a *ngIf="optn.botResponseOptionsStatus===false"  matTooltip="Activate" 
    (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);" style="cursor: pointer;">
      <img src="/assets/images/icons/ordersApproved.png" width="21" height="21" 
      alt="" loading="lazy"> </a>

                          </td>
                          
                          <td>
                            <!-- <button (click)="getNextResponseForTemplateResOption(optn);open(TemplateResOptionLinkedResModal);"
                            class="btn btn-primary" style="width: auto;" *ngIf="optn.nextBotResponseId!==null">View Next Response</button> -->
                            <a>
                              <img *ngIf="optn.nextBotResponseId!==null" 
                              matTooltip="View Next Response" style="cursor: pointer;margin-right: 1%;" 
                              (click)="getNextResponseForTemplateResOption(optn);open(TemplateResOptionLinkedResModal);"
                              src="/assets/images/icons/eye.png" width="23" height="23" alt="" loading="lazy">
                            </a>
                            <a>
                              <img *ngIf="optn.nextBotResponseId!==null" 
                              matTooltip="Unlink Next Response" style="cursor: pointer;margin-left: 1%;" 
                              (click)="unlinkNextResponseForTemplateResOption(templ.botTemplateId, optn.botResponseOptionsId);"
                              src="/assets/images/icons/delete.png" width="21" height="21" alt="" loading="lazy">
                            </a>
                            <button (click)="linkNextResponseToTemplateResOption(templ.botTemplateId, optn.botResponseOptionsId);"
                            class="btn btn-info" style="width: auto;" *ngIf="optn.nextBotResponseId===null">Link Next Response</button>
                          </td>

                          
                        </tr>
                      </tbody>
                    </table>
                
                   

                  </div> 
                </ng-template>

                <ng-template #LinkedFkBotTemplateResModal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title"> Linked Bot Template Response</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <a style="float: left;font-weight: bold;color: black;">Response {{templ.fkBotTemplateRes.slNo}}</a> <br> <br>
                    <a style="float: left;font-weight: bold;color: black;">{{templ.fkBotTemplateRes.response}}</a>
                
                    <br>
                    <br>
                    <h4 style="margin: 0%;"
                     *ngIf="templ.fkBotTemplateRes.options !== null 
                     && templ.fkBotTemplateRes.options !== undefined 
                     && templ.fkBotTemplateRes.options.length > 0">Options :</h4>
                    <div *ngFor="let opt of templ.fkBotTemplateRes.options">
                        <a style="color:black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> <br>
                    </div>
                
                  </div> 
                </ng-template>

              </tr>
            </tbody>
          </table>

      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Deactivated Templates
      </ng-template>
      
      <div class="p-4">

          <div class="search-hero">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off" 
            placeholder="Start searching by name or language">
          </div>
  
          <br>
          <table *ngIf="schedulesExists"
            class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
            <thead class="tableheader" >
              <tr>
                <!-- <th>DISPLAY NAME</th> -->
                <th>TEMPLATE NAME</th>
                <th>TEMPLATE ID</th>
                <th>STATUS</th>
                <th>CATEGORY</th>
                <th>HEADER TYPE</th>
                <th>LANGUAGE</th>
                <th>LANGUAGE CODE</th>
                <th>VARIABLE COUNT </th>
               
                <th class="tble-action">MENU</th>
               
              </tr>
            </thead>
            <tbody>
              <tr #rowInstance *ngFor="let templ of deactivatedBotTemplates | filter:searchText2">
                <!-- <td class="td-height">{{templ.templateDisplayName}}</td> -->
                <td class="td-height">{{templ.templateName}}</td>
                <td class="td-height">{{templ.whatsappMsgTemplateId}}</td>
                <td class="td-height">{{templ.whatsappMsgTemplateStatus}}

                  <a style="cursor: pointer;" *ngIf="templ.whatsappMsgTemplateId!==null && templ.whatsappMsgTemplateId!==undefined"
                  matTooltip="Update Status" (click)="updateWhatsappTemplateStatusAndCategory(templ);">
                  <img src="/assets/images/icons/rotate.png" width="17" height="17" alt="" loading="lazy"> </a>

                </td>
                <td class="td-height">{{templ.tempCategory}}</td>
                <td class="td-height">{{templ.tempHeaderType}}</td>
                <td class="td-height">{{templ.tempLanguage}}</td>
                <td class="td-height">{{templ.tempLanguageCode}}</td>
                <td class="td-height tble-action">{{templ.tempVarCount}}</td>
               

                <td class="td-height">
                 
                  <a>
                    <img style="cursor:pointer;" [matMenuTriggerFor]="beforeMenu" matTooltip="Menu" 
                    src="/assets/images/icons/three-dots.png" alt="user" height="30" width="30" class="rounded-circle">
                  </a>
  
                  <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button (click)="updateBotTemplateStatus(templ, 'inactives');" mat-menu-item>
                      <mat-icon>refresh</mat-icon>Activate</button>
              </mat-menu>

                </td>
 
               <ng-template #buttonsModal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title"> Buttons for Template : {{templ.templateDisplayName}}</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    
                    <button class="btn btn-danger" style="width: auto;float: right;margin-left: 1%;" *ngIf="templ.fkBotTemplateResId !== null"
                    (click)="unlinkFkBotTemplateResToBotTemplate(templ.botTemplateId);modal.dismiss('Cross click');">Delete All Buttons</button>
                    <button class="btn btn-info" style="width: auto;float: right;" 
                    (click)="addOptionToTemplateResponse(templ.botTemplateId, templ.fkBotTemplateResId);" >Add Button</button>
                   
                    <br> <br> <br>
                    <table class="table table-striped table-sm hover">
                      <thead>
                        <th>BUTTON</th>
                        <th>ACTION</th>
                        <th>NEXT RESPONSE</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let optn of templ.options">
                          <td>

            <img matTooltip="Active" *ngIf="optn.botResponseOptionsStatus===true" 
            src="/assets/images/icons/complete.png" width="19" height="19" alt="" loading="lazy"> 
            <img matTooltip="Inactive" *ngIf="optn.botResponseOptionsStatus===false" 
            src="/assets/images/icons/nonStarted.png" width="19" height="19" alt="" loading="lazy">
                            
                            {{optn.responseOption}}

                            
                          </td>
                          
                          <td>

                            <button (click)="editTemplateButton(templ.botTemplateId,optn);" matTooltip="Edit" mat-icon-button>
                              <mat-icon>mode_edit_outline</mat-icon>
                          </button>

                            <!-- <button *ngIf="optn.botResponseOptionsStatus===true" class="btn btn-danger"
                            (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);">Deactivate</button>
                            
                            <button *ngIf="optn.botResponseOptionsStatus===false" class="btn btn-success"
                            (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);">Deactivate</button> -->


      <a *ngIf="optn.botResponseOptionsStatus===true" matTooltip="Deactivate" 
      (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);" style="cursor: pointer;">
        <img src="/assets/images/icons/disable.png" width="21" height="21" 
        alt="" loading="lazy"> </a>
    <a *ngIf="optn.botResponseOptionsStatus===false"  matTooltip="Activate" 
    (click)="botResOptionDeactivation(templ.botTemplateId, optn.botResponseOptionsId);" style="cursor: pointer;">
      <img src="/assets/images/icons/ordersApproved.png" width="21" height="21" 
      alt="" loading="lazy"> </a>

                          </td>
                          
                          <td>
                            <!-- <button (click)="getNextResponseForTemplateResOption(optn);open(TemplateResOptionLinkedResModal);"
                            class="btn btn-primary" style="width: auto;" *ngIf="optn.nextBotResponseId!==null">View Next Response</button> -->
                            <a>
                              <img *ngIf="optn.nextBotResponseId!==null" 
                              matTooltip="View Next Response" style="cursor: pointer;margin-right: 1%;" 
                              (click)="getNextResponseForTemplateResOption(optn);open(TemplateResOptionLinkedResModal);"
                              src="/assets/images/icons/eye.png" width="23" height="23" alt="" loading="lazy">
                            </a>
                            <a>
                              <img *ngIf="optn.nextBotResponseId!==null" 
                              matTooltip="Unlink Next Response" style="cursor: pointer;margin-left: 1%;" 
                              (click)="unlinkNextResponseForTemplateResOption(templ.botTemplateId, optn.botResponseOptionsId);"
                              src="/assets/images/icons/delete.png" width="21" height="21" alt="" loading="lazy">
                            </a>
                            <button (click)="linkNextResponseToTemplateResOption(templ.botTemplateId, optn.botResponseOptionsId);"
                            class="btn btn-info" style="width: auto;" *ngIf="optn.nextBotResponseId===null">Link Next Response</button>
                          </td>

                          
                        </tr>
                      </tbody>
                    </table>
                
                   

                  </div> 
                </ng-template>

                <ng-template #LinkedFkBotTemplateResModal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title"> Linked Bot Template Response</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <a style="float: left;font-weight: bold;color: black;">Response {{templ.fkBotTemplateRes.slNo}}</a> <br> <br>
                    <a style="float: left;font-weight: bold;color: black;">{{templ.fkBotTemplateRes.response}}</a>
                
                    <br>
                    <br>
                    <h4 style="margin: 0%;"
                     *ngIf="templ.fkBotTemplateRes.options !== null 
                     && templ.fkBotTemplateRes.options !== undefined 
                     && templ.fkBotTemplateRes.options.length > 0">Options :</h4>
                    <div *ngFor="let opt of templ.fkBotTemplateRes.options">
                        <a style="color:black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> <br>
                    </div>
                
                  </div> 
                </ng-template>

              </tr>
            </tbody>
          </table>

      </div>
    </mat-tab>
  </mat-tab-group>
 
    

      







<ng-template #TemplateResOptionLinkedResModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Next Linked Response</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a style="float: left;font-weight: bold;color: black;">Response {{nextResponseForTemplateResOption.slNo}}</a> <br>
    <a style="float: left;font-weight: bold;color: black;">{{nextResponseForTemplateResOption.response}}</a>

    <br>
    <br>
    <h4 style="margin: 0%;"
     *ngIf="nextResponseForTemplateResOption.options !== null 
     && nextResponseForTemplateResOption.options !== undefined 
     && nextResponseForTemplateResOption.options.length > 0">Options :</h4>
    <div *ngFor="let opt of nextResponseForTemplateResOption.options">
        <a style="color:black;"> {{opt.responseOptionNumber}}. {{opt.responseOption}} </a> <br>
    </div>

  </div> 
</ng-template>





