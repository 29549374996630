import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IncentiveTier } from 'src/app/BIZ_CRM/biz_crm_classes/incentive-tier';
import { Target } from 'src/app/BIZ_CRM/biz_crm_classes/target';
import { IncentiveTierService } from 'src/app/BIZ_CRM/biz_crm_services/incentive-tier.service';
import { TargetService } from 'src/app/BIZ_CRM/biz_crm_services/target.service';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-edit-incentive-tier',
  templateUrl: './edit-incentive-tier.component.html',
  styleUrls: ['./edit-incentive-tier.component.scss']
})
export class EditIncentiveTierComponent implements OnInit {

  clicked = false;
  it : IncentiveTier = new IncentiveTier();
  loggedInUser : any; 
  theme : any;
  roles : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private roleService : RolesServiceService,
    private itService : IncentiveTierService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.it = this.fromParent.incentiveTier;
  

  

  }

  onSubmit(){

    this.itService.update(environment.apiUrl + "incentiveTier/update", this.it).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Incentive Tier updated successfully');
        this.passEntry.emit(this.it);
      });
 
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}