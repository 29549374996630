<div class="modal-header">
    <h4 class="modal-title pull-left">Sub-Offices</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">

        <table class="table table-striped table-bordered table-sm row-border hover">
        <thead class="tableheader">
          <tr>
            <th>#</th>
            <th>Office</th>
            <th>Location</th>
            <th>Type</th>
           
            
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let office of offices,let i = index">
            <td class="td-height">{{i+1}}</td>
            <td class="td-height">
              {{office.officeName}}
            </td>
            <td>
                {{office.officeLocation}}
            </td>
            <td class="td-height">{{office.type}}</td>

           
          </tr>
        </tbody>
      </table>
      </div> 
    </div>
   </div>
   
   