import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { RecurringActivityAccessmentModalComponent } from 'src/app/dashboards/parentDashboards/recurring-activity-accessment-modal/recurring-activity-accessment-modal.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ParentServiceService } from 'src/app/service/parent-service.service';
import { DestroyService } from 'src/app/services/destroyService';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parent-dashboard',
  templateUrl: './parent-dashboard.component.html',
  styleUrls: ['./parent-dashboard.component.scss']
})

export class ParentDashboardComponent extends DestroyService implements OnInit {
  
  currentRole = "PARENT";
  collapsed : boolean = false;
  showSpinner : boolean = true;
  collapsedState : boolean = false ;
  loggedInUser;
  safeChildImageUrl : any;
  userName: string = 'User';
  theme: string = '';
  activityPlannerListWithSafeUrl : any[] = [];
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };
  safeUrl : any;
  unsafeUrl : any;
  public screenWidth: any;
  public screenHeight: any;
  navbarTitle: any;
  userId!: string;



  xyz: any;
  abc: any;
  unsafeChildImageUrl : any;
  data : any = {
                    childImage: "",
                    childName: "",
                    childUserId: "",
                    childUserRoleOfficeId: "",
                    parentImage: "",
                    parentName: "",
                    parentUserId: "",
                    parentUserRoleOfficeId: "",
                    activities: [{
                                          activityCompletionStatus: "",
                                          activityPlannerCompletionStatus:  "",
                                          activityPlannerDescription:  "",
                                          activityPlannerId: "",
                                          activityPlannerImage:  "",
                                          activityPlannerName:  "",
                                          activityPlannerOrder:  "",
                                          activityPlannerStatus:  "",
                                          activityVideoLinkList:  "",
                                          assignmentActiveStatus:  "",
                                          fkSubjectId:  "",
                                          spontaneous:  "",
                                          studentActivityPlannerCompletionStatus:  "",
                                          type: "",
                                          studentId: "",
                                          safeUrl: "",
                                          parentPlannerStatus: "",
                                          currentActivityDate: [{
                                                                    0: '',
                                                                    1: '',
                                                                    2: '',
                                                                }],
                                          nextActivityDate: [{
                                                                    0: '',
                                                                    1: '',
                                                                    2: '',
                                                                }],
                                                                currentActivityDateFormatted: '',
                                                                nextActivityDateFormatted: ''
                                  }]
  }

  constructor(private sharedDataService: SharedDataService, public router: Router,
    private ngbModalService: NgbModal,private route : ActivatedRoute,
    private parentServiceService : ParentServiceService,
    private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
    public bsModalRef: BsModalRef) {
      super();
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  panelOpenState = false;
  capacity: any;
  title = 'my-app';
  state = 'collapsed';

  toggle(): void {
    this.state = this.state === 'collapsed' ? 'expanded' : 'collapsed';
  }

  childUserId : any;

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.childUserId = this.route.snapshot.params['childUserId'];

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
 
    this.parentServiceService.getParentDashboardDataActivities(environment.apiUrl + "parent/getParentDashboardDataActivities/" + this.childUserId).then(
      res=>{
        this.data = res;
        this.imageService.getUserImage(this.data.childImage).subscribe(
          data=>{
            this.xyz = data;
            this.abc = URL.createObjectURL(data);
            this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);
            this.safeChildImageUrl = this.xyz;
          });

          setTimeout(()=>{
            this.showSpinner = false;
       }, 2000);

          this.data.activities.forEach((value : any)=>{
            this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
              data=>{
                this.unsafeUrl = URL.createObjectURL(data);
                this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
    
                value.safeUrl = this.safeUrl;
              });
          });

          this.data.activities.forEach((value : any)=>{

            value.currentActivityDateFormatted = value.currentActivityDate[2] + '-' + value.currentActivityDate[1] + '-' + value.currentActivityDate[0];
            value.nextActivityDateFormatted = value.nextActivityDate[2] + '-' + value.nextActivityDate[1] + '-' + value.nextActivityDate[0]; 
          });          
      });
  }

  exploreClasses(){
    this.router.navigate(['/dashboard/parent/classes',this.childUserId]);
    }

  getParentDashboardDataActivities(){
    this.parentServiceService.getParentDashboardDataActivities(environment.apiUrl + "parent/getParentDashboardDataActivities/" + this.childUserId).then(
      res=>{
        this.data = res;

        this.imageService.getUserImage(this.data.childImage).subscribe(
          data=>{
            this.xyz = data;
            this.abc = URL.createObjectURL(data);
            this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);
            this.safeChildImageUrl = this.xyz;
               
          });

          setTimeout(()=>{                           //timer
            this.showSpinner = false;
       }, 2000);

          this.data.activities.forEach((value : any)=>{
            this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
              data=>{
                this.unsafeUrl = URL.createObjectURL(data);
                this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
    
                value.safeUrl = this.safeUrl;
              });
          })
          this.data.activities.forEach((value : any)=>{
            value.currentActivityDateFormatted = value.currentActivityDate[2] + '-' + value.currentActivityDate[1] + '-' + value.currentActivityDate[0];
            value.nextActivityDateFormatted = value.nextActivityDate[2] + '-' + value.nextActivityDate[1] + '-' + value.nextActivityDate[0]; 
          })
      });
  }

// authorized
  assess(activityPlannerId : any, studentId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( RecurringActivityAccessmentModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      parentUserId : this.data.parentUserId,
      studentId : studentId,
      activityPlannerId : activityPlannerId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.showSpinner = true;
      this.getParentDashboardDataActivities();
    });    
  }
  
  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });    
  }
}
