import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  // oapmUrl = environment.apiUrl + "admin/office/OA_ParentMentorStatus/update";
  // oasaUrl = environment.apiUrl + "admin/office/OA_SubAdminStatus/update";
  getUrl = environment.apiUrl + "admin/getOfficeById";
  getUr2 = environment.apiUrl + "admin/getOfficeByIdBotAcessControls";

  // oacrmaUrl =  environment.apiUrl + "admin/office/OA_CRMAdminStatus/update";
  // oaclassaUrl = environment.apiUrl + "admin/office/OA_ClassAdminStatus/update";
  // oacourseaUrl = environment.apiUrl + "admin/office/OA_CourseAdminStatus/update";
  // oasrUrl = environment.apiUrl + "admin/office/OA_SkillReportStatus/update";
  // oaaUrl = environment.apiUrl + "admin/office/OA_AssignmentStatus/update";
  // oapfUrl = environment.apiUrl + "admin/office/OA_ParentFeedbackStatus/update";

  authToken: string | null = '';
  constructor(private http: HttpClient) {
    // this.authToken = localStorage.getItem('auth-token');
  }

  clearTokenVariable(){
    this.authToken = null;    
  }

  getAllOffices(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  createOffice(avenue: string, formData: FormData): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, formData, {
        reportProgress: true,
        responseType: 'text'
      });

      self.http.post(avenue, formData, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateOfficeStatus(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.put(avenue, null, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateOffice(avenue: string, data: any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.put(avenue, data, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateAccessControlStatuses(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  setOptionsFromBotMembersInfoType(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  //  updateOA_SubAdminStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oasaUrl}/${officeId}`);
  //  }

  //  updateOA_CourseAdminStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oacourseaUrl}/${officeId}`);
  //  }

  //  updateOA_ClassAdminStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oaclassaUrl}/${officeId}`);
  //  }

  //  updateOA_CRMAdminStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oacrmaUrl}/${officeId}`);
  //  }

  //  updateOA_SkillReportStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oasrUrl}/${officeId}`);
  //  }

  //  updateOA_AssignmentStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oaaUrl}/${officeId}`);
  //  }

  //  updateOA_ParentFeedbackStatus(officeId : string):Observable<Object>{
  //   return this.http.delete(`${this.oapfUrl}/${officeId}`);
  //  }

   getOfficeById(officeId : string):Observable<Object>{
    return this.http.get<Object>(`${this.getUrl}/${officeId}`);
   }

   getOfficeByIdBotAcessControls(officeId : string):Observable<Object>{
    return this.http.get<Object>(`${this.getUr2}/${officeId}`);
   }

   addConvoInitiatorKeyword(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getOfficeByIdForCourseAdmin(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getOfficeByIdForClassAdmin(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getOfficeByIdForOfficeAdmin(avenue: string): Promise<any> {
     
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getOfficeByIdForTeacher(avenue: string): Promise<any> {
     
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
   
  getOfficeByIdForBcrmData(avenue: string): Promise<any> {
     
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
}
