import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExtMediaViewModalComponent } from '../../modals/ext-media-view-modal/ext-media-view-modal.component';

@Component({
  selector: 'app-ext-media-view-page',
  templateUrl: './ext-media-view-page.component.html',
  styleUrls: ['./ext-media-view-page.component.scss']
})
export class ExtMediaViewPageComponent implements OnInit {
 
  officeId:any;
  botMediaId:any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;


  constructor(
    private ngbModalService: NgbModal,
    private route : ActivatedRoute,
        public bsModalRef: BsModalRef, ) {
      }

  ngOnInit(): void {

    this.officeId = this.route.snapshot.params['officeId'];
    this.botMediaId = this.route.snapshot.params['botMediaId'];

    this.viewBotMedia();

  }

  viewBotMedia(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ExtMediaViewModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botMediaId : this.botMediaId,
      mediaType : "document",
      officeId : this.officeId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
     
    });
  }

}
