import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Result } from 'src/app/courseManagementClasses/result';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ResultServiceService } from 'src/app/service/result-service.service';
import { AddResultsModalComponent } from '../add-results-modal/add-results-modal.component';
import { StudentSelectionModalComponent } from '../student-selection-modal/student-selection-modal.component';

@Component({
  selector: 'app-results-admin-dashboard',
  templateUrl: './results-admin-dashboard.component.html',
  styleUrls: ['./results-admin-dashboard.component.scss']
})
export class ResultsAdminDashboardComponent implements OnInit {

  userName : any;
  loggedInUser: any;
  loggedUser: any;
  resultOptions: any;
  results : Result[] = [];
  navbarTitle: any;
  resultsExist = true;

  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private toaster: ToastrService,
              private resultService : ResultServiceService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    idObject :any = {
      studentId : '',
      subjectId : ''
    }
    customerOptions : any;

  public screenWidth: any;
  public screenHeight: any;
 
  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.resultsExist = true;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions  = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

  }

  getResults(studentId : string, subjectId : string){
this.resultService.findResultsByUserRoleOfficeIdAndSubjectId(studentId, subjectId).subscribe(
  data=>{
    this.results = data;
    this.toaster.success('data')
    this.resultsExist = true;
  });
  }

  openStudentSelectionModal(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(StudentSelectionModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
     
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.idObject = receivedEntry;
      this.getResults(this.idObject.studentId , this.idObject.subjectId);
    });  
    
  }
  
  openAddResultModal(){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddResultsModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
     
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.idObject = receivedEntry;
      this.getResults(this.idObject.studentId , this.idObject.subjectId);
    });  
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  reloadPage() {
    window.location.reload();
  }
 
}
