export class Office {
    id = null;
    officeName = null;
    officeLocation = null;
    logo = null;
    activeStatus = null;
    backgroundColor = null;
    theme = null;
    oa_ParentMentor! : boolean ;
    oa_SubAdmin! : boolean ;
    oa_CRMAdmin! : boolean;
    oa_CourseAdmin! : boolean;
    oa_ClassAdmin! : boolean;
    leadConversionType : any;
    type : any;
    parentOrgOfficeId : any;
}

export class Offices {
    offices: Office[] = [];
}

