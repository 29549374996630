import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { Shipment } from 'src/app/crmClasses/shipment';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-bcrm-shipment',
  templateUrl: './add-bcrm-shipment.component.html',
  styleUrls: ['./add-bcrm-shipment.component.scss']
})
export class AddBcrmShipmentComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any;
  invoices: InvoiceList[] = [];

  shipment : Shipment = new Shipment();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;
  customerId: any;
  invoice : any;

  constructor(
              public activeModal: NgbActiveModal,
              private shipmentService : ShipmentService,
              private sharedDataService: SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService  ) { }

              type : any;
              createShipmentForBatch = false;
              
  ngOnInit(): void {

    this.customerId = this.fromParent.customerId;

    this.type = this.fromParent.type;

    if(this.type==="single invoice shipment"){
    this.invoice = this.fromParent.invoice;
    this.shipment.fkInvoiceId = this.invoice.invoiceId;
    }

    if(this.type==="batch shipment"){
    this.invoices = this.fromParent.invoices;
    if(this.invoices !== null && this.invoices.length !== 0){
      this.invoices.forEach((inv)=>{
        inv.batchShipment = false;
      });
    }
  }

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 

  }

  handleClick(invoice : any) {
    this.invoices.forEach((inv)=>{
      if(inv.invoiceId===invoice.invoiceId){
        inv.batchShipment = !inv.batchShipment;
      }
    });
  }

  onSubmit(){
    this.shipmentService.addShipment(environment.apiUrl + 'shipment/add', this.shipment).then(
    data =>{
      this.shipment = data;
      this.passEntry.emit(this.invoice);
      this.activeModal.close();
      this.toaster.success("Shipment Created Successfully");
    })
  }

  createBatchShipment(){

    let ids : string[] = [];
    this.invoices.forEach((invoice)=>{
      if(invoice.batchShipment===true){
        ids.push(invoice.invoiceId);
      }
    });
    this.shipment.batchShipmentInvoiceIds = ids;

    if(ids.length===0){
      this.activeModal.close();
      this.toaster.error("No invoice selected");
    }else{
      this.shipmentService.addShipment(environment.apiUrl + 'shipment/createBatchShipment', this.shipment).then(
        data =>{
          this.passEntry.emit(this.shipment.batchShipmentInvoiceIds);
          this.activeModal.close();
          this.toaster.success("Batch Shipment Created Successfully");
        });
    }
  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 
  closeModal(){
    this.activeModal.close();
  }
 
}