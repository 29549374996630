<nav class="navbar navbar-expand-lg navbar-light bg-dark">
  <a class="navbar-brand" href="#">
    <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
  </a>
  <a class="navbar-brand title text-white" href="#">{{navbarTitle}}</a>
  <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
    class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="this.screenWidth > 768">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
      </li>
    </ul>

    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item dropdown center-align" dropdown placement="bottom right">
        <a class="nav-link text-white" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false" dropdownToggle (click)="false">
          <img alt="" height="20" loading="lazy" src="/assets/images/icons/settings.svg" width="25">
          {{userName}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item" style="cursor:pointer" (click)="changePassword();"><i class="fa fa-shield"
              style="padding-right: 0.7rem;"></i>Change
            Password</a>
          <a class='dropdown-item' style="cursor:pointer;" (click)="logOut();">
            <i class="fa fa-power-off" style="padding-right: 0.7rem;"></i>Logout</a>
        </div>
      </li>
    </ul>
  </div>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="this.screenWidth <= 768">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
      </li>
    </ul>

    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item center-align" placement="bottom right">
        <a class="nav-link text-white" href="#" role="button" aria-haspopup="true" aria-expanded="false"
          (click)="false"><i class="fa fa-user" style="padding-right: 0.7rem;"></i>
          {{userName}}
        </a>
      </li>
      <li class="nav-item center-align">
        <a class="nav-link text-white" data-toggle="dropdown" style="cursor:pointer" (click)="changePassword();"><i
            class="fa fa-shield" style="padding-right: 0.7rem;"></i>Change
          Password</a>
      </li>
      <li class="nav-item center-align">
        <a class='nav-link text-white' data-toggle="dropdown" style="cursor:pointer;" (click)="logOut();">
          <i class="fa fa-power-off" style="padding-right: 0.7rem;"></i>Logout</a>
      </li>
    </ul>
  </div>
</nav>

<section>
  <div class="p-4">
    <div class="row">
      <div class="col-md-2" *ngFor="let subscription of loggedInUser?.subscriptionDTO">
        <div *ngIf="subscription.role !== null && subscription.role !== undefined && subscription.role !== ''">
          <br>
          <div class="row">
            <div class="col-md-12">
              <a href="javascript: void(0)" (click)="navigate(subscription)">
              <div class="card">
                <h2 class="card-title">{{subscription.role.roleName}}</h2>
                <p class="card-text">{{subscription.product.productName}}</p>
                <img src="/assets/images/course.svg" class="img-fluid" alt="">
                <!-- <div class="row date-content">
                  <label class="label_text">Start Date : </label>
                  <p class="card-text sub_date">{{subscription.startDate}}</p>
                </div> -->
                <div class="row date-content">
                  <label class="label_text">Expire: </label>
                  <p class="card-text sub_date">{{subscription.endDate | date: 'dd-MMM-yyyy'}}</p>
                </div >
                <!-- <div class="text-center">
                  <button type="button" class="btn bg-dark mr-2 text-light" (click)="navigate(subscription)">Explore</button>
                </div> -->
              </div>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
