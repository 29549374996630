import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ClassStudents } from 'src/app/courseManagementClasses/class-students';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-class-students',
  templateUrl: './view-class-students.component.html',
  styleUrls: ['./view-class-students.component.scss']
})
export class ViewClassStudentsComponent implements OnInit {

  constructor( private studentService : StudentService,
    public activeModal: NgbActiveModal,
     public bsModalRef: BsModalRef,
     private sharedDataService : SharedDataService,

     private toaster: ToastrService ) { }
     loggedInUser: any;
     theme: any;
  classStudents : ClassStudents[] = [];

  classId!: string;
    name!: string;

  @Input() fromParent: any; 

  ngOnInit(): void {
    this.classId = this.fromParent.classId;
    this.name=this.fromParent.name;

    this.studentService.getStudentsInaClass(this.classId).subscribe(data=>{
      this.classStudents = data;
    }, error => console.log(error)
    );
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}
getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  closeUpdateModal(){
    this.activeModal.close();
    }
}
