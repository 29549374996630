  <nav class="navbar navbar-expand-md navbar-dark" [ngStyle]="backgroundStyle">

    <div style="margin-right: 2%;" *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div  style="margin-right: 2%;" *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
  <a class="navbar-brand" (click)="reloadPage()" style="color: white;font-weight: bold;cursor: pointer;text-align: center;">{{navBarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav  ml-auto">
      
      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-chevron-left"></i> Back </a>
      </li>

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
      </li>

      <li class="nav-item" style="float: right;">
        <a class="nav-link" (click)="logOut()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" >
          <i class="fa fa-sign-out" ></i> Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>

<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>

<!-- <div class="content">
  <div class="table-header row bg-dark navbar-dark" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">

      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
       Account Settings
      </span>


    </div> 
  </div>

</div> -->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home </a></li>
    <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Profile</li>
  </ol>
</nav>

<div class="content">

  <h1 style="text-align: left;color: rgb(71, 63, 63);margin-bottom: 2%;"> 
    Profile Settings</h1>

  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >

      <mat-card class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img style="border-radius: 50%;" class="card-icon-image" [src]="safeParentImageUrl" width="75" height="75"
             alt="" loading="lazy">
          </a>
        </div>
         <br>
        <h4 style="font-weight: bold;" class="title">{{accDetails.parentFirstName}} {{accDetails.parentLastName}}</h4>
        <h4 style="font-weight: bold;" class="title">Email:<h4>{{accDetails.parentEmailId}}</h4></h4> 
        <h4 style="font-weight: bold;" class="title">Phone:<h4>{{accDetails.parentPhone}}</h4></h4> 
 
      <mat-card-content>
        <p>
          <button style="width: 75%;" class="btn btn-info" [ngStyle]="backgroundStyle" (click)="changePassword()" mat-button>Change Password</button>
        </p>



        <p>
          <button style="width: 75%;" class="btn btn-info" [ngStyle]="backgroundStyle" (click)="editImage(accDetails.parentUserId, accDetails.parentImage)" mat-button>Change Profile Image</button>
        </p>

        
      </mat-card-content>
     
    </mat-card>

     
    </div>

  


    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >

      <mat-card class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img class="card-icon-image" src="assets/images/icons/map.svg" width="65" height="65"
             alt="" loading="lazy">
          </a>
        </div>

        <br>

        <!-- <h4 style="font-weight: bold;margin: 0%;" class="title">Address Line 1:<h4>{{accDetails.addressLine1}}</h4></h4> 
        <h4 style="font-weight: bold;margin: 0%;" class="title">Address Line 2:<h4>{{accDetails.addressLine2}}</h4></h4> 
        <h4 style="font-weight: bold;" class="title">City:<h4>{{accDetails.city}}</h4></h4> 
        <h4 style="font-weight: bold;" class="title">State:<h4>{{accDetails.state}}</h4></h4> 
        <h4 style="font-weight: bold;" class="title">Country:<h4>{{accDetails.country}}</h4></h4> 
        <h4 style="font-weight: bold;" class="title">ZipCode:<h4>{{accDetails.zipCode}}</h4></h4>
         -->
        <label style="text-align: center;font-weight: bold;">Address Line 1:</label> <label style="text-align: center;">{{accDetails.addressLine1}}</label><br>
        <label style="text-align: center;font-weight: bold;">Address Line 2:</label> <label style="text-align: center;">{{accDetails.addressLine2}}</label><br>
        <label style="text-align: center;font-weight: bold;">City:</label> <label style="text-align: center;">{{accDetails.city}}</label><br>
        <label style="text-align: center;font-weight: bold;">State:</label> <label style="text-align: center;">{{accDetails.state}}</label><br>
        <label style="text-align: center;font-weight: bold;">Country:</label> <label style="text-align: center;">{{accDetails.country}}</label><br>
        <label style="text-align: center;font-weight: bold;">ZipCode:</label> <label style="text-align: center;">{{accDetails.zipCode}}</label><br>

        
         
      <mat-card-content>
        <p>
          <button class="btn btn-info" style="width: 100%;" [ngStyle]="backgroundStyle" (click)="editDetails(accDetails.parentUserId)" mat-button>Edit Address</button>
        </p>

      </mat-card-content>
     
    </mat-card>

     
    </div>


   
    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >

      <mat-card class="example-card mat-elevation-z12" >

        <div class="card-icon" >
          <a> 
            <img class="card-icon-image" src="assets/images/icons/subscription.svg" width="100" height="100"
             alt="" loading="lazy">
          </a>
        </div>
         <br>

         <h4 style="font-weight: bold;" class="title">Roles</h4> 
        
        <h4 style="font-weight: bold;" *ngFor="let role of accDetails.roles">{{role}}</h4>
      
        <mat-card-content>
          <p>
            <button class="btn btn-info" style="width: 100%;" [ngStyle]="backgroundStyle"
             (click)="viewSubscriptions(accDetails.parentUserId)" mat-button>View Subscriptions</button>
          </p>
          <p>
            <button class="btn btn-info" style="width: 100%;" [ngStyle]="backgroundStyle"
             (click)="viewInvoices(accDetails.parentUserId)" mat-button>View Invoices</button>
          </p>
          <p>
            <button class="btn btn-info" style="width: 100%;" [ngStyle]="backgroundStyle"
             (click)="viewPayments(accDetails.parentUserId)" mat-button>View Payments</button>
          </p>
  
        </mat-card-content>
     
    </mat-card>

     
    </div>
   





  </div>
  </div>

  <div style="margin-bottom: 3%;" class="content">
    <h1 style="text-align: left;color: rgb(71, 63, 63);margin-bottom: 2%;"> 
      Children</h1>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
  
      <div *ngFor="let child of accDetails.children" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card class="example-card mat-elevation-z12" >
  
          <div class="card-icon" >
            <a> 
              <img style="border-radius: 50%;" class="card-icon-image" [src]="child.safeUrl" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>
           <br>
  
           <h4 style="font-weight: bold;text-align: center;" class="title">Child</h4> 
           
           <mat-card-subtitle style="color: black;">{{child.childFirstName}} {{child.childLastName}}</mat-card-subtitle>
           <!-- <mat-card-subtitle>{{child.childDoB}}</mat-card-subtitle> --> <br>
           <h4 style="font-weight: bold;text-align: center;" class="title">Courses : </h4> 

           <div *ngFor="let sub of child.subscriptions">
            <mat-card-subtitle style="color: black;margin: 0%;">{{sub.courseName}}</mat-card-subtitle>
            <mat-card-subtitle style="color: black;margin: 0%;">[ {{sub.startedDate}} to {{sub.endedDate}} ]</mat-card-subtitle> 
           </div>
   
      </mat-card>
  
       
      </div>
  
  
  
  
  
  
    </div>
    </div>












