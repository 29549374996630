<div class="modal-header">
    <h4 class="modal-title pull-left">Edit Form Config</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="add()" #aplnrForm="ngForm"> 

      
   

<br>

<div class="form-group row">

  <!-- <div class="col-md-4">
    <label for="groupName">Group Name</label> <br>
    <select class="form-control" [(ngModel)]="formConfig.groupName" id="groupName" name="groupName">
      <option  value="" disabled >Choose Group</option>
      <option  value="Health" id="Health" >Health</option>
      <option  value="Identification" id="Identification" >Identification</option>
    </select>
    </div> -->

    <div class="col-md-6">
      <label for="groupName">Group Name</label>
      <input #groupName="ngModel" required placeholder="Enter group name" required
       class="form-control" type="text" name="groupName"  id="groupName" [(ngModel)]="formConfig.groupName">
      <div class="alert alert-danger" *ngIf="groupName.touched && !groupName.valid">Group Name is required</div>
   </div> 

  <div class="col-md-4">
    <label for="officeId">Form Name</label> <br>
    <select class="form-control" [(ngModel)]="formConfig.formName" id="formName" name="formName">
      <option  value="" disabled >Choose Form</option>
      <option  value="Student Creation" id="Student Creation" >Student Creation</option>
      <option  value="Teacher Creation" id="Teacher Creation" >Teacher Creation</option>
    </select>
    </div>

   <!-- <div class="col-md-4">
    <label for="officeId">Column Name</label> <br>
    <select class="form-control" [(ngModel)]="formConfig.columnName" id="columnName" name="columnName">
      <option  value="" disabled >Choose Column</option>
      <option  value="height" id="height" >Height</option>
      <option  value="weight" id="weight" >Weight</option>
    </select>
    </div> -->

    <div class="col-md-6">
      <label for="columnName">Column Name</label>
      <input #columnName="ngModel" required placeholder="Enter column name" required
       class="form-control" type="text" name="columnName"  id="columnName" [(ngModel)]="formConfig.columnName">
      <div class="alert alert-danger" *ngIf="columnName.touched && !columnName.valid">Column Name is required</div>
   </div> 
</div>



<br>
           <div class="form-group row">

            <div class="col-md-4">
                <label for="key"> Display Name</label>
                <input #key="ngModel" required placeholder="Enter key" required
                 class="form-control" type="text" name="key"  id="key" [(ngModel)]="formConfig.displayName">
                <div class="alert alert-danger" *ngIf="key.touched && !key.valid">Display Name is required</div>
             </div> 
             
             <div class="col-md-4">
                <label for="value"> Display Status</label> <br>
                <mat-radio-group aria-label="displayStatus" name="displayStatus" [(ngModel)]="formConfig.displayStatus">
                  <mat-radio-button name="displayStatus4" id="displayStatus0" value="True"  >Yes</mat-radio-button>
                  <mat-radio-button name="displayStatus5" id="displayStatus1" value="False"  >No</mat-radio-button>
                </mat-radio-group> 
               </div>
 
             <div class="col-md-4">
              <label >Mandatory </label> <br>
              <mat-radio-group aria-label="mandatory" name="mandatory" [(ngModel)]="formConfig.mandatory">
                <mat-radio-button name="mandatory6" id="mandatory2" value="True"  >Yes</mat-radio-button>
                <mat-radio-button name="mandatory7" id="mandatory3" value="False"  >No</mat-radio-button>
              </mat-radio-group>
            </div>

      </div>

     
    



    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="add(); clicked=true">Save</button>
  </div>