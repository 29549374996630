import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  Observable, Subscription } from 'rxjs';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { StudentActivityTimelinePageComponent } from '../student-activity-timeline-page/student-activity-timeline-page.component';
import { ViewStudentInfoModalComponent } from '../view-student-info-modal/view-student-info-modal.component';
import { ViewStudentResultsModalComponent } from '../view-student-results-modal/view-student-results-modal.component';
import { ToastrService } from 'ngx-toastr';
import { OfficesService } from 'src/app/services/office/offices.service';
import { environment } from 'src/environments/environment';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { OverviewModalComponent } from '../overview-modal/overview-modal.component';

@Component({
  selector: 'app-view-students-page',
  templateUrl: './view-students-page.component.html',
  styleUrls: ['./view-students-page.component.scss']
})
export class ViewStudentsPageComponent implements OnInit {

  currentRole = 'TEACHER';
  showSpinner : boolean = true;
  userId!: string;
  userName : any;
  theme: string = '';
  public screenWidth: any;
  public screenHeight: any;
  loggedUser : any;
  loggedInUser : any;
  classId!: string;
  subId!:string;
  safeStudentUrl : any;
  unsafeStudentUrl : any;

  centered = false;
  disabled = false;
  unbounded = false;
  studentExist = false;
  radius!: number;
  color!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  officeObject : any = {
    activeStatus: '',
    backgroundColor: '',
    id:'',
    logo: '',
    oa_ParentMentor:'',
    oa_SubAdmin: '',
    oa_SkillReport: '',
    officeLocation: '',
    officeName: '',
    themeColour: ''
  }

  navbarTitle: any;
  officeId : any;

  constructor(private route : ActivatedRoute,
              private ngbModalService: NgbModal,
              private sharedDataService: SharedDataService, 
              private studentService : StudentService,
              private imageService : FileServiceService,
              private toastr : ToastrService,
              private sanitizer : DomSanitizer,
              private officeService : OfficesService, 
              private router : Router ) { }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.classId = this.route.snapshot.params['classId'];
    this.subId = this.route.snapshot.params['subjectId'];

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;
    this.officeId = this.loggedInUser.offices[0].id;

        // authorized
    this.officeService.getOfficeByIdForTeacher(environment.apiUrl + "teacher/getOfficeByIdForTeacher/" + this.officeId).then(
      data=>{
        this.officeObject = data;
      });

    this.getStudents();
  }

  open360View(studentId : string, name : string, email : string, dob : any, phone : any, image : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( OverviewModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      studentId : studentId,
      name : name,
      email : email,
      dob : dob,
      phone : phone,
      image : image,
      subId : this.subId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    }); 
  }

  viewDetails(studentId : string, name : string, email : string, dob : any, phone : any, image : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewStudentInfoModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      studentId : studentId,
      name : name,
      email : email,
      dob : dob,
      phone : phone,
      image : image
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    }); 
  }

   // authorized
    // changed from studentId to uroId
  viewResults(studentId : string, name : any, image : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewStudentResultsModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'modal-xl',
        keyboard: false
      });
    let data = {
      studentId : studentId,
      subjectId : this.subId,
      name : name,
      image : image
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    }); 
  }

  students : any[] = [{
    studentId: '',
    fkClass: '',
    fkUserRoleOffice: '',
    studentStatus: '',
    studentName: '',
    studentImage : '',
    dob : '',
    email : '',
    phone : '',
    safeStudentUrl : ''
}]



get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

  // authorized
  getStudents(){
    this.students = [];
    this.studentService.getStudentsInaClassForTeacher(environment.apiUrl +  "student/getStudentsInClassForTeacher/" + this.classId).then(
      data=>{        
        this.students.pop();

        for(var index in data){
          this.students.push( { 'studentId' : data[index].studentId, 
                                'fkClass' : data[index].fkClass,
                                'fkUserRoleOffice' : data[index].fkUserRoleOffice,
                                'studentStatus' : data[index].studentStatus,
                                'studentName' : data[index].studentName,
                                'studentImage' : data[index].studentImage,
                                'dob' : data[index].dob,
                                'email' : data[index].email,
                                'phone' : data[index].phone,
                                'safeStudentUrl' : this.safeStudentUrl                                
                                      });
}

if(this.students===undefined || this.students.length===0){
this.showSpinner=false;
this.toastr.show('No Students to display !');
}

let count : number = 0;
this.students.forEach( (value) => {
          count++;
  this.imageService.getUserImage(value.studentImage).subscribe(
    data=>{
      this.unsafeStudentUrl = URL.createObjectURL(data);
      this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);

      value.safeStudentUrl = this.safeStudentUrl;

      if(count===this.students.length){        
        setTimeout(()=>{                           //timer
          this.showSpinner = false;
     }, 2500);
      }



     });
   }); 
   this.studentExist = true;
});
  }

  goToAccountPage(){
    this.router.navigate(['user-account', this.userId]);
  }
 
  goToHomePage(){
    this.router.navigate(['/dashboard/teacher']);
  }

  // authorized
  goToActivityTimeline(studentId : string, name : any, image : any){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( StudentActivityTimelinePageComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      studentId : studentId,
      subId : this.subId,
      name : name,
      image : image
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    }); 
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  goBack() {
    this.router.navigate(['/dashboard/user']).then();
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      userId : this.userId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
  reloadPage() {
    window.location.reload();
  }
}
