
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active"><a style="cursor: pointer;color: #0275d8;" ><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <!-- <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Users</li> -->
    </ol>
  </nav>


  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

<div class="content" style="margin-top: 0%;">

  <h1 style="margin-bottom: 3%;margin-top: 0%;" class="sub-header-h1">Courses / Products</h1>


  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">

      <mat-card class="example-card mat-elevation-z12">
       
        
        <mat-card-content >
  
          <div style=" text-align: center;size: 25px;">
  
            
            


  
  
          <div style=" text-align: center; margin-bottom: 6%; margin-top: 5%;">
            <mat-card-title>New</mat-card-title>
            <mat-card-title>Course/Product</mat-card-title>

            <a style="cursor:pointer;align-self: center;"
             (click)="addNewProduct()">
            <img src="/assets/images/icons/plus.png"
             width="90" height="90" alt="" loading="lazy">
          </a> 
        </div>
        
  
        </div>
        </mat-card-content> 
           
       
       
      </mat-card>
    
  </div>


      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let product of productList">

        <mat-card *ngIf="productsExist === true" class="example-card mat-elevation-z12">
         
<div style="width: 100%;margin-bottom: 5%;">
          <a style="cursor:pointer;float: right;"
          (click)="editProduct(product.productId);">
          <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
        </a>
      </div>
        <br>
        <div style="width: 100%;color: black;text-align: center;" >
          <a style="cursor:pointer;">
             <img style="border-radius: 50%;" [src]="product.safeUrl" width="75" height="75"
             alt="" loading="lazy">
          </a>
        </div>
        <br>
              <mat-card-title>{{product.productName}}</mat-card-title>
              <mat-card-subtitle>{{product.productDescription}}</mat-card-subtitle>

          <mat-card-content>
            <p>
              <button [ngStyle]="backgroundStyle" class="btn btn-info" (click)="exploreProduct(product.productId)" mat-button>Explore</button>
            </p>
          </mat-card-content>
          
        </mat-card>
      
    </div>
  </div>
</div>

<br>
<br>