import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-old-invoices',
  templateUrl: './view-old-invoices.component.html',
  styleUrls: ['./view-old-invoices.component.scss']
})

export class ViewOldInvoicesComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  closeResult !: string;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser : any;
  theme : any;
  invoices: InvoiceList[] = [];
  achievedPercentage : any;
  outstandingAmtToEdit:any
  constructor(private ngbModalService: NgbModal,
    private toastrService : ToastrService,
    private invoiceService : InvoiceService,
              private activeModal : NgbActiveModal,
              private sharedDataService: SharedDataService,
              private datePipe : DatePipe

            ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.invoices = [];
    this.invoices = this.fromParent.invoices;
  
  }

  editOutstandingAmt(invoiceId : any){
    this.invoiceService.updateOldInvoiceOutstandingAmount(environment.apiUrl + "invoice/updateOldInvoiceOutstandingAmount/" + invoiceId + "/" + this.outstandingAmtToEdit).then(
      data=>{
        this.invoices.forEach((inv)=>{
          if(inv.invoiceId === invoiceId){
            this.toastrService.success("Update successfull !");
            inv.outstandingAmount = inv.grandTotal = this.outstandingAmtToEdit;
            this.passEntry.emit("edit");
          }
        });
      }
    )
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

}