import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackAnswer } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-answer';
import { FeedbackQuestion } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-question';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { CreateFeedbackAnswerComponent } from '../../feedback-answer/create-feedback-answer/create-feedback-answer.component';

@Component({
  selector: 'app-edit-feedback-question',
  templateUrl: './edit-feedback-question.component.html',
  styleUrls: ['./edit-feedback-question.component.scss']
})
export class EditFeedbackQuestionComponent implements OnInit {
  clicked = false;
  feedbackQuestion : FeedbackQuestion = new FeedbackQuestion();
  feedbackAnswers : FeedbackAnswer[] = [];
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    
    this.feedbackQuestion = this.fromParent.question;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    // this.getAnswers();

  }

  // getAnswers(){
  //   this.formService.getFeedbackAnswersByOfficeId(environment.apiUrl + "feedbackAnswer/getByOfficeId").then(
  //     data=>{
  //       this.feedbackAnswers = data;
  //     });
  // }

  // createFeedbackAnswer(){
  //   this.config.class = 'modal-md';
  //   const modalRef = this.ngbModalService.open( CreateFeedbackAnswerComponent,
  //     {
  //       size : 'lg',
  //       scrollable: true,
  //       windowClass: 'app-modal-window',
  //       keyboard: false
  //     });
  //   let data = { 
  //   }
  //   modalRef.componentInstance.fromParent = data;
  //     modalRef.result.then((result) => {
  //     }, (reason) => {
  //     });
  //   modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  //     this.getAnswers();
  //   });  
  // }


  onSubmit(){
    //authorized
    this.formService.updateFeedbackQuestion(environment.apiUrl + "feedbackQuestion/editFeedbackQuestion", this.feedbackQuestion).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Question updated successfully');
        this.passEntry.emit('success');
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 
