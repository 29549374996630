<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" *ngIf="type !== 'order'" class="modal-title pull-left">View Invoice </h4>
    <h4 style="color: white;" *ngIf="type === 'order'" class="modal-title pull-left">View Order </h4>          <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
    </button>
</div> 



<div class="modal-body">
 <div class="card">
  <div class="card-body">
   <form>

        <div class="form-group row">
            <div class="col-md-4">
            <label style="font-weight: bold;" for="customerName">Customer : </label> <label> {{invoice.customerName}}</label>
            </div>

            <div class="col-md-4">
                <label style="font-weight: bold;"  for="invoiceDate">Invoice Date : </label><label> {{invoice.invoiceDate}}</label>
                </div>
          

    


                <div  *ngIf="type === 'order'" class="col-md-4">
                    <label style="font-weight: bold;"  for="orderStatus" >Order Status : </label><label> {{invoice.orderStatus}}</label>
                    </div>
        </div> 


       <br>
        

        <div>
            <table border="1">
             <thead>
                    <tr>
                        <th [style.background-color]='getColor()'  >Item Name</th>
                        <th [style.background-color]='getColor()' >Quantity</th>
                        <th [style.background-color]='getColor()' >Price per Item</th>
                        <th [style.background-color]='getColor()' >Total Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor = "let invDet of invDetails">
                        <td *ngIf="invDet.type === 'invoice detail'">{{invDet.itemName}}</td>
                        <td *ngIf="invDet.type === 'invoice detail'" style="text-align: right;">{{invDet.quantity}}</td>
                        <td *ngIf="invDet.type === 'invoice detail'" style="text-align: right;">{{invDet.pricePerItem}}</td>
                        <td *ngIf="invDet.type === 'invoice detail'" style="text-align: right;">{{invDet.totalPrice}}</td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td style="font-weight: bold; text-align: right;">Sub-Total</td>
                        <td style="font-weight: bold; text-align: right;">{{invoice.totalAmount}}</td>
                    </tr>
                    <tr *ngFor = "let invDet of invDetails">
                        <td *ngIf="invDet.type === 'tax'"></td>
                        <td *ngIf="invDet.type === 'tax'"></td>
                        <td *ngIf="invDet.type === 'tax'" style="text-align: right;">{{invDet.taxName}}</td>
                        <td *ngIf="invDet.type === 'tax'" style="text-align: right;">{{invDet.taxAmount}}</td>
                       
                    </tr>
                    <tr *ngFor = "let invDet of invDetails">
                        <td *ngIf="invDet.type === 'cess'"></td>
                        <td *ngIf="invDet.type === 'cess'"></td>
                        <td *ngIf="invDet.type === 'cess'" style="text-align: right;">{{invDet.taxName}}</td>
                        <td *ngIf="invDet.type === 'cess'" style="text-align: right;">{{invDet.taxAmount}}</td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td style="font-weight: bold; text-align: right;">Total Tax</td>
                        <td style="font-weight: bold; text-align: right;">{{invoice.totalTax}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td style="font-weight: bold; text-align: right;">Total Cess</td>
                        <td style="font-weight: bold; text-align: right;">{{invoice.totalCess}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td style="font-weight: bold; text-align: right;">Grand Total</td>
                        <td style="font-weight: bold; text-align: right;">{{invoice.grandTotal}}</td>
                    </tr>
                 
                </tbody>
        </table>
    </div> <br> 


    

  


    <div>
        <label style="font-weight: bold;"  for="invoiceRemarks">Invoice Remarks : </label> <label> {{invoice.invoiceRemarks}}</label>
        
        </div>

<div>
    <a *ngIf="invoice.verifiedByUroId !== null" style="cursor:pointer;color: black;" matTooltip="Order Verified">
      <img src="/assets/images/icons/verified.png" width="30" height="30" alt="" loading="lazy">
      Verified By {{invoice.verifiedUserName}}
    </a>

    <a *ngIf="invoice.verifiedByUroId === null" style="cursor:pointer;color: black;" matTooltip="Order Unverified">
      <img src="/assets/images/icons/unverified.png" width="30" height="30" alt="" loading="lazy">
      Unverified
    </a>

</div>
    

            

  
        

        
    </form>
</div>
</div>
</div>



