export class Address {

    addressId!: string;
	landmark!: string;
	locality!: string;
	city!: string;
	state!: string;
	country!: string;
	postalcode!: string;
	addressType!: string;
	fkCustomerId!: string;
	addressStatus!: boolean;

}
