<div class="modal-header bg-dark">
    <h4 style="color: white;" class="modal-title pull-left">Preview</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
   

       <!-- TEST
       <div class="col-md-12" style="text-align: center;">
          <a style="text-align: center;">
            <img [src]="safeImageUrlTest" height="200" width="200">
          </a>
        </div>
       TEST
       <br>
       <br> -->

       <div *ngIf="mediaLoaded===true">


       



        <div class="col-md-12" style="text-align: center;" 
        *ngIf="mediaType === 'image'">
          <a style="text-align: center;">
            <img [src]="safeImageUrl" height="200" width="200">
          </a>
        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="mediaType === 'video'">
          <a style="text-align: center;">
            
           <video *ngIf="playVideo" muted autoplay width="320" height="240" controls>
            <source [src]="urlSafe" type="video/mp4">
            <source [src]="urlSafe" type="video/ogg">
          </video>

          </a>
        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="mediaType === 'document'">
        

          <a [href]="pdfFile" id="link" style="color: blue;"
          download="file.pdf">Click here to Download File</a> 

        </div>

        <!-- <div class="col-md-12" style="text-align: center;" 
        *ngIf="mediaType === 'txt'">
          
          <a style="color : blue" [href]="txtFile" download="file.txt">Click here to Download File for Preview</a>

          <iframe [src]='txtFile' width="100%" height="1000px"></iframe>

        </div> -->
   
        <!-- <div class="col-md-12" style="text-align: center;" 
        *ngIf="mediaType === 'xls' || mediaType === 'xlsx'">
          <a style="color : blue" [href]="txtFile" download="file.xls">Click here to Download File for Preview</a>

          <iframe [src]='txtFile' width="100%" height="1000px"></iframe>

        </div> -->
   
      </div>
      
   </div>
   
   <!-- <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary float-right" (click)="closeUpdateModal();">Close</button>
  </div> -->