import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
import { TaxService } from 'src/app/BIZ_CRM/biz_crm_services/tax.service';
import { GlobalClass } from 'src/app/global/global-class';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-tax',
  templateUrl: './edit-tax.component.html',
  styleUrls: ['./edit-tax.component.scss']
})
export class EditTaxComponent implements OnInit {
  clicked = false;
  tax : Tax = new Tax();
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private taxService : TaxService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();    

    this.tax = this.fromParent.tax;
  }

  onSubmit(){
    //authorized
    this.taxService.updateTax(environment.apiUrl + "tax/updateTax", this.tax).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Tax updated successfully');
        this.passEntry.emit('success');
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 