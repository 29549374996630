<div  [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Curriculum </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>  
  
  
  <div class="modal-body">
    <div class="card">
      <div class="card-body"> 
      <form (ngSubmit)="onSubmit()" #curForm="ngForm">  
  
       
            
  
              <div>
              <label class="required"  for="name">Name</label>
              <input #name="ngModel" maxlength="50" required class="form-control" required placeholder="Enter name" type="text" name="name" required id="name" [(ngModel)]="curriculum.name">
              <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
            </div>
         <br>
              <div>
              <label class="required" for="description">Description</label>
              <textarea maxlength="5000" required type="text" id="description" name="description" class="form-control" placeholder="Enter Description" [(ngModel)]="curriculum.description"></textarea>
              </div>

      </form> 
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button [disabled]="clicked"  [disabled]="!curForm.valid"  class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Add</button>
  </div> 