


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Ticket Support</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 
             
        <div class="form-group row">
          <div class="col-md-4">
            <label class="required"  for="ticketSupport"> Name</label>
            <input #no="ngModel" required class="form-control" type="text" id="ticketSupport" 
            name="ticketSupport" [(ngModel)]="ticketSupportDefinition.ticketSupport">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Name is required</div>
          </div>

          <div class="col-md-4">
            <label class="required"  for="ticketSupportType"> Type</label>
            <input #type="ngModel" required class="form-control" type="text" id="ticketSupportType" name="ticketSupportType" 
            [(ngModel)]="ticketSupportDefinition.ticketSupportType">
            <div class="alert alert-danger" *ngIf="type.touched && !type.valid">Type is required</div>
          </div>

          <div class="col-md-4">
            <!-- <label class="required"  for="ticketSupportGroup"> Group</label>
            <input #Group="ngModel" required class="form-control" type="text"
             id="ticketSupportGroup" name="ticketSupportGroup" 
            [(ngModel)]="ticketSupportDefinition.ticketSupportGroup">
            <div class="alert alert-danger" *ngIf="Group.touched && !Group.valid">Group is required</div> -->
            <label class="required" for="ticketSupportGroup"> Group</label>
            <select #Group="ngModel" required  class="form-control" 
            [(ngModel)]="ticketSupportDefinition.ticketSupportGroup" id="ticketSupportGroup" name="ticketSupportGroup">
              <option value="" disabled >Choose Group</option>
              <option name="roleId" id1="External" value="External" >External</option>
              <option name="roleId" id1="Internal" value="Internal" >Internal</option>
              <option name="roleId" id1="Customer" value="Customer" >Customer</option>
               </select>
          </div>
        </div>
             
            <div>
            <label class="required" for="ticketSupportDescription">Description</label>
            <textarea maxlength="240" required type="text"
             id="ticketSupportDescription" name="description" 
             class="form-control" placeholder="Enter Description" 
             [(ngModel)]="ticketSupportDefinition.ticketSupportDescription"></textarea>
            </div>

            <br>

            <div class="form-group row">

            <div class="col-md-6">
              <mat-form-field [style.width.%]=100 appearance="fill">
                <mat-label>Choose form</mat-label>
                <mat-select required disableRipple [(ngModel)]="ticketSupportDefinition.fkFormId">
                  <mat-option *ngFor="let cb of feedbackForms" [value]="cb.id"> {{cb.name}}, Type : {{cb.type}} </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field [style.width.%]=100 style=" color: white; margin-right: 2%; border-top: 0;">
                <mat-label>Select Roles</mat-label>
                <mat-select required [formControl]="toppingsControl"  (selectionChange)="rolesSelection($event.value);" multiple>
              
                  <mat-option *ngFor="let topping of roles" [value]="topping.roleId">{{topping.displayName}}</mat-option>
              
                </mat-select>
              </mat-form-field>
            </div>

            </div>


      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
      </div>