import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-assignment-submission-dashboard',
  templateUrl: './assignment-submission-dashboard.component.html',
  styleUrls: ['./assignment-submission-dashboard.component.scss']
})
export class AssignmentSubmissionDashboardComponent implements OnInit {

  constructor(private imageService: FileServiceService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

              clicked = true;
              studentId : any;
              officeId : any;
              activityPlannerId : any;
              selectedFile!: File;
              res : any;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.studentId = this.fromParent.studentId;
    this.officeId = this.fromParent.officeId;
    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
    this.clicked = false;
  }

 onSubmit(){
  let body = new FormData();
  body.append("file", this.selectedFile);
  this.imageService.StudentAssignmentSubmissionFileUpload(body,this.studentId, this.activityPlannerId, this.officeId).subscribe(
    data=>{
      if(data === true){
        this.toaster.success('Submission successful !');
        this.activeModal.close();
        this.passEntry.emit('Submission sucessful !');
      }else if(data === false){
        this.toaster.success('Submission failed !, Submit again');
        this.activeModal.close();
      }
     
    });

    setTimeout(()=>{                           //timer
      this.activeModal.close();
 }, 1000);
 }

 closeUpdateModal(){
  this.activeModal.close();
} 

}
