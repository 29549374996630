import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IncentiveTier } from 'src/app/BIZ_CRM/biz_crm_classes/incentive-tier';
import { IncentiveTierService } from 'src/app/BIZ_CRM/biz_crm_services/incentive-tier.service';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-incentive-tier',
  templateUrl: './add-incentive-tier.component.html',
  styleUrls: ['./add-incentive-tier.component.scss']
})
export class AddIncentiveTierComponent implements OnInit {

  clicked = false;
  it : IncentiveTier = new IncentiveTier();

  loggedInUser : any; 
  theme : any;
  roles : any[] = [];
  roles0 : any[] = [];

  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private roleService : RolesServiceService,
    private itService : IncentiveTierService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.getRoles();
  }

  getRoles(){
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/bizOfficeRoles').then(rolesRes => {
      this.roles = rolesRes;
      this.roles.forEach((role : any)=>{
        if(role.roleName === "BCRM_EXE" || role.roleName === "BCRM_MAN" || role.roleName === "BCRM_SALES_COORDINATOR"){
          this.roles0.push(role);
        }
      });
    });
  }

  onSubmit(){

    this.clicked = true;

    this.itService.checkForOverlappingTiers(environment.apiUrl + "incentiveTier/checkForOverlappingTiers", this.it).then(
      data=>{

        this.it = data;

        if(this.it.overlappingTierExists === true){

          this.clicked = false;
          this.toaster.error('Overlapping Incentive Tier exists !');

        }else if(this.it.overlappingTierExists === false){

          this.itService.add(environment.apiUrl + "incentiveTier/add", this.it).then(
            data=>{
              this.activeModal.close();
              this.toaster.success('Incentive Tier added successfully');
              this.passEntry.emit('success');
            });

        }
     
      });
 

  
 
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}