import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminUserService {
  authToken: string | null = '';
  constructor(private http: HttpClient) {
    // this.authToken = localStorage.getItem('auth-token');
  }

  clearTokenVariable(){
    this.authToken = null;    
  }

  getSuperAdminUsers(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  createOfficeAdmin(avenue: string, officeAdmin: any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      // const role = {
      //   "roleName" : roleName
      // }
      self.http.post(avenue, officeAdmin, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  addSubscriptionToUser(avenue: string, userSubscription: any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.post(avenue, userSubscription, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  // RICK FLAG
  addAdmin(avenue: string, userSubscription: any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.post(avenue, userSubscription, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  updateUserStatus(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.http.put(avenue, null, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  bulkUsers(avenue: string, formData: FormData): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest('POST', avenue, formData, {
        reportProgress: true,
        responseType: 'text'
      });

      self.http.post(avenue, formData, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err)
        });
    });
  }
}
