import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { OfficesService } from 'src/app/services/office/offices.service';
import { GlobalClass } from 'src/app/global/global-class';
import { BotLanguage } from 'src/app/BOT_CODE/bot_classes/bot-language';
import { BotSuperadminServiceService } from 'src/app/BOT_CODE/bot_services/bot-superadmin--service.service';
import { ToastrService } from 'ngx-toastr';
import { Office, Offices } from 'src/app/models/office';
import { Roles } from 'src/app/models/role';
import { SuperAdminUserService } from 'src/app/services/user/super-admin-user.service';
// import { AddOfficeComponent } from 'src/app/views/add-office/add-office.component';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from 'src/app/services/destroyService';
import { OfficeAccessControlsComponent } from 'src/app/views/office-access-controls/office-access-controls.component';
import { SubOfficesComponent } from 'src/app/views/org/sub-offices/sub-offices.component';
// import { AddUserComponent } from 'src/app/auth/add-user/add-user.component';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { WCASubscription } from 'src/app/BOT_CODE/Whatapp_Cloud_API_Control/classes/wca-subscription';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { AddBotOfficeComponent } from 'src/app/views/add-bot-office/add-bot-office.component';
import { AddBotUserComponent } from 'src/app/auth/add-bot-user/add-bot-user.component';
import { AddWcaSubscriptionComponent } from 'src/app/BOT_CODE/Whatapp_Cloud_API_Control/modals/wca_subscription/add-wca-subscription/add-wca-subscription.component';
import { WcaSubscriptionService } from 'src/app/BOT_CODE/Whatapp_Cloud_API_Control/service/wca-subscription.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cb-org-admin-home',
  templateUrl: './cb-org-admin-home.component.html',
  styleUrls: ['./cb-org-admin-home.component.scss']
})
export class CbOrgAdminHomeComponent extends DestroyService implements OnInit {

 
  showSpinner = true;
  currentRole = "CB_ORG_ADMIN";
  navbarTitle : any;
  userId !: string;
  userOfficeId: any;
  loggedInUser;
  userOptions:any;
  officeOptions:any;
  closeResult!:string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  constructor(public router: Router, private modalService: BsModalService, private toaster : ToastrService,
    private ngbModalService: NgbModal, private officeService : OfficesService, private roleService: RolesServiceService,
    private sharedDataService: SharedDataService,private toastrService : ToastrService,
    public bsModalRef: BsModalRef, private superAdminUserService: SuperAdminUserService,private subscriptionService : WcaSubscriptionService,
    private botService : BotSuperadminServiceService,
    private datePipe : DatePipe) {
      super();
    
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userId = this.loggedInUser.userId ;
    this.username = this.loggedInUser.username;

      }
      username:any;

      languages : BotLanguage[] = [];
      botLanguage : BotLanguage = new BotLanguage();
      roles = new Roles();
  offices = new Offices();
  superAdminUsers: any;
  commaSep = '';
  officesExists = false;
  officeAdminOptions : any;
  officeAdminsExists = false;
  subsExist = false;

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;
    this.botLanguage.language = null;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    // this.getAllLanguages();
    this.getOffices();
    this.getOfficeAdmins();
    this.getRoles();
    this.getWCA_Subscriptions();

    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.officeAdminOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.officeOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

  }

  getRoles(){
    this.roleService.getAllRoles(environment.apiUrl + 'cbOrgAdmin/get/roles').then(rolesRes => {
      this.roles.roles = rolesRes;
    });
  }

  wca_subscriptions : WCASubscription[] = [];
  getWCA_Subscriptions(){
this.subscriptionService.getWcaSubscriptions(environment.apiUrl + "cbOrgAdmin/getAll/WcaSubscriptions").then(
  res=>{
    this.wca_subscriptions = [];
    this.subsExist = false;
    let sub : WCASubscription = new WCASubscription();
    sub = res;
    this.wca_subscriptions = sub.list;
    if(this.wca_subscriptions !== null && this.wca_subscriptions !== undefined && this.wca_subscriptions.length > 0){
      this.subsExist = true;
      this.wca_subscriptions.forEach((subs)=>{
        let x = new Date(subs.startDate);
        subs.startDateFormatted = this.datePipe.transform(x, "dd-MM-yyyy");
        let y = new Date(subs.endDate);
        subs.endDateFormatted = this.datePipe.transform(y, "dd-MM-yyyy");

        let currentDate = new Date();
        let dateSent = new Date(y);
    
        let diff = Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) ) /(1000 * 60 * 60 * 24));
        subs.daysLeft = diff;

        if(subs.daysLeft === 0 || subs.daysLeft < 0){
          subs.expiryStatus = "Expired";
        }else if(subs.daysLeft > 0 && subs.daysLeft < 6){
          subs.expiryStatus = "Expiring";
        }
        
      });
    }

  });
  }


  addWcaSubscription(officeId:any, officeName:any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddWcaSubscriptionComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
     officeId : officeId,
     officeName : officeName,
     context : "Add Subscription"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : WCASubscription) => {
      // receivedEntry.officeName = officeName;
      // let x = new Date(receivedEntry.startDate);
      // receivedEntry.startDateFormatted = this.datePipe.transform(x, "dd-MM-yyyy");
      // let y = new Date(receivedEntry.endDate);
      // receivedEntry.endDateFormatted = this.datePipe.transform(y, "dd-MM-yyyy");
      // this.wca_subscriptions.push(receivedEntry);
      this.getWCA_Subscriptions();
    });
  }

  editWcaSubscription(subscription:any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddWcaSubscriptionComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
     subscription : subscription,
     officeName : subscription.officeName,
     context : "Update Subscription"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : WCASubscription) => {
      // this.wca_subscriptions.forEach((subs)=>{
      //   if(subs.subscriptionId===receivedEntry.subscriptionId){
      //     let x = new Date(receivedEntry.startDate);
      //     subs.startDateFormatted = this.datePipe.transform(x, "dd-MM-yyyy");
      //     let y = new Date(receivedEntry.endDate);
      //     subs.endDateFormatted = this.datePipe.transform(y, "dd-MM-yyyy");
      //   }
      // });
      this.getWCA_Subscriptions();
    });
  }

  openModalForOfficeUserAdd(): void {
    if (this.offices.offices.length > 1) {
      this.config.class = 'modal-lg';
      const data = { source: 'SUPER_ADMIN' };
      const initialState = {
        sourceObject: data,
        config: this.config
      };
      this.bsModalRef = this.modalService.show(AddBotUserComponent, {
        class: 'modal-lg', backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false, initialState
      });
      this.bsModalRef.content.closeBtnName = 'Close';
      this.modalRefs.push(this.bsModalRef);
      const self = this;
      this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
        this.addNewOfficeAdmin(res.data);
      });
    } else {
      this.toaster.info('Please add office before create office admin');
    }
  }

  getOffices(){
    this.officeService.getAllOffices(environment.apiUrl + 'cbOrgAdmin/get/allOrganisationOffices').then(officesRes => {
      this.offices.offices = officesRes;
      this.officesExists = true;
    });
  }


  updateUserStatus(officeAdmin: any, value: string) {
    const self = this;
    this.superAdminUserService.updateUserStatus(environment.apiUrl + 'user/update/status?userId=' + officeAdmin.userId + '&value=' + value + '&status=' + !officeAdmin.active).then(userResponse => {
      self.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'cbOrgAdmin/all/cb/officeAdmins').then(officeAdmins => {
        this.superAdminUsers = officeAdmins;
        this.toaster.success("UserStatus Updated Successfully")
              })
    }).catch((err) => {
      this.toaster.error(err.error.message);
    });
  }

  editOfficeAdmin(officeAdmin: any): void { 
    this.config.class = 'modal-lg';
    const data = { source: 'SUPER_ADMIN' ,
  officesDisplay : false};
    const initialState = {
      officeAdminState: officeAdmin,
      sourceObject: data,
      config: this.config
    };
    this.bsModalRef = this.modalService.show(AddBotUserComponent, {
      class: 'modal-lg', backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false, initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      this.addNewOfficeAdmin(res.data);
    });
  }

  addNewOfficeAdmin(adminInfo: any): void {
    const self = this;
    const newOfficeAdmin = {
      userId: adminInfo.userId,
      firstName: adminInfo.first_name,
      lastName: adminInfo.last_name,
      email: adminInfo.email,
      phoneNumber: adminInfo.phone_number,
      gender: adminInfo.gender,
      dateOfBirth: adminInfo.date_of_birth,
      role: this.roles.roles.filter(role => role.roleName=== 'OFFICE_ADMIN')[0].roleId,
      office: adminInfo.office,
      address: {
        addressLine1: adminInfo.addressLine1,
        addressLine2: adminInfo.addressLine2,
        city: adminInfo.city,
        country: adminInfo.country,
        state: adminInfo.state,
        zipCode: adminInfo.zipCode
      }
    };
    this.superAdminUserService.createOfficeAdmin(environment.apiUrl + 'cbOrgAdmin/add/office/admin', newOfficeAdmin).then(addOfficeAdminRes => {
      self.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'cbOrgAdmin/all/cb/officeAdmins').then(officeAdmins => {
        self.superAdminUsers = officeAdmins;        
        this.toaster.success( newOfficeAdmin.userId.length <1 ? 'Successfully Added New User' : 'Successfully Updated UserDetails');
      })
    }).catch((err) => {
      this.toaster.error(err.error.message);
          }).catch((err) => {
      this.toaster.error(err.error.message);
          });
  }

  getOfficeAdmins(){
    this.superAdminUserService.getSuperAdminUsers(environment.apiUrl + 'cbOrgAdmin/all/cb/officeAdmins').then(usersRes => {
      this.superAdminUsers = usersRes;
      this.officeAdminsExists = true;
      if (usersRes.length > 0) {
        this.commaSep = ',';
      }
    });
  }

  editOffice(office: any): void {
    this.config.class = 'modal-md';
    const initialState = {
      officeObject: office
    };
    this.bsModalRef = this.modalService.show(AddBotOfficeComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      self.editOfficeInDB(res.data);
    });
  }



  editOfficeInDB(office: any) {
    const officeObj = new Office();
    officeObj.id = office.id;
    officeObj.logo = office.image;
    officeObj.officeLocation = office.location;
    officeObj.officeName = office.name;
    officeObj.theme = office.theme;
    officeObj.activeStatus = office.activeStatus;
    officeObj.leadConversionType = office.leadConversionType;
    officeObj.type = office.type;
    const self = this;

    // my edit
    const off =[
      officeObj.id,
      officeObj.officeLocation,
      officeObj.officeName,
      officeObj.leadConversionType,
      officeObj.type,
      officeObj.theme,
    ]
    
    let logo0: File = office.image;

    const data = {
      info : off,
      logo : logo0 as File
    }

    if(data.logo instanceof File){
      this.toaster.success("Is a File !");
      this.officeService.updateOffice(environment.apiUrl + 'cbOrgAdmin/office/update2', toFormData(data)).then(updateOffice => {
        self.officeService.getAllOffices(environment.apiUrl + 'cbOrgAdmin/get/allOrganisationOffices').then(officesRes => {
          self.offices.offices = officesRes;
          this.toaster.success("Office updated Successfully");
        });
      }).catch((err) => {
        this.toaster.error(err.error.message);
      }).catch((err) => {
        this.toaster.error(err.error.message);
      });
    }else{
      this.toaster.success("NOTTTTTTTTTT a File !");
      this.officeService.updateOffice(environment.apiUrl + 'cbOrgAdmin/office/update', officeObj).then(updateOffice => {
      self.officeService.getAllOffices(environment.apiUrl + 'cbOrgAdmin/get/allOrganisationOffices').then(officesRes => {
        self.offices.offices = officesRes;
        this.toaster.success("Office updated Successfully");
      });
    }).catch((err) => {
      this.toaster.error(err.error.message);
    }).catch((err) => {
      this.toaster.error(err.error.message);
    });
    }
    
  }

  openModalForOrgSubOfficeAdd(): void {
    this.config.class = 'modal-md';
    this.bsModalRef = this.modalService.show(AddBotOfficeComponent, this.config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.modalRefs.push(this.bsModalRef);
    const self = this;
    this.bsModalRef.content.event.pipe(takeUntil(this.unSubcriber)).subscribe((res: any) => {
      self.addNewOrgSubOffice(res.data, res.data.image);
    });
  }

  viewSubOffices(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SubOfficesComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    }); 
  }
  
  addNewOrgSubOffice(info: any, logo: File): void {
    const self = this;
    const infoDtls = [
      info[`location`],
      info[`name`],
      info[`bgcolor`],
      info[`theme`],
      'CB_Office'
    ];

    const data = {
      info: infoDtls,
      logo: logo as File
    };
    this.officeService.createOffice(environment.apiUrl + 'cbOrgAdmin/add/organisationSubOffice' , toFormData(data)).then(addOfficeRes => {
      self.officeService.getAllOffices(environment.apiUrl + 'cbOrgAdmin/get/allOrganisationOffices').then(officesRes => {
        self.offices.offices = officesRes;
        this.toaster.success("Office Added Successfully");        
      });
    }).catch((err) => {
      this.toaster.error(err.error.message);
          }).catch((err) => {
      this.toaster.error(err.error.message);
          });
  }

  updateOfficeStatus(value: any): void {
    const self = this;
    this.officeService.updateOfficeStatus(environment.apiUrl + 'cbOrgAdmin/office/status/update?' + 'officeId='
      + value.id + '&status=' + !value.activeStatus).then(response => {
        self.officeService.getAllOffices(environment.apiUrl + 'cbOrgAdmin/get/allOrganisationOffices').then(officesRes => {
          self.offices.offices = officesRes;
          this.toaster.success("Office status changed Successfully");          
        }).catch((err) => {
          this.toaster.error(err.error.message);          
      })

    }).catch((err) => {
      this.toaster.error(err.error.message);      
    });
  }

  accessControls(officeId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( OfficeAccessControlsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      officeId : officeId
     }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      
    }); 
  }

    addLanguage(){
      if(this.botLanguage.language===null){
        this.toastrService.error("Language cannot be empty !");
      }else{
        this.botService.addBotLanguage(environment.apiUrl + "botConvo/addLanguage", this.botLanguage).then(
          res=>{
           this.languages.push(res);
           this.botLanguage.language = null;
           this.toastrService.success("Language added successfully !");
         });
      }
     }

     editLanguage(lang : BotLanguage){
      this.botService.editBotLanguage(environment.apiUrl + "botConvo/editLanguage", lang).then(
        res=>{
         this.languages.forEach((language)=>{
          if(language.botLanguageId === lang.botLanguageId){
            language.language = lang.language;
            this.toastrService.success("Language edited successfully !");
          }
         });
       });
     }

     getAllLanguages(){
      this.botService.getAllLanguages(environment.apiUrl + "botConvo/getAllLanguages").then(
        res=>{
          this.languages = res;
        });
     }

    logOut(): void { 
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });
  }
 
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
    
}


  



 

export function toFormData(formValue: any): FormData {
  const formData = new FormData();
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;
}