import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from 'src/app/courseManagementClasses/role';
import { Uro } from 'src/app/courseManagementClasses/uro';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }
  private getUrl =  environment.apiUrl + "user/getRolesForRoleSwitch";
  private getAllUrl =  environment.apiUrl + "role/getAll";
  private postUrl =  environment.apiUrl + "user/assignRole"


  getUserAssignedRoles(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getUserAssignableRoles(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  assignAdminRole(avenue: string, uro: any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, uro, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, uro, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
  getRoleListByUserId(userId : string):Observable<Role[]>{
    return this.httpClient.get<Role[]>(`${this.getUrl}/${userId}`);
   } 

  getAllRoles():Observable<Role[]>{
    return this.httpClient.get<Role[]>(`${this.getAllUrl}`);
  }

  // getAssignableRoles(userId : string, officeId : string):Observable<Role[]>{
  //   return this.httpClient.get<Role[]>(`${this.getUrl2}/${userId}/${officeId}`);
  // }

  assignRole(uro : Uro):Observable<Uro>{
    return this.httpClient.post<Uro>(`${this.postUrl}`, uro);
  }
}
