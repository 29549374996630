<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 

    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">


  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  
  <div class="p-4">
   
     
    <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          {{formActive.feedbackForm.name}} ( {{formActive.formCount}} responses )
        </span>

        <a style="cursor:pointer;" class="float-right" matTooltip="Refresh"
        (click)="getFormResponses();">
         <img src="/assets/images/icons/reset.svg" width="26" height="26" alt="" loading="lazy">
       </a>

      
      </div> 
    </div>
 
    <div class="card">
      <div class="card-body table-responsive">
        
      
      
          <mat-tab-group  style="padding: 1em;" dynamicHeight>
            <mat-tab label="Form">   
           
              <br>
      
              <div class="content">

              <div fxLayout="row wrap" fxLayoutGap="16px grid">

                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let response of formResponses">
            
                  
                  <mat-card  class="example-card mat-elevation-z12">
                 
                    <h4 style="color: black;">{{response.question}}</h4>
           
           <mat-form-field *ngIf="response.answerType === 'text'" [style.width.%]=100 class="example-full-width" appearance="fill">
                          <mat-label>Message</mat-label>
                          <input [(ngModel)]="response.answerValue" matInput #message
                           maxlength="256" [placeholder]="response.questionDescription">
                          
                        </mat-form-field>
          
            <mat-radio-group *ngIf="response.answerType === 'radio'"
            aria-labelledby="example-radio-group-label"
            class="example-radio-group"
            [(ngModel)]="response.fkAnswerValueId">
            <mat-radio-button class="example-radio-button" *ngFor="let answerValue of response.feedbackAnswerValuesDto" [value]="answerValue.id">
              {{answerValue.answerValue}}
            </mat-radio-button>
          
          </mat-radio-group>
          
          <div style="width: 50%;">
            <ngx-slider id="response.fkQuestionId" *ngIf="response.answerType === 'slider'" 
            [(value)]="response.sliderValue" 
            [options]="response.sliderOptions"></ngx-slider>
          </div>
          
          <div  *ngIf="response.answerType === 'checkbox'">
          <div class="form-check" *ngFor="let cb of response.feedbackAnswerValuesDto">
            <input class="form-check-input"
             type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              <a style="color: black;">
              {{cb.answerValue}} 
            </a> 
            </label>
            
          
          </div>
          </div>
          
          <div *ngIf="response.answerType === 'singleSelectDropdown'">
            <mat-form-field appearance="fill">
              <mat-label>Choose an option</mat-label>
              <mat-select disableRipple [(ngModel)]="response.fkAnswerValueId">
                <mat-option *ngFor="let cb of response.feedbackAnswerValuesDto" [value]="cb.id"> {{cb.answerValue}} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div *ngIf="response.answerType === 'multiSelectDropdown'">
            <mat-form-field [style.width.%]=50 style="color: white; margin-right: 2%; border-top: 0;">
              <mat-label>Choose Options</mat-label>
              <mat-select [formControl]="toppingsControl" multiple>
            
            
                <mat-option *ngFor="let cb of response.feedbackAnswerValuesDto" [value]="cb.id">{{cb.answerValue}}</mat-option>
            
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="response.answerType === 'timestamp'" >
            <div style="width: 50%;">
              <!-- <label class="required"  for="shippedDate">Choose </label> -->
              <input class="form-control" type="datetime-local" name="timestamp" id="timestamp" [(ngModel)]="response.answerValueTimestamp">
          </div>
          </div>
               </mat-card>
                  
                    </div>
               
              </div>
      
              </div>
                  
          </mat-tab>
            
           
               
            <mat-tab label="Responses" > 
            <br>

            
               

                <div style="padding : 0%;margin-left:5% ;">
                  <h2 class="example-h2">Responses</h2>

                  <mat-progress-spinner
                      class="example-margin"
                      [color]="color1"
                      [mode]="mode1"
                      [value]="value1">
                  </mat-progress-spinner>

                  <div style="position:relative; top: -60px; left: 45px;color: black;">
                    {{formActive.formCount}}/ {{formActive.formLimit}}
                </div>
              </div>
              <div style="padding: 2%;">
                <button (click)="download()" class="btn btn-info"> Download </button>
              </div>
                <!-- <div style="padding: 2%;">
                  <mat-accordion class="example-headers-align">
                 
                    <mat-expansion-panel *ngFor="let formRespondent of formActive.formRespondents" hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{formRespondent.timestamp}}
                        </mat-panel-title>
                        <mat-panel-description>
                          {{formRespondent.userInfo}}
                          <mat-icon>forum</mat-icon>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                  
                        <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
                          <div class="col-md-12" style="color: rgb(248, 245, 245);">
                            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
                             Responses
                            </span>
                          </div> 
                        </div>

                        <br>

                       <div *ngFor="let formResponse of formRespondent.formResponsesDto">
                        <h4 style="margin:0%;font-weight: bold;">{{formResponse.question}}</h4>
                        <h4 style="margin:0%">{{formResponse.answer}}</h4>
                        <br>
                      </div>
                  
                    </mat-expansion-panel>
            
                  </mat-accordion>
                  
                </div> -->
                <div style="padding: 2%;">
                  <mat-accordion class="example-headers-align">
                 
                    <mat-expansion-panel *ngFor="let formRespondent of sortedFormRespondents" hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{formRespondent.timestamp}}
                        </mat-panel-title>
                        <mat-panel-description>
                          {{formRespondent.userInfo}}
                          <mat-icon>forum</mat-icon>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                  
                        <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
                          <div class="col-md-12" style="color: rgb(248, 245, 245);">
                            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
                             Responses
                            </span>
                          </div> 
                        </div>

                        <br>

                       <div *ngFor="let formResponse of formRespondent.sortedResponses">
                        <h4 style="margin:0%;font-weight: bold;">{{formResponse.question}}</h4>
                        <h4 style="margin:0%">{{formResponse.answers}}</h4>
                        <h4 style="margin:0%" *ngIf="formResponse.answerType==='timestamp'">{{formResponse.answerValueTimestamp}}</h4>
                        <br>
                      </div>
                  
                    </mat-expansion-panel>
            
                  </mat-accordion>
                  
                </div>
            </mat-tab>

            <mat-tab label="Analytics" > 
           
              <br>
      
              <div class="content">

              <div fxLayout="row wrap" fxLayoutGap="16px grid">

                <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let question of formActive.feedbackForm.feedbackQuestions">
            
                  
                  <mat-card  class="example-card mat-elevation-z12">
                 
                    <h4 style="color: black;">{{question.question}}</h4>
           
          <div *ngIf="question.answerType === 'text' || question.answerType === 'timestamp'">

           <div *ngIf="question.viewAnswers===true">
            <div *ngFor="let formResponse of question.formResponses">
              <h4 style="margin:0%">{{formResponse.answerValue}}</h4>
            </div>
           </div>
            
           <button *ngIf="question.viewAnswers===false" class="btn btn-info" style="width: auto;"
           (click)="question.viewAnswers = !question.viewAnswers">View Responses</button>
           <button *ngIf="question.viewAnswers===true" class="btn btn-info" style="width: auto;" 
           (click)="question.viewAnswers = !question.viewAnswers">Hide Responses</button>
       
          </div>
          
          <div *ngIf="question.answerType !== 'text' && question.answerType !== 'timestamp'">
            
            <div class="p-4 col-md-6">
   
          
              <div class="card">
                <div class="card-body table-responsive">
                  <div style="display: block">
                    <canvas baseChart
                    [data]="question.pieChartData"
                    [labels]="question.pieChartLabels"
                    [chartType]="question.pieChartType"></canvas>
                  </div>
          
          
                </div>
              </div> 
          
          
          </div>
          
          </div>
          
         
               </mat-card>


                  
                    </div>
               
              </div>
      
              </div>
              

             
  
         
              </mat-tab>
      
             
            <mat-tab label="Details" > 
              <br>
  
              <div class="content">
                <div fxLayout="row wrap" fxLayoutGap="16px grid">
              
                 
            
                  <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
              
                    <mat-card class="example-card">
              
                  
                      <div class="email" style="text-align: left; color: black; word-wrap: break-word;" >
                          <a><i *ngIf="formActive.expiryStatus === false" class="fa fa-check-circle" aria-hidden="true">  Status : Active</i></a> <br *ngIf="formActive.expiryStatus === false">
                          <a><i *ngIf="formActive.expiryStatus === true" class="fa fa-times" aria-hidden="true">  Status : Expired</i></a> <br *ngIf="formActive.expiryStatus === true">
                      <br>
                        <a><i class="fa fa-calendar" aria-hidden="true">  {{formActive.formattedFromDate}} to {{formActive.formattedToDate}} </i></a> <br>
                     
                     <br>
                        <a><i class="fa fa-square" aria-hidden="true"> Limit : {{formActive.formLimit}}</i></a> <br>
                     <br>
                        <a><i class="fa fa-check-square " aria-hidden="true"> Count :  {{formActive.formCount}}</i></a> <br>
                     <br>
                        <a><i class="fa fa-external-link" aria-hidden="true">  </i> Url :  {{formActive.url}} </a><br>

                        <br>
                        <button style="float: left;" (click)="editFormActive();" class="btn btn-info">Edit</button>
                    </div>
                   
                  </mat-card>
              
                   
                  </div>
              
      
              
                 
               
                  
              
                </div>
                </div>
           
              </mat-tab>
            
            </mat-tab-group>
      </div>
    </div>
  

</div>