import { Subscription } from "../courseManagementClasses/subscription";
import { UserDetails } from "../models/user-details";
import { InvoiceList } from "./invoice-list";
import { Payment } from "./payment";

export class CompleteCustomerDetails {

    customerId!: string;
	firstName!: string;
	lastName!: string;
	phoneNumber : any;
	email : any;
	activeStatus: any;
	dateOfBirth: any;
	gender: any;
	customerType!: string;
	fkOfficeId!: string;
	leadType : any
	leadSource : any
	userId : any
	outstandingAmount : any = 0;
	followUpOverdue : any;
	username : any;
	leadCreationDate : any;

		// student/child info
		studentId!: string;
		studentFirstName!: string;
		studentLastName!: string;
		studentDateOfBirth!: string;
		studentGender!: string;
		childPetname:any
			// product info
	fkProductId!: string;

	// subscription info
	startedDate!: string;
	endedDate!: string;

	
	invoices : InvoiceList[] = [];
	payments : Payment[] = [];
	subscriptions : Subscription[] = [];
    addressId!: string;
	addressLine1 : any;
	addressLine2 : any;
	city!: string;
	state!: string;
	country!: string;
	zipCode!: string;
	addressType!: string;
	fkCustomerId!: string;
	addressStatus!: boolean;

	entityType : any;
	notes : any
	contactPersonPhone:any;
	contactPersonEmail:any;
	contactPerson:any;

	countryDialCode : any;
	contactPersonCountryDialCode : any;
	
	customerProfileExists!: boolean;
	userProfileExists!: boolean;
	userAndCustomerProfileExist!: boolean;

	createCustomer! : boolean;

	userDetails : UserDetails[] = [];

	nextActionDateTime : any;

	hotLeads:any
	coldLeads:any
	allLeads:any
	leadsToContact:any
	discardedLeads:any
	activeLeads : any;

	leads:CompleteCustomerDetails[] = []

	customers : CompleteCustomerDetails[] = [];
}
