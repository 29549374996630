
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a  onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-arrow-left"></i> Go back </a>
        </li>
  
        
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Account</li>
    </ol>
  </nav>

  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

    
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card class="example-card mat-elevation-z12" >
  
          <div class="card-icon" >
            <a> 
              <img style="border-radius: 50%;" class="card-icon-image" [src]="safeUrl" width="75" height="75"
               alt="" loading="lazy">
            </a>
          </div>
           <br>
          <mat-card-subtitle>{{info.userName}}</mat-card-subtitle>
          <h4 style="font-weight: bold;" class="title">Login Username:<h4>{{info.username}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">Email:<h4>{{info.emailId}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">Phone:<h4>{{info.phone}}</h4></h4> 
          <h4 *ngIf="isOfficeAdmin===true" style="font-weight: bold;" class="title">Whatsapp Number:<h4>{{info.whatsappNumber}}</h4></h4> 
   
        <mat-card-content>

          <p *ngIf="isOfficeAdmin===true && info.whatsappNumber === null
          || isOfficeAdmin===true && info.whatsappNumber === undefined">
            <button style="width: 75%;" class="btn btn-info" [style.background-color]='getColor()' (click)="open(searchByNameNumberModal)" mat-button>Update Whatsapp Number</button>
          </p>

          <p>
            <button style="width: 75%;" class="btn btn-info" [style.background-color]='getColor()' (click)="changePassword()" mat-button>Change Password</button>
          </p>


          <p>
            <button style="width: 75%;" class="btn btn-info" [style.background-color]='getColor()' (click)="editImage(info.userId, info.userImage)" mat-button>Change Profile Image</button>
          </p>
        </mat-card-content>
       
      </mat-card>
  
       
      </div>
  
    
     
 

      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card class="example-card mat-elevation-z12" >
  
          <div class="card-icon" >
            <a> 
              <img style="border-radius: 50%;" class="card-icon-image" src="assets/images/icons/roles.svg" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>
           <br>

           <h4 style="font-weight: bold;text-align: center;" class="title">Roles</h4> 
           <br> 
           <h4 *ngFor="let role of info.roleList"  class="title"><i class="fa fa-user" aria-hidden="true"></i> {{role.displayName}}
            </h4> 
           
        
       
      </mat-card>
  
       
      </div>



      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card class="example-card mat-elevation-z12" >
  
          <div class="card-icon" >
            <a> 
              <img class="card-icon-image" src="assets/images/icons/map.svg" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>

          <br>

          <h4 style="font-weight: bold;" class="title">Address Line 1:<h4>{{info.addressLine1}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">Address Line 2:<h4>{{info.addressLine2}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">City:<h4>{{info.city}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">State:<h4>{{info.state}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">Country:<h4>{{info.country}}</h4></h4> 
          <h4 style="font-weight: bold;" class="title">ZipCode:<h4>{{info.zipCode}}</h4></h4> 

           <br>
           
        <mat-card-content>
          <p>
            <button class="btn btn-info" [style.background-color]='getColor()' (click)="editDetails(info.userId)" mat-button>Edit</button>
          </p>

        </mat-card-content>
       
      </mat-card>
  
       
      </div>
  
  
    </div>
    </div>
  </div>











  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <ng-template #searchByNameNumberModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
       Update Whatsapp Number
      </h3>

      <div>
        <label class="required" for="botUserGrpName">Phone</label>
        <input maxlength="15" required class="form-control" type="number" 
         name="whatsappNo" id="whatsappNo" [(ngModel)]="whatsappNo">
      </div>
      <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="updateWhatsappNo();modal.dismiss('Cross click')"
      mat-raised-button>Update</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div>
  </ng-template>