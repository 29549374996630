import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wrong-url-message',
  templateUrl: './wrong-url-message.component.html',
  styleUrls: ['./wrong-url-message.component.scss']
})
export class WrongUrlMessageComponent implements OnInit {
  loggedInUser: any;

  constructor(
    private sharedDataService : SharedDataService,
    private logoutService : LogoutService,
    public router: Router,
  ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());

  } 

  logOut(): void {
    // email to username change
    // this.logoutService.deleteUserSession(this.loggedInUser.email).subscribe(data=>{
    // })
    localStorage.removeItem('auth-token');
    localStorage.clear();
    window.location.href=`${environment.authUrl}/logout`;
    // this.logoutService.deleteUserSession(environment.apiUrl + "user/destroySession/" + this.loggedInUser.username).then(data=>{
      
    // });
    // this.router.navigate(['/auth/login']).then();
  }

}
