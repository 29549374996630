import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Product } from 'src/app/crmClasses/product';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { ProductService } from 'src/app/service/product.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-combined-parent-teacher',
  templateUrl: './add-combined-parent-teacher.component.html',
  styleUrls: ['./add-combined-parent-teacher.component.scss']
})
export class AddCombinedParentTeacherComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  clicked=false;
  userOfficeId! : string;
  userExists = false;

  products: Product[] = [];

  role : string = "Combined Parent-Teacher";

  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();

  constructor(private combinedPTCService : CombinedParentTeacherService,
              private productService: ProductService,private sharedDataService : SharedDataService,
              private toaster : ToastrService, private ngbModalService: NgbModal,
              private subscriptionService : SubscriptionService,
              public activeModal: NgbActiveModal ) { }

              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
    this.countryCodes = GlobalClass.CountryCodes;

    this.userOfficeId = this.fromParent.userOfficeId;

    this.productService.getProductListByOfficeId(this.userOfficeId).subscribe(data=>{
      this.products = data;
    });

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  onSubmit(){
    this.clicked = true;
    this.combinedPTC.username = this.value;
    this.combinedPTC.fkOfficeId = this.userOfficeId;
    this.combinedPTCService.addCombinedParentTeacher(environment.apiUrl + 'combinedPTC/add', this.combinedPTC).then(
      data=>{
        this.combinedPTC = data;
        this.passEntry.emit(this.combinedPTC);
        this.activeModal.close();
        this.toaster.success('User Added Successfuly');
        this.ngbModalService.dismissAll();
      });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  usernameExists = false;
  choice : any;
  value : any;
emailUnavailable = false;
phoneUnavailable = false;


  checkUsernameAvailability(){

    if(this.emailUnavailable === true && this.phoneUnavailable === true){
      this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
    }else{

    if(this.choice === "Email"){
      this.value = this.combinedPTC.email;
    }else if(this.choice === "Phone"){
      this.value = this.combinedPTC.phoneNumber;
    }

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{

        this.usernameExists = res;

        if(this.usernameExists === true){

          if(this.choice === "Email"){
           this.emailUnavailable = true;
          }else if(this.choice === "Phone"){
            this.phoneUnavailable = true;
          }

          if(this.emailUnavailable === true && this.phoneUnavailable === true){
            this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
            this.value = null;
          }else{
            this.toaster.error("Username exists !");
          }

        }else if(this.usernameExists === false){

          this.toaster.success("Username available !");

          this.onSubmit();

        }
      });

    }
  }

  checkCustomUsernameAvailability(){

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{
        this.usernameExists = res;
        if(this.usernameExists === true){
          this.toaster.error("Username exists !");
        }else if(this.usernameExists === false){
          this.toaster.success("Username available !")
          this.onSubmit();
        }
      });
  }

  closeResult!:string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
}
