<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4  style="color: white;" class="modal-title pull-left">Add Student</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  <div class="modal-body">
    

                  
                  <mat-vertical-stepper orientation="vertical" #stepper>

                    <mat-step>
                      <form>
                        <ng-template matStepLabel> Class</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Class</mat-label>
                          <mat-select [(ngModel)]="selectedClassId" >
                            <mat-option *ngFor="let Class of classes" [value]="Class.classId">
                              {{Class.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div>
                          <button [disabled]="clicked" (click)="addStudent();clicked=true" mat-button matStepperNext>Add</button>
                        </div>
                      </form>
                    </mat-step>

                  </mat-vertical-stepper>
                  
  </div>

  <div class="modal-footer">
    <button class="btn btn-sm btn-primary float-right" (click)="closeUpdateModal()">Cancel</button>
  </div> 