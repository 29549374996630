<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Assign Role : {{userFirstName}} {{userLastName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form>

  <div class="row col-md-12"> 

    <label class="col-md-3">New Role :</label> 

    <form class="col-md-6" #aplnrForm="ngForm"> 
          <select #curId="ngModel" required class="form-control" [(ngModel)]="roleIdToAssign">
            <option  class="form-control" disabled value="" >Choose Role</option>
            <option  class="form-control" [ngValue]="assignableRole.id" *ngFor="let assignableRole of assignableRoles">{{assignableRole.displayName}}</option>
          </select>
          <!-- <div class="alert alert-danger" *ngIf="curId.touched && !curId.valid">Select Role</div> -->
          <br>
        </form>


      <button class="col-md-2" [disabled]="!aplnrForm.valid" class="btn btn-primary" style="float: right;" (click)="assignRole()" > Assign </button>
          
      
  </div>
 
       

     
  <!-- <div class="card">
    <div class="card-body table-responsive"> -->
      <table *ngIf="usersExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      >
        <thead class="tableheader">
            <tr>
              <th>Current Roles</th>
              <th>Action</th>

          </tr>
          </thead>
          <tbody>
            <tr style="color: black;" *ngFor="let role of assignedRoles">
                <td>{{role.displayName}}</td>
                <td>
                  <button *ngIf="role.userRoleOfficeStatus!== null && role.userRoleOfficeStatus && role.roleName !== 'BCRM_OFFICE_ADMIN'" type="button"
                    class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation);">Deactivate</button>
                  <button *ngIf="role.userRoleOfficeStatus !== null && !role.userRoleOfficeStatus && role.roleName !== 'BCRM_OFFICE_ADMIN'" type="button"
                    class="btn btn-success mr-2" (click)="open(confirmActivation);">Activate</button>
                  
                </td>

                <ng-template #confirmActivation let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Are you sure to activate role ?
                  </div> 
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                    <button type="button" class="btn btn-success" (click)="updateUserRoleOfficeStatus(role.userRoleOfficeId);modal.dismiss('Cross click')">Activate</button>
                  </div>
                </ng-template> 
                
                
                <ng-template #confirmDeactivation let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Are you sure to deactivate role ?
                  </div> 
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                    <button type="button" class="btn btn-success" (click)="updateUserRoleOfficeStatus(role.userRoleOfficeId);modal.dismiss('Cross click')">Deactivate</button>
                  </div>
                </ng-template>
              
              
            </tr>
        </tbody>
        </table>

    <!-- </div>
  </div> -->


    </form>
    </div>
    </div>
    
  