


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Incentive Tier</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form (ngSubmit)="onSubmit()" #invoiceForm="ngForm"> 
             
      
       
        <div class="form-group row">

          <div class="col-md-6">
            <label class="required" for="fkRoleId">Role</label> 
            <select required  class="form-control" [(ngModel)]="it.fkRoleId" id="fkRoleId" name="fkRoleId">
              <option value="" disabled >Choose Role</option>
              <option  *ngFor="let role of roles0"  [ngValue]="role.roleId">{{role.displayName}}</option>
            </select>
          </div>

          <div class="col-md-6">
            <label class="required"  for="incentive">Incentive </label>
            <input #no="ngModel" required class="form-control" type="number" id="incentive" 
            name="incentive" [(ngModel)]="it.incentive">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Incentive is required</div>
          </div>

          <!-- <div class="col-md-6">
            <label class="required"  for="roleLevel">Role Level</label>
            <input #no="ngModel" required class="form-control" type="number" id="roleLevel" 
            name="roleLevel" [(ngModel)]="it.roleLevel">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Role Level is required</div>
          </div> -->


        </div>
        <div class="form-group row">
            <div class="col-md-6">
              <label class="required"  for="startValue">Start Value</label>
              <input #no="ngModel" required class="form-control" type="number" id="startValue" 
              name="startValue" [(ngModel)]="it.startValue">
              <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Start Value is required</div>
            </div>
  
            <div class="col-md-6">
              <label class="required"  for="endValue">End Value</label>
              <input #no="ngModel" required class="form-control" type="number" id="endValue" 
              name="endValue" [(ngModel)]="it.endValue">
              <div class="alert alert-danger" *ngIf="no.touched && !no.valid">End Value is required</div>
            </div>
  
  
          </div>
       
  
             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();">Save</button>
      </div>