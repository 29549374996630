<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='CrmTask Details'"
    style="color: white;" class="modal-title pull-left">Task Details</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card-body"> 
    <form #adminForm="ngForm">  

   
    <div *ngIf="fromParent.context==='CrmTask Details'">
       <a style="font-weight: bold;">Agent</a> <br>
       <a>Agent Name : {{crmTask.fkAgentBotUserName}}</a>

       <br><br>

       <a style="font-weight: bold;">Customer/User</a> <br>
       <a> Name : {{crmTask.fkBotUserName}}</a>
       <a> Phone : {{crmTask.fkBotUserPhone}}</a>

       <br><br>

       <a style="font-weight: bold;">Created On</a> <br>
       <a>{{crmTask.creationTimestamp}}</a>

       <br><br>

       <a style="font-weight: bold;">Message</a> <br>
       <a>{{crmTask.taskMsg}}</a>
    </div>


    





</form>
</div>
</div>

<!-- <div class="modal-footer">
<button *ngIf="fromParent.context==='create crmList' || fromParent.context==='edit crmList'"
 [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="createOrEditList();">save</button>
</div> -->