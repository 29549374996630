import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { SharedDataService } from 'src/app/services/sharedData.service';
 
@Component({
  selector: 'app-view-orders-table',
  templateUrl: './view-orders-table.component.html',
  styleUrls: ['./view-orders-table.component.scss']
})
export class ViewOrdersTableComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser : any;
  theme : any;
  invoices: InvoiceList[] = [];
  achievedPercentage : any;

  constructor(
              private activeModal : NgbActiveModal,
              private sharedDataService: SharedDataService,
              private datePipe : DatePipe

            ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.invoices = [];
    this.invoices = this.fromParent.invoices;
  
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 


  closeUpdateModal(){
    this.activeModal.close();
  }
}