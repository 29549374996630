import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotApiConfiguration } from 'src/app/BOT_CODE/bot_classes/bot-api-configuration';
import { CreateBotApiConfigComponent } from 'src/app/BOT_CODE/bot_modals/bot_api_configuration/create-bot-api-config/create-bot-api-config.component';

@Component({
  selector: 'app-bot-api-configs-management',
  templateUrl: './bot-api-configs-management.component.html',
  styleUrls: ['./bot-api-configs-management.component.scss']
})
export class BotApiConfigsManagementComponent implements OnInit {

  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle : any;
  userId !: string;
  
  loggedInUser;
  schedulesExists = false;
  userOptions: any;
  closeResult! : string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private ngbModalService: NgbModal,
    public router: Router, private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef, private toaster: ToastrService,
    private botOfficeService : BotOfficeServiceService,
    private botResponseService : BotResponseServiceService) {
    
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userId = this.loggedInUser.userId ;
      }

      searchText1:any;
      apiConfigs : BotApiConfiguration[] = [];

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getBotApiConfigs();

    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 50000,
      lengthChange: false,
      processing: true,
      info:false,
      paging: false,
      searching: false,
      searchable : false,
      sortable : false,
      orderable : false
    };
  }

  getBotApiConfigs(){
    this.botResponseService.getBotApiConfigsByOfficeId(environment.apiUrl + "botConvo/getBotApiConfigsByOfficeId").then(
      res=>{
        let resObj : BotApiConfiguration = new BotApiConfiguration();
        resObj = res;
        this.apiConfigs = resObj.list;
        this.showSpinner = false;
        this.schedulesExists = true;
      });
  }

  editBotApiConfiguration(api : BotApiConfiguration){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateBotApiConfigComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botApiConfig : api,
      context : "editBotApiConfiguration"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotApiConfiguration) => {
      this.apiConfigs.forEach((apiConfig)=>{        
        if(apiConfig.botApiConfigId===api.botApiConfigId){  
         
        }
      });
    });    
  }

    open(content: any) {
      this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId]);
  }

  


 


 

}