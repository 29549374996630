
  <nav class="navbar navbar-expand-md navbar-dark " 
  [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Classes</li>
    </ol>
  </nav>
  


<div class="content">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">

    <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%">

      <mat-card class="example-card mat-elevation-z12">
       

  
        <div class="row col-lg-12 col-md-12" style="margin-top: 30%;margin-bottom: 30%;margin-right: auto;
        margin-left: auto;">

          <div style="text-align: center;margin-bottom: 7%;" class="col-lg-6 col-md-6">
        
                    <mat-card-title>Back</mat-card-title>
                    <a style="cursor:pointer;"
                    onclick="history.back()" > 
                    <img src="/assets/images/icons/back-track.svg" width="55" height="55" alt="" loading="lazy">
                    </a> 
          </div>

          <div style="text-align: center;"  class="col-lg-6 col-md-6"> 

                  <mat-card-title>New</mat-card-title>
                    <a style="cursor:pointer;"
                   (click)="addNewClass();" >  
                    <img src="/assets/images/icons/plus.png" width="55" height="55" alt="" loading="lazy">
                  </a> 
          </div>   
                
       </div>  
       
 
      </mat-card>
    
  </div>


      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%"  *ngFor="let ccd of classes">

        <mat-card *ngIf="classesExist === true" class="example-card mat-elevation-z12">
         
<div style="width: 100%;margin-bottom: 5%;height: 15%;">
          <a style="cursor:pointer;float: right;"
          (click)="editClass(ccd.classId);">
          <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
        </a>
      </div>
      <br>
      <div style="text-align: center;width: 100;">
        <img  src="/assets/images/icons/class.svg" height="86" width="86" alt="...">
        </div>
        <br>
              <mat-card-title>{{ccd.name}}</mat-card-title>
              <mat-card-subtitle style="color: black;">{{ccd.courseName}}</mat-card-subtitle>
              <mat-card-subtitle>{{ccd.description}}</mat-card-subtitle>

          <mat-card-content>
            <p>
              <button (click)="explore(ccd.classId)" [ngStyle]="backgroundStyle" class="btn btn-info" mat-button>Explore</button>
            </p>
          </mat-card-content>
          
        </mat-card>
      
    </div>
  </div>
</div>
<br>
<br>