// <!-- THIS DASHBOARD SHOWS CURRICULUMS OF A SUBJECT || THIS DASHBOARD SHOWS CURRICULUMS OF A SUBJECT || THIS DASHBOARD SHOWS ALL CURRICULUMS OF A SUBJECT -->

import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddActivityQuestionsComponent } from 'src/app/course-management/activity-questions/add-activity-questions/add-activity-questions.component';
import { ViewActivityQuestionsComponent } from 'src/app/course-management/activity-questions/view-activity-questions/view-activity-questions.component';
import { AddActivityPlannerComponent } from 'src/app/course-management/activityPlanner/add-activity-planner/add-activity-planner.component';
import { UpdateActivityPlannerComponent } from 'src/app/course-management/activityPlanner/update-activity-planner/update-activity-planner.component';
import { AddActivityVideoComponent } from 'src/app/course-management/activityVideo/add-activity-video/add-activity-video.component';
import { EditActivityVideoComponent } from 'src/app/course-management/activityVideo/edit-activity-video/edit-activity-video.component';
import { AddMainCurrComponent } from 'src/app/course-management/curriculum/add-main-curr/add-main-curr.component';
import { ChangeCurriculumOrderComponent } from 'src/app/course-management/curriculum/change-curriculum-order/change-curriculum-order.component';
import { UpdateMainCurrComponent } from 'src/app/course-management/curriculum/update-main-curr/update-main-curr.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { CompleteCurriculum } from 'src/app/courseManagementClasses/complete-curriculum';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { SubjectService } from 'src/app/service/subject.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { GenerateActivityQuestionsComponent } from '../generate-activity-questions/generate-activity-questions.component';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

interface curr {
  curriculumName: string;
  curriculumId : string;
  children?: curr[]; 
}

const TREE_DATA: any[] = [
 {
    name: 'Vegetables',
    children: [{name: 'Green',
                children: [
                            {name: 'Broccoli'},
                            {name: 'Brussels sprouts'},
                          ]
              }
    ]
  },
];

 const currList = [
  {
     name : '',
     id : '',
     children: [{name : '',
                 id : '',
                 children: [{name: '',
                              id : ''}]
               }]
  }];
 

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  curriculumId : string,
  level: number;
}


@Component({
  selector: 'app-subject-curriculum-dashboard',
  templateUrl: './subject-curriculum-dashboard.component.html',
  styleUrls: ['./subject-curriculum-dashboard.component.scss']
})


export class SubjectCurriculumDashboardComponent implements OnInit {
  private _transformer = (node: curr, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.curriculumName,
      curriculumId : node.curriculumId,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  selected = '1';
  deactivateApId : any;
  currentRole = "COURSE_ADMIN";
  showActivities : boolean = true;
  showAssignments = false;
  showParentActivities = false;
  activityPlannerLoaderMessage!: string;
  activityPlannerExists!: boolean;
  activityPlannerOptions : any;
  apafExist = false;
  activityPlannerLoader = false;
  loggedInUser: any;
  loggedUser: any;
  userName!: string;
  userId!: string;
  collapsedState : boolean = false;
  apDontExist : boolean = false;
  apafDontExist : boolean = false;
  oneExist = false;
  twoExist = false;
  threeExist = false;
  actionCollapse = false;
  closeResult!: string;
 
  safeImageUrl: any;
  showSpinner : boolean = true;
  unsafeImageUrl: any;
  infoState : boolean = false;
  activityPlanners : any[] =[ {
                                activityPlannerId : '', 
                                activityPlannerName : '',
                                activityPlannerDescription : '',
                                activityPlannerOrder : '',
                                activityPlannerStatus : '',
                                fkSubjectId : '',
                                activityPlannerImage : '' ,
                                safeUrl : '',
                                type : '',
                                collapsedState : '',
                                collapsedState2 : '',
                                readMore : '',
                                      
                                      activityVideoLinkList : [{
                                                                  actVideoLinkId: "",
                                                                  actVideoLinkName: "",
                                                                  actVideoLink: "",
                                                                  actVideoLinkDescription: "",
                                                                  fkActivityPlannerId: "",
                                                                  actVideoLinkStatus: "",
                                                                  urlSafe: ""
                                                                }]
                                                                        } ];


                                                                        activityPlanners2 : any[] =[ {
                                                                          activityPlannerId : '', 
                                                                          activityPlannerName : '',
                                                                          activityPlannerDescription : '',
                                                                          activityPlannerOrder : '',
                                                                          activityPlannerStatus : '',
                                                                          fkSubjectId : '',
                                                                          activityPlannerImage : '' ,
                                                                          safeUrl : '',
                                                                          type : '',
                                                                          collapsedState : '',
                                                                          collapsedState2 : '',
                                                                          readMore : '',
                                                                                
                                                                                activityVideoLinkList : [{
                                                                                                            actVideoLinkId: "",
                                                                                                            actVideoLinkName: "",
                                                                                                            actVideoLink: "",
                                                                                                            actVideoLinkDescription: "",
                                                                                                            fkActivityPlannerId: "",
                                                                                                            actVideoLinkStatus: "",
                                                                                                            urlSafe: ""
                                                                                                          }]
                                                                                                                  } ];
  curriculumsCreated = false;                                        
 createFirstCurriculum = false;
  parentRecurringActivityPlanners : any[] =[ {
    activityPlannerId : '', 
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '' ,
    safeUrl : '',
    type : '',
    collapsedState : '',
    collapsedState2 : '',
    readMore : '',
   

          activityVideoLinkList : [{
                                      actVideoLinkId: "",
                                      actVideoLinkName: "",
                                      actVideoLink: "",
                                      actVideoLinkDescription: "",
                                      fkActivityPlannerId: "",
                                      actVideoLinkStatus: "",
                                      urlSafe: ""
                                    }]
                                            } ];


assignments : any[] =[ {  activityPlannerId : '', 
                          activityPlannerName : '',
                          activityPlannerDescription : '',
                          activityPlannerOrder : '',
                          activityPlannerStatus : '',
                          fkSubjectId : '',
                          activityPlannerImage : '' ,
                          safeUrl : '',
                          type : '',
                          collapsedState : '',
                          collapsedState2 : '',
                          readMore : '',

                          activityVideoLinkList : [{
                                                      actVideoLinkId: "",
                                                      actVideoLinkName: "",
                                                      actVideoLink: "",
                                                      actVideoLinkDescription: "",
                                                      fkActivityPlannerId: "",
                                                      actVideoLinkStatus: "",
                                                      urlSafe: ""
                                                    }]
                                                            }];

  activityPlannerListWithSafeUrl : any[] =[ { activityPlannerId : '', 
                                              activityPlannerName : '',
                                              activityPlannerDescription : '',
                                              activityPlannerOrder : '',
                                              activityPlannerStatus : '',
                                              fkSubjectId : '',
                                              activityPlannerImage : '',
                                              safeUrl : '' 
                                            } ];
  xyz: any;
  abc: any;

  currm : curr[] = [
    {
      curriculumName : '',
      curriculumId : '',
       children: [{curriculumName : '',
                   curriculumId : '',
                   children: [{curriculumName: '',
                               curriculumId : ''}]
                 }]
    }];

  // NEW CODE VARIABLES

  centered = false;
  disabled = false;
  unbounded = false;

  radius!: number;
  color!: string;

  curriculum : CompleteCurriculum = new CompleteCurriculum();
  mainCurriculums : Curriculum[] = [];
  subCurriculums : Curriculum[] = [];
  subCurriculums2 : Curriculum[] = [];

  curriculumObject : Curriculum = new Curriculum();
  mainCurriculumIds : any[] =[];
  mainCurrState : boolean = true;
  mainCurrState2 : boolean = false;
  mainCurrState3 : boolean = false;
  prevParentId : string = '';
  prevParentId2 : string = '';
  currentCurriculum: any;
  curriculumExists = false;

  constructor(private route : ActivatedRoute, 
              private subjectService : SubjectService,
              private ngbModalService: NgbModal,
              private toaster: ToastrService,
              private curService : CurriculumService,
              private activityPlannerService : ActivityPlannerService,
              public bsModalRef: BsModalRef,
              private logoutService : LogoutService,
              private router : Router, 
              private sharedDataService : SharedDataService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private officeService : OfficesService 
              ) { }

 hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  subId!: string;
  subject : Subject = new Subject();
  CurriculumListBySubId : Curriculum[] = [];
  MainCurrListBySubId : Curriculum[] = [];
  CompCurrListBySubId : CompleteCurriculum[] = [];
  CurriculumId!: string;
  ActivityPlannerList : ActivityPlanner[] = [];
  panelOpenState = false; 
  asDontExist = false;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  officeObject : any = {
    activeStatus: '',
    backgroundColor: '',
    id:'',
    logo: '',
    oa_ParentMentor:'',
    oa_SubAdmin: '',
    oa_SkillReport: '',
    oa_ParentFeedback: '',
    oa_Assignment: '',
    oa_ActQuesSkillMapping:'',
    officeLocation: '',
    officeName: '',
    themeColour: ''
  }
  

  modalRefs: BsModalRef[] = [];
  public screenWidth: any;
  public screenHeight: any;
  officeId : any;
  navbarTitle : any;
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  isOpen = false;
  readMore = false;

  ngOnInit(): void {

    this.dataSource.data = this.currm;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.officeId = this.loggedInUser.offices[0].id;

    // authorized
    this.officeService.getOfficeByIdForCourseAdmin(environment.apiUrl + "admin/getOfficeByIdForCourseAdmin/" + this.officeId).then(
      data=>{        
        this.officeObject = data;        
      });
      this.activityPlannerExists = true;

      this.subId = this.route.snapshot.params['subId'];

    // this.subjectService.getSubById(this.subId).subscribe(
    //   data=>{
    //     this.subject=data;
    //   });

      this.getCurriculum();
  }

  // authorized
  getAssignmentListByCurId(curId : string){
   this.activityPlannerService.getAssignmentListByCurIdForCA(environment.apiUrl + "activityPlanner/getAssignmentListByCurIdForCA/" + curId).then(
     data=>{
              this.assignments = data;   
              

if(this.assignments === undefined || this.assignments.length === 0){

  this.asDontExist = true;

  setTimeout(()=>{                           //timer
    this.showSpinner = false;
}, 1000);


}else{
  this.twoExist = true;
}
              this.assignments.forEach( (value) => {

                value.readMore = false;
                          
                this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
                  data=>{
                    this.xyz = data;
                    this.abc = URL.createObjectURL(data);
                    this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

                    value.safeUrl = this.xyz;
                    value.collapsedState = false;
                    value.collapsedState2 = false;
                  });
      
              }); 

              
     });

   }
   valueChange(value : any){
  //   this.showSpinner = true;
  //   if(this.showActivities === true){
  //    this.showActivities = false;
  //    setTimeout(()=>{                           //timer
  //      this.showSpinner = false;
  //  }, 1000);
  //   }else  if(this.showActivities === false){
  //    this.showActivities = true;
  //    setTimeout(()=>{                           //timer
  //      this.showSpinner = false;
  //  }, 1000);
  //   }

  if (value === '1') {
    this.showSpinner=true;

    this.showAssignments = false;
    this.showParentActivities = false;
    this.showActivities = true;

    this.showSpinner=false;
    
  } else if (value === '2') {

    this.showSpinner=true;

    this.showParentActivities = false;
    this.showActivities = false;
    this.showAssignments = true;


    
    this.showSpinner=false;
    
  } else if (value === '3') {

    this.showSpinner=true;

    this.showActivities = false;
    this.showAssignments = false;
    this.showParentActivities = true;
    this.showSpinner=false;
    
  } 

  }
   show(){
     this.showSpinner = true;
     if(this.showActivities === true){
      this.showActivities = false;
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
    }, 1000);
     }else  if(this.showActivities === false){
      this.showActivities = true;
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
    }, 1000);
     }
   }

   // authorized
   changeCurriculumOrder(){
    this.actionCollapse = false;
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ChangeCurriculumOrderComponent,
      {
        size : 'md', 
        scrollable: true,
        windowClass: 'modal-xl',
        keyboard: false
      });
  
  let data = {
    curriculum : this.curriculumObject
    }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityPlanner) => {
    this.getDetails(receivedEntry);
    }); 
   }

   // authorized
  getDetails(currId : any){
    this.readMore = false;
    this.parentRecurringActivityPlanners =[];
    this.currentCurriculum = currId;
    this.showSpinner = true;
    this.apDontExist = false;
    this.asDontExist = false;
    this.curService.getCurrByIdForCA(environment.apiUrl + "curriculum/getCurrByIdForCA/" + currId).then(
      data=>{
        this.curriculumObject = data;
        
        this.infoState = true;
this.getactivityPlanners(currId);
this.getAssignmentListByCurId(currId);
      });
  }

  // authorized
  getactivityPlanners(currId : any){
    this.activityPlannerService.getActivityPlannerListByCurIdForCA(environment.apiUrl + "activityPlanner/getActivityPlannerListByCurIdForCA/" + currId).then(
      data=>{        
        this.apafDontExist = false;
        this.apafExist = false;
        this.activityPlanners = [];
        this.activityPlanners2 = [];
        this.activityPlanners.pop();

        for(var index in data){
          this.activityPlanners.push( {  'activityPlannerId' : data[index].activityPlannerId, 
                                              'activityPlannerName' : data[index].activityPlannerName,
                                              'activityPlannerDescription' : data[index].activityPlannerDescription,
                                              'activityPlannerOrder' : data[index].activityPlannerOrder,
                                              'activityPlannerStatus' : data[index].activityPlannerStatus,
                                              'fkSubjectId' : data[index].fkSubjectId,
                                              'activityPlannerImage' : data[index].activityPlannerImage ,
                                              'safeUrl' : this.safeImageUrl ,
                                              'collapsedState' : false,
                                              'collapsedState2' : false,
                                              'activityVideoLinkList' : data[index].activityVideoLinkList,
                                              'type' : data[index].type,
                                              'readMore' : false
                                                    });
}


this.activityPlanners.forEach( (value) => {
          
  this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
    data=>{
      this.unsafeImageUrl = URL.createObjectURL(data);
      this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);

      value.safeUrl = this.safeImageUrl;
     });

     if(value.activityVideoLinkList === undefined || value.activityVideoLinkList.length === 0){
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
   }, 1000);
     }

     value.activityVideoLinkList.forEach((val : any)=>{
    this.url =  val.actVideoLink;
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    val.urlSafe = this.urlSafe

    setTimeout(()=>{                           //timer
      this.showSpinner = false;
 }, 1000);

  });
   });
   
   
if(this.activityPlanners === undefined || this.activityPlanners.length === 0){

  this.apDontExist = true;
  setTimeout(()=>{                           //timer
    this.showSpinner = false;
}, 1000);
}else {
  this.oneExist = true;
}

let count = 0;

this.activityPlanners.forEach((value2)=>{
  count++;  
  if(value2.type === "parentRecurringActivity"){
    this.parentRecurringActivityPlanners.push(value2);
  }else if(value2.type === "activityPlanner"){
    this.activityPlanners2.push(value2);
  }
})


if(this.activityPlanners === undefined || this.activityPlanners.length === 0){
  this.apDontExist = true;
}

if(count === this.activityPlanners.length){
if(this.parentRecurringActivityPlanners === undefined || this.parentRecurringActivityPlanners.length === 0){
    this.apafDontExist = true;  
}else {
  this.apafExist = true;
  this.threeExist = true;
}
}


 });

  }

  collapseActivityCardContent(activityPlannerId : string){

    this.activityPlanners.forEach((value)=>{

      if(value.activityPlannerId === activityPlannerId){

        if(value.collapsedState === true){
          value.collapsedState = false
        } else if(value.collapsedState === false){
          value.collapsedState = true
        }

      }
    }); 

  }

  collapseActivityCardContent2(activityPlannerId : string){

  this.assignments.forEach((value)=>{

    if(value.activityPlannerId === activityPlannerId){

      if(value.collapsedState === true){
        value.collapsedState = false;
      } else if(value.collapsedState === false){
        value.collapsedState = true;
      }

    }
  });
}

// authorized
  getCurriculum(){
    this.showSpinner = true;
    this.curService.getSortedCurrListBySubIdForCourseAdmin(environment.apiUrl + "curriculum/getSortedCurrListBySubIdForCourseAdmin/" + this.subId).then(
      data=>{
this.currm= data;

if(this.currm === undefined || this.currm === null || this.currm.length === 0){
  this.showSpinner = false;
  this.createFirstCurriculum = true;
}else{
this.showSpinner = false;
  this.curriculumsCreated = true;
}

this.dataSource.data = this.currm;
this.curriculumExists = true;
setTimeout(()=>{                           //timer
  this.showSpinner = false;
}, 1000);
            });


  }

  // setSubCurriculumsLevel1(curriculumId : string){

  //   this.subCurriculums = [];
    
  //   this.curriculum.level1.forEach((value)=>{
  //     if(value.fkCurriculumId===curriculumId){
  //       this.subCurriculums.push(value);
  //     }
  //   });
  // }
  
  // openSubCurriculumDiv(curId : string){
  //   this.prevParentId = curId;
  //   this.setSubCurriculumsLevel1(curId);
  //   this.mainCurrState3 = false;
  //   this.mainCurrState = false;
  //   this.mainCurrState2 = true;
  //  }
  
  //  openSubCurriculumDiv2(curId : string){
  //    this.prevParentId2 = curId;
  //    this.mainCurrState = false;
  //    this.mainCurrState2 = false;
  //    this.mainCurrState3 = true;
 
  //    this.setSubCurriculumsLevel2(curId);
  //  }
   
//    setSubCurriculumsLevel2(curId : string){

//     this.prevParentId2 = curId;
//     this.subCurriculums2 = [];

//     this.curriculum.level2.forEach((value)=>{
//       if(value.fkCurriculumId === curId){
//         this.subCurriculums2.push(value);
//       }
//     });
//   }
 
//   goBackFunction2(){
//     this.mainCurrState3 = false;
//     this.mainCurrState2 = true;
//     this.mainCurrState = false;
// }

//   goBackFunction(){

//    this.mainCurrState2 = false;
//    this.mainCurrState3 = false;
//    this.mainCurrState = true;
// }

// ViewActivityPlannerForTeacherModal(curId : string){
//   this.config.class = 'modal-md';
//   const modalRef = this.ngbModalService.open( ViewActivityPlannerForTeacherModalComponent,
//     {
//       size : 'lg',
//       scrollable: true,
//       windowClass: 'app-modal-window',
//       keyboard: false
//     });
//   let data = {
//     curId : curId
//   }

//   modalRef.componentInstance.fromParent = data;
//     modalRef.result.then((result) => {
//     }, (reason) => {
//     });

//   modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
//   });    
// }

  // getCompCurrListBySubId(){
  //       this.curService.getCompCurrListBySubId(this.subId).subscribe(
  //         data=>{
  //           this.CompCurrListBySubId = data;            
  //         });
  // }

  // getActivityPlannerListBySubId(){
  //     this.activityPlannerService.getActivityPlannerListBySubId(this.subId).subscribe(
  //       data=>{
  //         this.ActivityPlannerList = data;
          
  //         this.activityPlannerListWithSafeUrl.pop();

  //         for(var index in data){

  //           this.activityPlannerListWithSafeUrl.push( { 'activityPlannerId' : data[index].activityPlannerId,
  //                                                       'activityPlannerName' : data[index].activityPlannerName,
  //                                                       'activityPlannerDescription' : data[index].activityPlannerDescription,
  //                                                       'activityPlannerOrder' : data[index].activityPlannerOrder,
  //                                                       'activityPlannerStatus' : data[index].activityPlannerStatus,
  //                                                       'fkSubjectId' : data[index].fkSubjectId,
  //                                                       'activityPlannerImage' : data[index].activityPlannerImage,
  //                                                       'safeUrl' : this.xyz
  //                                                     } );
  //       }

  //       this.activityPlannerListWithSafeUrl.forEach( (value) => {
          
  //         this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
  //           data=>{
  //             this.xyz = data;
  //             this.abc = URL.createObjectURL(data);
  //             this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

  //             value.safeUrl = this.xyz;
  //           });

  //       }); 


  //       })
  // }
 
  // authorized
  addNewActPlnr(){
    this.actionCollapse = false;
    this.addNewActivityPlanner(this.subId);
  }
  addNewActivityPlanner(subId : string){ 
        this.config.class = 'modal-md';
        const modalRef = this.ngbModalService.open(AddActivityPlannerComponent,
          {
            size : 'lg', 
            scrollable: true,
            windowClass: 'app-modal-window',
            keyboard: false
          });
      
      let data = {
        subId: subId,
        oa_Assignment : this.officeObject.oa_Assignment ,
        oa_ParentFeedback :  this.officeObject.oa_ParentFeedback
        }

      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
          this.getDetails(receivedEntry);
        }); 
    }



//  updateactivityPlannerStatus(activityPlannerId : string){
//      this.activityPlannerService.activityPlannerStatusUpdate(activityPlannerId).subscribe(
//        data=>{
//          this.toaster.success("Activity Planner Status Updated Successfully");
//            });
//   }
 

// authorized
 
editactivityPlanner(activityPlannerId : string){
  this.isOpen = false;
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(UpdateActivityPlannerComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
        keyboard: false
        });

      let data = {
        activityPlannerId: activityPlannerId 
        }

      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityPlanner) => {
      this.getDetails(this.currentCurriculum);
    }); 
  }

// authorized
  addActivityQuestionsModal(activityPlannerId : string){
    this.isOpen = false;

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddActivityQuestionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: activityPlannerId 
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityPlanner) => {
  }); 
  }

  // authorized
  viewActivityQuestionsModal(activityPlannerId : string){
    this.isOpen = false;

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewActivityQuestionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });

    let data = {
      activityPlannerId: activityPlannerId,
      objective : true,
      questionSkill : true,
      oa_ActQuesSkillMapping : this.officeObject.oa_ActQuesSkillMapping
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : ActivityQuestions) => {
  }); 
  }

  // authorized
  addNewCurr(){
    this.addNewCurr2(this.subId);
  }
    addNewCurr2(subId : string){ 
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddMainCurrComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
  
        let data = {
          subId: subId 
          }
  
        modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Curriculum) => {
        // this.getCompCurrListBySubId();
        this.createFirstCurriculum = false;
        this.getCurriculum();
      }); 
    }

    // authorized
    addSubCurr(curId : string){
      this.actionCollapse = false;
      this.addSubCurr2(curId, this.subId)
    }
    addSubCurr2(curId : string, subId : string){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(AddMainCurrComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      
    let data = {
      subId: subId ,
      curId: curId
      }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Curriculum) => {
     this.getCurriculum();
     
      });
          
        }


       // authorized 
    editCurriculum(curId : string){
      this.actionCollapse = false;
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(UpdateMainCurrComponent,
        {
          size : 'lg', 
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });

        let data = {
          curId: curId
          }

        modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          })

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        this.getCurriculum();
        this.getDetails(receivedEntry);
      });

  }

  goToHomePage(){
    this.router.navigate(['/dashboard/curriculum-admin']);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

    
   // authorized
  addVideoModal(activityPlannerId : any){
    this.isOpen = false;
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddActivityVideoComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      activityPlannerId : activityPlannerId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getDetails(this.currentCurriculum);
    });    
  }


   // authorized
  editVideo(videoId : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditActivityVideoComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      videoId : videoId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getDetails(this.currentCurriculum);
    });    
  }

  generateQuestions(activityPlannerId : any, description : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(GenerateActivityQuestionsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      activityPlannerId : activityPlannerId,
      description : description
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  } 
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    // clickMethod(activityName: string) {
    //   if(confirm("Are you sure to deactivate " + activityName)) {
    //     this.toaster.success('yes')
    //   }
    // }

    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }
    
    open(content: any) {
      this.actionCollapse = false
      this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
      
    }

    deactivateActivityPlanner(){
      
      this.activityPlannerService.deactivateActivityPlanner(this.deactivateApId).subscribe(
        data=>{
        this.getDetails(this.currentCurriculum);
        this.toaster.success('Activity deactivated successfully');
        });
    }

    deactivateAssignment(){
      
      this.activityPlannerService.deactivateAssignment(this.deactivateApId).subscribe(
        data=>{
        this.getDetails(this.currentCurriculum);
        this.toaster.success('Assignment deactivated successfully');
        });
    }

    deactivateParentPlanner(){

      this.activityPlannerService.deactivateParentPlanner(this.deactivateApId).subscribe(
        data=>{
        this.getDetails(this.currentCurriculum);
        this.toaster.success('Assignment deactivated successfully');
        });
    
    }
 
    deactivateCurriculum(){
    
      this.curService.deactivateCurriculum(this.deactivateApId).subscribe(
        data=>{
          this.infoState = false;
          this.getCurriculum();
          this.toaster.success('Curriculum deactivated successfully');
        });
    
    }
 
     
}
function viewActivityVideoModal(viewActivityVideoModal: any, arg1: { size: string; scrollable: true; windowClass: string; keyboard: false; }) {
  throw new Error('Function not implemented.');
}

function DialogElementsExampleDialog(DialogElementsExampleDialog: any) {
  throw new Error('Function not implemented.');
}

