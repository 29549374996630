import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { ViewInvoiceDetailsComponent } from 'src/app/crm/invoice/view-invoice-details/view-invoice-details.component';
import { CreatePaymentComponent } from 'src/app/crm/payment/create-payment/create-payment.component';
import { UpdatePaymentComponent } from 'src/app/crm/payment/update-payment/update-payment.component';
import { ViewPaymentDetailsComponent } from 'src/app/crm/payment/view-payment-details/view-payment-details.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { Payment } from 'src/app/crmClasses/payment';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { InvoiceService } from 'src/app/service/invoice.service';
import { PaymentService } from 'src/app/service/payment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crm-payment-dashboard',
  templateUrl: './crm-payment-dashboard.component.html',
  styleUrls: ['./crm-payment-dashboard.component.scss']
})
export class CrmPaymentDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";

  paymentLoader = false;

  paymentLoaderMessage!: string;
  closeResult!: string;
  paymentExists!: boolean;

  paymentOptions : any = {};

  userName !: string;
  userId!: string;

  payments: Payment[] = [];
  loggedInUser: any;
  loggedUser: any;
  navbarTitle: any;
  officeId : any;

  payment : Payment = new Payment();
  status : any = "Today's Payments"

  showSpinner = false;
  showInvoices = false;

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private invoiceService : InvoiceService,
              private datePipe : DatePipe,
              private toastr : ToastrService,
              private ccdetailsService:CompCustDetailsService,
              private paymentService: PaymentService,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    invoices: InvoiceList[] = [];

    todaysPaymentCount = 0;
    monthsPaymentCount = 0;
    totalOutstandingAmt = 0;

  ngOnInit(): void {
    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    this.officeId = this.loggedInUser.offices[0].id;
    
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getTodaysMonthPayments();

    this.paymentOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getTodaysMonthPayments(){
    this.showSpinner = true;
    this.paymentService.getCurrentMonthPayments(environment.apiUrl +  "payment/getCurrentMonthPayments").then(data => {
      this.payment= data;   
      this.payments =  this.payment.todaysPayments;
      this.payments.forEach((payment)=>{
        payment.paymentDate = this.datePipe.transform(payment.paymentDate, "dd-MM-yyyy");
      });
      this.status = "Today's Payments";
      this.dataSource = this.payments;  
      this.todaysPaymentCount = this.payment.todaysPayments.length;
      this.monthsPaymentCount = this.payment.monthsPayments.length;
      this.showSpinner = false;
      this.paymentExists = true;
    });
  }

  showCurrentMonthsPayments(){
    this.showSpinner = true;
    this.paymentService.getCurrentMonthPayments(environment.apiUrl +  "payment/getCurrentMonthPayments").then(data => {
      this.payment= data;   
      this.payments =  this.payment.monthsPayments;
      this.payments.forEach((payment)=>{
        payment.paymentDate = this.datePipe.transform(payment.paymentDate, "dd-MM-yyyy");
      });
      this.status = "Payments of current month";
      this.dataSource = this.payments;  
      this.showSpinner = false;
      this.paymentExists = true;
    });
  }

  getpaymentsByPeriod(){
    this.showSpinner = true;
    this.paymentService.getPaymentsByPeriod(environment.apiUrl +  "payment/getPaymentsByPeriod", this.payment).then(
      data => {
      this.payment= data;   
      this.payments = this.payment.monthsPayments;
      this.payments.forEach((payment)=>{
        payment.paymentDate = this.datePipe.transform(payment.paymentDate, "dd-MM-yyyy");
      });
      this.status = "Payments from " + this.datePipe.transform(new Date(this.payment.fromDate), "dd-MM-yyyy") + " to " + this.datePipe.transform(new Date(this.payment.toDate), "dd-MM-yyyy");
      this.dataSource = this.payments;  
      this.showSpinner = false;
      this.paymentExists = true;
    });
  }

  getOutstandingInvoiceListByOfficeId(){
    this.showSpinner = true;
    this.totalOutstandingAmt = 0;
    this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getOutstandingInvoiceListByOfficeId").then(
      data => {
        this.invoices= data;
        this.invoices.forEach((inv)=>{
          inv.invoiceDate = this.datePipe.transform(inv.invoiceDate, "dd-MM-yyyy");
          this.totalOutstandingAmt = this.totalOutstandingAmt + inv.outstandingAmount;
        })
        this.dataSourceInvoices = this.invoices;
        this.showSpinner = false;
        this.showInvoices = true;
      });
} 
  
  dataSource = this.payments;
    exportArray() {
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
        Customer: x.customerName,
        Payment_Method: x.paymentMethod,
        Date: x.paymentDate,
        Amount: x.paymentAmount
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
    }

    dataSourceInvoices = this.invoices;
    exportArrayInvoices() {
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSourceInvoices.map(x => ({
       InvoiceNumber : x.invoiceNo,
        Customer: x.customerName,
        Date: x.invoiceDate,
        GrandTotal: x.grandTotal
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
    }

  addNewPayment(invoiceId : any, firstName : any, lastName : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(CreatePaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        invoiceId : invoiceId,
        customerName : firstName + " " + lastName
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {});

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Payment) => {
       this.getTodaysMonthPayments();
      });  
  }

 // authorized
  editPayment(paymentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdatePaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        paymentId: paymentId
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

          modalRef.componentInstance.passEntry.subscribe((receivedEntry : Payment) => {
            this.getTodaysMonthPayments();
          });  
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    this.ccdetails = [];
    this.searchInput = true;
    this.searchValue = null;
    this.searchInputPayment = true;
    this.searchValuePayment = null;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  updatePaymentStatus(paymentId : string){

    this.paymentService.changeStatus(environment.apiUrl + "payment/statusUpdate/" + paymentId).then( data =>{
      this.getTodaysMonthPayments();
  });
}

viewInvoiceDetailsModal(invoiceId : string){

  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(ViewInvoiceDetailsComponent,
    {
      size : 'lg',
      scrollable: true,
      windowClass : 'app-modal-window',
      keyboard: false
    });
    

  let data = {
    invoiceId: invoiceId 
      }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
 
}

  viewPaymentDetailsModal(paymentId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewPaymentDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        paymentId: paymentId 
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

  }
  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
  }

  searchInput = true;
  searchValue : any = null;
  ccdetails: CompleteCustomerDetails[] = [];

  searchCustomers(){
    this.ccdetailsService.getOutstandingCustomerInvoices(environment.apiUrl + "crmCustAdd/getOutstandingCustomerInvoices/" + this.searchValue).then(data => {
      this.ccdetails= data;
      
      if(this.ccdetails.length !== 0 && this.ccdetails !== undefined){
        this.searchInput = false;
      }else{
        this.searchInput = true;
        this.toastr.error("Customer not found !")
      }
    });
  }

  searchInputPayment = true;
  searchValuePayment : any = null;
  ccdetails2: CompleteCustomerDetails[] = [];
  searchCustomerPayments(){
    this.ccdetailsService.getOutstandingCustomerInvoices(environment.apiUrl + "crmCustAdd/searchPaymentsByCustomerDetails/" + this.searchValuePayment).then(
      data => {
      this.ccdetails2= data;
            
      if(this.ccdetails2.length !== 0 && this.ccdetails2 !== undefined){
        this.searchInputPayment = false;
      }else{
        this.searchInputPayment = true;
        this.toastr.error("Customer Not found !")
      }
    });
  }

  getPaymentsByCustomerId(id:any){
    this.payments = [];
    this.showSpinner = true;
    this.ccdetails2.forEach((cust)=>{
      if(cust.customerId===id){
        this.payments = cust.payments;
        this.payments.forEach((payment)=>{
          payment.paymentDate = this.datePipe.transform(payment.paymentDate, "dd-MM-yyyy");
        });
        this.status = "Payments of " + cust.firstName + " " + cust.lastName;
        this.showSpinner = false;
      }
    });
  }
} 
