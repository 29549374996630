// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private sharedDataService: SharedDataService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		const loggedUser = localStorage.getItem('loggedUser');
		if (loggedUser) {
			this.sharedDataService.setLoggedInUserDtls(loggedUser);

			let loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());

			let currentActiveRole = loggedInUser.currentActiveRole;
			

			if (route.data.roles && route.data.roles.indexOf(loggedInUser.currentActiveRole) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/InvalidUrl']);
                return false;
            }

			return true;
		} else {
			this.router.navigateByUrl('/auth/login');
			return false;
		}
	}
}
