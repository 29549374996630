export class AddAdmin {

    userId!: string;
    firstName!: string;
	lastName!: string;
	password!: string;
	phoneNumber!: string;
	email!: string;
	dateOfBirth!: string;
	gender!: string;
 
	countryDialCode : any;
	username : any;
    addressId!: string;
	addressLine1!: string;
	addressLine2!: string;
	city!: string;
	country!: string;
	state!: string;
    zipCode!: string;
    fkUserId!: string;

    roleId!: string;
	officeId!: string;
    
}
