export class Product {

    productId!: string;
	productName!: string;
	productPrice!: DoubleRange;
	productDescription!: string;
	productStatus!: boolean;
	defaultQuantity : any;
	fkOfficeId!: string;

	safeUrl : any;

	productImage : any
	totalQty : any = 0;
	totalAlternateQty : any = 0;
}
 