
<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
    <img style="margin: 10px"
    src="/assets/images/courseManagementSystem.svg"
        class="me-2"
        height="30" 
        alt=""
        loading="lazy"
      /> 
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Super Admin Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="open(logOutModal)" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  <ng-template #logOutModal let-modal>

    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" 
      id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
      <h2 style="text-align: center;color: black;">Are you sure you want to logout ?</h2>
  
    </div> 
    <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-danger" (click)="logOut();modal.dismiss('Cross click')">Logout</button>
  </div>
  </ng-template>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

<div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let office of offices">
  
          <mat-card class="example-card mat-elevation-z12">
           
                <mat-card-title>{{office.officeName}}</mat-card-title>
                <mat-card-subtitle>{{office.officeLocation}}</mat-card-subtitle>
  
            <mat-card-content>
              <p>
                <button class="btn btn-info" (click)="exploreOffice(office.id)" mat-button>Explore</button>
              </p>
            </mat-card-content>
            
          </mat-card>
        
      </div>
    </div>
  </div>



