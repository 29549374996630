<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Language Variations</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      
<button (click)="createLanguageVariationForResponse();"
 style="width: auto;float: right;" class="btn btn-info">Create New Variation</button>
<br>
<br>
        <table
        class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
        <thead class="tableheader" >
          <tr>
            <th>Language</th>
            <th>Response</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let langRes of botLangResponses">
            <td class="td-height">{{langRes.fkBotLanguage}}</td>
            <td class="td-height">
                {{langRes.langResponse}}
                <a style="cursor:pointer; margin-left: 2%;"
                (click)="edit(langRes);">
                    <img src="/assets/images/icons/edit.svg" width="20" height="20" alt="" loading="lazy">
               </a>
            </td>
            

            

          </tr>
        </tbody>
      </table>

</div>
</div>

