import { BotConvoLog } from "./bot-convo-log";
import { BotCustomerUser } from "./bot-customer-user";
import { Message } from "./message";

export class BotUser {
  userId: any;
  firstName!: string;
  lastName!: string;
  password!: string;
  phoneNumber!: string;
  email!: string;
  dateOfBirth!: string;
  gender!: string;
  whatsappNumber: any;
  addressId!: string;
  addressLine1!: string;
  addressLine2!: string;

  countryDialCode: any;
  userIdentifier: any;
  name: any;
  uroId: any;
  city!: string;
  country!: string;
  state!: string;
  zipCode!: string;
  fkUserId!: string;

  roleId!: string;
  officeId!: string;
  roleName: any;
  userExists: any;
  botUserExists: any;
  activeStatus: any;
  alternatePhone: any;
  customVar1: any;
  customVar2: any;

  convoMode: any;
  fkUroIdManualChat: any;
  agentName: any;

  chatWindowOpen: any;
  botConvoLog: BotConvoLog = new BotConvoLog();
  messages: Message[] = [];
  users: BotUser[] = [];
  botUsersAssigned: BotCustomerUser[] = [];
  agentUroId: any;
  newAutomatedChatMessagesUsersCount = 0;
  newManualChatMessagesUsersCount = 0;
  automatedChatUsersCount = 0;
  manualChatUsersCount = 0;

  manualChatAccess: any;
  manualChatAgentResponse: any;
  userVisible: any;
  manualChatBotUserNames: any;
  customerServiceWindowOpen: any;
  filterVar: any;
  pwd: any;

  footerText: any;
  bodyText: any;
  numberOfAgentsAllowed: any;
  userIds: any[] = [];
  crmAccess: any;
}
