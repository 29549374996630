import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { TimeoutScreenComponent } from 'src/app/auth/timeout-screen/timeout-screen.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { StudentService } from 'src/app/service/student.service';
import { DestroyService } from 'src/app/services/destroyService';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class StudentDashboardComponent extends DestroyService implements OnInit {
  cardsExist = false;
  loggedInUser;
  showSpinner : boolean = true;
  navbarTitle : any;
  userName: string = 'User';
  theme: string = '';
  userId!: string;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };
  currentRole = "STUDENT";

  public screenWidth: any;
  public screenHeight: any;

  classCards : any[] = [{
                          classDescription: '',
                          classId: '',
                          className: '',
                          courseName: '',
                          fkOfficeId: '',
                          fkProductIdOfClass:  '',
                          studentId:  '',
                          userRoleOfficeId:  ''
                       }]

  constructor(private sharedDataService: SharedDataService, 
    private ngbModalService: NgbModal,
              public router: Router,
              private logoutService : LogoutService,
              private modalService: BsModalService,
              private studentService : StudentService,
              private bnIdle: BnNgIdleService,
              private toastr : ToastrService,
              public bsModalRef: BsModalRef) {
              super();
            this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
            this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
            this.theme = this.sharedDataService.getCardOfficeTheme();
            this.userId = this.loggedInUser.userId;

  }

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.userId = this.loggedInUser.userId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;

    this.studentService.getStudentClassesByUserId(this.userId).subscribe(
      data=>{
        this.classCards = data;
        this.cardsExist = true;
        setTimeout(()=>{                           //timer
          this.showSpinner = false;
      },1200);
      });

  }

  explore(productId : any, studentId : any, classId : any){
    this.router.navigate(['dashboard/student-subjects',productId, studentId, classId]);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
 
  getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

    goBack() {
      this.router.navigate(['/dashboard/user']).then();
    }

    reloadPage() {
      window.location.reload();
    }

    switchDashboard(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
        {
          size : 'sm',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        userId : this.userId,
        currentRole : this.currentRole
  
      }
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      });    
    }

}
