import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-target-based-invoices',
  templateUrl: './view-target-based-invoices.component.html',
  styleUrls: ['./view-target-based-invoices.component.scss']
})
export class ViewTargetBasedInvoicesComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser : any;
  theme : any;
  invoices: InvoiceList[] = [];
  achievedPercentage : any;

  constructor(private invoiceService : InvoiceService, 
              private activeModal : NgbActiveModal,
              private sharedDataService: SharedDataService,
              private datePipe : DatePipe

            ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.fromParent.targetAchieved =  Math.round((this.fromParent.targetAchieved + Number.EPSILON) * 100) / 100;

    this.achievedPercentage = this.fromParent.targetAchieved / this.fromParent.targetValue;
    this.achievedPercentage = this.achievedPercentage * 100;
    this.achievedPercentage =  Math.round((this.achievedPercentage + Number.EPSILON) * 100) / 100

    this.getTargetPeriodBasedInvoices();

  }

  //authorized
  getTargetPeriodBasedInvoices(){
    this.invoiceService.getTargetPeriodBasedInvoices(environment.apiUrl + "invoice/getTargetPeriodBasedInvoices/" + this.fromParent.targetId).then(
      data=>{        
        this.invoices = data;
        this.invoices.forEach((inv)=>{

          inv.invoiceDate = this.datePipe.transform(inv.invoiceDate, 'dd-MM-yyyy');
          inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
          inv.totalAmount =  Math.round((inv.totalAmount + Number.EPSILON) * 100) / 100;

          if(inv.outstandingAmount === 0){
            inv.incentiveEligibity = "Considered"
          }else{
            inv.incentiveEligibity = inv.outstandingAmount;
          }
        });
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 


  closeUpdateModal(){
    this.activeModal.close();
  }
}
