<div class="modal-header">
    <h4 class="modal-title pull-left">Add Results</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
  
<form #aplnrForm="ngForm"> 

  <div class="form-group row">

    <div class="col-md-6">
      <label class="required" for="studentName">Student</label>
      <input class="form-control" type="text" name="studentName" id="studentName" [(ngModel)]="idObject.selectedStudentName">
    </div>


    <div class="col-md-6">
      <label class="required" for="fkSkillId">Skill</label> <br>
          <select #skill="ngModel" required class="form-control" [(ngModel)]="result.fkSkillId" id="fkSkillId" name="fkSkillId">
            <option  value="" disabled >Choose Skill</option>
            <option  *ngFor="let skill of skillList"  [ngValue]="skill.skillId">{{skill.skillName}}</option>
          </select>
          <div class="alert alert-danger" *ngIf="skill.touched && !skill.valid">Select Skill</div>
      </div>

      <!-- <div class="col-md-6">
        <label class="required" for="fkSkillId">Class</label> <br>
            <select #skill="ngModel" required class="form-control" [(ngModel)]="result.fkStudentId" id="fkSkillId" name="fkSkillId">
               <option  value="" disabled >Choose Class</option>
              <option  *ngFor="let class of classCards"  [ngValue]="class.studentId">{{class.className}}</option>
            </select>
            <div class="alert alert-danger" *ngIf="skill.touched && !skill.valid">Select class</div>
        </div> -->

  </div>

 

  <div class="form-group row">

    <div class="col-md-6">
      <label class="required" for="skillScore">Score</label>
      <input  maxlength="250" class="form-control" type="text" name="skillScore" id="skillScore" [(ngModel)]="result.skillScore">
    </div>

      <div class="col-md-6">
        <label class="required" for="resultHistory">History</label>
        <input  maxlength="250" class="form-control" type="text" name="resultHistory" id="resultHistory" [(ngModel)]="result.resultHistory">
      </div>

  </div>

  <div class="form-group row">

    <div class="col-md-12">
      <label class="required" for="resultSuggestion">Suggestion</label>
      <textarea  maxlength="5000" class="form-control" type="text" name="resultSuggestion" id="resultSuggestion" [(ngModel)]="result.resultSuggestion"></textarea>
    </div>

  </div>

</form>

      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked" [disabled]="!aplnrForm.valid"  type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
  </div>