
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a  onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-arrow-left"></i> Go back </a>
        </li>
        
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

 
   

    
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" style="cursor: pointer;color: #0275d8;" (click)="goBack();" aria-current="page"><i class="fa fa-home" aria-hidden="true"></i> Home </li>
    <li style="cursor: pointer;" style="cursor: pointer;color: #0275d8;" class="breadcrumb-item" onclick="history.back()" aria-current="page"> Course </li>
    <li style="cursor: pointer;" style="cursor: pointer;color: #0275d8;" class="breadcrumb-item" onclick="history.back()" aria-current="page"> Subject </li>
    <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page"> Assignment </li>

</ol>
</nav>


<div>




<div class="content" style="margin: 2.5%;" >
 
  <div class="card-group row">

  <div class="col-lg-6" style="border-radius: 25px;">

    <div  class="card mat-elevation-z12">

  <div class="header" [style.background-color]='getColor()'>

    <mat-icon onclick="history.back()" style="margin-left: 2%; float: left; cursor: pointer; color: white; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>


    <h3>Select Assignment</h3>
      </div>

<br>

      <div class="content" style="padding: 1em;" *ngIf="studentAssignmentsExist === true">
        <h3 style="text-align: center;color: black;" *ngIf="allAssignmentList === undefined || allAssignmentList.length == 0">No Assignments to show !</h3>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">

         


          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let card of allAssignmentList">
            <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
              <div style="float: left;" *ngIf="card.selectedState === true">
                <img style="border-radius: 50%;" alt="" height="30" width="30" loading="lazy" src="/assets/images/icons/selected.png" >
              </div> 
  
  <br>
  <br>
              <div style="width: 100%;text-align: center;">
                <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
              </div>
              <br>
              <div style="text-align: center;">
                <h2>{{card.activityPlannerName}} </h2>
                <br>
                <button (click)="select(card.activityPlannerId)" class="btn btn-info">Select</button>

                <br>
                <a style="color: rgb(26, 116, 26);font-weight: bold;" *ngIf="card.activityPlannerCompletionStatus === true">Completed
                  <img matTooltip="Activity Complete" style="margin-left: 2%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
                </a>
              </div>
              
            <br>
              <div style="text-align: center;">
                <p>
                  {{card.activityPlannerDescription}}
                </p>
              </div>
             
            </mat-card>
          </div>
        </div>
        </div>  
      </div>
</div>

<br>
  <br>
  <br>
  <br>



     
<div style="margin-top: 1em;" class="col-lg-6 card mat-elevation-z12 Activity-Card" [style.display]="activityState ? 'block' : 'none'"  style="border-radius: 20px;">
      
 
<br>
<br>
<br>
  <div style="text-align: center;width: 100%;">
    
<img class="Activity-Image" [src]="safeUrl" height="75" width="75" alt="Card image cap">
 
  </div>
  <br>

  <div  style="text-align: center; width: 100%;">
    <h4 class="card-title">{{info.activityPlannerName}}</h4>
  
    <a style="color: rgb(26, 116, 26); font-weight: bold;margin-bottom : 5%" *ngIf="info.studentActivityPlannerCompletionStatus === '1' ">Submitted
      <img matTooltip="Activity Complete" style="margin-left: 1%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20">
    </a>
<br>
    <p style="margin-top: 5%;" class="card-text">{{info.activityPlannerDescription}}</p>
  </div>
  <div class="card-body">
     
  <div *ngIf="collapsedState2">
   
    
<h2 style="color: black;" *ngIf="this.info.activityVideoLinkList === undefined|| this.info.activityVideoLinkList.length === 0">No videos to display !</h2>
      <mat-vertical-stepper>

        <div class="stepper-class">

        <mat-step [editable]="true" [completed]="true"  *ngFor="let video of info.activityVideoLinkList">
          <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
            <p></p>

            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
            </div>


              <br>
    
              {{video.actVideoLinkDescription}}
              <br>
              
        </mat-step>
        
    </div>

    
      </mat-vertical-stepper>

      
  
    </div>
    
    <!-- <button (click)="submitAssignment();" *ngIf="info.studentActivityPlannerCompletionStatus === '0' " class="btn btn-info" style="margin: 3%;">Submit</button> -->
    <button (click)="submitAssignment();" class="btn btn-info" style="margin: 3%;">Submit</button>

    <br>
  <button mat-button color="primary" (click)="collapseActivityCardContent2(collapsedState2)">
      {{collapsedState2 ? 'Close' : 'More'}}</button>
  </div> 
</div>

  </div>

</div>
</div>

<br>
<br>
<br>
 
</div>
<br>
  <br>
  <br>
  <br>
  <br>