import { ActivityPlanner } from "./activity-planner";

export class StudentClass {

    studentId: any;
    fkClass: any;
    fkUserRoleOffice: any;
    studentStatus: any;
    studentName: any;
    studentImage : any;
    studentActivityHistoryIndicator : any;
    studentAttempts : any;
    safeStudentUrl : any;

    activityPlanners : ActivityPlanner[] = [];

    students : StudentClass[] = [];
    activityPlannersForDropdown : ActivityPlanner[] = [];
    activityPlannerIds : string[] = [];

}
