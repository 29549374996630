<!-- MODAL CLOSE BUTTON -->
<div  [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Payment For : {{fromParent.customerName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
   <!-- MODAL CLOSE BUTTON -->

  

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #paymentForm="ngForm">

 

 


        <div class="form-group row">

            <div class="col-md-4">
                <label class="required" for="paymentMethod">Method</label>
                <input #method="ngModel" required class="form-control" type="text" name="paymentMethod" id="paymentMethod" [(ngModel)]="payment.paymentMethod">
                <div class="alert alert-danger" *ngIf="method.touched && !method.valid">Provide Payment Method</div>
            </div>

            <div class="col-md-4">
            <label class="required" for="paymentDate">Date</label>
            <input #date="ngModel" required class="form-control" type="Date" name="paymentDate" id="paymentDate" [(ngModel)]="payment.paymentDate">
            <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Enter Amount</div>
        </div>

        <div class="col-md-4">
            <label class="required" for="paymentAmount">Amount</label>
            <input #amount="ngModel" required class="form-control" type="number" name="paymentAmount" id="paymentAmount" [(ngModel)]="payment.paymentAmount">
            <div class="alert alert-danger" *ngIf="amount.touched && !amount.valid">Enter Amount</div>
        </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6">
            <label for="paymentCompletion">Payment Completion</label>
            <input class="form-control" type="text" name="paymentCompletion" id="paymentCompletion" [(ngModel)]="payment.paymentCompletion">
        </div>

        <div class="col-md-6">
            <label for="paymentDue">Amount Due</label>
            <input class="form-control" type="number" name="paymentDue" id="paymentDue" [(ngModel)]="payment.paymentDue">
        </div>
        </div>

        

        <div>
            <label for="paymentRemarks">Remarks</label>
            <textarea  #remarks="ngModel" maxlength="244"  class="form-control" type="text" name="paymentRemarks" id="paymentRemarks" [(ngModel)]="payment.paymentRemarks"></textarea>
            <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
        </div>

       

        
    </form>
</div>
</div>
</div>

<div class="modal-footer">
    <button  [disabled]="clicked"  [disabled]="!paymentForm.valid" type="submit" class="btn btn-sm btn-primary float-right"  (click)="onSubmit(); clicked = true;">Save</button>
</div> 