
   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Product Quantities</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">

       
<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
  <thead class="tableheader">
    <tr>
      <th>Product</th>
      <th>  Quantity</th>
      <th> Alternate Quantity</th>
    </tr>
  </thead>
  <tbody>
    <tr class="td-height" *ngFor="let ccd of products">
      <td class="td-height">{{ccd.productName}}</td>
      <td class="td-height" style="text-align: right;">{{ccd.totalQty}}</td>

      <td class="td-height" style="text-align: right;">{{ccd.totalAlternateQty}}</td>
    </tr> 

    <tr>
      <td class="td-height" style="font-weight: bold;text-align: right;">Total</td>
      <td class="td-height" style="font-weight: bold;text-align: right;">{{totalQty}}</td>

      <td class="td-height" style="font-weight: bold;text-align: right;">{{totalAltQty}}</td>
    </tr>   

  </tbody>
</table>
  </div>
      </div>
      </div>