export class BotMedia {
  botMediaId: any;
  fkOfficeId: any;
  mediaName: any;
  mediaType: any;
  timestamp: any;
  wcaMediaId: any;
  activeStatus: any;
  title: any;
  description: any;
  data: any;
  mime_type: any;
  receiverBotUserId: any;
  receiverBotUsername: any;
  reuploadStatus: any;
  list: BotMedia[] = [];
  fileHandle: any;
}
