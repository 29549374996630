import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { Shipment } from 'src/app/crmClasses/shipment';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { TicketSupportService } from 'src/app/BIZ_CRM_TICKET_SUPPORT/services/ticket-support.service';
import { TicketSupportDefinition } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-support-definition';
import { TicketActual } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-actual';
import { ViewFormResponsesComponent } from 'src/app/BIZ_CRM_FEEDBACK/modals/form-response/view-form-responses/view-form-responses.component';

@Component({
  selector: 'app-external-ticket-support-dashboard',
  templateUrl: './external-ticket-support-dashboard.component.html',
  styleUrls: ['./external-ticket-support-dashboard.component.scss']
})

export class ExternalTicketSupportDashboardComponent implements OnInit {
  
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  officeId!: string;

  navbarTitle : any;
 
  constructor(
    private router : Router,private route : ActivatedRoute,
    private ticketService : TicketSupportService,
    private datePipe : DatePipe,
    private ngbModalService: NgbModal,
    private sharedDataService : SharedDataService ) {
}

color: ThemePalette = 'primary';
mode: ProgressSpinnerMode = 'determinate';
value = 50;

color0: ThemePalette = 'warn';
mode0: ProgressSpinnerMode = 'determinate';
value0 = 50;

color1: ThemePalette = 'accent';
mode1: ProgressSpinnerMode = 'determinate';
value1 = 50;

ticketSupportDefinitions : TicketSupportDefinition[] = [];
ticketActuals : TicketActual[] = [];

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.officeId = this.route.snapshot.params['officeId'];

    this.getTicketSupportDefs();

  }

  getTicketSupportDefs(){
    this.ticketService.getTicketSupportDefinitionsByOfficeId(environment.apiUrl + "ticketSupport/getExternalTicketSupportDefsByOfficeId/" + this.officeId).then(
      data=>{        
        this.ticketSupportDefinitions = data;
      });
  }

  raiseTicket(ticketSupportDef : TicketSupportDefinition){
    this.router.navigate(['ticketSupport/ext-ticket-support/raise-ticket/form', ticketSupportDef.ticketSupportDefinitionId, this.officeId]);
  }
 

}
