import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UserSub } from 'src/app/models/subscriptionDTOS';
import { Product } from 'src/app/crmClasses/product';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { OfficesService } from 'src/app/services/office/offices.service';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { SuperAdminUserService } from 'src/app/services/user/super-admin-user.service';
import { AddBcrmUserComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_user/add-bcrm-user/add-bcrm-user.component';
import { EditBcrmUserComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_user/edit-bcrm-user/edit-bcrm-user.component';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { AssignBcrmRoleComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_roles/assign-bcrm-role/assign-bcrm-role.component';
import { AssignReportingHeadComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_roles/assign-reporting-head/assign-reporting-head.component';
import { ResetPasswordComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_user/reset-password/reset-password.component';
import { OptionsModalComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_options/options-modal/options-modal.component';
import { NotificationSetting } from 'src/app/NOTIFICATION/classes/notification-setting';
import { NotificationMaster } from 'src/app/NOTIFICATION/classes/notification-master';
import { EditNotificationDetailsComponent } from 'src/app/NOTIFICATION/modals/notificationDetails/edit-notification-details/edit-notification-details.component';
import { NotificationService } from 'src/app/NOTIFICATION/services/notification.service';
import { CreateNotificationSettingComponent } from 'src/app/NOTIFICATION/modals/notificationSetting/create-notification-setting/create-notification-setting.component';
import { EditNotificationSettingComponent } from '../../modals/notificationSetting/edit-notification-setting/edit-notification-setting.component';
import { CreateNotificationMasterComponent } from '../../modals/notificationMaster/create-notification-master/create-notification-master.component';
import { EditNotificationMasterComponent } from '../../modals/notificationMaster/edit-notification-master/edit-notification-master.component';
import { NotificationActual } from '../../classes/notification-actual';

@Component({
  selector: 'app-office-notification-dashboard',
  templateUrl: './office-notification-dashboard.component.html',
  styleUrls: ['./office-notification-dashboard.component.scss']
})
export class OfficeNotificationDashboardComponent implements OnInit {

  showSpinner = true;
  currentRole = "BCRM_OFFICE_ADMIN";
  navbarTitle : any;
  users: any[] = [];
  userName = '';
  userId !: string;
  officeAdminUsers: any;
  usersLoading = false;
  superAdminUsers: any;
  products: any;
  userOfficeId: any;
  productsLoading = true;
  loggedInUser;
  usersExists = false;
  productOptions: any;
  userOptions: any;
  productLoader = false;
  closeResult! : string;
  productLoaderMessage = ''
  subscriptionLoader = false;
  statusUpdateUserId : any;
  subscriptionLoaderMessage = '';
  userLoader = false;
  userLoaderMessage = '';

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 
  
  officeObject : any = {
                            activeStatus: '',
                            backgroundColor: '',
                            id:'',
                            logo: '',
                            oa_ParentMentor:'',
                            oa_SubAdmin: '',
                            oa_CRMAdmin! : '',
                            oa_CourseAdmin! : '',
                            oa_ClassAdmin! : '',
                            oa_SkillReport! : '',
                            officeLocation: '',
                            officeName: '',
                            themeColour: ''
                          }

  modalRefs: BsModalRef[] = [];
  table: any;
  authToken: string | null = '';
  usersSubscriptions: UserSub[] = [];

  public screenWidth: any;
  public screenHeight: any;

  getProductListByofficeId!: string;
  productListById : Product[] = [];

  displayUrls : any[] = [];
  options = false;

  constructor(
    private ngbModalService: NgbModal, private subscriptionService : SubscriptionService,
    public router: Router, private sharedDataService: SharedDataService,
    private notificationService : NotificationService,
    public bsModalRef: BsModalRef, private toaster: ToastrService,
    private officeService : OfficesService) {
    
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
   
    let count = 0;
    this.loggedInUser.displayUrls.forEach((val : any)=>{
      count ++;
      if(val.roleName === this.currentRole){
        this.displayUrls.push(val);
      }
    });

    if(count === this.loggedInUser.displayUrls.length){
      if(this.displayUrls.length !== 0){
        this.options = true;
      }
    }

    this.authToken = null;
    this.authToken = localStorage.getItem('auth-token'); 

    this.userOfficeId = this.loggedInUser.offices[0].id;

    // authorized
    officeService.getOfficeByIdForOfficeAdmin(environment.apiUrl + "admin/getOfficeByIdForBCRMOfficeAdmin/" + this.userOfficeId).then(res=>{ 
      this.officeObject = res; 
    });

    this.userId = this.loggedInUser.userId ;
      }

    notificationSettings : NotificationSetting[] = [];
    notificationMasters : NotificationMaster[] = [];
    notificationActuals : NotificationActual[] = [];

    notificationSettingsExist = false;
    notificationMastersExist = false;
    
  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;


    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.productOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: false,
      info:false
    };

    this.userOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.getNotificationSettings();
    this.getNotificationMasters();
    this.getNotificationActuals();
    
    setTimeout(()=>{                           
            this.showSpinner = false;
       }, 1500);
     }

     getNotificationSettings(){

      this.notificationService.getNotificationSettingsByOfficeId(environment.apiUrl + "notification/getNotificationSettingsByOfficeId").then(
        data=>{
          this.notificationSettings = data;
          this.notificationSettingsExist = true;

          this.getNotificationMasters();
        });

     }

     getNotificationMasters(){

      this.notificationService.getNotificationMastersByOfficeId(environment.apiUrl + "notification/getNotificationMastersByOfficeId").then(
        data=>{
          this.notificationMasters = data;
          this.notificationMastersExist = true;
        });

    }

    getNotificationActuals(){

      this.notificationService.getNotificationMastersByOfficeId(environment.apiUrl + "notification/getNotificationMastersByOfficeId").then(
        data=>{
          this.notificationMasters = data;
          this.notificationMastersExist = true;
        });

    }

    editNotificationMaster(notificationMaster : any){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(EditNotificationMasterComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
        
  
  let data = {
    notificationMaster: notificationMaster 
      }
  
  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {

        this.getNotificationSettings();
      }); 
    }

    editNotificationSetting(notificationSetting : any){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(EditNotificationSettingComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
        
  
  let data = {
    notificationSetting: notificationSetting 
      }
  
  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {

        this.getNotificationSettings();
      }); 
    }
  

  addNotificationSetting(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateNotificationSettingComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.getNotificationSettings();
    });    
  }

  addNotificationMaster(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( CreateNotificationMasterComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      notificationSettings : this.notificationSettings 
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.getNotificationMasters();
    });    
  }



  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }
  
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }


  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });    
  }
 
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }


}

export function toFormData(formValue: any): FormData {
  const formData = new FormData();
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;


  



 
}
