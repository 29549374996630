<h1>Add Customer</h1>

<div>
   
  <form >

    <div>
      <label for="customerId">Customer Id</label>
      <input placeholder="Enter Id" type="text" name="customerId" id="customerId" [(ngModel)]="customer.customerId">
    <!-- <small *ngIf="requiredField.invalid && (requiredField.dirty || requiredField.touched)"> ID is required</small> -->
    </div>

    <div>
      <label for="firstName">First Name</label>
      <input  placeholder="Enter First Name" type="text" name="firstName" required id="firstName" [(ngModel)]="customer.firstName">
   <!-- <small *ngIf="lengthForField.invalid  && (requiredField.dirty || requiredField.touched)">First name must have minimun 4 characters</small> -->
  </div>


    <div>
      <label for="lastName">Last Name</label>
      <input type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="customer.lastName">
    </div>

    <div>
      <label for="customerGender">Gender</label>
      <input type="text" placeholder="Enter Gender" name="customerGender" id="customerGender" [(ngModel)]="customer.customerGender">
    </div>

    <div>
      <label for="customerDob">Date of birth</label>
      <input type="date" placeholder="Enter Date of birth" name="customerDob" id="customerDob" [(ngModel)]="customer.customerDob">
    </div>
    <!-- <mat-form-field appearance="fill">
      <mat-label>Date of birth</mat-label>
      <input name="customerDob" id="customerDob" [(ngModel)]="customer.customerDob" matInput [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->
    

    <div>
      <label for="customerPhone">Phone</label>
      <input type="text" placeholder="Enter phone number" name="customerPhone" id="customerPhone" [(ngModel)]="customer.customerPhone">
    </div>

    <div>
      <label for="customerAltPhone">Alternate Phone</label>
      <input type="text" placeholder="Enter Alternate phone number" name="customerAltPhone" id="customerAltPhone" [(ngModel)]="customer.customerAltPhone">
    </div>

    <div> 
      <label for="customerEmailId">EmailId</label>
      <input type="text" ngModel email="true" placeholder="Enter Email Id" name="customerEmailId" id="customerEmailId" [(ngModel)]="customer.customerEmailId">
    </div>

    <div>
      <label for="customerStatus">Status</label>
      <input type="text" placeholder="Enter Customer Status" name="customerStatus" id="customerStatus" [(ngModel)]="customer.customerStatus">
    </div>

    <div>
      <button (click)="onSubmit()">Save</button>
    </div>

  </form>
</div>