import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BotOfficeServiceService {
  authToken: string | null = "";
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem("auth-token");
  }

  url1 =
    environment.apiUrl + "botOfficeShopExt/getBotCategoriesAndItemsForShop";

  getBotCategoriesAndItemsForShop(botInvoiceId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url1}/${botInvoiceId}`);
  }

  placeOrder(obj: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiUrl + "botOfficeShopExt/placeOrder",
      obj
    );
  }

  addOrEditBotMemberInfo(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createBotValidationConfig(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  setOfficeDefaultErrorResponse(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotMembersByOfficeIdAndType(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  addBotUser(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  whatsappCloudApiMediaUpload(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  updateMedia(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("PUT", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotMediaForPreview(avenue: any): Observable<Blob> {
    return this.httpClient.get(avenue, { responseType: "blob" });
  }

  editBotUser(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("PUT", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getMimeType(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  assignAgentToManualChat(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotUsers(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotAgentsAndAssignedManualChatsByOffice(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotCustomerUsersByOfficeId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  deleteBotCustomerUser(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  deleteUserChatSession(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotSchedulesByOfficeId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotMediaByOfficeId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotScheduleDetailsByBotScheduleId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  searchBotUsersByCreationTimestamp(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createBotSchedule(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getWhatsappCloudApiTemplatesByOfficeId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getAllLangVariations(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createLanguageVariation(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  editLanguageVariation(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotItems(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotInvoicesByOfficeId(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotInvoiceDetailsByInvoiceId(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  statusUpdate(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  orderStatusUpdate(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getBotCategories(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createBotItem(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createBotCategory(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  editBotItem(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  editBotCategory(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .put(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  botCategoryAndItemsStatusUpdate(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  botItemStatusUpdate(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getGroups(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  createOrEditGroups(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");

    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });

      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }
}
