import { BotUserLocation } from "src/app/BOT_CODE/bot_classes/bot-user-location";
import { BotItemExt } from "./bot-item-ext";

export class BotInvoiceExt {
  invoiceId: any;
  invoiceNo: any;
  orderDate: any;
  fkBotUserId: any;
  fkOfficeId: any;
  invoiceRemarks: any;
  invoiceStatus: any;
  type: any;
  orderStatus: any;
  subTotal: any;
  grandTotal: any;
  chosenbotUserLoc: BotUserLocation = new BotUserLocation();
  cartItems: BotItemExt[] = [];

  fkBotUserName: any;
  fkBotUserPhone: any;

  orderCreationDate: any;
  orderPlacedDate: any;
  invoiceDate: any;
}
