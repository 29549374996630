export class Subscription{
    id = null;
    
}

export class UserSub {
    name = '';
    role = '';
    product = '';
    startDate = '';
    endDate = '';
    userId = null;
    roleId = null;
    productId = null;
    subscriptionId = null;
  }