


   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Announcement</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form (ngSubmit)="onSubmit()" #invoiceForm="ngForm"> 
             
        
          <div>
            <label class="required"  for="message">Message</label>
            <input #no="ngModel" required class="form-control" type="text" id="message" 
            name="message" [(ngModel)]="announcement.message">
            <div class="alert alert-danger" *ngIf="no.touched && !no.valid">message is required</div>
          </div>

       <br>

        <div class="form-group row">
          <div class="col-md-6">
          <label class="required" for="startDate">Start Date</label>
          <input #date="ngModel" required class="form-control" type="date" name="startDate" 
          id="startDate" [(ngModel)]="announcement.startDate">
          <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provide Start Date</div>
      </div>
  
      <div class="col-md-6">
          <label for="endDate">End Date</label>
          <input class="form-control" [(ngModel)]="announcement.endDate" type="date" id="endDate"
           name="endDate">
      </div>
  
     
      </div>
  
             
      </form>
      </div>
      </div>
      </div>
  
      <div class="modal-footer">
        <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Update</button>
      </div>