<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='create parent category'"
    style="color: white;" class="modal-title pull-left">Create Category</h4>
    <h4 *ngIf="fromParent.context==='create sub category'"
    style="color: white;" class="modal-title pull-left">Create Subcategory</h4>
    <h4 *ngIf="fromParent.context==='edit category'"
    style="color: white;" class="modal-title pull-left">Edit Category</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
    <div class="card-body"> 
    <form #adminForm="ngForm">  

      <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>
   
    <div *ngIf="matSpinnerActive===false">
        <div>
            <label class="required" for="name">Title</label>
            <input #name="ngModel" minlength="3" maxlength="255" required class="form-control" type="text" 
            placeholder="Enter Title" name="name" id="name" [(ngModel)]="botCategory.name">
            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is Required</div>
            </div>

            <br>
    
          <div>
              <label for="description">Description</label>
              <textarea class="form-control" type="text" name="description" 
               id="description" [(ngModel)]="botCategory.description"></textarea>
           </div> 
    </div>


    





</form>
</div>
</div>

<div class="modal-footer">
<button *ngIf="fromParent.context==='create parent category' || fromParent.context==='create sub category'" [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="matSpinnerActive=true;createBotCategory();">save</button>
<button *ngIf="fromParent.context==='edit category'" [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="matSpinnerActive=true;editBotCategory();">Update</button>
</div>