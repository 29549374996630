import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormConfiguation } from 'src/app/models/form-configuation';
import { FormConfigurationService } from 'src/app/services/FormConfiguration/form-configuration.service';
import { environment } from 'src/environments/environment';
  
@Component({
  selector: 'app-edit-form-config',
  templateUrl: './edit-form-config.component.html',
  styleUrls: ['./edit-form-config.component.scss']
})
export class EditFormConfigComponent implements OnInit {

  offices : any[] = [];
  clicked = false;
  formConfig : FormConfiguation = new FormConfiguation();

   constructor(private formConfigService : FormConfigurationService,
    private toastr : ToastrService,
    private activeModal : NgbActiveModal) { }

    
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {
    
    this.formConfig = this.fromParent.formConfig;
  //  this.offices = this.fromParent.offices;
  //  this.formConfig.columnName = "";
  //  this.formConfig.fkOfficeId = "";
  //  this.formConfig.formName = "";
  //  this.formConfig.groupName = "";

  }

  add(){
  this.formConfigService.editFormConfiguration(environment.apiUrl + "formConfig/editFormConfiguration", this.formConfig).then(
    data=>{
            this.activeModal.close();
            this.passEntry.emit("success");
            this.toastr.success("Form configuration updated successfully")
    });
  }

  close(){
    this.activeModal.close();
  }

}

