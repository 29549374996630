import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivityPlanner } from '../courseManagementClasses/activity-planner';
import { AddActPlnrCurrActPlnr } from '../courseManagementClasses/add-act-plnr-curr-act-plnr';

@Injectable({
  providedIn: 'root'
})
export class ActivityPlannerService { 

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  private getUrl =  environment.apiUrl + "activityPlanner/getActivityPlannerListBySubId";
  private statusChangeUrl = environment.apiUrl +  "activityPlanner/statusUpdate";
  private getByIdUrl =  environment.apiUrl + "activityPlanner/getById";
  private getUrl2 =  environment.apiUrl + "activityPlanner/getActivityPlannerListByCurId";
  private getActivityPlannerForSwapUrl = environment.apiUrl + "activityPlanner/getActivityPlannerForSwap"
  private getUrl5 =  environment.apiUrl + "activityPlanner/getAssignmentListByCurId";
  private getUrl8 =  environment.apiUrl + "activityPlanner/deactivateActivityPlanner";
  private getUrl9 =  environment.apiUrl + "activityPlanner/deactivateAssignment";
  private getUrl10 =  environment.apiUrl + "activityPlanner/deactivateParentPlanner";


  deactivateActivityPlanner(activityPlannerId : string):Observable<Object>{
    return this.httpClient.delete(`${this.getUrl8}/${activityPlannerId}`);
   }

   deactivateAssignment(activityPlannerId : string):Observable<Object>{
    return this.httpClient.delete(`${this.getUrl9}/${activityPlannerId}`);
   }

   deactivateParentPlanner(activityPlannerId : string):Observable<Object>{
    return this.httpClient.delete(`${this.getUrl10}/${activityPlannerId}`);
   }

  addActivityPlannerCAP(avenue: string, actPlnrCAP : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, actPlnrCAP, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, actPlnrCAP, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  addInstantAssignment(avenue: string, actPlnrCAP : AddActPlnrCurrActPlnr): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, actPlnrCAP, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, actPlnrCAP, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  getActivityPlannerListBySubId(subId : string):Observable<ActivityPlanner[]>{
    return this.httpClient.get<ActivityPlanner[]>(`${this.getUrl}/${subId}`);
  } 

  activityPlannerStatusUpdate(activityPlannerId : string):Observable<Object>{
    return this.httpClient.delete(`${this.statusChangeUrl}/${activityPlannerId}`);
   }

   getActivityPlannerById(activityPlannerId : string):Observable<ActivityPlanner>{
    return this.httpClient.get<ActivityPlanner>(`${this.getByIdUrl}/${activityPlannerId}`);
   }

  updateActivityPlanner(avenue: string, actPlnrCAP : ActivityPlanner): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, actPlnrCAP, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, actPlnrCAP, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  getActivityPlannerListByCurId(curId : string):Observable<ActivityPlanner[]>{
    return this.httpClient.get<ActivityPlanner[]>(`${this.getUrl2}/${curId}`);
   } 

   getActivityPlannerListByCurIdForSA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getActivityPlannerListByCurIdForCA(avenue: string): Promise<any> {
    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getActivityPlannerListByCurIdForTeacher(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getAssignmentListByCurIdForTeacher(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  markActivityActivityAsComplete(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  unMarkActivityAsComplete(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


   getAPsWithCompletionStatusBySubId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getStudentAPsForTimeline(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getActivityPlannerForSwap(activityPlannerId : string, teacherId : string):Observable<ActivityPlanner>{
    return this.httpClient.get<ActivityPlanner>(`${this.getActivityPlannerForSwapUrl}/${activityPlannerId}/${teacherId}`);
   }

   getActivityPlannerForSwapForTeacher(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getAssignmentListByCurId(curId : string):Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.getUrl5}/${curId}`);
   }

   getAssignmentListByCurIdForSA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getAssignmentListByCurIdForCA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

   getAssignmentsForSwapBySubId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


   startAssignment(avenue: string, teacherPlanner : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, teacherPlanner, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, teacherPlanner, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  EditTeacherPlanner(avenue: string, teacherPlanner : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, teacherPlanner, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, teacherPlanner, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getTeacherPlannerById(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
}
