<div  [ngStyle]="backgroundStyle"  class="modal-header">
  <h4 style="color: white;" *ngIf="type !== 'order'" class="modal-title pull-left">Edit Invoice Details</h4>
  <h4  style="color: white;"  *ngIf="type === 'order'" class="modal-title pull-left">Edit Order Details</h4>  
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> 

  
<div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onUpdate()" #invoiceDetForm="ngForm"> 

          <div class="form-group row">
            
            <div class="col-md-6">
          <label class="required" for="itemName">Item Name</label>
            <input #name="ngModel" required class="form-control" id="itemName" name="itemName" [(ngModel)]="invoiceDet.itemName" class="form-control" >
            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
          </div>
          

          <div class="col-md-6">
          <label class="required" for="quantity">Quantity</label>
            <input  (change)="onFileSelected($event)" #qty="ngModel" required class="form-control" type="number" id="quantity" name="quantity" [(ngModel)]="invoiceDet.quantity">
            <div class="alert alert-danger" *ngIf="qty.touched && !qty.valid">Enter the Quantity</div>
          </div>
        
        </div>

        <div class="form-group row">
        
          <div class="col-md-6">
          <label class="required" for="pricePerItem">Price per item</label>
          <input (change)="onFileSelected($event)"  #item="ngModel" class="form-control" type="number" id="pricePerItem" name="pricePerItem" [(ngModel)]="invoiceDet.pricePerItem">
          <div class="alert alert-danger" *ngIf="item.touched && !item.valid">Enter the Price</div>
        </div>

        <div class="col-md-6">
          <label class="required"  for="perAltQuantityDiscountRate">Per Alt Qty Discount Rate</label>
          <input #perAltQuantityDiscountRate="ngModel" (change)="onFileSelected($event)" 
          required class="form-control" type="number" id="perAltQuantityDiscountRate" 
          name="perAltQuantityDiscountRate" [(ngModel)]="invoiceDet.perAltQuantityDiscountRate">
          <div class="alert alert-danger" *ngIf="perAltQuantityDiscountRate.touched && !perAltQuantityDiscountRate.valid">Enter the Price</div>
          </div>

         
        </div>

        

          <div class="form-group row">

            <div class="col-md-6">
              <label for="alternateQuantity"> Alternate Quantity</label>
              <input readonly class="form-control" type="number" id="alternateQuantity" name="alternateQuantity" [(ngModel)]="invoiceDet.alternateQuantity">
              </div>

              <div class="col-md-6">
                <label class="required" for="totalPrice">Total price</label>
                  <input readonly #tp="ngModel" required type="number" class="form-control" id="totalPrice" name="totalPrice" [(ngModel)]="invoiceDet.totalPrice" class="form-control" >
                </div>
              
         


          </div>

          <br>
          <div>
            <label for="deliveryAddress">Delivery address</label>
              <input class="form-control" id="deliveryAddress" name="deliveryAddress" [(ngModel)]="invoiceDet.deliveryAddress" class="form-control" >
            </div>

          
          <div >
            <label for="description">Description</label>
              <textarea   #remarks="ngModel" maxlength="244" class="form-control" id="description" name="description" [(ngModel)]="invoiceDet.description" class="form-control" ></textarea>
              <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
            </div>

<br>
          <!-- <label hidden for="fkInvoiceId">fkInvoiceId</label>
          <div class="input-group">
            <input hidden type="text" id="fkInvoiceId" name="fkInvoiceId" [(ngModel)]="invoiceDet.fkInvoiceId" class="form-control">
          </div>

          <label hidden for="fkProductId">fkProductId</label>
          <div class="input-group">
            <input hidden id="fkProductId" name="fkProductId" [(ngModel)]="invoiceDet.fkProductId" class="form-control" >
          </div>

          <label hidden for="invoiceDetStatus">invoiceDetStatus</label>
          <div hidden class="input-group">
            <input hidden id="invoiceDetStatus" name="invoiceDetStatus" [(ngModel)]="invoiceDet.invoiceDetStatus" class="form-control" >
          </div>  -->

          

        </form>
      </div>
    </div>
    </div>

    <div class="modal-footer">
      <button  [disabled]="clicked"  [disabled]="!invoiceDetForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onUpdate(); clicked = true;">Update</button>
  </div>