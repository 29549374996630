import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseOptions } from 'src/app/BOT_CODE/bot_classes/bot-response-options';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { BotLangResOptions } from 'src/app/BOT_CODE/bot_classes/bot-lang-res-options';
import { BotLanguage } from 'src/app/BOT_CODE/bot_classes/bot-language';
import { BotSuperadminServiceService } from 'src/app/BOT_CODE/bot_services/bot-superadmin--service.service';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';


@Component({
  selector: 'app-create-option-lang-variations',
  templateUrl: './create-option-lang-variations.component.html',
  styleUrls: ['./create-option-lang-variations.component.scss']
}) 
export class CreateOptionLangVariationsComponent implements OnInit {

  clicked = false;
  loggedInUser : any;
  theme : any; 

  constructor(private sharedDataService: SharedDataService,
    private botOfficeService : BotOfficeServiceService,
    public activeModal: NgbActiveModal, private botService : BotSuperadminServiceService,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    botResponseOption : BotResponseOptions = new BotResponseOptions();
    botLangResOption : BotLangResOptions = new BotLangResOptions();
    botLanguages : BotLanguage[] = [];
    botLangResOptions : BotLangResOptions[] = [];
    context : any;

    ngOnInit(): void {

    this.botLangResOption.fkBotLanguageId = "chooselanguage";

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

    if(this.context === "create"){
      this.botResponseOption = this.fromParent.botResponseOption;
      this.botLangResOption.fkBotResOptionId = this.botResponseOption.botResponseOptionsId;
      this.getAllLanguagesAndAllLangResponsesAndFilterLanguages();
    }

    if(this.context === "edit"){
      let x : BotLangResOptions = new BotLangResOptions();
      x = this.fromParent.botLangResOption;

      this.botLangResOption.botLangResOptionsId = x.botLangResOptionsId;
      this.botLangResOption.botLangResOptionsStatus = x.botLangResOptionsStatus;
      this.botLangResOption.fkBotLanguage = x.fkBotLanguage;
      this.botLangResOption.fkBotLanguageId = x.fkBotLanguageId;
      this.botLangResOption.fkBotResOptionId = x.fkBotResOptionId;
      this.botLangResOption.langResOption = x.langResOption;
      this.botLangResOption.langResOptionNumber = x.langResOptionNumber;
      this.botLangResOption.interactiveListSectionName = x.interactiveListSectionName;
      this.botLangResOption.responseOptionDescription = x.responseOptionDescription;
    }

   }

   getAllLanguagesAndAllLangResponsesAndFilterLanguages(){
    this.botService.getAllLanguages(environment.apiUrl + "botConvo/getBotOfficeSubscribedLanguages").then(
      res=>{
        let tempList : BotLanguage[] = []; 
        tempList = res;

        this.botOfficeService.getAllLangVariations(environment.apiUrl + "botOffice/getAllLangResOptionsByOptionId/" + this.botResponseOption.botResponseOptionsId).then(
          res=>{
            let resObj : BotLangResOptions = new BotLangResOptions();
            resObj = res;
            this.botLangResOptions = resObj.list;

            if(this.botLangResOptions !== null && this.botLangResOptions !== undefined && this.botLangResOptions.length > 0){
              let addedLanguages : string[] = [];
              this.botLangResOptions.forEach((lRes)=>{
                addedLanguages.push(lRes.fkBotLanguage);
              });
  
              tempList.forEach((tempL)=>{
                if(!addedLanguages.includes(tempL.language)){
                  this.botLanguages.push(tempL);
                }
              });
            }else{
              this.botLanguages = tempList;
            }

          });

      });
   }

   create(){
    
    this.botOfficeService.createLanguageVariation(environment.apiUrl + "botOffice/createBotLangResOption", this.botLangResOption).then(
      res=>{
        this.activeModal.close();
        this.toastr.success("Option added successfully !");
        this.passEntry.emit("success");
      });

  }

  edit(){
        
    this.botOfficeService.editLanguageVariation(environment.apiUrl + "botOffice/editBotLangResOption", this.botLangResOption).then(
      res=>{
        this.passEntry.emit(this.botLangResOption);
        this.activeModal.close();
        this.toastr.success("Option updated successfully !");
      });

  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}