import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { UpdateInvoiceComponent } from 'src/app/crm/invoice/update-invoice/update-invoice.component';
import { ShipmentService } from 'src/app/service/shipment.service';
import { Shipment } from 'src/app/crmClasses/shipment';
import { UpdateShipmentComponent } from 'src/app/crm/shipment/update-shipment/update-shipment.component';
import { PaymentService } from 'src/app/service/payment.service';
import { UpdatePaymentComponent } from 'src/app/crm/payment/update-payment/update-payment.component';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { EditComponent } from '../../customerCommunication/edit/edit.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { EditCustomerComponent } from '../edit-customer/edit-customer.component';
import { ViewOrderDetailsComponent } from '../../orders/view-order-details/view-order-details.component';
import { AddCustomerCommunicationsComponent } from '../../customerCommunication/add-customer-communications/add-customer-communications.component';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

  customerId : any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  gridColumns = 3;
  currentRole = "BCRM_EXE";
  officeId!: string;
  loggedInUser: any;
  userName!: string;
  userId!: string;
  navbarTitle : any;
  loggedUser: any ;
  combinedPTC : Customer = new Customer();
  invoices : any[] = [{
    deliveryAddress: '',
description: '',
fkCustomerId: '',
fkInvoiceId: '',
fkProductId: '',
invoiceDate: '',
invoiceDetId: '',
invoiceDetStatus: '',
invoiceId: '',
invoiceNo: '',
invoiceRemarks: '',
invoiceStatus: '',
itemName: '',
paymentMethod: '',
pricePerItem: '',
quantity: '',
totalAmount: '',
totalPrice: ''
  }];
  shipments : Shipment[] = [];
  payments : any[] = [{   fkInvoiceId: "",
                          paymentAmount: "",
                          paymentCompletion: "",
                          paymentDate: "",
                          paymentDue: "",
                          paymentId: "",
                          paymentMethod: "",
                          paymentRemarks: "",
                          paymentStatus: ""

                        }]

invoiceExists!: boolean;
invoiceOptions: any = {};

paymentsExists!: boolean;
paymentOptions: any = {};

shipmentExists!: boolean;
shipmentOptions: any = {};

communicationsExists!: boolean;
communicationsOptions: any = {};

coms : CustomerCommunications[] = [];

  constructor(
    private router : Router,private route : ActivatedRoute,
    private invoiceService : InvoiceService,
    private datePipe : DatePipe,private customerService : CustomerService,
    private shipmentService : ShipmentService,
    private ngbModalService: NgbModal, private paymentService : PaymentService,
    private sharedDataService : SharedDataService ) {
}

color: ThemePalette = 'primary';
mode: ProgressSpinnerMode = 'determinate';
value = 50;

color0: ThemePalette = 'warn';
mode0: ProgressSpinnerMode = 'determinate';
value0 = 50;

color1: ThemePalette = 'accent';
mode1: ProgressSpinnerMode = 'determinate';
value1 = 50;


countryCodes : any[] = [];
     
ngOnInit(): void {

  this.countryCodes = GlobalClass.CountryCodes;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.customerId = this.route.snapshot.params['customerId'];

    this.invoiceOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.paymentOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.shipmentOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.communicationsOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

    this.getCustomer();
    this.getInvoices();
    this.getShipments();
    this.getPayments();
    this.getCommunications();
  }


  addNewCustomerCommunication(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCustomerCommunicationsComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
    let data = {
      customerId : this.customerId
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.getCommunications();
    });
}

  getCustomer(){
    this.customerService.getCustomerById(environment.apiUrl + "bcrmCustomer/getCustomerById/" + this.customerId).then(
      data=>{
      this.combinedPTC = data;

      if(this.combinedPTC.customerScoring.lastPurchaseDate !== null && this.combinedPTC.customerScoring.lastPurchaseDate !== undefined){
        this.combinedPTC.customerScoring.lastPurchaseDate = new Date(this.combinedPTC.customerScoring.lastPurchaseDate);
        this.combinedPTC.customerScoring.lastPurchaseDate = this.datePipe.transform(this.combinedPTC.customerScoring.lastPurchaseDate, "dd-MM-yyyy");  
      }
    
      this.value = this.combinedPTC.customerScoring.overallScore;
      this.value0 = this.combinedPTC.customerScoring.debitPercentile;
      this.value1 = this.combinedPTC.customerScoring.creditWorthiness;

    });
  }

  getInvoices(){
    this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.customerId).then(
      data=>{
        this.invoices = data;
        this.invoiceExists = true;
      });
  }

  getShipments(){

    this.shipmentService.getByCustomerId(environment.apiUrl + "shipment/getByCustomerId/" +  this.customerId).then(
      data=>{
        this.shipments = data;

        this.shipments.forEach((value)=>{
          let sd = value.shippedDateTime;
         let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);      
         value.shippedDateTime = this.datePipe.transform(startDate, 'yyyy-MM-dd hh:mm');

        });

        this.shipmentExists = true;
      });
  }

  getPayments(){

    this.paymentService.getByCustomerId(environment.apiUrl + "payment/getByCustomerId/" +  this.customerId).then(
      data=>{
        this.payments = data;
        this.paymentsExists = true;
      });
  }

  getCommunications(){
    this.customerService.getCustomerCommunicationsByCustomerId(environment.apiUrl + "bcrmCustomerCommunications/getCustomerCommunicationsByCustomerId/" +  this.customerId).then(
      data=>{
        this.coms = data;
        this.communicationsExists = true;

        this.coms.forEach((value)=>{

          let sd = value.currentActionDateTime;
          let ed = value.nextActionDateTime;

         let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
         let endDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);

         value.currentActionDateTime = startDate;   
         value.nextActionDateTime = endDate;           
      
         value.currentActionDateTime = this.datePipe.transform(startDate, 'yyyy-MM-dd hh:mm');
         value.nextActionDateTime = this.datePipe.transform(endDate, 'yyyy-MM-dd hh:mm');
        });
      });
  }

    // authorized
    editCustomer(){ 
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open(EditCustomerComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
  
      let data = {
        id: this.customerId ,
        custObject : this.combinedPTC,
            type : "CUSTOMER"
          }
  
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
        this.getCustomer();
      });  
    }

  editCommunications(coms : CustomerCommunications){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      coms : coms 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
    this.getCommunications();
    });  
    
  }

  editPayment(paymentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdatePaymentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        paymentId: paymentId
      }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

          modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
         this.getPayments();
          });  
  }
  editShipment(shipmentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      shipmentId: shipmentId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
     this.getShipments();
    });  
    
  }


  viewInvoiceDetailsModal(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOrderDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });

    let data = {
      invoiceId: invoiceId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }


  editInvoice(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( UpdateInvoiceComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
  let data = {
    invoiceId: invoiceId 
    }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.getInvoices();
      });
}
  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  reloadPage() {
    window.location.reload();
  }
 

}
