import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-test-add-component',
  templateUrl: './test-add-component.component.html',
  styleUrls: ['./test-add-component.component.scss']
})
export class TestAddComponentComponent implements OnInit {

  constructor() { }

    ngOnInit(): void { 

     }

    
     
 


    
   
   
   
}


