 <div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Instant Assignment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm"> 
     
         <div class="form-group row">
            <div class="col-md-6">
           <label class="required" for="curId">Curriculum</label> <br>
               <select #curId="ngModel" required class="form-control" [(ngModel)]="actPlnrCAP.curId" id="curId" name="curId">
                 <option  value="" disabled >Choose Curriculum</option>
                 <option  *ngFor="let cur of curriculums"  [ngValue]="cur.curId">{{cur.name}}</option>
               </select>
               <div class="alert alert-danger" *ngIf="curId.touched && !curId.valid">Select Curriculum</div>
           </div>
         </div>

           <div class="form-group row">
 
         <div class="col-md-4">
            <label class="required" for="activityPlannerName">Assignment Name</label>
            <input  maxlength="50" #activityPlannerName="ngModel" required placeholder="Enter Activity Name" required class="form-control" type="text" name="activityPlannerName"  id="activityPlannerName" [(ngModel)]="actPlnrCAP.activityPlannerName">
            <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Name is required</div>
         </div>

         <div class="col-md-4">
            <label class="required"  for="activityPlannerOrder">Order</label>
            <input #Order="ngModel" placeholder="Enter Order" required class="form-control" type="number" name="activityPlannerOrder"  id="activityPlannerOrder" [(ngModel)]="actPlnrCAP.activityPlannerOrder">
            <div class="alert alert-danger" *ngIf="Order.touched && !Order.valid">Order is required</div>
         </div>

         <div class="col-md-4">
          <label>Activity Planner Image</label>
          <input placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
       </div>
   
      </div>

   <br>
      <div>
         <label class="required" for="activityPlannerDescription">Description</label>
         <textarea  maxlength="250" placeholder="Enter Description" required class="form-control" type="text" name="activityPlannerDescription"  id="activityPlannerDescription" [(ngModel)]="actPlnrCAP.activityPlannerDescription"></textarea>
      </div>
<br>

    </form>
      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
  </div>