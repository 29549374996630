import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CrmServiceService {
  authToken: string | null = "";
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem("auth-token");
  }

  postMethod(avenue: string, obj: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");
    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("POST", avenue, obj, {
        reportProgress: true,
        responseType: "text",
      });
      self.httpClient
        .post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  getMethod(avenue: string): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");
    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      self.httpClient
        .get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }

  putMethod(avenue: string, cust: any): Promise<any> {
    this.authToken = localStorage.getItem("auth-token");
    const self = this;
    const headers = new HttpHeaders({
      Authorization: "Bearer " + this.authToken,
    });
    return new Promise((resolve, reject) => {
      const req = new HttpRequest("PUT", avenue, cust, {
        reportProgress: true,
        responseType: "text",
      });
      self.httpClient
        .put(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {});
    });
  }
}
