import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { AddBcrmDataComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_data/add-bcrm-data/add-bcrm-data.component';
import { BcrmDataService } from 'src/app/BIZ_CRM/biz_crm_services/bcrm-data.service';
import { environment } from 'src/environments/environment';
import { BcrmData } from 'src/app/BIZ_CRM/biz_crm_classes/bcrm-data';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-bcrm-data-home',
  templateUrl: './bcrm-data-home.component.html',
  styleUrls: ['./bcrm-data-home.component.scss']
})

export class BcrmDataHomeComponent implements OnInit {

  currentRole = "BCRM_DATA";
  customerLoaderMessage!: string;
  customerExists: boolean = false;
  customerLoader: boolean = false;
  roleLoader = false;
  userName ! : string;
  customerOptions : any;
  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  customerId: any;
  userId!: string
  navbarTitle: any;
  bcrmDataList : BcrmData[] = [];

  constructor(private ngbModalService: NgbModal,
    private toastr : ToastrService,
              public router: Router, 
              private datePipe : DatePipe,
              private spinner: NgxSpinnerService, 
              public bsModalRef: BsModalRef,
              private bcrmDataService : BcrmDataService,
              private sharedDataService : SharedDataService ) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };
    lastEndDate : any;
    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    startCustomerLoading(){
      this.customerLoader ? this.spinner.show() : this.spinner.hide();
    }

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getBcrmData();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

  deleteOfficeBcrmData(){
    this.bcrmDataService.deleteOfficeBcrmData(environment.apiUrl + "bcrmData/deleteOfficeBcrmData").then(
      data=>{
        this.toastr.success("Data reset successful");
      });
  }

  //authorized
  getBcrmData(){
    this.bcrmDataService.getAllBcrmDataByOfficeId(environment.apiUrl + "bcrmData/getAllBcrmDataByOfficeId").then(
      data=>{
        this.bcrmDataList = data;
        this.customerExists = true;

        let dates : any[] = [];

        let dates2 : any[] = [];

        this.bcrmDataList.forEach((val)=>{

          dates2.push(val.endDate);

          let ts = val.timestamp;          
          let timeStamp = new Date(ts[0], ts[1] - 1 , ts[2], ts[3], ts[4]);          
          val.timestamp = this.datePipe.transform(timeStamp, 'dd-MM-yyyy hh:mm');

          let sd = val.startDate;
          let startDate = new Date(sd);
          val.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');

          let ed = val.endDate;
          let endDate = new Date(ed);
          val.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
          dates.push(val.endDate);
         
        });

        this.lastEndDate = null;

        if(this.bcrmDataList.length > 0){
        // dates.forEach((date)=>{
        //   if(this.lastEndDate === null){
        //     this.lastEndDate = date;
        //   }else{
        //     if(date > this.lastEndDate){
        //       this.lastEndDate = date;
        //     }
        //   }
        // });
        this.lastEndDate = new Date(Math.max.apply(null,dates2));
        this.lastEndDate = this.datePipe.transform(this.lastEndDate, 'dd-MM-yyyy');
      }

      
      
      });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  goToAnalyticsDashboard(){
    this.router.navigate(["/dashboard/bcrm-analytics-dashboard"]);
  }

  goToTestDashboard(){
    this.router.navigate(["/test-dashboard"]);
  }
   //authorized
  addBcrmData(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddBcrmDataComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = { 
      lastEndDate : this.lastEndDate,
      context : "File Upload"
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.getBcrmData();
    });    
  }

    //authorized
    oneTimeUpload(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( AddBcrmDataComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        lastEndDate : this.lastEndDate,
        context : "One Time Upload"
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
        this.getBcrmData();
      });    
    }
  
    getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
        return this.loggedInUser.offices[0].theme + '!important';
    }
    
  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
  
  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  reloadPage() {
    window.location.reload();
  }

}
