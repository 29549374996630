<div [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="type==='single invoice shipment'" style="color: white;" class="modal-title pull-left">Create Shipment</h4>
    <h4 *ngIf="type==='batch shipment'" style="color: white;" class="modal-title pull-left">Create Batch Shipment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
 


    <div class="modal-body">
    <div class="card">
      <div class="card-body">

        <form (ngSubmit)="onSubmit()" #shipmentForm="ngForm">  
           
           <div *ngIf="type==='single invoice shipment'">

    <div class="form-group row">

        <div class="col-md-6">
            <label class="required"  for="shipmentPartner">Shipment Partner</label>
            <input #partner="ngModel" required class="form-control" type="text" name="shipmentPartner" id="shipmentPartner" [(ngModel)]="shipment.shipmentPartner">
            <div class="alert alert-danger" *ngIf="partner.touched && !partner.valid">Provide Shipment Partner</div>
            </div>
    
        <div class="col-md-6">
        <label for="shipmentAddress">Address</label>
        <input #address="ngModel" class="form-control" type="text" name="shipmentAddress" id="shipmentAddress" [(ngModel)]="shipment.shipmentAddress">
        <div class="alert alert-danger" *ngIf="address.touched && !address.valid">Provide Delivery Address</div>
    </div>

     
    </div>

    <div class="form-group row">
        <div class="col-md-6">
        <label class="required"  for="shippedDate">Shipped Date</label>
        <input #date="ngModel" required class="form-control" type="datetime-local" name="shippedDate" id="shippedDate" [(ngModel)]="shipment.shippedDateTime">
        <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provice Shipped Date</div>
    </div>

    <div class="col-md-6">
        <label for="expectedDeliveryDateTime">Expected Delivery Date</label>
        <input class="form-control" [(ngModel)]="shipment.expectedDeliveryDateTime" type="datetime-local" id="expectedDeliveryDateTime" name="expectedDeliveryDateTime">
    </div>

    <!-- <div class="col-md-4">
        <label  for="deliveryDateTime">Delivery Date</label>
        <input class="form-control" type="datetime-local" name="deliveryDateTime" id="deliveryDateTime" [(ngModel)]="shipment.deliveryDateTime">
    </div> -->
    </div>

    <div>
        <label  for="shipmentRemarks">Shipment Remarks</label>
        <textarea #remarks="ngModel" maxlength="244" class="form-control" type="text" name="shipmentRemarks" id="shipmentRemarks" [(ngModel)]="shipment.shipmentRemarks"></textarea>
        <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
    </div>
   
</div>

<div *ngIf="type==='batch shipment'">
  

    <table *ngIf="createShipmentForBatch===false" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
      <thead class="tableheader">
        <tr>
          <th>Executive</th>
          <th>Customer</th>
          <th>Status</th>
          <th>Date</th>
          <th>Total Alt Qty</th>
          <th> Total Amt</th>
          <th> Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="td-height" *ngFor="let ccd of invoices">
          <td class="td-height">{{ccd.executiveName}}</td>
          <td class="td-height">{{ccd.customerName}}</td>
          <td class="td-height">{{ccd.orderStatus}}</td>
          <td class="td-height">{{ccd.invoiceDate}}</td>
          <td  style="text-align: right;" class="td-height">{{ccd.totalAlternateQuantity}}</td>
          <td  style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>
          <td>


            <input (click)="handleClick(ccd);" class="form-check-input"
             type="checkbox" value="" id="flexCheckDefault">


          </td>

        
          
        </tr>    

      </tbody>
    </table>
    <div *ngIf="createShipmentForBatch===true">

      <div class="form-group row">
  
          <div class="col-md-6">
              <label class="required" for="shipmentPartner">Shipment Partner</label>
              <input #partner="ngModel" required class="form-control" type="text" name="shipmentPartner" id="shipmentPartner" [(ngModel)]="shipment.shipmentPartner">
              <div class="alert alert-danger" *ngIf="partner.touched && !partner.valid">Provide Shipment Partner</div>
              </div>
      
          <div class="col-md-6">
          <label for="shipmentAddress">Address</label>
          <input #address="ngModel" class="form-control" type="text" name="shipmentAddress" id="shipmentAddress" [(ngModel)]="shipment.shipmentAddress">
          <div class="alert alert-danger" *ngIf="address.touched && !address.valid">Provide Delivery Address</div>
      </div>
  
       
      </div>
  
      <div class="form-group row">
          <div class="col-md-6">
          <label class="required"  for="shippedDate">Shipped Date</label>
          <input #date="ngModel" required class="form-control" type="datetime-local" name="shippedDate" id="shippedDate" [(ngModel)]="shipment.shippedDateTime">
          <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provice Shipped Date</div>
      </div>
  
      <div class="col-md-6">
          <label for="expectedDeliveryDateTime">Expected Delivery Date</label>
          <input class="form-control" [(ngModel)]="shipment.expectedDeliveryDateTime" type="datetime-local" id="expectedDeliveryDateTime" name="expectedDeliveryDateTime">
      </div>
  
    
      </div>
  
      <div>
          <label for="shipmentRemarks">Shipment Remarks</label>
          <textarea #remarks="ngModel" maxlength="244" class="form-control" type="text" name="shipmentRemarks" id="shipmentRemarks" [(ngModel)]="shipment.shipmentRemarks"></textarea>
          <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
      </div>
     
  </div>

</div>

</form>
</div>
    </div>
    </div>

    <div class="modal-footer">
        <button [disabled]="clicked" [disabled]="!shipmentForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
        *ngIf="type==='single invoice shipment'" (click)="onSubmit(); clicked = true;">Save</button>

        <button [disabled]="clicked" style="width: auto;" [disabled]="!shipmentForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
        *ngIf="type==='batch shipment' && createShipmentForBatch===false" (click)="createShipmentForBatch = true;">Create Shipment</button>

        <button [disabled]="clicked" [disabled]="!shipmentForm.valid" type="submit" class="btn btn-sm btn-primary float-right" 
        *ngIf="type==='batch shipment' && createShipmentForBatch===true" (click)="createBatchShipment(); clicked = true;">Save</button>
    </div>