<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Form Responses</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
    <div class="card-body">

<div *ngFor="let formRespondent of formActive.sortedFormRespondents">
   
    <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
        <div class="col-md-12" style="color: rgb(248, 245, 245);">
          <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
           Responses
          </span>
        </div> 
      </div>

      <br>

     <div *ngFor="let formResponse of formRespondent.sortedResponses">
      <h4 style="margin:0%;font-weight: bold;">{{formResponse.question}}</h4>
      <h4 style="margin:0%">{{formResponse.answers}}</h4>
      <h4 style="margin:0%" *ngIf="formResponse.answerType==='timestamp'">{{formResponse.answerValueTimestampFormatted}}</h4>
      <br>
    </div>
    
  </div>

  <br>
  <button (click)="exploreFormCombinedHistory();"
  style="float: right;width: auto;" class="btn btn-info" >View all Responses and Analytics</button>

    </div>
    </div>
  </div>