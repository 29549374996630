<div class="tree">
    <ul>
    <li>
        <div class="family">
            <div class="person child male">
                <div class="name">Grandfather</div>
            </div>
        <div class="parent">
          <div class="person female">
            <div class="name">Grandmother</div>
          </div>
          <ul>
            <li>
              <div class="family" style="width: 172px">
                <div class="person child male">
                  <div class="name">Uncle</div>
                </div>
                <div class="parent">
                  <div class="person female">
                    <div class="name">Wife of Uncle</div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="family" style="width: 172px">
                <div class="person child female">
                  <div class="name">Aunt</div>
                </div>
                <div class="parent">
                  <div class="person male">
                    <div class="name">Husband of Aunt</div>
                  </div>
                </div>
            </div>
        </li>
        <li>
          <div class="family" style="width: 344px">
            <div class="person child female">
              <div class="name">Mother</div>
            </div>
            <div class="parent">
              <div class="person male">
                <div class="name">Father</div>
              </div>
              <ul>
                <li>
                  <div class="person child male">
                    <div class="name">Me</div>
                  </div>
                </li>
                <li>
                  <div class="person child female">
                    <div class="name">Sister</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="person spouse male">
              <div class="name">Spouse</div>
            </div>
        </div>
      </li>
    </ul>
  </div>
  </div>
</li>
</ul>
</div>
