<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Questions</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

<div class="modal-body">
<div class="card">
<div class="card-body">

  <h3 *ngIf="actQuestionList === undefined || actQuestionList.length === 0">
    No Questions to display !
  </h3>

<table>
  <thead>
    <tr>
      <th>Order</th>
      <th>Question</th>
      <!-- <th *ngIf="access !== 'TEACHER-VIEW-ONLY'">Action</th> -->
      <th *ngIf="fromParent.objective === true">Objective</th>
      <th *ngIf="fromParent.questionSkill === true && fromParent.oa_ActQuesSkillMapping === true">Skills</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let actQuestion of actQuestionList">
      <td>{{actQuestion.activityQuestionOrder}}</td>
      <td>{{actQuestion.activityQuestion}}
        <a  *ngIf="access !== 'TEACHER-VIEW-ONLY'" style="cursor: pointer;"
         (click)="editActivityQuestion(actQuestion.activityQuestionId, actQuestion.activityQuestion,actQuestion.activityQuestionDescription,actQuestion.activityQuestionOrder)">
           <img matTooltip="Edit Question" style="cursor: pointer;" src="/assets/images/icons/edit.svg" 
           width="20" height="20" alt="" loading="lazy">
        </a> 
      </td>
      <!-- <td *ngIf="access !== 'TEACHER-VIEW-ONLY'">
        <a  *ngIf="access !== 'TEACHER-VIEW-ONLY'" style="cursor: pointer;"
         (click)="editActivityQuestion(actQuestion.activityQuestionId, actQuestion.activityQuestion,actQuestion.activityQuestionDescription,actQuestion.activityQuestionOrder)">
           <img matTooltip="Edit Question" style="cursor: pointer;" src="/assets/images/icons/edit.svg" width="15" height="15" alt="" loading="lazy">
        </a> 
      </td> -->
      <td *ngIf="actQuestion.objective === false && fromParent.objective === true">
        <button (click)="makeQuestionObjective(actQuestion.activityQuestionId)" style="margin-left: 3%;width: auto;" class="btn btn-info">Make Objective</button>
      </td>
      <td style="color: green;" *ngIf="actQuestion.objective === true && fromParent.objective === true">
    Objective Question
      </td>
      <td *ngIf="fromParent.questionSkill === true  && fromParent.oa_ActQuesSkillMapping === true">
        <a style="cursor:pointer;margin: 1%;" class="float-right" *ngIf="fromParent.questionSkill === true"
        (click)="open(viewSkillMappings);" >
        <img matTooltip="View Skills" src="/assets/images/icons/eye.png" width="26" height="26" alt="" loading="lazy">
      </a>
      
      </td>

      <ng-template #viewSkillMappings let-modal>
        <div [ngStyle]="backgroundStyle" class="modal-header">
          <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Question - Skill Mapping</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
      
          <!-- <button style="float: right;margin: 2%;" (click)="open(AddSkill);" class="btn btn-secondary">Add Skill</button> -->

          

         <table>
           <thead>
             <tr>
               <th>Skill Name (Level)</th>
               <th>Action</th>
             </tr>
           </thead>
           <tbody>
             <tr *ngFor="let skill of actQuestion.skills">
              <td>{{skill.skillName}} ( {{skill.level}} )</td>
              <td>
                <a style="cursor:pointer;margin: 1%;" *ngIf="fromParent.questionSkill === true"
      (click)="deleteQuestionSKillMapping(actQuestion.activityQuestionId , skill.questionSkillMappingId );" >
      <img matTooltip="Delete Skill" src="/assets/images/icons/delete.png" width="26" height="26" alt="" loading="lazy">
    </a>
              </td>
             </tr>
<br>
             <tr>
               <td></td>
               <td><a style="cursor:pointer;margin: 1%;" *ngIf="fromParent.questionSkill === true"
                (click)="open(AddSkill);" >
                <img matTooltip="Add Skill" src="/assets/images/icons/add.svg" width="26" height="26" alt="" loading="lazy">
              </a>
            </td>
             </tr>
           </tbody>
         </table>
      
        </div> 
      </ng-template>
     

<ng-template #AddSkill let-modal>
  <div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Add Skill</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form #adminForm="ngForm"> 
    <div class="form-group row">
      <div class="col-md-6">
        <label class="required" for="Class">Skill</label> <br>
            <select required class="form-control" [(ngModel)]="questionSkillMapping.fkSkillId" id="Class" name="Class">
              <option value="" disabled >Choose Skill</option>
              <option  *ngFor="let skill of actQuestion.skillsToMap"  [ngValue]="skill.skillId">{{skill.skillName}}</option>
            </select>
        </div>
        <div class="col-md-6">
          <label class="required" for="Class">Level</label> <br>
          <input type="number" required class="form-control" [(ngModel)]="questionSkillMapping.level" id="level" name="level">
        </div>
    </div>
    </form>

  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" [disabled]="!adminForm.valid"
     (click)="addActivityQuestionSkillMapping(actQuestion.activityQuestionId);modal.dismiss('Cross click')">Add</button>
  </div>
</ng-template>

    </tr>
  </tbody>
</table>

</div>
</div>
</div>

