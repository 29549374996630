import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityQuestionsService } from 'src/app/service/activity-questions.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-update-activity-questions',
  templateUrl: './update-activity-questions.component.html',
  styleUrls: ['./update-activity-questions.component.scss']
})
export class UpdateActivityQuestionsComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  actQuestion : ActivityQuestions = new ActivityQuestions();
  activityQuestionId! : string;
  activityQuestionDescription : any;
  activityQuestion : any;
  activityQuestionOrder : any;
  clicked = false;

  constructor(public activeModal: NgbActiveModal, private sharedDataService : SharedDataService,
              private toaster: ToastrService,
              private activityQuestionsService : ActivityQuestionsService ) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.actQuestion.activityQuestionId = this.fromParent.activityQuestionId;
    this.actQuestion.activityQuestionDescription = this.fromParent.activityQuestionDescription;
    this.actQuestion.activityQuestion =  this.fromParent.activityQuestion;
    this.actQuestion.activityQuestionOrder = this.fromParent.activityQuestionOrder;


  } 
  onSubmit(){
    // authorized
    this.activityQuestionsService.editActivityQuestions(environment.apiUrl + 'activityQuestion/update', this.actQuestion).then(
            data=>{
        this.activeModal.close();
        this.passEntry.emit(this.actQuestion);
        this.toaster.success("Question Updated Successfully");
      });
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  closeUpdateModal(){
    this.activeModal.close();
  }
}
