import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/BIZ_CRM/biz_crm_services/user.service';
import { User } from 'src/app/BIZ_CRM/biz_crm_classes/user';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { Role } from 'src/app/models/role';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-bcrm-user',
  templateUrl: './add-bcrm-user.component.html',
  styleUrls: ['./add-bcrm-user.component.scss']
})
export class AddBcrmUserComponent implements OnInit {
 
  clicked = false;
  admin : User = new User();
  userExists = false;
  loggedInUser : any;
  theme : any; 
  roles : Role[] = [];

  constructor(private userService : UserService,
     private sharedDataService: SharedDataService,
     private roleService : RolesServiceService,
    public activeModal: NgbActiveModal,
    private subscriptionService : SubscriptionService,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    countryCodes : any[] = [];
     
    ngOnInit(): void {
               
    this.countryCodes = GlobalClass.CountryCodes;

    this.admin.roleId = "";

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.getRoles();
  }

  getRoles(){
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/bizOfficeRoles').then(
      rolesRes => {
      this.roles = rolesRes;
    }); 
  }

  onSubmit(){

    // AUTHORIZED
    this.subscriptionService.searchUserByEmailOfficeAdmin(environment.apiUrl + "user/searchUserByEmailBcrmOfficeAdmin/" + this.admin.email).then(
      data=>{
        this.userExists = data;

        if(this.userExists === true){

          this.toastr.error('Email already registered !')
          this.clicked = false;

        }else if(this.userExists === false){

          // AUTHORIZED
          this.userService.addBcrmUser(environment.apiUrl + 'bcrmUser/addUser', this.admin).then(
            res=>{
              this.admin = res;
              this.passEntry.emit(this.admin);
                  this.activeModal.close();
                  this.toastr.success('User Added Successfully');
            });

         }
      });


  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}