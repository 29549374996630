import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Skill } from 'src/app/courseManagementClasses/skill';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-skill',
  templateUrl: './edit-skill.component.html',
  styleUrls: ['./edit-skill.component.scss']
})
export class EditSkillComponent implements OnInit {
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  clicked=false;

  skill : Skill = new Skill();
  skillId!: string;

  unsafeUrl : any;
  safeUrl : any;

  selectedFile!: File;

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private sanitizer : DomSanitizer,
              private imageService : FileServiceService ,
              private skillService : SkillServiceService,
              ) { } 

  ngOnInit(): void {
this.skillId = this.fromParent.skillId;


this.skillService.getSkillById( environment.apiUrl + 'skill/getById/'+ this.skillId).then(
  res=>{
    this.skill = res;

    this.imageService.getSkillImage( this.skill.skillImage ).subscribe(
      data=>{
        this.unsafeUrl = URL.createObjectURL(data);
        this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
      });
  });
  }
  imageUpload = false;
  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
    this.imageUpload = true;
  }

  onSubmit(){

    this.skill.parentSkillId = null;

  this.skillService.updateSkill( environment.apiUrl + 'skill/update' , this.skill).then(
    data=>{
          

      if(this.imageUpload===false){
        this.activeModal.close();
        this.toaster.success("Skill updated successfully");
        this.passEntry.emit(this.skill);
      }else  if(this.imageUpload===true){
        let body = new FormData();
        body.append("file", this.selectedFile);
  
        this.imageService.uploadSkillImage(body, this.skill.skillId).subscribe(
          data=>{
  
            this.activeModal.close();
            this.toaster.success("Skill updated successfully");
            this.passEntry.emit(this.skill);
  
          });
      }

    });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }  

}
