import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  showResults = false;
  clicked : boolean = false;
  officeId : any;
  userExists = false;
  parentExists = false;
  type : any;
  selectedFile!: File;
  fileSelected = false;

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  resultDto : AddCombinedParentTeacher = new AddCombinedParentTeacher();

  constructor(private productService : ProductService,
              private toaster : ToastrService,
              public activeModal: NgbActiveModal,
              private subscriptionService  : SubscriptionService,
              private sharedDataService : SharedDataService
              ) { }

              loggedInUser: any;
              theme: any;

  ngOnInit(): void {


      this.officeId =  this.fromParent.officeId;

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  onFileSelected1(event: any){
    this.selectedFile = event.target.files[0];
    this.fileSelected = true;
  }

  onSubmit(){

    if(this.fileSelected === true){

    let body = new FormData();
    body.append("file", this.selectedFile);

    this.subscriptionService.createStudentsByFileUpload(environment.apiUrl + "file/botMemberCreationFileUpload", body).then(
      data=>{        
        this.resultDto = data;
        this.toaster.success("File uploaded successfully");
        this.passEntry.emit("Success");
        this.showResults = true;
      });

    }else{ 
      this.toaster.error("Select file");
    }
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
}
