import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { TicketSupportDefinition } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-support-definition';
import { TicketSupportRole } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-support-role';
import { TicketSupportService } from 'src/app/BIZ_CRM_TICKET_SUPPORT/services/ticket-support.service';
import { Role } from 'src/app/models/role';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-ticket-support-definition',
  templateUrl: './view-ticket-support-definition.component.html',
  styleUrls: ['./view-ticket-support-definition.component.scss']
})
export class ViewTicketSupportDefinitionComponent implements OnInit {
 
  closeResult !: string;
  clicked = false;
  ticketSupportDefinition : TicketSupportDefinition = new TicketSupportDefinition();
  feedbackForms : FeedbackForm[] = [];
  toppingsControl = new FormControl([]);
  loggedInUser : any; 
  theme : any;
  roles : Role[] = [];
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
              private formService : FeedbackFormService,
              private sharedDataService : SharedDataService,
              private roleService : RolesServiceService,
              private ngbModalService: NgbModal,
              private ticketSupportService : TicketSupportService,
              private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();  
    
    this.ticketSupportDefinition = this.fromParent.ticketSupportDefinition;
    
    let roleIds : string[] = [];
    this.ticketSupportDefinition.ticketSupportRoles.forEach((tsr)=>{
      roleIds.push(tsr.fkRoleId);
    });
    this.toppingsControl.setValue(roleIds);    
    this.getRoles();
  }

  getRoles(){
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/bizOfficeRoles').then(rolesRes => {
      this.roles = rolesRes;
    });
  }

  rolesSelection(value : any){
  this.ticketSupportDefinition.roleIds = [];
  value.forEach((val : any)=>{
    this.ticketSupportDefinition.roleIds.push(val);
  });    
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  onSubmit(ticketSupportRole : TicketSupportRole){
    
    this.ticketSupportService.changeDefaultTicketSupportRole(environment.apiUrl + "ticketSupport/changeDefaultTicketSupportRole/" + ticketSupportRole.ticketSupportRoleId + "/" +ticketSupportRole.fkTicketSupportDefinitionId ).then(
      data=>{
        this.ticketSupportDefinition.ticketSupportRoles.forEach((tsr)=>{
          if(tsr.ticketSupportRoleId === ticketSupportRole.ticketSupportRoleId){
            tsr.defaultRole = true;
          }else{
            tsr.defaultRole = false;
          }
        })
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
 
