import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { LocationGroup } from 'src/app/BIZ_CRM/biz_crm_classes/location-group';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { LocationGroupService } from 'src/app/BIZ_CRM/biz_crm_services/location-group.service';
import { GlobalClass } from 'src/app/global/global-class';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})

export class AddCustomerComponent implements OnInit {

  
  clicked = false;
  combinedPTC : Customer = new Customer();
  officeId!:string;
  loggedInUser: any;
  type : any;
  headerText : any;
  userExists = false;
  customerExists = false;
  showForm = false;
  custObject : Customer = new Customer();
  theme : any;
  locationGroups : LocationGroup[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private lgService : LocationGroupService,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef, 
              private customerService : CustomerService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService ,
              private subscriptionService  : SubscriptionService) {}

              @Input() fromParent: any;

     
              countryCodes : any[] = [];
     
  ngOnInit(): void {

    this.countryCodes = GlobalClass.CountryCodes;

    this.type = this.fromParent.type;
    this.custObject = this.fromParent.custObject;
    this.combinedPTC = this.custObject;

    this.getLocationGroups();

    if(this.type === 'CUSTOMER'){
      this.combinedPTC.customerType = 'C';
    }else if(this.type === 'LEAD'){
      this.combinedPTC.customerType = 'L';
    }

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;  
    this.theme = this.sharedDataService.getCardOfficeTheme();

  }

  getLocationGroups(){
    this.lgService.getLocationGroupsByOfficeId(environment.apiUrl + "locationGroup/getLocationGroupsByOfficeId").then(
      data=>{
        this.locationGroups = data;
      });
  }
  onSubmit(){

    this.subscriptionService.searchUserByEmail(this.combinedPTC.email).subscribe(
      data=>{
        this.userExists = data;
      });

    this.customerService.addCustomer(environment.apiUrl + 'bcrmCustomer/addCustomerOrLead', this.combinedPTC).then(
            data=>{
              this.combinedPTC = data;
        this.passEntry.emit(this.combinedPTC);
        this.activeModal.close();
        this.toaster.success("Customer Created Successfully");
      });

  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 
  
  closeUpdateModal(){
    this.activeModal.close();
  }

}