import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClassTeachers } from '../courseManagementClasses/class-teachers';
import { Subject } from '../courseManagementClasses/subject';
import { Teacher } from '../courseManagementClasses/teacher';
import { UserList } from '../courseManagementClasses/user-list';

@Injectable({
  providedIn: 'root'
}) 
export class TeacherService {

  baseURL =  environment.apiUrl + "teacher/getUserList";
  base1URL =  environment.apiUrl + "teacher/getTeachersInClass";
  base2URL =  environment.apiUrl + "teacher/getTeacherSubjects";
  base4URL =  environment.apiUrl + "teacher/getSubjectsByUroId";
  getTeacherClassSubjectsUrl =  environment.apiUrl + "teacher/getTeacherClassSubjects";
  getDetailsForTeacherSubjectDashboardUrl =   environment.apiUrl + "teacher/getDetailsForTeacherSubjectDashboard"
  getTeacherAccountInfoUrl = environment.apiUrl + "teacher/getTeacherAccountInfo";
  base3URL =   environment.apiUrl + "teacher/getAllTeachersInOffice";
  base5URL =   environment.apiUrl + "teacher/statusUpdate";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = null;
    this.authToken = localStorage.getItem('auth-token');
  }
  
  getUserList(officeId : any): Observable<UserList[]>{
    return this.httpClient.get<UserList[]>(`${this.baseURL}/${officeId}`);
  }
  getUserListAuth(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  addTeacher(teacher : Teacher):Observable<Teacher>{
    return this.httpClient.post<Teacher>( environment.apiUrl + 'teacher/add',teacher);
  }

  addTeacherUsingUserIdCA(teacher : Teacher):Observable<Teacher>{
    return this.httpClient.post<Teacher>( environment.apiUrl + 'teacher/addTeacherUsingUserIdCA',teacher);
  }

  getTeachersInaClass(classId : string): Observable<ClassTeachers[]>{
    return this.httpClient.get<ClassTeachers[]>(`${this.base1URL}/${classId}`);
  }

  getSubjects(userId : string): Observable<Subject[]>{
    return this.httpClient.get<Subject[]>(`${this.base2URL}/${userId}`);
  }

  getSubjectsByUroId(userRoleOfficeId : string): Observable<Subject[]>{
    return this.httpClient.get<Subject[]>(`${this.base4URL}/${userRoleOfficeId}`);
  }

  getAttendanceByDateAndDateTime(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  markAttendanceAbsentForOldSession(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getAttendanceByStudentId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  markAttendancePresentForOldSession(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  markAttendance(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentsFromSubscriptionsForAttendance(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getTeacherClasssesWithStudents(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getTeacherClassSubjects(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getDetailsForTeacherSubjectDashboard(avenue: string): Promise<any> {
    
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getTeacherAccountInfo(userId : string): Observable<Object>{
    return this.httpClient.get<Object>(`${this.getTeacherAccountInfoUrl}/${userId}`);
  }

  getAccountInfoForProfile(avenue: string): Promise<any> {
    
    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getTeachersInOffice(officeId : string): Observable<any[]>{
      return this.httpClient.get<any[]>(`${this.base3URL}/${officeId}`);
    }
    getTeachersInOfficeAuth(avenue: string): Promise<any> {
    
      this.authToken = localStorage.getItem('auth-token');
  
      const self = this;
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.authToken
      });
      return new Promise((resolve, reject) => {
        self.httpClient.get(avenue, { headers: headers })
          .toPromise()
          .then((data) => {
            resolve(data);
          }).catch((err) => {
          });
      });
    }

    
    teacherStatusUpdate(teacherId : any): Observable<any>{
      return this.httpClient.delete<any>(`${this.base5URL}/${teacherId}`);
    }

}
