import { FormResponse } from "./form-response";

export class FormRespondent {
    id:any;
	activeStatus:any;
    timestamp:any;
    formattedTimestamp:any;
	fkFormActiveId:any;
    userInfo : any;
    fkUserRoleOfficeId : any;
    fkCustomerId : any;

    fkOfficeId : any;
    viewResponses = false;
    formResponsesDto : FormResponse[] = [];

    sortedResponses : FormResponse[] = [];
    fkTicketSupportDefinitionId : any;

    botScheduleId:any;
	botScheduleDetailsId:any;

    fkBotUserId:any;
	fkBotUserName:any;
	fkBotUserPhone:any;
}
