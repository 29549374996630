import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Class } from 'src/app/courseManagementClasses/class';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { ClassService } from 'src/app/service/class.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.scss']
})
export class AddClassComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  constructor(private classService : ClassService,
              private productService : ProductService ,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService,
              public activeModal: NgbActiveModal ) { }
 
  class : Class = new Class();
  officeId!: string;
  products : Product[] = [];
  clicked = false;
  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.class.fkProduct = "";
    this.officeId = this.fromParent.officeId; 

    this.productService.getProductListByOfficeId(this.officeId).subscribe(
      data =>{
        this.products = data;
      });
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
    }
  
    getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
    
  onSubmit(){

    this.class.fkOfficeId = this.officeId;

    this.classService.addClass(this.class).subscribe(
      data =>{
        this.passEntry.emit(this.class);
        this.activeModal.close();
        this.toaster.success("Class created successfully");
    });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
