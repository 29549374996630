import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Shipment } from '../crmClasses/shipment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  private getUrl =  environment.apiUrl + "shipment/getDtoById"

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }
  
  addShipment(avenue: string, shipment : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {

    const req = new HttpRequest('POST', avenue, shipment, {
      reportProgress: true,
      responseType: 'text'
    });

    self.httpClient.post(avenue, shipment, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}
 
  getAllShipmentsByOfficeId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getByCustomerId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
   
   
 
  getShipmentById(shipmentId : string):Observable<Shipment>{
    return this.httpClient.get<Shipment>(`${this.getUrl}/${shipmentId}`);
   } 
  
  updateShipment(avenue: string, shipment : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
  
      const req = new HttpRequest('PUT', avenue, shipment, {
        reportProgress: true,
        responseType: 'text'
      });
  
      self.httpClient.put(avenue, shipment, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
changeStatus(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

  const self = this;
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.authToken
  });
  return new Promise((resolve, reject) => {
    self.httpClient.delete(avenue, { headers: headers })
      .toPromise()
      .then((data) => {
        resolve(data);
      }).catch((err) => {
      });
  });
}

} 



