<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">{{fromParent.header}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form (ngSubmit)="onSubmit()" #adminForm="ngForm"> 
        
        <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>
        
        <div *ngIf="matSpinnerActive===false">

        

        <div class="form-group row">

            <div class="col-md-6">
            <label class="required" for="memberName"> Name</label>
            <input #memberName="ngModel" minlength="3" maxlength="50" required class="form-control" type="text" 
            name="memberName" id="memberName" [(ngModel)]="botMemberInfo.memberName">
            <div class="alert alert-danger" *ngIf="memberName.touched && !memberName.valid"> Name is Required</div>
            </div>
          
            <div class="col-md-6">
            <label class="required" for="memberEmail">Email</label>
            <input  #last="ngModel" email minlength="3" required class="form-control" type="text"
             name="memberEmail" id="memberEmail" [(ngModel)]="botMemberInfo.memberEmail">
            <div class="alert alert-danger" *ngIf="last.touched && !last.valid">Email is Required</div>
          </div>

         

        </div>

        <!-- <div class="form-group row">

            <div class="col-md-6">
            <label class="required" for="memberSpecialization"> Specialization</label>
            <input #memberSpecialization="ngModel" minlength="3" required class="form-control" type="text" 
            name="memberSpecialization" id="memberSpecialization" [(ngModel)]="botMemberInfo.memberSpecialization">
            <div class="alert alert-danger" *ngIf="memberSpecialization.touched && !memberSpecialization.valid"> Specialization is Required</div>
            </div>
          
            <div class="col-md-6">
            <label for="memberDescription">Description</label>
            <input class="form-control" type="text" 
            name="memberDescription" id="memberDescription" [(ngModel)]="botMemberInfo.memberDescription">
          </div>

         

        </div> -->

        <div class="form-group row">
         
            <div class="col-md-6">
                <label class="required" for="memberPhone">Phone </label>
                <input #phone="ngModel" required minlength="10" maxlength="250" class="form-control" type="number" 
                name="memberPhone" id="memberPhone" [(ngModel)]="botMemberInfo.memberPhone">
                <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
            </div>
        
            <!-- <div class="col-md-6">
              <label >Gender </label> <br>
              <mat-radio-group aria-label="gender" [(ngModel)]="botMemberInfo.memberGender">
                <mat-radio-button name="gender" id="male" value="male"  >Male</mat-radio-button>
                <mat-radio-button name="gender" id="female" value="female"  >Female</mat-radio-button>
                <mat-radio-button name="gender" id="other" value="other"  >Other</mat-radio-button>
              </mat-radio-group>
            </div> -->

           

            <div class="col-md-6">
              <!-- <label for="orgType">Organisation Type</label> -->
              <label for="orgType">Specialization</label>
              <input maxlength="250" class="form-control" type="text" 
              name="orgType" id="orgType" [(ngModel)]="botMemberInfo.orgType">
              </div>

        </div>

     


      <!-- <div class="form-group row">

        <div class="col-md-4">
        <label for="orgName">Organisation Name</label>
        <input   maxlength="250" class="form-control" type="text"
        name="orgName" id="orgName" [(ngModel)]="botMemberInfo.orgName">
        </div>
      
        <div class="col-md-4">
        <label for="orgPhone">Organisation Phone</label>
        <input   maxlength="250" class="form-control" type="text"
        name="orgPhone" id="orgPhone" [(ngModel)]="botMemberInfo.orgPhone">
        </div>
      
        <div class="col-md-4">
          <label for="orgEmail">Organisation Email</label>
          <input   maxlength="250" class="form-control" type="text"
          name="orgEmail" id="orgEmail" [(ngModel)]="botMemberInfo.orgEmail">
          </div>
        
      </div> -->
      
      <!-- <div class="form-group row">

        <div class="col-md-4">
          <label for="orgType">Organisation Type</label>
          <input maxlength="250" class="form-control" type="text" 
          name="orgType" id="orgType" [(ngModel)]="botMemberInfo.orgType">
          </div>
      
        <div class="col-md-4">
          <label for="orgLocationName">Organisation Location Name</label>
          <input maxlength="250" class="form-control" type="text" 
          name="orgLocationName" id="orgLocationName" [(ngModel)]="botMemberInfo.orgLocationName">
          </div>
      
        

        <div class="col-md-4">
          <label for="orgAddress">Organisation Full Address</label>
          <input maxlength="250" class="form-control" type="text" 
          name="orgAddress" id="orgAddress" [(ngModel)]="botMemberInfo.orgAddress">
          </div>
      
      </div> -->
      

      <div class="form-group row">

        <div class="col-md-6">
          <label for="orgName">Organisation Name</label>
          <input   maxlength="250" class="form-control" type="text"
          name="orgName" id="orgName" [(ngModel)]="botMemberInfo.orgName">
        </div>
      
        <div class="col-md-6">
          <label for="orgLocationName"> Location</label>
          <input maxlength="250" class="form-control" type="text" 
          name="orgLocationName" id="orgLocationName" [(ngModel)]="botMemberInfo.orgLocationName">
          </div>
      
        

      
      
      </div>
  
        </div>
 
</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="matSpinnerActive=true;onSubmit();clicked=true">save</button>
</div>