import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Class } from '../courseManagementClasses/class';

@Injectable({
  providedIn: 'root'
})
export class ClassService {

  baseURL2 =  environment.apiUrl + "class/getListByOfficeId";
  statusChangeUrl =  environment.apiUrl + "class/statusUpdate";
  getUrl =  environment.apiUrl + "class/getById";
  updateUrl =  environment.apiUrl + "class/update";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  addClass(classs : Class):Observable<Class>{
    return this.httpClient.post<Class>( environment.apiUrl + 'class/add', classs);
  }

  changeStatus(classId : string) : Observable<Object>{
    return this.httpClient.delete(`${this.statusChangeUrl}/${classId}`);
  }

  getClassById(classId : string):Observable<Class>{
    return this.httpClient.get<Class>(`${this.getUrl}/${classId}`);
   } 

   updateClass(classs:Class):Observable<Object>{
    return this.httpClient.put(`${this.updateUrl}/`,classs);
  }

  getClassListByOfficeId(officeId : string): Observable<Class[]>{
    return this.httpClient.get<Class[]>(`${this.baseURL2}/${officeId}`);
  }

  getClassListByOfficeIdAuth(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
  getClassListByOfficeIdCA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getListByProductIdCA(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
}
