import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { AssignmentSubmissionDashboardComponent } from '../assignment-submission-dashboard/assignment-submission-dashboard.component';

@Component({
  selector: 'app-student-assignment-dashboard',
  templateUrl: './student-assignment-dashboard.component.html',
  styleUrls: ['./student-assignment-dashboard.component.scss']
})
export class StudentAssignmentDashboardComponent implements OnInit {
  studentAssignmentsExist = false;
  selectedId! : string;
  activityState : boolean = false;
  classId : any;
  subjectId : any;
  loggedInUser : any;
  showSpinner : boolean = false;
  navbarTitle : any;
  userName: string = 'User';
  theme: string = '';
  userId!: string;
  unsafeUrl : any;
  safeUrl : any;
  public screenWidth: any;
  public screenHeight: any;
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  unsafeSubUrl : any;
  safeSubUrl : any;
  collapsedState2 : boolean = false ;
  unsafeStudentUrl : any;
  safeStudentUrl : any;
  studentId : any;
  officeId : any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  allAssignmentList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    safeUrl : '',
    selectedState : '',
    spontaneous : '',
    assignmentActiveStatus : '',
    studentActivityPlannerCompletionStatus: ''
  }]

activeAssignmentList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    safeUrl : '',
    selectedState : '',
    spontaneous : '',
    assignmentActiveStatus : '',
    studentActivityPlannerCompletionStatus: ''

  }]

completedAssignmentList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    safeUrl : '',
    selectedState : '',
    spontaneous : '',
    assignmentActiveStatus : '',
    studentActivityPlannerCompletionStatus: ''

  }]

NewAssignmentList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    safeUrl : '',
    selectedState : '',
    spontaneous : '',
    assignmentActiveStatus : '',
    studentActivityPlannerCompletionStatus: ''

  }]

  info : any = {
    activityPlannerDescription: "",
    activityPlannerId: "",
    activityPlannerImage: "",
    activityPlannerName: "",
    activityPlannerOrder: "",
    activityPlannerStatus: "",
    fkSubjectId: "",
    studentCount: "",
    activityCompletionStatus : "",
    studentActivityPlannerCompletionStatus : "",
    
    activityVideoLinkList : [{
                              actVideoLinkId: "",
                              actVideoLinkName: "",
                              actVideoLink: "",
                              actVideoLinkDescription: "",
                              fkActivityPlannerId: "",
                              actVideoLinkStatus: "",
                              urlSafe: ""
                            }]
}

  constructor(private sharedDataService: SharedDataService, 
    public router: Router,
    private route : ActivatedRoute,
    private activityPlannerService : ActivityPlannerService,
    private ngbModalService: NgbModal,
    private imageService: FileServiceService,
    private sanitizer : DomSanitizer ,
    private studentService : StudentService, 
    public bsModalRef: BsModalRef) {
    // super();
  this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
  this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
  this.theme = this.sharedDataService.getCardOfficeTheme();
  this.userId = this.loggedInUser.userId;
  this.officeId = this.loggedInUser.offices[0].id;

}

ngOnInit(): void {

this.navbarTitle = GlobalClass.navbarTitle;

this.screenWidth = window.innerWidth;
this.screenHeight = window.innerHeight;

this.userId = this.loggedInUser.userId;

this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
this.theme = this.sharedDataService.getCardOfficeTheme();
this.userId = this.loggedInUser.userId;

this.subjectId = this.route.snapshot.params['subjectId'];
this.studentId = this.route.snapshot.params['studentId'];
this.classId = this.route.snapshot.params['classId'];

this.getStudentAssignments();

}

submitAssignment(){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( AssignmentSubmissionDashboardComponent,

    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
    
  let data = {
    activityPlannerId: this.info.activityPlannerId,
    studentId : this.studentId,
    officeId : this.officeId
      }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      }); 
  modalRef.componentInstance.passEntry.subscribe((receivedEntry : "") => {
    this.info.studentActivityPlannerCompletionStatus = "1";
  });
}

collapseActivityCardContent2(state : boolean){
  if(state === true){
    this.collapsedState2 = false;
  } else if(state === false){
    this.collapsedState2 = true;
  }
 }

getStudentAssignments(){
this.studentService.getAssignmentsForStudentDashboard(this.studentId, this.subjectId, this.classId).subscribe(
  data=>{
  this.allAssignmentList = data;
  
  this.allAssignmentList.forEach( (value) => {
        
    this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
      data=>{
        this.unsafeUrl = URL.createObjectURL(data);
        this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);

        value.safeUrl = this.safeUrl;
      });
  });
  this.studentAssignmentsExist = true;
  });
}

select(id : string){
  this.selectedId = id;

  this.allAssignmentList.forEach((value)=>{
     if(value.activityPlannerId === id){
      value.selectedState = true;
     }else {
      value.selectedState = false;
     }
  });

  this.activeAssignmentList.forEach((value)=>{
    if(value.activityPlannerId === id){
     value.selectedState = true;
    }else {
     value.selectedState = false;
    }
 });

 this.completedAssignmentList.forEach((value)=>{
  if(value.activityPlannerId === id){
   value.selectedState = true;
  }else {
   value.selectedState = false;
  }
});

this.NewAssignmentList.forEach((value)=>{
  if(value.activityPlannerId === id){
   value.selectedState = true;
  }else {
   value.selectedState = false;
  }
});

this.showSpinner = true;

this.activityPlannerService.getActivityPlannerForSwap(this.selectedId, this.studentId).subscribe(
  data=>{
    
      this.info = data;

      if(this.info.activityVideoLinkList === undefined|| this.info.activityVideoLinkList.length === 0){
        this.showSpinner = false;
        this.activityState = true;
      }
      let count : number = 0;
      this.info.activityVideoLinkList.forEach((val : any)=>{
        count++;
      this.url =  val.actVideoLink;
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      val.urlSafe = this.urlSafe


      if(count=== this.info.activityVideoLinkList.length ){
        this.showSpinner = false;
        this.activityState = true;
      }

    });

      this.imageService.getActivityPlannerImage(this.info.activityPlannerImage).subscribe(
        res=>{
          this.unsafeUrl = URL.createObjectURL(res);
          this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
        });
        // this.toaster.success('Activity Changed Successfully');
        this.collapsedState2 = false;
  });
}


goToAccountPage(){
  this.router.navigate(['user-account',this.userId]);
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

logOut(): void {
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
    {
      size : 'md',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = {
   email : this.loggedInUser.email 
  }

  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  });  
}

goBack() {
  this.router.navigate(['dashboard/student']).then();
}

reloadPage() {
  window.location.reload();
}

currentRole = "STUDENT";
switchDashboard(){
  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
    {
      size : 'sm',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  let data = { 
    userId : this.userId,
    currentRole : this.currentRole
  }
  modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  });    
}

}
