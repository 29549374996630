import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { Shipment } from 'src/app/crmClasses/shipment';
import { GlobalClass } from 'src/app/global/global-class';
import { InvoiceService } from 'src/app/service/invoice.service';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-shipment',
  templateUrl: './add-shipment.component.html',
  styleUrls: ['./add-shipment.component.scss']
})
export class AddShipmentComponent implements OnInit {

  clicked = false;

  invoices: InvoiceList[] = [];

  shipment : Shipment = new Shipment();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;
  customerId: any;
  // invoice : any;
  loggedInUser : any;
  theme : any;
  constructor(private invoiceService : InvoiceService,
              public activeModal: NgbActiveModal,
              private sharedDataService: SharedDataService,
              private shipmentService : ShipmentService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService  ) { }

  ngOnInit(): void {
    this.customerId = this.fromParent.customerId;
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 

    if(this.customerId === null){
    this.getInvoiceNo(); 
    }else if(this.customerId !== null){
      this.invoiceService.getByCustomerId(environment.apiUrl + "completeInvoice/getByCustomerId/" +  this.customerId).then(
        data=>{
          this.invoices = data;
        });
    }
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  getInvoiceNo(){
    // this.invoiceService.getInvoiceList().subscribe(data => {
    //   this.invoices= data;
    // }); 
  }

  // authorized
  onSubmit(){
    this.shipmentService.addShipment(environment.apiUrl + 'shipment/add', this.shipment).then(
    data =>{
      this.shipment = data;
      this.passEntry.emit(this.shipment);
      this.activeModal.close();
      this.toaster.success("Shipment Created Successfully");
    })
  }
  
  closeModal(){
    this.activeModal.close();
  }
 
}
