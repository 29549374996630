import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { GlobalClass } from 'src/app/global/global-class';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-overview-modal',
  templateUrl: './overview-modal.component.html',
  styleUrls: ['./overview-modal.component.scss']
})
export class OverviewModalComponent implements OnInit {

  showSpinner = true;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  safeImageUrl: any;
  unsafeImageUrl: any;
 
  studentId!: string;

  details : any ={
    dob: "",
    email: "",
    phone: "",
    studentImage: "",
    studentName: ""
  }

  allActivitiesList : any[] = [{
    activityPlannerId : '',
    activityPlannerName : '',
    activityPlannerDescription : '',
    activityPlannerOrder : '',
    activityPlannerStatus : '',
    fkSubjectId : '',
    activityPlannerImage : '',
    activityPlannerCompletionStatus : '',
    studentActivityPlannerCompletionStatus : '',
    safeUrl : '',
    selectedState : '',
    studentAttempts : ''
  }]
  lineChartData: any[] = [
    { data: [8, 7, 9, 8, 7, 8], label : 'progress'},
  ];
  

  lineChartLabels: Label[] = ['', '', '', '', '', ''];

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: '#b9d8f3',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType : ChartType = 'line';

  lineChartOptions = {
    
    legend: {
      onClick: (e: { stopPropagation: () => any; }) => e.stopPropagation()
  },
 
    elements: {
      line: {
          tension: 0
      }
  },
    responsive: true,
    scales: {
        xAxes: [{
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
               display: false
            }
        }],
        yAxes: [{
          
            display: false
       ,
            gridLines: {
              drawBorder: false,
              display: false,
                color: "rgba(0, 0, 0, 0)",
                
            }   
        }]
    }
}
  constructor(public activeModal: NgbActiveModal, private skillService : SkillServiceService,
    private sharedDataService : SharedDataService, private imageService : FileServiceService,
    private sanitizer : DomSanitizer,
    private activityPlannerService : ActivityPlannerService,
    ) { }

    loggedInUser: any;
    theme: any;
    subId:any;

    skillResults : any[] =[{

      parentSkillId : '',
      skillDescription: '',
      skillId: '',
      skillName:'',
      skillStatus: '',
      collapsedState: '',
      safeUrl : '',
      skillImage : '',

      resultlist: [{
                    fkSkillId: '',
                    fkStudentId:'',
                    fkSubjectId: '',
                    parentSkillName: '',
                    resultHistory: '',
                    resultId: '',
                    resultStatus: '',
                    resultSuggestion: '',
                    skillDescription: '',
                    skillName: '',
                    skillScore:'',
                    safeUrl : '',
                    skillImage : '',
                    lastScore : ''
                    }]
        }];

        actualResultlist:any[] = [{
          fkSkillId: '',
          fkStudentId:'',
          fkSubjectId: '',
          parentSkillName: '',
          resultHistory: '',
          resultId: '',
          resultStatus: '',
          resultSuggestion: '',
          skillDescription: '',
          skillName: '',
          skillScore:'',
          safeUrl : '',
          skillImage : '',
          lastScore : ''
          }]
          datae: any[] = []; 
  ngOnInit(): void {

    this.studentId = this.fromParent.studentId;
    this.details.studentName = this.fromParent.name;
    this.details.phone = this.fromParent.phone;
    this.details.email = this.fromParent.email;
    this.details.dob = this.fromParent.dob;
    this.safeImageUrl = this.fromParent.image;

    this.subId =  this.fromParent.subId;
    this.getActivitiesForTimeline();
    this.getSkillResults();
  
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

chartReady = false;
skillReportNotReady = false;

getSkillResults(){
   
    this.skillService.getStudentSkillResultForReport(environment.apiUrl + "result/getStudentSkillResultForReport/" + this.studentId).then(
      res=>{
        this.actualResultlist = [];
        this.skillResults = res;        

        if(this.skillResults !== null && this.skillResults !== undefined  && this.skillResults.length > 0){

        this.skillResults.forEach((val)=>{
  
          val.collapsedState = false;
  
          this.imageService.getSkillImage(val.skillImage ).subscribe(
            data=>{
              this.unsafeUrl = URL.createObjectURL(data);
              val.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
            });
  
          val.resultlist.forEach((value : any)=>{
  
            this.imageService.getSkillImage( value.skillImage ).subscribe(
              data=>{
                this.unsafeUrl = URL.createObjectURL(data);
                value.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
                });
  
              if(value.resultHistory !== undefined && value.resultHistory !== null){
  
            var myArray = value.resultHistory.split(",");
    for(var i=0; i<myArray.length; i++) { 
      this.datae[i] = +myArray[i]; 
    }
  
    value.lastScore = this.datae[this.datae.length - 1] 
      
            value.lineChartData = [
              { data: this.datae,
                 label : 'progress'},
            ];
            this.datae = [];
          }
  
          value.parentSkillName = val.skillName;
          value.skillScore = Math.round(value.skillScore * 10) / 10;
          this.actualResultlist.push(value);
          this.chartReady = true;
          });
        });

        if(this.actualResultlist === null || this.actualResultlist.length === 0){
          this.skillReportNotReady = true;
        }
        
      }

        
      });
  }

unsafeUrl : any;
safeUrl : any;
getActivitiesForTimeline(){
  this.activityPlannerService.getStudentAPsForTimeline(environment.apiUrl + "activityPlanner/getStudentAPsForTimeline/" + this.studentId + "/" + this.subId).then(
    data=>{
      this.allActivitiesList = data;
      this.allActivitiesList.forEach( (value) => {
        this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
          data=>{
            this.unsafeUrl = URL.createObjectURL(data);
            this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
            value.safeUrl = this.safeUrl;
          });
       });
    });
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

  closeModal(){
    this.activeModal.close(); 
  }
  
}
