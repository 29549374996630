import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { CrmServiceService } from "../../services/crm-service.service";
import { CrmList } from "../../classes/crm-list";

@Component({
  selector: "app-crm-lists-modal",
  templateUrl: "./crm-lists-modal.component.html",
  styleUrls: ["./crm-lists-modal.component.scss"],
})
export class CrmListsModalComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;
  crmList: CrmList = new CrmList();

  constructor(
    private sharedDataService: SharedDataService,
    private crmService: CrmServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  ngOnInit(): void {
    if (this.fromParent.context === "create crmList") {
    }

    if (this.fromParent.context === "edit crmList") {
      this.crmList = this.fromParent.crmList;
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  createOrEditList() {
    this.clicked = true;
    this.crmService
      .postMethod(
        environment.apiUrl + "bizCrm/createOrUpdateCrmList",
        this.crmList
      )
      .then((res) => {
        this.crmList = res;
        this.activeModal.close();
        this.passEntry.emit(this.crmList);
        if (this.fromParent.context === "create crmList") {
          this.toastr.success("List created successfully !");
        }
        if (this.fromParent.context === "edit crmList") {
          this.toastr.success("List updated successfully !");
        }
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
