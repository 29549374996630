export class CustomerCommunications {

    id : any;
	activeStatus: any;
	currentActionDateTime: any; 
	nextActionDateTime: any;
	fkCustomerId: any;
	message: any;
	messageType: any;
	remarks: any;
	fkAssignedIndividualUroId: any;

}
