<div class="modal-header" style=" max-height: 25% !important;" [style.background-color]='getColor()'>
    <h2 style="color: white;" class="modal-title pull-left">{{combinedPTC.firstName}}</h2>
   
  
  
    <div class="row pull-right">
  
      <button style=" color: black;background-color: white; ;" class="btn btn-info" (click)="editCustomer();">Edit Profile</button> 
  
    <button type="button" class="close" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button> 
  
  
  </div>
   
  </div> 
  
   <div class="modal-body">
    <div class="card">
      <div class="card-body" style="padding: 0.25em;">
  
       <div class="content" style="margin: 0%;padding: 0%;" [style.display]="profile ? 'block' : 'none'">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="100%" >
            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
             
              <div class="email" style="text-align: center; color: black; word-wrap: break-word;" >
                <a><i class="fa fa-phone" aria-hidden="true">{{combinedPTC.countryDialCode}} {{combinedPTC.phoneNumber}}</i></a> <br>
                <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.email}}</i></a>
            </div>
             
            </mat-card>
          </div>
  
          <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="100%" >
            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
             
              <div class="email" style="text-align: center; color: black; word-wrap: break-word;" >
                <a><i class="fa fa-address-card" aria-hidden="true"> {{combinedPTC.customerAddress}} </i></a> <br>
            </div>
             
            </mat-card>
  
          </div>
  
          <div fxFlex="33%" fxFlex.xs="50%" fxFlex.sm="100%" >
            <mat-card class="mat-elevation-z0" style="border-radius: 25px;margin: 0%; padding: 0%;">
             
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
  
                  <div class="email" style="text-align: center; color: black; word-wrap: break-word;" >
                    <a>Contact Person :</a> <br>
                    <a><i class="fa fa-male" aria-hidden="true"> {{combinedPTC.contactPerson}}</i></a> <br>
                    <a style="word-wrap: break-word;"><i class="fa fa-envelope" aria-hidden="true"> {{combinedPTC.contactPersonEmail}}</i></a> <br>
                    <a style="word-wrap: break-word;"><i class="fa fa-phone" aria-hidden="true">{{combinedPTC.contactPersonCountryDialCode}} {{combinedPTC.contactPersonPhone}}</i></a>
                  </div>
                 
            </div>
             
            </mat-card>
          </div>
         
        </div>
        </div> 
  
        <br>
  
        <div class="table-header row" [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
  
             <div style="float: left;">
              <select class="form-select form-select-sm"  [(ngModel)]="selectedOption" (change)="onChange($event)" aria-label=".form-select-sm example">
                <option  value="0" disabled selected>Choose an option</option>
                <option *ngIf="type === 'CUSTOMER'"  value="1"> Invoice</option>
                <option *ngIf="type === 'CUSTOMER'"  value="2">Payments </option>
                <option *ngIf="type === 'CUSTOMER'" value="3">Shipments</option>
                <option  value="4">Communications</option>
              </select>
            </div>
    
            <a *ngIf="showInvoices === true" style="cursor:pointer;" class="float-right" (click)="addNewInvoice()">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
           
            <a *ngIf="showPayments === true" style="cursor:pointer;" class="float-right" (click)="addNewPayment()">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
  
            <a *ngIf="showShipments === true" style="cursor:pointer;" class="float-right" (click)="addNewShipment()">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
            
            <a *ngIf="showComs === true" style="cursor:pointer;" class="float-right" (click)="addNewCustomerCommunication()">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>
           
          </div> 
        </div>
  
     <!-- Invoices -->
     <div  *ngIf="showInvoices === true">
    
      <div class="card">
        <div class="card-body table-responsive">
        
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead class="tableheader">
              <tr>
                <th>Date</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead> 
            <tbody>
              <tr class="td-height" *ngFor="let subs of invoices">
                <td class="td-height">{{subs.invoiceDate}}</td>
                <td class="td-height">{{subs.invoiceRemarks}}</td>
                <td *ngIf="invoices.length !== 0 && invoices !== undefined">
                   <button style="margin: 1%;" (click)="editInvoice(subs.invoiceId)" class="btn btn-primary">Edit</button>
                   <button style="margin: 1%;" (click)="viewInvoiceDetailsModal(subs.invoiceId)" class="btn btn-dark">View</button>
  
                  </td>
                
              </tr>
            </tbody>
          </table> 
  
        </div>
      </div>
    
  </div>  
        
        <!-- Payments -->
     <div  *ngIf="showPayments === true">
          
      <div class="card">
        <div class="card-body table-responsive">
        
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead class="tableheader">
              <tr>
                <th>Date</th>
                <th>Method</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead> 
            <tbody>
              <tr class="td-height" *ngFor="let subs of payments">
                <td class="td-height">{{subs.paymentDate}}</td>
                <td class="td-height">{{subs.paymentMethod}}</td>
                <td class="td-height">{{subs.paymentAmount}}</td>
                <td>
                  <button class="btn btn-primary" (click)="editPayment(subs.paymentId);">Edit</button>
                </td>
                
              </tr>
            </tbody>
          </table> 
  
        </div>
      </div>
    
  
  </div>
  
           <!-- Shipments -->
           <div  *ngIf="showShipments === true">
    
      
          
            <div class="card">
              <div class="card-body table-responsive">
              
                <table class="table table-striped table-bordered table-sm row-border hover">
                  <thead class="tableheader">
                    <tr>
                      <th>Shipment Partner</th>
                      <th>Shipped Date</th>
                      <th>Address</th>
                      <th>Action</th>
                    </tr>
                  </thead> 
                  <tbody>
                    <tr class="td-height" *ngFor="let subs of shipments">
                      <td class="td-height">{{subs.shipmentPartner}}</td>
                      <td class="td-height">{{subs.shippedDate}}</td>
                      <td class="td-height">{{subs.shipmentAddress}}</td>
                      <td>
                        <button style="margin: 1%;" (click)="editShipment(subs.shipmentId);" class="btn btn-primary">Edit</button>
                      </td>
                    
                    </tr>
                  </tbody>
                </table> 
        
              </div>
            </div>
          
        
        </div>
   
        <!-- communications -->
        <div  *ngIf="showComs === true">
    
      
          
          <div class="card">
            <div class="card-body table-responsive">
            
              <table class="table table-striped table-bordered table-sm row-border hover">
                <thead class="tableheader">
                  <tr>
                    <th>Action date</th>
                    <th>Next Action Date</th>
                    <th>Message</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead> 
                <tbody>
                  <tr class="td-height" *ngFor="let subs of coms">
                    <td class="td-height">{{subs.currentActionDateTime}}</td>
                    <td class="td-height">{{subs.nextActionDateTime}}</td>
                    <td class="td-height">{{subs.message}}</td>
                    <td class="td-height">{{subs.remarks}}</td>

                    <td>
                      <button style="margin: 1%;" (click)="editCommunications(subs);" class="btn btn-primary">Edit</button>
                    </td>
                  
                  </tr>
                </tbody>
              </table> 
      
            </div>
          </div>
        
      
      </div>
  
      </div>
    </div>
   </div>
   
  