
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
            <a  onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-fw fa-arrow-left"></i> Go back </a>
          </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

 
 
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a style="color:#0275d8 ;"  (click)="goBack()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Course</a></li>
      <li style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Curriclum</li>
    </ol>
  </nav>


 
  <div class="row col-lg-12 col-md-12" >



    <div class="col-lg-3 col-md-12" style="padding: 0%;">
    
    
      <div class="table-header row col-lg-12 col-md-12" [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
        <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
          <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>
    
          <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
            Curriculum
          </span>
          
    
    
        </div> 
      </div>
    
     
      <div class="card mat-elevation-z12 col-lg-12 col-md-12" >
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" >
          <mat-tree-node style="cursor: pointer;" (click)="getDetails(node.curriculumId)" *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            {{node.name}}
          </mat-tree-node>
          
          <mat-tree-node style="cursor: pointer;" (click)="getDetails(node.curriculumId)" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.name}}
          </mat-tree-node>
        </mat-tree>
      </div>
    
    
    
    </div>
    
    <div class="col-lg-9 col-md-12" style="padding: 0%;" *ngIf="infoState === false">
    
    
      
          <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
            Curriculum
          </span>
         
    
          <div style="text-align: center;margin-top: 5%;" *ngIf="infoState === false">

            <a style="text-align: center;font-size: 1.3em; font-weight: bold; color: black;">
              Click on a Curriculum name to explore.
            </a>
          </div>
    
    
    
    
    
    </div>
    
    <div class="col-lg-9 col-md-12" style="padding: 0%;" *ngIf="infoState === true">
    
    
      <div class="table-header row col-lg-12 col-md-12" [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
        <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
          <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

          <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
            {{curriculumObject.name}}
          </span>
          
        
    
    
        </div> 
      </div>
    
     
    <div class="card mat-elevation-z12 ">
      <br>
       <!-- <h4 style="padding: 1em;">{{curriculumObject.description}}</h4> -->
  <div *ngIf="curriculumObject.description !== null && curriculumObject.description !== undefined" style="padding: 1em;">
    {{(readMore) ? curriculumObject.description : curriculumObject.description | slice:0:150}} <span *ngIf="!readMore"></span>
    <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!readMore && curriculumObject.description.length >150" (click)="readMore=true">...</a>
    <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="readMore && curriculumObject.description.length >150" (click)="readMore=false">[Read less]</a>

</div>
      <div>
      
    <mat-tab-group  style="padding: 1em;" dynamicHeight>
    <mat-tab label="Activities">   
      <br *ngIf="apDontExist === true">
          <h3 *ngIf="apDontExist === true" style="color: black;text-align: center;">No Activity to display !</h3>
          <br *ngIf="apDontExist === true">
      <div fxLayout="row wrap" fxLayoutGap="16px grid" >
        <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let card of activityPlanners2">
          <mat-card class="mat-elevation-z12" style="border-radius: 25px;margin: 1em;">
           
          <div style="width: 100%; cursor:pointer;margin-bottom: 10%; padding: 1em;">  
            
        
   
        </div>
          
            <div style="width: 100%;text-align: center;">
              <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="card.safeUrl">
            </div>
           <br>
              <div style="text-align: center;">
              <h3 style="color: black;">{{card.activityPlannerName}} </h3> 
          </div>
      
          
             <!-- <div style="text-align: center;">
          <p>
            {{card.activityPlannerDescription}}
          </p>
        </div>  -->
        <div class="mat-card-subtitle" *ngIf="card.activityPlannerDescription !== null && card.activityPlannerDescription !== undefined" >
          {{(card.readMore) ? card.activityPlannerDescription : card.activityPlannerDescription | slice:0:70}} <span *ngIf="!card.readMore"></span>
          <a href="javascript:;" style="font-weight: 900;color: blue;" *ngIf="!card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=true">...</a>
          <a href="javascript:;" style="font-weight: bolder;color: blue;" *ngIf="card.readMore && card.activityPlannerDescription.length >70" (click)="card.readMore=false">[Read less]</a>
       
      </div>
            <div class="card-body">
         
              <div *ngIf="card.collapsedState === true" >
               
                
      
                  <mat-vertical-stepper>
        
                    <div class="stepper-class">
        
                    <mat-step [editable]="true" [completed]="true"  *ngFor="let video of card.activityVideoLinkList">
                      <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                        </div>
        
                          <br>
                
                          {{video.actVideoLinkDescription}}
                          <br>
                          
                          
                    </mat-step>
                    
                </div>
        
                
                  </mat-vertical-stepper>
        
           
              
                </div>
         
                
              <button mat-button color="primary" (click)="collapseActivityCardContent(card.activityPlannerId)">
                  {{card.collapsedState ? 'Close' : 'More'}}</button>
              </div> 
          </mat-card>
        </div>
      </div> </mat-tab>
    
   
    
    </mat-tab-group>
      </div>
      </div>
    
    
    
    </div>
    
    </div>
    
  
  </div>





 <br>
 <br>
 <br>