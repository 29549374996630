<div [ngStyle]="backgroundStyle"  class="modal-header">
  <h3 style="text-align: left;color: white;"> <a style="cursor:pointer;text-align: center;">
    <img [src]="fromParent.prodSafeUrl" width="45" height="45"
    alt="" loading="lazy">
 </a>{{fromParent.productName}}</h3> <br>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div> 

  

   <div class="modal-body">

    <div *ngIf="showAttendance===true" class="card">
      <div class="card-body table-responsive">
      
        <h3 style="text-align: center; color: rgb(76, 71, 71);font-weight: bold;">Attendance</h3>
        <h3 style="text-align: center; color: rgb(76, 71, 71);font-weight: bold;">No of classes attended : {{attendance.attendanceList.length}}</h3>
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead class="tableheader">
            <tr>
              <th>Class</th>
              <th>Date</th>
              <th>Date-Time</th>
            </tr>
          </thead> 
          <tbody>
            <tr class="td-height" *ngFor="let att of attendance.attendanceList">
              <td class="td-height">{{att.studentClassName}}</td>
              <td class="td-height">{{att.attendanceDate}}</td>
              <td class="td-height">{{att.attendanceFromDateTimeFormatted}} to {{att.attendanceToDateTimeFormatted}}</td>
            </tr>
          </tbody>
        </table>

        <mat-divider style="color: black;"></mat-divider>

      </div>
    </div>
<!-- <br>
    <mat-divider style="color: black;"></mat-divider> -->

    <div *ngFor="let sub of student.subjects" class="p-4">

  
        <div class="card">
          <div class="card-body table-responsive">
          
            <h3 style="text-align: center; color: rgb(76, 71, 71);font-weight: bold;"> Subject : {{sub.name}}</h3>

            <table class="table table-striped table-bordered table-sm row-border hover">
              <thead class="tableheader">
                <tr>
                  <th>Activity</th>
                  <th>Completion Status</th>
                  <th>Attempts</th>
                  <th> Exam Dates</th>
                </tr>
              </thead> 
              <tbody>
                <tr class="td-height" *ngFor="let ap of sub.activityPlanners">
                  <td class="td-height">{{ap.activityPlannerName}}</td>
                  <td class="td-height">

            <a *ngIf="ap.studentActivityHistoryIndicator == 0" ><img class="student-image" alt="" height="17"  width="50"loading="lazy" src="assets/images/icons/redRectangle.png"></a>
            <a *ngIf="ap.studentActivityHistoryIndicator == 1" style="font-weight: bold;"><img class="student-image" alt="" height="25" width="25" loading="lazy" src="assets/images/icons/inProgress.png" > In-Progress</a>
            <a *ngIf="ap.studentActivityHistoryIndicator == 2" style="font-weight: bold;"><img class="student-image" alt="" height="20" width="20" loading="lazy" src="assets/images/icons/selected.png" > Completed</a>
             
                  </td>
                  <td class="td-height">{{ap.studentAttempts}}</td>
                  <td class="td-height">{{ap.dateString}}</td>
                </tr>
              </tbody>
            </table>
    
            <mat-divider style="color: black;"></mat-divider>

          </div>
        </div>
      
    
    </div>

    <br>
    <br>




   </div>
   
   <div class="modal-footer">
    <button *ngIf="showAttendance===false" class="btn btn-sm btn-primary float-right" style="width: auto;" 
    (click)="getStudentAttendance();">View Attendance</button>
     <button *ngIf="showAttendance===true" class="btn btn-sm btn-primary float-right" style="width: auto;" 
    (click)="showAttendance=!showAttendance;">Hide Attendance</button>
 
  </div>