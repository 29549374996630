import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { GlobalClass } from 'src/app/global/global-class';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-customer-communications',
  templateUrl: './add-customer-communications.component.html',
  styleUrls: ['./add-customer-communications.component.scss']
})
export class AddCustomerCommunicationsComponent implements OnInit {

  clicked = false;
  coms : CustomerCommunications = new CustomerCommunications;
 
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser : any; 
  theme : any;
  constructor(public bsModalRef: BsModalRef, 
              private customerService : CustomerService,
              private sharedDataService : SharedDataService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService) {}

              @Input() fromParent: any;

     
  ngOnInit(): void {

this.coms.fkCustomerId = this.fromParent.customerId;
this.coms.messageType = '';

this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onSubmit(){

  
    this.customerService.addCustomerCommunications(environment.apiUrl + 'bcrmCustomerCommunications/add', this.coms).then(
            data=>{
        this.activeModal.close();
        this.passEntry.emit();
        this.toaster.success("Communication Created Successfully");
      });

  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }

}