import { BotAdminNotification } from "src/app/BOT_CODE/bot_classes/bot-admin-notification";

export class CrmTask {
  crmTaskId: any;
  crmTaskStatus: any;
  fkCrmListId: any;
  fkAgentBotUserId: any;
  fkAgentBotUserName: any;
  fkBotUserId: any;
  fkBotUserName: any;
  fkBotUserPhone: any;
  taskMsg: any;
  taskMediaMsg: any;
  creationTimestamp: any;
  media: BotAdminNotification[] = [];
}
