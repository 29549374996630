import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SharedDataService } from '../services/sharedData.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  loggedInUser: boolean = false;
  constructor(private router: Router,private sharedService: SharedDataService) { 
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login' || event['url'] == '/auth/login' || event['url'] == '/auth/change-password' ||
        event['url'] == '/auth/forgot-password' ||   event['url'] == '/') {
          this. loggedInUser = false;
        } else {
          this. loggedInUser = true;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  getColor() {
    return this.sharedService.getfooterColor() + '!important';;
  }

}
