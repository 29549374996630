import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-change-curriculum-order',
  templateUrl: './change-curriculum-order.component.html',
  styleUrls: ['./change-curriculum-order.component.scss']
})
export class ChangeCurriculumOrderComponent implements OnInit {

  clicked = false;
  curriculum : Curriculum = new Curriculum();
  curriculums : Curriculum[] = [];
  loggedInUser: any;
  theme: any;
  constructor(private currService : CurriculumService,
              public activeModal: NgbActiveModal,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService ) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.curriculum = this.fromParent.curriculum;

    this.currService.getSameLevelCurriculums(environment.apiUrl + "curriculum/getSameLevelCurriculums/" + this.curriculum.curId).then(
      data=>{
        this.curriculums = data;
      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  onSubmit(){

    this.currService.updateCurriculumOrder(environment.apiUrl + 'curriculum/updateCurriculumOrder', this.curriculum).then(
      data=>{
        this.passEntry.emit(this.curriculum.curId);
        this.activeModal.close();
        this.toaster.success('Order Swapped Successfully')
      });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
