<nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
  <div style="margin-right: 2%;" *ngIf="this.loggedInUser.offices[0].logo !== null">
    <img height="30" width="30" class="logo-image"
      [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
  </div>
  <div  style="margin-right: 2%;" *ngIf="this.loggedInUser.offices[0].logo === null">
    <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
  </div>
<a class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;">{{navbarTitle}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button> 

  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">

      <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="goToAccount()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-user"></i> Account </a>
      </li>

      <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
        <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
      </li> -->


      <li class="nav-item" style="float: right;">
        <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
          <i class="fa fa-sign-out" ></i>Log Out</a>
      </li>    

    </ul>
  </div>  
</nav>


 



<div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Course</li>
    </ol>
  </nav>

  <div class="content" >
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
  
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card style="border-radius: 25px;" class="example-card mat-elevation-z12" >
  
          <div class="sub-image-div">
            <img class="sub-image" [src]="safeChildImageUrl" height="110" width="110" alt="...">
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ptcDetails.childPetname}}</h5>
              
              <!-- <h5 class="card-title">( {{ptcDetails.childFirstName}} {{ptcDetails.childLastName}} )</h5> -->
              <p class="card-text">5 years old</p>
             
              <div class="button-div" style="width: 100%;">

              <button style="width: 100%;margin: 3%;" [ngStyle]="backgroundStyle"
              onclick="history.back()" class="btn btn-grad-custom">Go Back</button>

              <!-- <button [ngStyle]="backgroundStyle"
               class="btn btn-grad-custom">Test</button> -->
            </div>
            </div>
       
      </mat-card>
  
       
      </div>
  
    
     
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card style="border-radius: 25px;" class="example-card mat-elevation-z12" >
  
          <div class="sub-image-div">
            <img class="sub-image" src="/assets/images/icons/curriculum.svg"  height="100" width="100" alt="...">
            </div>
            <div class="card-body">
              <h5 class="card-title">Activity Progress</h5>
           
              
                <div class="progress" style="background-color: #c3daf1; margin-bottom: 2%;">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width]="value1 + '%'">{{value1}}%</div>
                </div>
      <br>
      <!-- do not delete -->
              <!-- <div class="button-div"  style="width: 100%;">
              <button *ngIf="officeObject.oa_SkillReport === true" style="width: 100% ; margin-top: 5%;" [style.background-color]='getColor()' (click)="goToResultPage(ptcDetails.studentId, safeChildImageUrl,ptcDetails.childFirstName )" class="btn btn-info">View Skill Report</button>
              </div> --> 
      <!-- do not delete -->
      <button *ngIf="ptcDetails.skillReport !== null" style="width: 100%;" [ngStyle]="backgroundStyle"
      class="btn btn-grad-custom">View Skill Insights</button>
              <button *ngIf="ptcDetails.skillReport === null" disabled style="width: 100%;" [ngStyle]="backgroundStyle"
               class="btn btn-grad-custom"> Skill Insights Not Ready</button>
            </div>
       
      </mat-card>
  
       
      </div>
  
      <div *ngIf="showActivityCard===true && ptcDetails.todaysActivityComplete === false" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card style="border-radius: 25px;" class="example-card mat-elevation-z12" *ngFor="let act of activityPlannerListWithSafeUrl">
  
          <div class="Activity-Card-Row-1" style="text-align: center;">

                <img style="border-radius: 50%;" class="Activity-Image" [src]="act.safeUrl"
                height="75" width="75" alt="Card image cap">

              </div>
<br>

            <div class="Activity-Card-Row-2" style="text-align: center;">

                <h4 class="card-title">{{act.activityPlannerName}}</h4>
                
                <!-- <button [style.background-color]='getColor()' style="width: auto;margin-bottom: 5%;" 
                class="btn btn-info" (click)="markActivityAsComplete();" >Mark As Complete</button> -->
               
                <p style=" text-align: justify;text-justify: inter-word;" class="card-text">{{act.activityPlannerDescription}}</p>
                
            </div>
       

       
        <div class="card-body">
           
        <div *ngIf="collapsedState === true" >
          
          <mat-vertical-stepper>

            <div class="stepper-class">

            <mat-step [editable]="true"  *ngFor="let video of act.activityVideoLinkList">
              <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                <p></p>

                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                </div>

                  <br>
                <div style=" text-align: justify;text-justify: inter-word;">
                  {{video.actVideoLinkDescription}}
                </div>
                  
            </mat-step>
            
        </div>


          </mat-vertical-stepper>

          <br>

<h4 style="margin: 0%;font-weight: bold;">Completed the activity ? Click the below button to assess</h4>
          <button style="margin: 2%;" (click)="onAssess(act.activityPlannerId)" class="btn btn-grad-green">Assess</button>
          <button style="margin: 2%;" class="btn btn-grad-custom" [ngStyle]="backgroundStyle"
          (click)="collapseActivityCardContent()">
            {{collapsedState ? 'Close' : 'Learn'}}</button>
          </div>
          
          <div style="text-align: center;">
            <button *ngIf="collapsedState===false" [ngStyle]="backgroundStyle" 
            class="btn btn-grad-custom" (click)="collapseActivityCardContent()">
              Learn</button>
          </div>

        </div> 
        
      </mat-card>
  
      </div>
  
  
  
      <div *ngIf="showActivityCard===true && ptcDetails.todaysActivityComplete === true" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="33%" >
  
        <mat-card style="border-radius: 25px;" class="example-card mat-elevation-z12">
  
          <div class="Activity-Card-Row-1" style="text-align: center;">
 <br>
 
                <img style="border-radius: 50%;" class="Activity-Image" src="/assets/images/icons/selected.png"
                height="100" width="100" alt="Card image cap">

              </div>
<br>

            <div class="Activity-Card-Row-2" style="text-align: center;margin-bottom: 8%;">

                <h4 class="card-title">Today's Activity Complete</h4>
                
             
               
                <p style=" text-align: justify;text-justify: inter-word;" class="card-text">
                You have completed today's activity successfully, please come back on {{nextActivityDay}} for the next activity.</p>
                
            </div>
       

       
      </mat-card>
  
      </div>
  
    </div>
    </div>


  </div>



  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>



