<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Notification</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

  
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="add()" #aplnrForm="ngForm"> 

      
 

      <div class="form-group row">
 
       

            <div class="col-md-6">
                <label class="required" for="name"> Name</label>
                <input #name="ngModel" required placeholder="Enter name" required class="form-control" 
                type="text" name="name"  id="name" [(ngModel)]="notificationMaster.name">
                <div class="alert alert-danger" *ngIf="name.touched && !name.valid">name is required</div>
             </div> 
             
             <div class="col-md-6">
                <label class="required" for="type"> Type</label>
                <input  #type="ngModel" required placeholder="Enter type" required 
                class="form-control" type="text" name="type"  id="type" [(ngModel)]="notificationMaster.type">
                <div class="alert alert-danger" *ngIf="type.touched && !type.valid">type is required</div>
             </div>
 

   
      </div>

     
      <div>
        <label class="required" for="name"> From Address</label>
        <input #fromAddress="ngModel" required placeholder="Enter From Address" required class="form-control" 
        type="text" name="fromAddress"  id="fromAddress" [(ngModel)]="notificationMaster.fromAddress">
        <div class="alert alert-danger" *ngIf="fromAddress.touched && !fromAddress.valid">fromAddress is required</div>
     </div> 
    

 <br>

 <table>
   <thead>
     <th [style.background-color]='getColor()' >Name</th>
     <th [style.background-color]='getColor()' >Type</th>
     <th [style.background-color]='getColor()' >Subject</th>
     <th [style.background-color]='getColor()' >Action</th>
     <!-- <th></th>
     <th></th> -->
   </thead>
   <tbody>
     <tr *ngFor="let detail of notificationMaster.details">
      <td>{{detail.name}}</td>
      <td>{{detail.type}}</td>
      <td>{{detail.messageSubject}}</td>
      <td>  <a style="cursor:pointer;padding-left: 1rem;"
        (click)="notificationDetail=detail;open(confirmEdit);">
        <img src="/assets/images/icons/edit.svg" width="20" height="20" alt="" loading="lazy"> </a> 
                 
      </td>
      <!-- <td>{{detail.}}</td>
      <td>{{detail.}}</td> -->
     </tr>
   </tbody>
 </table>

 <br>
  
        
          <a matTooltip="Add product" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
          (click)="open(confirmActivation)">
          <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
        </a> 



    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="add(); clicked=true">Update</button>
  </div>

  <ng-template #confirmActivation let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Add Notification Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
     <form (ngSubmit)="add()" #aplnrForm1="ngForm">

      <div class="form-group row">
        <div class="col-md-6">
          <label class="required" for="name"> Name</label>
          <input #name="ngModel" required placeholder="Enter name" required class="form-control" 
          type="text" name="name"  id="name" [(ngModel)]="notificationDetail.name">
          <div class="alert alert-danger" *ngIf="name.touched && !name.valid">name is required</div>
       </div> 
      
       <div class="col-md-6">
        <label class="required" for="name"> Type</label>
        <input #type="ngModel" required placeholder="Enter type" required class="form-control" 
        type="text" name="name"  id="name" [(ngModel)]="notificationDetail.type">
        <div class="alert alert-danger" *ngIf="type.touched && !type.valid">Type is required</div>
     </div> 
      </div>

   <br>
     <div>
      <label class="required" for="name"> Message Header</label>
      <input #messageHeader="ngModel" required placeholder="Enter header" required class="form-control" 
      type="text" name="messageHeader"  id="messageHeader" [(ngModel)]="notificationDetail.messageHeader">
      <div class="alert alert-danger" *ngIf="messageHeader.touched && !messageHeader.valid">Header is required</div>
   </div> 

   <br>
     <div>
      <label class="required" for="name">  Message Subject</label>
      <input #messageSubject="ngModel" required placeholder="Enter subject" required class="form-control" 
      type="text" name="messageSubject"  id="messageSubject" [(ngModel)]="notificationDetail.messageSubject">
      <div class="alert alert-danger" *ngIf="messageSubject.touched && !messageSubject.valid">Subject is required</div>
   </div> 

   <br> 
   <div>
    <label class="required" for="description">Message Body</label>
    <textarea maxlength="5000" required type="text" id="messageBody" name="messageBody" class="form-control"
     placeholder="Enter Message Body" [(ngModel)]="notificationDetail.messageBody"></textarea>
    </div>
   <br>
     <div>
      <label class="required" for="name">  Message Footer</label>
      <input #messageFooter="ngModel" required placeholder="Enter Footer" required class="form-control" 
      type="text" name="messageFooter"  id="messageFooter" [(ngModel)]="notificationDetail.messageFooter">
      <div class="alert alert-danger" *ngIf="messageFooter.touched && !messageFooter.valid">Footer is required</div>
   </div> 
     </form>
        </div>
      </div>
    </div> 

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="addDetail();modal.dismiss('Cross click')">Add</button>
    </div>
  </ng-template> 

  <ng-template #confirmEdit let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Edit Notification Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
     <form (ngSubmit)="add()" #aplnrForm1="ngForm">

      <div class="form-group row">
        <div class="col-md-6">
          <label class="required" for="name"> Name</label>
          <input #name="ngModel" required placeholder="Enter name" required class="form-control" 
          type="text" name="name"  id="name" [(ngModel)]="notificationDetail.name">
          <div class="alert alert-danger" *ngIf="name.touched && !name.valid">name is required</div>
       </div> 
      
       <div class="col-md-6">
        <label class="required" for="name"> Type</label>
        <input #type="ngModel" required placeholder="Enter type" required class="form-control" 
        type="text" name="name"  id="name" [(ngModel)]="notificationDetail.type">
        <div class="alert alert-danger" *ngIf="type.touched && !type.valid">Type is required</div>
     </div> 
      </div>

   <br>
     <div>
      <label class="required" for="name"> Message Header</label>
      <input #messageHeader="ngModel" required placeholder="Enter header" required class="form-control" 
      type="text" name="messageHeader"  id="messageHeader" [(ngModel)]="notificationDetail.messageHeader">
      <div class="alert alert-danger" *ngIf="messageHeader.touched && !messageHeader.valid">Header is required</div>
   </div> 

   <br>
     <div>
      <label class="required" for="name">  Message Subject</label>
      <input #messageSubject="ngModel" required placeholder="Enter subject" required class="form-control" 
      type="text" name="messageSubject"  id="messageSubject" [(ngModel)]="notificationDetail.messageSubject">
      <div class="alert alert-danger" *ngIf="messageSubject.touched && !messageSubject.valid">Subject is required</div>
   </div> 

   <br> 
   <div>
    <label class="required" for="description">Message Body</label>
    <textarea maxlength="5000" required type="text" id="messageBody" name="messageBody" class="form-control"
     placeholder="Enter Message Body" [(ngModel)]="notificationDetail.messageBody"></textarea>
    </div>
   <br>
     <div>
      <label class="required" for="name">  Message Footer</label>
      <input #messageFooter="ngModel" required placeholder="Enter Footer" required class="form-control" 
      type="text" name="messageFooter"  id="messageFooter" [(ngModel)]="notificationDetail.messageFooter">
      <div class="alert alert-danger" *ngIf="messageFooter.touched && !messageFooter.valid">Footer is required</div>
   </div> 
     </form>
        </div>
      </div>
    </div> 

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="editDetail();modal.dismiss('Cross click')">Update</button>
    </div>
  </ng-template> 
  