import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { Role } from 'src/app/models/role';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-bot-form-active',
  templateUrl: './edit-bot-form-active.component.html',
  styleUrls: ['./edit-bot-form-active.component.scss']
})
export class EditBotFormActiveComponent implements OnInit {

  clicked = false;
  formActive : FormActive = new FormActive();
  loggedInUser : any; 
  roles : Role[] = [];
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private datePipe : DatePipe,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    
    this.formActive = this.fromParent.form;
    this.formActive.fromDate = this.datePipe.transform(this.formActive.fromDate, "yyyy-MM-dd");
    this.formActive.toDate = this.datePipe.transform(this.formActive.toDate, "yyyy-MM-dd");

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
  
  }

  onSubmit(){
    this.formService.updateFormActive(environment.apiUrl + "formActive/editFormActive", this.formActive).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Form updated successfully');
        this.passEntry.emit('success');
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
