


   <div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">History : {{feedbackForm.name}} </h4>
    <div class="row pull-right" style="margin-right: 2%;">

      <button style=" color: black;background-color: white; ;" class="btn btn-info" (click)="addFormActive();">Schedule</button> 

    <button type="button" class="close" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button> 


  </div>
  </div>
  
  
  
  
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form #invoiceForm="ngForm"> 

    <table class="table table-striped table-bordered table-sm row-border hover">
        <thead class="tableheader">
          <tr>
            <!-- <th> Form sharing Date-Time</th> -->
            <th> Active Date - Expiry Date</th>
            <!-- <th> No of Users</th> -->
            <th> Response count</th>
            <!-- <th>Url</th> -->
            <th> Status</th>
            <th>Action</th>
          
          </tr>
        </thead> 
        <tbody>
          <tr class="td-height" *ngFor="let fa of formActives">
            
            <!-- <td class="td-height">{{fa.executionTimestamp}}</td> -->
            <td class="td-height">{{fa.formattedFromDate}} to {{fa.formattedToDate}}</td>
            <!-- <td style="text-align: right;" class="td-height">{{fa.formLimit}}</td> -->
            <td style="text-align: right;" class="td-height">{{fa.formCount}}</td>

            <!-- <td class="td-height">{{fa.url}}</td> -->
            <td *ngIf="fa.expiryStatus === true" class="td-height">Expired  
            </td>
            <td *ngIf="fa.expiryStatus === false" class="td-height">Active 
            </td>

            <td>
              <button (click)="explore(fa.id)" class="btn btn-info">Explore</button>
            </td>

            
          
          </tr>
        </tbody>
      </table> 
             
      </form>
      </div>
      </div>
      </div>
  
