// Angular
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { OfficeAdminDashboardComponent } from "../dashboards/office-admin-dashboard/office-admin-dashboard.component";
import { SuperadmindashboardComponent } from "src/app/superadmindashboard/superadmindashboard.component";
import { GeneralDashboardComponent } from "./general-dashboard/general-dashboard.component";
import { TableModule } from "primeng/table";
import { NgxSpinnerModule } from "ngx-spinner";
import { ButtonModule } from "primeng/button";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { StudentDashboardComponent } from "./student-dashboard/student-dashboard.component";
import { TeacherDashboardComponent } from "./teacher-dashboard/teacher-dashboard.component";
import { ParentDashboardComponent } from "./parent-dashboard/parent-dashboard.component";
import { DataTablesModule } from "angular-datatables";
import { OfficeAdminDashboardResolve } from "./office-admin-dashboard/office-admin-dashboard.resolve";
import { DataTableDirective } from "angular-datatables";
import { CrmDataDashboardComponent } from "src/app/dashboards/crm-data-dashboard/crm-data-dashboard.component";
import { CurriculumDashboardComponent } from "src/app/dashboards/courseManagementDashboards/curriculum-dashboard/curriculum-dashboard.component";
import { ClassDashboardComponent } from "src/app/dashboards/class-dashboard/class-dashboard.component";
import { CombinedUserParentDashboardComponent } from "src/app/dashboards/combinedParentTeacher/combined-user-parent-dashboard/combined-user-parent-dashboard.component";
import { ResultsAdminDashboardComponent } from "src/app/dashboards/resultsAdmin/results-admin-dashboard/results-admin-dashboard.component";
import { DashboardSpinnerComponent } from "src/app/spinner/dashboard-spinner/dashboard-spinner.component";
import { SuperCourseAdminDashboardComponent } from "src/app/dashboards/superCourseAdmin/super-course-admin-dashboard/super-course-admin-dashboard.component";
import { SuperCourseAdminProductDashboardComponent } from "src/app/dashboards/superCourseAdmin/super-course-admin-product-dashboard/super-course-admin-product-dashboard.component";
import { SuperCourseAdminSubjectDashboardComponent } from "src/app/dashboards/superCourseAdmin/super-course-admin-subject-dashboard/super-course-admin-subject-dashboard.component";
import { SuperCourseAdminCurriculumDashboardComponent } from "src/app/dashboards/superCourseAdmin/super-course-admin-curriculum-dashboard/super-course-admin-curriculum-dashboard.component";
import { StudentSubjectDashboardComponent } from "src/app/dashboards/studentDashboards/student-subject-dashboard/student-subject-dashboard.component";
import { StudentCourseDashboardComponent } from "src/app/dashboards/studentDashboards/student-course-dashboard/student-course-dashboard.component";
import { StudentCurriculumDashboardComponent } from "src/app/dashboards/studentDashboards/student-curriculum-dashboard/student-curriculum-dashboard.component";
import { ClassAdminDashboardComponent } from "src/app/dashboards/classDashboards/class-admin-dashboard/class-admin-dashboard.component";
import { ClassAdminSubscriptionDashboardComponent } from "src/app/dashboards/classDashboards/class-admin-subscription-dashboard/class-admin-subscription-dashboard.component";
import { ClassAdminTeacherDashboardComponent } from "src/app/dashboards/classDashboards/class-admin-teacher-dashboard/class-admin-teacher-dashboard.component";
import { ClassAdminStudentDashboardComponent } from "src/app/dashboards/classDashboards/class-admin-student-dashboard/class-admin-student-dashboard.component";
import { ClassViewPageComponent } from "src/app/dashboards/classDashboards/class-view-page/class-view-page.component";
import { AssignmentDashboardComponent } from "src/app/dashboards/teacherDashboards/assignment-dashboard/assignment-dashboard.component";
import { StudentAssignmentDashboardComponent } from "src/app/dashboards/studentDashboards/student-assignment-dashboard/student-assignment-dashboard.component";
import { SuperSkillReportDashboardComponent } from "src/app/dashboards/resultsAdmin/super-skill-report-dashboard/super-skill-report-dashboard.component";
import { ClassSkillReportsComponent } from "src/app/dashboards/teacherDashboards/class-skill-reports/class-skill-reports.component";
import { AuthGuard } from "src/app/handlers/guards/auth.guards";
import { CrmAdminDashboardComponent } from "src/app/dashboards/crm-admin-dashboard/crm-admin-dashboard.component";
import { CrmLeadInfoDashboardComponent } from "src/app/dashboards/crm-lead-info-dashboard/crm-lead-info-dashboard.component";
import { CrmInvoiceDashboardComponent } from "src/app/dashboards/crm-invoice-dashboard/crm-invoice-dashboard.component";
import { CrmPaymentDashboardComponent } from "src/app/dashboards/crm-payment-dashboard/crm-payment-dashboard.component";
import { CrmShipmentDashboardComponent } from "src/app/dashboards/crm-shipment-dashboard/crm-shipment-dashboard.component";
import { CrmProductDashboardComponent } from "src/app/dashboards/crm-product-dashboard/crm-product-dashboard.component";
import { ChildClassDashboardComponent } from "src/app/dashboards/parentDashboards/child-class-dashboard/child-class-dashboard.component";
import { ChildSubjectsDashboardComponent } from "src/app/dashboards/parentDashboards/child-subjects-dashboard/child-subjects-dashboard.component";
import { ChildCourseDashboardComponent } from "src/app/dashboards/parentDashboards/child-course-dashboard/child-course-dashboard.component";
import { StudentSkillReportPageComponent } from "src/app/dashboards/studentDashboards/student-skill-report-page/student-skill-report-page.component";
import { BcrmExeHomeComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-home/bcrm-exe-home.component";
import { BcrmExeLeadsComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-leads/bcrm-exe-leads.component";
import { BcrmExeCustomersComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-customers/bcrm-exe-customers.component";
import { BcrmManHomeComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-home/bcrm-man-home.component";
import { BcrmManCustomersComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-customers/bcrm-man-customers.component";
import { BcrmManLeadsComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-leads/bcrm-man-leads.component";
import { BcrmExeOrdersComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-orders/bcrm-exe-orders.component";
import { BcrmExeTargetsComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-targets/bcrm-exe-targets.component";
import { BcrmManTargetsComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-targets/bcrm-man-targets.component";
import { ProfilePageComponent } from "src/app/BIZ_CRM/biz_crm_Modals/Customer/profile-page/profile-page.component";
import { BcrmManOrdersComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-orders/bcrm-man-orders.component";
import { BcrmDataHomeComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_data/bcrm-data-home/bcrm-data-home.component";
import { BcrmFeedbackDashboardComponent } from "src/app/BIZ_CRM_FEEDBACK/dashboards/bcrm-feedback-dashboard/bcrm-feedback-dashboard.component";
import { FormActiveDashboardComponent } from "src/app/BIZ_CRM_FEEDBACK/dashboards/form-active-dashboard/form-active-dashboard.component";
import { BcrmExeDueOrdersComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-due-orders/bcrm-exe-due-orders.component";
import { OfficeNotificationDashboardComponent } from "src/app/NOTIFICATION/dashboard/office-notification-dashboard/office-notification-dashboard.component";
import { BcrmManDueOrdersComponent } from "src/app/BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-due-orders/bcrm-man-due-orders.component";
import { CrmSubscriptionsDashboardComponent } from "src/app/dashboards/crm-subscriptions-dashboard/crm-subscriptions-dashboard.component";
import { BcrmFeedbackManagerTicketSupportComponent } from "src/app/BIZ_CRM_FEEDBACK/dashboards/bcrm-feedback-manager-ticket-support/bcrm-feedback-manager-ticket-support.component";
import { ParentHomeDashboardComponent } from "src/app/dashboards/parentDashboards/parent-home-dashboard/parent-home-dashboard.component";
import { ParentMentorHomeComponent } from "src/app/dashboards/combinedParentTeacher/parent-mentor-home/parent-mentor-home.component";
import { CbSuperAdminHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_super_admin/cb-super-admin-home/cb-super-admin-home.component";
import { CbAConvoLogViewComponent } from "src/app/BOT_CODE/bot_dashboards/cb_agent/cb-a-convo-log-view/cb-a-convo-log-view.component";
import { CbOfficeAdminDashboardComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/cb-office-admin-dashboard/cb-office-admin-dashboard.component";
import { CbOrgAdminHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_org_admin/cb-org-admin-home/cb-org-admin-home.component";
import { AppSetupComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/app-setup/app-setup.component";
import { AdminNotificationsHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/admin-notifications-home/admin-notifications-home.component";
import { BotCustomerUsersComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot-customer-users/bot-customer-users.component";
import { BotScheduleHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot-schedule-home/bot-schedule-home.component";
import { BotShopHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot_shop/bot-shop-home/bot-shop-home.component";
import { BotShopOrdersComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot_shop/bot-shop-orders/bot-shop-orders.component";
import { BotFormManagementHomeComponent } from "src/app/BOT_CODE/bot_dashboards/bot_forms/bot-form-management-home/bot-form-management-home.component";
import { BotFormActiveDashboardComponent } from "src/app/BOT_CODE/bot_dashboards/bot_forms/bot-form-active-dashboard/bot-form-active-dashboard.component";
import { BotFormActiveHistoryComponent } from "src/app/BOT_CODE/bot_dashboards/bot_forms/bot-form-active-history/bot-form-active-history.component";
import { BotAdminsManagementComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot-admins-management/bot-admins-management.component";
import { CbNotificationsHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_notifications_manager/cb-notifications-home/cb-notifications-home.component";
import { CbShopOrdersComponent } from "src/app/BOT_CODE/bot_dashboards/cb_shop_orders_manager/cb-shop-orders/cb-shop-orders.component";
import { MedicalSpecialistsManagementDashboardComponent } from "src/app/BOT_CODE/bot_dashboards/bot_members_management/medical-specialists-management-dashboard/medical-specialists-management-dashboard.component";
import { CbOfficeUsersConversationsComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/cb-office-users-conversations/cb-office-users-conversations.component";
import { BotTemplatesManagementComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot-templates-management/bot-templates-management.component";
import { BotApiConfigsManagementComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot-api-configs-management/bot-api-configs-management.component";
import { BotAutoScheduleSettingsDashboardComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/bot-auto-schedule-settings-dashboard/bot-auto-schedule-settings-dashboard.component";
import { CbOfficeAdminsConversationsComponent } from "src/app/BOT_CODE/bot_dashboards/cb_office_admin/cb-office-admins-conversations/cb-office-admins-conversations.component";
import { CbOrganizationAdminHomeComponent } from "src/app/BOT_CODE/bot_dashboards/cb_organization_admin/cb-organization-admin-home/cb-organization-admin-home.component";
import { CrmListsDashboardComponent } from "../../CRM_MODULE/dashboards/crm-lists-dashboard/crm-lists-dashboard.component";
import { CrmAgentListsDashboardComponent } from "src/app/CRM_MODULE/dashboards/crm-agent-lists-dashboard/crm-agent-lists-dashboard.component";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    children: [
      {
        path: "office-admin",
        component: OfficeAdminDashboardComponent,
        resolve: {
          users: OfficeAdminDashboardResolve,
        },
        canActivate: [AuthGuard],
        data: { roles: ["OFFICE_ADMIN"] },
      },
      {
        path: "super-admin",
        component: SuperadmindashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },
      {
        path: "user",
        component: GeneralDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [
            "OFFICE_ADMIN",
            "CLASS_ADMIN",
            "COURSE_ADMIN",
            "CRM_ADMIN",
            "TEACHER",
            "STUDENT",
            "PARENT",
            "PARENT_MENTOR",
          ],
        },
      },
      {
        path: "student",
        component: StudentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["STUDENT"] },
      },
      {
        path: "parent-home/:childUserId",
        component: ParentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT"] },
      },
      {
        path: "parent",
        component: ParentHomeDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT"] },
      },
      {
        path: "parent/classes/:studentUserId",
        component: ChildClassDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT"] },
      },
      {
        path: "parent/subjects/:productId/:studentId/:classId",
        component: ChildSubjectsDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT"] },
      },
      {
        path: "parent/course/:subjectId/:studentId/:classId",
        component: ChildCourseDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT"] },
      },
      {
        path: "teacher",
        component: TeacherDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["TEACHER"] },
      },
      {
        path: "crm-admin",
        component: CrmDataDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },
      {
        path: "crm-admin/crmC",
        component: CrmAdminDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "crm-admin/crmL",
        component: CrmLeadInfoDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "crm-admin/crmI",
        component: CrmInvoiceDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "crm-admin/crmP",
        component: CrmPaymentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "crm-admin/crmS",
        component: CrmShipmentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "crm-admin/crmPr",
        component: CrmProductDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "crm-admin/crmSubscriptions",
        component: CrmSubscriptionsDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CRM_ADMIN"] },
      },

      {
        path: "curriculum-admin",
        component: CurriculumDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["COURSE_ADMIN"] },
      },
      {
        path: "UserParent/child/:childUserId",
        component: CombinedUserParentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT_MENTOR"] },
      },

      {
        path: "UserParent",
        component: ParentMentorHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["PARENT_MENTOR"] },
      },
      {
        path: "class-dashboard",
        component: ClassDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CLASS_ADMIN"] },
      },
      {
        path: "super-course-admin/offices",
        component: SuperCourseAdminDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },
      {
        path: "super-course-admin/products/:officeId",
        component: SuperCourseAdminProductDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },
      {
        path: "super-course-admin/subjects/:productId",
        component: SuperCourseAdminSubjectDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },

      {
        path: "super-course-admin/curriculum/:subjectId",
        component: SuperCourseAdminCurriculumDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },

      {
        path: "student-subjects/:productId/:studentId/:classId",
        component: StudentSubjectDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["STUDENT"] },
      },

      {
        path: "student-course/:subjectId/:studentId/:classId",
        component: StudentCourseDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["STUDENT"] },
      },

      {
        path: "student-curriculum/:subjectId",
        component: StudentCurriculumDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["STUDENT", "PARENT"] },
      },

      {
        path: "class-admin",
        component: ClassAdminDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CLASS_ADMIN"] },
      },

      {
        path: "class-admin/teachers",
        component: ClassAdminTeacherDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CLASS_ADMIN"] },
      },

      {
        path: "class-admin/students",
        component: ClassAdminStudentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CLASS_ADMIN"] },
      },

      {
        path: "class-admin/subscriptions",
        component: ClassAdminSubscriptionDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CLASS_ADMIN"] },
      },

      {
        path: "class-view/:classId",
        component: ClassViewPageComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CLASS_ADMIN"] },
      },

      {
        path: "teacher/assignment/:teacherId/:classId/:subjectId",
        component: AssignmentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["TEACHER"] },
      },

      {
        path: "student/assignment/:subjectId/:studentId/:classId",
        component: StudentAssignmentDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["STUDENT"] },
      },

      {
        path: "results-dashboard",
        component: ResultsAdminDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },

      {
        path: "super-skill-report",
        component: SuperSkillReportDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["SUPER_ADMIN"] },
      },

      {
        path: "class-skill-report/:subjectId/:classId",
        component: ClassSkillReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["TEACHER"] },
      },

      {
        path: "student-skill-report/:studentId",
        component: StudentSkillReportPageComponent,
        canActivate: [AuthGuard],
        data: { roles: ["STUDENT"] },
      },
      // BIZ-CRM FROM HERE
      {
        path: "bcrm-exe-dashboard",
        component: BcrmExeHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_EXE"] },
      },
      {
        path: "bcrm-exe-leads",
        component: BcrmExeLeadsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_EXE"] },
      },
      {
        path: "bcrm-exe-customers",
        component: BcrmExeCustomersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_EXE"] },
      },
      {
        path: "bcrm-exe-orders",
        component: BcrmExeOrdersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_EXE"] },
      },
      {
        path: "bcrm-exe-due-orders",
        component: BcrmExeDueOrdersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_EXE"] },
      },
      {
        path: "bcrm-exe-targets",
        component: BcrmExeTargetsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_EXE"] },
      },
      {
        path: "bcrm-man-dashboard",
        component: BcrmManHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_MAN"] },
      },
      {
        path: "bcrm-man-customers",
        component: BcrmManCustomersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_MAN"] },
      },
      {
        path: "bcrm-man-leads",
        component: BcrmManLeadsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_MAN"] },
      },
      {
        path: "bcrm-man-targets",
        component: BcrmManTargetsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_MAN"] },
      },
      {
        path: "bcrm-man-orders",
        component: BcrmManOrdersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_MAN"] },
      },
      {
        path: "bcrm-man-due-orders",
        component: BcrmManDueOrdersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_MAN"] },
      },

      {
        path: "office-notifications",
        component: OfficeNotificationDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_OFFICE_ADMIN"] },
      },

      {
        path: "bcrm-customer-profile/:customerId",
        component: ProfilePageComponent,
        canActivate: [AuthGuard],
        data: {
          roles: ["BCRM_EXE", "BCRM_SALES_COORDINATOR", "BCRM_MAN", "BCRM_TM"],
        },
      },
      {
        path: "bcrm-data-dashboard",
        component: BcrmDataHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_DATA"] },
      },

      {
        path: "bcrm-feedback-manager",
        component: BcrmFeedbackDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_FEEDBACK_MANAGER"] },
      },
      {
        path: "bcrm-form-active-dashboard/:formActiveId",
        component: FormActiveDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_FEEDBACK_MANAGER"] },
      },
      {
        path: "bcrm-feedback-manager-ticketsupport-dashboard",
        component: BcrmFeedbackManagerTicketSupportComponent,
        canActivate: [AuthGuard],
        data: { roles: ["BCRM_FEEDBACK_MANAGER"] },
      },

      // CONVO BOT Pages

      {
        path: "cb-super-admin",
        component: CbSuperAdminHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_SUPER_ADMIN"] },
      },
      {
        path: "cb-office-admin",
        component: CbOfficeAdminDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-org-admin",
        component: CbOrganizationAdminHomeComponent,
        // canActivate: [AuthGuard],
        // data: { roles: ["CB_OFFICE_ADMIN"]}
      },
      {
        path: "biz-crm/lists",
        component: CrmListsDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "user-conversations",
        component: CbOfficeUsersConversationsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-agent-convo-log-view",
        component: CbAConvoLogViewComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_AGENT"] },
      },
      {
        path: "cb-agent/crm/tasks",
        component: CrmAgentListsDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_AGENT"] },
      },
      {
        path: "cb-org-admin/home",
        component: CbOrgAdminHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_ORG_ADMIN"] },
      },
      {
        path: "cb-app-setup",
        component: AppSetupComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-admin-notification",
        component: AdminNotificationsHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-office-customers",
        component: BotCustomerUsersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-office-schedules",
        component: BotScheduleHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-office-shop",
        component: BotShopHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-office-shop/orders",
        component: BotShopOrdersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-template-management",
        component: BotTemplatesManagementComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "cb-office/forms",
        component: BotFormManagementHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "bot/forms/form-history/:formActiveId",
        component: BotFormActiveDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "bot/forms/form-response-history/:formId",
        component: BotFormActiveHistoryComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "bot-admin/management",
        component: BotAdminsManagementComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "bot-admin/admins/conversations",
        component: CbOfficeAdminsConversationsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "bot-admin/medical-specialists-management",
        component: MedicalSpecialistsManagementDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "office/notifications",
        component: CbNotificationsHomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_NOTIFICATIONS_MANAGER"] },
      },
      {
        path: "office/shop-orders",
        component: CbShopOrdersComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_SHOP_ORDERS_MANAGER"] },
      },
      {
        path: "bot/api-config",
        component: BotApiConfigsManagementComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
      {
        path: "bot/auto-scheduler-settings",
        component: BotAutoScheduleSettingsDashboardComponent,
        canActivate: [AuthGuard],
        data: { roles: ["CB_OFFICE_ADMIN"] },
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ButtonModule,
    BsDropdownModule,
    RouterModule.forChild(routes),
    TableModule,
    DataTablesModule,
  ],
  providers: [OfficeAdminDashboardResolve, DataTableDirective],
  exports: [DashboardComponent],
  declarations: [
    DashboardComponent,
    OfficeAdminDashboardComponent,
    GeneralDashboardComponent,
    StudentDashboardComponent,
    TeacherDashboardComponent,
    ParentDashboardComponent,
    DashboardSpinnerComponent,
  ],
})
export class DashboardModule {
  static forRoot(): ModuleWithProviders<DashboardModule> {
    return {
      ngModule: DashboardModule,
      providers: [],
    };
  }
}
