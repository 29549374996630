<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4  style="color: white;" class="modal-title pull-left">Add Parent</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
        <div class="card-body">
    <form (ngSubmit)="onSubmit" #adminForm="ngForm">   
        
        <div class="form-group row">
        
          <div class="col-md-6">
          <label class="required" for="firstName">First Name</label>
          <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
          <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
          </div>
        
          <div class="col-md-6">
          <label class="required" for="lastName">Last Name</label>
          <input #lastName="ngModel"  maxlength="50"  required class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
          <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
        </div>

      




        
        </div>

        <div class="form-group row">
        
          <div class="col-md-6">
          <label class="required" for="email">Email</label>
          <input #email="ngModel" maxlength="250"   required class="form-control" email type="email" placeholder="Enter Email" name="email" id="email" [(ngModel)]="combinedPTC.email">
          <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
          </div>


          <div class="col-md-2">
            <label class="required" for="countryDialCode"> Code</label>
            <select class="form-control" name="countryDialCode" id="countryDialCode"
            [(ngModel)]="combinedPTC.countryDialCode">
            <option value="" disabled selected>Select Country</option>
            <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
            </select>
            </div>

        <div class="col-md-4">
          <label class="required" for="phoneNumber">Phone Number</label>
          <input #phone="ngModel" maxlength="250"   required minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
          <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Invalid Phone Number </div>
          </div>
        
          
         
        
        </div>
        

        <div class="form-group row">

          <div class="col-md-6">
            <label for="dateOfBirth">Date of Birth</label>
            <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
            </div>

          <div class="col-md-6">

            <label >Gender </label> <br>
          <mat-radio-group aria-label="gender" [(ngModel)]="combinedPTC.gender">
            <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
            <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
            <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
          </mat-radio-group>
  
          </div>

        </div>
        
        

          
        


        
        
        <div class="form-group row">
        
          <div class="col-md-4">
          <label for="addressLine1">Address Line 1</label>
          <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
          </div>
        
          <div class="col-md-4">
          <label for="addressLine2">Address Line 2</label>
          <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
          </div>

          <div class="col-md-4">
            <label for="city">City</label>
            <input class="form-control" maxlength="250"  type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="combinedPTC.city">
            </div>
        
        </div>
        
        <div class="form-group row">
        
          <div class="col-md-4">
            <label for="state">State</label>
            <input class="form-control" maxlength="250" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="combinedPTC.state">
            </div>
       
        
          <div class="col-md-4">
          <label for="country">Country</label>
          <input class="form-control" maxlength="250" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="combinedPTC.country">
          </div>

          <div class="col-md-4">
            <label for="zipCode">Zip Code</label>
            <input class="form-control" maxlength="250" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
            </div>
        
        </div>
        
       
        
       

          
        
        
                </form>
        </div>
    </div>
</div>

<div class="modal-footer">
  <!-- <button  [disabled]="clicked"  [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Submit</button> -->
  <button [disabled]="clicked" style="width: auto;" [disabled]="!adminForm.valid" type="submit" 
  class="btn btn-sm btn-primary float-right" (click)="open(confirmUsernameCreation);">Create Username</button>

</div>



<ng-template #confirmUsernameCreation let-modal>
  <div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Username</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
   <div *ngIf="emailUnavailable === false || this.phoneUnavailable === false">
    <label>     Create Username using :      </label> <br>
  <mat-radio-group aria-label="choice" required [(ngModel)]="choice">
    <mat-radio-button name="choice" id="Email" value="Email"  >Email</mat-radio-button>
    <mat-radio-button name="choice" id="Phone" value="Phone"  >Phone</mat-radio-button>
  </mat-radio-group>
  </div>

  <form #usernameForm="ngForm">  
  <div *ngIf="emailUnavailable === true && this.phoneUnavailable === true">
    <label>Username : </label>
    <input class="col-md-12 form-control" required #username="ngModel"
type="text" minlength="5" [(ngModel)]="value" placeholder="Try firstname@email or lastname@email or firstname@phone" name="search">
<div class="alert alert-danger" *ngIf="username.touched && !username.valid"> username must have minimum of 5 characters</div>  </div>
  </form>

  </div> 
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')"></button> -->
    <button *ngIf="emailUnavailable === false || this.phoneUnavailable === false"
    type="button" class="btn btn-success"  [disabled]="clicked" 
    (click)="checkUsernameAvailability();">Proceed</button>

    <button *ngIf="emailUnavailable === true && this.phoneUnavailable === true"
    type="button" class="btn btn-success"  [disabled]="clicked" [disabled]="!usernameForm.valid"
    (click)="checkCustomUsernameAvailability();">Proceed</button>
  </div>
</ng-template>