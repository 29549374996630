
 <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToTicketSupportPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-question-circle"></i> Ticket Support </a>
        </li>
        
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
  <br>
  
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="content" >
   <div fxLayout="row wrap" fxLayoutGap="16px grid">
 
     <div fxFlex="14.2857" fxFlex.xs="100%" fxFlex.sm="100%" >
 
       
       <mat-card  class="example-card mat-elevation-z12" >
      
         <div class="card-icon" >
           <a> 
             <img src="/assets/images/icons/inv.svg" width="65" height="65"
              alt="" loading="lazy">
           </a>
         </div>
          <br>
          <mat-card-title style="margin-bottom: 6%;">Total <br>
           Orders</mat-card-title>
          <mat-card-title (click)="viewOrdersTable(orderStatus.allOrders, 'All Orders')"
           style="margin-top: 6%;color: blue;cursor: pointer;"> {{orderStatus.totalOrders}}</mat-card-title>
       
     </mat-card>
     
          
         </div>
     <div fxFlex="14.2857" fxFlex.xs="50%" fxFlex.sm="33.333%" >
 
       
   <mat-card  class="example-card mat-elevation-z12" >
  
     <div class="card-icon" >
       <a> 
         <img src="/assets/images/icons/ordersPlaced.png" width="65" height="65"
          alt="" loading="lazy">
       </a>
     </div>
      <br>
      <mat-card-title style="margin-bottom: 6%;">Orders Placed</mat-card-title>
      <mat-card-title (click)="viewOrdersTable(orderStatus.pendingApprovalOrders, 'Orders - Pending approval')"
       style="margin-top: 6%;color: blue;cursor: pointer;"> {{orderStatus.pendingApprovalOrderCount}}</mat-card-title>
   
 </mat-card>
 
      
     </div>
     <div fxFlex="14.2857" fxFlex.xs="50%" fxFlex.sm="33.333%" >
 
       
       <mat-card  class="example-card mat-elevation-z12" >
      
         <div class="card-icon" >
           <a> 
             <img src="/assets/images/icons/ordersApproved.png" width="65" height="65"
              alt="" loading="lazy">
           </a>
         </div>
          <br>
          <mat-card-title style="margin-bottom: 6%;">Orders Approved</mat-card-title>
          <mat-card-title (click)="viewOrdersTable(orderStatus.approvedOrders, 'Orders - Approved orders')"
           style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.approvedOrderCount}}</mat-card-title>
       
     </mat-card>
     
          
         </div>
         <div fxFlex="14.2857" fxFlex.xs="50%" fxFlex.sm="33.333%" >
 
       
           <mat-card  class="example-card mat-elevation-z12" >
          
             <div class="card-icon" >
               <a> 
                 <img src="/assets/images/icons/ordersInvoiced.png" width="65" height="65"
                  alt="" loading="lazy">
               </a>
             </div>
              <br>
              <mat-card-title style="margin-bottom: 6%;">Orders Invoiced</mat-card-title>
              <mat-card-title (click)="viewOrdersTable(orderStatus.invoicedOrders, 'Orders - Invoiced')"
               style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.invoicedOrderCount}}</mat-card-title>
           
         </mat-card>
         
              
             </div>
             <div fxFlex="14.2857" fxFlex.xs="50%" fxFlex.sm="33.333%" >
 
       
               <mat-card  class="example-card mat-elevation-z12" >
              
                 <div class="card-icon" >
                   <a> 
                     <img src="/assets/images/icons/ordersShipped.png" width="65" height="65"
                      alt="" loading="lazy">
                   </a>
                 </div>
                  <br>
                  <mat-card-title style="margin-bottom: 6%;">Orders Shipped</mat-card-title>
                  <mat-card-title (click)="viewOrdersTable(orderStatus.shippedOrders, 'Orders - Shipped')"
                   style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.shippedOrderCount}}</mat-card-title>
               
             </mat-card>
             
                  
                 </div>
                 <div fxFlex="14.2857" fxFlex.xs="50%" fxFlex.sm="33.333%" >
 
       
                   <mat-card  class="example-card mat-elevation-z12" >
                  
                     <div class="card-icon" >
                       <a> 
                         <img src="/assets/images/icons/ordersDelivered.png" width="65" height="65"
                          alt="" loading="lazy">
                       </a>
                     </div>
                      <br>
                      <mat-card-title style="margin-bottom: 6%;">Orders Delivered</mat-card-title>
                      <mat-card-title  (click)="viewOrdersTable(orderStatus.deliveredOrders, 'Orders - Delivered')"
                       style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.deliveredOrderCount}}</mat-card-title>
                   
                 </mat-card>
                 
                      
                     </div>
                     <div fxFlex="14.2857" fxFlex.xs="50%" fxFlex.sm="33.333%" >
 
       
                       <mat-card  class="example-card mat-elevation-z12" >
                      
                         <div class="card-icon" >
                           <a> 
                             <img src="/assets/images/icons/ordersCancelled.png" width="65" height="65"
                              alt="" loading="lazy">
                           </a>
                         </div>
                          <br>
                          <mat-card-title style="margin-bottom: 6%;">Orders Cancelled</mat-card-title>
                          <mat-card-title (click)="viewOrdersTable(orderStatus.cancelledOrders, 'Orders - Cancelled orders')"
                           style="margin-top: 6%;color: blue;cursor: pointer;">{{orderStatus.cancelledOrderCount}}</mat-card-title>
                       
                     </mat-card>
                     
                          
                         </div>
                   
 
   </div>
   </div>



  
  <div class="row col-md-12" style="margin:0%;padding: 0%;">

    <div class="col-md-9" style="padding: 0%;">

      <div class="content" >
        <div fxLayout="row wrap" fxLayoutGap="16px grid">

          <div fxFlex="70%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
      <br>
          <div class="col-md-12 row">
            <div class="col-md-8">
              <mat-card-title>Target ({{achievedPercentage}}%)
              </mat-card-title> <br>
              <mat-card-subtitle>[ {{target.targetStartDate}} to {{target.targetEndDate}} ]
              </mat-card-subtitle>
                        <mat-card-subtitle style="font-weight: bold;">Name : {{target.targetName}}</mat-card-subtitle>
                        <mat-card-subtitle>Goal : {{target.formattedTargetValue}}</mat-card-subtitle>
                        <mat-card-subtitle>Achieved : {{target.formattedTargetAchieved}}</mat-card-subtitle>
      
              <mat-card-content>
                <p >
                  <button class="btn btn-info" [style.background-color]='getColor()' 
                  (click)="viewTargetPeriodBasedOrders()" style="width: auto;margin: 2%;" mat-button>View Details</button>
                  <button class="btn btn-info" [style.background-color]='getColor()' 
                  (click)="ViewExecTargetsByPeriod()" style="width: auto;margin: 2%;" mat-button>View Targets</button>
              
                </p>
              </mat-card-content>
            </div>
            <div class="col-md-4">
              <div style="padding : 0%">
                <h2 class="example-h2">Target Achieved</h2>
                <mat-progress-spinner
                    class="example-margin"
                    [color]="color1"
                    [mode]="mode1"
                    [value]="value1">
                </mat-progress-spinner>
                <div style="position:relative; top: -60px; left: 45px;">
                  {{value1}}%
              </div>
            </div>
            </div>
          </div>
             
           
          </mat-card>
      
           
          </div>
      
          <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="100%" >
            <mat-card class="example-card mat-elevation-z12" > <br>
      <div class="card-icon" >
        <a> <img class="card-icon-image" src="/assets/images/icons/incentive.svg"
           width="80" height="80"
           alt="" loading="lazy"> </a>
      </div>
      <br>
      <mat-card-title>Eligible Incentive</mat-card-title> <br>
      <mat-card-subtitle>{{target.activeTargetMonth}} <br></mat-card-subtitle>   
      <mat-card-title> <i class="fa fa-inr" aria-hidden="true"></i>  {{target.eligibleIncentive}} </mat-card-title> 
      <mat-card-content>
        <p> <button class="btn btn-info" [style.background-color]='getColor()' (click)="ViewExecTargetsByPeriod()" mat-button>Explore</button>
          <!-- <button class="btn btn-info" [style.background-color]='getColor()' (click)="dueOrdersDashboard()" mat-button>Explore</button> -->
        </p> </mat-card-content>
          </mat-card>
          </div>

          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >
      
            <mat-card class="example-card mat-elevation-z12" >
      
              <div class="card-icon" >
                <a> 
                  <img class="card-icon-image" src="/assets/images/icons/customers.svg" width="65" height="65"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Customers
            </mat-card-title>
            <mat-card-content>
              <p >
                <button class="btn btn-info" [style.background-color]='getColor()' (click)="customerDashboard()" mat-button>Explore</button>
              </p>
            </mat-card-content>
           
          </mat-card>
      
           
          </div>
      
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >
      
            <mat-card class="example-card mat-elevation-z12" >
           
              <div class="card-icon" >
                <a> 
                  <img src="/assets/images/icons/leads.svg" width="65" height="65"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Leads
            </mat-card-title>
            <mat-card-content>
              <p >
                <button [style.background-color]='getColor()' class="btn btn-info" (click)="LeadDashboard()" mat-button>Explore</button>
              </p>
            </mat-card-content>
            
          </mat-card>
           
          </div>
       
          <div fxFlex="33.333%" fxFlex.xs="100%" fxFlex.sm="33.333%" >
      
            
        <mat-card  class="example-card mat-elevation-z12" >
       
          <div class="card-icon" >
            <a> 
              <img src="/assets/images/icons/inv.svg" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>
           <br>
           <mat-card-title style="margin-bottom: 6%;">Orders
        </mat-card-title>
        <mat-card-content>
          <p >
            <button class="btn btn-info" [style.background-color]='getColor()' (click)="ordersDashboard()" mat-button>Explore</button>
          </p>
        </mat-card-content>
        
      </mat-card>
      
           
          </div>
    
        </div>
        </div>

    </div>
    <div class="col-md-3" style="padding: 0%;">
      <div class="content" >
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
      
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12" >
      <br>
      <div class="card-icon" >
        <a> 
          <img class="card-icon-image" src="/assets/images/icons/outstandingOverdue.png" width="72" height="72"
           alt="" loading="lazy">
        </a>
      </div>

      <br>
<br>
      <mat-card-title>Outstanding Overdue
      </mat-card-title> 
     
      <br>


      <mat-card-title>
        <i class="fa fa-inr" aria-hidden="true"></i>  {{target.outstandingOverdue}}
      </mat-card-title> 

     <br>

      <mat-card-content>
        <p>
          <button class="btn btn-info" [style.background-color]='getColor()' (click)="dueOrdersDashboard()" mat-button>Explore</button>
        </p>
      </mat-card-content>

           
          </mat-card>
      
           
          </div>

          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" >
      
            <mat-card class="example-card mat-elevation-z12 col-md-12" >
      
              <div class="col-md-12" style="text-align: center;" >
                <a> 
                  <img class="card-icon-image" src="/assets/images/icons/announcements.svg" width="65" height="65"
                   alt="" loading="lazy">
                </a>
                <br>
                <mat-card-title style="text-align: left;">Announcements
                </mat-card-title>
              </div>
               <br>
            <div style="padding: 2%;
           
overflow-y: auto;
height: 370px;">
              <h3 style="color: black;text-align: left;" *ngFor="let announcement of announcements">
                <i class="fa fa-dot-circle-o" aria-hidden="true"></i> 
                  {{announcement.message}}</h3>
            </div>
           
          </mat-card>
      
           
          </div>
       
        </div>
        </div>
    </div>

  </div>
  
 
  
   
