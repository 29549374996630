import { UserDetails } from "./user-details"

export class FormConfiguation {

    formConfigId:any //
	 activeStatus:any //
	 fkOfficeId:any //
	 columnName:any //
	 displayName:any //
	 displayStatus:any //
	 formConfigorder:any
	 groupName:any //
	 formName:any //
	 mandatory:any //
     officeName:any //

	 groupedformConfigs : FormConfiguation[] = [];

	 userDetails : UserDetails[] = [];

}