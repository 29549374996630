import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddClassComponent } from 'src/app/course-management/class/add-class/add-class.component';
import { UpdateClassComponent } from 'src/app/course-management/class/update-class/update-class.component';
import { ViewClassStudentsComponent } from 'src/app/course-management/student/view-class-students/view-class-students.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddTeacherComponent } from 'src/app/course-management/teacher/add-teacher/add-teacher.component';
import { ViewClassTeachersComponent } from 'src/app/course-management/teacher/view-class-teachers/view-class-teachers.component';
import { Class } from 'src/app/courseManagementClasses/class';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ClassService } from 'src/app/service/class.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-class-dashboard',
  templateUrl: './class-dashboard.component.html',
  styleUrls: ['./class-dashboard.component.scss']
})
export class ClassDashboardComponent implements OnInit {

  classesExist = false;
  currentRole = "CLASS_ADMIN";
  showSpinner : boolean = true;
  classLoader = false;
  classLoaderMessage!: string;
  classExists!: boolean;
  classOptions: any = {};
  loggedInUser: any;

  officeId!: string;
  userName!: string;
  userId!: string;

  modalRefs: BsModalRef[] = [];
  classes: Class[] = [];

  public screenWidth: any;
  public screenHeight: any;
  
  constructor(private classService : ClassService,
              private ngbModalService: NgbModal,
              public router: Router, 
              private toaster: ToastrService,
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

navbarTitle : any;

  ngOnInit(): void {

this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;


    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;

    this.getClassList();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.classOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    }; 
  }

  getClassList(){
    this.classService.getClassListByOfficeIdAuth(environment.apiUrl + "class/getListByOfficeId/" + this.officeId).then(data =>
      {
        this.classes = data;
        setTimeout(()=>{                           //timer
          this.showSpinner = false;
     }, 1500);
        this.classExists = true;
        this.classesExist = true;
        if(this.classes === undefined || this.classes.length === 0){
          setTimeout(()=>{                           //timer
            this.showSpinner = false;
       }, 1500);
        }

      });

    
  }
  
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  addNewClass(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddClassComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        officeId: this.officeId 
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          }); 

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Class) => {
        this.getClassList();
      });  

  }

  updateClassStatus(classId : string){
    this.classLoader = true;
    this.startClassLoading();
    this.classLoaderMessage = 'Updating Customer Status ';

  this.classService.changeStatus(classId).subscribe( data =>{

    this.getClassList();
    this.toaster.success('Class Status Updated Successfully');
      });
  }

  editClass(classId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateClassComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      

      let data = {
        classId: classId 
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          }); 

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Class) => {
        this.getClassList();
      });  

  }

  
  viewTeachers(classId : string , name : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewClassTeachersComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        
      keyboard: false
      });
      
      let data = {
        classId: classId ,
        name : name
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  }

  explore(classId : string){
    this.router.navigate(['/dashboard/class-view', classId]);
  }

  
  viewStudents(classId : string , name : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewClassStudentsComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        
      keyboard: false
      });
      
      let data = {
        classId: classId ,
        name : name
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  }

  addNewTeacher(classId : string, name : string, fkProduct : string) {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddTeacherComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        classId: classId ,
        name : name,
        productId : fkProduct
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
    
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  goToHomePage(){
  this.router.navigate(['/dashboard/class-admin']);
  }

  getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }
    changePassword(): void {
      this.router.navigate(['/auth/change-password']).then();
    }

  startClassLoading(){}
  
  reloadPage() {
    window.location.reload();
  }

}
