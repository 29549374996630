import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalClass } from 'src/app/global/global-class';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-student-info-modal',
  templateUrl: './view-student-info-modal.component.html',
  styleUrls: ['./view-student-info-modal.component.scss']
})
export class ViewStudentInfoModalComponent implements OnInit {
  showSpinner = true;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  safeImageUrl: any;
  unsafeImageUrl: any;
 
  studentId!: string;

  details : any ={
    dob: "",
    email: "",
    phone: "",
    studentImage: "",
    studentName: ""
  }

  constructor(public activeModal: NgbActiveModal,
    private sharedDataService : SharedDataService
    ) { }
    loggedInUser: any;
    theme: any;

  ngOnInit(): void {

    this.studentId = this.fromParent.studentId;
    this.details.studentName = this.fromParent.name;
    this.details.phone = this.fromParent.phone;
    this.details.email = this.fromParent.email;
    this.details.dob = this.fromParent.dob;
    this.safeImageUrl = this.fromParent.image;

  
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

  closeModal(){
    this.activeModal.close(); 
  }
  
}
