import { BotUserGroupDetails } from "./bot-user-group-details";

export class BotUserGroup {
    botUserGrpId:any
	botUserGrpStatus:any
	fkOfficeId:any
	botUserGrpName:any
	botUserGrpDescription:any
	grpMemberBotUserIds:any[]=[]
	list:BotUserGroup[]=[]
	groupDetails:BotUserGroupDetails[]=[]
}
