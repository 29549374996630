import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { UserService } from 'src/app/BIZ_CRM/biz_crm_services/user.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-change-customer-owner',
  templateUrl: './change-customer-owner.component.html',
  styleUrls: ['./change-customer-owner.component.scss']
})
export class ChangeCustomerOwnerComponent implements OnInit {

  // managerUroId : any; // getting from backend
  clicked = false;
  selectedExeUroId : any = "";
  customerId : any;

  customer : Customer = new Customer();

  executives : any[] = [{
    activeStatus: '',
    childUroId: '',
    endDate: '',
    fkParentName: '',
    fkParentUroId: '',
    hierarchyId : '',
    fkUserRoleOfficeId: '',
    roleName: '',
    startDate: '',
    exeName : '',
	  exeUroId: ''
  }];


  constructor(private userService : UserService,
    private customerService : CustomerService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr : ToastrService) { }

  @Input() fromParent: any; 
  loggedInUser : any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  theme: any;


  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.customerId = this.fromParent.customerId;

    //authorized
    this.userService.getExecutivesUnderManagerByUroId(environment.apiUrl + "bcrmUser/getExecutivesUnderManagerByUroId").then(
      data=>{        
        this.executives = data;
      });
    
  }

  change(){
    //authorized
    this.customer.customerId = this.customerId;
    this.customer.fkUserRoleOfficeId = this.selectedExeUroId;

    this.customerService.changeCustomerOwner(environment.apiUrl + "bcrmCustomer/changeCustomerOwner", this.customer).then(
      data=>{
        this.toastr.success("Owner changed successfully");
        this.passEntry.emit("success");
        this.activeModal.close();
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
