<div class="modal-header" [ngStyle]="backgroundStyle">
  <h4 style="width: 50%;color: white;"><img [src]="safeImageUrl" style="border-radius: 50%; margin-right: 1%;" height="35" width="35">{{studentName}}</h4>
  <button *ngIf="NoOfQsnsAnswered!==actQuestions.length" type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal();">
    <span aria-hidden="true">&times;</span>
  </button>
  <button *ngIf="NoOfQsnsAnswered===actQuestions.length" type="button" class="close pull-right" aria-label="Close" (click)="open(confirmClose);">
    <span aria-hidden="true">&times;</span>
  </button>
  </div> 
  
  
  <div class="modal-body">

    <h2 style="text-align: center;" *ngIf="actQuestionsExists2 === true" >No questions to display !</h2>
   <div class="questions-modal">
      <form *ngIf="actQuestionsExists === true">  
 

        <mat-vertical-stepper class="nextline-stepper" [linear]="isLinear" #stepper2>

            <div class="stepper-class" *ngFor="let question of actQuestions, let i = index">

            <mat-step [editable]="false" >
              <ng-template style="text-overflow : inherit; white-space: normal;" class="mat-label" matStepLabel> {{i+1}} </ng-template>
                <p></p>
              <form #ngFrm="ngForm">
                <a style="color: black; font-weight: bold;">{{question.activityQuestion}}</a>
                 <div *ngIf="inputType=='Radio'; else elseBlock">
                    <mat-radio-group #ngForm="ngModel" required aria-label="Select an option" [(ngModel)]="activityScore.activityScoreValue" >
                        <mat-radio-button (ngModelChange)="Change($event)"  name="activityScoreValue" id="Yes" value="1">Yes</mat-radio-button>
                        <mat-radio-button  (ngModelChange)="Change($event)" name="activityScoreValue" id="No" value="0">No</mat-radio-button>
                      </mat-radio-group>  
                </div>
                <ng-template #elseBlock>OTHER INPUTS</ng-template> 
              </form>
                <button [disabled]="!ngFrm.valid" (click)="addToArray(question.activityQuestionId)"
                 mat-raised-button color="primary" matStepperNext>Next</button>
            </mat-step>
            
        </div>

        <mat-step>
          <ng-template matStepLabel>Complete</ng-template>
          <p> 

            <button [disabled]="clicked" *ngIf="actQuestionsExists === true" style="margin: 1%;"
            class="btn btn-sm btn-primary btn-green float-left" mat-raised-button color="success" 
            (click)="onSubmit();clicked=true">Save</button>
            <button *ngIf="actQuestionsExists === true" class="btn-grey float-left"  style="margin: 1%;"
            mat-raised-button color="grey" (click)="open(confirmClose);">Cancel</button>
          </p>
        </mat-step>
        
          </mat-vertical-stepper>

          <!-- <mat-vertical-stepper class="nextline-stepper" [linear]="isLinear" #stepper2>
            <mat-step>
              <form>
                <ng-template matStepLabel>1
                </ng-template>
                Fill out your firstfirst firstfirst name and last name
                Fill out your firstfirst firstfirst name and last name
               
              </form>
            </mat-step>
          </mat-vertical-stepper> -->
          
      </form>
    </div>  
  </div>




  <ng-template #confirmClose let-modal>
    <div [ngStyle]="backgroundStyle" class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div style="color: rgb(59, 55, 55);" class="modal-body">
      Are you sure to quit assessment ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" 
      (click)="modal.dismiss('Cross click');activeModal.close();">Quit</button>
    </div>
  </ng-template> 
  

 