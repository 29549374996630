import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { Customer } from 'src/app/customer';
import { CustomerService } from 'src/app/service/customer.service';
  
@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {
 
  customer : Customer = new Customer();
  customers : Array<Customer> = [];

  constructor(private customerService : CustomerService,
    private router : Router) { }

  ngOnInit(): void {
  } 

  addCustomerForm = new FormGroup({
    requiredField : new FormControl('',[Validators.required]),
    lengthForField : new FormControl('',[Validators.minLength(4)])
  })

  get requiredField(){
    return this.addCustomerForm.get('requiredField');
  }

  get lengthForField(){
    return this.addCustomerForm.get('lengthForField');
  }

  onSubmit(){
    this.customerService.addCustomer(this.customer).subscribe(
            data=>{
        // this.customers.push(data);
        this.goToCustomerList(); 
      }
    )
    // this.customer = new Customer();
  }
  goToCustomerList(){
    this.router.navigate(['/customerList']);
  }

}
