import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-edit-active-assignment',
  templateUrl: './edit-active-assignment.component.html',
  styleUrls: ['./edit-active-assignment.component.scss']
})
export class EditActiveAssignmentComponent implements OnInit {

  teacherPlannerId : any;
  clicked = false;

  appointmentTime : any;

  teacherPlanner : any ={
    teacherPlannerId : '',
    fkActivityPlannerId : '',
    fkTeacherId: '',
    show: '',
    teacherPlannerStatus: '',
    startDateTime: '',
    endDateTime: '',
    assignmentActiveStatus: '',
    startDateTimeString : '',
    startDateTimeNew : '',
    endDateTimeNew : ''
  }

  localEndDateTimeNew : any;

  
  constructor(public activeModal: NgbActiveModal,private datePipe: DatePipe,
              private toaster: ToastrService,
              private activityPlannerService : ActivityPlannerService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

              @Input() fromParent: any;

              @Output() passEntry: EventEmitter<any> = new EventEmitter();
              
  ngOnInit(): void {

    this.teacherPlannerId = this.fromParent.teacherPlannerId;
    
    this.activityPlannerService.getTeacherPlannerById(environment.apiUrl + "activityPlanner/getTeacherPlannerForEdit/" + this.teacherPlannerId).then(
      data=>{
        
        this.teacherPlanner = data;


            let sd = this.teacherPlanner.startDateTimeNew;
            let ed = this.teacherPlanner.endDateTimeNew;

           let startDate = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);
           let endDate = new Date(ed[0], ed[1] - 1, ed[2], ed[3], ed[4]);

           this.teacherPlanner.startDateTimeNew = startDate;   
           this.teacherPlanner.endDateTimeNew = endDate;           
        
           this.teacherPlanner.startDateTimeNew = this.datePipe.transform(startDate, 'yyyy-MM-ddThh:mm');
           this.teacherPlanner.endDateTimeNew = this.datePipe.transform(endDate, 'yyyy-MM-ddThh:mm');

      });

  
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  

  onSubmit(){
      
    this.activityPlannerService.EditTeacherPlanner(environment.apiUrl + "activityPlanner/editTeacherPlanner", this.teacherPlanner).then(
      data=>{
        this.passEntry.emit('successful');
        this.activeModal.close();
        this.toaster.success('Assignment started successfully');
      });
    }
      
        closeUpdateModal(){
          this.activeModal.close();
        } 

}
