import { IncentiveTier } from "./incentive-tier";

export class Target {

    id : any;
	targetName : any;
	targetStartDate : any;
	targetEndDate : any;
	activeStatus : any;
	targetValue : any;
	targetAchieved : any;
	targetStatus : any;
	createdUroId : any;
	assignedUroId : any;
	parentTargetId : any;

	activeTargetMonth : any;
	
	outstandingOverdue : any = 0;

	formattedTargetValue : any;
	formattedTargetAchieved : any;

	eligibleIncentive:any;
	incentiveActiveStatus : any;
	incentiveStatusMessage : any;

	incentiveTierDto : IncentiveTier = new IncentiveTier();

	exeName : any;
	executiveName : any;
	executiveTargets : Target[] = [];
 
	childTargetsExist : any;
}

