import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { ActivityVideoLink } from 'src/app/courseManagementClasses/activity-video-link';
import { AddActPlnrCurrActPlnr } from 'src/app/courseManagementClasses/add-act-plnr-curr-act-plnr';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-add-instant-assignment-modal',
  templateUrl: './add-instant-assignment-modal.component.html',
  styleUrls: ['./add-instant-assignment-modal.component.scss']
})
export class AddInstantAssignmentModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private curriculumService : CurriculumService,
              private activityPlannerService : ActivityPlannerService,
              private imageService: FileServiceService ,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;
              
  clicked = false;
  selectedFile!: File;
  videoDivState : boolean = false;
  curriculums : Curriculum[] = []; 
  subId!: string;
  teacherId!: string;
  actPlnrCAP : AddActPlnrCurrActPlnr = new AddActPlnrCurrActPlnr();
  ap : ActivityPlanner = new ActivityPlanner();
  activityVideoLink : ActivityVideoLink = new ActivityVideoLink();
  activityVideoLinkList : ActivityVideoLink[] = [];
  videoCount : number = 0;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {

    this.subId = this.fromParent.subjectId;
    this.teacherId = this.fromParent.teacherId;
    this.actPlnrCAP.fkTeacherId = this.teacherId;
    
    this.curriculumService.getCurriculumListBySubId(this.subId).subscribe(
      data=>{
        this.curriculums = data;
      });
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

   openVideoDiv(){
    this.videoDivState = true;
  }

  onVideoSubmission(){
    this.activityVideoLinkList.push(this.activityVideoLink);
    this.activityVideoLink = new ActivityVideoLink();
    this.videoDivState = false;
    this.videoCount = this.videoCount + 1;
    
  }

  onFileSelected(event: any){
   this.selectedFile = event.target.files[0];
 }

 onSubmit(){


  this.actPlnrCAP.activityVideoLinkList = this.activityVideoLinkList;
  this.actPlnrCAP.fkSubjectId = this.subId;
  this.activityPlannerService.addInstantAssignment( environment.apiUrl + 'activityPlanner/addInstantAssignment', this.actPlnrCAP).then(
    data=>
    {
      this.ap = data;

      this.passEntry.emit(this.ap.activityPlannerId);
      this.activeModal.close();
      this.toaster.success("Instant Assignment Created Successfully");

      let body = new FormData();
      body.append("file", this.selectedFile);

      this.imageService.uploadActivityPlannerImage(body, this.actPlnrCAP.activityPlannerId).subscribe(
        data=>{
        });

    });
  }

  closeUpdateModal(){
    this.activeModal.close();
  } 
}
