import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-bot-form-responses',
  templateUrl: './view-bot-form-responses.component.html',
  styleUrls: ['./view-bot-form-responses.component.scss']
})
export class ViewBotFormResponsesComponent implements OnInit {


  clicked = false;
  formActives : FormActive[] = [];
  feedbackForm : FeedbackForm = new FeedbackForm();
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private router : Router,
    private datePipe : DatePipe,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

            formRespondentId : any;
            formActive : FormActive = new FormActive();
            formId:any;
            formName:any;

  ngOnInit(): void { 
    
    this.formRespondentId = this.fromParent.formRespondentId;
    this.formId = this.fromParent.formId;
    this.formName = this.fromParent.formName;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.getBotFormResponseByRespondentId();

  }

 




  getBotFormResponseByRespondentId(){
   
    this.formService.getAllFormActivesByFormId(environment.apiUrl +"feedbackForm/getBotFormResponseByRespondentId/" + this.formRespondentId).then(
      res=>{
              this.formActive = res;
              this.formActive.sortedFormRespondents.forEach((sortedFR)=>{

                sortedFR.sortedResponses.forEach((formResponse)=>{
                  if(formResponse.answerType === "timestamp"){
                    if(formResponse.answerValueTimestamp !== null){
                      formResponse.answerValueTimestampFormatted = new Date(formResponse.answerValueTimestamp[0], formResponse.answerValueTimestamp[1] - 1, formResponse.answerValueTimestamp[2], formResponse.answerValueTimestamp[3], formResponse.answerValueTimestamp[4])
                      formResponse.answerValueTimestampFormatted = this.datePipe.transform(formResponse.answerValueTimestampFormatted, 'dd-MM-yyyy hh:mm');
                     formResponse.answer = formResponse.answerValueTimestampFormatted;
                     formResponse.answerValue = formResponse.answerValueTimestampFormatted;
                      } 
                  }
                });
                
              });
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  

  exploreFormCombinedHistory(){
    this.activeModal.close();    
    this.router.navigate(['dashboard/bot/forms/form-response-history',this.formId]);
  }
}
