import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackAnswer } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-answer';
import { FeedbackAnswerValue } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-answer-value';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-feedback-answer',
  templateUrl: './create-feedback-answer.component.html',
  styleUrls: ['./create-feedback-answer.component.scss']
})
export class CreateFeedbackAnswerComponent implements OnInit {

  clicked = false;
  feedbackAnswer : FeedbackAnswer = new FeedbackAnswer();
  answerValuesList : FeedbackAnswerValue[] = [];
  ansValue : any ;
  loggedInUser : any; 
  theme : any;
  closeResult!: string;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();    
  }

  addFeedbackAnswerValues(){

    if(this.ansValue !== null){
      let answervalue : FeedbackAnswerValue = new FeedbackAnswerValue();
      answervalue.answerValue = this.ansValue;
      this.answerValuesList.push(answervalue);
      this.ansValue = null;
    }

  }

  onSubmit(){
    //authorized
    this.feedbackAnswer.answerValuesList = this.answerValuesList;
    this.formService.addFeedbackQuestion(environment.apiUrl + "feedbackAnswer/addFeedbackAnswerAndAnswerValues", this.feedbackAnswer).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Answer created successfully');
        this.passEntry.emit('success');
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
}
 