export class BotConvoLog {
  botConvoLogId: any;
  botConvoLogStatus: any;
  request: any;
  response: any;
  timestamp: any;
  fkBotUserId: any;
  list: BotConvoLog[] = [];
  lastLogItemTimestamp: any;
  lastLogItemId: any;
  lastLogItemMessage: any;
  lastLogItemDate: any;
  userRespondedType: any;
  erroneousResFromUser: any;
  locationUrl: any;
  respondedBy: any;
  fkResponseBotMediaId: any;
  fkRequestBotMediaId: any;
  botRespondedType: any;
  unseenLogCount: any;
  latestFetchedConvoLogId: any;
  errorMsg: any;
}
