import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotTemplate } from "src/app/BOT_CODE/bot_classes/bot-template";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { UploadBotMediaComponent } from "../../bot-media/upload-bot-media/upload-bot-media.component";
import { BotScheduleTempVariable } from "src/app/BOT_CODE/bot_classes/bot-schedule-temp-variable";
import { AddBotResponseOptionsComponent } from "../../bot_response_options/add-bot-response-options/add-bot-response-options.component";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";

@Component({
  selector: "app-create-bot-template-modal",
  templateUrl: "./create-bot-template-modal.component.html",
  styleUrls: ["./create-bot-template-modal.component.scss"],
})
export class CreateBotTemplateModalComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    private activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    public toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botTemplate: BotTemplate = new BotTemplate();
  context: any;
  media: BotMedia[] = [];
  allMedia: BotMedia[] = [];

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  matSpinnerActive = false;

  chosenLanguage: any = "";
  languageCodes: any[] = [
    { lang: "Afrikaans", code: "af" },
    { lang: "Albanian", code: "sq" },
    { lang: "Arabic", code: "ar" },
    { lang: "Azerbaijani", code: "az" },
    { lang: "Bengali", code: "bn" },
    { lang: "Bulgarian", code: "bg" },
    { lang: "Catalan", code: "ca" },
    { lang: "Chinese (CHN)", code: "zh_CN" },
    { lang: "Chinese (HKG)", code: "zh_HK" },
    { lang: "Chinese (TAI)", code: "zh_TW" },
    { lang: "Croatian", code: "hr" },
    { lang: "Czech", code: "cs" },
    { lang: "Danish", code: "da" },
    { lang: "Dutch", code: "nl" },
    { lang: "English", code: "en" },
    { lang: "English (UK)", code: "en_GB" },
    { lang: "English (US)", code: "en_US" },
    { lang: "Estonian", code: "et" },
    { lang: "Filipino", code: "fil" },
    { lang: "Finnish", code: "fi" },
    { lang: "French", code: "fr" },
    { lang: "Georgian", code: "ka" },
    { lang: "German", code: "de" },
    { lang: "Greek", code: "el" },
    { lang: "Gujarati", code: "gu" },
    { lang: "Hausa", code: "ha" },
    { lang: "Hebrew", code: "he" },
    { lang: "Hindi", code: "hi" },
    { lang: "Hungarian", code: "hu" },
    { lang: "Indonesian", code: "id" },
    { lang: "Irish", code: "ga" },
    { lang: "Italian", code: "it" },
    { lang: "Japanese", code: "ja" },
    { lang: "Kannada", code: "kn" },
    { lang: "Kazakh", code: "kk" },
    { lang: "Kinyarwanda", code: "rw_RW" },
    { lang: "Korean", code: "ko" },
    { lang: "Kyrgyz (Kyrgyzstan)", code: "ky_KG" },
    { lang: "Lao", code: "lo" },
    { lang: "Latvian", code: "lv" },
    { lang: "Lithuanian", code: "lt" },
    { lang: "Macedonian", code: "mk" },
    { lang: "Malay", code: "ms" },
    { lang: "Malayalam", code: "ml" },
    { lang: "Marathi", code: "mr" },
    { lang: "Norwegian", code: "nb" },
    { lang: "Persian", code: "fa" },
    { lang: "Polish", code: "pl" },
    { lang: "Portuguese (BR)", code: "pt_BR" },
    { lang: "Portuguese (POR)", code: "pt_PT" },
    { lang: "Punjabi", code: "pa" },
    { lang: "Romanian", code: "ro" },
    { lang: "Russian", code: "ru" },
    { lang: "Serbian", code: "sr" },
    { lang: "Slovak", code: "sk" },
    { lang: "Slovenian", code: "sl" },
    { lang: "Spanish", code: "es" },
    { lang: "Spanish (ARG)", code: "es_AR" },
    { lang: "Spanish (SPA)", code: "es_ES" },
    { lang: "Spanish (MEX)", code: "es_MX" },
    { lang: "Swahili", code: "sw" },
    { lang: "Swedish", code: "sv" },
    { lang: "Tamil", code: "ta" },
    { lang: "Telugu", code: "te" },
    { lang: "Thai", code: "th" },
    { lang: "Turkish", code: "tr" },
    { lang: "Ukrainian", code: "uk" },
    { lang: "Urdu", code: "ur" },
    { lang: "Uzbek", code: "uz" },
    { lang: "Vietnamese", code: "vi" },
    { lang: "Zulu", code: "zu" },
  ];

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.getBotMediaByOfficeId();

    this.context = this.fromParent.context;
    if (this.context === "Create Template") {
      this.botTemplate.createTemplateOnMeta = false;
      this.botTemplate.botTemplateId = null;
      this.botTemplate.tempHeaderType = "none";
      this.botTemplate.fkDefaultHeaderBotMediaId = null;
      this.botTemplate.tempVarCount = 0;
    }

    if (this.context === "Edit Template") {
      this.botTemplate = this.fromParent.botTemplate;

      this.filterMedia(this.botTemplate.tempHeaderType);

      if (
        this.botTemplate.tempVarCount !== this.botTemplate.tempVarList.length
      ) {
        this.tempVarCountChange();
      }
    }
  }

  addVariableToBody() {
    if (
      this.botTemplate.tempBody !== null &&
      this.botTemplate.tempBody !== undefined &&
      this.botTemplate.tempBody.length > 0
    ) {
      this.botTemplate.tempVarCount = this.botTemplate.tempVarCount + 1;
      this.botTemplate.tempBody =
        this.botTemplate.tempBody +
        " {{" +
        this.botTemplate.tempVarCount +
        "}}";
    } else {
      this.toastr.error("Template Body cannot start or end with variables.");
    }
  }

  tempVarCountChange() {
    this.botTemplate.tempVarList = [];
    this.botTemplate.tempVarSampleContentList = [];

    let count = this.botTemplate.tempVarCount + 1;
    for (let i = 1; i < count; i++) {
      let tempVar: BotScheduleTempVariable = new BotScheduleTempVariable();
      tempVar.botScheduleTempVarOrder = i;
      this.botTemplate.tempVarList.push(tempVar);

      if (this.botTemplate.createTemplateOnMeta === true) {
        let tempVarSampleContent: BotScheduleTempVariable =
          new BotScheduleTempVariable();
        tempVarSampleContent.botScheduleTempVarOrder = i;
        this.botTemplate.tempVarSampleContentList.push(tempVarSampleContent);
      }
    }
  }

  onSubmit() {
    this.clicked = true;

    if (this.botTemplate.tempVarCount !== this.botTemplate.tempVarList.length) {
      this.toastr.error(
        "Variable count does not match the variables entered !"
      );
      this.clicked = false;
      this.tempVarCountChange();
    } else {
      this.botTemplate.templateButtons = this.templateButtons;
      this.botTemplate.templateDisplayName = this.botTemplate.templateName;
      this.botOfficeService
        .createBotSchedule(
          environment.apiUrl + "botOffice/createOrEditBotTemplate",
          this.botTemplate
        )
        .then((res) => {
          this.botTemplate = res;
          if (this.context === "Create Template") {
            this.toastr.success("Template created successfully !");
          }
          if (this.context === "Edit Template") {
            this.toastr.success("Template updated successfully !");
          }
          this.passEntry.emit(this.botTemplate);
          this.activeModal.close();
          // this.matSpinnerActive=false
          // this.clicked=false
        });
    }
  }

  langChangeFunc() {
    this.botTemplate.tempLanguage = this.chosenLanguage.lang;
    this.botTemplate.tempLanguageCode = this.chosenLanguage.code;
  }

  getBotMediaByOfficeId() {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getBotMediaByOfficeId"
      )
      .then((res) => {
        this.allMedia = [];
        let md: BotMedia = new BotMedia();
        md = res;
        this.allMedia = md.list;

        this.filterMedia(this.botTemplate.tempHeaderType);
      });
  }

  filterMedia(type: any) {
    this.media = [];
    if (type === "IMAGE" || type === "image" || type === "image with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "png" ||
          med.mediaType === "PNG" ||
          med.mediaType === "jpeg" ||
          med.mediaType === "JPEG" ||
          med.mediaType === "jpg" ||
          med.mediaType === "JPG"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "VIDEO" || type === "video" || type === "video with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "MP4" ||
          med.mediaType === "3gp" ||
          med.mediaType === "3GP"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "audio") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "mpeg" ||
          med.mediaType === "aac" ||
          med.mediaType === "ogg" ||
          med.mediaType === "amr" ||
          med.mediaType === "MP4" ||
          med.mediaType === "MPEG" ||
          med.mediaType === "AAC" ||
          med.mediaType === "OGG" ||
          med.mediaType === "AMR"
        ) {
          this.media.push(med);
        }
      });
    }
    if (
      type === "DOCUMENT" ||
      type === "document" ||
      type === "document with buttons"
    ) {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "pdf" ||
          med.mediaType === "text" ||
          med.mediaType === "xls" ||
          med.mediaType === "xlsx" ||
          med.mediaType === "csv" ||
          med.mediaType === "pptx" ||
          med.mediaType === "doc" ||
          med.mediaType === "docx" ||
          med.mediaType === "PDF" ||
          med.mediaType === "TEXT" ||
          med.mediaType === "XLS" ||
          med.mediaType === "XLSX" ||
          med.mediaType === "CSV" ||
          med.mediaType === "PPTX" ||
          med.mediaType === "DOC" ||
          med.mediaType === "DOCX"
        ) {
          this.media.push(med);
        }
      });
    }
  }

  uploadBotMedia() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Upload New Media",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotMediaByOfficeId();
    });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  closeResult!: string;

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  templateButtons: BotResponseOptions[] = [];
  addButtonsToTemplate() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addButtonsToTemplate",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        if (
          this.templateButtons === null ||
          this.templateButtons === undefined
        ) {
          this.templateButtons = [];
        }
        this.templateButtons.push(receivedEntry);
      }
    );
  }

  uploadMediaToResumableUploadApi() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "uploadMediaToResumableUploadApi",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotMedia) => {
        if (receivedEntry.reuploadStatus === false) {
          // this.toastr.error("Media upload failed !");
          // this.activeModal.close();
        } else if (receivedEntry.reuploadStatus === true) {
          this.botTemplate.fkDefaultHeaderBotMediaId = receivedEntry.botMediaId;
          this.botTemplate.fileHandle = receivedEntry.fileHandle;
          // this.toastr.success("Media upload successfull !");
          // this.passEntry.emit(resBotMedia);
          // this.activeModal.close();
        }
      }
    );
  }
}
