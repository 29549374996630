import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotTemplate } from "src/app/BOT_CODE/bot_classes/bot-template";
import { BotAutoSchedulerSettings } from "src/app/BOT_CODE/bot_classes/bot-auto-scheduler-settings";
import { FormControl } from "@angular/forms";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotNotificationConfiguration } from "src/app/BOT_CODE/bot_classes/bot-notification-configuration";
import { BotAdminNotification } from "src/app/BOT_CODE/bot_classes/bot-admin-notification";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";

@Component({
  selector: "app-create-bot-auto-scheduler-settings",
  templateUrl: "./create-bot-auto-scheduler-settings.component.html",
  styleUrls: ["./create-bot-auto-scheduler-settings.component.scss"],
})
export class CreateBotAutoSchedulerSettingsComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;
  showMatSpinner = false;
  searchText: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private ngbModalService: NgbModal,
    private sharedDataService: SharedDataService,
    private botResponseService: BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botTemplates: BotTemplate[] = [];
  botAutoSchSetting: BotAutoSchedulerSettings = new BotAutoSchedulerSettings();

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  contextArray: any[] = [
    { id: "AS", context: "Auto Schedule" },
    { id: "NMC", context: "Notify Manual Chat Requests" },
    { id: "NAA", context: "Notify Agents Assignment" },
  ];

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    if (this.fromParent.context === "Create NMC Settings") {
      this.botAutoSchSetting.fkBotTemplateId = "";
      this.botAutoSchSetting.botAutoSchedulerSettingsId = null;
      this.botAutoSchSetting.context = "NMC";
      this.botAutoSchSetting.scheduleAfterDays = 0;
    }
    if (this.fromParent.context === "Create NAA Settings") {
      this.botAutoSchSetting.fkBotTemplateId = "";
      this.botAutoSchSetting.botAutoSchedulerSettingsId = null;
      this.botAutoSchSetting.context = "NAA";
      this.botAutoSchSetting.scheduleAfterDays = 0;
    }
    if (this.fromParent.context === "Create Settings") {
      this.botAutoSchSetting.fkBotTemplateId = "";
      this.botAutoSchSetting.botAutoSchedulerSettingsId = null;
      this.botAutoSchSetting.context = "AS";
      this.botAutoSchSetting.scheduleAfterDays = 0;
    }
    if (this.fromParent.context === "Edit Settings") {
      this.botAutoSchSetting = this.fromParent.botAutoSchedulerSettings;
    }
    if (this.fromParent.context === "Configure NOP Settings") {
      this.botAutoSchSetting = this.fromParent.botAutoSchedulerSettings;

      if (
        this.botAutoSchSetting.botNotificationConfigurations !== null &&
        this.botAutoSchSetting.botNotificationConfigurations !== undefined &&
        this.botAutoSchSetting.botNotificationConfigurations.length > 0
      ) {
        this.botAutoSchSetting.botNotificationConfigurations.forEach((config) => {
          this.existingNotifConfigAdminsAndAgents.push(config.fkBotUserId);
        });
      }
    }

    if (this.fromParent.context === "Create Shopify Abandoned Cart Notification Schedules") {
      this.botAutoSchSetting.fkBotTemplateId = "";
      this.botAutoSchSetting.botAutoSchedulerSettingsId = null;
      this.botAutoSchSetting.context = "shopify_abandoned_cart_notifications";
      this.botAutoSchSetting.scheduleAfterDays = 0;
    }

    this.getWhatsappCloudApiTemplatesByOfficeId();
  }

  multiSelectValueChange(value: any) {}

  getWhatsappCloudApiTemplatesByOfficeId() {
    this.botOfficeService
      .getWhatsappCloudApiTemplatesByOfficeId(
        environment.apiUrl + "botOffice/getWhatsappCloudApiTemplatesByOfficeId"
      )
      .then((res) => {
        let bTemp: BotTemplate = new BotTemplate();
        bTemp = res;
        this.botTemplates = bTemp.list;
      });
  }

  onSubmit() {
    this.clicked = true;
    this.botOfficeService
      .createBotSchedule(
        environment.apiUrl + "botOffice/createOrEditBotAutoSchedulerSettings",
        this.botAutoSchSetting
      )
      .then((res) => {
        this.botAutoSchSetting = res;
        this.toastr.success("Auto-Scheduler Settings created successfully !");
        this.passEntry.emit(res);
        this.activeModal.close();
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  selectedAll = false;
  selectedUsers: BotCustomerUser[] = [];
  allUsers: BotCustomerUser[] = [];
  toppingsControl = new FormControl([]);
  existingNotifConfigAdminsAndAgents: any[] = [];

  selectAll(value: any, botUserObj: any) {
    // case 1
    if (value === "Select All" && this.selectedAll === false) {
      //
      let allUserIds: any[] = [];
      allUserIds.push(value);
      this.allUsers.forEach((user) => {
        this.selectedUsers.push(user);
        allUserIds.push(user.botUserId);
      });
      this.allUsers = [];
      this.toppingsControl.setValue(allUserIds);
      this.selectedAll = true;
      // case 2
    } else if (value === "Select All" && this.selectedAll === true) {
      //
      this.selectedUsers.forEach((user) => {
        this.allUsers.push(user);
      });
      this.selectedUsers = [];
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
      // case 3
    } else if (value !== "Select All" && this.selectedAll === true) {
      //
      this.selectedUsers.push(botUserObj);

      for (var i = this.allUsers.length - 1; i >= 0; --i) {
        if (this.allUsers[i].botUserId == botUserObj.botUserId) {
          this.allUsers.splice(i, 1);
        }
      }

      let selectedUserIds: any[] = this.toppingsControl.value;
      selectedUserIds.splice(selectedUserIds.indexOf("Select All"), 1);
      this.selectedAll = false;
      this.toppingsControl.setValue(selectedUserIds);
      // case 4
    } else if (value !== "Select All" && this.selectedAll === false) {
      //
      this.selectedUsers.push(botUserObj);
      for (var i = this.allUsers.length - 1; i >= 0; --i) {
        if (this.allUsers[i].botUserId == botUserObj.botUserId) {
          this.allUsers.splice(i, 1);
        }
      }
    }
  }

  onToppingRemoved(user: any) {
    this.allUsers.push(user);
    for (var i = this.selectedUsers.length - 1; i >= 0; --i) {
      if (this.selectedUsers[i].botUserId == user.botUserId) {
        this.selectedUsers.splice(i, 1);
      }
    }

    if (this.selectedAll === true) {
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
    }
  }

  deleteBotNotificationConfig(notifConfig: BotNotificationConfiguration) {
    this.showMatSpinner = true;
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl +
          "botConvo/deleteBotNotificationConfig/" +
          notifConfig.botNotificationConfigId
      )
      .then((res) => {
        const index: number = this.existingNotifConfigAdminsAndAgents.indexOf(
          notifConfig.fkBotUserId
        );
        if (index !== -1) {
          this.existingNotifConfigAdminsAndAgents.splice(index, 1);
        }
        let botNotificationConfigurations: BotNotificationConfiguration[] = [];
        this.botAutoSchSetting.botNotificationConfigurations.forEach(
          (config) => {
            if (
              config.botNotificationConfigId !==
              notifConfig.botNotificationConfigId
            ) {
              botNotificationConfigurations.push(config);
            }
          }
        );
        this.botAutoSchSetting.botNotificationConfigurations = [];
        this.botAutoSchSetting.botNotificationConfigurations =
          botNotificationConfigurations;
        this.toastr.success("Configuration updated successfully !");
        this.passEntry.emit(this.botAutoSchSetting);
        this.showMatSpinner = false;
      });
  }

  closeResult!: string;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  getBotOfficeAdminsAndAgents() {
    this.botOfficeService
      .getBotUsers(environment.apiUrl + "botOffice/getBotOfficeAdminsAndAgents")
      .then((res) => {
        this.allUsers = [];
        this.selectedUsers = [];
        this.toppingsControl.setValue([]);

        let botOfficeAdminsAndAgents: BotCustomerUser = new BotCustomerUser();
        botOfficeAdminsAndAgents = res;
        if (this.existingNotifConfigAdminsAndAgents.length === 0) {
          this.allUsers = botOfficeAdminsAndAgents.users;
        } else {
          botOfficeAdminsAndAgents.users.forEach((user) => {
            if (
              this.existingNotifConfigAdminsAndAgents.includes(
                user.botUserId
              ) === false
            ) {
              this.allUsers.push(user);
            }
          });
        }
        this.selectedUsers = [];
      });
  }

  createWhatsappNotificationConfigs() {
    this.showMatSpinner = true;
    if (
      this.selectedUsers !== null &&
      this.selectedUsers !== undefined &&
      this.selectedUsers.length > 0
    ) {
      let userIds: string[] = [];
      this.selectedUsers.forEach((user) => {
        userIds.push(user.botUserId);
      });

      let botAdminNotif: BotAdminNotification = new BotAdminNotification();

      botAdminNotif.userIds = userIds;

      botAdminNotif.fkBotAutoSchedulerSettingsId =
        this.botAutoSchSetting.botAutoSchedulerSettingsId;
      this.botResponseService
        .assignSections(
          environment.apiUrl + "botOffice/createWhatsappNotificationConfigs",
          botAdminNotif
        )
        .then((res) => {
          userIds.forEach((uId) => {
            this.existingNotifConfigAdminsAndAgents.push(uId);
          });
          botAdminNotif = res;
          if (
            this.botAutoSchSetting.botNotificationConfigurations === null ||
            this.botAutoSchSetting.botNotificationConfigurations ===
              undefined ||
            (this.botAutoSchSetting.botNotificationConfigurations !== null &&
              this.botAutoSchSetting.botNotificationConfigurations !==
                undefined &&
              this.botAutoSchSetting.botNotificationConfigurations.length === 0)
          ) {
            this.botAutoSchSetting.botNotificationConfigurations =
              botAdminNotif.botNotificationConfigurations;
          } else if (
            this.botAutoSchSetting.botNotificationConfigurations !== null &&
            this.botAutoSchSetting.botNotificationConfigurations !==
              undefined &&
            this.botAutoSchSetting.botNotificationConfigurations.length > 0
          ) {
            botAdminNotif.botNotificationConfigurations.forEach((config) => {
              this.botAutoSchSetting.botNotificationConfigurations.push(config);
            });
          }
          this.passEntry.emit(this.botAutoSchSetting);
          this.toastr.success("Configuration updated successfully!");
          this.showMatSpinner = false;
        });
    } else {
      this.toastr.error("Choose Admins/Agents to proceed");
      this.showMatSpinner = false;
    }
  }
}
