import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/courseManagementClasses/role';
import { GlobalClass } from 'src/app/global/global-class';
import { RoleService } from 'src/app/service/courseManagementServices/role.service';
import { UserSessionService } from 'src/app/service/user-session.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-switch-dashboard',
  templateUrl: './switch-dashboard.component.html',
  styleUrls: ['./switch-dashboard.component.scss']
})
export class SwitchDashboardComponent implements OnInit {

  // email to username change 15 times 
  userId!: string;
  roles : Role[] = [];
  rolesFiltered : Role[] = [];
  loggedInUser0: any;
  loggedInUser: any;
  theme: any;

  loggedInUserParsed : any;
  currentRole! : string;
  currentDisplayName! : string;

  constructor(public activeModal: NgbActiveModal,
              private router : Router,
              private sharedDataService : SharedDataService,
              private userSessionService : UserSessionService,
              private roleService : RoleService ) { }

  @Input() fromParent: any;

  @Output() public passEntry : EventEmitter<any> = new EventEmitter(); 

  ngOnInit(): void {

    this.userId = this.fromParent.userId;
    this.currentRole = this.fromParent.currentRole;

    this.loggedInUser0 = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    
    this.roleService.getRoleListByUserId(this.userId).subscribe(
      data=>{        
        this.roles = data;     
        this.roles.forEach((role)=>{
          if(role.roleName === this.currentRole){
            this.currentDisplayName = role.displayName;
          }
        })   
      });
      
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser0.offices[0].theme);
  }

  switchRole(roleName : string){
    if(roleName === "OFFICE_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);
    
      this.roles.forEach((role)=>{
        if(role.roleName === "OFFICE_ADMIN"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/office-admin']);
            });
        }
      })
      
    }else if(roleName === "COURSE_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "COURSE_ADMIN"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(   
            this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/curriculum-admin']);
            });
        }
      })

      

    }else if(roleName === "CRM_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CRM_ADMIN"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/crm-admin']);
            });
        }
      })

      

    }else if(roleName === "CLASS_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CLASS_ADMIN"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/class-admin']);
            });
        }
      });

      

    }else if(roleName === "PARENT_MENTOR"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "PARENT_MENTOR"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/UserParent']);
            });
        }
      });


    }else if(roleName === "PARENT"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "PARENT"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/parent']);
            });
        }
      });


    }else if(roleName === "STUDENT"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "STUDENT"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/student']);
            });
        }
      });


    }else if(roleName === "BCRM_OFFICE_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_OFFICE_ADMIN"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-office-admin']);
            });
        }
      });


    }
    else if(roleName === "BCRM_PM"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_PM"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe( 
              this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-product-management']);
            });
        }
      });


    }
    else if(roleName === "BCRM_DM"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_DM"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe( 
              this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-dispatch-management']);
            });
        }
      });


    }
    else if(roleName === "BCRM_FM"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_FM"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(   
            this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-finance-management']);
            });
        }
      });


    }
    else if(roleName === "BCRM_TM"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_TM"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-top-management']);
            });
        }
      });


    }
    else if(roleName === "BCRM_MAN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_MAN"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-man-dashboard']);
            });
        }
      });


    }
    else if(roleName === "BCRM_EXE"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_EXE"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-exe-dashboard']);
            });
        }
      });


    }
    else if(roleName === "BCRM_FEEDBACK_MANAGER"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_FEEDBACK_MANAGER"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-feedback-manager']);
            });
        }
      });


    }
    else if(roleName === "BCRM_SALES_COORDINATOR"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_SALES_COORDINATOR"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-sales-coordinator-dashboard']);
            });
        }
      });


    }
    else if(roleName === "BCRM_DATA"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "BCRM_DATA"){
          //this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.email, role.userRoleOfficeId).subscribe(  
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/bcrm-data-dashboard']);
            });
        }
      });


    }
    else if(roleName === "CB_OFFICE_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CB_OFFICE_ADMIN"){
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/cb-office-admin']);
            });
        }
      });


    }
    else if(roleName === "CB_AGENT"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CB_AGENT"){
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/cb-agent-convo-log-view']);
            });
        }
      });


    }    else if(roleName === "CB_NOTIFICATIONS_MANAGER"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CB_NOTIFICATIONS_MANAGER"){
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/office/notifications']);
            });
        }
      });


    }
    else if(roleName === "CB_SHOP_ORDERS_MANAGER"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CB_SHOP_ORDERS_MANAGER"){
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/office/shop-orders']);
            });
        }
      });


    }
    else if(roleName === "CB_ORG_ADMIN"){

      this.loggedInUser = localStorage.getItem('loggedUser');
      this.loggedInUserParsed = JSON.parse(this.loggedInUser);
      this.loggedInUserParsed.currentActiveRole = roleName;
      this.loggedInUser = JSON.stringify(this.loggedInUserParsed);
      localStorage.setItem('loggedUser', this.loggedInUser);

      this.roles.forEach((role)=>{
        if(role.roleName === "CB_ORG_ADMIN"){
             this.userSessionService.updateUserSessionOnRoleChange(this.loggedInUser0.username, role.userRoleOfficeId).subscribe(
            data=>{
              this.router.navigate(['/dashboard/cb-org-admin/home']);
            });
        }
      });
      
    }
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser0.offices[0].theme);
    return this.loggedInUser0.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.activeModal.close();
  }  

}
