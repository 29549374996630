import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";

@Component({
  selector: "app-upload-bot-media",
  templateUrl: "./upload-bot-media.component.html",
  styleUrls: ["./upload-bot-media.component.scss"],
})
export class UploadBotMediaComponent implements OnInit {
  clicked = false;
  userExists = false;
  matSpinnerActive = false;
  loggedInUser: any;
  theme: any;
  context: any;
  supportedMediaTypesVisible = false;
  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botMedia: BotMedia = new BotMedia();
  selectedMediaFile!: File;
  mediaFileSelected = false;

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.context = this.fromParent.context;

    if (this.context === "Reupload Media") {
      let med: BotMedia = new BotMedia();
      med = this.fromParent.botMedia;
      this.botMedia.botMediaId = med.botMediaId;
    }

    if (this.context === "Edit Media") {
      let med: BotMedia = new BotMedia();
      med = this.fromParent.botMedia;
      this.botMedia.botMediaId = med.botMediaId;
      this.botMedia.title = med.title;
      this.botMedia.description = med.description;
    }

    if (this.context === "Send Response With Media And Caption") {
      this.botMedia.receiverBotUserId = this.fromParent.receiverBotUserId;
      this.botMedia.receiverBotUsername = this.fromParent.receiverBotUsername;
      this.botMedia.mediaType = this.fromParent.mediaType;
    }
  }

  onFileSelected(event: any) {
    this.selectedMediaFile = event.target.files[0];
    this.mediaFileSelected = true;
  }

  reuploadMedia() {
    if (this.mediaFileSelected === false) {
      this.toastr.error("Choose media file to upload");
    } else if (this.mediaFileSelected === true) {
      this.clicked = true;
      let body = new FormData();
      body.append("multipartMedia", this.selectedMediaFile);
      body.append("mediaAsString", JSON.stringify(this.botMedia));

      this.botOfficeService
        .whatsappCloudApiMediaUpload(
          environment.apiUrl + "botMedia/whatsappCloudApi/media/reupload",
          body
        )
        .then((res) => {
          if (res.reuploadStatus === false) {
            this.toastr.error("Media upload failed !");
            this.activeModal.close();
          } else if (res.reuploadStatus === true) {
            this.botMedia.mediaType = res.mediaType;
            this.botMedia.wcaMediaId = res.wcaMediaId;
            this.toastr.success("Media reupload successfull !");
            this.passEntry.emit(this.botMedia);
            this.activeModal.close();
          }
        });
    }
  }

  uploadMediaToResumableUploadApi() {
    if (this.mediaFileSelected === false) {
      this.toastr.error("Choose media file to upload");
    } else if (this.mediaFileSelected === true) {
      this.clicked = true;
      let body = new FormData();
      body.append("multipartMedia", this.selectedMediaFile);
      body.append("mediaAsString", JSON.stringify(this.botMedia));

      this.botOfficeService
        .whatsappCloudApiMediaUpload(
          environment.apiUrl +
            "botMedia/whatsappCloudApi/uploadMediaToResumableUploadApi",
          body
        )
        .then((res) => {
          let resBotMedia: BotMedia = new BotMedia();
          resBotMedia = res;
          if (resBotMedia.reuploadStatus === false) {
            this.toastr.error("Sample Media upload failed !");
            this.activeModal.close();
          } else if (resBotMedia.reuploadStatus === true) {
            this.toastr.success("Sample Media upload successfull !");
            this.passEntry.emit(resBotMedia);
            this.activeModal.close();
          }
        });
    }
  }

  uploadNewMedia() {
    if (this.mediaFileSelected === false) {
      this.toastr.error("Choose media file to upload");
    } else if (this.mediaFileSelected === true) {
      this.clicked = true;
      let body = new FormData();
      body.append("multipartMedia", this.selectedMediaFile);
      body.append("mediaAsString", JSON.stringify(this.botMedia));

      this.botOfficeService
        .whatsappCloudApiMediaUpload(
          environment.apiUrl + "botMedia/whatsappCloudApi/media/upload",
          body
        )
        .then((res) => {
          if (res === false) {
            this.toastr.error("Media upload failed !");
            this.activeModal.close();
          } else if (res === true) {
            this.toastr.success("Media upload successfull !");
            this.passEntry.emit("success");
            this.activeModal.close();
          }
        });
    }
  }

  sendMediaResponse() {
    if (this.mediaFileSelected === false) {
      this.toastr.error("Choose media file to upload");
    } else if (this.mediaFileSelected === true) {
      this.clicked = true;
      let body = new FormData();
      body.append("multipartMedia", this.selectedMediaFile);
      body.append("mediaAsString", JSON.stringify(this.botMedia));

      this.botOfficeService
        .whatsappCloudApiMediaUpload(
          environment.apiUrl + "botMedia/sendMediaResponse",
          body
        )
        .then((res) => {
          if (res === false) {
            this.toastr.error("Could not share media !");
            this.activeModal.close();
          } else if (res === true) {
            this.toastr.success("Media shared successfull !");
            this.passEntry.emit("success");
            this.activeModal.close();
          }
        });
    }
  }

  updateMedia() {
    this.botOfficeService
      .updateMedia(
        environment.apiUrl + "botMedia/updateBotMediaObject",
        this.botMedia
      )
      .then((res) => {
        this.toastr.success("Media updated successfully !");
        this.passEntry.emit(this.botMedia);
        this.activeModal.close();
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
