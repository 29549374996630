<div class="modal-header" [ngStyle]="backgroundStyle" >
    <h4 style="color: white;" class="modal-title pull-left">Update Subject : {{subject.name}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

<div class="modal-body">
  <!-- <div class="card"> -->
    <div class="card-body"> 
    <form (ngSubmit)="onSubmit()" #subForm="ngForm"> 
    
      <div style="width: 100%;color: black;text-align: center;" >
        <a style="cursor:pointer;">
           <img style="border-radius: 50%;" [src]="safeImageUrl" width="75" height="75"
           alt="" loading="lazy">
        </a>
      </div>
 
      <div class="form-group row"> 

        <div class="col-md-6">
        <label class="required" for="name">Name</label>
        <input   maxlength="50"   #name="ngModel" required type="text" id="name" name="name" class="form-control" placeholder="Enter Subject Name" [(ngModel)]="subject.name">
        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
      </div>
<div class="col-md-6">
        <label for="name">Subject Image</label>
        <input type="file" class="form-control" (change)="onFileSelected($event)" placeholder=" Choose File To Upload" >
      </div>
      </div>
 <br>
    <div>
        <label class="required" for="description">Description</label>
        <textarea   maxlength="250" required type="text" id="description" name="description" class="form-control" placeholder="Enter Description" [(ngModel)]="subject.description"></textarea>
    </div>

  

   

    
</form>
    <!-- </div> -->
  </div>
</div>

<div class="modal-footer">
    <button  [disabled]="clicked" [disabled]="!subForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Update</button>
  </div>
