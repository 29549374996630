import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { ChangeCustomerOwnerComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_roles/change-customer-owner/change-customer-owner.component';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-man-customers',
  templateUrl: './bcrm-man-customers.component.html',
  styleUrls: ['./bcrm-man-customers.component.scss']
})
export class BcrmManCustomersComponent implements OnInit {

  currentRole = "BCRM_MAN";

  customerLoaderMessage!: string;

  customerExists: boolean = false;

  userName ! : string;

  customerOptions : any;

  ccdetails: CompleteCustomerDetails[] = [];

  customers : Customer[] = [];

  customer : CompleteCustomerDetails = new CompleteCustomerDetails();

  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  
  customerId: any;
  userId!: string
  navbarTitle: any;
  totalCount : any = 0;

  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private spinner: NgxSpinnerService, 
              private toaster: ToastrService,
              public bsModalRef: BsModalRef,
              private ccdetailsService:CompCustDetailsService, 
              private CustomerService : CustomerService,
              private sharedDataService : SharedDataService) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    showSpinner = false;

  ngOnInit(): void {

    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getCcDetails();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

  openCustomerDetailsPage(id : any){
    this.router.navigate(['/dashboard/bcrm-customer-profile', id]);
  }

//authorized
  changeOwner(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ChangeCustomerOwnerComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        customerId : id
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
        this.getCcDetails();
      });  
  }

  // authorized
  getCcDetails(){
    this.showSpinner = true;
    this.CustomerService.getCustomersForBCRM_MANByUroId(environment.apiUrl + 'bcrmCustomer/getCustomersForBCRM_MANByUroId').then(
      data=>{
        this.customers = data;
        this.customers.forEach((cust)=>{
          cust.outstandingAmount =  Math.round((cust.outstandingAmount + Number.EPSILON) * 100) / 100;
        });
        this.dataSource = this.customers
        this.totalCount = this.customers.length;
        this.customerExists = true;
        this.showSpinner = false;
      });
  }
  dataSource = this.customers;
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      Executive: x.executiveName,
      Customer: x.firstName,
      Email: x.email,
      GST: x.gst,
      Phone: x.phoneNumber,
      Address: x.customerAddress
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  } 

   // authorized
  updateCustomerStatus(customerId : string){
    this.showSpinner = true;
    this.ccdetailsService.changeStatus(environment.apiUrl + "crmCustAdd/statusUpdate/" + customerId).then( data =>{
      this.customers.forEach((customer)=>{
        if(customer.customerId===customerId){
          customer.activeStatus = !customer.activeStatus;
          this.showSpinner = false;
        }
      });
      this.toaster.success("Customer status updated Successfully");
      });
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
 
      open(content: any) {
        this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
        
      }

    getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }

    reloadPage() {
      window.location.reload();
    }
}  