import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddShipmentComponent } from 'src/app/crm/shipment/add-shipment/add-shipment.component';
import { UpdateShipmentComponent } from 'src/app/crm/shipment/update-shipment/update-shipment.component';
import { ViewShipmentDetailsComponent } from 'src/app/crm/shipment/view-shipment-details/view-shipment-details.component';
import { Shipment } from 'src/app/crmClasses/shipment';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ShipmentService } from 'src/app/service/shipment.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crm-shipment-dashboard',
  templateUrl: './crm-shipment-dashboard.component.html',
  styleUrls: ['./crm-shipment-dashboard.component.scss']
})
export class CrmShipmentDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";
  closeResult!: string
  shipmentLoader = false;

  userName ! : string;

  userId!: string;
  officeId : any;
  
  shipmentLoaderMessage!: string;

  shipmentExists!: boolean;

  shipmentOptions: any = {};

  shipments: Shipment[] = [];

  loggedInUser: any;
  loggedUser: any;
  navbarTitle: any;
  
  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private spinner: NgxSpinnerService,
              private shipmentService : ShipmentService,
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

  modalRefs: BsModalRef[] = [];
  public screenWidth: any;
  public screenHeight: any;
 
  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.officeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;

    this.getShipmentDetails();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.shipmentOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  // authorized
  getShipmentDetails(){

    this.shipmentService.getAllShipmentsByOfficeId(environment.apiUrl +  "shipment/getAllShipmentsByOfficeId/" + this.officeId).then(
      data => {
            this.shipments= data;
            this.shipmentExists = true;
          });
  }

 // authorized
  addNewShipment(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      customerId : null
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
          this.shipments.push(receivedEntry);
        });   
   
  }
 
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  
 // authorized
  editShipment(shipmentId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateShipmentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      shipmentId: shipmentId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Shipment) => {
      this.getShipmentDetails();
    });  
    
  }



  updateShipmentStatus(shipmentId : string){

  

  this.shipmentService.changeStatus(environment.apiUrl + "shipment/statusUpdate/" + shipmentId).then( data =>{

    this.getShipmentDetails();

      });
  }


  viewShipmentDetailsModal(shipmentId : string){
    
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewShipmentDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        shipmentId: shipmentId 
          }

      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
 
  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  startShipmentLoading(){
    this.shipmentLoader ? this.spinner.show() : this.spinner.hide();
  }
  reloadPage() {
    window.location.reload();
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
 
 
}
 