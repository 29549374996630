import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedbackForm } from '../classes/feedback-form';

@Injectable({
  providedIn: 'root'
})
export class FeedbackFormService {

  private getCompleteFeedbackFormByFormActiveIdUrl =  environment.apiUrl + "feedbackForm/getCompleteFeedbackFormByFormActiveId";
  private createExternalFeedbackResponseUrl = environment.apiUrl + "feedbackAnswer/createExternalFeedbackResponse";
  private getCompleteFeedbackFormByFormActiveIdAndBotUserIdUrl = environment.apiUrl + "feedbackForm/getCompleteFeedbackFormByFormActiveIdAndBotUserId";
  private createExternalFeedbackResponseForBotUserUrl = environment.apiUrl + "feedbackAnswer/createExternalFeedbackResponseForBotUser";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  getCompleteFeedbackFormByFormActiveId0(formActiveId : string):Observable<FeedbackForm>{
    return this.httpClient.get<FeedbackForm>(`${this.getCompleteFeedbackFormByFormActiveIdUrl}/${formActiveId}`);
   } 

   getCompleteFeedbackFormByFormActiveIdAndBotUserId(formActiveId : string, botUserId : string):Observable<FeedbackForm>{
    return this.httpClient.get<FeedbackForm>(`${this.getCompleteFeedbackFormByFormActiveIdAndBotUserIdUrl}/${formActiveId}/${botUserId}`);
   } 

   createExternalFeedbackResponse0(formRespondent : any):Observable<any>{
    return this.httpClient.post<any>(`${this.createExternalFeedbackResponseUrl}`, formRespondent);
   } 

   createExternalFeedbackResponseForBotUser(formRespondent : any):Observable<any>{
    return this.httpClient.post<any>(`${this.createExternalFeedbackResponseForBotUserUrl}`, formRespondent);
   } 
    
  addFeedbackForm(avenue: string, bcrmDataDto : any,): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, bcrmDataDto, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, bcrmDataDto, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  createExternalFeedbackResponse(avenue: string, bcrmDataDto : any,): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, bcrmDataDto, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, bcrmDataDto, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  

  createFormActive(avenue: string, bcrmDataDto : any,): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, bcrmDataDto, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, bcrmDataDto, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  addFeedbackQuestion(avenue: string, bcrmDataDto : any,): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, bcrmDataDto, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, bcrmDataDto, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getCompleteFeedbackFormByFormActiveId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getBotFormResponseByRespondentId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getAllFormActivesByFormId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
  getFormResponsesByFormActiveId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  getFeedbackFormsByOfficeId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getFeedbackAnswersByOfficeId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getFeedbackQuestionsByOfficeId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  editFormActive(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }


  updateFeedbackQuestion(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateFormActive(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateFeedbackAnswer(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  updateFeedbackForm(avenue: string, cust : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('PUT', avenue, cust, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.put(avenue, cust, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  changeMandatoryStatus(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
}
