import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { Teacher } from 'src/app/courseManagementClasses/teacher';
import { UserList } from 'src/app/courseManagementClasses/user-list';
import { GlobalClass } from 'src/app/global/global-class';
import { SubjectService } from 'src/app/service/subject.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-teacher',
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.scss']
})
export class AddTeacherComponent implements OnInit {
  clicked=false;

  classId!: string;
  name!: string; 
  productId!: string;
  duplicateSubjectId !: string;
  subIds : any[] = [];
  officeId : any;
  subjects: Subject[] = [];
  existingSubjects : Subject[] = [];
  users : UserList[] = [];

  constructor(public activeModal: NgbActiveModal,
              public bsModalRef: BsModalRef,
              private subjectService : SubjectService,
              private teacherService : TeacherService,
              private toaster: ToastrService ,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

  @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  teacher : Teacher = new Teacher();

  ngOnInit(): void {
    
    this.teacher.fkSubject = "";
    this.teacher.fkUserRoleOffice = "";

    this.classId = this.fromParent.classId;
    this.name = this.fromParent.name;
    this.productId = this.fromParent.productId;
    this.officeId = this.fromParent.officeId;

    this.getSubjectList();
    this.getUserList();

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}
get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}
  onSubmit(){
       
    this.teacher.fkClass = this.classId;

      this.teacherService.addTeacher(this.teacher).subscribe(
        data=>{
        this.activeModal.close();
        this.passEntry.emit('Teacher added successfully')
        this.toaster.success('Teacher added successfully');
      }); 
  }

  

  



  closeUpdateModal(){
    this.activeModal.close();
  }

  getSubjectList(){
    this.subjectService.getSubListByProductId(this.productId).subscribe(data=>{
    this.subjects = data;
    });
  }

  getUserList(){
    this.teacherService.getUserListAuth(environment.apiUrl + "teacher/getUserList/"+this.officeId).then(data=>{
    this.users = data;
    });
  }

}
