import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddChildToParentMentorComponent } from 'src/app/course-management/combinedParentTeacher/add-child-to-parent-mentor/add-child-to-parent-mentor.component';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Student } from 'src/app/courseManagementClasses/student';
import { UserList } from 'src/app/courseManagementClasses/user-list';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { AddNewSubscriptionModalComponent } from '../add-new-subscription-modal/add-new-subscription-modal.component';
import { AddParentComponent } from '../add-parent/add-parent.component';
import { AddStudentModalComponent } from '../add-student-modal/add-student-modal.component';
import { EditSubscriptionModalComponent } from '../edit-subscription-modal/edit-subscription-modal.component';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { StudentCourseActivitiesDetailsComponent } from '../student-course-activities-details/student-course-activities-details.component';
import { TeacherClassAssignModalComponent } from '../teacher-class-assign-modal/teacher-class-assign-modal.component';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  safeImageUrl : any;
  parentImageUrl : any;
  childImageUrl : any;
  selectedOption : any = "0";
  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  parent : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  // child : AddCombinedParentTeacher = new AddCombinedParentTeacher();

  children : UserList = new UserList();
  userId : any;
  role : any;
  parentUserId : any
  unsafeImageUrl : any;
  profile = false;
  theme : any;
  loggedInUser: any;
  subscriptions : any[] = [{
    endedDate: "",
    fkProductId: "",
    fkRoleId: "",
    fkUserId: "",
    productName: "",
    roleName: "",
    startedDate: "",
    userName: "",
    subscriptionId: "",
    className : "",
    studentId:"",
    productImage : "",
    safeUrl : ""
  }];

  classCardsWithSafeUrl : any[] =[ {  teacherId : '', 
                                      teacherStatus : '', 
                                      userRoleOfficeId : '',
                                      classId : '',
                                      className : '',
                                      classDescription : '',
                                      fkOfficeId : '',
                                      fkProductIdOfClass : '' ,
                                      subId : '' ,
                                      subName : '' ,
                                      subDescription : '' ,
                                      fkProductIdOfSubject : '' ,
                                      subjectImage : '' ,
                                      safeUrl : '' 
                                                                        } ];
  classOptions : any;
  showSubscriptions = false;
  showSubjects = false;
  showParentDetails = false;
  showChildDetails = false;
  officeId : any;
  closeResult!: string;

  student : Student = new Student();

  constructor(
    private datePipe: DatePipe,
    private toaster : ToastrService,
    private teacherService : TeacherService,
              private ngbModalService: NgbModal,
              public activeModal: NgbActiveModal,
              private sanitizer : DomSanitizer,
              private imageService : FileServiceService ,
              private sharedDataService: SharedDataService,
              private subscriptionService  : SubscriptionService ) { }

    @Input() fromParent: any;  

    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    parentType : any;
    isChildStudent : any;

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.officeId = this.loggedInUser.offices[0].id;

    this.userId =  this.fromParent.userId;
    this.role =  this.fromParent.role;
    this.parentUserId =  this.fromParent.parentUserId;
    this.parentType = this.fromParent.parentType;
    
    this.isChildStudent = this.fromParent.isChildStudent;

    this.subscriptionService.getUserForEdit(this.userId).subscribe(
      data=>{
        this.combinedPTC = data;
        this.profile = true;

        let x =this.datePipe.transform(this.combinedPTC.dateOfBirth, 'dd-MM-yyyy');

        this.combinedPTC.dateOfBirth = x;
        
        this.imageService.getUserImage(this.combinedPTC.userImage).subscribe(
          data=>{
            this.unsafeImageUrl = URL.createObjectURL(data);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
          });

      });


      this.classOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        lengthChange: false,
        processing: true,
        info:false
      }; 
  }

  addAnotherChildStudentToParentMentor(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddChildToParentMentorComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
     parentUserId : this.userId,
     parentName : this.combinedPTC.firstName + " " + this.combinedPTC.lastName,
     userOfficeId : this.officeId
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {

      this.subscriptionService.getChildren(environment.apiUrl + "parent/getChildrenByParentUserId/" + this.userId).then(
        data=>{
          this.children = data;

          this.children.children.forEach((child)=>{
            this.imageService.getUserImage(child.childImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                child.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
              });
          })

          this.showChildDetails = true;

        });

    }); 
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
 
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  valueChange(value : any){

    this.showSubjects = false;    
    this.showSubscriptions = false;
    this.showParentDetails = false;
    this.showChildDetails = false;
    
    if(value === '1'){

      this.subscriptionService.getSubscriptionsByUserId(environment.apiUrl + "user/getSubscriptionsByUserIdAndOfficeId/" + this.userId).then(
        data=>{
          
          this.subscriptions = data;  
          this.subscriptions.forEach((subscription)=>{
            this.imageService.getProductImage(subscription.productImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                subscription.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);  
              });
          });    
          this.showSubscriptions = true;
        });

    }else if(value === '2'){
        this.showSubjects = true;

      this.teacherService.getTeacherClassSubjects( environment.apiUrl + "teacher/getTeacherClassSubjectsForClassAdmin/" + this.userId).then(
        data=>{        
          this.classCardsWithSafeUrl= [];
          for(var index in data){
                                this.classCardsWithSafeUrl.push( {  'teacherId' : data[index].teacherId, 
                                                                    'userRoleOfficeId' : data[index].userRoleOfficeId,
                                                                    'classId' : data[index].classId,
                                                                    'className' : data[index].className,
                                                                    'classDescription' : data[index].classDescription,
                                                                    'fkOfficeId' : data[index].fkOfficeId,
                                                                    'fkProductIdOfClass' : data[index].fkProductIdOfClass ,
                                                                    'subId' : data[index].subId ,
                                                                    'subName' : data[index].subName ,
                                                                    'subDescription' : data[index].subDescription ,
                                                                    'fkProductIdOfSubject' : data[index].fkProductIdOfSubject ,
                                                                    'subjectImage' : data[index].subjectImage ,
                                                                    'teacherStatus' : data[index].teacherStatus ,
                                                                          });
        }

        if( this.classCardsWithSafeUrl === undefined || this.classCardsWithSafeUrl.length === 0){
          this.showSubjects = true;
        }else{
          this.showSubjects = true;
        }

        this.showSubjects = true;
         });

    }else if(value === '4'){

      this.subscriptionService.getUserForEdit(this.parentUserId).subscribe(
        data=>{
          this.parent = data;

          this.imageService.getUserImage(this.parent.userImage).subscribe(
            data=>{
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.parentImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
            });

          this.showParentDetails = true;
        });

    }else if(value === '3'){



      this.subscriptionService.getChildren(environment.apiUrl + "parent/getChildrenByParentUserId/" + this.userId).then(
        data=>{
          this.children = data;

          this.children.children.forEach((child)=>{
            this.imageService.getUserImage(child.childImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                child.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
              });
          })

          this.showChildDetails = true;

        });
        
    }




  }

  onChange(event : any){ 
    

    let value = this.selectedOption;
    this.showSubjects = false;    
    this.showSubscriptions = false;
    this.showParentDetails = false;
    this.showChildDetails = false;
    
    
    if(value === '1'){

      this.subscriptionService.getSubscriptionsByUserId(environment.apiUrl + "user/getSubscriptionsByUserIdAndOfficeId/" + this.userId).then(
        data=>{

          this.subscriptions = data;   
          this.subscriptions.forEach((subscription)=>{
            this.imageService.getProductImage(subscription.productImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                subscription.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);  
              });
          });   
          this.showSubscriptions = true;
        });

    }else if(value === '2'){

      this.teacherService.getTeacherClassSubjects( environment.apiUrl + "teacher/getTeacherClassSubjectsForClassAdmin/" + this.userId).then(
        data=>{        
          this.classCardsWithSafeUrl= [];
          for(var index in data){
                                this.classCardsWithSafeUrl.push( {  'teacherId' : data[index].teacherId, 
                                                                    'userRoleOfficeId' : data[index].userRoleOfficeId,
                                                                    'classId' : data[index].classId,
                                                                    'className' : data[index].className,
                                                                    'classDescription' : data[index].classDescription,
                                                                    'fkOfficeId' : data[index].fkOfficeId,
                                                                    'fkProductIdOfClass' : data[index].fkProductIdOfClass ,
                                                                    'subId' : data[index].subId ,
                                                                    'subName' : data[index].subName ,
                                                                    'subDescription' : data[index].subDescription ,
                                                                    'fkProductIdOfSubject' : data[index].fkProductIdOfSubject ,
                                                                    'subjectImage' : data[index].subjectImage ,
                                                                    'teacherStatus' : data[index].teacherStatus ,
                                                                          });
        }
        if( this.classCardsWithSafeUrl === undefined || this.classCardsWithSafeUrl.length === 0){
          this.showSubjects = true;
        }else{
          this.showSubjects = true;
        }
         });

    }else if(value === '4'){

      this.showParentDetails = true;
      if(this.combinedPTC.parentId !== null){
      this.subscriptionService.getUserForEdit(this.parentUserId).subscribe(
        data=>{
          this.parent = data;

          this.imageService.getUserImage(this.parent.userImage).subscribe(
            data=>{
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.parentImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
            });

          this.showParentDetails = true;
        });
      }
      
    }else if(value === '3'){


      this.subscriptionService.getChildren(environment.apiUrl + "parent/getChildrenByParentUserId/" + this.userId).then(
        data=>{
          this.children = data;

          this.children.children.forEach((child)=>{
            this.imageService.getUserImage(child.childImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                child.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
              });
          })

          this.showChildDetails = true;

        });
    
      }

  }



  getAcademicDetailsByStudentId(studentId : string, productName : any, className : any, prodSafeUrl : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( StudentCourseActivitiesDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      studentId : studentId,
      productName : productName,
      className : className,
      studentName : this.combinedPTC.firstName + " " + this.combinedPTC.lastName,
      safeImageUrl : this.safeImageUrl,
      prodSafeUrl : prodSafeUrl
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {

    }); 
  }

  addNewSubscription(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddNewSubscriptionModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      officeId : this.officeId,
      firstName : this.combinedPTC.firstName,
      lastName : this.combinedPTC.lastName
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
  
      this.subscriptionService.getSubscriptionsByUserId(environment.apiUrl + "user/getSubscriptionsByUserIdAndOfficeId/" + this.userId).then(
        data=>{
          this.subscriptions = data;   
          this.subscriptions.forEach((subscription)=>{
            this.imageService.getProductImage(subscription.productImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                subscription.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);  
              });
          });
          
          this.showSubscriptions = true;
        });

    }); 
  }

  editSubscription(subscriptionId : string, startedDate : string, endedDate : string, roleName : any, productName : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( EditSubscriptionModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      subscriptionId : subscriptionId,
      startedDate : startedDate,
      endedDate : endedDate,
      roleName : roleName,
      productName : productName
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      
      this.subscriptionService.getSubscriptionsByUserId(environment.apiUrl + "user/getSubscriptionsByUserIdAndOfficeId/" + this.userId).then(
        data=>{
          this.subscriptions = data; 
          this.subscriptions.forEach((subscription)=>{
            this.imageService.getProductImage(subscription.productImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                subscription.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);  
              });
          });     
          this.showSubscriptions = true;
        });

    }); 
  }

  addClassSubject(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( TeacherClassAssignModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      officeId : this.officeId,
      firstName : this.combinedPTC.firstName,
      lastName : this.combinedPTC.lastName
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.teacherService.getTeacherClassSubjects( environment.apiUrl + "teacher/getTeacherClassSubjectsForClassAdmin/" + this.userId).then(
        data=>{        
          this.classCardsWithSafeUrl= [];
          for(var index in data){
                                this.classCardsWithSafeUrl.push( {  'teacherId' : data[index].teacherId, 
                                                                    'userRoleOfficeId' : data[index].userRoleOfficeId,
                                                                    'classId' : data[index].classId,
                                                                    'className' : data[index].className,
                                                                    'classDescription' : data[index].classDescription,
                                                                    'fkOfficeId' : data[index].fkOfficeId,
                                                                    'fkProductIdOfClass' : data[index].fkProductIdOfClass ,
                                                                    'subId' : data[index].subId ,
                                                                    'subName' : data[index].subName ,
                                                                    'subDescription' : data[index].subDescription ,
                                                                    'fkProductIdOfSubject' : data[index].fkProductIdOfSubject ,
                                                                    'subjectImage' : data[index].subjectImage ,
                                                                    'teacherStatus' : data[index].teacherStatus ,
                                                                          });
        }
        if( this.classCardsWithSafeUrl === undefined || this.classCardsWithSafeUrl.length === 0){
        }else{
          this.showSubjects = true;
        }
         });
    }); 
  }

  editUser(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditUserModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      

let data = {
  userId: this.userId,
  isChildStudent : this.isChildStudent
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      
      this.subscriptionService.getUserForEdit(this.userId).subscribe(
        data=>{
          this.combinedPTC = data;
          this.profile = true;
  
          let x =this.datePipe.transform(this.combinedPTC.dateOfBirth, 'dd-MM-yyyy');
  
          this.combinedPTC.dateOfBirth = x;
          
          this.imageService.getUserImage(this.combinedPTC.userImage).subscribe(
            data=>{
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
            });
  
        });
      
    }); 
  }


  teacherStatusUpdate(teacherId : any){

    this.teacherService.teacherStatusUpdate(teacherId).subscribe(
      data=>{

        this.classCardsWithSafeUrl.forEach((val)=>{
          if(val.teacherId === teacherId){
            val.teacherStatus = !val.teacherStatus;
          }

        });

      });
  }

  addParent(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddParentComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      

let data = {
  officeId: this.officeId ,
  studentUserId : this.userId
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.subscriptionService.getUserForEdit(this.userId).subscribe(
        data=>{
          this.combinedPTC = data;
          this.profile = true;
  
          let x =this.datePipe.transform(this.combinedPTC.dateOfBirth, 'dd-MM-yyyy');
  
          this.combinedPTC.dateOfBirth = x;
          
          this.imageService.getUserImage(this.combinedPTC.userImage).subscribe(
            data=>{
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
            });

            this.subscriptionService.getUserForEdit(this.combinedPTC.parentId).subscribe(
              data=>{
                this.parent = data;
      
                this.imageService.getUserImage(this.parent.userImage).subscribe(
                  data=>{
                    this.unsafeImageUrl = URL.createObjectURL(data);
                    this.parentImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
                  });
      
                this.showParentDetails = true;
              });
  
        });


    }); 
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    this.userSearch = true;
    this.value = null;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }
  addStudentToClass(fkProductId : any){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddStudentModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      

let data = {
  userId : this.userId,
  fkProductId : fkProductId,
  officeId : this.officeId,
  firstName : this.combinedPTC.firstName,
  lastName : this.combinedPTC.lastName
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.subscriptionService.getSubscriptionsByUserId(environment.apiUrl + "user/getSubscriptionsByUserIdAndOfficeId/" + this.userId).then(
        data=>{
          
          this.subscriptions = data; 
          this.subscriptions.forEach((subscription)=>{
            this.imageService.getProductImage(subscription.productImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                subscription.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);  
              });
          });     
          this.showSubscriptions = true;
        });

    }); 
  }

  users : any[] =[{
    address: '',
    dateOfBirth: '',
    email: '',
    firstName: '',
    gender: '',
    lastName:'',
    office: '',
    phoneNumber: '',
    role:'',
    userId: '',
    parentId : '',
    userImage : '',
    status: '',
    safeUrl: '',
    roles : '',
    isParent : '',
    isStudent : '',
    isParentMentor : '',
    isChildStudent : ''
  }]

  clicked = false;
  value : any;
  userSearch = true;
  searchForUsersToLinkParent(){
    this.subscriptionService.getUsersByEmailOrPhone(environment.apiUrl + "user/getUsersByEmailOrPhoneToLinkAsParent/" + this.value + "/" + this.officeId).then(
      data=>{
        
        this.users = data;

        if(this.users.length !== 0 && this.users !== undefined){
          this.userSearch = false;
        }else{
          this.userSearch = true;
          this.toaster.error("Users not found !");
        }
     

       
      });
  }

  linkParent(parentUserId : any){

    let obj : AddCombinedParentTeacher = new AddCombinedParentTeacher();
    obj.studentUserId = this.combinedPTC.userId;
    obj.parentUserId = parentUserId;

    this.subscriptionService.linkExistingUserAsParent(environment.apiUrl + "user/linkExistingUserAsParent", obj).then(
      res=>{
        this.subscriptionService.getUserForEdit(this.userId).subscribe(
          data=>{
            this.combinedPTC = data;
            this.profile = true;
    
            let x =this.datePipe.transform(this.combinedPTC.dateOfBirth, 'dd-MM-yyyy');
    
            this.combinedPTC.dateOfBirth = x;
            
            this.imageService.getUserImage(this.combinedPTC.userImage).subscribe(
              data=>{
                this.unsafeImageUrl = URL.createObjectURL(data);
                this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
              });
  
              this.subscriptionService.getUserForEdit(this.combinedPTC.parentId).subscribe(
                data=>{
                  this.parent = data;
        
                  this.imageService.getUserImage(this.parent.userImage).subscribe(
                    data=>{
                      this.unsafeImageUrl = URL.createObjectURL(data);
                      this.parentImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
                    });
        
                  this.showParentDetails = true;
                });
    
          });
      });
  }

}
 