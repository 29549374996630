import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { DatePipe } from "@angular/common";
import { Message } from "src/app/BOT_CODE/bot_classes/message";
import { ViewBotMediaForAgentComponent } from "src/app/BOT_CODE/bot_modals/bot-media/view-bot-media-for-agent/view-bot-media-for-agent.component";
import { SendTemplateMessageComponent } from "src/app/BOT_CODE/bot_modals/bot_template_modals/send-template-message/send-template-message.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { FormControl } from "@angular/forms";
import { BotConvoLog } from "src/app/BOT_CODE/bot_classes/bot-convo-log";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-cb-office-admins-conversations",
  templateUrl: "./cb-office-admins-conversations.component.html",
  styleUrls: ["./cb-office-admins-conversations.component.scss"],
})
export class CbOfficeAdminsConversationsComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botAgentsObject: BotUser = new BotUser();
  botUsersObject: BotUser = new BotUser();
  countObj: BotUser = new BotUser();
  botUserForChatWindow: BotUser = new BotUser();
  toppings = new FormControl([]);

  constructor(
    private ngbModalService: NgbModal,
    private sanitizer: DomSanitizer,
    private botResponseService: BotResponseServiceService,
    private datePipe: DatePipe,
    public router: Router,
    private sharedDataService: SharedDataService,
    private botOfficeService: BotOfficeServiceService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  locationUrl: any;
  searchText: any;
  exitLoop = false;

  chatsContext = "";
  searchValue = null;

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    // this.getBotAgents();
    // this.getChatModeCountsByOfficeId();
    this.getAdminUsersConvoBasicDetailsByOfficeId();
  }

  multiSelectValueChange(value: any) {
    let selectAgentIDs: any[] = this.toppings.value;
    if (selectAgentIDs.length > 0) {
      this.botUsersObject.users.forEach((user) => {
        if (selectAgentIDs.includes(user.fkUroIdManualChat) === true) {
          user.userVisible = true;
        } else {
          user.userVisible = false;
        }
      });
    } else {
      this.botUsersObject.users.forEach((user) => {
        user.userVisible = true;
      });
    }
  }

  removeAgentsFilter() {
    this.showSpinner = true;
    this.toppings.setValue([]);
    let count = 1;
    this.botUsersObject.users.forEach((user) => {
      user.userVisible = true;
      if (count === this.botUsersObject.users.length) {
        this.showSpinner = false;
      }
      count++;
    });
  }

  getBotAgents() {
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBasicAgentDetailsByOffice"
      )
      .then((res) => {
        this.botAgentsObject = res;
      });
  }

  getChatModeCountsByOfficeId() {
    this.showSpinner = true;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botConvoLog/getChatModeCountsByOfficeId"
      )
      .then((res) => {
        this.countObj = res;
        this.showSpinner = false;
      });
  }

  getBasicConvoDetailsByType(type: any) {
    this.showSpinner = true;
    this.botUserForChatWindow = new BotUser();
    this.botUsersObject = new BotUser();
    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/getUserConvoBasicDetailsByContextAndOfficeId/" +
          type
      )
      .then((res) => {
        this.botUsersObject = res;

        let count = 1;
        this.botUsersObject.users.forEach((user) => {
          if (
            this.botAgentsObject !== null &&
            this.botAgentsObject !== undefined &&
            this.botAgentsObject.users !== null &&
            this.botAgentsObject.users !== undefined &&
            this.botAgentsObject.users.length > 0
          ) {
            this.botAgentsObject.users.forEach((agent) => {
              if (agent.uroId === user.fkUroIdManualChat) {
                user.agentName = agent.name;
              }
            });
          }

          user.userVisible = true;
          user.botConvoLog.lastLogItemId = null;

          if (
            user.botConvoLog.lastLogItemTimestamp !== null &&
            user.botConvoLog.lastLogItemTimestamp !== undefined
          ) {
            let t = user.botConvoLog.lastLogItemTimestamp;
            let ft = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]);
            user.botConvoLog.lastLogItemDate = this.datePipe.transform(
              ft,
              "MMM d, y"
            );
          }
          if (count === this.botUsersObject.users.length) {
            this.showSpinner = false;
          }
          count++;
        });
        this.showSpinner = false;
      });
  }

  searchForBasicConvoDetailsByNamePhone() {
    this.showSpinner = true;
    this.botUserForChatWindow = new BotUser();
    this.botUsersObject = new BotUser();
    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/searchForBasicConvoDetailsByNameOrPhone/" +
          this.searchValue
      )
      .then((res) => {
        this.botUsersObject = res;

        let count = 1;
        this.botUsersObject.users.forEach((user) => {
          if (
            this.botAgentsObject !== null &&
            this.botAgentsObject !== undefined &&
            this.botAgentsObject.users !== null &&
            this.botAgentsObject.users !== undefined &&
            this.botAgentsObject.users.length > 0
          ) {
            this.botAgentsObject.users.forEach((agent) => {
              if (agent.uroId === user.fkUroIdManualChat) {
                user.agentName = agent.name;
              }
            });
          }

          user.userVisible = true;
          user.botConvoLog.lastLogItemId = null;

          if (
            user.botConvoLog.lastLogItemTimestamp !== null &&
            user.botConvoLog.lastLogItemTimestamp !== undefined
          ) {
            let t = user.botConvoLog.lastLogItemTimestamp;
            let ft = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]);
            user.botConvoLog.lastLogItemDate = this.datePipe.transform(
              ft,
              "MMM d, y"
            );
          }
          if (count === this.botUsersObject.users.length) {
            this.showSpinner = false;
          }
          count++;
        });
        this.showSpinner = false;
      });
  }

  loadOlderMessages() {
    this.showSpinner = true;

    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/getOlderConvoLogs/" +
          this.botUserForChatWindow.userId +
          "/" +
          this.botUserForChatWindow.messages[0].botConvoLogId
      )
      .then((res) => {
        let logObject: BotConvoLog = res;

        this.botUsersObject.users.forEach((existingUser) => {
          if (this.botUserForChatWindow.userId === existingUser.userId) {
            if (
              logObject.list !== null &&
              logObject.list !== undefined &&
              logObject.list.length > 0
            ) {
              let olderMessages: Message[] = [];

              logObject.list.forEach((log) => {
                let logt = log.timestamp;
                let logFt = Date.UTC(
                  logt[0],
                  logt[1] - 1,
                  logt[2],
                  logt[3],
                  logt[4]
                );
                let formattedT = this.datePipe.transform(
                  logFt,
                  "dd/MM/yyyy, h:mm a"
                );

                let req: Message = new Message();
                req.action = "request";
                req.message = log.request;
                req.timestamp = formattedT;
                req.erroneousResFromUser = log.erroneousResFromUser;
                req.userRespondedType = log.userRespondedType;
                req.locationUrl = log.locationUrl;
                req.fkRequestBotMediaId = log.fkRequestBotMediaId;
                req.botRespondedType = log.botRespondedType;
                req.botConvoLogId = log.botConvoLogId;
                olderMessages.push(req);

                let res: Message = new Message();
                res.action = "response";
                res.message = log.response;
                res.timestamp = formattedT;
                res.erroneousResFromUser = log.erroneousResFromUser;
                res.userRespondedType = log.userRespondedType;
                res.locationUrl = log.locationUrl;
                res.respondedBy = log.respondedBy;
                res.fkResponseBotMediaId = log.fkResponseBotMediaId;
                res.botRespondedType = log.botRespondedType;
                res.botConvoLogId = log.botConvoLogId;
                olderMessages.push(res);
              });

              let existingMessages: Message[] = existingUser.messages;

              existingUser.messages = olderMessages;
              existingMessages.forEach((eMessage) => {
                existingUser.messages.push(eMessage);
              });

              this.botUserForChatWindow.messages = existingUser.messages;

              this.showSpinner = false;
            } else {
              this.toaster.show("No Older Messages Found");
              this.showSpinner = false;
            }
          }
        });
      });
  }

  openChatWindow(botUser: BotUser) {
    this.showSpinner = true;
    this.botUserForChatWindow = botUser;
    this.updateUserListAndChatWindow();
  }

  updateUserListAndChatWindow() {
    this.showSpinner = true;
    let chatWindowUserId: any;
    let latestFetchedConvoLogId: any;

    if (this.botUserForChatWindow.userId === null) {
      chatWindowUserId = null;
      latestFetchedConvoLogId = null;
    } else {
      chatWindowUserId = this.botUserForChatWindow.userId;
      latestFetchedConvoLogId =
        this.botUserForChatWindow.botConvoLog.latestFetchedConvoLogId;
    }

console.log(chatWindowUserId + " " + latestFetchedConvoLogId + " " + this.chatsContext + " " + this.searchValue);


    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/updateUsersAndChatWindowConvoLogByContextAndOfficeId/" +
          chatWindowUserId +
          "/" +
          latestFetchedConvoLogId +
          "/" +
          this.chatsContext +
          "/" +
          this.searchValue
      )
      .then((res) => {
        let newObjUsers: BotUser = res;

        let newUserIds: any = [];

        newObjUsers.users.forEach((updatedUser) => {
          if (
            this.botAgentsObject !== null &&
            this.botAgentsObject !== undefined &&
            this.botAgentsObject.users !== null &&
            this.botAgentsObject.users !== undefined &&
            this.botAgentsObject.users.length > 0
          ) {
            this.botAgentsObject.users.forEach((agent) => {
              if (agent.uroId === updatedUser.fkUroIdManualChat) {
                updatedUser.agentName = agent.name;
              }
            });
          }

          newUserIds.push(updatedUser.userId);

          let selectAgentIDs: any[] = this.toppings.value;

          if (selectAgentIDs.length > 0) {
            if (
              selectAgentIDs.includes(updatedUser.fkUroIdManualChat) === true
            ) {
              updatedUser.userVisible = true;
            } else {
              updatedUser.userVisible = false;
            }
          } else {
            updatedUser.userVisible = true;
          }

          updatedUser.messages = [];

          if (
            updatedUser.botConvoLog.lastLogItemTimestamp !== null &&
            updatedUser.botConvoLog.lastLogItemTimestamp !== undefined
          ) {
            let t = updatedUser.botConvoLog.lastLogItemTimestamp;
            let ft = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]);
            updatedUser.botConvoLog.lastLogItemDate = this.datePipe.transform(
              ft,
              "MMM d, y"
            );
          }

          // CHAT WINDOW USER
          if (
            this.botUserForChatWindow.userId !== null &&
            this.botUserForChatWindow.userId !== undefined &&
            this.botUserForChatWindow.userId === updatedUser.userId
          ) {
            updatedUser.chatWindowOpen = true;

            updatedUser.messages = [];
            if (
              this.botUserForChatWindow.messages !== null &&
              this.botUserForChatWindow.messages !== undefined
            ) {
              this.botUserForChatWindow.messages.forEach((messg) => {
                updatedUser.messages.push(messg);
              });
            }

            if (
              updatedUser.botConvoLog !== null &&
              updatedUser.botConvoLog !== undefined &&
              updatedUser.botConvoLog.list !== null &&
              updatedUser.botConvoLog.list !== undefined &&
              updatedUser.botConvoLog.list.length > 0
            ) {
              updatedUser.botConvoLog.list.forEach((log) => {
                let logt = log.timestamp;
                let logFt = Date.UTC(
                  logt[0],
                  logt[1] - 1,
                  logt[2],
                  logt[3],
                  logt[4]
                );
                let formattedT = this.datePipe.transform(
                  logFt,
                  "dd/MM/yyyy, h:mm a"
                );

                let req: Message = new Message();
                req.action = "request";
                req.message = log.request;
                req.timestamp = formattedT;
                req.erroneousResFromUser = log.erroneousResFromUser;
                req.userRespondedType = log.userRespondedType;
                req.locationUrl = log.locationUrl;
                req.fkRequestBotMediaId = log.fkRequestBotMediaId;
                req.botRespondedType = log.botRespondedType;
                req.botConvoLogId = log.botConvoLogId;
                updatedUser.messages.push(req);

                let res: Message = new Message();
                res.action = "response";
                res.message = log.response;
                res.timestamp = formattedT;
                res.erroneousResFromUser = log.erroneousResFromUser;
                res.userRespondedType = log.userRespondedType;
                res.locationUrl = log.locationUrl;
                res.respondedBy = log.respondedBy;
                res.fkResponseBotMediaId = log.fkResponseBotMediaId;
                res.botRespondedType = log.botRespondedType;
                res.botConvoLogId = log.botConvoLogId;
                updatedUser.messages.push(res);
              });
            }
            this.botUserForChatWindow = updatedUser;
            this.showSpinner = false;
          } else {
            // copy existing messages & botConvoLog from old array of users
            var removeIndex = -1;
            this.botUsersObject.users.forEach((existingUser, index) => {
              if (
                updatedUser.userId === existingUser.userId &&
                existingUser.messages !== null &&
                existingUser.messages !== undefined
              ) {
                updatedUser.messages = existingUser.messages;
                updatedUser.botConvoLog.latestFetchedConvoLogId =
                  existingUser.botConvoLog.latestFetchedConvoLogId;
                removeIndex = index;
              }
            });
            this.botUsersObject.users.splice(removeIndex, 1);
            updatedUser.chatWindowOpen = false;
          }
        });

        if (newUserIds.includes(this.botUserForChatWindow.userId) === false) {
          this.botUserForChatWindow = new BotUser();
        }

        this.botUsersObject = newObjUsers;
        this.showSpinner = false;
      });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      this.exitLoop = true;
    });
  }

  viewBotMediaInChatWindow(botMediaId: any, mediaType: any, message: Message) {
    if (mediaType === "any") {
      message = this.getMediaByteArrayAlongWithMimeType(botMediaId, message);
    } else if (mediaType !== "any") {
      message = this.getBotMediaAsByteArrayForPreviewByBotMediaId(
        botMediaId,
        mediaType,
        message
      );
    }
  }

  getMediaByteArrayAlongWithMimeType(
    botMediaId: any,
    message: Message
  ): Message {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByBotMediaId/" +
          botMediaId +
          "/" +
          this.userOfficeId
      )
      .subscribe((data) => {
        this.botOfficeService
          .getMimeType(
            environment.apiUrl +
              "botMedia/getMediaByteArrayAndMimeTypeFromWcaByBotMediaId/" +
              botMediaId +
              "/" +
              this.userOfficeId
          )
          .then((res) => {
            message.mimeType = res.mime_type;

            if (
              message.mimeType === "image/jpeg" ||
              message.mimeType === "image/png"
            ) {
              let unsafeImageUrl = URL.createObjectURL(data);
              message.safeImageUrl =
                this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
              message.mediaLoaded = true;
            }

            if (
              message.mimeType === "video/mp4" ||
              message.mimeType === "video/3gp" ||
              message.mimeType === "audio/aac" ||
              message.mimeType === "audio/mp4" ||
              message.mimeType === "audio/mpeg" ||
              message.mimeType === "audio/ogg"
            ) {
              let url = URL.createObjectURL(data);
              message.urlSafe = this.sanitizer.bypassSecurityTrustUrl(url);
              message.playVideo = true;
              message.mediaLoaded = true;
            }

            if (
              message.mimeType === "text/plain" ||
              message.mimeType === "application/pdf" ||
              message.mimeType === "application/vnd.ms-powerpoint" ||
              message.mimeType === "application/msword" ||
              message.mimeType === "application/vnd.ms-excel" ||
              message.mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              message.mimeType ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
              message.mimeType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
              message.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
                window.URL.createObjectURL(data)
              );
              message.mediaLoaded = true;
            }
          });
      });

    return message;
  }

  getBotMediaAsByteArrayForPreviewByBotMediaId(
    botMediaId: any,
    mediaType: any,
    message: Message
  ): Message {
    this.botOfficeService
      .getBotMediaForPreview(
        environment.apiUrl +
          "botMedia/getMediaFromWhatsappCloudApiByBotMediaId/" +
          botMediaId +
          "/" +
          this.userOfficeId
      )
      .subscribe((data) => {
        if (mediaType === "image") {
          let unsafeImageUrl = URL.createObjectURL(data);
          message.safeImageUrl =
            this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          message.mediaLoaded = true;
          message.mimeType = mediaType;
        }

        if (mediaType === "video" || mediaType === "audio") {
          let url = URL.createObjectURL(data);
          message.urlSafe = this.sanitizer.bypassSecurityTrustUrl(url);
          message.mediaLoaded = true;
          message.playVideo = true;
          message.mimeType = mediaType;
        }

        if (mediaType === "document") {
          message.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(data)
          );
          message.mediaLoaded = true;
          message.mimeType = mediaType;
        }
      });

    return message;
  }

  viewBotMedia(botMediaId: any, mediaType: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewBotMediaForAgentComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botMediaId: botMediaId,
      mediaType: mediaType,
      officeId: this.userOfficeId,
      getByBOT_MEDIA_ID: true,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUser) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  sendTemplateMessage(botUser: any) {
    let currentContext = "Send Template Message To a User";

    let usersList: any[] = [];

    if (botUser !== null) {
      currentContext = "Send Template Message To Particular User";

      this.config.class = "modal-md";
      const modalRef = this.ngbModalService.open(SendTemplateMessageComponent, {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      });
      let data = {
        context: currentContext,
        usersList: usersList,
        receiverBotUser: botUser,
      };
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        if (receivedEntry === "success") {
          this.updateUserListAndChatWindow();
        }
      });
    }

    if (botUser === null) {
      this.botOfficeService
        .getBotCustomerUsersByOfficeId(
          environment.apiUrl +
            "botOffice/getBotUserNamesAndBotUserIdsByOfficeId"
        )
        .then((res) => {
          let botCustomerUser: BotCustomerUser = new BotCustomerUser();
          botCustomerUser = res;
          usersList = botCustomerUser.users;

          this.config.class = "modal-md";
          const modalRef = this.ngbModalService.open(
            SendTemplateMessageComponent,
            {
              size: "lg",
              scrollable: true,
              windowClass: "app-modal-window",
              keyboard: false,
            }
          );
          let data = {
            context: currentContext,
            usersList: usersList,
            receiverBotUser: botUser,
          };
          modalRef.componentInstance.fromParent = data;
          modalRef.result.then(
            (result) => {},
            (reason) => {}
          );
          modalRef.componentInstance.passEntry.subscribe(
            (receivedEntry: any) => {
              if (receivedEntry === "success") {
                this.updateUserListAndChatWindow();
              }
            }
          );
        });
    }

    // this.config.class = "modal-md";
    // const modalRef = this.ngbModalService.open(SendTemplateMessageComponent, {
    //   size: "lg",
    //   scrollable: true,
    //   windowClass: "app-modal-window",
    //   keyboard: false,
    // });
    // let data = {
    //   context: currentContext,
    //   usersList: usersList,
    //   receiverBotUser: botUser,
    // };
    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then(
    //   (result) => {},
    //   (reason) => {}
    // );
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
    //   if (receivedEntry === "success") {
    //     this.updateUserListAndChatWindow();
    //   }
    // });
  }

  markNewMessagesAsSeen() {
    this.botResponseService
      .deactivateManualChat(
        environment.apiUrl +
          "botConvoLog/markNewMessagesAsSeen/" +
          this.botUserForChatWindow.userId
      )
      .then((res) => {
        this.showSpinner = true;
        this.toaster.success("Conversation marked as seen !");
        this.getChatModeCountsByOfficeId();
        this.updateUserListAndChatWindow();
      });
  }

  deactivateManualChat() {
    this.botResponseService
      .deactivateManualChat(
        environment.apiUrl +
          "botConvo/deactivateManualChat/" +
          this.botUserForChatWindow.userId
      )
      .then((res) => {
        this.showSpinner = true;
        this.toaster.success("Manual chat mode deactivated !");
        this.updateUserListAndChatWindow();
      });
  }

  botAgent: BotUser = new BotUser();
  getBotAgentsForAssignment() {
    this.showSpinner = false;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBotAgentsAndManualChatsByOffice"
      )
      .then((res) => {
        this.botAgent = res;
      });
  }

  botAgentUserIdToAssign = "";
  assignAgentToManualChat() {
    if (this.botAgentUserIdToAssign !== "") {
      this.botOfficeService
        .assignAgentToManualChat(
          environment.apiUrl +
            "botConvo/assignAgentToManualChat/" +
            this.botUserForChatWindow.userId +
            "/" +
            this.botAgentUserIdToAssign
        )
        .then((res) => {
          this.showSpinner = true;
          this.toaster.success("Manual chat mode deactivated !");
          this.botUserForChatWindow = new BotUser();
          this.updateUserListAndChatWindow();
        });
    } else {
      this.toaster.error("Choose an Agent to assign !");
    }
  }

  ////////////////////////// ADMIN USER CHATS

  getAdminUsersConvoBasicDetailsByOfficeId() {
    this.showSpinner = true;
    this.botUserForChatWindow = new BotUser();
    this.botUsersObject = new BotUser();
    this.botResponseService
      .getUsersChatLog(
        environment.apiUrl +
          "botConvoLog/getAdminUsersConvoBasicDetailsByOfficeId"
      )
      .then((res) => {
        this.botUsersObject = res;

        let count = 1;
        this.botUsersObject.users.forEach((user) => {
          if (
            this.botAgentsObject !== null &&
            this.botAgentsObject !== undefined &&
            this.botAgentsObject.users !== null &&
            this.botAgentsObject.users !== undefined &&
            this.botAgentsObject.users.length > 0
          ) {
            this.botAgentsObject.users.forEach((agent) => {
              if (agent.uroId === user.fkUroIdManualChat) {
                user.agentName = agent.name;
              }
            });
          }

          user.userVisible = true;
          user.botConvoLog.lastLogItemId = null;

          if (
            user.botConvoLog.lastLogItemTimestamp !== null &&
            user.botConvoLog.lastLogItemTimestamp !== undefined
          ) {
            let t = user.botConvoLog.lastLogItemTimestamp;
            let ft = Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4]);
            user.botConvoLog.lastLogItemDate = this.datePipe.transform(
              ft,
              "MMM d, y"
            );
          }
          if (count === this.botUsersObject.users.length) {
            this.showSpinner = false;
          }
          count++;
        });
        this.showSpinner = false;
      });
  }
}
