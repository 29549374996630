import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TimeoutScreenComponent } from '../timeout-screen/timeout-screen.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import {MODAL_HEADERS} from '../../utils/constants/app-constants';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  headerText = MODAL_HEADERS.ADD_ROLE; // 'Add Role';
  clicked=false;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };
  dashboards = [{ id: 'STUDENT_DASH_BOARD', value: 'Student Dashboard' },
  { id: 'PARENT_DASH_BOARD', value: 'Parent Dashboard' },
  { id: 'TEACHER_DASH_BOARD', value: 'Teacher Dashboard' },
  { id: 'CRM_DASH_BOARD', value: 'Crm Dashboard' },
  { id: 'CURRICULUM_DASH_BOARD', value: 'Curriculum Dashboard' },
  { id: 'CLASS_DASH_BOARD', value: 'Class Dashboard' },
  { id: 'PARENT_MENTOR_DASH_BOARD', value: 'Parent Mentor Dashboard' }];

  itemform = new FormGroup({
    name: new FormControl(null, Validators.required),
    id: new FormControl(null),
    dashboard: new FormControl(null),
    displayName: new FormControl(null)
  });
  roleObject: any;
  name = '';
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, public bsModalRef: BsModalRef,private modalService: BsModalService, 
    private bnIdle: BnNgIdleService, private router: Router) {

  }

  ngOnInit() {
    this.itemform = this.formBuilder.group({
      name: (this.roleObject) ? this.roleObject.roleName : '',
      id: (this.roleObject) ? this.roleObject.roleId : null,
      dashboard: (this.roleObject) ? this.roleObject.dashBoard : '',
      displayName: (this.roleObject) ? this.roleObject.displayName : ''
    });

    if (this.roleObject?.roleId) {
      this.headerText = MODAL_HEADERS.MODIFY_ROLE;
    }
  }

  saveToList(form: any): void {
    if (form.value) {
      this.triggerEvent(form.value);
      this.bsModalRef.hide();
    }
  }

  triggerEvent(item: any): void {
    this.event.emit({ data: item, res: 200 });
  }

}
