export class IncentiveTier {

    id : any;
    activeStatus : any;
    fkOfficeId : any;
    fkRoleId : any;
    incentive : any;
    startValue : any;
    endValue : any;
    roleLevel : any;

    overlappingTierExists : any;
    roleName : any
    roleDisplayName : any

    groupedIncentiveTiers : IncentiveTier[] = []
}
