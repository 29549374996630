import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NotificationDetails } from 'src/app/NOTIFICATION/classes/notification-details';
import { NotificationMaster } from 'src/app/NOTIFICATION/classes/notification-master';
import { NotificationSetting } from 'src/app/NOTIFICATION/classes/notification-setting';
import { NotificationService } from 'src/app/NOTIFICATION/services/notification.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-create-notification-master',
  templateUrl: './create-notification-master.component.html',
  styleUrls: ['./create-notification-master.component.scss']
})
export class CreateNotificationMasterComponent implements OnInit {

  clicked = false;
  notificationSettings : NotificationSetting[] = [];
  notificationMaster : NotificationMaster = new NotificationMaster();
  notificationDetail : NotificationDetails = new NotificationDetails();
  
  closeResult!: string;
  
  constructor(private notificationService : NotificationService,private ngbModalService: NgbModal,
    private toastr : ToastrService,    private sharedDataService: SharedDataService,
    private activeModal : NgbActiveModal) { }

  loggedInUser : any;
  theme : any;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.notificationSettings = this.fromParent.notificationSettings;
    this.notificationMaster.fkNotificationSettingId = "";
  }

  add(){

    this.notificationService.addNotificationMaster(environment.apiUrl + "notification/addNotificationMaster", this.notificationMaster).then(
      data=>{
        this.toastr.success("Notification saved successfully");
        this.activeModal.close();
        this.passEntry.emit("success");
      });

  }

  addDetail(){

    this.notificationMaster.details.push(this.notificationDetail);
    this.notificationDetail = new NotificationDetails;
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.notificationDetail = new NotificationDetails;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.notificationDetail = new NotificationDetails;
      return 'by clicking on a backdrop';
    } else {
      this.notificationDetail = new NotificationDetails;
      return `with: ${reason}`;
    }
    
  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  close(){
    this.activeModal.close();
  }

}
