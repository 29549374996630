import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotSchedule } from "src/app/BOT_CODE/bot_classes/bot-schedule";
import { BotTemplate } from "src/app/BOT_CODE/bot_classes/bot-template";
import { FormControl } from "@angular/forms";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotScheduleTempVariable } from "src/app/BOT_CODE/bot_classes/bot-schedule-temp-variable";
import { BotUserGroup } from "src/app/BOT_CODE/bot_classes/bot-user-group";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { UploadBotMediaComponent } from "../../bot-media/upload-bot-media/upload-bot-media.component";
import { BotScheduleDetails } from "src/app/BOT_CODE/bot_classes/bot-schedule-details";

@Component({
  selector: "app-schedule-messages",
  templateUrl: "./schedule-messages.component.html",
  styleUrls: ["./schedule-messages.component.scss"],
})
export class ScheduleMessagesComponent implements OnInit {
  clicked = false;
  userExists = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private toastr: ToastrService
  ) {}

  selectedUsers: BotCustomerUser[] = [];
  allUsers: BotCustomerUser[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botSchedule: BotSchedule = new BotSchedule();
  botTemplates: BotTemplate[] = [];
  botCustomerUser: BotCustomerUser = new BotCustomerUser();
  searchText: any;
  searchText1: any;
  media: BotMedia[] = [];
  allMedia: BotMedia[] = [];

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  chosenTemplate: BotTemplate = new BotTemplate();
  botUserGroup: BotUserGroup = new BotUserGroup();
  botScheduleTempVariableList: BotScheduleTempVariable[] = [];

  botScheduleDetails: BotScheduleDetails[] = [];

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.theme = this.sharedDataService.getCardOfficeTheme();

    if (
      this.fromParent.context === "Create Schedule" ||
      this.fromParent.context === "Reschedule Template Message"
    ) {
      this.botSchedule.fkBotTemplateId = "";
      this.chosenTemplate.tempBody = "Choose a template to see template body";
      this.botSchedule.fkBotMediaIdForTemplateHeader = null;

      this.getWhatsappCloudApiTemplatesByOfficeId();
      this.getBotMediaByOfficeId();
      if (this.fromParent.context === "Create Schedule") {
        this.getBotCustomerUsersByOfficeId();
        this.getBotUserGroupsByOfficeId();
      }

      if (this.fromParent.context === "Reschedule Template Message") {
        this.selectedUsers = [];
        this.botScheduleDetails = this.fromParent.botScheduleDetails;
        this.botScheduleDetails.forEach((schD) => {
          let user: BotCustomerUser = new BotCustomerUser();
          user.botUserId = schD.fkBotUserId;
          user.name = schD.fkBotUserName;
          this.allUsers.push(user);
        });
      }
    }

    if (this.fromParent.context === "View Schedule Details") {
      this.botSchedule = this.fromParent.botSchedule;
      this.botOfficeService
        .getBotCustomerUsersByOfficeId(
          environment.apiUrl +
            "botConvo/getBotScheduleTempVariablesByFKBotScheduleId/" +
            this.botSchedule.botScheduleId
        )
        .then((res) => {
          let resObj: BotScheduleTempVariable = new BotScheduleTempVariable();
          resObj = res;
          this.botScheduleTempVariableList = resObj.list;
          console.log(this.botScheduleTempVariableList);
        });
    }
  }

  getBotUserGroupsByOfficeId() {
    this.botOfficeService
      .getGroups(environment.apiUrl + "botConvo/getBotUserGroupsByOfficeId")
      .then((res) => {
        this.botUserGroup = res;
      });
  }

  chooseTemplate() {
    this.botTemplates.forEach((temp) => {
      if (temp.botTemplateId === this.botSchedule.fkBotTemplateId) {
        this.chosenTemplate.tempVarList = [];

        this.chosenTemplate.botTemplateId = temp.botTemplateId;
        this.chosenTemplate.templateName = temp.templateName;
        this.chosenTemplate.templateDisplayName = temp.templateDisplayName;
        this.chosenTemplate.tempBody = temp.tempBody;
        this.chosenTemplate.tempVarCount = temp.tempVarCount;
        this.chosenTemplate.tempVarList = temp.tempVarList;
        this.chosenTemplate.fkBotTemplateRes = temp.fkBotTemplateRes;
        this.chosenTemplate.fkBotTemplateResId = temp.fkBotTemplateResId;
        this.chosenTemplate.tempHeaderType = temp.tempHeaderType;

        this.filterMedia(this.chosenTemplate.tempHeaderType);

        this.chosenTemplate.fkOfficeId = temp.fkOfficeId;
        this.chosenTemplate.options = temp.options;
        this.chosenTemplate.tempLanguage = temp.tempLanguage;
        this.chosenTemplate.tempLanguageCode = temp.tempLanguageCode;
        this.chosenTemplate.updateUserSessionCurrentQueryId =
          temp.updateUserSessionCurrentQueryId;
        this.botSchedule.fkBotMediaIdForTemplateHeader =
          temp.fkDefaultHeaderBotMediaId;
        this.botSchedule.headerTextForTemplateHeader = temp.tempHeaderText;

        if (temp.tempVarCount > 0) {
          if (temp.tempVarList === null || temp.tempVarList === undefined) {
            let count = this.chosenTemplate.tempVarCount + 1;
            for (let i = 1; i < count; i++) {
              let tempVar: BotScheduleTempVariable =
                new BotScheduleTempVariable();
              tempVar.botScheduleTempVarOrder = i;
              this.chosenTemplate.tempVarList.push(tempVar);
            }
          } else {
            if (temp.tempVarList.length === temp.tempVarCount) {
              this.chosenTemplate.tempVarList = temp.tempVarList;
            } else {
              let count = this.chosenTemplate.tempVarCount + 1;
              for (let i = 1; i < count; i++) {
                let tempVar: BotScheduleTempVariable =
                  new BotScheduleTempVariable();
                tempVar.botScheduleTempVarOrder = i;
                this.chosenTemplate.tempVarList.push(tempVar);
              }
            }
          }
        }
      }
    });
  }

  getWhatsappCloudApiTemplatesByOfficeId() {
    this.botOfficeService
      .getWhatsappCloudApiTemplatesByOfficeId(
        // environment.apiUrl + "botOffice/getWhatsappCloudApiTemplatesByOfficeId"
        environment.apiUrl +
          "botOffice/getActiveWhatsappCloudApiTemplatesByOfficeId"
      )
      .then((res) => {
        let bTemp: BotTemplate = new BotTemplate();
        bTemp = res;
        this.botTemplates = bTemp.list;
      });
  }

  getBotCustomerUsersByOfficeId() {
    this.botOfficeService
      .getBotCustomerUsersByOfficeId(
        environment.apiUrl +
          "botOffice/getOptedInBotUserNamesAndBotUserIdsByOfficeId"
      )
      .then((res) => {
        this.botCustomerUser = res;
        this.allUsers = this.botCustomerUser.users;
        this.selectedUsers = [];
      });
  }

  onToppingRemoved(user: any) {
    this.allUsers.push(user);
    for (var i = this.selectedUsers.length - 1; i >= 0; --i) {
      if (this.selectedUsers[i].botUserId == user.botUserId) {
        this.selectedUsers.splice(i, 1);
      }
    }

    if (this.selectedAll === true) {
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
    }
  }

  selectedAll = false;
  selectAll(value: any, botUserObj: any) {
    // case 1
    if (value === "Select All" && this.selectedAll === false) {
      //
      let allUserIds: any[] = [];
      allUserIds.push(value);
      this.allUsers.forEach((user) => {
        this.selectedUsers.push(user);
        allUserIds.push(user.botUserId);
      });
      this.allUsers = [];
      this.toppingsControl.setValue(allUserIds);
      this.selectedAll = true;
      // case 2
    } else if (value === "Select All" && this.selectedAll === true) {
      //
      this.selectedUsers.forEach((user) => {
        this.allUsers.push(user);
      });
      this.selectedUsers = [];
      this.toppingsControl.setValue([]);
      this.selectedAll = false;
      // case 3
    } else if (value !== "Select All" && this.selectedAll === true) {
      //
      this.selectedUsers.push(botUserObj);

      for (var i = this.allUsers.length - 1; i >= 0; --i) {
        if (this.allUsers[i].botUserId == botUserObj.botUserId) {
          this.allUsers.splice(i, 1);
        }
      }

      let selectedUserIds: any[] = this.toppingsControl.value;
      selectedUserIds.splice(selectedUserIds.indexOf("Select All"), 1);
      this.selectedAll = false;
      this.toppingsControl.setValue(selectedUserIds);
      // case 4
    } else if (value !== "Select All" && this.selectedAll === false) {
      //
      this.selectedUsers.push(botUserObj);
      for (var i = this.allUsers.length - 1; i >= 0; --i) {
        if (this.allUsers[i].botUserId == botUserObj.botUserId) {
          this.allUsers.splice(i, 1);
        }
      }
    }
  }

  toppingsControlGrps = new FormControl([]);
  selectedAllGroups = false;
  selectAllGrps(value: any) {
    if (value === "Select All" && this.selectedAllGroups === false) {
      let allGrpIds: any[] = [];
      allGrpIds.push(value);
      this.botUserGroup.list.forEach((grp) => {
        allGrpIds.push(grp.botUserGrpId);
      });
      this.toppingsControlGrps.setValue(allGrpIds);
      this.selectedAllGroups = true;
    } else if (value === "Select All" && this.selectedAllGroups === true) {
      this.toppingsControlGrps.setValue([]);
      this.selectedAllGroups = false;
    } else if (value !== "Select All" && this.selectedAllGroups === true) {
      let selectedUserIds: any[] = this.toppingsControlGrps.value;
      selectedUserIds.splice(selectedUserIds.indexOf("Select All"), 1);
      this.selectedAllGroups = false;
      this.toppingsControlGrps.setValue(selectedUserIds);
    }
  }

  onSubmit() {
    let userIds: string[] = [];
    // userIds = this.toppingsControl.value;
    this.selectedUsers.forEach((user) => {
      userIds.push(user.botUserId);
    });

    let grpIds: string[] = [];
    grpIds = this.toppingsControlGrps.value;

    const index = userIds.indexOf("Select All", 0);
    if (index > -1) {
      userIds.splice(index, 1);
    }

    const index2 = grpIds.indexOf("Select All", 0);
    if (index2 > -1) {
      grpIds.splice(index2, 1);
    }

    let usersOrGrpsSelected = false;
    if (
      (userIds !== null && userIds.length > 0) ||
      (grpIds !== null && grpIds.length > 0)
    ) {
      usersOrGrpsSelected = true;
    }

    if (usersOrGrpsSelected === true) {
      this.clicked = true;

      this.botSchedule.tempVarList = this.chosenTemplate.tempVarList;
      this.botSchedule.botUserIds = userIds;
      this.botSchedule.botUserGroupIds = grpIds;

      this.botSchedule.botScheduleType = "template";
      this.botOfficeService
        .createBotSchedule(
          environment.apiUrl + "botOffice/createBotSchedule",
          this.botSchedule
        )
        .then((res) => {
          this.toastr.success("Schedule created successfully !");
          this.passEntry.emit("success");
          this.activeModal.close();
        });
    } else {
      this.clicked = false;
      this.toastr.error("Choose Groups/Users to proceed !");
    }
  }

  getBotMediaByOfficeId() {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getBotMediaByOfficeId"
      )
      .then((res) => {
        this.allMedia = [];
        let md: BotMedia = new BotMedia();
        md = res;
        this.allMedia = md.list;

        this.filterMedia(this.chosenTemplate.tempHeaderType);
      });
  }

  filterMedia(type: any) {
    this.media = [];
    if (type === "IMAGE" || type === "image" || type === "image with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "png" ||
          med.mediaType === "PNG" ||
          med.mediaType === "jpeg" ||
          med.mediaType === "JPEG" ||
          med.mediaType === "jpg" ||
          med.mediaType === "JPG"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "VIDEO" || type === "video" || type === "video with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "MP4" ||
          med.mediaType === "3gp" ||
          med.mediaType === "3GP"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "audio") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "mpeg" ||
          med.mediaType === "aac" ||
          med.mediaType === "ogg" ||
          med.mediaType === "amr" ||
          med.mediaType === "MP4" ||
          med.mediaType === "MPEG" ||
          med.mediaType === "AAC" ||
          med.mediaType === "OGG" ||
          med.mediaType === "AMR"
        ) {
          this.media.push(med);
        }
      });
    }
    if (
      type === "DOCUMENT" ||
      type === "document" ||
      type === "document with buttons"
    ) {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "pdf" ||
          med.mediaType === "text" ||
          med.mediaType === "xls" ||
          med.mediaType === "xlsx" ||
          med.mediaType === "csv" ||
          med.mediaType === "pptx" ||
          med.mediaType === "doc" ||
          med.mediaType === "docx" ||
          med.mediaType === "PDF" ||
          med.mediaType === "TEXT" ||
          med.mediaType === "XLS" ||
          med.mediaType === "XLSX" ||
          med.mediaType === "CSV" ||
          med.mediaType === "PPTX" ||
          med.mediaType === "DOC" ||
          med.mediaType === "DOCX"
        ) {
          this.media.push(med);
        }
      });
    }
  }

  uploadBotMedia() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Upload New Media",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotMediaByOfficeId();
    });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  toppingsControl = new FormControl([]);

  multiSelectValueChange(value: any) {}
}
