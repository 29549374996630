<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 

    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToTicketSupportPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-question-circle"></i> Ticket Support </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
  <div class="p-4">
   
     
    <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Feedback Forms
        </span>

        <a style="cursor:pointer;" class="float-right" 
         (click)="addForm();" >
          <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
        </a>
        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
          <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
        </a>
      </div> 
    </div>

    <div class="card">
      <div class="card-body table-responsive">
         
        <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="feedbackOptions" >
          <thead class="tableheader">
            <tr>
              <th>Name</th>
              <th>Type</th> 
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of feedbackForms">
              <td class="td-height">{{ccd.name}}</td>
              <td class="td-height">{{ccd.type}}</td>
              <td class="td-height">
                <button (click)="editForm(ccd);" class="btn btn-info">Edit</button>
                <!-- <button (click)="addFormActive(ccd);" class="btn btn-success">Activate</button> -->
                <button (click)="viewFormActives(ccd);" class="btn btn-secondary">History</button>
              </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  

</div>

<div class="p-4">
   
     
  <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Ticket Support 
      </span>

      <a style="cursor:pointer;" class="float-right" 
      (click)="addTicketSupport();" >
       <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
     </a>

      <a style="cursor:pointer;margin-right: 2%;" class="float-right" matTooltip="External link"
       (click)="open(openUrl);" >
        <img src="/assets/images/icons/hyperlink.svg" width="26" height="26" alt="" loading="lazy">
      </a>
      <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArrayT()">
        <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
      </a>
    </div> 
  </div>

  <div class="card">
    <div class="card-body table-responsive">
       
      <table *ngIf="ticketsExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="feedbackOptions" >
        <thead class="tableheader">
          <tr>
            <th>Ticket Support</th>
            <th>Type</th> 
            <th>Group</th>
            <th>Description</th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of ticketSupportDefinitions">
            <td class="td-height">{{ccd.ticketSupport}}</td>
            <td class="td-height">{{ccd.ticketSupportType}}</td>
            <td class="td-height">{{ccd.ticketSupportGroup}}</td>
            <td class="td-height">{{ccd.ticketSupportDescription}}</td>
            <td class="td-height">
             
              <button (click)="editTicketSupport(ccd);" class="btn btn-info">Edit</button>
              <button (click)="viewTicketSupport(ccd);" class="btn btn-secondary">View</button>
         
            </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>


</div>

<ng-template #openUrl let-modal>
  <div  [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">External Ticket Support Url</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a style="color: black;">{{extTicketSupportUrl}}</a>
  </div> 
 
</ng-template>