export class TicketAction {

    ticketActionId : any;
    ticketActionActiveStatus : any;
    fkTicketActualId : any;
    assignedUroId : any;
    reassignedToUroId : any;
    actionType : any;
    timeStamp : any;
    remarks : any;

    takeUpTicketUserName : any;
	 reassignedToUserName : any;
	 reassignedByUserName : any;
}
