import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddInvoiceComponent } from 'src/app/crm/invoice/add-invoice/add-invoice.component';
import { UpdateInvoiceComponent } from 'src/app/crm/invoice/update-invoice/update-invoice.component';
import { ViewInvoiceDetailsComponent } from 'src/app/crm/invoice/view-invoice-details/view-invoice-details.component';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crm-invoice-dashboard',
  templateUrl: './crm-invoice-dashboard.component.html',
  styleUrls: ['./crm-invoice-dashboard.component.scss']
})
export class CrmInvoiceDashboardComponent implements OnInit {

  currentRole = "CRM_ADMIN";
  invoiceExists!: boolean;
  invoiceOptions: any = {};

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  loggedInUser: any;
  loggedUser: any;
  officeId : any;
  userName!: string;
  userId!: string;
  navbarTitle: any;
  closeResult!: string;
  showSpinner = false;

  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private invoiceService : InvoiceService, 
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    invoices: InvoiceList[] = [];

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;
this.showSpinner = true;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

      this.getInvoiceDetails();

      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      this.invoiceOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
        lengthChange: false,
        processing: true,
        info:false
      };
  }

  // authorized
  getInvoiceDetails(){
    this.showSpinner = true;
      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getInvoiceListByOfficeId/" + this.officeId).then(data => {
          this.invoices= data;          
          this.dataSource = this.invoices;
          this.invoiceExists = true;
          this.showSpinner = false;
        });
  } 

  dataSource = this.invoices;
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
     InvoiceNumber : x.invoiceNo,
      Customer: x.customerName,
      Date: x.invoiceDate,
      GrandTotal: x.grandTotal
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  }

   // authorized
  addNewInvoice(){
        this.config.class = 'modal-md';
        const modalRef = this.ngbModalService.open(AddInvoiceComponent,
         {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
        
        let data = {
          customer : null
        }
  
        modalRef.componentInstance.fromParent = data;
            modalRef.result.then((result) => {
            }, (reason) => {
            });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceList) => {
          this.getInvoiceDetails();
        });
    }
    
    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }

    // authorized
  editInvoice(invoiceId : string){

      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( UpdateInvoiceComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      invoiceId: invoiceId 
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceList) => {
          this.getInvoiceDetails();
        });
  }

  // authorized
  updateInvoiceStatus(invoiceId : string){
this.showSpinner = true;
    this.invoiceService.changeStatus(environment.apiUrl + "invoice/statusUpdate/" + invoiceId).then( data =>{
          this.getInvoiceDetails();
          this.showSpinner = false;
     });

}

open(content: any) {
  this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
  
}

  viewInvoiceDetailsModal(invoiceId : string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewInvoiceDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });
      

    let data = {
      invoiceId: invoiceId 
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
  
  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
    reloadPage() {
      window.location.reload();
    }
}
