
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-dashboard-spinner></app-dashboard-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">


   

    
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page"><i class="fa fa-home" aria-hidden="true"></i> Home</li>
  </ol>
</nav>


  <div class="content" style="margin: 2.5%;">

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
      

        <div class="col mb-4">
          
      <div class="card mat-elevation-z12">

        <div class="sub-image-div">
        <img class="sub-image" src="/assets/images/courseManagementSystem.svg" height="95" width="95" alt="...">
        </div>
        <div class="card-body">
          <!-- <h5 class="card-title">Attendance</h5> -->
          <!-- <p class="card-text" style="color: black;">Attendance</p> -->
          <div class="button-div">
          <button (click)="markAttendance();" [ngStyle]="backgroundStyle" 
          class="btn btn-info" style="width: auto;margin-bottom: 5%;">Take Attendance </button> <br>
          <button (click)="viewAttendance();" [ngStyle]="backgroundStyle" 
          class="btn btn-info" style="width: auto;">View Attendance </button>
          </div>
        </div>
      </div>
    </div>

  
  
         <div  *ngFor = "let card of classCardsWithSafeUrl">

          <div *ngIf="classesExist === true" class="col mb-4">
            
        <div class="card mat-elevation-z12">

          <div class="sub-image-div">
          <img class="sub-image" [src]="card.safeUrl" height="75" width="75" alt="...">
          </div>
          <div class="card-body">
            <h5 class="card-title">{{card.subName}}</h5>
            <p class="card-text" style="color: black;">{{card.className}}</p>
            <div class="button-div">
            <button [ngStyle]="backgroundStyle" (click)="onExplore(card.subId, card.teacherId, card.classId)" class="btn btn-info">Explore</button>
            </div>
          </div>
        </div>
      </div>
  
      </div>

     </div>
  
  
  
  
  </div>
  

  </div>



  