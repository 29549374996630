<div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Data</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm"> 

       
<div *ngIf="fromParent.context==='File Upload'">
  <div class="form-group row">
    <div class="col-md-6">
    <label class="required" for="startDate">Start Date</label>
    <input #date="ngModel" required class="form-control" type="date" name="startDate" 
    id="startDate" [(ngModel)]="bcrmData.startDate">
    <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Provide Start Date</div>
</div>

<div class="col-md-6">
    <label class="required" for="endDate">End Date</label>
    <input #date2="ngModel" required class="form-control" type="date" name="endDate" 
    id="endDate" [(ngModel)]="bcrmData.endDate">
    <div class="alert alert-danger" *ngIf="date2.touched && !date2.valid">Provide End Date</div>
</div>


</div>




       <div class="form-group row">

   
        <div class="col-md-6">
            <label  class="required">Sales Ledger File</label>
            <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
             class="form-control" accept=".xlsx" >
         </div>


     <div class="col-md-6">
      <label  class="required">  Customer Ledger File</label>
      <input type="file" required placeholder="Choose File" (change)="onFileSelected2($event)"
       class="form-control" accept=".xlsx">
   </div>

  </div>

</div>    
     
<div *ngIf="fromParent.context==='One Time Upload'">
  
<div>
    <label class="required" for="endDate"> Date</label>
    <input #date2="ngModel" required class="form-control" type="date" name="endDate" 
    id="endDate" [(ngModel)]="bcrmData.endDate">
    <div class="alert alert-danger" *ngIf="date2.touched && !date2.valid">Provide Date</div>
  </div>



<br>


       <div>

   
        <!-- <div class="col-md-6">
            <label  class="required">Sales Ledger File</label>
            <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
             class="form-control" accept=".xlsx" >
         </div> -->


      <label  class="required">  Customer Ledger File</label>
      <input type="file" required placeholder="Choose File" (change)="onFileSelected2($event)"
       class="form-control" accept=".xlsx">

  </div>

</div>
     
     
     

  



     

      

    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button *ngIf="fromParent.context==='File Upload'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();">Save</button>
    <button *ngIf="fromParent.context==='One Time Upload'" [disabled]="clicked" [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onetimeFileUpload();">Save</button>
  </div>