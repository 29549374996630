<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Confirm Logout</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form> 

       

<h2 style="text-align: center;color: black;">Are you sure you want to logout ?</h2>


     

      

    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary float-right" (click)="closeModal()">Cancel</button>

    <button type="submit" class="btn btn-sm btn-danger float-right" (click)="logOut()">Logout</button>
</div>