import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-comp-customer-details',
  templateUrl: './view-comp-customer-details.component.html',
  styleUrls: ['./view-comp-customer-details.component.scss']
})
export class ViewCompCustomerDetailsComponent implements OnInit {


  constructor( ) { }

  ngOnInit(): void {

 }

}
