<div class="modal-header">
  <h4 class="modal-title pull-left">Add Bulk Users</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="addBulkUsers" method="post" enctype="multipart/form-data" (ngSubmit)="saveToList(addBulkUsers)">
        <div class="form-group">
          <label for="if-role">Role</label>
            <select class="form-control" formControlName="role" id="if-role" name="if-role">
              <option disabled value="">Choose Role</option>
              <option *ngFor="let role of roles.roles" [ngValue]="role.roleId">{{role.roleName}}</option>
            </select>
        </div>
        <div class="form-group">
          <label for="if-user-file">File</label>
          <app-file-upload formControlName="file" [progress]="progress" [fileType] = "csvFile"></app-file-upload>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button [disabled]="clicked"  type="submit" class="btn btn-sm btn-primary float-right" (click)="saveToList(addBulkUsers);clicked=true">
    Save</button>
</div>
