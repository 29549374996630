import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { AddCustomerComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/Customer/add-customer/add-customer.component';
import { ProfileComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/Customer/profile/profile.component';
import { ViewCustomerCommunicationsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/customerCommunication/view-customer-communications/view-customer-communications.component';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { UsernameCheckModalComponent } from 'src/app/dashboards/crmDashboards/username-check-modal/username-check-modal.component';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-exe-leads',
  templateUrl: './bcrm-exe-leads.component.html',
  styleUrls: ['./bcrm-exe-leads.component.scss']
})
export class BcrmExeLeadsComponent implements OnInit {
 
  currentRole = "BCRM_EXE";
  customerExists: boolean = false;
  userName ! : string;

  customerOptions : any;


  customer : CompleteCustomerDetails = new CompleteCustomerDetails();

  officeId!: string;
  loggedInUser: any;
  loggedUser: any;
  closeResult!: string;
  
  customerId: any;
  userId!: string
  navbarTitle: any;
  totalCount : any = 0;

  showSpinner = false;
  
  constructor(private ngbModalService: NgbModal,
              public router: Router, 
              private toaster: ToastrService,
              private datePipe : DatePipe,
              public bsModalRef: BsModalRef,
              private ccdetailsService:CompCustDetailsService, 
              private CustomerService : CustomerService,
              private sharedDataService : SharedDataService ) { }
              
    officeLoader = false;
    officeLoaderMessage!: string;

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    mainObj : Customer = new Customer();
  
    status = "Leads to be contacted";

    leadsToBeContacted: Customer[] = []; leadsToBeContactedFetched = false;
    hotLeadList: Customer[] = [];           hotLeadListFetched = false;
    coldLeadList: Customer[] = [];           coldLeadListFetched = false;
    discardedLeadList: Customer[] = [];           discardedLeadListFetched = false;
    activeLeadsList: Customer[] = [];           activeLeadsListFetched = false;

    leadsToContact : any = 0;
    coldLeads : any = 0;
    discarded : any = 0;
    hotLeads : any = 0;
    allLeads : any = 0;
    activeLeads:any = 0;

  ngOnInit(): void {
    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    
    this.getLeadsToBeContacted();

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info:false
    };
  }

   // authorized
  addNewLead(custObject : Customer){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddCustomerComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        type : 'LEAD',
        custObject : custObject
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {
     this.refresh();
    });  
    
  }

  openEmailCheckModalComponent(){
    this.config.class = 'modal-md';
    // const modalRef = this.ngbModalService.open(EmailCheckComponent,
    const modalRef = this.ngbModalService.open(UsernameCheckModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

      let data = {
        officeId : this.officeId,
        header : "Lead"
      }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Customer) => {
       if(receivedEntry.createCustomer === true){
        this.addNewLead(receivedEntry);
       }else if(receivedEntry.createCustomer === false){
        this.openCustomerProfileModalComponent(receivedEntry.customerId);
       }
      });  
  }
 
  openCustomerProfileModalComponent(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ProfileComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      id: id ,
      type : 'LEAD'
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : CompleteCustomerDetails) => {

    });  
  }

   // authorized
  updateCustomerStatus(customerId : string){
    this.ccdetailsService.changeStatus(environment.apiUrl + "crmCustAdd/statusUpdate/" + customerId).then( data =>{
      this.getLeadsToBeContacted();
      this.toaster.success("Customer status updated Successfully");
      });

  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
 
  // authorized
    convertToCustomer(customerId : string){
      this.showSpinner = true;
      this.ccdetailsService.changeCustomerType(environment.apiUrl + "crmCustAdd/changeType/" + customerId).then(
        data=>{
        this.getLeadsToBeContacted();
        this.toaster.success('Lead Changed Successfully');
        this.ngbModalService.dismissAll();
        this.showSpinner = false;
        });
    }

      open(content: any) {
        this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
        
      }

    getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }

    reloadPage() {
      window.location.reload();
    }


viewCustomerCommunication(customerId:any){

  this.config.class = 'modal-md';
  const modalRef = this.ngbModalService.open(ViewCustomerCommunicationsComponent,
   {
    size : 'lg',
    scrollable: true,
    windowClass: 'app-modal-window',
    keyboard: false
  });
  
  let data = {
    customerId : customerId,
    context : "SHOW_ADD_COMMUNICATION"
  }

  modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

  modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    this.getLeadsToBeContacted();
  });
}

getLeadsToBeContacted(){
  this.showSpinner = true;
  this.CustomerService.getBcrmLeadsToBeContacted(environment.apiUrl + 'bcrmLead/getBcrmLeadsToBeContacted').then(
    data=>{
      this.mainObj = data;
      this.leadsToBeContacted = this.mainObj.leads;
      this.status = "Leads to be contacted";
      this.dataSource = this.leadsToBeContacted
      this.totalCount = this.leadsToBeContacted.length;

      this.leadsToBeContactedFetched = true;
        
      this.hotLeads = this.mainObj.hotLeads;
      this.coldLeads = this.mainObj.coldLeads
      this.allLeads = this.mainObj.allLeads
      this.discarded = this.mainObj.discardedLeads;
      this.leadsToContact = this.mainObj.leadsToContact;
      this.activeLeads = this.mainObj.activeLeads;

      this.leadsToBeContacted.forEach((lead)=>{
        let sd = lead.nextActionDateTime;
        let date = new Date(sd[0], sd[1] - 1, sd[2], sd[3], sd[4]);      
        lead.nextActionDateTime = this.datePipe.transform(date, 'dd-MM-yyyy hh:mm');

      });

      this.showSpinner = false;
      this.customerExists = true;
    });
}

dataSource = this.leadsToBeContacted;
exportArray() {
  const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
    Customer: x.firstName,
    Phone: x.phoneNumber,
    Email: x.email,
    GST: x.gst,
    Address: x.customerAddress
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
} 

getDiscardedLeads(){
this.showSpinner = true;
  if(this.discardedLeadListFetched === true){
    this.status = "Discarded Leads";
    this.showSpinner = false;
  }else if(this.discardedLeadListFetched === false){
    this.CustomerService.getBcrmLeadsToBeContacted(environment.apiUrl + 'bcrmLead/getBcrmActiveLeadsByLeadType/' + "Discarded Lead").then(
      data=>{
        this.mainObj = data;
        this.discardedLeadList = this.mainObj.leads;
        this.status = "Discarded Leads";
        this.dataSource = this.discardedLeadList;
        this.totalCount = this.discardedLeadList.length;
  
        this.discardedLeadListFetched = true;
          
        this.hotLeads = this.mainObj.hotLeads;
        this.coldLeads = this.mainObj.coldLeads;
        this.discarded = this.discardedLeadList.length;
        // this.leadsToContact = this.mainObj.leadsToContact;
        // this.activeLeads = this.mainObj.activeLeads;
  
        this.customerExists = true;
        this.showSpinner = false;
      });
  }
}

getColdLeads(){
this.showSpinner = true;
  if(this.coldLeadListFetched === true){
    this.status = "Cold Leads";
    this.showSpinner = false;
  }else if(this.coldLeadListFetched === false){

  this.CustomerService.getBcrmLeadsToBeContacted(environment.apiUrl + 'bcrmLead/getBcrmActiveLeadsByLeadType/' + "Cold").then(
    data=>{
      this.mainObj = data;
      this.coldLeadList = this.mainObj.leads;
      this.status = "Cold Leads";
      this.dataSource = this.coldLeadList
      this.totalCount = this.coldLeadList.length;

      this.coldLeadListFetched = true;
        
      // this.hotLeads = this.mainObj.hotLeads;
      this.coldLeads = this.coldLeadList.length;
      // this.discarded = this.mainObj.discardedLeads;
      // this.leadsToContact = this.mainObj.leadsToContact;
      // this.activeLeads = this.mainObj.activeLeads;

      this.showSpinner = false;
      this.customerExists = true;
    });
  }
}

getHotLeads(){
this.showSpinner = true;
  if(this.hotLeadListFetched === true){
    this.status = "Hot Leads";
    this.showSpinner = false;
  }else if(this.hotLeadListFetched === false){

    this.CustomerService.getBcrmLeadsToBeContacted(environment.apiUrl + 'bcrmLead/getBcrmActiveLeadsByLeadType/' + "Hot").then(
      data=>{
        this.mainObj = data;
        this.hotLeadList = this.mainObj.leads;
        this.status = "Hot Leads";
        this.dataSource = this.hotLeadList
        this.totalCount = this.hotLeadList.length;
  
        this.hotLeadListFetched = true;
          
        this.hotLeads = this.hotLeadList.length;
        this.coldLeads = this.mainObj.coldLeads
        this.discarded = this.mainObj.discardedLeads;
        // this.leadsToContact = this.mainObj.leadsToContact;
        // this.activeLeads = this.mainObj.activeLeads;
  
        this.customerExists = true;
        this.showSpinner = false;
      });
  }
}

getActiveLeads(){
this.showSpinner = true
  if(this.activeLeadsListFetched === true){
    this.status = "Active Leads";
    this.showSpinner = false;
  }else if(this.activeLeadsListFetched === false){

  this.CustomerService.getBcrmLeadsToBeContacted(environment.apiUrl + 'bcrmLead/getBcrmActiveLeads').then(
    data=>{
      this.mainObj = data;
      this.activeLeadsList = this.mainObj.leads;
      this.status = "Active Leads";
      this.dataSource = this.activeLeadsList
      this.totalCount = this.activeLeadsList.length;

      this.activeLeadsListFetched = true;
        
      this.hotLeads = this.mainObj.hotLeads;
      this.coldLeads = this.mainObj.coldLeads;
      // this.discarded = this.mainObj.discardedLeads;
      this.activeLeads = this.activeLeadsList.length;

      this.customerExists = true;
      this.showSpinner = false;
    });
  }
}

discardReason = null;
discardLead(id:any){
  if(this.discardReason === null){
    this.toaster.error("Enter reason !")
  }else{
    this.showSpinner = true;
    this.ccdetailsService.discardLead(environment.apiUrl + "crmCustAdd/discardLead/" + id + "/" + this.discardReason).then(data => {
      this.toaster.success("Lead discarded successfully");
      this.discardReason = null;
      this.showSpinner = false;
    this.refresh();
    });
  }
}

refresh(){
  this.showSpinner = true;
  this.CustomerService.getBcrmLeadsToBeContacted(environment.apiUrl + 'bcrmLead/getBcrmLeadCount').then(
    data=>{
      this.mainObj = data;
      this.hotLeads = this.mainObj.hotLeads;
      this.coldLeads = this.mainObj.coldLeads;
      this.discarded = this.mainObj.discardedLeads;
      this.activeLeads = this.mainObj.activeLeads;
      this.showSpinner = false;
    });
  if(this.status==="Leads to be contacted"){
this.getLeadsToBeContacted();
this.hotLeadListFetched = this.coldLeadListFetched = this.activeLeadsListFetched = this.discardedLeadListFetched = false;
  }else if(this.status==="Active Leads"){
this.getActiveLeads();
this.leadsToBeContactedFetched = this.hotLeadListFetched = this.coldLeadListFetched = this.discardedLeadListFetched = false;
  }else if(this.status==="Hot Leads"){
this.getHotLeads();
this.leadsToBeContactedFetched = this.coldLeadListFetched = this.activeLeadsListFetched = this.discardedLeadListFetched = false;
  }else if(this.status==="Cold Leads"){
this.getColdLeads();
this.leadsToBeContactedFetched = this.hotLeadListFetched = this.activeLeadsListFetched = this.discardedLeadListFetched = false;
  }else if(this.status==="Discarded Leads"){
this.getDiscardedLeads();
this.leadsToBeContactedFetched = this.hotLeadListFetched = this.coldLeadListFetched = this.activeLeadsListFetched = false;
  }
}

}  