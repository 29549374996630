 <div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 class="modal-title pull-left">Switch Role</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>    
  </div> 

 
    <div style="margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left;" >
      <a style="cursor:pointer;margin-left: 25%;"> 
        <img src="/assets/images/icons/cRole.svg" width="25" height="25"
         alt="" loading="lazy">
      </a>
      <button  style="color: rgb(21, 1, 77);font-weight: bold; cursor:pointer; background-color: white;border: white;" > 
        {{currentDisplayName}}
         
      </button>
    </div>
    <mat-divider style="color: black;"></mat-divider>


    <div *ngFor="let role of roles" style="text-align: left;">

      <div *ngIf="role.roleName !== currentRole" style="margin-bottom: 0.5rem; margin-top: 0.5rem;" class="roless" >
        <a style="cursor:pointer;margin-left: 25%;"> 
          <img src="/assets/images/icons/role.png" width="25" height="25"
           alt="" loading="lazy">
        </a>
      <!-- <a  style="color: black;font-weight: bold; cursor:pointer; background-color: white;border: white;" (click)="switchRole(role.roleName);"> 
        {{role.roleName}} 
      </a>  -->
      <a  style="color: black;font-weight: bold; cursor:pointer; background-color: white;border: white;" (click)="switchRole(role.roleName);"> 
        {{role.displayName}} 
      </a> 
    </div>

      <mat-divider style="color: black;"></mat-divider>
      
 
</div>

  