export class Tax {
    id : any;
    activeStatus : any
    name : any;
    value : any;
    type : any;
    fkOfficeId : any;

    calculatedTax : any;
    
}
