import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotResponseOptions } from 'src/app/BOT_CODE/bot_classes/bot-response-options';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { AddBotResponseComponent } from '../../bot_response/add-bot-response/add-bot-response.component';
import { AddBotResponseOptionsComponent } from '../add-bot-response-options/add-bot-response-options.component';
import { BotResponse } from 'src/app/BOT_CODE/bot_classes/bot-response';
import { GetGlobalTempResComponent } from '../../global_template_response/get-global-temp-res/get-global-temp-res.component';

@Component({
  selector: 'app-view-bot-option-details',
  templateUrl: './view-bot-option-details.component.html',
  styleUrls: ['./view-bot-option-details.component.scss']
})
export class ViewBotOptionDetailsComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  }; 
  
  clicked = false;
  loggedInUser : any;
  theme : any;

  constructor(private sharedDataService: SharedDataService,private ngbModalService: NgbModal,
    private botResponseService : BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Output() passEntry2: EventEmitter<any> = new EventEmitter();
    @Output() passEntry3: EventEmitter<any> = new EventEmitter();
    @Output() passEntry4: EventEmitter<any> = new EventEmitter();
    @Output() passEntry5: EventEmitter<any> = new EventEmitter();
    @Output() passEntry6: EventEmitter<any> = new EventEmitter();

    @Input() fromParent: any;

    botResponseOption : BotResponseOptions = new BotResponseOptions();
    botResponses : BotResponse[] = [];
    botResponses0 : any;

    ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.botResponses0 = [];
    this.botResponses0 = this.fromParent.botResponses;

    this.botResponses = [];

    if(this.botResponses0 !== null && this.botResponses0 !== undefined && this.botResponses0.responses !== null && this.botResponses0.responses !== undefined && this.botResponses0.responses.length > 0){
      Array.prototype.forEach.call(this.botResponses0.responses, child => {
        this.botResponses.push(child);
      });
    }

      this.botResponseService.getBotResponseOptionDetails(environment.apiUrl + "botConvo/getBotResponseOptionDetails/" + this.fromParent.botResponseOptionsId).then(
        res=>{
          this.botResponseOption = res;
          if(this.botResponseOption.childOptions===null){
            this.botResponseOption.childOptions=[];
          }
        });
   }



   closeResult!:string;
   open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

   ViewBotOptionDetails(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewBotOptionDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      botResponseOptionsId : id,
      botResponses : this.botResponses
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.passEntry.emit(receivedEntry);
    }); 
    modalRef.componentInstance.passEntry2.subscribe((receivedEntry : BotResponseOptions) => {
      this.botResponseOption.childOptions.forEach((cOpt)=>{
        if(cOpt.botResponseOptionsId===receivedEntry.botResponseOptionsId){
          cOpt.responseOption = receivedEntry.responseOption;
          cOpt.responseOptionNumber = receivedEntry.responseOptionNumber;
        }
      })
    });
  }

  linkNextResponseToOption(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddBotResponseComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      context : "linkNextResponseToOption",
      botResponseOptionsId : this.fromParent.botResponseOptionsId,
      botResponses : this.botResponses
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotResponse) => {
      this.botResponseOption.nextBotResponseId = receivedEntry.botResponseId;
      this.botResponseOption.nextBotResponseResponse = receivedEntry.response;
      this.passEntry3.emit(receivedEntry);
    });
  }

   addNextResponseToOption(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddBotResponseComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      context : "addResponseToOption",
      botResponseOptionsId : this.fromParent.botResponseOptionsId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotResponse) => {
      this.botResponseOption.nextBotResponseId = receivedEntry.botResponseId;
      this.botResponseOption.nextBotResponseResponse = receivedEntry.response;
      this.botResponses.push(receivedEntry); 
      this.passEntry4.emit(receivedEntry);
    });    
  }
  
  editOption(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddBotResponseOptionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      context : "editOption",
      botResponseOption : this.botResponseOption
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotResponseOptions) => {
     this.botResponseOption.responseOption = receivedEntry.responseOption;
     this.botResponseOption.responseOptionNumber = receivedEntry.responseOptionNumber;
     this.passEntry2.emit(receivedEntry);
    });    
  }

  getTemplateResponsesForOption(id : any){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( GetGlobalTempResComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      parentOptionId : id,
      context : "OPTION"
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotResponse) => { 
      this.botResponseOption.nextBotResponseId = receivedEntry.botResponseId;
      this.botResponseOption.nextBotResponseResponse = receivedEntry.response;
      this.passEntry6.emit(receivedEntry);
    });    
  }

   unlinkNextResponse(){
    this.botResponseService.unlinkNextResponse(environment.apiUrl + "botConvo/unlinkNextResponseForOption/" + this.botResponseOption.botResponseOptionsId).then(
      res=>{
        this.toastr.success("Next response unlinked successfully")
        this.botResponseOption.nextBotResponseId = null;
        this.passEntry5.emit(this.botResponseOption.botResponseOptionsId);
      });
   }

  addChildOptionToOption(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( AddBotResponseOptionsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      context : "addOptionToOption",
      botResponseOptionsId : this.fromParent.botResponseOptionsId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : BotResponseOptions) => {
      if(this.botResponseOption.childOptions === null){
        this.botResponseOption.childOptions = [];
      }
      this.botResponseOption.childOptions.push(receivedEntry);
    });    
  }



  closeUpdateModal(){
    this.activeModal.close();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
}


