import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityQuestionsService } from 'src/app/service/activity-questions.service';
import { environment } from 'src/environments/environment';
import { UpdateActivityQuestionsComponent } from '../update-activity-questions/update-activity-questions.component';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { Skill } from 'src/app/courseManagementClasses/skill';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-view-activity-questions',
  templateUrl: './view-activity-questions.component.html',
  styleUrls: ['./view-activity-questions.component.scss']
})
export class ViewActivityQuestionsComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  access : any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  actQuestionList : ActivityQuestions[] = [];
  activityPlannerId! : string;

  constructor(public activeModal: NgbActiveModal, private sharedDataService : SharedDataService,
    private ngbModalService: NgbModal, private skillService : SkillServiceService,
              private activityQuestionsService : ActivityQuestionsService ) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  skills : Skill[] =[];
  questionSkillMapping : ActivityQuestions = new ActivityQuestions();

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.access = this.fromParent.access;
    
    if(this.fromParent.questionSkill === true){
  this.skillService.getAllSkills(environment.apiUrl + 'skill/getAllChildSkills').then(
    res=>{            
      this.skills = res;
      this.getAQs();
        });
    }else{
      this.getAQs();
    }

  } 

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getAQs(){
    this.activityQuestionsService.getActivityQuestionListByActPlnrId(environment.apiUrl + "activityQuestion/getByActPlnrId/" + this.activityPlannerId).then(
      data=>{
        this.actQuestionList = data;

        this.actQuestionList.forEach((question)=>{
          question.skillsToMap = [];
          this.skills.forEach((sk)=>{
            let skill = new Skill();
            skill.skillId = sk.skillId;
            skill.skillName = sk.skillName;
            // skill.skillId = sk.skillId;
            question.skillsToMap.push(skill);
          });
          // question.skillsToMap = this.skills;
          question.skills.forEach((existingSkill)=>{
            question.skillsToMap.forEach((skillToMap, index)=>{
              if(skillToMap.skillId === existingSkill.skillId) question.skillsToMap.splice(index,1);
            });
          });
        });
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
 
  deleteQuestionSKillMapping(questionId : any, mapppingId : any){
    this.activityQuestionsService.deleteQuestionSKillMapping(environment.apiUrl + "activityQuestion/deleteQuestionSKillMapping/" + mapppingId).then(
      res=>{
        this.getAQs();
        this.actQuestionList.forEach((actQ)=>{
          if(actQ.activityQuestionId === questionId){
            actQ.skills.forEach((skill,index)=>{
              if(skill.questionSkillMappingId === mapppingId) actQ.skills.splice(index,1);
            });
          }
        });
      });
  }

  addActivityQuestionSkillMapping(id : any){
    this.questionSkillMapping.fkActivityQuestionId = id;
    this.activityQuestionsService.addActivityQuestionSkillMapping(environment.apiUrl + "activityQuestion/addActivityQuestionSkillMapping", this.questionSkillMapping).then(
      res=>{
        this.getAQs();
          this.questionSkillMapping = new ActivityQuestions();
      });
  }

  makeQuestionObjective(id : string){
    this.activityQuestionsService.makeActivityQuestionObjective(environment.apiUrl + "activityQuestion/makeActivityQuestionObjective/" + id).then(
      data=>{
        this.actQuestionList.forEach((que)=>{
          if(que.activityQuestionId === id){
            que.objective = true;
          }else{
            que.objective = false;
          }
        });
      });
  }

  editActivityQuestion(id : string, question : any, description : any, order : any){
    
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( UpdateActivityQuestionsComponent,
        {
          size : 'lg',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });

      let data = { 
        activityQuestionId : id,
        activityQuestionDescription : description,
        activityQuestion : question,
        activityQuestionOrder : order
      }

      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
this.getAQs();
      });    
  

  }

  closeResult !: string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    this.questionSkillMapping = new ActivityQuestions();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  close(){
    this.activeModal.close();
  }

}
