 <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
   
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToTicketSupportPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-question-circle"></i> Ticket Support </a>
        </li>
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>
  
  <div class="p-4">
   
     
        <div class="table-header row"  [style.background-color]='getColor()' style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              {{currentOrders}}
            </span>

          
            <a style="cursor:pointer;" matTooltip="Options" class="float-right" 
            (click)="openOptions();" >
            <img src="/assets/images/icons/settings.svg" width="26" height="26" alt="" loading="lazy">
            </a>

            <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
              <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
            </a>

            <a style="cursor:pointer;margin-right: 2%;" matTooltip="View Old Invoices" class="float-right" 
            (click)="viewOldInvoices();">
              <img src="/assets/images/icons/e-invoice.svg" width="25" height="25" alt="" loading="lazy">
            </a>

            <a style="cursor:pointer;margin-right: 2%;" class="float-right" (click)="viewProductBasedOrders();">
              <img src="/assets/images/icons/productSvg.svg" width="25" height="25" alt="" loading="lazy">
            </a>

          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            
            <table *ngIf="invoiceExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="invoiceOptions" >
              <thead class="tableheader">
                <tr>
                  <th>Executive</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th> Alt Qty</th>
                  <th>Grand Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of invoices">
                  <td class="td-height">{{ccd.executiveName}}</td>
                  <td class="td-height">{{ccd.customerName}}</td>
                  <td class="td-height">{{ccd.invoiceDate}}</td>
                  <td  style="text-align: right;" class="td-height">{{ccd.totalAlternateQuantity}}</td>
                  <td style="text-align: right;" class="td-height">{{ccd.grandTotal}}</td>

                  <td class="td-height" style=" height: 2rem;">

                    <a *ngIf="ccd.verifiedByUroId !== null" style="cursor:pointer;" matTooltip="Order Verified">
                      <img src="/assets/images/icons/verified.png" width="30" height="30" alt="" loading="lazy">
                    </a>
        
                    <a *ngIf="ccd.verifiedByUroId === null" style="cursor:pointer;" matTooltip="Order Unverified">
                      <img src="/assets/images/icons/unverified.png" width="30" height="30" alt="" loading="lazy">
                    </a>

                    <button type="button" *ngIf="currentOrders === 'Orders placed'"
                      class="btn btn-danger mr-2 btn-sm" style="width:auto;" (click)="open(confirmDeactivation);selectedInvoiceId=ccd.invoiceId">Cancel</button>
                   
                    <button *ngIf="currentOrders === 'Orders placed'"
                     style="width:auto;" (click)="open(confirmApproval);selectedInvoiceId=ccd.invoiceId;taxed=ccd.taxed" class="btn btn-success">Approve</button>

                     <button *ngIf="currentOrders === 'Orders placed' || currentOrders === 'Approved orders'"
                     style="width:auto;"
                      (click)="editInvoice(ccd.invoiceId)" class="btn btn-secondary">Edit</button>

                     <button *ngIf="currentOrders === 'Approved orders'"
                     style="width:auto;" (click)="open(confirmInvoiceApproval);selectedInvoiceId=ccd.invoiceId" class="btn btn-success">Convert</button>
                     <button (click)="viewInvoiceDetailsModal(ccd.invoiceId)" class="btn btn-primary">View</button>  
                  </td>
                  
                </tr>    

              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>
  
  <ng-template #confirmApproval let-modal>
    <div class="modal-header">
      <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to approve order ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" style="width:auto;" (click)="approveOrder();modal.dismiss('Cross click')">Approve order</button>
    </div>
  </ng-template>
 
  <ng-template #confirmInvoiceApproval let-modal>
    <div class="modal-header">
      <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Convert To Invoice</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to convert Order to Invoice ? 
      <!-- Note : This is an alternative option in case the File-Upload doesn't convert the order to invoice. -->
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" style="width:auto;" (click)="convertOrderToInvoice();modal.dismiss('Cross click')">Convert</button>
    </div>
  </ng-template>
  
  <ng-template #confirmDeactivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to cancel order ?

      <br>
      <div>
        <label class="required" for="description">Remarks </label>
        <textarea maxlength="244" class="form-control" type="text" id="cancellationRemarks" name="cancellationRemarks"
         [(ngModel)]="cancellationRemarks"></textarea>
       
        </div>
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" [disabled]="cancellationRemarks===null" class="btn btn-success" style="width:auto;" (click)="cancelOrder();modal.dismiss('Cross click')">Cancel order</button>
    </div>
  </ng-template>