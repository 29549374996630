import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { AddBotResponseComponent } from "../../bot_response/add-bot-response/add-bot-response.component";
import { CreateResLangVariationComponent } from "../../language_variation/res_language_variation/create-res-lang-variation/create-res-lang-variation.component";
import { ViewResLangVariationsComponent } from "../../language_variation/res_language_variation/view-res-lang-variations/view-res-lang-variations.component";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { AddBotResponseOptionsComponent } from "../../bot_response_options/add-bot-response-options/add-bot-response-options.component";
import { environment } from "src/environments/environment";
import { BotTreeDefinition } from "src/app/BOT_CODE/bot_classes/bot-tree-definition";
import { BotUserGroup } from "src/app/BOT_CODE/bot_classes/bot-user-group";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";

@Component({
  selector: "app-view-definitions",
  templateUrl: "./view-definitions.component.html",
  styleUrls: ["./view-definitions.component.scss"],
})
export class ViewDefinitionsComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;

  constructor(
    private sharedDataService: SharedDataService,
    private ngbModalService: NgbModal,
    private botResponseService: BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private botOfficeService: BotOfficeServiceService,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() passEntry1: EventEmitter<any> = new EventEmitter();
  @Output() passEntry2: EventEmitter<any> = new EventEmitter();
  @Output() passEntry3: EventEmitter<any> = new EventEmitter();
  @Output() passEntry4: EventEmitter<any> = new EventEmitter();
  @Output() passEntry5: EventEmitter<any> = new EventEmitter();
  @Output() passEntry6: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botResponses: BotResponse[] = [];
  botResponse: BotResponse = new BotResponse();
  swapOrderRes1: any = null;
  swapOrderRes2: any = null;
  context: any;
  flows: BotTreeDefinition[] = [];
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  errorResponses: BotResponse[] = [];
  botTreeDef: BotTreeDefinition = new BotTreeDefinition();
  userRegRes1SlNo: any;
  botUserGroup: BotUserGroup = new BotUserGroup();

  ngOnInit(): void {
    this.context = this.fromParent.context;

    if (this.context === "User Registration") {
      this.botOfficeService
        .getGroups(environment.apiUrl + "botConvo/getBotUserGroupsByOfficeId")
        .then((res) => {
          this.botUserGroup = res;

          this.errorResponses = this.fromParent.errorResponses;
          let userRegResponses: BotResponse[] = [];
          userRegResponses = this.fromParent.treeDefs;
          let count = 0;
          userRegResponses.forEach((regRes) => {
            count++;
            regRes.userRegResSlNo = count;

            if (
              this.botUserGroup.list !== null &&
              this.botUserGroup.list !== undefined &&
              this.botUserGroup.list.length > 0
            ) {
              if (
                regRes.options !== null &&
                regRes.options !== undefined &&
                regRes.options.length > 0
              ) {
                regRes.options.forEach((opt) => {
                  if (
                    opt.fkBotUserGrpIdToAdd !== null &&
                    opt.fkBotUserGrpIdToAdd !== undefined
                  ) {
                    this.botUserGroup.list.forEach((grpL) => {
                      if (opt.fkBotUserGrpIdToAdd === grpL.botUserGrpId) {
                        opt.fkBotUserGrpNameToAdd = grpL.botUserGrpName;
                      }
                    });
                  }
                });
              }
            }

            this.botResponses.push(regRes);
          });
          this.flows = this.fromParent.flows;
        });
    }

    if (this.context === "Conversation Initiation") {
      this.botResponse = this.fromParent.treeDef;
    }

    if (this.context === "Create New Conversation Flow") {
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    if (this.context === "Swap Order") {
      this.botResponses = this.fromParent.treeDefs;
      this.swapOrderRes1 = this.fromParent.swapOrderRes1;
      this.userRegRes1SlNo = this.fromParent.userRegRes1SlNo;
    }

    if (this.context === "view additional responses") {
      this.botResponse = this.fromParent.botRes;
      this.getAdditionalResponsesByBotResId();
    }

    if (this.context === "swapMainResAndSubResOrder") {
      this.botResponses = this.fromParent.bResponses;
      this.swapOrderRes1 = this.fromParent.swapOrderRes1;
    }
  }

  getAdditionalResponsesByBotResId() {
    this.botResponseService
      .botResOptionStatusChange(
        environment.apiUrl +
          "botConvo/getAdditionalResponsesByBotResId/" +
          this.botResponse.botResponseId
      )
      .then((data) => {
        let resDto: BotResponse = new BotResponse();
        resDto = data;
        this.botResponses = resDto.additonalSubResponses;
        if (
          this.botResponses === null ||
          this.botResponses === undefined ||
          this.botResponses.length === 0
        ) {
          this.botResponse.additionalSubResOrder = 1;
          this.toastr.show("No Sub-Responses found");
        }
      });
  }

  swapOrder(swapOrderRes1: any, userRegRes1SlNo: any) {
    let bResponses: any[] = [];
    this.botResponses.forEach((bres) => {
      if (bres.botResponseId !== swapOrderRes1) {
        bResponses.push(bres);
      }
    });
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewDefinitionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Swap Order",
      treeDefs: bResponses,
      swapOrderRes1: swapOrderRes1,
      userRegRes1SlNo: userRegRes1SlNo,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.activeModal.close();
      this.passEntry.emit("Swapped Order");
    });
  }

  saveOrderSwap() {
    let swapOrderRes2Status = false;
    this.botResponses.forEach((bres) => {
      if (bres.botResponseId === this.swapOrderRes2) {
        swapOrderRes2Status = bres.botResponseStatus;
      }
    });

    if (this.userRegRes1SlNo === 1 && swapOrderRes2Status === false) {
      this.toastr.error(
        "First User-Registration question cannot be Inactive. Activate the Question before swapping !"
      );
      this.activeModal.close();
    } else if (this.swapOrderRes1 !== null && this.swapOrderRes2 !== null) {
      this.botResponseService
        .swapUserRegistrationQuestionsOrders(
          environment.apiUrl +
            "botConvo/swapUserRegistrationQuestionsOrders/" +
            this.swapOrderRes1 +
            "/" +
            this.swapOrderRes2
        )
        .then((res) => {
          this.toastr.success("Order Swapped Successfully");
          this.activeModal.close();
          this.passEntry.emit("Order Swapped");
        });
    }
  }

  responseStatusUpdate(updateResponse: BotResponse) {
    if (updateResponse.userRegResSlNo > 1) {
      this.botResponseService
        .botResOptionStatusChange(
          environment.apiUrl +
            "botConvo/responseStatusUpdate/" +
            updateResponse.botResponseId
        )
        .then((data) => {
          this.botResponses.forEach((userRegRes) => {
            if (userRegRes.botResponseId === updateResponse.botResponseId) {
              userRegRes.botResponseStatus = !userRegRes.botResponseStatus;
              this.passEntry1.emit(this.botResponses);
              this.toastr.success("Status Updated Successfully !");
            }
          });
        });
    } else {
      this.toastr.error(
        "Cannot deactivate First User-Registration Question ! Swap Order and deactivate"
      );
    }
  }

  createNewConversationFlow() {
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/create/newConversationFlow",
        this.botTreeDef
      )
      .then((res) => {
        if (res === null || res === undefined) {
          this.toastr.error("Please provide a different Flow Name !");
          this.clicked = false;
        } else {
          this.botTreeDef = res;
          this.activeModal.close();
          this.passEntry.emit(this.botTreeDef);
          this.toastr.success("Flow created successfully !");
        }
      });
  }

  editBotResponse(botResponse: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editBotResponse",
      botResponse: botResponse,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        if (this.context === "User Registration") {
          this.botResponses.forEach((bRes) => {
            if (bRes.botResponseId === receivedEntry.botResponseId) {
              bRes.response = receivedEntry.response;
            }
          });
        } else if (this.context === "Conversation Initiation") {
          this.botResponse = receivedEntry;
        } else if (this.context === "view additional responses") {
          this.getAdditionalResponsesByBotResId();
        }
      }
    );
  }

  editOption(botResponseOption0: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editOption",
      botResponseOption: botResponseOption0,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.botResponse.responses.forEach((res) => {
          if (res.botResponseId === receivedEntry.fkBotResponseId) {
            res.options.forEach((option) => {
              if (
                option.botResponseOptionsId ===
                receivedEntry.botResponseOptionsId
              ) {
                option.responseOption = receivedEntry.responseOption;
                option.responseOptionNumber =
                  receivedEntry.responseOptionNumber;
                option.responseOptionDescription =
                  receivedEntry.responseOptionDescription;
              }
            });
          }
        });
      }
    );
  }

  optionToDeactivate: any;
  botResponseOptionIdToDeactivate: any;
  botResOptionDeactivation() {
    this.botResponseService
      .botResOptionStatusChange(
        environment.apiUrl +
          "botConvo/botResOptionStatusChange/" +
          this.botResponseOptionIdToDeactivate
      )
      .then((res) => {
        this.toastr.success("Status changed successfully");
        this.botResponses.forEach((resp) => {
          if (resp.options !== null && resp.options.length > 0) {
            resp.options.forEach((optn) => {
              if (
                optn.botResponseOptionsId ===
                this.botResponseOptionIdToDeactivate
              ) {
                optn.botResponseOptionsStatus = !optn.botResponseOptionsStatus;
              }
            });
          }
        });
      });
  }

  addConvoInitiationResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addConvoInitiationResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponse = receivedEntry;
        this.fromParent.convoInitiationExists = true;
        this.passEntry.emit(receivedEntry);
        this.activeModal.close();
      }
    );
  }

  addFirstUserRegistrationResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addFirstUserRegistrationResponse",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.botResponses.push(receivedEntry);
    });
  }

  addNextUserRegistrationResponse(id: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addNextUserRegistrationResponse",
      botResponseId: id,
      botResponses: this.botResponses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponses.forEach((res) => {
          if (res.botResponseId === id) {
            res.nextBotResponseId = receivedEntry.botResponseId;
          }
        });
        this.botResponses.push(receivedEntry);
        this.passEntry1.emit(this.botResponses);
      }
    );
  }

  addOptionToResponse(resId: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseOptionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "addOptionToResponse",
      botResponseId: resId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponseOptions) => {
        this.botResponses.forEach((res) => {
          if (res.botResponseId === resId) {
            if (res.options === null) {
              res.options = [];
            }
            res.optionsExist = true;
            res.options.push(receivedEntry);
          }
        });
      }
    );
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  createLanguageVariationForResponse(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(
      CreateResLangVariationComponent,
      {
        size: "lg",
        scrollable: true,
        windowClass: "app-modal-window",
        keyboard: false,
      }
    );
    let data = {
      botResponse: botResponse,
      context: "create",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {}
    );
  }

  closeResult: any;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  viewLanguageVariationsForResponse(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewResLangVariationsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      botResponse: botResponse,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {}
    );
  }

  linkConvoFlowToUserRegResOption(responseId: any, option: BotResponseOptions) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkConvoFlowToUserRegResOption",
      botResponseOptionsId: option.botResponseOptionsId,
      flows: this.flows,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.flows.forEach((flow) => {
        if (flow.botTreeDefinitionId === receivedEntry) {
          this.botResponses.forEach((botRes) => {
            if (botRes.botResponseId === responseId) {
              if (
                botRes.options !== null &&
                botRes.options !== undefined &&
                botRes.options.length > 0
              ) {
                botRes.options.forEach((opt) => {
                  if (
                    opt.botResponseOptionsId === option.botResponseOptionsId
                  ) {
                    opt.fkDefaultConvoFlowBotTreeDef = flow;
                    opt.fkDefaultConvoFlowBotTreeDefinitionId =
                      flow.botTreeDefinitionId;
                    this.passEntry1.emit(this.botResponses);
                  }
                });
              }
            }
          });
        }
      });
    });
  }

  unlinkFkConvoFlowFromOption(resId: any, optionId: any) {
    this.botResponseService
      .swapUserRegistrationQuestionsOrders(
        environment.apiUrl + "botConvo/unlinkFkConvoFlowFromOption/" + optionId
      )
      .then((res) => {
        this.toastr.success("Convo Flow Unlinked Successfully");
        this.botResponses.forEach((botRes) => {
          if (botRes.botResponseId === resId) {
            botRes.options.forEach((opt) => {
              if (opt.botResponseOptionsId === optionId) {
                opt.fkDefaultConvoFlowBotTreeDef = new BotTreeDefinition();
                opt.fkDefaultConvoFlowBotTreeDefinitionId = null;
                this.passEntry2.emit(optionId);
              }
            });
          }
        });
      });
  }

  unlinkFkGroupIdFromOption(resId: any, optionId: any) {
    this.botResponseService
      .swapUserRegistrationQuestionsOrders(
        environment.apiUrl + "botConvo/unlinkFkGroupIdFromOption/" + optionId
      )
      .then((res) => {
        this.toastr.success("Group Unlinked Successfully");
        this.botResponses.forEach((botRes) => {
          if (botRes.botResponseId === resId) {
            botRes.options.forEach((opt) => {
              if (opt.botResponseOptionsId === optionId) {
                opt.fkBotUserGrpIdToAdd = null;
                opt.fkBotUserGrpNameToAdd = null;
                this.passEntry6.emit(optionId);
              }
            });
          }
        });
      });
  }

  setErrorResponse(botResponse: BotResponse) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "setErrorResponse",
      botResponse: botResponse,
      errorResponses: this.errorResponses,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.botResponses.forEach((res) => {
          if (res.botResponseId === botResponse.botResponseId) {
            res.errorResponseId = receivedEntry.botResponseId;
            res.errorResponseResponse = receivedEntry.response;
            res.errorResponseSlNo = receivedEntry.slNo;
            this.passEntry4.emit(res);
          }
        });
      }
    );
  }

  unlinkErrorResponseForResponse(botResponseId: any) {
    this.botResponseService
      .unlinkNextResponse(
        environment.apiUrl +
          "botConvo/unlinkErrorResponseForResponse/" +
          botResponseId
      )
      .then((res) => {
        this.botResponses.forEach((resp) => {
          if (resp.botResponseId === botResponseId) {
            resp.errorResponseId = null;
            resp.errorResponseResponse = "";
            this.passEntry5.emit(res);
            this.toastr.success("Error Response unlinked successfully !");
          }
        });
      });
  }

  linkBotUserGroupToUserRegResOption(
    responseId: any,
    option: BotResponseOptions
  ) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "linkBotUserGroupToUserRegResOption",
      botResponseOptionsId: option.botResponseOptionsId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUserGroup) => {
        this.botResponses.forEach((botRes) => {
          if (botRes.botResponseId === responseId) {
            if (
              botRes.options !== null &&
              botRes.options !== undefined &&
              botRes.options.length > 0
            ) {
              botRes.options.forEach((opt) => {
                if (opt.botResponseOptionsId === option.botResponseOptionsId) {
                  opt.fkBotUserGrpIdToAdd = receivedEntry.botUserGrpId;
                  opt.fkBotUserGrpNameToAdd = receivedEntry.botUserGrpName;
                  this.passEntry1.emit(this.botResponses);
                }
              });
            }
          }
        });
      }
    );
  }

  createAdditionalSubResponse() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createAdditionalSubResponse",
      botResponseId: this.botResponse.botResponseId,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotResponse) => {
        this.getAdditionalResponsesByBotResId();
      }
    );
  }

  unlinkSubResponseFromMainResponse(resId: any) {
    this.botResponseService
      .botResOptionStatusChange(
        environment.apiUrl +
          "botConvo/unlinkSubResponseFromMainResponse/" +
          resId
      )
      .then((res) => {
        this.toastr.success("Sub-response removed successfully");
        this.getAdditionalResponsesByBotResId();
      });
  }

  swapMainResAndSubResOrder(swapOrderRes1: any) {
    let bResponses: any[] = [];
    bResponses.push(this.botResponse);
    this.botResponses.forEach((bres) => {
      if (bres.botResponseId !== swapOrderRes1) {
        bResponses.push(bres);
      }
    });
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(ViewDefinitionsComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "swapMainResAndSubResOrder",
      bResponses: bResponses,
      swapOrderRes1: swapOrderRes1,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === this.botResponse.botResponseId) {
        let order1: any = this.botResponse.additionalSubResOrder;
        this.botResponses.forEach((bres) => {
          if (bres.botResponseId === swapOrderRes1) {
            this.botResponse.additionalSubResOrder = bres.additionalSubResOrder;
            bres.additionalSubResOrder = order1;
          }
        });
      } else {
        this.botResponses.forEach((bres) => {
          if (bres.botResponseId === receivedEntry) {
            this.botResponses.forEach((bres2) => {
              if (bres2.botResponseId === swapOrderRes1) {
                let order1: any = bres2.additionalSubResOrder;
                bres2.additionalSubResOrder = bres.additionalSubResOrder;
                bres.additionalSubResOrder = order1;
              }
            });
          }
        });
      }
      this.passEntry.emit(this.botResponse.additionalSubResOrder);
    });
  }

  saveSwappedMainResAndSubResOrder() {
    this.botResponseService
      .swapUserRegistrationQuestionsOrders(
        environment.apiUrl +
          "botConvo/saveSwappedMainResAndSubResOrder/" +
          this.swapOrderRes1 +
          "/" +
          this.swapOrderRes2
      )
      .then((res) => {
        this.toastr.success("Order Swapped Successfully");
        this.activeModal.close();
        this.passEntry.emit(this.swapOrderRes2);
      });
  }
}
