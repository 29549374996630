import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { BotCustomerUser } from 'src/app/BOT_CODE/bot_classes/bot-customer-user';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { Role } from 'src/app/models/role';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-bot-form-active-component',
  templateUrl: './create-bot-form-active-component.component.html',
  styleUrls: ['./create-bot-form-active-component.component.scss']
})
export class CreateBotFormActiveComponentComponent implements OnInit {

 
  clicked = false;
  formActive : FormActive = new FormActive();
  feedbackForm : FeedbackForm = new FeedbackForm();
  loggedInUser : any; 
  roles : Role[] = [];
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private botOfficeService : BotOfficeServiceService,
    private roleService : RolesServiceService,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };
            searchText="";

  ngOnInit(): void { 

    this.formActive.fkRoleId = "";
    
    this.feedbackForm = this.fromParent.form;
    this.formActive.fkFormId = this.feedbackForm.id;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
   this.getBotCustomerUsersByOfficeId();

  }

  botCustomerUser : BotCustomerUser = new BotCustomerUser();
  users : BotCustomerUser[] = [];
  selectedUsers : BotCustomerUser[] = [];

  getBotCustomerUsersByOfficeId(){
    this.botOfficeService.getBotCustomerUsersByOfficeId(environment.apiUrl + "botOffice/getBotCustomerUsersByOfficeId").then(
      res=>{
        this.botCustomerUser = res;
        this.users = this.botCustomerUser.users;
        this.selectedUsers = this.botCustomerUser.users;
      });
  }

  stringValue !: string;
  onKey(value:any) { 
    if(this.stringValue.length > 0){
      this.selectedUsers = this.search(value.key);
    }else{
      this.selectedUsers = this.users;
    } 
    }

    search(value: any) { 
      let filter = value.toLowerCase();
      return this.users.filter(option => option.name.toLowerCase().startsWith(filter));
    }

  toppingsControl = new FormControl([]);
  multiSelectValueChange(value : any){
  }

  onSubmit(){

    let userIds : string[] = this.toppingsControl.value;

    if(userIds !== null && userIds.length > 0){
    this.formActive.botScheduleType = "form";
    this.formActive.botUserIds = userIds;
  

    

    this.formService.createFormActive(environment.apiUrl + "formActive/createFormActiveForBot", this.formActive).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Form scheduled successfully');
        this.passEntry.emit('success');
      });
    }else{
      this.clicked = false;
      this.toaster.error("Choose customers/users")
    }

  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}