
  <nav class="navbar navbar-expand-md navbar-dark " 
  [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a  style="cursor: pointer;color: #0275d8;" (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Assessment-Results</li>
    </ol>
  </nav>


  <div class="container" style="margin-bottom: 4%;">
  
    <div class="table-responsive">          
    <table class="table" *ngIf="resultsExist === true">
      <thead>
        <tr>

          <th>
            <mat-icon onclick="history.back()" style=" cursor: pointer; color: black; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>
          </th>
          <th *ngFor="let student of resultData[index].studentsList" >
           
            <a style="color: black;">
              <img style="margin: 2%; border-radius: 50%;" class="student-image" alt="" height="23" loading="lazy" [src]="student.studentImageUrl" width="23">
              {{student.studentName}}
            </a>

          </th>
    
        </tr>
      </thead>
      <tbody>
      
        <tr *ngFor="let act of resultData">
         
          <td style="text-align: start;">
            
            
            <a style="font-weight: normal;color: black;" *ngIf="act.activityPlanner.activityPlannerCompletionStatus === true"><img style="margin: 2%; border-radius: 50%;" class="student-image" alt="" height="23" loading="lazy" [src]="act.activityPlanner.activityPlannerImageUrl" width="23">{{act.activityPlanner.activityPlannerName }}<img matTooltip="Activity Complete" style="margin-left: 7%;" class="student-image" alt="" height="20" loading="lazy" src="assets/images/icons/tick.png" width="20"></a>
            <a style="font-weight: normal;color: black;"  *ngIf="act.activityPlanner.activityPlannerCompletionStatus === false"><img style="margin: 2%; border-radius: 50%;" class="student-image" alt="" height="23" loading="lazy" [src]="act.activityPlanner.activityPlannerImageUrl" width="23">{{act.activityPlanner.activityPlannerName}}</a>

          </td>
        
        
          <td *ngFor="let student of act.studentsList">

            <a *ngIf="student.studentActivityHistoryIndicator == 0" ><img class="student-image" alt="" height="17"  width="50"loading="lazy" src="assets/images/icons/redRectangle.png"></a>
            <a *ngIf="student.studentActivityHistoryIndicator == 1" style="font-weight: bold;color: black;"><img class="student-image" alt="" height="20" width="20" loading="lazy" src="assets/images/icons/inProgress.png" > {{student.studentAttempts}}</a>
            <a *ngIf="student.studentActivityHistoryIndicator == 2" style="font-weight: bold;color: black;"><img class="student-image" alt="" height="20" width="20" loading="lazy" src="assets/images/icons/selected.png" > {{student.studentAttempts}}</a>
             
          </td>
        

        </tr>
      
      </tbody>
    </table>
    </div>
  </div>
  </div>




  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>