<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAdminsConversationsPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Conversations </a>
        </li> -->

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>   
        
      </ul>
    </div>  
  </nav>


  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>


  <mat-tab-group style="padding: 1em;" (selectedTabChange)="tabClick($event)" dynamicHeight>
   
    <mat-tab>

      <ng-template mat-tab-label>
       Chat Agents
         <button (click)="addBotAgent('CB_AGENT', 'Add Bot Agent');" matTooltip="Create Chat Agent" mat-icon-button>
           <mat-icon>add_box</mat-icon>
       </button>
       </ng-template>

      <div class="p-4">
       
    
        <div class="search-hero">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Start searching for an agent by name, email or phone number">
          </div>
          <br>
    
       
    
         
              <table *ngIf="agentsExists" class="table table-striped table-sm hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Name</th>
                    <th> Whatsapp No</th>
                    <th> Email</th>
                    <th>Filter Var 1</th>
                    <th>Users Assigned</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let ccd of botUser.users | filter:searchText">
                    <td class="td-height">{{ccd.firstName}} {{ccd.lastName}}</td>
                    <td class="td-height">{{ccd.whatsappNumber}}</td>
                    <td class="td-height">{{ccd.email}}</td>
                    <td class="td-height">{{ccd.filterVar}}</td>
                    <td class="td-height">
                      
                      <a style="color: black;" *ngFor="let userAssigned of ccd.botUsersAssigned">
                        {{userAssigned.name}}
                        <img (click)="open(botUserDetailsModal);" src="/assets/images/icons/info.png" width="20" height="20" alt="" loading="lazy"> 

                        <ng-template #botUserDetailsModal let-modal>
                          <div class="modal-header">
                            <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">User Details</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div style="color: black;" class="modal-body">
                            Name : {{userAssigned.name}} <br>
                            Phone : {{userAssigned.userIdentifier}} <br>
                            Alternate Phone : {{userAssigned.alternatePhone}} <br>
                            Gender : {{userAssigned.gender}} <br>
                            Custom Variable 1 : {{userAssigned.customVar1}} <br>
                            Custom Variable 2 : {{userAssigned.customVar2}} <br>
                          </div> 
                        </ng-template>
                        
                      </a>

                    </td>
                    <td>

                      <a>
                        <img style="cursor:pointer;" [matMenuTriggerFor]="beforeMenu" matTooltip="Menu" 
                        src="/assets/images/icons/three-dots.png" alt="user" height="30" width="30" class="rounded-circle">
                      </a>

                      <mat-menu #beforeMenu="matMenu" xPosition="before">
                        
                        <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" 
                        (click)="open(confirmDeactivation);" mat-menu-item>
                          Deactivate
                        </button>

                        <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" 
                        (click)="open(confirmActivation);" mat-menu-item>
                        Activate
                        </button>
                      
                        <button  mat-menu-item
                        (click)="open(resetPwdModal);">
                        Reset passowrd
                        </button>

                        <button mat-menu-item
                        (click)="assignMultipleUsersToAgent(ccd.agentUroId);">
                        Reassign Users
                        </button>

                        <button mat-menu-item
                        (click)="exportUsersArray(ccd.botUsersAssigned);">
                          Download Assigned Users
                        </button>

                        <button mat-menu-item *ngIf="ccd.whatsappNumber===null" class="btn btn-primary"
                        (click)="open(updateWaNoModal1);">
                        Update Whatsapp Number
                        </button>

                        <!-- <button *ngIf="ccd.whatsappNumber!==null" mat-menu-item
                        (click)="open(updateFilterVarModal1);">
                        Update Filter Variable
                        </button> -->
                        
                      </mat-menu>

                       

                       

                      
                    </td>

                    <ng-template #updateFilterVarModal1 let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Update Filter Variable
                        </h3>
                  
                        <div>
                          <label class="required" for="filterVar">Variable</label>
                          <input maxlength="15" required class="form-control" type="text" 
                           name="filterVar" id="filterVar" [(ngModel)]="filterVar">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateFilterVar('ag',ccd.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Update</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>

                    <ng-template #updateWaNoModal1 let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Update Whatsapp Number
                        </h3>
                  
                        <div>
                          <label class="required" for="botUserGrpName">Phone</label>
                          <input maxlength="15" required class="form-control" type="number" 
                           name="whatsappNo" id="whatsappNo" [(ngModel)]="whatsappNo">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateWhatsappNo('ag',ccd.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Update</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>

                    <ng-template #resetPwdModal let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Reset Password for {{ccd.firstName}} {{ccd.lastName}}
                        </h3>
                  
                        <div>
                          <label class="required" for="newPassword">Enter new passowrd</label>
                          <input maxlength="255" required class="form-control" type="text" 
                           name="newPassword" id="newPassword" [(ngModel)]="newPassword">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="resetPassword(ccd.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Reset</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>
                   
                    <ng-template #confirmActivation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          Are you sure to activate {{ccd.firstName}} {{ccd.lastName}} ?
                        </div> 
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                          <button type="button" class="btn btn-success" (click)="agentStatusUpdate(ccd.userId);modal.dismiss('Cross click')">Activate</button>
                        </div>
                      </ng-template>          
                      
                      <ng-template #confirmDeactivation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          Are you sure to deactivate {{ccd.firstName}} {{ccd.lastName}} ?
                        </div> 
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                          <button type="button" class="btn btn-success" (click)="agentStatusUpdate(ccd.userId);modal.dismiss('Cross click')">Deactivate</button>
                        </div>
                      </ng-template>
     </tr>
                </tbody>
              </table>
     
            
      </div>
    </mat-tab>

    <mat-tab>

      <ng-template mat-tab-label>
        Admins
         <button (click)="addBotUser('CHOOSE_ADMIN', 'Add Admin');" matTooltip="Create Admin" mat-icon-button>
           <mat-icon>add_box</mat-icon>
       </button>
       </ng-template>

      <div class="p-4">
           
              <div class="search-hero">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off"
                 placeholder="Start searching for an admin by name, email or phone number">
              </div>
              
    <br>
            
              <table *ngIf="adminsExist" class="table table-striped table-sm hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Name</th>
                    <th> Whatsapp No</th>
                    <th> Email</th>
                    <th>Filter Var 1</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let ccd of botAdmin.users | filter:searchText2">
                    <td class="td-height">{{ccd.firstName}} {{ccd.lastName}}</td>
                    <td class="td-height">{{ccd.whatsappNumber}}</td>
                    <td class="td-height">{{ccd.email}}</td>
                    <td class="td-height">{{ccd.filterVar}}</td>
                    <td>
                        <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
                        class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation);">Deactivate</button>
                        <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
                        class="btn btn-success mr-2" (click)="open(confirmActivation);">Activate</button>

                        <button (click)="open(resetPwdModal);" class="btn btn-info" style="width: auto;">Reset Password</button>

                        <button *ngIf="ccd.whatsappNumber===null" class="btn btn-primary"
                        (click)="open(updateWaNoModal2);"
                        style="width: auto;">Update Whatsapp Number</button>

                        <button *ngIf="ccd.whatsappNumber!==null" class="btn btn-secondary"
                        (click)="open(updateFilterVarModal2);"
                        style="width: auto;">Update Filter Variable</button>
                    </td>

                    <ng-template #updateFilterVarModal2 let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Update Filter Variable
                        </h3>
                  
                        <div>
                          <label class="required" for="filterVar">Filter Var</label>
                          <input maxlength="15" required class="form-control" type="text" 
                           name="filterVar" id="filterVar" [(ngModel)]="filterVar">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateFilterVar(null,ccd.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Update</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>

                    <ng-template #updateWaNoModal2 let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Update Whatsapp Number
                        </h3>
                  
                        <div>
                          <label class="required" for="botUserGrpName">Phone</label>
                          <input maxlength="15" required class="form-control" type="number" 
                           name="whatsappNo" id="whatsappNo" [(ngModel)]="whatsappNo">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="updateWhatsappNo(null,ccd.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Update</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>
                   
                    <ng-template #resetPwdModal let-modal>
                      <div class="modal-body" style="color: black;">
                  
                        <h3 style="font-weight: bold;">
                         Reset Password for {{ccd.firstName}} {{ccd.lastName}}
                        </h3>
                  
                        <div>
                          <label class="required" for="newPassword">Enter new passowrd</label>
                          <input maxlength="255" required class="form-control" type="text" 
                           name="newPassword" id="newPassword" [(ngModel)]="newPassword">
                        </div>
                        <br> <br>
                  
                        <button style="float: right;width: auto;margin: 0.5%;" (click)="resetPassword(ccd.userId);modal.dismiss('Cross click')"
                        mat-raised-button>Reset</button>
                        <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
                        
                      </div>
                    </ng-template>
                    
                    <ng-template #confirmActivation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          Are you sure to activate {{ccd.firstName}} {{ccd.lastName}} ?
                        </div> 
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                          <button type="button" class="btn btn-success" (click)="agentStatusUpdate(ccd.userId);modal.dismiss('Cross click')">Activate</button>
                        </div>
                      </ng-template> 
                                         
                      <ng-template #confirmDeactivation let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          Are you sure to deactivate {{ccd.firstName}} {{ccd.lastName}} ?
                        </div> 
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                          <button type="button" class="btn btn-success" (click)="agentStatusUpdate(ccd.userId);modal.dismiss('Cross click')">Deactivate</button>
                        </div>
                      </ng-template>
     </tr>
                </tbody>
              </table>
       
      </div>
    </mat-tab>

  </mat-tab-group>
  
  

  



 

