import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { Product } from 'src/app/crmClasses/product';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-product-based-invoices',
  templateUrl: './view-product-based-invoices.component.html',
  styleUrls: ['./view-product-based-invoices.component.scss']
})
export class ViewProductBasedInvoicesComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  totalQty : any = 0;
  totalAltQty : any=0;

  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser : any;
  theme : any;
  invoices: InvoiceList[] = [];
  achievedPercentage : any;
  productIds : string[] = [];
  products : Product[] = [];
  invoiceWithIds : InvoiceList = new InvoiceList();

  constructor(
              private activeModal : NgbActiveModal,
              private sharedDataService: SharedDataService,
              private datePipe : DatePipe,
              private invoiceService : InvoiceService
            ) { }

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    // this.invoiceService.getInvoiceBasedProductQty(environment.apiUrl + "invoice/getInvoiceBasedProductQty").then(
    //   data=>{
    //     this.products = data;
    //   });

     this.invoiceWithIds.invoices = this.fromParent.invoices;

     let inv : InvoiceList = new InvoiceList();

    let count = 0;
     this.invoiceWithIds.invoices.forEach((val)=>{
      count++;
      let inv2 : InvoiceList = new InvoiceList();
      inv2.invoiceId = val.invoiceId;
      inv.invoices.push(inv2);
    });
    

    if(count === this.invoiceWithIds.invoices.length){
      this.invoiceService.getInvoiceDetailsBasedProductQty(environment.apiUrl + "invoice/getInvoiceDetailsBasedProductQty", inv).then(
        data=>{
          this.products = data;

          this.products.forEach((prod)=>{
            this.totalQty = this.totalQty + prod.totalQty;
            this.totalAltQty = this.totalAltQty + prod.totalAlternateQty;
          });
        });
    }

  
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}