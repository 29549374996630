export class CrmDashboardData {

    totalCustomers: any =0;
    totalLeads: any =0;
    totalInvoices: any =0;
	totalPayments: any =0;
	totalShipments: any =0;
	totalProducts: any =0;
    totalActiveSubscriptions : any = 0;

}

