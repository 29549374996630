import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClassStudents } from '../courseManagementClasses/class-students';
import { AddActActualActScore } from '../courseManagementClasses/combinedParentTeacher/add-act-actual-act-score';
import { Student } from '../courseManagementClasses/student';
import { UserList } from '../courseManagementClasses/user-list';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  base1URL = environment.apiUrl +  "student/getStudentsInClass";
  base2URL = environment.apiUrl +  "student/getUsersWithStudentRoles";
  getStudentDetailsUrl = environment.apiUrl + "student/getStudentDetails";
  base3URL = environment.apiUrl +  "student/getStudentsForAssessment";
  base4URL = environment.apiUrl +  "studentActivity/setStudentActivityCompletionStatus";
  base5URL = environment.apiUrl +  "student/getStudentResultsForAPs";
  base6URL = environment.apiUrl +  "student/getStudentSkillResultsList";
  base7URL = environment.apiUrl +  "studentActivity/UnMarkActivityCompletedForStudent";
  base8URL = environment.apiUrl +  "student/getStudentsByProductSubsciption";
  base9URL = environment.apiUrl +  "student/getStudentDetailsByUserId";
  base10URL = environment.apiUrl +  "student/statusUpdate";
  base11URL = environment.apiUrl +  "student/getStudentsForAssignmentEvaluation";
  base12URL = environment.apiUrl +  "student/getStudentClassesByUserId";
  base13URL = environment.apiUrl +  "activityPlanner/getAssignmentsForStudentDashboard";
  base14URL = environment.apiUrl +  "student/getAllStudentsInClass";
  base15URL = environment.apiUrl +  "student/getStudentClassesByUserRoleOfficeId";
  base16URL = environment.apiUrl +  "student/getStudentsByProductSubsciptionRA";

  authToken: string | null = '';
  constructor(private httpClient: HttpClient) {
    this.authToken = localStorage.getItem('auth-token');
  }

  addStudent(student : Student):Observable<Student>{
    return this.httpClient.post<Student>( environment.apiUrl + 'student/add',student);
  }

  getStudentsInaClass(classId : string): Observable<ClassStudents[]>{
    return this.httpClient.get<ClassStudents[]>(`${this.base1URL}/${classId}`);
  }

  generateOfficeStudentsSkillReport(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentsInaClassForTeacher(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentResultsByStudentId(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentsWithProductActiveSubscriptions(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  //fetch all active + inactive students 
  getAllStudentsInClass(classId : string): Observable<ClassStudents[]>{
    return this.httpClient.get<ClassStudents[]>(`${this.base14URL}/${classId}`);
  }

  getUsersWithStudentRoles(officeId : any, classId : any): Observable<UserList[]>{
    return this.httpClient.get<UserList[]>(`${this.base2URL}/${officeId}/${classId}`);
  }

  getStudentDetails(studentId : string): Observable<Object>{
    return this.httpClient.get<Object>(`${this.getStudentDetailsUrl}/${studentId}`);
  }

  addStudentActualActivityScore(avenue: string,obj : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, obj, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  addStudentCA(avenue: string,obj : any): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {

      const req = new HttpRequest('POST', avenue, obj, {
        reportProgress: true,
        responseType: 'text'
      });

      self.httpClient.post(avenue, obj, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentsForAssessment(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }
  
  getStudentsForAssignmentEvaluation(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.get(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  setStudentActivityCompletionStatus(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  UnMarkActivityCompletedForStudent(avenue: string): Promise<any> {

    this.authToken = localStorage.getItem('auth-token');

    const self = this;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authToken
    });
    return new Promise((resolve, reject) => {
      self.httpClient.delete(avenue, { headers: headers })
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
        });
    });
  }

  getStudentResultsForAPs(subId : string, classId : string, teacherId : string): Observable<any>{
    return this.httpClient.get<any>(`${this.base5URL}/${subId}/${classId}/${teacherId}`);
  }

  getStudentSkillResultsList(classId : string): Observable<any>{
      return this.httpClient.get<any>(`${this.base6URL}/${classId}`);
  }

  getStudentsByProductSubsciption(productId : string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.base8URL}/${productId}`);
  }

  getStudentsByProductSubsciptionRA(productId : string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.base16URL}/${productId}`);
  }

  getStudentDetailsByUserId(userId : any): Observable<any>{
    return this.httpClient.get<any>(`${this.base9URL}/${userId}`);
  }

  studentStatusUpdate(studentId : any, notes : any): Observable<any>{
    return this.httpClient.delete<any>(`${this.base10URL}/${studentId}/${notes}`);
  }

  getStudentClassesByUserId(userId : any): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.base12URL}/${userId}`);
  }

  getStudentClassesByUserRoleOfficeId(uroId : any): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.base15URL}/${uroId}`);
  }

  getAssignmentsForStudentDashboard(studentId : any, subjectId : any, classId : any): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.base13URL}/${studentId}/${subjectId}/${classId}`);
  }



}
