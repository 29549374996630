<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">File Upload</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
       <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
        <div class="card-body">
    <form (ngSubmit)="onSubmit" #adminForm="ngForm">   
      
      <div *ngIf="showResults===false">

        Please note that Specialist / Doctor name, specialization and Organisation name, organisation location name and organisation type are mandatory. 
        You can download a sample file that can be used to know the format of the file you are needed to upload. <br>
        <a style="color: blue;"
        href="assets/sample_file_bot_members_creation.csv" 
        target="_blank" 
        download="sample-file.csv">
        Download Sample File
        </a>
    <br>
       
        <br>
        <div>
            <label  class="required">File</label>
            <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
             class="form-control" accept=".csv" >
         </div>

        </div>
        <div *ngIf="showResults===true">

          <!-- <h3 style="font-weight: bold;">Existing Users / Emails failed to be added({{resultDto.existingStudents.length}}) :</h3>
          <div *ngFor="let email of resultDto.existingStudents">
            <a style="color: black;">{{email}}</a>
          </div> -->

          <!-- <br>
          <br> -->

          <h3 style="font-weight: bold;">Members created / updated successfully({{resultDto.createdStudents.length}}) :</h3>
          <div *ngFor="let email of resultDto.createdStudents">
            <a style="color: black;">{{email}}</a>
          </div>
         
          <br>

        


        </div>
                </form>
        </div>
    </div>
</div>

<div class="modal-footer">
  <button *ngIf="showResults===false" [disabled]="clicked"  [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Submit</button>
</div>