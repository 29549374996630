
  <nav class="navbar navbar-expand-md navbar-dark "
   [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a  style="cursor: pointer;color: #0275d8;" (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Students</li>
    </ol>
  </nav>

  <div class="content" style="margin: 2.5%;" >

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
      
      <div class="col mb-4">
        <div onclick="history.back()" class="card example-ripple-container mat-elevation-z12"
         matRipple
         [matRippleCentered]="centered"
         [matRippleDisabled]="disabled"
         [matRippleUnbounded]="unbounded"
         [matRippleRadius]="radius"
         [matRippleColor]="color">
    
         <br>
         <br>
         <br>
         <br>
         <br>
         <br>
         <div class="sub-image-div">
          <img class="sub-image" src="/assets/images/icons/back-track.svg"  height="75" width="75" alt="...">
          </div>
   
    <br>
    <br>
    <br>
    <br>
    <br> 
    </div>
    </div>
    
  
         <div  *ngFor = "let card of students">

          <div *ngIf="studentExist === true" class="col mb-4">
            
        <div class="card mat-elevation-z12">

          <div class="sub-image-div">
          <img class="sub-image" [src]="card.safeStudentUrl" height="75" width="75" alt="...">
          </div>
          <div class="card-body">

            <p style="color: black;" class="card-text">{{card.studentName}}</p>
            <h5 >Age</h5>
            
            <div class="button-div" style="width: 100%;">
            <button style="margin: 1%;" [ngStyle]="backgroundStyle" (click)="viewDetails(card.studentId, card.studentName, card.email, card.dob, card.phone, card.safeStudentUrl  )" class="btn btn-info"> Details</button>
            <button *ngIf="officeObject.oa_SkillReport === true"  style="margin: 1%;"  (click)="viewResults(card.studentId, card.studentName, card.safeStudentUrl )" class="btn btn-success"> Skill Report</button>
            <button style="margin: 1%;"  (click)="goToActivityTimeline(card.studentId, card.studentName, card.safeStudentUrl)" class="btn btn-danger">Timeline</button>
            <button style="margin: 1%;" [ngStyle]="backgroundStyle" 
            (click)="open360View(card.studentId, card.studentName, card.email, card.dob, card.phone, card.safeStudentUrl)" 
            class="btn btn-info"> 360 View</button>

          </div>
          </div>
        </div>
      </div>
  
      </div>

     </div>
  
  
  
  
  </div>
  
  </div>





