
   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Old Invoices</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
     <div class="modal-body">
      <!-- <div class="card">
        <div class="card-body"> -->
 
<a style="color: black;">
  The invoices present below are system generated invoices to tally the outstanding amount in your accounting system.
  <br>
  Please note that these invoices are not available anywhere else.    
</a>

<br>
<br>

<table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
  <thead class="tableheader">
    <tr>
      <th *ngIf="fromParent.currentRole!=='BCRM_EXE'">Executive</th>
      <th>Customer</th>
      <th>Outstanding Amount</th>
      <th *ngIf="fromParent.currentRole==='BCRM_FM'">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr class="td-height" *ngFor="let ccd of invoices">
      <td *ngIf="fromParent.currentRole!=='BCRM_EXE'" class="td-height">{{ccd.executiveName}}</td>
      <td class="td-height">{{ccd.customerName}}</td>

      <td style="text-align: right;" class="td-height">{{ccd.outstandingAmount}}</td>
      
      
      <td *ngIf="fromParent.currentRole==='BCRM_FM'" style="text-align: center;" class="td-height">
        <button style="width: auto;" (click)="outstandingAmtToEdit=ccd.outstandingAmount;open(confirmEdit)" class="btn btn-info">Edit outstanding amt</button>
      </td>
      

      <ng-template #confirmEdit let-modal>
        <div [style.background-color]='getColor()' class="modal-header">
          <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Edit outstanding amount</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <a style="color: black;">
          Please note that this would also update the outstanding amount of the customer.</a>
          <br>
          <br>
          <input #date="ngModel" required class="form-control" type="number" name="outstandingAmtToEdit" 
          id="outstandingAmtToEdit" [(ngModel)]="outstandingAmtToEdit">
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
          <button type="button" class="btn btn-success" style="width:auto;" (click)="editOutstandingAmt(ccd.invoiceId);modal.dismiss('Cross click')">Update</button>
        </div>
      </ng-template>
   
    </tr>    

  </tbody>
</table>
  </div>
      <!-- </div>
      </div> -->