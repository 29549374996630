import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss']
})
export class LeadListComponent implements OnInit {
  loggedInUser: any;

  constructor(private compCustDetailsService : CompCustDetailsService, private sharedDataService : SharedDataService,
    private router : Router) { }
    clicked = false;
  leads : CompleteCustomerDetails[] = [];

  officeId!: string;

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.getLeadDetails();
  }

  getLeadDetails(){
    this.compCustDetailsService.getLeadList(environment.apiUrl + "crmCustAdd/getallLeads/" + this.officeId).then( data =>{
this.leads=data;
    });
  }

  updateCustomer(addressId : string){
    this.router.navigate(['updateCcDetails',addressId]);
  }

  deleteCustomer(addressId : string){
    this.compCustDetailsService.deleteCustomer(addressId).subscribe( data => {
      this. getLeadDetails();
    })
  
  }

} 
