<div class="modal-header" [ngStyle]="backgroundStyle" >
    <h4 style="color: white;"  class="modal-title pull-left">Assign Subject</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  
  <div class="modal-body">
    

                  
                  <!-- <mat-vertical-stepper orientation="vertical" #stepper>

                    <mat-step>
                      <form>
                        <ng-template matStepLabel> Class</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Class</mat-label>
                          <mat-select [(ngModel)]="selectedClassId" >
                            <mat-option *ngFor="let Class of classes" [value]="Class.classId">
                              {{Class.name}}
                            </mat-option>
                          </mat-select> 
                        </mat-form-field>

                        <div>
                          <button (click)="chooseSubject()" mat-button matStepperNext>Next</button>
                        </div>
                      </form>
                    </mat-step>

                    <mat-step>
                      <form>
                        <ng-template matStepLabel>Subject</ng-template>
                        <mat-form-field appearance="fill">
                          <mat-label>Choose Subject</mat-label>
                          <mat-select [(ngModel)]="selectedSubjectId" >
                            <mat-option *ngFor="let Subject of subjects" [value]="Subject.subId">
                              {{Subject.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div>
                          <button mat-button matStepperPrevious>Back</button>
                          <button (click)="assignClassSubject()" mat-button matStepperNext>Assign</button>
                        </div>
                      </form>
                    </mat-step>



                  </mat-vertical-stepper> -->

                  <div class="form-group row">


                    <div class="col-md-6">
                      <label class="required" for="Class">Class</label> <br>
                          <select (change)="chooseSubject();" required  class="form-control" [(ngModel)]="selectedClassId" id="Class" name="Class">
                            <option value="" disabled >Choose Class</option>
                            <option  *ngFor="let Class of classes"  [ngValue]="Class.classId">{{Class.name}}</option>
                          </select>
                      </div>
                      
                      <div class="col-md-6">
          
                  <label class="required" for="Subject">Subject</label> <br>
                      <select required  class="form-control" [(ngModel)]="selectedSubjectId" id="Subject" name="Subject">
                        <option value="" disabled >Choose Subject</option>
                        <option  *ngFor="let Subject of subjects"  [ngValue]="Subject.subId">{{Subject.name}}</option>
                      </select>
                  </div>
          
                  
          
                
          
                  </div>
                  
  </div>

  <div class="modal-footer">
    <button class="btn btn-sm btn-primary float-right" (click)="assignClassSubject()">Add</button>
  </div> 