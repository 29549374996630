import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Product } from 'src/app/crmClasses/product';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { FormConfigurationService } from 'src/app/services/FormConfiguration/form-configuration.service';
import { FormConfiguation } from 'src/app/models/form-configuation';
import { UserDetails } from 'src/app/models/user-details';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {

  clicked : boolean = false;
  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  userId : any;
  products : Product[] = [];
  roles : any[] = ["TEACHER", "STUDENT"];
  formConfigGroups : FormConfiguation[] =[];

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  isChildStudent : any;

  constructor(
              private toaster : ToastrService,
              public activeModal: NgbActiveModal,
              private formConfigService : FormConfigurationService,
              private subscriptionService  : SubscriptionService ,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
    this.countryCodes = GlobalClass.CountryCodes;
    
this.userId =  this.fromParent.userId;
this.isChildStudent = this.fromParent.isChildStudent;

    this.subscriptionService.getUserForEdit(this.userId).subscribe(
      data=>{
        this.combinedPTC = data;
      });

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
 
      this.formConfigService.getByFormNameAndOfficeId(environment.apiUrl + "formConfig/getGroupedFormConfigByFormNameAndOfficeId/" + "Student Creation").then(
        data=>{            
          
          this.formConfigGroups = data;
          if(this.formConfigGroups.length !== 0  && this.formConfigGroups !== undefined && this.formConfigGroups !== null){
            this.formConfigGroups.forEach((formConfigGroup)=>{

              let uds : UserDetails[] = [];

              formConfigGroup.groupedformConfigs.forEach((formConfig)=>{

                    let ud : UserDetails = new UserDetails();
                    ud.fkFormConfigId = formConfig.formConfigId;
                    ud.mandatory = formConfig.mandatory;
                    ud.displayName = formConfig.displayName;
                    ud.columnName = formConfig.columnName;
                    ud.key = formConfig.columnName;

                    this.combinedPTC.userDetails.forEach((userDetail)=>{

                    if(userDetail.fkFormConfigId === formConfig.formConfigId){

                    ud.value = userDetail.value;
                    ud.userDetailsId = userDetail.userDetailsId;
                    ud.fkUserId = userDetail.fkUserId;
                    
                  }

                });
 
                uds.push(ud);

              });

              formConfigGroup.userDetails = uds;

            });
          }
        });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onSubmit(){

   
    this.formConfigGroups.forEach((fcg)=>{
      fcg.userDetails.forEach((userDetails)=>{
        this.combinedPTC.userDetails.push(userDetails);
      });
    });

    this.subscriptionService.updateUser(environment.apiUrl + "user/updateUser", this.combinedPTC).then(
      data=>{
        this.passEntry.emit("success");
        this.activeModal.close();
        this.toaster.success("User updated successfully");
      });
   
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
