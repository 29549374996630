<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left"> Evaluate Activity</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  
  
  <div class="modal-body">
   
    <form style="width: 100%;">
      
      <table  style="width: 100%;">
        <thead>
          <tr>
            <th style="width: 10%;">No</th>
            <th style="width: 60%;">Objective</th>
            <th style="width: 30%;">Response</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let qsn of actQuestions, let i = index">
            <td style="width: 10%;">{{i+1}}</td>
            <td style="width: 60%;">{{qsn.activityQuestion}}</td>

            <td style="width: 30%;">
              <div>
                <div *ngIf="inputType=='Radio'; else elseBlock">
                   <mat-radio-group id="i" aria-label="Select an option" [(ngModel)]="activityScore.activityScoreValue" >
                       <mat-radio-button name="i" value="1">Yes</mat-radio-button>
                       <mat-radio-button name="i" value="1">No</mat-radio-button>
                     </mat-radio-group>
               </div>
               <ng-template #elseBlock>OTHER INPUTS</ng-template> 
              </div>
            </td>

          </tr>
        </tbody>
      </table>


    </form>
      
</div>


<br>
<br>
<br>





<!-- 
<div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div> -->