import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubjectService } from 'src/app/service/subject.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';
    
@Component({
  selector: 'app-update-subject',
  templateUrl: './update-subject.component.html',
  styleUrls: ['./update-subject.component.scss']
})
export class UpdateSubjectComponent implements OnInit {

  constructor(private subjectService : SubjectService,
              public activeModal: NgbActiveModal,
              public bsModalRef: BsModalRef,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private toaster: ToastrService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;
  selectedFile!: File;
  fileChange : boolean = false;
  subId!: string;
  subject : Subject = new Subject();
  unsafeImageUrl! : any;
  safeImageUrl! : any;
  clicked=false;
  
  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
   

    this.subId = this.fromParent.subId;

    this.subjectService.getSubById(this.subId).subscribe(
      data=>{
        this.subject = data;

        // Get image
        this.imageService.getSubjectImage(this.subject.subjectImage).subscribe(
          data=>{
            this.safeImageUrl = data;
            this.unsafeImageUrl = URL.createObjectURL(this.safeImageUrl);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
          });
      }); 

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  onFileSelected(event: any){
    this.fileChange = true;
    this.selectedFile = event.target.files[0];
  }

  onSubmit(){
      this.subjectService.updateSubject(environment.apiUrl + 'subject/update',this.subject).then(

      data =>{

        if(this.fileChange === true){
           //UPLOAD IMAGE
         let body = new FormData();
         body.append("file", this.selectedFile);

         this.imageService.uploadSubjectImage(body, this.subject.subId).subscribe(
           data=>{

            this.passEntry.emit(this.subject);
            this.activeModal.close();
            this.toaster.success("Subject Updated Successfully");
           });
           
        } else if(this.fileChange === false){

          this.passEntry.emit(this.subject);
          this.activeModal.close();
          this.toaster.success("Subject Updated Successfully");

        }

        
    });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
