<div [ngStyle]="backgroundStyle"  class="modal-header">
  <h4 style="color: white;" class="modal-title pull-left">Edit {{type}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> 

 


  <div class="modal-body">
    <div class="card">
      <div class="card-body">
   
        <form (ngSubmit)="onUpdate" #customerRegisteration="ngForm">   
      
         
          <div *ngIf="fromParent.type === 'LEAD'" class="form-group row" style="margin-bottom: 2%;">
 
            <div class="col-md-4">
  
              <label class="required">Entity Type </label> <br>
              <mat-radio-group name="entityType" required aria-label="entityType" [(ngModel)]="combinedPTC.entityType">
                <mat-radio-button name="entityType" id="Individual" value="Individual"  >Individual</mat-radio-button>
                <mat-radio-button name="entityType" id="Organisation" value="Organisation"  >Organisation</mat-radio-button>
              </mat-radio-group>
    
            </div>

                    <div class="col-md-4">
                    <label for="leadSource"> Lead Source</label> <br> 
                    <select #leadSource="ngModel" class="form-control" [(ngModel)]="combinedPTC.leadSource" id="leadSource" name="leadSource">
                    <option value="" disabled >Choose Lead Source</option>
                    <option name="leadSource" id1="Billboards" value="Billboards" >Billboards</option>
                    <option name="leadSource" id1="Blogs" value="Blogs">Blogs</option>
                    <option name="leadSource" id1="Email Advertising" value="Email Advertising" >Email Advertising</option>
                    <option name="leadSource" id1="Facebook" value="Facebook" >Facebook</option>
                    <option name="leadSource" id1="Google" value="Google" >Google</option>
                    <option name="leadSource" id1="Instagram" value="Instagram" >Instagram</option>
                    <option name="leadSource" id1="In-Person Event" value="In-Person Event" >In-Person Event</option>
                    <option name="leadSource" id1="Magazine" value="Magazine" >Magazine</option>
                    <option name="leadSource" id1="Phone" value="Phone" >Phone</option>
                    <option name="leadSource" id1="Radio" value="Radio" >Radio</option>
                    <option name="leadSource" id1="Referral" value="Referral" >Referral</option>
                    <option name="leadSource" id1="Television" value="Television" >Television</option>
                    <option name="leadSource" id1="Whatsapp" value="Whatsapp" >Whatsapp</option>
                    <option name="leadSource" id1="Others" value="Others" >Others</option>
                    </select>
                    <div class="alert alert-danger" *ngIf="leadSource.touched && !leadSource.valid">Select Lead Source</div>
                    </div>

          
               <div class="col-md-4">
                <label class="required">Lead Type </label> <br>
                <mat-radio-group required aria-label="leadType" name="combinedPTC.leadType" [(ngModel)]="combinedPTC.leadType">
                  <mat-radio-button name="leadType" id="Hot" value="Hot Lead"  >Hot Lead</mat-radio-button>
                  <mat-radio-button name="leadType" id="Cold" value="Cold Lead"  >Cold Lead</mat-radio-button>
                </mat-radio-group>
              </div>

              </div> 

              <br>
              <h3 style="text-align: center;font-weight: bold;">Basic Details</h3>
           
            <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
            
              <div class="col-md-6">
              <label class="required" for="firstName">First Name</label>
              <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter First Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
              <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid">First Name is Required</div>
              </div>
            
              <div class="col-md-6">
              <label class="required" for="lastName">Last Name</label>
              <input #lastName="ngModel"  maxlength="50"  required class="form-control" type="text" placeholder="Enter Last name" name="lastName" id="lastName" [(ngModel)]="combinedPTC.lastName">
              <div class="alert alert-danger" *ngIf="lastName.touched && !lastName.valid">Last Name is Required</div>
            </div>
         
            </div>
            
            
            <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
            
              <div class="col-md-6">
              <label for="email">Email</label>
              <input readonly #email="ngModel" maxlength="250" email
               class="form-control" type="email" placeholder="Enter Email" 
               name="email" id="email" [(ngModel)]="combinedPTC.email">
              </div>
            
              <div class="col-md-2">
                <label class="required" for="countryDialCode"> Code</label>
                <select class="form-control" name="countryDialCode" id="countryDialCode"
                 [(ngModel)]="combinedPTC.countryDialCode">
                  <option value="" disabled selected>Select Country</option>
                  <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
             </select>
              </div>

            <div class="col-md-4">
              <label class="required" for="phoneNumber">Phone Number</label>
              <input  #phone="ngModel" readonly maxlength="250" minlength="10" 
              class="form-control" type="text" placeholder="Enter Phone Number"
               name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
              </div>

           
            
            </div>

            <div *ngIf="combinedPTC.entityType==='Individual'" class="form-group row">
            
              <div class="col-md-4">
                <label for="dateOfBirth">Date of Birth</label>
                <input class="form-control" type="date"  name="dateOfBirth" id="dateOfBirth" [(ngModel)]="combinedPTC.dateOfBirth">
                </div>
             
                <div class="col-md-4">
    
                  <label >Gender </label> <br>
                <mat-radio-group aria-label="gender" name="combinedPTC.gender" [(ngModel)]="combinedPTC.gender">
                  <mat-radio-button name="gender" id="Male" value="Male"  >Male</mat-radio-button>
                  <mat-radio-button name="gender" id="Female" value="Female"  >Female</mat-radio-button>
                  <mat-radio-button name="gender" id="Other" value="Other"  >Other</mat-radio-button>
                </mat-radio-group>
        
                </div>

                <div class="col-md-4">
                  <label for="leadCreationDate">Date of Creation</label>
                  <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                   [(ngModel)]="combinedPTC.leadCreationDate">
                  </div> 

            </div>
            
            <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
            
              <div class="col-md-6">
                <label class="required" for="firstName"> Name</label>
                <input #firstName="ngModel"  maxlength="50"  required minlength="3" class="form-control" type="text" placeholder="Enter Name" name="firstName" id="firstName" [(ngModel)]="combinedPTC.firstName">
                <div class="alert alert-danger" *ngIf="firstName.touched && !firstName.valid"> Name is Required</div>
                </div>
              
                <div class="col-md-6">
                  <label for="email">Email</label>
                  <input readonly #email="ngModel" maxlength="250" email
                   class="form-control" type="email" placeholder="Enter Email"
                    name="email" id="email" [(ngModel)]="combinedPTC.email">
                  </div>
        
        
            
            </div>
            
            
            <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">
            
              <div class="col-md-4">
              <label for="contactPerson"> Contact Person</label>
              <input #contactPerson="ngModel"  maxlength="50" minlength="3" class="form-control" type="text" placeholder="Enter Name" name="contactPerson" id="contactPerson" [(ngModel)]="combinedPTC.contactPerson">
              <div class="alert alert-danger" *ngIf="contactPerson.touched && !contactPerson.valid"> Name is Required</div>
              </div>
              
                <div class="col-md-4">
                <label for="contactPersonEmail">Contact Person Email</label>
                <input #contactPersonEmail="ngModel" maxlength="250"    class="form-control" type="contactPersonEmail" placeholder="Enter Email" name="contactPersonEmail" id="contactPersonEmail" [(ngModel)]="combinedPTC.contactPersonEmail">
                <div class="alert alert-danger" *ngIf="contactPersonEmail.touched && !contactPersonEmail.valid">Email is required</div>
                </div>
        
                <div class="col-md-4">
                  <label for="leadCreationDate">Date of Creation</label>
                  <input class="form-control" type="date"  name="leadCreationDate" id="leadCreationDate"
                   [(ngModel)]="combinedPTC.leadCreationDate">
                  </div> 
            
            </div>

            <div *ngIf="combinedPTC.entityType==='Organisation'" class="form-group row">

              <div class="col-md-2">
              <label class="required" for="countryDialCode"> Code</label>
              <select class="form-control" name="countryDialCode" id="countryDialCode"
              [(ngModel)]="combinedPTC.countryDialCode">
              <option value="" disabled selected>Select Country</option>
              <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
              </select>
              </div>

              <div class="col-md-4">
                <label for="phoneNumber">Customer Phone</label>
                <input readonly #phone="ngModel" maxlength="250" minlength="10" 
                class="form-control" type="text" placeholder="Enter Phone Number"
                 name="phoneNumber" id="phoneNumber" [(ngModel)]="combinedPTC.phoneNumber">
                </div>


              <div class="col-md-2">
              <label for="contactPersonCountryDialCode"> Code</label>
              <select class="form-control" name="contactPersonCountryDialCode" id="contactPersonCountryDialCode"
              [(ngModel)]="combinedPTC.contactPersonCountryDialCode">
              <option value="" disabled selected>Select Country</option>
              <option *ngFor="let country of countryCodes" [value]="country.number">{{country.name}} ( {{country.code}} {{country.number}} )</option>
              </select>
              </div>

              <div class="col-md-4">
                <label for="contactPersonPhone">Contact Person Phone</label>
                <input #contactPersonPhone="ngModel" maxlength="250"    minlength="10" class="form-control" type="text" placeholder="Enter Phone Number" name="contactPersonPhone" id="contactPersonPhone" [(ngModel)]="combinedPTC.contactPersonPhone">
                <div class="alert alert-danger" *ngIf="contactPersonPhone.touched && !contactPersonPhone.valid">Invalid Phone Number </div>
                </div>
          
            </div>
            
              
            
            <div *ngFor="let formConfigGroup of formConfigGroups">
              <br>
                <h3 style="text-align: center;font-weight: bold;">{{formConfigGroup.groupName}}</h3>
      
                <div class="form-group row">
      
                  <div class="col-md-4" *ngFor="let ud of formConfigGroup.userDetails">
                    <div *ngIf="ud.mandatory">
                      <label class="required" for="ud.displayName">{{ud.displayName}}</label>
                    <input class="form-control" maxlength="250"  type="text"
                    required placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                     [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                    </div>
        
                    <div *ngIf="!ud.mandatory" >
                      <label for="ud.displayName">{{ud.displayName}}</label>
                    <input class="form-control" maxlength="250"  type="text"
                     placeholder="Enter {{ud.displayName}}" [name]="ud.fkFormConfigId" 
                     [id]="ud.fkFormConfigId" [(ngModel)]="ud.value">
                    </div>
                  
                  </div>
                </div>
              </div>

              <br>
              <h3 style="text-align: center;font-weight: bold;">Address Details</h3>

            
            
            <div class="form-group row">
            
              <div class="col-md-4">
              <label for="addressLine1">Address Line 1</label>
              <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 1" name="addressLine1" id="addressLine1" [(ngModel)]="combinedPTC.addressLine1">
              </div>
            
              <div class="col-md-4">
              <label for="addressLine2">Address Line 2</label>
              <input class="form-control" maxlength="250"  type="text" placeholder="Enter Address Line 2" name="addressLine2" id="addressLine2" [(ngModel)]="combinedPTC.addressLine2">
              </div>
    
              <div class="col-md-4">
              <label for="city">City</label>
              <input class="form-control" maxlength="250"  type="text" placeholder="Enter city" name="city" id="city" [(ngModel)]="combinedPTC.city">
              </div>
            
            </div>
            
            <div class="form-group row">
            
              <div class="col-md-4">
                <label for="state">State</label>
                <input class="form-control" maxlength="250" type="text" placeholder="Enter State" name="state" id="state" [(ngModel)]="combinedPTC.state">
                </div>
            
            
              <div class="col-md-4">
              <label for="country">Country</label>
              <input class="form-control" maxlength="250" type="text" placeholder="Enter country" name="country" id="country" [(ngModel)]="combinedPTC.country">
              </div>
    
              <div class="col-md-4">
                <label for="zipCode">Zip Code</label>
                <input class="form-control" maxlength="250" type="text" placeholder="Enter Zip Code" name="zipCode" id="zipCode" [(ngModel)]="combinedPTC.zipCode">
                </div>
            
            </div>
            
            <div>
              <label for="notes">Notes</label>
              <textarea  maxlength="5000" placeholder="Enter notes" class="form-control" type="text" name="notes"  id="notes" [(ngModel)]="combinedPTC.notes"></textarea>
           </div> 
            
           
    
             
            
            
                    </form>

  </div>
</div>
</div>
<div class="modal-footer">
  <button [disabled]="clicked"   [disabled]="!customerRegisteration.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onUpdate(); clicked = true;">Update</button>
</div>
