<div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 *ngIf="context==='Add Subscription'" style="color: white;" class="modal-title pull-left">{{context}} to {{fromParent.officeName}}</h4>
    <h4 *ngIf="context==='Update Subscription'" style="color: white;" class="modal-title pull-left">{{context}} of {{fromParent.officeName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form (ngSubmit)="addWcaSubscription()" #adminForm="ngForm">  

 



        <div class="form-group row">
         
            <div class="col-md-6">
              <label class="required" for="startDate">Start Date</label>
              <input required class="form-control" type="date"  name="startDate" id="startDate" [(ngModel)]="subscription.startDate">
              </div>
          
              <div class="col-md-6">
                <label class="required" for="endDate">End Date</label>
                <input required class="form-control" type="date"  name="endDate" id="endDate" [(ngModel)]="subscription.endDate">
                </div>
  
          </div> 

  
  

 
</form>
</div>
</div>

<div class="modal-footer">
<button *ngIf="context==='Add Subscription'" [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="addWcaSubscription(); clicked=true">save</button>
<button *ngIf="context==='Update Subscription'" [disabled]="clicked" [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" 
(click)="editWcaSubscription(); clicked=true">Update</button>
</div>