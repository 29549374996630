import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CustomerCommunications } from 'src/app/BIZ_CRM/biz_crm_classes/customer-communications';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent implements OnInit {

  clicked = false;
coms : CustomerCommunications = new CustomerCommunications;
 
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef,
              private datePipe: DatePipe, 
              private customerService : CustomerService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService) {}

              @Input() fromParent: any;

  ngOnInit(): void {

  this.coms = this.fromParent.coms;         

  this.coms.nextActionDateTime = this.datePipe.transform(this.coms.nextActionDateTime, 'yyyy-MM-ddThh:mm');
  this.coms.currentActionDateTime = this.datePipe.transform(this.coms.currentActionDateTime, 'yyyy-MM-ddThh:mm');

  }

  onSubmit(){
    this.customerService.updateCustomerCommunications(environment.apiUrl + 'bcrmCustomerCommunications/update', this.coms).then(
            data=>{
        this.activeModal.close();
        this.passEntry.emit();
        this.toaster.success(" Update Successful");
      });
  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }

}