import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { TicketSupportDefinition } from 'src/app/BIZ_CRM_TICKET_SUPPORT/classes/ticket-support-definition';
import { TicketSupportService } from 'src/app/BIZ_CRM_TICKET_SUPPORT/services/ticket-support.service';
import { Role } from 'src/app/models/role';
import { RolesServiceService } from 'src/app/services/role/roles-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-ticket-support-definition',
  templateUrl: './create-ticket-support-definition.component.html',
  styleUrls: ['./create-ticket-support-definition.component.scss']
})

export class CreateTicketSupportDefinitionComponent implements OnInit {
 
  clicked = false;
  ticketSupportDefinition : TicketSupportDefinition = new TicketSupportDefinition();
  feedbackForms : FeedbackForm[] = [];
  toppingsControl = new FormControl([]);
  loggedInUser : any; 
  theme : any;
  roles : Role[] = [];
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
    private formService : FeedbackFormService,
    private sharedDataService : SharedDataService,
    private roleService : RolesServiceService,
    private ticketSupportService : TicketSupportService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();    
    this.getForms();
    this.getRoles();
  }

  getForms(){
    this.formService.getFeedbackFormsByOfficeId(environment.apiUrl + "feedbackForm/getByOfficeId").then(
      data=>{
        this.feedbackForms = data;
      });
  }

  getRoles(){
    this.roleService.getAllRoles(environment.apiUrl + 'admin/get/bizOfficeRoles').then(rolesRes => {
      this.roles = rolesRes;
    });
  }

  rolesSelection(value : any){
  this.ticketSupportDefinition.roleIds = [];
  value.forEach((val : any)=>{
    this.ticketSupportDefinition.roleIds.push(val);
  });    
  }

  onSubmit(){    
    this.ticketSupportService.createTicketSupportDefinition(environment.apiUrl + "ticketSupport/createTicketSupportDefinition", this.ticketSupportDefinition).then(
      data=>{
        this.activeModal.close();
        this.toaster.success('Ticket Support Definition created successfully');
        this.passEntry.emit('success');
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

}
 