import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Payment } from 'src/app/crmClasses/payment';
import { PaymentService } from 'src/app/service/payment.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-update-payment',
  templateUrl: './update-payment.component.html',
  styleUrls: ['./update-payment.component.scss']
})
export class UpdatePaymentComponent implements OnInit {

  loggedInUser : any;
  theme : any;

  paymentId!: string; 
  clicked = false;
  @Input() fromParent: any;

  payment : Payment = new Payment();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
              private paymentService : PaymentService,
              private sharedDataService: SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService ) { }

  ngOnInit(): void {
   
    this.paymentId = this.fromParent.paymentId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.paymentService.getPaymentById(this.paymentId).subscribe(data=>{
      this.payment = data;
    }, error => console.log(error));
  } 

  onEdit(){
    this.paymentService.updatePayment(environment.apiUrl + "payment/update", this.payment).then(
      data =>{
        this.passEntry.emit(this.payment);
        this.activeModal.close();
        this.toaster.success("Payment Updated Successfully");
      }
      ,error => console.error()
    );
  }

  closeModal(){
    this.activeModal.close();
  }
 
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

}
