 <div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" *ngIf="role !== 'BCRM_PM'" class="modal-title pull-left">Create Course / Product</h4>
    <h4 style="color: white;" *ngIf="role === 'BCRM_PM'" class="modal-title pull-left">Create Product</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 




   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form  (ngSubmit)="onSubmit()"  #productForm="ngForm">

       

        <div *ngIf="fromParent.officeType === 'BCRM'" class="form-group row">

            <div class="col-md-4">
            <label class="required" for="productName"> Name</label>
            <input  maxlength="50"  #name="ngModel" required class="form-control"  placeholder="Enter Product Name" type="text" name="productName" id="productName" [(ngModel)]="prod.productName">
            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
        </div>

        <div class="col-md-4">
            <label for="productPrice"> Price</label>
            <input   maxlength="250" class="form-control"  placeholder="Enter Price" type="number" name="productPrice" id="productPrice" [(ngModel)]="prod.productPrice">
        </div>

        <div class="col-md-4">
          <label for="defaultQuantity"> Default Quantity</label>
          <input   maxlength="250" class="form-control"  placeholder="Enter Default Quantity" type="number" name="defaultQuantity" id="defaultQuantity" [(ngModel)]="prod.defaultQuantity">
      </div>

        </div>

              

        <div *ngIf="fromParent.officeType === 'EDU'" class="form-group row">

          <div class="col-md-6">
          <label class="required" for="productName"> Name</label>
          <input  maxlength="50"  #name="ngModel" required class="form-control"  placeholder="Enter Product Name" type="text" name="productName" id="productName" [(ngModel)]="prod.productName">
          <div class="alert alert-danger" *ngIf="name.touched && !name.valid">Name is required</div>
      </div>

      <div class="col-md-6">
          <label for="productPrice"> Price</label>
          <input   maxlength="250" class="form-control"  placeholder="Enter Price" type="number" name="productPrice" id="productPrice" [(ngModel)]="prod.productPrice">
      </div>

     

      </div>

      <br>
      <div>
        <label for="name">Product Image</label>
        <input type="file" class="form-control" (change)="onFileSelected($event)" placeholder=" Choose File To Upload" >
      </div>
<br>
        <div>
            <label for="productDescription">Description</label>
            <textarea  maxlength="250"  #remarks="ngModel" maxlength="244" class="form-control"  placeholder="Enter Description" type="text" name="productDescription" id="productDescription" [(ngModel)]="prod.productDescription"></textarea>
            <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
        </div>

        

          

    </form>
</div>
    </div>
   </div>

   <div class="modal-footer">
    <button  [disabled]="clicked"  [disabled]="!productForm.valid"  type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked = true;">Save</button>
 </div>