import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';


interface curr {
  curriculumName: string;
  curriculumId : string;
  children?: curr[];
}

const TREE_DATA: any[] = [
 {
    name: 'Vegetables',
    children: [{name: 'Green',
                children: [
                            {name: 'Broccoli'},
                            {name: 'Brussels sprouts'},
                          ]
              }
    ]
  },
];

 const currList = [
  {
     name : '',
     id : '',
     children: [{name : '',
                 id : '',
                 children: [{name: '',
                              id : ''}]
               }]
  }];
 

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  curriculumId : string,
  level: number;
}

@Component({
  selector: 'app-student-curriculum-dashboard',
  templateUrl: './student-curriculum-dashboard.component.html',
  styleUrls: ['./student-curriculum-dashboard.component.scss']
})
export class StudentCurriculumDashboardComponent implements OnInit {

  

  private _transformer = (node: curr, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.curriculumName,
      curriculumId : node.curriculumId,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  currentRole="STUDENT";
  showSpinner : boolean = true;
  userId!: string;
  userName : any;
  loggedUser : any;
  infoState : boolean = false;
  loggedInUser : any;
  theme: string = '';
  subjectId!: string;
  public screenWidth: any;
  public screenHeight: any;
  asDontExist = false;
  showActivities : boolean = true;
  apDontExist = false;
  assignments : any[] =[ {  activityPlannerId : '', 
                          activityPlannerName : '',
                          activityPlannerDescription : '',
                          activityPlannerOrder : '',
                          activityPlannerStatus : '',
                          fkSubjectId : '',
                          activityPlannerImage : '' ,
                          safeUrl : '',
                          type : '',
                          collapsedState : '',
                          
                          activityVideoLinkList : [{
                                                      actVideoLinkId: "",
                                                      actVideoLinkName: "",
                                                      actVideoLink: "",
                                                      actVideoLinkDescription: "",
                                                      fkActivityPlannerId: "",
                                                      actVideoLinkStatus: "",
                                                      urlSafe: ""
                                                    }]
                                                            }];

  safeImageUrl: any;
  unsafeImageUrl: any
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  curriculumObject : Curriculum = new Curriculum();

  centered = false;
  disabled = false;
  unbounded = false;
  xyz: any;
  abc: any;
  radius!: number;
  color!: string;

  mainCurriculumIds : any[] =[];
  mainCurrState : boolean = true;
  mainCurrState2 : boolean = false;
  mainCurrState3 : boolean = false;
  prevParentId : string = '';
  prevParentId2 : string = '';

  currm : curr[] = [
    {
      curriculumName : '',
      curriculumId : '',
       children: [{curriculumName : '',
                   curriculumId : '',
                   children: [{curriculumName: '',
                               curriculumId : ''}]
                 }]
    }];

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;


  navbarTitle: any;
  readMore = false;
  activityPlanners2 : any[] =[ {  activityPlannerId : '', 
  activityPlannerName : '',
  activityPlannerDescription : '',
  activityPlannerOrder : '',
  activityPlannerStatus : '',
  fkSubjectId : '',
  activityPlannerImage : '' ,
  safeUrl : '',
  collapsedState : '',
  type : '',

  
 
  activityVideoLinkList : [{
                              actVideoLinkId: "",
                              actVideoLinkName: "",
                              actVideoLink: "",
                              actVideoLinkDescription: "",
                              fkActivityPlannerId: "",
                              actVideoLinkStatus: "",
                              urlSafe: ""

                            }]
                                    } ];

  activityPlanners : any[] =[ {  activityPlannerId : '', 
  activityPlannerName : '',
  activityPlannerDescription : '',
  activityPlannerOrder : '',
  activityPlannerStatus : '',
  fkSubjectId : '',
  activityPlannerImage : '' ,
  safeUrl : '',
  collapsedState : '',
  type : '',
  readMore : '',
  
 
  activityVideoLinkList : [{
                              actVideoLinkId: "",
                              actVideoLinkName: "",
                              actVideoLink: "",
                              actVideoLinkDescription: "",
                              fkActivityPlannerId: "",
                              actVideoLinkStatus: "",
                              urlSafe: ""
                            }]
                                    } ];

                                   
  
  constructor(private sharedDataService: SharedDataService, 
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private ngbModalService: NgbModal,
              public router: Router,
              private activityPlannerService : ActivityPlannerService,
              public route : ActivatedRoute,
              private curriculumService : CurriculumService ) { }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.subjectId = this.route.snapshot.params['subjectId'];

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;

    this.getCurriculum();
  }

 
  getCurriculum(){
    this.curriculumService.getSortedCurrListBySubId(this.subjectId).subscribe(
      data=>{
this.currm= data;
this.dataSource.data = this.currm;
setTimeout(()=>{                           //timer
  this.showSpinner = false;
}, 1000);
            });

            if(this.currm === undefined || this.currm.length === 0){
              setTimeout(()=>{                           //timer
                this.showSpinner = false;
           }, 1000);
            }
  }

  getDetails(currId : any){
    this.apDontExist = false;
    this.asDontExist = false;
    this.showSpinner = true;
    this.curriculumService.getCurrById(currId).subscribe(
      data=>{
        this.curriculumObject = data;
        this.infoState = true;
this.getactivityPlanners(currId);

      });
  }

  collapseActivityCardContent(activityPlannerId : string){

    this.activityPlanners.forEach((value)=>{

      if(value.activityPlannerId === activityPlannerId){

        if(value.collapsedState === true){
          value.collapsedState = false
        } else if(value.collapsedState === false){
          value.collapsedState = true
        }

      }
    }); 

  }

  collapseActivityCardContent2(activityPlannerId : string){

  this.assignments.forEach((value)=>{

    if(value.activityPlannerId === activityPlannerId){

      if(value.collapsedState === true){
        value.collapsedState = false;
      } else if(value.collapsedState === false){
        value.collapsedState = true;
      }

    }
  });
}
  show(){
    this.showSpinner = true;
    if(this.showActivities === true){
     this.showActivities = false;
     setTimeout(()=>{                           //timer
       this.showSpinner = false;
   }, 1000);
    }else  if(this.showActivities === false){
     this.showActivities = true;
     setTimeout(()=>{                           //timer
       this.showSpinner = false;
   }, 1000);
    }
  }


  getactivityPlanners(currId : any){
    this.activityPlannerService.getActivityPlannerListByCurId(currId).subscribe(
      data=>{
        
        this.activityPlanners = [];
        this.activityPlanners2 = [];

        this.activityPlanners.pop();

        for(var index in data){
          this.activityPlanners.push( {  'activityPlannerId' : data[index].activityPlannerId, 
                                              'activityPlannerName' : data[index].activityPlannerName,
                                              'activityPlannerDescription' : data[index].activityPlannerDescription,
                                              'activityPlannerOrder' : data[index].activityPlannerOrder,
                                              'activityPlannerStatus' : data[index].activityPlannerStatus,
                                              'fkSubjectId' : data[index].fkSubjectId,
                                              'activityPlannerImage' : data[index].activityPlannerImage ,
                                              'safeUrl' : this.safeImageUrl ,
                                              'collapsedState' : false,
                                              'type' : data[index].type,
                                              'activityVideoLinkList' : data[index].activityVideoLinkList,
                                                 'readMore' : false
                                            });
}

if(this.activityPlanners === undefined || this.activityPlanners.length === 0){

this.apDontExist =  true;
  setTimeout(()=>{                           //timer
    this.showSpinner = false;
}, 1000);


}

this.activityPlanners.forEach( (value) => {
          
  this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
    data=>{
      this.unsafeImageUrl = URL.createObjectURL(data);
      this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);

      value.safeUrl = this.safeImageUrl;

      setTimeout(()=>{                           //timer
        this.showSpinner = false;
   }, 1000);
     });


     value.activityVideoLinkList.forEach((val : any)=>{
    this.url =  val.actVideoLink;
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    val.urlSafe = this.urlSafe

  

  });
   });
   
   let count = 0;
   this.activityPlanners.forEach((value2)=>{
    count++;  
     if(value2.type === "activityPlanner"){
      this.activityPlanners2.push(value2);
    }
  })

  if(count === this.activityPlanners.length){
    if(this.activityPlanners2.length === 0 || this.activityPlanners2 === undefined){
      this.apDontExist = true;
    }
  }
 });

  }


 





goToHomePage(){
  this.router.navigate(['/dashboard/teacher']);
}

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      userId : this.userId,
      currentRole : this.currentRole
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
 

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }
  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
  goBack() {
    this.router.navigate(['/dashboard/student']).then();
  }
  reloadPage() {
    window.location.reload();
  }
}
