import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Result } from 'src/app/courseManagementClasses/combinedParentTeacher/result';
import { ResultService } from 'src/app/service/result.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-view-student-results-modal',
  templateUrl: './view-student-results-modal.component.html',
  styleUrls: ['./view-student-results-modal.component.scss']
})
export class ViewStudentResultsModalComponent implements OnInit {
  showSpinner = true;

  resultExists : boolean = true;
  showResults = false;

  details : any ={
    dob: "", 
    email: "",
    phone: "",
    studentImage: "",
    studentName: ""
  }
  safeImageUrlS: any;
  unsafeImageUrlS: any;

  studentId!: string;
  parentUserId!: string;
  subjectId!: string;

  resultList : Result[] = [];
 
  panelOpenState = false;

  datae: any[] = [];  

  resultListWithChartData : any[] = [{

    resultId: '',
    fkStudentId: '',
    fkSubjectId: '',
    fkSkillId: '',
    skillScore: '',
    resultStatus: '',
    resultHistory: '',
    resultSuggestion: '',
  
      skillName: '',
    parentSkillName: '',

    lineChartData :[{ 
      data: [1,2,2,1,2,2,1], 
      label : 'progress'
    }]
}]

  // lineChartData: ChartDataSets[] = [
  //   { data: [7, 8, 8, 5.5, 6, 8], label : 'progress'},
  // ];
  lineChartData: any[] = [
    { data: [8, 7, 9, 8, 7, 8], label : 'progress'},
  ];
  

  lineChartLabels: Label[] = ['', '', '', '', '', ''];

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType : ChartType = 'line';

  lineChartOptions = {
    
    legend: {
      onClick: (e: { stopPropagation: () => any; }) => e.stopPropagation()
  },
 
    elements: {
      line: {
          tension: 0
      }
  },
    responsive: true,
    scales: {
        xAxes: [{
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
               display: false
            }
        }],
        yAxes: [{
          
            display: false
       ,
            gridLines: {
              drawBorder: false,
              display: false,
                color: "rgba(0, 0, 0, 0)",
                
            }   
        }]
    }
}
  loggedInUser: any;
  loggedUser: any;
  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  safeImageUrl: any;
  unsafeImageUrl: any;
 
  constructor(public activeModal: NgbActiveModal,
              private resultService : ResultService,
              private sharedDataService : SharedDataService
              ) { }
              theme: any;
  ngOnInit(): void {

    this.studentId = this.fromParent.studentId;
    this.subjectId = this.fromParent.subjectId;
    this.details. studentName = this.fromParent.name;
    this.safeImageUrl = this.fromParent.image;


    // authorized
this.resultService.getResultsByStudentId(environment.apiUrl + "combinedPTC/getResultsByStudentId/" + this.studentId).then(
    data=>{              
        this.resultList = data;
        this.resultListWithChartData = data;

        this.resultListWithChartData.forEach((value)=>{

          var myArray = value.resultHistory.split(",");
  for(var i=0; i<myArray.length; i++) { this.datae[i] = +myArray[i]; } 
    
          value.lineChartData = [
            { data: this.datae,
               label : 'progress'},
          ];
          this.datae = [];
        });

        if (this.resultList === undefined || this.resultList.length == 0) {
          this.resultExists = false;
      }else {
        this.showResults = true;
      }

      });

  
      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  closeModal(){
    this.activeModal.close(); 
  }

}
