// combined parent teacher activity assignment
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityQuestions } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-questions';
import { ActivityScore } from 'src/app/courseManagementClasses/combinedParentTeacher/activity-score';
import { AddActActualActScore } from 'src/app/courseManagementClasses/combinedParentTeacher/add-act-actual-act-score';
import { GetCombinedPTCDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-combined-ptc-details';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-activity-feedback-questions-modal',
  templateUrl: './activity-feedback-questions-modal.component.html',
  styleUrls: ['./activity-feedback-questions-modal.component.scss']
})
export class ActivityFeedbackQuestionsModalComponent implements OnInit {

  clicked=false
  actQuestionsExists : boolean = false;
  actQuestionsDoesntExists = false
  safeImageUrl : any;
  studentName : any;
  actQuestions : ActivityQuestions[] = [];

  activityScore : ActivityScore = new ActivityScore();
  activityScoreList : ActivityScore[] = [];
 
  activityPlannerId! : string;

  inputType : any;
  questionOrder : any;

  combinedPTCDetails: GetCombinedPTCDetails = new GetCombinedPTCDetails;

  fkStudentId!: string;
  fkTeacherId!: string;

  addActActualActScore : AddActActualActScore = new AddActActualActScore();

  isEditable = false;
  stepper: any; 

  NoOfQsnsAnswered = 0;

  @Input() fromParent: any; 
  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 
 
  constructor(private combinedParentTeacherService : CombinedParentTeacherService,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService,private ngbModalService: NgbModal,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any; 

  ngOnInit(): void {
 
    this.activityPlannerId = this.fromParent.activityPlannerId;
    this.fkStudentId = this.fromParent.fkStudentId;
    this.fkTeacherId = this.fromParent.fkTeacherId;

    this.safeImageUrl = this.fromParent.safeImageUrl;
    this.studentName = this.fromParent.studentName;
    
// authorized
    this.combinedParentTeacherService.getActivityQuestionListByActPlnrId(environment.apiUrl + "activityQuestion/getByActPlnrId/" + this.activityPlannerId).then(
      data=>{
        this.actQuestions=data;

        if (this.actQuestions === undefined || this.actQuestions.length == 0) {
          this.actQuestionsDoesntExists = true;
        }else{
        this.actQuestionsExists = true;
        if(this.fromParent.childPetname !== null){
          this.actQuestions.forEach((que)=>{
            que.activityQuestion = que.activityQuestion.split('the child').join(this.fromParent.childPetname);
          });
        }
        
      }

        this.actQuestions.forEach((question) =>{
            if(question.activityQuestionType=='R'){
              this.inputType = 'Radio';
              this.questionOrder = question.activityQuestionOrder;
            }
        }); 

      });

     
      // this.combinedPTCDetails = this.combinedParentTeacherService.getLocalCombinedPTCDetails();
      // this.fkTeacherId = this.combinedPTCDetails.teacherId;

      this.addActActualActScore.fkTeacherId = this.fkTeacherId;
      this.addActActualActScore.fkActivityPlannerId = this.activityPlannerId;
   
        this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  closeResult!:string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  closeUpdateModal(){
      this.activeModal.close();
  }

  addToArray(activityQuestionId : string){

    this.NoOfQsnsAnswered = this.NoOfQsnsAnswered + 1;

    this.activityScore.fkActivityQuestionId = activityQuestionId;

    this.activityScore.fkStudentId = this.fkStudentId;
    
    if(this.activityScore.activityScoreValue == '1'){
      this.activityScore.activityScoreName = "Yes";
    } else {
      this.activityScore.activityScoreName = "No";
    }

    this.activityScoreList.push(this.activityScore);

    this.activityScore = new ActivityScore();

    this.stepper.selected.completed = true;
    this.stepper.next();
    

  }

  onSubmit(){
   
    this.addActActualActScore.fkStudentId = this.fkStudentId;
    this.addActActualActScore.activityScoreList = this.activityScoreList;

    // FOR PARENT-MENTOR
    this.combinedParentTeacherService.addPtcActActualActScore(environment.apiUrl + 'combinedPTC/addActActualActScore', this.addActActualActScore).then(
      data=>{
        this.passEntry.emit("complete");
        this.activeModal.close();
        this.toaster.success('Activity Completed Successfully');
      });
    
  }

  Change(evt: any) {
    this.activityScore.activityScoreName = evt;
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
}
