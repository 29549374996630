<div [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Preview</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">

    <mat-spinner style="margin:0 auto;" *ngIf="mediaLoaded===false"></mat-spinner>

<div *ngIf="mediaLoaded===true">

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="botMedia.mediaType === 'png' || botMedia.mediaType === 'svg' || botMedia.mediaType === 'jpg' || botMedia.mediaType === 'jpeg'">
          <a style="text-align: center;">
            <img [src]="safeImageUrl" height="200" width="200">
          </a>
        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="botMedia.mediaType === 'mp4' || botMedia.mediaType === 'mp3'">
          <a style="text-align: center;">
            
           <video *ngIf="playVideo" muted autoplay width="320" height="240" controls>
            <source [src]="urlSafe" type="video/mp4">
            <source [src]="urlSafe" type="video/ogg">
          </video>

          </a>
        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="botMedia.mediaType === 'pdf'">
        

          <a [href]="pdfFile" id="link" style="color: blue;"
          download="file.pdf">Click here to Download File for Preview</a> 

        </div>

        <div class="col-md-12" style="text-align: center;" 
        *ngIf="botMedia.mediaType === 'txt'">
          
          <a style="color : blue" [href]="txtFile" download="file.txt">Click here to Download File for Preview</a>

          <!-- <iframe [src]='txtFile' width="100%" height="1000px"></iframe> -->

        </div>
   
        <div class="col-md-12" style="text-align: center;" 
        *ngIf="botMedia.mediaType === 'xls' || botMedia.mediaType === 'xlsx'">
          <a style="color : blue" [href]="txtFile" download="file.xls">Click here to Download File for Preview</a>

          <!-- <iframe [src]='txtFile' width="100%" height="1000px"></iframe> -->

        </div>
   
   </div>
     
   </div>
   
   <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary float-right" (click)="open(reUploadWarning);">Re-upload</button>
  </div>


  <ng-template #reUploadWarning let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
        Are you sure to reupload media ?
      </h3>

      Reuploading media will affect all the places this media is linked, example : conversations, conversation flows, templates etc.  <br> 
      In the above mentioned places, old media will be replaced with new media.<br> <br>

      Please make sure to reupload same type of media to avoid errors (Reupload image for image, video for video etc)

      <br>


      <button style="float: right;width: auto;margin: 0.5%;" (click)="reuploadBotMedia();modal.dismiss('Cross click');"
      mat-raised-button>Reupload</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click');" mat-raised-button>Cancel</button>
      
    </div> 
   
  </ng-template>