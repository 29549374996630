


   <!-- MODAL CLOSE BUTTON -->
 <div [style.background-color]='getColor()' class="modal-header">
  <h4 style="color: white;" class="modal-title pull-left">Create Invoice</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
 <!-- MODAL CLOSE BUTTON -->





   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #invoiceForm="ngForm"> 
            <!-- <div>
                <label hidden for="invoiceId">invoiceId</label>
                <input hidden type="text" id="invoiceId" name="invoiceId" [(ngModel)]="invoice.invoiceId">
            </div>
            
            <div>
              <label hidden for="fkCustomerId0">fkCustomerId</label>
              <input hidden readonly type="text" id="fkCustomerId0" name="fkCustomerId0" [(ngModel)]="invoice.fkCustomerId">
          </div> -->

            <div class="form-group row">
                <div class="col-md-4">
                      <label class="required" for="fkCustomerId">Customer</label> <br>
                      <select #customer="ngModel" class="form-control" required  [(ngModel)]="invoice.fkCustomerId" id="fkCustomerId" name="fkCustomerId">
                     <option value="" disabled>Choose Customer</option>
                        <option *ngFor="let customer of customers"  [ngValue]="customer.customerId">{{customer.firstName}} {{customer.lastName}}</option>
                      </select>
                      <div class="alert alert-danger" *ngIf="customer.touched && !customer.valid">Select Customer</div>
                </div>
              

                  <div class="col-md-4">
                  <label class="required" for="invoiceNo">Invoice No</label>
                  <input #no="ngModel" required class="form-control" type="number" id="invoiceNo" name="invoiceNo" [(ngModel)]="invoice.invoiceNo">
                  <div class="alert alert-danger" *ngIf="no.touched && !no.valid">Invoice No is required</div>
                </div>

                <div class="col-md-4">
                  <label class="required" for="invoiceDate">Invoice Date</label>
                  <input #date="ngModel" required class="form-control"  type="date" id="invoiceDate" name="invoiceDate" [(ngModel)]="invoice.invoiceDate">
                  <div class="alert alert-danger" *ngIf="date.touched && !date.valid">Date is required</div>
                </div>
            </div>


            

            <div class="form-group row">
           
              <div class="col-md-4">
                <label class="required" for="subscriptionFromDate">Subscription From-Date</label>
                <input #subscriptionFromDate="ngModel" required class="form-control" 
                 type="date" id="subscriptionFromDate" name="subscriptionFromDate" [(ngModel)]="invoice.subscriptionFromDate">
                <div class="alert alert-danger" *ngIf="subscriptionFromDate.touched && !subscriptionFromDate.valid">From Date is required</div>
              </div>
      
                <div class="col-md-4">
                <label  class="required" for="subscriptionToDate" >Subscription To-Date</label>
                <input #subscriptionToDate="ngModel" required class="form-control"  type="date" 
                id="subscriptionToDate" name="subscriptionToDate" [(ngModel)]="invoice.subscriptionToDate">
                <div class="alert alert-danger" *ngIf="subscriptionToDate.touched && !subscriptionToDate.valid">To Date is required</div>
                 </div>

              <div class="col-md-4">
              <label for="paymentMethod" >Payment Method</label>
              <input  class="form-control" type="text" id="paymentMethod" name="paymentMethod" [(ngModel)]="invoice.paymentMethod">
              </div>
            </div>


            

            

            

            <div>
                <label hidden for="invoiceStatus">Invoice Status</label>
                <input hidden type="text" id="invoiceStatus" name="invoiceStatus" [(ngModel)]="invoice.invoiceStatus">
            </div>



             <div >
                <table  border="1">
                    <thead>
                        <tr>
                            <th  [style.background-color]='getColor()' >Item Name</th>
                            <th  [style.background-color]='getColor()' >Quantity</th>
                            <th  [style.background-color]='getColor()' >Price per Item</th>
                            <th  [style.background-color]='getColor()' >Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor = "let invDet of invoiceDetailsList">
                            <td>{{invDet.itemName}}</td>
                            <td style="text-align: right;">{{invDet.quantity}}</td>
                            <td style="text-align: right;">{{invDet.pricePerItem}}</td>
                            <td style="text-align: right;">{{invDet.totalPrice}}</td>
                        </tr>
                        <tr>
                           
                        </tr>
                        <tr *ngIf="invoiceDetailsList !== undefined && invoiceDetailsList.length !== 0">
                          <td style="text-align: right;"></td>
                          <td style="text-align: right;"></td>
                          <td style="text-align: right;font-weight: bold;">Product Sub-Total </td>
                          <td style="text-align: right;font-weight: bold;">{{invoice.subTotalAmount}}</td>

                        </tr>
                        <tr *ngIf="discounted === true">
                          <td></td>
                          <td></td>
                          <td style="font-weight: bold; text-align: right;">Discount</td>
                          <td style="font-weight: bold; text-align: right;">{{discount}}</td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td style="font-weight: bold; text-align: right;">Product Total</td>
                          <td style="font-weight: bold; text-align: right;">{{invoice.totalAmount}}</td>
                      </tr>
                      <tr *ngFor = "let invDet of invoice.taxesAndCess">
                          <td *ngIf="invDet.taxType === 'tax'"></td>
                          <td *ngIf="invDet.taxType === 'tax'"></td>
                          <td *ngIf="invDet.taxType === 'tax'" style="text-align: right;">{{invDet.taxName}}</td>
                          <td *ngIf="invDet.taxType === 'tax'" style="text-align: right;">{{invDet.taxAmount}}</td>
                         
 
                      </tr>
                      <tr *ngFor = "let invDet of invoice.taxesAndCess">
                          <td *ngIf="invDet.taxType === 'cess'"></td>
                          <td *ngIf="invDet.taxType === 'cess'"></td>
                          <td *ngIf="invDet.taxType === 'cess'" style="text-align: right;">{{invDet.taxName}}</td>
                          <td *ngIf="invDet.taxType === 'cess'" style="text-align: right;">{{invDet.taxAmount}}</td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>                        
                          <td style="font-weight: bold; text-align: right;">Total Tax</td>
                          <td style="font-weight: bold; text-align: right;">{{invoice.totalTax}}</td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>
                          <td style="font-weight: bold; text-align: right;">Total Cess</td>
                          <td style="font-weight: bold; text-align: right;">{{invoice.totalCess}}</td>
                      </tr>
                      <tr>
                          <td></td>
                          <td></td>                         
                          <td style="font-weight: bold; text-align: right;">Grand Total</td>
                          <td style="font-weight: bold; text-align: right;">{{invoice.grandTotal}}</td>
                      </tr>
                    </tbody>
            </table>
        </div> <br>

        
        
        
         

        <a style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
        (click)="addInvoiceDetails()">
        <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
      </a> 
      <br>
      <br>

    
      <a style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
         (click)="open(addDiscount)" matTooltip="Add Discount">
         <img src="/assets/images/icons/discount.png" width="25" height="25" alt="" loading="lazy">
       </a>
       
       <br>
     <br>
     
     
       <a style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
           (click)="recomputeTaxes()" matTooltip="Compute tax">
           <img src="/assets/images/icons/tax.svg" width="28" height="28" alt="" loading="lazy">
         </a>
     
         <br>
     
     <br>

      <!-- <div class="form-group row">
        <div class="col-md-6">

        </div>

          <div style="display:flex; flex-direction: row; justify-content: center; align-items: center" class="col-md-6">
          <label style="font-size: 18px;" for="totalAmount">Total:</label>
          <input style="text-align: right;" readonly class="form-control"  type="number" id="totalAmount" name="totalAmount" [(ngModel)]="invoice.totalAmount">
          
          </div>
      </div>  -->
 
        <div>
        <label for="invoiceRemarks">Invoice Remarks</label>
        <textarea  #remarks="ngModel" maxlength="244" class="form-control" maxlength="244" type="text" id="invoiceRemarks" name="invoiceRemarks" [(ngModel)]="invoice.invoiceRemarks"></textarea>
        <div class="alert alert-danger" *ngIf="remarks.touched && !remarks.valid">Max length exceeded</div>
        </div>
           
    </form>
    </div>
    </div>
    </div>

    <div class="modal-footer">
      <button  [disabled]="clicked"  [disabled]="!invoiceForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit();">Save</button>
    </div>

    <ng-template #addDiscount let-modal>
      <div  [style.background-color]='getColor()' class="modal-header">
          <h4 *ngIf="discounted === false" class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Add Discount</h4>
          <h4 *ngIf="discounted === true"  class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Edit Discount</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label>Enter Dicount Amount :</label>
        <input class="form-control" type="number" id="discount" 
        name="discount" [(ngModel)]="discount">
  
  
      </div> 
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button *ngIf="discounted === true" type="button" class="btn btn-success" style="width:auto;" (click)="editDiscount();modal.dismiss('Cross click')">Edit</button>
        <button *ngIf="discounted === false" type="button" class="btn btn-success" style="width:auto;" (click)="addDiscountToInvoice();modal.dismiss('Cross click')">Add</button>
      </div>
    </ng-template>