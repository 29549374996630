// THIS DASHBOARD SHOWS ALL AVAILABLE PRODUCTS/COURSES || THIS DASHBOARD SHOWS ALL AVAILABLE PRODUCTS/COURSES || THIS DASHBOARD SHOWS ALL AVAILABLE PRODUCTS/COURSES


import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddProductComponent } from 'src/app/crm/product/add-product/add-product.component';
import { UpdateProductComponent } from 'src/app/crm/product/update-product/update-product.component';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-curriculum-dashboard',
  templateUrl: './curriculum-dashboard.component.html',
  styleUrls: ['./curriculum-dashboard.component.scss']
})
export class CurriculumDashboardComponent implements OnInit {
  productsExist = false;
  currentRole = "COURSE_ADMIN";
  gridColumns = 4; 
  showSpinner : boolean = true;
  productList: Product[] = [];
  officeId! : string;
  userName!: string;
  loggedInUser : any;
  userId!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };
  navbarTitle : any;
  constructor(private productService : ProductService,
              private sharedDataService: SharedDataService,
              private ngbModalService: NgbModal,
              private imageService: FileServiceService,
              private sanitizer : DomSanitizer,
              private router : Router ) { }

  ngOnInit(): void { 

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;
    this.getProductList();

  }

  abc : any;
  xyz : any;

  // authorized
  getProductList(){
  
    this.productService.getProductListByOfficeIdForCourseAdmin(environment.apiUrl + "crmproduct/getProductListByOfficeIdForCourseAdminAuth").then(
      data => {
        this.productList = data;
        this.productList.forEach((prod)=>{
          this.imageService.getProductImage(prod.productImage).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              prod.safeUrl = this.xyz;

            });
        })
        this.productsExist = true;
      });
      setTimeout(()=>{                           
        this.showSpinner = false;
    }, 1000);
      
  }

   // authorized
  addNewProduct(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddProductComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
      keyboard: false
      });
let data = {
  officeId: this.officeId,
  officeType : "EDU"
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {
this.showSpinner=true;
this.getProductList();
    }); 
  }
  
  
  // authorized
  editProduct(productId: string){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateProductComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        
      keyboard: false
      });
      

let data = {
  productId: productId ,
  officeId: this.loggedInUser.offices[0].id,
  officeType : "EDU"
    }

modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Product) => {
      this.showSpinner = true;
      this.getProductList();

    }); 
  } 

  exploreProduct(productId : string){
    this.router.navigate(['subjectDashboard',productId]);
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  reloadPage() {
    window.location.reload();
  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }
}
