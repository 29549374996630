import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/courseManagementClasses/role';
import { RoleService } from 'src/app/service/courseManagementServices/role.service';
import { UserSessionService } from 'src/app/service/user-session.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-options-modal',
  templateUrl: './options-modal.component.html',
  styleUrls: ['./options-modal.component.scss']
})
export class OptionsModalComponent implements OnInit {
  userId!: string;
  roles : Role[] = [];
  rolesFiltered : Role[] = [];
  loggedInUser0: any;
  loggedInUser: any;
  loggedInUserParsed : any;
  currentRole! : string;
  theme: any;

  displayUrls : any[] = [];

  constructor(public activeModal: NgbActiveModal,
              private router : Router,
              private sharedDataService : SharedDataService,
              private userSessionService : UserSessionService,
              private roleService : RoleService ) { }

  @Input() fromParent: any;

  @Output() public passEntry : EventEmitter<any> = new EventEmitter(); 

  ngOnInit(): void {

    this.loggedInUser0 = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.userId = this.fromParent.userId;
    this.currentRole = this.fromParent.currentRole;


    this.loggedInUser0.displayUrls.forEach((val : any)=>{
      if(val.roleName === this.currentRole && val.groupName === "order"){
        this.displayUrls.push(val);
      }
    });

  }

  switchRole(displayFeature : any){

    this.passEntry.emit(displayFeature);
    this.activeModal.close();

  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser0.offices[0].theme);
    return this.loggedInUser0.offices[0].theme + '!important';
  }

  closeUpdateModal(){
    this.activeModal.close();
  }  

}
