import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Result } from 'src/app/courseManagementClasses/result';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { ResultServiceService } from 'src/app/service/result-service.service';
import { environment } from 'src/environments/environment';
import { AddResultsModalComponent } from '../add-results-modal/add-results-modal.component';
import { EditResultsModalComponent } from '../edit-results-modal/edit-results-modal.component';
import { StudentSelectionModalComponent } from '../student-selection-modal/student-selection-modal.component';

@Component({
  selector: 'app-super-skill-report-dashboard',
  templateUrl: './super-skill-report-dashboard.component.html',
  styleUrls: ['./super-skill-report-dashboard.component.scss']
})
export class SuperSkillReportDashboardComponent implements OnInit {

  userName : any;
  loggedInUser: any;
  loggedUser: any;
  resultOptions: any;
  results : Result[] = [];
  navbarTitle: any;
  resultsExist = true;

  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private toaster: ToastrService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private logoutService : LogoutService,
              private resultService : ResultServiceService) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    idObject :any = {
      studentId : '',
      fkUserRoleOffice : '',
      subjectId : '',
      selectedStudentName : '',
      selectedSubjectName : ''
    }

    customerOptions : any;
    unsafeUrl : any;
    safeUrl: any;

  public screenWidth: any;
  public screenHeight: any;
 
  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.resultsExist = true;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.customerOptions  = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    };

  }

  editResult(result : Result){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(EditResultsModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      result : result
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      // this.idObject = receivedEntry;
      this.getResults(this.idObject.studentId , this.idObject.subjectId);
    });  
  }

  updateResultStatus(id : any){

    this.resultService.updateResultStatus(environment.apiUrl + "result/statusUpdate/" + id).then(
      res=>{
        this.results.forEach((value)=>{
          if(value.resultId === id){
            if(value.resultStatus === true){
              value.resultStatus = false;
            }else if(value.resultStatus === false){
              value.resultStatus =true;
            }
          }
        });
       
      });
  }

  openAddResultModal(){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddResultsModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {

      studentId : this.idObject.studentId,
      subjectId : this.idObject.subjectId,
      selectedStudentName : this.idObject.selectedStudentName,
      selectedSubjectName : this.idObject.selectedSubjectName
      
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      // this.idObject = receivedEntry;
      this.getResults(this.idObject.studentId , this.idObject.subjectId);
    });  
  }

  closeResult !:string;
  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openStudentSelectionModal(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(StudentSelectionModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
      this.idObject = receivedEntry;
      this.getResults(this.idObject.studentId  , this.idObject.subjectId);
    });  
    
  }
  
  getResults(studentId : string, subjectId : string){
    this.resultService.findResultsByStudentIdRA(studentId).subscribe(
      data=>{
        this.results = data;
        this.toaster.success('Results fetched successfully')
        this.resultsExist = true;


        this.results.forEach((value)=>{

          this.imageService.getSkillImage( value.skillImage ).subscribe(
            data=>{
              this.unsafeUrl = URL.createObjectURL(data);
              this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
  
              value.safeUrl = this.safeUrl;
            });
        })
      });
      }
      
      logOut(): void {

        localStorage.removeItem('auth-token');
        localStorage.clear();
        window.location.href=`${environment.authUrl}/logout`;
        // this.logoutService.deleteUserSession(environment.apiUrl + "user/destroySession/" + this.loggedInUser.username).then(data=>{
        // })
        // this.router.navigate(['/auth/login']).then();  
          
      }

  changePassword(): void {
    this.router.navigate(['/auth/change-password']).then();
  }

  reloadPage() {
    window.location.reload();
  }

}
