
  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Leads</li>
    </ol>
  </nav>

  <ng-template #content let-modal>

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Convert to Customer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
    <div>
      <label class="required" for="customerId">Lead Name</label> <br>
      <select class="form-control" required id="customerId" [(ngModel)]="customerId" name="customerId">
          <option   *ngFor="let ccd of ccdetails"  [ngValue]="ccd.customerId">{{ccd.firstName}} {{ccd.lastName}}</option>
        </select>
    </div>
</div>


<div class="modal-footer">
  <button type="submit" class="btn btn-sm btn-primary float-right" (click)="changeToCustomer(customerId)">Change</button>
</div>
  </ng-template>
 
  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
  
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
        <mat-card  class="example-card mat-elevation-z12" >
          <div class="card-icon" >
            <a> 
              <img src="/assets/images/icons/followUpDue.png" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>
           <br>
           <mat-card-title style="margin-bottom: 6%;">Follow-Up Due</mat-card-title>
           <mat-card-title class="click" (click)="getLeadsToBeContacted();">{{leadsToContact}}</mat-card-title>
       <!-- <mat-card-subtitle>({{overdueAmt}})</mat-card-subtitle> -->
      </mat-card>
      
           
          </div>

      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
    <mat-card  class="example-card mat-elevation-z12" >
      <div class="card-icon" >
        <a> 
          <img src="/assets/images/icons/totalDue.png" width="65" height="65"
           alt="" loading="lazy">
        </a>
      </div>
       <br>
       <mat-card-title style="margin-bottom: 6%;">Active Leads</mat-card-title>
       <mat-card-title class="click" (click)="getCcDetails();">{{activeLeads}}</mat-card-title>
        <!-- <mat-card-subtitle>({{totalDueAmt}})</mat-card-subtitle> -->
    
  </mat-card>
  
       
      </div>
  
      <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
        <mat-card  class="example-card mat-elevation-z12" >
       
          <div class="card-icon" >
            <a> 
              <img src="/assets/images/icons/hotLead.png" width="65" height="65"
               alt="" loading="lazy">
            </a>
          </div>
           <br>
           <mat-card-title style="margin-bottom: 6%;">Hot</mat-card-title>
           <mat-card-title class="click" (click)="getHotLeads();">{{hotLeads}}</mat-card-title>
           <!-- <mat-card-subtitle>({{dueInOneWeekAmt}})</mat-card-subtitle> -->
  
      </mat-card>
      
           
          </div>
      
          <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
            <mat-card  class="example-card mat-elevation-z12" >
           
              <div class="card-icon" >
                <a> 
                  <img src="/assets/images/icons/dueToday.png" width="65" height="65"
                   alt="" loading="lazy">
                </a>
              </div>
               <br>
               <mat-card-title style="margin-bottom: 6%;">Cold</mat-card-title>
               <mat-card-title class="click" (click)="getColdLeads();">{{coldLeads}}</mat-card-title>
               <!-- <mat-card-subtitle>({{dueTodayAmt}})</mat-card-subtitle> -->
            
          </mat-card>
          
               
              </div>
        
              <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="100%" >
  
        
                <mat-card  class="example-card mat-elevation-z12" >
               
                  <div class="card-icon" >
                    <a> 
                      <img src="/assets/images/icons/discarded.png" width="65" height="65"
                       alt="" loading="lazy">
                    </a>
                  </div>
                   <br>
                   <mat-card-title style="margin-bottom: 6%;">Discarded</mat-card-title>
                   <mat-card-title class="click" (click)="getDiscardedLeads();">{{discarded}}</mat-card-title>
               <!-- <mat-card-subtitle>({{overdueAmt}})</mat-card-subtitle> -->
                
              </mat-card>
              
                   
                  </div>
                    

    </div>
    </div>




  <div *ngIf="status === 'Leads to be contacted'" class="p-4">
   
     
        <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              Follow-Up Due
            </span>

            <!-- <button hidden style="margin-left: 56rem;" (click)="open(content)" mat-flat-button color="primary">Convert to Customer</button> -->

            <a style="cursor:pointer;" class="float-right" (click)="openEmailPhoneCheckModalComponent();">
              <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
            </a>

            <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right"
             (click)="exportLeadsToBeContacted()">
              <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
            </a>

          </div> 
        </div>

        <div class="card">
          <div class="card-body table-responsive">
            
            <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="customerOptions" >
              <thead class="tableheader">
                <tr>
                  <th style="text-align: left;">Name</th> 
                  <th style="text-align: left;">Email Id</th>
                  <th>Phone</th> <th>Created On</th>
                  <th>Due on</th>
                  <th>Due Status</th>
                  <th>Action</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of leadsToBeContacted">
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.firstName}} {{ccd.lastName}}</td>
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.email}}</td>
                  <td class="td-height">{{ccd.phoneNumber}}</td> <td class="td-height">{{ccd.leadCreationDate}}</td>
                  <td class="td-height">{{ccd.nextActionDateTime}}</td>
                  <td class="td-height" style="font-weight: bold;color: orange;" *ngIf="ccd.followUpOverdue==='Due Today'">Due Today</td>
                  <td class="td-height" style="font-weight: bold;color: red;" *ngIf="ccd.followUpOverdue==='Overdue'"> Overdue</td>
                  <td class="td-height" style="font-weight: bold;color: black;" *ngIf="ccd.followUpOverdue==='Upcoming'">Upcoming</td>
                  <td class="td-height" style=" height: 2rem;">
                    
                    <button (click)="editCustomer(ccd.customerId)" class="btn btn-info">Edit</button> 
                    <button (click)="open(confirm)"  class="btn btn-secondary">Convert</button>
                    <button (click)="open(Discard)" class="btn btn-danger">Discard</button>
                    <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">Communication</button> <br>

                  </td>
                 
                  <ng-template #comm let-modal>
                    <div [ngStyle]="backgroundStyle" class="modal-header">
                      <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Customer Communication : {{ccd.firstName}} {{ccd.lastName}}</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <button (click)="addNewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-success">Add Communication</button>
                      <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">View Communication</button> 
                    </div> 
                  </ng-template> 
                  <ng-template #Discard let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Are you sure to Discard Lead ?
<br>
                      <div>
                        <label class="required" for="description">Reason / Remarks </label>
                        <textarea maxlength="244" class="form-control" type="text" id="Reason" name="Reason"
                         [(ngModel)]="discardReason"></textarea>
                       
                        </div>
                    </div> 

                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                      <button type="button" class="btn btn-success"
                       (click)="discardLead(ccd.customerId);modal.dismiss('Cross click')">Discard</button>
                    </div>
                  </ng-template>           
                                  
                  <ng-template #confirmActivation let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Are you sure to activate Lead ?
                    </div> 
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                      <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Activate</button>
                    </div>
                  </ng-template> 
                  
                  
                  <ng-template #confirmDeactivation let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      Are you sure to deactivate Lead ?
                    </div> 
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                      <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Deactivate</button>
                    </div>
                  </ng-template>

                <ng-template #confirm let-modal>
                  
                  <div class="modal-header">
                    <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div style="color: black;" class="modal-body">
                    Are you sure to convert  "{{ccd.firstName}} {{ccd.lastName}} " to customer ?
                  </div> 
                
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button type="button" class="btn btn-success"  (click)="convertToCustomer(ccd.customerId, ccd.email, ccd.phoneNumber);modal.dismiss('Cross click')">Convert</button>
                  </div>
                  
                </ng-template>

              </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>

  <div *ngIf="status === 'All Leads'" class="p-4">
   
     
    <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">
        <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
         Active Leads
        </span>

        <button hidden style="margin-left: 56rem;" (click)="open(content)" mat-flat-button color="primary">Convert to Customer</button>

        <a style="cursor:pointer;" class="float-right" (click)="openEmailPhoneCheckModalComponent();">
          <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
        </a>

        <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right"
        (click)="exportCcdetails()">
         <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
       </a>

      </div> 
    </div>

    <div class="card">
      <div class="card-body table-responsive">
        
        <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="customerOptions" >
          <thead class="tableheader">
            <tr>
              <th style="text-align: left;">Name</th> 
              <th style="text-align: left;">Email Id</th>
              <th>Phone</th> <th>Created On</th>
              <th>Action</th>
             
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of ccdetails">
              <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.firstName}} {{ccd.lastName}}</td>
              <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.email}}</td>
              <td class="td-height">{{ccd.phoneNumber}}</td> <td class="td-height">{{ccd.leadCreationDate}}</td>
              <!-- <td class="td-height">{{ccd.nextActionDateTime}}</td> -->
              <td class="td-height">
                <!-- <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
                class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
              <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
                class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button> -->
             

                <button (click)="editCustomer(ccd.customerId)" class="btn btn-info">Edit</button> 
                <button (click)="open(confirm)"  class="btn btn-secondary">Convert</button>
                <button (click)="open(Discard)" class="btn btn-danger">Discard</button>
                <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">Communication</button> <br>

              </td>

              <ng-template #comm let-modal>
                <div [ngStyle]="backgroundStyle" class="modal-header">
                  <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Customer Communication : {{ccd.firstName}} {{ccd.lastName}}</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <button (click)="addNewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-success">Add Communication</button>
                  <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">View Communication</button> 
                </div> 
              </ng-template> 
             
              <ng-template #Discard let-modal>
                <div class="modal-header">
                  <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure to Discard Lead ?
<br>
                  <div>
                    <label class="required" for="description">Reason / Remarks </label>
                    <textarea maxlength="244" class="form-control" type="text" id="Reason" name="Reason"
                     [(ngModel)]="discardReason"></textarea>
                   
                    </div>
                </div> 

                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                  <button type="button" class="btn btn-success"
                   (click)="discardLead(ccd.customerId);modal.dismiss('Cross click')">Discard</button>
                </div>
              </ng-template>           
                              
              <ng-template #confirmActivation let-modal>
                <div class="modal-header">
                  <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure to activate Lead ?
                </div> 
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                  <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Activate</button>
                </div>
              </ng-template> 
              
              
              <ng-template #confirmDeactivation let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure to deactivate Lead ?
                </div> 
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                  <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Deactivate</button>
                </div>
              </ng-template>
           

      

            <ng-template #confirm let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to convert  "{{ccd.firstName}} {{ccd.lastName}} " to customer ?
              </div> 
            
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success"  (click)="convertToCustomer(ccd.customerId, ccd.email, ccd.phoneNumber);modal.dismiss('Cross click')">Convert</button>
              </div>
              
            </ng-template>

          </tr>
          </tbody>
        </table>

      </div>
    </div>
  

</div>
  
  
 
<div *ngIf="status === 'Hot Leads'" class="p-4">
   
     
  <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">
      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Hot Leads
      </span>

      <button hidden style="margin-left: 56rem;" (click)="open(content)" mat-flat-button color="primary">Convert to Customer</button>

      <a style="cursor:pointer;" class="float-right" (click)="openEmailPhoneCheckModalComponent();">
        <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
      </a>

      <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right"
      (click)="exportHotLeads()">
       <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
     </a>

    </div> 
  </div>

  <div class="card">
    <div class="card-body table-responsive">
      
      <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="customerOptions" >
        <thead class="tableheader">
          <tr>
            <th style="text-align: left;">Name</th> 
            <th style="text-align: left;">Email Id</th>
            <th>Phone</th> <th>Created On</th>
            <!-- <th>Due on</th> -->
            <th>Action</th>
           
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of hotLeadList">
            <td class="td-height"  style="text-align: left;padding: 10px 18px;">{{ccd.firstName}} {{ccd.lastName}}</td>
            <td class="td-height"  style="text-align: left;padding: 10px 18px;">{{ccd.email}}</td>
            <td class="td-height">{{ccd.phoneNumber}}</td> <td class="td-height">{{ccd.leadCreationDate}}</td>
            <!-- <td class="td-height">{{ccd.nextActionDateTime}}</td> -->
            <td class="td-height" style=" height: 2rem;">
              <!-- <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
              class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
            <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
              class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
            -->

              <button (click)="editCustomer(ccd.customerId)" class="btn btn-info">Edit</button> 
              <button (click)="open(confirm)"  class="btn btn-secondary">Convert</button>
              <button (click)="open(Discard)" class="btn btn-danger">Discard</button>
              <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">Communication</button> <br>

            </td>
            <ng-template #comm let-modal>
              <div [ngStyle]="backgroundStyle" class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Customer Communication : {{ccd.firstName}} {{ccd.lastName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <button (click)="addNewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-success">Add Communication</button>
                <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">View Communication</button> 
              </div> 
            </ng-template> 

            <ng-template #Discard let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to Discard Lead ?
<br>
                <div>
                  <label class="required" for="description">Reason / Remarks </label>
                  <textarea maxlength="244" class="form-control" type="text" id="Reason" name="Reason"
                   [(ngModel)]="discardReason"></textarea>
                 
                  </div>
              </div> 

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success"
                 (click)="discardLead(ccd.customerId);modal.dismiss('Cross click')">Discard</button>
              </div>
            </ng-template>           
                            
            <ng-template #confirmActivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to activate Lead ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Activate</button>
              </div>
            </ng-template> 
            
            
            <ng-template #confirmDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to deactivate Lead ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Deactivate</button>
              </div>
            </ng-template>
         

    

          <ng-template #confirm let-modal>
            <div class="modal-header">
              <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure to convert  "{{ccd.firstName}} {{ccd.lastName}} " to customer ?
            </div> 
          
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
              <button type="button" class="btn btn-success"  (click)="convertToCustomer(ccd.customerId, ccd.email, ccd.phoneNumber);modal.dismiss('Cross click')">Convert</button>
            </div>
            
          </ng-template>

        </tr>
        </tbody>
      </table>

    </div>
  </div>


</div> 
 
 
  
  
<div *ngIf="status === 'Cold Leads'" class="p-4">
   
     
  <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">
      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Cold Leads
      </span>

      <button hidden style="margin-left: 56rem;" (click)="open(content)" mat-flat-button color="primary">Convert to Customer</button>

      <a style="cursor:pointer;" class="float-right" (click)="openEmailPhoneCheckModalComponent();">
        <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
      </a>
      <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right"
      (click)="exportColdLeads()">
       <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
     </a>
    </div> 
  </div>

  <div class="card">
    <div class="card-body table-responsive">
      
      <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="customerOptions" >
        <thead class="tableheader">
          <tr>
            <th style="text-align: left;">Name</th> 
            <th style="text-align: left;">Email Id</th>
            <th>Phone</th> <th>Created On</th>
            <!-- <th>Due on</th> -->
            <th>Action</th>
           
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of coldLeadList">
            <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.firstName}} {{ccd.lastName}}</td>
            <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.email}}</td>
            <td class="td-height">{{ccd.phoneNumber}}</td> <td class="td-height">{{ccd.leadCreationDate}}</td>
            <!-- <td class="td-height">{{ccd.nextActionDateTime}}</td> -->
            <td class="td-height" style=" height: 2rem;">
              <!-- <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
              class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
            <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
              class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
            -->

              <button (click)="editCustomer(ccd.customerId)" class="btn btn-info">Edit</button>
              <button (click)="open(confirm)"  class="btn btn-secondary">Convert</button>
              <button (click)="open(Discard)" class="btn btn-danger">Discard</button>
              <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">Communication</button> <br>

            </td>
           
            <ng-template #comm let-modal>
              <div [ngStyle]="backgroundStyle" class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Customer Communication : {{ccd.firstName}} {{ccd.lastName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <button (click)="addNewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-success">Add Communication</button>
                <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">View Communication</button> 
              </div> 
            </ng-template> 

            <ng-template #Discard let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to Discard Lead ?
<br>
                <div>
                  <label class="required" for="description">Reason / Remarks </label>
                  <textarea maxlength="244" class="form-control" type="text" id="Reason" name="Reason"
                   [(ngModel)]="discardReason"></textarea>
                 
                  </div>
              </div> 

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success"
                 (click)="discardLead(ccd.customerId);modal.dismiss('Cross click')">Discard</button>
              </div>
            </ng-template>           
                            
            <ng-template #confirmActivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to activate Lead ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Activate</button>
              </div>
            </ng-template> 
            
            
            <ng-template #confirmDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to deactivate Lead ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Deactivate</button>
              </div>
            </ng-template>
         

    

          <ng-template #confirm let-modal>
            <div class="modal-header">
              <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure to convert "{{ccd.firstName}} {{ccd.lastName}} " to customer ?
            </div> 
          
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
              <button type="button" class="btn btn-success"  (click)="convertToCustomer(ccd.customerId, ccd.email, ccd.phoneNumber);modal.dismiss('Cross click')">Convert</button>
            </div>
            
          </ng-template>

        </tr>
        </tbody>
      </table>

    </div>
  </div>


</div> 
  

  
  
<div *ngIf="status === 'Discarded Leads'" class="p-4">
   
     
  <div class="table-header row" [ngStyle]="backgroundStyle" style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
    <div class="col-md-12" style="color: rgb(248, 245, 245);">
      <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

      <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
        Discarded Leads
      </span>

      <button hidden style="margin-left: 56rem;" (click)="open(content)" mat-flat-button color="primary">Convert to Customer</button>

      <a style="cursor:pointer;" class="float-right" (click)="openEmailPhoneCheckModalComponent();">
        <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
      </a>

      <a style="cursor:pointer;margin-right: 2%;" matTooltip="Download as excel file" class="float-right"
      (click)="exportDiscardedLeads()">
       <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
     </a>
    </div> 
  </div>

  <div class="card">
    <div class="card-body table-responsive">
      
      <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
      datatable [dtOptions]="customerOptions" >
        <thead class="tableheader">
          <tr>
            <th style="text-align: left;">Name</th> 
            <th style="text-align: left;">Email Id</th>
            <th>Phone</th> <th>Created On</th>
            <!-- <th>Due on</th> -->
            <th>Action</th>
           
          </tr>
        </thead>
        <tbody>
          <tr class="td-height" *ngFor="let ccd of discardedLeadList">
            <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.firstName}} {{ccd.lastName}}</td>
            <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.email}}</td>
            <td class="td-height">{{ccd.phoneNumber}}</td> <td class="td-height">{{ccd.leadCreationDate}}</td>
            <!-- <td class="td-height">{{ccd.nextActionDateTime}}</td> -->
            <td class="td-height" style=" height: 2rem;">
              <!-- <button *ngIf="ccd.activeStatus!== null && ccd.activeStatus" type="button"
              class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
            <button *ngIf="ccd.activeStatus !== null && !ccd.activeStatus" type="button"
              class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button>
            -->

              <!-- <button (click)="editCustomer(ccd.customerId)" class="btn btn-info">Edit</button>
              <button (click)="open(confirm)"  class="btn btn-secondary">Convert</button> -->
              <!-- <button (click)="open(Discard)" class="btn btn-danger">Discard</button> -->
              <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">Communication</button> 

            </td>
           
            <ng-template #comm let-modal>
              <div [ngStyle]="backgroundStyle" class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;color: white;" id="modal-basic-title">Customer Communication : {{ccd.firstName}} {{ccd.lastName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <button (click)="addNewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-success">Add Communication</button>
                <button (click)="viewCustomerCommunication(ccd.customerId)" style="width: auto;" class="btn btn-primary">View Communication</button> 
              </div> 
            </ng-template> 

            <ng-template #Discard let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to Discard Lead ?
<br>
                <div>
                  <label class="required" for="description">Reason / Remarks </label>
                  <textarea maxlength="244" class="form-control" type="text" id="Reason" name="Reason"
                   [(ngModel)]="discardReason"></textarea>
                 
                  </div>
              </div> 

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success"
                 (click)="discardLead(ccd.customerId);modal.dismiss('Cross click')">Discard</button>
              </div>
            </ng-template>           
                            
            <ng-template #confirmActivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to activate Lead ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Activate</button>
              </div>
            </ng-template> 
            
            
            <ng-template #confirmDeactivation let-modal>
              <div class="modal-header">
                <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure to deactivate Lead ?
              </div> 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                <button type="button" class="btn btn-success" (click)="updateCustomerStatus(ccd.customerId);modal.dismiss('Cross click')">Deactivate</button>
              </div>
            </ng-template>
         

    

          <ng-template #confirm let-modal>
            <div class="modal-header">
              <h4 class="modal-title"  style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure to convert  "{{ccd.firstName}} {{ccd.lastName}} " to customer ?
            </div> 
          
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
              <button type="button" class="btn btn-success"  (click)="convertToCustomer(ccd.customerId, ccd.email, ccd.phoneNumber);modal.dismiss('Cross click')">Convert</button>
            </div>
            
          </ng-template>

        </tr>
        </tbody>
      </table>

    </div>
  </div>


</div> 
  