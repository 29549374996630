import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FeedbackForm } from 'src/app/BIZ_CRM_FEEDBACK/classes/feedback-form';
import { FormActive } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-active';
import { FormRespondent } from 'src/app/BIZ_CRM_FEEDBACK/classes/form-respondent';
import { FeedbackFormService } from 'src/app/BIZ_CRM_FEEDBACK/services/feedback-form.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-form-responses',
  templateUrl: './view-form-responses.component.html',
  styleUrls: ['./view-form-responses.component.scss']
})
export class ViewFormResponsesComponent implements OnInit {

  clicked = false;
  loggedInUser : any; 
  theme : any;
  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  formRespondent : FormRespondent = new FormRespondent();

  constructor(public activeModal: NgbActiveModal,
    private router : Router,
    private datePipe : DatePipe,
    private ngbModalService: NgbModal,
    private formService : FeedbackFormService,
    private sharedDataService: SharedDataService,
             private toaster: ToastrService) { }

             config = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-md',
              windowClass: 'app-modal-window'
            };

  ngOnInit(): void { 
    
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();   
    
    this.formRespondent = this.fromParent.formRespondent;
    this.formRespondent.formResponsesDto.forEach((fr)=>{
      if(fr.answerType === "timestamp" && fr.answerValueTimestamp !== null && fr.answerValueTimestamp!== undefined){
        fr.answerValueTimestampFormatted = new Date(fr.answerValueTimestamp[0], fr.answerValueTimestamp[1] - 1, fr.answerValueTimestamp[2], fr.answerValueTimestamp[3], fr.answerValueTimestamp[4]);      
        fr.answerValueTimestampFormatted = this.datePipe.transform(fr.answerValueTimestampFormatted, 'yyyy-MM-dd hh:mm');
      }
    });
    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
  
}
 
