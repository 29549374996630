import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { CrmDashboardData } from 'src/app/crmClasses/crm-dashboard-data';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CrmDashboardDataServiceService } from 'src/app/service/crm-dashboard-data-service.service';
import { DestroyService } from 'src/app/services/destroyService';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-crm-data-dashboard',
  templateUrl: './crm-data-dashboard.component.html',
  styleUrls: ['./crm-data-dashboard.component.scss']
})
export class CrmDataDashboardComponent extends DestroyService implements OnInit {
  gridColumns = 3;
  dashboardData: CrmDashboardData = new CrmDashboardData;

  currentRole = "CRM_ADMIN";
  officeId!: string;
  loggedInUser: any;
  userName!: string;
  userId!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  constructor(private crmDashboardDataServiceService: CrmDashboardDataServiceService,
              private router : Router,
              private ngbModalService: NgbModal,
              private sharedDataService : SharedDataService ) {
              super();
  }
  navbarTitle : any;
  showSpinner = false;

  ngOnInit(): void {
    this.showSpinner = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;

    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

    this.getDashboardData(); 
  }

  // AUTHORIZED
  private getDashboardData(){
    this.showSpinner = true;
        this.crmDashboardDataServiceService.getCrmDashboardData(environment.apiUrl + "crmDashboardData/getData/" + this.officeId).then(
        data => {
          this.dashboardData = data;
          this.showSpinner = false;
        });
  }

  customerDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmC']); 
  }

  LeadDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmL']);
  }

  invoiceDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmI']);
  }

  paymentDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmP']);
  }

  shipmentDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmS']);
  }

  productDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmPr']);
  }

  subscriptionDashboard(){
    this.router.navigate(['/dashboard/crm-admin/crmSubscriptions']);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])    
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole

    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
  reloadPage() {
    window.location.reload();
  }
 
}
