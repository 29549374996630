<nav class="navbar navbar-expand-md bg-dark navbar-dark " >
    <img style="margin: 10px"
    src="/assets/images/courseManagementSystem.svg"
        class="me-2"
        height="30" 
        alt=""
        loading="lazy"
      /> 
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;font-weight: bold;cursor: pointer;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Go back </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="open(logOutModal)" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>


  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>



<div class="content">
    <div class="row">
  
      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" >
      <mat-card class="example-card mat-elevation-z12" style=" margin:  2%;" >
  
       
       <br>
      
        <br>
        <br>
          
      
        <mat-card-content >
  
          <div class="row"  style=" text-align: center;size: 25px;margin-left: 25%;">
  
            <div style="float: left; margin-right: 25px; text-align: center;">
              <mat-card-title>Back</mat-card-title>
            <a style="cursor:pointer;align-self: center;"
            onclick="history.back()" >
            <img src="/assets/images/icons/back-track.svg" width="45" height="45" alt="" loading="lazy">
            </a> 
          </div>
  
  
          <div style="float: right; text-align: center;">
            <mat-card-title>New</mat-card-title>
            <a style="cursor:pointer;align-self: center;"
            (click)="add()" >
            <img src="/assets/images/icons/plus.png" width="45" height="45" alt="" loading="lazy">
          </a> 
        </div>
  
        </div>
        </mat-card-content> 
        
        <br>
        <br>
        <br>
      </mat-card>
  
      
    </div>
  
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let subject of subjectListWithSafeUrl">
  
          
          <mat-card class="example-card mat-elevation-z12" style=" margin:  2%;">
  
            <a style="cursor:pointer; margin-left: 90%;" (click)="editSubject(subject.subId);">
              <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
         </a> 
  
            <div style="width: 100%;color: black;text-align: center;" >
              <a style="cursor:pointer;">
                 <img style="border-radius: 50%;" [src]="subject.safeUrl" width="75" height="75"
                 alt="" loading="lazy">
              </a>
            </div>
            <br>
           
              <mat-card-title>{{subject.name}}</mat-card-title>
              <mat-card-subtitle>{{subject.description}}</mat-card-subtitle>
          
            <mat-card-content>
              <p  style="text-align: center;size: 25px;">
                <button class="btn btn-info" style="align-self: center;" (click)="exploreSubject(subject.subId)" mat-button>Explore</button>
              </p>
            </mat-card-content> 
            
          </mat-card>
  
        
      </div>
    </div>
  </div>
  

  <ng-template #logOutModal let-modal>

    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" 
      id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
      <h2 style="text-align: center;color: black;">Are you sure you want to logout ?</h2>
  
    </div> 
    <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-danger" (click)="logOut();modal.dismiss('Cross click')">Logout</button>
  </div>
  </ng-template>