<div style="width: 100%; height: 100%; display: block;"
 style="background-position: right; background-repeat: no-repeat; background-size: cover;">

 <div class="wrapper">
  <div class="overlay" *ngIf="showSpinner">
    <div class="spinner-wrapper">
      <app-auth-spinner></app-auth-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="showSpinner">
    <ng-content></ng-content>
  </div>
</div>

<div class="login-wrap"  
style="background-position: center; background-repeat: no-repeat; background-size: cover;" >

	<div class="login-html">

    
    <div style="text-align: center;">
      <img style="border-radius: 50%;" height="100" width="100" class="logo-image"
        src="/assets/images/logo.png" />
    </div>
		<input id="tab-1"  type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab"></label>
		<input id="tab-2" type="radio" name="tab" class="sign-up"><label for="tab-2" class="tab"></label>
    <div class="login-form">
			<!-- <div class="sign-in-htm">
        <form class="customerRegisteration-form" #customerRegisteration="ngForm"
            (ngSubmit)="loginClick(customerRegisteration.value)">
				<div class="group">
					<label style="color: white; font-weight: bold;font-size: 12px;" for="user" class="label">Email</label>
					<input type="email" required ngModel name="email" id="email" class=" input"
                  #email="ngModel" />
                <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is required</div>
              
				</div>
				<div class="group">
					<label style="color: white; font-weight: bold;font-size: 12px;" for="pass" class="label">Password</label>
          <input type="password" required ngModel name="password" id="password" class="input"
          #password="ngModel" />
        <div class="alert alert-danger" *ngIf="password.touched && !password.valid">Password is required</div>			
      	</div>
        
				
        <br>
       
        <br>
				<div class="group">
					<input [disabled]="clicked" style="background-color: rgb(9, 9, 104);" type="submit" class="button" [disabled]="!customerRegisteration.valid" value="Login">
				</div>

        <br>

       

      </form> 
       
			</div>-->
			
	</div>
</div>
</div>

</div>