 <div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Activity / Assignment</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  

 
   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm"> 

      
      <div class="form-group row">
        <div class="col-md-6">
      <label class="required">Type </label> <br>
  <mat-radio-group aria-label="type" required [(ngModel)]="actPlnrCAP.type">
    <mat-radio-button name="type" id="activityPlanner" value="activityPlanner"  >Activity </mat-radio-button>
    <mat-radio-button *ngIf="oa_Assignment === true" name="type" id="assignment" value="assignment"  >Assignment</mat-radio-button>
    <mat-radio-button *ngIf="oa_ParentFeedback === true" name="type" id="parentRecurringActivity" value="parentRecurringActivity"  >Parent Activity / Feedback</mat-radio-button>
  </mat-radio-group>
        </div>

        <div class="col-md-6">
          <label class="required" for="curId">Curriculum</label> <br>
              <select #curId="ngModel" required class="form-control" [(ngModel)]="actPlnrCAP.curId" id="curId" name="curId">
                <option  value="" disabled >Choose Curriculum</option>
                <option  *ngFor="let cur of curriculums"  [ngValue]="cur.curId">{{cur.name}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="curId.touched && !curId.valid">Select Curriculum</div>
          </div>
      </div>
     



           <div class="form-group row">
 
         <div class="col-md-6">
            <label class="required" for="activityPlannerName"> Name</label>
            <input  maxlength="50"  #activityPlannerName="ngModel" required placeholder="Enter Activity Name" required class="form-control" type="text" name="activityPlannerName"  id="activityPlannerName" [(ngModel)]="actPlnrCAP.activityPlannerName">
            <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Name is required</div>
         </div>


 

         <div class="col-md-6">
          <label>  Image</label>
          <input placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
       </div>
   
      </div>

     
     

   <br>
      <div>
         <label class="required" for="activityPlannerDescription">Description</label>
         <textarea  maxlength="5000" placeholder="Enter Description" required class="form-control" type="text" name="activityPlannerDescription"  id="activityPlannerDescription" [(ngModel)]="actPlnrCAP.activityPlannerDescription"></textarea>
      </div>
<br>




     
<!-- <form #videoForm="ngForm">

  <div class="header" style="background-color: black;">
    <h3 style="text-align: center; color: white;">Video links</h3>
      </div>
      <br>
      <a [style.display]="!videoDivState ? 'block' : 'none'" style="margin-bottom: 4%; cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
      (click)="openVideoDiv()">
      <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
     </a> 
     <br>
  <div>
    <table  border="1">
        <thead>
            <tr>
              <th>#</th>
                <th>Video Name</th>
                <th>Link</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor = "let video of activityVideoLinkList, let i = index">
              <td>{{i+1}}</td>
                <td>{{video.actVideoLinkName}}</td>
                <td>{{video.actVideoLink}}</td>
            <tr>
               
            </tr>
        </tbody>
</table>
</div>




  <div style="margin-top: 7%;" [style.display]="videoDivState ? 'block' : 'none'">  
      <div class="form-group row">
 
        <div class="col-md-6">
      <label>Video Name</label>
      <input placeholder="Enter Video Name" class="form-control" type="text"  name="actVideoLinkName"  id="actVideoLinkName" [(ngModel)]="activityVideoLink.actVideoLinkName">
   </div>


   <div class="col-md-6">
    <label class="required">Video Link</label>
    <input #link="ngModel" required placeholder="Enter Video Link" class="form-control" type="text"  name="actVideoLink"  id="actVideoLink" [(ngModel)]="activityVideoLink.actVideoLink">
  </div>

      </div>

   <br>
      <div>
         <label for="actVideoLinkDescription">Video Description</label>
         <textarea placeholder="Enter Video Description" class="form-control" type="text" name="actVideoLinkDescription"  id="actVideoLinkDescription" [(ngModel)]="activityVideoLink.actVideoLinkDescription"></textarea>
      </div>

      <br>

      <button [disabled]="!videoForm.valid" style="float: left;" (click)="onVideoSubmission()"  class="btn btn-info">Save Video</button>

    </div>
<br>
   
    </form> -->
      

    </form>
      </div> 
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
  </div>