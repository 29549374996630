<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">File Upload</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
       <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
        <div class="card-body">
    <form (ngSubmit)="onSubmit" #adminForm="ngForm">   
      

      <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerActive===true"></mat-spinner>

      <div *ngIf="showResults===false && matSpinnerActive===false">

        <div *ngIf="spreadsheetLinkExists===false">
           
            <a style="color: blue; cursor: pointer;"
           (click)="open(saveSpreadsheetUrlModal);">
            Click here 
            </a>to save the spreadsheet url

        </div>

        <div *ngIf="spreadsheetLinkExists===true">
          
            <a style="color: blue;cursor: pointer;"
            [href]="metaInventorySpreadsheetLink" 
            target="_blank" 
            download="sample-file.csv">
            Click here
            </a> to view your spreadsheet. <br><br>

            To edit the spreadsheet url,
            <a style="color: blue;cursor: pointer;"
            (click)="open(saveSpreadsheetUrlModal);">
            click here
            </a> 
        </div>

       
        
      
        <br>
        <div>
            <label  class="required">File</label>
            <input type="file" required placeholder="Choose File" (change)="onFileSelected1($event)"
             class="form-control" accept=".csv" >
         </div>

        </div>
        <div *ngIf="showResults===true && matSpinnerActive===false">

          <h3 style="font-weight: bold;"> Items failed to be added/updated({{resultDto.existingStudents.length}}) :</h3>
          <div *ngFor="let email of resultDto.existingStudents">
            <a style="color: black;">{{email}}</a>
          </div>

          <br>
          <br>

          <h3 style="font-weight: bold;">Items created/updated successfully({{resultDto.createdStudents.length}}) :</h3>
          <div *ngFor="let email of resultDto.createdStudents">
            <a style="color: black;">{{email}}</a>
          </div>
         
          <br>

        


        </div>
                </form>
        </div>
    </div>
</div>

<div class="modal-footer">
  <button *ngIf="showResults===false" [disabled]="clicked"  [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Submit</button>
</div>



<ng-template #saveSpreadsheetUrlModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
       Save Spreadsheet url
      </h3>

      <div>
        <label class="required" for="metaInventorySpreadsheetLink">Spreadsheet url</label>
        <input maxlength="255" required class="form-control" type="text" 
         name="metaInventorySpreadsheetLink" id="metaInventorySpreadsheetLink" [(ngModel)]="metaInventorySpreadsheetLink">
      </div>
      <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="saveMetaInventorySpreadsheetUrl();modal.dismiss('Cross click')"
      mat-raised-button>Save</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div>
  </ng-template>