import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AddCombinedParentTeacher } from "src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher";
import { SubscriptionService } from "src/app/service/subscription.service";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { GlobalClass } from "src/app/global/global-class";
import { BotItem } from "src/app/BOT_CODE/bot_classes/bot-item";

@Component({
  selector: "app-bot-items-file-upload",
  templateUrl: "./bot-items-file-upload.component.html",
  styleUrls: ["./bot-items-file-upload.component.scss"],
})
export class BotItemsFileUploadComponent implements OnInit {
  matSpinnerActive = false;
  showResults = false;
  clicked = false;
  combinedPTC: AddCombinedParentTeacher = new AddCombinedParentTeacher();
  userExists = false;
  parentExists = false;
  type: any;
  selectedFile!: File;
  fileSelected = false;
  closeResult!: string;

  @Input() fromParent: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  resultDto: AddCombinedParentTeacher = new AddCombinedParentTeacher();
  metaInventorySpreadsheetLink: any;
  spreadsheetLinkExists = false;

  constructor(
    private ngbModalService: NgbModal,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private subscriptionService: SubscriptionService,
    private sharedDataService: SharedDataService
  ) {}

  loggedInUser: any;
  theme: any;

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();

    this.subscriptionService
      .getMetaUrl(
        environment.apiUrl + "botOfficeShop/getMetaInventorySpreadsheetUrl"
      )
      .then((data) => {
        let item: BotItem = new BotItem();
        item = data;
        this.metaInventorySpreadsheetLink = item.metaInventorySpreadsheetUrl;
        if (
          this.metaInventorySpreadsheetLink !== null &&
          this.metaInventorySpreadsheetLink !== undefined
        ) {
          this.spreadsheetLinkExists = true;
        }
      });
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
  get backgroundStyle() {
    let globalClass: GlobalClass = new GlobalClass();
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onFileSelected1(event: any) {
    this.selectedFile = event.target.files[0];
    this.fileSelected = true;
  }

  onSubmit() {
    this.clicked = true;
    if (this.fileSelected === true) {
      this.matSpinnerActive = true;
      let body = new FormData();
      body.append("file", this.selectedFile);

      this.subscriptionService
        .createStudentsByFileUpload(
          environment.apiUrl + "botMedia/botItemsManagementByFileUpload",
          body
        )
        .then((data) => {
          this.resultDto = data;
          this.passEntry.emit("success");
          this.toaster.success("File uploaded successfully");
          this.showResults = true;
          this.matSpinnerActive = false;
        });
    } else {
      this.toaster.error("Select file");
      this.clicked = false;
    }
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  saveMetaInventorySpreadsheetUrl() {
    if (
      this.metaInventorySpreadsheetLink !== null &&
      this.metaInventorySpreadsheetLink !== undefined
    ) {
      let item: BotItem = new BotItem();
      item.metaInventorySpreadsheetUrl = this.metaInventorySpreadsheetLink;
      this.subscriptionService
        .createStudentsByFileUpload(
          environment.apiUrl + "botOfficeShop/saveMetaInventorySpreadsheetUrl",
          item
        )
        .then((data) => {
          this.spreadsheetLinkExists = true;
          this.toaster.success("Url set successfully");
        });
    } else {
      this.toaster.error("Please enter url to proceed !");
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
