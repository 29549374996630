<div  [style.background-color]='getColor()'  class="modal-header">
    <h4
    style="color: white;" class="modal-title pull-left">Response Details / Configurations</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


  <div class="modal-body">
    <div class="card-body"> 

      <div>
        <h3 style="font-weight: bold;margin: 0%;">Response : </h3>
        <h4>
             {{botResponse.response}} 
            <a matTooltip="Edit" style="cursor: pointer;" (click)="editBotResponse()"> 
                <img src="/assets/images/icons/edit.svg" width="19" height="19" 
                alt="" loading="lazy"> </a>
        </h4>
        <h3 style="margin: 0%;font-weight: bold;" *ngIf="botResponse.options !== null && botResponse.options.length > 0">Options :</h3>
<div *ngFor="let opt of botResponse.options">
    <a style="color:black;"> 
      
      <img matTooltip="Active" *ngIf="opt.botResponseOptionsStatus===true" src="/assets/images/icons/complete.png" width="15" height="15" alt="" loading="lazy">
      <img matTooltip="Inactive" *ngIf="opt.botResponseOptionsStatus===false" src="/assets/images/icons/nonStarted.png" width="15" height="15" alt="" loading="lazy">
      {{opt.responseOptionNumber}}. {{opt.responseOption}} 
    
       

    </a> <br> 
</div>
      </div>

      <br>
      <mat-divider></mat-divider>
      <br>

      
      



      
            <!-- Assign Agent -->
            <div>
      
            <h3 style="font-weight: bold;">Assign Agent
              <a style="cursor: pointer;" (click)="open(assignAgentDescription);"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" 
                alt="" loading="lazy"> </a> </h3>

              
                <button *ngIf="botResponse.fkAgentUroIdToAssign===null" (click)="getBotAgents();open(assignAgentModal);" 
                mat-raised-button>Choose Agent</button>

              <h3 *ngIf="botResponse.fkAgentUroIdToAssign!==null">Agent being assigned : {{botResponse.fkAgentFullNameToAssign}}</h3>
              
              <button *ngIf="botResponse.fkAgentUroIdToAssign!==null" (click)="removeAgentAssignmentConfigFromResponse();" 
              style="width: auto;" mat-raised-button>Remove Agent Assign Configuration</button>

              
      
           <br> <br>
           <a>To manage whatsapp notifications to agents & notification configurations, go to Home > Notifications > Configurations or </a>
           <a (click)="goToAdminNotificationPage();" style="color: blue;cursor: pointer;">click here</a>

            </div>


<br>
<mat-divider></mat-divider>
<br>

      <!-- ADMIN NOTIFICATION -->
      <div>

       <h3 style="font-weight: bold;">Admin Notification
        <a style="cursor: pointer;" (click)="open(adminNotificationDescription);"> 
          <img src="/assets/images/icons/info.png" width="21" height="21" 
          alt="" loading="lazy"> </a> </h3>

          
        <button *ngIf="botResponse.createAdminNotification!==true" mat-raised-button (click)="open(CreateAdminNotfn);" 
        style="width: auto;">Create admin notification</button>
        <br *ngIf="botResponse.createAdminNotification!==true">

        <div *ngIf="botResponse.createAdminNotification===true">

          <h4 style="font-weight: bold;margin: 0%;">Title <br> <h4 style="font-weight: normal;margin: 0%;">{{botResponse.adminNotificationTitle}}</h4></h4>
          <h4 style="font-weight: bold;margin: 0%;">Message <br> <h4 style="font-weight: normal;margin: 0%;">{{botResponse.adminNotificationMessage}}</h4></h4>
       
          <div *ngIf="botResponse.botNotificationConfigurations !== null 
          && botResponse.botNotificationConfigurations !== undefined
          && botResponse.botNotificationConfigurations.length > 0">

          <br>

          <h3>Admins & Agents being notified on whatsapp:</h3>
          <table *ngIf="showMatSpinner===false" class="table table-striped table-sm hover">
            <thead class="tableheader">
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let config of botResponse.botNotificationConfigurations">
                <td>{{config.fkBotUserName}}</td>
                <td>
                  <button
                  (click)="deleteBotNotificationConfig(config);" matTooltip="Deactivate" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <mat-spinner style="margin:0 auto;" *ngIf="showMatSpinner===true"></mat-spinner>

          <br>
          <button mat-raised-button (click)="getBotOfficeAdminsAndAgents();open(assignAdminsAndAgentForWaNotificationsModal);"
        style="width: auto;margin-right: 1%;">Add Agents</button>

          </div>

          <div *ngIf="botResponse.botNotificationConfigurations === null 
          || botResponse.botNotificationConfigurations === undefined
          || botResponse.botNotificationConfigurations.length === 0">
            <mat-spinner style="margin:0 auto;" *ngIf="showMatSpinner===true"></mat-spinner>
          </div>
          <br>
          
<div>
        <button mat-raised-button (click)="open(editAdminNotfn);"
        style="width: auto;margin-right: 1%;">Edit Notification</button>

        <button *ngIf="botResponse.botNotificationConfigurations === null 
        || botResponse.botNotificationConfigurations === undefined
        || botResponse.botNotificationConfigurations.length === 0"
        mat-raised-button (click)="getBotOfficeAdminsAndAgents();open(assignAdminsAndAgentForWaNotificationsModal);"
        style="width: auto;margin-right: 1%;">Notify Agents on Whatsapp</button>

        <button mat-raised-button (click)="deactivateAdminNotification();" 
        style="width: auto;">Deactivate Notification</button>
</div>

        </div>
     
        <br>
           <a>To view / manage notifications & notification configurations, go to Home > Notifications > New Conversation Notifications or </a>
           <a (click)="goToAdminNotificationPage();" style="color: blue;cursor: pointer;">click here</a>


           <div *ngIf="crmAccess===true && botResponse.createAdminNotification===true">
            <br><br>

            <button *ngIf="botResponse.fkCrmListId === null"
            mat-raised-button style="width: auto;"
            (click)="getCrmLists();open(attachCrmListModal);"
            >Create CRM Task</button>

            <div *ngIf="botResponse.fkCrmListId !== null">

              <h3>CRM List associated with Tasks : {{botResponse.fkCrmListName}}</h3>
              <button mat-raised-button style="width: auto;margin-right: 1%;"
            (click)="getCrmLists();open(attachCrmListModal);"
            >Change CRM List</button>

            <button *ngIf="botResponse.crmTaskConfigs === null || botResponse.crmTaskConfigs === undefined || botResponse.crmTaskConfigs.length === 0"
            mat-raised-button style="width: auto;margin-right: 1%;"
            (click)="getBotOfficeAdminsAndAgentsForCrmTaskConfig();open(assignAdminsAndAgentForCrmTaskConfigs);"
            >Create CRM Task For Agents</button>

            <button mat-raised-button style="width: auto;"
            (click)="deactivateCrmTaskCreation();"
            >Deactivate Task Creation</button>

            <h3 *ngIf="botResponse.crmTaskConfigs !== null && botResponse.crmTaskConfigs !== undefined && botResponse.crmTaskConfigs.length > 0"
              >Admins & Agents being assigned Tasks:</h3>
          <table *ngIf="crmMatSpinner===false && botResponse.crmTaskConfigs !== null && botResponse.crmTaskConfigs !== undefined && botResponse.crmTaskConfigs.length > 0"
             class="table table-striped table-sm hover">
            <thead class="tableheader">
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let config of botResponse.crmTaskConfigs">
                <td>{{config.fkBotUserName}}</td>
                <td>
                  <button
                  (click)="deleteCrmTaskConfig(config);" matTooltip="Deactivate" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <mat-spinner style="margin:0 auto;" *ngIf="crmMatSpinner===true"></mat-spinner>

            <button *ngIf="botResponse.crmTaskConfigs !== null && botResponse.crmTaskConfigs !== undefined && botResponse.crmTaskConfigs.length > 0"
            mat-raised-button style="width: auto;"
            (click)="getBotOfficeAdminsAndAgentsForCrmTaskConfig();open(assignAdminsAndAgentForCrmTaskConfigs);"
            >Add More Agents For CRM Task</button>

          </div>
       

           </div>

      </div>

<br>
<mat-divider></mat-divider>
<br>


      
<!-- Manual Chat Notification -->
      <div>
        <h3 style="font-weight: bold;">Manual Chat Notification </h3>
      
          <button *ngIf="botResponse.createManualChatNotification!==true"
          mat-raised-button (click)="saveManualChatNotificationMessage();" 
              style="width: auto;">Create Manual Chat Notification</button>
      
          
        <button *ngIf="botResponse.createManualChatNotification===true"
        mat-raised-button (click)="deactivateManualChatNotification();" 
        style="width: auto;">Deactivate Manual Chat Notification</button>
        
        <br><br>
           <a>To view / manage notifications & notification configurations, go to Home > Notifications > Manual Chat Request Notifications / Configurations or </a>
           <a (click)="goToAdminNotificationPage();" style="color: blue;cursor: pointer;">click here</a>
      </div>

      <div *ngIf="advConfigsVisible===false">
        <br>
        <button mat-raised-button style="width: auto;float: right;"
        (click)="advConfigsVisible=true;">View Advanced Configurations</button>
      </div>
      

<!-- ADVANCED CONFIGS -->
<div *ngIf="advConfigsVisible===true">

  <br>
  <mat-divider></mat-divider>
  <br>

  <!-- Response Link -->
<div>
 
  <h3 style="font-weight: bold;">Response Link  </h3>
  <button *ngIf="botResponse.resAlias === null || botResponse.resAlias === undefined" 
  (click)="open(updateResponseAliasModal);"
  mat-raised-button style="width: auto;">Add Alias to generate Response Link</button>
  <div *ngIf="botResponse.resAlias !== null && botResponse.resAlias !== undefined">
    <a style="color: black;">  {{responseLink}}
      <a [cdkCopyToClipboard]="responseLink" (click)="toastr.show('Link Copied !');" 
      matTooltip="Copy Link" style="cursor:pointer;">
        <img src="/assets/images/icons/copyText.png" width="20" height="20"
        alt="" loading="lazy">
    </a>
  </a>
  <br> <br>
  <button (click)="resAlias=botResponse.resAlias;open(updateResponseAliasModal);"
  mat-raised-button style="width: auto;">Edit Alias</button>
  </div>
</div>


<br>
<mat-divider></mat-divider>
<br>

        <!-- Validations -->
        <div>
          <h3 style="font-weight: bold;">Validations </h3>
          <button 
          (click)="open(createValidation);"
          mat-raised-button style="width: auto;">Create Validation</button>
        
          <br> 
          
          <div *ngIf="botResponse.resValidationConfigs!==null 
          && botResponse.resValidationConfigs!==undefined 
          && botResponse.resValidationConfigs.length > 0">
          <br>

          <h3>Existing Validations :</h3>

          <mat-spinner style="margin:0 auto;" *ngIf="matSpinnerValidations===true"></mat-spinner>

          <table *ngIf="matSpinnerValidations===false" class="table table-striped table-sm hover">
            <thead class="tableheader">
              <tr>
                <th>Validation</th>
                <th>Type</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let config of botResponse.resValidationConfigs">

                <td *ngIf="config.validationFor==='n'">Number Validation</td>
                
                <td *ngIf="config.validationType==='l'">Length Check</td>
                <td *ngIf="config.validationType==='r'">Range Check</td>
                <td *ngIf="config.validationType==='lr'">Length Range Check</td>

                <td *ngIf="config.validationType==='l'">Expected Length : {{config.minCheck}}</td>
                <td *ngIf="config.validationType==='r'">Value Range : [ {{config.minCheck}} - {{config.maxCheck}} ]</td>
                <td *ngIf="config.validationType==='lr'">Length Range : [ {{config.minCheck}} - {{config.maxCheck}} ]</td>
                
                
                <td>
                  <button (click)="deleteBotValidationConfig(config);"
                   matTooltip="Deactivate" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
          </div>
          

        </div>

<br>
<mat-divider></mat-divider>
<br>

  <!-- ADMIN NOTES -->
  <div>
    <h3 style="font-weight: bold;">Admin Notes
     <a style="cursor: pointer;" (click)="open(adminNotesDescription);"> 
       <img src="/assets/images/icons/info.png" width="21" height="21" 
       alt="" loading="lazy"> </a> </h3>

     <div  *ngIf="botResponse.createAdminNotes!==true">
       <button mat-raised-button (click)="open(CreateAdminNotesfn);" style="width: auto;">Create admin notes</button>
     </div>

   <div *ngIf="botResponse.createAdminNotes===true">

     
     <h4 style="font-weight: bold;margin: 0%;">Title <br> <h4 style="font-weight: normal;margin: 0%;">{{botResponse.adminNotesTitle}}</h4></h4>
     <h4 style="font-weight: bold;margin: 0%;">Message <br> <h4 style="font-weight: normal;margin: 0%;">{{botResponse.adminNotesMessage}}</h4></h4>
  
     <br>

     <div>
       <button mat-raised-button (click)="open(editAdminNotesfn);"
       style="width: auto;margin-right: 1%;">Edit Notes</button>

       <button mat-raised-button (click)="deactivateAdminNotes();" 
       style="width: auto;">Deactivate Notes</button>
     </div>

   </div>

   <br>
   <a>To view / manage notes, go to Home > Notifications > Notes or </a>
   <a (click)="goToAdminNotificationPage();" style="color: blue;cursor: pointer;">click here</a>
   </div>

<br>
<mat-divider></mat-divider>
<br>

<!-- Group Options into Sections -->
   <div>
     <h3 style="font-weight: bold;">Group Options into Sections
       <a style="cursor: pointer;" (click)="open(interactiveListDescription);"> 
         <img src="/assets/images/icons/info.png" width="21" height="21" 
         alt="" loading="lazy"> </a> </h3>
     
       <!-- <button *ngIf="botResponse.resOptionsType===null" 
       (click)="open(interactiveListTypes);" style="width: auto;" mat-raised-button>Make Interactive Lists</button>

     <button *ngIf="botResponse.resOptionsType==='interactive_lists'"
     (click)="removeInteractiveOptionsType();" style="width: auto;" class="btn btn-danger">Remove Interactive Lists</button> -->

     <button (click)="makeOptionsInteractiveLists();" style="width: auto;" mat-raised-button>Group into Sections</button>
<br> <br>
   <button (click)="removeInteractiveOptionsType();" style="width: auto;" mat-raised-button>Remove Sections</button>


   </div>

<br>
<mat-divider></mat-divider>
<br>


          
    
   
          
    <!-- External API For Variables -->
          <div>
            
           <h3 style="font-weight: bold;"> External API For Variables
            <a matTooltip="Edit" style="cursor: pointer;" (click)="open(extApiForVarDescription);"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" 
              alt="" loading="lazy"> </a> </h3>
            
            <div *ngIf="botResponse.callExtApiForResVariables === false" >
              <button (click)="createOrLinkApiConfigurationForResVars();"
              mat-raised-button style="width: auto;">Add API Configuration</button>
          </div>
          <div *ngIf="botResponse.callExtApiForResVariables === true">
            API Config Set : {{botResponse.fkApiConfigIdForResVariablesTitle}} <a matTooltip="Remove API Configuration" style="margin-right: 1%;cursor: pointer;"
            (click)="removeApiConfigForResVariables();" > 
             <img src="/assets/images/icons/delete.png" width="21" height="21" 
             alt="" loading="lazy"> </a>
          </div>
    
          </div>
    
    <br>
    <mat-divider></mat-divider>
    <br>
          
    <!-- Attach a form link to the response -->
          <div>
            
           
            <h3 style="font-weight: bold;"> 
              Attach a form link to the response
              <a matTooltip="Info" style="cursor: pointer;" (click)="open(formLinkDescription);"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" 
                alt="" loading="lazy"> </a> </h3>
    
            <div *ngIf="botResponse.fkFormActiveId === null" >
              <button (click)="open(attachFormModal);"
              mat-raised-button style="width: auto;">Attach Form Link</button>
          </div>
          <div style="font-weight: bold;" *ngIf="botResponse.fkFormActiveId !== null">
            "{{botResponse.fkFormName}}" is attached as a url to this response. 
            <a matTooltip="Delete form attachment" style="cursor: pointer;" (click)="unlinkAttachedFormFromResponse()"> 
              <img src="/assets/images/icons/delete.png" width="21" height="21" 
              alt="" loading="lazy"> </a>
          </div>
    
          </div>

             <!-- globalTemplateResCreation -->
   <div *ngIf="globalTemplateResCreation===true">

    <br>
    <mat-divider></mat-divider>
    <br>
          
    <!-- Global Template Response  -->
          <div>
           <h3 style="font-weight: bold;">Global Template Response 
            <a matTooltip="Edit" style="cursor: pointer;" (click)="open(aGlobalTempResDescription);"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" 
              alt="" loading="lazy"> </a> </h3>
    
              <div *ngIf="botResponse.globalTemplateRes!==true">
                <button mat-raised-button (click)="open(makeGlobalTemplRes);" 
                    style="width: auto;">Mark as Global Template</button>
            </div>
            <div *ngIf="botResponse.globalTemplateRes===true">
               
              This response is a global template response 
    
              <a matTooltip="Unmark as Global Template Response" style="cursor: pointer;" (click)="unmarkGlobalTemplateResponse()"> 
                <img src="/assets/images/icons/delete.png" width="21" height="21" 
                alt="" loading="lazy"> </a>
    
            </div>
    
          </div>
    
          </div>
</div>







        <br><br>



        

    </div>
  </div>

  <ng-template #interactiveButtonsDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Interactive Buttons</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Share options in the form of interactive buttons.

      <br> <br>
     <div style="font-weight: bold;">Warning:</div>  <br>
      1. Number of options should not exceed 3. <br>
      2. Length of each option should not exceed 20.

      <br> <br>
      Example: <br>
      <a> 
        <img src="/assets/images/snapshots/sample_interactive_buttons.jpeg" width="450" height="200" 
        alt="" loading="lazy"> 
      </a>


    </div> 
  </ng-template>

  <ng-template #interactiveListDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Interactive Lists</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Share options in the form of Interactive Lists with sections.

      <br> <br>
     <div style="font-weight: bold;">Warning:</div>  <br>
      1. Number of options should not exceed 10. <br>
      2. Length of each option should not exceed 20.

      <br> <br>
      Example: <br>
      <a> 
        <img src="/assets/images/snapshots/sample_interactive_buttons.jpeg" width="450" height="200" 
        alt="" loading="lazy"> 
      </a>


    </div> 
  </ng-template>

  <ng-template #interactiveListTypes let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Interactive Lists</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
      Would you like to group the options into seperate sections ?
    </div> 

    <div class="modal-footer">
      <button type="button" style="width: auto;" class="btn btn-primary" (click)="makeOptionsInteractiveListsWithoutGrouping();modal.dismiss('Cross click')">Proceed without grouping</button>
      <button type="button" style="width: auto;" class="btn btn-secondary" (click)="makeOptionsInteractiveLists();modal.dismiss('Cross click')">Group options into sections</button>
    </div>
  </ng-template>

  <ng-template #adminNotificationDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Admin Notifications</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Create a Notification for admin for this response.
      <br>
      A notification "message" will be created along with whatsapp user details when Bot sends the current response.
      <br> <br>
      You can view the notifications in <br>
      Home page > "Customers, Notifications" section > Notifications <br>
      or <a style="color: blue;cursor: pointer;" (click)="goToAdminNotificationPage();modal.dismiss('Cross click');">click here</a> 


    

      


    </div> 
  </ng-template>

  <ng-template #assignAgentDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Admin Notifications</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
Assign Agent to User
      <br>
      Chosen Agent will be assigned to User when Bot sends the current response.
      <br> 
    </div> 
  </ng-template>

  <ng-template #adminNotesDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Admin Notes</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Create a unique note for admin for this response.
      <br>
      A "note" will be created along with whatsapp user details when Bot sends the current response which would be unique for this user and response.
      The same note will be updated everytime based on the latest conversation.
      <br> <br>
      You can view the notes in <br>
      Home page > "Customers, Notifications" section > Notifications <br>
      or <a style="color: blue;cursor: pointer;" (click)="goToAdminNotificationPage();modal.dismiss('Cross click');">click here</a> 


    

      


    </div> 
  </ng-template>

  <ng-template #aGlobalTempResDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Global Template Response</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Mark this response as a Template Response which can be copied by other admin users.
      <br>
      
    </div> 
  </ng-template>

  <ng-template #extApiForVarDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">External Api Variables</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Make an external API call to replace variables in the response.
      <br>
      
    </div> 
  </ng-template>
  
  <ng-template #formLinkDescription let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Form Link</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      Choose a form whose link you want to attach to the end of the response.
      <br>
      
    </div> 
  </ng-template>

  <ng-template #makeGlobalTemplRes let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Global Template Response</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="makeGlobalTemplateResponse()" #aqForm="ngForm"> 
        
          <div class="col-md-12">
          <label class="required" for="adminNotificationMessage">Tag</label>

          <input #gtt0="ngModel" required class="form-control"
           type="text" name="gtt"  id="gtt" [(ngModel)]="gtt">
          

          <div class="alert alert-danger" *ngIf="gtt0.touched && !gtt0.valid">Enter Tag</div>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="makeGlobalTemplateResponse();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>

  <ng-template #attachFormModal let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Admin Notification</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form #aqForm="ngForm"> 
        
        <div>
          <label for="formId">Form</label>
          <select required class="form-control" [(ngModel)]="formId">
            <option value="" disabled>Choose a Form</option>
            <option *ngFor="let temp of feedbackForms" [ngValue]="temp.id">{{temp.name}}, ({{temp.type}})</option>
          </select>
        </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="attachFormLinkToResponse();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>

  <ng-template #assignAdminsAndAgentForWaNotificationsModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Notifications to Admins & Agents</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
       

         <br *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
         <div *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
           <label>Selected Admins & Agents :</label>
           <mat-select-trigger>
             <mat-chip-list  style="display: flex;">
               <mat-chip *ngFor="let topping of selectedUsers"
                 [removable]="true" (removed)="onToppingRemoved(topping)">
                 {{ topping.name }}
                 <mat-icon matChipRemove>cancel</mat-icon>
               </mat-chip>
               
             </mat-chip-list>
           </mat-select-trigger>
         </div>
         
         
         <br>

         <div>
          <label>Admins & Agents</label>
          <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
            <mat-label>Choose Admins & Agents</mat-label>
            <mat-select [formControl]="toppingsControl"
            (selectionChange)="multiSelectValueChange($event.value);" multiple>
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" 
            placeholder="Start searching by name or phone number">
        
            <mat-option (click)="selectAll('Select All', null)" value="Select All">Select All</mat-option>
            <mat-option (click)="selectAll(cb.botUserId, cb)" *ngFor="let cb of allUsers | filter:searchText" [value]="cb.botUserId">{{cb.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-info" 
      (click)="createWhatsappNotificationConfigs();modal.dismiss('Cross click');">Save</button>
    </div>
  </ng-template>


  <ng-template #assignAdminsAndAgentForCrmTaskConfigs let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Notifications to Admins & Agents</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
       

         <br *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
         <div *ngIf="selectedUsers !== null && selectedUsers !== undefined && selectedUsers.length > 0">
           <label>Selected Admins & Agents :</label>
           <mat-select-trigger>
             <mat-chip-list  style="display: flex;">
               <mat-chip *ngFor="let topping of selectedUsers"
                 [removable]="true" (removed)="onToppingRemoved(topping)">
                 {{ topping.name }}
                 <mat-icon matChipRemove>cancel</mat-icon>
               </mat-chip>
               
             </mat-chip-list>
           </mat-select-trigger>
         </div>
         
         
         <br>

         <div>
          <label>Admins & Agents</label>
          <mat-form-field [style.width.%]=100 style="color: white; margin-right: 2%; border-top: 0;">
            <mat-label>Choose Admins & Agents</mat-label>
            <mat-select [formControl]="toppingsControl"
            (selectionChange)="multiSelectValueChange($event.value);" multiple>
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" 
            placeholder="Start searching by name or phone number">
        
            <mat-option (click)="selectAll('Select All', null)" value="Select All">Select All</mat-option>
            <mat-option (click)="selectAll(cb.botUserId, cb)" *ngFor="let cb of allUsers | filter:searchText" [value]="cb.botUserId">{{cb.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-info" 
      (click)="createCrmTaskConfigs();modal.dismiss('Cross click');">Save</button>
    </div>
  </ng-template>





























  <ng-template #CreateAdminNotfn let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Admin Notification</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="saveAdminNotificationMessage()" #aqForm="ngForm"> 
        
        <div class="col-md-12">
          <label class="required" for="adminNotificationTitle">Title</label>
          <input type="text" 
          #permanentToken11="ngModel" required class="form-control"
           type="text" name="adminNotificationTitle" id="adminNotificationTitle" [(ngModel)]="adminNotificationTitle">
           <div class="alert alert-danger" *ngIf="permanentToken11.touched && !permanentToken11.valid">Enter Title</div>
           </div>

           <br>
          
           <div class="col-md-12">
          <label class="required" for="adminNotificationMessage">Message
            <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
          </label>

          <textarea #permanentToken0="ngModel" required class="form-control"
           type="text" name="adminNotificationMessage" rows="10" id="adminNotificationMessage" [(ngModel)]="adminNotificationMessage">
          </textarea>

          <div class="alert alert-danger" *ngIf="permanentToken0.touched && !permanentToken0.valid">Enter Message</div>
          </div>
      <br>
          <div class="col-md-12">
            <label for="adminNotificationMedia">Media
              <a (click)="open(botAdminNotifMediaMessageInfo);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label>
            <textarea class="form-control"
             type="text" name="adminNotificationMedia" rows="5" id="adminNotificationMedia" [(ngModel)]="adminNotificationMedia">
            </textarea>
          </div>
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="saveAdminNotificationMessage();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template> 

  <ng-template #CreateAdminNotesfn let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Admin Notes</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="saveAdminNotesMessage()" #aqForm="ngForm"> 
        
        <div class="col-md-12">
          <label class="required" for="adminNotesTitle">Title</label>
          <input type="text" 
          #permanentToken11="ngModel" required class="form-control"
           type="text" name="adminNotesTitle" id="adminNotesTitle" [(ngModel)]="adminNotesTitle">
           <div class="alert alert-danger" *ngIf="permanentToken11.touched && !permanentToken11.valid">Enter Title</div>
           </div>

           <br>

          <div class="col-md-12">
          <label class="required" for="adminNotesMessage">Message
            <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
          </label>

          <textarea #permanentToken0="ngModel" required class="form-control"
           type="text" name="adminNotesMessage" rows="10" id="adminNotesMessage" [(ngModel)]="adminNotesMessage">
          </textarea>

          <div class="alert alert-danger" *ngIf="permanentToken0.touched && !permanentToken0.valid">Enter Message</div>
          </div>

          <br>
          <div class="col-md-12">
            <label for="adminNotesMedia">Media
              <a (click)="open(botAdminNotifMediaMessageInfo);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label>
            <textarea class="form-control"
             type="text" name="adminNotesMedia" rows="5" id="adminNotesMedia" [(ngModel)]="adminNotesMedia">
            </textarea>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="saveAdminNotesMessage();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>

  <ng-template #editAdminNotfn let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Edit Admin Notification Message</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="editAdminNotificationMessage()" #aqForm="ngForm"> 
        
        <div class="col-md-12">
          <label class="required" for="botResponse.adminNotificationTitle">Title</label>
          <input type="text" 
          #permanentToken11="ngModel" required class="form-control"
           type="text" name="botResponse.adminNotificationTitle" id="botResponse.adminNotificationTitle" [(ngModel)]="botResponse.adminNotificationTitle">
           <div class="alert alert-danger" *ngIf="permanentToken11.touched && !permanentToken11.valid">Enter Title</div>
           </div>

           <br>

          <div class="col-md-12">
          <label class="required" for="botResponse.adminNotificationMessage">Message
            <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
          </label>
          
          <textarea #permanentToken2="ngModel" required class="form-control"
           type="text" rows="10" name="botResponse.adminNotificationMessage"  id="botResponse.adminNotificationMessage" 
           [(ngModel)]="botResponse.adminNotificationMessage">
          </textarea>

          <div class="alert alert-danger" *ngIf="permanentToken2.touched && !permanentToken2.valid">Enter Message</div>
          </div>

          <br>
          <div class="col-md-12">
            <label for="botResponse.adminNotificationMedia">Media
              <a (click)="open(botAdminNotifMediaMessageInfo);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label>
            <textarea class="form-control"
             type="text" name="botResponse.adminNotificationMedia" rows="5" id="botResponse.adminNotificationMedia" 
             [(ngModel)]="botResponse.adminNotificationMedia">
            </textarea>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="editAdminNotificationMessage();modal.dismiss('Cross click')">Update</button>
    </div>
  </ng-template>

  <ng-template #editAdminNotesfn let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Edit Admin Notes Message</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form (ngSubmit)="editAdminNotesMessage()" #aqForm="ngForm"> 
        
        <div class="col-md-12">
          <label class="required" for="botResponse.adminNotesTitle">Title</label>
          <input type="text" 
          #permanentToken11="ngModel" required class="form-control"
           type="text" name="botResponse.adminNotesTitle" id="botResponse.adminNotesTitle" [(ngModel)]="botResponse.adminNotesTitle">
           <div class="alert alert-danger" *ngIf="permanentToken11.touched && !permanentToken11.valid">Enter Title</div>
           </div>

           <br>

          <div class="col-md-12">
          <label class="required" for="botResponse.adminNotesMessage">Message
            <a (click)="open(variableDetails);" matTooltip="Variables" style="cursor: pointer;"> 
              <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
            </a>
          </label>
          
          <textarea #permanentToken2="ngModel" required class="form-control"
           type="text" rows="10" name="botResponse.adminNotesMessage"  id="botResponse.adminNotesMessage" [(ngModel)]="botResponse.adminNotesMessage">
          </textarea>

          <div class="alert alert-danger" *ngIf="permanentToken2.touched && !permanentToken2.valid">Enter Message</div>
          </div>

          <br>
          <div class="col-md-12">
            <label for="botResponse.adminNotesMedia">Media
              <a (click)="open(botAdminNotifMediaMessageInfo);" matTooltip="Variables" style="cursor: pointer;"> 
                <img src="/assets/images/icons/info.png" width="21" height="21" alt="" loading="lazy"> 
              </a>
            </label>
            <textarea class="form-control"
             type="text" name="botResponse.adminNotesMedia" rows="5" id="botResponse.adminNotesMedia" 
             [(ngModel)]="botResponse.adminNotesMedia">
            </textarea>
          </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="editAdminNotesMessage();modal.dismiss('Cross click')">Update</button>
    </div>
  </ng-template>


  <ng-template #updateResponseAliasModal let-modal>
    <div class="modal-body" style="color: black;">

      <h3 style="font-weight: bold;">
       Update Response Alias
      </h3>

      <div>
        <label class="required" for="resAlias">Alias</label>
        <input maxlength="255" required class="form-control" type="text" 
         name="resAlias" id="resAlias" [(ngModel)]="resAlias">
      </div>
      <br> <br>

      <button style="float: right;width: auto;margin: 0.5%;" (click)="updateResponseAlias();modal.dismiss('Cross click')"
      mat-raised-button>Update</button>
      <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click')" mat-raised-button>Cancel</button>
      
    </div>
  </ng-template>

  <ng-template #assignAgentModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Agent</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        

        <div style="margin-bottom: 2%;">
          <label class="required" for="var">Agents</label> <br> 
             <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botAgentUroIdToAssign" 
                          id="botAgentUroIdToAssign" name="botAgentUroIdToAssign">
               <option value="" disabled >Choose Agent to assign</option>
               <option *ngFor="let agent of botAgent.users" name="varV" id="India" [value]="agent.uroId">{{agent.name}}</option>
              </select>
         </div> 
      </div>
    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-info" 
      (click)="setAgentAssignmentConfigToResponse();modal.dismiss('Cross click');">Assign</button>
    </div>
  </ng-template>


  <ng-template #createValidation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Create Validation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    
      
        <div>
          <label class="required"> Validation For </label> <br>
          <mat-radio-group name="newBotvalidationConfig.validationFor" id="validationFor" required 
          aria-label="validationFor" [(ngModel)]="newBotvalidationConfig.validationFor">
            <mat-radio-button name="validationFor" id="1" value="n">Number</mat-radio-button>
          </mat-radio-group>
        </div>  

        <br>

        <div>
          <label class="required"> Validation Type </label> <br>
          <mat-radio-group name="newBotvalidationConfig.validationType" id="validationType" required 
          aria-label="validationType" [(ngModel)]="newBotvalidationConfig.validationType">
            <mat-radio-button name="validationType" id="2" value="l">Length</mat-radio-button>
            <mat-radio-button name="validationType" id="4" value="lr">Length Range</mat-radio-button>
            <mat-radio-button name="validationType" id="3" value="r">Value Range</mat-radio-button>
          </mat-radio-group>
        </div>  

        <br>

        <div *ngIf="newBotvalidationConfig.validationType==='l'" class="form-group row">
          
          <div class="col-md-6">
            <label for="minCheck">Length Value</label>
            <input class="form-control" type="number" required
            name="minCheck" id="minCheck" [(ngModel)]="newBotvalidationConfig.minCheck">
          </div>

        </div>

        <div *ngIf="newBotvalidationConfig.validationType==='lr'" class="form-group row">
          <div class="col-md-6">
            <label for="minCheck">Min Length</label>
            <input class="form-control" type="number" required
            name="minCheck" id="minCheck" [(ngModel)]="newBotvalidationConfig.minCheck">
          </div>

          <div class="col-md-6">
            <label for="maxCheck">Max Length</label>
            <input class="form-control" type="number" required
            name="maxCheck" id="maxCheck" [(ngModel)]="newBotvalidationConfig.maxCheck">
          </div>
        </div>

        <div *ngIf="newBotvalidationConfig.validationType==='r'" class="form-group row">
          <div class="col-md-6">
            <label for="minCheck">Min Value</label>
            <input class="form-control" type="number" required
            name="minCheck" id="minCheck" [(ngModel)]="newBotvalidationConfig.minCheck">
          </div>

          <div class="col-md-6">
            <label for="maxCheck">Max Value</label>
            <input class="form-control" type="number" required
            name="maxCheck" id="maxCheck" [(ngModel)]="newBotvalidationConfig.maxCheck">
          </div>
        </div>
      

    </div> 
    <div class="modal-footer">
      <button style="width: auto;float: right;" class="btn btn-info" 
      (click)="createBotValidationConfig();modal.dismiss('Cross click');">Save</button>
    </div>
  </ng-template>

  <ng-template #attachCrmListModal let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Choose List</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <form #aqForm="ngForm"> 
        
        <div>
          <label for="crmListIdToAttach">List</label>
          <select required class="form-control" [(ngModel)]="crmListIdToAttach">
            <option value=null disabled>Choose a List</option>
            <option *ngFor="let crmList of crmLists" [ngValue]="crmList.crmListId">{{crmList.listName}}</option>
          </select>
        </div>
      
      </form>
      
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-success" [disabled]="!aqForm.valid" (click)="linkCrmListToBotResponse();modal.dismiss('Cross click')">Save</button>
    </div>
  </ng-template>


  <ng-template #variableDetails let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Variables</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
  
      <table
      class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
      <thead class="tableheader" >
        <tr>
          <th>User Variables</th>
          <th>Detail that replaces variable</th>
      </tr>
      </thead>
      <tbody>
        <tr>
          <td>$!#userName$!# 
            <a style="cursor: pointer;" cdkCopyToClipboard="$!#userName$!#" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>User name</td>
        </tr>
        <tr>
          <td>$!#userPhone$!#
            <a style="cursor: pointer;" cdkCopyToClipboard="$!#userPhone$!#" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>User phone number</td>
        </tr>
        <tr>
          <td>$!#userAlternatePhone$!#
            <a style="cursor: pointer;" cdkCopyToClipboard="$!#userAlternatePhone$!#" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>User alternate phone number</td>
        </tr>
        <tr>
          <td>$!#userGender$!#
            <a style="cursor: pointer;" cdkCopyToClipboard="$!#userGender$!#" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>User gender</td>
        </tr>
        <tr>
          <td>$!#customVar1$!#
            <a style="cursor: pointer;" cdkCopyToClipboard="$!#customVar1$!#" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>Custom Variable 1</td>
        </tr>
        <tr>
          <td>$!#customVar2$!#
            <a style="cursor: pointer;" cdkCopyToClipboard="$!#customVar2$!#" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>Custom Variable 2</td>
        </tr>
        <tr>
          <td>###zotishop###
            <a style="cursor: pointer;" cdkCopyToClipboard="###zotishop###" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>A link to shopping page</td>
        </tr>
        <tr>
          <td>###zotiquote###
            <a style="cursor: pointer;" cdkCopyToClipboard="###zotiquote###" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>A link to quotaion page</td>
        </tr>
        <tr>
          <td>###cus_address_list###
            <a style="cursor: pointer;" cdkCopyToClipboard="###cus_address_list###" (click)="toastr.success('var copied !');"> 
              <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
            </a>
          </td>
          <td>A List of user's addresses</td>
        </tr>
      </tbody>
    </table>
  
    <table *ngIf="eligibleVariableList!==null && eligibleVariableList!==undefined && eligibleVariableList.length>0"
    class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
    <thead class="tableheader" >
      <tr>
        <th> Response Variables</th>
        <th>Response No</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let elgVarRes of eligibleVariableList">
        <td>{{elgVarRes.var}}
          <a style="cursor: pointer;" [cdkCopyToClipboard]="elgVarRes.var" (click)="toastr.success('var copied !');"> 
            <img src="/assets/images/icons/copyText.png" width="18" height="18" alt="" loading="lazy"> 
          </a>
        </td>
        <td>{{elgVarRes.slNo}}</td>
      </tr>
    </tbody>
  </table>
      
    </div> 
  </ng-template>


  <ng-template #botAdminNotifMediaMessageInfo let-modal>
    <div [style.background-color]='getColor()' class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Media Message Format</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="color: black;">
  
      <a style="font-weight: bold;"> Format for single media :-</a>
    <br>
  <a>media_name::media_type::media_convo_flow_variable</a>    <br><br>

  <a style="font-weight: bold;"> Example : </a> <br>
  <a> Enquiry Product Image::image::###reqProdImg### </a> <br><br>

    <mat-divider></mat-divider>

    <br>

    <a style="font-weight: bold;"> Format for multiple media :- </a> <br>
    <a> media_1_name::media_1_type::media_1_convo_flow_variable;;media_2_name <br>
    ::media_2_type::media_2_convo_flow_variable </a> <br><br>

    <a style="font-weight: bold;"> Example (2 media/images): </a> <br>
    <a> Enquiry Product 1 Image::image::###reqProd1Img###;;Enquiry Product 2 Image::image::###reqProd2Img### </a><br><br>
  
    
      
    </div> 
  </ng-template>