import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BotUser } from 'src/app/BOT_CODE/bot_classes/bot-user';
import { Message } from 'src/app/BOT_CODE/bot_classes/message';
import { BotResponseServiceService } from 'src/app/BOT_CODE/bot_services/bot-response-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-parent',
  templateUrl: './user-parent.component.html',
  styleUrls: ['./user-parent.component.scss']
}) 
export class UserParentComponent implements OnInit {

  constructor(private botResponseService : BotResponseServiceService,
    private datePipe : DatePipe) { }

  botUsersObject : BotUser = new BotUser();

  botUserForChatWindow : BotUser = new BotUser();

  nodes = [
    {
      id: 1,
      name: 'root1',
      children: [
        { id: 2, name: 'child1' },
        { id: 3, name: 'child2' }
      ]
    },
    {
      id: 4,
      name: 'root2',
      children: [
        { id: 5, name: 'child2.1' },
        {
          id: 6,
          name: 'child2.2',
          children: [
            { id: 7, name: 'subsub' }
          ]
        }
      ]
    }
  ];
  options = {};

  ngOnInit(){
 
    
    }

    

}
