import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import {MatMenuModule} from '@angular/material/menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SuperadmindashboardModule } from './superadmindashboard/superadmindashboard.module';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { ViewsModule } from './views/views.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgBusyModule } from 'ng-busy';
import { NgxSpinnerModule } from "ngx-spinner";
import { ColorPickerModule } from 'ngx-color-picker';
import { BnNgIdleService } from 'bn-ng-idle';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './footer/footer.component';
import { CustomerListComponent } from './crm/customer-list/customer-list.component';
import { CreateCustomerComponent } from './crm/create-customer/create-customer.component';
import { UpdateComponent } from './crm/update/update.component';
import { CreateAddressComponent } from './crm/address/create-address/create-address.component';
import { AddressUpdateComponent } from './crm/address/address-update/address-update.component';
import { AddressListComponent } from './crm/address/address-list/address-list.component';
import { AddCompCustDetailsComponent } from './crm/compCustomer/add-comp-cust-details/add-comp-cust-details.component';
import { ListCompCustDetailsComponent } from './crm/compCustomer/list-comp-cust-details/list-comp-cust-details.component';
import { UpdateCompCustDetailsComponent } from './crm/compCustomer/update-comp-cust-details/update-comp-cust-details.component';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon'; 
import {MatRippleModule} from '@angular/material/core';
import { DataTablesModule } from 'angular-datatables';
import {MatStepperModule} from '@angular/material/stepper'; 
import {MatCardModule} from '@angular/material/card'; 
import {MatFormFieldModule} from '@angular/material/form-field'; 
import {MatExpansionModule} from '@angular/material/expansion'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatSliderModule} from '@angular/material/slider';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ChartsModule } from 'ng2-charts';
import {MatChipsModule} from '@angular/material/chips';
import {MatTreeModule} from '@angular/material/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import {MatGridListModule} from '@angular/material/grid-list'; 
import {MatDatepickerModule} from '@angular/material/datepicker';
import { CrmAdminDashboardComponent } from './dashboards/crm-admin-dashboard/crm-admin-dashboard.component';
import { CrmDataDashboardComponent } from './dashboards/crm-data-dashboard/crm-data-dashboard.component';
import { LeadListComponent } from './crm/lead/lead-list/lead-list.component';
import { AddInvoiceComponent } from './crm/invoice/add-invoice/add-invoice.component';
import { AddProductComponent } from './crm/product/add-product/add-product.component';
import { ListProductComponent } from './crm/product/list-product/list-product.component';
import { UpdateProductComponent } from './crm/product/update-product/update-product.component';
import { AddInvoiceDetailsComponent } from './crm/invoice/add-invoice-details/add-invoice-details.component';
import { ListInvoiceComponent } from './crm/invoice/list-invoice/list-invoice.component';
import { UpdateInvoiceComponent } from './crm/invoice/update-invoice/update-invoice.component';
import { ListInvoiceDetailsComponent } from './crm/invoice/list-invoice-details/list-invoice-details.component';
import { UpdateInvoiceDetailsComponent } from './crm/invoice/update-invoice-details/update-invoice-details.component';
import { AddPaymentComponent } from './crm/payment/add-payment/add-payment.component';
import { AddShipmentComponent } from './crm/shipment/add-shipment/add-shipment.component';
import { ListPaymentComponent } from './crm/payment/list-payment/list-payment.component';
import { ListShipmentComponent } from './crm/shipment/list-shipment/list-shipment.component';
import { UpdatePaymentComponent } from './crm/payment/update-payment/update-payment.component';
import { UpdateShipmentComponent } from './crm/shipment/update-shipment/update-shipment.component';
import { TestingComponentComponent } from './crm/test-maan/testing-component/testing-component.component';
import { TestAddComponentComponent } from './crm/test-maan/test-add-component/test-add-component.component';
import { ViewCompCustomerDetailsComponent } from './crm/compCustomer/view-comp-customer-details/view-comp-customer-details.component';
import { CrmLeadInfoDashboardComponent } from './dashboards/crm-lead-info-dashboard/crm-lead-info-dashboard.component';
import { CrmPaymentDashboardComponent } from './dashboards/crm-payment-dashboard/crm-payment-dashboard.component';
import { ViewPaymentDetailsComponent } from './crm/payment/view-payment-details/view-payment-details.component';
import { ViewShipmentDetailsComponent } from './crm/shipment/view-shipment-details/view-shipment-details.component';
import { CrmShipmentDashboardComponent } from './dashboards/crm-shipment-dashboard/crm-shipment-dashboard.component';
import { CrmInvoiceDashboardComponent } from './dashboards/crm-invoice-dashboard/crm-invoice-dashboard.component';
import { CrmProductDashboardComponent } from './dashboards/crm-product-dashboard/crm-product-dashboard.component';
import { ViewInvoiceDetailsComponent } from './crm/invoice/view-invoice-details/view-invoice-details.component';
import { ViewProductDetailsComponent } from './crm/product/view-product-details/view-product-details.component';
import { ClassDashboardComponent } from './dashboards/class-dashboard/class-dashboard.component';
import { AddClassComponent } from './course-management/class/add-class/add-class.component';
import { UpdateClassComponent } from './course-management/class/update-class/update-class.component';
import { ViewClassComponent } from './course-management/class/view-class/view-class.component';
import { AddSubjectComponent } from './course-management/subject/add-subject/add-subject.component';
import { AddTeacherComponent } from './course-management/teacher/add-teacher/add-teacher.component';
import { AddStudentComponent } from './course-management/student/add-student/add-student.component';
import { ViewClassTeachersComponent } from './course-management/teacher/view-class-teachers/view-class-teachers.component';
import { ViewClassStudentsComponent } from './course-management/student/view-class-students/view-class-students.component';
import { CurriculumDashboardComponent } from './dashboards/courseManagementDashboards/curriculum-dashboard/curriculum-dashboard.component';
import { SubjectDashboardComponent } from './dashboards/courseManagementDashboards/subject-dashboard/subject-dashboard.component';
import { SubjectCurriculumDashboardComponent } from './dashboards/courseManagementDashboards/subject-curriculum-dashboard/subject-curriculum-dashboard.component';
import { AddActivityPlannerComponent } from './course-management/activityPlanner/add-activity-planner/add-activity-planner.component';
import { UpdateActivityPlannerComponent } from './course-management/activityPlanner/update-activity-planner/update-activity-planner.component';
import { ViewActivityPlannerComponent } from './course-management/activityPlanner/view-activity-planner/view-activity-planner.component';
import { ParentDashboardComponent } from './tryOutFolder/dashboards-tryout/parent-dashboard/parent-dashboard.component';
import { AddCombinedParentTeacherComponent } from './course-management/combinedParentTeacher/add-combined-parent-teacher/add-combined-parent-teacher.component';
import { ShowAccountPageComponent } from './tryOutFolder/accountPage/show-account-page/show-account-page.component';
import { ViewActivitiesComponent } from './tryOutFolder/activities/view-activities/view-activities.component';
import { ShowQuestionsModalComponent } from './tryOutFolder/questionsModal/show-questions-modal/show-questions-modal.component';
import { UpdateSubjectComponent } from './course-management/subject/update-subject/update-subject.component';
import { AddMainCurrComponent } from './course-management/curriculum/add-main-curr/add-main-curr.component';
import { UpdateMainCurrComponent } from './course-management/curriculum/update-main-curr/update-main-curr.component';
import { WrongUrlMessageComponent } from './course-management/wrong-url-message/wrong-url-message.component';
import { CombinedParentTeacherDashboardComponent } from './tryOutFolder/dashboards-tryout/combined-parent-teacher-dashboard/combined-parent-teacher-dashboard.component';
import { EditCombinedParentTeacherComponent } from './course-management/combinedParentTeacher/edit-combined-parent-teacher/edit-combined-parent-teacher.component';
import { MatOptionModule } from '@angular/material/core';
import { AddAdminComponent } from './course-management/admin/add-admin/add-admin.component';
import { UserParentComponent } from './tryOutFolder/user-parent/user-parent.component';
import { AddNewInvoiceDetailsComponent } from './crm/invoice/add-new-invoice-details/add-new-invoice-details.component';
import { SwitchDashboardComponent } from './course-management/switch-dashboard/switch-dashboard.component';
import { AddActivityQuestionsComponent } from './course-management/activity-questions/add-activity-questions/add-activity-questions.component';
import { UpdateActivityQuestionsComponent } from './course-management/activity-questions/update-activity-questions/update-activity-questions.component';
import { ViewActivityQuestionsComponent } from './course-management/activity-questions/view-activity-questions/view-activity-questions.component';
import { TestGraphComponent } from './crm/test-maan/test-graph/test-graph.component';
import { AccountPageComponent } from './dashboards/combinedParentTeacher/account-page/account-page.component';
import { ResultPageComponent } from './dashboards/combinedParentTeacher/result-page/result-page.component';
import { CombinedUserParentDashboardComponent } from './dashboards/combinedParentTeacher/combined-user-parent-dashboard/combined-user-parent-dashboard.component';
import { AssignRoleComponent } from './course-management/assign-role/assign-role.component';
import { ImageEditPopupComponent } from './dashboards/combinedParentTeacher/image-edit-popup/image-edit-popup.component';
import { TeacherSubjectDashboardComponent } from './dashboards/teacherDashboards/teacher-subject-dashboard/teacher-subject-dashboard.component';
import { ViewStudentsPageComponent } from './dashboards/teacherDashboards/view-students-page/view-students-page.component';
import { ClassResultsPageComponent } from './dashboards/teacherDashboards/class-results-page/class-results-page.component';
import { CurriculumOfSubjectPageComponent } from './dashboards/teacherDashboards/curriculum-of-subject-page/curriculum-of-subject-page.component';
import { ActivityAssessmentPageComponent } from './dashboards/teacherDashboards/activity-assessment-page/activity-assessment-page.component';
import { ViewStudentInfoModalComponent } from './dashboards/teacherDashboards/view-student-info-modal/view-student-info-modal.component';
import { ViewStudentResultsModalComponent } from './dashboards/teacherDashboards/view-student-results-modal/view-student-results-modal.component';
import { ViewActivityPlannerForTeacherModalComponent } from './dashboards/teacherDashboards/view-activity-planner-for-teacher-modal/view-activity-planner-for-teacher-modal.component';
import { MarkStudentActivityScoreModalComponent } from './dashboards/teacherDashboards/mark-student-activity-score-modal/mark-student-activity-score-modal.component';
import { ActivitySwapModalComponent } from './dashboards/teacherDashboards/activity-swap-modal/activity-swap-modal.component';
import { TeacherUserProfileComponent } from './dashboards/teacherDashboards/teacher-user-profile/teacher-user-profile.component';
import { ActivityTimelinePageComponent } from './dashboards/teacherDashboards/activity-timeline-page/activity-timeline-page.component';
import { StudentActivityTimelinePageComponent } from './dashboards/teacherDashboards/student-activity-timeline-page/student-activity-timeline-page.component';
import { ActivityFeedbackQuestionsModalComponent } from './dashboards/combinedParentTeacher/activity-feedback-questions-modal/activity-feedback-questions-modal.component';
import { AddParentSkillComponent } from './course-management/skill/add-parent-skill/add-parent-skill.component';
import { EditSkillComponent } from './course-management/skill/edit-skill/edit-skill.component';
import { StudentSelectionModalComponent } from './dashboards/resultsAdmin/student-selection-modal/student-selection-modal.component';
import { AddResultsModalComponent } from './dashboards/resultsAdmin/add-results-modal/add-results-modal.component';
import { SpinnerComponent } from './spinner/spinner/spinner.component';
import { SuperCourseAdminDashboardComponent } from './dashboards/superCourseAdmin/super-course-admin-dashboard/super-course-admin-dashboard.component';
import { SuperCourseAdminProductDashboardComponent } from './dashboards/superCourseAdmin/super-course-admin-product-dashboard/super-course-admin-product-dashboard.component';
import { SuperCourseAdminSubjectDashboardComponent } from './dashboards/superCourseAdmin/super-course-admin-subject-dashboard/super-course-admin-subject-dashboard.component';
import { SuperCourseAdminCurriculumDashboardComponent } from './dashboards/superCourseAdmin/super-course-admin-curriculum-dashboard/super-course-admin-curriculum-dashboard.component';
import { StudentSubjectDashboardComponent } from './dashboards/studentDashboards/student-subject-dashboard/student-subject-dashboard.component';
import { StudentCourseDashboardComponent } from './dashboards/studentDashboards/student-course-dashboard/student-course-dashboard.component';
import { StudentCurriculumDashboardComponent } from './dashboards/studentDashboards/student-curriculum-dashboard/student-curriculum-dashboard.component';
import { ClassAdminDashboardComponent } from './dashboards/classDashboards/class-admin-dashboard/class-admin-dashboard.component';
import { ClassAdminTeacherDashboardComponent } from './dashboards/classDashboards/class-admin-teacher-dashboard/class-admin-teacher-dashboard.component';
import { ClassAdminStudentDashboardComponent } from './dashboards/classDashboards/class-admin-student-dashboard/class-admin-student-dashboard.component';
import { ClassAdminSubscriptionDashboardComponent } from './dashboards/classDashboards/class-admin-subscription-dashboard/class-admin-subscription-dashboard.component';
import { SubscriptionModalComponent } from './dashboards/classDashboards/subscription-modal/subscription-modal.component';
import { AddNewSubscriptionModalComponent } from './dashboards/classDashboards/add-new-subscription-modal/add-new-subscription-modal.component';
import { EditSubscriptionModalComponent } from './dashboards/classDashboards/edit-subscription-modal/edit-subscription-modal.component';
import { AddUserModalComponent } from './dashboards/classDashboards/add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from './dashboards/classDashboards/edit-user-modal/edit-user-modal.component';
import { ClassViewPageComponent } from './dashboards/classDashboards/class-view-page/class-view-page.component';
import { AssignmentDashboardComponent } from './dashboards/teacherDashboards/assignment-dashboard/assignment-dashboard.component';
import { ChooseAssignmentModalComponent } from './dashboards/teacherDashboards/choose-assignment-modal/choose-assignment-modal.component';
import { AddInstantAssignmentModalComponent } from './dashboards/teacherDashboards/add-instant-assignment-modal/add-instant-assignment-modal.component';
import { StartAssignmentModalComponent } from './dashboards/teacherDashboards/start-assignment-modal/start-assignment-modal.component';
import { StudentAssignmentDashboardComponent } from './dashboards/studentDashboards/student-assignment-dashboard/student-assignment-dashboard.component';
import { AssignmentSubmissionDashboardComponent } from './dashboards/studentDashboards/assignment-submission-dashboard/assignment-submission-dashboard.component';
import { AddActivityVideoComponent } from './course-management/activityVideo/add-activity-video/add-activity-video.component';
import { EditActivityVideoComponent } from './course-management/activityVideo/edit-activity-video/edit-activity-video.component';
import { RecurringActivityAccessmentModalComponent } from './dashboards/parentDashboards/recurring-activity-accessment-modal/recurring-activity-accessment-modal.component';
import { ViewStudentAssignmentSubmissionModalComponent } from './dashboards/teacherDashboards/view-student-assignment-submission-modal/view-student-assignment-submission-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SuperSkillResultsDashboardComponent } from './dashboards/resultsAdmin/super-skill-results-dashboard/super-skill-results-dashboard.component';
import { SuperSkillReportDashboardComponent } from './dashboards/resultsAdmin/super-skill-report-dashboard/super-skill-report-dashboard.component';
import { EditResultsModalComponent } from './dashboards/resultsAdmin/edit-results-modal/edit-results-modal.component';
import { ChangeCurriculumOrderComponent } from './course-management/curriculum/change-curriculum-order/change-curriculum-order.component';
import { ClassSkillReportsComponent } from './dashboards/teacherDashboards/class-skill-reports/class-skill-reports.component';
import { EditActiveAssignmentComponent } from './dashboards/teacherDashboards/edit-active-assignment/edit-active-assignment.component';
import { ViewSkillComponent } from './course-management/skill/view-skill/view-skill.component';
import { UserSearchModalComponent } from './dashboards/classDashboards/user-search-modal/user-search-modal.component';
import { DatePipe } from '@angular/common';
import { ViewParentOrChildDetailsModalComponent } from './dashboards/classDashboards/view-parent-or-child-details-modal/view-parent-or-child-details-modal.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {OverlayModule} from '@angular/cdk/overlay';
import { TeacherClassSubjectsModalComponent } from './dashboards/classDashboards/teacher-class-subjects-modal/teacher-class-subjects-modal.component';
import { ProfileModalComponent } from './dashboards/classDashboards/profile-modal/profile-modal.component';
import { TeacherClassAssignModalComponent } from './dashboards/classDashboards/teacher-class-assign-modal/teacher-class-assign-modal.component';
import { AddStudentModalComponent } from './dashboards/classDashboards/add-student-modal/add-student-modal.component';
import { AddParentComponent } from './dashboards/classDashboards/add-parent/add-parent.component';
import { CustomerProfileModalComponent } from './dashboards/crmDashboards/customer-profile-modal/customer-profile-modal.component';
import { EmailCheckModalComponent } from './dashboards/crmDashboards/email-check-modal/email-check-modal.component';
import { ChildClassDashboardComponent } from './dashboards/parentDashboards/child-class-dashboard/child-class-dashboard.component';
import { ChildSubjectsDashboardComponent } from './dashboards/parentDashboards/child-subjects-dashboard/child-subjects-dashboard.component';
import { ChildCourseDashboardComponent } from './dashboards/parentDashboards/child-course-dashboard/child-course-dashboard.component';
import { ChildCurriculumDashboardComponent } from './dashboards/parentDashboards/child-curriculum-dashboard/child-curriculum-dashboard.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { GenerateActivityQuestionsComponent } from './dashboards/courseManagementDashboards/generate-activity-questions/generate-activity-questions.component';
import { StudentSkillReportPageComponent } from './dashboards/studentDashboards/student-skill-report-page/student-skill-report-page.component';
import {MatDialogModule} from '@angular/material/dialog';
import { LogoutWarningModalComponent } from './logout/logout-warning-modal/logout-warning-modal.component';
import { BcrmExeHomeComponent } from './BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-home/bcrm-exe-home.component';
import { BcrmExeLeadsComponent } from './BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-leads/bcrm-exe-leads.component';
import { AddCustomerComponent } from './BIZ_CRM/biz_crm_Modals/Customer/add-customer/add-customer.component';
import { EditCustomerComponent } from './BIZ_CRM/biz_crm_Modals/Customer/edit-customer/edit-customer.component';
import { EmailCheckComponent } from './BIZ_CRM/biz_crm_Modals/email/email-check/email-check.component';
import { BcrmExeCustomersComponent } from './BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-customers/bcrm-exe-customers.component';
import { ProfileComponent } from './BIZ_CRM/biz_crm_Modals/Customer/profile/profile.component';
import { AddCustomerCommunicationsComponent } from './BIZ_CRM/biz_crm_Modals/customerCommunication/add-customer-communications/add-customer-communications.component';
import { EditComponent } from './BIZ_CRM/biz_crm_Modals/customerCommunication/edit/edit.component';
import { BcrmManHomeComponent } from './BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-home/bcrm-man-home.component';
import { BcrmManCustomersComponent } from './BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-customers/bcrm-man-customers.component';
import { BcrmManLeadsComponent } from './BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-leads/bcrm-man-leads.component';
import { AddBcrmUserComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_user/add-bcrm-user/add-bcrm-user.component';
import { EditBcrmUserComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_user/edit-bcrm-user/edit-bcrm-user.component';
import { AssignBcrmRoleComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_roles/assign-bcrm-role/assign-bcrm-role.component';
import { AssignReportingHeadComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_roles/assign-reporting-head/assign-reporting-head.component';
import { ResetPasswordComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_user/reset-password/reset-password.component';
import { BcrmExeOrdersComponent } from './BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-orders/bcrm-exe-orders.component';
import { AddNewOrderComponent } from './BIZ_CRM/biz_crm_Modals/orders/add-new-order/add-new-order.component';
import { AddOrderDetailsComponent } from './BIZ_CRM/biz_crm_Modals/orders/add-order-details/add-order-details.component';
import { ChangeCustomerOwnerComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_roles/change-customer-owner/change-customer-owner.component';
import { BcrmDmHomeComponent } from './BIZ_CRM/biz_dashboards/bcrm_dm/bcrm-dm-home/bcrm-dm-home.component';
import { BcrmFmHomeComponent } from './BIZ_CRM/biz_dashboards/bcrm_fm/bcrm-fm-home/bcrm-fm-home.component';
import { AddBcrmShipmentComponent } from './crm/shipment/add-bcrm-shipment/add-bcrm-shipment.component';
import { OptionsModalComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_options/options-modal/options-modal.component';
import { BcrmExeTargetsComponent } from './BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-targets/bcrm-exe-targets.component';
import { BcrmManTargetsComponent } from './BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-targets/bcrm-man-targets.component';
import { ViewTargetBasedInvoicesComponent } from './BIZ_CRM/biz_crm_Modals/orders/view-target-based-invoices/view-target-based-invoices.component';
import { ProfilePageComponent } from './BIZ_CRM/biz_crm_Modals/Customer/profile-page/profile-page.component';
import { BcrmManOrdersComponent } from './BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-orders/bcrm-man-orders.component';
import { BcrmDataHomeComponent } from './BIZ_CRM/biz_dashboards/bcrm_data/bcrm-data-home/bcrm-data-home.component';
import { AddBcrmDataComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_data/add-bcrm-data/add-bcrm-data.component';
import { BcrmUserProfileComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_user/bcrm-user-profile/bcrm-user-profile.component';
import { AddLocationGroupComponent } from './BIZ_CRM/biz_crm_Modals/locatinGroup/add-location-group/add-location-group.component';
import { UpdateLocationGroupComponent } from './BIZ_CRM/biz_crm_Modals/locatinGroup/update-location-group/update-location-group.component';
import { ViewLocationGroupBasedInvoicesComponent } from './BIZ_CRM/biz_crm_Modals/orders/view-location-group-based-invoices/view-location-group-based-invoices.component';
import { SearchOrdersByPeriodComponent } from './BIZ_CRM/biz_crm_Modals/orders/search-orders-by-period/search-orders-by-period.component';
import { ViewOrdersTableComponent } from './BIZ_CRM/biz_crm_Modals/orders/view-orders-table/view-orders-table.component';
import { AddAnnouncementComponent } from './BIZ_CRM/biz_crm_Modals/announcement/add-announcement/add-announcement.component';
import { ViewAnnouncementComponent } from './BIZ_CRM/biz_crm_Modals/announcement/view-announcement/view-announcement.component';
import { EditAnnouncementComponent } from './BIZ_CRM/biz_crm_Modals/announcement/edit-announcement/edit-announcement.component';
import { AddTaxComponent } from './BIZ_CRM/biz_crm_Modals/tax/add-tax/add-tax.component';
import { EditTaxComponent } from './BIZ_CRM/biz_crm_Modals/tax/edit-tax/edit-tax.component';
import { ChooseTaxesComponent } from './BIZ_CRM/biz_crm_Modals/tax/choose-taxes/choose-taxes.component';
import { ViewOrderDetailsComponent } from './BIZ_CRM/biz_crm_Modals/orders/view-order-details/view-order-details.component';
import { EditOrderComponent } from './BIZ_CRM/biz_crm_Modals/orders/edit-order/edit-order.component';
import { AddIncentiveTierComponent } from './BIZ_CRM/biz_crm_Modals/incentiveTier/add-incentive-tier/add-incentive-tier.component';
import { EditIncentiveTierComponent } from './BIZ_CRM/biz_crm_Modals/incentiveTier/edit-incentive-tier/edit-incentive-tier.component';
import { ViewGroupedIncentiveTiersComponent } from './BIZ_CRM/biz_crm_Modals/incentiveTier/view-grouped-incentive-tiers/view-grouped-incentive-tiers.component';
import { AddNewOrderDetailsComponent } from './BIZ_CRM/biz_crm_Modals/orders/add-new-order-details/add-new-order-details.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatNativeDateModule } from '@angular/material/core';
import { NavigationOptionsComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_options/navigation-options/navigation-options.component';
import { CreateFeedbackFormComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-form/create-feedback-form/create-feedback-form.component';
import { EditFeedbackFormComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-form/edit-feedback-form/edit-feedback-form.component';
import { CreateFeedbackQuestionComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-question/create-feedback-question/create-feedback-question.component';
import { EditFeedbackQuestionComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-question/edit-feedback-question/edit-feedback-question.component';
import { ViewFeedbackQuestionComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-question/view-feedback-question/view-feedback-question.component';
import { ViewFeedbackFormComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-form/view-feedback-form/view-feedback-form.component';
import { BcrmFeedbackDashboardComponent } from './BIZ_CRM_FEEDBACK/dashboards/bcrm-feedback-dashboard/bcrm-feedback-dashboard.component';
import { CreateFeedbackAnswerComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-answer/create-feedback-answer/create-feedback-answer.component';
import { ViewFeedbackAnswerComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-answer/view-feedback-answer/view-feedback-answer.component';
import { EditFeedbackAnswerComponent } from './BIZ_CRM_FEEDBACK/modals/feedback-answer/edit-feedback-answer/edit-feedback-answer.component';
import { ExternalFeedbackPageComponent } from './BIZ_CRM_FEEDBACK/dashboards/external-feedback-page/external-feedback-page.component';
import { CreateFormActiveComponent } from './BIZ_CRM_FEEDBACK/modals/form-active/create-form-active/create-form-active.component';
import { ViewFormActivesComponent } from './BIZ_CRM_FEEDBACK/modals/form-active/view-form-actives/view-form-actives.component';
import { EditFormActiveComponent } from './BIZ_CRM_FEEDBACK/modals/form-active/edit-form-active/edit-form-active.component';
import { FeedbackFormModalComponent } from './BIZ_CRM_FEEDBACK/feedback-form-modal/feedback-form-modal/feedback-form-modal.component';
import {MatInputModule} from '@angular/material/input';
import { FormActiveDashboardComponent } from './BIZ_CRM_FEEDBACK/dashboards/form-active-dashboard/form-active-dashboard.component';
import { ViewFormResponsesComponent } from './BIZ_CRM_FEEDBACK/modals/form-response/view-form-responses/view-form-responses.component';
import { CreateTicketSupportDefinitionComponent } from './BIZ_CRM_TICKET_SUPPORT/modals/TicketSupportDefinition/create-ticket-support-definition/create-ticket-support-definition.component';
import { EditTicketSupportDefinitionComponent } from './BIZ_CRM_TICKET_SUPPORT/modals/TicketSupportDefinition/edit-ticket-support-definition/edit-ticket-support-definition.component';
import { ViewTicketSupportDefinitionComponent } from './BIZ_CRM_TICKET_SUPPORT/modals/TicketSupportDefinition/view-ticket-support-definition/view-ticket-support-definition.component';
import { TicketSupportFormComponent } from './BIZ_CRM_TICKET_SUPPORT/dashboard/ticket-support-form/ticket-support-form.component';
import { CreateTicketActionComponent } from './BIZ_CRM_TICKET_SUPPORT/modals/ticket-action/create-ticket-action/create-ticket-action.component';
import { ExternalTicketSupportDashboardComponent } from './BIZ_CRM_TICKET_SUPPORT/dashboard/external-ticket-support-dashboard/external-ticket-support-dashboard.component';
import { ExternalTicketSupportFormComponent } from './BIZ_CRM_TICKET_SUPPORT/dashboard/external-ticket-support-form/external-ticket-support-form.component';
import { BcrmExeDueOrdersComponent } from './BIZ_CRM/biz_dashboards/bcrm_exe/bcrm-exe-due-orders/bcrm-exe-due-orders.component';
import { ViewCustomerCommunicationsComponent } from './BIZ_CRM/biz_crm_Modals/customerCommunication/view-customer-communications/view-customer-communications.component';
import { ViewProductBasedInvoicesComponent } from './BIZ_CRM/biz_crm_Modals/orders/view-product-based-invoices/view-product-based-invoices.component';
import { CreateNotificationSettingComponent } from './NOTIFICATION/modals/notificationSetting/create-notification-setting/create-notification-setting.component';
import { EditNotificationSettingComponent } from './NOTIFICATION/modals/notificationSetting/edit-notification-setting/edit-notification-setting.component';
import { EditNotificationMasterComponent } from './NOTIFICATION/modals/notificationMaster/edit-notification-master/edit-notification-master.component';
import { CreateNotificationMasterComponent } from './NOTIFICATION/modals/notificationMaster/create-notification-master/create-notification-master.component';
import { CreateNotificationDetailsComponent } from './NOTIFICATION/modals/notificationDetails/create-notification-details/create-notification-details.component';
import { EditNotificationDetailsComponent } from './NOTIFICATION/modals/notificationDetails/edit-notification-details/edit-notification-details.component';
import { ViewNotificationDetailsComponent } from './NOTIFICATION/modals/notificationDetails/view-notification-details/view-notification-details.component';
import { OfficeNotificationDashboardComponent } from './NOTIFICATION/dashboard/office-notification-dashboard/office-notification-dashboard.component';
import { BcrmManDueOrdersComponent } from './BIZ_CRM/biz_dashboards/bcrm_man/bcrm-man-due-orders/bcrm-man-due-orders.component';
import { ClassActivitiesDashboardComponent } from './dashboards/teacherDashboards/class-activities-dashboard/class-activities-dashboard.component';
import { StudentCreationFileUploadModalComponent } from './dashboards/classDashboards/student-creation-file-upload-modal/student-creation-file-upload-modal.component';
import { AddFormConfigComponent } from './views/formConfig/add-form-config/add-form-config.component';
import { EditFormConfigComponent } from './views/formConfig/edit-form-config/edit-form-config.component';
import { ResetBcrmDataComponent } from './BIZ_CRM/biz_crm_Modals/bcrm_data/reset-bcrm-data/reset-bcrm-data.component';
import { ViewOldInvoicesComponent } from './BIZ_CRM/biz_crm_Modals/orders/view-old-invoices/view-old-invoices.component';
import { CrmSubscriptionsDashboardComponent } from './dashboards/crm-subscriptions-dashboard/crm-subscriptions-dashboard.component';
import { CreatePaymentComponent } from './crm/payment/create-payment/create-payment.component';
import { MarkAttendanceComponent } from './dashboards/teacherDashboards/mark-attendance/mark-attendance.component';
import { ViewAttendanceComponent } from './dashboards/teacherDashboards/view-attendance/view-attendance.component';
import { StudentCourseActivitiesDetailsComponent } from './dashboards/classDashboards/student-course-activities-details/student-course-activities-details.component';
import { BcrmFeedbackManagerTicketSupportComponent } from './BIZ_CRM_FEEDBACK/dashboards/bcrm-feedback-manager-ticket-support/bcrm-feedback-manager-ticket-support.component';
import { ConvertToCustomerUserComponent } from './crm/compCustomer/convert-to-customer-user/convert-to-customer-user.component';
import { UsernameCheckModalComponent } from './dashboards/crmDashboards/username-check-modal/username-check-modal.component';
import { ParentHomeDashboardComponent } from './dashboards/parentDashboards/parent-home-dashboard/parent-home-dashboard.component';
import { AddChildToParentMentorComponent } from './course-management/combinedParentTeacher/add-child-to-parent-mentor/add-child-to-parent-mentor.component';
import { ParentMentorHomeComponent } from './dashboards/combinedParentTeacher/parent-mentor-home/parent-mentor-home.component';
import { ComputeTaxToApproveOrderComponent } from './BIZ_CRM/biz_crm_Modals/tax/compute-tax-to-approve-order/compute-tax-to-approve-order.component';
import { CbSuperAdminHomeComponent } from './BOT_CODE/bot_dashboards/cb_super_admin/cb-super-admin-home/cb-super-admin-home.component';
import { CbOfficeAdminHomeComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/cb-office-admin-home/cb-office-admin-home.component';
import { AddBotResponseOptionsComponent } from './BOT_CODE/bot_modals/bot_response_options/add-bot-response-options/add-bot-response-options.component';
import { AddBotResponseComponent } from './BOT_CODE/bot_modals/bot_response/add-bot-response/add-bot-response.component';
import { ViewBotOptionDetailsComponent } from './BOT_CODE/bot_modals/bot_response_options/view-bot-option-details/view-bot-option-details.component';
import { AddBotUserComponent } from './BOT_CODE/bot_modals/bot_user/add-bot-user/add-bot-user.component';
import { CbAConvoLogViewComponent } from './BOT_CODE/bot_dashboards/cb_agent/cb-a-convo-log-view/cb-a-convo-log-view.component';
import { ViewDefinitionsComponent } from './BOT_CODE/bot_modals/bot_tree_definitions/view-definitions/view-definitions.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { CbOfficeAdminDashboardComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/cb-office-admin-dashboard/cb-office-admin-dashboard.component';
import { ExtActivityAssessmentPageComponent } from './dashboards/combinedParentTeacher/ext/ext-activity-assessment-page/ext-activity-assessment-page.component';
import { ExtActivityFeedbackQsnsModalComponent } from './dashboards/combinedParentTeacher/ext/ext-activity-feedback-qsns-modal/ext-activity-feedback-qsns-modal.component';
import { OverviewModalComponent } from './dashboards/teacherDashboards/overview-modal/overview-modal.component';
import { CbOrgAdminHomeComponent } from './BOT_CODE/bot_dashboards/cb_org_admin/cb-org-admin-home/cb-org-admin-home.component';
import { AddWcaSubscriptionComponent } from './BOT_CODE/Whatapp_Cloud_API_Control/modals/wca_subscription/add-wca-subscription/add-wca-subscription.component';
import { AppSetupComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/app-setup/app-setup.component';
import { SetUpComponent } from './BOT_CODE/bot_modals/app_set_up/set-up/set-up.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { BotResponseDetailsComponent } from './BOT_CODE/bot_modals/bot_response/bot-response-details/bot-response-details.component';
import { DeleteBotResponseComponent } from './BOT_CODE/bot_modals/bot_response/delete-bot-response/delete-bot-response.component';
import { AdminNotificationsHomeComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/admin-notifications-home/admin-notifications-home.component';
import { BotCustomerUsersComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-customer-users/bot-customer-users.component';
import { TimezoneSelectorModalComponent } from './BOT_CODE/bot_modals/timezone_modal/timezone-selector-modal/timezone-selector-modal.component';
import { GetGlobalTempResComponent } from './BOT_CODE/bot_modals/global_template_response/get-global-temp-res/get-global-temp-res.component';
import { BotScheduleHomeComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-schedule-home/bot-schedule-home.component';
import { ScheduleMessagesComponent } from './BOT_CODE/bot_modals/schedule-messages/schedule-messages/schedule-messages.component';
import { ViewScheduleDetailsComponent } from './BOT_CODE/bot_modals/schedule-messages/view-schedule-details/view-schedule-details.component';
import { UploadBotMediaComponent } from './BOT_CODE/bot_modals/bot-media/upload-bot-media/upload-bot-media.component';
import { ViewBotMediaComponent } from './BOT_CODE/bot_modals/bot-media/view-bot-media/view-bot-media.component';
import { CreateResLangVariationComponent } from './BOT_CODE/bot_modals/language_variation/res_language_variation/create-res-lang-variation/create-res-lang-variation.component';
import { ViewResLangVariationsComponent } from './BOT_CODE/bot_modals/language_variation/res_language_variation/view-res-lang-variations/view-res-lang-variations.component';
import { ViewOptionLangVariationsComponent } from './BOT_CODE/bot_modals/language_variation/option_language_variation/view-option-lang-variations/view-option-lang-variations.component';
import { CreateOptionLangVariationsComponent } from './BOT_CODE/bot_modals/language_variation/option_language_variation/create-option-lang-variations/create-option-lang-variations.component';
import { CreateBotApiConfigComponent } from './BOT_CODE/bot_modals/bot_api_configuration/create-bot-api-config/create-bot-api-config.component';
import { BotShopHomeComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot_shop/bot-shop-home/bot-shop-home.component';
import { CreateBotCategoryComponent } from './BOT_CODE/bot_modals/bot_category/create-bot-category/create-bot-category.component';
import { CreateBotItemComponent } from './BOT_CODE/bot_modals/bot_item/create-bot-item/create-bot-item.component';
import { ViewBotCategoriesComponent } from './BOT_CODE/bot_modals/bot_category/view-bot-categories/view-bot-categories.component';
import { BotShopExtHomeComponent } from './BOT_CODE/bot_dashboards/bot_shop_ext/bot-shop-ext-home/bot-shop-ext-home.component';
import { ExtBotShoppingPageComponent } from './BOT_CODE_EXT_SHOP/bot_pages/ext-shop/ext-bot-shopping-page/ext-bot-shopping-page.component';
import { BotShopOrdersComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot_shop/bot-shop-orders/bot-shop-orders.component';
import { InvoiceDetailsViewComponent } from './BOT_CODE/bot_modals/bot_invoice_details/invoice-details-view/invoice-details-view.component';
import { BotFormManagementHomeComponent } from './BOT_CODE/bot_dashboards/bot_forms/bot-form-management-home/bot-form-management-home.component';
import { ViewBotFormActivesComponent } from './BOT_CODE/bot_modals/bot_forms/view-bot-form-actives/view-bot-form-actives.component';
import { CreateBotFormActiveComponentComponent } from './BOT_CODE/bot_modals/bot_forms/create-bot-form-active-component/create-bot-form-active-component.component';
import { BotUserExtFormPageComponent } from './BOT_CODE_EXT_FORMS/bot_dashboard/bot-user-ext-form-page/bot-user-ext-form-page.component';
import { BotFormActiveDashboardComponent } from './BOT_CODE/bot_dashboards/bot_forms/bot-form-active-dashboard/bot-form-active-dashboard.component';
import { EditBotFormActiveComponent } from './BOT_CODE/bot_modals/bot_forms/edit-bot-form-active/edit-bot-form-active.component';
import { BotFormActiveHistoryComponent } from './BOT_CODE/bot_dashboards/bot_forms/bot-form-active-history/bot-form-active-history.component';
import { ViewBotMediaForAgentComponent } from './BOT_CODE/bot_modals/bot-media/view-bot-media-for-agent/view-bot-media-for-agent.component';
import { ResponseOptionSettingsComponent } from './BOT_CODE/bot_modals/bot_response_options/response-option-settings/response-option-settings.component';
import { BotAdminsManagementComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-admins-management/bot-admins-management.component';
import { CbNotificationsHomeComponent } from './BOT_CODE/bot_dashboards/cb_notifications_manager/cb-notifications-home/cb-notifications-home.component';
import { CbShopOrdersComponent } from './BOT_CODE/bot_dashboards/cb_shop_orders_manager/cb-shop-orders/cb-shop-orders.component';
import { MedicalSpecialistsManagementDashboardComponent } from './BOT_CODE/bot_dashboards/bot_members_management/medical-specialists-management-dashboard/medical-specialists-management-dashboard.component';
import { CreateBotMemberComponent } from './BOT_CODE/bot_modals/bot_member_info/create-bot-member/create-bot-member.component';
import { FileUploadComponent } from './BOT_CODE/bot_modals/bot_member_info/file-upload/file-upload.component';
import { ViewBotFormResponsesComponent } from './BOT_CODE/bot_modals/bot_forms/view-bot-form-responses/view-bot-form-responses.component';
import { ExtMediaViewPageComponent } from './BOT_CODE_EXT_MEDIA/dashboards/ext-media-view-page/ext-media-view-page.component';
import { ExtMediaViewModalComponent } from './BOT_CODE_EXT_MEDIA/modals/ext-media-view-modal/ext-media-view-modal.component';
import { WebSocketTestComponent } from './BOT_CODE/web-socket-test/web-socket-test.component';
import { CbOfficeUsersConversationsComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/cb-office-users-conversations/cb-office-users-conversations.component';
import { BotUserFileUploadComponent } from './BOT_CODE/bot_modals/bot_user/bot-user-file-upload/bot-user-file-upload.component';
import { BotUserGroupModalComponent } from './BOT_CODE/bot_modals/bot_user_group/bot-user-group-modal/bot-user-group-modal.component';
import { BotTemplatesManagementComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-templates-management/bot-templates-management.component';
// import { CreateBotTemplateModalComponent } from "./BOT_CODE/bot_modals/bot_template_modals/create-bot-template-modal/CreateBotTemplateModalComponent";
import { BotApiConfigsManagementComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-api-configs-management/bot-api-configs-management.component';
import { SendTemplateMessageComponent } from './BOT_CODE/bot_modals/bot_template_modals/send-template-message/send-template-message.component';
import { BotConvoFlowTreeComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-convo-flow-tree/bot-convo-flow-tree.component';
import { CreateBotAutoSchedulerSettingsComponent } from './BOT_CODE/bot_modals/bot_auto_scheduler_settings/create-bot-auto-scheduler-settings/create-bot-auto-scheduler-settings.component';
import { BotAutoScheduleSettingsDashboardComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/bot-auto-schedule-settings-dashboard/bot-auto-schedule-settings-dashboard.component';
import { BotUserLocationModalComponent } from './BOT_CODE/bot_modals/bot_user_location/bot-user-location-modal/bot-user-location-modal.component';
import { VoiceRecorderModalComponent } from './BOT_CODE/bot_modals/voice_recording/voice-recorder-modal/voice-recorder-modal.component';
import { AudioRecordingService } from './BOT_CODE/bot_classes/audio-recording-service';
import { BotItemsFileUploadComponent } from './BOT_CODE/bot_modals/bot_item/bot-items-file-upload/bot-items-file-upload.component';
import { CbOfficeAdminsConversationsComponent } from './BOT_CODE/bot_dashboards/cb_office_admin/cb-office-admins-conversations/cb-office-admins-conversations.component';
import { CbOrganizationAdminHomeComponent } from './BOT_CODE/bot_dashboards/cb_organization_admin/cb-organization-admin-home/cb-organization-admin-home.component';
import { CrmListsDashboardComponent } from './CRM_MODULE/dashboards/crm-lists-dashboard/crm-lists-dashboard.component';
import { CrmListsModalComponent } from './CRM_MODULE/modals/crm-lists-modal/crm-lists-modal.component';
import { CrmTasksModalComponent } from './CRM_MODULE/modals/crm-tasks-modal/crm-tasks-modal.component';
import { CrmAgentListsDashboardComponent } from './CRM_MODULE/dashboards/crm-agent-lists-dashboard/crm-agent-lists-dashboard.component';
import { CreateBotTemplateModalComponent } from './BOT_CODE/bot_modals/bot_template_modals/create-bot-template-modal/create-bot-template-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CustomerListComponent,
    CreateCustomerComponent,
    UpdateComponent,
    CreateAddressComponent,
    AddressUpdateComponent,
   AddressListComponent,
   AddCompCustDetailsComponent,
   ListCompCustDetailsComponent,
   UpdateCompCustDetailsComponent,
   CrmAdminDashboardComponent,
   CrmDataDashboardComponent,
   LeadListComponent,
   AddInvoiceComponent,
   AddProductComponent,
   ListProductComponent,
   UpdateProductComponent,
   AddInvoiceDetailsComponent,
   ListInvoiceComponent,
   UpdateInvoiceComponent,
   ListInvoiceDetailsComponent,
   UpdateInvoiceDetailsComponent,
   AddPaymentComponent,
   AddShipmentComponent,
   ListPaymentComponent,
   ListShipmentComponent,
   UpdatePaymentComponent,
   UpdateShipmentComponent,
   TestingComponentComponent,
   TestAddComponentComponent,
   ViewCompCustomerDetailsComponent,
   CrmLeadInfoDashboardComponent,
   CrmPaymentDashboardComponent,
   ViewPaymentDetailsComponent,
   ViewShipmentDetailsComponent,
   CrmShipmentDashboardComponent,
   CrmInvoiceDashboardComponent,
   CrmProductDashboardComponent,
   ViewInvoiceDetailsComponent,
   ViewProductDetailsComponent,
   ClassDashboardComponent,
   AddClassComponent,
   UpdateClassComponent,
   ViewClassComponent,
   AddSubjectComponent,
   AddTeacherComponent,
   AddStudentComponent,
   ViewClassTeachersComponent,
   ViewClassStudentsComponent,
   CurriculumDashboardComponent,
   SubjectDashboardComponent,
   SubjectCurriculumDashboardComponent,
   AddActivityPlannerComponent,
   UpdateActivityPlannerComponent,
   ViewActivityPlannerComponent,
   ParentDashboardComponent,
   AddCombinedParentTeacherComponent,
   ShowAccountPageComponent,
   ViewActivitiesComponent,
   ShowQuestionsModalComponent,
   UpdateSubjectComponent,
   AddMainCurrComponent,
   UpdateMainCurrComponent,
   WrongUrlMessageComponent,
   CombinedParentTeacherDashboardComponent,
   EditCombinedParentTeacherComponent,
   AddAdminComponent,
   UserParentComponent,
   AddNewInvoiceDetailsComponent,
   SwitchDashboardComponent,
   AddActivityQuestionsComponent,
   UpdateActivityQuestionsComponent,
   ViewActivityQuestionsComponent,
   TestGraphComponent,
   AccountPageComponent,
   ResultPageComponent,
   CombinedUserParentDashboardComponent,
   AssignRoleComponent,
   ImageEditPopupComponent,
   TeacherSubjectDashboardComponent,
   ViewStudentsPageComponent,
   ClassResultsPageComponent,
   CurriculumOfSubjectPageComponent,
   ActivityAssessmentPageComponent,
   ViewStudentInfoModalComponent,
   ViewStudentResultsModalComponent,
   ViewActivityPlannerForTeacherModalComponent,
   MarkStudentActivityScoreModalComponent,
   ActivitySwapModalComponent,
   TeacherUserProfileComponent,
   ActivityTimelinePageComponent,
   StudentActivityTimelinePageComponent,
   ActivityFeedbackQuestionsModalComponent,
   AddParentSkillComponent,
   EditSkillComponent,
   StudentSelectionModalComponent,
   AddResultsModalComponent,
   SpinnerComponent,
  SuperCourseAdminDashboardComponent,
  SuperCourseAdminProductDashboardComponent,
  SuperCourseAdminSubjectDashboardComponent,
  SuperCourseAdminCurriculumDashboardComponent,
  StudentSubjectDashboardComponent,
  StudentCourseDashboardComponent,
  StudentCurriculumDashboardComponent,
  ClassAdminDashboardComponent,
  ClassAdminTeacherDashboardComponent,
  ClassAdminStudentDashboardComponent,
  ClassAdminSubscriptionDashboardComponent,
  SubscriptionModalComponent,
  AddNewSubscriptionModalComponent,
  EditSubscriptionModalComponent,
  AddUserModalComponent,
  EditUserModalComponent,
  ClassViewPageComponent,
  AssignmentDashboardComponent,
  ChooseAssignmentModalComponent,
  AddInstantAssignmentModalComponent,
  StartAssignmentModalComponent,
  StudentAssignmentDashboardComponent,
  AssignmentSubmissionDashboardComponent,
  AddActivityVideoComponent,
  EditActivityVideoComponent,
  RecurringActivityAccessmentModalComponent,
  ViewStudentAssignmentSubmissionModalComponent,
  // ResultsAdminComponent,
  SuperSkillResultsDashboardComponent,
  SuperSkillReportDashboardComponent,
  EditResultsModalComponent,
  ChangeCurriculumOrderComponent,
  ClassSkillReportsComponent,
  EditActiveAssignmentComponent,
  ViewSkillComponent,
  UserSearchModalComponent,
  ViewParentOrChildDetailsModalComponent,
  TeacherClassSubjectsModalComponent,
  ProfileModalComponent,
  TeacherClassAssignModalComponent,
  AddStudentModalComponent,
  AddParentComponent,
  CustomerProfileModalComponent,
  EmailCheckModalComponent,
  ChildClassDashboardComponent,
  ChildSubjectsDashboardComponent,
  ChildCourseDashboardComponent,
  ChildCurriculumDashboardComponent,
  GenerateActivityQuestionsComponent,
  StudentSkillReportPageComponent,
  LogoutWarningModalComponent,
  BcrmExeHomeComponent,
  BcrmExeLeadsComponent,
  AddCustomerComponent,
  EditCustomerComponent,
  EmailCheckComponent,
  BcrmExeCustomersComponent,
  ProfileComponent,
  AddCustomerCommunicationsComponent,
  EditComponent,
  BcrmManHomeComponent,
  BcrmManCustomersComponent,
  BcrmManLeadsComponent,
  AddBcrmUserComponent,
  EditBcrmUserComponent,
  AssignBcrmRoleComponent,
  AssignReportingHeadComponent,
  ResetPasswordComponent,
  BcrmExeOrdersComponent,
  AddNewOrderComponent,
  AddOrderDetailsComponent,
  ChangeCustomerOwnerComponent,
  BcrmDmHomeComponent,
  BcrmFmHomeComponent,
  AddBcrmShipmentComponent,
  OptionsModalComponent,
  BcrmExeTargetsComponent,
  BcrmManTargetsComponent,
  ViewTargetBasedInvoicesComponent,
  ProfilePageComponent,
  BcrmManOrdersComponent,
  BcrmDataHomeComponent,
  AddBcrmDataComponent,
  BcrmUserProfileComponent,
  AddLocationGroupComponent,
  UpdateLocationGroupComponent,
  ViewLocationGroupBasedInvoicesComponent,
  SearchOrdersByPeriodComponent,
  ViewOrdersTableComponent,
  AddAnnouncementComponent,
  ViewAnnouncementComponent,
  EditAnnouncementComponent,
  AddTaxComponent,
  EditTaxComponent,
  ChooseTaxesComponent,
  ViewOrderDetailsComponent,
  EditOrderComponent,
  AddIncentiveTierComponent,
  EditIncentiveTierComponent,
  ViewGroupedIncentiveTiersComponent,
  AddNewOrderDetailsComponent,
  NavigationOptionsComponent,
  CreateFeedbackFormComponent,
  EditFeedbackFormComponent,
  CreateFeedbackQuestionComponent,
  EditFeedbackQuestionComponent,
  ViewFeedbackQuestionComponent,
  ViewFeedbackFormComponent,
  BcrmFeedbackDashboardComponent,
  CreateFeedbackAnswerComponent,
  ViewFeedbackAnswerComponent,
  EditFeedbackAnswerComponent,
  ExternalFeedbackPageComponent,
  CreateFormActiveComponent,
  ViewFormActivesComponent,
  EditFormActiveComponent,
  FeedbackFormModalComponent,
  FormActiveDashboardComponent,
  ViewFormResponsesComponent,
  CreateTicketSupportDefinitionComponent,
  ViewTicketSupportDefinitionComponent,
  EditTicketSupportDefinitionComponent,
  TicketSupportFormComponent,
  CreateTicketActionComponent,
  ExternalTicketSupportDashboardComponent,
  ExternalTicketSupportFormComponent,
  BcrmExeDueOrdersComponent,
  ViewCustomerCommunicationsComponent,
  ViewProductBasedInvoicesComponent,
  CreateNotificationSettingComponent,
  EditNotificationSettingComponent,
  EditNotificationMasterComponent,
  CreateNotificationMasterComponent,
  CreateNotificationDetailsComponent,
  EditNotificationDetailsComponent,
  ViewNotificationDetailsComponent,
  OfficeNotificationDashboardComponent,
  BcrmManDueOrdersComponent,
  ClassActivitiesDashboardComponent,
  StudentCreationFileUploadModalComponent,
  EditFormConfigComponent,
  AddFormConfigComponent,
  ResetBcrmDataComponent,
  ViewOldInvoicesComponent,
  CrmSubscriptionsDashboardComponent,
  CreatePaymentComponent,
  MarkAttendanceComponent,
  ViewAttendanceComponent,
  StudentCourseActivitiesDetailsComponent,
  BcrmFeedbackManagerTicketSupportComponent,
  ConvertToCustomerUserComponent,
  UsernameCheckModalComponent,
  ParentHomeDashboardComponent,
  AddChildToParentMentorComponent,
  ParentMentorHomeComponent,
  ComputeTaxToApproveOrderComponent,
  CbSuperAdminHomeComponent,
  CbOfficeAdminHomeComponent,
  AddBotResponseOptionsComponent,
  AddBotResponseComponent,
  ViewBotOptionDetailsComponent,
  AddBotUserComponent,
  CbAConvoLogViewComponent,
  ViewDefinitionsComponent,
  CbOfficeAdminDashboardComponent,
  ExtActivityAssessmentPageComponent,
  ExtActivityFeedbackQsnsModalComponent,
  OverviewModalComponent,
  CbOrgAdminHomeComponent,
  AddWcaSubscriptionComponent,
  AppSetupComponent,
  SetUpComponent,
  BotResponseDetailsComponent,
  DeleteBotResponseComponent,
  AdminNotificationsHomeComponent,
  BotCustomerUsersComponent,
  TimezoneSelectorModalComponent,
  GetGlobalTempResComponent,
  BotScheduleHomeComponent,
  ScheduleMessagesComponent,
  ViewScheduleDetailsComponent,
  UploadBotMediaComponent,
  ViewBotMediaComponent,
  CreateResLangVariationComponent,
  ViewResLangVariationsComponent,
  ViewOptionLangVariationsComponent,
  CreateOptionLangVariationsComponent,
  CreateBotApiConfigComponent,
  BotShopHomeComponent,
  CreateBotCategoryComponent,
  CreateBotItemComponent,
  ViewBotCategoriesComponent,
  BotShopExtHomeComponent,
  ExtBotShoppingPageComponent,
  BotShopOrdersComponent,
  InvoiceDetailsViewComponent,
  BotFormManagementHomeComponent,
  ViewBotFormActivesComponent,
  CreateBotFormActiveComponentComponent,
  BotUserExtFormPageComponent,
  BotFormActiveDashboardComponent,
  EditBotFormActiveComponent,
  BotFormActiveHistoryComponent,
  ViewBotMediaForAgentComponent,
  ResponseOptionSettingsComponent,
  BotAdminsManagementComponent,
  CbNotificationsHomeComponent,
  CbShopOrdersComponent,
  MedicalSpecialistsManagementDashboardComponent,
  CreateBotMemberComponent,
  FileUploadComponent,
  ViewBotFormResponsesComponent,
  ExtMediaViewPageComponent,
  ExtMediaViewModalComponent,
  WebSocketTestComponent,
  CbOfficeUsersConversationsComponent,
  BotUserFileUploadComponent,
  BotUserGroupModalComponent,
  BotTemplatesManagementComponent,
  CreateBotTemplateModalComponent,
  BotApiConfigsManagementComponent,
  SendTemplateMessageComponent,
  BotConvoFlowTreeComponent,
  CreateBotAutoSchedulerSettingsComponent,
  BotAutoScheduleSettingsDashboardComponent,
  BotUserLocationModalComponent,
  VoiceRecorderModalComponent,
  BotItemsFileUploadComponent,
  CbOfficeAdminsConversationsComponent,
  CbOrganizationAdminHomeComponent,
  CrmListsDashboardComponent,
  CrmListsModalComponent,
  CrmTasksModalComponent,
  CrmAgentListsDashboardComponent

  ],
  imports: [
    ColorPickerModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule, SuperadmindashboardModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      closeButton: true
    }),
    BsDropdownModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ViewsModule,
    NgBusyModule,
    BrowserAnimationsModule,
    NgxSpinnerModule, 
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    MatStepperModule,
    DataTablesModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatToolbarModule, 
    MatCheckboxModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSliderModule, 
    MatTabsModule,
    MatSelectModule,
    MatOptionModule,
    MatDividerModule, 
    ChartsModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatTooltipModule,
    MatTreeModule,
    MatMenuModule,
    // MatProgressBarModule
    PdfViewerModule,
    Ng2SearchPipeModule,
    OverlayModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectFilterModule,
    NgxSliderModule,
    MatNativeDateModule,
    MatInputModule,
    TreeModule,
    ClipboardModule
  ], 
  providers: [BsModalRef,BnNgIdleService,DatePipe,AudioRecordingService],
  bootstrap: [AppComponent], 
  exports:[ColorPickerModule ] 
})
export class AppModule {
}
