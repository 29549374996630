import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalClass } from 'src/app/global/global-class';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-image-edit-popup',
  templateUrl: './image-edit-popup.component.html',
  styleUrls: ['./image-edit-popup.component.scss']
})
export class ImageEditPopupComponent implements OnInit {

  userId! : string;
  imageName! : string;
  safeUrl : any;
  unsafeUrl : any;
  selectedFile!: File;


  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  constructor(public activeModal: NgbActiveModal,
              public toaster : ToastrService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer ,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

  ngOnInit(): void {

   this.userId = this.fromParent.userId;
   this.imageName = this.fromParent.imageName;

   this.imageService.getUserImage(this.imageName).subscribe(
     data=>{
              this.unsafeUrl = URL.createObjectURL(data);
              this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeUrl);
     });
     this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
     this.theme = this.sharedDataService.getCardOfficeTheme();
 }

 getColor(){
   this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
   return this.loggedInUser.offices[0].theme + '!important';
 }

  onFileSelected(event: any){
    this.selectedFile = event.target.files[0];
  }

  onSubmit(){

    let body = new FormData();
    body.append("file", this.selectedFile);

    this.imageService.uploadUserImage(body, this.userId).subscribe(
      data=>{
        this.passEntry.emit('upload successfull');
        this.activeModal.close();
        this.toaster.success('Image Updated');
        
      });
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }

}
