import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { GlobalClass } from 'src/app/global/global-class';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-view-activity-planner',
  templateUrl: './view-activity-planner.component.html',
  styleUrls: ['./view-activity-planner.component.scss']
})
export class ViewActivityPlannerComponent implements OnInit {
  videoDivState : boolean = true;
  activityPlannerId!: string;
  activityPlanner : ActivityPlanner = new ActivityPlanner();
  loggedInUser: any;
  theme: any;
  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  
  @Input() fromParent: any;
  safeImageUrl: any;
  unsafeImageUrl: any;
  
  constructor(public bsModalRef: BsModalRef,
              public activeModal: NgbActiveModal,
              private toaster: ToastrService, private sharedDataService : SharedDataService,
              private activityPlannerService : ActivityPlannerService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer ) { } 
    
    ngOnInit(): void {

      this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
      this.theme = this.sharedDataService.getCardOfficeTheme();

      this.activityPlannerId = this.fromParent.activityPlannerId;

      this.activityPlannerService.getActivityPlannerById(this.activityPlannerId).subscribe(
        data=>{
          this.activityPlanner = data;          

          this.activityPlanner.activityVideoLinkList.forEach((value)=>{
            this.url = value.actVideoLink;
            this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
           value.urlSafe = this.urlSafe
          })
           // Get image
         this.imageService.getActivityPlannerImage(this.activityPlanner.activityPlannerImage).subscribe(
          data=>{
            this.safeImageUrl = data;
            this.unsafeImageUrl = URL.createObjectURL(this.safeImageUrl);
            this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeImageUrl);
          });
        });
    }

    get backgroundStyle(){
      let globalClass : GlobalClass = new GlobalClass() ;
      return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
    }

    getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
    
    closeUpdateModal(){
      this.activeModal.close();
    }

}
