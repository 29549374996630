

<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Choose Feedback Questions</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form #aqForm="ngForm"> 
        

    <div>
        <label>Questions</label>

        <button (click)="createFeedbackQuestions()" style="float: right;width: auto;" class="btn btn-info">Create New Question</button>

    <!-- <a matTooltip="Create Question" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
    (click)="createFeedbackQuestions()">
    <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
  </a>  -->

        <div class="form-check" *ngFor="let qsn of feedbackQuestions1">
            <input (click)="handleClick(qsn);" class="form-check-input"
             type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              <a style="color: black;">
              {{qsn.question}} 
               <img matTooltip="Edit Question" style="cursor: pointer;" (click)="editFeedbackQuestions(qsn);" src="/assets/images/icons/edit.svg" width="15" height="15" alt="" loading="lazy">
            </a> 
            </label>
            
        
          </div>
    </div>

  



</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Add</button>
</div>