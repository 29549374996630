import { ActivityPlanner } from "./activity-planner";

export class Subject {

    subId!: string;

	name!: string;

	description!: string;

	subjectStatus!: boolean;

	fkProductId!: string;
    
	subjectImage!: string;

	activityPlanners : ActivityPlanner[] = [];
}
