export class LocationGroup {

    id: any;
	activeStatus: any;
	groupCode: any;
	groupName: any;
	fkOfficeId: any;


	totalAmount : any = 0;
	totalQuantity : any = 0;
	totalNoOfOrders : any = 0;
	grandTotal : any = 0;
	totalAlternateQuantity : any = 0;
}
