  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 

    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToTestDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Analytics </a>
        </li> -->

        <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAnalyticsDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Analytics </a>
        </li> -->
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
  <div class="p-4">
   
    <div class="table-header row" [style.background-color]='getColor()' style=" white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
      <div class="col-md-12" style="color: rgb(248, 245, 245);">

        <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
          Data
        </span>

        <a style="cursor:pointer;" class="float-right" 
          (click)="addBcrmData();" >
          <img src="/assets/images/icons/plus-circle.svg" width="26" height="26" alt="" loading="lazy">
        </a> 

        <a style="cursor:pointer;margin-right: 2%;" class="float-right" 
        (click)="open(confirmOneTimeUpload);" >
        <img src="/assets/images/icons/fileUpload.svg" width="26" height="26" alt="" loading="lazy">
      </a>

        <a style="cursor:pointer;margin-right: 2%;" class="float-right" 
        (click)="open(confirmDeactivation);" >
        <img src="/assets/images/icons/delete.png" width="26" height="26" alt="" loading="lazy">
      </a>

      </div> 
    </div>

    <div class="card">
      <div class="card-body table-responsive">
         
        <table *ngIf="customerExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
        datatable [dtOptions]="customerOptions" >
          <thead class="tableheader">
            <tr>
              <th>Start Date</th>
              <th>End Date</th> 
              <th>Process Status</th>
              <th>Time Stamp</th>
              <th>Status</th>
             
            </tr>
          </thead>
          <tbody>
            <tr class="td-height" *ngFor="let ccd of bcrmDataList">
              <td class="td-height">{{ccd.startDate}}</td>
              <td class="td-height">{{ccd.endDate}}</td>
              <td class="td-height">{{ccd.processStatus}}</td>
              <td class="td-height">{{ccd.timestamp}}</td>
              <td *ngIf="ccd.fileProcessStatus === null" class="td-height">Processing</td>
              <td *ngIf="ccd.fileProcessStatus === true" class="td-height">Process Successful</td>
              <td *ngIf="ccd.fileProcessStatus === false" class="td-height">Process Failed</td>

          </tr>
          </tbody>
        </table>

      </div>
    </div>

</div>

<ng-template #confirmDeactivation let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="color: black;">

This would delete the following data : <br>
 <br>
Payment data, <br>
Invoice outstanding data, <br>
Analytics data, <br>
<!-- File upload data, -->
Customer outstanding data <br>
Etc. <br>
<br>

would you like to continue ?

    
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="deleteOfficeBcrmData();modal.dismiss('Cross click')">Delete</button>
  </div>
</ng-template>


<ng-template #confirmOneTimeUpload let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="color: black;">

This is the first-time file upload option for set-up, <br>
Ensure you have good understanding of the process before you continue. <br>
This process will update the outstanding amount of all customers.

<br> <br>
The date you enter next should be one day before the date of entry of first invoice you record in this application or
the date you start using the application.
<br>
For example, if the first invoice you record or the first day you use the application is 01-12-2021, then
you should enter the date of 30-11-2021 in the following form.


  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="oneTimeUpload();modal.dismiss('Cross click')">Continue</button>
  </div>
</ng-template>