import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivityVideoLink } from 'src/app/courseManagementClasses/activity-video-link';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { ActivityQuestionsService } from 'src/app/service/activity-questions.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-activity-video',
  templateUrl: './add-activity-video.component.html',
  styleUrls: ['./add-activity-video.component.scss']
})
export class AddActivityVideoComponent implements OnInit {
  loggedInUser: any;
  theme: any;
  clicked = false;
  activityVideoLink : ActivityVideoLink = new ActivityVideoLink();
  
  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService, private sharedDataService : SharedDataService,
              private activityQuestionsService : ActivityQuestionsService,
              private activityPlannerService : ActivityPlannerService) { }
  
              @Input() fromParent: any;

              @Output() passEntry: EventEmitter<any> = new EventEmitter();
            
  ngOnInit(): void {
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.activityVideoLink.fkActivityPlannerId = this.fromParent.activityPlannerId;

  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  onSubmit(){
  
    // authorized
    this.activityQuestionsService.adaActivityVideoLink(environment.apiUrl + "activityVideoLink/add", this.activityVideoLink).then(
      data=>{
        this.activeModal.close();
        this.passEntry.emit('success');
        this.toaster.success('Video added successfully');

      }
    )
  }

  closeUpdateModal(){
    this.activeModal.close();
  } 

}
