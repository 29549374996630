 <div  [style.background-color]='getColor()' class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left"> Assignment Submission</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  


   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm"> 

    
     



           <div class="form-group row">
 
   

         <div class="col-md-12">
          <label>Choose file to submit</label>
          <input required placeholder="Choose File" (change)="onFileSelected($event)" class="form-control" type="file">
       </div>
   
      </div>

     
     






     


    </form>
      </div>
    </div>
   </div>
   
   <div class="modal-footer">
    <button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
  </div>