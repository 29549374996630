import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { BotOfficeServiceService } from 'src/app/BOT_CODE/bot_services/bot-office-service.service';
import { BotMedia } from 'src/app/BOT_CODE/bot_classes/bot-media';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-ext-media-view-modal',
  templateUrl: './ext-media-view-modal.component.html',
  styleUrls: ['./ext-media-view-modal.component.scss']
})
export class ExtMediaViewModalComponent implements OnInit {

  clicked = false;
  userExists = false;
 

  constructor(private botOfficeService : BotOfficeServiceService,
     private sharedDataService: SharedDataService,
     private sanitizer : DomSanitizer,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService) { }

    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() fromParent: any;

    botMedia : BotMedia = new BotMedia();
    officeId:any;

    botMediaId:any;
    mediaType:any;

    ngOnInit(): void {
               
   

    this.botMediaId = this.fromParent.botMediaId; 
    this.mediaType = this.fromParent.mediaType;
    this.officeId = this.fromParent.officeId;    
 
    this.getBotMediaForPreview();

  }

  unsafeImageUrl : any;
  safeImageUrl : any;

  unsafeImageUrlTest : any;
  safeImageUrlTest : any;

  url: any = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;
  pdfFile !: SafeResourceUrl;
  txtFile : any;

  playVideo=false;
  mediaLoaded=false;

  getBotMediaForPreview(){
    this.botOfficeService.getBotMediaForPreview(environment.apiUrl + "botMedia/getMediaFromWhatsappCloudApiByObjectId/" + this.botMediaId + "/" + this.officeId).subscribe(
      data=>{
        
        if(this.mediaType === "image"){
              this.unsafeImageUrl = URL.createObjectURL(data);
              this.safeImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeImageUrl);
              this.mediaLoaded = true;
            }
    
        if(this.mediaType === "video"){
              this.url = URL.createObjectURL(data);
              this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.url);
              this.playVideo = true;
              this.mediaLoaded = true;
            }
    
        if(this.mediaType === "document"){
              this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
              this.mediaLoaded = true;
            }
    
        // if( this.mediaType === "txt" || this.mediaType === "xls" || this.mediaType === "xlsx"){         
        //       this.txtFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
        // this.mediaLoaded = true;
        // }
    
        // if(this.mediaType === "xls" || this.mediaType === "xlsx"){        
        //       this.txtFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
       // this.mediaLoaded = true;
        // }

        

        });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }



}