import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Curriculum } from 'src/app/courseManagementClasses/curriculum';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-main-curr',
  templateUrl: './add-main-curr.component.html',
  styleUrls: ['./add-main-curr.component.scss']
})
export class AddMainCurrComponent implements OnInit {

  constructor(private currService : CurriculumService,
              public activeModal: NgbActiveModal,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService ) { }

  curriculum : Curriculum = new Curriculum();
  subId!: string;
  curId!: string;
  clicked = false;
  loggedInUser: any;
  theme: any;
  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.subId = this.fromParent.subId;
    this.curId = this.fromParent.curId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  onSubmit(){

    this.curriculum.fkSubject = this.subId;
    this.curriculum.fkCurriculumId = this.curId;

     //authorized
    this.currService.addCurriculum(environment.apiUrl + 'curriculum/add', this.curriculum).then(
      data=>{
        this.curriculum = data;
        this.passEntry.emit(this.curriculum);
        this.activeModal.close();
        this.toaster.success("Curriculum Added Successfully");
      });
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }

}
