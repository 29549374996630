import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { WebSocketTestComponent } from '../web-socket-test/web-socket-test.component';

export class WebSocketAPI {

    webSocketEndPoint: string = 'http://localhost:8080/ws';
    topic: string = "/topic/greetings";
    stompClient: any;
    appComponent: WebSocketTestComponent;

    constructor(appComponent: WebSocketTestComponent){
        this.appComponent = appComponent;
    }

    _connect() {
        let ws = new SockJS(this.webSocketEndPoint);
        // console.log("=====");
        this.stompClient = Stomp.over(ws);
        // console.log("0000");
        const _this = this;
        // console.log("11");
        
        _this.stompClient.connect({}, function (frame:any) {
            // console.log("CONNECT !");
            
            _this.stompClient.subscribe(_this.topic, function (sdkEvent:any) {
                // console.log("Subscribe ? !");
                _this.onMessageReceived(sdkEvent);
                // console.log("MSG RECVD !");
            });
            _this.stompClient.reconnect_delay = 2000;
        }, this.errorCallBack);
    };

    _disconnect() {
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        // console.log("Disconnected");
    }

     // on error, schedule a reconnection attempt
     errorCallBack(error:any) {
        // console.log("errorCallBack -> " + error)
        setTimeout(() => {
            this._connect();
        }, 5000);
    }

 /**
  * Send message to sever via web socket
  * @param {*} message 
  */
    _send(message:any) {
        // console.log("SEND TEXT TO SERVER");
        this.stompClient.send("/app/hello", {}, JSON.stringify(message));
    }

    onMessageReceived(message:any) {
        // console.log("Message Recieved from Server :: " + message);
        this.appComponent.handleMessage(JSON.stringify(message.body));
    }
}
