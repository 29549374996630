<div class="modal-header">
    <h4 class="modal-title pull-left">Session Timeout</h4>
</div>
<div class="card-body">
  <!-- <p class="card-text">You are being timed out due to inactivity. Please choose to stay 'Continue' or 'Logout'. Otherwise you will logged off automatically.</p> -->
  <p class="card-text">You are being Logged out since your session has been timed out. Please re-Login to continue.</p>
</div>
  <div class="modal-footer">
    <!-- <button type="submit" class="btn btn-sm btn-primary float-right logout" (click)="saveToList('Logout');">
      Logout</button>
      <button type="submit" class="btn btn-sm btn-primary float-right" (click)="saveToList('Continue');">
       Continue</button> -->
       <button type="submit" class="btn btn-info btn-primary float-right logout" (click)="saveToList('Logout');">
        Login</button>
  </div>
  