<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Subscription</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div> 




<div class="modal-body">
    <!-- <div class="card">
      <div class="card-body"> -->
        <form >
  
          <!-- <div class="form-group row">          
            <div class="col-md-6">
              <label for="if-user">User</label>
              <input readonly type="text" [(ngModel)]="userName">
            </div>
          </div>  -->
  
          <div class="form-group row">          
            <div class="col-md-6">
              <label for="subscription.roleName">Role</label>
              <input type="text" id="roleName" name="roleName" class="form-control" [(ngModel)]="subscription.roleName" readonly>
            </div>
  
            <div class="col-md-6">
              <label for="subscription.fkProductId">Product</label>
             <input type="text" id="productName" name="productName" readonly class="form-control" [(ngModel)]="subscription.productName">
            </div> 
          </div>         
   
          <div class="form-group row">
            <div class="col-md-6">
              <label for="subscription.startedDate">Start Date</label>
              <input class="form-control" id="startedDate" name="startedDate" [(ngModel)]="subscription.startedDate" type="date">
            </div>
   
            <div class="col-md-6">
              <label for="">End Date</label>
              <input class="form-control" id="endedDate" name="endedDate" [(ngModel)]="subscription.endedDate" type="date">
            </div>
          </div>
  
        </form>
      <!-- </div>
    </div> -->
  </div>



  <div class="modal-footer">
    <button type="submit" [disabled]="clicked" class="btn btn-sm btn-primary float-right" (click)="submit();clicked=true">Update</button>
  </div>