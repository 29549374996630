import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { Office } from 'src/app/models/office';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { ResultServiceService } from 'src/app/service/result-service.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-super-course-admin-dashboard',
  templateUrl: './super-course-admin-dashboard.component.html',
  styleUrls: ['./super-course-admin-dashboard.component.scss']
})
export class SuperCourseAdminDashboardComponent implements OnInit {


  userName : any;
  loggedInUser: any;
  loggedUser: any;
  navbarTitle: any;
  offices : Office[] = [];
  showSpinner : boolean = true;

  public screenWidth: any;
  public screenHeight: any;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  constructor(private resultService : ResultServiceService,
              private ngbModalService: NgbModal,
              public router: Router,
              private officesService: OfficesService,
              private toaster: ToastrService,
              private logoutService : LogoutService,
              private sharedDataService : SharedDataService ) { }

  
  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getAllOffices();
  }

  exploreOffice(officeId : any){
    this.router.navigate(['dashboard/super-course-admin/products',officeId]);
  }

  // authorized
  getAllOffices(){
    this.officesService.getAllOffices(environment.apiUrl + 'admin/get/offices').then(
      officesRes => {
        this.offices = officesRes;
        this.showSpinner = false;
    });
  }

  logOut(): void {

    localStorage.removeItem('auth-token');
    localStorage.clear();
    window.location.href=`${environment.authUrl}/logout`;
      
  }

  reloadPage() {
    window.location.reload();
  }

  closeResult !:string;
open(content: any) {
  this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
 
}
