import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OptionsModalComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/bcrm_options/options-modal/options-modal.component';
import { EditOrderComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/edit-order/edit-order.component';
import { ViewOldInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-old-invoices/view-old-invoices.component';
import { ViewOrderDetailsComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-order-details/view-order-details.component';
import { ViewProductBasedInvoicesComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/orders/view-product-based-invoices/view-product-based-invoices.component';
import { ComputeTaxToApproveOrderComponent } from 'src/app/BIZ_CRM/biz_crm_Modals/tax/compute-tax-to-approve-order/compute-tax-to-approve-order.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { InvoiceList } from 'src/app/crmClasses/invoice-list';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { InvoiceService } from 'src/app/service/invoice.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { TableUtil } from 'src/app/table-util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bcrm-fm-home',
  templateUrl: './bcrm-fm-home.component.html',
  styleUrls: ['./bcrm-fm-home.component.scss']
})
export class BcrmFmHomeComponent implements OnInit {
  currentRole = "BCRM_FM";
  currentOrders = "Orders placed";

  invoiceExists: boolean = true;
  invoiceOptions: any = {};
  showSpinner = false;

  selectedInvoiceId : any;
  cancellationRemarks : any = null;
  taxed : any;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  loggedInUser: any;
  loggedUser: any;
  officeId : any;
  userName!: string;
  userId!: string;
  navbarTitle: any;
  closeResult!: string;


  constructor(private ngbModalService: NgbModal,
              public router: Router,
              private datePipe : DatePipe,
              private toaster: ToastrService,
              private invoiceService : InvoiceService, 
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    modalRefs: BsModalRef[] = [];
    public screenWidth: any;
    public screenHeight: any;

    invoices: InvoiceList[] = [];
    oldInvoices: InvoiceList[] = [];

  ngOnInit(): void {

    this.showSpinner = true;

    this.navbarTitle = GlobalClass.navbarTitle;

    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.officeId = this.loggedInUser.offices[0].id;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

      this.getInvoiceDetails();
      this.getOldInvoiceListByUroId();
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      this.invoiceOptions = {
        pagingType: 'full_numbers',
        pageLength: 2000,
        lengthChange: false,
        processing: true,
        info:false
      };
  }

          // authorized
          getOldInvoiceListByUroId(){
            this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getOldInvoiceListForFM").then(
              data => {
              this.oldInvoices = data;
                this.oldInvoices.forEach((val)=>{
                  val.invoiceDate = new Date(val.invoiceDate);
                  val.invoiceDate = this.datePipe.transform(val.invoiceDate, "dd-MM-yyyy");
              
                  val.grandTotal =  Math.round((val.grandTotal + Number.EPSILON) * 100) / 100;
                });
                this.invoiceExists = true;
              });
        }

    // authorized
    editInvoice(invoiceId : string){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( EditOrderComponent,
       {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      invoiceId: invoiceId ,
      type : "order",
      role : this.currentRole
      }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceList) => {
          this.showSpinner = true;
          this.updateInvoiceObjInInvoiceListById(receivedEntry);
          this.showSpinner = false;
        });
  }

  updateInvoiceObjInInvoiceListById(receivedEntry : InvoiceList){
    this.invoices.forEach((inv)=>{
      if(inv.invoiceId === receivedEntry.invoiceId){
        inv.invoiceDate = receivedEntry.invoiceDate;
        inv.invoiceRemarks = receivedEntry.invoiceRemarks;
        inv.invoiceNo = receivedEntry.invoiceNo;
        inv.paymentMethod = receivedEntry.paymentMethod;
        inv.totalAmount = receivedEntry.totalAmount;
        inv.totalQuantity = receivedEntry.totalQuantity;
        inv.orderStatus = receivedEntry.orderStatus;
        inv.totalAlternateQuantity = receivedEntry.totalAlternateQuantity;
        inv.grandTotal = receivedEntry.grandTotal;
        inv.outstandingAmount = receivedEntry.outstandingAmount;
        inv.totalTax = receivedEntry.totalTax;
        inv.totalCess = receivedEntry.totalCess;
        inv.taxed = receivedEntry.taxed;
        inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
      }
     });
  }

  viewProductBasedOrders(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewProductBasedInvoicesComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  
    let data = {
      invoices : this.invoices
    }
  
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  });
  }

  
  // authorized
  getInvoiceDetails(){
    this.showSpinner = true;
      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl +  "invoice/getOrdersPlacedByOfficeId").then(
        data => {          
        this.invoices= data;
        this.invoices.forEach((inv)=>{
          inv.invoiceDate = this.datePipe.transform(inv.invoiceDate, "dd-MM-yyyy");
      
          inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
        });
          this.invoiceExists = true;
          this.currentOrders = "Orders placed";
          this.dataSource = this.invoices;
          this.showSpinner = false;
        });
  }

  dataSource = this.invoices;
  exportArray() {
    const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map(x => ({
      Executive: x.executiveName,
      Customer: x.customerName,
      Status: x.orderStatus,
      Date: x.invoiceDate,
      TotalAltQty: x.totalAlternateQuantity,
      TotalAmt: x.grandTotal
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "data");
  } 

  viewOldInvoices(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOldInvoicesComponent,
     {
      size : 'lg',
      scrollable: true,
      windowClass: 'app-modal-window',
      keyboard: false
    });
  
    let data = {
      invoices : this.oldInvoices,
      currentRole : this.currentRole
    }
  
    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
  });
  }

  goToTicketSupportPage(){
    this.router.navigate(['dashboard/ticket-support']);
  }
  
cancelOrder(){
  this.showSpinner = true;
  this.invoiceService.cancelOrder(environment.apiUrl + "invoice/cancelOrder/" +this.selectedInvoiceId +"/" + this.cancellationRemarks ).then(
    data=>{
      this.toaster.success("Order Cancelled successfully");
      this.removeInvoiceObjectFromInvoiceList(this.selectedInvoiceId);
      this.cancellationRemarks = null;
      this.showSpinner = false;
    });

}

removeInvoiceObjectFromInvoiceList(invoiceId : any){
  this.invoices.forEach( (invoice, index) => {
    if(invoice.invoiceId === invoiceId) this.invoices.splice(index,1);
  });
}

convertOrderToInvoice(){
  this.showSpinner = true;
  this.invoiceService.convertOrderToInvoice(environment.apiUrl + "invoice/convertOrderToInvoice/" +this.selectedInvoiceId ).then(
    data=>{
      this.toaster.success("Order converted to Invoice successfully");
      this.removeInvoiceObjectFromInvoiceList(this.selectedInvoiceId);
      this.showSpinner = false;
    });
}

approveOrder(){
  if(this.taxed === true){
    this.showSpinner = true;
    this.invoiceService.approveOrder(environment.apiUrl + "invoice/approveOrder/" +this.selectedInvoiceId ).then(
      data=>{
        this.toaster.success("Order Approved successfully");
        this.removeInvoiceObjectFromInvoiceList(this.selectedInvoiceId);
        this.showSpinner = false;
      });
  }else{
    this.toaster.error("Compute taxes to approve this order");
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ComputeTaxToApproveOrderComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      invoiceId : this.selectedInvoiceId
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      this.showSpinner = true;
      this.invoiceService.approveOrder(environment.apiUrl + "invoice/approveOrder/" +this.selectedInvoiceId ).then(
        data=>{
          this.toaster.success("Tax computed and Order Approved Successfully");
          this.removeInvoiceObjectFromInvoiceList(this.selectedInvoiceId);
          this.showSpinner = false;
        });
    });   

  }
}

  viewInvoiceDetailsModal(invoiceId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ViewOrderDetailsComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass : 'app-modal-window',
        keyboard: false
      });
    let data = {
      invoiceId: invoiceId ,
      type : "order"
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
   
  }

  goToAccountPage(){
    this.router.navigate(['bcrm-user-account',this.userId])
  }
  
  openOptions(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( OptionsModalComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {

      this.showSpinner = true;

      this.invoiceService.getInvoiceListByOfficeId(environment.apiUrl + receivedEntry.link).then(
        data => {
          
        this.invoices= data;
        this.invoices.forEach((inv)=>{
          inv.invoiceDate = this.datePipe.transform(inv.invoiceDate, "dd-MM-yyyy");
          inv.grandTotal =  Math.round((inv.grandTotal + Number.EPSILON) * 100) / 100;
        });
        
        this.dataSource = this.invoices;
          this.invoiceExists = true;
          this.currentOrders = receivedEntry.displayName;
          this.showSpinner = false;
        });
    });    
  }

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }
    
    reloadPage() {
      window.location.reload();
    }
}