export class WCASubscription {
    subscriptionId:any;

    type:any;

	fkOfficeId:any;

    fkUserId:any;

	startDate:any;

	endDate:any;

	officesCount:any;

	officesLimit:any;

	officeName : any;
	startDateFormatted:any;
	endDateFormatted:any;
	list : WCASubscription[] = [];

	daysLeft = 0;
	expiryStatus = "";
}
