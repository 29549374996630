<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Update Profile Image</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

<div class="modal-body">
  <!-- <div class="card">
    <div class="card-body">  -->
    <form (ngSubmit)="onSubmit()" #subForm="ngForm"> 


    <div style="width: 100%;color: black;text-align: center;" >
    <a style="cursor:pointer;">
    <img style="border-radius: 50%;" [src]="safeUrl" width="100" height="100"
    alt="" loading="lazy">
    </a>
    </div>


    <div>
    <label for="name">File</label>
    <input required type="file" class="form-control" (change)="onFileSelected($event)" placeholder=" Choose File To Upload" >
    </div>




</form>
<!-- </div>
</div> -->
</div>

<div class="modal-footer">
<button [disabled]="!subForm.valid" [ngStyle]="backgroundStyle"
 class="btn btn-sm btn-primary float-right" (click)="onSubmit()">Update</button>
</div>
