import { Tax } from "../BIZ_CRM/biz_crm_classes/tax";

export class InvoiceDetailsList {

	//class while fetching invoice details
    productName!: string;
    invoiceDetId!: string;
	itemName!: string;
	quantity!: any;
	alternateQuantity : any;
	productDefaultQuantity : any
	pricePerItem!: any;
    totalPrice!: any;
	description!: string;
	paymentMethod!: string;
	deliveryAddress!: string;
	fkInvoiceId!: string;
	fkProductId!: string;
	invoiceDetStatus!: boolean; 
	type : any;
	fkTaxId : any;
	taxAmount : any;
	taxName : any;

	taxObject : Tax = new Tax();

	prodDefaultQty : any;
	
	totalDiscountAmount : any;

	perQuantityDiscountRate : any = 0;
	perAltQuantityDiscountRate : any = 0;
	totalPriceBeforeDiscount : any;


}
