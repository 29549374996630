import { BotOfficeLanguage } from "./bot-office-language";

export class BotOfficeControls {
  wcaCallbackUrlVerificationStatus: any;
  wcaPermanentToken: any;
  wcaPhoneNumberID: any;
  whatsappNumber: any;
  callbackUrlVerifyToken: any;
  botOfficeLanguageDto: BotOfficeLanguage = new BotOfficeLanguage();
}
