import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubjectService } from 'src/app/service/subject.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';
  
@Component({
  selector: 'app-add-subject',
  templateUrl: './add-subject.component.html',
  styleUrls: ['./add-subject.component.scss']
})
export class AddSubjectComponent implements OnInit {
  clicked=false
  selectedFile!: File;
  fileChange : boolean = false;
  loggedInUser: any;
  theme: any;
  subject : Subject = new Subject(); 

  productId!: string;

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private subjectService : SubjectService,
              public activeModal: NgbActiveModal,
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService,
              private toaster: ToastrService,
              private imageService: FileServiceService  ) { }

  ngOnInit(): void {
    this.productId = this.fromParent.productId;
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  onFileSelected(event: any){
    this.fileChange = true;
    this.selectedFile = event.target.files[0];
  }
  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  // authorized
  onSubmit(){
    this.subject.fkProductId = this.productId;

      this.subjectService.addNewSubject(environment.apiUrl + 'subject/add', this.subject).then(

      data =>{
            this.subject = data;

            if(this.fileChange === true){
            let body = new FormData();
            body.append("file", this.selectedFile);

            this.imageService.uploadSubjectImage(body, this.subject.subId).subscribe(
              data=>{
                this.passEntry.emit(this.subject);
                this.activeModal.close();
                this.toaster.success("Subject Added Successfully");
              });  
            }
             else {
              this.passEntry.emit(this.subject);
              this.activeModal.close();
              this.toaster.success("Subject Added Successfully");
             }

                  
    })
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

}
