<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Invoice Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      
        <table
        class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
        <thead class="tableheader" >
          <tr>
            <th>Item</th>
            <th> Quantity</th>
            <th>Price per Item</th>
            <th>Total Price</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let idet of invoiceDetails">
            <td>{{idet.itemName}}</td>
            <td>{{idet.itemQuantity}}</td>
            <td>{{idet.pricePerItem}}</td>
            <td>{{idet.totalPrice}}</td>
          </tr>
        </tbody>
      </table>

      

</div>
</div>

