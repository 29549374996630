export class BotItem {
  botItemId: any;
  botItemStatus: any;
  botItemName: any;
  botItemImage: any;
  botItemDescription: any;
  pricePerItem: any;
  fkBotCategoryId: any;
  fkOfficeId: any;

  list: BotItem[] = [];
  fkBotCategoryName: any;
  safeImageUrl: any;
  imageUpload = false;
  quantityAddedToCart = 0;
  totalPriceforQty = 0;

  availability: any;
  image_url: any;
  condition: any;
  website_link: any;
  retailerId: any;
  brand: any;

  metaInventorySpreadsheetUrl: any;
}
