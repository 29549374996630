import { CrmTask } from "./crm-task";

export class CrmList {
  crmListId: any;
  fkOfficeId: any;
  listName: any;
  listDescription: any;
  lists: CrmList[] = [];
  crmListTasks: CrmTask[] = [];
}
