<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>  
        
  
      </ul>
    </div>  
  </nav>


  <div class="p-4">
    <!-- <h2 style="text-align: left; font-weight: bold;color: rgb(76, 71, 71);">Definitions</h2> -->
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          
          <div style="width: 100%;color: black;text-align: center;margin-bottom: 2%;" >
            <a>
               <img src="/assets/images/icons/ordersPlaced.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
          

          <div style="text-align: center;">
            <h4 style="font-weight: bold;margin: 0%;">
              {{ordersPlacedCount}}
              <br>
              Orders Placed
            </h4>
          </div>
         
      </mat-card>
      </div>

      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          
          <div style="width: 100%;color: black;text-align: center;margin-bottom: 2%;" >
            <a>
               <img src="/assets/images/icons/ordersApproved.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>
         
          <div style="text-align: center;">
            <h4 style="font-weight: bold;margin: 0%;">
              {{ordersProcessedCount}}
              <br>
              Orders Processed
            </h4>
          </div>
         
      </mat-card>
      </div>

      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          
          <div style="width: 100%;color: black;text-align: center;margin-bottom: 2%;" >
            <a>
               <img src="/assets/images/icons/ordersInvoiced.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>

          <div style="text-align: center;">
            <h4 style="font-weight: bold;margin: 0%;">
              {{ordersInvoicedCount}}
              <br>
              Orders Invoiced
            </h4>
          </div>
         
      </mat-card>
      </div>

      <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
        <mat-card class="example-card mat-elevation-z12" >
          
                   <div style="width: 100%;color: black;text-align: center;margin-bottom: 2%;" >
            <a>
               <img src="/assets/images/icons/ordersCancelled.png" width="60" height="60"
               alt="" loading="lazy">
            </a>
          </div>

          <div style="text-align: center;">
            <h4 style="font-weight: bold;margin: 0%;">
              {{ordersCancelledCount}}
              <br>
              Orders Cancelled
            </h4>
          </div>
         
      </mat-card>
      </div>

      

    </div>
  </div>
  

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div> 

  <mat-tab-group (selectedTabChange)="tabClick($event)" 
  style="padding: 0.4em;" dynamicHeight>
   
    <mat-tab label="Orders Placed">
      <div class="p-4">  
    
        <div class="table-header row"  [style.background-color]='getColor()' style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
            <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
              <span class="table-caption">
                {{orderStatusTable}} {{currentDateRange}}
              </span>
    
              <a style="cursor:pointer;margin-right: 1%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
                <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
              </a>
    
              <!-- <a style="cursor:pointer;margin-right: 2%;" matTooltip="Options" class="float-right" 
                (click)="open(options);" >
                <img src="/assets/images/icons/settings.svg" width="26" height="26" alt="" loading="lazy">
                </a> -->
    
                <a style="cursor:pointer;margin-right: 2%;" matTooltip="View Old Invoices" class="float-right" 
                (click)="open(DateRangePicker);">
                  <img src="/assets/images/icons/searchIcon.svg" width="25" height="25" alt="" loading="lazy">
                </a>
    
            </div> 
          </div>
          
          <div class="card">
            <div class="card-body table-responsive">
              <table *ngIf="invoicesExist" class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Type</th>
                    <th>Order Placed On</th>
                    <th>Status</th>
                    <th>Grand Total</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let invoice of invoices">
                    <td class="td-height">{{invoice.fkBotUserName}}</td>
                    <td class="td-height">{{invoice.fkBotUserPhone}}</td>

                    <td *ngIf="invoice.linkType==='s' || invoice.linkType==='m'" class="td-height">ORDER</td>
                    <td *ngIf="invoice.linkType==='q'" class="td-height">QUOTATION REQ</td>
                    
                    <td class="td-height">{{invoice.orderPlacedDate}}</td>
                    <td class="td-height">{{invoice.orderStatus}}</td>
                    <td class="td-height">{{invoice.grandTotal}}</td>
                    <td class="td-height">{{invoice.fkBotUserLocationAddress}}</td>
                    <td>
    
                       <button (click)="viewInvoiceDetails(invoice.invoiceId);" style="width: auto;" class="btn btn-info">View Details</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order cancelled');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-danger">Cancel Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order processed');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-primary">Process Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order invoiced');" *ngIf="invoice.orderStatus==='order processed'" style="width: auto;" class="btn btn-primary">Invoice Order</button>
    
                    </td>
                   
    
     </tr>
                </tbody>
              </table>
     
            </div>
          </div>
          
    </div>
    </mat-tab>

    <mat-tab label="Orders Processed">
      <div class="p-4">  
    
        <div class="table-header row"  [style.background-color]='getColor()' style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
            <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
              <span class="table-caption">
                {{orderStatusTable}} {{currentDateRange}}
              </span>
    
              <a style="cursor:pointer;margin-right: 1%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
                <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
              </a>
    
              <!-- <a style="cursor:pointer;margin-right: 2%;" matTooltip="Options" class="float-right" 
                (click)="open(options);" >
                <img src="/assets/images/icons/settings.svg" width="26" height="26" alt="" loading="lazy">
                </a> -->
    
                <a style="cursor:pointer;margin-right: 2%;" matTooltip="View Old Invoices" class="float-right" 
                (click)="open(DateRangePicker);">
                  <img src="/assets/images/icons/searchIcon.svg" width="25" height="25" alt="" loading="lazy">
                </a>
    
            </div> 
          </div>
          
          <div class="card">
            <div class="card-body table-responsive">
              <table *ngIf="invoicesExist" class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Type</th>
                    <th>Order Placed On</th>
                    <th>Status</th>
                    <th>Grand Total</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let invoice of invoices">
                    <td class="td-height">{{invoice.fkBotUserName}}</td>
                    <td class="td-height">{{invoice.fkBotUserPhone}}</td>

                    <td *ngIf="invoice.linkType==='s' || invoice.linkType==='m'" class="td-height">ORDER</td>
                    <td *ngIf="invoice.linkType==='q'" class="td-height">QUOTATION REQ</td>
                    
                    <td class="td-height">{{invoice.orderPlacedDate}}</td>
                    <td class="td-height">{{invoice.orderStatus}}</td>
                    <td class="td-height">{{invoice.grandTotal}}</td>
                    <td class="td-height">{{invoice.fkBotUserLocationAddress}}</td>
                    <td>
    
                       <button (click)="viewInvoiceDetails(invoice.invoiceId);" style="width: auto;" class="btn btn-info">View Details</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order cancelled');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-danger">Cancel Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order processed');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-primary">Process Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order invoiced');" *ngIf="invoice.orderStatus==='order processed'" style="width: auto;" class="btn btn-primary">Invoice Order</button>
    
                    </td>
                   
    
     </tr>
                </tbody>
              </table>
     
            </div>
          </div>
          
    </div>
    </mat-tab>

    <mat-tab label="Orders Invoiced">
      <div class="p-4">  
    
        <div class="table-header row"  [style.background-color]='getColor()' style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
            <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
              <span class="table-caption">
                {{orderStatusTable}} {{currentDateRange}}
              </span>
    
              <a style="cursor:pointer;margin-right: 1%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
                <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
              </a>
    
              <!-- <a style="cursor:pointer;margin-right: 2%;" matTooltip="Options" class="float-right" 
                (click)="open(options);" >
                <img src="/assets/images/icons/settings.svg" width="26" height="26" alt="" loading="lazy">
                </a> -->
    
                <a style="cursor:pointer;margin-right: 2%;" matTooltip="View Old Invoices" class="float-right" 
                (click)="open(DateRangePicker);">
                  <img src="/assets/images/icons/searchIcon.svg" width="25" height="25" alt="" loading="lazy">
                </a>
    
            </div> 
          </div>
          
          <div class="card">
            <div class="card-body table-responsive">
              <table *ngIf="invoicesExist" class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Type</th>
                    <th>Order Placed On</th>
                    <th>Status</th>
                    <th>Grand Total</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let invoice of invoices">
                    <td class="td-height">{{invoice.fkBotUserName}}</td>
                    <td class="td-height">{{invoice.fkBotUserPhone}}</td>

                    <td *ngIf="invoice.linkType==='s' || invoice.linkType==='m'" class="td-height">ORDER</td>
                    <td *ngIf="invoice.linkType==='q'" class="td-height">QUOTATION REQ</td>
                    
                    <td class="td-height">{{invoice.orderPlacedDate}}</td>
                    <td class="td-height">{{invoice.orderStatus}}</td>
                    <td class="td-height">{{invoice.grandTotal}}</td>
                    <td class="td-height">{{invoice.fkBotUserLocationAddress}}</td>
                    <td>
    
                       <button (click)="viewInvoiceDetails(invoice.invoiceId);" style="width: auto;" class="btn btn-info">View Details</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order cancelled');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-danger">Cancel Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order processed');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-primary">Process Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order invoiced');" *ngIf="invoice.orderStatus==='order processed'" style="width: auto;" class="btn btn-primary">Invoice Order</button>
    
                    </td>
                   
    
     </tr>
                </tbody>
              </table>
     
            </div>
          </div>
          
    </div>
    </mat-tab>

    <mat-tab label="Orders Cancelled">
      <div class="p-4">  
    
        <div class="table-header row"  [style.background-color]='getColor()' style="white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem; margin-left: 0%;margin-right: 0%;">
            <div class="col-md-12" style="color: rgb(248, 245, 245);">
    
              <span class="table-caption">
                {{orderStatusTable}} {{currentDateRange}}
              </span>
    
              <a style="cursor:pointer;margin-right: 1%;" matTooltip="Download as excel file" class="float-right" (click)="exportArray()">
                <img src="/assets/images/icons/download.svg" width="25" height="25" alt="" loading="lazy">
              </a>
    
              <!-- <a style="cursor:pointer;margin-right: 2%;" matTooltip="Options" class="float-right" 
                (click)="open(options);" >
                <img src="/assets/images/icons/settings.svg" width="26" height="26" alt="" loading="lazy">
                </a> -->
    
                <a style="cursor:pointer;margin-right: 2%;" matTooltip="View Old Invoices" class="float-right" 
                (click)="open(DateRangePicker);">
                  <img src="/assets/images/icons/searchIcon.svg" width="25" height="25" alt="" loading="lazy">
                </a>
    
            </div> 
          </div>
          
          <div class="card">
            <div class="card-body table-responsive">
              <table *ngIf="invoicesExist" class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="userOptions">
                <thead class="tableheader">
                  <tr>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Type</th>
                    <th>Order Placed On</th>
                    <th>Status</th>
                    <th>Grand Total</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="td-height" *ngFor="let invoice of invoices">
                    <td class="td-height">{{invoice.fkBotUserName}}</td>
                    <td class="td-height">{{invoice.fkBotUserPhone}}</td>

                    <td *ngIf="invoice.linkType==='s' || invoice.linkType==='m'" class="td-height">ORDER</td>
                    <td *ngIf="invoice.linkType==='q'" class="td-height">QUOTATION REQ</td>
                    
                    <td class="td-height">{{invoice.orderPlacedDate}}</td>
                    <td class="td-height">{{invoice.orderStatus}}</td>
                    <td class="td-height">{{invoice.grandTotal}}</td>
                    <td class="td-height">{{invoice.fkBotUserLocationAddress}}</td>
                    <td>
    
                       <button (click)="viewInvoiceDetails(invoice.invoiceId);" style="width: auto;" class="btn btn-info">View Details</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order cancelled');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-danger">Cancel Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order processed');" *ngIf="invoice.orderStatus==='order placed'" style="width: auto;" class="btn btn-primary">Process Order</button>
              <button (click)="orderStatusUpdate(invoice.invoiceId, 'order invoiced');" *ngIf="invoice.orderStatus==='order processed'" style="width: auto;" class="btn btn-primary">Invoice Order</button>
    
                    </td>
                   
    
     </tr>
                </tbody>
              </table>
     
            </div>
          </div>
          
    </div>
    </mat-tab>
  
  </mat-tab-group>




<ng-template #options let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">Change View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="cursor: pointer;text-align: center;font-weight: bold;color: black;">
    <a (click)="getBotInvoicesByOfficeId('order placed', null, null);modal.dismiss('Cross click');orderStatusTable='Orders Placed'"> 
      Get Orders Placed </a> <br> 
   <mat-divider></mat-divider>
    <br> <a (click)="getBotInvoicesByOfficeId('order processed', null, null);modal.dismiss('Cross click');orderStatusTable='Orders Processed'">
        Get Processed Orders     </a> <br> 
   <mat-divider></mat-divider>
    <br> <a (click)="getBotInvoicesByOfficeId('order invoiced', null, null);modal.dismiss('Cross click');orderStatusTable='Orders Invoiced'">
        Get Invoiced Orders     </a> <br>
        <mat-divider></mat-divider>
    <br> <a (click)="getBotInvoicesByOfficeId('order cancelled', null, null);modal.dismiss('Cross click');orderStatusTable='Orders Cancelled'">
        Get Cancelled Orders     </a>
  </div> 
</ng-template>

<ng-template #DateRangePicker let-modal>
  <div [style.background-color]='getColor()' class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;color: white;" id="modal-basic-title">
      Get {{orderStatusTable}} (By {{orderStatusTable}} date)</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
  <mat-form-field [style.width.%]=100 appearance="fill">
<mat-label>Choose a date range</mat-label>
<mat-date-range-input [rangePicker]="picker">
<input [(ngModel)]="rangeStartDate" matStartDate placeholder="Start date">
<input [(ngModel)]="rangeEndDate" matEndDate placeholder="End date">
</mat-date-range-input>
<mat-hint>MM/DD/YYYY to MM/DD/YYYY</mat-hint>
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
    <button type="button" class="btn btn-success" (click)="searchInvoices();modal.dismiss('Cross click')">Search</button>
  </div>
</ng-template>