import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  constructor(private httpClient : HttpClient) { }

  private getUserImageUrl = environment.apiUrl + "file/userImage";
  private getSubjectImageUrl = environment.apiUrl + "file/subjectImage";
  private getProductImageUrl = environment.apiUrl + "file/productImage";
  private getActivityPlannerImageUrl = environment.apiUrl + "file/activityPlannerImage";
  private getFeedbackFormImageUrl = environment.apiUrl + "file/feedbackFormImage";
  private getSkillImageUrl = environment.apiUrl + "file/skillImage";
  private uploadUserImageUrl = environment.apiUrl + "file/userProfileImageUpdate";
  private uploadSubjectImageUrl = environment.apiUrl + "file/subjectImageUpdate";
  private uploadProductImageUrl = environment.apiUrl + "file/productImageUpdate";
  private uploadFeedbackFormImageUrl = environment.apiUrl + "file/formImageUpdate";
  private uploadActivityPlannerImageUrl = environment.apiUrl + "file/activityPlannerImageUpdate";
  private uploadSkillImageUrl = environment.apiUrl + "file/skillImageUpdate";
  private StudentAssignmentSubmissionFileUploadUrl = environment.apiUrl + "file/studentAssignmentSubmissionFileUpload";
  private getStudentAssignmentSubmissionFilesUrl =  environment.apiUrl + "file/getStudentAssignmentSubmissionFiles";
  private getAssignmentSubmissionFileUrl = environment.apiUrl + "file/getAssignmentSubmissionFile"
  private getBotItemImageUrl = environment.apiUrl + "file/botItemImage";
  private botItemImageUpdateUrl = environment.apiUrl + "file/botItemImageUpdate";

  getUserImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getUserImageUrl}/${key}`, { responseType: 'blob' });
  }

  getBotItemImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getBotItemImageUrl}/${key}`, { responseType: 'blob' });
  }

  getSubjectImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getSubjectImageUrl}/${key}`, { responseType: 'blob' });
  }

  getProductImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getProductImageUrl}/${key}`, { responseType: 'blob' });
  }

  getActivityPlannerImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getActivityPlannerImageUrl}/${key}`, { responseType: 'blob' });
  }

  getFeedbackFormImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getFeedbackFormImageUrl}/${key}`, { responseType: 'blob' });
  }

  getSkillImage(key : any): Observable<Blob>{
    return this.httpClient.get(`${this.getSkillImageUrl}/${key}`, { responseType: 'blob' });
  }

  getAssignmentSubmissionFile(fileName : any, officeId : any): Observable<Blob>{
    return this.httpClient.get(`${this.getAssignmentSubmissionFileUrl}/${fileName}/${officeId}`, { responseType: 'blob' });
  }

  uploadUserImage(image : any, activityPlannerId : string): Observable<any>{
    return this.httpClient.post<any>(`${this.uploadUserImageUrl}/${activityPlannerId}`, image);
  }

  botItemImageUpdate(image : any, id : string): Observable<any>{
    return this.httpClient.post<any>(`${this.botItemImageUpdateUrl}/${id}`, image);
  }

  uploadSkillImage(image : any, skillId : string): Observable<any>{
    return this.httpClient.post<any>(`${this,this.uploadSkillImageUrl}/${skillId}`, image);
  }

  uploadSubjectImage(image : any, subjectId : string): Observable<any>{
    return this.httpClient.post<any>(`${this.uploadSubjectImageUrl}/${subjectId}`, image);
  }

  uploadProductImage(image : any, subjectId : string): Observable<any>{
    return this.httpClient.post<any>(`${this.uploadProductImageUrl}/${subjectId}`, image);
  }

  uploadFeedbackFormImage(image : any, formId : string): Observable<any>{
    return this.httpClient.post<any>(`${this.uploadFeedbackFormImageUrl}/${formId}`, image);
  }

  uploadActivityPlannerImage(image : any, activityPlannerId : string): Observable<any>{
    return this.httpClient.post<any>(`${this.uploadActivityPlannerImageUrl}/${activityPlannerId}`, image);
  }

  StudentAssignmentSubmissionFileUpload(file : any,studentId : string, activityPlannerId : string, officeId : string): Observable<boolean>{
    return this.httpClient.post<boolean>(`${this.StudentAssignmentSubmissionFileUploadUrl}/${studentId}/${activityPlannerId}/${officeId}`, file);
  }

  getStudentAssignmentSubmissionFiles(studentId : string, activityPlannerId : string, officeId : string): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.getStudentAssignmentSubmissionFilesUrl}/${studentId}/${activityPlannerId}/${officeId}`);
  }

}
