import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Student } from 'src/app/courseManagementClasses/student';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { Product } from 'src/app/crmClasses/product';
import { Office } from 'src/app/models/office';
import { ProductService } from 'src/app/service/product.service';
import { ResultServiceService } from 'src/app/service/result-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SubjectService } from 'src/app/service/subject.service';

@Component({
  selector: 'app-student-selection-modal',
  templateUrl: './student-selection-modal.component.html',
  styleUrls: ['./student-selection-modal.component.scss']
})
export class StudentSelectionModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private resultService : ResultServiceService,
              private toastr : ToastrService,
              private productService : ProductService,
              private subjectService : SubjectService,
              private studentService : StudentService) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  officeSelectionForm : boolean = true;
  productSelectionForm : boolean = false;
  subjectSelectionForm : boolean = false;
  studentSelectionForm : boolean = false;

  idObject :any = {
    studentId : '',
    fkUserRoleOffice : '',
    subjectId : '',
    selectedStudentName : '',
    selectedSubjectName : ''

  }

  offices : Office[] = [];
  products : Product[] = [];
  subjects : Subject[] = [];
  students : any[] = [{
                      dob: '',
                      email:  '',
                      fkClass:  '',
                      fkUserRoleOffice:  '',
                      phone:  '',
                      skillScore:  '',
                      studentActivityHistoryIndicator:  '',
                      studentAttempts:  '',
                      studentId: '',
                      studentImage: '',
                      studentName: '',
                      studentStatus: ''
                    }];

    selectedOfficeId : any;
    selectedProductId : any;
    selectedSubjectId : any;
    selectedStudentId : any;

    selectedSubjectName : any;
    selectedStudentName : any;

  ngOnInit(): void {

    this.resultService.getAllOffices().subscribe(
      data=>{
        this.offices = data;
      });
  }

  chooseProduct(){
this.productService.getProductListByOfficeId(this.selectedOfficeId).subscribe(
  data=>{
    this.products = data;
    this.productSelectionForm = true;
  });
  }

  chooseSubject(){
this.subjectService.getSubListByProductId(this.selectedProductId).subscribe(
  data=>{
    this.subjects = data;
    this.subjectSelectionForm = true;
  });

  this.studentService.getStudentsByProductSubsciptionRA(this.selectedProductId).subscribe(
    data=>{      
this.students = data;
    });
  }


  onSubmit(){

    this.subjects.forEach((value)=>{
      if(value.subId === this.selectedSubjectId){
        this.idObject.selectedSubjectName = value.name;
        this.idObject.subjectId = this.selectedSubjectId;
      }
    });

    this.students.forEach((value)=>{
      if(value.studentId === this.selectedStudentId){
        this.idObject.selectedStudentName = value.studentName;
        this.idObject.studentId = value.studentId;
        this.idObject.fkUserRoleOffice = value.fkUserRoleOffice;
      }
    });

    // this.idObject.studentId = this.selectedStudentId;
   
    this.passEntry.emit(this.idObject);
    this.activeModal.close();

  }
  
  closeUpdateModal(){
    this.activeModal.close();
  }
}
