import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordServiceService {

  authToken: string | null = '';
  constructor(private http: HttpClient) { 
    this.authToken = localStorage.getItem('auth-token');
  }

 forgetPassword(avenue: string): Promise<any> {

  this.authToken = localStorage.getItem('auth-token');

    const self = this;
    return new Promise((resolve, reject) => {
      self.http.put(avenue,null)
        .toPromise()
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
    });
  }
}
