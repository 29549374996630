<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Schedule Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      
        <mat-spinner style="margin:0 auto;" *ngIf="dataLoaded===false"></mat-spinner>

        <div *ngIf="dataLoaded===true">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
  
            <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-card (click)="changeContext('all');" style="cursor: pointer;" class="example-card mat-elevation-z12" >
               
                 <h4 *ngIf="currentContext==='all'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
                  {{totalCount}} <br> All</h4>
                <h4 *ngIf="currentContext!=='all'" class="grey-f" style="text-align: center;font-weight: bold;">
                  {{totalCount}} <br> All</h4>
                  
                  
                </mat-card>
            </div>
            
      
            <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-card (click)="changeContext('success');" style="cursor: pointer;" class="example-card mat-elevation-z12" >
               
                 <h4 *ngIf="currentContext==='success'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
                  {{successCount}} <br> Successful</h4>
                  <h4 *ngIf="currentContext!=='success'" class="grey-f" style="text-align: center;font-weight: bold;">
                    {{successCount}} <br> Successful</h4>
                  
                </mat-card>
            </div>
      
            <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-card (click)="changeContext('scheduled');" style="cursor: pointer;" class="example-card mat-elevation-z12" >
               
                 <h4 *ngIf="currentContext==='scheduled'" class="grey-f" style="text-align: center;font-weight: bold;color:blue">
                  {{scheduledCount}} <br> Scheduled</h4>
                  <h4 *ngIf="currentContext!=='scheduled'" class="grey-f" style="text-align: center;font-weight: bold;">
                    {{scheduledCount}} <br> Scheduled</h4>
                  
                </mat-card>
            </div>
  
            <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-card (click)="changeContext('queued');" style="cursor: pointer;" class="example-card mat-elevation-z12" >
               
                 <h4 *ngIf="currentContext==='queued'" class="grey-f" style="text-align: center;font-weight: bold;color:blue">
                  {{queuedCount}} <br> Queued</h4>
                  <h4 *ngIf="currentContext!=='queued'" class="grey-f" style="text-align: center;font-weight: bold;">
                    {{queuedCount}} <br> Queued</h4>
                  
                </mat-card>
            </div>
          
            <div fxFlex="20%" fxFlex.xs="100%" fxFlex.sm="50%">
              <mat-card (click)="changeContext('failed');" style="cursor: pointer;" class="example-card mat-elevation-z12" >
               
                 <h4 *ngIf="currentContext==='failed'" class="grey-f" style="text-align: center;font-weight: bold;color: blue;">
                  {{failedCount}} <br> Failed</h4>
                  <h4 *ngIf="currentContext!=='failed'" class="grey-f" style="text-align: center;font-weight: bold;">
                    {{failedCount}} <br> Failed</h4>
                  
                </mat-card>
            </div>
      
            </div>
        </div>
<br>
        <div *ngIf="dataLoaded===true && botScheduleDetails!==null && botScheduleDetails!==undefined && botScheduleDetails.length>0" class="search-hero">
    <button *ngIf="dataLoaded===true" style="width: 100%;" color="primary" 
    (click)="addBotSchedule();"
    mat-raised-button>Schedule Template Message For These Users</button>

<br><br>
<input class="form-control" type="text" name="search" [(ngModel)]="searchText1" autocomplete="off" 
          placeholder="Start searching by name or number">
        </div>
<br>

        <table *ngIf="dataLoaded===true"
        class="table table-striped table-sm hover">
        <thead class="tableheader" >
          <tr>
            <th>Customer Name</th>
            <th>Customer Phone</th>
            <th>Status</th>
            <th>Executed At (Date-Time)</th>
            <th>Group</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let details of botScheduleDetails | filter:searchText1">
            <td style="text-align: left;" class="td-height">{{details.fkBotUserName}}</td>
            <td class="td-height">{{details.fkBotUserPhone}}</td>

            <td *ngIf="botSchedule.botScheduleStatus===false">
              <a style="cursor:pointer;color: black;">
                <img src="/assets/images/icons/nonStarted.png" width="26" height="26" alt="" loading="lazy">
                Deactivated
              </a>
            </td>

            <td *ngIf="botSchedule.botScheduleStatus===true && details.duplicateStatus===null" class="td-height" style="text-align: center;">
             
        <a *ngIf="details.botSchDetExecStatus === 'scheduled' && details.botSchDetCompletionStatus !== 'q'" style="cursor:pointer;color: black;">
          <img src="/assets/images/icons/inProgress.png" width="26" height="26" alt="" loading="lazy">
          Scheduled
        </a> 
        <a *ngIf="details.botSchDetExecStatus === 'success'" style="cursor:pointer;color: black;">
          <img src="/assets/images/icons/tick.png" width="26" height="26" alt="" loading="lazy">
           Successful
        </a> 
        <a *ngIf="details.botSchDetExecStatus === 'failure'" style="cursor:pointer;color: black;">
          <img src="/assets/images/icons/nonStarted.png" width="26" height="26" alt="" loading="lazy">
           Failed
        </a> 

        <a *ngIf="details.botSchDetCompletionStatus === 'q'" style="cursor:pointer;color: black;">
          <img src="/assets/images/icons/wall-clock.png" width="26" height="26" alt="" loading="lazy">
          Queued
        </a>

            </td>

            <td *ngIf="botSchedule.botScheduleStatus===true && details.duplicateStatus==='duplicate'" class="td-height" style="text-align: center;">
              Duplicate Schedule
             </td>
 
            <td class="td-height">
             {{details.executedTimestamp}}
            </td>

            <td class="td-height">
              {{details.botUserGroupName}}
            </td>

          </tr>
        </tbody>
      </table>

</div>
</div>

