export class UserDetails {

  userDetailsId:any
	fkUserId:any
  fkFormConfigId : any
	activeStatus:any

  key : any;
  value : any  //actual value entered by user

  // extra
  displayName : any
  mandatory : any
  columnName : any
  required : any

}
