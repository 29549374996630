import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { Invoice } from 'src/app/crmClasses/invoice';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { InvoiceService } from 'src/app/service/invoice.service';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/crmClasses/product';
import { ProductService } from 'src/app/service/product.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { CustomerService } from 'src/app/BIZ_CRM/biz_crm_services/customer.service';
import { AddOrderDetailsComponent } from '../add-order-details/add-order-details.component';
import { ChooseTaxesComponent } from '../../tax/choose-taxes/choose-taxes.component';
import { Tax } from 'src/app/BIZ_CRM/biz_crm_classes/tax';
 
@Component({
  selector: 'app-add-new-order',
  templateUrl: './add-new-order.component.html',
  styleUrls: ['./add-new-order.component.scss']
})
export class AddNewOrderComponent implements OnInit {
  taxed = false;
  customer : any;
  customers: CompleteCustomerDetails[] = [];
  invoice : Invoice = new Invoice ();
  invoiceDetailsList: InvoiceDetails[] = [];
  closeResult!: string;
  invoiceDet : InvoiceDetails = new InvoiceDetails;
  prod : Product = new Product();
  products: Product[] = [];
  testList: InvoiceDetails[] = []; 
  officeId!: string;
  loggedInUser: any;
  totalAmount : any = 0;
  totalQuantity : any = 0;
  totalAlternateQuantity: any = 0;
  pricePerItem : any;
  clicked = false;
  theme : any
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  taxesAndCess : Tax[] = [];
  taxes : Tax[] = [];
  Cess : Tax[] = [];
  totalTax : any;
  totalCess : any;
  grandTotal : any;
  addedProductIds : string[] = [];
  
  constructor(private  productService : ProductService, 
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private CustomerService : CustomerService,
              private invoiceService : InvoiceService,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef,
              private toaster: ToastrService ) { } 

  
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

    @Input() fromParent: any;

  ngOnInit(): void { 

    this.invoice.fkCustomerId = "";
    this.customer = this.fromParent.customer;
    if(this.customer !== null){
    this.customers.push(this.customer);
    this.invoice.fkCustomerId = this.customer.customerId;
    }
    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.theme = this.sharedDataService.getCardOfficeTheme();

    if(this.customer === null){
    this.getCustomers();
    }

    if(this.fromParent.context === "SalesCoordinator"){
      this.getCustomersForSalesCoordinatorByHierarchy();
    }
 
    this.getProducts();
  } 
  
  getProducts(){
    this.productService.getProductListByOfficeId(this.officeId).subscribe(data => {
      this.products= data;
    });
  }

  getCustomers(){
    this.CustomerService.getCustomersByUroId(environment.apiUrl + 'bcrmCustomer/getCustomersByUroId').then(
      data=>{
        this.customers = data;
      });
  }

  getCustomersForSalesCoordinatorByHierarchy(){
    this.CustomerService.getCustomersByUroId(environment.apiUrl + 'bcrmCustomer/getCustomersForSalesCoordinatorByHierarchy').then(
      data=>{
        this.customers = data;
      });
  }

  addTax(){

    if(this.invoiceDetailsList.length !== 0 && this.invoiceDetailsList !== undefined){
      this.config.class = 'modal-md';
      const modalRef = this.modalService.open(ChooseTaxesComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
  
      let data = {
        officeId : this.officeId
      }
  
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });
  
          modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
 
         this.taxesAndCess = receivedEntry;

            this.invoice.totalTax = 0;
            this.invoice.totalCess = 0;
            this.invoice.grandTotal = 0;

            let count = 0;
            this.taxesAndCess.forEach((value)=>{
              count = count + 1;
              if(value.type === "tax"){
               let val = value.value / 100;
               let tax = this.invoice.totalAmount * val;
               value.calculatedTax = tax;
               this.invoice.totalTax = this.invoice.totalTax + tax;               
              }
            });

            if(this.taxesAndCess.length === count){
              this.taxesAndCess.forEach((value)=>{
                if(value.type === "cess"){
                  let val = value.value / 100;
                  let cess = this.invoice.totalTax * val;
                  value.calculatedTax = cess;
                  this.invoice.totalCess = this.invoice.totalCess + cess;               
                }
              });
            }
            
            this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

     let taxList : InvoiceDetails[] = [];
         this.taxesAndCess.forEach((tax)=>{
           let inv = new InvoiceDetails();
           inv.taxId = tax.id;
           inv.taxType = tax.type;
           inv.taxAmount = tax.calculatedTax;
           taxList.push(inv);
         });
         this.invoice.taxesAndCess = taxList;

         this.taxed = true;


          });  

    }else{
      this.toaster.error("No Products added !")
    }
   
  }

  addInvoiceDetails(){
    this.config.class = 'modal-md';
    const modalRef = this.modalService.open(AddOrderDetailsComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });

    let data = {
      officeId : this.officeId,
      addedProductIds : this.addedProductIds
    }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });

        modalRef.componentInstance.passEntry.subscribe((receivedEntry : InvoiceDetails) => {
          
          this.addedProductIds.push(receivedEntry.fkProductId);
          this.invoiceDetailsList.push(receivedEntry);
        
        this.pricePerItem = receivedEntry.totalPrice;
        this.totalAmount = this.totalAmount + this.pricePerItem;
        this.invoice.totalAmount = this.totalAmount;

        this.totalQuantity =  this.totalQuantity + receivedEntry.quantity;
        this.invoice.totalQuantity = this.totalQuantity;

        this.totalAlternateQuantity = this.totalAlternateQuantity + receivedEntry.alternateQuantity;
        this.invoice.totalAlternateQuantity = this.totalAlternateQuantity ;

        this.invoice.totalTax = 0;
        this.invoice.totalCess = 0;
        this.invoice.grandTotal = 0;

        let count = 0;
        this.taxesAndCess.forEach((value)=>{
          count = count + 1;
          if(value.type === "tax"){
           let val = value.value / 100;
           let tax = this.invoice.totalAmount * val;
           value.calculatedTax = tax;
           this.invoice.totalTax = this.invoice.totalTax + tax;               
          }
        });

        if(this.taxesAndCess.length === count){
          this.taxesAndCess.forEach((value)=>{
            if(value.type === "cess"){
              let val = value.value / 100;
              let cess = this.invoice.totalTax * val;
              value.calculatedTax = cess;
              this.invoice.totalCess = this.invoice.totalCess + cess;               
            }
          });
        }
        
        this.invoice.grandTotal = this.invoice.totalAmount + this.invoice.totalTax + this.invoice.totalCess;            

        });  
  }

  onSubmit(){
    if(this.invoiceDetailsList.length !== 0 && this.invoiceDetailsList !== undefined){
        this.clicked = true;
        this.invoice.type = "order";
        this.invoice.invDetList = this.invoiceDetailsList;
        this.invoice.orderStatus = "Order Placed";
        // authorized
        this.invoiceService.addInvoice(environment.apiUrl + 'completeInvoice/add', this.invoice).then(
          data=>{
            this.invoice = data;
            this.passEntry.emit(this.invoice);
            this.activeModal.close();
            this.toaster.success("Invoice Created Successfully");
          });
    }else{
      this.toaster.error("Add Products to proceed!")
    }
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }
}
