<nav class="navbar navbar-expand-md navbar-dark bg-dark" >
    <div  style="margin-right: 2%;">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
  <a class="navbar-brand" style="color:white;font-weight: bold;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
  
  
      </ul>
    </div>  
  </nav>
  
  
   
  
  
  
  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">
  
    <div *ngIf="showActivityCard===true && ptcDetails.linkStatus==='active'" 
    class="content" style="text-align: center;margin-top: 3%;">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">

        <div fxFlex="25%" fxFlex.xs="0%" fxFlex.sm="0%" >

        </div>
    
        <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" >
          <mat-card style="border-radius: 25px;" class="example-card mat-elevation-z12" *ngFor="let act of activityPlannerListWithSafeUrl">
    
            <div class="Activity-Card-Row-1" style="text-align: center;">
  
                  <img style="border-radius: 50%;" class="Activity-Image" [src]="act.safeUrl"
                  height="75" width="75" alt="Card image cap">
  
                </div>
  <br>
  
              <div class="Activity-Card-Row-2" style="text-align: center;">
  
                  <h4 class="card-title">{{act.activityPlannerName}}</h4>
               
                  <p class="card-text">{{act.activityPlannerDescription}}</p>
                  
                  <button *ngIf="collapsedState===false" class="btn btn-info" (click)="collapseActivityCardContent()">
                    Learn</button>
              </div>
         

         
          <div class="card-body">
             
          <div *ngIf="collapsedState === true" >
            
            <mat-vertical-stepper>
  
              <div class="stepper-class">
  
              <mat-step [editable]="true"  *ngFor="let video of act.activityVideoLinkList">
                <ng-template matStepLabel>  {{video.actVideoLinkName}} </ng-template>
                  <p></p>
  
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" [src]="video.urlSafe" allowfullscreen></iframe>
                  </div>
  
                    <br>
          
                    {{video.actVideoLinkDescription}}
                    <!-- <button mat-raised-button matStepperNext>Next</button> -->
              </mat-step>
              
          </div>
  
  
            </mat-vertical-stepper>
  
            <br>
  
  <h4 style="font-weight: bold;margin: 0%;">Completed the activity ? Click the below button to assess</h4>

            <button style="margin: 5%;" (click)="onAssess(act.activityPlannerId)" 
            class="btn btn-success">Assess</button>
            <button class="btn btn-info" (click)="collapseActivityCardContent()">
                Close</button>

            </div>
  
            

              
          </div> 
          
         
        </mat-card>
        </div>

        <div fxFlex="25%" fxFlex.xs="0%" fxFlex.sm="0%" >

        </div>
    
      </div>
      </div>
  
     


<div *ngIf="showActivityCard===true && ptcDetails.linkStatus==='completed'" class="container" style="align-self: center; text-align: center;">
<br><br><br><br><br>
<a> <img src="assets/images/icons/selected.png" height="80" width="80"></a>
<h2 style="margin-top: 2%; align-self: center;color: rgb(77, 75, 75);"> This Activity has been completed !</h2>
<button style="width: auto;" class="btn btn-primary">Generate new link </button>
</div>
  

<div *ngIf="showActivityCard===true && ptcDetails.linkStatus==='activity completed now'" class="container" style="align-self: center; text-align: center;">
<br><br><br><br><br>
<a> <img src="assets/images/icons/selected.png" height="80" width="80"></a>
<h2 style="margin-top: 2%; align-self: center;color: rgb(77, 75, 75);"> Activity completed successfully ! </h2>
</div>
  
  
<div *ngIf="showActivityCard===true && ptcDetails.linkStatus==='expired'" class="container" style="align-self: center; text-align: center;">
<br><br><br><br><br>
<a> <img src="assets/images/icons/dueToday.png" height="80" width="80"></a>
<h2 style="margin-top: 2%; align-self: center;color: rgb(77, 75, 75);"> This link has expired ! </h2>
<button style="width: auto;" class="btn btn-primary"> Generate new link </button>
</div>

</div>
  
    <div class="wrapper">
      <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>
    
      <div class="loaded-content" [class.blurred]="showSpinner">
        <ng-content></ng-content>
      </div>
    </div>
  
  
  
  