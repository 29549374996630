import { SafeResourceUrl } from "@angular/platform-browser";

export class Message {
  action: any;
  message: any;
  timestamp: any;
  userRespondedType: any;
  erroneousResFromUser: any;
  locationUrl: any;
  respondedBy: any;
  fkResponseBotMediaId: any;
  fkRequestBotMediaId: any;
  botRespondedType: any;
  botConvoLogId: any;
  errorMsg: any;

  //media
  safeImageUrl: any;
  mediaLoaded: any;
  urlSafe!: SafeResourceUrl;
  pdfFile!: SafeResourceUrl;
  mimeType: any;
  playVideo: any;
}
