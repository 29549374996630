import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-user-search-modal',
  templateUrl: './user-search-modal.component.html',
  styleUrls: ['./user-search-modal.component.scss']
})
export class UserSearchModalComponent implements OnInit {

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal,
              private toaster: ToastrService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              private subscriptionService : SubscriptionService,
              private sharedDataService : SharedDataService
              ) { }
              loggedInUser: any;
              theme: any;

              users : any[] =[{
                address: '',
                dateOfBirth: '',
                email: '',
                firstName: '',
                gender: '',
                lastName:'',
                office: '',
                phoneNumber: '',
                role:'',
                userId: '',
                userImage : '',
                status: '',
                safeUrl: ''
              }]


    safeStudentUrl : any;
    unsafeStudentUrl : any;
    classExists = false;
    officeId : any;
    value : any = null;

  ngOnInit(): void {

    this.officeId = this.fromParent.officeId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
}

getColor(){
  this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
  return this.loggedInUser.offices[0].theme + '!important';
}
get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

  searchFoUser2(){

    if(this.value === null){
      this.toaster.error('Enter Email / Phone / Name');
    }else{

    this.passEntry.emit(this.value);
    this.activeModal.close();
    }
  }

  searchFoUser(){
    // this.showSpinner = true;
    this.subscriptionService.getUsersByEmailOrPhone(environment.apiUrl + "user/getUsersByEmailOrPhone/" + this.value + "/" + this.officeId).then(
      data=>{
        this.users = data;

        if(this.users.length === 0 || this.users === undefined){
          setTimeout(()=>{                           //timer
       }, 1500);
        }

        let count : number = 0;
        this.users.forEach( (value) => {
          count++;
          this.imageService.getUserImage(value.userImage).subscribe(
          data=>{
          this.unsafeStudentUrl = URL.createObjectURL(data);
          this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
          
          value.safeUrl = this.safeStudentUrl;
          if(count === this.users.length){
            setTimeout(()=>{                           //timer
         }, 1500);
          }
          });
          });
        this.classExists = true;
        
        if(this.users === undefined || this.users.length === 0 ){
        this.toaster.error('User not found !')
        }else{
          this.passEntry.emit(this.users);
          this.activeModal.close();
        }
      });
  }

  closeUpdateModal(){
    this.activeModal.close();
  }
  

}
