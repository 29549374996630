import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Result } from 'src/app/courseManagementClasses/result';
import { Skill } from 'src/app/courseManagementClasses/skill';
import { ActivityPlannerService } from 'src/app/service/activity-planner.service';
import { CurriculumService } from 'src/app/service/curriculum.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { ResultServiceService } from 'src/app/service/result-service.service';
import { SkillServiceService } from 'src/app/service/skill-service.service';
import { StudentService } from 'src/app/service/student.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-results-modal',
  templateUrl: './edit-results-modal.component.html',
  styleUrls: ['./edit-results-modal.component.scss']
})
export class EditResultsModalComponent implements OnInit {

  result : Result = new Result();

  clicked = false;

  @Input() fromParent: any;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  constructor(public activeModal : NgbActiveModal,
    private skillService : SkillServiceService,
    private studentService : StudentService,
    private toaster : ToastrService,
    private resultService : ResultServiceService) { }

  ngOnInit(): void {
    this.result = this.fromParent.result;
  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  onSubmit(){
 
    this.resultService.editResult(this.result).subscribe(
      data=>{
       this.passEntry.emit(this.result.resultId);
       this.activeModal.close();
       this.toaster.success('Result Updated Successful');
    });
  }
}
