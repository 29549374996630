import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { ActivityPlanner } from 'src/app/courseManagementClasses/activity-planner';
import { GetCombinedPTCDetails } from 'src/app/courseManagementClasses/combinedParentTeacher/get-combined-ptc-details';
import { GetCPTCDetails } from 'src/app/courseManagementClasses/get-cptcdetails';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { CombinedParentTeacherService } from 'src/app/service/courseManagementServices/combined-parent-teacher.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { ActivityFeedbackQuestionsModalComponent } from '../activity-feedback-questions-modal/activity-feedback-questions-modal.component';
import { ResultPageComponent } from '../result-page/result-page.component';

@Component({
  selector: 'app-combined-user-parent-dashboard',
  templateUrl: './combined-user-parent-dashboard.component.html',
  styleUrls: ['./combined-user-parent-dashboard.component.scss'],
  animations: [
    trigger('bodyExpansion', [
      state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed, void => collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
}) 
export class CombinedUserParentDashboardComponent implements OnInit {

  currentRole = "PARENT_MENTOR";
  collapsed : boolean = false;
  showSpinner : boolean = true;
  collapsedState : boolean = false ;
  value1: any;
  loggedInUser: any;
  gridColumns = 3;
  parentUserId!: string;
  userName!: string; 
  combinedPtcDetails: GetCombinedPTCDetails = new GetCombinedPTCDetails;
  studentUserId!: string;

  ptcDetails : GetCPTCDetails = new GetCPTCDetails;
  actPlnrList : ActivityPlanner[] = [];
  subjectList : Subject[] = [];

  fkStudentId!: string; 
  fkTeacherId!: string;
  returnString!: string;

  childName! : any;

  showActivityCard = false;

  safeChildImageUrl : any;
  unsafeChildImageUrl : any;

  url: string = "https://www.youtube.com/embed/zpOULjyy-n8?rel=0";
  urlSafe!: SafeResourceUrl;

  activityPlannerListWithSafeUrl : any[] =[ { activityPlannerId : '', 
                                              activityPlannerName : '',
                                              activityPlannerDescription : '',
                                              activityPlannerOrder : '',
                                              activityPlannerStatus : '',
                                              fkSubjectId : '',
                                              activityPlannerImage : '',
                                              safeUrl : '',
                                              
                          activityVideoLinkList : [{
                                                      actVideoLinkId: "",
                                                      actVideoLinkName: "",
                                                      actVideoLink: "",
                                                      actVideoLinkDescription: "",
                                                      fkActivityPlannerId: "",
                                                      actVideoLinkStatus: "",
                                                      urlSafe: ""
                                                    }]
                                            } ];
  xyz: any;
  abc: any;
  officeId: any;

nextActivityDay : any;

  constructor(private datePipe : DatePipe,
              private sharedDataService : SharedDataService,
              private officeService : OfficesService,
              private router : Router,
              private route : ActivatedRoute,
              private combinedParentTeacherService : CombinedParentTeacherService,
              private ngbModalService: NgbModal,
              private imageService : FileServiceService,
              private toastr : ToastrService,
              private sanitizer : DomSanitizer ) { }

              officeObject : any = {
                activeStatus: '',
                backgroundColor: '',
                id:'',
                logo: '',
                oa_ParentMentor:'',
                oa_SubAdmin: '',
                oa_SkillReport: '',
                officeLocation: '',
                officeName: '',
                themeColour: ''
              }

    config = { 
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };


  panelOpenState = false;
  capacity: any;
  title = 'my-app';
  state = 'collapsed';
  navbarTitle : any;

  toggle(): void {
    this.state = this.state === 'collapsed' ? 'expanded' : 'collapsed';
  }

  ngOnInit(): void {

    this.collapsed = true;
    this.navbarTitle = GlobalClass.navbarTitle;

    this.studentUserId = this.route.snapshot.params['childUserId'];

    this.value1 = 100;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.parentUserId = this.loggedInUser.userId;
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
     this.officeId = this.loggedInUser.offices[0].id;
     
     this.officeService.getOfficeById(this.officeId).subscribe(
      data=>{
        this.officeObject = data;
      });

    this.getCPTCDetails();

  }

  markActivityAsComplete(){
    
  this.combinedParentTeacherService.markActivityAsComplete(environment.apiUrl + "combinedPTC/markActivityAsComplete/" + this.fkTeacherId).then(
    data=>{

      this.getCPTCDetails();
      this.toastr.success("Activity marked as Complete");
        this.collapsedState = false;

    });
  }

  collapsePanel(state : boolean){
    if(state === true){
      this.collapsed = false;
    } else if(state === false){
      this.collapsed = true;
    }
  }

  collapseActivityCardContent(){
    if(this.collapsedState === true){
      this.collapsedState = false;
    } else if(this.collapsedState === false){
      this.collapsedState = true;
    }
   }

   // authorized
  getCPTCDetails(){

    this.showSpinner = true;
    this.collapsedState = false;

    this.combinedParentTeacherService.getCombinedPTCActPlnrDtoByUserId(environment.apiUrl + "combinedPTC/getChildAndActivitiesForParentMentor/" + this.studentUserId).then(
      data=>{
        this.showActivityCard = false;
        this.ptcDetails = data;
        this.childName = this.ptcDetails.childFirstName + ' ' + this.ptcDetails.childLastName;

        if(this.ptcDetails.todaysActivityComplete === true && this.ptcDetails.nextActivityDate !== null){
        this.ptcDetails.nextActivityDate = new Date(this.ptcDetails.nextActivityDate);      
        this.nextActivityDay = this.datePipe.transform(this.ptcDetails.nextActivityDate, 'EEEE');
      }

        this.value1 = this.ptcDetails.progressValue;

        this.value1 = Math.round((this.value1 + Number.EPSILON) * 100) / 100;

        this.actPlnrList = this.ptcDetails.activityPlannerList;
        this.fkStudentId = this.ptcDetails.studentId;
        this.fkTeacherId = this.ptcDetails.teacherId;

        this.activityPlannerListWithSafeUrl.pop();

          for(var index in this.actPlnrList){

            this.activityPlannerListWithSafeUrl.push( { 'activityPlannerId' : this.actPlnrList[index].activityPlannerId,
                                                        'activityPlannerName' : this.actPlnrList[index].activityPlannerName,
                                                        'activityPlannerDescription' : this.actPlnrList[index].activityPlannerDescription,
                                                        'activityPlannerOrder' : this.actPlnrList[index].activityPlannerOrder,
                                                        'activityPlannerStatus' : this.actPlnrList[index].activityPlannerStatus,
                                                        'fkSubjectId' : this.actPlnrList[index].fkSubjectId,
                                                        'activityPlannerImage' : this.actPlnrList[index].activityPlannerImage,
                                                        'safeUrl' : this.xyz,

                                                        'activityVideoLinkList' : this.actPlnrList[index].activityVideoLinkList
                                                      } );
        }

        this.activityPlannerListWithSafeUrl.forEach( (value) => {

          value.activityPlannerDescription = value.activityPlannerDescription.split('the child ').join(this.ptcDetails.childPetname + " ");
          value.activityPlannerDescription = value.activityPlannerDescription.split('The child ').join(this.ptcDetails.childPetname + " ");

          this.imageService.getActivityPlannerImage( value.activityPlannerImage ).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);
              value.safeUrl = this.xyz;
            });

        }); 

        this.activityPlannerListWithSafeUrl.forEach((info)=>{

          info.activityVideoLinkList.forEach((val : any)=>{
            
          val.actVideoLinkDescription = val.actVideoLinkDescription.split('the child ').join(this.ptcDetails.childPetname + " ");
          val.actVideoLinkDescription = val.actVideoLinkDescription.split('The child ').join(this.ptcDetails.childPetname + " ");

          this.url =  val.actVideoLink;
          this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          val.urlSafe = this.urlSafe
    
        });
        });

        if(this.activityPlannerListWithSafeUrl.length === 0){
          if(this.ptcDetails.toasterMessage === "All Activities Completed !"){
            this.toastr.success(this.ptcDetails.toasterMessage);
          }else if(this.ptcDetails.toasterMessage === "No Activities to display !"){
            this.toastr.show(this.ptcDetails.toasterMessage);
          }
        }else{
          this.showActivityCard = true;
        }

        // GETS CHILD IMAGE
        this.imageService.getUserImage(this.ptcDetails.childImage).subscribe(
          data=>{
            this.unsafeChildImageUrl = URL.createObjectURL(data);
            this.safeChildImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeChildImageUrl);
            setTimeout(()=>{                           //timer
              this.showSpinner = false;
         }, 1500);
          });

      });
  }

  goToAccount(){
    this.router.navigate(['account',this.parentUserId]);
  }

  // authorized
  goToResultPage(studentId : string, image : any, name : any){
  
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ResultPageComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
    let data = {
      studentId : studentId,
      name : name,
      image : image
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        }); 
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : "") => {
    })
  
  }

  // authorized
  onAssess(activityPlannerId : string){
    this.onAssess2(activityPlannerId, this.fkStudentId, this.fkTeacherId );
    }
    // authorized
    onAssess2(activityPlannerId : string, fkStudentId : string, fkTeacherId : string ){

    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(ActivityFeedbackQuestionsModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false,
        backdrop : 'static',
        backdropClass : 'backG-dark'
      });
      
    let data = {
      activityPlannerId: activityPlannerId ,
      fkStudentId : fkStudentId,
      fkTeacherId : fkTeacherId,
      safeImageUrl : this.safeChildImageUrl,
      studentName : this.childName,
      childPetname : this.ptcDetails.childPetname
        }

    modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        }); 
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : "") => {
      this.getCPTCDetails();
      this.collapsedState = false;

    })

    } 

    switchDashboard(){
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
        {
          size : 'sm',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = { 
        userId : this.parentUserId,
        currentRole : this.currentRole
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });    
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

}
