import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { Announcement } from 'src/app/BIZ_CRM/biz_crm_classes/announcement';
import { AnnouncementService } from 'src/app/BIZ_CRM/biz_crm_services/announcement.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';
import { EditAnnouncementComponent } from '../edit-announcement/edit-announcement.component';

@Component({
  selector: 'app-view-announcement',
  templateUrl: './view-announcement.component.html',
  styleUrls: ['./view-announcement.component.scss']
})
export class ViewAnnouncementComponent implements OnInit {
  
  constructor(private activeModal : NgbActiveModal,
    private ngbModalService: NgbModal,
    private announcementService : AnnouncementService,
    private datePipe : DatePipe,
    private sharedDataService : SharedDataService,
    private toastrService : ToastrService) { }
    closeResult !: String
    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md'
    };

    @Output() passEntry : EventEmitter<any> = new EventEmitter();
    @Input() fromParent : any;

  announcements : Announcement[] = [];
  clicked = false;
  loggedInUser : any; 
  theme : any;

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.getAnnouncementsForEdit();
  }

  getAnnouncementsForEdit(){
    this.announcementService.getAnnouncementsForEdit(environment.apiUrl + "announcements/getAnnouncementsForEdit").then(
      data=>{
        this.announcements = data;
        this.announcements.forEach((value)=>{
          let sd = value.startDate;
          let startDate = new Date(sd);      
          value.formattedStartDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');
 
          let ed = value.endDate;
          let endDate = new Date(ed);      
          value.formattedEndDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
          
        })
      });
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeUpdateModal(){
    this.activeModal.close();
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  deleteAnnouncement(id : any){
    this.announcementService.deleteAnnouncement(environment.apiUrl + "announcements/deleteById/" + id).then(
      data=>{
        this.getAnnouncementsForEdit();
        this.passEntry.emit("success");
      });
  }
  
  editAnnouncements(announcement :any){
 this.config.class = 'modal-md';
              const modalRef = this.ngbModalService.open( EditAnnouncementComponent,
                {
                  size : 'md',
                  scrollable: true,
                  windowClass: 'app-modal-window',
                  keyboard: false
                });
              let data = {
                announcement : announcement
              }
            
              modalRef.componentInstance.fromParent = data;
                modalRef.result.then((result) => {
                }, (reason) => {
                });
            
              modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
                this.getAnnouncementsForEdit();
                this.passEntry.emit("success");
              });  
  }
}

