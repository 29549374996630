import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { GlobalClass } from "src/app/global/global-class";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotMemberInfo } from "src/app/BOT_CODE/bot_classes/bot-member-info";

@Component({
  selector: "app-create-bot-member",
  templateUrl: "./create-bot-member.component.html",
  styleUrls: ["./create-bot-member.component.scss"],
})
export class CreateBotMemberComponent implements OnInit {
  clicked = false;
  botMemberInfo: BotMemberInfo = new BotMemberInfo();
  userExists = false;
  loggedInUser: any;
  theme: any;
  matSpinnerActive = false;

  constructor(
    private botOfficeService: BotOfficeServiceService,
    private sharedDataService: SharedDataService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  countryCodes: any[] = [];
  chooseAdmin = false;

  ngOnInit(): void {
    this.countryCodes = GlobalClass.CountryCodes;

    this.botMemberInfo = this.fromParent.botMemberInfo;

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  onSubmit() {
    if (this.fromParent.context === "ADD_BOT_MEMBER") {
      this.clicked = true;
      this.botOfficeService
        .addOrEditBotMemberInfo(
          environment.apiUrl + "botConvo/addOrEditBotMemberInfo",
          this.botMemberInfo
        )
        .then((res) => {
          this.botMemberInfo = res;
          // if(this.botUser.userExists === true){
          //   this.clicked = false;
          //   this.toastr.error("email already registered !");
          // }else if(this.botUser.userExists === false){
          this.toastr.success("User created successfully !");
          this.activeModal.close();
          this.passEntry.emit(this.botMemberInfo);
          // }
        });
    } else if (this.fromParent.context === "EDIT_BOT_MEMBER") {
      this.clicked = true;
      this.botOfficeService
        .addOrEditBotMemberInfo(
          environment.apiUrl + "botConvo/addOrEditBotMemberInfo",
          this.botMemberInfo
        )
        .then((res) => {
          this.toastr.success("User updated successfully !");
          this.activeModal.close();
          this.passEntry.emit(this.botMemberInfo);
        });
    }
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }
}
