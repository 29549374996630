import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Product } from 'src/app/crmClasses/product';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { GlobalClass } from 'src/app/global/global-class';

@Component({
  selector: 'app-add-parent',
  templateUrl: './add-parent.component.html',
  styleUrls: ['./add-parent.component.scss']
})
export class AddParentComponent implements OnInit {

  closeResult!:string;
  clicked : boolean = false;
  combinedPTC : AddCombinedParentTeacher = new AddCombinedParentTeacher();
  officeId : any;
  products : Product[] = [];
  roles : any[] = ["TEACHER", "STUDENT"];
  userExists = false;
  parentExists = false;
  type : any;

  @Input() fromParent: any;  

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  studentUserId: any;
  
  constructor(
              private toaster : ToastrService,
              public activeModal: NgbActiveModal,
              private ngbModalService: NgbModal,
              private subscriptionService  : SubscriptionService ,         
                   private sharedDataService : SharedDataService
              ) { }

              loggedInUser: any;
              theme: any;
              countryCodes : any[] = [];
     
 ngOnInit(): void {
            
    this.countryCodes = GlobalClass.CountryCodes;
this.officeId =  this.fromParent.officeId;
this.studentUserId = this.fromParent.studentUserId;
this.combinedPTC.studentUserId = this.studentUserId;
this.combinedPTC.fkOfficeId = this.officeId;

this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
this.theme = this.sharedDataService.getCardOfficeTheme();

}

getColor(){
this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
return this.loggedInUser.offices[0].theme + '!important';
}
get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}
  onSubmit(){
      // this.subscriptionService.searchUserByEmail(this.combinedPTC.email).subscribe(
      //   data=>{
      //     this.userExists = data;

      //     if(this.userExists === true){
      //       this.toaster.error('Email is already registered !')
      //       this.clicked = false;
      //     }
      //     else if(this.userExists === false){
   
      //       this.subscriptionService.addParent(environment.apiUrl + "user/addParent", this.combinedPTC).then(
      //         data=>{
      //           this.combinedPTC = data;
      //           this.passEntry.emit(this.combinedPTC.email);
      //           this.activeModal.close();
      //           this.toaster.success("User Added Successfully");
      //         });
      //     }
      //   });

      this.clicked = true;
      
      this.combinedPTC.username = this.value;

      this.subscriptionService.addParent(environment.apiUrl + "user/addParent", this.combinedPTC).then(
        data=>{
          this.combinedPTC = data;
          this.passEntry.emit(this.combinedPTC.email);
          this.activeModal.close();
          this.toaster.success("Parent Created Successfully");
        });

  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  usernameExists = false;
  choice : any;
  value : any;
emailUnavailable = false;
phoneUnavailable = false;

  checkUsernameAvailability(){

    if(this.emailUnavailable === true && this.phoneUnavailable === true){
      this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
    }else{

    if(this.choice === "Email"){
      this.value = this.combinedPTC.email;
    }else if(this.choice === "Phone"){
      this.value = this.combinedPTC.phoneNumber;
    }

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{

        this.usernameExists = res;

        if(this.usernameExists === true){

          if(this.choice === "Email"){
           this.emailUnavailable = true;
          }else if(this.choice === "Phone"){
            this.phoneUnavailable = true;
          }

          if(this.emailUnavailable === true && this.phoneUnavailable === true){
            this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
            this.value = null;
          }else{
            this.toaster.error("Username exists !");
          }

        }else if(this.usernameExists === false){

          this.toaster.success("Username available !");

          this.onSubmit();

        }
      });

    }
  }

  checkCustomUsernameAvailability(){

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{

        this.usernameExists = res;

        if(this.usernameExists === true){

          this.toaster.error("Username exists !");

        }else if(this.usernameExists === false){

          this.toaster.success("Username available !");

          this.onSubmit();
        }
      });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

}
