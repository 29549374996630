<div  [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Child To {{fromParent.parentName}}</h4>
    <button (click)="closeUpdateModal()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-body">
        <form (ngSubmit)="onSubmit" #adminForm="ngForm">


<br>
<h3 style="text-align: center;font-weight: bold;">STUDENT DETAILS :</h3>


<div class="form-group row">

  <div class="col-md-4">
  <label class="required" for="studentFirstName">First Name</label>
  <input  maxlength="50"  class="form-control" type="text" placeholder="Enter First Name" name="studentFirstName" id="studentFirstName" [(ngModel)]="combinedPTC.studentFirstName">
  </div>

  <div class="col-md-4">
  <label class="required" for="studentLastName">Last Name</label>
  <input required maxlength="50"  class="form-control" type="text" placeholder="Enter Last name" name="studentLastName" id="studentLastName" [(ngModel)]="combinedPTC.studentLastName">
  </div>

  <div class="col-md-4">
    <label for="childPetname">Nick Name</label>
    <input maxlength="50"  class="form-control" type="text" placeholder="Enter nick name" name="childPetname"
     id="childPetname" [(ngModel)]="combinedPTC.childPetname">
    </div>

</div>

<div class="form-group row">
<div class="col-md-6">
  <label >Gender </label> <br>
  <mat-radio-group aria-label="studentGender" [(ngModel)]="combinedPTC.studentGender">
    <mat-radio-button name="studentGender" id1="male" value1="male"  >Male</mat-radio-button>
    <mat-radio-button name="studentGender" id1="female" value1="female"  >Female</mat-radio-button>
    <mat-radio-button name="studentGender" id1="other" value1="other"  >Other</mat-radio-button>
  </mat-radio-group>
</div>

<div class="col-md-6">
  <label for="studentDateOfBirth">Date of Birth</label>
  <input class="form-control" type="date"  name="studentDateOfBirth" id="studentDateOfBirth" [(ngModel)]="combinedPTC.studentDateOfBirth">
  </div>
</div>


<br>
<h3 style="text-align: center;font-weight: bold;">COURSE DETAILS :</h3>
<div class="form-group row"> 

<div class="col-md-4">
  <label class="required" for="fkProductId">Course / Product</label>
  <select #product="ngModel" required class="form-control col-md-12" [(ngModel)]="combinedPTC.fkProductId" id="fkProductId" name="fkProductId">
    <option disabled value="">Choose Course / Product</option>
    <option *ngFor="let product of products" [ngValue]="product.productId">{{product.productName}}</option>
  </select>
  <div class="alert alert-danger" *ngIf="product.touched && !product.valid">Select Product</div>
</div>
<div class="col-md-4">
  <label  class="required" for="startedDate">Start Date</label>
  <input required class="col-md-12 form-control" type="date" id="startedDate" name="startedDate" [(ngModel)]="combinedPTC.startedDate">
</div>
<div class="col-md-4">
  <label  class="required" for="endedDate">End Date</label>
  <input required class="col-md-12 form-control" type="date" id="endedDate" name="endedDate" [(ngModel)]="combinedPTC.endedDate">
</div>

</div>


        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button style="width: auto;" [disabled]="clicked"  [disabled]="!adminForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();">Save</button>
  </div>
