import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Result } from 'src/app/courseManagementClasses/combinedParentTeacher/result';
import { LogoutService } from 'src/app/service/loginLogout/logout.service';
import { ResultService } from 'src/app/service/result.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss']
})
export class ResultPageComponent implements OnInit {

  resultExists : boolean = true;

  safeImageUrl: any;
  unsafeImageUrl: any;

  studentId!: string;
  parentUserId!: string;
  resultList : Result[] = [];
 
  panelOpenState = false;

  // lineChartData: ChartDataSets[] = [
  //   { data: [7, 8, 8, 5.5, 6, 8], label : 'progress'},
  // ];
  lineChartData =[{ 
                            data: [1,2,2,1,2,2,1], 
                            label : 'progress'
                          }];
                          
                     datae: any[] = [];  

  lineChartLabels: Label[] = ['', '', '', '', '', ''];

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType : ChartType = 'line';

  lineChartOptions = {
    
    legend: {
      onClick: (e: { stopPropagation: () => any; }) => e.stopPropagation()
  },
 
    elements: {
      line: {
          tension: 0
      }
  },
    responsive: true,
    scales: {
        xAxes: [{
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
               display: false
            }
        }],
        yAxes: [{
          
            display: false
       ,
            gridLines: {
              drawBorder: false,
              display: false,
                color: "rgba(0, 0, 0, 0)",
                
            }   
        }]
    }
}
  loggedInUser: any;
  loggedUser: any;

  @Input() fromParent: any;


  resultListWithChartData : any[] = [{

                          resultId: '',
                          fkStudentId: '',
                          fkSubjectId: '',
                          fkSkillId: '',
                          skillScore: '',
                          resultStatus: '',
                          resultHistory: '',
                          resultSuggestion: '',
                        
                            skillName: '',
                          parentSkillName: '',

                          lineChartData :[{ 
                            data: [1,2,2,1,2,2,1], 
                            label : 'progress'
                          }]
  }]

  constructor(
              public activeModal: NgbActiveModal,
              private resultService : ResultService ,
              private sharedDataService : SharedDataService
              ) { }
              theme: any;

              config = {
                backdrop: true,
                ignoreBackdropClick: true,
                keyboard: false,
                class: 'modal-md',
                windowClass: 'app-modal-window'
              };

              details : any ={
                dob: "", 
                email: "",
                phone: "",
                studentImage: "",
                studentName: ""
              }

  ngOnInit(): void { 

    this.studentId = this.fromParent.studentId;
    this.details. studentName = this.fromParent.name;
    this.safeImageUrl = this.fromParent.image;

    // authorized
   this.resultService.getResultsByStudentId(environment.apiUrl + "combinedPTC/getResultsByStudentId/" + this.studentId).then(
     data=>{
       this.resultList = data;
      this.resultListWithChartData = data;

      if (this.resultList === undefined || this.resultList.length == 0) {
        this.resultExists = false;
    }
      
      this.resultListWithChartData.forEach((value)=>{

        var myArray = value.resultHistory.split(",");
for(var i=0; i<myArray.length; i++) { this.datae[i] = +myArray[i]; } 

        // this.datae = [1,2,4,3,2,1];

        value.lineChartData = [
          { data: this.datae,
             label : 'progress'},
        ];
        this.datae = [];
      });
     

     });

     this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
     this.theme = this.sharedDataService.getCardOfficeTheme();
 }

 getColor(){
   this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
   return this.loggedInUser.offices[0].theme + '!important';
 }
  closeModal(){
    this.activeModal.close(); 
  }
 
}
