<nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;float:right;"> {{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">

        <!-- <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="refreshNotifications();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-refresh" aria-hidden="true"></i> Refresh </a>
        </li> -->

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-home" aria-hidden="true"></i> Home </a>
        </li>

        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage();" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
       
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

 
  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <mat-tab-group (selectedTabChange)="tabClick($event)" style="padding: 1em;" dynamicHeight>

    <mat-tab label="New Conversation Notifications">   
      <br>
       <h2 style="text-align: left; font-weight: bold;">New Conversation Notifications
         <a *ngIf="newNotificationsListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
         (click)="newNotificationsListType='cards'">
           <img src="/assets/images/icons/eye.png" width="25" height="25"
           alt="" loading="lazy">
        </a>
        <a *ngIf="newNotificationsListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
        (click)="newNotificationsListType='table'">
         <img src="/assets/images/icons/eye.png" width="25" height="25"
         alt="" loading="lazy">
      </a>
         <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
         (click)="exportArrayGeneric(newCustomConvoFlowNotifications.newNotifications)">
           <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
         </a>
         <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Search By Date"
         (click)="open(searchByDateModal);searchType='custom convo flow notification'">
           <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
         </a> 
         <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Refresh"
         (click)="refreshNotifications();">
           <img src="/assets/images/icons/rotate.png" width="25" height="25" alt="" loading="lazy">
         </a> 
 
         <h4> [{{newCustomConvoFlowNotifications.from}} to {{newCustomConvoFlowNotifications.to}}] </h4>
       </h2>
 
       <!-- <button style="width:auto;" (click)="open(searchByDateModal);searchType='custom convo flow notification'" class="btn btn-info">Search By Date</button> -->
 
       <div class="search-hero">
         <input class="form-control" type="text" name="search" [(ngModel)]="searchText4" autocomplete="off" 
         placeholder="Start searching by name, phone, date or message">
       </div>
   
   <br>
       <div *ngIf="newNotificationsListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
   
         <div *ngFor="let notification of newCustomConvoFlowNotifications.newNotifications | filter:searchText4" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
           <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
             
           <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
           <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
           <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
   
           <a style="font-weight: bold;">Message : </a>
           <div style="padding: 2%; overflow-y: auto;height: 180px;">
             <a style="text-align: justify;text-justify: inter-word;white-space: pre-line">
               {{notification.botAdminNotificationMessage}}</a> 
           </div>
           <br>
 
           <div *ngIf="notification.media !== null && notification.media !== undefined && notification.media.length > 0">
             <a style="font-weight: bold;">Media : </a> <br>
             <button style="margin: 0.4%;width: auto;" class="btn btn-primary" *ngFor="let media of notification.media"
             (click)="viewBotMedia(media.mediaObjId, media.mediaType);">View {{media.mediaName}}</button>
           </div>
           <br *ngIf="notification.media !== null && notification.media !== undefined && notification.media.length > 0">
 
           <div style="text-align: center;">          
             <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
               <mat-icon>remove_red_eye</mat-icon>
           </button>
           </div>
         </mat-card>
         </div>
   
       </div>
   
       <div *ngIf="newNotificationsListType==='table'" class="card">
 
     
 
         <div *ngIf="grpNewConvTableBasedOnTitle===false" class="card-body table-responsive">
 
           <button (click)="groupNotifications();" class="btn btn-secondary" 
           style="width: auto;float: right;">Group Notifications</button>
 
           <table *ngIf="tableNewConvoFlowNotifsExists" class="table table-striped table-sm hover" datatable
             [dtOptions]="userOptions">
             <thead class="tableheader">
               <tr>
                 <th>NAME</th>
                 <th>PHONE</th>
                 <th>DATE</th>
                 <th>MESSAGE</th>
                 <th>MEDIA</th>
                 <th>ACTION</th>
               </tr>
             </thead>
             <tbody>
               <tr class="td-height" *ngFor="let notification of newCustomConvoFlowNotifications.newNotifications | filter:searchText4">
                 <td class="td-height">{{notification.fkBotUserName}}</td>
                 <td class="td-height">{{notification.fkBotUserPhone}}</td>
                 <td class="td-height">{{notification.formattedTimestamp}}</td>
                 <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                 <td class="td-height">
 
                   <button style="margin: 0.4%;width: auto;" class="btn btn-primary" *ngFor="let media of notification.media"
                   (click)="viewBotMedia(media.mediaObjId, media.mediaType);">View {{media.mediaName}}</button>
 
                 </td>
                 <td>
   
                   <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                     <mat-icon>remove_red_eye</mat-icon>
                 </button>  
                 </td>
                
                 
   </tr>
             </tbody>
           </table>
   
         </div>
 
         <div *ngIf="grpNewConvTableBasedOnTitle===true" class="card-body table-responsive">
 
           <button (click)="grpNewConvTableBasedOnTitle=false;" class="btn btn-secondary" 
           style="width: auto;float: right;">Switch To Normal View</button>
 
           <br>
           <br>
 
           <div *ngFor="let grouped of groupNotfns">
 
             <div>
               <h4 style="font-weight: bold;margin: 0%;">Title : 
                 {{grouped.botAdminNotificationTitle}}</h4>
                 <button *ngIf="grouped.splitMessage===false" (click)="grouped.splitMessage=true" class="btn btn-info" 
             style="width: auto;float: left;">Split Notification Message</button>
             <button *ngIf="grouped.splitMessage===true" (click)="grouped.splitMessage=false" class="btn btn-info" 
             style="width: auto;float: left;">Normal Message View</button>
 
             <button *ngIf="grouped.splitMessage===false" (click)="exportArrayGeneric(grouped.newNotifications);" class="btn btn-info" 
     style="width: auto;float: left;">Download</button>
     <button *ngIf="grouped.splitMessage===true" (click)="exportTableGeneric(grouped);" 
     class="btn btn-info" style="width: auto;float: left;">Download</button>
             
             </div>
             
 
           <table *ngIf="grouped.splitMessage===false" class="table table-striped table-sm hover" datatable
             [dtOptions]="userOptions">
             <thead class="tableheader">
               <tr>
                 <th>NAME</th>
                 <th>PHONE</th>
                 <th>DATE</th>
                 <th>MESSAGE</th>
                 <th>MEDIA</th>
                 <th>ACTION</th>
               </tr>
             </thead>
             <tbody>
               <tr class="td-height" *ngFor="let notification of grouped.newNotifications">
                 <td class="td-height">{{notification.fkBotUserName}}</td>
                 <td class="td-height">{{notification.fkBotUserPhone}}</td>
                 <td class="td-height">{{notification.formattedTimestamp}}</td>
                 <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                 <td class="td-height">
                   <button style="margin: 0.4%;width: auto;" class="btn btn-primary" *ngFor="let media of notification.media"
                   (click)="viewBotMedia(media.mediaObjId, media.mediaType);">View {{media.mediaName}}</button>
                 </td>
                 <td>
   
                   <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                     <mat-icon>remove_red_eye</mat-icon>
                 </button>  
                 </td>
                
                 
   </tr>
             </tbody>
           </table>
 
           <table *ngIf="grouped.splitMessage===true" [id]="grouped.botAdminNotificationTitle" class="table table-striped table-sm hover" datatable
             [dtOptions]="userOptions">
             <thead class="tableheader">
               <tr>
                 <th *ngFor="let header of grouped.headers"> {{header}}</th>
               </tr>
             </thead>
             <tbody>
               <tr class="td-height" *ngFor="let notfData of grouped.data">
                 <td *ngFor="let dt of notfData" class="td-height">{{dt}}</td>
               </tr>
             </tbody>
           </table>
 
    <br><br>
         </div>
 
         </div>
         
 
         
 
 
       </div>
     
     
             
     </mat-tab>
    <mat-tab label="New User Notifications">   
     <br>
    
      <h2 style="text-align: left; font-weight: bold; margin-bottom: 0%;">New User Notifications
        
       
        <a *ngIf="newNotificationsListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
        (click)="newNotificationsListType='cards'">
          <img src="/assets/images/icons/eye.png" width="25" height="25"
          alt="" loading="lazy">
       </a>
       <a *ngIf="newNotificationsListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
       (click)="newNotificationsListType='table'">
        <img src="/assets/images/icons/eye.png" width="25" height="25"
        alt="" loading="lazy">
     </a>
        <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
        (click)="exportArrayGeneric(newUserNotifications.newNotifications)">
          <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
        </a>
        <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Search By Date"
        (click)="open(searchByDateModal);searchType='new user notification'">
          <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
        </a> 
        <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Refresh"
        (click)="refreshNotifications();">
          <img src="/assets/images/icons/rotate.png" width="25" height="25" alt="" loading="lazy">
        </a> 

        <h4> [{{newUserNotifications.from}} to {{newUserNotifications.to}}] </h4>

      </h2>

      <!-- <button style="width:auto;" (click)="open(searchByDateModal);searchType='new user notification'" class="btn btn-info">Search By Date</button> -->

      <div class="search-hero">
        <input class="form-control" type="text" name="search" [(ngModel)]="searchText3" autocomplete="off" 
        placeholder="Start searching by name, phone, date or message">
      </div>
  <br>
      <div *ngIf="newNotificationsListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
  
        <div *ngFor="let notification of newUserNotifications.newNotifications | filter:searchText3" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
          <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
            
          <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
          <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
          <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
  
          <a style="font-weight: bold;">Message : </a>
          <div style="padding: 2%; overflow-y: auto;height: 200px;">
            <a style="text-align: justify;text-justify: inter-word;white-space: pre-line">
              {{notification.botAdminNotificationMessage}}</a> 
          </div>
          <br>
          <div style="text-align: center;">
            <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
              <mat-icon>remove_red_eye</mat-icon>
          </button>
                  </div>
        </mat-card>
        </div>
  
      </div>
  
      <div *ngIf="newNotificationsListType==='table'" class="card">
        <div class="card-body table-responsive">
          <table *ngIf="tableNewUserNotifsExists" class="table table-striped table-sm hover" datatable
            [dtOptions]="userOptions">
            <thead class="tableheader">
              <tr>
                <th>NAME</th>
                <th>PHONE</th>
                <th>DATE</th>
                <th>MESSAGE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr class="td-height" *ngFor="let notification of newUserNotifications.newNotifications | filter:searchText3">
                <td class="td-height">{{notification.fkBotUserName}}</td>
                <td class="td-height">{{notification.fkBotUserPhone}}</td>
                <td class="td-height">{{notification.formattedTimestamp}}</td>
                <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                <td>
    
                  <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                    <mat-icon>remove_red_eye</mat-icon>
                </button>

                </td>
               
                
  </tr>
            </tbody>
          </table>
  
        </div>
      </div>
    
            
    </mat-tab>


 


    <mat-tab label="New Form Submission Notifications">   
     
    <br>
      <h2 style="text-align: left; font-weight: bold;">New Form Submission Notifications
        <a *ngIf="newNotificationsListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
        (click)="newNotificationsListType='cards'">
          <img src="/assets/images/icons/eye.png" width="25" height="25"
          alt="" loading="lazy">
       </a>
       <a *ngIf="newNotificationsListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
       (click)="newNotificationsListType='table'">
        <img src="/assets/images/icons/eye.png" width="25" height="25"
        alt="" loading="lazy">
     </a>
        <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
        (click)="exportArrayGeneric(newFormResSubmissionNotifications.newNotifications)">
          <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
        </a>
        <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Search By Date"
        (click)="open(searchByDateModal);searchType='form response submission notification'">
          <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
        </a> 
        <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Refresh"
        (click)="refreshNotifications();">
          <img src="/assets/images/icons/rotate.png" width="25" height="25" alt="" loading="lazy">
        </a> 
        <h4> [{{newFormResSubmissionNotifications.from}} to {{newFormResSubmissionNotifications.to}}] </h4>
      </h2>

      <!-- <button style="width:auto;" (click)="open(searchByDateModal);searchType='form response submission notification'" class="btn btn-info">Search By Date</button> -->

      <div class="search-hero">
        <input class="form-control" type="text" name="search" [(ngModel)]="searchText5" autocomplete="off" 
        placeholder="Start searching by name, phone, date or message">
      </div>
  <br>
      <div *ngIf="newNotificationsListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
  
        <div *ngFor="let notification of newFormResSubmissionNotifications.newNotifications | filter:searchText5" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
          <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
            
          <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
          <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
          <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
  
          <a style="font-weight: bold;">Message : </a>
          <div style="padding: 2%; overflow-y: auto;height: 200px;">
            <a style="text-align: justify;text-justify: inter-word;white-space: pre-line">
              {{notification.botAdminNotificationMessage}}</a> 
          </div>
          <br>
          <div style="text-align: center;">
            <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
              <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button (click)="viewFormResponse(notification.fkFormRespondentId, notification.formId , notification.formName);" matTooltip="View Response" mat-icon-button>
            <mat-icon>view_array</mat-icon>
        </button>
                </div>
        </mat-card>
        </div>
  
      </div>
  
      <div *ngIf="newNotificationsListType==='table'" class="card">
        <div class="card-body table-responsive">
          <table *ngIf="tableNewFormResSubNotifsExists" class="table table-striped table-sm hover" datatable
            [dtOptions]="userOptions">
            <thead class="tableheader">
              <tr>
                <th>NAME</th>
                <th>PHONE</th>
                <th>DATE</th>
                <th>MESSAGE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr class="td-height" *ngFor="let notification of newFormResSubmissionNotifications.newNotifications | filter:searchText5">
                <td class="td-height">{{notification.fkBotUserName}}</td>
                <td class="td-height">{{notification.fkBotUserPhone}}</td>
                <td class="td-height">{{notification.formattedTimestamp}}</td>
                <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                <td>
  
                  <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                    <mat-icon>remove_red_eye</mat-icon>
                </button>

                <button (click)="viewFormResponse(notification.fkFormRespondentId, notification.formId , notification.formName);" matTooltip="View Response" mat-icon-button>
                  <mat-icon>view_array</mat-icon>
              </button>

                
                                    
                </td>
               
                
  </tr>
            </tbody>
          </table>
  
        </div>
      </div>
    
            
    </mat-tab>


    <mat-tab label="Manual Chat Request Notifications">   
     
      <br>
        <h2 style="text-align: left; font-weight: bold;">Manual Chat Request Notifications
          <a *ngIf="manualChatReqNotificationsListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
          (click)="manualChatReqNotificationsListType='cards'">
            <img src="/assets/images/icons/eye.png" width="25" height="25"
            alt="" loading="lazy">
         </a>
         <a *ngIf="manualChatReqNotificationsListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
         (click)="manualChatReqNotificationsListType='table'">
          <img src="/assets/images/icons/eye.png" width="25" height="25"
          alt="" loading="lazy">
       </a>
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
          (click)="exportArrayGeneric(manualChatRequestNotifications.newNotifications)">
            <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
          </a>
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Search By Date"
          (click)="open(searchByDateModal);searchType='manual chat notification'">
          <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
        </a> 
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Refresh"
        (click)="refreshNotifications();">
          <img src="/assets/images/icons/rotate.png" width="25" height="25" alt="" loading="lazy">
        </a> 
          <h4> [{{manualChatRequestNotifications.from}} to {{manualChatRequestNotifications.to}}] </h4>
        </h2>
  
        <!-- <button style="width:auto;" (click)="open(searchByDateModal);searchType='manual chat notification'" class="btn btn-info">Search By Date</button> -->

        <div class="search-hero">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText7" autocomplete="off" 
          placeholder="Start searching by name, phone, date or message">
        </div>
    <br>
        <div *ngIf="manualChatReqNotificationsListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
    
          <div *ngFor="let notification of manualChatRequestNotifications.newNotifications | filter:searchText7" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
            <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
              
            <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
            <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
            <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
    
            <a style="font-weight: bold;">Message : </a>
            <div style="padding: 2%; overflow-y: auto;height: 200px;">
              <a style="text-align: justify;text-justify: inter-word;white-space: pre-line">
                {{notification.botAdminNotificationMessage}}</a> 
            </div>
            <br>
            <div style="text-align: center;">
             
              <button (click)="getBotAgents();open(assignAgentModal);" matTooltip="Assign Agent" mat-icon-button>
                <mat-icon>assignment_ind</mat-icon>
              </button>
              
              <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                <mat-icon>remove_red_eye</mat-icon>
            </button>
               
             
            </div>
          </mat-card>

          <ng-template #assignAgentModal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Agent</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div style="margin-bottom: 2%;">
                  <table class="table table-striped table-sm hover">
                    <thead>
                      <tr>
                        <th>Agent Name</th>
                        <th>Users Assigned For Manual Chat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let agent of botAgent.users">
                        <td>{{agent.firstName}} {{agent.lastName}}</td>
                        <td>{{agent.manualChatBotUserNames}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style="margin-bottom: 2%;">
                  <label class="required" for="var">Agents</label> <br> 
                     <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botAgentUserIdToAssign" 
                                  id="botAgentUserIdToAssign" name="botAgentUserIdToAssign">
                       <option value="" disabled >Choose Agent to assign</option>
                       <option *ngFor="let agent of botAgent.users" name="varV" id="India" [value]="agent.userId">{{agent.firstName}} {{agent.lastName}}</option>
                      </select>
                 </div> 
              </div>
            </div> 
            <div class="modal-footer">
              <button style="width: auto;float: right;" class="btn btn-info" 
              (click)="assignAgentToManualChat(notification.fkBotUserId, notification.botAdminNotificationId);modal.dismiss('Cross click');">Assign</button>
            </div>
          </ng-template>

          </div>
    
        </div>
    
        <div *ngIf="manualChatReqNotificationsListType==='table'" class="card">
          <div class="card-body table-responsive">
            <table *ngIf="tableNewManualChatNotifsExists" class="table table-striped table-sm hover" datatable
              [dtOptions]="userOptions">
              <thead class="tableheader">
                <tr>
                  <th>NAME</th>
                  <th>PHONE</th>
                  <th>DATE</th>
                  <th>MESSAGE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let notification of manualChatRequestNotifications.newNotifications | filter:searchText7">
                  <td class="td-height">{{notification.fkBotUserName}}</td>
                  <td class="td-height">{{notification.fkBotUserPhone}}</td>
                  <td class="td-height">{{notification.formattedTimestamp}}</td>
                  <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                  <td>
    
                    

                    <button (click)="getBotAgents();open(assignAgentModal);" matTooltip="Assign Agent" mat-icon-button>
                      <mat-icon>assignment_ind</mat-icon>
                    </button>

                    <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                      <mat-icon>remove_red_eye</mat-icon>
                  </button>    
                  </td>
                 
                  <ng-template #assignAgentModal let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Assign Agent</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div>
                        <div style="margin-bottom: 2%;">
                          <table class="table table-striped table-sm hover">
                            <thead>
                              <tr>
                                <th>Agent Name</th>
                                <th>Users Assigned For Manual Chat</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let agent of botAgent.users">
                                <td>{{agent.firstName}} {{agent.lastName}}</td>
                                <td>{{agent.manualChatBotUserNames}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
        
                        <div style="margin-bottom: 2%;">
                          <label class="required" for="var">Agents</label> <br> 
                             <select #invoice="ngModel" required  class="form-control" [(ngModel)]="botAgentUserIdToAssign" 
                                          id="botAgentUserIdToAssign" name="botAgentUserIdToAssign">
                               <option value="" disabled >Choose Agent to assign</option>
                               <option *ngFor="let agent of botAgent.users" name="varV" id="India" [value]="agent.userId">{{agent.firstName}} {{agent.lastName}}</option>
                              </select>
                         </div> 
                      </div>
                    </div> 
                    <div class="modal-footer">
                      <button style="width: auto;float: right;" class="btn btn-info" 
                      (click)="assignAgentToManualChat(notification.fkBotUserId, notification.botAdminNotificationId);modal.dismiss('Cross click');">Assign</button>
                    </div>
                  </ng-template>
                  
    </tr>
              </tbody>
            </table>
    
          </div>
        </div>
      
              
      </mat-tab>


    <mat-tab label="Viewed Notifications">   
     
      <div class="p-4">
    
        <h1 style="text-align: left; font-weight: bold;">Viewed Notifications
          <a *ngIf="viewedNotificationsListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
          (click)="viewedNotificationsListType='cards'">
            <img src="/assets/images/icons/eye.png" width="25" height="25"
            alt="" loading="lazy">
         </a>
         <a *ngIf="viewedNotificationsListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
         (click)="viewedNotificationsListType='table'">
          <img src="/assets/images/icons/eye.png" width="25" height="25"
          alt="" loading="lazy">
       </a>
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
          (click)="exportArrayGeneric(viewedNotifications.newNotifications)">
            <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
          </a>
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Get Viewed Notifications"
          (click)="open(getViewed);">
          <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
        </a> 
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Refresh"
        (click)="refreshNotifications();">
          <img src="/assets/images/icons/rotate.png" width="25" height="25" alt="" loading="lazy">
        </a> 
          <h4> [{{viewedNotifications.from}} to {{viewedNotifications.to}}] </h4>
        </h1>
    
        <!-- <button style="width:auto;" (click)="open(getViewed);" class="btn btn-info">Get Viewed Notifications</button> -->
    
        <br>
    
        <div class="search-hero">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText2" autocomplete="off" 
          placeholder="Start searching by name, phone, date or message">
        </div>
    
        <br>
    
        <div *ngIf="viewedNotificationsListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
    
          <div *ngFor="let notification of viewedNotifications.newNotifications | filter:searchText2" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
            <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
              
            <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
            <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
            <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
    
            <a style="font-weight: bold;">Message : </a>
            <div style="padding: 2%; overflow-y: auto;height: 200px;">
              <a>{{notification.botAdminNotificationMessage}}</a> 
            </div>
            <br>
            
          </mat-card>
          </div>
    
        </div>
    
        <div *ngIf="viewedNotificationsListType==='table'" class="card">
          <div class="card-body table-responsive">
            <table *ngIf="tableViewedNotifsExists" class="table table-striped table-sm hover" datatable
              [dtOptions]="userOptions">
              <thead class="tableheader">
                <tr>
                  <th>NAME</th>
                  <th>PHONE</th>
                  <th>DATE</th>
                  <th>MESSAGE</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let notification of viewedNotifications.newNotifications | filter:searchText2">
                  <td class="td-height">{{notification.fkBotUserName}}</td>
                  <td class="td-height">{{notification.fkBotUserPhone}}</td>
                  <td class="td-height">{{notification.formattedTimestamp}}</td>
                  <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                 
                 
                  
    </tr>
              </tbody>
            </table>
    
          </div>
        </div>
      </div>
    
    
            
    </mat-tab>



    <mat-tab label="Notes">   
      <br>
       <h2 style="text-align: left; font-weight: bold;">Notes
         <a *ngIf="newNotesListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
         (click)="newNotesListType='cards'">
           <img src="/assets/images/icons/eye.png" width="25" height="25"
           alt="" loading="lazy">
        </a>
        <a *ngIf="newNotesListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
        (click)="newNotesListType='table'">
         <img src="/assets/images/icons/eye.png" width="25" height="25"
         alt="" loading="lazy">
      </a>
         <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
         (click)="exportArrayGeneric(newCustomConvoFlowNotes.newNotifications)">
           <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
         </a>
         <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Search By Date"
         (click)="open(searchByDateModal);searchType='custom convo flow notes'">
          <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
        </a> 
         <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Refresh"
        (click)="refreshNotifications();">
          <img src="/assets/images/icons/rotate.png" width="25" height="25" alt="" loading="lazy">
        </a> 
         <h4> [{{newCustomConvoFlowNotes.from}} to {{newCustomConvoFlowNotes.to}}] </h4>
        </h2>

        <!-- <button style="width:auto;" (click)="open(searchByDateModal);searchType='custom convo flow notes'" class="btn btn-info">Search By Date</button> -->

       <div class="search-hero">
         <input class="form-control" type="text" name="search" [(ngModel)]="searchText6" autocomplete="off" 
         placeholder="Start searching by name, phone, date or message">
       </div>
   
   <br>
       <div *ngIf="newNotesListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
   
         <div *ngFor="let notification of newCustomConvoFlowNotes.newNotifications | filter:searchText6" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
           <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
             
           <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
           <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
           <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
   
          
   
           <a style="font-weight: bold;">Message : </a>
           <div style="padding: 2%; overflow-y: auto;height: 150px;">
             <a style="text-align: justify;text-justify: inter-word;white-space: pre-line">
               {{notification.botAdminNotificationMessage}}</a> 
           </div>
           <br>
 
           <a style="font-weight: bold;">Remarks : </a>
           <div style="padding: 2%; overflow-y: auto;height: 100px;">
             <a style="text-align: justify;text-justify: inter-word;white-space: pre-line">
               {{notification.botAdminNotificationRemarks}}</a> 
           </div>

           <br>
           <div>
             <a style="font-weight: bold;">Media : </a> <br>
             <button style="margin: 0.4%;width: auto;" class="btn btn-primary" *ngFor="let media of notification.media"
             (click)="viewBotMedia(media.mediaObjId, media.mediaType);">View {{media.mediaName}}</button>
 
           </div>
           <br>
           <div style="text-align: center;">
           

            <button (click)="open(editRemarksModal2);" matTooltip="Edit Remarks" mat-icon-button>
              <mat-icon>edit</mat-icon>
          </button>

            <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
              <mat-icon>remove_red_eye</mat-icon>
          </button>
           </div>
         </mat-card>

         <ng-template #editRemarksModal2 let-modal>
          <div class="modal-header">
            <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Remarks</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            
            <div>
              <label for="">Remarks</label>
              <textarea class="form-control" rows="6" [(ngModel)]="notification.botAdminNotificationRemarks" 
              type="text" id="notification.botAdminNotificationRemarks" name="notification.botAdminNotificationRemarks"></textarea>
              
            </div>
        
          </div> 
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Close</button>
            <button type="button" class="btn btn-success" style="width:auto;" 
            (click)="updateRemarks(notification.botAdminNotificationId,notification.botAdminNotificationRemarks);modal.dismiss('Cross click')">Update</button>
          </div>
        </ng-template>

         </div>
   
       </div>
   
       <div *ngIf="newNotesListType==='table'" class="card">
 
     
 
         <div *ngIf="grpNewNotesTableBasedOnTitle===false" class="card-body table-responsive">
 
           <button (click)="groupNotesFunction();" class="btn btn-secondary" 
           style="width: auto;float: right;">Group Notes</button>
 
           <table *ngIf="tableNewCustomConvoFlowNotesExists" class="table table-striped table-sm hover" datatable
             [dtOptions]="userOptions">
             <thead class="tableheader">
               <tr>
                 <th>NAME</th>
                 <th>PHONE</th>
                 <th>DATE</th>
                 <th>MESSAGE</th>
                 <th>MEDIA</th>
                 <th>REMARKS</th>
                 <th>ACTION</th>
               </tr>
             </thead>
             <tbody>
               <tr class="td-height" *ngFor="let notification of newCustomConvoFlowNotes.newNotifications | filter:searchText6">
                 <td class="td-height">{{notification.fkBotUserName}}</td>
                 <td class="td-height">{{notification.fkBotUserPhone}}</td>
                 <td class="td-height">{{notification.formattedTimestamp}}</td>
                 <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                 <td class="td-height">
 
                   <button style="margin: 0.4%;width: auto;" class="btn btn-primary" *ngFor="let media of notification.media"
                   (click)="viewBotMedia(media.mediaObjId, media.mediaType);">View {{media.mediaName}}</button>
 
                 </td>
                 <td class="td-height">{{notification.botAdminNotificationRemarks}}</td>
                 <td>
   
                   <button (click)="open(editRemarksModal);" matTooltip="Edit Remarks" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>

                   <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                    <mat-icon>remove_red_eye</mat-icon>
                </button>
                 </td>
                
                 <ng-template #editRemarksModal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Remarks</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    
                    <div>
                      <label for="">Remarks</label>
                      <textarea class="form-control" rows="6" [(ngModel)]="notification.botAdminNotificationRemarks" 
                      type="text" id="notification.botAdminNotificationRemarks" name="notification.botAdminNotificationRemarks"></textarea>
                      
                    </div>
                
                  </div> 
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Close</button>
                    <button type="button" class="btn btn-success" style="width:auto;" 
                    (click)="updateRemarks(notification.botAdminNotificationId,notification.botAdminNotificationRemarks);modal.dismiss('Cross click')">Update</button>
                  </div>
                </ng-template>

   </tr>
             </tbody>
           </table>
   
         </div>
 
         <div *ngIf="grpNewNotesTableBasedOnTitle===true" class="card-body table-responsive">
 
           <button (click)="grpNewNotesTableBasedOnTitle=false;" class="btn btn-secondary" 
           style="width: auto;float: right;">Switch To Normal View</button>
 
           <br>
           <br>
 
           <div *ngFor="let grouped of groupNotes">
 
             <div>
               <h4 style="font-weight: bold;margin: 0%;">Title : 
                 {{grouped.botAdminNotificationTitle}}</h4>
                 <button *ngIf="grouped.splitMessage===false" (click)="grouped.splitMessage=true" class="btn btn-info" 
             style="width: auto;float: left;">Split Notification Message</button>
             <button *ngIf="grouped.splitMessage===true" (click)="grouped.splitMessage=false" class="btn btn-info" 
             style="width: auto;float: left;">Normal Message View</button>
 
             <button *ngIf="grouped.splitMessage===false" (click)="exportArrayGeneric(grouped.newNotifications);" class="btn btn-info" 
     style="width: auto;float: left;">Download</button>
     <button *ngIf="grouped.splitMessage===true" (click)="exportTableGeneric(grouped);" 
     class="btn btn-info" style="width: auto;float: left;">Download</button>
             
             </div>
             
 
           <table *ngIf="grouped.splitMessage===false" class="table table-striped table-sm hover" datatable
             [dtOptions]="userOptions">
             <thead class="tableheader">
               <tr>
                 <th>NAME</th>
                 <th>PHONE</th>
                 <th>DATE</th>
                 <th>MESSAGE</th>
                 <th>MEDIA</th>
                 <th>ACTION</th>
               </tr>
             </thead>
             <tbody>
               <tr class="td-height" *ngFor="let notification of grouped.newNotifications">
                 <td class="td-height">{{notification.fkBotUserName}}</td>
                 <td class="td-height">{{notification.fkBotUserPhone}}</td>
                 <td class="td-height">{{notification.formattedTimestamp}}</td>
                 <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                 <td class="td-height">
                   <button style="margin: 0.4%;width: auto;" class="btn btn-primary" *ngFor="let media of notification.media"
                   (click)="viewBotMedia(media.mediaObjId, media.mediaType);">View {{media.mediaName}}</button>
                 </td>
                 <td>
   
                  <button (click)="markNotificationAsViewed(notification.botAdminNotificationId);" matTooltip="Mark as Viewed" mat-icon-button>
                    <mat-icon>remove_red_eye</mat-icon>
                </button>   
                 </td>
                
                 
   </tr>
             </tbody>
           </table>
 
           <table *ngIf="grouped.splitMessage===true" [id]="grouped.botAdminNotificationTitle" class="table table-striped table-sm hover" datatable
             [dtOptions]="userOptions">
             <thead class="tableheader">
               <tr>
                 <th *ngFor="let header of grouped.headers"> {{header}}</th>
               </tr>
             </thead>
             <tbody>
               <tr class="td-height" *ngFor="let notfData of grouped.data">
                 <td *ngFor="let dt of notfData" class="td-height">{{dt}}</td>
               </tr>
             </tbody>
           </table>
 
    <br><br>
         </div>
 
         </div>
         
 
         
 
 
       </div>
     
     
             
     </mat-tab>
        
     <mat-tab label="Configurations"> 
      <div class="p-4">

        <table *ngIf="settingsExists"
          class="table table-striped table-sm hover" datatable [dtOptions]="userOptions">
          <thead class="tableheader" >
            <tr>
              <th>CONFIGURATION</th>
              <th>DESCRIPTION</th>
              <th>ADMINS / AGENTS</th>
              <th class="tble-action">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="td-height">Notify Manual Chat Requests</td>
              <td class="td-height">Notify Office Admin via whatsapp template messages upon new manual chat requests</td>
              <td class="td-height">{{notifyManualChatRequestsSettings.fkBotTemplateName}}
                <!-- <button *ngIf="notifyManualChatRequestsSettings.botAutoSchedulerSettingsId!==null" 
                (click)="editBotAutoSchedulerSettings(notifyManualChatRequestsSettings);" matTooltip="Edit" mat-icon-button>
                  <mat-icon>mode_edit_outline</mat-icon>
              </button> -->
              Office Admin, Notifications Manager
              </td>
              <td class="td-height"> 
               
                <!-- <button *ngIf="notifyManualChatRequestsSettings.botAutoSchedulerSettingsId===null"
                (click)="createBotAutoSchedulerSettings('Create NMC Settings');" class="btn btn-info">Activate</button> -->
                <button *ngIf="notifyManualChatRequestsSettings.botAutoSchedulerSettingsId===null"
                (click)="create_NMC_Settings();" class="btn btn-info">Activate</button>
                
                <button (click)="botAutoSchedulerSettingsStatusUpdate(notifyManualChatRequestsSettings);"
                *ngIf="notifyManualChatRequestsSettings.botAutoSchedulerSettingsId!==null 
                && notifyManualChatRequestsSettings.botAutoSchedulerSettingsStatus===false" class="btn btn-info">Activate</button>
               
                <button (click)="botAutoSchedulerSettingsStatusUpdate(notifyManualChatRequestsSettings);"
                *ngIf="notifyManualChatRequestsSettings.botAutoSchedulerSettingsId!==null 
                && notifyManualChatRequestsSettings.botAutoSchedulerSettingsId!==undefined
                && notifyManualChatRequestsSettings.botAutoSchedulerSettingsStatus===true" class="btn btn-danger">Deactivate</button>
              
              </td>
            </tr>
                    
            <tr>
              <td class="td-height">Notify User Assignments</td>
              <td class="td-height">Notify respective agents via whatsapp template messages upon new user assignments</td>
              <td class="td-height">{{notifyAgentsAssignmentSettings.fkBotTemplateName}}
                <!-- <button *ngIf="notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId!==null" 
                (click)="editBotAutoSchedulerSettings(notifyAgentsAssignmentSettings);" matTooltip="Edit" mat-icon-button>
                  <mat-icon>mode_edit_outline</mat-icon>
              </button> -->
              Respective Agent will be notified via whatsapp
              </td>
              <td class="td-height">
           
                <!-- <button (click)="createBotAutoSchedulerSettings('Create NAA Settings');"
                *ngIf="notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId===null" class="btn btn-info">Activate</button> -->
                <button (click)="create_NAA_Settings();"
                *ngIf="notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId===null" class="btn btn-info">Activate</button>
              
                <button (click)="botAutoSchedulerSettingsStatusUpdate(notifyAgentsAssignmentSettings);"
                *ngIf="notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId!==null 
                && notifyAgentsAssignmentSettings.botAutoSchedulerSettingsStatus===false" class="btn btn-info">Activate</button>

                <button (click)="botAutoSchedulerSettingsStatusUpdate(notifyAgentsAssignmentSettings);"
                *ngIf="notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId!==null 
                && notifyAgentsAssignmentSettings.botAutoSchedulerSettingsId!==undefined
                && notifyAgentsAssignmentSettings.botAutoSchedulerSettingsStatus===true" class="btn btn-danger">Deactivate</button>
              </td>
            </tr>

            <tr>
              <td class="td-height">Notify Orders Placed</td>
              <td class="td-height">Notify chosen agents via whatsapp template messages upon new orders placed</td>
              <td class="td-height">{{agentsNewOrdersPlacedNotificationSettings.fkBotTemplateName}}
                <button *ngIf="agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId!==null" 
                (click)="configureNopSettings();" matTooltip="Edit" mat-icon-button>
                  <mat-icon>mode_edit_outline</mat-icon>
              </button>
              </td>
              <td class="td-height">
           
                <button (click)="createAgentsNewOrdersPlacedNotificationSettings();"
                *ngIf="agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId===null" class="btn btn-info">Activate</button>

                <button (click)="botAutoSchedulerSettingsStatusUpdate(agentsNewOrdersPlacedNotificationSettings);"
                *ngIf="agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId!==null 
                && agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsStatus===false" class="btn btn-info">Activate</button>

                <button (click)="botAutoSchedulerSettingsStatusUpdate(agentsNewOrdersPlacedNotificationSettings);"
                *ngIf="agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId!==null 
                && agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsId!==undefined
                && agentsNewOrdersPlacedNotificationSettings.botAutoSchedulerSettingsStatus===true" class="btn btn-danger">Deactivate</button>
              </td>
            </tr>
           


          </tbody>
        </table>

    </div>
     </mat-tab>

     <mat-tab label="User Notifications">   
     
      <div class="p-4">
    
        <h1 style="text-align: left; font-weight: bold;">User Notifications
          <a *ngIf="userNotificationsListType==='table'" matTooltip="Switch View" style="cursor:pointer;"
          (click)="userNotificationsListType='cards'">
            <img src="/assets/images/icons/eye.png" width="25" height="25"
            alt="" loading="lazy">
         </a>
         <a *ngIf="userNotificationsListType==='cards'" matTooltip="Switch View" style="cursor:pointer;"
         (click)="userNotificationsListType='table'">
          <img src="/assets/images/icons/eye.png" width="25" height="25"
          alt="" loading="lazy">
       </a>
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Download as excel file"
          (click)="exportArrayGeneric(userNotifications.newNotifications)">
            <img src="/assets/images/icons/download.png" width="25" height="25" alt="" loading="lazy">
          </a>
          <a style="cursor:pointer;margin-left: 0.5%;" matTooltip="Get User Notifications"
          (click)="open(searchByNameNumberModal);">
          <img src="/assets/images/icons/magnifying-glass.png" width="25" height="25" alt="" loading="lazy">
        </a> 
          <h4 *ngIf="userNotifications.fkBotUserName!==null && userNotifications.fkBotUserName!==undefined">
             {{userNotifications.fkBotUserName}} </h4>
        </h1>
    
        <!-- <button style="width:auto;" (click)="open(searchByNameNumberModal);" class="btn btn-info">Get User Notifications</button> -->
    
        <br>
    
        <div class="search-hero">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText8" autocomplete="off" 
          placeholder="Start searching by name, phone, date or message">
        </div>
    
        <br>
    
        <div *ngIf="userNotificationsListType==='cards'" fxLayout="row wrap" fxLayoutGap="16px grid">
    
          <div *ngFor="let notification of userNotifications.newNotifications | filter:searchText8" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="50%" >
            <mat-card style="cursor: pointer;" class="example-card mat-elevation-z12" >
              
            <a style="font-weight: bold;">Name : </a>  <a>{{notification.fkBotUserName}}</a>   <br>
            <a style="font-weight: bold;">Phone : </a>  <a>{{notification.fkBotUserPhone}}</a>  <br>
            <a style="font-weight: bold;">Date : </a>  <a>{{notification.formattedTimestamp}}</a>  <br>
    
            <a style="font-weight: bold;">Message : </a>
            <div style="padding: 2%; overflow-y: auto;height: 200px;">
              <a>{{notification.botAdminNotificationMessage}}</a> 
            </div>
            <br>
            
          </mat-card>
          </div>
    
        </div>
    
        <div *ngIf="userNotificationsListType==='table'" class="card">
          <div class="card-body table-responsive">
            <table *ngIf="tableUserNotifsExists" class="table table-striped table-sm hover" datatable
              [dtOptions]="userOptions">
              <thead class="tableheader">
                <tr>
                  <th>NAME</th>
                  <th>PHONE</th>
                  <th>DATE</th>
                  <th>MESSAGE</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let notification of userNotifications.newNotifications | filter:searchText2">
                  <td class="td-height">{{notification.fkBotUserName}}</td>
                  <td class="td-height">{{notification.fkBotUserPhone}}</td>
                  <td class="td-height">{{notification.formattedTimestamp}}</td>
                  <td class="td-height">{{notification.botAdminNotificationMessage}}</td>
                 
                 
                  
    </tr>
              </tbody>
            </table>
    
          </div>
        </div>
      </div>
    
      <ng-template #searchByNameNumberModal let-modal>
        <div class="modal-body" style="color: black;">
    
          <div *ngIf="usersExist===false">

            <h3 style="font-weight: bold;">
           Search Users By Name or Phone
          </h3>
    
          <div>
            <label class="required" for="botUserGrpName">Name or Phone</label>
            <input maxlength="255" required class="form-control" type="text" 
             name="searchValue" id="searchValue" [(ngModel)]="searchValue">
          </div>
          <br> <br>
    
          <button style="float: right;width: auto;margin: 0.5%;" (click)="searchBotUsersByNameOrPhone();"
          mat-raised-button>Search</button>
          <button style="float: right;margin: 0.5%;" (click)="modal.dismiss('Cross click');" mat-raised-button>Cancel</button>
          
        </div>
        <div *ngIf="usersExist===true">
          <h3 style="font-weight: bold;">
           Get User Notifications
           </h3>
          <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of botCustomerUser.users">
                <td>{{user.name}}</td>
                <td>{{user.userIdentifier}}</td>
                <td>
                  <button style="width: auto;" 
                  (click)="getBotAdminNotificationsByUserId(user.botUserId);userNotifications.fkBotUserName=user.name;modal.dismiss('Cross click')"
                  mat-raised-button>Get Notifications</button>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <button (click)="searchValue=null;usersExist=false;" style="width: auto;float: right;" mat-raised-button>Search Others</button>
        </div>

        </div>
      </ng-template>
            
    </mat-tab>

      </mat-tab-group>






 

<ng-template #switchTZ let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Timezone</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <div>

      <div style="margin-bottom: 2%;">
        <label class="required" for="var">Timezone</label> <br> 
           <select #invoice="ngModel" required  class="form-control" [(ngModel)]="currentTimezone" id="var" name="var">
             <option value="" disabled >Choose Timezone</option>
             <option name="varV" id1="India" value="India" >India</option>
             <option name="varV" id1="United Arab Emirates" value="United Arab Emirates" >United Arab Emirates</option>
             <option name="varV" id1="Saudi Arabia" value="Saudi Arabia" >Saudi Arabia</option>
             <option name="varV" id1="USA" value="USA" >USA</option>
            </select>
       </div> 
       
    
    </div>

  </div> 
  
</ng-template>



<ng-template #getViewed let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Get Viewed Notifications</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
<div class="form-group row">
  <div class="col-md-6">
    <label class="required" for="">Select Date-Time</label>
    <input class="form-control" required [(ngModel)]="fromDateTime" 
    type="datetime-local" id="fromDateTime" name="fromDateTime">
  </div>

  <div class="col-md-6">
    <label class="required" for="">Select Date-Time</label>
    <input class="form-control" required [(ngModel)]="toDateTime" 
    type="datetime-local" id="toDateTime" name="toDateTime">
  </div>
</div>

  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-success" style="width:auto;" (click)="getViewedAdminNotifications();modal.dismiss('Cross click')">Get Notifications</button>
  </div>
</ng-template>

<ng-template #searchByDateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Search By Date</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
<div class="form-group row">
  <div class="col-md-6">
    <label class="required" for="">Select Date-Time</label>
    <input class="form-control" required [(ngModel)]="fromDateTime" 
    type="datetime-local" id="fromDateTime" name="fromDateTime">
  </div>

  <div class="col-md-6">
    <label class="required" for="">Select Date-Time</label>
    <input class="form-control" required [(ngModel)]="toDateTime" 
    type="datetime-local" id="toDateTime" name="toDateTime">
  </div>
</div>

  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-success" style="width:auto;" (click)="searchByDate();modal.dismiss('Cross click')">Get Notifications</button>
  </div>
</ng-template>



