<div [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Create Feedback Question</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 


<div class="modal-body">
<div class="card">
<div class="card-body">
<form #aqForm="ngForm"> 
        
<div class="form-group row">
  <div class="col-md-3">
    <label class="required"  for="activityQuestionOrder">Order</label>
    <input #Order="ngModel" placeholder="Enter Order" required class="form-control" type="number" name="activityQuestionOrder"  id="activityQuestionOrder" [(ngModel)]="feedbackQuestion.questionOrder">
    <div class="alert alert-danger" *ngIf="Order.touched && !Order.valid">Order is required</div>
 </div>

    <div class="col-md-9">
    <label class="required" for="activityQuestion">Question</label>
    <input maxlength="250" #activityPlannerName="ngModel" required placeholder="Enter Question" required class="form-control" type="text" name="activityQuestion"  id="activityQuestion" [(ngModel)]="feedbackQuestion.question">
    <div class="alert alert-danger" *ngIf="activityPlannerName.touched && !activityPlannerName.valid">Enter Question</div>
    </div>
</div>
  

    <br>
    <div>
       <label class="required" for="activityPlannerDescription">Description</label>
       <textarea  maxlength="5000" placeholder="Enter Description" required 
       class="form-control" type="text"
    name="activityPlannerDescription"  id="activityPlannerDescription"
     [(ngModel)]="feedbackQuestion.questionDescription"></textarea>
    </div> 
<br>



<div class="form-group row">

  <div class="col-md-6">
    <label class="required" for="answerType">Answer Type</label> 
    <select required  class="form-control" [(ngModel)]="feedbackQuestion.answerType" id="answerType" name="answerType">
      <option value="" disabled >Choose Answer Type</option>
      <option value="text">Text Input</option>
      <option value="radio">Radio Buttons</option>
      <option value="slider">Slider</option>
      <option value="checkbox">Checkbox</option>
      <option value="singleSelectDropdown">Single-Select Dropdown</option>
      <option value="multiSelectDropdown">Multi-Select Dropdown</option>
      <option value="timestamp">Date-Time</option>
    </select>
  </div>
  
  <div class="col-md-6">
    <label class="required" for="selectedUroId">Answer</label> 
    <select required  class="form-control" [(ngModel)]="feedbackQuestion.fkAnswerId" id="selectedUroId" name="selectedUroId">
      <option value="" disabled >Choose Answer</option>
      <option  *ngFor="let ans of feedbackAnswers" 
       [ngValue]="ans.id">{{ans.answer}}
      </option>
    </select>
  </div>


  </div>
  <br>
  <div class="row">
    <!-- <a matTooltip="Create answer" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
    (click)="createFeedbackAnswer()">
    <img src="/assets/images/icons/plus.png" width="25" height="25" alt="" loading="lazy">
  </a> 
  <a matTooltip="View answers" style="cursor:pointer;padding-left: 1rem;float: right;margin-right: 2%;"
  (click)="viewAnswers()">
  <img src="/assets/images/icons/edit.svg" width="25" height="25" alt="" loading="lazy">
</a> -->

<button (click)="createFeedbackAnswer()" style="float: right;width: auto;margin: 1%;" class="btn btn-info">Create answer</button>
<button (click)="viewAnswers()" style="float: right;width: auto;margin: 1%;" class="btn btn-info">View Answers</button>

  </div>
  


</form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aqForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Save</button>
</div>