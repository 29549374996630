   <div  [style.background-color]='getColor()'  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">View Ticket Support</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
     <div class="modal-body">
      <div class="card">
        <div class="card-body">
      <form> 
             
        <div class="form-group row">
          <div class="col-md-4">
            <label class="required"  for="ticketSupport"> Name</label>
            <input readonly required class="form-control" type="text" id="ticketSupport" 
            name="ticketSupport" [(ngModel)]="ticketSupportDefinition.ticketSupport">
          </div>

          <div class="col-md-4">
            <label class="required"  for="ticketSupportType"> Type</label>
            <input readonly required class="form-control" type="text" id="ticketSupportType" name="ticketSupportType" 
            [(ngModel)]="ticketSupportDefinition.ticketSupportType">
          </div>

          <div class="col-md-4">
            <label class="required" for="ticketSupportGroup"> Group</label>
            <input readonly required class="form-control" type="text" id="ticketSupportGroup" name="ticketSupportGroup" 
            [(ngModel)]="ticketSupportDefinition.ticketSupportGroup">
          </div>
        </div>
             
            

            <br>

            <div class="card">
                <div class="card-body table-responsive">
                   
                  <table class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
                   >
                    <thead class="tableheader">
                      <tr>
                        <th [style.background-color]='getColor()' >Role</th>
                        <th [style.background-color]='getColor()' >Action</th>
            
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="td-height" *ngFor="let ccd of ticketSupportDefinition.ticketSupportRoles">
                        <td class="td-height">{{ccd.roleName}}</td>
                 <td class="td-height">
                   
                    <h4 *ngIf="ccd.defaultRole !== null && ccd.defaultRole" >Default Role</h4>
                    <button *ngIf="ccd.defaultRole !== null && !ccd.defaultRole" type="button"
                    class="btn btn-success mr-2" (click)="open(confirmActivation);"
                    style="width: auto;"
                    >Make Default</button>
                   
                        </td>

                        <ng-template #confirmActivation let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              Are you sure to change default role ?
                            </div> 
                            <div class="modal-footer">
                              <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
                              <button type="button" class="btn btn-success" style="width: auto;" (click)="onSubmit(ccd);modal.dismiss('Cross click')">Make Default</button>
                            </div>
                          </ng-template> 

                    </tr>
                    </tbody>
                  </table>
            
                </div>
              </div>
            

      </form>
      </div>
      </div>
      </div>
  
    
      