 <div [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Student Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

 
    <form>

      <br>

   
      <div style="width: 100%;color: black;text-align: center;" >
        <a style="cursor:pointer;">
           <img style="border-radius: 50%;" [src]="safeImageUrl" width="75" height="75"
           alt="" loading="lazy">
        </a>
      </div>

      <div class="modal-body">
      
        <h4 style="text-align: center;margin: 0%;">
          {{details.studentName}}
        </h4>

        <h4 *ngIf="details.dob!==null" style="text-align: center;margin: 0%;"><i class="fa fa-calendar" aria-hidden="true">
          {{details.dob}}
        </i></h4>

        <h4 style="text-align: center;margin: 0%;"><i class="fa fa-phone" aria-hidden="true">
          {{details.phone}}
        </i></h4>

        <h4 style="text-align: center;margin: 0%;"><i class="fa fa-envelope" aria-hidden="true">
          {{details.email}}
        </i></h4>


</div>

  
</form>
    

  