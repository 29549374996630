import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceDetails } from 'src/app/crmClasses/invoice-details';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { InvoiceService } from 'src/app/service/invoice.service';
import { ProductService } from 'src/app/service/product.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-add-invoice-details',
  templateUrl: './add-invoice-details.component.html',
  styleUrls: ['./add-invoice-details.component.scss']
})
export class AddInvoiceDetailsComponent implements OnInit {
  clicked = false;
  invoiceDetailsList: InvoiceDetails[] = []; 
  invoiceDet : InvoiceDetails = new InvoiceDetails;
  
  prod : Product = new Product();
  products: Product[] = [];
  products0: Product[] = [];

  invoiceId!: string;
  officeId!: string;

  qty : any;
  price : any;
  tp : any;
  type : any;
  loggedInUser : any;
  theme : any;
  @Input() fromParent: any;
  addedProductIds : string[] = [];
  productIds : string[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  constructor(private  productService : ProductService, 
              private invoiceService : InvoiceService,
              private sharedDataService: SharedDataService,
              public activeModal: NgbActiveModal ) { }
 
  ngOnInit(): void { 

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.invoiceId = this.fromParent.invoiceId;
    this.officeId = this.fromParent.officeId;
    this.type = this.fromParent.type;

    this.addedProductIds = this.fromParent.addedProductIds;    

    this.invoiceDet.fkProductId = "";
    
    this.getProducts();
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  onSubmit(){ 

    this.invoiceDet.fkInvoiceId = this.invoiceId;

    this.invoiceService.addInvoiceDetailAndSubscription(this.invoiceDet).subscribe(
      data=>{
        this.passEntry.emit(this.invoiceDet);
        this.activeModal.close();
      });
  }

  getFilteredProducts(){
    let count = 0;
    this.addedProductIds.forEach((addedProductId)=>{
      count++;
      this.productIds.forEach((productId,index)=>{
        if(productId==addedProductId)  this.productIds.splice(index,1);
     });
    });  
    if(count === this.addedProductIds.length){
      this.productIds.forEach((val)=>{
        this.products.forEach((prod)=>{
          if(val === prod.productId){
            this.products0.push(prod);
          }
        });
      });
    } 
  }

  getProducts(){
    this.productService.getProductListByOfficeId(this.officeId).subscribe(data => {
      this.products= data;
      let count = 0;
      this.products.forEach((val)=>{
        count++;
        this.productIds.push(val.productId);
      });

      if(count === this.products.length){
        this.getFilteredProducts();
      }
    });
  }

  onFileSelected(event: any){
    this.qty = this.invoiceDet.quantity;
    this.price = this.invoiceDet.pricePerItem;

     this.tp = this.qty * this.price;

     this.invoiceDet.totalPrice = this.tp;

     this.products.forEach((prod)=>{
      if(prod.productId === this.invoiceDet.fkProductId){
        this.invoiceDet.alternateQuantity = prod.defaultQuantity * this.invoiceDet.quantity;
      }
    });
  }

  onChange(event : any){
    this.products.forEach((prod)=>{
      if(prod.productId === this.invoiceDet.fkProductId){
        this.invoiceDet.itemName = prod.productName;
        this.invoiceDet.pricePerItem = prod.productPrice;
      }
    });
  }



  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  closeModal(){
    this.activeModal.close();
}

}
