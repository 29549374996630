import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddStudentComponent } from 'src/app/course-management/student/add-student/add-student.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddTeacherComponent } from 'src/app/course-management/teacher/add-teacher/add-teacher.component';
import { Class } from 'src/app/courseManagementClasses/class';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { ClassService } from 'src/app/service/class.service';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { TeacherService } from 'src/app/service/teacher.service';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-class-view-page',
  templateUrl: './class-view-page.component.html',
  styleUrls: ['./class-view-page.component.scss']
})
export class ClassViewPageComponent implements OnInit {

  teachersExist = false;
  studentsExist = false;
  currentRole = "CLASS_ADMIN";
  showSpinner : boolean = true;
  classLoader = false;
  classLoaderMessage!: string;
  classExists!: boolean;
  classOptions: any = {};
  loggedInUser: any;
  class : Class = new Class();
  officeId!: string;
  userName!: string;
  userId!: string;
  classId!: string;
  modalRefs: BsModalRef[] = [];
  classes: Class[] = [];

  classTeachers : any[] = [{
                              fkClass: "",
                              fkSubject: "",
                              fkUserRoleOffice: "",
                              teacherId: "",
                              teacherImage: "",
                              teacherName: "",
                              teacherStatus: "",
                              safeUrl : "",
                              collapsedState : "",
                              subjects : [{
                                              description: "",
                                              fkProductId: "",
                                              name: "",
                                              subId: "",
                                              subjectImage: "",
                                              subjectStatus: "",
                                              teacherId: ""
                                            }]
                            }];

  classStudents : any[] = [{dob: "",
                            email:  "",
                            fkClass:  "",
                            fkUserRoleOffice:  "",
                            phone:  "",
                            skillScore:  "",
                            studentActivityHistoryIndicator:  "",
                            studentAttempts:  "",
                            studentId:  "",
                            studentImage:  "",
                            studentName:  "",
                            studentStatus:  "",
                            safeUrl : "",
                            notes:""
                          }];

                          classStudentsActive : any[] = [{dob: "",
                          email:  "",
                          fkClass:  "",
                          fkUserRoleOffice:  "",
                          phone:  "",
                          skillScore:  "",
                          studentActivityHistoryIndicator:  "",
                          studentAttempts:  "",
                          studentId:  "",
                          studentImage:  "",
                          studentName:  "",
                          studentStatus:  "",
                          safeUrl : "",
                          notes:""
                        }];

                        classStudentsInActive : any[] = [{dob: "",
                        email:  "",
                        fkClass:  "",
                        fkUserRoleOffice:  "",
                        phone:  "",
                        skillScore:  "",
                        studentActivityHistoryIndicator:  "",
                        studentAttempts:  "",
                        studentId:  "",
                        studentImage:  "",
                        studentName:  "",
                        studentStatus:  "",
                        safeUrl : "",
                        notes:""
                      }];

  public screenWidth: any;
  public screenHeight: any;
  
  safeStudentUrl : any;
  unsafeStudentUrl : any;
  closeResult!: string;

  constructor(private classService : ClassService,
              private ngbModalService: NgbModal,
              public router: Router, 
              private route : ActivatedRoute,
              private teacherService : TeacherService,
              private studentService : StudentService,
              private imageService : FileServiceService,
              private sanitizer : DomSanitizer,
              public bsModalRef: BsModalRef,
              private sharedDataService : SharedDataService ) { }

    config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-md',
      windowClass: 'app-modal-window'
    };

navbarTitle : any;

  ngOnInit(): void {

this.navbarTitle = GlobalClass.navbarTitle;

this.classId = this.route.snapshot.params['classId'];

// GET CLASS OBJECT
this.classService.getClassById(this.classId).subscribe(data=>{
  this.class = data;
});

this.getAllStudents();

// GET TEACHERS
this.teacherService.getTeachersInaClass(this.classId).subscribe(data=>{  
  this.classTeachers = data;

  this.teachersExist = true;

  if(this.classTeachers === undefined || this.classTeachers.length === 0){
    setTimeout(()=>{                           //timer
      this.showSpinner = false;
 }, 1500);
  }

  let count : number = 0;
  this.classTeachers.forEach( (value) => {
    count++;
    this.imageService.getUserImage(value.teacherImage).subscribe(
    data=>{
    this.unsafeStudentUrl = URL.createObjectURL(data);
    this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
    
    value.safeUrl = this.safeStudentUrl;
    value.collapsedState = false;
    });

    if(count === this.classTeachers.length){
      setTimeout(()=>{                           //timer
        this.showSpinner = false;
   }, 1500);
    }
    });
});

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.officeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;


    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.classOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange: false,
      processing: true,
      info:false
    }; 
  }

  getAllStudents(){
this.studentService.getAllStudentsInClass(this.classId).subscribe(data=>{
  this.classStudentsInActive = [];
  this.classStudentsActive = [];
  this.classStudents = data;
  this.studentsExist = true;
  this.classStudents.forEach((value) => {
this.imageService.getUserImage(value.studentImage).subscribe(
data=>{
this.unsafeStudentUrl = URL.createObjectURL(data);
value.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
if(value.studentStatus===true){
this.classStudentsActive.push(value);
}else if(value.studentStatus===false){
this.classStudentsInActive.push(value);
}
});
}); 
});
}

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }

  addNewTeacher() {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddTeacherComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      let data = {
        classId: this.classId ,
        name : this.class.name,
        productId : this.class.fkProduct,
        officeId : this.officeId
                 }
      modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

          modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {

            this.showSpinner = true;

            this.teacherService.getTeachersInaClass(this.classId).subscribe(data=>{  
              this.classTeachers = data;
            
              if(this.classTeachers === undefined || this.classTeachers.length === 0){
                setTimeout(()=>{                           //timer
                  this.showSpinner = false;
             }, 1500);
              }
            
              let count : number = 0;
              this.classTeachers.forEach( (value) => {
                count++;
                this.imageService.getUserImage(value.teacherImage).subscribe(
                data=>{
                this.unsafeStudentUrl = URL.createObjectURL(data);
                this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
                
                value.safeUrl = this.safeStudentUrl;
                value.collapsedState = false;

                });
            
                if(count === this.classTeachers.length){
                  setTimeout(()=>{                           //timer
                    this.showSpinner = false;
               }, 1500);
                }
              });
            });
          });
  }
  
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  addNewStudent(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddStudentComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
      classId: this.classId ,
      name : this.class.name,
      officeId : this.officeId
        }

      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
        this.showSpinner = true;
        this.getAllStudents();
        this.showSpinner = false;
//         this.studentService.getStudentsInaClass(this.classId).subscribe(data=>{

//           this.classStudents = data;
//           this.classStudents.forEach( (value) => {
//         this.imageService.getUserImage(value.studentImage).subscribe(
//         data=>{
//         this.unsafeStudentUrl = URL.createObjectURL(data);
//         this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
        
//         value.safeUrl = this.safeStudentUrl;
//         setTimeout(()=>{                           //timer
//       this.showSpinner = false;
//  }, 1500);
//         });
//         }); 
//         });
      });   
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }

  goToHomePage(){
    this.router.navigate(['/dashboard/class-admin']);
    }

  teacherStatusUpdate(teacherId : any){

    this.teacherService.teacherStatusUpdate(teacherId).subscribe(
      data=>{
        this.classTeachers.forEach((val)=>{


          val.subjects.forEach((value : any)=>{

            if(value.teacherId === teacherId){
              if(value.subjectStatus === true){
                value.subjectStatus = false;
              }else if(value.subjectStatus === false){
                value.subjectStatus = true;
              }
            }

          })



        });
      }
    )
  }
  
  studentStatusUpdate(studentId : any, notes : any){
    this.showSpinner = true;
    this.studentService.studentStatusUpdate(studentId, notes).subscribe(
      data=>{
        this.classStudents.forEach((value)=>{
          if(value.studentId === studentId){
            value.studentStatus = !value.studentStatus;
            value.notes = notes;
          }
        });
        this.classStudentsActive = [];
        this.classStudentsInActive = [];
        this.classStudents.forEach( (value) => {
          this.imageService.getUserImage(value.studentImage).subscribe(
          data=>{
          this.unsafeStudentUrl = URL.createObjectURL(data);
          value.safeUrl = this.sanitizer.bypassSecurityTrustUrl( this.unsafeStudentUrl);
          
          if(value.studentStatus===true){
          this.classStudentsActive.push(value);
          }else if(value.studentStatus===false){
          this.classStudentsInActive.push(value);
          }
          
          });
          });
          this.showSpinner = false;
      });
    }

  collapseActivityCardContent(teacherId : string){
      
    this.classTeachers.forEach((value)=>{

      
      if(value.teacherId === teacherId){

        if(value.collapsedState === true){
          value.collapsedState = false
        } else if(value.collapsedState === false){
          value.collapsedState = true
        }

      }
    });
  }
  getColor(){
      this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    logOut(): void {
      this.config.class = 'modal-md';
      const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
        {
          size : 'md',
          scrollable: true,
          windowClass: 'app-modal-window',
          keyboard: false
        });
      let data = {
       email : this.loggedInUser.email 
      }
  
      modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
  
      modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
      });  
    }
  
  reloadPage() {
    window.location.reload();
  }

}
