<div [ngStyle]="backgroundStyle"  class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Edit Video</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

   <div class="modal-body">
    <div class="card">
      <div class="card-body">
    <form (ngSubmit)="onSubmit()" #aplnrForm="ngForm"> 

      <div class="form-group row">
 
    <div class="col-md-6">
      <label>Video Name</label>
      <input  maxlength="50"  placeholder="Enter Video Name" class="form-control" type="text"  name="actVideoLinkName"  id="actVideoLinkName" [(ngModel)]="activityVideoLink.actVideoLinkName">
    </div>


    <div class="col-md-6">
        <label class="required">Video Link</label>
        <input  maxlength="250"  #link="ngModel" required placeholder="Enter Video Link" class="form-control" type="text"  name="actVideoLink"  id="actVideoLink" [(ngModel)]="activityVideoLink.actVideoLink">
    </div>

      </div>

   <br>
      <div>
         <label for="actVideoLinkDescription">Video Description</label>
         <textarea  maxlength="250"  placeholder="Enter Video Description" class="form-control" type="text" name="actVideoLinkDescription"  id="actVideoLinkDescription" [(ngModel)]="activityVideoLink.actVideoLinkDescription"></textarea>
      </div>

    </form>
</div>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked"   [disabled]="!aplnrForm.valid" type="submit" class="btn btn-sm btn-primary float-right" (click)="onSubmit(); clicked=true">Update</button>
</div>