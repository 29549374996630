
  


  <nav class="navbar navbar-expand-md navbar-dark " [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i>Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>
  
 
 
 
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a style="cursor: pointer;color: #0275d8;" onclick="history.back()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Shipments</li>
    </ol>
  </nav>
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  <div class="p-4">
   
     
        <div class="table-header row" [ngStyle]="backgroundStyle"  style="background-color: #343a40 ; white-space: nowrap; text-overflow: ellipsis; display: flex; flex-wrap: wrap;padding: 0.7rem;margin-left: 0%;margin-right: 0%;">
          <div class="col-md-12" style="color: rgb(248, 245, 245);">
            <mat-icon onclick="history.back()" style="margin-right: 2%; float: left; cursor: pointer; color: cornsilk; " aria-hidden="false" aria-label="Example home icon">reply_all</mat-icon>

            <span class="table-caption" style="color: rgb(250, 246, 246); font-size: 16px; font-weight: 600;" >
              Shipments
            </span>

            <a style="cursor:pointer;" class="float-right" (click)="addNewShipment();">
              <img src="/assets/images/icons/plus-circle.svg" width="25" height="25" alt="" loading="lazy">
            </a>

          </div> 
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <ngx-spinner *ngIf="shipmentLoader" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="line-spin-clockwise-fade-rotating"
            [fullScreen]="false">
            <p style="color: white">{{shipmentLoaderMessage}}</p>
          </ngx-spinner>
            <table *ngIf="shipmentExists" class="table mat-elevation-z6 table-striped table-bordered table-sm row-border hover" 
            datatable [dtOptions]="shipmentOptions" >
              <thead class="tableheader">
                <tr>
                  <th style="text-align: left;">Shipment Partner</th>
                  <th style="text-align: left;">Address</th>
                  <th >Action</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr class="td-height" *ngFor="let ccd of shipments">
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.shipmentPartner}}</td>
                  <td class="td-height" style="text-align: left;padding: 10px 18px;">{{ccd.shipmentAddress}}</td>
                  <td class="td-height" style=" height: 2rem;">
                    <button *ngIf="ccd.shipmentStatus!== null && ccd.shipmentStatus" type="button"
                      class="btn btn-danger mr-2  btn-sm" (click)="open(confirmDeactivation)">Deactivate</button>
                    <button *ngIf="ccd.shipmentStatus !== null && !ccd.shipmentStatus" type="button"
                      class="btn btn-success mr-2" (click)="open(confirmActivation)">Activate</button> 


                    <button (click)="editShipment(ccd.shipmentId);" class="btn btn-info  mr-2  btn-sm">Edit</button>
                    <button (click)="viewShipmentDetailsModal(ccd.shipmentId)" class="btn btn-secondary  mr-2  btn-sm">View</button>
                  </td>

                                                      

  <ng-template #confirmActivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;"  id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to activate shipment ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updateShipmentStatus(ccd.shipmentId);modal.dismiss('Cross click')">Activate</button>
    </div>
  </ng-template> 
  
  
  <ng-template #confirmDeactivation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="font-weight: bold;" id="modal-basic-title">Confirmation</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure to deactivate shipment ?
    </div> 
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-success" (click)="updateShipmentStatus(ccd.shipmentId);modal.dismiss('Cross click')">Deactivate</button>
    </div>
  </ng-template>

                 
                </tr>
              </tbody>
            </table>
  
          </div>
        </div>
      
   
  </div>
  
 
 
  
 
  
  
  