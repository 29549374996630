<div  [style.background-color]='getColor()'  class="modal-header">
    <h4 *ngIf="fromParent.context==='create'"
    style="color: white;" class="modal-title pull-left">Create Language Response</h4>
    <h4 *ngIf="fromParent.context==='edit'"
    style="color: white;" class="modal-title pull-left">Edit Language Response</h4>
    
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 

  <div class="modal-body">
      <div class="card-body"> 
      <form #botResponseForm="ngForm">  

        <div *ngIf="fromParent.context==='create'">
            
            <div>
                <label for="fkBotLanguageId">Language</label>
                <select required class="form-control" [(ngModel)]="botLangResponse.fkBotLanguageId">
                  <option value="chooselanguage" disabled>Choose Language</option>
                  <option *ngFor="let temp of botLanguages" [ngValue]="temp.botLanguageId">{{temp.language}}</option>
                </select>
              </div>

              <br>

            <div>
              <label class="required" for="response">Response</label> <br>
              <textarea maxlength="1024" [(ngModel)]="botLangResponse.langResponse" class="form-control" rows="10" required name="response" id="response">
              </textarea>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="interactiveListButtonName">Interactive List Button Name</label>
                <input class="form-control" maxlength="19" type="text" placeholder="Enter Button Name" 
                name="interactiveListButtonName" id="interactiveListButtonName" [(ngModel)]="botLangResponse.interactiveListButtonName">
              </div>
            </div>

        
        </div>


        <div *ngIf="fromParent.context==='edit'">
            
         

            <div>
              <label class="required" for="response">Response</label> <br>
              <textarea maxlength="1024" [(ngModel)]="botLangResponse.langResponse" class="form-control" rows="10" required name="response" id="response">
              </textarea>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="interactiveListButtonName">Interactive List Button Name</label>
                <input class="form-control" maxlength="19" type="text" placeholder="Enter Button Name" 
                name="interactiveListButtonName" id="interactiveListButtonName" [(ngModel)]="botLangResponse.interactiveListButtonName">
              </div>
            </div>
        
        </div>



</form>
</div>
</div>

<div class="modal-footer">
<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='create'" (click)="create(); clicked=true">Save</button>

<button [disabled]="clicked" [disabled]="!botResponseForm.valid" class="btn btn-sm btn-primary float-right" 
*ngIf="fromParent.context==='edit'" (click)="edit(); clicked=true">Update</button>

</div>


