import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { FormConfiguation } from 'src/app/models/form-configuation';
import { UserDetails } from 'src/app/models/user-details';
import { CompCustDetailsService } from 'src/app/service/comp-cust-details.service';
import { ProductService } from 'src/app/service/product.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { FormConfigurationService } from 'src/app/services/FormConfiguration/form-configuration.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-convert-to-customer-user',
  templateUrl: './convert-to-customer-user.component.html',
  styleUrls: ['./convert-to-customer-user.component.scss']
})
export class ConvertToCustomerUserComponent implements OnInit {

  clicked = false;
  combinedPTC : CompleteCustomerDetails = new CompleteCustomerDetails();
  officeId!:string;
  loggedInUser: any;
  type : any;
  headerText : any;
  userExists = false;
  customerExists = false;
  showForm = false;
  custObject : CompleteCustomerDetails = new CompleteCustomerDetails();
  theme : any;
  customerId : any;
  closeResult !: string;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private ccdService : CompCustDetailsService,
              private sharedDataService : SharedDataService,
              public bsModalRef: BsModalRef, private productService: ProductService,
              private ngbModalService: NgbModal,
              private formConfigService : FormConfigurationService,
              public activeModal: NgbActiveModal,
              private subscriptionService : SubscriptionService,
              private datePipe : DatePipe,
              private toaster: ToastrService) {}

              @Input() fromParent: any;

              formConfigGroups : FormConfiguation[] =[];
              countryCodes : any[] = [];
              leadConversionType : any;
              products: Product[] = [];

              case : any;

 ngOnInit(): void {

  this.customerId = this.fromParent.customerId;
  this.leadConversionType = this.fromParent.leadConversionType;  

  this.case = this.fromParent.case;

  this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
  this.officeId = this.loggedInUser.offices[0].id;  
  this.theme = this.sharedDataService.getCardOfficeTheme();
    this.countryCodes = GlobalClass.CountryCodes;
    if(this.case==='CREATE CUSTOMER AND USER'){
      this.combinedPTC.entityType="Individual";
    }
    this.combinedPTC.fkProductId = "";


    
    if(this.leadConversionType==="Customer And ParentMentor"){
      this.productService.getProductListByOfficeId(this.officeId).subscribe(data=>{
        this.products = data;
      });
    }

    this.ccdService.getCustomerById(this.customerId).subscribe(data=>{
      this.combinedPTC = data;
      if(this.combinedPTC.leadCreationDate !== null && this.combinedPTC.leadCreationDate !== undefined){
      this.combinedPTC.leadCreationDate = new Date(this.combinedPTC.leadCreationDate);
      this.combinedPTC.leadCreationDate = this.datePipe.transform(this.combinedPTC.leadCreationDate, "yyyy-MM-dd");
    }
    });

    this.formConfigService.getByFormNameAndOfficeId(environment.apiUrl + "formConfig/getGroupedFormConfigByFormNameAndOfficeId/" + "Student Creation").then(
      data=>{                    
        
        this.formConfigGroups = data;
        if(this.formConfigGroups.length !== 0  && this.formConfigGroups !== undefined && this.formConfigGroups !== null){
          this.formConfigGroups.forEach((formConfigGroup)=>{

            let uds : UserDetails[] = [];

            formConfigGroup.groupedformConfigs.forEach((formConfig)=>{
              let ud : UserDetails = new UserDetails();
              ud.fkFormConfigId = formConfig.formConfigId;
              ud.mandatory = formConfig.mandatory;
              ud.displayName = formConfig.displayName;
              ud.columnName = formConfig.columnName;
              ud.key = formConfig.columnName;
              uds.push(ud);
            });
            formConfigGroup.userDetails = uds;
          });
        }              
      }); 
  }

  usernameExists = false;
  choice : any;
  value : any;
emailUnavailable = false;
phoneUnavailable = false;

get backgroundStyle(){
  let globalClass : GlobalClass = new GlobalClass() ;
  return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
}

  checkUsernameAvailability(){
    if(this.emailUnavailable === true && this.phoneUnavailable === true){
      this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
    }else{
    if(this.choice === "Email"){
      this.value = this.combinedPTC.email;
    }else if(this.choice === "Phone"){
      this.value = this.combinedPTC.phoneNumber;
    }

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{

        this.usernameExists = res;

        if(this.usernameExists === true){

          if(this.choice === "Email"){
           this.emailUnavailable = true;
          }else if(this.choice === "Phone"){
            this.phoneUnavailable = true;
          }

          if(this.emailUnavailable === true && this.phoneUnavailable === true){
            this.toaster.error("Provided email & phone not available for usernames, Try firstname@email or firstname@phone !")
            this.value = null;
          }else{
            this.toaster.error("Username exists !");
          }

        }else if(this.usernameExists === false){
          this.toaster.success("Username available !");
          // if(this.leadConversionType==="Customer And User" || this.leadConversionType==="Customer Only"){
          //   this.convertToCustomerAndUserOnSubmit();
          // }else if(this.leadConversionType==="Customer And ParentMentor"){
          //   this.convertToCustomerAndParentMentorOnSubmit();
          // }
          if(this.case==="CONVERT LEAD TO CUSTOMER USER"){
            if(this.leadConversionType==="Customer And User" || this.leadConversionType==="Customer Only"){
              this.convertToCustomerAndUserOnSubmit();
            }else if(this.leadConversionType==="Customer And ParentMentor"){
              this.convertToCustomerAndParentMentorOnSubmit();
            }
          }else if(this.case==="CREATE CUSTOMER AND USER"){
            this.createCustomerUserAndCreateUserDetails();
          }
        }
      });

    }
  }

  checkCustomUsernameAvailability(){

    this.subscriptionService.searchIfUsernameExistsByEmailOrPhone(environment.apiUrl + "user/searchIfUsernameExists/" + this.value).then(
      res=>{
        this.usernameExists = res;
        if(this.usernameExists === true){
          this.toaster.error("Username exists !");
        }else if(this.usernameExists === false){
          this.toaster.success("Username available !");
          if(this.case==="CONVERT LEAD TO CUSTOMER USER"){
            if(this.leadConversionType==="Customer And User" || this.leadConversionType==="Customer Only"){
              this.convertToCustomerAndUserOnSubmit();
            }else if(this.leadConversionType==="Customer And ParentMentor"){
              this.convertToCustomerAndParentMentorOnSubmit();
            }
          }else if(this.case==="CREATE CUSTOMER AND USER"){
            this.createCustomerUserAndCreateUserDetails();
          }
        }
      });
  }

  open(content: any) {
    this.ngbModalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  convertToCustomerAndUserOnSubmit(){
    this.clicked = true;
    this.combinedPTC.username = this.value;
    this.formConfigGroups.forEach((fcg)=>{
      fcg.userDetails.forEach((userDetails)=>{
        this.combinedPTC.userDetails.push(userDetails);
      });
    });

    this.combinedPTC.fkOfficeId = this.officeId;
    this.ccdService.addCompCustomerDetails(environment.apiUrl + 'crmCustAdd/convertToCustomerUserAndCreateUserDetails', this.combinedPTC).then(
            data=>{
              this.passEntry.emit("Successful");
              this.toaster.success('Lead Converted Successfully');
              this.activeModal.close();
              this.ngbModalService.dismissAll();
      });
  }

  convertToCustomerAndParentMentorOnSubmit(){
    this.clicked = true;
    this.combinedPTC.username = this.value;
    this.formConfigGroups.forEach((fcg)=>{
      fcg.userDetails.forEach((userDetails)=>{
        this.combinedPTC.userDetails.push(userDetails);
      });
    });

    this.combinedPTC.fkOfficeId = this.officeId;
    this.ccdService.addCompCustomerDetails(environment.apiUrl + 'crmCustAdd/convertToCustomerParentMentorWithSubscription', this.combinedPTC).then(
            data=>{
              this.passEntry.emit("Successful");
              this.toaster.success('Lead Converted Successfully');
              this.activeModal.close();
              this.ngbModalService.dismissAll();
      });
  }

  createCustomerUserAndCreateUserDetails(){
    this.clicked = true;
    this.combinedPTC.username = this.value;
    this.formConfigGroups.forEach((fcg)=>{
      fcg.userDetails.forEach((userDetails)=>{
        this.combinedPTC.userDetails.push(userDetails);
      });
    });

    this.combinedPTC.fkOfficeId = this.officeId;
    this.ccdService.addCompCustomerDetails(environment.apiUrl + 'crmCustAdd/createCustomerUserAndCreateUserDetails', this.combinedPTC).then(
            data=>{
              this.passEntry.emit("Successful");
              this.toaster.success('Customer Created Successfully');
              this.activeModal.close();
              this.ngbModalService.dismissAll();
      }); 
  }
  
  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }  

  closeUpdateModal(){
    this.activeModal.close();
  }

}
