import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-convo-flow-tree',
  templateUrl: './bot-convo-flow-tree.component.html',
  styleUrls: ['./bot-convo-flow-tree.component.scss']
})
export class BotConvoFlowTreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
