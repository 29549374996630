import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";
import { BotMemberInfo } from "src/app/BOT_CODE/bot_classes/bot-member-info";
import { CreateBotMemberComponent } from "src/app/BOT_CODE/bot_modals/bot_member_info/create-bot-member/create-bot-member.component";
import { FileUploadComponent } from "src/app/BOT_CODE/bot_modals/bot_member_info/file-upload/file-upload.component";

@Component({
  selector: "app-medical-specialists-management-dashboard",
  templateUrl: "./medical-specialists-management-dashboard.component.html",
  styleUrls: ["./medical-specialists-management-dashboard.component.scss"],
})
export class MedicalSpecialistsManagementDashboardComponent implements OnInit {
  showSpinner = true;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  userOptions: any;
  closeResult!: string;
  membersExist = false;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botMember: BotMemberInfo = new BotMemberInfo();

  constructor(
    private ngbModalService: NgbModal,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private botOfficeService: BotOfficeServiceService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );

    this.userOfficeId = this.loggedInUser.offices[0].id;

    this.userId = this.loggedInUser.userId;
  }

  botResponse: BotResponse = new BotResponse();
  allResponses: BotResponse = new BotResponse();
  convoInitiationTreeDef: BotResponse = new BotResponse();
  UpdateNewUserDetailsTreeDefs: BotResponse[] = [];
  convoInitiationExists = false;
  context = "Definitions";
  botAgentsReceived = false;
  botUser: BotUser = new BotUser();
  viewResponses = false;

  usersListType = "table";
  searchText = "";

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getBotMembersByOfficeId();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  getBotMembersByOfficeId() {
    this.botOfficeService
      .getBotMembersByOfficeIdAndType(
        environment.apiUrl + "botConvo/getBotMembersByOfficeIdAndType/doctor"
      )
      .then((res) => {
        this.botMember = res;
        this.membersExist = true;
        this.showSpinner = false;
      });
  }

  // deleteBotCustomerUser(id : any){
  //   this.botOfficeService.deleteBotCustomerUser(environment.apiUrl + "botOffice/deleteBotCustomerUser/" + id).then(
  //     res=>{
  //       this.toaster.success("Customer deleted successfully !");
  //      this.getBotCustomerUsersByOfficeId();
  //     });
  // }

  // deleteUserChatSession(id:any){
  //   this.botOfficeService.deleteUserChatSession(environment.apiUrl + "botOffice/deleteUserChatSession/" + id).then(
  //     res=>{
  //       this.toaster.success("Customer Chat session deleted successfully !")
  //     });
  // }

  createBotMember() {
    let memberInfo: BotMemberInfo = new BotMemberInfo();
    memberInfo.botMemberId = null;
    memberInfo.memberType = "doctor";

    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotMemberComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      header: "Add New Listing",
      context: "ADD_BOT_MEMBER",
      botMemberInfo: memberInfo,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotMemberInfo) => {
        if (this.botMember.list === null) {
          this.botMember.list = [];
        }
        this.botMember.list.push(receivedEntry);
      }
    );
  }

  editBotMember(memberInfo: any) {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(CreateBotMemberComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      header: "Edit Listing",
      context: "EDIT_BOT_MEMBER",
      botMemberInfo: memberInfo,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotMemberInfo) => {
        this.botMember.list.forEach((memInfo) => {
          if (memInfo.botMemberId === receivedEntry.botMemberId) {
            memInfo = receivedEntry;
          }
        });
      }
    );
  }

  botMemberCreationFileUpload() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(FileUploadComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });

    let data = {};

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      this.getBotMembersByOfficeId();
    });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }
}
