<div   [ngStyle]="backgroundStyle" class="modal-header">
    <h4 style="color: white;" class="modal-title pull-left">Add Student to {{name}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUpdateModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> 
  


  <div class="modal-body">
    <div class="card">
      <div class="card-body"> 
      <form (ngSubmit)="onSubmit()" #clsForm="ngForm"> 



        <div class="form-group row" style="padding-left: 10px; padding-right: 10px;">


   
        <label class="required" for="fkUserRoleOffice">Student</label> <br>
            <select required class="form-control" [(ngModel)]="student.fkUserRoleOffice" id="fkUserRoleOffice" name="fkUserRoleOffice">
              <option value="" disabled >Choose User</option>
              <option  *ngFor="let user of users"  [ngValue]="user.id">{{user.userName}}</option>
            </select>
        
          </div>


        <!-- </div> -->

           

    
</form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button [disabled]="clicked"  [disabled]="!clsForm.valid" class="btn btn-sm btn-primary float-right" (click)="onSubmit();clicked=true">Add</button>
  </div>

