<nav class="navbar navbar-expand-md navbar-dark "
 [ngStyle]="backgroundStyle">
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out </a>
        </li>    
  
      </ul>
    </div>  
  </nav>



  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a  style="cursor: pointer;color: #0275d8;" (click)="goToHomePage()"><i class="fa fa-home" aria-hidden="true"></i> Home</a></li>
      <li  style="cursor: pointer;color: #0275d8;" class="breadcrumb-item"><a onclick="history.back()">Subject</a></li>
      <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page">Skill Report</li>
    </ol>
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div *ngIf="showDropDown === true" class="row col-md-12">
    <div class="col-md-6"></div>
  <div class="col-md-4">
    <form #aplnrForm="ngForm"> 
          <select #curId="ngModel" required class="form-control" [(ngModel)]="selectedStudentId">
            <option  class="form-control" value="" disabled selected>Select Student</option>

            <option  class="form-control" [ngValue]="student.studentId" *ngFor="let student of students">{{student.studentName}}</option>
          </select>
          <div class="alert alert-danger" *ngIf="curId.touched && !curId.valid">Select Student</div>
          <br>
        </form>
  </div>

  <div class="row col-md-2">
    <button [disabled]="!aplnrForm.valid" class="btn btn-info" style="float: left; background-color: rgb(17, 17, 58); color: white;" (click)="getSkillResults()" > Go</button>
   
    <a (click)="computeSkillReport();" matTooltip="Compute Skill Report" style="cursor:pointer;margin-left: 6%;">
      <img alt="" height="35" loading="lazy" src="/assets/images/icons/bar-chart.png" width="35">
    </a>
  </div>

</div>

<div style="margin: 2em;" *ngIf="showMainPanel === true">
<div style="padding: 1em;" class="card mat-elevation-z12 row">

  <div class="row col-md-12">

    <div class="col-md-6 col-lg-6">
    <div class="row sub-image-div">
      <img style="border-radius:50% ;margin: 1em" class="sub-image" [src]="selectedStudent.safeStudentUrl" height="45" width="45" alt="...">
      <h4 style="color: black;margin: 1em; font-weight: bold;">{{selectedStudent.studentName}}</h4>

    </div>
    </div>
   
    <div  class="col-md-6 col-lg-6">

    <a (click)="showDropDownToChoose();" matTooltip="Select Student" style="cursor:pointer; float: right;">
      <img alt="" height="35" loading="lazy" src="/assets/images/icons/cRole.svg" width="35">
    </a>

    <a *ngIf="groupedView===false" (click)="groupedView=!groupedView;" matTooltip="Group Skills" style="cursor:pointer; float: right;margin-right: 2%;">
      <img alt="" height="35" loading="lazy" src="/assets/images/icons/flowchart.png" width="35">
    </a>

    <a *ngIf="groupedView===true" (click)="groupedView=!groupedView;" matTooltip="Ungroup Skills" style="cursor:pointer; float: right;margin-right: 2%;">
      <img alt="" height="35" loading="lazy" src="/assets/images/icons/list.png" width="35">
    </a>

  </div>
  </div>
</div>

<div *ngIf="groupedView===true" style="padding: 1em;margin-top: 1em;" class="card mat-elevation-z12 row">

  <div class="row col-md-12">

    <div style="padding: 1em;" class="card mat-elevation-z12 col-md-3 col-lg-3">

      <div *ngIf="mainSKillSelected === false">
        <div style="width: 100%;text-align: center;">
          <img width="75" height="75" style="border-radius:50%;" alt="image" src="/assets/images/icons/changeActivity1.png">
        </div>
  
        <br>
        <div style="text-align: center;">
          <h2>Skills</h2> 
          <p>
            A skill is the learnt ability to perform an action to get determined results which can be obtained 
             with good execution often within a given amount of time & energy.
<br>
            Click on 'Explore' to view skill report and suggestion.
          </p>
        </div>
  
        <br>
  
      </div>
      <div *ngIf="mainSKillSelected === true">
      <div style="width: 100%;text-align: center;">
        <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="selectedMainSkill.safeUrl">
      </div>

      <br>
      <div style="text-align: center;">
        <h2>{{selectedMainSkill.skillName}} </h2> 
        <p>
          {{selectedMainSkill.skillDescription}}
        </p>
      </div>

      <br>

      <div style="text-align: center;">
      <button [ngStyle]="backgroundStyle" style="text-align: center;margin: 3%;" class="btn btn-info" (click)="showMainSkillResult()">
        Back</button>
      </div>
    </div>
    </div>

    <div class="col-md-9 col-lg-9">
  
      <div class="content" [style.display]="mainSkills ? 'block' : 'none'">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let mainSkill of skillResults">
            <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
            
              <div style="width: 100%;text-align: center;">
                <img width="75" height="75" style="border-radius:50%;" alt="image" [src]="mainSkill.safeUrl">
              </div>
              <br>
                <div style="text-align: center;">
                <h2>{{mainSkill.skillName}} </h2> 
                 
            </div>
              <br>
           
              <div *ngIf="mainSkill.collapsedState === true">
                
              <div style="text-align: center;">
                <p>
                  {{mainSkill.skillDescription}}
                </p>
              </div> 
            </div>
            <div style="text-align: center;">
            <button style="text-align: center;" mat-button color="primary" (click)="collapseActivityCardContent2(mainSkill.skillId)">
              {{mainSkill.collapsedState ? 'Close' : 'View Description'}}</button> <br>
              <button [ngStyle]="backgroundStyle" style="text-align: center;margin: 3%;" class="btn btn-info" (click)="exploreMainSkillResult(mainSkill.skillId)">
                Explore</button>
                
            </div>
            </mat-card>
          </div>
        </div>
        </div>

        <div class="content" [style.display]="results ? 'block' : 'none'">
          <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let skillResult of selectedMainSkill.resultlist">
              <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
              
                <div class="row col-md-12">
                  <div class="col-md-6">
                     <h2 style="float: left;">{{skillResult.skillName}} </h2>
                  </div>
                  <div style="text-align: right;" class="col-md-6">
                    <!-- <a matTooltip="View Skill Details"  style="cursor: pointer;" (click)="openSubSkillDetailsModal(skillResult)">
                    <img style="float: right;" width="25" height="25" style="border-radius:50%;" alt="image" src="/assets/images/icons/eye.png">
                  </a> -->
                  
                  </div> 
                </div>

                <div class="row col-md-12">

                <div class="col-md-4" style="width: 100%;text-align: center;">
                  <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="skillResult.safeUrl">
                </div>

                  <div *ngIf="skillResult.resultHistory !== undefined && skillResult.resultHistory !== null" class="col-md-4" style="width: 100%;text-align: center;">

                    <div class="chart-wrapper">
                      <canvas baseChart
                          [datasets]="skillResult.lineChartData" 
                          [labels]="lineChartLabels" 
                          [options]="lineChartOptions"
                          [colors]="lineChartColors" 
                          [legend]="lineChartLegend" 
                          [chartType]="lineChartType" 
                          [plugins]="lineChartPlugins">
                      </canvas>
                  </div>

                  </div>

                  <div *ngIf="skillResult.resultHistory === undefined || skillResult.resultHistory === null" class="col-md-4" style="width: 100%;text-align: center;">

                    <h1>
                      No Data 
                     </h1>

                  </div>
              
                  <div class="col-md-4" style="width: 100%;text-align: center;margin-top: 5%;">
                 <h1 *ngIf="skillResult.skillScore > skillResult.lastScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
                  <a style="font-size:1.8em;">
                  <i class="fa fa-caret-up" style="color:green"></i></a>
                 </h1>

                 <h1 *ngIf="skillResult.lastScore > skillResult.skillScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
                  <a style="font-size:1.8em;">
                  <i class="fa fa-caret-down" style="color:red"></i></a>
                 </h1>

                 <h1 *ngIf="skillResult.lastScore === skillResult.skillScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
                  <a style="font-size:1.8em;">
                 </a>
                 </h1>

                 <h1 *ngIf="skillResult.lastScore === undefined"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}} -</a>
                 </h1>

                  </div>

                </div>
                <br>
                  <div style="text-align: center;">
                  
              </div>
                <br>
                               
                <div style="text-align: center;">
                  <p>
                    {{skillResult.resultSuggestion}}
                  </p>
                </div> 

              </mat-card>
            </div>
          </div>
          </div>
    </div>
    </div>
</div>







































<div *ngIf="groupedView===false" style="padding: 1em;margin-top: 1em;" class="card mat-elevation-z12 row">
  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" *ngFor="let skillResult of actualResultlist">
        <mat-card class="mat-elevation-z12" style="border-radius: 25px;">
        
          <div class="row col-md-12" style="margin: 0%; padding: 0%;">
            <div class="col-md-6">
               <h2 style="float: left;">{{skillResult.skillName}} </h2>
            </div>
            <div style="text-align: right;" class="col-md-6">
              <!-- <a matTooltip="View Skill Details"  style="cursor: pointer;" (click)="openSubSkillDetailsModal(skillResult)">
              <img style="float: right;" width="25" height="25" style="border-radius:50%;" alt="image" src="/assets/images/icons/eye.png">
            </a> -->
            <button style="width: auto;float: right;pointer-events: none;" [ngStyle]="backgroundStyle" class="btn btn-info">{{skillResult.parentSkillName}}</button>
            </div> 
          </div>

          <div class="row col-md-12">

          <div class="col-md-4" style="width: 100%;text-align: center;">
            <img width="100" height="100" style="border-radius:50%;" alt="image" [src]="skillResult.safeUrl">
          </div>

            <div *ngIf="skillResult.resultHistory !== undefined && skillResult.resultHistory !== null" class="col-md-4" style="width: 100%;text-align: center;">

              <div class="chart-wrapper">
                <canvas baseChart
                    [datasets]="skillResult.lineChartData" 
                    [labels]="lineChartLabels" 
                    [options]="lineChartOptions"
                    [colors]="lineChartColors" 
                    [legend]="lineChartLegend" 
                    [chartType]="lineChartType" 
                    [plugins]="lineChartPlugins">
                </canvas>
            </div>

            </div>

            <div *ngIf="skillResult.resultHistory === undefined || skillResult.resultHistory === null" class="col-md-4" style="width: 100%;text-align: center;">

              <h1>
                No Data 
               </h1>

            </div>
        
            <div class="col-md-4" style="width: 100%;text-align: center;margin-top: 5%;">
           <h1 *ngIf="skillResult.skillScore > skillResult.lastScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
            <a style="font-size:1.8em;">
            <i class="fa fa-caret-up" style="color:green"></i></a>
           </h1>

           <h1 *ngIf="skillResult.lastScore > skillResult.skillScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
            <a style="font-size:1.8em;">
            <i class="fa fa-caret-down" style="color:red"></i></a>
           </h1>

           <h1 *ngIf="skillResult.lastScore === skillResult.skillScore"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}}</a>
            <a style="font-size:1.8em;">
           </a>
           </h1>

           <h1 *ngIf="skillResult.lastScore === undefined"><a style="font-size:3em;margin-right: 3%;">{{skillResult.skillScore}} -</a>
           </h1>

            </div>

          </div>
          <br>
            <div style="text-align: center;">
            
        </div>
          <br>
                         
          <div style="text-align: center;">
            <p>
              {{skillResult.resultSuggestion}}
            </p>
          </div> 

        </mat-card>
      </div>
    </div>
    </div>
  
</div>
</div>