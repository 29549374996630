
  <nav class="navbar navbar-expand-md navbar-dark " [style.background-color]='getColor()'>
    <div *ngIf="this.loggedInUser.offices[0].logo !== null">
      <img height="30" width="30" class="logo-image"
        [src]="'data:image/jpeg;base64,'+ this.loggedInUser.offices[0].logo" />
    </div>
    <div *ngIf="this.loggedInUser.offices[0].logo === null">
      <img alt="" height="30" loading="lazy" src="/assets/images/courseManagementSystem.svg" width="30">
    </div>
    <a (click)="reloadPage()" class="navbar-brand" style="color:white;cursor: pointer;margin-left: 1%;">{{navbarTitle}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button> 
  
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a  onclick="history.back()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-arrow-left"></i> Go back </a>
        </li>
        
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="goToAccountPage()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-user"></i> Account </a>
        </li>
  
        <li class="dropdown pmd-dropdown pmd-user-info ml-auto">
          <a (click)="switchDashboard()" style="margin-right: 10px;color: white;float: right;cursor: pointer;" class="nav-link" data-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-fw fa-toggle-on"></i> Switch Dashboard </a>
        </li>
  
        <li class="nav-item" style="float: right;">
          <a (click)="logOut()" class="nav-link" style="margin-right: 10px; color:white;float: right;cursor: pointer;">
            <i class="fa fa-sign-out" ></i> Log Out</a>
        </li>    
  
      </ul>
    </div>  
  </nav>

  <div class="wrapper">
    <div class="overlay" *ngIf="showSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="showSpinner">
      <ng-content></ng-content>
    </div>
  </div>

  <div style="height: 100%;position: absolute;width: 100%;" [style.background-image]="'url(/assets/images/geometry.png)'">


   

    
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" style="cursor: pointer;color: #0275d8;" (click)="goBack();" aria-current="page"><i class="fa fa-home" aria-hidden="true"></i> Home </li>
    <li style="cursor: pointer;" style="cursor: pointer;color: #0275d8;" class="breadcrumb-item" onclick="history.back()" aria-current="page"> Course </li>
    <li style="cursor: pointer;" class="breadcrumb-item active" aria-current="page"> Subject </li>
</ol>
</nav>


  <div class="content" *ngIf="cardsExist === true" style="margin: 2.5%;">

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
      
  <div class="col mb-4">
        <div class="card mat-elevation-z12">
          <br>
          <div class="sub-image-div">
          <img class="sub-image" [src]="safeSubUrl" height="86" width="86" alt="...">
          </div>
          <div class="card-body">
            <h5 class="card-title">{{subjectObject.name}}</h5>
            <p class="card-text"></p>
            <br>
            <div onclick="history.back()" style="cursor: pointer;" class="sub-image-div">
                <img class="sub-image" src="/assets/images/icons/back-track.svg" height="73" width="73" alt="...">
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="officeObject.oa_Assignment === true" class="col mb-4">
        <div class="card mat-elevation-z12">
            <br>
            <br>
          <div class="sub-image-div">
          <img src="/assets/images/icons/assignments.svg" height="100" width="100" alt="...">
          </div>
          <br>
          <div class="card-body">
            <h5 class="card-title">Assignments</h5>
            <p class="card-text"></p>
            <div class="button-div">
            <button [style.background-color]='getColor()' (click)="goToStudentAssignmentPage();" class="btn btn-info">Explore</button>
            <br>
            <br>
          </div>
          </div>
        </div>
      </div>


      <div class="col mb-4">
        <div class="card mat-elevation-z12">
            <br>
            <br>
          <div class="sub-image-div">
          <img class="sub-image" src="/assets/images/icons/curriculum.svg" height="100" width="100" alt="...">
          </div>
          <br>
          <div class="card-body">
            <h5 class="card-title">Curriculum</h5>
            <p class="card-text"></p>
            <div class="button-div">
            <button [style.background-color]='getColor()' (click)="exploreCurriculum();" class="btn btn-info">Explore</button>
            <br>
            <br>
          </div>
          </div>
        </div>
      </div>

      <div class="col mb-4">
        <div class="card mat-elevation-z12">
            <br>
            <br>
          <div class="sub-image-div">
          <img class="sub-image" src="/assets/images/icons/timeline.svg" height="100" width="100" alt="...">
          </div>
          <br>
          <div class="card-body">
            <h5 class="card-title">Timeline</h5>
            <p class="card-text"></p>
            <div class="button-div">
            <button [style.background-color]='getColor()' (click)="goToActivityTimeline();" class="btn btn-info">Explore</button>
           <br>
           <br>
          
          </div>
          </div>
        </div>
      </div>

      <div class="col mb-4" *ngIf="officeObject.oa_SkillReport === true">
        <div class="card mat-elevation-z12">
            <br>
            <br>
          <div class="sub-image-div">
          <img class="sub-image"  src="/assets/images/icons/results.svg" height="100" width="100" alt="...">
          </div>
          <br>
          <div class="card-body">
            <h5 class="card-title">Skill Report</h5>
            <p class="card-text"></p>
            <div class="button-div">
            <button [style.background-color]='getColor()' (click)="goToStudentSkillReport();" class="btn btn-info">Explore</button>
            <br>
           <br>
          </div>
          </div>
        </div>
      </div>
     </div>
  </div>
</div>



  