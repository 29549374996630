import { NotificationDetails } from "./notification-details"

export class NotificationMaster {

    notificationMasterId:any
	notificationMasterActiveStatus:any
	officeId:any
	type:any
	name:any
	fromAddress:any
	fkNotificationSettingId:any

	details : NotificationDetails[] = [];
	newDetails : NotificationDetails[] = [];

	fkNotificationSettingKey : any;
	fkNotificationSettingValue : any;
}
