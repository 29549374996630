import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/BIZ_CRM/biz_crm_classes/customer';
import { CompleteCustomerDetails } from 'src/app/crmClasses/complete-customer-details';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/services/sharedData.service';

@Component({
  selector: 'app-email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.scss']
})
export class EmailCheckComponent implements OnInit {
  loggedInUser : any; 
  theme : any;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  header : any;
  officeId : any;
  email : any;
  profile : CompleteCustomerDetails = new CompleteCustomerDetails();
  profile2 : Customer = new Customer();
  search = true;
  customerExists = false;
  userExists = false;
  userAndCustomerProfileExist = false;

  constructor(private subscriptionService : SubscriptionService,    private sharedDataService: SharedDataService,
    private activeModal : NgbActiveModal,private ngbModalService: NgbModal,
    private toastr : ToastrService) { }

  @Input() fromParent: any; 

  @Output() passEntry: EventEmitter<any> = new EventEmitter(); 

  ngOnInit(): void {

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.theme = this.sharedDataService.getCardOfficeTheme();
 
    this.header = this.fromParent.header;
    this.officeId = this.fromParent.officeId;

  }

  closeUpdateModal(){
    this.activeModal.close();
  }

  searchFoUser(){

    this.profile.email = this.email;
    
    this.subscriptionService.searchCustomerAndUserProfiles(environment.apiUrl + "customer/searchCustomerAndUserProfilesBcrm/" + this.email + "/" + this.officeId).then(
      data =>{        
        this.profile = data;

        this.profile2.firstName = this.profile.firstName;
        this.profile2.customerId = this.profile.customerId;
        this.profile2.email = this.profile.email;
        this.profile2.phoneNumber = this.profile.phoneNumber;
        
        if(this.profile.customerProfileExists === true){

          this.search = false;
          this.customerExists = true;

        }else if(this.profile.userProfileExists === true){

          this.search = false;
          this.userExists = true;

        }else if(this.profile.userAndCustomerProfileExist === true){

          this.search = false;
          this.userAndCustomerProfileExist = true;

        }else{

          this.createCustomer();

        }
      });



  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  } 

  useDifferentEmail(){

    this.email = '';
    this.userExists = false;
    this.customerExists = false;
    this.userAndCustomerProfileExist = false;
    this.search = true;

  }


  // authorized
  createCustomer(){ 

    this.activeModal.close();
    this.profile2.email = this.email;
    this.profile2.createCustomer = true;
    this.passEntry.emit(this.profile2);
  } 

  showProfile(){

    this.activeModal.close();
    this.profile2.createCustomer = false;
    this.passEntry.emit(this.profile2);

  }


}
