import { ChartType } from "chart.js";
import { FeedbackAnswer } from "./feedback-answer";
import { FeedbackAnswerValue } from "./feedback-answer-value";
import { FormResponse } from "./form-response";

export class FeedbackQuestion {

    id:any;
	activeStatus:any;
    question:any;
	questionDescription:any;
	questionOrder:any;
	answerType:any;
	questionType:any;
	fkOfficeId:any;
	fkAnswerId : any;
	fkCustomerId:any;

	feedbackFormQuestionId : any;

	mandatory : any = false;

	feedbackAnswerDto : FeedbackAnswer = new FeedbackAnswer();

	feedbackAnswerValuesDto : FeedbackAnswerValue[] = [];

	formResponses : FormResponse[] = [];

	viewAnswers : any = false;

	public pieChartLabels : string[] = [];
	public pieChartData : number[] = [20,50,60,20,50,60,20,50,60,20,50,60,20,50,60,20,50,60,20,50,60,20,50,60];
	public pieChartType : ChartType = 'pie';
}
