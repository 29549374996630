import { BotResponse } from "./bot-response";
import { BotResponseOptions } from "./bot-response-options";
import { BotScheduleTempVariable } from "./bot-schedule-temp-variable";

export class BotTemplate {
  botTemplateId: any;
  templateName: any;
  templateDisplayName: any;
  fkOfficeId: any;
  botTemplateStatus: any;
  tempLanguage: any;
  tempVarCount: any;
  tempBody: any;
  list: BotTemplate[] = [];
  tempLanguageCode: any;
  fkBotTemplateResId: any;
  tempVarList: BotScheduleTempVariable[] = [];
  tempVarSampleContentList: BotScheduleTempVariable[] = [];
  tempHeaderType: any;
  tempHeaderText: any;
  tempHeaderSample: any;
  tempHeaderVariable: any;
  headerFilename: any;
  fkDefaultHeaderBotMediaId: any;
  fileHandle: any;
  fkBotTemplateRes: BotResponse = new BotResponse();
  updateUserSessionCurrentQueryId: any;
  tryZotiTemplateLinkedResponse: any;
  options: BotResponseOptions[] = [];
  tempCategory: any;
  createTemplateOnMeta: any;
  whatsappMsgTemplateId: any;
  whatsappMsgTemplateStatus: any;
  templateButtons: BotResponseOptions[] = [];
}
