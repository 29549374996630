// Angular
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AddRoleComponent } from './add-role/add-role.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from '../handlers/guards/auth.guards';
import { TimeoutScreenComponent } from './timeout-screen/timeout-screen.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { AuthSpinnerComponent } from '../spinner/auth-spinner/auth-spinner.component';
import { AddBotUserComponent } from './add-bot-user/add-bot-user.component';
const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: '/login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'change-password',
				component: ChangePasswordComponent,
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent,
			}
		]
	}
];



@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgxSpinnerModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes)
	],
	providers: [
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		ForgotPasswordComponent,
		AddRoleComponent,
		AddUserComponent,
		ResetPasswordComponent,
		ChangePasswordComponent,
		TimeoutScreenComponent,
		AuthSpinnerComponent,
		AddBotUserComponent
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				AuthGuard
			]
		};
	}
}
