import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SharedDataService } from "src/app/services/sharedData.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwitchDashboardComponent } from "src/app/course-management/switch-dashboard/switch-dashboard.component";
import { GlobalClass } from "src/app/global/global-class";
import { LogoutWarningModalComponent } from "src/app/logout/logout-warning-modal/logout-warning-modal.component";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotCustomerUser } from "src/app/BOT_CODE/bot_classes/bot-customer-user";
import { BotUserFileUploadComponent } from "src/app/BOT_CODE/bot_modals/bot_user/bot-user-file-upload/bot-user-file-upload.component";
import { BotUserGroup } from "src/app/BOT_CODE/bot_classes/bot-user-group";
import { BotUserGroupModalComponent } from "src/app/BOT_CODE/bot_modals/bot_user_group/bot-user-group-modal/bot-user-group-modal.component";
import { TableUtil } from "src/app/table-util";
import { DatePipe } from "@angular/common";
import { BotUser } from "src/app/BOT_CODE/bot_classes/bot-user";

@Component({
  selector: "app-bot-customer-users",
  templateUrl: "./bot-customer-users.component.html",
  styleUrls: ["./bot-customer-users.component.scss"],
})
export class BotCustomerUsersComponent implements OnInit {
  showSpinner = false;
  currentRole = "CB_OFFICE_ADMIN";
  navbarTitle: any;
  userId!: string;
  userOfficeId: any;
  loggedInUser;
  usersExists = false;
  userOptions: any;
  closeResult!: string;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  modalRefs: BsModalRef[] = [];
  table: any;

  public screenWidth: any;
  public screenHeight: any;

  botCustomerUser: BotCustomerUser = new BotCustomerUser();
  botUserGroup: BotUserGroup = new BotUserGroup();
  botGroupsByBotUserId: BotUserGroup = new BotUserGroup();
  botGroupsByBotUserIdLoaded = false;
  botCustomerUsersWithoutGroups: BotCustomerUser = new BotCustomerUser();

  constructor(
    private ngbModalService: NgbModal,
    private datePipe: DatePipe,
    public router: Router,
    private sharedDataService: SharedDataService,
    public bsModalRef: BsModalRef,
    private toaster: ToastrService,
    private botOfficeService: BotOfficeServiceService
  ) {
    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.userOfficeId = this.loggedInUser.offices[0].id;
    this.userId = this.loggedInUser.userId;
  }

  usersExist = false;
  usersGroupsExist = false;
  searchText = "";
  searchTextGrp = "";
  searchTextUsersWithoutGrps = "";

  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.getBasicAgentDetailsByOffice();

    this.userOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      lengthChange: false,
      processing: true,
      info: false,
      paging: false,
      searching: false,
    };
  }

  groupsFetched = false;
  tabClick(tab: any) {
    tab.index;
    if (tab.index === 1 && this.groupsFetched === false) {
      this.getBotUserGroupsByOfficeId();
    }
  }

  botAgentsObject: BotUser = new BotUser();
  getBasicAgentDetailsByOffice() {
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBasicAgentDetailsByOffice"
      )
      .then((res) => {
        this.botAgentsObject = res;
      });
  }

  getBotUserGroupsByOfficeId() {
    this.showSpinner = true;
    this.botOfficeService
      .getGroups(environment.apiUrl + "botConvo/getBotUserGroupsByOfficeId")
      .then((res) => {
        this.botUserGroup = res;
        this.usersGroupsExist = true;
        this.groupsFetched = true;
        this.showSpinner = false;
      });
  }

  deleteUserFromArray(botUserId: any) {
    for (var i = this.botCustomerUser.users.length - 1; i >= 0; --i) {
      if (this.botCustomerUser.users[i].botUserId == botUserId) {
        this.botCustomerUser.users.splice(i, 1);
      }
    }
  }

  getAllBotCustomerUsersByOfficeId() {
    this.showSpinner = true;
    this.botOfficeService
      .getBotCustomerUsersByOfficeId(
        environment.apiUrl +
          "botOffice/getBotUsersForBotUsersDashboardByOfficeId"
      )
      .then((res) => {
        this.botCustomerUser = new BotCustomerUser();
        this.botCustomerUser = res;

        this.botCustomerUser.users.forEach((user) => {
          if (
            user.creationTimestamp !== null &&
            user.creationTimestamp !== undefined
          ) {
            let x = user.creationTimestamp;
            // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
            user.creationTimestampFormatted = this.datePipe.transform(
              x,
              "dd-MM-yyyy, h:mm a"
            );
          }

          if (
            this.botAgentsObject.users !== null &&
            this.botAgentsObject.users !== undefined
          ) {
            this.botAgentsObject.users.forEach((agent) => {
              if (agent.uroId === user.fkUroIdManualChat) {
                user.assignedAgentName = agent.name;
              }
            });
          }
        });

        this.usersExist = true;
        this.showSpinner = false;
      });
  }

  searchValue = null;
  searchBotUsersByNameOrPhone() {
    if (this.searchValue !== null) {
      this.showSpinner = true;
      this.botOfficeService
        .getBotCustomerUsersByOfficeId(
          environment.apiUrl +
            "botOffice/searchBotUsersByNameOrPhone/" +
            this.searchValue
        )
        .then((res) => {
          this.botCustomerUser = new BotCustomerUser();
          this.botCustomerUser = res;

          if (
            this.botCustomerUser.users !== undefined &&
            this.botCustomerUser.users !== null &&
            this.botCustomerUser.users.length > 0
          ) {
            this.botCustomerUser.users.forEach((user) => {
              if (
                user.creationTimestamp !== null &&
                user.creationTimestamp !== undefined
              ) {
                let x = user.creationTimestamp;
                // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
                user.creationTimestampFormatted = this.datePipe.transform(
                  x,
                  "dd-MM-yyyy, h:mm a"
                );
              }
              if (
                this.botAgentsObject.users !== null &&
                this.botAgentsObject.users !== undefined
              ) {
                this.botAgentsObject.users.forEach((agent) => {
                  if (agent.uroId === user.fkUroIdManualChat) {
                    user.assignedAgentName = agent.name;
                  }
                });
              }
            });
            this.usersExist = true;
          } else {
            this.usersExist = false;
            this.toaster.error("Users Not Found");
          }
          this.showSpinner = false;
        });
    } else {
      this.toaster.error("Enter a name or phone number to search");
    }
  }

  fromDateTime = null;
  toDateTime = null;
  searchBotUsersByCreationTimestamp() {
    if (this.fromDateTime !== null && this.toDateTime !== null) {
      this.showSpinner = true;

      let dto: BotCustomerUser = new BotCustomerUser();
      dto.fromDateTime = this.fromDateTime;
      dto.toDateTime = this.toDateTime;

      this.botOfficeService
        .searchBotUsersByCreationTimestamp(
          environment.apiUrl + "botOffice/searchBotUsersByCreationTimestamp",
          dto
        )
        .then((res) => {
          this.botCustomerUser = new BotCustomerUser();
          this.botCustomerUser = res;

          if (
            this.botCustomerUser.users !== undefined &&
            this.botCustomerUser.users !== null &&
            this.botCustomerUser.users.length > 0
          ) {
            this.botCustomerUser.users.forEach((user) => {
              if (
                user.creationTimestamp !== null &&
                user.creationTimestamp !== undefined
              ) {
                let x = user.creationTimestamp;
                // let y = Date.UTC(x[0], x[1] - 1, x[2], x[3], x[4]);
                user.creationTimestampFormatted = this.datePipe.transform(
                  x,
                  "dd-MM-yyyy, h:mm a"
                );
              }
              if (
                this.botAgentsObject.users !== null &&
                this.botAgentsObject.users !== undefined
              ) {
                this.botAgentsObject.users.forEach((agent) => {
                  if (agent.uroId === user.fkUroIdManualChat) {
                    user.assignedAgentName = agent.name;
                  }
                });
              }
            });
            this.usersExist = true;
          } else {
            this.usersExist = false;
            this.toaster.error("Users Not Found");
          }
          this.showSpinner = false;
        });
    } else {
      this.toaster.error("Enter a proper Date-Range");
    }
  }

  deleteBotCustomerUser(id: any, dataType: any) {
    this.showSpinner = true;
    this.botOfficeService
      .deleteBotCustomerUser(
        environment.apiUrl +
          "botOffice/deleteBotUserData/" +
          id +
          "/" +
          dataType
      )
      .then((res) => {
        if (dataType === "user and entire user data") {
          this.toaster.success("User deleted successfully !");
          this.deleteUserFromArray(id);
          this.showSpinner = false;
        }
        if (dataType === "convo log") {
          this.toaster.success("Convo log deleted successfully !");
          this.showSpinner = false;
        }
        if (dataType === "invoices") {
          this.toaster.success("Invoices deleted successfully !");
          this.showSpinner = false;
        }
        if (dataType === "admin notifications") {
          this.toaster.success(
            "Admin notifications & notes deleted successfully !"
          );
          this.showSpinner = false;
        }
        if (dataType === "form responses") {
          this.toaster.success("Form Responses deleted successfully !");
          this.showSpinner = false;
        }
        if (dataType === "user group details") {
          this.toaster.success("User removed from all groups successfully !");
          this.showSpinner = false;
        }
        if (dataType === "schedules") {
          this.toaster.success("Schedules deleted successfully !");
          this.showSpinner = false;
        }
      });
  }

  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(LogoutWarningModalComponent, {
      size: "md",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      email: this.loggedInUser.email,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: string) => {}
    );
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  goToAccountPage() {
    this.router.navigate(["bcrm-user-account", this.userId]);
  }

  switchDashboard() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(SwitchDashboardComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      userId: this.userId,
      currentRole: this.currentRole,
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {});
  }

  botUsersCreationFileUpload() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserFileUploadComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });

    let data = {};

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe((receivedEntry: string) => {
      // this.getAllBotCustomerUsersByOfficeId();
    });
  }

  createBotUserGroup(): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserGroupModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "createBotUserGroup",
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUserGroup) => {
        this.showSpinner = true;
        if (this.botUserGroup.list === null) {
          this.botUserGroup.list = [];
        }
        this.botUserGroup.list.push(receivedEntry);
        this.showSpinner = false;
      }
    );
  }

  editBotUserGroup(grp: BotUserGroup): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserGroupModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "editBotUserGroup",
      botUserGroup: grp,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUserGroup) => {
        this.showSpinner = true;
        this.botUserGroup.list.forEach((group) => {
          if (group.botUserGrpId === receivedEntry.botUserGrpId) {
            group = receivedEntry;
          }
        });
        this.showSpinner = false;
      }
    );
  }

  viewBotUserGroupDetails(grp: BotUserGroup): void {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(BotUserGroupModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "viewBotUserGroupDetails",
      botUserGroup: grp,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );

    modalRef.componentInstance.passEntry.subscribe(
      (receivedEntry: BotUserGroup) => {
        if (this.botUserGroup.list === null) {
          this.botUserGroup.list = [];
        }
        this.botUserGroup.list.push(receivedEntry);
      }
    );
  }

  dataSource: BotCustomerUser[] = [];
  exportUsersArray(users: BotCustomerUser[]) {
    this.showSpinner = true;
    if (users !== null && users !== undefined && users.length > 0) {
      this.dataSource = users;
      const onlyNameAndSymbolArr: Partial<any>[] = this.dataSource.map((x) => ({
        Name: x.name,
        Phone: x.userIdentifier,
        Alternate_Phone: x.alternatePhone,
        Gender: x.gender,
        Custom_var_1: x.customVar1,
        Custom_var_2: x.customVar2,
      }));
      TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "users");
      this.showSpinner = false;
    } else {
      this.toaster.error("No Users to download !");
      this.showSpinner = false;
    }
  }

  botAgentUserIdToAssign = "";
  assignAgentToManualChat(botUserId: any, existingConvoMode: any) {
    this.showSpinner = true;
    if (this.botAgentUserIdToAssign !== "") {
      this.botOfficeService
        .assignAgentToManualChat(
          environment.apiUrl +
            "botConvo/setConvoModeAndAssignAgent/" +
            botUserId +
            "/" +
            this.botAgentUserIdToAssign +
            "/" +
            existingConvoMode
        )
        .then((res) => {
          this.showSpinner = true;

          let agentName = "";
          this.botAgent.users.forEach((agent) => {
            if (agent.userId === this.botAgentUserIdToAssign) {
              agentName = agent.firstName + " " + agent.lastName;
            }
          });

          this.botCustomerUser.users.forEach((user) => {
            if (user.botUserId === botUserId) {
              user.fkUroIdManualChat = this.botAgentUserIdToAssign;
              user.assignedAgentName = agentName;
            }
          });

          this.showSpinner = false;
          this.toaster.success("Assigned successfully!");
        });
    } else {
      this.toaster.error("Choose an Agent to assign !");
      this.showSpinner = false;
    }
  }

  botAgent: BotUser = new BotUser();
  getBotAgents() {
    this.showSpinner = false;
    this.botOfficeService
      .getBotUsers(
        environment.apiUrl + "botOffice/getBotAgentsAndManualChatsByOffice"
      )
      .then((res) => {
        this.botAgent = new BotUser();
        this.botAgent = res;
      });
  }

  getBotUserGroupsByBotUserId(id: any) {
    this.botGroupsByBotUserId = new BotUserGroup();
    this.botOfficeService
      .getGroups(
        environment.apiUrl + "botConvo/getBotUserGroupsByBotUserId/" + id
      )
      .then((res) => {
        this.botGroupsByBotUserId = res;
        if (
          this.botGroupsByBotUserId.list === null ||
          this.botGroupsByBotUserId.list === undefined ||
          this.botGroupsByBotUserId.list.length === 0
        ) {
          this.toaster.error("User is not part of any group !");
        }
        this.botGroupsByBotUserIdLoaded = true;
      });
  }

  getUsersNotPartOfGroupsByOfficeId() {
    this.botOfficeService
      .getBotCustomerUsersByOfficeId(
        environment.apiUrl + "botOffice/getUsersNotPartOfGroupsByOfficeId"
      )
      .then((res) => {
        this.botCustomerUsersWithoutGroups = new BotCustomerUser();
        this.botCustomerUsersWithoutGroups = res;
        this.botGroupsByBotUserIdLoaded = true;
      });
  }
}
