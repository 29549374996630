//  THIS IS A SUBJECT DASHBOARD FOR A PRODUCT/COURSE || THIS IS A SUBJECT DASHBOARD FOR A PRODUCT/COURSE || THIS IS A SUBJECT DASHBOARD FOR A PRODUCT/COURSE 

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSubjectComponent } from 'src/app/course-management/subject/add-subject/add-subject.component';
import { UpdateSubjectComponent } from 'src/app/course-management/subject/update-subject/update-subject.component';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { Product } from 'src/app/crmClasses/product';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { SubjectService } from 'src/app/service/subject.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subject-dashboard',
  templateUrl: './subject-dashboard.component.html',
  styleUrls: ['./subject-dashboard.component.scss']
})
export class SubjectDashboardComponent implements OnInit {

  currentRole = "COURSE_ADMIN";
  showSpinner = true;
  subjectsDontExist = false;
  subjectsExist = false;
  gridColumns = 4;
  spinnerEnabled : boolean = false;
  loggedInUser: any;
  loggedUser: any;
  productId!: string;
  product : Product = new Product();
  subjectListByProdId: Subject[] = [];
  userName !: string;
  userId!: string;
  imageDataFromServer : any;
  unsafeImageUrl: any;
  safeImageUrl: any;

  abc : any;
  xyz : any;
  xxx : any;

  subjectListWithSafeUrl : any[] =[ { subId : '', 
                                      name : '',
                                      description : '',
                                      subjectStatus : '',
                                      fkProductId : '',
                                      subjectImage : '',
                                      safeUrl : '',
                                      readMore : '' 
                                    } ];
  safeImageUrl2: any;
  unsafeImageUrl2: any;

   
  

  constructor(private route : ActivatedRoute,
              private subjectService : SubjectService,
              private ngbModalService: NgbModal,
              public router: Router, 
              private sharedDataService : SharedDataService,
              private imageService: FileServiceService,
              private sanitizer : DomSanitizer ) { }

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
    windowClass: 'app-modal-window'
  };

  navbarTitle : any;
  
  ngOnInit(): void {
    this.navbarTitle = GlobalClass.navbarTitle;

    this.productId = this.route.snapshot.params['productId'];

    this.getSubListByProductId(this.productId);
  
    this.loggedUser = localStorage.getItem('loggedUser');
    this.loggedInUser = JSON.parse(this.loggedUser);
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.userId = this.loggedInUser.userId;

 
  }

  // authorized
  getSubListByProductId(productId : any){
    this.subjectService.getSubjectsByProductIdCourseAdmin(environment.apiUrl + "subject/getSubListByProductIdCourseAdmin/" + productId).then(
      data=>{
        this.subjectListWithSafeUrl = [];

        for(var index in data){

            this.subjectListWithSafeUrl.push( { 'subId' : data[index].subId,
                                                'name' : data[index].name,
                                                'description' : data[index].description,
                                                'subjectStatus' : data[index].subjectStatus,
                                                'fkProductId' : data[index].fkProductId,
                                                'subjectImage' : data[index].subjectImage,
                                                'safeUrl' : this.xyz,
                                                'readMore' : false
                                               });
        }

        if(this.subjectListWithSafeUrl === undefined || this.subjectListWithSafeUrl.length === 0){
          this.showSpinner = false;
        }else{
          this.subjectsExist = true;
        }
      
        let count = 0;
        this.subjectListWithSafeUrl.forEach( (value) => {
          count++;

          this.imageService.getSubjectImage( value.subjectImage ).subscribe(
            data=>{
              this.xyz = data;
              this.abc = URL.createObjectURL(data);
              this.xyz = this.sanitizer.bypassSecurityTrustUrl( this.abc);

              value.safeUrl = this.xyz;

            });

        }); 

        if(count === this.subjectListWithSafeUrl.length){
          this.showSpinner = false;
        }

      });


  }

  get backgroundStyle(){
    let globalClass : GlobalClass = new GlobalClass() ;
    return globalClass.backgroundStyle(this.loggedInUser.offices[0].theme);
  }
  
  add(){
    this.add1(this.productId);
  }

    // authorized
  add1(productId : string){ 
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(AddSubjectComponent,
      {
        size : 'lg', scrollable: true, windowClass: 'app-modal-window', keyboard: false
      });
      
    let data = { productId : productId }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : Subject) => {
      this.showSpinner = true;
      this.subjectListWithSafeUrl.pop();
      this.subjectListWithSafeUrl = [];
      this.getSubListByProductId(this.productId);
    }); 
  }

   // authorized
  editSubject(subId : string){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open(UpdateSubjectComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
      
      let data = {
        subId : subId 
          }

          modalRef.componentInstance.fromParent = data;
          modalRef.result.then((result) => {
          }, (reason) => {
          });

      modalRef.componentInstance.passEntry.subscribe((receivedEntry : Subject) => {
        this.showSpinner = true;
        this.subjectListWithSafeUrl = [];
        this.subjectListWithSafeUrl.pop();
        this.getSubListByProductId(this.productId);
      }); 

      
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId])
  }
  

  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });    
  }

  logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }
    
      changePassword(): void {
        this.router.navigate(['/auth/change-password']).then();
      }

  exploreSubject(subId : string){
    this.router.navigate(['subjectCurriculumDashboard',subId]);
  }

  getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
      return this.loggedInUser.offices[0].theme + '!important';
    }

    reloadPage() {
      window.location.reload();
    } 
}
