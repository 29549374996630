import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-notification-details',
  templateUrl: './create-notification-details.component.html',
  styleUrls: ['./create-notification-details.component.scss']
})
export class CreateNotificationDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
