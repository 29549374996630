import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SharedDataService } from "src/app/services/sharedData.service";
import { BotResponseServiceService } from "src/app/BOT_CODE/bot_services/bot-response-service.service";
import { BotResponse } from "src/app/BOT_CODE/bot_classes/bot-response";
import { BotOfficeServiceService } from "src/app/BOT_CODE/bot_services/bot-office-service.service";
import { BotMedia } from "src/app/BOT_CODE/bot_classes/bot-media";
import { UploadBotMediaComponent } from "../../bot-media/upload-bot-media/upload-bot-media.component";
import { BotTreeDefinition } from "src/app/BOT_CODE/bot_classes/bot-tree-definition";
import { BotResponseOptions } from "src/app/BOT_CODE/bot_classes/bot-response-options";
import { BotUserGroup } from "src/app/BOT_CODE/bot_classes/bot-user-group";

@Component({
  selector: "app-add-bot-response",
  templateUrl: "./add-bot-response.component.html",
  styleUrls: ["./add-bot-response.component.scss"],
})
export class AddBotResponseComponent implements OnInit {
  clicked = false;
  loggedInUser: any;
  theme: any;
  eligibleVariableList: BotResponse[] = [];

  constructor(
    private sharedDataService: SharedDataService,
    private botResponseService: BotResponseServiceService,
    public activeModal: NgbActiveModal,
    private ngbModalService: NgbModal,
    private botOfficeService: BotOfficeServiceService,
    public toastr: ToastrService
  ) {}

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() passEntryRemoveDefErrorRes: EventEmitter<any> = new EventEmitter();
  @Input() fromParent: any;

  botResponse: BotResponse = new BotResponse();
  botResponses: BotResponse[] = [];
  botResOption: BotResponseOptions = new BotResponseOptions();

  context: any;

  nameQExists = false;
  genderQExists = false;
  altPhoneQExists = false;
  langQExists = false;
  customVar1Exists = false;
  customVar2Exists = false;
  dataLoaded = false;
  media: BotMedia[] = [];
  allMedia: BotMedia[] = [];

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };

  defOfficeErrorResId: any;
  templateId: any;
  botTLResId: any;
  treeDefs: BotTreeDefinition[] = [];
  botUserGroup: BotUserGroup = new BotUserGroup();
  message: any;

  ngOnInit(): void {
    this.context = this.fromParent.context;
    this.eligibleVariableList = this.fromParent.eligibleVariableList;

    if (this.context === "Warning Component") {
      this.message = this.fromParent.message;
    }

    if (this.context === "addConvoInitiationResponse") {
      this.botResponse.saveVar = false;
      this.botResponse.userResType = 9;
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
    }

    if (this.context === "createFlowInitiationResponse") {
      this.botResponse.definition = this.fromParent.definition;
      this.botResponse.saveVar = false;
      this.botResponse.resType = "text";
      this.botResponse.userResType = 9;
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
    }

    if (this.context === "addResponseToResponse") {
      this.botResponse.parentResponseId = this.fromParent.botResponseId;
      this.botResponse.userResType = 9;
      this.botResponse.resType = "text";
      this.botResponse.fkBotMediaId = "";
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
      this.getBotMediaByOfficeId();
    }

    if (this.context === "createAdditionalSubResponse") {
      this.botResponse.parentResponseId = this.fromParent.botResponseId;
      this.botResponse.userResType = 9;
      this.botResponse.resType = "text";
      this.botResponse.fkBotMediaId = "";
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
      this.getBotMediaByOfficeId();
    }

    if (this.context === "createOrderPlacedResponse") {
      this.botResponse.parentResponseId = this.fromParent.botResponseId;
      this.botResponse.userResType = 9;
      this.botResponse.resType = "text";
      this.botResponse.fkBotMediaId = "";
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
      this.getBotMediaByOfficeId();
    }

    if (this.context === "addResponseToOption") {
      this.botResponse.parentOptionId = this.fromParent.botResponseOptionsId;
      this.botResponse.userResType = 9;
      this.botResponse.resType = "text";
      this.botResponse.fkBotMediaId = "";
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
      this.getBotMediaByOfficeId();
    }

    if (
      this.context === "editBotResponse" ||
      this.context === "editErrorResponse" ||
      this.context === "editTemplateResponse"
    ) {
      this.getBotMediaByOfficeId();

      let bRes: BotResponse = new BotResponse();
      bRes = this.fromParent.botResponse;

      this.botResponse.botResponseId = bRes.botResponseId;
      this.botResponse.response = bRes.response;
      this.botResponse.saveVar = bRes.saveVar;
      this.botResponse.var = bRes.var;
      this.botResponse.optionsExist = bRes.optionsExist;
      this.botResponse.userResType = bRes.userResType;
      this.botResponse.type = bRes.type;
      this.botResponse.resType = bRes.resType;
      this.botResponse.userDetailsUpdateVar = bRes.userDetailsUpdateVar;
      this.botResponse.saveLocationTypeAs = bRes.saveLocationTypeAs;
      this.botResponse.advancedLocationConfig = bRes.advancedLocationConfig;

      this.filterMedia(this.botResponse.resType);

      this.botResponse.fkBotMediaId = bRes.fkBotMediaId;
      this.botResponse.options = bRes.options;
      this.botResponse.userResValidation = bRes.userResValidation;
      this.botResponse.userResValidationType = bRes.userResValidationType;
      this.botResponse.interactiveListButtonName =
        bRes.interactiveListButtonName;

      if (this.botResponse.userResValidation === false) {
        this.botResponse.userResValidation = "No Dont Validate";
      } else if (this.botResponse.userResValidation === true) {
        this.botResponse.userResValidation = "Yes Validate";
      }

      if (
        this.botResponse.fkBotMediaId === null ||
        this.botResponse.fkBotMediaId === undefined
      ) {
        this.botResponse.fkBotMediaId = "";
      }
    }

    if (this.context === "addConditionalResponseToResponse") {
      this.botResponse.parentResponseId = this.fromParent.botResponseId;
      this.botResponse.userResType = 9;
      this.botResponse.resType = "text";
      this.botResponse.fkBotMediaId = "";
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
      this.getBotMediaByOfficeId();
    }

    if (this.context === "addConditionalResponseToOption") {
      this.botResponse.parentOptionId = this.fromParent.botResponseOptionsId;
      this.botResponse.userResType = 9;
      this.botResponse.resType = "text";
      this.botResponse.fkBotMediaId = "";
      this.botResponse.userResValidation = "No Dont Validate";
      this.botResponse.saveVar = false;
      this.getBotMediaByOfficeId();
    }

    if (this.context === "linkNextResponseToOption") {
      this.botResponse.parentOptionId = this.fromParent.botResponseOptionsId;
      this.botResponseService
        .getResponsesByOfficeId(
          environment.apiUrl + "botConvo/getResponsesForLinkingByOfficeId"
        )
        .then((res) => {
          let resObj: BotResponse = new BotResponse();
          resObj = res;
          this.botResponses = resObj.responses;
          this.dataLoaded = true;
        });

      this.botResponse.nextBotResponseId = "";
    }

    if (this.context === "linkNextResponseToResponse") {
      this.botResponse.parentResponseId = this.fromParent.botResponseId;
      this.botResponseService
        .getResponsesByOfficeId(
          environment.apiUrl + "botConvo/getResponsesForLinkingByOfficeId"
        )
        .then((res) => {
          let resObj: BotResponse = new BotResponse();
          resObj = res;
          this.botResponses = resObj.responses;
          this.dataLoaded = true;
        });

      this.botResponse.nextBotResponseId = "";
    }

    if (this.context === "setBackResponse") {
      let bRes: BotResponse = new BotResponse();
      bRes = this.fromParent.botResponse;
      this.botResponse.backResponseId = bRes.backResponseId;
      this.botResponse.botResponseId = bRes.botResponseId;
      this.botResponse.slNo = bRes.slNo;

      this.botResponses = this.fromParent.botResponses;
      let prevResponses: BotResponse[] = [];
      this.botResponses.forEach((res) => {
        if (res.slNo < this.botResponse.slNo) {
          prevResponses.push(res);
        }
      });
      this.botResponses = prevResponses;
    }

    if (this.context === "setErrorResponse") {
      let bRes: BotResponse = new BotResponse();
      bRes = this.fromParent.botResponse;
      this.botResponse.botResponseId = bRes.botResponseId;
      this.botResponse.errorResponseId = null;

      this.botResponses = this.fromParent.errorResponses;
    }

    if (this.context === "linkFkBotTemplateResToBotTemplate") {
      this.botTLResId = null;
      this.templateId = this.fromParent.templateId;
      this.botResponseService
        .getBotTemplateResponses(
          environment.apiUrl + "botConvo/getBotTemplateResponses"
        )
        .then((res) => {
          let resObj: BotResponse = new BotResponse();
          resObj = res;
          this.botResponses = resObj.templateResponses;
        });
    }

    if (this.context === "addFirstUserRegistrationResponse") {
      this.botResponse.var = "";
      this.botResponse.userResType = 9;
    }

    if (this.context === "addNextUserRegistrationResponse") {
      this.botResponse.var = "";
      this.botResponse.userResType = 9;
      this.botResponse.parentResponseId = this.fromParent.botResponseId;

      let botResponses0: BotResponse[] = [];
      botResponses0 = this.fromParent.botResponses;

      botResponses0.forEach((bRes0) => {
        if (bRes0.type === "UpdateNewUserDetails") {
          if (bRes0.var === "name") {
            this.nameQExists = true;
          }
          if (bRes0.var === "gender") {
            this.genderQExists = true;
          }
          if (bRes0.var === "alternatePhone") {
            this.altPhoneQExists = true;
          }
          if (bRes0.var === "language") {
            this.langQExists = true;
          }
          if (bRes0.var === "customVar1") {
            this.customVar1Exists = true;
          }
          if (bRes0.var === "customVar2") {
            this.customVar2Exists = true;
          }
        }
      });
    }

    if (this.context === "createErrorResponse") {
      this.botResponse.resType = "text";
      this.getBotMediaByOfficeId();
    }

    if (this.context === "createTemplateResponse") {
      this.botResponse.resType = "text";
      this.getBotMediaByOfficeId();
    }

    if (this.context === "setOfficeDefaultErrorResponse") {
      this.botResponses = this.fromParent.errorResponses;
      this.defOfficeErrorResId = "chooseErrorRes";
      this.botResponse = this.fromParent.currentDefaultErrorRes;
    }

    if (this.context === "add_callback_response_to_option") {
      this.botResponse.parentOptionId = this.fromParent.botResponseOptionsId;
      this.treeDefs = this.fromParent.flows;
      this.botResponse.treeDefId = "";
    }

    if (this.context === "deactivate_option_callback_response") {
      this.botResOption = this.fromParent.botResponseOption;
    }

    if (this.context === "setup_option_callback_function") {
      this.botResOption = this.fromParent.botResponseOption;
    }

    if (this.context === "setup_response_callback_function") {
      this.botResponse = this.fromParent.botResponse;
    }

    if (this.context === "linkConvoFlowToUserRegResOption") {
      this.botResponse.treeDefId = "";
      this.botResponse.parentOptionId = this.fromParent.botResponseOptionsId;
      this.treeDefs = this.fromParent.flows;
    }

    if (this.context === "linkBotUserGroupToUserRegResOption") {
      this.botResponse.botUserGrpId = "";
      this.botResponse.parentOptionId = this.fromParent.botResponseOptionsId;
      this.botOfficeService
        .getGroups(environment.apiUrl + "botConvo/getBotUserGroupsByOfficeId")
        .then((res) => {
          this.botUserGroup = res;
        });
    }

    this.loggedInUser = JSON.parse(
      this.sharedDataService.getLoggedInUserDtls()
    );
    this.theme = this.sharedDataService.getCardOfficeTheme();
  }

  createErrorResponse() {
    this.clicked = true;
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/createErrorResponse",
        this.botResponse
      )
      .then((res) => {
        this.botResponse = res;
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Error Response created successfully !");
      });
  }

  createTemplateResponse() {
    this.clicked = true;
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/createTemplateResponse",
        this.botResponse
      )
      .then((res) => {
        this.botResponse = res;
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Error Response created successfully !");
      });
  }

  addConditionalResponseToResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;
            this.botResponseService
              .addResponse(
                environment.apiUrl +
                  "botConvo/addConditionalResponseToResponse",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;
      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/addConditionalResponseToResponse",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  addConditionalResponseToOption() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;
            this.botResponseService
              .addResponse(
                environment.apiUrl + "botConvo/addConditionalResponseToOption",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;
      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/addConditionalResponseToOption",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  add_callback_response_to_option() {
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/addCallbackResponseToOption",
        this.botResponse
      )
      .then((res) => {
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Callback Response added successfully !");
      });
  }

  deactivate_option_callback_response() {
    this.botResponseService
      .checkIfResVarExists(
        environment.apiUrl +
          "botConvo/deactivateOptionCallbackResponse/" +
          this.botResOption.botResponseOptionsId
      )
      .then((res) => {
        this.activeModal.close();
        this.passEntry.emit("success");
        this.toastr.success("Callback Response deactivated successfully !");
      });
  }

  setup_option_callback_function() {
    this.botResponseService
      .checkIfResVarExists(
        environment.apiUrl +
          "botConvo/setupOptionCallbackFunction/" +
          "o/" +
          this.botResOption.botResponseOptionsId +
          "/" +
          this.botResOption.callbackNotificationFunction
      )
      .then((res) => {
        this.activeModal.close();
        this.passEntry.emit(this.botResOption.callbackNotificationFunction);
        this.toastr.success("Callback Function updated successfully !");
      });
  }

  setup_response_callback_function() {
    this.botResponseService
      .checkIfResVarExists(
        environment.apiUrl +
          "botConvo/setupOptionCallbackFunction/" +
          "r/" +
          this.botResponse.botResponseId +
          "/" +
          this.botResponse.callbackNotifResFunction
      )
      .then((res) => {
        this.activeModal.close();
        this.passEntry.emit(this.botResponse.callbackNotifResFunction);
        this.toastr.success("Callback Function updated successfully !");
      });
  }

  linkConvoFlowToUserRegResOption() {
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/linkConvoFlowToUserRegResOption",
        this.botResponse
      )
      .then((res) => {
        this.toastr.success("Convo Flow linked successfully !");
        this.activeModal.close();
        this.passEntry.emit(this.botResponse.treeDefId);
      });
  }

  linkBotUserGroupToUserRegResOption() {
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/linkBotUserGroupToUserRegResOption",
        this.botResponse
      )
      .then((res) => {
        let groupAttached: BotUserGroup = new BotUserGroup();
        this.botUserGroup.list.forEach((grp) => {
          if (grp.botUserGrpId === this.botResponse.botUserGrpId) {
            groupAttached = grp;
          }
        });
        this.toastr.success("Group linked successfully !");
        this.activeModal.close();
        this.passEntry.emit(groupAttached);
      });
  }

  addResponseToOption() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;

            this.botResponseService
              .addResponse(
                environment.apiUrl + "botConvo/addResponseToOption",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;

      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/addResponseToOption",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  createFlowInitiationResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;
            this.botResponseService
              .addResponse(
                environment.apiUrl + "botConvo/createFlowInitiationResponse",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;
      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/createFlowInitiationResponse",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  addConvoInitiationResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;

            this.botResponseService
              .addResponse(
                environment.apiUrl + "botConvo/addConvoInitiationResponse",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;

      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/addConvoInitiationResponse",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  setOfficeDefaultErrorResponse() {
    this.botOfficeService
      .setOfficeDefaultErrorResponse(
        environment.apiUrl +
          "botConvo/setOfficeDefaultErrorResponse/" +
          this.defOfficeErrorResId
      )
      .then((res) => {
        this.toastr.success("Default Error Response Set !");
        this.passEntry.emit(this.defOfficeErrorResId);
        this.activeModal.close();
      });
  }

  removeOfficeDefaultErrorResponse() {
    this.botOfficeService
      .setOfficeDefaultErrorResponse(
        environment.apiUrl + "botConvo/removeOfficeDefaultErrorResponse"
      )
      .then((res) => {
        this.toastr.success("Default Error Response removed successfully !");
        this.passEntryRemoveDefErrorRes.emit("removed");
        this.activeModal.close();
      });
  }

  editErrorResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let continueEdit = false;

    if (
      this.botResponse.resType === "image with buttons" ||
      this.botResponse.resType === "video with buttons" ||
      this.botResponse.resType === "document with buttons"
    ) {
      let optionsCount = 0;

      if (
        this.botResponse.options !== null &&
        this.botResponse.options !== undefined
      ) {
        this.botResponse.options.forEach((optn) => {
          if (optn.botResponseOptionsStatus === true) {
            optionsCount++;
          }
        });
      }

      if (optionsCount > 3) {
        this.toastr.error(
          "To use 'Image/Video With Buttons' Format, Number of active options can be upto 3 only !"
        );
        continueEdit = false;
        this.activeModal.close();
      } else if (optionsCount === 0) {
        this.toastr.error(
          "To use 'Image/Video With Buttons' Format, Response should have atleast 1 active option !"
        );
        continueEdit = false;
        this.activeModal.close();
      } else {
        continueEdit = true;
      }
    } else {
      continueEdit = true;
    }

    if (continueEdit === true) {
      let varExists = false;
      if (this.botResponse.var !== null && this.botResponse.var !== undefined) {
        this.botResponseService
          .checkIfResVarExists(
            environment.apiUrl +
              "botConvo/checkIfResVarExists/" +
              this.botResponse.var +
              "/" +
              this.botResponse.botResponseId
          )
          .then((res) => {
            varExists = res;

            if (varExists === false) {
              this.clicked = true;

              this.botResponseService
                .editBotResponse(
                  environment.apiUrl + "botConvo/editBotResponse",
                  this.botResponse
                )
                .then((res) => {
                  this.botResponse = res;
                  this.activeModal.close();
                  this.passEntry.emit(this.botResponse);
                  this.toastr.success("Response updated successfully !");
                });
            } else {
              this.clicked = false;
              this.toastr.error(
                "Variable Name exists! Enter a different variable name"
              );
            }
          });
      } else {
        this.clicked = true;

        this.botResponseService
          .editBotResponse(
            environment.apiUrl + "botConvo/editBotResponse",
            this.botResponse
          )
          .then((res) => {
            this.botResponse = res;
            this.activeModal.close();
            this.passEntry.emit(this.botResponse);
            this.toastr.success("Response updated successfully !");
          });
      }
    }
  }

  editTemplateResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let continueEdit = false;

    if (
      this.botResponse.resType === "image with buttons" ||
      this.botResponse.resType === "video with buttons" ||
      this.botResponse.resType === "document with buttons"
    ) {
      let optionsCount = 0;

      if (
        this.botResponse.options !== null &&
        this.botResponse.options !== undefined
      ) {
        this.botResponse.options.forEach((optn) => {
          if (optn.botResponseOptionsStatus === true) {
            optionsCount++;
          }
        });
      }

      if (optionsCount > 3) {
        this.toastr.error(
          "To use 'Image/Video With Buttons' Format, Number of active options can be upto 3 only !"
        );
        continueEdit = false;
        this.activeModal.close();
      } else if (optionsCount === 0) {
        this.toastr.error(
          "To use 'Image/Video With Buttons' Format, Response should have atleast 1 active option !"
        );
        continueEdit = false;
        this.activeModal.close();
      } else {
        continueEdit = true;
      }
    } else {
      continueEdit = true;
    }

    if (continueEdit === true) {
      let varExists = false;
      if (this.botResponse.var !== null && this.botResponse.var !== undefined) {
        this.botResponseService
          .checkIfResVarExists(
            environment.apiUrl +
              "botConvo/checkIfResVarExists/" +
              this.botResponse.var +
              "/" +
              this.botResponse.botResponseId
          )
          .then((res) => {
            varExists = res;

            if (varExists === false) {
              this.clicked = true;

              this.botResponseService
                .editBotResponse(
                  environment.apiUrl + "botConvo/editBotResponse",
                  this.botResponse
                )
                .then((res) => {
                  this.botResponse = res;
                  this.activeModal.close();
                  this.passEntry.emit(this.botResponse);
                  this.toastr.success("Response updated successfully !");
                });
            } else {
              this.clicked = false;
              this.toastr.error(
                "Variable Name exists! Enter a different variable name"
              );
            }
          });
      } else {
        this.clicked = true;

        this.botResponseService
          .editBotResponse(
            environment.apiUrl + "botConvo/editBotResponse",
            this.botResponse
          )
          .then((res) => {
            this.botResponse = res;
            this.activeModal.close();
            this.passEntry.emit(this.botResponse);
            this.toastr.success("Response updated successfully !");
          });
      }
    }
  }

  editBotResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let continueEdit = false;

    if (
      this.botResponse.resType === "image with buttons" ||
      this.botResponse.resType === "video with buttons" ||
      this.botResponse.resType === "document with buttons"
    ) {
      let optionsCount = 0;

      if (
        this.botResponse.options !== null &&
        this.botResponse.options !== undefined
      ) {
        this.botResponse.options.forEach((optn) => {
          if (optn.botResponseOptionsStatus === true) {
            optionsCount++;
          }
        });
      }

      if (optionsCount > 3) {
        this.toastr.error(
          "To use 'Image/Video With Buttons' Format, Number of active options can be upto 3 only !"
        );
        continueEdit = false;
        this.activeModal.close();
      } else if (optionsCount === 0) {
        this.toastr.error(
          "To use 'Image/Video With Buttons' Format, Response should have atleast 1 active option !"
        );
        continueEdit = false;
        this.activeModal.close();
      } else {
        continueEdit = true;
      }
    } else {
      continueEdit = true;
    }

    if (continueEdit === true) {
      let varExists = false;
      if (this.botResponse.var !== null && this.botResponse.var !== undefined) {
        this.botResponseService
          .checkIfResVarExists(
            environment.apiUrl +
              "botConvo/checkIfResVarExists/" +
              this.botResponse.var +
              "/" +
              this.botResponse.botResponseId
          )
          .then((res) => {
            varExists = res;

            if (varExists === false) {
              this.clicked = true;

              this.botResponseService
                .editBotResponse(
                  environment.apiUrl + "botConvo/editBotResponse",
                  this.botResponse
                )
                .then((res) => {
                  this.botResponse = res;
                  this.activeModal.close();
                  this.passEntry.emit(this.botResponse);
                  this.toastr.success("Response updated successfully !");
                });
            } else {
              // this.config.class = "modal-md";
              // const modalRef = this.ngbModalService.open(
              //   AddBotResponseComponent,
              //   {
              //     size: "sm",
              //     scrollable: true,
              //     windowClass: "app-modal-window",
              //     keyboard: false,
              //   }
              // );
              // let data = {
              //   context: "Warning Component",
              //   message:
              //     "Variable already exists ! Would you like to use the same variable again ?",
              // };
              // modalRef.componentInstance.fromParent = data;
              // modalRef.result.then(
              //   (result) => {},
              //   (reason) => {}
              // );
              // modalRef.componentInstance.passEntry.subscribe(
              //   (receivedEntry: any) => {
              //     if (receivedEntry === "y") {
              //       this.botResponseService
              //         .editBotResponse(
              //           environment.apiUrl + "botConvo/editBotResponse",
              //           this.botResponse
              //         )
              //         .then((res) => {
              //           this.botResponse = res;
              //           this.activeModal.close();
              //           this.passEntry.emit(this.botResponse);
              //           this.toastr.success("Response updated successfully !");
              //         });
              //     } else if (receivedEntry === "n") {
              //       this.clicked = false;
              //     }
              //   }
              // );
              this.clicked = false;
              this.toastr.error(
                "Variable Name exists! Enter a different variable name"
              );
            }
          });
      } else {
        this.clicked = true;

        this.botResponseService
          .editBotResponse(
            environment.apiUrl + "botConvo/editBotResponse",
            this.botResponse
          )
          .then((res) => {
            this.botResponse = res;
            this.activeModal.close();
            this.passEntry.emit(this.botResponse);
            this.toastr.success("Response updated successfully !");
          });
      }
    }
  }

  setBackResponse() {
    let backRes: BotResponse = new BotResponse();
    this.botResponses.forEach((res) => {
      if (res.botResponseId === this.botResponse.backResponseId) {
        backRes = res;
      }
    });

    if (backRes.saveVar === true) {
      this.clicked = true;
      this.botResponseService
        .editBotResponse(
          environment.apiUrl + "botConvo/setBackResponse",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(backRes);
          this.toastr.success("Back Response updated successfully !");
        });
    } else if (backRes.saveVar === false) {
      this.toastr.error("Back Response's save variable cannot be false !");
    }
  }

  setErrorResponse() {
    let errRes: BotResponse = new BotResponse();
    this.botResponses.forEach((res) => {
      if (res.botResponseId === this.botResponse.errorResponseId) {
        errRes = res;
      }
    });

    this.clicked = true;
    this.botResponseService
      .editBotResponse(
        environment.apiUrl + "botConvo/setErrorResponse",
        this.botResponse
      )
      .then((res) => {
        this.botResponse = res;
        this.activeModal.close();
        this.passEntry.emit(errRes);
        this.toastr.success("Error Response updated successfully !");
      });
  }

  linkFkBotTemplateResToBotTemplate() {
    this.clicked = true;
    this.botResponseService
      .setUp(
        environment.apiUrl +
          "botConvo/linkFkBotTemplateResToBotTemplate/" +
          this.botTLResId +
          "/" +
          this.templateId
      )
      .then((res) => {
        let botTemplateRes: any;
        this.botResponses.forEach((resp) => {
          if (resp.botResponseId === this.botTLResId) {
            botTemplateRes = resp;
          }
        });

        this.activeModal.close();
        this.passEntry.emit(botTemplateRes);
        this.toastr.success("Template Response set successfully !");
      });
  }

  linkNextResponseToOption() {
    this.botResponseService
      .linkNextResponseToOption(
        environment.apiUrl + "botConvo/linkNextResponseToOption",
        this.botResponse
      )
      .then((res) => {
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Response linked successfully !");
      });
  }

  linkNextResponseToResponse() {
    this.botResponseService
      .linkNextResponseToResponse(
        environment.apiUrl + "botConvo/linkNextResponseToResponse",
        this.botResponse
      )
      .then((res) => {
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Response linked successfully !");
      });
  }

  addFirstUserRegistrationResponse() {
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/addFirstUserRegistrationResponse",
        this.botResponse
      )
      .then((res) => {
        this.botResponse = res;
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Response added successfully !");
      });
  }

  addNextUserRegistrationResponse() {
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/addNextUserRegistrationResponse",
        this.botResponse
      )
      .then((res) => {
        this.botResponse = res;
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Response added successfully !");
      });
  }

  closeUpdateModal() {
    this.activeModal.close();
  }

  getColor() {
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + "!important";
  }

  setExtApi() {
    setTimeout(() => {
      //timer
      this.toastr.error("Could not verify url ! Please try again later");
    }, 1000);
  }

  closeResult!: string;
  open(content: any) {
    this.ngbModalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getBotMediaByOfficeId() {
    this.botOfficeService
      .getBotMediaByOfficeId(
        environment.apiUrl + "botOffice/getBotMediaByOfficeId"
      )
      .then((res) => {
        this.allMedia = [];
        let md: BotMedia = new BotMedia();
        md = res;
        this.allMedia = md.list;

        this.filterMedia(this.botResponse.resType);
      });
  }

  filterMedia(type: any) {
    this.media = [];
    if (type === "image" || type === "image with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "png" ||
          med.mediaType === "PNG" ||
          med.mediaType === "jpeg" ||
          med.mediaType === "JPEG" ||
          med.mediaType === "jpg" ||
          med.mediaType === "JPG"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "video" || type === "video with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "MP4" ||
          med.mediaType === "3gp" ||
          med.mediaType === "3GP"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "audio") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "mp4" ||
          med.mediaType === "mp3" ||
          med.mediaType === "mpeg" ||
          med.mediaType === "aac" ||
          med.mediaType === "ogg" ||
          med.mediaType === "amr" ||
          med.mediaType === "MP4" ||
          med.mediaType === "MP3" ||
          med.mediaType === "MPEG" ||
          med.mediaType === "AAC" ||
          med.mediaType === "OGG" ||
          med.mediaType === "AMR"
        ) {
          this.media.push(med);
        }
      });
    }
    if (type === "document" || type === "document with buttons") {
      this.allMedia.forEach((med) => {
        if (
          med.mediaType === "pdf" ||
          med.mediaType === "text" ||
          med.mediaType === "xls" ||
          med.mediaType === "xlsx" ||
          med.mediaType === "csv" ||
          med.mediaType === "pptx" ||
          med.mediaType === "doc" ||
          med.mediaType === "docx" ||
          med.mediaType === "PDF" ||
          med.mediaType === "TEXT" ||
          med.mediaType === "XLS" ||
          med.mediaType === "XLSX" ||
          med.mediaType === "CSV" ||
          med.mediaType === "PPTX" ||
          med.mediaType === "DOC" ||
          med.mediaType === "DOCX"
        ) {
          this.media.push(med);
        }
      });
    }
  }

  uploadBotMedia() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(UploadBotMediaComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Upload New Media",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.getBotMediaByOfficeId();
    });
  }

  createOrderPlacedResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;
            this.botResponseService
              .addResponse(
                environment.apiUrl + "botConvo/createOrderPlacedResponse",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;
      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/createOrderPlacedResponse",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  addResponseToResponse() {
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }

    let varExists = false;
    if (this.botResponse.saveVar === true) {
      this.botResponseService
        .checkIfResVarExists(
          environment.apiUrl +
            "botConvo/checkIfResVarExists/" +
            this.botResponse.var +
            "/" +
            null
        )
        .then((res) => {
          varExists = res;

          if (varExists === false) {
            this.clicked = true;
            this.botResponseService
              .addResponse(
                environment.apiUrl + "botConvo/addResponseToResponse",
                this.botResponse
              )
              .then((res) => {
                this.botResponse = res;
                this.activeModal.close();
                this.passEntry.emit(this.botResponse);
                this.toastr.success("Response added successfully !");
              });
          } else {
            this.clicked = false;
            this.toastr.error(
              "Variable Name exists! Enter a different variable name"
            );
          }
        });
    } else {
      this.clicked = true;
      this.botResponseService
        .addResponse(
          environment.apiUrl + "botConvo/addResponseToResponse",
          this.botResponse
        )
        .then((res) => {
          this.botResponse = res;
          this.activeModal.close();
          this.passEntry.emit(this.botResponse);
          this.toastr.success("Response added successfully !");
        });
    }
  }

  createAdditionalSubResponse() {
    this.clicked = true;
    if (this.botResponse.userResValidation === "No Dont Validate") {
      this.botResponse.userResValidation = false;
    } else if (this.botResponse.userResValidation === "Yes Validate") {
      this.botResponse.userResValidation = true;
    }
    this.botResponseService
      .addResponse(
        environment.apiUrl + "botConvo/createAdditionalSubResponse",
        this.botResponse
      )
      .then((res) => {
        this.botResponse = res;
        this.activeModal.close();
        this.passEntry.emit(this.botResponse);
        this.toastr.success("Response added successfully !");
      });
  }

  code() {
    this.config.class = "modal-md";
    const modalRef = this.ngbModalService.open(AddBotResponseComponent, {
      size: "sm",
      scrollable: true,
      windowClass: "app-modal-window",
      keyboard: false,
    });
    let data = {
      context: "Warning Component",
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === "continue") {
      } else if (receivedEntry === "continue") {
      }
    });
  }

  varContinue(msg: any) {
    this.passEntry.emit(msg);
    this.closeUpdateModal();
  }
}
