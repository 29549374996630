import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SwitchDashboardComponent } from 'src/app/course-management/switch-dashboard/switch-dashboard.component';
import { AddCombinedParentTeacher } from 'src/app/courseManagementClasses/combinedParentTeacher/add-combined-parent-teacher';
import { Subject } from 'src/app/courseManagementClasses/subject';
import { GlobalClass } from 'src/app/global/global-class';
import { LogoutWarningModalComponent } from 'src/app/logout/logout-warning-modal/logout-warning-modal.component';
import { FileServiceService } from 'src/app/service/file-service.service';
import { StudentService } from 'src/app/service/student.service';
import { SubjectService } from 'src/app/service/subject.service';
import { SubscriptionService } from 'src/app/service/subscription.service';
import { OfficesService } from 'src/app/services/office/offices.service';
import { SharedDataService } from 'src/app/services/sharedData.service';
import { StudentActivityTimelinePageComponent } from '../../teacherDashboards/student-activity-timeline-page/student-activity-timeline-page.component';
import { ViewStudentResultsModalComponent } from '../../teacherDashboards/view-student-results-modal/view-student-results-modal.component';
 
@Component({
  selector: 'app-child-course-dashboard',
  templateUrl: './child-course-dashboard.component.html',
  styleUrls: ['./child-course-dashboard.component.scss']
})
export class ChildCourseDashboardComponent implements OnInit {
  cardsExist = true;
  classId : any;
  subjectId : any;
  loggedInUser : any;
  showSpinner : boolean = true;
  navbarTitle : any;
  userName: string = 'User';
  theme: string = '';
  userId!: string;
  subjectObject : Subject = new Subject();
  child : AddCombinedParentTeacher = new AddCombinedParentTeacher();

  studentObject :any ={
                          dob: '',
                          email: '',
                          fkClass: '',
                          fkUserRoleOffice: '',
                          phone: '',
                          skillScore: '',
                          studentActivityHistoryIndicator: '',
                          studentAttempts: '',
                          studentId: '',
                          studentImage: '',
                          studentName: '',
                          studentStatus: ''
  }

  

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md'
  };

  public screenWidth: any;
  public screenHeight: any;

  unsafeSubUrl : any;
  safeSubUrl : any;
  studentImage : any;

  unsafeStudentUrl : any;
  safeStudentUrl : any;
  studentId : any;
  officeId : any;
  officeObject : any = {
                          activeStatus: '',
                          backgroundColor: '',
                          id:'',
                          logo: '',
                          oa_ParentMentor:'',
                          oa_SubAdmin: '',
                          oa_SkillReport: '',
                          officeLocation: '',
                          oa_ParentFeedback: '',
                          oa_Assignment: '',
                          officeName: '',
                          themeColour: ''
                        }
  unsafeImageUrl: any;
                        
  constructor(private sharedDataService: SharedDataService, 
              public router: Router,
              private route : ActivatedRoute,
              private ngbModalService: NgbModal,
              private imageService: FileServiceService,
              private sanitizer : DomSanitizer ,
              private subjectService : SubjectService,
              private studentService : StudentService,
              private subscriptionService  : SubscriptionService,
              private officeService : OfficesService, 
              public bsModalRef: BsModalRef) {
              // super();
            this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
            this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
            this.theme = this.sharedDataService.getCardOfficeTheme();
            this.userId = this.loggedInUser.userId;
            this.officeId = this.loggedInUser.offices[0].id;

  }

  ngOnInit(): void {

    this.navbarTitle = GlobalClass.navbarTitle;

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.userId = this.loggedInUser.userId;

    this.loggedInUser = JSON.parse(this.sharedDataService.getLoggedInUserDtls());
    this.userName = this.loggedInUser?.firstName + ' ' + this.loggedInUser?.lastName;
    this.theme = this.sharedDataService.getCardOfficeTheme();
    this.userId = this.loggedInUser.userId;
    // this.showSpinner = false;

    this.officeService.getOfficeById(this.officeId).subscribe(
      data=>{
        this.officeObject = data;
      });

    this.subjectId = this.route.snapshot.params['subjectId'];
    this.studentId = this.route.snapshot.params['studentId'];
    this.classId = this.route.snapshot.params['classId'];

    this.getSubById(this.subjectId);
    // this.getStudentDetailsByUserId(this.userId);
   
  }

  getSubById(subjectId : any){
    this.subjectService.getSubById(subjectId).subscribe(
      data=>{
        this.subjectObject = data;

        this.imageService.getSubjectImage(this.subjectObject.subjectImage).subscribe(
          data=>{
            this.unsafeSubUrl = URL.createObjectURL(data);
            this.safeSubUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeSubUrl);
            setTimeout(()=>{                           //timer
              this.showSpinner = false;
         }, 1500);
          });
      });
  }

  goToStudentAssignmentPage(){
    this.router.navigate(['dashboard/student/assignment',this.subjectId,this.studentId, this.classId]);
  }

  getStudentDetailsByUserId(userId : any){
    this.studentService.getStudentDetailsByUserId(userId).subscribe(
      data=>{
        this.studentObject = data;  
        this.cardsExist = true;
        this.imageService.getUserImage(this.studentObject.studentImage).subscribe(
          data=>{
            this.unsafeStudentUrl = URL.createObjectURL(data);
            this.safeStudentUrl = this.sanitizer.bypassSecurityTrustUrl(this.unsafeStudentUrl);
          });
      });
  }

  exploreCurriculum(){
    this.router.navigate(['dashboard/student-curriculum',this.subjectId]);
  }

  goToAccountPage(){
    this.router.navigate(['user-account',this.userId]);
  }

  goToActivityTimeline(){
        this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( StudentActivityTimelinePageComponent,
      {
        size : 'lg',
        scrollable: true, 
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
      studentId : this.studentId,
      subId : this.subjectId,
      name : this.studentObject.studentName,
      image : this.studentImage
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    // modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    // }); 
  }

  viewResults(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( ViewStudentResultsModalComponent,
      {
        size : 'lg',
        scrollable: true,
        windowClass: 'modal-xl',
        keyboard: false
      });
    let data = {
      studentId : this.studentId,
      subjectId : this.subjectId,
      name : this.studentObject.studentName,
      image : this.studentImage
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    // modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    // }); 
  }
 

 getColor(){
    this.sharedDataService.setfooterColor(this.loggedInUser.offices[0].theme);
    return this.loggedInUser.offices[0].theme + '!important';
  }

 logOut(): void {
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( LogoutWarningModalComponent,
      {
        size : 'md',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = {
     email : this.loggedInUser.email 
    }

    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });

    modalRef.componentInstance.passEntry.subscribe((receivedEntry : string) => {
    });  
  }

  currentRole = "PARENT";
  switchDashboard(){
    this.config.class = 'modal-md';
    const modalRef = this.ngbModalService.open( SwitchDashboardComponent,
      {
        size : 'sm',
        scrollable: true,
        windowClass: 'app-modal-window',
        keyboard: false
      });
    let data = { 
      userId : this.userId,
      currentRole : this.currentRole
    }
    modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
      }, (reason) => {
      });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry : any) => {
    });    
  }

  goBack() {
    this.router.navigate(['dashboard/student']).then();
  }

  reloadPage() {
    window.location.reload();
  }
} 
